import React from 'react';
import * as turf from '@turf/turf';

const radiansToDegrees = rad => (rad + (rad > 0 ? -2*Math.PI*Math.floor(rad/(2*Math.PI)) : 2*Math.PI*Math.ceil(-rad/(2*Math.PI))))*360/(2*Math.PI);

const pointQuery = (grid, georaster, field) => {

    turf.featureEach(grid, feature => {
        const lng = feature.geometry.coordinates[0];
        const lat = feature.geometry.coordinates[1];
        const x = Math.floor((lng - georaster.xmin) / georaster.pixelWidth);
        const y = Math.floor((georaster.ymax - lat) / georaster.pixelHeight);

        if (x > 0 && x < georaster.width && y > 0 && y < georaster.height) {
            let val = georaster.values.map(rows => rows[y][x]);
            feature.properties[field] = field === 'windAngle' ? radiansToDegrees(val[0]) : val[0];
        }
    });

    return grid;
}

const buildFakeGrid = (spacing , extent) => {

    var grid = turf.pointGrid(extent, spacing, {units: 'kilometers'});
    turf.featureEach(grid, feature => {

        feature.properties.windAngle = Math.floor(Math.random() * 360)
        feature.properties.windModule = Math.floor(Math.random() * 50)

    });
    return grid;
}
export default class GeoJsonUtils {

    static buildGeoJSONFromGeorasters = (georasters = [], fields = []) => {
        let extent = [ 14.839, 37.327, 17.641, 40.579];
        let spacing = 10;

        // Add the values of the i-th georaster as i-th field to geoJson features
        if (georasters.length > 0 && georasters.length === fields.length){
            let grid = turf.pointGrid(extent, spacing, {units: 'kilometers'});
            georasters.forEach((raster,i) => pointQuery(grid, raster, fields[i]))

            return grid;
        }
        return null;

    }

    static buildFakeGeoJSONFromGeorasters = (georasters = [], fields = []) => {
        let extent = [ 14.839, 37.327, 17.641, 40.579];
        let spacing = 10;
        return buildFakeGrid(spacing, extent);;

    }


}
