import React, { useEffect, useState } from 'react';
import InformatiEPreparatiItem from '#/frontoffice/components/InformatiEPreparatiItem.jsx';
import StaticPageClient from '#/lib/StaticPageClient'
import _ from "lodash"
import { Fab } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

var baseItems = {
    ip1 : {
      id: "informati-e-preparati-1",
      text: "Piene dei fiumi",
      imgUrl: "/img/1x/fiumehover.png",
      imgUrlAlternative: "/img/1x/piene_dei_fiumi_2.png",
	  imgCover: "/img/eventi/piene.jpg",
    },
    ip2 : {
      id: "informati-e-preparati-2",
      text: "Frane e Smottamenti",
      imgUrl: "/img/1x/franahover.png",
      imgUrlAlternative: "/img/1x/frane.png",
      imgCover: "/img/eventi/frane.jpg",

    },
    ip3 : {
      id: "informati-e-preparati-3",
      text: "Temporali",
      imgUrl: "/img/1x/temporalehover.png",
      imgUrlAlternative: "/img/1x/temporale.png",
      imgCover: "/img/eventi/temporale.jpg",
     
    },
    ip4 : {
      id: "informati-e-preparati-4",
      text: "Neve",
      imgUrl: "/img/1x/nevehover.png",
      imgUrlAlternative: "/img/1x/neve.png",
      imgCover: "/img/eventi/neve.jpg",
    },
    ip5 : {
      id: "informati-e-preparati-5",
      text: "Pioggia che gela",
      imgUrl: "/img/1x/pioggiagelohover.png",
      imgUrlAlternative: "/img/1x/pioggia_gelo.png",
      imgCover: "/img/eventi/pioggia.jpg",
      
    },
    ip6 : {
      id: "informati-e-preparati-6",
      text: "Vento",
      imgUrl: "/img/1x/ventohover.png",
      imgUrlAlternative: "/img/1x/vento.png",
      imgCover: "/img/eventi/vento.jpg",
      
    },
    ip7 : {
      id: "informati-e-preparati-7",
      text: "Temperature Estreme",
      imgUrl: "/img/1x/temperaturehover.png",
      imgUrlAlternative: "/img/1x/temperature.png",
      imgCover: "/img/eventi/temperature-freddo.jpg"

    },
    ip8 : {
      id: "informati-e-preparati-8",
      text: "Mareggiate / Stato del Mare",
      imgUrl: "/img/1x/mareggiatahover.png",
      imgUrlAlternative: "/img/1x/mareggiate.png",
      imgCover: "/img/eventi/mareggiate.jpg",
      
    },
    ip9 : {
      id: "informati-e-preparati-9",
      text: "Valanghe",
      imgUrl: "/img/1x/valangahover.png",
      imgUrlAlternative: "/img/1x/valanghe.png",
      imgCover: "/img/eventi/valanghe.jpg"
    }
};

function InformatiEPreparati(props) {

	const [items, setItems] = useState(Object.values(baseItems))

	useEffect(()=>{
		Promise.all([
			getStaticPagePromise("ip_1"),
			getStaticPagePromise("ip_2"),
			getStaticPagePromise("ip_3"),
			getStaticPagePromise("ip_4"),
			getStaticPagePromise("ip_5"),
			getStaticPagePromise("ip_6"),
			getStaticPagePromise("ip_7"),
			getStaticPagePromise("ip_8"),
			getStaticPagePromise("ip_9"),
		])
		.then(([ip1,ip2,ip3,ip4,ip5,ip6,ip7,ip8,ip9])=>{
			console.log(ip1,ip2,ip3,ip4,ip5,ip6,ip7,ip8,ip9)
			let itemsObj = _.cloneDeep(baseItems);
			itemsObj["ip1"].content = ip1
			itemsObj["ip2"].content = ip2
			itemsObj["ip3"].content = ip3
			itemsObj["ip4"].content = ip4
			itemsObj["ip5"].content = ip5
			itemsObj["ip6"].content = ip6
			itemsObj["ip7"].content = ip7
			itemsObj["ip8"].content = ip8
			itemsObj["ip9"].content = ip9

			console.log(">>>>>>>>>",Object.values(itemsObj))
			setItems(Object.values(itemsObj))
		})
	},[])


	const getStaticPagePromise = (staticPageCode)=>{
		return new Promise((res,rej)=>{
			StaticPageClient.getByCode(
				(sp)=>{
					res(sp.code)
				},
				()=>{},
				staticPageCode
			)
		})
	}

  const downloadImage = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
    <div className='container'>
      <div className="row" style={{ marginTop:"1rem", paddingTop: "3rem", paddingBottom: "2rem" }}>
        {!_.isEmpty(items) && items.map((item, idx) => {console.log(item);return <InformatiEPreparatiItem key={idx} {...item}></InformatiEPreparatiItem>})}
      </div>
    </div>
    </>
  );
}

export default InformatiEPreparati;