import React, {useState, useEffect} from 'react';
import StaticPageClient from '#/lib/StaticPageClient'

const LinkUtiliPage = () => {
    const [page, setPage] = useState(null)

	useEffect(()=>{
		StaticPageClient.getByCode(
			(sp)=>{
				console.log(sp)
				setPage(sp.code)
			},
			()=>{},
			"links"
		)
	})

    return<div className="generic-page container-fluid">
		{!!page && <p dangerouslySetInnerHTML={{ __html: `${page}` }}></p>}
        {/*<><h2>Elenco contatti C.F.M.</h2>
        <ReactTabulator
            columns={[{title: "Nome", field: 'name', headerFilter: true,  headerFilterPlaceholder:"Filtra per nome..."},{title: "Ruolo", field: 'role'},{title: "Telefono", field: 'phone', headerSort: false}]}
            data={data}
            options={{height: '70vh'}}
            key={"contacts"}/></>*/}
    </div>;
}
/*
const liStyle = {paddingLeft: '30px'};
const LinkUtiliPage = () =>
    <div className="generic-page container">
        <h2 className="textAlignCenter">Link utili</h2>
        <section className="article-content clearfix">
            <p><strong>Documenti</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="https://www.cfd.calabria.it/DatiVari/PDF/Direttiva_Con_Appendici%20123%20del%2029%20Novembre%202017.pdf" target="_blank"
                                       rel="noreferrer">DIRETTIVA - Sistema di allertamento Regionale</a></li>
                <li style={liStyle}><a className="category" href="https://www.cfd.calabria.it/DatiVari/PDF/CircolareIntegrativa.pdf" target="_blank"
                                       rel="noreferrer">CIRCOLARE INTEGRATIVA - Sistema di Allertamento</a></li>
                <li style={liStyle}><a className="category" href="https://www.cfd.calabria.it/DatiVari//PDF/DGR%2024%20luglio%202007.pdf" target="_blank"
                                       rel="noreferrer">LINEE GUIDA - Pianificazione Comunale</a></li>
                <li style={liStyle}><a className="category" href="https://www.cfd.calabria.it/DatiVari/PDF/Dichiarazione_operativita_CFM.pdf" target="_blank"
                                       rel="noreferrer">Dichiarazione Operatività - C.F.M.</a></li>
            </ul>
            <p><strong>Istituzioni Internazionali</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="http://www.fema.gov/" target="_blank"
                                       rel="noreferrer">Federal Emergency Management Agency (FEMA)</a></li>
                <li style={liStyle}><a className="category" href="http://www.esa.int/ESA" target="_blank"
                                       rel="noreferrer">Esa - Ente Spaziale Europeo</a></li>
            </ul>
            <p><strong>Istituzioni Nazionali</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="http://www.parlamento.it/home" target="_blank"
                                       rel="noreferrer">Parlamento</a></li>
                <li style={liStyle}><a className="category" href="http://www.governo.it" target="_blank"
                                       rel="noreferrer">Governo</a></li>
                <li style={liStyle}><a className="category" href="http://www.interno.gov.it/it" target="_blank"
                                       rel="noreferrer">Ministero degli Interni</a></li>
                <li style={liStyle}><a className="category" href="http://www.mit.gov.it/mit/site.php"
                                       target="_blank" rel="noreferrer">Ministero Infrastrutture e Trasporti</a>
                </li>
                <li style={liStyle}><a className="category" href="http://www.protezionecivile.gov.it/"
                                       target="_blank" rel="noreferrer">Dipartimento Nazionale di Protezione
                    Civile</a></li>
                <li style={liStyle}><a className="category" href="http://www.minambiente.it/" target="_blank"
                                       rel="noreferrer">Ministero dell'Ambiente</a></li>
                <li style={liStyle}><a className="category" href="http://www.asi.it/" target="_blank"
                                       rel="noreferrer">Agenzia Spaziale Italiana</a></li>
            </ul>
            <p><strong>Link Regionali di interesse</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="http://www.regione.calabria.it/"
                                       target="_blank" rel="noreferrer">Regione Calabria</a></li>
                <li style={liStyle}><a className="category" href="http://www.consiglioregionale.calabria.it"
                                       target="_blank" rel="noreferrer">Consiglio Regionale della Calabria</a>
                </li>
                <li style={liStyle}><a href="http://www.autoritadibacinocalabria.it/" target="_blank"
                                       rel="noreferrer">Autorità bacino Regione Calabria</a></li>
                <li style={liStyle}><a className="category" href="http://www.regione.calabria.it/?module=bur"
                                       target="_blank" rel="noreferrer">BUR Regionale</a></li>
                <li style={liStyle}><a className="category" href="http://www.arssacalabria.it/" target="_blank"
                                       rel="noreferrer">ARSSA</a></li>
            </ul>
            <p><strong>Utilità meteo</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="http://www.usgs.gov/" target="_blank"
                                       rel="noreferrer">U.S. Geological Survey</a></li>
                <li style={liStyle}><a className="category" href="https://www.wmo.int/pages/index_en.html"
                                       target="_blank" rel="noreferrer">Worle Meteorological Organization</a>
                </li>
                <li style={liStyle}><a className="category" href="http://www.noaa.gov/" target="_blank"
                                       rel="noreferrer">NOOA (National Oceanic and Atmospheric Administration</a>
                </li>
                <li style={liStyle}><a className="category" href="http://ncar.ucar.edu/" target="_blank"
                                       rel="noreferrer">NCAR (National Center for Atmosferic Research)</a></li>
                <li style={liStyle}><a className="category" href="http://gmao.gsfc.nasa.gov/" target="_blank"
                                       rel="noreferrer">NASA Goddard Data Assimilation Office USA</a></li>
                <li style={liStyle}><a className="category" href="http://www.ecmwf.int/" target="_blank"
                                       rel="noreferrer">European Centre for Medium-Range Weather Forecasts</a>
                </li>
                <li style={liStyle}><a className="category" href="http://www.meteofrance.com/accueil"
                                       target="_blank" rel="noreferrer">Meteo France</a></li>
                <li style={liStyle}><a className="category" href="http://www.crati.it/" target="_blank"
                                       rel="noreferrer">CRATI</a></li>
                <li style={liStyle}><a className="category" href="http://www.lamma.rete.toscana.it/"
                                       target="_blank" rel="noreferrer">LAMMA</a></li>
                <li style={liStyle}><a className="category" href="http://www.ibimet.cnr.it/" target="_blank"
                                       rel="noreferrer">IBIMET - CNR</a></li>
                <li style={liStyle}><a className="category" href="http://www.isac.cnr.it/" target="_blank"
                                       rel="noreferrer">ISAC - CNR</a></li>
                <li style={liStyle}><a className="category" href="http://www.cesi.it/Pages/default.aspx"
                                       target="_blank" rel="noreferrer">CESI - METEO</a></li>
            </ul>
            <p><strong>Utilità per il rischio idrogeologico</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a href="http://www.camilab.unical.it/" target="_blank" rel="noreferrer">CAMILAB
                    - Università</a></li>
                <li style={liStyle}><a className="category" href="http://www.irpi.cnr.it/" target="_blank"
                                       rel="noreferrer">CNR - IRPI</a></li>
            </ul>
            <p><strong>Utilità per il rischio sismico</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="http://193.204.162.114/gnv/index.html"
                                       target="_blank" rel="noreferrer">Didattica della Vulcanologia</a></li>
                <li style={liStyle}><a className="category" href="http://gnv.ingv.it/" target="_blank"
                                       rel="noreferrer">Gruppo Nazionale per la Vulcanologia</a></li>
                <li style={liStyle}><a className="category" href="http://www.ingv.it/it/" target="_blank"
                                       rel="noreferrer">Istituto Nazionale Geofisica e Vulcanologia</a></li>
            </ul>
            <p><strong>Utilità multirischio</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="http://www.vigilfuoco.it/aspx/home.aspx"
                                       target="_blank" rel="noreferrer">Corpo Nazionale dei Vigili del Fuoco</a>
                </li>
                <li style={liStyle}><a className="category" href="http://www.cnsas.it/" target="_blank"
                                       rel="noreferrer">Corpo Nazionale Soccorso Alpino e Speleologico</a></li>
                <li style={liStyle}><a className="category" href="http://www.salvamento.it/" target="_blank"
                                       rel="noreferrer">Societ</a></li>
                <li style={liStyle}><a className="category" href="http://www.cospas-sarsat-italy.it/"
                                       target="_blank" rel="noreferrer">Cospas Sarsat Stazione Satellitare
                    Internazionale per la Ricerca ed il Soccorso</a></li>
            </ul>
            <p><strong>Enti Nazionali</strong></p>
            <ul style={{listStyleType: 'circle'}}>
                <li style={liStyle}><a className="category" href="http://www.cnr.it/sitocnr/home.html"
                                       target="_blank" rel="noreferrer">C.N.R.</a></li>
                <li style={liStyle}><a className="category" href="http://www.enea.it/it" target="_blank"
                                       rel="noreferrer">E.N.E.A.</a></li>
                <li style={liStyle}><a className="category" href="http://www.stradeanas.it/" target="_blank"
                                       rel="noreferrer">ANAS</a></li>
                <li style={liStyle}><a className="category" href="http://www.cri.it/home" target="_blank"
                                       rel="noreferrer">Croce Rossa Italiana</a></li>
            </ul>
        </section>
    </div>
*/
export default LinkUtiliPage;
