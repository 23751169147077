import React from 'react';
import { MdPermIdentity, MdMailOutline, MdPhoneAndroid, MdEdit, MdDelete }  from 'react-icons/md';
import Fab from '@material-ui/core/Fab';
import { withRouter } from 'react-router';


class UserItem extends React.Component {


	render() {
		let title = this.props.surname + ' ' + this.props.name;
		const iconSize = 25;

		return (
			<>
				<div className="accordion" id={"accordion_" + this.props.username}>
					<div className="card" style={{ marginBottom: '0.7rem', borderRadius: '5px', border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }}>
						<div className="card-header d-flex flex-wrap justify-content-between">
							<button className={"col-"+ (!!this.props.editable ? "9" : "11") + " btn accordion-header text-left"} type="button" data-toggle="collapse" data-target={"#collapse_" + this.props.username} aria-expanded="true">
								<div>
									<span className="item-title">{title}</span>
								</div>
								<div>
									<span> <MdPermIdentity /> {this.props.username} </span>
								</div>
								<div>
									<span> <MdMailOutline /> {this.props.email} </span>
								</div>
								<div>
									<span> <MdPhoneAndroid /> {this.props.mobilePhone} </span>
								</div>

							</button>


							{!!this.props.editable
								?
								<>
								<div className='d-flex'>
									<div className="my-auto mr-2">
										<Fab size="medium" aria-label="Modifica" onClick={()=>{this.props.history.push("user/edit/" + this.props.username)}} 
											style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}} 
										>
											<MdEdit size={iconSize} title="Modifica"/>
										</Fab>
									</div>
									<div className="my-auto">
										<Fab size="medium" aria-label="Cancella" onClick={() => this.props.onClickDelete(this.props.username)} 
											style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
										>
											<MdDelete size={iconSize} title="Cancella" />
										</Fab>

									</div>
								</div>
								</>
								:
								<></>

							}
						</div>
					</div>
				</div>
				<div id={"collapse_" + this.props.username} className="collapse" data-parent={"#accordion_" + this.props.username}>
					{this.props.details}
				</div>
			</>
		)
	}

}

export default withRouter(UserItem)