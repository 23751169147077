import React from 'react'
import {GeoJSON, TileLayer} from 'react-leaflet';
import GISTools from '#/lib/GISTools';
import SensorClient from '#/lib/SensorClient';
import {FaMapMarkerAlt} from "react-icons/fa";
import {
	ALL_CATEGORIES_OPTIONS,
	COMBINED_TO_STANDARD,
	COMBO_TYPE_BY_CATEGORY,
	MEASUREMENT_CATEGORY_ICONS
} from "#/lib/MeasurementCategory";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';


import Snackbar from '@material-ui/core/Snackbar';
import {Alert, AlertTitle} from '@material-ui/lab';
import Markers from "#/lib/Markers";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StationClient from "#/lib/StationClient";
import _ from "lodash";
import {BiSelectMultiple} from "react-icons/bi";
import MapComponent from "#/commons/map/MapComponent";
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import WidthUtils from "#/lib/WidthUtils"
import SwitchComponent from '#/commons/components/SwitchComponent';
import { Button } from '@material-ui/core';

export default class CompareSection extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			stationOptions : [],
			measurementOptions : [],
			compareList : [],
			compareKey : 0,
			snackbar : {
				display : false
			},
			availableMeasurements: [],
			selectedCategory: null,
			selectionsCache: {},
			filteredStationsGeoJson: {},
			mapKey: 0,
			showMap : false
		}
	}

	componentDidMount = () => {
		let compareList = !!this.props.compareList ? this.props.compareList : [];
			this.setState({
			availableMeasurements: ALL_CATEGORIES_OPTIONS.filter(opt => !Object.keys(COMBO_TYPE_BY_CATEGORY).includes(opt.value)),
			compareList,
			selectionsCache: this.buildSelections(compareList),
			compareKey : (this.state.compareKey+1)%1000
		})
	}
	buildSelections = compareList => compareList.reduce((map, elem, i) => {
		const sensorCategory = elem.measure.value ? elem.measure.value : elem.measure.sensor[0].category;
		const stationCode = elem.station.value;
		map[sensorCategory] = {...map[sensorCategory],
			[stationCode]: true}
		// if (this.props.stations.length !== 0) {
		// 	map[sensorCategory] = {...map[sensorCategory],
		// 		['ALL']: true}
		// }
		return map;
	}, {});

	onSelectedStation = stationCode => {
		if (!this.state.selectionsCache[this.state.selectedCategory] || !this.state.selectionsCache[this.state.selectedCategory][stationCode]) {
			this.getAvailableMeasurements(stationCode)
				.then((availableMeasurement) => {
					const currentCompareMeasure = availableMeasurement.find(meas => meas.value === this.state.selectedCategory);
					this.addSensor(stationCode, currentCompareMeasure);
				});
		} else {
			this.deleteSensor(stationCode, this.state.selectedCategory);
		}

	}

	getAvailableMeasurementsByCategory() {
		return new Promise((resolve, reject) => {
			let availableMeasurement = [];
			SensorClient.getSensorsBySingleCategory(this.state.selectedCategory,
				(data) => {
					data.forEach((value, index) => {
						let measurement = {};
						measurement.value = value.category;
						measurement.label = value.label;
						measurement.icon = MEASUREMENT_CATEGORY_ICONS[value.category];
						measurement.sensor = [{
							category: value.category,
							sensorCode: value.code,
						}];
						measurement.group = "SENSOR";
						measurement.groupLabel = "Misure sensori";
						measurement.stationCode = value.stationCode;
						availableMeasurement.push(measurement);
					})
					resolve(availableMeasurement);
				}
			);
		});
	}

	getAvailableMeasurements = stationCode => {
		return new Promise((resolve, reject) => {
			let availableMeasurement = [];
			SensorClient.getSensorsByStation(stationCode,
				(data) => {
					data.forEach((value, index) => {
						let measurement = {};
						measurement.value = value.category;
						measurement.label = value.label;
						measurement.icon = MEASUREMENT_CATEGORY_ICONS[value.category];
						measurement.sensor = [{
							category: value.category,
							sensorCode: value.code,
						}];
						measurement.group = "SENSOR";
						measurement.groupLabel = "Misure sensori";

						availableMeasurement.push(measurement);
					})
					resolve(availableMeasurement);
				}
			);
		});
	}

	buildStationOptions(data){

		let stationOptions = [];
		let geoJson = !!data ? data : this.props.stationsGeoJSON;
		let targetStation = this.props.target.station.value ? this.props.target.station.value : this.props.stationOptions[1].value;
		let targetPoint = GISTools.getStationByField(this.props.stationsGeoJSON,"code",targetStation);
		let stationByDistance = GISTools.getArrayStationByDistance(targetPoint, geoJson);
		if (this.props.allowAddAll){
			stationOptions.push({
				name: 'ALL',
				label: "Tutte le stazioni",
				value: 'ALL',
				icon: <BiSelectMultiple className="mr-2"></BiSelectMultiple>
			});
		}
		stationByDistance.forEach(station => {
			stationOptions.push({
				name: station.name,
				label: station.name + "   ( "+station.distance+" km )",
				value: station.code,
				icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
			});
		})
		return stationOptions
	}

	addSensors(availableMeasurements){
		let compareList = this.state.compareList;
		let toBeAdded = this.state.stationOptions
			.filter(statOpt => statOpt.name !== 'ALL')
			.map(statOpt => ({
					station: statOpt,
					measure: availableMeasurements.find(meas => meas.stationCode === statOpt.value)
				})
			).filter(elem => ![...this.state.compareList, this.props.target].find(comp => comp.station.value === elem.station.value && comp.measure.value === elem.measure.value));

		if(this.state.compareList.length +  toBeAdded.length > this.props.maxCompareListLenght){
			this.setState({
				snackbar:{
					display:true,
					severity:"warning",
					title:"Numero massimo di sensori superato",
					text:"Seleziona un numero minore di sensori per procedere",
				}
			})
			return;
		}

		const {selectedCategory} = this.state;
		let selectionsCache = this.state.selectionsCache;

		toBeAdded.map(elem => elem.station.value).forEach(
			stationCode =>
				selectionsCache[selectedCategory] = {
					...selectionsCache[selectedCategory],
					[stationCode]: true
				}
		)
		selectionsCache[selectedCategory] = {
			...selectionsCache[selectedCategory],
			ALL : true
		}

		this.setState({
			mapKey: (this.state.mapKey + 1) % 1000,
			selectionsCache,
			compareList: [...compareList,...toBeAdded],
			snackbar:{
				display:true,
				severity:"success",
				title:"SUCCESS",
				text:"Sensori inseriti per il confronto",

			}
		});
	}
	addSensor(stationCode, currentCompareMeasure){
		let compareList = this.state.compareList;
		let currentCompareStation = this.state.stationOptions.find(stat => stat.value === stationCode);

		if(!!!currentCompareStation || !!!currentCompareMeasure){
			this.setState({
				snackbar:{
					display:true,
					severity:"error",
					title:"ERROR",
					text:"Seleziona un sensore",
				}
			})
			return;
		}

		let foundElementInCompareList = this.state.compareList.find(element => (element.station.value===currentCompareStation.value && element.measure.value===currentCompareMeasure.value));
		if(!!foundElementInCompareList){
			this.setState({
				snackbar:{
					display:true,
					severity:"warning",
					title:"WARNING",
					text:"Sensore già inserito per il confronto",
				}
			})
			return;
		}

		if(this.props.target.station.value===currentCompareStation.value && this.props.target.measure.value===currentCompareMeasure.value){
			this.setState({
				snackbar:{
					display:true,
					severity:"warning",
					title:"WARNING",
					text:"Sensore già selezionato come target",
				}
			})
			return;
		}
		if(this.state.compareList.length === this.props.maxCompareListLenght){
			this.setState({
				snackbar:{
					display:true,
					severity:"warning",
					title:"Numero massimo di sensori superato",
					text:"Rimuovi  un sensore dalla lista per procedere",
				}
			})
			return;
		}


		compareList.push({
			station: currentCompareStation,
			measure: currentCompareMeasure,
		})
		const {selectedCategory} = this.state;
		let selectionsCache = this.state.selectionsCache;
		selectionsCache[selectedCategory] = {
			...selectionsCache[selectedCategory],
			[stationCode]: true
		}
		let sub = this.props.target.measure.value === selectedCategory ? 2 : 1;
		if (this.props.allowAddAll &&
			Object.keys(selectionsCache[selectedCategory]).filter(k => k !== 'ALL').map(key => selectionsCache[selectedCategory][key] === true).length === this.state.stationOptions.length - sub){
			selectionsCache[selectedCategory] = {
				...selectionsCache[selectedCategory],
				ALL : true
			}
		}
		this.setState({
			mapKey: (this.state.mapKey + 1) % 1000,
			selectionsCache,
			compareList: compareList,
			snackbar:{
				display:true,
				severity:"success",
				title:"SUCCESS",
				text:"Sensore inserito per il confronto",

			}
		});
	}

	deleteSensor(stationCode, sensorCategory){
		let compareList = this.state.compareList.filter(element => (!(element.station.value===stationCode && element.measure.value===sensorCategory)));
		let selectionsCache = this.state.selectionsCache;
		selectionsCache[sensorCategory] = {
			...selectionsCache[sensorCategory],
			[stationCode]: false
		}

		let sub = this.props.target.measure.value === sensorCategory ? 1 : 0;
		if (this.props.allowAddAll &&
			Object.keys(selectionsCache[sensorCategory]).filter(k => k !== 'ALL').map(key => selectionsCache[sensorCategory][key] === true).length !== this.state.stationOptions.length - sub){
			selectionsCache[sensorCategory] = {
				...selectionsCache[sensorCategory],
				ALL : false
			}
		}
		this.setState({
			mapKey: (this.state.mapKey + 1) % 1000,
			selectionsCache,
			compareList: compareList,
		})
	}
	deleteSensors(){
		let {selectedCategory, selectionsCache} = this.state;
		let compareList = this.state.compareList.filter(element => (!element.measure.value===selectedCategory));
		delete selectionsCache[selectedCategory];
		this.setState({
			mapKey: (this.state.mapKey + 1) % 1000,
			selectionsCache,
			compareList: compareList,
		})
	}

	toggleStation = (event) => {
		const {name: stationCode, checked} = event.target;
		if (stationCode === 'ALL') {
			if (checked) {
				this.getAvailableMeasurementsByCategory()
					.then((availableMeasurements) => {
						this.addSensors(availableMeasurements);
					})
			} else {
				this.deleteSensors()
			}
		} else {
			if (checked) {
				this.getAvailableMeasurements(stationCode)
					.then((availableMeasurement) => {
						const currentCompareMeasure = availableMeasurement.find(meas => meas.value === this.state.selectedCategory);
						this.addSensor(stationCode, currentCompareMeasure);
					});
			} else {
				this.deleteSensor(stationCode, this.state.selectedCategory)
			}
		}
	}
	findEquippedStations(selectedCategory){
		this.resetCategory();
		if (!!selectedCategory) {


			if (["WV", "WS", "WR"].includes(selectedCategory)) {
				selectedCategory = COMBINED_TO_STANDARD[selectedCategory];
			}

			// Search equipped stations
			StationClient.getStationsGeojsonByCategory(
				selectedCategory,
				(data) => {
					console.log("Found " + data.features.length + " station equipped")
					const stationOptions = this.buildStationOptions(data);
					this.setState({
						stationOptions,
						selectedCategory,
						filteredStationsGeoJson: data,
						mapKey: (this.state.mapKey + 1) % 1000
					})
				},
				(err) => {
					console.log(err)
				}
			)
		} else this.resetCategory();
	}

	resetCategory = () => {
		this.setState({
			selectedCategory: null,
			stationOptions: [],
			filteredStationsGeoJson: {},
			mapKey: (this.state.mapKey + 1) % 1000
		})
	}

	onToggleShowTable = () => {
		this.setState({
			showMap : !this.state.showMap
		})
	}

	render() {
		let mobileMode = WidthUtils.isMobile()
		if(mobileMode){
			return this.renderMobile();
		}
		else{
			return this.renderDesktop();
		}
	}

	renderMobile() {

		const {selectionsCache, selectedCategory} = this.state;

		return (
			<>
				<div className="mt-4">
					<div className="mx-4">
						<InputSelectComponent
							placeholder={"Selezione Categoria"}
							onChange={(option) => this.findEquippedStations(option)}
							optionList={this.state.availableMeasurements}
							value={!!this.state.selectedCategory ? this.state.selectedCategory : null}
							isSearchable={true}
							deleteTooltip={"Rimuovi misura selezionata"}
						/>
					</div>
					<div className="mx-4">
						<SwitchComponent
							name={"Mostra mappa"}
							value={this.state.showMap}
							disabled={false}
							onChange={(e) => this.onToggleShowTable()}
						/>
					</div>

					<div>
						{this.state.showMap ? 
							<GISSelection
								borders = {GISTools.getCalabriaBorders()}
								zones = {GISTools.getAllZonePolygon()}
								target = {this.props.target}
								handleStationSelect = {code => this.onSelectedStation(code)}
								compareList = {this.state.compareList}
								selectedCategory = {this.state.selectedCategory}
								stationsGeoJSON = {this.state.filteredStationsGeoJson}
								mapKey = {this.state.compareKey +"_"+ this.state.mapKey}
								mapHeight = {"60vh"}
							></GISSelection>
						:

						<div className="mx-4" style={{overflowY: 'scroll', maxHeight: '60vh', overflowX: 'hidden'}}>
							<FormGroup>
								{this.state.stationOptions.map(station =>
									<FormControlLabel
										key ={station.value}
										control={<Checkbox
											disabled={this.props.target.station.value === station.value && this.props.target.measure.value === this.state.selectedCategory}
											checked={!!selectionsCache[selectedCategory] ? selectionsCache[selectedCategory][station.value] : undefined}
											onChange={e => this.toggleStation(e)} name={station.value} />}
										label={station.label}
									/>
								)}
							</FormGroup>

						</div>
							
						}
					</div>
					<Snackbar
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						open={this.state.snackbar.display}
						autoHideDuration={2500}
						onClose={()=>{this.setState({snackbar: {display:false}})}}>
						<Alert elevation={6} severity={this.state.snackbar.severity}>
							<AlertTitle>{this.state.snackbar.title}</AlertTitle>
							{this.state.snackbar.text}
						</Alert>
					</Snackbar>

					<div className='d-flex justify-content-end'>
						<Tooltip title="Confronta sensori" className='mr-3 my-3'>
							<Button
								aria-label="add"
								variant="contained"
								color="primary"
								style={{  minWidth: "10vw" }}
								onClick={(e) => this.props.confirm(this.state.compareList) }
							>
								<i className="ml-2 fas fa-check mr-2"></i>
								Conferma
							</Button>
						</Tooltip>
					</div>
				</div>
		
			</>

		)
	}

	renderDesktop() {
		let comparedMeasure = this.state.compareList.map((element,index) => <SensorDisplayComponent
				key={index}
				station={element.station}
				measure={element.measure}
				/* 			aggregationFunction={element.aggregationFunction}
                 */			deleteSensor = {(station, measure)=> this.deleteSensor(station.value, measure.value)}
				context={"COMPARED"}
			></SensorDisplayComponent>
		);
		const {selectionsCache, selectedCategory} = this.state;


		return (
			<>
				<div className="row mt-4">

					<div className="col-3">
						<div className="container">

							<div className="">
								<h2>Dati selezionati</h2>
							</div>
							<div className="">
								<h3>Intervallo selezionato: </h3>
							</div>
							<div className="">
								<span>{this.props.interval.start.format("DD/MM/YYYY")} - {this.props.interval.end.format("DD/MM/YYYY")}</span>
							</div>
							<div className="">
								<h3>Sensore selezionato: </h3>
							</div>
							<div className="col">
								<SensorDisplayComponent
									station={this.props.target.station.value ? this.props.target.station : this.props.stationOptions[0]}
									measure={this.props.target.measure}
									context={"TARGET"}>
								</SensorDisplayComponent>
							</div>
							<hr></hr>
							<div className="">
								<h3>Altri sensori selezionati: </h3>
							</div>
							<div className="col" style={{maxHeight: '45vh', minWidth: '22vw', overflowY: 'scroll', overflowX: 'hidden'}}>
								{comparedMeasure}
							</div>
						</div>


					</div>
					<div className="col-5" >
						<GISSelection
							borders = {GISTools.getCalabriaBorders()}
							zones = {GISTools.getAllZonePolygon()}
							target = {this.props.target}
							handleStationSelect = {code => this.onSelectedStation(code)}
							compareList = {this.state.compareList}
							selectedCategory = {this.state.selectedCategory}
							stationsGeoJSON = {this.state.filteredStationsGeoJson}
							mapKey = {this.state.compareKey +"_"+ this.state.mapKey}
						></GISSelection>
					</div>
					<div className="col-4" >
						<Snackbar
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							open={this.state.snackbar.display}
							autoHideDuration={2500}
							onClose={()=>{this.setState({snackbar: {display:false}})}}>
							<Alert elevation={6} severity={this.state.snackbar.severity}>
								<AlertTitle>{this.state.snackbar.title}</AlertTitle>
								{this.state.snackbar.text}
							</Alert>
						</Snackbar>
						<div className="container">
							<div className="row">
								<h2>Compara con:</h2>
							</div>
							<div className="row">
								<h3>Scegli misure:</h3>
							</div>
							<div className=" m-2">
								<InputSelectComponent
									placeholder={"Selezione Categoria"}
									onChange={(option) => this.findEquippedStations(option)}
									optionList={this.state.availableMeasurements}
									value={!!this.state.selectedCategory ? this.state.selectedCategory : null}
									isSearchable={true}
									deleteTooltip={"Rimuovi misura selezionata"}
								/>
							</div>
							<div style={{overflowY: 'scroll', maxHeight: '60vh', overflowX: 'hidden'}}>
								<FormGroup>
									{this.state.stationOptions.map(station =>
										<FormControlLabel
											key ={station.value}
											control={<Checkbox
												disabled={this.props.target.station.value === station.value && this.props.target.measure.value === this.state.selectedCategory}
												checked={!!selectionsCache[selectedCategory] ? selectionsCache[selectedCategory][station.value] : undefined}
												onChange={e => this.toggleStation(e)} name={station.value} />}
											label={station.label}
										/>
									)}
								</FormGroup>

							</div>

						</div>
					</div>

				</div>

				<div className='d-flex justify-content-end'>
					<Tooltip title="Confronta sensori" className='mr-3 my-3'>
						<Button
							aria-label="add"
							variant="contained"
							color="primary"
							style={{  minWidth: "10vw" }}
							onClick={(e) => this.props.confirm(this.state.compareList) }
						>
							<i className="ml-2 fas fa-check mr-2"></i>
							Conferma
						</Button>
					</Tooltip>
				</div>
			</>

		)
	}
}


const SensorDisplayComponent = props => {
	let color = props.context === "TARGET" ? { color: "#2b7943" } : { color: "#20468c" }
	let sensorIcon = MEASUREMENT_CATEGORY_ICONS[props.measure.value];
	return (
		<div className="row align-items-center py-2 mb-3"  style={{/* backgroundColor: "#eeeeee", borderRadius: "5px" */}}>
			<div className="col-9 ml-3">

				<div className="row align-items-center">

					<FaMapMarkerAlt style={color} className="mr-2"></FaMapMarkerAlt><div>{props.station.label ? props.station.label : props.station.name}</div>
				</div>
				<div className="row align-items-center">

					{sensorIcon}<div>{props.measure.label}</div>
				</div>
				{/* {(props.measure.value===MEASUREMENT_CATEGORY.P)
				?
					<div className="row align-items-center">
						{props.aggregationFunction.icon}<div>{props.aggregationFunction.label}</div>
					</div>
					:
					<></>
				} */}
			</div>
			{props.context === "COMPARED"
				?
				<div className="col-2">
					<IconButton aria-label="delete"
								onClick={(e)=>props.deleteSensor(props.station, props.measure)}>
						<DeleteIcon />
					</IconButton>
				</div>
				:
				<></>
			}


		</div>
	);
}


class GISSelection extends React.Component{

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 0,
		maxZoom: 12,
		width: "40vw",
		height: "80vh"

	}


	stationToMarker(feature, latlng) {
		let marker = null;
		let code = feature.properties.code;

		let compareListStationCodes = this.props.compareList.map(x=>x.station.value)
		let markerColor = 'grey';

		let stationMeasurePairs = this.props.compareList.filter(p => p.measure.value === this.props.selectedCategory);
		if (!!stationMeasurePairs && stationMeasurePairs.map(p => p.station.value).includes(code)){
			if (compareListStationCodes.includes(code)) {
				markerColor = '#20468c';
			}
		}
		if (code === this.props.target.station.value && this.props.selectedCategory === this.props.target.measure.value) {
			markerColor = '#2b7943';
		}
		marker = Markers.getMarker(latlng, markerColor, markerColor);
		marker.on('click', () => {
			this.props.handleStationSelect(code);
		});
		return marker;
	}

	render(){
		const tile = <TileLayer
			attribution='<a href="https://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a> '
			url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		/>;
		return (
			<>
				<MapComponent
					width={"100%"}
					height={this.props.mapHeight ?? this.mapOptions.height}
					zoom={this.mapOptions.zoom}
					zoomSnap={false}
					tile={tile}
					minZoom={this.mapOptions.minZoom}
					maxZoom={this.mapOptions.maxZoom}>


					{!_.isEmpty(this.props.stationsGeoJSON)
						?
						<GeoJSON key={"stations_" + this.props.mapKey}
								 data={this.props.stationsGeoJSON}
							/* 						onEachFeature={(feature, layer) => this.onEachStation(feature, layer)}*/
								 pointToLayer={(feature, latlng) => this.stationToMarker(feature, latlng)}
						/>
						:
						<></>

					}

					<GeoJSON
						data={this.props.borders}
						style={{
							fillColor: "#fff",
							fillOpacity: 0,
							weight: 2,
							opacity: 1,
							color: "green",
						}}
					/>

					<GeoJSON
						data={this.props.zones}
						style={{
							fillColor: "#fff0",
							weight: 0.5,
							opacity: 1,
							color: "#20468c",
							fillOpacity: 0
						}}
					/>
				</MapComponent>
			</>
		);
	}
}




