import React, {useState} from 'react'

import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import moment from "moment";
import LandscapeMobileWrapper from  "#/commons/components/LandscapeMobileWrapper"

const TestSelPage = () => {
	const [range, setRange] = useState(null)

	const onPickRange = (start, end, year)=>{
		start = start.startOf('day');

		if (end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString()){
			end = moment().add(-30, 'm');
		} else {
			end = end.add(1,'d').startOf('day');
		}

		let range = {
			start: start,
			end: end
		}
		setRange(range);
	}

    return (
		<div className="generic-page">
        <LandscapeMobileWrapper><span> The content</span></LandscapeMobileWrapper>
		</div>
    )

};

export default TestSelPage;
