import React from 'react'

import moment from "moment"

import {Calendar, CalendarControls} from "react-yearly-calendar";

const BigCalendarTimeZoned = ({timeZone, range, year, onPrevYear, onNextYear, onPickRangeCallback, future, lockedClasses = {}, minDate, maxDate}) => {

	const onPickRange = (start, end, year)=>{
		onPickRangeCallback(toPreferredTimeZone(start), toPreferredTimeZone(end), year);
	}

	const toLocalTimeZone = (momentObj)=>{
		let string = momentObj.format('YYYY-MM-DDTHH:mm:ss')
		return moment(string)
	}

	const toPreferredTimeZone = (momentObj)=>{
		let string = momentObj.format('YYYY-MM-DDTHH:mm:ss')
		return moment.tz(string, timeZone)
	}

    return (
            <>
				<CalendarControls
					year={year}
					showTodayButton={true}
					onPrevYear={() => onPrevYear()}
					onNextYear={() => onNextYear()}
					goToToday={() => { let now = moment(); onPickRange(now, now, now.year()) }}
				/>
				<Calendar
					year={year}
					showDaysOfWeek={true}
					selectRange={true}
					selectedRange={!!range ? [toLocalTimeZone(range.start).startOf("day"), toLocalTimeZone(range.end).add(-1,'d').startOf("day")]:[moment().startOf("day"), moment().endOf("day")]}
					onPickRange={(start, end) => onPickRange(start, end)}
					customClasses={{future: future ? future : day => day.isAfter( moment()), ...(lockedClasses)}}
					minDate={minDate}
					maxDate={maxDate}
				/>
            </>
    )

};

export default BigCalendarTimeZoned;
