import React from "react";
import ThresholdListComponent from "#/commons/components/ThesholdListComponent";
import ThresholdRangeComponent from "#/commons/components/ThesholdRangeComponent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import MathJax from 'react-mathjax';
import Dropzone from 'react-dropzone-uploader'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import _ from 'lodash';
const q = 'q = a (h + e)^{b} + c';


export default class
    extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      min: -1,
      max: 20,
      step: 0.01,
      h0: "",
      values: [],
      colors: [],
      params: [],
      error: {
        /*a : false,
        b : false,
        c : false,*/
        h0 : false
      },
      dirty: false
    };
    this.canvas = React.createRef();
  }

  componentDidMount() {

    let params = [];
    let values = [...this.state.values];
    let minThresholdValue = this.state.max;
    if (this.props.data.thresholds.length !== 0) {
      params = [...this.props.data.thresholds];
      console.log("params", params)
      for (let i = 0; i < params.length; i++) {
        if (params[i].thresholdStart !== -1){
          values.push(params[i].thresholdStart);
        }
        params[i].q = this.buildQFormula(i, params);
        minThresholdValue =
            params[i].thresholdStart < minThresholdValue
                ? params[i].thresholdStart
                : minThresholdValue;
      }
      console.log("minThresholdValue", minThresholdValue)
    } else {
      params.unshift({});
      params[0] = {
        thresholdStart: this.state.min,
        thresholdEnd: minThresholdValue,
        h0 : this.props.data.h0,
        q : !!this.props.data.h0 ? this.buildQFormula(0, params) : q
      }
    }
    this.props.onChangeThresholdsValue(params);

    let colors = this.buildColors(values);

    let h0 = "";
    let geom = [];
    if (!!this.props.data.geometry && 'h0' in this.props.data){
      geom = JSON.parse(this.props.data.geometry);

      this.createPreview(geom);
      this.props.onUploadCsvFile(geom);
      h0 = this.props.data.h0;
      this.props.onChangeZeroValue(h0);
    }

    this.setState({
      params: params,
      colors: colors,
      values: values,
      h0: h0,
      shape: geom
    });
  }
  isFalsyExceptZero = value => value || value === 0;

  componentDidUpdate(prevProps, prevState, snapshot) {
    let valid = !prevState.params.some(({a, b, c, e}) => ([a, b, c, e].some(i => !this.isFalsyExceptZero(i))));
    this.props.allowSave(this.state.dirty, valid);
  }

  buildColors = (values) => {
    let hue = 223;
    let saturation = "95%";
    let maxLuminosity = 100;
    let minLuminosity = 2;

    let colors = [];

    let n = values.length + 1;

    let step = Math.floor((maxLuminosity - minLuminosity) / (n + 1));

    let currentLuminosity = maxLuminosity;
    for (let i = 0; i < n; i++) {
      currentLuminosity = currentLuminosity - step;
      colors.push(
          "hsla(" + hue + ", " + saturation + ", " + currentLuminosity + "%, 1)"
      );
    }
    return colors;
  }

  buildQFormula = (index, params) => {
    let q_new = q;
    q_new = !!params[index].a ? q_new.replace('a', params[index].a) : q_new;
    q_new = !!params[index].b ? q_new.replace('b', params[index].b) : q_new;
    q_new = !!params[index].c ? q_new.replace('c', params[index].c) : q_new;
    q_new = !!params[index].e ? q_new.replace('e', params[index].e) : q_new;
    return q_new;
  }

  handleChangeTextField = (index, obj) => {
    let params = this.state.params;
    params[index] = {...params[index], [obj.name]: Number(obj.value)};

    this.setState({
      params: params,
      dirty: !_.isEqual(this.props.data.thresholds, params)
    });


  }

  handleOnBlurTextField = (index, obj) => {
    let params = this.state.params;
    params[index] = { ...params[index], [obj.name]: obj.value, q: this.buildQFormula(index, params)};
    this.props.onChangeThresholdsValue(params)
    let oldError = this.state.error;
    this.setState({
      params: params,
      error: {...oldError,[index + "_" + obj.name]: !!!obj.value}
    });

  }
  validForm() {
    return !Object.values(this.state.error).includes(true);
  }

  toItem = (index, interval, showDelete, param) => {
    return (
        <>
          {index > 0 && <Divider variant="inset" />}
          <Grid item md={12}>
            <List dense={false}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>{index}</Avatar>
                </ListItemAvatar>

                <ListItemText
                    primary={
                      <Typography
                          component="span"
                          variant="body1"
                          color="textPrimary"
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={3} md={3}>{interval}</Grid>
                          <Grid item xs={3} md={3}>
                            <MathJax.Provider>
                              <MathJax.Node inline formula={param.q} />
                            </MathJax.Provider>
                          </Grid>
                        </Grid>
                      </Typography>
                    }
                    secondary={
                      <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                      >
                        <Grid container spacing={5}>
                          <Grid item xs={3} md={3}>
                            <TextField
                                error ={this.state.error[`${index}_a`]}
                                fullWidth
                                type="number"
                                id={`${index}_a`}
                                name="a"
                                label="Parametro a"
                                required
                                value={param.a}
                                onChange={(e) => this.handleChangeTextField(index, e.target)}
                                onBlur={(e) => this.handleOnBlurTextField(index, e.target)}

                            />
                          </Grid>
                          <Grid item xs={3} md={3}>
                            <TextField
                                error ={this.state.error[`${index}_b`]}
                                fullWidth
                                type="number"
                                id={`${index}_b`}
                                name="b"
                                label="Parametro b"
                                required
                                value={param.b}
                                onChange={(e) => this.handleChangeTextField(index, e.target)}
                                onBlur={(e) => this.handleOnBlurTextField(index, e.target)}
                            />
                          </Grid>
                          <Grid item xs={3} md={3}>
                            <TextField
                                error ={this.state.error[`${index}_c`]}
                                fullWidth
                                type="number"
                                id={`${index}_c`}
                                name="c"
                                label="Parametro c"
                                required
                                value={param.c}
                                onChange={(e) => this.handleChangeTextField(index, e.target)}
                                onBlur={(e) => this.handleOnBlurTextField(index, e.target)}
                            />
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <TextField
                                error ={this.state.error[`${index}_e`]}
                                fullWidth
                                type="number"
                                id={`${index}_e`}
                                name="e"
                                label="Parametro e"
                                required
                                value={param.e}
                                onChange={(e) => this.handleChangeTextField(index, e.target)}
                                onBlur={(e) => this.handleOnBlurTextField(index, e.target)}
                            />
                          </Grid>
                        </Grid>
                      </Typography>
                    }
                />
                <ListItemSecondaryAction>
                  {showDelete && (
                      <IconButton
                          edge="end"
                          aria-label="Elimina"
                          tabIndex={-1}
                          onClick={(e) => {
                            this.delete(index);
                          }}
                      >
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                  )}
                  <IconButton
                      edge="end"
                      disabled={!this.validForm()}
                      aria-label="Aggiungi"
                      tabIndex={-1}
                      onClick={(e) => {
                        this.split(index);
                      }}
                  >
                    <AddCircleIcon fontSize="large" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
        </>
    )
  }



  delete = (index) => {
    let params = this.state.params;
    let values = this.state.values;

    params.splice(index, 1);
    values.splice(index - 1, 1);

    params.forEach((value, index) => {
      params[index] = {
        ...params[index],
        thresholdStart: index === 0 ? this.state.min : values[index - 1],
        thresholdEnd: !!values[index] ? values[index] : this.state.max,
      };
    });


    let colors = this.buildColors(values);
    this.props.onChangeThresholdsValue(params);

    let newError = {...this.state.error};
    delete newError[index + "_a"];
    delete newError[index + "_b"];
    delete newError[index + "_c"];

    this.setState({
      colors: colors,
      params: params,
      values: values,
      error: newError
    });
  }

  split = (index) => {
    let params = this.state.params;
    let values = this.state.values;

    let intervalArray = [];
    let extValues = [this.state.min, ...values, this.state.max];
    for (let i = 0; i < extValues.length - 1; i++) {
      intervalArray.push({ min: extValues[i], max: extValues[i + 1] });
    }

    let intervalToSplit = intervalArray[index];
    let middleValue =
        Math.floor((intervalToSplit.max - intervalToSplit.min) / 2) +
        intervalToSplit.min;

    values.splice(index, 0, middleValue);
    params.splice(index + 1, 0, {});

    params.forEach((value, index) => {
      params[index] = {
        sensorCategory: this.props.data.category,
        stationCode: this.props.data.stationCode,
        sensorCode: this.props.data.code,
        a: "",
        b: "",
        c: "",
        q: !!this.state.h0 ? q.replace('h_0', this.state.h0) : q,
        ...params[index],
        thresholdStart: index === 0 ? this.state.min : values[index - 1],
        thresholdEnd: !!values[index] ? values[index] : this.state.max,
      };
    });

    console.log('@@@',params)

    let colors = this.buildColors(values);
    this.props.onChangeThresholdsValue(params);

    this.setState({
      colors: colors,
      params: params,
      values: values,
    });
  }

  onChangeValues = (values) => {
    let params = [...this.state.params];
    params.forEach((value, index) => {
      params[index] = {
        ...params[index],
        thresholdStart: index === 0 ? this.state.min : values[index - 1],
        thresholdEnd: !!values[index] ? values[index] : this.state.max,
      };
    });
    this.props.onChangeThresholdsValue(params);

    this.setState({
      params: params,
      values: values,
      dirty: true
    });
  };

  onChangeH0 = value => {
    let h0 = value && !_.isEmpty(value) ? Number(value) : '';
    this.setState({
      h0,
      dirty:  !_.isEqual(this.props.data.h0, h0)
    });
  }

  onBlurH0 = (value) => {
    let params = [...this.state.params];
    params.forEach((val, index) => {
      params[index] = {
        ...params[index],
        q: this.buildQFormula(index, params)
      };
    });
    let oldError = this.state.error;
    this.setState({
      params: params,
      error: {...oldError,h0: !!!value}
    });
    this.props.onChangeZeroValue(value);
  }

  handleChangeStatusShape = ({ file }, status) => {
    if (status === "removed") {
      this.cleanPreview();
      this.setState({
        shape: [],
        dirty: true
      });
    }
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target.result;
        let arrayOfLines = fileContent.match(/[^\r\n]+/g);

        let shape = [];

        arrayOfLines.forEach((line,index)=>{
          if(index!==0){
            let cells = line.split(";");
            const point = [Number(cells[0]),Number(cells[1])];
            shape.push(point);
          }
        });
        this.props.onUploadCsvFile(shape);
        this.createPreview(shape);
        this.setState({
          shape: shape,
          dirty: true
        })
      };
      reader.onerror = (event) => {
        this.setState({
          shape: [],
        })
      };
      reader.readAsText(file);
    }

  }

  createPreview(shape) {


    this.cleanPreview();
    const c = this.canvas.current;
    let ctx = c.getContext('2d');
    ctx.fillStyle = '#87bde7';

    const scaledShape = this.scaleShape(shape, c);

    const minX = Math.min.apply(Math, scaledShape.map((point) => point[0]));
    const maxX = Math.max.apply(Math, scaledShape.map((point) => point[0]));
    const minY = Math.min.apply(Math, scaledShape.map((point) => point[1]));
    const maxY = Math.max.apply(Math, scaledShape.map((point) => point[1]));

    ctx.save();

    ctx.translate((c.width - Math.abs(maxX - minX))/2,(c.height - Math.abs(maxY - minY))/2);
    ctx.beginPath();

    scaledShape.forEach((point,index) => {
      if(index === 0){
        ctx.moveTo(point[0],point[1]);
      } else {
        ctx.lineTo(point[0], point[1]);
      }})
    ctx.lineTo(maxX, minY);
    ctx.lineTo(minX, minY);
    ctx.closePath();
    ctx.fill();

    ctx.restore();
  }

  scaleShape(shape, c) {
    let a = 0;
    let b = 0;
    shape.forEach(point => {
      a = Number(point[0]) > a ? Number(point[0]) : a;
      b = Number(point[1]) > b ? Number(point[1]) : b;
    })
    const xScaleFactor = c.width / a;
    const yScaleFactor = c.height / b;
    return shape.map(point => [point[0] * xScaleFactor, point[1] * yScaleFactor]);

  }

  cleanPreview(){
    let ctx = this.canvas.current.getContext('2d');
    ctx.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
  }

  render() {
    let itemsList = [];



    let intervalArray = [];
    let extValues = [this.state.min, ...this.state.values, this.state.max];
    for (let i = 0; i < extValues.length - 1; i++) {
      intervalArray.push({ min: extValues[i], max: extValues[i + 1] });
    }
    let itemsNumber = this.state.params.length;
    for (let i = 0; i < itemsNumber; i++) {
      itemsList.push(
          this.toItem(
              i,
              "] " + intervalArray[i].min + " , " + intervalArray[i].max+" ]",
              itemsNumber > 1,
              this.state.params[i]
          )
      );
    }

    return (
        <div className="generic-page pt-4">
          <div className="mb-5 container">
            <Accordion defaultExpanded>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
              >
                <Typography>Definizione shape</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block'}}>

                <div className="row">
                  <div className="col-sm-8 my-auto">
                    <Dropzone
                        onChangeStatus={this.handleChangeStatusShape}
                        accept=".csv"
                        maxFiles={1}
                        inputContent="Inserisci il file della geometria dell'alveo in formato CSV"
                        styles={{
                          dropzone: { overflow: "hidden", width: "100%" },
                          dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                          inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
                        }}
                    />
                  </div>
                  <div className="col-sm-4 my-auto">
                    <canvas ref={this.canvas} style={{transform: "rotate(180deg)"}} />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel1c-header"
              >
                <Typography>Definizione altezza s.l.m.</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                    error = {this.state.error.h0}
                    fullWidth
                    type="number"
                    id="h0"
                    name="h0"
                    label="Parametro h0"
                    required
                    value={this.state.h0}
                    onChange={(e) => this.onChangeH0(e.target.value)}
                    onBlur={(e) => this.onBlurH0(e.target.value)}
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="mt-4 container">
            <ThresholdRangeComponent
                min={this.state.min}
                max={this.state.max}
                step={this.state.step}
                values={this.state.values}
                colors={this.state.colors}
                onChange={(values) => this.onChangeValues(values)}
            />
            <ThresholdListComponent
                colors={this.state.colors}
                items={itemsList}
            />
          </div>
        </div>
    );
  }
}
