import React from 'react';
import parse_georaster from "georaster";
import {GeoJSON, MapControl, withLeaflet} from "react-leaflet";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import ChromaticScale, {fireRiskDomain} from "#/lib/ChomaticScale";
import GISTools from "#/lib/GISTools";
import Dropzone from "react-dropzone-uploader";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import IdwClient from "#/lib/IdwClient";
import Fab from "@material-ui/core/Fab";
import {ReactTabulator} from "react-tabulator";
import L from "leaflet";
import MapComponent from "#/commons/map/MapComponent";
import DownloadContainer from "#/commons/components/DownloadContainer";

class FireRiskLegend extends MapControl {
	createLeafletElement(props) {}
	componentWillUnmount(){}

	componentDidMount() {

		const legend = L.control({ position: "bottomright" });

		legend.onAdd = () => {
			let classNames = !!this.props.replaceMode ?  "info legend newLegend" : "info legend";
			const div = L.DomUtil.create("div", classNames);
			const grades = this.props.grades;
			let labels = [];
			let from;
			let to;

			if (!!this.props.legendTitle){
				labels.push(`<div><b>${this.props.legendTitle}</b></div>`)
			}
			for (let i = 0; i < grades.length; i++) {
				from = grades[i];
				to = grades[i + 1];

				if (to){
					labels.push(
						'<i style="background:' +
						this.props.getColor(from) +
						'"></i> ' +
						from +
						" - " + to
					)
				};
			}

			div.innerHTML = labels.join("<br>");
			return div;
		};

		const { map } = this.props.leaflet;

		let tmp = document.getElementsByClassName("newLegend");
		if (!!this.props.replaceMode && !!tmp && tmp.length > 0) {
			tmp[0].remove()
		};
		legend.addTo(map);
	}
}
const Legend = withLeaflet(FireRiskLegend);

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

const hasDuplicates = (array) => {
	return (new Set(array)).size !== array.length;
}

const createBlobFromArrayBuffer = (arrayBuffer) => {
	let blob =  new Blob([arrayBuffer], { type: '' });
	blob.lastModifiedDate = new Date();
	blob.name = "something.tif";
	return blob;
}
export default class FireRiskMapPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 0,
		maxZoom: 12,
		width: "60vw",
		height: "70vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			georaster : null,
			mapKey : 1,
			tableKey : 1,
			geoJSON: props.geoInfo && props.geoInfo.geoJson,
			identifierCandidates: null ,
			geoJsonIdentifier: props.geoInfo && props.geoInfo.geoJsonIdentifier,
			showModal: false,
			zonalValuesMap : null

		}
	}

	componentDidMount() {
		this.setState({
			arrayBuffer : this.props.arrayBuffer ? this.props.arrayBuffer.slice() : null
		},() => {
			this.createRasterAndUpdateMap(this.props.arrayBuffer, false)
		})
	}

	createRasterAndUpdateMap(arrayBuffer, zonal){

		parse_georaster(arrayBuffer).then(georaster => {

			this.setState({
				georaster,
				mapKey: (this.state.mapKey + 1) % 1000,
				tableKey: (this.state.tableKey + 1) % 1000
			},() => {
				if (zonal){
					loadingSwal.close();
					ReactSwal.fire(`<h2>La mappa zonale è stata generata con successo</h2>`,'','success');
				}
				this.fixMap();
			})
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.fixMap();
	}

	fixMap = () => {
		let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
		this.mapRef.leafletElement.fitBounds([
			[bbox.bbox[1], bbox.bbox[0]],
			[bbox.bbox[3], bbox.bbox[2]]
		]);
	}

	/**
	 * GeoJson upload
	 * */

	handleChangeStatusZone = ({ file }, status) => {
		if (status === "removed") {
			this.setState({
				zonalValuesMap: null,
				geoJsonIdentifier: null,
				mapKey: (this.state.mapKey + 1) % 1000,
				identifierCandidates: null,
			})
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let geoJSON = JSON.parse(fileContent);
				// Check keys
				let identifierCandidates = this.findIdentifierCandidates(geoJSON);
				if (identifierCandidates.length > 0)
				{
					this.setState({
						geoJSON,
						zonalValuesMap: null,
						mapKey: (this.state.mapKey + 1) % 1000,
						identifierCandidates: identifierCandidates,
						file: file
					})
				}
			};
			reader.onerror = (event) => {
				ReactSwal.hideLoading();
				ReactSwal.fire('Si è verificato un errore nella lettura delle zone', '', 'error');
			};
			reader.readAsText(file);
		}
	}
	findIdentifierCandidates = (geoJSON) => {
		let identifiers = [];
		const features = geoJSON.features;
		if (!!features) {
			const feature = features[0];
			const keys = Object.keys(feature.properties);
			keys.forEach(key => {
				let allValues = features.map(feature => feature.properties[key])
				if (!hasDuplicates(allValues)){
					identifiers.push(key)
				}
			})
		}
		return identifiers;
	}
	openModal = () => {
		this.setState({
			showModal: true
		})
	}

	onCloseModal = () => {
		this.setState({
			showModal: false
		})
	}

	/**
	 *
	 * Map
	 */
	polygonStyle(feature, latlng) {
		let fillColor =  "#fff0";
		if (!!this.state.zonalValuesMap){
			const key = this.state.geoJsonIdentifier;
			const zoneNumber = feature.properties[key];
			let scale  = ChromaticScale.getFireRiskScale();
			fillColor = scale(this.state.zonalValuesMap[zoneNumber].mean)
		}
		return {
			fillColor: fillColor,
			weight: 0.8,
			opacity: 1,
			color: "blue",
			fillOpacity: 1
		};
	}
	onEachPolygon(feature, layer) {
		if (!!this.state.zonalValuesMap){
			let popupContent = "";
			const key = this.state.geoJsonIdentifier;
			const zoneNumber = feature.properties[key];
			popupContent += `${key} : ${zoneNumber}</br>`;
			popupContent += `Suscettività : ${this.state.zonalValuesMap[zoneNumber].mean}`
			layer.bindPopup(popupContent);
		}
	}

	/**
	 *
	 * Recap Table
	 */
	getColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Livello informativo utilizzato", field: "label", headerSort: false },
			{ title: "Peso", field: "weight", headerSort: false, width : 100}
		]);
		return columns;
	}

	getTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
		}
		return tabulatorOptions;
	}
	/**
	 * Final Map with zones*/
	generateFinalZonalMap = () => {
		let data = new FormData();

		// Add the file just received
		const file =  createBlobFromArrayBuffer(this.state.arrayBuffer);
		data.append("raster", file );
		data.append("name", "raster")

		// Add zonal geoJson
		data.append("shapes", JSON.stringify(this.state.geoJSON))
		data.append("name","shapes" )

		// Add field name
		data.append("codeFieldName", this.state.geoJsonIdentifier)
		data.append("name","codeFieldName" )

		loadingSwal.fire('<div>Costruzione della mappa zonale in corso...</div>');
		IdwClient.sendTifsAndGetFinalGeoJson((zonalValues)=> {
				console.log("zonalValues",zonalValues);
				let zonalValuesMap = zonalValues.reduce((obj, item)=> {obj[item.code]=item; return obj}, {});
				this.setState({zonalValuesMap,mapKey: (this.state.mapKey + 1) % 1000},() => {
					loadingSwal.close();
					if (this.props.zonalMapCreationHandler){
						this.props.zonalMapCreationHandler(zonalValuesMap);
					}
				})
			},()=>{
				loadingSwal.close();
				ReactSwal.fire('Errore nel calcolo del raster finale','','error')
			},
			data
		)
	}

	render() {
		return (

			<>
				<div className="row mt-4">
					<div className="col-9" style={{height : "90%"}}>
						<DownloadContainer
							imageName={"Mappa _suscettività_riscio_incendi"}
							disabled={false}
							extraClassNames={" "}
						>
							<MapComponent
								width={this.mapOptions.width}
								height={this.mapOptions.height}
								zoom={this.mapOptions.zoom}
								zoomSnap={false}
								minZoom={this.mapOptions.minZoom}
								maxZoom={this.mapOptions.maxZoom}
								setMapRef={mapRef => this.mapRef = mapRef}>
								{!!this.state.georaster && !!!this.state.zonalValuesMap ?
									<GeotiffRasterLayer
										key={"finalraster_" + this.state.mapKey}
										georaster={this.state.georaster}
										opacity={1}
										resolution={1024}
										colorScale={ChromaticScale.getFireRiskScale()}
										handleClick={(val, latlng) => GISTools.showPopup(val, latlng, this.mapRef.leafletElement)}
									/> : <></>}
								<GeoJSON key={"polygons_" + this.state.mapKey}
										 data={this.state.geoJSON}
										 onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
										 style={(feature, latlng) => this.polygonStyle(feature, latlng)}
										 pointToLayer={(feature, latlng, context) => {}}
								/>

								{!!this.state.georaster ?
									<Legend
										legendTitle={"Suscettività al rischio incendi"}
										grades={fireRiskDomain}
										getColor={(num) => ChromaticScale.getFireRiskScale()(num)}/> :
									<></>}
							</MapComponent>
						</DownloadContainer>
					</div>
					<div className="col-3" style={{height : "50vh"}}>
						{!this.props.geoInfo && <Button
							variant="contained"
							color="primary"
							startIcon={<PublishIcon/>}
							onClick={(e) => this.openModal()}
						>Carica Nuove Zone
						</Button>}
						<ReactTabulator
							columns={this.getColumns()}
							data={this.props.factors}
							options={this.getTabulatorOptions()}
							key={"factors_" + this.state.tableKey}
						/>
					</div>

				</div>
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "50vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3 >Import Zone da file</h3>
						</div>

						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<Dropzone
									onChangeStatus={this.handleChangeStatusZone}
									accept=".geojson,.json"
									maxFiles={1}
									inputContent="Inserisci il file delle zone in formato GeoJSON"
									styles={{
										dropzone: { overflow: "hidden", width: "30vw" },
										dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
										inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
									}}
								/>
							</div>
						</div>
						<br/>
						{!!this.state.identifierCandidates ?
							<>
								<div className="d-flex justify-content-center">
									{this.state.identifierCandidates.length > 0 ?
										<h3>Scegli il campo che identifica le geometrie zonali:</h3> :
										<h3 style={{color: "red"}}>Il file caricato non contiene alcun campo identificativo. Caricare un file valido.</h3>
									}
								</div>
								<div className="d-flex justify-content-center">
									{this.state.identifierCandidates.map(key =>
										<Button
											key={key}
											size="medium"
											className="col-sm-2 my-auto"
											variant="contained"
											color="primary"
											onClick={(e) => this.setState({geoJsonIdentifier: key, showModal: false})}
										>{key}</Button>

									)}
								</div>
							</> : <></>
						}

					</div>

				</Modal>

				<div style={{ position: "fixed", bottom: "15vh", right: "1vw", zIndex : 9999 }}>
					<Fab variant="extended" color="secondary" aria-label="add"
						 onClick={() => {this.setState({zonalValuesMap: null}, () => this.props.goBack())}}>
						<i className="ml-2 fas fa-chevron-left"></i>
						<i className="fas fa-chevron-left"></i>
						Ritorna alla configurazione

					</Fab>
				</div>
				{this.state.zonalValuesMap && this.props.geoInfo ? <></> : <div style={{position: "fixed", bottom: "5vh", right: "1vw", zIndex: 9999}}>
					<Fab variant="extended" color="primary" aria-label="add"
						 onClick={this.generateFinalZonalMap}
						 disabled={!this.state.georaster || !this.state.geoJsonIdentifier}>
						Genera la mappa di suscettività per le zone selezionate
						<i className="ml-2 fas fa-chevron-right"></i>
						<i className="fas fa-chevron-right"></i>
					</Fab>
				</div>}
			</>
		)
	}
}

