import {properties} from '#/properties.js';
import APIUtils from "#/lib/APIUtils";

export default class LegacyMeasurementsDataClient {

	static getJsonData(okCallback, errCallback, params){
		let url = properties.url.legacyData;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static getIntervals(okCallback, errCallback, stationCode) {
		let url = properties.url.legacyDataIntervals(stationCode);
		let fetch_options = {
			method: 'GET',
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	

}
