import React, {Component} from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import 'moment/locale/it';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import MapSettings from "#/backoffice/Settings/MapSettings";
import OtherSettings from "#/backoffice/Settings/OtherSettings";
import FireRiskCampaign from "#/backoffice/Settings/FireRiskCampaign"
import BlacklistSettings from "#/backoffice/pages/BlacklistPage";
import ProtocolloSettings from "#/backoffice/Settings/ProtocolloSettings";
import LinkPrevisioniMeteoSettings from "#/backoffice/Settings/LinkPrevisioniMeteoSettings";


export default class SettingsPage extends Component {

    constructor(props){
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {

      return (
          <>
            <div className="generic-page container-fluid data-page">
              <Tabs defaultActiveKey="mapSettings"
                    activeKey={this.state.selectedTabKey}
                    onSelect={(k) => this.setState({
                      selectedTabKey : k
                    })}
              >
                <Tab eventKey="mapSettings" title="Mappe">
                  <MapSettings/>
                </Tab>
				<Tab eventKey="fireRiskCampaignSettings" title="Campagna Rischio Incendi">
                  <FireRiskCampaign/>
                </Tab>
				<Tab eventKey="protocolloSettings" title="Protocollo">
                  <ProtocolloSettings/>
                </Tab>
				<Tab eventKey="forecastLink" title="Link Previsioni Meteo">
                  <LinkPrevisioniMeteoSettings/>
                </Tab>
                <Tab eventKey="otherSettings" title="Altre">
                  <OtherSettings/>
                </Tab>
              </Tabs>
            </div>

          </>
      )
    }


}

