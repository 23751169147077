import React from 'react'
import {GeoJSON} from 'react-leaflet';
import L from 'leaflet';
import MapComponent from "#/commons/map/MapComponent";
import _ from 'lodash';
export default class MapFormComponent extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		width: "35vw",
		height: "70vh",
	}

	onEachPolygon(feature, layer) {
	}
	polygonStyle(feature, latlng) {
		return {
			fillColor: this.props.getColorById(feature.properties.ZONE) ?? "#fff0",
			weight: 1,
			opacity: 1,
			color: "#2c2c2c",
			fillOpacity: !!this.props.getColorById(feature.properties.ZONE) ? 1 : 0
		};
	}

	pointToLayer(feature, latlng){

		let marker = L.marker(latlng, {
			icon: L.divIcon({
				className: 'label',
				html: `<span style="font-size:1rem; font-weight:600">${feature.properties.ZONE}</span>`,
			})
		});
		/*let zonalDetails = this.props.formData.previsione.find(item => item.zone === feature.properties.ZONE);*/
		return marker;
	}

	render() {

		return (
			<div className="custom-popup" id={this.props.id}>
				<MapComponent
					key={this.props.mapKey /*+ "_" + JSON.stringify(this.props.formData)*/}
					width={this.mapOptions.width}
					height={this.mapOptions.height}
					zoom={this.mapOptions.zoom}
					zoomSnap={false}
					zoomControl={false}
					doubleClickZoom={false}
					dragging={false}
					zoomDelta={false}
					touchZoom={false}
					scrollWheelZoom={false}
					closePopupOnClick={false}
					attributionControl={false}
					backgroundColor={'rgba(255,0,0,0.0)'}
					tile={<></>}
					noHome={true}
				>
					{!!this.props.geoJSON &&
					<GeoJSON key={"polygons_"+this.props.mapKey /*+ "_" + JSON.stringify(this.props.formData)*/}
							 data={this.props.geoJSON}
							 onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
							 style={(feature, latlng) => this.polygonStyle(feature, latlng)}
							 pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}
					/>
					}

					{this.props.legend}
				</MapComponent>
			</div>
		)
	}

}
