const envProps = {
	env: "dev",
	authentication_api_url: window.location.origin+"/api/rest/authenticate",
	refresh_authentication_api_url: window.location.origin+"/api/rest/refresh",
	base_api_url: window.location.origin+"/api/rest",
	base_img_url: window.location.origin+"/img/",
	base_stream_url: window.location.origin+"/infostream/rest",
	base_url: window.location.origin,
	isLight : true,

};
export default envProps;
