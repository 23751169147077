import React from 'react';

import {Redirect, Route, Switch} from "react-router-dom";
import Header from '#/commons/components/Header';

import TestMixedChart from "#/test/pages/TestMixedChart";
import DailyStatsPage from '#/commons/dailyStats/DailyStatsPage_old'
import TestSelPage from "#/test/pages/TestSelPage";


const Test = () =>(
		<>
			<Header home={window.location.origin+"/test"}></Header>
			<Route component={({ match }) =>
				<div>
					<Switch>
						<Route exact path={match.url + "/testChart"}><TestMixedChart/></Route>
						<Route exact path={match.url + "/daily"}><DailyStatsPage/></Route>
						<Route exact path={match.url + "/sel"}><TestSelPage/></Route>

						<Route exact path={match.url + "/"}><h1 className="generic-page">Test App</h1>{localStorage.getItem('username')}</Route>
						<Redirect from={match.url} to="/test" />
					</Switch>
				</div>
			} />
		</>
	);

export default Test;




