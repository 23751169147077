import React from 'react'
import LabelledOutline from '#/commons/components/LabelledOutline'

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export default class MultiSelectZoneForm extends React.PureComponent {

	buildOptions = () => {
		let optionsMenuItems = [];
		this.props.options.forEach((o,i)=>{
			optionsMenuItems.push(<MenuItem key={"opt_"+i} value={o.value || ''}>{o.label}</MenuItem>);
		})
		return optionsMenuItems;
	}

	toItemFunction = (zone) => {
		let options = this.buildOptions();
		return (
			<FormControl className = "mt-2" key={"select_"+zone.id}>
				<InputLabel id="h-helper-label">{zone.label}</InputLabel>
				<Select
					fullWidth
					labelId={zone.label}
					disabled = {this.props.viewMode}
					id={zone.label}
					value={!!this.props.zones.find(z=> z.id===zone.id) ? this.props.zones.find(z=> z.id===zone.id).value || '' : ''}
					onChange={(e)=>this.props.onZoneChange(zone.id, e.target.value)}
					>
					{options}
				</Select>
			</FormControl>
		)
	}

	render(){
		const selectList = !!this.props.zones ? this.props.zones.map((zone) => this.toItemFunction(zone)) : [];
		return (
			 !!selectList.length &&
				<LabelledOutline id="myID" label={this.props.labelForm}>
					<FormGroup >
						{selectList}
					</FormGroup>
				</LabelledOutline>
		)
	}

}