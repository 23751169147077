import React from "react";
import {withRouter} from 'react-router';
import domtoimage from 'dom-to-image-more';
import {properties} from '#/properties.js';
import DateUtils from "#/lib/DateUtils";
import clonedeep from 'lodash/cloneDeep';

//import { DEFAULT_MAPS } from "#/backoffice/mau/utils/MAUConst";
import {BIM_STEPS, DEFAULT_FORM_JSON_DATA, OPTIONAL_MAP_FILES} from "#/backoffice/bim/utils/BIMUtils";
import BulletinClient from "#/lib/BulletinClient";
import _ from "lodash";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {BackButton} from "#/commons/components/forms/BackButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import WorkItemClient from "#/lib/WorkItemClient";

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

class BollettinoIdrologicoMeseEditorPage extends React.PureComponent {


  steps = undefined;
  totalSteps = 0;
  today = new Date();
  refMonthNumber = this.today.getMonth() + 1;
  refYear = this.refMonthNumber === 0 ? this.today.getFullYear()-1 : this.today.getFullYear();
  refMonthName = DateUtils.getMonthNameFromMonthNumber(this.refMonthNumber);
  mapKey = 0;

  constructor(props) {
    super(props);
    this.steps = props.viewMode ? BIM_STEPS.VIEW : BIM_STEPS.EDIT;
    this.totalSteps = this.steps.length;
    const url = window.location.href;
    const idString = url.split("/").pop();
    const id = parseInt(idString);
    let formJsonData = DEFAULT_FORM_JSON_DATA;
    formJsonData.reference_month = this.refMonthName;
    formJsonData.reference_year = this.refYear;
    this.state = {
      id: !!id ? id : null,
      bulletinTypeName: "BIM",
      selectedMonth: this.today.getMonth() + 1,
      selectedYear: this.today.getFullYear(),
      monthName : DateUtils.getMonthNameFromMonthNumber(this.today.getMonth()),
      activeStep: 0,
      formJsonData : formJsonData,
      viewMode : this.props.viewMode,
      skippedSteps : [],
      addressTo: []
    };
  }

  componentDidMount() {
    if (!!this.state.id) {
      this.getBulletinById(this.state.id);
    } else {
      this.isCreationAllowedToday(this.setState({ allowCreationToday: true }), this.goToListPage);
    }
  }

  goToListPage = () => {
    this.props.history.push("/backoffice/bim");
  }

  getBulletinById = (id) => {
    if (!!id) {
      BulletinClient.getBulletin(
          (result) => {
            this.setState({
              formJsonData : result.jsonData,
              published: result.published,
              allowCreationToday: true,
              addressTo: result.jsonData.address_to || []
            });
          },
          (msg) => {
            console.log("msg: ", msg);
          },
          {
            id: this.state.id,
            bulletinTypeName: this.state.bulletinTypeName,
          }
      );
    }
  }

  isCreationAllowedToday = (trueCallback, falseCallback) => {
    BulletinClient.isCreationAllowedToday(
        (data) => {
          this.setState({ allowCreationToday: true }, trueCallback);
        },
        (error) => {
          falseCallback();
        },
        this.state.bulletinTypeName
    );
  };

  changeYearMonthRef = (month, year) => {
    let monthName = DateUtils.getMonthNameFromMonthNumber( month);
    let formJsonData = clonedeep(DEFAULT_FORM_JSON_DATA);
    formJsonData.reference_month = monthName;
    formJsonData.reference_year = year;
    formJsonData.month = month+1;
    formJsonData.year = year;
    this.setState({
      selectedMonth: month + 1,
      selectedYear: year,
      monthName : monthName,
      formJsonData : formJsonData
    })

  }

  convertHtmltoImg = (elementId) => {
    console.time("convertHtmltoImg_"+ elementId);
    return new Promise((resolve, reject) => {
      domtoimage.toPng(document.getElementById(elementId))
          .then( (dataUrl) => {
            console.timeEnd("convertHtmltoImg_"+ elementId);
            resolve (dataUrl);
          })
          .catch( (error) => {
            console.error('oops, something went wrong!', error);
            reject(error);
          });
    });
  }

  send = (mail_addresses, to_include) => {
    loadingSwal.fire('Archiviazione del bollettino in corso...');
    let jsonData = clonedeep(this.state.formJsonData);
    jsonData.skippedSteps = this.state.skippedSteps;
    console.log("jsonData", jsonData)
    jsonData.monitoraggio.inputData = undefined;
    jsonData.outflows.inputData=undefined;
    jsonData.rainfalls.inputData=undefined;
    jsonData.sp_index.inputData=undefined;

    jsonData = {...jsonData, mail_addresses, to_include, address_to: this.state.addressTo, month: this.state.selectedMonth + 1, year: this.state.selectedYear};

    let params = {
      bulletinId: this.state.id,
      documentTypeName: this.state.bulletinTypeName,
      jsonData: JSON.stringify(jsonData),
      trigger : this.props.trigger
    };
    BulletinClient.saveBulletin(
        (result) => {
          this.setState({
            id: result.id,
            saveStatus: 'Bollettino archiviato con successo.',
          },()=>{
            loadingSwal.close();
            ReactSwal.fire(
                {
                  icon: 'success',
                  title: 'Bollettino Idrologico Mensile archiviato con successo.',
                  html: 'Ti Stiamo Reindirizzando alla lista dei bollettini',
                  timer: 4000,
                  confirmButtonText: `OK`,
                })
                .then(() => {
                  WorkItemClient.getWorkItemsState(
                      (data) => {

                        const originalSetItem = localStorage.setItem;
                        localStorage.setItem = function(key, value) {
                          const event = new Event('workItemsUpdated');

                          document.dispatchEvent(event);
                          originalSetItem.apply(this, arguments);
                        };

                        localStorage.setItem("workItemsState", JSON.stringify(data));
                        this.goToListPage();
                      }
                      ,() => {
                        console.log("Error while retrieving work items data");
                        this.goToListPage();
                      }
                  )
                })
          });
        },
        (msg) => {
          this.setState({
            saveStatus: "Si è verificato un errore durante l'archiviazione del bollettino",
          });
        },
        params
    );
  };

  handleNext = (skipActiveStep = false) => {

    console.log('handleNext: ', this.state.formJsonData);
    const prevActiveStep = this.state.activeStep;
    const nextStep = prevActiveStep + 1;
    let skippedSteps = _.cloneDeep(this.state.skippedSteps);
    if (skipActiveStep){
      if (!skippedSteps.includes(BIM_STEPS.EDIT[prevActiveStep].value)){
        skippedSteps.push(BIM_STEPS.EDIT[prevActiveStep].value);
      }
    } else {
      if (skippedSteps.includes(BIM_STEPS.EDIT[prevActiveStep].value)){
        skippedSteps = skippedSteps.filter(s => s !== BIM_STEPS.EDIT[prevActiveStep].value);
      }
    }
    this.setState({
      activeStep: nextStep,
      skippedSteps
    });

  };

  handleBack = () => {
    const prevActiveStep = this.state.activeStep;
    this.setState({
      activeStep: prevActiveStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      saveStatus: "",
    });
  };

  getStepContentComponent = (stepIndex) => {
    let props = {
      stepLabel: this.steps[stepIndex].label,
      viewMode: this.state.viewMode,
      totalSteps : this.totalSteps,
      activeStep : stepIndex,
      handleBack: this.handleBack,
      disableBackButton : !stepIndex,
    };
    if (stepIndex === this.steps.length - 1) {
      props = {...props,
        formData: {address_to: this.state.addressTo},
        handleNext: (mail_addresses, to_include) => this.send(mail_addresses, to_include),
        onFormChange: (section, zoneId, name, addressTo) => this.setState({addressTo}),
      }
    } else {
      props = {...props,
        month : this.state.selectedMonth-1,
        year : this.state.selectedYear,
        formData : this.state.formJsonData,
        textNextButton : stepIndex === this.steps.length - 1 ? "Genera bollettino" : "Successivo",
        textSkipButton : stepIndex === this.steps.length - 1 ? "Salta e genera bollettino" : "Salta",
        handleSaveImg : this.convertHtmltoImg,
        changeYearMonthRef : this.changeYearMonthRef,
        handleNext : this.handleNext,
        handleBack : this.handleBack,
        published : this.state.published,
        selectOptions: [{ value: "", label: "Seleziona", identifier : null }, ...OPTIONAL_MAP_FILES],
        context : "BIM"
      }
    }

    let comp = this.steps[stepIndex].component(props);
    return comp;
  };

  render() {
    let downloadLink = !!this.state.id ? properties.url.bulletinDownloadFile + '/' + this.state.id + '/pdf' : null;

    return (
        <div className="generic-page container-fluid data-page" >
          <div>
            <Stepper activeStep={this.state.activeStep} alternativeLabel>
              {this.steps.map((step) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
              ))}
            </Stepper>
            <div>
              {this.state.activeStep === this.totalSteps ? (
                  <>
                    <div className="mt-2 row justify-content-center">
                      <h3>{this.state.saveStatus}<br/>{this.state.id && <a href={downloadLink}>Scarica file</a>}</h3>
                    </div>
                    <BackButton
                        variant="contained"
                        textbutton="Torna alla prima pagina"
                        onClick={this.handleReset}
                    />
                  </>
              ) : (
                  <div>
                    {!!this.state.formJsonData &&
                    this.getStepContentComponent(this.state.activeStep)}
                  </div>
              )}
            </div>
          </div>
        </div>
    );
  }
}


export default withRouter(BollettinoIdrologicoMeseEditorPage)
