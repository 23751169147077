import React from "react";
import Dropzone from 'react-dropzone-uploader';

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';

import { BackButton } from "#/commons/components/forms/BackButton";
import { NextButton } from "#/commons/components/forms/NextButton";


import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';


export const StepUploadFile = (props) => {

  const reactSwal = props.reactSwal;
  const startDate = props.startDate;
  const endDate = props.endDate;

  const initialFile = props.initialFile;


  const handleNextStep = () => {
    if (!!props.selectedFile) {
      props.handleNext();
    } else {
      reactSwal.fire(
        "Report post evento",
        "Selezionare una data e caricare un file prima di procedere",
        "error"
      );
    }
  }

  return (
      <>
        <div className="row justify-content-center">
          <h2>{props.stepLabel}</h2>
        </div>
        <Grid container justify="center" spacing={1}>
            <Grid item xs={6} style={{textAlign: 'center'}}>
              <div>Data inizio evento *</div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={date => props.handleStartDate(date)}
              />
            </Grid>
            <Grid item xs={6} style={{textAlign: 'center'}}>
              <div>Data fine evento</div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={date => props.handleEndDate(date)}
              />              
            </Grid>

            <Grid item xs={12}>
              <Dropzone
                  onChangeStatus={props.handleDropzoneChangeStatus}
                  accept=".pdf"
                  maxFiles={1}
                  inputContent="Seleziona il file da caricare"
                  initialFiles={initialFile && [initialFile]}
                  styles={{
                    dropzone: { overflow: "hidden", width: "30vw" },
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
                  }}
              />
          </Grid>
        </Grid>

        <MobileStepper
          style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
          position="static"
          variant="text"
          activeStep={props.activeStep}
          steps={props.totalSteps}
          nextButton={
              <NextButton
                variant="contained"
                color="primary"
                disabled={!props.startDate || !props.selectedFile ||props.viewMode}
                onClick={handleNextStep}
                textbutton={props.textNextButton}
            />
          }
          backButton={
            <BackButton
              variant="contained"
              disabled={props.disableBackButton}
              onClick={props.handleBack}
            />
          }
        />
      </>
    );
  };
