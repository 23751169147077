import React from "react";
import {ALLERTAMENTO_IDRO_DEFINITIONS} from "#/backoffice/mau/utils/MauUtils";

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';

import {MAUMultizoneIdroForm} from "#/backoffice/mau/components/MAUMultizoneIdroForm";
import MapFormComponent from "#/commons/map/MapFormComponent";
import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton} from "#/commons/components/forms/NextButton";
import _ from 'lodash';


export const StepCriticitaIdroToday = (props) => {
    const formData = props.formData;
    const map = !!props.maps && props.maps.length > 0 ? props.maps[0] : null;
    const todayDate = !!formData && !!formData.start && !!formData.start.date_short ? formData.start.date_short : '';
    const criticita = formData.start.criticita;
    const rootSection = ["start"];
    const section =  ["start","criticita"];
    const imgSection = ["start","criticita","img"];
    let validForm = true;

    const handleNextStep = () => {
        if (props.viewMode) {
            props.handleNext();
        } else if (validForm) {
            props.handleSaveImg(imgSection, map.mapId, props.handleNext);
        } else {
            props.reactSwal.fire(
                "Redazione mau",
                "Compilare tutti i campi prima di procedere",
                "error"
            );
        }
    }

    const getColorById = (id) => {
        const color_temp = criticita.zones.find( z => z.zone === id).idro_temp || "VERDE";
        const color_idro = criticita.zones.find( z => z.zone === id).idro_idra || "VERDE";
        let ret_color = null;
        if ( !!color_temp || !!color_idro ) {
            const color_temp_weight = !!color_temp ? ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.label === color_temp).weight : 0;
            const color_idro_weight = !!color_idro ? ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.label === color_idro).weight : 0;
            const max_weight = Math.max(color_temp_weight, color_idro_weight);
            ret_color = ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.weight === max_weight).color;
        }
        return ret_color;
    };

    const setScenari = () => {

        let scenari = [];
        let scenari_string = '';
        let lastString = '';
        let appendici = ALLERTAMENTO_IDRO_DEFINITIONS.map(def => def.scenario);
        if (criticita.scenario_string){
            appendici.forEach(appendice => {
                criticita.scenario_string = criticita.scenario_string.replace(appendice, '');
            });
            lastString = criticita.scenario_string.replaceAll("\n", '');

        }
        let numOfAppendici = 0;
        ALLERTAMENTO_IDRO_DEFINITIONS.map(def => {
            if (!!criticita.zones.find(zone => zone.idro_temp === def.label) && scenari.indexOf(def.scenario) === -1) {
                scenari.push(def.scenario);
                scenari_string = !!scenari_string ? scenari_string + '\n' + def.scenario : def.scenario;
                numOfAppendici++;
            }
            if (!!criticita.zones.find(zone => zone.idro_idra === def.label) && scenari.indexOf(def.scenario) === -1) {
                scenari.push(def.scenario);
                scenari_string = !!scenari_string ? scenari_string + '\n' + def.scenario : def.scenario;
                numOfAppendici++;
            }
            return scenari_string;
        });
        criticita.scenario_string = scenari_string + '\n';

        if (!_.isEmpty(lastString) ){
            scenari[numOfAppendici] = lastString;
            criticita.scenario_string += lastString;
        }
        criticita.scenario = scenari;

    }

    setScenari();


    return (
        <>
            <div className="row justify-content-center">
                <h2>{props.stepLabel + ' ' + todayDate}</h2>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {!!map &&
                    <MapFormComponent
                        id={map.mapId}
                        key={map.mapKey}
                        geoJSON={map.shapesGeoJSON}
                        width={map.width || "25vw"}
                        height={map.height || "60vh"}
                        mapKey={map.Key}
                        getColorById={(id) => getColorById(id)}
                    />
                    }
                </Grid>
                <Grid item xs={8}>
                    {!!map &&
                    <MAUMultizoneIdroForm
                        key={JSON.stringify(formData.start)}
                        viewMode={props.viewMode}
                        labelForm={map.labelForm + todayDate}
                        zones={map.zones}
                        rootSection={rootSection}
                        section={section}
                        onFormChange={props.onFormChange}
                        formData={formData.start}
                    />
                    }
                </Grid>
            </Grid>
            <MobileStepper
                style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
                position="static"
                variant="text"
                activeStep={props.activeStep}
                steps={props.totalSteps}
                nextButton={
                    <NextButton
                        variant="contained"
                        color="primary"
                        disabled={!validForm}
                        onClick={handleNextStep}
                        textbutton={props.textNextButton}
                    />
                }
                backButton={
                    <BackButton
                        variant="contained"
                        disabled={props.disableBackButton}
                        onClick={props.handleBack}
                    />
                }
            />
        </>
    );
};
