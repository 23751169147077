import React from 'react'

import Button from '@material-ui/core/Button';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MosipClient from '#/lib/MosipClient'
import PolygonMapComponent from '#/commons/map/PolygonMapComponent';

import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import AuthenticationService, {ROLE_ADMIN, ROLE_CONFIGURATOR} from "#/lib/AuthenticationService";

function ShowButton(props) {
	const rowData = props.cell._cell.row.data;

	return (
		<>
			<Tooltip title={"Mostra " + rowData.name + " sulla mappa"}>
				<NotListedLocationIcon style={{ color: "#324aa8" }} onClick={(e) => { props.clickCallback(rowData.istatCode) }} />
			</Tooltip>


		</>
	)
}

export default class MosipMunicipalitiesPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 7,
		maxZoom: 12,
		width: "30vw",
		height: "70vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			municipalitiesArray: [],
			municipalitiesGeoJSON: null,
			selectedMunicipalityIstatCode: null,
			enableSave: false,
			showModal: false,
			mapKey: 1
		}
	}

	componentDidMount() {


		this.loadFromApi();


	}

	loadFromApi() {
		new Promise((resolve, reject) => {
			MosipClient.getAllMunicipalities((data) => { resolve(data); }, () => { console.log("ERROR COMUNI"); });
		}).then(data => {
			let geoJSON = this.buildGeoJSONFromArray(data);
			this.setState({
				municipalitiesArray: data,
				municipalitiesGeoJSON: geoJSON,
				selectedMunicipalityIstatCode: null,
				mapKey: (this.state.mapKey + 1) % 1000,
				enableSave: false,
				showModal: false
			});
		});
	}

	buildGeoJSONFromArray(data) {
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Municipalities";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				COMUNE: value.name,
				ISTAT: value.istatCode,
				PROVINCIA: value.province,
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}

	buildArrayFromGeoJSON(geoJSON) {
		let comuni = geoJSON.features.map(x => {
			return {
				name: x.properties.COMUNE,
				istatCode: x.properties.ISTAT,
				province: x.properties.PROVINCIA
			}
		})
		return comuni;

	}

	getMunicipalitiesColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Comune", field: "name", headerSort: true, headerFilter: true },
			{ title: "Provincia", field: "province", headerSort: true, headerFilter: "select", headerFilterParams: { "CZ": "Catanzaro", "CS": "Cosenza", "KR": "Crotone", "VV": "Vibo Valenzia", "RC": "Reggio Calabria" } },
			{ title: "Istat", field: "istatCode", headerSort: true },
			{
				title: "",
				formatter: reactFormatter(<ShowButton clickCallback={(istatCode) => this.selectMunicipality(istatCode)}></ShowButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				minWidth: "200px",
			}
		]);
		return columns;
	}

	selectMunicipality(istatCode) {
		this.setState({
			selectedMunicipalityIstatCode: istatCode
		})
	}

	resetSelectedMunicipality() {
		this.setState({
			selectedMunicipalityIstatCode: null,
			mapKey: (this.state.mapKey + 1) % 1000

		})
	}
	isConfigurator = () => AuthenticationService.haveRolesPermssions([ROLE_CONFIGURATOR, ROLE_ADMIN]);

	rowFormatter(row){
	
		if(!!this.state.enableSave){
			row.getElement().style.backgroundColor = "#f5cb5c";
		}
	}

	getTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "70vh",
			rowFormatter:(row)=>this.rowFormatter(row),
		}
		return tabulatorOptions;
	}

	handleChangeStatusComuni = ({ file }, status) => {
		if (status === "revoved") {
			this.setState({
				comuniGeoJSON: {},
				comuniFileName: null
			})
			this.loadFromApi();
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let geoJSON = JSON.parse(fileContent);
				let data = this.buildArrayFromGeoJSON(geoJSON);
				this.setState({
					municipalitiesArray: data,
					municipalitiesGeoJSON: geoJSON,
					selectedMunicipalityIstatCode: null,
					mapKey: (this.state.mapKey + 1) % 1000,
					enableSave: true,
					showModal: false
				})

			};
			reader.onerror = (event) => {
				this.loadFromApi();
			};
			reader.readAsText(file);
		}


	}

	openModal() {
		this.setState({
			showModal: true
		})
	}

	onCloseModal() {
		this.setState({
			showModal: false
		})
	}

	saveComuni() {

		let comuni = this.state.municipalitiesGeoJSON.features.map(x => this.municipalityFeatureToObject(x))

		MosipClient.replaceAllMunicipalities(
			() => { this.loadFromApi() },
			() => { console.log("ERROR COMUNI") },
			comuni

		)


	}

	municipalityFeatureToObject(feature) {
		return {
			comune: feature.properties.COMUNE,
			istatCode: feature.properties.ISTAT,
			province: feature.properties.PROVINCIA,
			polygonGeojson: JSON.stringify(feature.geometry),
			riskCode: feature.properties.CODICE_RISCHIO,
			riskDescription: feature.properties.DESCRIZIONE
		}
	}

	render() {



		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className="row mt-4">

						<div className="col-4">
							<PolygonMapComponent
								selectedFieldToCheck={"ISTAT"}
								selected={this.state.selectedMunicipalityIstatCode}
								geoJSON={this.state.municipalitiesGeoJSON}
								key={this.state.mapKey}
								resetSelection={() => this.resetSelectedMunicipality()}
								onSelect={(istatCode) => this.selectMunicipality(istatCode)}
								zoomOnSelected = {true}
								reload={()=>this.loadFromApi()}
								mapOptions={this.mapOptions}
								showTileLayer={true}
							></PolygonMapComponent>
						</div>
						<div className="col-8" >
							<ReactTabulator
								ref={ref => (this.tableRef = ref)}
								columns={this.getMunicipalitiesColumns()}
								data={this.state.municipalitiesArray}
								options={this.getTabulatorOptions()}
								key={"table_" + this.props.mapKey}
							/>
							<div className="d-flex">
								<Button
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									disabled={!this.isConfigurator()}
									startIcon={<PublishIcon />}
									onClick={(e) => this.openModal()}
								>Carica Nuovi Comuni</Button>
							</div>
							<div className="d-flex mt-1">
								<Button
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									startIcon={<SaveIcon />}
									disabled={!this.state.enableSave}
									onClick={(e) => this.saveComuni()}
								>Salva Comuni</Button>
							</div>

						</div>
					</div>

				</div>
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "50vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3 >Import Comuni da file</h3>
						</div>

						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<Dropzone
									onChangeStatus={this.handleChangeStatusComuni}
									accept=".geojson,.json"
									maxFiles={1}
									inputContent="Inserisci il file dei comuni in formato GeoJSON"
									styles={{
										dropzone: { overflow: "hidden", width: "30vw" },
										dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
										inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
									}}
								/>
							</div>
						</div>


					</div>

				</Modal>
			</>
		)
	}
}



