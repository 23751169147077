import React from "react";

export default class ThresholdListComponent extends React.Component {
  /*
		colors
		items
	*/

  render() {
    const list = this.props.items.map((element, index) => {
      let borderLeft = "5px solid " + this.props.colors[index];
      return (
        <div style={{ borderLeft: borderLeft }} key={index}>
          {element}
        </div>
      );
    });

    return <div className=" mt-4 container">{list}</div>;
  }
}
