import React from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import '#/backoffice/style/MonitoringPage.css'
import MosipTab from "#/backoffice/Monitoring/tabs/MosipTab";
import errorMosip from "#/commons/sounds/notificationMosip.mp3"
import errorRain from "#/commons/sounds/notificationRain.mp3"
import MidaComponent from "#/backoffice/mida/components/MidaComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrafficLight from 'react-trafficlight';
import DateUtils from "#/lib/DateUtils";
import moment from "moment";
import RadarMeteosatComponent from "#/backoffice/radar/component/RadarMeteosatComponent";
import LapsWrfComponent from "#/backoffice/lapsWrf/component/LapsWrfComponent";
import {isLight} from '#/properties.js';
import AegisTab from './tabs/AegisTab';


const audios = {
	mida : new Audio(errorRain),
	mosip : new Audio(errorMosip),
}

const navTitleWithBadges = (logoUrl, badgeArray, ready, lastUpdate) =>
	(<>
		<div className="row" >
			<div className="col-2 my-auto "></div>
			<div className="col-2 my-auto "></div>
			<div className="col-4 my-auto">
				<img alt={''} src={logoUrl} style={{ height:"2rem"}} />
			</div>
			<div className="col-2 my-auto ">
				{!ready && <CircularProgress size={24} style={{color: 'green'}}/>}
			</div>
			<div className={`col-2 ${!lastUpdate && 'invisible'}`}>

				<TrafficLight RedOn={badgeArray.includes('red')}
							   YellowOn={badgeArray.includes('DarkOrange')}
							   GreenOn={badgeArray.includes('yellow')}
							   YellowColor={'DarkOrange'}
							   GreenColor={'#F8E71C'}
							   BlackColor={'transparent'}
							   DisabledColor={'#efeff0'}
							   Size={18}/>
			</div>
		</div>
		<div className={`${!lastUpdate && 'invisible'}`} style={{fontSize: '0.6rem'}}>Ultimo aggiornamento: {lastUpdate}</div>
	</>)

const initialLastUpdate = DateUtils.epochToDateMinuteResolution(moment().valueOf());
export default class MonitoringPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedTabKey : 'mida',
			badges : {mida : [], temperature : [], mosip : []},
			ready : {mida: false, mosip: false, radar: false, lapsWrf: false},
			lastUpdates : {mida: initialLastUpdate, mosip: initialLastUpdate, radar: initialLastUpdate, lapsWrf: initialLastUpdate }
		}
	}
	setTabReady = key => {
		let ready = {...this.state.ready, [key] : true}
		this.setState({ready})
	}
	setLastUpdate = (lastUpdate, key) => {
		let lastUpdates = {...this.state.lastUpdates, [key] : lastUpdate}
		this.setState({lastUpdates})
	}

	setBadges(badges,key){
		let newBadges = this.state.badges;
		newBadges[key] = badges;
		this.setState({
			badges : newBadges
		},() => {
			audios[key].play();
		})
	}


	render() {
		const {selectedTabKey, badges, ready, lastUpdates} = this.state;
		return (
			<>
				<div className="generic-page container-fluid data-page pt-0">
					<Tabs defaultActiveKey={"mida"}
						  activeKey={selectedTabKey}
						  onSelect={(k) => this.setState({
							  selectedTabKey : k
						  },() => {
							  let newBadges = this.state.badges
							  newBadges[k] = [];
							  this.setState({
								  badges : newBadges
							  })
						  })}
					>
						<Tab key="mida" eventKey="mida" title={navTitleWithBadges("/img/mida/logomida.png", badges.mida, ready.mida, lastUpdates.mida)}>
							<MidaComponent
								withDateSelection={false}
								showNotifications={badges => this.setBadges(badges,'mida')}
								ready={() => this.setTabReady('mida')}
								lastUpdate={lastUpdate => this.setLastUpdate(lastUpdate, 'mida')}/>
						</Tab>
						<Tab key="mosip" eventKey="mosip" title={navTitleWithBadges("/img/mosip/logomosip_transparent.png", badges.mosip, ready.mosip, lastUpdates.mosip)}>
							<MosipTab
								showNotifications={badges => this.setBadges(badges,'mosip')}
								ready={() => this.setTabReady('mosip')}
								lastUpdate={lastUpdate => this.setLastUpdate(lastUpdate, 'mosip')}/>
						</Tab>
						{/* <Tab key="aegis" eventKey="aegis" title={navTitleWithBadges("/img/logo_Aegis.png", [], true, null)}> */}
						<Tab key="aegis" eventKey="aegis" title={(<>
									<div className="row rounded">
										<div className="col-2 my-auto "></div>
										<div className="col-2 my-auto "></div>
										<div className="p-2 rounded" style={{backgroundColor: '#333333'}}>
											<img alt={'Aegis'} src={"/img/logo_Aegis.png"} style={{ height:"2rem"}} />
										</div>
										<div className="col-2 my-auto "></div>
										<div className="col-2"></div>
									</div>
								</>)
							}>
							<AegisTab />
						</Tab>
						{!isLight && 
							<Tab key="radar" eventKey="radar" title={navTitleWithBadges("/img/radar/radar_meteosat.png", [], ready.radar, null)}>
								<RadarMeteosatComponent ready={() => this.setTabReady('radar')}/>
							</Tab>
						}
						{!isLight && <Tab key="lapsWrf" eventKey="lapsWrf" title={navTitleWithBadges("/img/lapsWrf/lapsWrf.png", [], ready.lapsWrf, null)}>
							<LapsWrfComponent ready={() => this.setTabReady('lapsWrf')}/>
						</Tab>
						}

					</Tabs>

				</div>

			</>
		)
	}
}
