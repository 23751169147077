import React, {Fragment, useEffect, useState} from 'react';
import AuthenticationService, {
    ROLE_ADMIN,
    ROLE_ADMIN_PERSONALE,
    ROLE_CONFIGURATOR,
    ROLE_OFFICER
} from '#/lib/AuthenticationService';
import Badge from "@material-ui/core/Badge";
import WorkItemClient from "#/lib/WorkItemClient";
import _ from 'lodash';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {Link, useHistory} from "react-router-dom";
import {FaUserCircle} from 'react-icons/fa';
import {FiLogIn, FiLogOut} from 'react-icons/fi';
import WidthUtils from "#/lib/WidthUtils";
import {isLight} from '#/properties.js';
import { useAuth } from 'react-oidc-context';


const ReactSwal = withReactContent(Swal);

const fireAlert = (data) => {

    let expiring = data.find(wi => wi.level === 'EXPIRING');
    let warningCount = !!expiring ? expiring.number : 0;
    let expired = data.find(wi => wi.level === 'EXPIRED');
    let errorCount = !!expired ? expired.number : 0;
    if (warningCount + errorCount > 0) {
        let message = 'Trovate ';
        message += errorCount > 0 ? errorCount + ' attività scadute e ' : '';
        if (warningCount > 0) {
            message += warningCount + ' attività in scadenza';
        } else {
            message = message.replace(' e ', '');
        }
        ReactSwal.fire(message, 'Per maggiori dettagli, consultare il giornale di sala', 'warning');
    }

}
function BackOfficeNavigationComponent(props) {
    const [workItems, setworkItems] = useState([]);
    const auth = useAuth();
    const history = useHistory();
    const isAdminPersonale = AuthenticationService.haveRolesPermssions([ROLE_ADMIN_PERSONALE, ROLE_ADMIN],auth);
    const isOfficer = AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN],auth);
    const isConfigurator = AuthenticationService.haveRolesPermssions([ROLE_CONFIGURATOR, ROLE_ADMIN],auth);

    // Component will recieve props
    useEffect(() => {setworkItems(props.workItemsFromLogin)},[props.workItemsFromLogin])

    const workItemsUpdatedHandler = e => {
        console.log("Local storage changed!");
        let itemsFromStorage = JSON.parse(localStorage.getItem("workItemsState"));
        if (!_.isEqual(workItems, itemsFromStorage)) {
            console.log(' setworkItems(itemsFromStorage)')
            setworkItems(itemsFromStorage);
        }
    };

    // Component did mount + Component will unmount
    useEffect(() => {
        setworkItems(JSON.parse(localStorage.getItem("workItemsState")));
        document.addEventListener("workItemsUpdated", workItemsUpdatedHandler, false);
        WorkItemClient.getWorkItemsState(
            (data) => {
                setworkItems(data);
                localStorage.setItem("workItemsState", JSON.stringify(data));
            },
            () => console.log("Error while retrieving work items data")
        );
        const interval = setInterval(function() {
                let isLoggedIn = AuthenticationService.isUserLoggedIn(auth);
                let isOfficer = AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN],auth);
                if(isLoggedIn && isOfficer){
                    WorkItemClient.getWorkItemsState(
                        (data) => {

                            // Fires an alert if expiring/expired activities count pass from zero to N
                            if (!!JSON.parse(localStorage.getItem("workItemsState")) &&
                                JSON.parse(localStorage.getItem("workItemsState")).length === 0 &&
                                data.length > 0) {
                                /*fireAlert(data)*/
                            }

                            // If new work items' state is different from previous one, sets state and updates BackOfficeNavigationComponent (badges)
                            if (!_.isEqual(data, JSON.parse(localStorage.getItem("workItemsState")))) {
                                setworkItems(data);
                            }
                            localStorage.setItem("workItemsState", JSON.stringify(data));
                        },
                        () => console.log("Error while retrieving work items data")
                    );
                }
            }
            ,60000);

        return () => {
            console.log("STOP SCHEDULER")
            clearInterval(interval);
        };
    }, []);

    let {id} = props;



    const getAccountLi = () => {

        let accountLi =
            <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to={!AuthenticationService.isUserLoggedIn(auth) ? "/backoffice/login" : ""} id={id + "-account-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">
                    {AuthenticationService.isUserLoggedIn(auth)? <><FaUserCircle className="mr-2"/>{AuthenticationService.getLoggedInName(auth)} </>: <><FiLogIn className="mr-2"/>Login</>}
                </Link>
                <div className="dropdown-menu" aria-labelledby={id + "-account-dropdown"}>
                    {isOfficer && <Link className="dropdown-item" to={"/backoffice/workItems"}>Giornale di sala</Link>}
                    <Link className="dropdown-item" to={"/backoffice/user/account/info"}>Configurazione <br></br> notifiche</Link>
                    <Link className="dropdown-item" to={"/backoffice/user/work"}>Visualizza turni</Link>
                    <a href={() => false} role="button" className="dropdown-item"  onClick={()=>{
                        AuthenticationService.logout();
                        history.push("/backoffice/login")}}>
                        <FiLogOut className="mr-2"/>
                        Logout
                    </a>
                </div>
            </li>
        if (!!workItems && workItems.length > 0){
            let expiring = workItems.find(wi => wi.level === 'EXPIRING');
            let warningCount = !!expiring ? expiring.number : 0;
            let expired = workItems.find(wi => wi.level === 'EXPIRED');
            let errorCount = !!expired ? expired.number : 0;
            if (errorCount > 0){
                accountLi = (<Badge badgeContent={errorCount} color="error" anchorOrigin={{vertical: 'top',horizontal: 'right'}}>{accountLi}</Badge>);
            }
            if (warningCount > 0){
                accountLi = (<Badge badgeContent={warningCount} color="secondary" anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>{accountLi}</Badge>);
            }
        }
        return accountLi;
    }


    const test = () => {
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function(key, value) {
            const event = new Event('workItemsUpdated');

            document.dispatchEvent(event);
            originalSetItem.apply(this, arguments);
        };

        localStorage.setItem("workItemsState", JSON.stringify({}));
    }
    return (
        <>

            <button className="navb navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target={"#" + id}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id={id}>
                {WidthUtils.isWidthUp('xl')
                    ?
                    <></>
                    /*<div style={{fontSize: "xx-small", fontWeight: "bold", color: "#2e5396"}}>
                        Portale gestito dal Centro Funzionale Multirischi - Sicurezza del territorio dell'Agenzia Regionale per la Protezione dell'Ambiente della Calabria
                    </div>*/
                    :
                    <></>
                }
                <ul className="navbar-nav ml-auto my-2 my-lg-0">
                    {/* MONITORAGGIO */}
                    <li className="nav-item"><Link to={"/backoffice/monitoring"} className="nav-link js-scroll-trigger">Monitoraggio</Link></li>
                    {/* DATI */}
                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle" id={id + "-dati-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Dati</a>
                        <ul className="dropdown-menu multi-level" aria-labelledby={id + "-analisi-ul-dropdown"}>
                            <li className="dropdown-submenu">
                                <a href={() => false}  className="dropdown-item dropdown-toggle" data-toggle="dropdown"  aria-expanded="false">Gestione Dati</a>
                                <ul className="dropdown-menu" aria-labelledby={id + "-struttura-dropdown"}>
                                    <Link className="dropdown-item" to={"/backoffice/data"}>Consulta e Valida</Link>
                                    <Link className="dropdown-item" to={"/backoffice/legacydata"}>Dati piogge storici</Link>
                                    <Link className="dropdown-item" to={"/backoffice/dailyStats"}>Dati giornalieri</Link>
									<Link className="dropdown-item" to={"/backoffice/monthlyStats"}>Dati mensili</Link>

                                    <Link className="dropdown-item" to={"/backoffice/mida"}>Mida storico</Link>
                                    <Link className="dropdown-item" to={"/backoffice/download"}>Export dati</Link>
                                </ul>
                            </li>
                            <li className="dropdown-submenu">
                                <a href={() => false}  className="dropdown-item dropdown-toggle" data-toggle="dropdown"  aria-expanded="false">Rete di Monitoraggio</a>
                                <ul className="dropdown-menu" aria-labelledby={id + "-struttura-dropdown"}>
                                    <Link className="dropdown-item" to={"/backoffice/sensorcategory"}>Categorie Sensori</Link>
                                    <Link className="dropdown-item" to={"/backoffice/gridmgr"}>Gestione Sensori</Link>
                                    <Link className="dropdown-item" to={"/backoffice/foreside"}>Foreside</Link>
                                </ul>
                            </li>
                            <Link className="dropdown-item" to={"/backoffice/requestManager"}>Richieste dati</Link>
							<Link className="dropdown-item" to={"/backoffice/forceIngestion"}>Importazione manuale</Link>
                        </ul>
                    </li>
                    {/* ANALISI */}
                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle"  id={id + "-analisi-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Analisi</a>
                        <ul className="dropdown-menu multi-level" aria-labelledby={id + "-analisi-ul-dropdown"}>
                            <li className="dropdown-submenu">
                                <a href={() => false}  className="dropdown-item dropdown-toggle" data-toggle="dropdown"  aria-expanded="false">Mosip</a>
                                <ul className="dropdown-menu multi-level">
                                    <li className="dropdown-submenu">
                                        <a href={() => false}  className="dropdown-item dropdown-toggle" data-toggle="dropdown"  aria-expanded="false">Configurazione</a>
                                        <ul className="dropdown-menu" aria-labelledby={id + "-struttura-dropdown"}>
                                            <li><Link className="dropdown-item" to={"/backoffice/mosip/configurazione/municipalities"}>Comuni</Link></li>
                                            <li><Link className="dropdown-item" to={"/backoffice/mosip/configurazione/basins"}>Bacini</Link></li>
                                            <li><Link className="dropdown-item" to={"/backoffice/mosip/configurazione/risks"}>Rischi e Soglie</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link className="dropdown-item" to={"/backoffice/mosip/executions"}>Storico soglie superate</Link></li>
                                    <li><Link className="dropdown-item" to={"/backoffice/mosip/allertamenti"}>Messaggi CSS Emessi</Link></li>
                                    <li><Link className="dropdown-item" to={"/backoffice/mosip/a3_builder"}>Nuovo A3</Link></li>
                                    <li><Link className="dropdown-item" to={"/backoffice/mosip/testMosip"}>Test Mosip Execution</Link></li>
                                </ul>
                            </li>
                            <li className="dropdown-submenu">
                                <a href={() => false}  className="dropdown-item dropdown-toggle" data-toggle="dropdown"  aria-expanded="false">Rischio incendi</a>
                                <ul className="dropdown-menu" aria-labelledby={id + "-struttura-dropdown"}>
                                    <li><Link className="dropdown-item" to={"/backoffice/fireRiskConfig"}>Configurazione</Link></li>
                                    <li><Link className="dropdown-item" to={"/backoffice/firerisk"}>Mappa suscettività</Link></li>
                                    {!isLight && <li><Link className="dropdown-item" to={"/backoffice/firePropagation"}>Propagazione</Link></li>}

                                </ul>
                            </li>
                            <li><Link className="dropdown-item" to={"/backoffice/monthlyRain"}>Precipitazioni Mensili</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/spi"}>SPI</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/monthlyTemperature"}>Temperature Mensili</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/monthlyOutflow"}>Deflussi Mensili</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/outflowModel"}>Modello Afflussi/Deflussi</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/hecHMS"}>Modello Bilancio Idrologico</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/dailyStats"}>Dati giornalieri</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/maxvalues"}>Massimi annuali</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/rainstats"}>CPP/CDF</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/blacklist"}>Blacklist Sensori</Link></li>
                            {!isLight && <li><Link className="dropdown-item" to={"/backoffice/nowcasting"}>Nowcasting</Link></li>}
                            {!isLight && <li><Link className="dropdown-item" to={"/backoffice/radarMeteosat"}>Radar/Meteosat</Link></li>}
                        </ul>
                    </li>
                    {/* BOLLETTINI */}
                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle"  id={id + "-bollettini-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Bollettini</a>
                        <ul className="dropdown-menu multi-level" aria-labelledby={id + "-bollettini-ul-dropdown"}>

                            <li><Link className="dropdown-item" to={"/backoffice/mau"}>MAU</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/maib"}>MAIB</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/bim"}>Bollettino Idrologico Siccità</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/postevento"}>Report post evento</Link></li>
                            <li><Link className="dropdown-item" to={"/backoffice/forecast"}>Previsioni Meteo</Link></li>
                        </ul>
                    </li>
                    {/* Giornale di Sala */}
                    {/*workItemLi*/}


                    {/* STREAN NEWS */}
                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle"  id={id + "-infostream-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Stream News</a>
                        <div className="dropdown-menu" aria-labelledby={id + "-infostream-dropdown"}>
                            <Link className="dropdown-item" to={"/backoffice/stream/conf"}>Configurazione</Link>
                            <Link className="dropdown-item" to={"/backoffice/stream/news"}>Visualizzazione</Link>
                        </div>
                    </li>
                    {/* COMUNICATI */}
                    <li className="nav-item"><Link to={"/backoffice/comunicato"} className="nav-link js-scroll-trigger">Comunicati</Link></li>


                    {/* SETTINGS */}
                    {isConfigurator && <li className="nav-item dropdown">
                        <a href={() => false} className="nav-link dropdown-toggle" id={id + "-settings-dropdown"}
                           role="button" data-toggle="dropdown" aria-expanded="false">Impostazioni</a>
                        <ul className="dropdown-menu" aria-labelledby={id + "-settings-dropdown"}>
                            <Link className="dropdown-item" to={"/backoffice/settings"}>Impostazioni generali</Link>
                            <li className="dropdown-submenu">
                                <a href={() => false} className="dropdown-item dropdown-toggle" data-toggle="dropdown"
                                   aria-expanded="false">Gestione Mailing list</a>
                                <ul className="dropdown-menu" aria-labelledby={id + "-struttura-dropdown"}>
                                    <li><Link className="dropdown-item"
                                              to={"/backoffice/municipalityMailingListManager"}>Comuni</Link></li>
                                    <li><Link className="dropdown-item"
                                              to={"/backoffice/institutionMailingListManager"}>Enti</Link></li>
                                    <li><Link className="dropdown-item"
                                              to={"/backoffice/privateMailingListManager"}>Privati</Link></li>
                                </ul>
                            </li>
							<Link className="dropdown-item" to={"/backoffice/templateConfig"}>Configurazione Template Bollettini</Link>
							<Link className="dropdown-item" to={"/backoffice/staticPagesConf"}>Configurazione Pagine Statiche</Link>
                        </ul>
                    </li>}

                    {/* AMMINISTRAZIONE */}
                    {isAdminPersonale && <li className="nav-item dropdown">
                        <a href={() => false} className="nav-link dropdown-toggle" id={id + "-amministrazione-dropdown"}
                           role="button" data-toggle="dropdown" aria-expanded="false">Amministrazione</a>
                        <div className="dropdown-menu" aria-labelledby={id + "-amministrazione-dropdown"}>
                            <Link className="dropdown-item" to={"/backoffice/user"}>Gestione Utenti</Link>
                            <Link className="dropdown-item" to={"/backoffice/work"}>Gestione Turni</Link>
                        </div>
                    </li>}


                    {/* ACCOUNT */}
                    <Fragment key={JSON.stringify(workItems)}>{getAccountLi()}</Fragment>
                </ul>
              {/*  <button onClick={() => {test()}}>
                    WELO
                </button>*/}
            </div>

        </>

    );

}

export default (BackOfficeNavigationComponent);
