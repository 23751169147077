import React from 'react';
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {RiArrowDropDownLine, RiArrowDropUpLine} from "react-icons/ri";
import WidthUtils from "#/lib/WidthUtils"

const HeaderToggler = ({clickCallback, reduceHeader, disabled}) => (
    <Tooltip title={reduceHeader ? "Espandi loghi" : "Riduci loghi"} placement="left">
        <IconButton
            color="primary"
            aria-label="visualizza grafico"
            component="span"
            onClick={(e) => clickCallback()}
            disabled={disabled}
        >
            {reduceHeader ? <RiArrowDropDownLine/> : <RiArrowDropUpLine/>}
        </IconButton>
    </Tooltip>
)
class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            onTop: true,
            reduceHeader: false
        }
    }
    mode720p = () => !WidthUtils.isWidthUp('xl');

    componentDidMount() {
        window.onscroll = function () { this.scrollFunction() }.bind(this);
    }


    scrollFunction() {
        if (window.pageYOffset===0) {
            this.setState({
                onTop: true
            })
        }
        else{
            this.setState({
                onTop: false
            })
        }
    }

    render() {

        let backoffice = window.location.href.includes("backoffice");

        const oneLineHeader =
            <>
                <nav className="navbar navbar-expand-xl navbar-light d-xl-none" id="nav-oneline">

                    <div className="container-fluid">
                        <Link className="navbar-brand d-none d-md-block" to={this.props.home} style={{ lineHeight: '0.8' }}>
                            <img className="img-fluid" style={{maxWidth: "60vw"}}
                                src={!!backoffice ? "/img/4x/allertacal_logo_backoffice_bigger.png" : "/img/logo.png"}
                                alt="Logo"/>
                            {!!backoffice ? <></> : 
                                <>
                                <br></br>
                                <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                <br></br>
                                <small className='small-text-logo'>Sicurezza del Territorio</small>
                                </>
                            }
                        </Link>
                        <Link className="navbar-brand d-none d-sm-block d-md-none" to={this.props.home} style={{ lineHeight: '0.8' }}>
                            <img className="img-fluid" style={{maxWidth: "60vw"}} src={!!backoffice ? "/img/4x/allertacal_logo_backoffice_big.png" : "/img/logo.png"} alt="LogoPiccolo" />
                            {!!backoffice ? <></> : 
                                <>
                                <br></br>
                                <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                <br></br>
                                <small className='small-text-logo'>Sicurezza del Territorio</small>
                                </>
                            }
                        </Link>
                        <Link className="navbar-brand d-sm-none" to={this.props.home} style={{ lineHeight: '0.8' }}>
                            <img className="img-fluid" style={{maxWidth: "60vw"}} src="/img/logo_medium.png" alt="Logo"  />
                            {!!backoffice ? <></> : 
                                <>
                                <br></br>
                                <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                <br></br>
                                <small className='small-text-logo'>Sicurezza del Territorio</small>
                                </>
                            }
                        </Link>
                        {this.props.nav}

                    </div>
                </nav>
            </>

        const compactOneLineHeader =
            <>
                <nav className="navbar navbar-expand-xl navbar-light" id="nav-compact">

                    <div className="container-fluid">
                        <Link className="navbar-brand d-none d-md-block py-0" to={this.props.home} style={{ lineHeight: '0.8' }}>
                            <img className="img-fluid" style={{maxWidth: "60vw"}} src={!!backoffice ? "/img/4x/allertacal_logo_backoffice_bigger.png" : "/img/logo.png"} alt="Logo"  />
                            {!!backoffice ? <></> : 
                                <>
                                <br></br>
                                <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                <br></br>
                                <small className='small-text-logo'>Sicurezza del Territorio</small>
                                </>
                            }
                        </Link>
                        <Link className="navbar-brand d-none d-sm-block d-md-none py-0" to={this.props.home} style={{ lineHeight: '0.8' }}>
                            <img className="img-fluid" style={{maxWidth: "60vw"}} src={!!backoffice ? "/img/4x/allertacal_logo_backoffice_big.png" : "/img/logo.png"} alt="LogoPiccolo"  />
                            {!!backoffice ? <></> : 
                                <>
                                <br></br>
                                <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                <br></br>
                                <small className='small-text-logo'>Sicurezza del Territorio</small>
                                </>
                            }
                        </Link>
                        <Link className="navbar-brand d-sm-none" to={this.props.home} style={{ lineHeight: '0.8' }}>
                            <img className="img-fluid" style={{maxWidth: "60vw"}} src="/img/logo_medium.png" alt="Logo" />
                            {!!backoffice ? <></> : 
                                <>
                                <br></br>
                                <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                <br></br>
                                <small className='small-text-logo'>Sicurezza del Territorio</small>
                                </>
                            }
                        </Link>
                        {this.props.nav}

                    </div>
                </nav>
            </>

        const twoLinesHeader =
            <>
                <nav className="navbar navbar-expand-xl navbar-light d-none d-xl-block" id="nav-big-line-1">

                    <div className="container-fluid">
                        <div style={{ lineHeight: '0.8' }}>
                            <Link className="navbar-brand d-none d-sm-block" to={this.props.home} id="logo-big"><img className="img-fluid" style={{maxWidth: "60vw"}}
                                src={!!backoffice ? "/img/4x/allertacal_logo_backoffice_bigger.png" : "/img/logo.png"}
                                alt="Logo"
                            />
                                {!!backoffice ? <></> : 
                                    <>
                                    <br></br>
                                    <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                    <br></br>
                                    <small className='small-text-logo'>Sicurezza del Territorio</small>
                                    </>
                                }
                            </Link>
                            <Link className="navbar-brand d-sm-none" to={this.props.home} id="logo-small"><img className="img-fluid" style={{maxWidth: "60vw"}}
                                src={!!backoffice ? "/img/4x/allertacal_logo_backoffice_big.png" : "/img/logo.png"}
                                alt="LogoPiccolo"/>
                                {!!backoffice ? <></> : 
                                    <>
                                    <br></br>
                                    <small className='small-text-logo'>Centro Funzionale Multirischi 2.0</small>
                                    <br></br>
                                    <small className='small-text-logo'>Sicurezza del Territorio</small>
                                    </>
                                }
                            </Link>
                        </div>
                        <div id="logoMore">
                            <Link to={this.props.infoPage}>
                                <img className="img-fluid" style={{maxWidth: "60vw"}} src="/img/4x/loghi_alto_dx.png" alt="logo_more"/>
                            </Link>
                        </div>
                    </div>
                </nav>
                <nav className="navbar navbar-expand-lg navbar-light d-none d-xl-block" id="nav-big-line-2">
                    <div className="container-fluid">
                        {this.props.nav}
                    </div>
                </nav>
            </>


        let header =
                <div className="d-inline-flex justify-content-between w-100">
                    {/* style={{width: backoffice ? "95vw" : "100vw"}} */}
                    <div style={{width: "100%"}}>
                        {!this.state.reduceHeader && this.state.onTop ?
                            <>
                                {oneLineHeader}
                                {twoLinesHeader}
                            </> :
                            <>
                                {compactOneLineHeader}
                            </>
                        }
                    </div>
                    {backoffice && <div className=" my-auto" style={{textAlign: 'end', maxWidth: 'fit-content', width: "5vw"}}>
                        <HeaderToggler
                            clickCallback={() => {
                                this.setState({reduceHeader: !this.state.reduceHeader})
                            }}
                            reduceHeader={this.state.reduceHeader}
                            disabled={!this.state.onTop}
                        />
                    </div>}
                </div>

        return (

            <div className="sticky-top w-100" id={"mainNav"}>
                {header}
            </div>

        );
    }
}

export default (Header);

