import React from 'react'
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";
import DateUtils from "#/lib/DateUtils";
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import Looks3Icon from "@material-ui/icons/Looks3";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {getUnitByCategory} from "#/lib/MeasurementCategory";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Skeleton from "react-loading-skeleton";
import _ from 'lodash'
import chroma from 'chroma-js'
import moment from "moment"
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {ImArrowRight} from "react-icons/im";
import VerifiedUserSharpIcon from "@material-ui/icons/VerifiedUserSharp";

function ValidationIcons (props) {
	const {v1, v2, v3} = props.cell._cell.row.data;
	return (
		<div>
				<span style={{float: 'right'}}>
					<LooksOneIcon style={{color: v1 === 'VALID' ? "green" : "red"}} />
					<LooksTwoIcon style={{color: v2 === 'SUSPECT' ? "red" : "green"}}/>
					<Looks3Icon style={{color: colors[v3]}}/>
				</span>
		</div>

	)
}

const colors = {
	VALID : "rgba(16,187,34,0.8)",
	SUSPECT: "rgba(234,129,49,0.8)",
	NOT_VALID: "rgba(241,46,2,0.8)",
	NOT_VALIDATED: "rgba(239,221,61,0.8)",
	RECONSTRUCTED: "rgba(126,197,219,0.8)",
	MISSING: "#ff00008a",
	NONE: "rgb(255,255,255)"
}

const validationOptions = {
	VALID : "Dato corretto",
	SUSPECT: "Dato sospetto",
	NOT_VALID: "Dato errato",
	NOT_VALIDATED: "Dato non validato",
	RECONSTRUCTED: "Dato ricostruito",
	MISSING: "Dato mancante",
	NONE: "none"
}

const isIncluded = (val, t1, t2) => val >= Math.min(t1, t2) && val <= Math.max(t1, t2);

export default class DataEditorTable extends React.Component {


	/*
    dataset
    dataSetCache
    tablekey
    filter
    heigth
    */
	constructor(props){
		super(props);

		this.state = {
			validateAll : validationOptions.none,
			commonValue : null,
		}
	}

	componentDidMount(){

	}

	componentDidUpdate(){

		if(!!this.tableRef && !!this.tableRef.table ){
			/*if (this.state.rowIndex) {
				this.tableRef.table.scrollToRow(this.state.rowIndex, "center", true);
			}*/
			if (!this.props.filter){
				this.tableRef.table.clearFilter()
			}
		}
	}

	resetTable = () => {
		if(!!this.tableRef && !!this.tableRef.table){
			try {
				this.tableRef.table.clearFilter()
			} catch (error) {
				console.error(error);
			}
		}
	}
	syncTableToChart = () => {
		if(!!this.tableRef && !!this.tableRef.table){
			try {
				this.filterTableAndGoToRow();
			} catch (error) {
				console.error(error);
			}
		}
	}

	filterTableAndGoToRow = () => {
		if(!!this.props.filter){
			this.tableRef.table.setFilter([
				{field:"ts", type:">=", value:this.props.filter.min},
				{field:"ts", type:"<=", value:this.props.filter.max}
			]);
		}
		else {
			this.tableRef.table.clearFilter()
		}
		if (this.state.rowIndex){
			this.tableRef.table.scrollToRow(this.state.rowIndex, "center", true);
		}
	}

	rowFormatter(row){
		var data = row.getData();

		if(data.v3==="MISSING"){
			row.getElement().style.backgroundColor = colors.MISSING;
			if(data.ts > moment().valueOf()){
				row.getElement().style.cursor = "not-allowed";
			}
		}
		else if(!!data.locked){
			row.getElement().style.backgroundColor = "hsla(99,54%,63%,0.8)";
			row.getElement().style.cursor = "not-allowed";
		}
		else if(!!data.dirty){
			row.getElement().style.backgroundColor = "rgba(245,203,92,0.5)";
		}
	}

	cellEdited(cell){
		console.log('cell', cell)
		let rowIndex = cell.getRow().getIndex();
		this.setState({rowIndex}, () => {
			this.props.cellEditedCallback(cell.getRow().getData());
			this.rowFormatter(cell.getRow())

		})

	}

	getTabulatorOptions(){
		let tabulatorOptions = {
			data: [],
			dataEdited: this.props.dataEditedCallback,
			cellEdited:(cell)=>this.cellEdited(cell),
			history:true,
			height:this.props.height,
			autoResize: false,
			layout:"fitColumns",
			rowFormatter:(row)=>this.rowFormatter(row),
			tooltips:(cell)=>{
				let data = cell.getRow().getData();
				let currentTimestamp = data.ts;
				let list = DateUtils.epochToGMT1String(currentTimestamp) +" :\n\n";
				Object.keys(this.props.stationNameBySensorCode).forEach(sensorCode =>{
					list += this.getTooltipItemBySensorCode(sensorCode, currentTimestamp) +"\n" ;
				});
				return  list;
			},
			rowMouseOver: (e, row) => {
				if (this.props.selectedTimestamps.length % 2 !== 0){
					let startTimestamp = this.props.selectedTimestamps[this.props.selectedTimestamps.length - 1];
					let endTimestamp = row.getData().ts;
					this.tableRef.table.rowManager.activeRows.forEach(r => {
						if (r.getCells()[0]) {
							if (isIncluded(r.getCells()[0].getValue(), startTimestamp, endTimestamp)) {
								r.getCells()[0].getElement().style.backgroundColor = chroma('#add69a').alpha(0.4);
							} else {
								r.getCells()[0].getElement().style.backgroundColor = null;
							}
						}
					})
				}
			}
		}
		return tabulatorOptions;
	}

	getTooltipItemBySensorCode = (sensorCode, currentTimestamp) =>{
		if(!!this.props.sensorValueMapByTimestamp[sensorCode]){
			let item = this.props.sensorValueMapByTimestamp[sensorCode][currentTimestamp];
			let category = this.props.categoryBySensorCode[sensorCode];
			let value = item ?? "ND";

			return this.props.stationNameBySensorCode[sensorCode]+" ("+category+") : "+value;
		}
		else{
			return "";
		}

	}


	getRowsCount = () => this.tableRef && this.tableRef.table.rowManager.activeRowsCount;

	getColumns(){
		let columns =   [

			{
				title: this.props.selectedMeasure.value === 'P' ? "Absolute" : "Value",
				field: this.props.selectedMeasure.value === 'P' ? "aVal" : "value",
				formatter: function (cell) {
					return cell.getRow().getData().v3!=="MISSING" ? cell.getValue() : "ND";
				},
				editor: this.customEditor,
				editable: (cell) => !cell.getRow().getData().locked,
				headerSort:false
			},
			{
				title: "Original",
				field: "oVal",
				headerSort:false
			},
			{
				title: "Validations",
				field: "",
				headerSort:false,
				formatter: reactFormatter(<ValidationIcons/> ),

			},
			{
				title: "Validate",
				field: "v3",
				headerSort:false,
				formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return validationOptions[cell.getValue()];
				},
				editor: "select",
				editorParams: {
					values: validationOptions
				} ,
				editable: (cell) => !cell.getRow().getData().locked  && cell.getRow().getData().v3!=="MISSING",
			},
		];

		if (this.props.selectedMeasure.value === 'P'){
			columns.unshift({
				title: "Ref time",
				field: "rTs",
				width: 160,
				formatter: function (cell) {
					return cell.getRow().getData().v3!=="MISSING" ? DateUtils.epochToGMT1String(cell.getValue()) : "ND" ;
				},
				headerSort:false
			})
		}
		columns.unshift({
			title: "Time",
			field: "ts",
			width: 160,
			formatter: cell =>  {
				if (this.props.filter && isIncluded(cell.getValue(), this.props.filter.min, this.props.filter.max)) {
					cell.getElement().style.backgroundColor = chroma('#add69a').alpha(0.2)
				}
				return DateUtils.epochToGMT1String(cell.getValue());
			},
			cellDblClick: (e, cell) => {
				let rowIndex = cell.getRow().getIndex();
				this.setState({rowIndex}, () => {
					this.props.handleSelectTimestamp(cell.getRow().getData().ts);
				})

			},
			cellContext: (e, cell) => {
				e.preventDefault();
				/*	this.resetTable();*/
				this.props.handleSelectTimestamp(null);
			},
			headerSort:false
		})
		return columns;
	}

	handleValidateAll(value) {
		this.props.allDataValidatedCallback(value)
	};

	customEditor = (cell, onRendered, success, cancel, editorParams) =>{
		let editor = document.createElement("input");
		editor.setAttribute("type", "numeric");
		editor.style.padding = "3px";
		editor.style.width = "100%";
		editor.style.height = "100%";
		editor.value = cell.getValue();
		const successFunc = () => {
			cell.getRow().getData()[this.props.selectedMeasure.value === 'P' ? "aVal" : "value"] = editor.value;
			cell.getRow().getData().valueDirty = true;
			cell.getRow().getData().v3 = 'RECONSTRUCTED';

			if (!cell.getRow().getData().id){
				cell.getRow().getData().createdNew = true
			}
			console.log("Custom editor",cell.getRow().getData());

			success(editor.value);

		}
		editor.addEventListener("change", ()=>{
			successFunc();
			this.props.onTableChange();

		});
		return editor;
	};
	onChangeValue = commonValue => {this.setState({commonValue: commonValue !== "" ? commonValue : null});}
	render(){
		return _.isEmpty(this.props.dataset) ? (
			<>
				<Skeleton height={"15vh"} duration={8} />
				<Skeleton height={"50vh"} duration={8} />
			</>
		) : (<>
				<div>
					<Button
						className="justify-content-end ml-2"
						variant="contained"
						color="primary"
						startIcon={<ImArrowRight />}
						onClick={() => this.syncTableToChart()}>Sincronizza selezione da grafico</Button>
					</div>
				<div className="row mt-2">
					<div className="col-6">
						<FormControl>
							<InputLabel id="allValidationLabel">Applica validazione nell'intervallo</InputLabel>
							<Select
								style={{ minWidth: "15vw" }}
								labelId="allValidationLabel"
								id="allValidationSelect"
								value={this.state.validateAll}
								onChange={(e)=>this.handleValidateAll(e.target.value)}>
								{Object.keys(validationOptions).map(k => <MenuItem value={k}>{validationOptions[k]}</MenuItem>)}
							</Select>
						</FormControl>
					</div>
					<div className="col-6">
						<TextField
							fullWidth
							type="number"
							id="commonValue"
							name="commonValue"
							label="Applica valore nell'intervallo"
							value={this.state.commonValue}
							onChange={(e) => this.onChangeValue(e.target.value)}

							InputProps={{
								inputProps: { step: 0.1 },
								startAdornment: <InputAdornment position="start">
									{getUnitByCategory(this.props.selectedMeasure.value)}
								</InputAdornment>,
								endAdornment: this.state.commonValue !== null &&
									<InputAdornment position="end">
										<Button style={{backgroundColor: '#a1e2a1'}} onClick={() => this.props.allDataEditedCallback(this.state.commonValue)}>Conferma</Button>
									</InputAdornment>
							}}
						/>

					</div>
				</div>

				<ReactTabulator
					ref={ref => (this.tableRef = ref)}
					columns={this.getColumns()}
					data={this.props.dataset}
					options={this.getTabulatorOptions()}
					key={"table_"+ this.props.tableKey}
				/>

			</>
		)
	}

}
