import React, {useRef, useState} from 'react';
import {withRouter} from 'react-router';
import {useHistory} from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {useForm} from "react-hook-form";
import UserClient from '#/lib/UserClient';
import AuthenticationService from '#/lib/AuthenticationService';

import {HeaderForm} from '#/commons/components/forms/HeaderForm';
import {TextFieldForm} from '#/commons/components/forms/TextFieldForm';
import {ResetButton} from '#/commons/components/forms/ResetButton';
import {SaveButton} from '#/commons/components/forms/SaveButton';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';


const ReactSwal = withReactContent(Swal);


class AccountEditorPage extends React.Component {

	formContent = {}

	constructor(props) {
		super(props);
		
		const username = AuthenticationService.getLoggedInUsername();
		this.state = {
			username: username!=='new' ? username : null,
			checkedRolesPermessions: [],
			userAnag: {}
		}
	}

	componentDidMount() {
		if(!!this.state.username){
			let params = {
				username : this.state.username
			}
			UserClient.getUserRolesPermissions (
				(result) => {

					const checkedRolesPermessions = this.getCheckedRolesPermissions(result.roles);
					this.setState({
						userAnag : result.userAnag || {},
						checkedRolesPermessions : checkedRolesPermessions
					})
				},
				(msg) => {
					ReactSwal.fire(
						'getUserRolesPermissions Failed',
						msg,
						'error'
					)
				},
				params
			)
		}
	}


	getCheckedRolesPermissions = (roles) =>{
		let rolesPermissions = [];
		roles.forEach( role => {
			role.permissions.forEach( permission => {
				rolesPermissions.push(role.roleName + '|' + permission.permissionName);
			})
		})
		return rolesPermissions;
	}

	render = () => {
		const userAnag = this.state.userAnag;
		const userRoles = this.state.checkedRolesPermessions;
		const modifyExistingUser = !!this.state.username;

		return (
			<div className="generic-page">
				<div className="container">
					<HeaderForm title="Modifica dati Account" />
					{modifyExistingUser && !!userAnag.name ?
						<UserForm userAnag={userAnag} userRoles={userRoles} modifyExistingUser={modifyExistingUser}/>:
						!modifyExistingUser && <UserForm userAnag={userAnag} userRoles={userRoles} modifyExistingUser={modifyExistingUser}/>
					}
				</div>

			</div>

		)
	}
}

const UserForm = (props) => {
	const history = useHistory();
	const fieldRequiredMessage = "Campo obbligatorio";
	const defaultValues = !!props.userAnag ? {...props.userAnag} : '';
	const { register, handleSubmit, errors, setValue, reset, watch  } = useForm({defaultValues: defaultValues});
	const [checked_nodes, setCheckedNodes] = useState(!!props.userRoles ? props.userRoles : ['ROLE_GUEST']);

	const newPwd = useRef({});
	newPwd.current = watch("newPwd", "");


	const getRolesPermissionsFromChecked = (checkedRolesPermissions) => {
		let rolesPermissions = [];
		if (!!checkedRolesPermissions){
			checkedRolesPermissions.forEach( rolePermission => {
				const splitRolePermission = rolePermission.split('|');
				const role = splitRolePermission[0];
				const permission = splitRolePermission[1];
				const indexRole = rolesPermissions.findIndex( p => p.roleName === role);
				indexRole > -1 ?  rolesPermissions[indexRole].permissionsName.push(permission) : rolesPermissions.push({roleName: role, permissionsName: [permission] });
			})
		}
		return rolesPermissions;
	}

	const resetForm = () => {
		setCheckedNodes([]);
		if (props.modifyExistingUser ) {
			reset({ username: defaultValues.username });
		} else {
			reset();
		}
	}

	const onSubmit = (data) => {

		const rolesPermissions = getRolesPermissionsFromChecked(checked_nodes);
		const params = {rolePermission : rolesPermissions, username: defaultValues.username , ...data};
		console.log('params: ', JSON.stringify(params));

		if (props.modifyExistingUser ) {//Modify
			let recap = '';
			ReactSwal.fire({
				title: "<h2>Confermi la modifica dei dati utente</h2>",
				html: recap,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Invia',
				cancelButtonText: 'Annulla'
			}).then((result) => {
				if (result.value) {
					UserClient.userChangePassword(
						(result) => {
							ReactSwal.fire('Archiviazione utente','Utente archiviato con successo','success');
							history.push("/backoffice/user");
						},
						(msg)  => {
							console.log(msg);
							ReactSwal.fire('Errore in modifica password', !!msg ? msg.message : '', 'error');

						},
						params
					)
				}
			});
		} 
	}

	return (
		<form onSubmit = {handleSubmit(onSubmit)} autoComplete="off">
		<Card>
			<CardHeader title="Modifica Password" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
			<Divider/>
			<CardContent>
				<Grid container spacing={10}>
					<Grid item xs={4}>
						<TextFieldForm
							id = "oldPwd"
							label = "Vecchia Password*"
							error={!!errors.oldPwd}
							helperText={!!errors.oldPwd ? errors.oldPwd.message : null}
							inputRef={register({ required: fieldRequiredMessage })}
							handleClearButton={() => setValue('oldPwd', null)}
							type="password"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldForm
							id = "newPwd"
							label = "Nuova Password*"
							error={!!errors.newPwd}
							helperText={!!errors.newPwd ? errors.newPwd.message : null}
							inputRef={register({ required: fieldRequiredMessage })}
							handleClearButton={() => setValue('newPwd', null)}
							type="password"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldForm
							id = "newPwdConfirm"
							label = "Conferma Password*"
							error={!!errors.newPwdConfirm}
							helperText={!!errors.newPwdConfirm ? errors.newPwdConfirm.message : null}
							inputRef={register({ required: fieldRequiredMessage, 
								validate: value => value === newPwd.current || "Le password non coincidono"
								})}
							handleClearButton={() => setValue('newPwdConfirm', null)}
							type="password"
						/>
					</Grid>
				</Grid>

			</CardContent>
		</Card>



		<Grid container justify="center" alignItems="center" className="mt-1">
			<ResetButton variant="contained" size="large" onClick={() => resetForm()}/>
			<SaveButton variant="contained" size="large" type="submit"/>
		</Grid>

		</form>

	);
}

export default withRouter(AccountEditorPage)
