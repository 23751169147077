import {properties} from "#/properties.js";
import APIUtils from "#/lib/APIUtils";

export default class ComunicatoClient {

  static saveNews = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.newsSave);
    let fetch_options = {
      headers: { "Content-Type": "application/json", charset: "utf-8" },
      method: "POST",
      body: JSON.stringify(params),
    };

    console.log('JSON.stringify(params): ', JSON.stringify(params));
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };

  static getSchema = (okCallback, errCallback) => {
    let url = new URL(properties.url.newsSchema);
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static listNews = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.newsList);
    params.isBO = !!params.isBO ? params.isBO : false;
    let fetch_options = {
      headers: { "Content-Type": "application/json", charset: "utf-8" },
      method: "POST",
      body: JSON.stringify(params),
    };
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };

  static listHotNews = (okCallback, errCallback) => {
    let url = new URL(properties.url.newsHot);
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static getNews = (okCallback, errCallback, id) => {
    let url = new URL(properties.url.newsGet + "/" + id);
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static deleteNews = (okCallback, errCallback, id) => {
    let url = new URL(properties.url.newsDelete(id));
    let fetch_options = {
      headers: { "Content-Type": "application/json", charset: "utf-8" },
      method: "DELETE"
    };
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };
}
