import React from 'react'

import LightMeasurementsHeader from "#/commons/data/components/LightMeasurementsHeader"
import LightMeasurementsBody from "#/commons/data/components/LightMeasurementsBody"

let defaultAggregationFunction = {
	label: "",
	value: 0
}


let defaultAggregationInterval = {
	label: "Misure puntuali",
	value: 'PUNTUAL'
}


export default class LightMeasurementsComponent extends React.Component {

	constructor(props) {
		super(props);

		let stationNameBySensorCode = {}
		this.props.selectedMeasure.sensor.forEach(s=>{
			stationNameBySensorCode[s.sensorCode] = this.props.selectedStation.label
		})


		this.state = {
			selectedViewMode: {
				label: "",
				value: "AGGREGATED_TABLE"
			},
			aggregationInterval: defaultAggregationInterval,
			aggregationFunction: defaultAggregationFunction,
			aggregationIntervalDisabled: false,
			aggregationFunctionDisabled: true,
			stationNameBySensorCode,
			compareList : !!this.props.compareList ? this.props.compareList : [],
		}
	}

	componentDidMount() {
	}

	onSelectAggregationInterval(data) {

		this.setState({
			aggregationInterval: data,
		})

	}

	onSelectAggregationFunction(data) {
		this.setState({
			aggregationFunction: data,
		})

	}
	updateCompareList = data =>  {

		this.setState({
			compareList: data,
			stationNameBySensorCode : this.getStationNameBySensorCodeMap(data),
		},() => this.props.updateCompareList(data))
	}
	getStationNameBySensorCodeMap = compareList =>{
		let mapToStationName = {
			[this.props.selectedMeasure.sensor[0].sensorCode] : this.props.selectedStation.label
		}
		compareList.reduce((map,elem)=>{
			map[elem.measure.sensor[0].sensorCode] = elem.station.name
			return map;
		}, mapToStationName);

		return mapToStationName
	}

	render() {

		return <>
			<LightMeasurementsHeader
				resetInterval={(interval) => this.props.resetInterval(this.state.compareList, interval)}
				selectedMeasure={this.props.selectedMeasure}
				selectedStation={this.props.selectedStation}
				selectedViewMode={this.state.selectedViewMode}
				interval={this.props.interval}
				aggregationInterval={this.state.aggregationInterval}
				aggregationFunction={this.state.aggregationFunction}
				aggregationIntervalDisabled = {this.state.aggregationIntervalDisabled}
				aggregationFunctionDisabled = {this.state.aggregationFunctionDisabled}
				onSelectAggregationInterval={(data) => this.onSelectAggregationInterval(data)}
				onSelectAggregationFunction={(data) => this.onSelectAggregationFunction(data)}
				stationsGeoJSON = {this.props.stationsGeoJSON}
				updateCompareList = {data => this.updateCompareList(data)}
				compareList = {this.state.compareList}
				stations={this.props.stations}
				stationOptions = {this.props.stationOptions}
			></LightMeasurementsHeader>
			<LightMeasurementsBody
				selectedMeasure={this.props.selectedMeasure}
				selectedStation={this.props.selectedStation}
				interval={this.props.interval}
				aggregationInterval={this.state.aggregationInterval}
				aggregationFunction={this.state.aggregationFunction}
				selectedViewMode={this.state.selectedViewMode}
				stationsGeoJSON = {this.props.stationsGeoJSON}
				compareList = {this.state.compareList}
				stationNameBySensorCode = {this.state.stationNameBySensorCode}
				stations={this.props.stations}
				stationOptions = {this.props.stationOptions}
			></LightMeasurementsBody>
		</>

	}
}
