import environment from 'environment';
import ApiUtils from '#/lib/APIUtils'
import Markers from "#/lib/Markers";

console.log("Current env variables");
console.log(environment);

const baseUrl	= environment.base_api_url;
const baseStreamUrl 	= environment.base_stream_url;
export const radarBaseUrl = `${baseUrl}/pub`;
export const meteosatBaseUrl = `${baseUrl}/pub`;
export const wrfBaseUrl = `${baseUrl}/pub`;
export const hecHmsBaseUrl = `${baseUrl}/pub/hechms`;

export const isLight = environment.isLight !== null ? environment.isLight : false;

export const properties = {

	url: {
		imageUrl : `${environment.base_img_url}`,
		logout			: `${baseUrl}/logout`,

		intervalData : 				`${baseUrl}/${ApiUtils.getContext()}/measurement/intervalMeasurements`,
		intervalStats:				`${baseUrl}/${ApiUtils.getContext()}/measurement/intervalStats`,
		dataForPuntualChart : 		`${baseUrl}/${ApiUtils.getContext()}/measurement/measurementsForPunctualChart`,/* ****/
		updateData :  				`${baseUrl}/${ApiUtils.getContext()}/measurement/updateMeasurements`,

		aggregatedTableData : 		`${baseUrl}/${ApiUtils.getContext()}/measurement/tablelist/aggregated`,
		aggregatedTabledataCSV :	`${baseUrl}/${ApiUtils.getContext()}/measurement/tablelist/aggregated/csv`,
		lastValuesGeoJsonData : 	`${baseUrl}/${ApiUtils.getContext()}/measurement/lastValues/geoJson`,

		data : 						`${baseUrl}/${ApiUtils.getContext()}/measurement/data`,
		windComboAggregated : 		`${baseUrl}/${ApiUtils.getContext()}/measurement/windCombo/sector/aggregate`,
		crossValidation : 			`${baseUrl}/${ApiUtils.getContext()}/measurement/crossValidation`,
		multiSensorData	: 			`${baseUrl}/pub/measurement/puntualMeasurements/json`,
		multiSensorDataExport :		`${baseUrl}/pub/measurement/puntualMeasurements/csv`,
		aggregatedMultiSensorData	: 			`${baseUrl}/pub/measurement/aggregatedMeasurements/json`,
		aggregatedMultiSensorDataExport :		`${baseUrl}/pub/measurement/aggregatedMeasurements/csv`,
		editMeasurementsByCommandList :		`${baseUrl}/pub/measurement/editMeasurementsByCommandList`,
		legacyData : 				`${baseUrl}/${ApiUtils.getContext()}/legacy_measurement/aggregatedMeasurements/json`,
		legacyDataIntervals : 			(stationCode)	=> `${baseUrl}/${ApiUtils.getContext()}/legacy_measurement_intervals/${stationCode}`,

		midaGeoJson : 				`${baseUrl}/${ApiUtils.getContext()}/mida/geoJson`,
		midaLightGeoJson : 			`${baseUrl}/pub/mida/light/geoJson`,

		saveLockData : 	`${baseUrl}/${ApiUtils.getContext()}/lockeddata/save`,
		getLockData : 	`${baseUrl}/${ApiUtils.getContext()}/lockeddata/list/bySensorCode`,


		bulletinGet 			: `${baseUrl}/${ApiUtils.getContext()}/bulletin/get`,
		bulletinGetPrevious 			: `${baseUrl}/${ApiUtils.getContext()}/bulletin/getPrevious`,
		bulletinList 			: `${baseUrl}/${ApiUtils.getContext()}/bulletin/list`,
		bulletinFullList 		: `${baseUrl}/${ApiUtils.getContext()}/bulletin/fulllist`,
		bulletinSchema 			: `${baseUrl}/${ApiUtils.getContext()}/bulletin/schema`,
		bulletinSave 			: `${baseUrl}/${ApiUtils.getContext()}/bulletin/save`,
		bulletinApprove 		: `${baseUrl}/${ApiUtils.getContext()}/bulletin/approve`,
		bulletinPublish 		: `${baseUrl}/${ApiUtils.getContext()}/bulletin/publish`,
		bulletinDownloadFile 	: `${baseUrl}/pub/bulletin/downloadFile`,
		bulletinUploadFile 		: `${baseUrl}/${ApiUtils.getContext()}/bulletin/uploadFile`,
		bulletinDelete			: `${baseUrl}/${ApiUtils.getContext()}/bulletin/delete`,
		isCreationAllowedToday  : `${baseUrl}/${ApiUtils.getContext()}/bulletin/isCreationAllowedToday`,
		bulletinShare			: `http://mockagenny.ddns.net:8081/rest/pub/bulletin/share`,

		bulletinGetTypes 		: `${baseUrl}/${ApiUtils.getContext()}/bulletin/getTypes`,
		bulletinTypeUpdateTemplate		: (documentTypeName, templateName)	=> `${baseUrl}/${ApiUtils.getContext()}/bulletin/updateBulletinTemplate/${documentTypeName}/${templateName}`,



		newsGet 	: `${baseUrl}/${ApiUtils.getContext()}/news`,
		newsList 	: `${baseUrl}/${ApiUtils.getContext()}/news/list`,
		newsSchema 	: `${baseUrl}/${ApiUtils.getContext()}/news/schema`,
		newsSave 	: `${baseUrl}/${ApiUtils.getContext()}/news/save`,
		newsHot 	: `${baseUrl}/${ApiUtils.getContext()}/news/foreground/list`,
		newsDelete 	: id=> `${baseUrl}/${ApiUtils.getContext()}/news/delete/${id}`,


		sensorByStationCodeAndCategory: `${baseUrl}/${ApiUtils.getContext()}/sensor/sensorByStationCodeAndCategory`,
		sensorsData : 					`${baseUrl}/${ApiUtils.getContext()}/sensor/sensorsByCategory`,
		sensorsByCategory: 				`${baseUrl}/${ApiUtils.getContext()}/sensor/sensorsBySingleCategory`,
		sensorsByStation : 				`${baseUrl}/${ApiUtils.getContext()}/sensor/sensorsByStationCode`,
		sensorByCode : 					`${baseUrl}/${ApiUtils.getContext()}/sensor/sensorByCode`,
		allSensors : 					`${baseUrl}/${ApiUtils.getContext()}/sensor/all`,
		saveSensor						:`${baseUrl}/${ApiUtils.getContext()}/sensor/save`,
		deleteSensor						:`${baseUrl}/${ApiUtils.getContext()}/sensor/deleteById`,
		stations : 						`${baseUrl}/${ApiUtils.getContext()}/station/geojson`,
		saveStation						:`${baseUrl}/${ApiUtils.getContext()}/station/save`,
		deleteStation						:`${baseUrl}/${ApiUtils.getContext()}/station/deleteById`,

		sensorThresholds		            : `${baseUrl}/${ApiUtils.getContext()}/threshold/stationsensor/list/bySensorCode`,
		sensorThresholdsMapByCategory		: `${baseUrl}/${ApiUtils.getContext()}/threshold/stationsensor/map`,
		sensorThresholdsBySensorCategory	: `${baseUrl}/${ApiUtils.getContext()}/threshold/stationsensor/list/bySensorCategory`,
		sensorThresholdSave		            : `${baseUrl}/${ApiUtils.getContext()}/threshold/stationsensor/save`,
		sensorThresholdDelete				: `${baseUrl}/${ApiUtils.getContext()}/threshold/stationsensor/bySensorCode`,

		sensorOutflows			:	`${baseUrl}/${ApiUtils.getContext()}/outflow/scales/list/bySensorCode`,
		allOutflowsScales		:	`${baseUrl}/${ApiUtils.getContext()}/outflow/scales/list`,
		sensorOutflowSave		:	`${baseUrl}/${ApiUtils.getContext()}/outflow/scales/list/save`,
		sensorOutflowDelete		:	`${baseUrl}/${ApiUtils.getContext()}/outflow/scales/delete/bySensorCode`,
		sensorOutflowsGeom		: 	`${baseUrl}/${ApiUtils.getContext()}/outflow/geom/bySensorCode`,
		sensorOutflowGeomSave	: 	`${baseUrl}/${ApiUtils.getContext()}/outflow/geom/save`,
		sensorOutflowGeomDelete	: 	`${baseUrl}/${ApiUtils.getContext()}/outflow/geom/delete/bySensorCode`,
		outflowStats 			:	`${baseUrl}/${ApiUtils.getContext()}/outflow/outflowStats/byMonthAndYear`,

		estimatedFakeFlow 			:	`${baseUrl}/${ApiUtils.getContext()}/outflow/influxOutflow`,
		influxOutflowDataBySensorAndTimestamp 			:	`${baseUrl}/${ApiUtils.getContext()}/influxOutflowData/execution`,
		influxOutflowBasins 			:	`${baseUrl}/${ApiUtils.getContext()}/influxOutflowData/basins`,
		influxOutflowDataByHydrometer 			:	`${baseUrl}/${ApiUtils.getContext()}/influxOutflowData/executionByHydrometer`,

		workShiftGetList			: `${baseUrl}/${ApiUtils.getContext()}/workshift/search`,
		workShiftGetFunctionsList	: `${baseUrl}/${ApiUtils.getContext()}/workshift/functions/list`,
		workShiftSave 				: `${baseUrl}/${ApiUtils.getContext()}/workshift/save`,
		workShiftSaveAll			: `${baseUrl}/${ApiUtils.getContext()}/workshift/batchLoad`,
		workShiftDelete				: `${baseUrl}/${ApiUtils.getContext()}/workshift`,
		workShiftCsvUpload			: `${baseUrl}/${ApiUtils.getContext()}/workshift/files/upload`,

		userList					: `${baseUrl}/${ApiUtils.getContext()}/user/list`,
		rolesList					: `${baseUrl}/${ApiUtils.getContext()}/role/list`,
		usernameExist				: `${baseUrl}/${ApiUtils.getContext()}/user/exists`,
		userDelete					: `${baseUrl}/${ApiUtils.getContext()}/user`,
		userListRolesPermissions	: `${baseUrl}/${ApiUtils.getContext()}/allusers/authorities`,
		userRolesPermissions		: `${baseUrl}/${ApiUtils.getContext()}`,
		userSave					: `${baseUrl}/${ApiUtils.getContext()}/user/save`,
		userResetPassword			: `${baseUrl}/pub/user/resetPassword`,
		userChangePassword			: `${baseUrl}/${ApiUtils.getContext()}/user/changePassword`,

		sensorCategory 			: `${baseUrl}/${ApiUtils.getContext()}/sensorClass/findAllNotDeleted`,
		sensorCategorySave 		: `${baseUrl}/${ApiUtils.getContext()}/sensorClass/save`,
		sensorCategoryDelete 	: `${baseUrl}/${ApiUtils.getContext()}/sensorClass/delete`,

		getBlacklist: 		`${baseUrl}/${ApiUtils.getContext()}/settings/getBlacklist`,
		saveBlacklistItem: 		`${baseUrl}/${ApiUtils.getContext()}/settings/saveBlacklistItem`,
		removeBlacklistItemBySensorCode: 		`${baseUrl}/${ApiUtils.getContext()}/settings/removeFromBlacklistByCode`,

		sensorClassThresholdMapByCategory		: `${baseUrl}/${ApiUtils.getContext()}/threshold/sensorclass/map`,
		sensorCategoryThreshold			: `${baseUrl}/${ApiUtils.getContext()}/threshold/sensorclass/list/bySensorCategory`,
		sensorCategoryThresholdSave 	: `${baseUrl}/${ApiUtils.getContext()}/threshold/sensorclass/save`,
		sensorCategoryThresholdDelete	: `${baseUrl}/${ApiUtils.getContext()}/threshold/sensorclass/bySensorCategory`,

		streamNewsList			: `${baseStreamUrl}/news`,
		streamTweetConfig		: `${baseStreamUrl}/list-search-criteria/tweet`,
		streamRssConfig			: `${baseStreamUrl}/list-search-criteria/rss`,
		streamTweetConfigById	: `${baseStreamUrl}/search-criteria/tweet`,
		streamRssConfigById		: `${baseStreamUrl}/search-criteria/rss`,
		streamConfigSave		: `${baseStreamUrl}/save-criteria`,
		streamConfigDelete		: `${baseStreamUrl}/delete-search-criteria`,

		mosipMunicipalitiesMauZoneSave : 			`${baseUrl}/${ApiUtils.getContext()}/mosip/municipalitiesMauZoneSave`,
		mosipMunicipalities : 						`${baseUrl}/${ApiUtils.getContext()}/mosip/municipalities`,
		mosipBasins : 								`${baseUrl}/${ApiUtils.getContext()}/mosip/basins`,
		mosipRisks : 								`${baseUrl}/${ApiUtils.getContext()}/mosip/risks`,

		mosipDownloadA3 :  							`${baseUrl}/pub/mosip/downloadFile/pdf`,
		mosipLast12HAlertedMunicipalities : 		`${baseUrl}/${ApiUtils.getContext()}/mosip/warnedMunicipalities`,
		mosipAlertDocuments : 						`${baseUrl}/${ApiUtils.getContext()}/mosip/alertDocument/all`,
		mosipAlertDocumentsPaginated : 				`${baseUrl}/${ApiUtils.getContext()}/mosip/alertDocument/paginated`,

		mosipRisksByMunicipality : 					`${baseUrl}/${ApiUtils.getContext()}/mosip/risksByMunicipality`,
		mosipMunicipalitiesReplaceAll : 			`${baseUrl}/${ApiUtils.getContext()}/mosip/municipalities/replaceAll`,
		mosipBasinsReplaceAll : 					`${baseUrl}/${ApiUtils.getContext()}/mosip/basins/replaceAll`,
		mosipRiskThresholds : 						`${baseUrl}/${ApiUtils.getContext()}/mosip/riskThresholds`,
		mosipRiskThresholdsReplaceAll : 			`${baseUrl}/${ApiUtils.getContext()}/mosip/riskThresholds/replaceAll`,
		mosipRiskThresholdSave : 					`${baseUrl}/${ApiUtils.getContext()}/mosip/riskThresholds/save`,
		mosipExecutions : 							`${baseUrl}/${ApiUtils.getContext()}/mosip/mosipExecutions`,
		mosipExecutionsPaginated : 					`${baseUrl}/${ApiUtils.getContext()}/mosip/mosipExecutions/paginated`,
		mosipExceededThresholdsByMosipExecution : 	`${baseUrl}/${ApiUtils.getContext()}/mosip/exceededThresholds/byRunId`,
		mosipExceededThresholds12HrAll : 			`${baseUrl}/${ApiUtils.getContext()}/mosip/exceededThresholds12Hr`,
		exceededThresholdsLastRun : 				`${baseUrl}/${ApiUtils.getContext()}/mosip/exceededThresholdsLastRun`,
		mosipAlertedMunicipalitiesByProtocol : 		`${baseUrl}/${ApiUtils.getContext()}/mosip/warnedMunicipalities`,
		mosipLast12HExceededThresholds :  			`${baseUrl}/${ApiUtils.getContext()}/mosip/exceededThresholds/byMunicipality`,
		mosipBuildA3 :  							`${baseUrl}/${ApiUtils.getContext()}/mosip/saveMunicipalitiesWarning`,
		mosipTestExecution :  						`${baseUrl}/${ApiUtils.getContext()}/mosip/test/mosipExecution`,
		mosipExecutionReport :						`${baseUrl}/${ApiUtils.getContext()}/mosip/mosipExecutions/report`,
		mosipExecutionFullReport :					`${baseUrl}/${ApiUtils.getContext()}/mosip/mosipExecutions/full_report`,
		getLastMAUstate :							`${baseUrl}/${ApiUtils.getContext()}/mosip/mau/last`,

		rainStatsGetCfByStationCodeAndDistribution :  	`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/cf`,
		rainStatsGetCppByStationCodeAndDistribution :  	`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/cpp`,
		rainStatsGumbelGetCf :  						`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/cf/all/GUMBEL`,
		rainStatsTCEVGetCf :  							`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/cf/all/TCEV`,

		zonalSPI :  									`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/getZonalSPI`,
		zonalAndLocalSPI :  							`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/getZonalAndLocalSPI`,
		zonalStats :									`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/getZonalStats`,
		lastMonthCumulatedRain :  						`${baseUrl}/${ApiUtils.getContext()}/rainStatsAnalisys/getLastMonthRainValues`,

		temperatureStats :  `${baseUrl}/${ApiUtils.getContext()}/temperatureStats`,

		maxRainValuesByStationCode : 	`${baseUrl}/${ApiUtils.getContext()}/maxvalue/rain/find`,
		computeRainValues : 			`${baseUrl}/${ApiUtils.getContext()}/maxvalue/rain/computeNewMax`,
		saveRainValues : 				`${baseUrl}/${ApiUtils.getContext()}/maxvalue/rain/saveNewMax`,

		telegramInfo: 		`${baseUrl}/${ApiUtils.getContext()}/telegram/userInfo`,
		sendTestMessage: 	`${baseUrl}/${ApiUtils.getContext()}/telegram/sendTestMessage`,

		workItemsState: 	`${baseUrl}/${ApiUtils.getContext()}/workitems/state`,
		workItemsList: 		`${baseUrl}/${ApiUtils.getContext()}/workitems/list`,
		addWorkItem: 		`${baseUrl}/${ApiUtils.getContext()}/workitems/add`,
		updateWorkItem: 	`${baseUrl}/${ApiUtils.getContext()}/workitems/update`,
		deleteWorkItem: 	`${baseUrl}/${ApiUtils.getContext()}/workitems/delete`,

		idw : `${baseUrl}/pub/idw`,
		idwMida : `${baseUrl}/pub/idw/mida`,
		idwMidaLight : `${baseUrl}/pub/mida/light/tiff`,
		tif : `${baseUrl}/pub/tif`,
		zonalTif : `${baseUrl}/pub/zonalTif`,

		fireRiskConfigList:	`${baseUrl}/${ApiUtils.getContext()}/firerisk/list`,
		fireRiskSaveAll:	`${baseUrl}/${ApiUtils.getContext()}/firerisk/batchsave`,
		fireRiskTiffbyIntervalAndCategory: `${baseUrl}/${ApiUtils.getContext()}/firerisk/tiff/byIntervalAndCategory`,
		fireRiskTodayMaps: `${baseUrl}/${ApiUtils.getContext()}/firerisk/today`,
		fireRiskCampaign: `${baseUrl}/pub/fireRiskCampaign`,
		fireRiskCampaignSave: `${baseUrl}/priv/fireRiskCampaign/save`,
		getPropagationData: `${baseUrl}/priv/propagationData`,

		cumulativeRainThresholdsList: 	`${baseUrl}/${ApiUtils.getContext()}/raincumulative/thresholds/list`, // TODO: TO DELETE
		cumulativeRainThresholdsMap: 	`${baseUrl}/${ApiUtils.getContext()}/raincumulative/thresholds/map`,
		cumulativeRainThresholdsUpdate:	`${baseUrl}/${ApiUtils.getContext()}/raincumulative/thresholds/update`,

		
		returnTimeRainThresholds: 		`${baseUrl}/${ApiUtils.getContext()}/returntime/thresholds`, 
		returnTimeRainThresholdsUpdate: `${baseUrl}/${ApiUtils.getContext()}/returntime/thresholds/update`,

		geojsonTemplates : 				`${baseUrl}/${ApiUtils.getContext()}/settings/geojsonTemplates`,
		geojsonTemplateByDocumentType:  `${baseUrl}/${ApiUtils.getContext()}/settings/geojsonTemplateByName`,
		updateGeojsonTemplate: 			`${baseUrl}/${ApiUtils.getContext()}/settings/updateGeojsonTemplate`,
		updateGeojsonTemplates: 		`${baseUrl}/${ApiUtils.getContext()}/settings/updateGeojsonTemplates`,

		forecastLink: `${baseUrl}/pub/settings/forecastLink`,
		forecastLinkSave: `${baseUrl}/priv/settings/forecastLink/save`,

		dailyStats: 					`${baseUrl}/${ApiUtils.getContext()}/dailyStatsMap`,
		dailyStatsCalculateByInterval:	`${baseUrl}/${ApiUtils.getContext()}/dailyStats/calculateByInterval`,
		dailyStatsCalculateByDate:		`${baseUrl}/${ApiUtils.getContext()}/dailyStats/calculateByDate`,
		interpolateDailyStatsByDate: 	`${baseUrl}/${ApiUtils.getContext()}/dailyStats/calculateByIdw`,
		updateAllDailyStats: 			`${baseUrl}/${ApiUtils.getContext()}/dailyStats/updateAll`,

		radarLayers: `${radarBaseUrl}/radar/radarLayers`,
		radarByInterval: `${radarBaseUrl}/radar/radarDataByInterval`,

		meteosatLayers: `${meteosatBaseUrl}/meteosat/meteosatLayers`,
		meteosatByInterval: `${meteosatBaseUrl}/meteosat/meteosatDataByInterval`,
		
		wrfLayers: `${wrfBaseUrl}/wrf/wrfLayers`,
		wrfDataByLayer: `${wrfBaseUrl}/wrf/wrfData`,
		wrfImageByPath: `${wrfBaseUrl}/wrf/wrfImage`,
		wrfCombinedData : `${wrfBaseUrl}/wrf/wrfCombinedData`,

		hecHmsListByInterval: `${hecHmsBaseUrl}/list`,
		hecHmsReExecute: `${hecHmsBaseUrl}/reexecute`,
		hecHmsExecute: `${hecHmsBaseUrl}/executeByInterval`,

		dataRequests: `${baseUrl}/${ApiUtils.getContext()}/dataRequest/all/`,
		saveDataRequest: `${baseUrl}/${ApiUtils.getContext()}/dataRequest/save`,

		mailingList: `${baseUrl}/${ApiUtils.getContext()}/mailingList/all/`,
		saveMailingList: `${baseUrl}/${ApiUtils.getContext()}/mailingList/save`,
		deleteFromMailingList: `${baseUrl}/${ApiUtils.getContext()}/mailingList/delete`,

		nowcastingData: `${baseUrl}/pub/nowcasting`,

		protocolloSettings : `${baseUrl}/${ApiUtils.getContext()}/protocolloSettings`,
		protocolloStore : (type)=>`${baseUrl}/${ApiUtils.getContext()}/protocolloSettings/save/${type}`,
		protocolloStoreTest : (type)=>`${baseUrl}/${ApiUtils.getContext()}/bulletin/testpublish`,
		protocolloGetTest : (protocol)=>`${baseUrl}/pub/bulletin/testget/${protocol}`,

		addWatermark : `${baseUrl}/pub/watermark`,

		staticPage : {
			getAll : `${baseUrl}/${ApiUtils.getContext()}/staticpage/getAll`,
			save : `${baseUrl}/${ApiUtils.getContext()}/staticpage/save`,
			getByPageCode : (pageCode) => `${baseUrl}/${ApiUtils.getContext()}/staticpage/getByCode/${pageCode}`
		}, 

		forceIngestion : `${baseUrl}/${ApiUtils.getContext()}/forceIngestion`



    }
};









