import {properties} from '#/properties.js';

export default class WatermarkClient {

	static download = (imageName, base64) => {
		let url = new URL(properties.url.addWatermark);

        let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
            method: "POST",
			body: JSON.stringify({file:base64})
        };
        fetch(url, fetch_options).then(r => r.blob()).then(data => {
                let a = document.createElement("a");
                a.href = window.URL.createObjectURL(data);
                a.download = imageName;
                a.click();
            }
        );
    }



}
