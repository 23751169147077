import React, { useState } from "react";
import Select from "react-select";
import { Button } from "@material-ui/core";

const YearMonthRangePicker = ({ onRangeChange }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => ({
    value: currentYear - i,
    label: (currentYear - i).toString(),
  }));

  const months = [
    { value: 1, label: "Gennaio" },
    { value: 2, label: "Febbraio" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Aprile" },
    { value: 5, label: "Maggio" },
    { value: 6, label: "Giugno" },
    { value: 7, label: "Luglio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Settembre" },
    { value: 10, label: "Ottobre" },
    { value: 11, label: "Novembre" },
    { value: 12, label: "Dicembre" },
  ];

  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);

  const handleStartYearChange = (selectedOption) => {
    setStartYear(selectedOption);
    if (endYear && selectedOption.value > endYear.value) {
      setEndYear(null);
      setEndMonth(null);
    }

  };

  const handleEndYearChange = (selectedOption) => {
    setEndYear(selectedOption);
    if (selectedOption.value < startYear?.value) {
      setEndYear(null);
      setEndMonth(null);
    }

  };

  const handleStartMonthChange = (selectedOption) => {
    setStartMonth(selectedOption);
    if (
      endYear === startYear &&
      selectedOption.value > endMonth?.value
    ) {
      setEndMonth(null);
    }

  };

  const handleEndMonthChange = (selectedOption) => {
    setEndMonth(selectedOption);
    if (
      endYear === startYear &&
      selectedOption.value < startMonth.value
    ) {
      setEndMonth(null);
    }

  };

  const filteredEndMonths = () =>{

	return     startYear?.value === endYear?.value
	? months.filter((month) => month.value >= startMonth?.value)
	: months;
  }


  const filteredEndYears = () => years.filter(
    (year) => !startYear || year.value >= startYear.value
  );

  const handleConfirm = () => {
    onRangeChange({
      startYear: startYear?.value || null,
      startMonth: startMonth?.value || null,
      endYear: endYear?.value || null,
      endMonth: endMonth?.value || null,
    });
  };

  const handleReset = () => {
    setStartYear(null);
    setEndYear(null);
    setStartMonth(null);
    setEndMonth(null);
  };

  return (
    <div key={JSON.stringify({startMonth, startYear,endMonth, endYear})}>
      <div>
        <label>Anno Inizio</label>
        <Select
          options={years}
          value={startYear}
          onChange={handleStartYearChange}
          placeholder="Seleziona anno di inizio"
        />
      </div>
      <div>
        <label>Mese Inizio</label>
        <Select
          options={months}
          value={startMonth}
          onChange={handleStartMonthChange}
          placeholder="Seleziona mese di inizio"
          isDisabled={!startYear}
        />
      </div>
      <div>
        <label>Anno Fine</label>
        <Select
          options={filteredEndYears()}
          value={endYear}
          onChange={handleEndYearChange}
          placeholder="Seleziona anno di fine"
          isDisabled={!startYear}
        />
      </div>
      <div>
        <label>Mese Fine</label>
        <Select
          options={filteredEndMonths()}
          value={endMonth}
          onChange={handleEndMonthChange}
          placeholder="Seleziona mese di fine"
          isDisabled={!endYear}
        />
      </div>
      <div className="mt-3">
        <Button className="mr-2" variant="contained" color="primary" disabled={!(!!startYear && !!startMonth && !!endYear && !!endMonth) } onClick={handleConfirm}>Conferma</Button>
        <Button variant="contained"  color="secondary" onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
};

export default YearMonthRangePicker;
