import React from 'react'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import {MeasurementAggregation, MeasurementAggregationDisplayValues} from "#/lib/MeasurementAggregation";
import {COMBINED_TO_STANDARD, MEASUREMENT_CATEGORY} from "#/lib/MeasurementCategory";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import CompareSensorComponent from "#/commons/data/components/CompareSensorComponent";
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import Tooltip from "@material-ui/core/Tooltip";
import {MdDateRange} from "react-icons/md";
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';
import moment from 'moment';
import WarningIcon from "@material-ui/icons/Warning";

let defaultAggregationInterval = {
	label: <>&nbsp;</>,
	value: 0
}

let notAggregableMeasurements = [
	/*MEASUREMENT_CATEGORY.P,*/
	MEASUREMENT_CATEGORY.DS,
	MEASUREMENT_CATEGORY.DV,
	MEASUREMENT_CATEGORY.DR,
	MEASUREMENT_CATEGORY.WS,
	MEASUREMENT_CATEGORY.WR,
	MEASUREMENT_CATEGORY.WR,
	MEASUREMENT_CATEGORY.BA,
	MEASUREMENT_CATEGORY.R,
	MEASUREMENT_CATEGORY.RETE

];

export default class LightMeasurementsHeader extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			showModal : false,
			target : {measure : this.props.selectedMeasure, station : this.props.selectedStation}
		}
	}

	isOptionDisabled(key){
		let {start, end} = this.props.interval;
		let duration = end.add(1,'seconds').diff(start, 'days');
		let monthDuration = end.add(1,'seconds').diff(start, 'months');
		let yearDuration = end.add(1,'seconds').diff(start, 'years');
		console.log('duration in months',monthDuration)
		switch (key) {
			case 'HOUR': return monthDuration > 0;
			case 'HOUR_2': return monthDuration > 0;
			case 'HOUR_3':	return monthDuration > 0;
			case 'HOUR_6': return duration < 2;
			case 'HOUR_12': return duration < 5;
			case 'DAY':	return duration < 7;
			case 'MONTH': return monthDuration < 1;
			case 'YEAR': return yearDuration < 1;
			default : return false;
		}
	}

	getAggregationIntervalSelect() {
		let options = [];
		options.push(defaultAggregationInterval)

		if (!notAggregableMeasurements.includes(this.props.selectedMeasure.value)) {
			Object.keys(MeasurementAggregation).forEach(key => {
				options.push({
					label: MeasurementAggregationDisplayValues[key],
					value: MeasurementAggregation[key]
				});

			});
		}

		return 	<InputSelectComponent
			className={""}
			placeholder={"Seleziona Intervallo"}
			onChange={val => this.props.onSelectAggregationInterval(options.find(opt => opt.value === val))}
			optionList={options}
			value={!!this.props.aggregationInterval ? this.props.aggregationInterval.value : null}
			isDisabled={this.props.aggregationIntervalDisabled}
		/>

	}

	showCompareSensorModal() {
		this.setState({
			showModal: true
		})
	}
	onCloseModal() {
		this.setState({
			showModal: false
		})
	}

	updateCompareList(data) {
		this.setState({
			showModal: false
		}, () => this.props.updateCompareList(data))
	}

	onPickRange(dates){
		let interval = null
		if(dates && dates[0] && dates[1]){
			interval = {}
			interval.start = moment(dates[0].getTime()).startOf('day')
			interval.end = moment(dates[1].getTime()).endOf('day')
		}
		this.props.resetInterval(interval)
	}

	render() {
		let isBackoffice = window.location.href.includes("backoffice");
		return (
			<>
				<div className="">

					<div >
						<h3>Intervallo selezionato:&nbsp;&nbsp;</h3>
						<DateRangePicker
							locale={"it-IT"}
							onChange={(dates) => this.onPickRange(dates)}
							value={[this.props.interval && this.props.interval.start && new Date(this.props.interval.start.valueOf()), this.props.interval && this.props.interval.end && new Date(this.props.interval.end.valueOf())]}
							maxDate={new Date()}
							format={"dd/MM/yyyy"}
							rangeDivider='&nbsp;&nbsp;&nbsp;'
						/>
					</div>

					<div >
						<Button
							size="small"
							variant="contained"
							color="default"
							startIcon={<AddIcon />}
							onClick={(e) => this.showCompareSensorModal()}
						>
							Aggiungi misure
						</Button>
						<Tooltip title={"Elimina tutte"}>
							<IconButton color="primary" aria-label="upload picture" component="span"
										onClick={(e) => this.updateCompareList([])}>
								<DeleteIcon/>
							</IconButton>
						</Tooltip>
					</div>


					{![...Object.keys(COMBINED_TO_STANDARD), ...Object.values(COMBINED_TO_STANDARD)].includes(this.props.selectedMeasure.value) &&


					<>{isBackoffice ?
						<div className="mt-4 w-50">
							{this.getAggregationIntervalSelect()}
						</div>
						:
						<div className="d-inline-flex ">
							<div className="mt-4 w-50">
								{this.getAggregationIntervalSelect()}
							</div>
							<div className="ml-2 mt-4 w-50 my-auto">
							<span>
								<WarningIcon color="primary" /> L'intervallo selezionato verrà applicato a tutti i sensori inclusi nella richiesta. Se si
							desidera ottenere i dati aggregati su diversi intervalli temporali, effettuare richieste
							distinte.
							</span>

							</div>
						</div>
					}</>

					}
				</div>



				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "90vw"
						}}
					>	
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon/>
							</IconButton>
						</div>

						<CompareSensorComponent
							stationOptions = {this.props.stationOptions}
							stations={this.props.stations}
							stationsGeoJSON={this.props.stationsGeoJSON}
							target={this.state.target}
							compareList={this.props.compareList}
							interval={this.props.interval}
							confirm={(data) => { this.updateCompareList(data)}}
							allowAddAll={true}
							maxCompareListLenght = {1000}
						/>
					</div>

				</Modal>
			</>

		)

	}
}
