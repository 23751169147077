import React from 'react';
import ComunicatoItem from '#/commons/comunicato/ComunicatoItem';

class HotNewsSection extends React.Component {

	constructor(props){
		super(props)
		this.state = {
		};
	}

	componentDidMount(){
	}

	render(){

		let list = this.props.data.map((x,idx)=>{
			if(idx===0){
				return (<div className="carousel-item active" key={idx}>
					<div className="d-block w-100">
						<ComunicatoItem {...x}  key={idx} editable={false} />
					</div>
				</div>)
			}
			else{
				return (<div className="carousel-item " key={idx}>
							<div className="d-block w-100">
								<ComunicatoItem {...x}  key={idx} editable={false} />
							</div>				
						</div>)
			}			
		})
		let index = this.props.data.map((x,idx)=>{
			if(idx===0){
				return <li data-target="#carouselExampleIndicators" data-slide-to={idx} key={idx} className="active"></li>
			}
			else{
				return <li data-target="#carouselExampleIndicators" data-slide-to={idx} key={idx}></li>
			}
		})
		return (
			<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
			<ol className="carousel-indicators">
				{index}
			</ol>
			<div className="carousel-inner">
				{list}
			</div>
			<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
				<span className="carousel-control-prev-icon" aria-hidden="true"></span>
				<span className="sr-only">Previous</span>
			</a>
			<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
				<span className="carousel-control-next-icon" aria-hidden="true"></span>
				<span className="sr-only">Next</span>
			</a>
		</div>
		);
	}
}

export default HotNewsSection;

