import {properties} from '#/properties.js';

import parse_georaster from "georaster"


const test = {
	"type": "FeatureCollection",
	"name": "points",
	"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
	"features": [
		{ "type": "Feature", "properties": { "id": 1, "value": 10.0 }, "geometry": { "type": "Point", "coordinates": [15.93016099529736, 39.840452515368085] } },
		{ "type": "Feature", "properties": { "id": 2, "value": 12.0 }, "geometry": { "type": "Point", "coordinates": [15.98482234579873, 39.629615877719942] } },
		{ "type": "Feature", "properties": { "id": 3, "value": 11.0 }, "geometry": { "type": "Point", "coordinates": [16.148806397302842, 39.252192267115234] } },
		{ "type": "Feature", "properties": { "id": 4, "value": 13.0 }, "geometry": { "type": "Point", "coordinates": [16.315393370259404, 39.410970475714457] } },
		{ "type": "Feature", "properties": { "id": 5, "value": 12.0 }, "geometry": { "type": "Point", "coordinates": [16.302378762997172, 39.694688914031097] } },
		{ "type": "Feature", "properties": { "id": 6, "value": 15.0 }, "geometry": { "type": "Point", "coordinates": [16.471568657406177, 39.939363530561039] } },
		{ "type": "Feature", "properties": { "id": 7, "value": 16.0 }, "geometry": { "type": "Point", "coordinates": [16.557465065336903, 39.49166104074029] } },
		{ "type": "Feature", "properties": { "id": 8, "value": 12.0 }, "geometry": { "type": "Point", "coordinates": [16.547053379527117, 39.254795188567684] } },
		{ "type": "Feature", "properties": { "id": 9, "value": 10.0 }, "geometry": { "type": "Point", "coordinates": [16.867212718178006, 39.28082440309214] } },
		{ "type": "Feature", "properties": { "id": 10, "value": 9.0 }, "geometry": { "type": "Point", "coordinates": [17.085858120183488, 39.377132496832651] } },
		{ "type": "Feature", "properties": { "id": 11, "value": 34.0 }, "geometry": { "type": "Point", "coordinates": [16.258129098305584, 38.947650457179023] } },
		{ "type": "Feature", "properties": { "id": 12, "value": 36.0 }, "geometry": { "type": "Point", "coordinates": [16.468965735953731, 38.929430007011902] } },
		{ "type": "Feature", "properties": { "id": 13, "value": 37.0 }, "geometry": { "type": "Point", "coordinates": [16.841183503653543, 38.986694278965714] } },
		{ "type": "Feature", "properties": { "id": 14, "value": 35.0 }, "geometry": { "type": "Point", "coordinates": [16.312790448806957, 38.645711568695262] } },
		{ "type": "Feature", "properties": { "id": 15, "value": 37.0 }, "geometry": { "type": "Point", "coordinates": [16.075924596634348, 38.578035610931657] } },
		{ "type": "Feature", "properties": { "id": 16, "value": 39.0 }, "geometry": { "type": "Point", "coordinates": [15.878102566248435, 38.229244136303862] } },
		{ "type": "Feature", "properties": { "id": 17, "value": 34.0 }, "geometry": { "type": "Point", "coordinates": [16.211276512161554, 38.406242795070206] } },
		{ "type": "Feature", "properties": { "id": 18, "value": 32.0 }, "geometry": { "type": "Point", "coordinates": [15.997836953060961, 38.002789969941041] } }
	]
}



export default class IdwClient {
	static performIdwMidaFail = (okCallback, errCallback, geojson, codeFieldName = 'value', maxAgeInMillis = 60*60*1000) => {
		return errCallback();
	}
	static performIdwMidaLight = (okCallback, errCallback, sensorCategory) => {
		let url = new URL(properties.url.idwMidaLight + "/" + sensorCategory);
		fetch(url)
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				parse_georaster(arrayBuffer).then(georaster => {
					okCallback(georaster)
				})
			})
			.catch((error) => {
				return errCallback(error);
			});
	};
	static performIdwMida = (okCallback, errCallback, geojson, codeFieldName = 'value', maxAgeInMillis = 60*60*1000) => {
		let url = new URL(properties.url.idwMida);
		if (!geojson) {
			errCallback();
		}

		let requestBody = { geojson, codeFieldName, maxAgeInMillis }

		fetch(url, {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(requestBody)
		})
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				parse_georaster(arrayBuffer).then(georaster => {
					okCallback(georaster)
				})
			})
			.catch((error) => {
				return errCallback(error);
			});
	};
	static performIdw = (okCallback, errCallback, geojson, codeFieldName) => {
		let url = new URL(properties.url.idw);
		if (!geojson) {
			geojson = test
		}
		if (!codeFieldName) {
			codeFieldName = "value"
		}


		let requestBody = { geojson, codeFieldName }

		fetch(url, {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(requestBody)
		})
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				parse_georaster(arrayBuffer).then(georaster => {
					okCallback(georaster)
				})
			})
			.catch((error) => {
				return errCallback(error);
			});
	};


	static sendTifsAndGetFinalGeoJson = (okCallback, errCallback, data) => {
		let url = new URL(properties.url.zonalTif);
		fetch(url, {
			/*mode: 'no-cors',*/
			method: "POST",
			body: data,
			processData: false,
			contentType: false
		})
			.then(response => {
				if(response.status === 200){
					return response.json()
				}
			})
			.then(json => okCallback(json)
			)
			.catch((error) => {
				return errCallback(error);
			});

	};
	static sendTifsAndGetFinalRaster = (okCallback, errCallback, data) => {
		let url = new URL(properties.url.tif);
		fetch(url, {
			/*mode: 'no-cors',*/
			method: "POST",
			body: data,
			processData: false,
			contentType: false
		})
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				okCallback(arrayBuffer)
			})
			.catch((error) => {
				return errCallback(error);
			});

	};



}
