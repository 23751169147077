import React from 'react';
import StationClient from '#/lib/StationClient';
import MassiveDownloadSection from "#/backoffice/pages/MassiveDownloadSection";


export default class MassiveDownloadPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stationsGeoJSON : null,
			interval : null,
			selectedMeasure : null,
			selectedStation : null
		}
	}

	componentDidMount(){
		StationClient.getStationsGeojson(
			(data)=>{
				this.setState({
					stationsGeoJSON : data
				})
			},
			(err) => {console.log(err)}

		)

	}
	render() {
		if (!!this.state.stationsGeoJSON){
			return <div className="generic-page container-fluid data-page">
				<span>Seleziona uno o più dei criteri sottostanti</span>
				<MassiveDownloadSection
					stationsGeoJSON = {this.state.stationsGeoJSON}
					selectedZone = {this.state.selectedZone}
					selectedStation = {this.state.selectedStation}
					selectedMeasure = {this.state.selectedMeasure}
				></MassiveDownloadSection>
			</div>
		} else return <div className="generic-page container-fluid data-page"></div>

	}
}
