import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class DailyStatsClient {


	static getDailyStats(okCallback, errCallback, params){
		let url = properties.url.dailyStats;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	

	static calculateDailyStatsByInterval(okCallback, errCallback, params){
		let url = properties.url.dailyStatsCalculateByInterval;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static calculateDailyStatsByDate(okCallback, errCallback, params){
		let url = properties.url.dailyStatsCalculateByDate;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static interpolateDailyStatsByDate(okCallback, errCallback, params){
		let url = properties.url.interpolateDailyStatsByDate;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static updateAllDailyStats(okCallback, errCallback, category, params){
		let url = properties.url.updateAllDailyStats+"/"+category;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

}
