import MonitoringPluvsThresholdClient from "#/lib/MonitoringPluvsThresholdClient";
import RainStatsClient from "#/lib/RainStatsClient";
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import SensorClient from "#/lib/SensorClient";
import _ from "lodash";
import SensorCategoryClient from "#/lib/SensorCategoryClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
	COMBINED_MEASUREMENT_CATEGORY,
	EXCLUDE_FROM_INTERPOLATION, MEASUREMENT_CATEGORY_SORT,
	MEASUREMENT_LABELS, MIDA_CATEGORIES_OPTIONS,
	MIDA_MEASUREMENT_LABELS,
	STANDARD_TO_COMBINED, TO_INTERPOLATE
} from "#/lib/MeasurementCategory";
import IdwClient from "#/lib/IdwClient";
import moment from "moment";
import SettingsClient from "#/lib/SettingsClient";

const ReactSwal = withReactContent(Swal);

export const RAIN_LAYER_OPTIONS = [
	{ value: "P_ESTIMATED_1H", label: "Pr. 1H stimata (15min)", legendLabel: "Pr. 1H</br>stimata (15min) [mm/H]" },
	{ value: "P_DAILY", label: "Pioggia giornaliera", legendLabel: "Pioggia</br>giornaliera [mm]" },
	{ value: "P1H", label: "Pioggia a 1 ora", legendLabel: "Pioggia</br>a 1 ora [mm]" },
	{ value: "P3H", label: "Pioggia a 3 ore", legendLabel: "Pioggia</br>a 3 ore [mm]" },
	{ value: "P6H", label: "Pioggia a 6 ore", legendLabel: "Pioggia</br>a 6 ore [mm]" },
	{ value: "P12H", label: "Pioggia a 12 ore", legendLabel: "Pioggia</br>a 12 ore [mm]" },
	{ value: "P24H", label: "Pioggia a 24 ore", legendLabel: "Pioggia</br>a 24 ore [mm]" }
]
export const LAYER_OPTIONS =  [
	...RAIN_LAYER_OPTIONS,
	{ value: "NONE", label: "Nessuna interpolazione", legendLabel: "" }
]
export const THRESHOLD_LABELS = [
	"Nessuna allerta",
	"Livello 1",
	"Livello 2",
	"Livello 3"
]

export const THRESHOLD_LEVELS = ['','yellow','orange','red'];

export const DEFAULT_MIDA_STATE = {
	mapKey: 1,
	tableKey: 1,
	legendKey:1,
	pointsKey: 1,
	tableThemes : 0,
	loadingTab: true,
	showStations : true,
	showLegend : true,

	interpolatedData: {},
	cfGumbelParametersMap : {},
	cfTCEVParametersMap : {},
	rainReturnTimeEvents : [],
	rainThresholdsEvents : [],
	rasterEnabled: true,

	outflowScales : {},

	stationSensorThresholds : {},

	selectedFeature : null,
	showInfoModal : false,
	showThresholdModal: false,
	showReturnTimeThresholdModal: false,

	geoDataByCategory: {},
	selectedGeoData: {},
	eventsByCategory: {},

	rainMode : 'TH',
	rainThresholds: {},
	lastRequestTimestamp: 0,
	interpolatedDataCache: {},

	selectedCategoryOption: 'P_TH',
	selectedCategory: 'P',
	currentLayer: 'NONE',

	optionList: []
}

export const getRainThresholdsPromise = () => new Promise((resolve, reject) => {
		MonitoringPluvsThresholdClient.getThresholdsGroupedBySensorCode(
			rainThresholds => {
				resolve(rainThresholds)
			},
			() => {
				console.log('Problems retrieving rain thresholds!')
			}
		)
	}
);

export const getReturnTimeThresholdsPromise = () => new Promise((resolve, reject) => {
	MonitoringPluvsThresholdClient.getThresholdsReturnTime(
		returnTimeThresholds => {
			resolve(returnTimeThresholds)
		},
		() => {
			console.log('Problems retrieving returnTimeThresholds!')
		}
	)
}
);

export const getCfGumbelParametersMapPromise = () => new Promise((resolve, reject) => {
		RainStatsClient.getAllCfGumbel(
			cfGumbelParametersMap => {
				resolve(cfGumbelParametersMap)
			},
			() => {
				console.log('Problems retrieving CF parameters!')
			}
		)
	}
);

export const getCfTCEVParametersMapPromise = () => new Promise((resolve, reject) => {
		RainStatsClient.getAllCfTCEV(
			(cfTCEVParametersMap) => {
				resolve(cfTCEVParametersMap)
			},
			() => {
				console.log('Problems retrieving CF parameters!')
			}
		)
	}
);

export const getGeoDataPromise = selectedTimestamp => new Promise((resolve, reject) => {
	MeasurementsDataClient.getMidaGeoJson(
		(geoData)=>{
			if (geoData === 'NOT_MODIFIED' || !!geoData.features){
				resolve(geoData)
			} else {
				ReactSwal.hideLoading();
				reject();
			}
		},
		()=>{
			console.log('Problems retrieving geoJson data!')
		},
		selectedTimestamp
	)
})

export const getOutflowScalesPromise = () =>  new Promise((resolve, reject) => {
	SensorClient.getAllOutflowsScalesMapBySensorCode(
		(outflowScales) => {
			if (!_.isEmpty(outflowScales)){
				resolve(outflowScales)
			} else {
				ReactSwal.fire({
					title: 'Nessuna scala di deflusso trovata',
					text : 'Non è possibile calcolare le portate.',
					icon : 'error'
				});
			}
		},
		(msg) => {
			console.log('Error retrieving outflow scales');
		}
	)
});


export const getSensorThresholdsPromise = () => new Promise((resolve, reject) => {
		SensorClient.getSensorThresholds(
			(stationSensorThresholds) => {
				resolve(stationSensorThresholds)
			},
			() => {
				console.log('Problems retrieving station-sensor thresholds!')
			}
		)
	}
);

export const getSensorClassThresholdsPromise = () =>  new Promise((resolve, reject) => {
		SensorCategoryClient.getSensorClassThresholds(
			(sensorClassThresholds) => {
				resolve(sensorClassThresholds)
			},
			() => {
				console.log('Problems retrieving station-sensor thresholds!')
			}
		)
	}
);
export const getPromises = () =>
	[
		getCfGumbelParametersMapPromise(),
		getCfTCEVParametersMapPromise(),
		getGeoDataPromise(),
		getRainThresholdsPromise(),
		getReturnTimeThresholdsPromise(),
		getOutflowScalesPromise(),
		getSensorThresholdsPromise(),
		getSensorClassThresholdsPromise(),
		getBlacklistPromise()

	];
const getBlacklistPromise = () => new Promise((resolve, reject) => {
	SettingsClient.getBlacklist(
		blacklist => {
			resolve(blacklist)
		},
		() => {
			console.log('Error while retrieving sensor blacklist');
		}
	)

})
export const performIdwPromise = (layerCategory, interpolatedDataCache, geoData) => new Promise((resolve, reject) => {

	console.log('layerCategory', layerCategory)
	if (!TO_INTERPOLATE.includes(layerCategory)) {
		resolve({interpolatedData: null, showLegend: false});
		return;
	}
	if (layerCategory in interpolatedDataCache){
		resolve({interpolatedData: interpolatedDataCache[layerCategory], showLegend: true});
		return;
	} else {
		console.log(">>>>>>>>>>>>>>>>> Performing IDW remotely");
		IdwClient.performIdwMida(
			interpolatedData => {
				resolve({
					interpolatedData,
					interpolatedDataCache: {...interpolatedDataCache, [layerCategory]: interpolatedData},
					showLegend: true
				})
			},
			(error) => {
				console.log(">>>>>>>>>>>>>>>>> Problems performing IDW -> Trying to perform IDW locally ");
				ReactSwal.fire('Si è verificato un errore nell\' interpolazione dei dati','','error').then(() =>
					resolve({currentLayer: 'NONE', showLegend: true}))
			},
			geoData,
			layerCategory
		)
	}
})

export const calculateFlow = (value, scales) => {
	if (!!scales && value !== 'nd'){
		const scale = scales.find(scale => value >= scale.thresholdStart && value < scale.thresholdEnd);
		if (!!scale){
			const {a, b, c, e} = scale;
			return Math.round(((a*Math.pow(value + e, b) + c) + Number.EPSILON) * 100) / 100;
		}
	}
	return 'nd'
}
export const buildGeoDataByCategory = (geoData, outflowScales) => Object.keys(MEASUREMENT_LABELS).reduce((geoDataByCategory, category) => {
	let categoryGeoData = _.cloneDeep(geoData);
	categoryGeoData.features = categoryGeoData.features.filter(feature => category in feature.properties.lastMeasurementTimestamps);
	categoryGeoData.features = categoryGeoData.features.map(feature => {
		const {properties, properties: {station: {code, name}, sensors, lastMeasurementTimestamps, broken}} = feature;
		feature.properties = {
			code,
			name,
			sensorCode: sensors[category],
			timestamp: (!!broken && !!broken[category]) ? 9999 : lastMeasurementTimestamps[category],
			...((() => {
				switch(category){
					case 'P':
						return RAIN_LAYER_OPTIONS.reduce((obj, {value}) => {
							obj[value] = properties[value]!== -9999 ? properties[value] : "nd";
							return obj
						},{});
					case 'I':
						return {
							value: properties[category],
							flow: calculateFlow(properties[category], outflowScales[sensors[category]])
						};
					case 'DV':
					case 'DS':
					case 'DR':
						return {
							[category]: properties[category],
							[category.replace('D','V')]: properties[category.replace('D','V')]
						}
					default:
						return {
							value: properties[category]
						};
				}})())
		}
		return feature;
	})
	delete categoryGeoData.requestTimestamp;
	geoDataByCategory[STANDARD_TO_COMBINED[category] || category] = categoryGeoData;
	return geoDataByCategory;
},{});

export const checkForReturnTimeAnomalies = (newGeoData, cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds) => {
	let badges = [];
	let rainReturnTimeEvents = [];
	newGeoData.features.forEach(f => {
		let {properties : p} = f;
		const stationCode = p.code;
		const stationName = p.name;
		let rainEvent = {stationName : stationName, _children : []};
		['P_ESTIMATED_1H',...Object.keys(MonitoringPluvsThresholdClient.rainLayerMap)].forEach(timescale =>{
				const rainValue = p[timescale];
				if(rainValue!=="nd"){
					const num = timescale === 'P_ESTIMATED_1H' ? [1] : timescale.match(/\d+/g).map(Number);
					const gumbelReturnTime = MonitoringPluvsThresholdClient.getReturnTime(stationCode,rainValue,num,'GUMBEL',cfGumbelParametersMap,cfTCEVParametersMap);
					const tcevReturnTime = MonitoringPluvsThresholdClient.getReturnTime(stationCode,rainValue,num,'TCEV',cfGumbelParametersMap,cfTCEVParametersMap);

					const gumbelSeverity = MonitoringPluvsThresholdClient.getColorByReturnTime(gumbelReturnTime, returnTimeRainThresholds);
					const tcevSeverity = MonitoringPluvsThresholdClient.getColorByReturnTime(tcevReturnTime, returnTimeRainThresholds);

					if (!!gumbelSeverity || !!tcevSeverity){

						if (!!gumbelSeverity && gumbelSeverity !== 'green'){
							let innerChild = {};
							innerChild.duration =  timescale === 'P_ESTIMATED_1H' ? '1 (stimata)' : num;
							innerChild.distribution = 'Gumbel';
							innerChild.value = rainValue;
							innerChild.returnTime = gumbelReturnTime;
							innerChild.color = gumbelSeverity;
							rainEvent._children.push(innerChild)
						}
						if (!!tcevSeverity && tcevSeverity !== 'green'){
							let innerChild = {};
							innerChild.duration = timescale === 'P_ESTIMATED_1H' ? '1 (stimata)' : num;
							innerChild.distribution = 'TCEV';
							innerChild.value = rainValue;
							innerChild.returnTime = tcevReturnTime;
							innerChild.color = tcevSeverity;
							rainEvent._children.push(innerChild)
						}
					}
				}
			}

		)
		if (rainEvent._children.length > 0){
			rainReturnTimeEvents.push(rainEvent);

			['red','DarkOrange','yellow'].forEach(s => {
				if (rainEvent._children.some(child => child.color === s)) {
					badges.push(s);
				}
			})
		}
	})
	return {rainReturnTimeEvents, badges};
}

export const checkRainThresholdsAnomalies = (newGeoData, rainThresholds) => {
	let badges = [];
	let rainThresholdsEvents = [];
	newGeoData.features.forEach(f => {
		let {properties : p} = f;
		const stationName = p.name;
		let rainEvent = {stationName : stationName, _children : []};
		let maxLev = 0;
		['P_ESTIMATED_1H',...Object.keys(MonitoringPluvsThresholdClient.rainLayerMap)].forEach(timescale =>{
				const rainValue = p[timescale];
				let severity = MonitoringPluvsThresholdClient.getSeverityByValue(timescale, rainValue, rainThresholds[p.sensorCode] || rainThresholds['GENERIC'])
				if (severity.lev>0){
					const num = timescale.match(/\d+/g).map(Number);
					let innerChild = {};
					innerChild.duration = timescale === 'P_ESTIMATED_1H' ? '1 (stimata)' : num;
					innerChild.value = rainValue;
					innerChild.color = severity.color;
					rainEvent._children.push(innerChild)

					if(severity.lev > maxLev){
						maxLev = severity.lev
					}
				}
			}
		)
		rainEvent.stationColor = MonitoringPluvsThresholdClient.getThresholdColors()[maxLev]
		if (rainEvent._children.length > 0){
			rainThresholdsEvents.push(rainEvent);

			['red','DarkOrange','yellow'].forEach(s => {
				if (rainEvent._children.some(child => child.color === s)) {
					badges.push(s);
				}
			})
		}
	})
	return {rainThresholdsEvents, badges};
}

const compareThreshold = (a, b) => {
	let t_a = a.thresholdStart;
	let t_b = b.thresholdStart;

	let comparison = 0;
	if (t_a > t_b) {
		comparison = 1;
	} else if (t_a < t_b) {
		comparison = -1;
	}
	return comparison;
}
export const calculateThresholdSeverity = (temp, thresholds) =>{
	let severity = 'white';
	if (thresholds.length < 1) {
		return severity;
	}

	thresholds.sort(compareThreshold);
	thresholds.forEach(t => {
			if (temp >= t.thresholdStart) {
				severity = t.thresholdLevel;
			}
		}
	)
	return severity;
}
export const checkForNewEvents = (newGeoData, stationSensorThresholds, sensorClassThresholds, valueFieldName = 'value') => {
	let events = [], badges = [];
	newGeoData.features.forEach(f => {
			let {properties: p} = f;
			const {code, name, timestamp} = p;
			const value = p[valueFieldName]
			// Soglia generica
			const scSeverity = calculateThresholdSeverity(value,sensorClassThresholds);

			// Soglia specifica sensore
			const ssThresholdsByCode = stationSensorThresholds.filter(t => t.stationCode === code);
			const ssSeverity = calculateThresholdSeverity(value,ssThresholdsByCode);

			// Check worst severity
			let thresholdType = '';
			let color = '';

			if (scSeverity !== 'white' || ssSeverity !== 'white'){

				if (THRESHOLD_LEVELS.indexOf(scSeverity) > THRESHOLD_LEVELS.indexOf(ssSeverity)) {
					thresholdType = 'Soglia generica';
					color = scSeverity;
				} else {
					thresholdType = 'Soglia specifica sensore';
					color = ssSeverity;
				}
				if (color !== 'green'){
					events.push({code, name, value, thresholdType, color, timestamp});
				}
			}
			['red','orange','yellow'].forEach(s => {
				if (events.some(child => child.color === s)) {
					badges.push(s);
				}
			})
		}
	)
	return {events, badges};
}
export const buildEventsByCategory = (geoDataByCategory, stationSensorThresholds, sensorClassThresholds) => Object.keys(MIDA_MEASUREMENT_LABELS).reduce((eventsByCategory, category) => {
	const {events} = checkForNewEvents(geoDataByCategory[category],stationSensorThresholds[category] || [],sensorClassThresholds[category] || [] );
	eventsByCategory[category] = events;
	return eventsByCategory
},{});

export const hasLinkLost = (timestamp, lastRequestTimestamp ) => {
	let durationSentinel = moment.duration(1, 'hours')
	let lastRequestMoment = moment(lastRequestTimestamp);
	let measurementMoment = moment(timestamp);
	return lastRequestMoment.diff(measurementMoment) > durationSentinel;
}
export const checkWindThresholdsAnomalies = (geoDataByCategory, stationSensorThresholds, sensorClassThresholds) =>
	Object.keys(COMBINED_MEASUREMENT_CATEGORY).reduce((map, combCat) => {
		const velocity = combCat.replace('W', 'V');
		let {events} = checkForNewEvents(
			geoDataByCategory[combCat],
			stationSensorThresholds[velocity] || [],
			sensorClassThresholds[velocity] || [],
			velocity);
		map[combCat] = events;
		return map
	},{})

export const getLargestReturnTime = (props, cfGumbelParametersMap, cfTCEVParametersMap) => ['P_ESTIMATED_1H',...Object.keys(MonitoringPluvsThresholdClient.rainLayerMap)].reduce((map, aggr) => {
	const num = aggr === 'P_ESTIMATED_1H' ? 1 : aggr.match(/\d+/g).map(Number);
	const value = props[aggr];
	const returnTime = MonitoringPluvsThresholdClient.getReturnTime(props.code, value, num, 'GUMBEL', cfGumbelParametersMap, cfTCEVParametersMap);
	if (returnTime > map.max) {
		map.max = returnTime;
		map.timescale = aggr;
	}
	return map;
}, {max: 0, timescale: null});

export const getReturnTimeSeverity = (props, cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds) => {
	const {max, timescale} = getLargestReturnTime(props, cfGumbelParametersMap, cfTCEVParametersMap);
	return !!timescale ? MonitoringPluvsThresholdClient.getColorByReturnTime(max, returnTimeRainThresholds) : 'green';

}

export const getRainThresholdSeverity = (props, rainThresholds) => ['P_ESTIMATED_1H',...Object.keys(MonitoringPluvsThresholdClient.rainLayerMap)].reduce((map, aggr) => {

	const value = props[aggr];
	let res = MonitoringPluvsThresholdClient.getSeverityByValue(aggr, value, rainThresholds)

	if (res.lev > map.max) {
		map.max = res.lev;
		map.color = res.color
	}
	return map;
}, { max: -9999, color: 'green' }).color;

export const getRainThresholdSeverityByAggregation = (props, rainThresholds, aggr) => {
	let color = "green";
	const value = props[aggr];
	let res = MonitoringPluvsThresholdClient.getSeverityByValue(aggr, value, rainThresholds);
	if (res.lev > -9999){
		color = res.color;
	}
	return color;
}

const getRainMarkerColor = (props,
							rainThresholds ,
							cfGumbelParametersMap ,
							cfTCEVParametersMap ,
							lastRequestTimestamp,
							rainMode) => hasLinkLost(props.timestamp, lastRequestTimestamp) ? 'grey' : (rainMode === 'RT' ? getReturnTimeSeverity(props, cfGumbelParametersMap, cfTCEVParametersMap) : getRainThresholdSeverity(props, rainThresholds));

export const findThreshold = (selectedEvents, stationCode, type) => selectedEvents.find(({code, thresholdType}) => code === stationCode && thresholdType === type);

export const getGenericMarkerColor = (stationCode, timestamp, selectedEvents, lastRequestTimestamp) => {
	let specificSensorEvent = findThreshold(selectedEvents, stationCode, 'Soglia specifica sensore');
	let sensorClassEvent =  findThreshold(selectedEvents, stationCode, 'Soglia generica');

	return hasLinkLost(timestamp, lastRequestTimestamp) ? 'grey' :
		(!!specificSensorEvent ? specificSensorEvent.color :
			(!!sensorClassEvent ? sensorClassEvent.color :
				'green'))
}

export const buildDataByStationCode = (geoData, outflowScales, eventsByCategory, rainThresholds, cfGumbelParametersMap, cfTCEVParametersMap, lastRequestTimestamp, rainMode) => geoData.features.reduce((dataByStationCode, feature) => {

	dataByStationCode[feature.properties.station.code] = {
		station: feature.properties.station,
		measurements: Object.keys(feature.properties.sensors).reduce((measurementMap, category) => {
			const timestamp = feature.properties.lastMeasurementTimestamps[category];
			measurementMap[STANDARD_TO_COMBINED[category] || category] = {
				sensorCode: ['DV','DS','DR'].includes(category) ?
					{
						[category]: feature.properties.sensors[category],
						[category.replace('D','V')]: feature.properties.sensors[category.replace('D','V')]
					}
					:
					feature.properties.sensors[category],
				value: (() => {
					switch(category){
						case 'P':
							return RAIN_LAYER_OPTIONS.reduce((map, {value}) => {
								map[value] = feature.properties[value];
								return map
							},{});
						case 'I':
							return {
								I: feature.properties.I,
								flow: calculateFlow(feature.properties.I, outflowScales[feature.properties.sensors[category]])
							};
						case 'DV':
						case 'DS':
						case 'DR':
							return {
								[category]: feature.properties[category],
								[category.replace('D','V')]: feature.properties[category.replace('D','V')]
							}
						default:
							return feature.properties[category];
					}})(),
				timestamp,
				severityColor: category === 'P' ? getRainMarkerColor(
					{
						timestamp,
						...RAIN_LAYER_OPTIONS.reduce((map, {value}) => {
							map[value] = feature.properties[value];
							return map
						},{})
					},
					rainThresholds[feature.properties.sensors['P']] || rainThresholds['GENERIC'],
					cfGumbelParametersMap,
					cfTCEVParametersMap,
					lastRequestTimestamp,
					rainMode
					) :
					getGenericMarkerColor(feature.properties.station.code, timestamp, eventsByCategory[STANDARD_TO_COMBINED[category] || category], lastRequestTimestamp)
			};
			delete measurementMap.VV;
			delete measurementMap.VS;
			delete measurementMap.VR;
			return measurementMap;
		}, {})
	};
	return dataByStationCode;
},{});

const buildOptions = () => {
	let allOptions = _.cloneDeep(MIDA_CATEGORIES_OPTIONS)
		.sort((opt1, opt2) =>  (MEASUREMENT_CATEGORY_SORT[opt1.value] || 999) - (MEASUREMENT_CATEGORY_SORT[opt2.value] || 9999));
	let pluviometerOption = _.remove(allOptions,opt => opt.value === 'P')[0];
	let clonePluviometerOption = _.cloneDeep(pluviometerOption);
	pluviometerOption = {...pluviometerOption,value: 'P_RT', label: 'Pluviometro (tempi di ritorno)'};
	clonePluviometerOption = {...clonePluviometerOption,value: 'P_TH', label: 'Pluviometro (soglie)'};
	allOptions.unshift(pluviometerOption, clonePluviometerOption);
	return allOptions;
}
export const initState = (geoData, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds, rainThresholds, stationSensorThresholds, sensorClassThresholds, sensorBlacklist) => {
	let geoDataByCategory = buildGeoDataByCategory(geoData, outflowScales);
	delete geoDataByCategory.VV;
	delete geoDataByCategory.VS;
	delete geoDataByCategory.VR;

	// Rain
	const {rainReturnTimeEvents, badges: badges_rt} = checkForReturnTimeAnomalies(geoDataByCategory['P'], cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds);
	const {rainThresholdsEvents, badges: badges_th} = checkRainThresholdsAnomalies(geoDataByCategory['P'], rainThresholds);
	const badges = [...badges_rt, ...badges_th];

	// Others
	const eventsByCategory = {
		...buildEventsByCategory(geoDataByCategory, stationSensorThresholds, sensorClassThresholds), // generic events
		...checkWindThresholdsAnomalies(geoDataByCategory, stationSensorThresholds, sensorClassThresholds) // wind events
	};

	const lastRequestTimestamp = geoData.requestTimestamp;
	let dataByStationCode = buildDataByStationCode(geoData, outflowScales, eventsByCategory, rainThresholds, cfGumbelParametersMap, cfTCEVParametersMap, lastRequestTimestamp, DEFAULT_MIDA_STATE.rainMode);

	// Build category option list
	let optionList = buildOptions();
	return {
		mapKey: (DEFAULT_MIDA_STATE.mapKey + 1) % 1000,
		tableKey: (DEFAULT_MIDA_STATE.tableKey + 1) % 1000,
		lastRequestTimestamp,

		returnTimeRainThresholds,
		rainReturnTimeEvents,
		rainThresholdsEvents,

		geoData,
		geoDataByCategory,
		dataByStationCode,
		eventsByCategory,

		cfGumbelParametersMap,
		cfTCEVParametersMap,
		rainThresholds,
		stationSensorThresholds,
		sensorClassThresholds,
		outflowScales,

		selectedGeoData: geoDataByCategory[DEFAULT_MIDA_STATE.selectedCategory],
		selectedEvents: eventsByCategory[DEFAULT_MIDA_STATE.selectedCategory],

		sensorBlacklist,

		optionList,
		badges,
		loadingTab: false,
	};
}
export const updateMidaState = (mapKey, tableKey, pointsKey, geoData, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, rainThresholds, returnTimeRainThresholds, stationSensorThresholds, sensorClassThresholds, rainMode, selectedCategory, lastRequestTimestamp) => {
	let geoDataByCategory = buildGeoDataByCategory(geoData, outflowScales);
	delete geoDataByCategory.VV;
	delete geoDataByCategory.VS;
	delete geoDataByCategory.VR;

	// Rain
	const {rainReturnTimeEvents, badges: badges_rt} = checkForReturnTimeAnomalies(geoDataByCategory['P'], cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds);
	const {rainThresholdsEvents, badges: badges_th} = checkRainThresholdsAnomalies(geoDataByCategory['P'], rainThresholds);
	const badges = [...badges_rt, ...badges_th];

	let eventsByCategory = {
		...buildEventsByCategory(geoDataByCategory, stationSensorThresholds, sensorClassThresholds), // generic events
		...checkWindThresholdsAnomalies(geoDataByCategory, stationSensorThresholds, sensorClassThresholds) // wind events
	};
	let dataByStationCode = buildDataByStationCode(geoData, outflowScales, eventsByCategory, rainThresholds, cfGumbelParametersMap, cfTCEVParametersMap, lastRequestTimestamp, rainMode);
	return {
		mapKey: (mapKey + 1) % 1000,
		tableKey: (tableKey + 1) % 1000,
		pointsKey: (pointsKey + 1) % 1000,
		lastRequestTimestamp,

		rainReturnTimeEvents,
		rainThresholdsEvents,
		geoData,

		geoDataByCategory,
		dataByStationCode,
		eventsByCategory,
		...(selectedCategory && {
			selectedGeoData: geoDataByCategory[selectedCategory],
			selectedEvents: eventsByCategory[selectedCategory]
		}),

		interpolatedDataCache: {},
		badges
	}
}


