import React, {  } from 'react';
import StaticPageClient from '#/lib/StaticPageClient'
import _ from "lodash"
import { Fab } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

function Preparati(props) {

  const downloadImage = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
    <div className='container'>
      <div className='row' style={{ marginTop:"1rem", paddingTop: "3rem", paddingBottom: "2rem" }}>
        <div className="col-lg-6 col-xl-6" >
					<div className="informati-e-preparati-item mx-auto text-center">
						<div className="informati-e-preparati-item-caption d-flex align-items-center justify-content-center h-100 w-100">
							<a 
                href='https://iononrischio.protezionecivile.it/it/'
								className="informati-e-preparati-item-caption-content text-center text-white d-flex align-items-center justify-content-center py-2 px-3 bg-white" 
								style={{ border: "1px solid #242a30", width: "22rem", borderRadius: '10px', height: '107.6px' }}
							>
								<img className="bg-white d-flex align-items-center justify-content-center" style={{ width: "12rem" }}
                  alt='Io Non Rischio'
                  src="https://iononrischio.protezionecivile.it/static/INR_Logo_FondoBianco_1-65ad1b1cd11430bcc00b2588d0c9facf.svg"
                />
							</a>
						</div>
					</div>
				</div>
        <div className="col-lg-6 col-xl-6" >
					<div className="informati-e-preparati-item mx-auto text-center" data-toggle="modal" data-target="#sistema-monitoraggio-allertamento">
						<div className="informati-e-preparati-item-caption d-flex align-items-center justify-content-center h-100 w-100">
							<div 
								className="informati-e-preparati-item-caption-content text-center text-white d-flex align-items-center py-2 px-3 bg-white" 
								style={{ border: "1px solid #242a30", width: "22rem", borderRadius: '10px', height: '107.6px' }}
							>
								<h5 style={{color:"#000000"}} className='text-break'>Sistema di monitoraggio ed allertamento</h5>
							</div>
						</div>
					</div>
				</div>
        <div className="informati-e-preparati-modal modal w-100" id="sistema-monitoraggio-allertamento" tabIndex="-1" role="dialog" aria-labelledby="sistema-monitoraggio-allertamento" aria-hidden="true">
					<div className="modal-dialog mw-100" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="informati-e-preparati-modal-title text-secondary mb-0">Sistema di monitoraggio ed allertamento</h2>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span >
										<i className="fas fa-times"></i>
									</span>
								</button>
							</div>
							<div className="modal-body overflow-auto">
								<img src="/img/Sistema-allertamento-Calabria-corretto.jpg" className='w-100' alt="sistema-monitoraggio-allertamento"  />
							</div>
							<div className="modal-footer text-center">
                <div>
                  <Fab size="medium" title="Scarica" aria-label="Scarica" 
                    onClick={(event) => {event.stopPropagation(); downloadImage('/img/Sistema-allertamento-Calabria-corretto.jpg', 'Sistema-monitoraggio-allertamento.jpg');}}
                    style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
                  >
                    <GetAppIcon  color='action' style={{color: '#fff'}} />
                  </Fab>
                </div>
							</div>
						</div>
					</div>
				</div>
      </div>
    </div>
    </>
  );
}

export default Preparati;