import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import ComunicatoClient from '#/lib/ComunicatoClient';
import JsonFormHolderComponent from '#/commons/components/JsonFormHolderComponent';
import { FiAlignJustify } from 'react-icons/fi';
import {Row, Col} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import it from 'date-fns/locale/it';
import 'react-datepicker/dist/react-datepicker.css';
import { ResetButton } from '#/commons/components/forms/ResetButton';
import { SaveButton } from '#/commons/components/forms/SaveButton';

const date_options = {year : 'numeric', month : '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric'};
const local_string = 'it-IT';

const ReactSwal = withReactContent(Swal)

export default class ComunicatoEditorPage extends React.Component {

	formContent = {}


	constructor(props) {
		super(props);
		const url = window.location.href;
		const idString = url.split('/').pop();
		const id = parseInt(idString);
		this.state = {
			id: !!id ? id : null,
			documentWebTemplateCode: null,
			comunicatoForm: {},
			title: "",
			foreground : false,
			foregroundStart : new Date(),
			foregroundEnd : new Date(),
			image: []
		}
	}

	componentDidMount() {
		let promises = [];
		let schemaPromise = new Promise((resolve,reject)=>{
			ComunicatoClient.getSchema(
				(schema) => {
					resolve({
                        schema
                    })
				},
				(error) => {
					reject({
                        error
                    })
				}
			);
		});
		promises.push(schemaPromise);

		if(!!this.state.id){

			let getNewsPromise = new Promise((resolve,reject)=>{
				ComunicatoClient.getNews(
					(data) => {
						resolve({
							data
						})
					},
					(error) => {
						reject({
							error
						})
					},
					this.state.id
				);
			});
			promises.push(getNewsPromise);

		}
		let result = {};
		Promise.all(promises).then(values => {
            Object.assign(result, ...values);
            this.getFormSuccessCallBack(result)
        });
	}


	convertBlobToBase64 = blob => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(blob);
	});

	getFormSuccessCallBack(result) {
		let schema = result.schema;
		let data = result.data;
		console.log('data: ', data);


		if (!!result.data) {
			this.formContent.data = data.jsonData;
			const imgBase64 = data.imageBase64;

			fetch(imgBase64)
			.then(res => res.blob())
			.then(blob => {
				const file = new File([blob], "Immagine",{ type: blob.type });
				this.setState({
				  id: data.id,
				  documentWebTemplateCode: data.documentWebTemplateCode,
				  comunicatoForm: schema.jsonSchema,
				  title: data.title,
				  foreground : data.foreground,
				  foregroundStart : !!data.foregroundStart ? new Date(data.foregroundStart) : null,
				  foregroundEnd : !!data.foregroundEnd ? new Date(data.foregroundEnd) : null,
				  image: [file]
			  })
			})


		}

		else {
			this.formContent = schema.jsonData;
			this.formContent.documentWebTemplateCode = schema.code;
			this.setState({
				documentWebTemplateCode: schema.code,
				comunicatoForm: schema.jsonSchema,
				title: ""
			})
		}
	}

	dataURItoBlob (dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], {type:mimeString});
	}

	handleChangeStatus = ({ meta }, status) => {
		if (status === "removed") {
			this.formContent.image = undefined;
		}
		if (status === "done") {
			var canvas = document.getElementById("canvas");
			var ctx = canvas.getContext("2d");
			var maxW = 150;
			var maxH = 150;
			var img = new Image();
			this.toDataURL(meta.previewUrl).then((image) => {
				img.src = image;
			})

			img.onload = () => {
				var iw = img.width;
				var ih = img.height;
				var scale = Math.min((maxW / iw), (maxH / ih));
				var iwScaled = iw * scale;
				var ihScaled = ih * scale;
				canvas.width = iwScaled;
				canvas.height = ihScaled;
				ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

				let image = canvas.toDataURL();
				this.formContent.image = {}
				this.formContent.image.blob = image;
				this.formContent.image.name = meta.name;
			}
		}
	}

	toDataURL = url => fetch(url)
		.then(response => response.blob())
		.then(blob => new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onloadend = () => resolve(reader.result)
			reader.onerror = reject
			reader.readAsDataURL(blob)
		}))


	onComunicatoFormStateChange = (data) => {
		console.log('onComunicatoFormStateChange: ', data);
		let newState = Object.assign({}, this.formContent.data, data.formData)
		this.formContent.data = newState;

		if (!!this.formContent.data.title && this.formContent.data.title !== this.state.title) {
			this.setState({
				title: this.formContent.data.title
			})
		}
	}

	onForegroundChange = (e) => {
		this.formContent.data.foreground = e.target.checked;
		this.setState({
			foreground: e.target.checked
		})
	}

	onForegroundStartDateChange = (foregroundStart) => {
		const foregroundStartDateString = !!foregroundStart ? foregroundStart.toLocaleString(local_string, date_options).replace(',', '') : null;
		this.formContent.data.foregroundStart = foregroundStartDateString;
		this.setState({
			foregroundStart: foregroundStart
		})
	}

	onForegroundEndDateChange = (foregroundEnd) => {
		const foregroundEndDateString = foregroundEnd ? foregroundEnd.toLocaleString(local_string, date_options).replace(',', '') : null;
		this.formContent.data.foregroundEnd = foregroundEndDateString;
		this.setState({
			foregroundEnd: foregroundEnd
		})
	}

	reset() {
		this.formContent = {};
		this.setState({ title: "", foreground: false, foregroundStart: null, foregroundEnd: null })
		if (!!document.getElementsByClassName("dzu-previewButton")[0]){
			document.getElementsByClassName("dzu-previewButton")[0].click();
		}
	}

	send() {
		if (!!this.formContent.image && !!this.formContent.data.title && !!this.formContent.data.subtitle && !!this.formContent.data.category && !!this.formContent.data.body) {
			let recap = <ComunicatoRecap {...this.formContent}></ComunicatoRecap>;
			ReactSwal.fire({
				title: <h3>Confermi la creazione del comunicato</h3>,
				html: recap,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Invia',
				cancelButtonText: 'Annulla'
			}).then((result) => {
				if (result.value) {
					let params = {
						id: this.state.id,
						documentWebTemplateCode: this.state.documentWebTemplateCode,
						jsonData: JSON.stringify(this.formContent),
						category: this.formContent.data.category,
						title : this.formContent.data.title,
						base64Image : this.formContent.image.blob,
						imageName : this.formContent.image.name,
						foregroundStatus : this.formContent.data.foreground,
						foregroundStart : !!this.state.foregroundStart ? (new Date(this.state.foregroundStart)).getTime() : undefined,
						foregroundEnd : !!this.state.foregroundEnd ? (new Date(this.state.foregroundEnd)).getTime() : undefined,
					}
					console.log('params: ', params);
					ComunicatoClient.saveNews(
						(result) => {
							ReactSwal.fire(
								'News',
								'Comunicato archiviato con successo',
								'success'
							)
						},
						function (msg) {
						},
						params
					)
				}
			});
		}
		else {
			ReactSwal.fire(
				'Redazione Comunicato!',
				'Tutti i campi devono essere compilati',
				'error'
			)
		}
	}


	render = () => {
		let uiSchema = {"body" : {"ui:widget": "wysiwygWidget"}};
		/*
			tags:{
				"ui:options": {
					"orderable": false
					}
				}
			}
			*/
		return (
			<div className="generic-page">

				<div className="container">

					<h2>{"Comunicato :  " + this.state.title}</h2>
					<Dropzone
						onChangeStatus={this.handleChangeStatus}
						accept="image/*"
						maxFiles={1}
						initialFiles={this.state.image}
						inputContent="Trascina o scegli un'immagine per questo comunicato"
						styles={{
							dropzone: { overflow: "hidden", width: "50vw" },
							dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
							inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
						}}
					/>
					<canvas id="canvas" hidden></canvas>

					{!!this.state.comunicatoForm.jsonFormSchema ?
					<>
						<JsonFormHolderComponent
							schema={this.state.comunicatoForm.jsonFormSchema}
							uiSchema={uiSchema}
							formData={this.formContent.data}
							onChange={(data) => this.onComunicatoFormStateChange(data)}
						>
						</JsonFormHolderComponent>
						<Row>
							<Col md={4}></Col>
							<Col md={4}>Giorno e ora inizio pubblicazione in primo piano</Col>
							<Col md={4}>Giorno e ora termine pubblicazione in primo piano</Col>
						</Row>
						<Row className="mt-2">
							<Col md={4}>
								<input
									name="foreground"
									type="checkbox"
									checked={this.state.foreground}
									onChange={this.onForegroundChange}
								/>&nbsp;Comunicato in primo piano
							</Col>
							<Col md={4}>
								<DatePicker
									id="foregroundStart"
									key="foregroundStart"
									className = "col-12"
									locale={it}
									dateFormat="dd/MM/yyyy HH:mm"
									showTimeSelect
      								timeFormat="HH:mm"
      								timeIntervals={15}
									onChange={this.onForegroundStartDateChange}
									selected={this.state.foregroundStart}
									plaintext = {!this.state.foreground}
                      				readOnly = {!this.state.foreground}
								/>
							</Col>
							<Col md={4}>
							<DatePicker
									id="foregroundEnd"
									key="foregroundEnd"
									className = "col-12"
									locale={it}
									dateFormat="dd/MM/yyyy HH:mm"
									showTimeSelect
      								timeFormat="HH:mm"
      								timeIntervals={15}
									onChange={this.onForegroundEndDateChange}
									selected={!!this.state.foregroundEnd && this.state.foregroundEnd < this.state.foregroundStart ? this.state.foregroundStart : this.state.foregroundEnd}
									minDate={this.state.foregroundStart }
									plaintext = {!this.state.foreground}
                      				readOnly = {!this.state.foreground}
								/>
							</Col>
						</Row>
					</>
						:

						<></>
					}


					<div className="row justify-content-center">
						{/* <button type="button" style={{ margin: "2vw", minWidth: "10vw" }} className="btn btn-secondary btn-lg" onClick={() => this.reset()}>Reset</button>
						<button type="button" style={{ margin: "2vw", minWidth: "10vw" }} className="btn btn-primary btn-lg" onClick={() => this.send()} >Invia</button> */}
						<ResetButton variant="contained" size="large" onClick={() => this.reset()}/>
						<SaveButton variant="contained" size="large" onClick={() => this.send()}  />
					</div>

				</div>
			</div>
		)
	}


}


function ComunicatoRecap(props) {

	return (
		<>

			<div className="row">
				<div className="col-4">
					<img
						style={{ margin: 9 }}
						src={props.image.blob}
						alt="immagine comunicato"
						align="left">
					</img>
				</div>
				<div className="col-8 ml-1" style={{ textAlign: "left", display: "inline-grid" }}>
					<span style={{ fontWeight: "700", fontSize: "1.5rem !important", marginTop: "1.5rem !important", color: "#2b7943" }}>Titolo : {props.data.title}</span>
					<span style={{ fontSize: "1rem !important" }}>Sottotitolo : {props.data.subtitle}</span>
					<span className="icon-span"> <FiAlignJustify /> Categoria: {props.data.category}</span>
					Corpo :<span dangerouslySetInnerHTML={{ __html: `<div>${props.data.body}</div>` }}></span>
				</div>
			</div>



		</>
	);
}