import React from 'react';
import {AiOutlineDownload} from "react-icons/ai";
import IconButton from '@material-ui/core/IconButton';
import domtoimage from 'dom-to-image-more';
import RefreshIcon from "@material-ui/icons/Refresh";
import Tooltip from "@material-ui/core/Tooltip";
import WatermarkClient from '#/lib/WatermarkClient';


function randomString(length) {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

function DownloadButton(props) {
    return (
		<IconButton color="primary" component="span" disabled={props.disabled}
					onClick={(e) => { props.clickCallback() }}>
			<AiOutlineDownload />
		</IconButton>
    )
}

export default class DownloadContainer extends React.Component {


	constructor(props){
		super(props);
		this.state = {
			elementId : randomString(10)
		}
	}

	convertHtmltoImg = () => {
		domtoimage.toPng(document.getElementById(this.state.elementId))
		  .then( (dataUrl) => {

			let realBase64 = dataUrl.split(',')[1]

			WatermarkClient.download(this.props.imageName+'.png', realBase64)
		  })
		  .catch( (error) => {
			console.error('oops, something went wrong!', error);
		  });
	}


    render(){

        return (
			<div>
				<div className={this.props.extraClassNames || "float-right"} >
					{this.props.extra}
					<DownloadButton disabled={this.props.disabled} clickCallback={()=>this.convertHtmltoImg()}></DownloadButton>
					{this.props.updateChart && <Tooltip title="Aggiorna Grafico">
						<IconButton
							size="small"
							className="justify-content-end ml-auto"
							variant="contained"
							color="default"
							onClick={(e) => this.props.updateChart()}>
							<RefreshIcon/>
						</IconButton>
					</Tooltip>}
				</div>
				<div id={this.state.elementId}>
					{this.props.children}
				</div>
			</div>
            
        );
    }

  }
