import React, { useState, useEffect} from "react";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import HecHmsChart from "#/backoffice/hecHMS/component/HecHmsChart";
import Skeleton from "react-loading-skeleton";
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import HecHmsChartAlt from "#/backoffice/hecHMS/component/HecHmsChartAlt";
import SwitchComponent from "#/commons/components/SwitchComponent";
import NumberUtils from "#/lib/NumberUtils"

const units =  {
    "Inflow Minimum":`m<sup>3</sup>/s` ,
	"Inflow Maximum":`m<sup>3</sup>/s` ,
	"Inflow Volume":`m<sup>3</sup>` ,
	"Inflow Depth":"mm" ,
	"Inflow Average":`m<sup>3</sup>/s` ,
	"Outflow Minimum":`m<sup>3</sup>/s` ,
	"Outflow Maximum":`m<sup>3</sup>/s` ,
	"Outflow Volume":`m<sup>3</sup>` ,
	"Outflow Depth":"mm" ,
	"Outflow Average":`m<sup>3</sup>/s` ,
	"Direct Flow Minimum":`m<sup>3</sup>/s` ,
	"Direct Flow Maximum":`m<sup>3</sup>/s` ,
	"Direct Flow Volume":`m<sup>3</sup>` ,
	"Direct Flow Depth":"mm" ,
	"Direct Flow Average":`m<sup>3</sup>/s` ,
	"Baseflow Minimum":`m<sup>3</sup>/s` ,
	"Baseflow Maximum":`m<sup>3</sup>/s` ,
	"Baseflow Volume":`m<sup>3</sup>` ,
	"Baseflow Depth":"mm" ,
	"Baseflow Average":`m<sup>3</sup>/s` ,
	"Precipitation Maximum":"mm" ,
	"Precipitation Total":"mm" ,
	"Precipitation Volume":`m<sup>3</sup>` ,
	"Loss Maximum":"mm" ,
	"Loss Total":"mm" ,
	"Loss Volume":`m<sup>3</sup>` ,
	"Excess Maximum":"mm" ,
	"Excess Total":"mm" ,
	"Excess Volume":`m<sup>3</sup>`
	}

const hydrometerSensorCodes = ['33914','33924','16499','22654','42668','33913','14827','18505','16501','16503','39421','22695','12672','12668','12670','33903','21659','18437','43552','21502','21642','21460','14828','33909','33920','48608','21691','22637','48626','21696','21670','16505','21647','21664','18461','21482','18518','21830','33906'];
const HecHMSData = ({dataKey, data, outflowScales, title}) => {

    const [selectedBasin, setSelectedBasin] = useState(null);
    const [series, setSeries] = useState(null);
    const [showStats, toggleStats] = useState(true);
    useEffect(() => {
        buildSeries();
    },[selectedBasin])
    const columns = [
        { title: "", field: "key"},
        { title: "", field: "value", formatter: 'html'}
    ];

    const buildTableData = () => selectedBasin ? Object.entries(data[selectedBasin].stats).map((stat,) => (
		{
			key: stat[0], 
			value:  units[stat[0]] ? `${NumberUtils.round(stat[1], 1)} ${units[stat[0]]}` : `${stat[1]}`}
			))  : [];
    const calculateFlow = (value, scales) => {
        const scale = scales.find(scale => value >= scale.thresholdStart && value < scale.thresholdEnd);
        if (!!scale) {
            const { a, b, c, e } = scale;
            return Math.round(((a * Math.pow(value + e, b) + c) + Number.EPSILON) * 100) / 100;
        }
        return null;
    }
    const buildSeries = () => {
        if (data[selectedBasin]) {
            let series = {...data[selectedBasin].timeseries, ['Heights']: [],  ['MeasuredOutflow']: []};
            let sensorCode = data[selectedBasin].sensorCode || hydrometerSensorCodes[Math.floor(Math.random() * hydrometerSensorCodes.length)];
            let outflowSeries = series['Outflow'];

            let params = {
                start: outflowSeries[0][0],
                end: outflowSeries[outflowSeries.length - 1][0],
                code: sensorCode
            }
            getDataPromise(params)
                .then((timeseries) => {
                    series.Heights = timeseries;
                    series.MeasuredOutflow = outflowScales[sensorCode] ? timeseries.map(ts => [ts[0], calculateFlow(ts[1], outflowScales[sensorCode])]) : [];
                    delete series['Incremental Precip'];
/*
                    series['Precip Excess'] = series['Precip Excess'].map(s => [s[0], Math.random()*10])
*/
                    setSeries(series);
                }).catch(
                err => {
                    console.error(err);
                    setSeries(series)
                }
            )
        } else setSeries(null)

    }
    const getDataPromise = (params) =>
        new Promise((resolve, reject) => {
                MeasurementsDataClient.getDataForChart(

                    (measuredData) =>{
                        if(measuredData.length > 0){
                            measuredData.shift();
                            /*  const fakeSeries = data[selectedBasin].timeseries.Outflow.map((o) => [o[0],Math.random()]);
                              resolve(fakeSeries)*/
                            resolve(measuredData.map((meas,k) => [meas.ts,meas.value]))
                        } else {
                            console.log('No data measurements');
                            resolve( [])
                        }
                    },
                    (msg) => {
                        console.log('Error retrieving data measurements');
                        reject(msg)},
                    params,
                    2000)
            }
        );

    return (<>
        <div className="row mt-4 ml-2">
            <h3>{title}</h3>
            <InputSelectComponent
                className={"mt-4 col-6 row justify-content-center mx-auto"}
                placeholder={"Seleziona un bacino"}
                isSearchable={true}
                optionList={data.basinList ? data.basinList.map((basin) => ({label: basin, value: basin})) : []}
                value={selectedBasin}
                onChange={selectedOption=> setSelectedBasin(selectedOption)}
            />
            <SwitchComponent
                name={showStats ? 'Nascondi statistiche' : 'Mostra statistiche'}
                value={showStats}
                disabled={!selectedBasin}
                onChange={(e) => toggleStats(!showStats)}
                />
        </div>
        {selectedBasin ? <div className="row mt-4">
            {showStats &&<div className="col-4">
                <h3>Statistiche</h3>
                <ReactTabulator
                    columns={columns}
                    data={buildTableData()}
                    options={{
                        height: "60vh"
                    }}
                    key={"table_data"}
                />
            </div>}
            <div className={showStats ? "col-8 my-auto" : "col-12 my-auto"}>
                {selectedBasin && series &&
                <HecHmsChartAlt
                    key={showStats  + "_" + selectedBasin}
                    chartKey={selectedBasin}
                    series={series}/>}
            </div>
        </div> : <div className="row mt-4">
            <div className="col-4">
                <Skeleton height={"60vh"} duration={8}/>
            </div>
            <div className="col-8">
                <Skeleton height={"60vh"} duration={8}/>
            </div>
        </div>}
    </>)

}

export default HecHMSData;
