import React from 'react';
import ComunicatoItem from './ComunicatoItem';
import PaginationComponent from '#/commons/components/PaginationComponent';
import ComunicatoClient from '#/lib/ComunicatoClient';

import {Link} from "react-router-dom";

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { CircularProgress } from '@material-ui/core';


class ComunicatoPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDateFilter: null,
			endDateFilter: null,
			isLoading: false
		}
	}

	changeCallback = (event, picker) => {
		if (event.type === "apply") {
			this.setState({
				startDateFilter: picker.startDate.valueOf(),
				endDateFilter: picker.endDate.valueOf()
			});
		}
	}

	fromJsonToComunicatoItem = (obj, editable) => {
		return <ComunicatoItem {...obj}  key={obj.id} editable={editable}/>;
	}

	getPage = (successCallback, errorCallback, page) => {
		let params = {
			pageNumber: page,
			pageSize: 3,
			isBO: !!this.props.canEdit ? this.props.canEdit : false
		}
		this.setState({ isLoading: true });
		ComunicatoClient.listNews(
			(obj)=>{
				this.setState({ isLoading: false });
				successCallback(obj)
			},
			(err)=>{
				this.setState({ isLoading: false });
				errorCallback(err)
			},
			params
		)
	}


	render() {
		return (
			<div className="generic-page mt-2">

				<h2 className='d-flex justify-content-center align-items-center mb-5'>Comunicati</h2>
				{this.state.isLoading && (
					<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<CircularProgress />
					</div>
				)}
				<PaginationComponent
					toItemFunction={(obj) => this.fromJsonToComunicatoItem(obj, this.props.canEdit)}
					getPage={( successCallback, errorCallback, page) => this.getPage( successCallback, errorCallback, page)} />
				{this.props.canEdit ?
					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
						
						<Link to={this.props.createUrl}>
							<Tooltip title="Crea un nuovo comunicato">
								<Fab color="primary">
									<AddIcon />
								</Fab>
							</Tooltip>
						</Link>
					</div>
				:
				<></>
				}

			</div>
		)
	}
};

export default ComunicatoPagedList;
