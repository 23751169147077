import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function GuidaUffici(props) {

  return (
    <div className='container-fluid' style={{maxWidth: "1600px"}}>
		<div className="row justify-content-center col-12 m-0 pb-4" style={{ paddingLeft: "3rem", paddingRight: "3rem", backgroundColor: '#d1efff' }}>
			<div className="footer-link-block col-sm-6 col-lg-4 pt-2">
				<h3 className="footer-group-title"  style={{ color: '#4c61a4' }}>Direzione Generale</h3>
				<hr style={{ border: "1px solid #4c61a4" }}></hr>
				<ul className="list-unstyled footer-link-list">
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>Via Lungomare Loc. Giovino</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>88100 - Catanzaro</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Tel:</strong> 0961.732500</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Fax:</strong> 0961.732544</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Mail:</strong> direzionegenerale@arpacal.it</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>PEC:</strong> direzionegenerale@pec.arpacalabria.it</span></li>
				</ul>
			</div>
			<div className="footer-link-block col-sm-6 col-lg-4 pt-2">
				<h3 className="footer-group-title" style={{ color: '#4c61a4' }}>Dipartimento di Catanzaro</h3>
				<hr style={{ border: "1px solid #4c61a4" }}></hr>
				<ul className="list-unstyled footer-link-list">
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>Via Lungomare Loc. Giovino</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>88100 - Catanzaro</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Tel:</strong> 0961.731268</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Fax:</strong> 0961.738689</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Mail:</strong> dip.cz@arpacal.it</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>PEC:</strong> catanzaro@pec.arpacalabria.it</span></li>
				</ul>
			</div>
			<div className="footer-link-block col-sm-6 col-lg-4 pt-2">
				<h3 className="footer-group-title" style={{ color: '#4c61a4' }}>Dipartimento di Cosenza</h3>
				<hr style={{ border: "1px solid #4c61a4" }}></hr>
				<ul className="list-unstyled footer-link-list">
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>Via Montesanto, 123</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>87100 – Cosenza</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Tel:</strong> 0984.794674</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Mail:</strong> dip.cs@arpacal.it</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>PEC:</strong> cosenza@pec.arpacalabria.it</span></li>
				</ul>
			</div>
			<div className="footer-link-block col-sm-6 col-lg-4 pt-2">
				<h3 className="footer-group-title" style={{ color: '#4c61a4' }}>Dipartimento di Reggio Calabria</h3>
				<hr style={{ border: "1px solid #4c61a4" }}></hr>
				<ul className="list-unstyled footer-link-list">
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>Via Troncovito snc - Gallico Sup.</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>89135 - Reggio Calabria</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Tel:</strong> e fax 0965.372618</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Mail:</strong> dip.rc@arpacal.it</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>PEC:</strong> reggiocalabria@pec.arpacalabria.it</span></li>
				</ul>
			</div>
			<div className="footer-link-block col-sm-6 col-lg-4 pt-2">
				<h3 className="footer-group-title" style={{ color: '#4c61a4' }}>Dipartimento di Vibo Valentia</h3>
				<hr style={{ border: "1px solid #4c61a4" }}></hr>
				<ul className="list-unstyled footer-link-list">
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>Via Zona Industriale, Loc.Aeroporto</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>89900 - Vibo Valentia</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Tel:</strong> 0963.567367</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Mail:</strong> dip.vv@arpacal.it</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>PEC:</strong> vibovalentia@pec.arpacalabria.it</span></li>
				</ul>
			</div>
			<div className="footer-link-block col-sm-6 col-lg-4 pt-2">
				<h3 className="footer-group-title" style={{ color: '#4c61a4' }}>Dipartimento di Crotone</h3>
				<hr style={{ border: "1px solid #4c61a4" }}></hr>
				<ul className="list-unstyled footer-link-list">
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>Via Enrico Fermi, Loc. Passovecchio</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}>88900 - Crotone</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Tel:</strong> 0962.21526</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Fax:</strong> 0962.930669</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>Mail:</strong> dip.kr@arpacal.it</span></li>
					<li><span className="nav-link pl-0 pb-0" style={{ color: '#4D4D4D', fontSize: '1rem' }}><strong>PEC:</strong> crotone@pec.arpacalabria.it</span></li>
				</ul>
			</div>
		</div>
    </div>
  );
}

export default GuidaUffici;