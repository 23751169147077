import {properties} from "#/properties.js";
import APIUtils from "#/lib/APIUtils";

export default class TelegramClient {

  static getUserInfo = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.telegramInfo + "/" + params.username);
    let fetch_options = {};
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };

  static sendTestmessage = (okCallback, errCallback, params) => {
    console.log(params);
    let url = new URL(properties.url.sendTestMessage + "/" + params.userName);
    let fetch_options = {
      headers: { "Content-Type": "application/json", charset: "utf-8" },
      method: "POST",
      body: JSON.stringify(params),
    };
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };

}
