import React from 'react'
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";
import DateUtils from "#/lib/DateUtils";
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import Looks3Icon from "@material-ui/icons/Looks3";

import MeasurementsDataClient from "#/lib/MeasurementsDataClient";

function ValidationIcons (props) {


		const {v1, v2, v3} = props.cell._cell.row.data;
		return (
			<div>
				<span style={{float: 'right'}}>
					<LooksOneIcon style={{color: v1 === 'VALID' ? "green" : "red"}} />
					<LooksTwoIcon style={{color: v2 === 'SUSPECT' ? "red" : "green"}}/>
					<Looks3Icon style={{color: colors[v3]}}/>
				</span>
			</div>

		)

}

const colors = {
	VALID : "rgba(16,187,34,0.8)",
	SUSPECT: "rgba(234,129,49,0.8)",
	NOT_VALID: "rgba(241,46,2,0.8)",
	NOT_VALIDATED: "rgba(239,221,61,0.8)",
	RECONSTRUCTED: "rgba(126,197,219,0.8)",
	MISSING: "#ff00008a",
	NONE: "rgb(255,255,255)"
}

const validationOptions = {
	VALID : "Dato corretto",
	SUSPECT: "Dato sospetto",
	NOT_VALID: "Dato errato",
	NOT_VALIDATED: "Dato non validato",
	RECONSTRUCTED: "Dato ricostruito",
	MISSING: "Dato mancante",
	NONE: "none"
}

export default class PuntualDetailsTable extends React.Component {


	constructor(props){
		super(props);

		this.state = {
			data : []
		}
	}

	componentDidMount(){
		this.getData()

	}

	getData(){
		let params = {};
		params.start = this.props.interval.start;
		params.end = this.props.interval.end;
		params.code = this.props.sensorCode;
		params.addMissing = true;
		
		MeasurementsDataClient.getPuntualData(
			 (data) =>{
				this.setState({data})
			},
			function (msg) {
				console.log("Si è verificato un errore inaspettato");
			},
			params,
		);
		
		
	}

	rowFormatter(row){
		var data = row.getData();

		if(data.v3==="MISSING"){
			row.getElement().style.backgroundColor = colors.MISSING;
			row.getElement().style.cursor = "not-allowed";
		}
		else if(!!data.locked){
			row.getElement().style.backgroundColor = "hsla(99,54%,63%,0.8)";
			row.getElement().style.cursor = "not-allowed";
		}
		else if(!!data.dirty){
			row.getElement().style.backgroundColor = "rgba(245,203,92,0.5)";
		}

	}


	getTabulatorOptions(){
		let tabulatorOptions = {
			data: [],
			height:this.props.height,
			rowFormatter:(row)=>this.rowFormatter(row),
			layout:"fitDataTable"
		}
		return tabulatorOptions;
	}


	getColumns(){
		let columns =   [
			{
				title: "Time",
				field: "ts",
				formatter: function (cell) {
					return DateUtils.epochToGMT1String(cell.getValue());
				},
				headerSort:false
			},
			{
				title: this.props.category === 'P' ? "Absolute" : "Value",
				field: this.props.category === 'P' ? "aVal" : "value",
				formatter: function (cell) {
					return cell.getRow().getData().v3!=="MISSING" ? cell.getValue() : "ND";
				},
				headerSort:false
			},
			{
				title: "Original",
				field: "oVal",
				headerSort:false,
			},
			{
				title: "Validations",
				field: "",
				headerSort:false,
				formatter: reactFormatter(<ValidationIcons/> ),

			},
			{
				title: "",
				field: "v3",
				width: 160,
				headerSort:false,
				formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return validationOptions[cell.getValue()];
				}
			},
			
		];

			if (this.props.category === 'P'){
				columns.unshift({
					title: "Ref time",
					field: "rTs",
					formatter: function (cell) {
						return cell.getRow().getData().v3!=="MISSING" ? DateUtils.epochToGMT1String(cell.getValue()) : "ND" ;
					},
					headerSort:false
				})
			}
			return columns;

	}




	render(){
		return(<>

				<ReactTabulator
					columns={this.getColumns()}
					data={this.state.data}
					options={this.getTabulatorOptions()}
				/>
			</>
		)
	}

}
