import React from "react";

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';

import MapFormComponent from "#/commons/map/MapFormComponent";
import MultiSelectZoneForm from "#/commons/components/forms/MultiSelectZoneForm";
import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton} from "#/commons/components/forms/NextButton";
import {ALLERTAMENTO_IDRO_DEFINITIONS} from "#/backoffice/mau/utils/MauUtils";


const FASI_OPERATIVE_OPTIONS = [
	{
		label : "BASE",
		value : "BASE"
	},
	{
		label : "ATTENZIONE",
		value : "ATTENZIONE"
  },
	{
		label : "PREALLARME",
		value : "PREALLARME"
  },
	{
		label : "ALLARME",
		value : "ALLARME"
	}
]

const initFasiOperative = (data) =>{
	data.fasi_op.zones.forEach(zone=>{
		const color_temp = data.criticita.zones.find( z => z.zone === zone.id).idro_temp || "VERDE";
		const color_idro = data.criticita.zones.find( z => z.zone === zone.id).idro_idra || "VERDE";
		const temp_weight = !!color_temp ? ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.label === color_temp).weight : 0;
		const idro_weight = !!color_idro ? ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.label === color_idro).weight : 0;
		const max_weight = Math.max(temp_weight, idro_weight);
		if(max_weight === 0){

		}
		if(max_weight === 1 || max_weight === 2 ){
			zone.value = "ATTENZIONE";
		}  
		if(max_weight === 3 ){
			zone.value = "PREALLARME";
		}  

	})
}

export const StepFasiOperative = (props) => {

    const viewMode = props.viewMode;
    const formData = props.formData;
	const zonesStart = formData.start.fasi_op.zones;
    const zonesEnd = formData.end.fasi_op.zones;
	initFasiOperative(formData.start)
	initFasiOperative(formData.end)
    const todayDate = (!!formData && !!formData.start.date_short && formData.start.date_short) || '';
    const tomorrowDate = (!!formData && !!formData.end.date_short && formData.end.date_short) || '';
    const notValidForm = zonesStart.find(zone=> {return !zone.hasOwnProperty('value')}) || zonesEnd.find(zone=> {return !zone.hasOwnProperty('value')});

    const mapToday = !!props.maps && props.maps.length > 0 ? props.maps[0] : null;
	const mapTomorrow = !!props.maps && props.maps.length > 1 ? props.maps[1] : null;
	const mapEmpty = !!props.maps && props.maps.length > 2 ? props.maps[2] : null;

    const sectionStart =  ["start","fasi_op"];
	const sectionEnd =  ["end","fasi_op"];
	const imgSection = ["img_empty"]

    const handleNextStep = () => {
      if (!(!!notValidForm) || viewMode) {
		props.handleSaveImg(imgSection, mapEmpty.mapId, props.handleNext);

      } else {
        props.reactSwal.fire(
          "Redazione mau",
          "Compilare tutti i campi prima di procedere",
          "error"
        );
      }
	}
	


    return (
      <>
        <div className="row justify-content-center">
          <h2>{props.stepLabel}</h2>
        </div>
        <Grid container spacing={1}>
            <Grid item xs={4}>
              {!!mapEmpty &&
                <MapFormComponent
                  id={mapEmpty.mapId}
                  key={mapEmpty.Key}
                  geoJSON={mapEmpty.shapesGeoJSON}
                  width={mapEmpty.width || "25vw"}
                  height={mapEmpty.height || "60vh"}
                  mapKey={mapEmpty.Key}
                  getColorById={(id) => {return null}}
                />
              }
            </Grid>
          {!!mapToday&& (
            <Grid item xs={4}>
              <MultiSelectZoneForm
                viewMode = {viewMode}
                mapKey={mapToday.mapKey}
                labelForm={mapToday.labelForm + todayDate}
                zones={zonesStart}
                options={FASI_OPERATIVE_OPTIONS}
                onZoneChange = {(zoneId, value) => props.onFormChange(sectionStart, zoneId, 'value', value)}
              />
            </Grid>
          )}
          {!!mapTomorrow && (
            <Grid item xs={4}>
              <MultiSelectZoneForm
                viewMode = {viewMode}
                mapKey={mapTomorrow.mapKey}
                labelForm={mapTomorrow.labelForm + tomorrowDate}
                zones={zonesEnd}
                options={FASI_OPERATIVE_OPTIONS}
                onZoneChange = {(zoneId, value) => props.onFormChange(sectionEnd, zoneId, 'value', value)}
              />
            </Grid>
          )}
        </Grid>
        <MobileStepper
          style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
          position="static"
          variant="text"
          activeStep={props.activeStep}
          steps={props.totalSteps}
          nextButton={
              <NextButton
                variant="contained"
                color="primary"
                disabled={!!notValidForm}
                onClick={handleNextStep}
                textbutton={props.textNextButton}
              />
          }
          backButton={
              <BackButton
                variant="contained"
                disabled={props.disableBackButton}
                onClick={props.handleBack}
              />
          }
        />
      </>
    );
  };
