import React, {useEffect, useState, useRef} from "react";
import MapComponent from "#/commons/map/MapComponent";
import {GeoJSON} from "react-leaflet";
import MapWatermark from "#/commons/map/MapWatermark";
import NowcastingClient from "#/lib/NowcastingClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import GISTools from "#/lib/GISTools";
import DateUtils from "#/lib/DateUtils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import L from "leaflet";
import {SEA_ICONS_PNG, WEATHER_ICONS_PNG, WIND_ICONS_PNG} from "#/backoffice/forecast/utils/ForecastUtils";
import FireRiskClient from "#/lib/FireRiskClient";
import ChromaticScale, {firePropagationDomain, statsTDomain, temperatureDomain} from "#/lib/ChomaticScale";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import parse_georaster from "georaster"
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";
import unifiedCalabria from '#/commons/geodata/unifiedCalabria.json';
import {ResetButton} from "#/commons/components/forms/ResetButton";
import LeafletLegend from "#/commons/map/LeafletLegend";



const mapOptions = {
    center: [39.11, 16.55],
    zoom: 6,
    minZoom: 6,
    width: "40vw",
    height: "60vh",
    forecastColor: '#0eb1b7',
    stormColor: '#bc4949'
}


const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
        Swal.showLoading()
    },
});

const FirePropagationPage = ({ready, mapHeight}) => {
    const [raster, setRaster] = useState(null);
    const [rasterKey, setRasterKey] = useState(1);
    const [mapKey, setMapKey] = useState(1);
    const [coords, setCoords] = useState(null);
    const [showBorders, toggleBorders] = useState(false);
    const mapRef = useRef(null);

    useEffect(() => {
        if (coords){
            loadingSwal.fire("Simulazione in corso...")
            getPropagationData()
        } else {
            setMapKey((mapKey + 1) % 1000)
        }
    },[coords]);

    useEffect(() => {
        setRasterKey((rasterKey + 1) % 1000)
    },[raster]);

    const getPropagationData = () => {
        FireRiskClient.getTiffFromPropagationData(
            georaster => {
                loadingSwal.close();
                setRaster(georaster)

            },
            err => {
                loadingSwal.close();
                ReactSwal.fire('Si è verificato un problema durante l\'esecuzione del modello','','error')
                console.error(err)
            },
            coords
        )
    }
    const zoneBbox = GISTools.getBBoxFromPoints(/*data ? data.storm :*/ GISTools.getCalabriaBorders());
    const fixMap = () => {
        if (mapRef && mapRef.current){
            mapRef.current.leafletElement.invalidateSize();
            mapRef.current.leafletElement.fitBounds([
                [zoneBbox.bbox[1], zoneBbox.bbox[0]],
                [zoneBbox.bbox[3], zoneBbox.bbox[2]]
            ]);
        }
    }
    let currentMarker;

    const addMarker = (e) => {
        if (mapRef.current.leafletElement){
            if (currentMarker){
                mapRef.current.leafletElement.removeLayer(currentMarker)
            }
            if ( GISTools.isWithinPoligon([e.latlng.lng, e.latlng.lat], unifiedCalabria.features[0].geometry)){
                let newMarker = new L.marker(e.latlng);
                newMarker.setIcon(new L.Icon({
                    iconSize: [20, 20],
                    iconUrl: '/img/fire_start.png',
                    popupAnchor: [0, 0]
                }));
                newMarker.addTo(mapRef.current.leafletElement);
                currentMarker = newMarker;
                setCoords({x : e.latlng.lng,  y : e.latlng.lat})
            } else {
                setCoords(null)
            }
        }

    }

    const reset = () => {
        if (mapRef.current.leafletElement && currentMarker){
            mapRef.current.leafletElement.removeLayer(currentMarker);

        }
        removeLegend()
        setRaster(null);
    }
    const removeLegend = () => {

        let tmp = document.getElementsByClassName("newLegend");
        if (!!tmp && tmp.length > 0) {
            tmp[0].remove()
        };
    }
    return (
        <div className="generic-page container-fluid data-page">
            <h2 className="textAlignCenter">Propagazione incendi</h2>
            <h3>Fare click destro su un punto della mappa per effettuare una simulazione di propagazione dell'incendio.</h3>

            <ResetButton className="float-right"
                         variant="contained"
                         size="large"
                         label="Rimuovi layer" onClick={() => reset()}/>

            <MapComponent
                functionalRef={mapRef}
                key={ 'aKey' + mapKey }
                width={"100%"}
                height={mapHeight || mapOptions.height}
                zoom={mapOptions.zoom}
                zoneBbox={zoneBbox}
                zoomSnap={false}
                buttonKey={'nowcasting'}
                tile={null}
                skipRecenter={false}
                toggleBorders={() => toggleBorders(!showBorders)}
                addMarker={addMarker}>
                {raster &&
                    <>
                <GeotiffRasterLayer
                    key={"finalraster_" + rasterKey}
                    georaster={raster}
                    opacity={1}
                    resolution={1024}
                    colorScale={ChromaticScale.getFirePropagationScale()}
                    /*handleClick={(val,latlng) => {}*//>

                        <LeafletLegend
                            legendTitle={'Tempo di propagazioni (minuti)'}
                            replaceMode={true}
                            grades={firePropagationDomain}
                            getColor={(num)=> ChromaticScale.getFirePropagationScale()(num) }
                            firstLabel={"Nessuna propagazione"}/>

                    </>}

                {showBorders && <GeoJSON key={"calabria_radar_"}
                                         data={GISTools.getRegioniBorders()}
                                         style={{
                                             fillColor: "#fff",
                                             fillOpacity: 0,
                                             weight: 2,
                                             opacity: 1,
                                             color: "green",
                                         }}/>}
            </MapComponent>
            {fixMap()}
        </div>
    )
}

export default FirePropagationPage;
