import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class WorkshiftClient {

	static getCalendarList = (events) => {
		let returnEvents = [];
		for (let event of events){
			const newEvent = {...event,
				title : event.name + ' ' + event.surname + ' - ' + event.function,
				start :  new Date(event.startDate + ' ' + event.startTime),
				end : new Date(event.endDate + ' ' + event.endTime),
				worker : event.name + ' ' + event.surname,
			   };
			returnEvents.push(newEvent);
		}
		return returnEvents;
	}

	static workShiftGetList = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.workShiftGetList);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static workShiftGetFunctionsList = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.workShiftGetFunctionsList);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	};

	static workShiftSave = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.workShiftSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static workShiftSaveAll = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.workShiftSaveAll);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static workShiftDelete = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.workShiftDelete+"/"+ params.id);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static workShiftCsvUpload = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.workShiftCsvUpload);
		const formData = new FormData();
		formData.append("file", params.file);
		let fetch_options = {
			//headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: formData
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};


}



