import React from 'react';
import {SelectForm} from "#/commons/components/forms/SelectForm";
import _ from "lodash";
import GISTools from "#/lib/GISTools";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dropzone from "react-dropzone-uploader";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";
import DeleteIcon from "@material-ui/icons/Delete";


const ReactSwal = withReactContent(Swal);

const hasDuplicates = array => (new Set(array)).size !== array.length;

export default class UploadGeoJsonComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			selectedGeoJSON: this.getSelectOptions()[0],
			dirty: false
		}
	}
	componentDidMount() {
		if (!!this.props.initialTemplate){
			this.setState({selectedGeoJSON: this.getSelectOptions()[2]})
		}
	}

	getSelectOptions = () => {
		let options = [ {
			label : "8 zone",
			value : zonesGeoJson8,
			identifier : "ZONE"
		}, {
			label : !!this.state && this.state.dirty ? "Nuove zone" : "Carica nuove zone",
			value : "NEW",
			identifier : "__NEW__"
		}];
		if (!!this.props.initialTemplate){
			options.push({
				label : "File corrente",
				value : this.props.initialTemplate,
				identifier : this.props.initialIdentifier,
			})
		}
		return options;
	};
	handleSelectGeoJSON = (valueSelected) => {
		let options = this.getSelectOptions();
		let selectedOption = options.find(opt => opt.value === valueSelected);

		let geoJsonIdentifier = selectedOption.identifier;
		let geoJSON = selectedOption.value;

		if (geoJsonIdentifier==="__NEW__" && geoJSON === "NEW") {
			this.openModal();
		} else {
			let clonedGeoJson = _.cloneDeep(geoJSON)
			this.addCentroidGeoJSON(clonedGeoJson);

			this.setState({
				zoneGeoJson: clonedGeoJson,
				geoJsonIdentifier: geoJsonIdentifier,
				selectedGeoJSON: selectedOption
			}, () => {
				let isDirty = !_.isEqual(valueSelected,this.getSelectOptions()[2].value);
				this.props.handleNewGeoJsonUpload(this.state.geoJsonIdentifier, this.state.zoneGeoJson, isDirty)
			})
		}
		this.hasBeenModified = true;
	};
	openModal = () => {
		this.setState({showModal: true});
	}
	onCloseModal = () => {
		this.setState({showModal: false});
	}
	addCentroidGeoJSON(geoJSON) {
		if (!!geoJSON){
			GISTools.addCentroids(geoJSON)
		}
	}
	handleChangeStatusZone = ({ file }, status) => {
		if (status === "removed") {
			this.setState({
				zoneGeoJson: null,
				identifierCandidates: null,
				file : null
			})
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let geoJSON = JSON.parse(fileContent);
				// Check keys
				let identifierCandidates = this.findIdentifierCandidates(geoJSON);
				if (identifierCandidates.length > 0) {
					let clonedGeoJson = _.cloneDeep(geoJSON)
					this.addCentroidGeoJSON(clonedGeoJson);
					this.setState({
						file: file,
						zoneGeoJson: clonedGeoJson,
						selectedGeoJSON: this.getSelectOptions()[1],
						dirty: true,
						tableKey: (this.state.tableKey + 1) % 1000,
						mapKey: (this.state.mapKey + 1) % 1000,
						identifierCandidates: identifierCandidates
					})
				}
			};
			reader.onerror = (event) => {
				ReactSwal.hideLoading();
				ReactSwal.fire('Si è verificato un errore nella lettura delle zone', '', 'error');
			};
			reader.readAsText(file);
		}
	}
	findIdentifierCandidates = (geoJSON) => {
		let identifiers = [];
		const features = geoJSON.features;
		if (!!features) {
			const feature = features[0];
			const keys = Object.keys(feature.properties);
			keys.forEach(key => {
				let allValues = features.map(feature => feature.properties[key])
				if (!hasDuplicates(allValues)){
					identifiers.push(key)
				}
			})
		}
		return identifiers;
	}
	removeFile = () => {
		const initialOption = this.getSelectOptions()[2];
		this.setState({
			zoneGeoJson: initialOption.value,
			geoJsonIdentifier: initialOption.identifier,
			identifierCandidates: null,
			file : null,
			selectedGeoJSON: initialOption,
			dirty: false
		},() =>	this.props.handleNewGeoJsonUpload(this.state.geoJsonIdentifier, this.state.zoneGeoJson, false))
	}
	confirmSelection = (key) => {
		this.setState({geoJsonIdentifier: key, showModal: false}, () => {
			this.props.handleNewGeoJsonUpload(this.state.geoJsonIdentifier, this.state.zoneGeoJson, true);
		})
	}
	render() {
		return (
			<>
				<div className="row">
				<SelectForm
					id="select_default_GeoJSON"
					label="Seleziona file di zone"
					className="mt-3 col-10 justify-content-center"
					options={this.getSelectOptions()}
					selectedValue = {this.state.selectedGeoJSON.value}
					handleOnChange = {this.handleSelectGeoJSON}
				/>
				{this.state.dirty ?
					<div className="col-2 mt-3">
						<Button
							variant="contained"
							color="default"
							onClick={(event) => this.removeFile()}>
							<DeleteIcon />
						</Button>
					</div>: <></>}
				</div>
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "50vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3 >Import Zone da file</h3>
						</div>

						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<Dropzone
									{...this.state.file && {initialFiles : [this.state.file]}}
									onChangeStatus={this.handleChangeStatusZone}
									accept=".geojson,.json"
									maxFiles={1}
									inputContent="Inserisci il file delle zone in formato GeoJSON"
									styles={{
										dropzone: { overflow: "hidden", width: "30vw" },
										dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
										inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
									}}
								/>
							</div>
						</div>
						<br/>
						{!!this.state.identifierCandidates ?
							<>
								<div className="d-flex justify-content-center">
									{this.state.identifierCandidates.length > 0 ?
										<h3>Scegli il campo che identifica le geometrie zonali:</h3> :
										<h3 style={{color: "red"}}>Il file caricato non contiene alcun campo identificativo. Caricare un file valido.</h3>
									}
								</div>
								<div className="d-flex justify-content-center">
									{this.state.identifierCandidates.map(key =>
										<Button
											key = {key}
											size="medium"
											className="col-sm-2 my-auto"
											variant="contained"
											color="primary"
											onClick={(e) => this.confirmSelection(key)}
										>{key}</Button>

									)}
								</div>
							</> : <></>
						}


					</div>

				</Modal>
			</>
		)
	}
}
