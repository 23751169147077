import {MapControl, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "#/commons/map/LeafletLegend.css";
import {
	ALLERTAMENTO_IDRO_DEFINITIONS,
	PRECIPITAZIONI_ICONS,
	PRECIPITAZIONI_IMPULSIVE_OPTIONS,
	PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS
} from "#/backoffice/mau/utils/MauUtils";
import chroma from "chroma-js";

class LeafletMauLegend extends MapControl {
	createLeafletElement(props) {}

	componentWillUnmount(){}
	
    componentDidMount() {

		const legend = L.control({ position: "bottomright" });
		let labels = [];

        legend.onAdd = () => {
			let classNames = !!this.props.replaceMode ?  "info legend newLegend " : "info legend ";
            const div = L.DomUtil.create("div", classNames);
			const selectedLayer = this.props.selectedLayer;
			let optionArray = [];

			switch (selectedLayer) {
				case 'rainfall_pni':
					optionArray.push(...PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS)
					break;
				case 'rainfall_pi':
					optionArray.push(...PRECIPITAZIONI_IMPULSIVE_OPTIONS);
					break;
				case 'wind':
					optionArray.push({label: "Presenza di venti", value: "wind"},{label: "Assenza di venti", value: "no_wind"});
					break;
				case 'storm_surges':
					optionArray.push({label: "Presenza di mareggiate", value: "storm_surges"},{label: "Assenza di mareggiate", value: "no_storm_surges"});
					break;
				case 'frost':
					optionArray.push({label: "Presenza di temperature estreme", value: "frost"},{label: "Assenza di temperature estreme", value: "no_frost"});
					break;
			/*	case 'idro_idra':
				case 'idro_temp':*/
				default:
					optionArray.push(...ALLERTAMENTO_IDRO_DEFINITIONS);
					break;

			}
		if (!['idro_idra','idro_temp','criticalities','general'].includes(selectedLayer)){
			optionArray.forEach(opt => {
				if (!!opt.value){
					labels.push(
						`<img src=${PRECIPITAZIONI_ICONS[opt.value]} style="width: 28px"></img>  ${opt.label}`
					);
				}
			});
		} else {
			optionArray.forEach(opt => {
				let color = opt.color;
				if (selectedLayer === 'criticalities'){
					color = chroma(color).alpha(0.5);
				}
				labels.push(
					'<i style="background:' +
					color  +
					'"></i> ' + opt.label+'</br>'
				);
			})
		}

			div.innerHTML = labels.join("</br>");
            return div;
        };

        const { map } = this.props.leaflet;

        let tmp = document.getElementsByClassName("newLegend");
        if (!!this.props.replaceMode && !!tmp && tmp.length > 0) {
            tmp[0].remove()
        };
        legend.addTo(map);

    }
}

export default withLeaflet(LeafletMauLegend);
