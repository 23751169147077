import React from 'react'
import L from 'leaflet';
import GISTools from '#/lib/GISTools';
import SensorClient from '#/lib/SensorClient';
import {FaAngleLeft, FaCrosshairs, FaMapMarkerAlt} from "react-icons/fa";
import {
	COMBINED_TO_STANDARD,
	MEASUREMENT_CATEGORY,
	MEASUREMENT_CATEGORY_ICONS,
	MEASUREMENT_CATEGORY_SORT,
	MEASUREMENT_LABELS
} from "#/lib/MeasurementCategory";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import Fab from '@material-ui/core/Fab';
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import "#/commons/data/section/year-calendar.css";
import _ from 'lodash';


import moment from 'moment';


import MeasurementsComponent from '../components/MeasurementsComponent';
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import DateUtils from "#/lib/DateUtils";

import StationGisPicker from "#/commons/map/StationGisPicker";
import StationClient from "#/lib/StationClient";

import chroma from 'chroma-js'
import Tooltip from "@material-ui/core/Tooltip";
import ApiUtils from "#/lib/APIUtils";
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';
import LandscapeMobileWrapper from  "#/commons/components/LandscapeMobileWrapper"
import WidthUtils from "#/lib/WidthUtils"
import SensorCategoryClient from '#/lib/SensorCategoryClient';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const defaultMeasurement = {
	value: null,
	label: <>&nbsp;</>
}
const defaultZone = {
	value: null,
	label: "Tutte le zone"
}

const defaultStation = {
	value: null,
	label: <>&nbsp;</>
}

const defaultYear = moment().year();
const defaultSelectedRage = [moment().startOf("day"), moment().endOf("day")];

let allowedValidationCategory = [
	MEASUREMENT_CATEGORY.P,
	MEASUREMENT_CATEGORY.T,
	MEASUREMENT_CATEGORY.VR,
	MEASUREMENT_CATEGORY.VV,
	MEASUREMENT_CATEGORY.VS,
	MEASUREMENT_CATEGORY.DR,
	MEASUREMENT_CATEGORY.DV,
	MEASUREMENT_CATEGORY.DS,
	MEASUREMENT_CATEGORY.I,
	MEASUREMENT_CATEGORY.N,
	MEASUREMENT_CATEGORY.B,
	MEASUREMENT_CATEGORY.R,
	MEASUREMENT_CATEGORY.IG,
	MEASUREMENT_CATEGORY.F
]

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

class ViewSection extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 0,
		maxZoom: 12,
		width: "40vw",
		height: "65vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			year: defaultYear,
			selectedRange: defaultSelectedRage,
			allSensorCategories:[],
			zoneOptions: [],
			stationOptions: [],
			availableMesaurements: [],

			selectedZone: defaultZone,
			selectedStation: defaultStation,
			selectedMeasure: defaultMeasurement,

			interval: null,
			mapKey: 0,

			showGisPicker: true,
			lockedClasses: {}

		}
	}

	componentDidMount() {


		let selectedZone = !!this.props.selectedZone ? this.props.selectedZone : defaultZone;
		let selectedStation = !!this.props.selectedStation ? this.props.selectedStation : defaultStation;
		let selectedMeasure = !!this.props.selectedMeasure ? this.props.selectedMeasure : defaultMeasurement;

		let zoneOptions = this.buildZoneOptions();
		let stationOptions = this.buildStationOptions(selectedZone.value);

		// Find stations out of Calabria
		let stationsOutsideRegion = GISTools.getStationsOutsideCalabria(this.props.stationsGeoJSON);

		loadingSwal.fire()
		/*if (!!selectedStation.value) {*/
		this.getAvailableMeasurements(selectedStation)
			.then((availableMesaurements) => {
				selectedMeasure = !!this.props.selectedMeasure ? availableMesaurements.find(element => element.value === this.props.selectedMeasure.value) : defaultMeasurement;
				let state = {
					zoneOptions: zoneOptions,
					stationOptions: stationOptions,
					stationsOutsideRegion: stationsOutsideRegion,
					availableMesaurements: availableMesaurements,
					selectedZone: selectedZone,
					selectedStation: selectedStation,
					selectedMeasure: selectedMeasure,

					mapKey: (this.state.mapKey + 1) % 1000
				};
				/*if (!!!selectedStation.value){
					state['interval'] = this.props.interval;
				}*/
					loadingSwal.close();
				this.setState(state)
				



			});
		/*}
		else {
			let availableMesaurements = [defaultMeasurement];
			this.setState({
				zoneOptions: zoneOptions,
				stationOptions: stationOptions,
				stationsOutsideRegion: stationsOutsideRegion,
				availableMesaurements: availableMesaurements,

				selectedZone: selectedZone,
				selectedStation: selectedStation,
				selectedMeasure: selectedMeasure,
				interval: this.props.interval,

				mapKey: (this.state.mapKey + 1) % 1000
			})
		}*/

	}

	buildZoneOptions() {
		let zoneOptions = [];
		let zones = GISTools.getAllZonePolygon();

		zoneOptions.push(defaultZone)
		zones.forEach(zone => {
			let id = zone.properties.ZONE;
			zoneOptions.push({
				value: parseInt(id),
				label: "ZONA " + id,
				icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
			});
		})
		// Add Out-of-Calabria zone
		zoneOptions.push({
			value: 9999,
			label: "Territorio extraregionale",
			icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
		});

		return zoneOptions
	}

	buildStationOptions(zoneId) {
		let stationOptions = [];
		let stations = this.props.stationsGeoJSON;

		if (!!zoneId) {
			if (zoneId === 9999){
				stations = this.state.stationsOutsideRegion;
			} else{

				let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
				;
				let stationWithinZone = GISTools.intersect(stations, zonePolygon);
				stations = stationWithinZone;
			}
		}

		let stationsProperties = GISTools.getPropertiesFromFeature(stations);
		stationOptions.push(defaultStation);
		stationsProperties.forEach(properties => {
			stationOptions.push({
				label: properties.name,
				value: properties.code,
				icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
			});
		})

		return stationOptions
	}

	zonesStyle(feature) {
		let zone = parseInt(feature.properties.ZONE);
		let style = {};

		if (this.state.selectedZone.value === 0) {
			if (this.state.zoneOptions.map(x => x.value).includes(zone)) {

				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}

		}
		else {
			if (this.state.selectedZone.value === zone) {
				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}
		}


		return style;
	}

	onEachStation(feature, layer) {
		layer.on('click', (e) => this.onSelectStation(feature.properties.code));

		var popup = L.popup({ closeButton: false })
			.setContent('<div><span class="mr-2 fas fa-map-marker-alt"></span>' + feature.properties.name + '</div>');
		layer.bindPopup(popup);
		let timer = null;
		layer.on('mouseover', function (e) {
			timer = setTimeout(() => this.openPopup(), 1000)
		});
		layer.on('mouseout', function (e) {
			clearTimeout(timer);
			this.closePopup();
		});
	}

	stationToMarker(feature, latlng) {
		let marker = null;

		let name = feature.properties.name;
		let isSelected = name === this.state.selectedStation.label;

		if (isSelected) {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point_selected.png',
					popupAnchor: [0, 0]
				})
			});
		}
		else {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point.png',
					popupAnchor: [0, 0]
				})
			});
		}

		return marker;

	}

	onSelectZone(zoneId) {

		loadingSwal.fire();

		SensorCategoryClient.getAll(
			(allCategories) => {
				let stationOptions = [];
				let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
				stationOptions = this.buildStationOptions(zoneId);
				let availableMesaurements = this.populateAvailableMeasurements(this.allMeasurements(), allCategories);
				
				loadingSwal.close();
				this.setState({
					stationOptions: stationOptions,
					availableMesaurements: availableMesaurements,
					selectedZone: selectedZone,
					selectedStation: defaultStation,
					selectedMeasure: defaultMeasurement,
					mapKey: (this.state.mapKey + 1) % 1000,
					filteredStationsGeoJson : null
				})
				
				
			},
			(error) => {
				console.log(error.message);
			}
		)

		

	}

	onSelectStation(stationCode) {

		let stationOptions = this.buildStationOptions();
		let selectedStation = stationOptions.find(element => element.value === stationCode);
		let stationPoint = GISTools.getStationByField(this.props.stationsGeoJSON, "code", stationCode);
		let zonePolygon = GISTools.getZonePolygonByPoint(stationPoint);
		let zoneId = (!!zonePolygon && !!zonePolygon.properties)  ? zonePolygon.properties.ZONE : 9999;


		let selectedZone = this.state.zoneOptions.find(element => element.value === parseInt(zoneId, 10));

		let zoneBbox = null;
		if (zoneId !== 9999){
			zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
			zoneBbox = GISTools.getBBoxFromPoints(zonePolygon);
		}
		loadingSwal.fire()
		this.getAvailableMeasurements(selectedStation)
			.then((availableMesaurements) => {

				console.log(availableMesaurements)

				const selectedMeasure = !!availableMesaurements.filter(meas => meas.value === this.state.selectedMeasure.value)[0]
					? availableMesaurements.filter(meas => meas.value === this.state.selectedMeasure.value)[0]
					: defaultMeasurement;

				loadingSwal.close()
				this.setState({
					availableMesaurements,
					selectedZone: selectedZone,
					selectedStation: selectedStation,
					selectedMeasure,
					mapKey: (this.state.mapKey + 1) % 1000,
					showGisPicker: !!this.state.interval && this.state.selectedMeasure.value  ? false : true,
					zoneBbox
				})
			});


	}
	createCustomClasses(lockedData) {
		let dateList = new Set();
		lockedData.forEach(data => {

			const endDate = new Date(data.end);
			let dateMove = new Date(data.start);
			let strDate;
			while (dateMove < endDate) {
				strDate = DateUtils.changeDateFormat(dateMove.toLocaleDateString());
				dateList.add(strDate);
				dateMove.setDate(dateMove.getDate() + 1);
			}
		})
		return {
			locked: [...dateList]
		}
	}


	onSelectMeasure(measurementCode) {
		console.log("measurementCode",measurementCode)
		let selectedMeasure = this.state.availableMesaurements.find(element => element.value === measurementCode);

		if (!_.isEmpty(selectedMeasure.sensor)){
			if (selectedMeasure.sensor[0].sensorCode === -9999) {
				console.log("Selection starts from instrument -> Searching equipped stations and zone containing those stations... ");
				this.findEquippedStations(selectedMeasure);
			} else {
				if(ApiUtils.getContext() === 'priv') {
					this.getLockData(selectedMeasure);
				} else {
					this.setState({
						selectedMeasure: selectedMeasure,
						mapKey: (this.state.mapKey + 1) % 1000,
						showGisPicker: !!this.state.interval && selectedMeasure.value  ? false : true

					},() => this.findEquippedStations(selectedMeasure))
				}
			}
		}
	}
	findEquippedStations(selectedMeasure){
		let measurementCode = selectedMeasure.value;

		if (["WV","WS","WR"].includes(measurementCode)){
			measurementCode = COMBINED_TO_STANDARD[measurementCode];
		}
		// Search equipped stations
		StationClient.getStationsGeojsonByCategory(
			measurementCode,
			(data)=>{
				console.log("Found " + data.features.length + " station equipped")
				let stationOptions = [];
				let stations = data;
				let stationsProperties = GISTools.getPropertiesFromFeature(stations);
				stationOptions.push(defaultStation);
				stationsProperties.forEach(properties => {
					stationOptions.push({
						label: properties.name,
						value: properties.code,
						icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
					});
				})
				// Filter geoJson
				let filteredStationsGeoJSON= _.cloneDeep(this.props.stationsGeoJSON);
				filteredStationsGeoJSON.features = filteredStationsGeoJSON.features.filter(f => stationOptions.map(opt => opt.value).includes(f.properties.code));
				console.log("stationsGeoJSON",this.props.stationsGeoJSON)
				console.log("filteredStationsGeoJson",filteredStationsGeoJSON)

				this.setState({
					stationOptions,
					selectedMeasure,
					filteredStationsGeoJson: filteredStationsGeoJSON,
					mapKey: (this.state.mapKey + 1) % 1000
				})
			},
			(err) => {console.log(err)}
		)
	}

	getLockData(selectedMeasure){
		MeasurementsDataClient.getLockData(
			(json) => {
				const customClasses = this.createCustomClasses(json);
				console.log('custom classe', customClasses)
				this.setState({
					selectedMeasure: selectedMeasure,
					mapKey: (this.state.mapKey + 1) % 1000,
					lockedClasses: customClasses,
					showGisPicker: !!this.state.interval && selectedMeasure.value  ? false : true
				},() => this.findEquippedStations(selectedMeasure))
			},
			() => console.log("Error while retrieving locked data"),
			selectedMeasure.sensor[0].sensorCode
		)
	}

	getAvailableMeasurements(selectedStation) {
		return new Promise((resolve, reject) => {
			SensorCategoryClient.getAll(
				(allCategories) => {
					if (!!selectedStation && !!selectedStation.value) {
						SensorClient.getSensorsByStation(selectedStation.value,
							(data) => {
								resolve(this.populateAvailableMeasurements(data, allCategories))
							}
						)
					}
					else {
						const data = this.allMeasurements();
		
						resolve(this.populateAvailableMeasurements(data, allCategories))
					}
					
					
				},
				(error) => {
					console.log(error.message);
				}
			)

			
		})

	}
	allMeasurements = () => Object.keys(MEASUREMENT_LABELS)
		.map(cat => {
			let obj = {};
			obj['category'] = cat;
			obj['label'] = MEASUREMENT_LABELS[cat];
			obj['code'] = -9999;
			return obj;
		});

	populateAvailableMeasurements(data, allCategories){

		console.log("data",data)
		console.log("allCategories",allCategories)

		let isFrontoffice = window.location.href.includes("frontoffice")
		data = data.sort((a,b)=>{
			let aSortValue = MEASUREMENT_CATEGORY_SORT[a.category] || 999999999;
			let bSortValue = MEASUREMENT_CATEGORY_SORT[b.category] || 999999999;
			return aSortValue - bSortValue;
		});

		let availableMeasurement = [];
		availableMeasurement.push(defaultMeasurement);

		data.forEach((value, index) => {
			if(isFrontoffice && !allCategories.find(x=>x.category === value.category)?.includeFrontoffice){
				
			}
			else {
				let measurement = {};
				measurement.value = value.category;
				measurement.label = value.label;
				measurement.icon = MEASUREMENT_CATEGORY_ICONS[value.category];
				measurement.sensor = [{
					category: value.category,
					sensorCode: value.code,
				}];
				measurement.group = "SENSOR";
				measurement.groupLabel = "Misure sensori";

				availableMeasurement.push(measurement);
			}
			
		})
		if (!!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DV)
			&& !!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VV)
		) {
			let measurement = {};
			measurement.value = MEASUREMENT_CATEGORY.WV;
			measurement.label = "Vento Vettoriale";
			measurement.icon = MEASUREMENT_CATEGORY_ICONS[measurement.value];
			measurement.group = "WIND_COMBO";
			measurement.groupLabel = "Misure combinate";
			measurement.sensor = [availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DV).sensor[0], availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VV).sensor[0]];

			availableMeasurement.push(measurement);
		}

		if (!!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DS) && !!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VS)) {
			let measurement = {};
			measurement.value = MEASUREMENT_CATEGORY.WS;
			measurement.label = "Vento Scalare";
			measurement.icon = MEASUREMENT_CATEGORY_ICONS[measurement.value];
			measurement.group = "WIND_COMBO";
			measurement.groupLabel = "Misure combinate";
			measurement.sensor = [availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DS).sensor[0], availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VS).sensor[0]];
			availableMeasurement.push(measurement);
		}

		if (!!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DR) && !!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VR)) {
			let measurement = {};
			measurement.value = MEASUREMENT_CATEGORY.WR;
			measurement.label = "Vento Raffiche";
			measurement.icon = MEASUREMENT_CATEGORY_ICONS[measurement.value];
			measurement.group = "WIND_COMBO";
			measurement.groupLabel = "Misure combinate";
			measurement.sensor = [availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DR).sensor[0], availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VR).sensor[0]];
			availableMeasurement.push(measurement);
		}
		return availableMeasurement;
	}

	onPrevYear() {
		this.setState({
			year: this.state.year - 1
		})
	}

	onNextYear() {
		this.setState({
			year: this.state.year + 1
		})
	}

	onPickRange(start, end, year) {

		let isTillToday = end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		let isFromToday = start.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		end = isTillToday ? moment().add(-30, 'm') : end.add(1,'d').startOf('day');
		start = isFromToday ? end.clone().add(-24, 'h') : start.startOf('day');

		let interval = {start, end }
		console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",interval.start.valueOf(), interval.end.valueOf())
		this.setState({
			year: !!year ? year : this.state.year,
			selectedRange: [start, end],
			interval: interval,
			showGisPicker: false
		})
	}

	resetInterval(interval) {

		this.setState({
			interval,
			selectedRange: !!interval ? [interval.start, interval.end] : defaultSelectedRage,
			year: defaultYear,
			showGisPicker: !!interval && !!this.state.selectedMeasure.value  ? false : true
		});
	}

	toggleSidebar(event) {
		if (event==="over") {
			document.getElementById("mySidebar").style.width = "2.5rem";
			document.getElementById("main").style.marginLeft = "1.5rem";
			document.getElementById("sidebar-button").style.color = "#428f81";
		} else {
			document.getElementById("mySidebar").style.width = "1.5rem";
			document.getElementById("main").style.marginLeft = "0";
			document.getElementById("sidebar-button").style.color = chroma('#428f81').alpha(0.3)
		}
	}

	toggleGisPicker = () => {
		if (!this.state.showGisPicker){
			document.getElementById("main").style.marginLeft = '0px';
		}
		this.setState({showGisPicker : !this.state.showGisPicker, interval: null})
	}

	onMobilePickRange(dates){
		let interval = null
		if(dates && dates[0] && dates[1]){
			interval = {}
			interval.start = moment(dates[0].getTime()).startOf('day')
			interval.end = moment(dates[1].getTime()).endOf('day')
		}
		this.resetInterval(interval)
	}

	render() {


		let key = this.state.interval ? JSON.stringify(this.state.interval) :"init" 
		let mobileMode = WidthUtils.isMobile()
		console.log("mobileMode", mobileMode )
		let view = {
			STUB: "STUB",
			CALENDAR: "CALENDAR",
			DATA: "DATA"
		}

		let currentView = view.STUB;

		if (!this.state.interval && !this.state.selectedMeasure.value) {
			currentView = view.STUB;
		}
		else if (!!this.state.selectedMeasure.value && this.state.selectedStation.value && !this.state.interval) {
			currentView = view.CALENDAR;
		}
		else if (!!this.state.selectedMeasure.value && !!this.state.interval) {
			currentView = view.DATA;
		}

		let componentToShow = null;
		switch (currentView) {
			case view.STUB:
				componentToShow = !mobileMode ? <StubComponent></StubComponent> : <></>;
				break;
			case view.CALENDAR:
				componentToShow = !mobileMode ? (
					<div id={"main"}>
						<h3>Seleziona Intervallo di osservazione</h3>
						<BigCalendarTimeZoned
							timeZone={"Etc/GMT-1"} 
							range={this.state.interval} 
							year={this.state.year}
							onPrevYear={() => this.onPrevYear()}
							onNextYear={() => this.onNextYear()}
							onPickRangeCallback={(start, end, year)=> this.onPickRange(start, end, year)}
							lockedClasses={this.state.lockedClasses}
						></BigCalendarTimeZoned>
					</div>) : <div id={"main"}></div>;
				break;
			case view.DATA:
				componentToShow = <div id="main">
					<LandscapeMobileWrapper>
						<MeasurementsComponent
							key={key}
							resetInterval={(interval) => this.resetInterval(interval)}
							interval={this.state.interval}
							selectedMeasure={this.state.selectedMeasure}
							selectedStation={this.state.selectedStation}
							stationsGeoJSON = {this.props.stationsGeoJSON}
							allSensorsMap = {this.props.allSensorsMap}
							outflowScales = {this.props.outflowScales}
						></MeasurementsComponent>

					</LandscapeMobileWrapper>

					
				</div>;
				break;
			default:
				componentToShow = !mobileMode ? <StubComponent></StubComponent> : <></>;
				break;
		}
		return (
			<>
				<div className="row mt-4">
	
					{this.state.showGisPicker ? <div className={mobileMode ? "col-12" : "col-4"}>
						<div className={mobileMode ? "" :"d-none"}>
							<DateRangePicker
								className={"col-12"}
								locale={"it-IT"}
								onChange={(dates) => this.onMobilePickRange(dates)}
								value={[this.state.interval && this.state.interval.start && new Date(this.state.interval.start.valueOf()), this.state.interval && this.state.interval.end && new Date(this.state.interval.end.valueOf())]}
								maxDate={new Date()}
								format={"dd/MM/yyyy"}
								rangeDivider='&nbsp;&nbsp;&nbsp;'
							/>
						</div>
						<StationGisPicker
							centerMapOnSelect={true}
							geoJSON={!!this.state.filteredStationsGeoJson ? this.state.filteredStationsGeoJson : this.props.stationsGeoJSON}
							zoneOptions={this.state.zoneOptions}
							selectedZone={this.state.selectedZone}
							onSelectZone={(option) => this.onSelectZone(option)}
							stationOptions={this.state.stationOptions}
							selectedStation={this.state.selectedStation}
							onSelectStation={(option) => this.onSelectStation(option)}

							showAvailableMeasurements={true}
							availableMesaurements={this.state.availableMesaurements}
							selectedMeasure={this.state.selectedMeasure}
							onSelectMeasure={(option) => this.onSelectMeasure(option)}
							zoneBbox={this.state.zoneBbox}

							mapKey={this.state.mapKey}
							onEachStation={(feature, layer) => this.onEachStation(feature, layer)}
							stationToMarker={(feature, latlng) => this.stationToMarker(feature, latlng)}
							zonesStyle={(feature) => this.zonesStyle(feature)}

						></StationGisPicker>
					</div> : <></>}

					<div id="mySidebar" className="sidebar" style={{display: this.state.showGisPicker ? 'none' : 'block'}}
						 onMouseOver={() => this.toggleSidebar("over")}
						 onMouseOut={() => this.toggleSidebar("out")}
						 onClick={() => this.toggleGisPicker()}>
						<Tooltip open={false} title={"Seleziona un altro sensore"}>
							<><FaAngleLeft style={{height: 'inherit', width: 'inherit'}} id="sidebar-button" color={chroma('#428f81').alpha(0.3)}/></>
						</Tooltip>
					</div>
					<div className={this.state.showGisPicker ? "col-8" : "col-12"}>
						{/*
						<div style={{backgroundColor: '#b3e7ac', width:'1rem'}} onClick={() => this.setState({showGisPicker: !this.state.showGisPicker})}></div>
*/}
						{componentToShow}
					</div>
				</div>
				<div className="row" ></div>
				{!!this.state.selectedMeasure.value && allowedValidationCategory.includes(this.state.selectedMeasure.value) && ApiUtils.getContext() === 'priv'
					?
					<div style={{ position: "fixed", bottom: "1vh", right: "1vw" }}>
						<Fab variant="extended" color="primary" aria-label="add" className="goToValidation"
							 onClick={(e) => this.props.nextStep({
								 interval: this.state.interval,
								 selectedStation: this.state.selectedStation,
								 selectedMeasure: this.state.selectedMeasure

							 })}
							 disabled={!!!this.state.interval || !!!this.state.selectedStation.value || !!!this.state.selectedMeasure.value}>
							Valida misure
							<i className="ml-2 fas fa-chevron-right"></i>
							<i className="fas fa-chevron-right"></i>
						</Fab>
					</div>
					:
					<></>
				}
			</>

		)
	}
}

export default (ViewSection);

function StubComponent() {
	return (
		<div id="main">
			<SkeletonTheme  >
				<div className="row mt-2">
					<div className="col-2 " >
						<Skeleton height={"5vh"} duration={8} />
					</div>
					<div className="col-4 ml-auto" >
						<Skeleton height={"5vh"} duration={8} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-4 ml-auto" >
						<Skeleton height={"5vh"} duration={8} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-12">

						<Skeleton height={"5vh"} duration={8} />


					</div>
				</div>
				<div className="row mt-2">
					<div className="col-12 float-right" >
						<Skeleton height={"60vh"} duration={8} />
					</div>
				</div>
			</SkeletonTheme>
		</div>
	)
}

