import React from 'react';
import NumberUtils from "#/lib/NumberUtils";
import MixedChart from "#/commons/chart/MixedChart";

export default class TestMixedChart extends React.Component {

    render(){
        const sensorCategories = [
            {id: 'P', label: 'Pluviometri', masterColor: 'blue'},
            {id: 'T', label: 'Termometri', masterColor: 'red'},
            {id: 'I', label: 'Igrometri', masterColor: 'green'}
        ];
        const xLabels = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
        const series = [
            {
                label: 'Oriolo (Termometro)',
                color: '#41040d',
                data: xLabels.map((l,i) => NumberUtils.round(Math.random()*20,1)),
                sensorCategory: 'T'
            },
            {
                label: 'Sibari (Termometro)',
                color: '#ba231e',
                data: xLabels.map((l,i) => NumberUtils.round(Math.random()*20,1)),
                sensorCategory: 'T'
            },
            {
                label: 'Sibari (Igrometro)',
                color: '#89e05a',
                data: xLabels.map((l,i) => NumberUtils.round(Math.random()*10,1)),
                sensorCategory: 'I'
            },
            {
                label: 'Paola (Igrometro)',
                color: '#0f7307',
                data: xLabels.map((l,i) => NumberUtils.round(Math.random()*10,1)),
                sensorCategory: 'I'},
            {
                label: 'Albi (Pluviometro)',
                color: '#4b6cb1',
                data: xLabels.map((l,i) => NumberUtils.round(Math.random(),1)),
                sensorCategory: 'P'
            },
            {
                label: 'Albidona (Pluviometro)',
                color: '#3da9ac',
                data: xLabels.map((l,i) => NumberUtils.round(Math.random(),1)),
                sensorCategory: 'P'
            },
            {
                label: 'Oriolo (Pluviometro)',
                color: '#081f51',
                data: xLabels.map((l,i) => NumberUtils.round(Math.random(),1)),
                sensorCategory: 'P'
            }];
        return (
            <div className="generic-page">
                <MixedChart
                    sensorCategories={sensorCategories}
                    xLabels={xLabels}
                    series={series}
                    chartKey={0}
                /></div>
        );
    }

}
