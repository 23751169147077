import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import React, {useEffect, useState} from "react";
import {reactFormatter} from "react-tabulator";
import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";
import DateUtils from "#/lib/DateUtils";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import moment from "moment";


const ConsultaButton = (props) =>  (<Button startIcon={<WarningIcon />} {...props}
                        onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>Consulta</Button>);

const RieseguiButton = (props) =>  (<Button startIcon={<WarningIcon />} {...props}
                                            onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>Riesegui</Button>);


const HecHMSExecutions = ({tableKey, data, onSelectExecution, runExecution, interval, onNewExecutionPickRange}) => {
    const [showNewExecutionModal, toggleNewExecutionModal] = useState(false);
    const [year, setYear] = useState(moment().year());
    useEffect(() => {toggleNewExecutionModal(false)},[data]);
    const columns = [
        { title: "Tipologia", field: "type"},
        { title: "Anno", field: "year"},
        { title: "Mese", field: "month"},
        { title: "Decade", field: "decade"},
        { title: "Da", field: "start", formatter: cell => DateUtils.epochToDateMinuteResolution(cell.getValue()) },
        { title: "A", field: "end", formatter: cell => DateUtils.epochToDateMinuteResolution(cell.getValue()) },
        {
            title: "",
            formatter: reactFormatter(<ConsultaButton clickCallback={(data) => onSelectExecution(data)} variant="outlined" size="small"/> ),
            headerSort:false,
            hozAlign:"center",
            vertAlign: "middle",
            minWidth :"150px"
        },
        {
            title: "",
            formatter: reactFormatter(<RieseguiButton clickCallback={(data) => runExecution(data)} variant="outlined" size="small"/> ),
            headerSort:false,
            hozAlign:"center",
            vertAlign: "middle",
            minWidth :"150px"
        },
    ];

    return <>
        <ReactTabulator
            columns={columns}
            data={data}
            options={{
                height: "60vh"
            }}
            key={"table_" + tableKey}
        />
        {/*NEW EXECUTION DATE PICKER MODAL*/}
        <Modal key={'newExecution'}
               style={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center'
               }}
               open={showNewExecutionModal}
               onClose={() => toggleNewExecutionModal(false)}
               aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description"
        >
            <div
                style={{
                    background: "white",
                    width: "70vw",
                    height: "75vh",
                    borderRadius: '15px'
                }}
            >
                <div className='w-100 d-flex justify-content-end'>
                    <IconButton aria-label="close"
                                onClick={() => toggleNewExecutionModal(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className="d-flex justify-content-center">
                    <h3>Seleziona Intervallo di esecuzione</h3>
                </div>

                <div className="d-flex h-80">
                    <div className="col-sm-12 my-auto">
                        <BigCalendarTimeZoned
                            timeZone={"Etc/GMT-1"}
                            range={null}
                            year={year}
                            onPrevYear={() => setYear(year - 1)}
                            onNextYear={() => setYear(year + 1)}
                            onPickRangeCallback={(start, end, year)=> onNewExecutionPickRange(start, end)}
                            lockedClasses={{
                                notSelectable: day => day.isBefore( interval.start) || day.isAfter( interval.end)
                            }}
                        ></BigCalendarTimeZoned>
                    </div>
                </div>
                <br/>
            </div>
        </Modal>
        <div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
            <Tooltip title="Crea una nuova esecuzione">
                <Fab color="primary" onClick={() => toggleNewExecutionModal(true)}>
                    <AddIcon />
                </Fab>
            </Tooltip>
        </div>
    </>
}

export default HecHMSExecutions;
