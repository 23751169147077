import React from 'react'
import _ from "lodash";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import ChromaticScale from "#/lib/ChomaticScale";
import GISTools from "#/lib/GISTools";
import {GeoJSON} from "react-leaflet";
import LeafletLegend from "#/commons/map/LeafletLegend";
import {
    checkForReturnTimeAnomalies,
    checkRainThresholdsAnomalies,
    DEFAULT_MIDA_STATE,
    getGenericMarkerColor,
    getGeoDataPromise,
    getPromises,
    getRainThresholdSeverity,
    getReturnTimeSeverity,
    hasLinkLost,
    initState,
    LAYER_OPTIONS,
    performIdwPromise,
    THRESHOLD_LABELS,
    updateMidaState
} from "#/lib/MidaUtils";
import MonitoringPluvsThresholdClient from "#/lib/MonitoringPluvsThresholdClient";
import {COMBINED_MEASUREMENT_CATEGORY, MEASUREMENT_LABELS} from "#/lib/MeasurementCategory";
import LeafletWindLegend from "#/commons/map/LeafletWindLegend";
import SwitchComponent from "#/commons/components/SwitchComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import {BUTTON_PROGRESS_STYLE} from "#/backoffice/bim/utils/BIMUtils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import DownloadContainer from "#/commons/components/DownloadContainer";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Update} from "@material-ui/icons";
import DateRangeIcon from '@material-ui/icons/DateRange';
import MidaTables from "#/backoffice/mida/components/MidaTables";
import MidaModals from "#/backoffice/mida/components/MidaModals";
import DatePicker from "react-datepicker";
import it from "date-fns/locale/it";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NumberUtils from "#/lib/NumberUtils";
import * as turf from "@turf/turf";
import Markers from "#/lib/Markers";
import L from "leaflet";
import DateUtils from "#/lib/DateUtils";
import MapComponent from "#/commons/map/MapComponent";
import {VscDebug} from "react-icons/vsc";
import InputSelectComponent from "#/commons/components/InputSelectComponent";


const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

export default class MidaComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_MIDA_STATE,
            selectedDate: null,
            partialDate: null,
            lastUpdate: null,
            intervalId: 0,
            rasterKey: 1,
            tile: 'T1'
        };
    }

    // EXPLAIN: >>>>>>>>>>>>>>>>> STATE RELATED <<<<<<<<<<<<<<<<<<

    componentDidMount() {
        if (this.props.withDateSelection){
            loadingSwal.fire('Caricamento dei dati più recenti...');
            Promise.all(getPromises())
                .then(([cfGumbelParametersMap, cfTCEVParametersMap, geoData, rainThresholds, returnTimeRainThresholds, outflowScales, stationSensorThresholds, sensorClassThresholds, sensorBlacklist]) => {
                    console.log("TEST", returnTimeRainThresholds)
                    let newState = initState(geoData, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds, rainThresholds, stationSensorThresholds, sensorClassThresholds, sensorBlacklist);
                    newState.selectedDate =  new Date(newState.lastRequestTimestamp);
                    this.setState(newState)
                }).catch(errors =>{
                    console.log('Errors from promise all',errors)
                }
            )
        } else {
            Promise.all(getPromises())
                .then(([cfGumbelParametersMap, cfTCEVParametersMap, geoData, rainThresholds, returnTimeRainThresholds, outflowScales, stationSensorThresholds, sensorClassThresholds, sensorBlacklist]) => {

                    let newState = initState(geoData, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds, rainThresholds, stationSensorThresholds, sensorClassThresholds, sensorBlacklist);
                    newState.intervalId = setInterval(() => {
                        console.log("Updating data...")
                        this.updateGeoData()
                    }, 300000);
                    newState.lastUpdate = DateUtils.epochToDateMinuteResolution(newState.lastRequestTimestamp);

                    this.setState(newState, () => {
                        if (newState.badges.length > 0){
                            this.props.showNotifications(newState.badges);
                        }
                        this.props.ready();
                        this.props.lastUpdate(newState.lastUpdate);
                    })
                }).catch(errors =>{
                    console.log('Errors from promise all',errors)
                }
            )
        }
    }
    componentDidUpdate(){
        if (this.props.withDateSelection){
            loadingSwal.close();
        }
    }
    componentWillUnmount() {
        if (!this.props.withDateSelection){
            clearInterval(this.state.intervalId);
        }
    }

    updateGeoData = (timestamp,showConfirmation=false, selectedDate) => {
        /* if(!timestamp && !!this.state.geoData){
             timestamp = this.state.geoData.requestTimestamp;
         }*/

        const lastUpdate = DateUtils.epochToDateMinuteResolution(moment().valueOf())
        if (!this.props.withDateSelection) {
            getGeoDataPromise(timestamp).then(
                geoData => {
                    if (geoData === 'NOT_MODIFIED') {
                        console.log(">>>>>>>>>>>>>>>>> Geo data have not been modified!");

                        this.setState({
                            lastUpdate,
                            lastRequestTimestamp: moment().valueOf()
                        }, () => {
                            if (showConfirmation) {
                                ReactSwal.fire('I dati sono stati aggiornati', '', 'success');
                            }
                            this.props.lastUpdate(lastUpdate);
                        })
                    } else {
                        const {mapKey, tableKey, pointsKey, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, rainThresholds, stationSensorThresholds, sensorClassThresholds, selectedCategory, rainMode, returnTimeRainThresholds} = this.state;
                        let lastRequestTimestamp = moment().valueOf();
                        let updatedState = updateMidaState(mapKey, tableKey, pointsKey, geoData, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, rainThresholds, returnTimeRainThresholds, stationSensorThresholds, sensorClassThresholds, rainMode, selectedCategory, lastRequestTimestamp);
                        updatedState.lastUpdate = DateUtils.epochToDateMinuteResolution(lastRequestTimestamp);

                        this.setState(updatedState, () => {
                            console.log(">>>>>>>>>>>>>>>>> Geo data are now up to date!")
                            if (updatedState.badges.length > 0) {
                                this.props.showNotifications(updatedState.badges)
                            }
                            if (showConfirmation) {
                                ReactSwal.fire('I dati sono stati aggiornati', '', 'success')
                            }
                            this.props.lastUpdate(lastUpdate);
                            if (selectedCategory !== 'P') {
                                this.performInterpolation(selectedCategory)
                            }
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("Error while updating data", error);
                    this.setState({
                        lastUpdate: DateUtils.epochToDateMinuteResolution(moment().valueOf())
                    })
                }
            )
        } else {
            console.log('timestamp', timestamp)
            getGeoDataPromise(timestamp).then(
                geoData => {
                    timestamp = timestamp || moment().valueOf();
                    if (geoData === 'NOT_MODIFIED'){
                        console.log(">>>>>>>>>>>>>>>>> Geo data have not been modified!")
                        this.setState({
                            partialDate: null,
                            selectedDate,
                            lastRequestTimestamp:timestamp // FIXME:
                        }, () => ReactSwal.fire('I dati sono stati aggiornati', '', 'success'))
                    } else {
                        const {mapKey, tableKey, pointsKey, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, rainThresholds, stationSensorThresholds, sensorClassThresholds, selectedCategory, rainMode, returnTimeRainThresholds} = this.state;

                        let updatedState = updateMidaState(mapKey, tableKey, pointsKey, geoData, outflowScales, cfGumbelParametersMap, cfTCEVParametersMap, rainThresholds, returnTimeRainThresholds, stationSensorThresholds, sensorClassThresholds, rainMode, selectedCategory, timestamp);
                        updatedState.selectedDate = selectedDate;
                        updatedState.partialDate = null;

                        this.setState(updatedState, () => {
                            console.log(">>>>>>>>>>>>>>>>> Geo data are now up to date!")
                            ReactSwal.fire('I dati sono stati aggiornati', '', 'success')
                            if (selectedCategory !== 'P') {
                                this.performInterpolation(selectedCategory)
                            }
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("Error while updating data or data is up to date", error);
                    ReactSwal.fire('Nessuna misura trovata per l\'istante selezionato','','error');
                    this.setState({
                        mapKey: (this.state.mapKey + 1) % 1000,
                        tableKey: (this.state.tableKey + 1) % 1000,
                        pointsKey: (this.state.pointsKey + 1) % 1000,
                        geoDataByCategory: {},
                        dataByStationCode: {},
                        eventsByCategory: {},
                        interpolatedDataCache: {}
                    })
                }
            )
        }
    }
    performInterpolation = layerCategory => {

        performIdwPromise(layerCategory, this.state.interpolatedDataCache, this.state.geoData)
            .then(interpolatedData => this.setState({
                currentLayer: layerCategory,
                mapKey: (this.state.mapKey + 1) % 1000,
                rasterKey: (this.state.rasterKey + 1) % 1000,
                legendKey: (this.state.legendKey + 1) % 1000,
                loading: false,
                ...interpolatedData
            }));
    }

    onCategoryChange = sensorCategory => {
        console.log("Sensor category has been changed: ", sensorCategory);
        const categoryInfo = sensorCategory.split('_');
        let selectedCategory = categoryInfo[0];
        let rainMode = categoryInfo[1];

        if (sensorCategory === 'ALL'){
            // TODO:
        } else {
            this.removeLegend();
            this.setState({
                    selectedCategoryOption: sensorCategory,
                    selectedCategory,
                    selectedGeoData: this.state.geoDataByCategory[selectedCategory],
                    selectedEvents: this.state.eventsByCategory[selectedCategory],
                    ...(!!rainMode && {rainMode}),
                    currentLayer: 'NONE',
                    interpolatedData: {},
                    mapKey: (this.state.mapKey + 1) % 1000,
                    legendKey: (this.state.legendKey + 1) % 1000}
                , () => {
                    if (selectedCategory !== 'P'){
                        this.performInterpolation(selectedCategory);
                    }
                })
        }
    }

    onEachFeatureStations(feature, layer, context) {
        layer.bindTooltip(`<b>${feature.properties.name}</b>`).on('tooltipopen', () => {
            if (this.state.showInfoModal){
                layer.closeTooltip()
            }
        })
        layer.on("add",  (event) => {
            if (this.state.selectedStationCode === feature.properties.code){
                this.openInfoModal(feature)
            }
        });
    }
    onEachWindFeatureStations = (feature, layer, context) => {}

    onEachInterpolatedFeatureStations(feature, layer, context) {
        layer.bindPopup(`<p>
					lat: ${NumberUtils.round(turf.center(feature).geometry.coordinates[0], 6)} </br>
					lon: ${NumberUtils.round(turf.center(feature).geometry.coordinates[1], 6)} </br>
					Valore Interpolato: ${NumberUtils.round(feature.properties[this.state.currentLayer], 2)}
							</p>`);
    }

    pointToMarker(feature, latlng, context) {
        const {properties: {code : stationCode, timestamp, sensorCode}, properties} = feature;
        const {selectedCategory, selectedEvents} =this.state;
        const color = selectedCategory === 'P' ? this.getRainMarkerColor(properties, this.state.rainThresholds[sensorCode] || this.state.rainThresholds['GENERIC']) : getGenericMarkerColor(stationCode, timestamp, selectedEvents, this.state.lastRequestTimestamp);
        return this.createMarker(feature, latlng, color);
    }

    createMarker = (feature, latlng, color, borderColor = 'grey') => {
        if (this.state.sensorBlacklist.find(item => item.code === feature.properties.sensorCode)){
            color = 'black';
        }
        let marker = Markers.getMarker(latlng, color,borderColor).on('click', () => {
            this.openInfoModal(feature);
            marker.setIcon(new L.Icon({
                iconSize: [40, 40],
                iconUrl: '/img/marker_point_selected_new.png',
                popupAnchor: [0, 0]
            }))
        });
        if (this.state.selectedStationCode ===  feature.properties.code){
            marker.setIcon(new L.Icon({
                iconSize: [40, 40],
                iconUrl: '/img/marker_point_selected_new.png',
                popupAnchor: [0, 0]
            }))
        }
        return marker;
    }

    windPointToMarker = (feature, latlng, context) =>
        Markers.getWindMarker(latlng,
            feature.properties[this.state.selectedCategory.replace('W','D')],
            feature.properties[this.state.selectedCategory.replace('W','V')]);
    getRainMarkerColor = (props,
                          rainThresholds = this.state.rainThresholds,
                          returnTimeRainThresholds = this.state.returnTimeRainThresholds,
                          cfGumbelParametersMap = this.state.cfGumbelParametersMap,
                          cfTCEVParametersMap = this.state.cfTCEVParametersMap,
                          lastRequestTimestamp = this.state.lastRequestTimestamp) => hasLinkLost(props.timestamp, lastRequestTimestamp) ? 'grey' : (this.state.rainMode === 'RT' ? getReturnTimeSeverity(props, cfGumbelParametersMap, cfTCEVParametersMap, returnTimeRainThresholds) : getRainThresholdSeverity(props, rainThresholds));


    onBaseLayerChange = (layerCategory) => {
        console.log("LAYER VALUE", layerCategory)
        if (layerCategory !== 'NONE'){
            this.setState({loading : true},() =>this.performInterpolation(layerCategory));
        } else {
            this.removeLegend();
            this.setState({
                currentLayer: layerCategory,
                interpolatedData: {},
                mapKey: (this.state.mapKey + 1) % 1000,
                legendKey: (this.state.legendKey + 1) % 1000})
        }
    }

    onToggleShowStations = ()=>{
        this.setState({
            showStations : !this.state.showStations
        })
    }

    removeLegend(){
        let tmp = document.getElementsByClassName("newLegend");
        if (!!tmp && tmp.length > 0) {
            tmp[0].remove()
        };
    }


    getServerityLegendTitle(){
        switch(this.state.rainMode){
            case 'RT':
                return "Tempi di ritorno"
            case 'TH':
                return "Soglie"
            default :
                return "Tempi di ritorno"
        }

    }

    getLegendColorFunction(){
        switch(this.state.rainMode){
            case 'RT':
                return (g)=>{return MonitoringPluvsThresholdClient.getColorByReturnTime(g,this.state.returnTimeRainThresholds)}
            case 'TH':
                return (i)=>{return MonitoringPluvsThresholdClient.getThresholdColors()[i]}
            default :
                return (i)=>{return "grey"}
        }
    }

    openThresholdDefinitioModal(rainMode){
        if(rainMode === 'TH'){
            this.openThresholdModal()
        }
        if(rainMode === 'RT'){
            this.openReturnTimeThresholdModal()
        }
    }

    openThresholdModal(){
        this.setState({
            showThresholdModal : true
        })
    }

    openReturnTimeThresholdModal(){
        this.setState({
            showReturnTimeThresholdModal : true
        })
    }

    openInfoModal(selectedFeature){
        this.setState({
            showInfoModal : true,
            selectedFeature
        })

    }

    toggleAnomalies = () => {
        this.setState({
            showAnomalies : !this.state.showAnomalies,
            tableKey: this.state.tableKey + 1 })
    }

    handleChangeDate = (selectedDate) => {
        if (this.state.selectedDate.toLocaleTimeString() !== selectedDate.toLocaleTimeString()){
            const {partialDate} = this.state;
            let date = partialDate || selectedDate;
            let newSelectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), selectedDate.getHours(), selectedDate.getMinutes());
            loadingSwal.fire("Caricamento dei dati in corso...");
            this.updateGeoData(DateUtils.getGMT1DateFromLocalDate(newSelectedDate).getTime(), false, newSelectedDate)
        } else {
            this.setState({partialDate: selectedDate})
        }
    }

    rowClickListener = (code, pointsKey) => {
        this.setState({
            selectedStationCode : code
        },() => {
            this.setState({
                pointsKey: (pointsKey + 1) % 1000
            })
        })
    }

    closeInfoModal(){
        this.setState({
            showInfoModal : false,
            selectedFeature : null,
            selectedStationCode: null,
            pointsKey : (this.state.pointsKey + 1) % 1000
        })
    }

    onThresholdsSave(rainThresholds){
        const newRainThresholds = {...this.state.rainThresholds, GENERIC: rainThresholds};
        const {rainThresholdsEvents} = checkRainThresholdsAnomalies(this.state.selectedGeoData, newRainThresholds);
        this.setState({
            rainThresholds: newRainThresholds,
            pointsKey: (this.state.pointsKey + 1) % 1000,
            tableKey: this.state.tableKey + 1,
            rainThresholdsEvents,
            showThresholdModal : false
        })
    }

    onReturnTimeThresholdsSave(returnTimeRainThresholds){
        const {rainReturnTimeEvents} = checkForReturnTimeAnomalies(this.state.selectedGeoData, this.state.cfGumbelParametersMap, this.state.cfTCEVParametersMap, returnTimeRainThresholds);
        this.setState({
            returnTimeRainThresholds: returnTimeRainThresholds,
            pointsKey: (this.state.pointsKey + 1) % 1000,
            tableKey: this.state.tableKey + 1,
            legendKey: (this.state.legendKey + 1) % 1000,
            rainReturnTimeEvents,
            showReturnTimeThresholdModal : false
        })
    }

    closeThresholdModal() {
        this.setState({
            showThresholdModal: false
        })
    }

    closeReturnTimeThresholdModal() {
        this.setState({
            showReturnTimeThresholdModal: false
        })
    }

    // EXPLAIN: >>>>>>>>>>>>>>>>> RENDER RELATED <<<<<<<<<<<<<<<<<<
    gradientStyle = feature => {
        const {selectedCategory, currentLayer} = this.state;
        const scale = selectedCategory === 'P' ?
            ChromaticScale.getRainScaleByTimescale(currentLayer) :
            ChromaticScale.getScaleBySensorCategory(selectedCategory);
        const color = scale(feature.properties[currentLayer]);
        return {
            fillColor: color,
            weight: 0.3,
            color: "transparent",
            opacity: 1,
            fillOpacity: 0.7
        };
    };
    // FIXME: Add black for missing ossing Gumbul params
    extraLegendItem = selectedCategory => selectedCategory === 'P' &&
        `<tr>
				<td style="min-width: 20px; background-color: grey"></td>
				<td style="padding-left: 10px">Non funzionante</td>
			</tr>`;
    getInterpolatedLayer = () => {
        const {interpolatedData, selectedCategory, currentLayer, rasterKey} = this.state;
        return !_.isEmpty(interpolatedData) ?
            <GeotiffRasterLayer
                key={"raster_" + rasterKey}
                georaster={interpolatedData}
                opacity={0.5}
                resolution={256}
                colorScale={selectedCategory === 'P' ? ChromaticScale.getRainScaleByTimescale(currentLayer) : ChromaticScale.getScaleBySensorCategory(selectedCategory)}
                handleClick={(val, latlng) => GISTools.showPopup(val, latlng, this.mapRef.leafletElement)}
            /> : <></>;
    }

    switchMarkersBySelectedCategory = () => {
        const {selectedGeoData, selectedCategoryOption, pointsKey, showStations} = this.state;
        return !_.isEmpty(selectedGeoData) &&
            (
                <>
                    {!!showStations &&
                    <>
                        {['WR', 'WS', 'WV'].includes(selectedCategoryOption) &&
                        <GeoJSON key={`windArrows_${selectedCategoryOption}_${pointsKey}`}
                                 data={selectedGeoData}
                                 onEachFeature={(feature, layer, context) => this.onEachWindFeatureStations(feature, layer, this)}
                                 pointToLayer={(feature, latlng, context) => this.windPointToMarker(feature, latlng, this)}
                        />}
                        <GeoJSON key={`${selectedCategoryOption}_${pointsKey}`}
                                 data={selectedGeoData}
                                 onEachFeature={(feature, layer, context) => this.onEachFeatureStations(feature, layer, this)}
                                 pointToLayer={(feature, latlng, context) => this.pointToMarker(feature, latlng, this)}
                        />
                    </>
                    }
                    {this.getInterpolatedLayer()}
                </>);
    }

    switchLegendBySelectedLayer = () =>  {
        const {selectedGeoData, selectedCategory, rainMode, legendKey, currentLayer, mapKey, showLegend, returnTimeRainThresholds} = this.state;
        let legends = [];
        if (!showLegend){
            return legends;
        }
        if (!_.isEmpty(selectedGeoData)) {
            if (selectedCategory === 'P') {
                legends.push(
                    <LeafletLegend
                        key={"legend_severity_" + rainMode + "_" + legendKey}
                        replaceMode={true}
                        legendTitle={this.getServerityLegendTitle()}
                        extraLabels={this.extraLegendItem(selectedCategory)}
                        labels={rainMode === 'TH' ? THRESHOLD_LABELS : null}
                        grades={rainMode === 'RT' ? returnTimeRainThresholds : null}
                        onLegendClick={rainMode === 'TH' || rainMode === 'RT' ? () => this.openThresholdDefinitioModal(rainMode) : null}
                        getColor={this.getLegendColorFunction()}/>
                );

                if (currentLayer !== 'NONE') {
                    legends.push(
                        <LeafletLegend
                            key={"legend_rain_" + legendKey}
                            replaceMode={true}
                            legendTitle={LAYER_OPTIONS.find(lo => lo.value === currentLayer).legendLabel}
                            grades={ChromaticScale.getRainDomainByTimescale(currentLayer)}
                            getColor={(num) => ChromaticScale.getRainScaleByTimescale(currentLayer)(num)}/>
                    )
                }
            } else if (Object.keys(COMBINED_MEASUREMENT_CATEGORY).includes(selectedCategory)) {
                legends.push(
                     <LeafletWindLegend
                        key={"wind_legend_" + mapKey}
                        replaceMode={true}
                    />
                )
            } else {
                legends.push(
                    selectedCategory === 'I' ? <></> :
                    <LeafletLegend
                        key={"legend_" + legendKey}
                        replaceMode={true}
                        legendTitle={MEASUREMENT_LABELS[['WV', 'WS', 'WR'].includes(selectedCategory) ? selectedCategory.replace('W', 'V') : selectedCategory]}
                        grades={ChromaticScale.getDomainBySensorCategory(selectedCategory)}
                        getColor={(num) => ChromaticScale.getScaleBySensorCategory(selectedCategory)(num)}/>
                )
            }
        }
        return legends;
    }

    dumpGeoJson = () => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.state.geoData)));
        element.setAttribute('download', "dump.json");

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    render(){
        const {selectedGeoData,
            selectedCategory,
            selectedEvents,
            loadingTab,
            tableKey,
            rainMode,
            rainReturnTimeEvents,
            rainThresholdsEvents,
            pointsKey,
            showInfoModal,
            selectedFeature,
            dataByStationCode,
            rainThresholds,
            cfGumbelParametersMap,
            cfTCEVParametersMap,
            showThresholdModal,
            outflowScales,
            stationSensorThresholds,
            selectedCategoryOption,
            currentLayer,
            loading,
            selectedDate,
            lastUpdate,
            showStations,
            optionList,
            partialDate,
            sensorBlacklist,
            returnTimeRainThresholds,
            showReturnTimeThresholdModal
        } = this.state;
        const {withDateSelection} = this.props;
        let layerOptions = LAYER_OPTIONS.map(o=> ({...o, displayValue: o.label}));
        return loadingTab ? Stub : (
            <>
                <div className="row">
                    <div className="col-4 custom-popup mt-3">
                        <div className="row">
                            <div className="col-7">
                                <InputSelectComponent
                                    className={"mt-4 col-12 row justify-content-center"}
                                    placeholder={"Seleziona una categoria"}
                                    isSearchable={true}
                                    optionList={optionList}
                                    value={selectedCategoryOption }
                                    onChange={selectedOption=> this.onCategoryChange(selectedOption)}
                                />
                            </div>
                            {selectedCategory === 'P' && rainMode === 'TH' &&
                            <>
                                <div className="col-4">
                                    <InputSelectComponent
                                        className={"mt-4 p-0 col-12 row justify-content-center"}
                                        placeholder={"Seleziona un layer"}
                                        optionList={layerOptions}
                                        value={currentLayer || null }
                                        onChange={selectedOption=> this.onBaseLayerChange(selectedOption)}
                                    />
                                </div>
                                <div className="col-1">
                                    {loading && <CircularProgress size={24} style={BUTTON_PROGRESS_STYLE}/>}
                                </div>
                            </>}

                        </div>

                        <DownloadContainer
                            extra = {
                                <>
                                    <HideStationSwitchForm
                                        label={"Mostra Stazioni"}
                                        showStations={showStations}
                                        disabled={!!!selectedCategory}
                                        onToggleShowStations = {this.onToggleShowStations}
                                    />
                                </>
                            }
                            imageName={selectedCategory+" map"}
                            disabled={!!!selectedCategory}
                        >
                            <MapComponent
                                width={"100%"}
                                height={"65vh"}
                                minZoom={7}
                                maxZoom={12}
                                setMapRef={mapRef => this.mapRef = mapRef}
                            >
                                {/* ----------------------- MARKERS ----------------------- */}
                                {this.switchMarkersBySelectedCategory()}
                                {/* ----------------------- LEGENDS ----------------------- */}
                                {this.switchLegendBySelectedLayer()}
                            </MapComponent>
                        </DownloadContainer>

                    </div>

                    <div className="col-8 custom-popup">
                        <div className="my-4 d-flex justify-content-between">
                            <div className="row">
                                {withDateSelection ? <>
                                    <h2 className="my-auto">Istante di riferimento:&nbsp;&nbsp;</h2>
                                    <div className="my-auto d-flex justify-content-center align-items-center">
                                        {!!selectedDate ?
                                            <DatePicker
                                                locale={it}
                                                selected={partialDate || selectedDate}//DateUtils.getLocalDateFromGMT1Date(selectedDate)
                                                onChange={(date) => this.handleChangeDate(date)}
                                                maxDate={new Date(moment().valueOf())}

                                                showTimeSelect={true}
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption="Ora"
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                customInput={<CustomInput ref={React.createRef()}/>}
                                            /> : <></>}
                                    </div>
                                </> : <>
                                    <h2>Ultimo aggiornamento: {lastUpdate}</h2>
                                    <Tooltip title={"Aggiorna adesso"}>
                                        <IconButton color="primary" aria-label="upload picture" component="span"
                                                    onClick={(e) => this.updateGeoData(null, true)}>
                                            <Update/>
                                        </IconButton>
                                    </Tooltip>
                                </>}
                            </div>
                        </div>
                        {withDateSelection ? <>
                                <div className="row ml-auto">
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="default"
                                        startIcon={<Update />}
                                        onClick={(e) => {
                                            loadingSwal.fire('Caricamento dei dati più recenti...');
                                            this.updateGeoData(null, true);
                                        }}
                                    >
                                        Ultimi dati disponibili
                                    </Button>
                                </div>
                                <h3>Dati di misurazione</h3>
                            </> :
                            <h3>Dati in tempo reale</h3>}
                        <MidaTables
                            {...{
                                selectedGeoData,
                                selectedCategory,
                                selectedEvents,
                                tableKey,
                                rainMode,
                                rainReturnTimeEvents,
                                rainThresholdsEvents,
                                pointsKey
                            }}
                            rowClickListener={(code, pointsKey) => this.rowClickListener(code, pointsKey)}
                        />
                    </div>
                </div>
                <MidaModals
                    {...{showInfoModal,
                        selectedFeature,
                        dataByStationCode,
                        selectedCategory,
                        rainThresholds,
                        returnTimeRainThresholds,
                        cfGumbelParametersMap,
                        cfTCEVParametersMap,
                        showThresholdModal,
                        showReturnTimeThresholdModal, 
                        outflowScales,
                        stationSensorThresholds,
                        sensorBlacklist
                    }}
                    closeInfoModal={() => this.closeInfoModal()}
                    onThresholdsSave={(rainThresholds) => this.onThresholdsSave(rainThresholds)}
                    onReturnTimeThresholdsSave={(returnTimeRainThresholds) => this.onReturnTimeThresholdsSave(returnTimeRainThresholds)}
                    closeThresholdModal={() => this.closeThresholdModal()}
                    closeReturnTimeThresholdModal={() => this.closeReturnTimeThresholdModal()}/>

            </>
        )
    }
}


const Stub = (<SkeletonTheme>
    <div className="row">
        <div className="col-4">
            <Skeleton height={"70vh"} duration={8}/>
        </div>
        <div className="col-8">
            <Skeleton height={"70vh"} duration={8}/>
        </div>
    </div>
</SkeletonTheme>);

const CustomInput = React.forwardRef(({ value, onClick }, ref ) => (
        <Button
            ref={ref}
            size="small"
            variant="contained"
            color="default"
            startIcon={<DateRangeIcon />}
            onClick={onClick}
        >
            {value}
        </Button>

    )
);

const HideStationSwitchForm = (props) => {
    return (
        <>
            <SwitchComponent
                name={props.label}
                value={props.showStations}
                disabled={props.disabled}
                onChange={(e) => props.onToggleShowStations()}
            />
        </>
    )
}

