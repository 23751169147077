import React from 'react';
import Swal from 'sweetalert2';
import {useHistory} from "react-router-dom";
import withReactContent from 'sweetalert2-react-content';
import { useForm } from "react-hook-form";
import UserClient from '#/lib/UserClient';
import TelegramClient from '#/lib/TelegramClient';
import AuthenticationService from '#/lib/AuthenticationService';

import { HeaderForm } from '#/commons/components/forms/HeaderForm';
import { TextFieldForm } from '#/commons/components/forms/TextFieldForm';
import { ResetButton } from '#/commons/components/forms/ResetButton';
import { SaveButton } from '#/commons/components/forms/SaveButton';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WarningIcon from '@material-ui/icons/Warning';


const ReactSwal = withReactContent(Swal);


export default class AccountInfoPage extends React.Component {

	formContent = {}

	constructor(props) {
		super(props);
		this.state = {
			username: AuthenticationService.getLoggedInUsername(),
			checkedRolesPermessions: [],
            userAnag: {},
            telegramInfo: {}
		}
	}

	componentDidMount() {
		if(!!this.state.username){
			let params = {
				username : this.state.username
			}
			UserClient.getUserRolesPermissions (
				(result) => {
					const checkedRolesPermessions = this.getCheckedRolesPermissions(result.roles);
					this.setState({
						userAnag : result.userAnag || {},
						checkedRolesPermessions : checkedRolesPermessions
					})
				},
				(msg) => {
					ReactSwal.fire(
						'Errore nella verifica dei permessi utente',
						msg,
						'error'
					)
				},
				params
            );
            TelegramClient.getUserInfo(
				(result) => {
					this.setState({
                        telegramInfo: result || {}
					})
				},
				(msg) => {
					ReactSwal.fire(
						'Errore nel recupero delle informazioni dell\'account telegram',
						msg,
						'error'
					)
				},
				params
            );
		}
	}


	getCheckedRolesPermissions = (roles) =>{
		let rolesPermissions = [];
		roles.forEach ( role => {
			role.permissions.forEach( permission => {
				rolesPermissions.push(role.roleName + '|' + permission.permissionName);
			})
		})
		return rolesPermissions;
	}

	render = () => {
		const userAnag = this.state.userAnag;
		const userRoles = this.state.checkedRolesPermessions;
        const modifyExistingUser = !!this.state.username;
        
        const tgInfo = this.state.telegramInfo;
        
		return (
			<div className="generic-page">
				<div className="container">
					<HeaderForm title = {`Telegram `} />
					{modifyExistingUser && !!userAnag.name ?
						<UserForm 
							telegramInfo={tgInfo}
							userAnag={userAnag} 
							userRoles={userRoles} 
							modifyExistingUser={modifyExistingUser}/>:
						!modifyExistingUser && <UserForm userAnag={userAnag} telegramInfo={tgInfo} userRoles={userRoles} modifyExistingUser={modifyExistingUser}/>
					}
				</div>

			</div>

			)
	}
}


const UserForm = (props) => {
	const tgInfo = props.telegramInfo;
	const defaultValues = '';
	const { register, handleSubmit, setValue, reset  } = useForm({defaultValues: defaultValues});
	const history = useHistory();

	const resetForm = () => {
		reset({ testMessage: defaultValues });
	}

	const onSubmit = (data) => {

		const params = {userName : tgInfo.userName,...data};
		//console.log('params: ', JSON.stringify(params));

		TelegramClient.sendTestmessage(
			(result) => {
				ReactSwal.fire('Telegram','Messaggio di prova spedito con successo','success');
				history.push("/backoffice/user/account/info");
			},
			(msg)  => {
				console.log(msg);
				ReactSwal.fire('Errore nella spdizione del messaggio di prova', !!msg ? msg.message : '', 'error');

			},
			params
		)
	}

	return (
		<form onSubmit = {handleSubmit(onSubmit)} autoComplete="off">
		<Card>
			<CardHeader title="Info Account" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
			<Divider/>
			<CardContent>
				<Grid container spacing={10}>
					<Grid item xs={12}>
						<label title="Username">Username: <span>{tgInfo.userName}</span></label><br/>
						<label title="Nome BOT">Nome BOT: <span>{tgInfo.botName}</span></label><br/>
						<label title="Secret">Secret: <span>{tgInfo.secret}</span></label><br/>
						{ tgInfo.active ? 
							<span><VerifiedUserIcon color="primary" /> Secret code verificato</span>:
							<span><WarningIcon color="error" /> Inserire il secret code nel bot telegram</span>							
						}
					</Grid>
				</Grid>
			</CardContent>
		</Card>

		<Card>
			<CardHeader title="Messaggio di prova" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
			<Divider/>
			<CardContent>
				<Grid container spacing={10}>
					<Grid item xs={12}>
						<TextFieldForm
							id = "testMessage"
							label = "Inserisci testo di prova"
							inputRef={register()}
							handleClearButton={() => setValue('testMessage', null)}
							type="text"
						/>
					</Grid>
				</Grid>

			</CardContent>
		</Card>

		<Grid container justify="center" alignItems="center" className="mt-1">
			<ResetButton variant="contained" size="large" onClick={() => resetForm()}/>
			<SaveButton variant="contained" size="large" type="submit"/>
		</Grid>

		</form>

	);
}
