import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class RainStatsClient {


	static getCfByStationCodeAndDistribution(stationCode, distribution, stats, okCallback, errCallback) {
		let url = new URL(properties.url.rainStatsGetCfByStationCodeAndDistribution +"/"+ distribution +"/"+stationCode);
		let fetchOptions = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: !!stats ? JSON.stringify(stats) : null
		}
		APIUtils.getFetchResponse(url, fetchOptions, okCallback, errCallback);
	}


	static getCppByStationCodeAndDistribution(stationCode, distribution, tempiDiRitorno, stats, okCallback, errCallback) {
		let url = new URL(properties.url.rainStatsGetCppByStationCodeAndDistribution +"/"+ distribution +"/"+ stationCode);
		let fetchOptions = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify({tempiDiRitorno, stats})
		}
		APIUtils.getFetchResponse(url, fetchOptions, okCallback, errCallback);
	}
	

	static getAllCfGumbel(okCallback, errCallback) {

		let url = new URL(properties.url.rainStatsGumbelGetCf);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}


	static getAllCfTCEV(okCallback, errCallback) {

		let url = new URL(properties.url.rainStatsTCEVGetCf);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}



	static getZonalSPI(yearLimit, monthLimit, identifier, zonalGeoJson, okCallback, errCallback){
		let url = new URL(properties.url.zonalSPI + "/" + yearLimit+ "/" + monthLimit) + "/" + identifier;
		let fetchOptions = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(zonalGeoJson)
		}

		APIUtils.getFetchResponse(url, fetchOptions, okCallback, errCallback);
	}

	static getZonalAndLocalSPI(yearLimit, monthLimit, identifier, zonalGeoJson, okCallback, errCallback){
		let url = new URL(properties.url.zonalAndLocalSPI + "/" + yearLimit+ "/"+ monthLimit) + "/" + identifier;
		let fetchOptions = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(zonalGeoJson)
		}

		APIUtils.getFetchResponse(url, fetchOptions, okCallback, errCallback);
	}
	static getZonalStats(selectedRange, yearLimit, monthLimit, identifier, zonalGeoJson, okCallback, errCallback){
		let url = new URL(properties.url.zonalStats + "/" + selectedRange[0] + "/" + selectedRange[1] + "/" + yearLimit+ "/" + monthLimit) + "/" + identifier;
		let fetchOptions = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(zonalGeoJson)
		}

		APIUtils.getFetchResponse(url, fetchOptions, okCallback, errCallback);
	}

	static getLastMonthCumulatedRain(yearLimit, monthLimit, okCallback, errCallback){
		let url = new URL(properties.url.lastMonthCumulatedRain + "/" + yearLimit+ "/" + monthLimit) ;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	

}
