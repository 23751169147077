import React from 'react'
import {
    COMBINED_MEASUREMENT_LABELS,
    getSortOrderByCategory,
    getUnitByCategory,
    MEASUREMENT_CATEGORY_ICONS,
    MEASUREMENT_CATEGORY_SORT,
    MEASUREMENT_LABELS
} from "#/lib/MeasurementCategory";
import _ from 'lodash';
import Fab from "@material-ui/core/Fab";
import DateUtils from "#/lib/DateUtils";
import MonitoringPluvsThresholdClient from "#/lib/MonitoringPluvsThresholdClient";
import NumberUtils from "#/lib/NumberUtils";
import {GiPentagramRose} from "react-icons/gi";
import {FiNavigation, FiWind} from "react-icons/fi";
import Tooltip from "@material-ui/core/Tooltip";
import Markers from "#/lib/Markers";

import {TiWarning} from "react-icons/ti";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import {AiOutlineStop} from "react-icons/ai";

const borderStyle = {
    borderColor: '#2b353f',
    borderRadius: '70px',
    borderStyle: 'solid'
}
const getFontColorByBackground = background => background === 'yellow' ? '#737070' : '#fff';
const colouredColumnStyle = backgroundColor => ({
    ...borderStyle,
    backgroundColor,
    color: getFontColorByBackground(backgroundColor)
})

const getSortedCategories = (selectedCategory) => {
    let allCat = _.cloneDeep(MEASUREMENT_CATEGORY_SORT);
    allCat[selectedCategory] = -getSortOrderByCategory(selectedCategory);
    let orderedCat = Object.keys(allCat).sort((k1, k2) => allCat[k1] - allCat[k2]);
    return orderedCat;
}
const blacklistIcon =
    <span style={{float: 'right'}} className="mr-1">
        <Tooltip title={'Questo valore non viene considerato ai fini di analisi'}>
            <b style={{color: 'black'}}>BLACKLISTED</b>
    </Tooltip>

    </span>
const buildHeader = (openModal, name, stationCode, sensorCode, category, timestamp) =>
    <React.Fragment key={category + "_" + stationCode + "_header"}>

        {['WV','WS','WR'].includes(category) ? <>
                <Tooltip title="Apri grafico intensità">
                    <Fab variant="extended"
                         style={{float: 'right', marginRight: '1vw', backgroundColor: '#2b7943'}}
                         color="primary" aria-label="add"
                         onClick={() => openModal(name, stationCode, sensorCode[category.replace('W','V')], category.replace('W','V'), timestamp)}>
                        <FiWind style={{fontSize: 'x-large'}}/>
                    </Fab>
                </Tooltip>
                <Tooltip title="Apri grafico direzione">
                    <Fab variant="extended"
                         style={{float: 'right', marginRight: '1vw', backgroundColor: '#2b7943'}}
                         color="primary" aria-label="add"
                         onClick={() => openModal(name, stationCode, sensorCode[category.replace('W','D')], category.replace('W','D'), timestamp)}>
                        <FiNavigation  style={{fontSize: 'x-large'}}/>
                    </Fab>
                </Tooltip>
                <Tooltip title="Apri rosa dei venti">
                    <Fab variant="extended"
                         style={{float: 'right', marginRight: '1vw', backgroundColor: '#2b7943'}}
                         color="primary" aria-label="add"
                         onClick={() => openModal(name, stationCode, sensorCode, category, timestamp)}>
                        <GiPentagramRose  style={{fontSize: 'x-large'}}/>
                    </Fab>
                </Tooltip></>
            :
            <Tooltip title="Apri grafico">
                <Fab variant="extended"
                     style={{float: 'right', marginRight: '1vw', backgroundColor: '#2b7943'}}
                     color="primary" aria-label="add"
                     onClick={() => openModal(name, stationCode, sensorCode, category, timestamp)}>
                    <i className="fa fa-line-chart"></i>
                </Fab>
            </Tooltip>}
        <h2 style={{color: '#fff'}}>{MEASUREMENT_CATEGORY_ICONS[category]}{{...MEASUREMENT_LABELS, ...COMBINED_MEASUREMENT_LABELS}[category]}</h2>
    </React.Fragment>



const buildBody = (category, timestamp, value, color, isBlacklisted) =>
    <React.Fragment key={category + "_"}>
        <hr style={{margin: 0, height: '4vh'}}/>
        <table className="textAlignCenter" style={{minWidth: '100%'}}>
            <tbody>
            <tr>
                <th style={{width: '50%'}}>Ultima misurazione</th>
                <th style={{paddingRight: '10px', width: '50%'}}>Ultimo valore misurato</th>
            </tr>
            <tr>
                {value !== 'nd' ? <td>{DateUtils.epochToDateMinuteResolution(timestamp)} {color === 'grey' &&
                    <TiWarning style={{fontSize: 'x-large', color: 'DarkOrange'}}/>}</td> :
                    <td style={{color: 'red'}}>Più di due ore fa <TiWarning style={{fontSize: 'x-large', color: 'red'}}/></td>
                }
                <td style={colouredColumnStyle(color)}>{value} {value !== 'nd' && getUnitByCategory(category)} {isBlacklisted && blacklistIcon}</td>
            </tr>
            </tbody>
        </table>
    </React.Fragment>;

const buildHydroBody = (category, timestamp, value, color) =>
    <React.Fragment key={category + "_"}>
        <hr style={{margin: 0, height: '4vh'}}/>
        <table className="textAlignCenter" style={{minWidth: '100%'}}>
            <tbody>
            <tr>
                <th style={{width: '33%'}}>Ultima misurazione</th>
                <th style={{paddingRight: '10px', width: '33%'}}>Ultimo valore misurato</th>
                <th style={{paddingRight: '10px', width: '33%'}}>Portata relativa</th>
            </tr>
            <tr>
                {value.I !== 'nd' ? <td>{DateUtils.epochToDateMinuteResolution(timestamp)} {color === 'grey' &&
                    <TiWarning style={{fontSize: 'x-large', color: 'DarkOrange'}}/>}</td> :
                    <td style={{color: 'red'}}>Più di due ore fa <TiWarning style={{fontSize: 'x-large', color: 'red'}}/></td>
                }
                <td style={colouredColumnStyle(color)}>{value.I} {value.I !== 'nd' && getUnitByCategory(category)}</td>
                {value.flow !== 'nd' ? <td>{value.flow} m<sup>3</sup>/s</td> : <td>{value.flow}</td>}
            </tr>
            </tbody>
        </table>
    </React.Fragment>;

const buildWindBody = (category, timestamp, value, color) =>
    <React.Fragment key={category + "_wind"}>
        <hr style={{margin: 0, height: '4vh'}}/>
        <table className="textAlignCenter" style={{minWidth: '100%'}}>
            <tbody>
            <tr>
                <th style={{width: '33%'}}>Ultima misurazione</th>
                <th style={{paddingRight: '10px', width: '33%'}}>Intensità</th>
                <th style={{paddingRight: '10px', width: '33%'}}>Direzione</th>

            </tr>
            <tr>
                {value !== 'nd' ? <td>{DateUtils.epochToDateMinuteResolution(timestamp)} {color === 'grey' &&
                    <TiWarning style={{fontSize: 'x-large', color: 'DarkOrange'}}/>}</td> :
                    <td style={{color: 'red'}}>Più di due ore fa <TiWarning style={{fontSize: 'x-large', color: 'red'}}/></td>
                }
                <td style={colouredColumnStyle(color)}>{value[category.replace('W','V')]} m/s</td>
                <td>
                    <div style={{display:"inline-flex"}}>
                        <span>{value[category.replace('W','D')]} {value[category.replace('W','D')] !== 'nd' && '°'}</span>
                        <div className="ml-2" style={{width:"30px", height:"30px"}}>

                            {value[category.replace('W', 'D')] !== 'nd' && <img alt={''} src={Markers.getIconByIntensity(value[category.replace('W', 'V')], true)}
                                                                                style={{
                                                                                    maxWidth: '100%',
                                                                                    maxHeight: '100%',
                                                                                    transform: `rotate(${value[category.replace('W', 'D')]+180}deg)`
                                                                                }}></img>}
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </React.Fragment>;

const buildPluviometerBody = (category, timestamp, value, stationCode, rainThresholds, returnTimeRainThresholds, gumbelMap, TCEVMap, color, isBlacklisted) =>
    <React.Fragment key={category + "_" + stationCode}>
        <hr key={'hr_1'} style={{margin: 0, height: '4vh'}}/>
        <table className="textAlignCenter" style={{minWidth: '100%'}}>
            <tbody>
            <tr>
                <th>Ultima misurazione</th>
                <th style={{paddingRight: '10px'}}>Pioggia cumulata oggi</th>
            </tr>
            <tr>
                {value['P_DAILY'] !== 'nd' ? <td>{DateUtils.epochToDateMinuteResolution(timestamp)} {color === 'grey' &&
                    <TiWarning style={{fontSize: 'x-large', color: 'DarkOrange'}}/>}</td> :
                    <td style={{color: 'red'}}>Più di due ore fa <TiWarning style={{fontSize: 'x-large', color: 'red'}}/></td>
                }
                <td>{value['P_DAILY']} {value['P_DAILY'] !== 'nd' && 'mm'} {isBlacklisted && blacklistIcon}</td>
            </tr>
            </tbody>
        </table>
        <br></br>
        <hr key={'hr_2'} style={{margin: 0}}/>
        <table className="textAlignCenter" style={{minWidth: '100%'}}>
            <tbody>
            <tr>
                <th style={{paddingRight: '10px', width:'25%'}}>Aggregazione</th>
                <th style={{paddingRight: '10px', width:'25%'}}>Valore misurato</th>
                <th style={{width:'25%'}}>Tempo di ritorno (Gumbel)</th>
                <th style={{width:'25%'}}>Tempo di ritorno (TCEV)</th>
            </tr>
            {['P_ESTIMATED_1H',...Object.keys(MonitoringPluvsThresholdClient.rainLayerMap)].map(aggr => {
                const num = aggr === 'P_ESTIMATED_1H' ? 1 : aggr.match(/\d+/g).map(Number);
                const label = aggr === 'P_ESTIMATED_1H' ? 'Intensità oraria': `${num} ${num !== 1 ? 'ore' : 'ora'}`;

                const aggregatedValue = value[aggr]!==-9999 ? value[aggr] : "nd";
                if (aggregatedValue === 'nd'){
                    return <tr key={stationCode + "_" + aggr}>
                        <td>{label}</td>
                        <td style={colouredColumnStyle("grey")}>{aggregatedValue}</td>
                        <td style={colouredColumnStyle("grey")}>{aggregatedValue}</td>
                        <td style={colouredColumnStyle("grey")}>{aggregatedValue}</td>
                    </tr>
                }

                const gumbelReturnTime = MonitoringPluvsThresholdClient.getReturnTime(stationCode, aggregatedValue, num, 'GUMBEL', gumbelMap, TCEVMap);
                const gumbelColor = MonitoringPluvsThresholdClient.getColorByReturnTime(gumbelReturnTime, returnTimeRainThresholds) || 'rgba(255, 255, 255, .1)';

                const tcevReturnTime = MonitoringPluvsThresholdClient.getReturnTime(stationCode, aggregatedValue, num, 'TCEV', gumbelMap, TCEVMap)
                const tcevColor = MonitoringPluvsThresholdClient.getColorByReturnTime(tcevReturnTime, returnTimeRainThresholds) || 'rgba(255, 255, 255, .1)';

                const rainColor = MonitoringPluvsThresholdClient.getSeverityByValue(aggr, aggregatedValue, rainThresholds).color;

                return <tr key={stationCode + "_" + aggr}>
                    <td>{label}</td>
                    <td style={colouredColumnStyle(rainColor)}>{aggregatedValue}</td>
                    <td style={colouredColumnStyle(gumbelColor)}>{gumbelReturnTime}</td>
                    <td style={colouredColumnStyle(tcevColor)}>{tcevReturnTime}</td>
                </tr>
            })}
            </tbody>
        </table>
    </React.Fragment>;

const MidaModalContent = ({selectedCategory, feature, data, openModal, rainThresholds, returnTimeRainThresholds, gumbelMap, TCEVMap, sensorBlacklist}) => {
    const {name, code: stationCode} = feature.properties;

    let modalContent = [];
    let sortedCategories = getSortedCategories(selectedCategory);
    if (!!feature){
        modalContent.push(<h1 style={{textAlign: 'center', color: '#fff'}}> {name} </h1>);

        sortedCategories.forEach((category) => {
            let measurement = data.measurements[category];
            let categoryContent = [];
            if (!!measurement){
                const {sensorCode, value, timestamp, severityColor : color} = measurement;
                const isBlacklisted = sensorBlacklist.find(item => item.code === sensorCode);

                categoryContent.push(buildHeader(openModal, name, stationCode, sensorCode, category, timestamp));
                categoryContent.push(
                    (() => {switch(category){
                        case 'P': return buildPluviometerBody(category, timestamp, value, stationCode, rainThresholds[sensorCode] || rainThresholds['GENERIC'], returnTimeRainThresholds, gumbelMap, TCEVMap, 'blue', isBlacklisted);
                        case 'I': return buildHydroBody(category, timestamp, value, color);
                        case 'WV':
                        case 'WS':
                        case 'WR': return buildWindBody(category, timestamp, value, color);
                        default: return buildBody(category, timestamp, value,  color, isBlacklisted)
                    }})());
                categoryContent.push(<hr key={category + "_" + stationCode + "_break"} style={{margin: 0, height: '4vh', borderBottom: 'solid', marginBottom: '2vh'}}/>);
                modalContent.push(<React.Fragment key={stationCode + "_" + category}>{[...categoryContent]}</React.Fragment>)
            }
        })
    }
    return modalContent;
}

export default MidaModalContent;
