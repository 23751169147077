import ForecastLinkClient from "#/lib/ForecastLinkClient";
import { TextField } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import moment from 'moment';
import React from 'react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ReactSwal = withReactContent(Swal);



export default class LinkPrevisioniMeteoSettings extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			link : "",
		}
	}

	componentDidMount(){
		ForecastLinkClient.getLink(
			(data) => {
				this.setState({
					link : data.link
				})
			},
			(err) => {
				this.setState({
					link : "",
				})
			}
		)
	}

	save(){
		ForecastLinkClient.saveLink(
			() => {
				ReactSwal.fire('Link Previsioni Meteo','Dati Salvati con Successo').then(
					(res) => {
						this.setState({
							edited : false
						})
					}
				);
				
			},
			(err) => {
				console.log("error")
			},
			{
				link : this.state.link
			}
		)
	}






	render() {

		return (
		<>
		<div className="textAlignCenter">
			<>
				<h1 className="mt-4" style={{ color: "#2b7943", fontWeight: 900 }}>
					Link Previsioni Meteo
				</h1>
				<TextField
						fullWidth
						autoComplete='off'
						label={"Piano AIB"}
						value={this.state.link}
						onChange={e=>{ this.setState({link:e.target.value})}}
						InputLabelProps={{
							shrink: true,
						}}
						placeholder={ ""}
				/>
				
							
				<Button 
					color="primary" style={{ margin: "2vh", minWidth: "10vw" }}
					startIcon={<SendIcon />}
					variant="contained" type="submit"
					onClick={() => {this.save()}}>
					Salva
				</Button>
						
					
				


			</>
		</div>
		

		</>)
	}
}

