import {MapControl, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "#/commons/map/LeafletLegend.css";

class LeafletLegend extends MapControl {
	createLeafletElement(props) {}
	componentWillUnmount(){}

	getRowContent = (grade, i) => {
		let {grades, firstLabel} = this.props;
		if (i === 0 && firstLabel){
			return firstLabel;
		}
		let retval = '';
		retval += i === 0 ? '<' : '';
		retval += i === grades.length - 1 ? '>' : '';
		retval += !isNaN(grades[i - 1]) && i !== 0 ? grades[i - 1] : '';
		retval += i !== 0 && i !== grades.length - 1 ? ' / ' : '';
		retval += i !== grades.length - 1 ? grade : '';

		return retval;
	}

	componentDidMount() {

		console.log(this.props)

		const legend = L.control({ position: "bottomright" });

		legend.onAdd = () => {
			let classNames = !!this.props.replaceMode ?  "w-auto info legend newLegend " : " w-auto info legend ";
			const div = L.DomUtil.create("div", classNames);

			const {grades, labels: levels, extraLabels} = this.props;


			let labels = [];
			if (!!this.props.legendTitle){
				labels.push(`<div class="mb-1"><b>${this.props.legendTitle}</b></div>`)
			}
			if (grades) {
				labels.push(`<table>
					<tbody>
					${grades.map((grade, i) => `<tr  ${this.props.size==="big" ? "style='height: 25px;'" : ""}>
								<td style="${this.props.size==="big" ? "min-width: 25px;" : "min-width: 20px;"}  background-color: ${this.props.getColor(grade)}"></td>
								<td style="${this.props.size==="big" ? "padding-left: 10px; font-size: larger" : "padding-left: 5px;"}">
									${this.getRowContent(grade, i)} 
								</td>
							</tr>`).concat([extraLabels || []]).join("")}
					</tbody>
				</table>`);
			} else if (levels) {
				labels.push(`<table>
					<tbody>
					${levels.map((level, i) => `<tr>
								<td style="${this.props.size==="big" ? "min-width: 25px;" : "min-width: 20px;"} background-color: ${this.props.getColor(i)}"></td>
								<td style="${this.props.size==="big" ? "padding-left: 10px; font-size: larger" : "padding-left: 5px;"}">
									${level}
								</td>
							</tr>`).concat([extraLabels || []]).join("")}
					</tbody>
				</table>`);
			}
			div.innerHTML = labels.join("");
			if(!!this.props.onLegendClick){
				div.style.cursor = 'pointer';
				div.addEventListener('click', (event) =>{event.preventDefault(); this.props.onLegendClick()})
			}
			return div;
		};

		const { map } = this.props.leaflet;

		let tmp = document.getElementsByClassName("newLegend");
		if (!!this.props.replaceMode && !!tmp && tmp.length > 0) {
			tmp[0].remove()
		};
		legend.addTo(map);
	}
}

export default withLeaflet(LeafletLegend);
