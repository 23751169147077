import {properties} from "#/properties.js";
import APIUtils from "#/lib/APIUtils";

export default class StaticPageClient {



	
  static getAll = (okCallback, errCallback) => {
    let url = new URL(properties.url.staticPage.getAll);
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static getByCode = (okCallback, errCallback, pageCode) => {
    let url = new URL(properties.url.staticPage.getByPageCode(pageCode));
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };


  static save = (okCallback, errCallback, page) => {
    let url = new URL(properties.url.staticPage.save);
    let fetch_options = {
      headers: { "Content-Type": "application/json", charset: "utf-8" },
      method: "POST",
      body: JSON.stringify(page),
    };
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };

}
