import React from 'react';
import HomePageSection from '#/frontoffice/sections/HomePageSection.jsx';
import InformatiEPreparati from '#/frontoffice/sections/InformatiEPreparati.jsx';
import HotNewsSection from '../sections/HotNewsSection';
import ComunicatoClient from '#/lib/ComunicatoClient'
import CurrentSituationComponent from "../components/CurrentSituationComponent";
import CentroFunzionale from '../sections/CentroFunzionale';
import GuidaUffici from '../sections/GuidaUffici';
import Preparati from '../sections/Preparati';


class HomePage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			sections: []
		}
	}

	componentDidMount() {
		let sections = [
			{
				id: "sezione-1",
				title: "Situazione attuale",
				backgroundColor: "white",
				colorTitle: "#000000",
				subtitle: "",
				contentBackground :"white",
				content: <CurrentSituationComponent/>,
				nextSection: "sezione-2",
				nextSectionName: "Informati",
				noHeader: true
			},
			{
				id: "sezione-2",
				backgroundColor: "#d1efff",
				colorTitle: "#000000",
				contentBackground :"#d1efff",
				title: "Informati",
				subtitle: "",
				content: <InformatiEPreparati />,
				nextSection: "sezione-3",
				nextSectionName: "Preparati"
			},
			{
				id: "sezione-3",
				backgroundColor: "#d1efff",
				colorTitle: "#000000",
				contentBackground :"#d1efff",
				title: "Preparati",
				subtitle: "",
				content: <Preparati />,
				nextSection: "sezione-4",
				nextSectionName: "Cos'è il Centro Funzionale"
			},
			{
				id: "sezione-4",
				backgroundColor: "white",
				colorTitle: "#000000",
				contentBackground :"white",
				title: "",
				subtitle: "",
				content: <CentroFunzionale />,
				// nextSection: "sezione-4",
				// nextSectionName: "Social Allerta"
			},
			{
				id: "sezione-4",
				backgroundColor: "#d1efff",
				colorTitle: "#000000",
				contentBackground :"#d1efff",
				title: "Guida agli uffici ARPACal",
				subtitle: "",
				content: <GuidaUffici />,
				// nextSection: "sezione-4",
				// nextSectionName: "Social Allerta"
			},
			/*{
				id: "sezione-3",
				title: "Social Allerta",
				subtitle: "",
				backgroundColor: "#f4f4f4",
				colorTitle: "#000000",
				contentBackground :"#f4f4f4",
				content: <TwitterTimelineEmbed sourceType="list" id="1329090678572281856" options={{ height: 500 }} noHeader noFooter	/>,
				nextSection: "sezione-4",
				nextSectionName: "Previsioni Meteo"
			},
			{
				id: "sezione-4",
				title: "Previsioni Meteo",
				backgroundColor: "white",
				colorTitle: "#000000",
				subtitle: "",
				contentBackground :"white",
				content: <ForecastComponent/>,
				nextSection: "",
				nextSectionName: ""

			}*/
		];

		ComunicatoClient.listHotNews(
			(data) => {
				if (data.length !== 0) {
					sections = [{
						id: "sezione-0",
						title: "In primo piano",
						subtitle: "",
						content: <HotNewsSection data={data}/>,
						nextSection: "sezione-1",
						nextSectionName: "MAU"
					}, ...sections];

				}
				this.setState({
					sections: sections
				})
			},
			(err) => {
				console.log(err);
				this.setState({
					sections: sections
				})
			}
		)




	}



	render() {
		let lista = this.state.sections.map((section, idx) =>
			<HomePageSection key={idx} first={idx === 0} {...section}></HomePageSection>
		);
		return (
			<div className="home" style={{minHeight:"100vh", backgroundColor:"white"}}>
				{lista}
			</div>
		);
	}






}

export default HomePage;
