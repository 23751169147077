import React from 'react';
import {MdDelete} from 'react-icons/md';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TwitterIcon from '@material-ui/icons/Twitter';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import LinkIcon from '@material-ui/icons/Link';
import {blue, orange} from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';

export default class InfostreamItem extends React.Component {

	render() {

		const iconSize = 25;
		let link = null;
		if (this.props.channel==='rss'){
			link = this.props.source;
		} else {
			const idxHttpsString = !!this.props.message ? this.props.message.lastIndexOf('https://') : -1;
			link = idxHttpsString > 0 ? this.props.message.slice(idxHttpsString) : null;
		}

		return (
			<>
				<div className="accordion" id={"accordion_" + this.props.title}>
					<div className="card" style={{ marginBottom: '0.7rem', borderRadius: '5px', border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }}>
						<div className="card-header" style={{ display: "flex" }}>
							<button className={"col-"+ (!!this.props.editable ? "9" : "11") + " btn accordion-header text-left"} type="button" data-toggle="collapse" data-target={"#collapse_" + this.props.title} aria-expanded="true">
								<div>
									<span className="item-title">
										{this.props.channel === 'tweetter' ? 
										// <TwitterIcon  style={{ color: blue[500] }}/> : 
										<svg fontSize={'1rem'} color={blue[500]} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
											<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
										</svg> :
										<RssFeedIcon  style={{ color: orange[500] }}/>} {this.props.title}
									</span>
								</div>
								<div>
									<span> <CalendarTodayOutlinedIcon /> {this.props.published_date} </span>
								</div>
								{ !!link &&
									<div>
										<span> <LinkIcon /> <a title={this.props.title} href={link} target="_blank" rel="noopener noreferrer">{link}</a></span>
									</div>
								}

								<div>
									<span> <DescriptionOutlinedIcon /> {this.props.message} </span>
								</div>

							</button>


							{!!this.props.editable &&
							<div className="my-auto col-1">
								<Fab size="medium" aria-label="Cancella" onClick={() => this.props.onClickDelete(this.props.username)} 
									style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
								>
									<MdDelete size={iconSize} title="Cancella" />
								</Fab>
							</div>
							}
						</div>
					</div>
				</div>
				<div id={"collapse_" + this.props.username} className="collapse" data-parent={"#accordion_" + this.props.username}>
					{this.props.message}
				</div>
			</>
		)
	}

};
