import {properties} from "#/properties.js";
import AuthenticationService from "#/lib/AuthenticationService"

export default class ForceIngestionClient {

	static forceIngestion = async (selectedFile, selectedFileName, setUploadProgress) => {
	
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open('POST', properties.url.forceIngestion, true);
			xhr.setRequestHeader("Authorization", AuthenticationService.getLoggedInToken());
	
			// Handle the progress event
			xhr.upload.onprogress = (event) => {
				console.log("uploadFile onprogress event", event);
				if (event.lengthComputable) {
					const progress = Math.round((event.loaded * 100) / event.total);
					console.log("Progress", progress)
					//setUploadProgress(progress);
				}
			};
	
			// Handle the load event
			xhr.onload = (event) => {
				console.log("uploadFile onload event", event);
				if (xhr.status >= 200 && xhr.status < 300) {
					const response = JSON.parse(xhr.responseText);
					resolve(response.path);
				} else {
					reject(new Error(`Failed to upload file: ${xhr.statusText}`));
				}
			};
	
			// Handle the error event
			xhr.onerror = (event) => {
				console.log("uploadFile onerror event", event);
				reject(new Error('Network error occurred during file upload.'));
			};
	
			// Create form data and append file
			const formData = new FormData();
			formData.append("file", selectedFile);
			formData.append("fileName", selectedFileName);
	
			// Send the request
			xhr.send(formData);
		});
	  };

}
