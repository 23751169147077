import {MapControl, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "#/commons/map/LeafletWatermark.css";

class MapWatermark extends MapControl {
	createLeafletElement(props) {}
	componentWillUnmount(){}

	removeExistingWatermark = customClass => {
		let watermarkArray = document.getElementsByClassName(customClass);
		if (!!watermarkArray && watermarkArray.length > 0) {
			watermarkArray[0].remove()
		};
	}

	componentDidMount = () => {

		const watermark = L.control({ position: "topright" });
		const {customClass, leaflet : {map}, text} = this.props;
		watermark.onAdd = () => {
			const div = L.DomUtil.create("div", "watermark " +customClass);
			div.innerHTML = text;
			div.style.color = this.props.customColor || '#2b7943';
			return div;
		};
		this.removeExistingWatermark(customClass);
		watermark.addTo(map);
	}
}

export default withLeaflet(MapWatermark);
