import React, { useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';

import "jodit";
import "jodit/build/jodit.min.css";


import InputSelectComponent from "#/commons/components/InputSelectComponent";
import _ from "lodash";
import { Button } from 'react-bootstrap';

import StaticPageClient from '#/lib/StaticPageClient';



const StaticPagesConf = () => {
	const [value, setValue] = useState(null);
	const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState([]);


	useEffect(()=>{
		reload()
	},[])


	const reload = () => {
		StaticPageClient.getAll(
			(staticPages) => {
				let options = []
				staticPages.sort((a,b)=>a.pageName.localeCompare(b.pageName)).forEach((t)=>{
					options.push({label: t.pageName, value : t})
				})

				setOptions(options)
				setSelected(null)
			}
		)
	}

	const save = () => {
		console.log(selected)
		console.log(value)
		let toSave = _.cloneDeep(selected);
		toSave.code= value;
		StaticPageClient.save(()=>{reload()}, ()=>{}, toSave)

	}



	const config = {
		buttons: [
			"undo",
			"redo",
			"|",
			'bold', 'italic', 'underline', 'strikethrough', '|',
			{
				name: 'font',
				list: {
					'roboto': 'Roboto',
					'arial': 'Arial',
					'times-new-roman': 'Times New Roman',
					'courier-new': 'Courier New',
					'georgia': 'Georgia'
				},
			},

			"brush", '|',
			'hr', 'ul', 'ol', '|',
			'outdent', 'indent', '|',
			'align', '|',
			'link', 'image', 'video', '|',
			'table', '|', 'source'
		],
		controls: {
			textColor: {
			  list: [
				"#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", 
				"#000000", "#FFFFFF", "#808080", "#C0C0C0", "#800000", "#808000"
			  ],
			},
			backgroundColor: {
			  list: [
				"#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", 
				"#000000", "#FFFFFF", "#808080", "#C0C0C0", "#800000", "#808000"
			  ],
			}
		  },
		  colorPickerDefaultTab: "textColor",
		uploader: {
			insertImageAsBase64URI: true, // Directly insert as Base64 for local files
			filesVariableName: function (file) { return 'files[]'; },
		},
		height: 800,
		toolbarSticky: false,
		showCharsCounter: false,
		showWordsCounter: false,
		showXPathInStatusbar: false,
		enter: 'P',
	};

	const handlePreview = () => {
		const previewWindow = window.open('', '_blank');
		previewWindow.document.write(`
      <html>
        <head>
          <title>Preview</title>
		      	<link
					rel="stylesheet"
					href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
					integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
					crossOrigin="anonymous"
				/>
				<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
				<link href="/css/style.css" rel="stylesheet" type="text/css">


				<script src="/js/jquery-3.3.1.min.js"></script>
				<script src="/js/popper.min.js"></script> 
				<script src="/js/bootstrap-4.3.1.js"></script>
				<script src="/js/jquery.easing.min.js"></script>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
          <style>
            body { font-family: Roboto, Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${value}
        </body>
      </html>
    `);
		previewWindow.document.close();
	};

	return (
		<div className="mt-4 generic-page container-fluid data-page">
			<InputSelectComponent
                placeholder={"Seleziona una pagina statica"}
                isSearchable={true}
                optionList={options}
                value={selected}
                onChange={selectedOption=> {setSelected(selectedOption);setValue(selectedOption.code)}}
            />
			{!!selected && <>
			<Button onClick={handlePreview}>Anteprima</Button>
			<Button onClick={save}>Salva</Button>
			<JoditEditor
				value={value}
				config={config}
				onBlur={(newContent) => setValue(newContent)}
			/>
			</>}
		</div>
	);
};

export default StaticPagesConf;
