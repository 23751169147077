import React, {useState} from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Button from "@material-ui/core/Button";
import {GoFileDirectory} from "react-icons/go";

const baseImgUrl = '/img/fotoGallery';
const urls = {
    'Nevicate': [
        '/nevicate/neve a cz lido.jpg',
        '/nevicate/neve a cz lido 2.jpg',
        '/nevicate/neve a cz lido 3.jpg',
        '/nevicate/neve a cz lido 4.jpg'
    ],
    'Dissesti': [
        '/dissesti/frana iano 2010.jpg'
    ],
    'Stazioni e ponti radio': [
        '/stazioni/ponte radio stazione di cecita.jpg'
    ],
    'Temporali': [
        '/temporali/20082013-stretto di messina_1.jpg',
        '/temporali/20082013-stretto di messina_2.jpg',
        '/temporali/20082013-stretto di messina_3.jpg',
        '/temporali/20082013-stretto di messina_4.jpg',
        '/temporali/20082013-stretto di messina_5.jpg',
        '/temporali/tropea.jpg'
    ],
    'Varie': [
        '/varie/ciro_1.jpg',
        '/varie/ciro_2.jpg',
        '/varie/ciro_3.jpg',
        '/varie/ciro_4.jpg',
        '/varie/ciro_5.jpg',
        '/varie/ciro_6.jpg',
        '/varie/ciro_7.jpg',
        '/varie/ciro_8.jpg',
        '/varie/ciro_9.jpg',
        '/varie/cumuli nembi sulla sila.jpg',
        '/varie/f.tacina ss106.jpg',
        '/varie/mare lido.jpg',
        '/varie/mareggiata cz-lido 01-02-2014_1.jpg',
        '/varie/mareggiata cz-lido 01-02-2014_2.jpg',
        '/varie/rossano.jpg',
        '/varie/sp 25  evento del 19-20 novembre 2013.jpg',
        '/varie/strada taverna catanzaro via fondovalle 1.jpg',
        '/varie/strada taverna catanzaro via fondovalle 2.jpg'
    ]
};

const categories = ['Dissesti','Nevicate','Stazioni e ponti radio','Temporali','Varie'];
const FotoGalleryPage = () => {
    const [category, setCategory] = useState(null);
    return <div className="generic-page container-fluid textAlignCenter">
        <h2>Foto gallery {category || ''}</h2>
        <div>
            {categories.map(category =>
                <Button
                    color="primary" style={{margin: "2vh", minWidth: "10vw"}}
                    startIcon={<GoFileDirectory/>}
                    variant="contained" type="submit"
                    onClick={() => setCategory(category)}>
                    {category}
                </Button>)}

        </div>
        {category && <AwesomeSlider key={category} className="mx-auto" style={{height: '70vh', width: '60vw'}}>
            {urls[category].map((n, i) => <div key={i} data-src={`${baseImgUrl}${n}`}/>)}
        </AwesomeSlider>}
    </div>
}

export default FotoGalleryPage;
