import React from 'react';

function SponsorPage(props) {
	return (
		<div>
			<div className="generic-page container">
				<div className="generic-page" style={{textAlign:"center"}}>
					<img src="/img/backoffice_landing_page.png" alt="Logo"></img>

				</div>
			</div>
		</div>
	)
}

export default SponsorPage;