import React from 'react'
import "#/commons/data/section/year-calendar.css"
import "#/backoffice/style/PolygonMapComponent.css"

import moment from 'moment';
import BulletinClient from "#/lib/BulletinClient";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import MapWeatherFormComponent from "#/commons/map/MapWeatherFormComponent";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import WidthUtils from "#/lib/WidthUtils"
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";
import ForecastLinkClient from "#/lib/ForecastLinkClient";
import { Fab, ListItem, withStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import {properties} from '#/properties';

const defaultTodayTomorrowLabels = {today : 'Oggi', tomorrow : 'Domani'};
const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

const CustomBottonNavigation = withStyles((theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightRegular,
		backgroundColor:"#f5f5f5",
		'&:hover': {
			color: '#0c6aaf',
			opacity: 1,
		},
		'&$selected': {
			color: '#134367',
			fontWeight: theme.typography.fontWeightMedium,
			backgroundColor:"#b7e6ff"
		},
		'&:focus': {
			color: '#0c6aaf',
		},
	},
	selected: {},
}))((props) => <BottomNavigationAction {...props} />);

class ForecastComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mapKey: 1,
			title : '',
			todayGeoJson : null,
			tomorrowGeoJson : null,
			forecastFields : {'today' : {}, 'tomorrow' : {}},
			selectedDay : 'today',
			createdAt : null,
			noData: true,
			todayTomorrowLabels : defaultTodayTomorrowLabels,
			selectedLayer : undefined,
			bollettinoOdierno: false
		}
	}
	mapOptions = {
		center: [39.11, 16.55],
		zoom: 6,
		minZoom: 6,
		width: "40vw",
		height: "70vh"
	}

	componentDidMount() {
		this.parseForecastData(this.props.forecastItem);

		/*	if (!!this.props.forecastItem){
                this.parseForecastData(this.props.forecastItem);
            } else {
                this.getLastFORECAST();
            }*/
	}

	getLastFORECAST() {
		BulletinClient.listBulletins(
			(obj) => {
				console.log('forecast DATA', obj.data);
				if (obj.data.length > 0) {
					
					this.parseForecastData(obj.data[0]);
				} else {
					this.setState({noData: true})
				}
			},
			(err) => { console.log('Error while retrieving forecast data', err) },
			{
				pageNumber: 1,
				pageSize: 1,
				bulletinTypeName: 'forecast'
			}
		)

	}

	parseForecastData(data) {
		
		let {
			jsonData: {
				geoJson,
				today,
				tomorrow,
				maps: {
					today: todayGeoJson,
					tomorrow: tomorrowGeoJson
				}
			},
			createdAt,
			id: forecastId
		} = data;


		let todayTomorrowLabels = defaultTodayTomorrowLabels;
		let selectedDay = 'today';
		let now = moment();
		let passedDays = now.endOf('day').diff(moment(createdAt).startOf('day'), 'days');
		if (passedDays > 1) {
			todayTomorrowLabels = {
				today: '',
				tomorrow: ''
			};
			this.setState({bollettinoOdierno: false});
		} else if (passedDays === 1) {
			todayTomorrowLabels = {today: 'Ieri', tomorrow: 'Oggi'};
			selectedDay = 'tomorrow';
			this.setState({bollettinoOdierno: false});
		} else {
			this.setState({bollettinoOdierno: true});
		}
		let forecastFields = {'today' : today.fields, 'tomorrow' : tomorrow.fields};
		
		const field = Object.keys(forecastFields[selectedDay])[0] ?? null;
		if (field !== null) {
			this.setState({selectedLayer: `${forecastId}_${field}`}, () => {})
		}
		
		ForecastLinkClient.getLink(
			(fclinkdata) => {
				this.setState({
					link : fclinkdata.link,
					todayGeoJson,
					tomorrowGeoJson,
					forecastFields,
					createdAt,
					forecastId,
					todayTomorrowLabels,
					selectedDay,
					noData:false,
					mapKey: ((!!this.props.mapKey ? this.props.mapKey : this.state.mapKey) + 1) % 1000
				})
			},
			(err) => {
				this.setState({
					link : "",
				})
			}
		)

	}

	handleDaySelection = (event, newDay) => {
		this.setState({selectedDay : newDay, mapKey: (this.state.mapKey + 1) % 1000});
	};

	passMapToFix(map){
		if (!!this.props.mapHasBeenChanged){
			this.props.mapHasBeenChanged(map)
		}
	}
	mobileMode = () => WidthUtils.isMobile();

	handleListItemClick = (index) => {
		if (this.state.selectedLayer === index){
			this.setState({selectedLayer: undefined}, () => {});
		} else {
			this.setState({selectedLayer: index}, () => {
			});
		}
	};

	render() {
		let {selectedDay, todayTomorrowLabels, createdAt, todayGeoJson, tomorrowGeoJson, forecastFields, noData, selectedLayer} = this.state;
		
		let todayStr = moment(createdAt).format('DD/MM/yyyy');
		let tomorrowStr = moment(createdAt).add(1,'days').format('DD/MM/yyyy');
		let docUrl = !!this.state.forecastId ? properties.url.bulletinDownloadFile + '/' + this.state.forecastId + '/pdf' : "";
		const Stub = (<SkeletonTheme>
			<div className="container-fluid">
				<div className="justify-content-center mt-4 mb-4">
					<div className="row">
						<div className="col-6">
							<Skeleton height={"45vh"} duration={8}/>
						</div>
						<div className="col-6">
							<Skeleton height={"45vh"} duration={8}/>
						</div>
					</div>
				</div>
			</div>
		</SkeletonTheme>);
		return (

			<div className=" container-fluid ">
				{noData ? Stub : <>
					<div className="d-flex justify-content-center mt-4">
						<BottomNavigation
							value={selectedDay}
							onChange={(event, newDay) => this.handleDaySelection(event,newDay)}
							style={{ height: "auto"}}
							showLabels>
							<CustomBottonNavigation style={{minWidth: "10vw", minHeight: "4rem", borderRadius: "25px 0px 0px 25px"}} label={<><b>{todayTomorrowLabels.today}</b><br/>{todayStr}</>}
													value="today"/>
							<CustomBottonNavigation style={{minWidth: "10vw", minHeight: "4rem", borderRadius: "0px 25px 25px 0px"}} label={<><b>{todayTomorrowLabels.tomorrow}</b><br/>{tomorrowStr}</>}
													value="tomorrow"/>
						</BottomNavigation>
					</div>
					<div className="row mt-2 mb-2">
						<div className="col-sm mt-2 scrollbar scrollbar-primary" style={{maxHeight: '60vh', overflowY: 'auto'}}>
							{Object.keys(forecastFields[selectedDay]).map(field =>
								<ListItem
									className='row m-0'
									style={{ border: '1px solid #E4E4E4', padding: '1rem' }}
									button
									selected={selectedLayer === `${this.state.forecastId}_${field}`}
									onClick={(event) => this.handleListItemClick(`${this.state.forecastId}_${field}`)}
								>
									<React.Fragment key={`${this.state.forecastId}_${field}`}>
										<div className='col-12 d-flex justify-content-between'>
											<h3 className='m-0'>{capitalizeFirstLetter(field)}</h3>
											<i className={selectedLayer === `${this.state.forecastId}_${field}` ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} 
												style={{ fontSize: '1.5rem', color: '#6c757d' }}
											></i>
										</div>
										<p style={this.mobileMode() ? {fontSize: '0.8rem'} : {}} className={ selectedLayer === `${this.state.forecastId}_${field}` ? 'pl-3 mt-3' : 'd-none' }>
											{forecastFields[selectedDay][field]}
										</p>
									</React.Fragment>
								</ListItem>
							)}
							{
								(docUrl !== '' || this.state.link) ?
								<div className="mt-4">
									<Fab size="medium" title={"Scarica Bollettino Meteorologico Regionale"} aria-label={"Scarica Bollettino Meteorologico Regionale"}
										href={docUrl ? docUrl : this.state.link} className='btn-download-bollettino-meteo h-100 p-2'
										style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
									>
										<span className='ml-2 text-uppercase d-flex align-items-center justify-content-center'>
											{docUrl ? "Scarica Bollettino Meteorologico Regionale" : this.state.link}
											<GetAppIcon style={{color: '#fff'}} className='ml-2' />
										</span>
									</Fab>
								</div> : <></>
							}
						</div>
						<div className="col-sm">
							<MapWeatherFormComponent
								id={this.state.mapKey}
								readOnly={true}
								key={selectedDay + this.state.mapKey}
								geoJSON={selectedDay === 'today' ? todayGeoJson : tomorrowGeoJson}
								width={!!this.props.width || "100%"}
								height={!!this.props.height || "40vh"}
								mapKey={this.state.mapKey}
								currentDay={selectedDay}
								getColorById={(id) => {
									return null
								}}
								fixMap={(map) => this.passMapToFix(map)}
							/>
						</div>
					</div>
				</>}
			</div>
		)
	}
}


export default (ForecastComponent);
