import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {AiOutlineDownload} from "react-icons/all";
import React from "react";
import ExportButton from '#/commons/components/forms/ExportButton';

export const PdfModal = ({showPdfModal, onClosePdfModal, getTabulatorColumns, excludedColumns, toggleColumn, downloadPdf}) => {
    const getPdfColumnCount = () => getTabulatorColumns().filter(col => !col.frozen).length + 1 - excludedColumns.length;

    return (
        <Modal key={'pdf_modal'}
               style={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                   overflow:'scroll',
               }}
               open={showPdfModal}
               onClose={() => onClosePdfModal()}
               aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description"
        >
            <div
                style={{
                    background: "white",
                    width: "30vw",
                    height: "70vh"
                }}
            >
                <div className='w-100 d-flex justify-content-end'>
                    <IconButton aria-label="close" onClick={() => onClosePdfModal()}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="mx-3">
                    <h3 >Scegli le colonne da includere nel documento (max 9)</h3>
                    <div className="mx-auto " style={{minHeight: "40vh", maxHeight: "40vh", overflowY:'scroll', textAlign: 'initial'}}>
                        {getTabulatorColumns().filter(col => !col.frozen).map(col => !!col.title ? col.title : col.titleDownload).map(colTitle =>
                            <div>
                                <FormControlLabel
                                    key ={colTitle}
                                    control={<Checkbox
                                        checked={!excludedColumns.includes(colTitle)}
                                        onChange={e => toggleColumn(e)} name={colTitle} />}
                                    label={colTitle}
                                />
                            </div>
                        )}
                    </div>
                    <ExportButton disabled={getPdfColumnCount() > 10} label={"Avvia Download "}  icon={<AiOutlineDownload/>} downloadHandler={()=>downloadPdf()}/>
                </div>
            </div>
        </Modal>
    )
}
