import React, {useState, useEffect, useCallback} from 'react';
import BulletinClient from '#/lib/BulletinClient';
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import _ from "lodash";


import CodeEditor from "@uiw/react-textarea-code-editor";
import {useDebouncedCallback} from "use-debounce";
import { Button } from 'react-bootstrap';
const beautify_html = require("js-beautify").html;


const beautyOptions = {
    indent_size: 2
};

const TemplateConfigPage = () => {

    const [types, setTypes] = useState(null);
    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState([]);
	const [pendingHTMLTemplate, setPendingHTMLTemplate] = useState(null);
    const [current, setCurrent] = useState(null);


	useEffect(()=>{
		reload()
	},[])


	const reload = () => {
		BulletinClient.getBulletinTypes(
			(types) => {
				setTypes(types)
				let options = []
				types.sort((a,b)=>a.name.localeCompare(b.name)).forEach((t)=>{
					options.push({label: t.name+" - Template", value : {documentTypeName:t.name, templateName: "template_source_code", template: t.templateSourceCode}})
					options.push({label: t.name+" - Template Immagine", value : {documentTypeName:t.name, templateName: "image_template_source_code", template: t.imageTemplateSourceCode}})
					options.push({label: t.name+" - Template Share", value : {documentTypeName:t.name, templateName: "share_page_template_source_code", template: t.sharePageTemplateSourceCode}})

				})
				setOptions(options)
				setCurrent(null)
				setSelected(null)
				setPendingHTMLTemplate(null)
			}
		)
	}

	const save = () => {
		console.log(selected)
		BulletinClient.updateBulletinTemplate(()=>{reload()}, ()=>{}, selected.documentTypeName, selected.templateName, current)

	}


	return (
		<>
		<div className="generic-page container-fluid data-page">
			<InputSelectComponent
                placeholder={"Seleziona un template"}
                isSearchable={true}
                optionList={options}
                value={selected}
                onChange={selectedOption=> {console.log(selectedOption);setSelected(selectedOption);setCurrent(selectedOption.template)}}
            />
			<Button disabled={!current} onClick={save}>Salva</Button>
			{selected && <HTMLPreviewComponent
				initialTemplate={current}
				handleChange={template => setPendingHTMLTemplate(template)}
				applyChanges={() => {
					setCurrent(pendingHTMLTemplate);
					setPendingHTMLTemplate(null)
				}}
				hasChanges={!_.isEmpty(pendingHTMLTemplate)}/>
			}
		</div>

		</>
	)


}


const HTMLPreviewComponent = ({initialTemplate, handleChange, hasChanges, applyChanges}) => {

    const [srcDoc, setSrcDoc] = useState(null);

    useEffect(() => {
        setSrcDoc(beautify_html(initialTemplate, beautyOptions));
    },[initialTemplate])



    return <div>
        <div className={'row'}>
            <div className={'col-6'} >
               <div className={'d-flex justify-content-between'}>
                   <h3>Template</h3>
                   <Button disabled={!hasChanges} onClick={() => applyChanges()}>Applica modifiche</Button>
               </div>
                <HTMLEditor srcDoc={srcDoc} onChange={(evn) => handleChange(evn.target.value)}/>
            </div>
            <div className={'col-6'}>
                <h3>Anteprima</h3>
                <div style={{height: window.innerHeight - 250, overflowY: 'auto', padding: '1vw'}}
                     dangerouslySetInnerHTML={{__html: srcDoc}}/>
            </div>
        </div>
    </div>
}


const HTMLEditor = ({srcDoc, onChange}) => {

    const [innerValue, setInnerValue] = useState("<div>Nessun template presente</div>");

    useEffect(() => {
        if (srcDoc) {
            setInnerValue(srcDoc);
        } else {
            setInnerValue("<div>Nessun template presente</div>");
        }

    }, [srcDoc]);

    const debouncedHandleOnChange = useDebouncedCallback(
        (event) => {
            if (onChange) {
                onChange(event);
            }
        },
        1000
    );

    const handleChange = useCallback((event) => {
        event.persist();
        debouncedHandleOnChange(event);
    }, []);

    return <>
        <div data-color-mode="light" style={{height: window.innerHeight - 250, overflowY: "auto"}}>
            <CodeEditor
                value={innerValue}
                language="html"
                onChange={handleChange}
                padding={15}
                style={{
                    fontFamily:
                        "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    fontSize: 12
                }}
            />
        </div>
    </>;
}



export default TemplateConfigPage;

