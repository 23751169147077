import React from 'react'
import {GeoJSON, TileLayer} from 'react-leaflet';
import L from 'leaflet';
import GISTools from '#/lib/GISTools';
import "#/commons/data/section/year-calendar.css"
import "#/backoffice/style/PolygonMapComponent.css"
import {makeStyles, withStyles} from '@material-ui/core/styles';

import moment from 'moment';
import {zonesGeoJson8} from '#/lib/ZoneGeoJsonDefault';
import BulletinClient from "#/lib/BulletinClient";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {ALLERTAMENTO_IDRO_DEFINITIONS, MAP_LAYER_LABELS, PRECIPITAZIONI_ICONS} from "#/backoffice/mau/utils/MauUtils";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import {FiClock} from "react-icons/fi";
import DateUtils from "#/lib/DateUtils";
import {properties} from "#/properties";
import LeafletMauLegend from "#/commons/map/LeafletMauLegend";
import {FaCircle} from "react-icons/fa";
import GridList from "@material-ui/core/GridList";
import {WiRain, WiStrongWind, WiThermometer, WiThunderstorm} from "react-icons/wi";
import {GiBigWave, GiLightningTree, GiRiver} from "react-icons/gi";
import {TiWarning} from "react-icons/ti";
import LeafletMauLegendControl from "#/commons/map/LeafletMauLegendControl";
import chroma from "chroma-js";
import {VscWarning} from "react-icons/vsc";
import {
	PRECIPITAZIONI_IMPULSIVE_OPTIONS,
	PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS
} from "#/backoffice/forecast/utils/ForecastUtils";
import MapComponent from "#/commons/map/MapComponent";
import _ from 'lodash';

import WidthUtils from "#/lib/WidthUtils"
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as turf from "@turf/turf";


const defaultTodayTomorrowLabels = {today : 'Oggi', tomorrow : 'Domani'};
const colorScale = ['grey', 'black','green','yellow','orange','red','#4a0505'];

export const getLayerIcon = (layer, color) => {
	switch (layer) {
		case 'general': return <TiWarning color = {color} style={{fontSize: 'xx-large'}}/>;
		case 'rainfall_pni': return <WiRain color = {color} style={{fontSize: 'xx-large'}}/>;
		case 'rainfall_pi': return <WiThunderstorm color = {color}  style={{fontSize: 'xx-large'}}/>;
		case 'storm_surges': return <GiBigWave color = {color}  style={{fontSize: 'x-large'}}/>;
		case 'wind': return <WiStrongWind color = {color}  style={{fontSize: 'xx-large'}}/>;
		case 'frost': return <WiThermometer color = {color}  style={{fontSize: 'xx-large'}}/>;
		case 'idro_temp': return <GiLightningTree color = {color}  style={{fontSize: 'x-large'}}/>;
		case 'idro_idra': return <GiRiver color = {color}  style={{fontSize: 'x-large'}}/>;
		case 'fasi_op': return <VscWarning color = {color}  style={{fontSize: 'x-large'}}/>;
		default: return <></>;
	}
}

const CustomBottonNavigation = withStyles((theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightRegular,
		backgroundColor:"#f5f5f5",
		'&:hover': {
			color: '#0c6aaf',
			opacity: 1,
		},
		'&$selected': {
			color: '#134367',
			fontWeight: theme.typography.fontWeightMedium,
			backgroundColor:"#b7e6ff"
		},
		'&:focus': {
			color: '#0c6aaf',
		},
	},
	selected: {},
}))((props) => <BottomNavigationAction {...props} />);

const polygonHighlightStyle = {
	'color': 'black',
	'weight': 2,
	'opacity': 1
}

const colors = {
	"GIALLO" : "yellow",
	"ARANCIONE" : "DarkOrange",
	"ROSSO" : "red"
}

const alertLabels = {
	"LEVEL1" : "GIALLA",
	"LEVEL2" : "ARANCIONE",
	"LEVEL3" : "ROSSA"
}
const criticitaLevels = ['VERDE','GIALLO','ARANCIONE','ROSSO'];
class MauMapComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			mapKey: 1,
			title : '',
			geoJson : zonesGeoJson8,
			envelope : {},

			selectedLayer : "general",
			selectedDay : 'today',
			createdAt : null,
			todayTomorrowLabels : defaultTodayTomorrowLabels,

			showLegend : !this.columnMode()

		}
	}
	mapOptions = {
		center: [39.11, 16.55],
		zoom: 6,
		minZoom: 6,
		width: "40vw",
		height: "50vh"
	}

	componentDidMount() {
		console.log("MAU MAP COMPONENT ",this.props?.mauItem?.id)
		// debugger
		if (!!this.props.mauItem){
			console.log('mauItem', this.props.mauItem)
			this.parseMauData(this.props.mauItem);
		} else {
			this.getLastMAU();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// if (!!this.map){
		// 	this.map.leafletElement.invalidateSize();
		// 	this.centerMap();
		// }
		if (!this.showLegendControl()){
			this.removeLegendControl();
			if (!this.state.showLegend){
				this.toggleLegend();
			}
		} else {
			if (prevProps.width === 'md'){
				this.toggleLegend()
			}

		}
	}

	getLastMAU() {
		BulletinClient.listBulletins(
			(obj) => {

				if (obj.data.length > 0) {
					this.parseMauData(obj.data[0]);
				}
			},
			(err) => { console.log('Error while retrieving mau data', err) },
			{
				pageNumber: 1,
				pageSize: 1,
				bulletinTypeName: 'MAU'
			}
		)

	}

	parseMauData(data) {
		let envelope = {};
		let {
			jsonData: {
				geoJson,
				start: {previsione: {zones: todayPrevisioneArray}, criticita: {zones: todayCriticitaArray}, fasi_op : {zones: todayFasiOperativeArray}},
				end: {previsione: {zones: tomorrowPrevisioneArray}, criticita: {zones: tomorrowCriticitaArray}, fasi_op : {zones: tomorrowFasiOperativeArray}}
			},
			createdAt,
			id: mauId
		} = data;
		geoJson.features.forEach(feature => {
			let todayPrevisioneObj = todayPrevisioneArray.find(obj => obj.zone === feature.properties.ZONE);
			let todayCriticitaObj = todayCriticitaArray.find(obj => obj.zone === feature.properties.ZONE);
			let zoneTodayEnvelope = {
				[todayCriticitaObj.zone] : criticitaLevels.indexOf(todayCriticitaObj.idro_idra) > criticitaLevels.indexOf(todayCriticitaObj.idro_temp) ? todayCriticitaObj.idro_idra : todayCriticitaObj.idro_temp
			};
			envelope.today = {...envelope.today, ...zoneTodayEnvelope}
			let today = {...todayPrevisioneObj, ...todayCriticitaObj};

			let tomorrowPrevisioneObj = tomorrowPrevisioneArray.find(obj => obj.zone === feature.properties.ZONE);
			let tomorrowCriticitaObj = tomorrowCriticitaArray.find(obj => obj.zone === feature.properties.ZONE);
			let zoneTomorrowEnvelope = {
				[tomorrowCriticitaObj.zone]: criticitaLevels.indexOf(tomorrowCriticitaObj.idro_idra) > criticitaLevels.indexOf(tomorrowCriticitaObj.idro_temp) ? tomorrowCriticitaObj.idro_idra : tomorrowCriticitaObj.idro_temp
			};
			envelope.tomorrow = {...envelope.tomorrow, ...zoneTomorrowEnvelope}
			let tomorrow = {...tomorrowPrevisioneObj, ...tomorrowCriticitaObj};

			feature.properties.today = today;
			feature.properties.tomorrow = tomorrow;
		});
		const operationalPhases = {
			'today' : todayFasiOperativeArray,
			'tomorrow' : tomorrowFasiOperativeArray
		}
		let todayTomorrowLabels = defaultTodayTomorrowLabels;
		let selectedDay = 'today';
		let now = moment();
		let passedDays = now.endOf('day').diff(moment(createdAt).startOf('day'), 'days');
		if (passedDays > 1) {
			todayTomorrowLabels = {
				today: '',
				tomorrow: ''
			};
		} else if (passedDays === 1) {
			todayTomorrowLabels = {today: 'Ieri', tomorrow: 'Oggi'};
			selectedDay = 'tomorrow';
		}
		this.addCentroidGeoJSON(geoJson);
		this.setState({
			geoJson,
			createdAt,
			mauId,
			todayTomorrowLabels,
			selectedDay,
			envelope,
			operationalPhases,
			mapKey: (this.state.mapKey + 1) % 1000
		}, () => {
			if (!!this.mapRef){
				this.passMapToFix(this.mapRef)
			}
		})

	}
	addCentroidGeoJSON(geoJSON) {
		if (!!geoJSON){
			GISTools.addCustomCentroids(geoJSON);
			GISTools.addCustomSeaPoints(geoJSON);
		}
	}
	/*	centerMap() {
            let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
            this.map.leafletElement.fitBounds([
                [bbox.bbox[1], bbox.bbox[0]],
                [bbox.bbox[3], bbox.bbox[2]]
            ]);
        }*/
	onEachPolygon(feature, layer) {
		let popupContent = `<h3 style='color : white'>Zona ${feature.properties.ZONE}</h3>`;
		let popup = L.popup({ /*offset: [40, 40]*/})
			.setContent(popupContent);
		layer.bindPopup(popup);
		layer.on('mouseover', function (e) {
			this.openPopup();
		});
		layer.on('mouseout', function (e) {
			this.closePopup();
		});
		if (this.state.selectedLayer === 'fasi_op'){
			let operationalPhaseValue = this.state.operationalPhases[this.state.selectedDay].find(oP => oP.zone === feature.properties.ZONE).value;
			layer.bindTooltip(operationalPhaseValue,
				{permanent: true, direction:[1, 2, 3, 4].includes(feature.properties.ZONE) ? "left" : "right"}
			).openTooltip()
		}
	}

	getBackgroundColor(property, selectedLayer, selectedDay, zone){
		let color = 'grey';
		switch (selectedLayer) {
			case 'idro_idra':
			case 'idro_temp': color = property !== undefined ? ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.value === property).color : 'grey'; break;
			case 'general': color = chroma(ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.value === this.state.envelope[selectedDay][zone]).color); break;
			default: color = chroma(ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.value === this.state.envelope[selectedDay][zone]).color).alpha(0.75);
		}

		return color;
	}

	getIconColor(property, selectedLayer, selectedDay, zone){
		let color = 'grey';
		switch (selectedLayer) {
			case 'rainfall_pni': color = property !== undefined ? PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS.find(def => def.value === property).color : 'grey'; break;
			case 'rainfall_pi': color = property !== undefined ? PRECIPITAZIONI_IMPULSIVE_OPTIONS.find(def => def.value === property).color : 'grey'; break;

			case 'storm_surges':
			case 'wind':
			case 'frost': color = property !== undefined && property ? 'black' : 'grey'; break;

			case 'idro_idra':
			case 'idro_temp': color = property !== undefined ? ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.value === property).color : 'grey'; break;
			default: break;
		}
		return color;
	}
	getIconMarker(feature, latlng, context){
		let iconUrl = '', absent = false;

		const {selectedLayer, selectedDay} = this.state;

		const selectedFeature = feature.properties[selectedDay][selectedLayer];
	

		if (feature.properties.sea ){
			if (selectedLayer === 'storm_surges') {
				if (selectedFeature) {
					iconUrl = PRECIPITAZIONI_ICONS[selectedLayer];
				} else {
					iconUrl = PRECIPITAZIONI_ICONS[`no_${selectedLayer}`]
				}
			}
		} else {
			if (!['idro_temp', 'idro_idra','storm_surges' ].includes(selectedLayer) ) {

				switch (selectedLayer) {
					case 'frost':
					case 'wind':
						if (selectedFeature) {
							iconUrl = PRECIPITAZIONI_ICONS[selectedLayer];
						} else {
							iconUrl = PRECIPITAZIONI_ICONS[`no_${selectedLayer}`];
							absent = true;
						}
						break;
					default: {
						if (!!selectedFeature) {
							iconUrl = PRECIPITAZIONI_ICONS[selectedFeature];
						}
					}
				}

			}
		}


		if (!!iconUrl) {
			return L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [30, 30],
					iconUrl: iconUrl,
					popupAnchor: [0, 0]
				})
			})
		}
	}

	polygonStyle(feature, latlng) {
		const {selectedLayer, selectedDay} = this.state;
		let zone = feature.properties.ZONE;

		let color = _.isEmpty(this.state.envelope) ? 'grey' : chroma(ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.value === this.state.envelope[selectedDay][zone]).color).alpha(selectedLayer==="general" ? 1 : 0.5);
		if (!!selectedLayer && !!feature.properties[selectedDay] && !!feature.properties[selectedDay][selectedLayer]){
			let property = feature.properties[selectedDay][selectedLayer];
			color = this.getBackgroundColor(property, selectedLayer, selectedDay, zone);
			return {
				fillColor: color,
				weight: 1,
				opacity: 1,
				color: 'black',
				fillOpacity: 1
			};
		}
		return {
			fillColor: color,
			weight: 1,
			opacity: 1,
			color: "black",
			fillOpacity: 1
		};
	}
	handleListItemClick = (event, index) => {
		this.setState({selectedLayer: index, mapKey: (this.state.mapKey + 1) % 1000}, () => {
			if (this.state.selectedLayer === 'fasi_op'){
				this.removeLegend();
			}
		});
	};
	handleDaySelection = (event, newDay) => {
		this.setState({selectedDay : newDay, mapKey: (this.state.mapKey + 1) % 1000});
	};
	findWorstAlert(layerKey){
		let color = 'grey';
		try {
			let {geoJson, selectedDay, createdAt} = this.state;

			//console.log("Evaluate worst alert for ",this.state.mauId, layerKey, selectedDay)

			if (!!createdAt){
				geoJson.features.forEach(feature => {
					let property = feature.properties[selectedDay][layerKey];
					let zone = feature.properties.ZONE;
					let newColor = this.getIconColor(property, layerKey, selectedDay, zone);
					//console.log(this.state.mauId, layerKey, selectedDay, zone, property)
					if (colorScale.indexOf(newColor) > colorScale.indexOf(color)) {
						color = newColor;
					}
				})
			}
		} catch (err) {
			console.error(err)
		}

		return color;
	}
	getGridListCols = () => {
		if (WidthUtils.isMobile()) {
			return 4;
		}
		/*if (isWidthDown('sm', this.props.width)) {
			return 2;
		}*/
		return 1;
	}
	columnMode = () => WidthUtils.isMobile();
	showLegendControl = () => WidthUtils.isMobile() && this.state.selectedLayer !== 'fasi_op';

	toggleLegend = () => {
		this.setState({
			showLegend : !this.state.showLegend,
			mapKey: (this.state.mapKey + 1) % 1000
		}, () => {
			if (!this.state.showLegend){
				this.removeLegend();
			}
		})
	}
	removeLegend(){
		let tmp = document.getElementsByClassName("newLegend");
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		};
	}
	removeLegendControl(){
		let tmp = document.getElementsByClassName("legendControl");
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		};
	}
	passMapToFix(map){
		if (!!this.props.mapHasBeenChanged){
			this.props.mapHasBeenChanged(map)
		}
	}
	openInfoModal(selectedFeature, latlng){
		this.setState({
			showInfoModal : true,
			selectedFeature,
			selectedLatLng: latlng,
			selectedIstatCode : selectedFeature.properties.ISTAT
		})
	}

	closeInfoModal(){
		this.setState({
			showInfoModal : false,
			selectedFeature : null,
			selectedIstatCode : null,
			mapKey : (this.state.mapKey + 1) % 1000
		})
	}
	onEachMunicipalityPolygon(feature, layer) {
		layer.bindTooltip(`<b>${feature.properties.COMUNE}</b>`);
		let isBackoffice = window.location.href.includes("backoffice");
		layer.on('click', (e) => {
			if (!isBackoffice){
				layer.setStyle(polygonHighlightStyle)
				this.openInfoModal(feature, e.latlng);
			}
		});
	}

	getInfoModalContent = () => {
		let modalContent = [];
		let alertedListContent = [];
		const {selectedFeature, selectedLatLng, geoJson, envelope, selectedDay} = this.state;
		if (!!selectedFeature) {
			let {properties} = selectedFeature;
			let color = '#fff';

			let point = turf.point([selectedLatLng.lng, selectedLatLng.lat ]);
			let zonalFeature = geoJson.features.find(zone => turf.booleanPointInPolygon(point, zone));

			if (zonalFeature){

				let mauColor = chroma(colors[envelope[selectedDay][zonalFeature.properties.ZONE]] || 'green').alpha(0.5);
				alertedListContent.push(<h3 style={{textAlign: 'center', color: '#fff'}}> Livello generale di allerta
					idrogeologica da MAU: </h3>)
				alertedListContent.push(<h3 style={{
					textAlign: 'center',
					color: '#fff',
					backgroundColor: mauColor,
					borderRadius: '15px'
				}}> {envelope[selectedDay][zonalFeature.properties.ZONE] || 'VERDE'}</h3>)
			}

			modalContent.push(<h3 style={{textAlign: 'center', color: color}}> {properties.COMUNE} ({properties.PROVINCIA})<br></br> {properties.ISTAT} </h3>);

			if (alertedListContent.length > 0){
				/*modalContent.push(<br/>);*/
				/*	modalContent.push(<hr style={{margin: 0, height: '4vh'}}/>);*/
				modalContent.push(...alertedListContent);
			} else {
				modalContent.push(<h3 style={{textAlign: 'center', color: color}}> Nessuna allerta </h3>)
			}
		}
		return modalContent;
	}

	render() {
		let {selectedLayer, selectedDay, mauId, todayTomorrowLabels, createdAt, showLegend} = this.state;
		let isFrontoffice = window.location.href.includes("frontoffice")
		let docUrl = !!mauId ? properties.url.bulletinDownloadFile + '/' + mauId + '/pdf' : '';
		if(!!isFrontoffice){
			docUrl = "https://pc2.protezionecivilecalabria.it/api/alerts/last-mau/attachment";
		}
		let todayStr = moment(createdAt).format('DD/MM/yyyy');
		let tomorrowStr = moment(createdAt).add(1,'days').format('DD/MM/yyyy');
		let mapHeight = !!this.props.mapHeight ? this.props.mapHeight : this.mapOptions.height;

		return (

			<div className=" container-fluid mb-2">
				<div className="d-flex justify-content-center m-3">
					<BottomNavigation
						value={selectedDay}
						onChange={(event, newDay) => this.handleDaySelection(event,newDay)}
						style={{ height: "auto"}}
						showLabels>
						<CustomBottonNavigation style={{minWidth: "10vw", minHeight: "5rem", borderRadius: "25px 0px 0px 25px"}} label={<><b>{todayTomorrowLabels.today}</b><br/><b>PREVISIONE</b><br/>{todayStr}</>} value="today"/>
						<CustomBottonNavigation style={{minWidth: "10vw", minHeight: "5rem", borderRadius: "0px 25px 25px 0px"}} label={<><b>{todayTomorrowLabels.tomorrow}</b><br/><b>PREVISIONE</b><br/>{tomorrowStr}</>} value="tomorrow" />
					</BottomNavigation>
				</div>
				{!!this.state.createdAt ? <div className="row d-flex justify-content-center">
					<a href={docUrl}>
						<span className="icon-span">
							<FiClock/> Stato di allertamento emanato dal MAU (Messaggio Allertamento Unificato) del {DateUtils.epochToDate(this.state.createdAt)}
						</span>
					</a>

				</div> : <></>}
				<div className="row mt-2">
					<div className="col-lg-4 col-12">

						{/*<List component="nav" aria-label="main mailbox folders">*/}
						<GridList key={"list_"+this.state.mauId+"_"+this.state.selectedDay} spacing={15} cellHeight='auto' cols={this.getGridListCols()} className='pr-lg-3 pr-0'>
							{Object.keys(MAP_LAYER_LABELS).map(layerKey =>
								<ListItem
									style={{ border: '1px solid #E4E4E4' }}
									key={layerKey+"_"+this.state.mauId+"_"+this.state.selectedDay}
									button
									selected={selectedLayer === layerKey}
									onClick={(event) => this.handleListItemClick(event, layerKey)}
								>
									{!this.columnMode() ?
										<ListItemIcon >
											{<FaCircle color={this.findWorstAlert(layerKey)}/>}
										</ListItemIcon> : <></>}
									<ListItemIcon>
										{getLayerIcon(layerKey,this.columnMode() ? this.findWorstAlert(layerKey) : '#0c6aaf')}
									</ListItemIcon>
									{!this.columnMode() ? <ListItemText style={{color:"#0c6aaf"}} primary={MAP_LAYER_LABELS[layerKey]}/> : <></>}
								</ListItem>
							)}
						</GridList>
						{/*</List>*/}

					</div>
					<div className="col-lg-8 col-12">
						{this.columnMode() ? <h3 style={{textAlign: 'center'}}>{MAP_LAYER_LABELS[selectedLayer]}</h3> : <></>}
						<div className="custom-popup pt-lg-0 pt-2">
							<MapComponent
								key={this.state.mauId}
								width={"100%"}
								height={mapHeight}
								zoom={!!this.props.zoom ? this.props.zoom : this.mapOptions.zoom}
								zoomSnap={false}
								zoomControl={false}
								doubleClickZoom={false}
								dragging={false}
								zoomDelta={false}
								touchZoom={false}
								scrollWheelZoom={false}
								setMapRef={mapRef => this.mapRef = mapRef}
								noHome={true}
								tile = { <TileLayer
									attribution='<a href="https://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a> '
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>}>

								<div id="testamitutto">
									<GeoJSON key={"polygons_"+ this.state.selectedLayer +"_"+this.state.mauId+"_"+this.state.selectedDay}
											 data={this.state.geoJson}
											 onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
											 pointToLayer={(feature, latlng, context) => this.getIconMarker(feature, latlng, this)}
											 style={(feature, latlng) => this.polygonStyle(feature, latlng)}
									/>
									<GeoJSON key={"municipalities_" + this.state.mapKey}
											 data={GISTools.getCalabriaMunicipalities()}
											 style={{weight: 0.4,opacity: 1,color: "grey"}}
											 onEachFeature={(feature, layer) => this.onEachMunicipalityPolygon(feature, layer)}
									/>
								</div>
								{this.showLegendControl() && selectedLayer ? <LeafletMauLegendControl
									key={"legendControl_" + this.state.mapKey}
									toggleLegend={() => this.toggleLegend()}
									showLegend={showLegend}
								/> : <></>}
								{showLegend && selectedLayer && selectedLayer !== 'fasi_op' ? <LeafletMauLegend
									key={"legend_" + this.state.mapKey}
									replaceMode={true}
									selectedLayer={selectedLayer}
								/> : <></>}
							</MapComponent>
						</div>

					</div>
				</div>
				{/* ----------------------- INFO MODAL ----------------------- */}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showInfoModal}
					onClose={() => this.closeInfoModal()}
					onBackdropClick={() => this.closeInfoModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "rgba(27,39,50,0.83)",//2c3e50d4
							color : "#fff",
							width: this.columnMode() ? "100vw":"50vw",
							height: "auto",
							padding: '5vh',
							borderRadius: '15px',
							display: 'inline-grid'
						}}
					>
						<div>
							<IconButton  aria-label="close" style={{ float: 'right', color: 'white', padding: 0 }} onClick={() => this.closeInfoModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div>
							{this.getInfoModalContent()}
						</div>
					</div>
				</Modal>
			</div>

		)
	}
}


export default (MauMapComponent);
