import React from 'react'
import {GeoJSON, TileLayer} from 'react-leaflet';
import L from 'leaflet';
import Markers from "#/lib/Markers";
import {SEA_ICONS_PNG, WEATHER_ICONS_PNG, WIND_ICONS_PNG} from "#/backoffice/forecast/utils/ForecastUtils";
import MapComponent from "#/commons/map/MapComponent";

export default class MapWeatherFormComponent extends React.PureComponent {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		width: "100%",
		height: "60vh",
	}

	componentDidMount() {

		if (!!this.mapRef && !!this.props.fixMap){
			this.props.fixMap(this.mapRef)
		}
	}
	onEachPolygon(feature, layer) {
		//console.log('feature: ', feature);
	}

	polygonStyle(feature, latlng) {
		return {
			fillColor: '#fff0',
			weight: 1,
			opacity: 1,
			color: "#b8b5b5",
			fillOpacity:  0
		};
	}

	pointToLayer(feature, latlng){

		console.log(this.props)

		if (!!feature.properties.iconKey){
			let marker = L.marker(latlng, {
				draggable: !this.props.readOnly
				})
			marker.setIcon(new L.Icon({
				iconSize: [40, 40],
				iconUrl: {...WEATHER_ICONS_PNG, ...WIND_ICONS_PNG, ...SEA_ICONS_PNG}[feature.properties.iconKey],
				popupAnchor: [0, 0]
			}));
			if (this.props.onDragEnd){
				marker.on('dragend', event => {
					let latlng = event.target.getLatLng();
					this.props.onDragEnd(feature.properties.key, latlng)
				});
			}
			if (this.props.onRightClick){
				marker.on('contextmenu', event => {
					this.props.onRightClick(feature.properties.key)
				});
			}
			if (feature.properties.iconKey.includes('wind')) {
				if (this.props.onDoubleClick) {
					marker.on('click', event => {
						this.props.onDoubleClick(feature.properties.key)
					});
				}
					marker.setRotationAngle(feature.properties.angle).setRotationOrigin('center center')
			}

			return marker;
		};
		return null;
	}

	render() {
		const tile = <TileLayer
			attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
			url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
		/>;
		return (
			<div className="custom-popup" id={this.props.id}>
				<MapComponent
					key={this.props.mapKey}
					width={this.mapOptions.width}
					height={this.mapOptions.height}
					zoom={this.mapOptions.zoom}
					zoomSnap={false}
					zoomControl={false}
					doubleClickZoom={false}
					dragging={false}
					zoomDelta={false}
					touchZoom={false}
					scrollWheelZoom={false}
					closePopupOnClick={false}

					attributionControl={false}
					tile={tile}
					noHome={true}

					setMapRef={mapRef => this.mapRef = mapRef}>

					{!!this.props.geoJSON &&
					<GeoJSON key={"polygons_" + this.props.currentDay + "_" +  this.props.mapKey}
							 data={this.props.geoJSON}
							 onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
							 style={(feature, latlng) => this.polygonStyle(feature, latlng)}
							 pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}
					/>
					}
					{this.props.legend}
				</MapComponent>
			</div>
		)
	}

}
