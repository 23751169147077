import React from "react";

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import 'react-dropzone-uploader/dist/styles.css'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import moment from "moment";
import DownloadContainer from "#/commons/components/DownloadContainer"
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import InfluxOutflowClient from "#/lib/InfluxOutflowClient";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import ReactApexChart from "react-apexcharts";
import clonedeep from "lodash/cloneDeep";
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import {getOutflowScalesPromise} from "#/lib/MidaUtils";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import StationGisPicker from "#/commons/map/StationGisPicker";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import StationClient from "#/lib/StationClient";
import GISTools from "#/lib/GISTools";
import {FaCrosshairs, FaMapMarkerAlt} from "react-icons/fa";
import L from "leaflet";
import DateUtils from "#/lib/DateUtils";
import _ from "lodash";


const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
        Swal.showLoading()
    },
});




const columns = [
    { title: "Data e ora (UTC)", unit: "", field: "time"},
    { title: "Afflussi netti", unit: "[mc/s]",field: "afflussiNetti"},
    { title: "Afflussi totali", unit: "[mc/s]",field: "afflussiTotali"},
    { title: "Portata", unit: "[mc/s]",field: "q"},
    { title: "Coefficiente di deflusso", unit: "",field: "coeffDef"},
    { title: "Pioggia media (in 10 minuti)", unit: "[mm]",field: "pioggiaMedia"},
    { title: "Coefficiente udometrico", unit: "[(mc/s) / Kmq]",field: "coeffUDO"},
    { title: "Tirante misurato", unit: "",field: "tiranteMisurato"},
    { title: "Ws", unit: "",field: "ws"},
    { title: "Portata misurata ", unit: "[mc/s]", field: "qmisurata"},
];

const measuredHeightYAxis = {
    seriesName: 'Altezza misurata',
    opposite: true,
    axisTicks: {
        show: true,
    },
    axisBorder: {
        show: true,
        color: '#42b35e'
    },
    labels: {
        style: {
            colors: '#42b35e',
        },
        formatter: y => y ? y.toFixed(1) : ''
    },
    title: {
        text: "Altezza misurata (m)",
        style: {
            color: '#42b35e',
        }
    },
    tooltip: {
        enabled: true
    }
};
const measuredOutflowYAxis = {
    seriesName: 'Portata misurata (scale di deflusso)',
    opposite: true,
    axisTicks: {
        show: true,
    },
    axisBorder: {
        show: true,
        color: '#06a6b5'
    },
    labels: {
        style: {
            colors: '#06a6b5',
        },
        formatter: y => y ? y.toFixed(1) : ''
    },
    title: {
        text: "Portata misurata (scale di deflusso) (m^3/s)",
        style: {
            color: '#06a6b5',
        }
    },
    tooltip: {
        enabled: true
    }
};
const flowChartOptions = {
    chart: {
        id: "chartQ",
        type: "line",
    },
    xaxis: {
        type: "datetime",
    },
    tooltip: {
        x: {
            format: "dd/MM/yyyy HH:mm"
        }
    },
    yaxis: [{
        axisBorder: {
            show: true,
            color: '#ca703f'
        },
        labels: {
            style: {
                colors: '#ca703f',
            },
            minWidth: 40
        },
        title: {
            text: "Portata (m^3 /s)",
            style: {
                color: '#ca703f',
            }
        }
    }],
    colors: [ '#ca703f', '#06a6b5', '#42b35e'],
    stroke: {
        show: true,
        curve: "straight", // or 'smooth'
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: [5, 0, 0]
    },
    annotations: {
        yaxis: [],
        points: [{
            x: null,
            y: null,
            marker: {
                size: 6,
                fillColor: '#124a9b',
                strokeColor: '#124a9b',
                radius: 3,
                cssClass: 'apexcharts-custom-class'
            }
        }]
    }
};

const modelLabels = columns.reduce((map, elem) => {map[elem.field] = elem.title; return map},{});
const aDayInMillis = 24*60*60*1000;
const aQuarterInMillis = 15*60*1000;
const sixHoursInMillis = 6*60*60*1000;

const defaultStation = {
    value: null,
    label: <>&nbsp;</>
}
const defaultZone = {
    value: null,
    label: "Tutte le zone"
}


export class AfflussiDeflussiPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tableKey: 1,
            chartKey: 1,
            data: [],
            basinOptions: [],
            viewMode: "TABLE",
            chartSeries: [],
            chartOptions: {},

            showStationsModal: true,
            geoJSON: null,
            zoneOptions: [],
            stationOptions: [],
            selectedZone: defaultZone,
            selectedStation: defaultStation,
        }
    }

    buildZoneOptions() {
        let zoneOptions = [];
        let zones = GISTools.getAllZonePolygon();

        zoneOptions.push(defaultZone)
        zones.forEach(zone => {
            let id = zone.properties.ZONE;
            zoneOptions.push({
                value: parseInt(id),
                label: "ZONA " + id,
                icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
            });
        })
        // Add Out-of-Calabria zone
        let id = 9999;
        zoneOptions.push({
            value: parseInt(id),
            label: "Territorio extraregionale",
            icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
        });

        return zoneOptions
    }

    buildStationOptions(zoneId, geoJSON) {
        let stationOptions = [];
        let stations = geoJSON;

        if (!!zoneId) {
            if (zoneId === 9999){
                stations = this.state.stationsOutsideRegion;
            } else {
                let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
                let stationWithinZone = GISTools.intersect(geoJSON, zonePolygon);
                stations = stationWithinZone;
            }
        }
        let stationsProperties = GISTools.getPropertiesFromFeature(stations);
        stationOptions.push(defaultStation);
        stationsProperties.forEach(properties => {
            stationOptions.push({
                label: properties.name,
                value: properties.code,
                icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
            });
        })

        return stationOptions
    }
    componentDidMount = () => {
        Promise.all([getOutflowScalesPromise(), this.getHydrometersPromise()])
            .then(([outflowScales, data]) => {
                let zoneOptions = this.buildZoneOptions();
                let stationOptions = this.buildStationOptions(defaultStation.value, data.geoJSON);
                // Find stations out of Calabria
                let stationsOutsideRegion = GISTools.getStationsOutsideCalabria(data.geoJSON);
                this.setState({
                    mapKey: (this.state.mapKey + 1) % 1000,
                    outflowScales,
                    loading: false,
                    geoJSON: data.geoJSON,
                    zoneOptions: zoneOptions,
                    stationOptions: stationOptions,
                    stationsOutsideRegion: stationsOutsideRegion,
                })
            })
    }

    getHydrometersPromise = () => new Promise((resolve, reject) => {
        StationClient.getHydrometerStationsGeojson((geoJSON) => { resolve({ geoJSON }); }, () => { console.log("ERROR distributionParameters"); });
    });
    getInfluxOuflowBasinsPromise = () => new Promise((resolve, reject) => {
            InfluxOutflowClient.getInfluxOuflowBasins(
                basins => {
                    console.log("Basin's data retrieved..", basins);

                    resolve(basins);
                },
                (err) => {
                    console.error("An error occurred while retrieving basin's data..", err);
                    ReactSwal.fire('Errore nel recupero dei bacini', '', 'error');
                    reject();
                }
            )
        }
    )

    onSelectBasin = basinCode =>  {
        let selectedBasin = this.state.basinOptions.find(element => element.value === basinCode);
        let hydrometers = this.state.basins.find(b => b.code === basinCode).hydrometers;
        loadingSwal.fire('Recupero degli ultimi dati disponibili per il bacino ' + selectedBasin.label );
        InfluxOutflowClient.getInfluxOuflowDataByBasinCode(
            basinCode,
            execution => {
                loadingSwal.close();
                this.setState({
                    hydrometers,
                    execution,
                    selectedBasin,
                    selectedField: null,
                    chartSeries: null,
                    measuredSeries: null,
                    tableKey: (this.state.tableKey + 1) % 1000
                }, () => {

                })
            },
            err => {
                console.error("An error occurred while retrieving afflussi deflussi data..", err);
                ReactSwal.fire('Errore nel recupero dei dati del modello', '', 'error');
            }
        )
    }
    onViewModeChange = viewMode => {
        let state = {
            viewMode,
            tableKey: (this.state.tableKey + 1) % 1000
        };
        if (viewMode === 'TABLE'){
            state.field = null;
        }
        this.setState(state);
    }

    buildChartOptions = (key, chartSeries) => {
        let chartOptions = clonedeep(flowChartOptions);
        chartOptions.yaxis[0].title.text = modelLabels[key];

        chartOptions.xaxis.min = Math.min(...chartSeries[0].data.map(d => d[0]));
        chartOptions.xaxis.max = Math.max(...chartSeries[0].data.map(d => d[0]));

        let yMin = Math.min(...chartSeries[0].data.map(d => d[1]));
        let yMax = Math.max(...chartSeries[0].data.map(d => d[1]));
        if (yMin === 0 && yMax === 0){
            yMax = 0.1
        }
        chartOptions.yaxis[0].max = yMax*1.1;
        chartOptions.yaxis[0].min = yMin*0.9;
        if (chartSeries.length > 1){
            chartOptions.xaxis.min = Math.min(...[...chartSeries[0].data.map(d => d[0]), ...chartSeries[1].data.map(d => d[0])]);
            chartOptions.xaxis.max = Math.max(...[...chartSeries[0].data.map(d => d[0]), ...chartSeries[1].data.map(d => d[0])]);

            let flowYMin = Math.min(...[...chartSeries[1].data.map(d => d[1])]);
            let flowYMax = Math.max(...[ ...chartSeries[1].data.map(d => d[1])]);

            chartOptions.yaxis.push(measuredOutflowYAxis);
            if (flowYMin === 0 && flowYMax === 0){
                flowYMax = 0.1
            }
            chartOptions.yaxis[1].max = flowYMax*1.1;
            chartOptions.yaxis[1].min = flowYMin*0.9;

            let heightYMin = Math.min(...[...chartSeries[2].data.map(d => d[1])]);
            let heightYMax = Math.max(...[ ...chartSeries[2].data.map(d => d[1])]);

            chartOptions.yaxis.push(measuredHeightYAxis);
            if (heightYMin === 0 && heightYMax === 0){
                heightYMax = 0.1
            }
            chartOptions.yaxis[2].max = heightYMax*1.1;
            chartOptions.yaxis[2].min = heightYMin*0.9;
        }



        let tEsecuzioneMillis = this.state.execution.executionTimestamp;
        let tCorrivazioneMillis = tEsecuzioneMillis + this.state.execution.tempoCorrivazioneMinutes*60*1000;
        chartOptions.annotations = {
            xaxis: [{
                x: tEsecuzioneMillis,
                fillColor: '#69cf29',
                label: {
                    style: {
                        color: '#69cf29',
                    },
                    text: 'Inizio esecuzione',
                    textAnchor: 'middle',
                }
            },
                {
                    x: tCorrivazioneMillis,
                    fillColor: '#cf2929',
                    label: {
                        style: {
                            color: '#cf2929',
                        },
                        text: 'Tempo di corrivazione',
                        textAnchor: 'middle',
                    }
                }]
        }

        return chartOptions;
    }

    buildChartSeries = key => new Promise((resolve, reject) => {

        let chartSeries = [ {
            name: modelLabels[key],
            data: this.state.execution.data.map(x => [x.timestamp, x[key] || x[key] === 0 ? x[key].toFixed(2) : null])
        }];
        if (this.state.measuredSeries){
            chartSeries.push(this.state.measuredSeries.OUTFLOW);
            chartSeries.push(this.state.measuredSeries.HEIGHT);
        }
        resolve(chartSeries)
    });

    addMeasuredDataToChart = () => {
        let {chartSeries, selectedField, sensorCode} = this.state;
        let end = this.state.execution.executionTimestamp;
        let start =  Math.min(...chartSeries[0].data.map(d => d[0])) - sixHoursInMillis;

        this.getDataPromise(sensorCode, start , end )
            .then(measuredSeries => {
                chartSeries = [chartSeries[0]];
                chartSeries.push(measuredSeries.OUTFLOW);
                chartSeries.push(measuredSeries.HEIGHT);
                let chartOptions = this.buildChartOptions(selectedField, chartSeries);
                loadingSwal.close();
                this.setState({
                    measuredSeries ,
                    chartSeries,
                    chartOptions,
                    chartKey: (this.state.chartKey + 1) % 1000
                })
            }).catch(() => {
            ReactSwal.fire('Errore nel recupero dei dati','','error')
            loadingSwal.close()
        })

    }

    buildChart = field => {

        this.buildChartSeries(field).then(chartSeries => {

            let chartOptions = this.buildChartOptions(field, chartSeries);
            let selectedField = columns.find(element => element.field === field);
            this.setState({
                selectedField,
                chartSeries,
                chartOptions,
                chartKey: (this.state.chartKey + 1) % 1000
            }, () => {
                if (field === 'q'){
                    this.addMeasuredDataToChart()
                }
            })
        })
    }
    getFlowFromOutflowScaleFormula = value =>{
        const {outflowScale : scales} = this.state;
        if (!!scales){
            const scale = scales.find(scale => value >= scale.thresholdStart && value < scale.thresholdEnd);
            if (!!scale){

                const {a,b,c,e} = scale;
                return a*Math.pow(value + e, b) + c; // 'q = a (h + e)^{b} + c';
            }
        }
        return null;
    }
    getFakeDataPromise = (sensorCode, start, end) => new Promise((resolve, reject) => {
            let data =  Array(Math.ceil((end - start)/aQuarterInMillis) + 1).fill().map((_, idx) => [start + idx*aQuarterInMillis, Math.round(Math.random()*10)]);
            let outflow = Array(Math.ceil((end - start)/aQuarterInMillis) + 1).fill().map((_, idx) => [start + idx*aQuarterInMillis, Math.round(Math.random()*100)]);
            resolve(
                {
                    OUTFLOW: {
                        name: 'Portata misurata (scale di deflusso)',
                        data: outflow
                    },
                    HEIGHT: {
                        name: 'Altezza misurata',
                        data: data
                    }
                }
            )
        }
    );
    getDataPromise = (sensorCode, start, end) => new Promise((resolve, reject) => {

            MeasurementsDataClient.getDataForChart(
                (data) =>{
                    if(data.length > 0){
                        data.shift();
                        resolve(
                            {
                                OUTFLOW: {
                                    name: 'Portata misurata (scale di deflusso)',
                                    data : data.map(({ts, value}) => [ts, this.getFlowFromOutflowScaleFormula(value)])
                                },
                                HEIGHT: {
                                    name: 'Altezza misurata',
                                    data: data.map(({ts, value}) => [ts, value])
                                }
                            }
                        )
                    } else {
                        console.log('No data measurements');
                        resolve({
                            name: 'Portata misurata (scale di deflusso)',
                            data: []
                        })

                    }
                },
                (msg) => {
                    console.error('Error retrieving data measurements');
                    reject()},
                {code: sensorCode, start, end},
                2000)
        }
    );
    onCloseStationsModal() {
        this.setState({
            showStationsModal: false
        })
    }

    openStationsModal() {
        this.setState({
            showStationsModal: true
        })
    }

    onSelectZone(zoneId) {
        let stationOptions = [];
        let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
        stationOptions = this.buildStationOptions(zoneId, this.state.geoJSON);

        this.setState({
            stationOptions: stationOptions,
            selectedZone: selectedZone,
            selectedStation: defaultStation,
        })
    }

    onSelectStation(stationCode) {
        this.setState({
            showStationsModal: false,
            chartSeries: [],
            chartOptions: {},
            execution: null
        }, () =>{
            loadingSwal.fire('Caricamento dati in corso...');
            let foundFeature = this.state.geoJSON.features.find(f => f.properties.code === stationCode);
            if (foundFeature){
                let sensorCode = foundFeature.properties.sensorCode;

                InfluxOutflowClient.getInfluxOutflowDataByHydrometer(
                    sensorCode,
                    (execution) =>{
                        loadingSwal.close();
                        if(execution){
                            this.setState({
                                sensorCode,
                                execution,
                                selectedField: null,
                                chartSeries: null,
                                measuredSeries: null,
                                tableKey: (this.state.tableKey + 1) % 1000
                            })
                        } else {
                            console.log('No influx outflow data measurements');
                            ReactSwal.fire({
                                title: 'Nessun dato disponibile per la stazione selezionata ',
                                text : '',
                                icon : 'info'
                            }).then(v => {
                                this.setState({
                                    showStationsModal: true
                            })
                            });
                        }
                    },
                    (msg) => {
                        console.log('Error retrieving data measurements');
                        ReactSwal.fire({
                            title: 'Si è verificato un errore nel caricamento dei dati ',
                            text : '',
                            icon : 'error'
                        }).then(v => {
                            this.setState({
                                showStationsModal: true
                            })
                        });
                    }
                )
            }


        })
    }
    zonesStyle(feature) {
        let zone = parseInt(feature.properties.ZONE);
        let style = {};

        if (this.state.selectedZone.value === 0) {
            if (this.state.zoneOptions.map(x => x.value).includes(zone)) {

                style = {
                    fillColor: "#20468c69",
                    weight: 0.5,
                    opacity: 1,
                    color: "#20468c69",
                    fillOpacity: 0.5
                };
            }
            else {
                style = {
                    fillColor: "#fff0",
                    weight: 0.5,
                    opacity: 1,
                    color: "#20468c",
                    fillOpacity: 0
                };
            }

        }
        else {
            if (this.state.selectedZone.value === zone) {
                style = {
                    fillColor: "#20468c69",
                    weight: 0.5,
                    opacity: 1,
                    color: "#20468c69",
                    fillOpacity: 0.5
                };
            }
            else {
                style = {
                    fillColor: "#fff0",
                    weight: 0.5,
                    opacity: 1,
                    color: "#20468c",
                    fillOpacity: 0
                };
            }
        }


        return style;
    }

    stationToMarker = (feature, latlng) =>
        L.marker(latlng, {
            icon: new L.Icon({
                iconSize: [15, 15],
                iconUrl: '/img/marker_point.png',
                popupAnchor: [0, 0]
            })
        });



    onEachStation(feature, layer) {
        layer.on('click', (e) => this.onSelectStation(feature.properties.code));

        var popup = L.popup({ closeButton: false })
            .setContent('<div><span class="mr-2 fas fa-map-marker-alt"></span>' + feature.properties.name + '</div>');
        layer.bindPopup(popup);
        let timer = null;
        layer.on('mouseover', function (e) {
            timer = setTimeout(() => this.openPopup(),
                1000)
        });
        layer.on('mouseout', function (e) {
            clearTimeout(timer);
            this.closePopup();
        });
    }

    render = () => {
        const {execution, loading, tableKey, basinOptions, selectedBasin, viewMode, chartKey, chartOptions, chartSeries, selectedField} = this.state;
        console.log('chartSeries', chartSeries)
        return (
            <div className="generic-page container-fluid data-page">
                <div className="row justify-content-center">
                    <h2>Modello Afflussi-Deflussi</h2>{execution && <h3 style={{marginLeft: '2vw'}}>(tempo di esecuzione: {DateUtils.epochToDateMinuteResolution(execution.executionTimestamp)} GMT+1)</h3>}
                </div>
                <div className="row textAlignCenter">
                    <div className="col-3 m-auto">
                        <Tooltip title={"Aggiungi dati al grafico"}>
                            <Button
                                className="justify-content-end ml-auto"
                                variant="contained"
                                color="primary"
                                startIcon={<></>}
                                onClick={(e) => this.openStationsModal()}
                            >Cambia idrometro</Button>
                        </Tooltip>
                    </div>

                    <div className="col-3 m-auto">
                        <FormControl className="ml-4" component="fieldset" >
                            <FormLabel component="legend">Modalità di visualizzazione</FormLabel>
                            <RadioGroup row={true} aria-label="viewMode" name="viewMode" value={viewMode}
                                        onChange={(event) => this.onViewModeChange(event.target.value)}>
                                <FormControlLabel value="TABLE" disabled={!execution} control={<Radio />} label="Tabella" />
                                <FormControlLabel value="CHART" disabled={!execution}  control={<Radio />} label="Grafico" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    { viewMode === 'CHART' ? <div className="col-6 row">
                        <div className="col-12 m-auto">
                            <InputSelectComponent
                                className={"col-12"}
                                isDisabled={!execution}
                                placeholder={"Selezione grandezza"}
                                optionList={columns.map(({field, title}) => ({value: field, label: title}))}
                                value={!!selectedField ? selectedField.value : null}
                                onChange={field => this.buildChart(field)}
                            />
                        </div>

                    </div> : <div className="col-6 row"/>}
                </div>
                {execution && execution.data &&
                <div className="row mt-4">
                    <div className="col-12">
                        <DownloadContainer
                            imageName={viewMode}
                            disabled={loading}
                            extraClassNames={"float-left"}
                        >
                            {viewMode === 'TABLE' && <ReactTabulator
                                ref={ref => (this.tableRef = ref)}
                                columns={columns}
                                data={execution.data}
                                options={{
                                    data: [],
                                    height: "60vh"

                                }}
                                key={"table_" + tableKey}
                            />}
                            {selectedField &&
                            <div className="mt-2" style={{height: '60vh'}}>
                                <ReactApexChart
                                    key={"q_" + chartKey}
                                    options={chartOptions}
                                    series={chartSeries}
                                    type="line"
                                    height="100%"
                                    width="100%"
                                />
                            </div>}
                        </DownloadContainer>
                    </div>
                </div>}
                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    open={this.state.showStationsModal}
                    onClose={() => this.onCloseStationsModal()}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div
                        className="p-2"
                        style={{
                            background: "white",
                            width: "40vw",
                            height: "85vh",
                            borderRadius: "15px"
                        }}
                    >
                        <div className='w-100 d-flex justify-content-end'>
                            <IconButton aria-label="close" onClick={() => this.onCloseStationsModal()}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className="d-flex justify-content-center">
                            <h3>Scelta Stazione</h3>
                        </div>

                        <div>
                            <StationGisPicker
                                centerMapOnSelect={true}
                                geoJSON={this.state.geoJSON}
                                zoneOptions={this.state.zoneOptions}
                                selectedZone={this.state.selectedZone}
                                onSelectZone={(option) => this.onSelectZone(option)}
                                stationOptions={this.state.stationOptions}
                                selectedStation={this.state.selectedStation}
                                onSelectStation={(option) => this.onSelectStation(option)}
                                mapKey={this.state.mapKey}
                                onEachStation={(feature, layer) => this.onEachStation(feature, layer)}
                                stationToMarker={(feature, latlng) => this.stationToMarker(feature, latlng)}
                                zonesStyle={(feature) => this.zonesStyle(feature)}
                                mapHeight={"60vh"}
                            ></StationGisPicker>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
