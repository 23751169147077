import React from 'react'
import TimeField from 'react-simple-timefield';

import {SEA_ICONS_PNG, WEATHER_ICONS_SVG, WIND_ICONS_SVG} from "#/backoffice/forecast/utils/ForecastUtils";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';

import LabelledOutline from '#/commons/components/LabelledOutline';
import Tooltip from "@material-ui/core/Tooltip";

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)
export const ForecastMultizoneMeteoForm = (props) => {

	const viewMode = props.viewMode;
	const {fields} = props.formData;
	let currentDay = props.currentDay;

	return (

		<LabelledOutline id="myID" label={props.labelForm}>
			<FormGroup >
				<Grid container style={{ marginLeft: "30px" }} spacing={1} >
					<Grid item xs={4}>
					<TextField
						disabled
						label="Inizio Validità"
						name="hour_start"
						value={!!props.formData ? props.formData.hour_start || '' : ''}
					/>
					</Grid>
					<Grid item xs={4}>
					<TextField
						disabled
						label="Fine Validità"
						name="hour_end"
						value={!!props.formData ? props.formData.hour_end || '' : ''}
					/>
					</Grid>
				</Grid>


				<Grid container  spacing={6} >
					<Grid item xs={4} className="mt-2" >

						{Object.keys(WEATHER_ICONS_SVG).map(icon =>(
							<Tooltip
								title={"Aggiungi l'icona alla mappa. Posizionala trascinandola. Rimuovila con click destro."}>
								<button onClick={() => props.onIconSelected(icon)}
										style={{
											background: '#f4f6f3',
											borderRadius: '50px',
											border: '0px',
											margin: '1%'
										}}>
									<img style={{height: '2vw'}} src={WEATHER_ICONS_SVG[icon]} alt={icon}/>
								</button>
							</Tooltip>
						))}
						<hr/>
						{Object.keys(WIND_ICONS_SVG).map(icon =>(
							<Tooltip
								title={"Aggiungi l'icona alla mappa. Posizionala trascinandola. Rimuovila con click destro."}>
								<button onClick={() => props.onIconSelected(icon)}
										style={{
											background: '#f4f6f3',
											borderRadius: '50px',
											border: '0px',
											margin: '1%'
										}}>
									<img style={{height: '2vw'}} src={WIND_ICONS_SVG[icon]} alt={icon}/>
								</button>
							</Tooltip>
						))}
						<hr/>
						{Object.keys(SEA_ICONS_PNG).map(icon =>(
							<Tooltip
								title={"Aggiungi l'icona alla mappa. Posizionala trascinandola. Rimuovila con click destro."}>
								<button onClick={() => props.onIconSelected(icon)}
										style={{
											background: '#f4f6f3',
											borderRadius: '50px',
											border: '0px',
											margin: '1%'
										}}>
									<img style={{height: '2vw'}} src={SEA_ICONS_PNG[icon]} alt={icon}/>
								</button>
							</Tooltip>
						))}
					</Grid>
					<Grid item xs={8}>
						{Object.keys(fields).map(field =>
							<div className="mt-3">
								<TextField
									disabled={viewMode}
									fullWidth
									multiline
									rows={2}
									id={field}
									name={field}
									label={capitalizeFirstLetter(field)}
									value={!!fields[field] ? fields[field] || '' : ''}
									onChange={(e) => props.onFormChange(currentDay, null, e.target.name, e.target.value)}
								/>
							</div>
						)}
					</Grid>
				</Grid>


			</FormGroup>
		</LabelledOutline>
	)


}
