import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class AnalysisClient {

	static getTemperatureStats(range, yearLimit, monthLimit, okCallback, errCallback){
		let url = new URL(properties.url.temperatureStats + "/" + range[0] + "/" + range[1]+ "/" + yearLimit+ "/" + monthLimit) ;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getOutflowStats(yearLimit, monthLimit, okCallback, errCallback){
		let url = new URL(properties.url.outflowStats + "/" + yearLimit+ "/" + monthLimit) ;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

}
