import React from 'react'
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import '#/backoffice/style/OutflowStats.css';
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import '#/backoffice/style/BackOffice.css';

import AddIcon from '@material-ui/icons/Add';
import 'react-dropzone-uploader/dist/styles.css'

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import WorkItemClient from "#/lib/WorkItemClient";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DateUtils from "#/lib/DateUtils";
import {Calendar, CalendarControls} from "react-yearly-calendar";
import moment from "moment";
import {DateRange, Delete, Edit} from "@material-ui/icons";


const workItemType = {
    MAU : "/mau/new",
    MAIB : "/maibmp/new",
    BIM : "/bim/new"
}
const EditButton = (props) =>
    props.cell._cell.row.data.showButton ?
        <Tooltip title={"Modifica attività"}>
            <IconButton color="primary" aria-label="upload picture" component="span"
                        onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
                <Edit />
            </IconButton>
        </Tooltip> :
        <></>

const DeleteButton = (props) =>
    props.cell._cell.row.data.showButton ?
        <Tooltip title={"Elimina attività"}>
            <IconButton color="primary" aria-label="upload picture" component="span"
                        onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
                <Delete />
            </IconButton>
        </Tooltip> :
        <></>



const showGoToButton = (workItem,newDate) =>
    workItem.type !== 'OTHER' && // activity type is a standard type (mau, maib, etc)
    Object.keys(missing).includes(workItem.level) && // activity must be not completed
    DateUtils.isToday(newDate) // activity can be carried out only for current day
;
const GoToButton = (props) => {

	if(!!props.cell._cell.row.data.showButton){
		return (
			//FIXME this href è una merda
			<a href={"/backoffice" + workItemType[props.cell._cell.row.data.type]}>
				<Tooltip title={"Completa attività"}>
					<IconButton color="primary" aria-label="upload picture" component="span" >
						<ArrowForwardIcon/>
					</IconButton>
				</Tooltip>
			</a>
		)
	}
	else {
		return <></>;
	}

};

const OpenCalendarButton = (props) =>
    (<Tooltip title={"Consulta giornali di sala storici"}>
        <IconButton color="primary" aria-label="upload picture" component="span"
                    onClick={(e) => { props.clickCallback(e) }}>
            <DateRange/>
        </IconButton>
    </Tooltip>);



const ReactSwal = withReactContent(Swal);

const inputSwal = Swal.mixin({
    title: 'Aggiungi una descrizione',
    input: 'textarea',
    inputValue: 'Descrizione',
    showCancelButton: true,
    cancelButtonText: 'Annulla',
    inputValidator: (value) => {
        if (!value) {
            return 'La descrizione non può essere vuota!'
        }
    }
})



const missing = {
    NONE : '#fff',
    EXPIRING : '#e0b10b',
    EXPIRED : '#f44336',
};
const workItemDeadlineLevel = {

    ...missing,
    COMPLETED : 'hsla(95,92%,45%,0.74)',
    DELAYED : 'hsla(173,93%,41%,0.47)'
};

const translations = {
    NONE : '',
    EXPIRING : 'IN SCADENZA',
    EXPIRED : 'SCADUTO',
    COMPLETED : 'COMPLETATO',
    DELAYED : 'IN RITARDO'
}


const defaultColumns =  [].concat([
    { title: "<div style=\"text-align: center\">Funzionario</div>", formatter: "html", field: "author", headerSort: false},
    { title: "<div style=\"text-align: center\">Data di <br/>esecuzione</div>",
        formatter: (cell) => DateUtils.epochToDate(cell.getValue()),
        field: "timestamp",
        headerSort: false,
        hozAlign: "center"},

]);

const defaultDate = moment();
export default class WorkItemsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedDate : defaultDate,
            year : defaultDate.year(),
            tableKey: 1,
            workItemsData : [],
            showCalendar : false
        }
    }

    componentDidMount() {
        this.loadWorkItemListFromAPI();
    }

    loadWorkItemListFromAPI(date){
        const d = !!date ? date : this.state.selectedDate;
        WorkItemClient.getWorkItemsList(d.year(), d.month() + 1, d.date(),
            (data) => {
                data.forEach(wi => wi.showButton = wi.mandatory ? showGoToButton(wi,d) : DateUtils.isToday(d));
                this.setState({
                        workItemsData: data,
                        selectedDate : d,
                        year : d.year(),
                        showCalendar : false,
                        tableKey: (this.state.tableKey + 1) % 1000
                    }
                );
            },
            () => console.log("Error while retrieving work item list"));
    }

    getMandatoryColumns() {
        let columns = [...defaultColumns];
        columns.push(
            { title: "<div style=\"text-align: center\">Data di <br/>scadenza</div>",
                formatter: (cell) => DateUtils.epochToDate(cell.getValue()),
                field: "expireTimestamp",
                headerSort: false,
                hozAlign: "center"},
            { title: "<div style=\"text-align: center\">Tipo</div>", formatter: "html", field: "type", headerSort: false, width: 100},
            { title: "<div style=\"text-align: center\">Attività</div>", formatter: "textarea", field: "activity", headerSort: false, widthGrow: 2},
            {
                title: "", field: "level", width: 150, formatter: function (cell) {
                    cell.getElement().style.backgroundColor = workItemDeadlineLevel[cell.getValue()]
                    return translations[cell.getValue()];
                }
            },
            {
                title: "",
                formatter: reactFormatter(<GoToButton  ></GoToButton>),
                headerSort: false,
                hozAlign: "center",
                vertAlign: "middle",
                width: 50

            }
        )
        return columns;
    }

    getFacultativeColumns() {
        let columns = [...defaultColumns];
        columns.push(

            { title: "<div style=\"text-align: center\">Attività</div>", formatter: "textarea", field: "activity", headerSort: false, widthGrow: 4},
            {
                title: "",
                formatter: reactFormatter(<EditButton clickCallback={(elem) => this.editActivity(elem)}></EditButton>),
                headerSort: false,
                hozAlign: "center",
                vertAlign: "middle",
                width: 50

            },
            {
                title: "",
                formatter: reactFormatter(<DeleteButton clickCallback={(elem) => this.deleteActivity(elem)}></DeleteButton>),
                headerSort: false,
                hozAlign: "center",
                vertAlign: "middle",
                width: 50

            }
        )
        return columns;
    }

    editActivity(data){

        const editSwal = Swal.mixin({
            title: 'Modifica la descrizione',
            input: 'textarea',
            inputValue: data.activity,
            confirmButtonText: "Applica modifica",
            denyButtonText: `Elimina attività`,
            showCancelButton: true,
            showDenyButton: true,
            cancelButtonText: 'Annulla',

            inputValidator: (value) => {
                if (!value) {
                    return 'La descrizione non può essere vuota!'
                }
            }
        });
        editSwal.fire()
            .then(result => {
                    if (result.isConfirmed){
                        data.activity = result.value;
                        delete data.showButton;
                        WorkItemClient.updateWorkItem(data,
                            (res) => {
                                ReactSwal.fire('Attività modificata con successo', '', 'success');
                                this.loadWorkItemListFromAPI();
                            },
                            () => console.log("ERROR")
                        )
                    }
                }

            );
    }

    deleteActivity(data){
        const deleteSwal = Swal.mixin({
            title: 'Elimina attività',
            inputValue: data.activity,
            confirmButtonText: "Conferma",
            showCancelButton: true,
            cancelButtonText: 'Annulla'
        });
        deleteSwal.fire()
            .then(result => {
                    if (result.isConfirmed) {
                         WorkItemClient.deleteWorkItem(data.id,
                             (res) => {
                                 ReactSwal.fire('Attività eliminata con successo', '', 'success');
                                 this.loadWorkItemListFromAPI();
                             },
                             () => console.log("ERROR")
                         )
                    }
                }
            );
    }
    addNewActivity(){
        inputSwal.fire().then(result => {
                if (result.isConfirmed) {
                    WorkItemClient.addWorkItem(result.value,
                        (res) => {
                            ReactSwal.fire('Nuova attività archiviata con successo', '', 'success');
                            this.loadWorkItemListFromAPI();
                        },
                        () => console.log("ERROR")
                    )
                }
            }
        );
    }

    getTabulatorOptions() {
        let tabulatorOptions = {
            data: [],
        }
        return tabulatorOptions;
    }


    onPickDate(date){
         ;
        this.loadWorkItemListFromAPI(date);

    }
    onPrevYear() {
        this.setState({
            year: this.state.year - 1
        })
    }

    onNextYear() {
        this.setState({
            year: this.state.year + 1
        })
    }
    render() {
        return (
            <>

                <div className="generic-page container-fluid data-page">
                    <div className="d-flex flex-row-reverse mt-4">
                        <OpenCalendarButton clickCallback={() => this.setState({showCalendar: !this.state.showCalendar})}/>
                    </div>
                    {this.state.showCalendar ? <div className="row mt-4">
                        <div className="col-2">
                            <h3>Seleziona una data</h3>
                        </div>
                        <div className="col-8">

                            <CalendarControls
                                year={this.state.year}
                                showTodayButton={true}
                                onPrevYear={() => this.onPrevYear()}
                                onNextYear={() => this.onNextYear()}
                                goToToday={() => this.onPickDate(moment())}
                            />
                            <Calendar
                                year={this.state.year}
                                showDaysOfWeek={true}
                                selectedDay={this.state.selectedDate}
                                onPickDate={date => this.onPickDate(date)}
                            />
                        </div>
                        <div className="col-2"/>
                    </div> : <></>}
                    <div className=" mt-4">

                        <div className="d-flex justify-content-between">
                            <h3 >Attività obbligatorie</h3>
                        </div>
                        <ReactTabulator
                            columns={this.getMandatoryColumns()}
                            data={this.state.workItemsData.filter(wi => wi.mandatory)}
                            options={this.getTabulatorOptions()}
                            key={"expired_" + this.props.tableKey}
                        />

                        <div className="d-flex justify-content-between">
                            <h3>Attività facoltative</h3>
                            {DateUtils.isToday(this.state.selectedDate) ? <Tooltip title={"Aggiungi attività svolta"}>
                                <IconButton color="primary" aria-label="upload picture" component="span"
                                            size="medium"
                                            onClick={(e) => {
                                                this.addNewActivity()
                                            }}>
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Tooltip> : <></>}
                        </div>

                        <ReactTabulator
                            width={"500px"}
                            columns={this.getFacultativeColumns()}
                            data={this.state.workItemsData.filter(wi => !wi.mandatory)}
                            options={this.getTabulatorOptions()}
                            key={"completed_" + this.props.tableKey}
                        />


                    </div>

                </div>
            </>
        )
    }
}
