import React from "react";
import Select, {components} from "react-select";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {Input, MenuItem} from "@material-ui/core";
import './InputSelectComponent.css';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import _ from "lodash";

const customSelectStyles = {
    control: () => ({
        display: "flex",
        alignItems: "center",
        border: 0,
        height: "auto",
        background: "transparent",
        "&:hover": {
            boxShadow: "none"
        }
    }),
    menu: () => ({
        backgroundColor: "white",
        boxShadow: "1px 2px 6px #888888", // should be changed as material-ui
        position: "absolute",
        left: 0,
        top: `calc(100% + 1px)`,
        width: "100%",
        zIndex: 2,
        maxHeight: ITEM_HEIGHT * 4.5
    }),
    menuList: () => ({
        maxHeight: ITEM_HEIGHT * 4.5,
        overflowY: "auto"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        position: "absolute",
        top: state.hasValue || state.selectProps.inputValue ? -20 : "50%",
        transition: "top 0.1s, font-size 0.1s",
        fontSize: (state.hasValue || state.selectProps.inputValue) && 13
    })
}

const { ValueContainer, Placeholder } = components;

const Option = ({ selectOption, isFocused, isSelected, onFocus, label, data }) => {
    const handleClick = (event) => {
        selectOption(data, event);
    };
    return (
        <MenuItem
            key={data.value}
            onFocus={onFocus}
            selected={isFocused}
            onClick={handleClick}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400
            }}
        >
            {label}
        </MenuItem>
    );
}

const ArrowDropdowFunction = (props) => {

    return <ArrowDropDownIcon />;
}

const CustomValueContainer = ({ children, ...props }) =>
    <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
            {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
        )}
    </ValueContainer>


const CrossComponent = ({ component: Component, inputRef, ...other }) => {
    React.useImperativeHandle(inputRef, () => ({
        focus: () => {
            inputRef.current.focus();
        },
    }));
    return <CustomSelect {...other} />;
}

const CustomSelect = ({disabled, ...other }) =>  <Select
    components={{
        Option: Option,
        DropdownIndicator: ArrowDropdowFunction,
        ValueContainer: CustomValueContainer
    }}

    styles={customSelectStyles}
    isClearable={false}
    {...other}
    filterOption={({ data }, string) => (typeof data.displayValue === 'string') ? data.displayValue.toLowerCase().includes(string.toLowerCase()) : true}
/>

const ITEM_HEIGHT = 48;
const toOption = ({icon, label : displayValue, value, disabled}) => ({
    label : <>{icon}{displayValue}</>,
    displayValue,
    value,
    disabled
})

const buildOptions =  optionList =>  {
    const uniqueGroups = [...new Set(optionList.map(item => item.group))]
        .filter((obj) => obj)
    let options = [];
    if (uniqueGroups.length === 0) {
        options = optionList.map(item => toOption(item));
    } else {
        options = options.concat(optionList.filter(item => !!!item.group).map(item => toOption(item)));
        uniqueGroups.forEach(group => {
            let optionByGroup = optionList.filter(item => item.group === group).map(item => toOption(item))
            let firstItemInGroup = optionList.filter(item => item.group === group)[0]
            options = options.concat({
                label: firstItemInGroup.groupLabel || firstItemInGroup.group,
                options: optionByGroup
            });
        })
    }
    return options;
}

const findSelectedOption = (value, options) => {
    let flatoptions = [];
    if (!_.isEmpty(options)){
        options.forEach(opt => {
                if (!!opt.options){
                    flatoptions.push(...opt.options)
                } else {
                    flatoptions.push(opt)
                }
            }
        )
        return flatoptions.find(o => o.value === value);
    } else return null;
}

const InputSelectComponent = ({className, placeholder, isSearchable = false, deleteTooltip, optionList, value, onChange, isDisabled = false}) => {
    const handleChange = selectedOption => {
        onChange(selectedOption.value)
    }
    const options = buildOptions(optionList);
    const currentValue = value !== null && findSelectedOption(value, options);

    return(
        <div className={`${className }`}>
            <Input
                disabled={isDisabled}
                className={`inputSelect ${!!value && "inputSelectNotEmpty"} ${!isSearchable && "pointerCursor"}`}
                fullWidth
                inputComponent={CrossComponent}
                value={currentValue}
                onChange={handleChange}
                placeholder={placeholder}
                id="react-select-single"
                inputProps={{
                    options,
                    isSearchable: !isDisabled && isSearchable
                }}
                endAdornment={
                    (!!deleteTooltip && !!value) && <InputAdornment position="end">
                        <Tooltip title={deleteTooltip}>
                            <IconButton aria-label="delete" onClick={() => handleChange(options.find(opt => opt.value === null))} color="secondary">
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }
            />
        </div>
    );
}

export default InputSelectComponent;
