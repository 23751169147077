import React from 'react';
import Skeleton from "react-loading-skeleton";
import ReactApexChart from "react-apexcharts";
import clonedeep from 'lodash/cloneDeep';
import SensorClient from "#/lib/SensorClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import DateUtils from "#/lib/DateUtils";
import {FaRegCalendarAlt} from "react-icons/fa";
import Button from "@material-ui/core/Button";
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import moment from "moment";
import InfluxOutflowClient from "#/lib/InfluxOutflowClient";
import _ from 'lodash';

const ReactSwal = withReactContent(Swal)
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

const heigthChartOptions = {
	chart: {
		id: "chartH",
		type: "line",
		/* 		group: "hydro",
         */		events: {
			markerClick: null
		}
	},
	xaxis: {
		type: "datetime",
	},
	tooltip: {
		x: {
			format: "dd/MM/yyyy HH:mm"
		}
	},
	yaxis: {
		labels: {
			minWidth: 40
		},
		title: {
			text: "Altezza Idrometrica (m)"
		}
	},
	stroke: {
		show: true,
		curve: "straight",
		lineCap: "butt",
		colors: undefined,
		width: 2,
		dashArray: 0
	},
	annotations: {
		yaxis: [],
		points: [{
			x: null,
			y: null,
			marker: {
				size: 6,
				fillColor: '#124a9b',
				strokeColor: '#124a9b',
				radius: 3,
				cssClass: 'apexcharts-custom-class'
			}
		}]
	}
};

const newError = (timestamp, label) => ({
	x: timestamp - 1000*60*15,
	x2: timestamp + 1000*60*15,
	fillColor: '#cf2929',
	label: {
		style: {
			color: '#cf2929',
		},
		text: label,
		textAnchor: 'middle',
	}
})
const flowChartOptions = {
	chart: {
		id: "chartQ",
		type: "line",
		/* 		group: "hydro",
         */	},
	xaxis: {
		type: "datetime",
	},
	tooltip: {
		x: {
			format: "dd/MM/yyyy HH:mm"
		}
	},
	yaxis: {
		labels: {
			minWidth: 40
		},
		title: {
			text: "Portata (m^3 /s)"
		}
	},

	stroke: {
		show: true,
		curve: "straight", // or 'smooth'
		lineCap: "butt",
		colors: undefined,
		width: 2,
		dashArray: [0, 5]
	},
	annotations: {
		yaxis: [],
		points: [{
			x: null,
			y: null,
			marker: {
				size: 6,
				fillColor: '#124a9b',
				strokeColor: '#124a9b',
				radius: 3,
				cssClass: 'apexcharts-custom-class'
			}
		}]
	}
};

const sectionChartOptions = {
	chart: {
		id: "chartS",
		height: 550,
		type: "area"
	},
	colors: ["#1a94a4","#6d5540" ],
	fill: {
		opacity: 1,
		type: "solid",
		colors: ["#1a94a4","#6d5540" ]
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: "smooth"
	},
	xaxis: {
		type: "numeric",
	},
	annotations: {
		yaxis: [],
		points: [{
			x: null,
			y: null,
			marker: {
				size: 6,
				fillColor: '#124a9b',
				strokeColor: '#124a9b',
				radius: 3,
				cssClass: 'apexcharts-custom-class'
			}
		}]
	}
}

const thresholdYAnnotationTemplate = {
	y: null, // setThis
	y2: null,
	borderColor: null, //setThis
	fillColor: null, //setThis
	opacity: 0.2,
	label: {
		borderColor: null, //setThis
		style: {
			fontSize: '10px',
			color: '#333',
			background: null, //setThis
		},
		text: null, //setThis
	}
}


export default class HydroCharts extends React.Component {

	/*  props:
		sensorCode,
		interval,
		thresholdList,
		outflowScale,


	*/

	constructor(props) {
		super(props);
		this.state = {
			seriesH : [],
			seriesQ : [],
			optionsH : {},
			optionsQ : {},
			optionsS : sectionChartOptions,
			seriesS : [],
			selectedTimestamp : null,
			loading : true,
			chartKey : 1,
			showBigCalendar: false,
			year: moment().year(),
			enableFlowChart: false
		}
	}

	componentDidMount(){
		let chartData = {thresholdList : []};
		const { sensorThresholdList} = this.props;
		if (!!sensorThresholdList){
			let thresholdList = sensorThresholdList.map(({id, thresholdStart : min, thresholdEnd : max, thresholdCode : name, thresholdLevel : color}) => ({
				id, min, max, name, color
			}));
			chartData.thresholdList = thresholdList;
		}
		let promises = [
			this.getOutflowsGeometryPromise(),
			this.getDataPromise(this.props.sensorCode, this.props.interval)
		];
		if (this.props.afflussiDeflussi){
			promises.push(this.getLastAfflussiDeflussiExecutionData(this.props.sensorCode));
		}
		Promise.all(promises)
			.then(values => {
				chartData = {...chartData,...values[0],...values[1], ...values[2]};
				console.log("CHART DATA",chartData)
				this.buildChart(chartData)
			}).catch(errors =>{
				console.log('Errors from promise all',errors)
			}
		)

	}

	getFlowFromOutflowScaleFormula(value){
		const {outflowScale : scales} = this.props;
		if (!!scales){
			const scale = scales.find(scale => value >= scale.thresholdStart && value < scale.thresholdEnd);
			if (!!scale){
				const {a,b,c,e} = scale;
				let q = a*Math.pow(value + e, b) + c;
				return q; // 'q = a (h + e)^{b} + c';
			}
		}
		return null;
	}

	buildChart(chartData){

		console.log("loading Hydro data")

		let xMax = this.props.interval.end.valueOf()+ ((this.props.interval.end.valueOf()-this.props.interval.start.valueOf())*0.1);
		let optionsH = clonedeep(heigthChartOptions);

		let hydrometricZero = chartData.hydrometricZero;
		// Update y-axis range on heights-chart
		let minData = Math.min(...chartData.data.map(x => x.value));
		let maxData = Math.max(...chartData.data.map(x => x.value));
		let minThreshold = Math.min(...chartData.thresholdList.map(th => th.min));
		let maxThreshold = Math.max(...chartData.thresholdList.map(th => th.min));
		optionsH = this.computeYaxisRange(optionsH, minThreshold, maxThreshold, minData, maxData);

		let optionsQ = {};
		let seriesQ = [];
		let minFlowThreshold = 0, maxFlowThreshold = 0;

		optionsQ = clonedeep(flowChartOptions);

		let errors = [];
		seriesQ = [{
			name: "Portata",
			data: chartData.data.map((x, i) => {
				let {flow, measurement_timestamp : timestamp} = x;
				// check flow values
				if (x.flow === null){
					if (i !== 0 ){
						if (errors.length > 0 && errors[errors.length - 1].x2 === timestamp && errors[errors.length - 1].label.text === 'Scala non trovata'){
							errors[errors.length - 1].x2 = timestamp + 1000*60*15
						} else {
							errors.push(newError(timestamp, 'Scala non trovata'))
						}
					} else {
						errors.push(newError(timestamp, 'Scala non trovata'))
					}
					flow = null;
				} else if (Number.isNaN(x.flow)){
					// Errore nel calcolo (base negativa)
					if (i !== 0 ){
						if (errors.length > 0 && errors[errors.length - 1].x2 === timestamp && errors[errors.length - 1].label.text === 'Errore nel calcolo (base negativa)'){
							errors[errors.length - 1].x2 = timestamp + 1000*60*15
						} else {
							errors.push(newError(timestamp, 'Errore nel calcolo (base negativa)'))
						}
					} else {
						errors.push(newError(timestamp, 'Errore nel calcolo (base negativa)'))
					}
					flow = null;
				} else if (x.flow < 0){
					// Valore negativo non accettabile
					if (i !== 0 ){
						if (errors.length > 0 && errors[errors.length - 1].x2 === timestamp && errors[errors.length - 1].label.text === 'Valore negativo non accettabile'){
							errors[errors.length - 1].x2 = timestamp + 1000*60*15
						} else {
							errors.push(newError(timestamp, 'Valore negativo non accettabile'))
						}
					} else {
						errors.push(newError(timestamp, 'Valore negativo non accettabile'))
					}
					flow = null;
				} else {
					// valore corretto
				}
				return [timestamp, flow ? flow.toFixed(2) : null]
			})
		}];
		if (this.props.afflussiDeflussi){
			seriesQ.push({
				name: "Portata stimata",
				data: []
			})

			if (chartData.estimatedFlowData && chartData.estimatedFlowData.length > 0) {
				seriesQ[1].data = chartData.estimatedFlowData.map(d => [d[0], d[1].toFixed(2)]);
			}
		}

		if (chartData.data.filter(x => x.flow !== null && !Number.isNaN(x.flow) && x.flow > 0).length === 0){
			errors = [{
				x: this.props.interval.start.valueOf(),
				x2: this.props.interval.end.valueOf(),
				fillColor: '#cf2929',
				label: {
					style: {
						color: '#cf2929',
					},
					orientation: 'horizontal',
					text: 'Scale di deflusso non trovate',
					textAnchor: 'start',
					offsetX: 100
				}
			}]
		}
		optionsQ.annotations.xaxis = errors;
		let minX = this.props.interval.start.valueOf() + 3600000;
		let maxX = xMax + 3600000;
		if (this.props.afflussiDeflussi) {
			 maxX = seriesQ[1].data.length > 0 ? Math.max(...seriesQ[1].data.map(d => d[0])) + 3600000 : xMax + 3600000;
		}
		optionsQ.xaxis.min = minX;
		optionsQ.xaxis.max = maxX;
		optionsH.xaxis.min = minX;
		optionsH.xaxis.max = maxX;

		// Update y-axis range on flows-chart
		let allFlowData = [...seriesQ[0].data.map(d => d[1])];
		if (this.props.afflussiDeflussi) {
			allFlowData = [...allFlowData, ...seriesQ[1].data.map(d => d[1])];
		}
		let minFlowData = Math.min(...allFlowData);
		let maxFlowData = Math.max(...allFlowData);

		minFlowThreshold = chartData.thresholdList.length > 0 ? Math.min(...chartData.thresholdList.map(th => this.getFlowFromOutflowScaleFormula(th.min))) : null;
		maxFlowThreshold = chartData.thresholdList.length > 0 ? Math.max(...chartData.thresholdList.map(th => this.getFlowFromOutflowScaleFormula(th.min))) : null;

		optionsQ = this.computeYaxisRange(optionsQ, minFlowThreshold, maxFlowThreshold, minFlowData, maxFlowData);

		let seriesH = [{ name: "Altezza Idrometrica", data: chartData.data.map(x => { return  [x.measurement_timestamp, x.value] })}]
		/*let lastH = [0,-hydrometricZero];*/
		let lastH = [0,0];
		if (chartData.data.length > 0){
			optionsH.annotations.points[0].x = lastH[0];
			optionsH.annotations.points[0].y = lastH[1];
			lastH = seriesH[0].data[seriesH[0].data.length - 1];
		}

		optionsH.chart.events.markerClick = (event, chartContext, { seriesIndex, dataPointIndex, config}) => this.chartClick(seriesIndex,dataPointIndex);

		let optionsS = clonedeep(sectionChartOptions);

		let minYShape = !!chartData.sectionShape ? Math.min(...chartData.sectionShape.map(point => point[1])) : 0;
		let shiftedShape = chartData.sectionShape.map(point => [point[0],(point[1] -minYShape).toFixed(2)]);
		let hydroLevel = shiftedShape.map(x => { return  [x[0], lastH[1]] });
		let seriesS = [{ name: "Livello", data: hydroLevel}, { name: "Sezione", data: shiftedShape}];

		chartData.thresholdList.forEach((x,i)=>{
			// Add thresholds to heights chart
			let threshold = clonedeep(thresholdYAnnotationTemplate);
			const thresholdValue = x.min;
			threshold.y = thresholdValue;
			threshold.borderColor = x.color;
			threshold.fillColor = x.color;
			threshold.label.borderColor = x.color;
			threshold.label.style.background = x.color;
			threshold.label.text = x.name + ': ' + thresholdValue + " m";
			optionsH.annotations.yaxis.push(threshold);

			// Add thresholds to flows chart
			let flowThreshold = clonedeep(threshold);
			const flowThresholdValue = this.getFlowFromOutflowScaleFormula(x.min);
			flowThreshold.y = flowThresholdValue;
			flowThreshold.label.text = x.name + ': ' + flowThresholdValue + " m^3 / s";
			optionsQ.annotations.yaxis.push(flowThreshold);

			// Add thresholds to section chart
			let sectionThreshold = clonedeep(threshold);
			sectionThreshold.y = thresholdValue + hydrometricZero;
			sectionThreshold.label.text = x.name + ': ' + thresholdValue + " m ( " + flowThresholdValue + " m^3 / s )";
			if ( i % 2 === 0){
				sectionThreshold.label.position = 'left';
				sectionThreshold.label.textAnchor = 'start';
			}
			optionsS.annotations.yaxis.push(sectionThreshold);
		})

		this.setState({
			seriesH : seriesH,
			seriesQ : seriesQ,
			seriesS : seriesS,
			optionsH : optionsH,
			optionsQ : optionsQ,
			optionsS : optionsS,
			maxThreshold : maxThreshold,
			minThreshold : minThreshold,
			maxFlowThreshold : maxFlowThreshold,
			minFlowThreshold : minFlowThreshold,
			chartKey : this.state.chartKey + 1,
			selectedTimestamp : lastH[0],
			sectionShape : shiftedShape,
			hydrometricZero : hydrometricZero,
			loading : false,
			interval: this.props.interval,
			estimatedFlowData: chartData.estimatedFlowData

		})

	}

	computeYaxisRange(options,minThreshold,maxThreshold, minData, maxData) {

		options.yaxis.max = (!maxData || (maxThreshold && maxThreshold > maxData)) ? maxThreshold*1.1 : maxData*1.1;
		options.yaxis.min = (!minData || (minThreshold && minThreshold < minData)) ? minThreshold*0.9 : minData*0.9;
		return options;
	}

	chartClick(seriesIndex,dataPointIndex){

		console.log('dataPointIndex: ', dataPointIndex);
		console.log('seriesIndex: ', seriesIndex);
		console.log("Chart Click");

		let seriesH = this.state.seriesH[seriesIndex].data;
		let selectedH = seriesH[dataPointIndex];

		let optionsH = clonedeep(this.state.optionsH);
		optionsH.annotations.points[0].x = selectedH[0];
		optionsH.annotations.points[0].y = selectedH[1];


		let selectedTimestamp = selectedH[0];
		// Mark selected in flow chart
		let optionsQ = this.getUpdateOptionsQ(selectedTimestamp, seriesIndex);

		/*let hydrometricZero = this.state.hydrometricZero;*/
		let shape = this.state.sectionShape;
		let hydroLevel = shape.map(x => { return  [x[0], selectedH[1]] });
		let seriesS = [{ name: "Livello", data: hydroLevel}, { name: "Sezione", data: shape}]

		this.setState({
			chartKey : this.state.chartKey + 1,
			optionsH : optionsH,
			optionsQ : optionsQ,
			seriesS : seriesS,
			selectedTimestamp : selectedTimestamp
		})

	}

	getUpdateOptionsQ(selectedTimestamp, seriesIndex){
		let seriesQ = this.state.seriesQ[seriesIndex].data;
		let selectedQ = seriesQ.find(sQ => sQ[0] === selectedTimestamp);
		let optionsQ = clonedeep(this.state.optionsQ);
		optionsQ.annotations.points[0].x = selectedQ[0];
		optionsQ.annotations.points[0].y = selectedQ[1];

		return optionsQ
	}

	getDataPromise(sensorCode, interval){
		let params = {};
		params.start = interval.start.valueOf();
		params.end = interval.end.valueOf();
		params.code = sensorCode;

		return new Promise((resolve, reject) => {
				MeasurementsDataClient.getDataForChart(

					(data) =>{
						if(data.length > 0){
							data.shift();
							resolve(
								{
									data : data.map((meas,k) => {
										return {
											id: k,
											measurement_timestamp : meas.ts,
											value : meas.value,
											flow : !!this.props.outflowScale ? this.getFlowFromOutflowScaleFormula(meas.value) : null
										}})
								}
							)
						} else {
							console.log('No data measurements');
							ReactSwal.fire({
								title: 'Nessun dato reperito',
								text : 'Cambiare l\'intervallo di ricerca',
								icon : 'error'
							});
							resolve({
								data: []
							})

						}
					},
					(msg) => {
						console.log('Error retrieving data measurements');
						reject()},
					params,
					2000)
			}
		);
	}
	getLastAfflussiDeflussiExecutionData = sensorCode =>
		new Promise((resolve, reject) => {
				InfluxOutflowClient.getInfluxOutflowDataByHydrometer(
					sensorCode,
					(dataObject) =>{
						console.log('getInfluxOutflowDataByHydrometer', dataObject);
						let estimatedFlowData = [];
						if(!_.isEmpty(dataObject) && dataObject.data){
							estimatedFlowData = dataObject.data.map(({timestamp, q}) => [timestamp, q]);
						} else {
							console.log('No influx outflow data measurements');
							ReactSwal.fire({
								title: 'Nessun dato previsionale dal modello Afflussi Deflussi',
								text : '',
								icon : 'info'
							});
						}
						resolve({estimatedFlowData})
					},
					(msg) => {
						console.log('Error retrieving data measurements');
						resolve([])}
				)
			}
		);

	/*	getFAKEEstimatedFlowDataPromise(sensorCode, interval){

            let instant = interval.end.valueOf();

            return new Promise((resolve, reject) => {
                    InfluxOutflowClient.getEstimatedFakeFlowBySensorAndTimestamp(
                        sensorCode,
                        instant,
                        (dataObject) =>{
                            if(!_.isEmpty(dataObject)){
                                resolve({
                                        estimatedFlowData: Object.entries(dataObject).map(e => [Number(e[0]), e[1] !== null ? e[1].toFixed(2) : null])
                                    }
                                )
                            } else {
                                console.log('No influx outflow data measurements');
                                ReactSwal.fire({
                                    title: 'Nessun dato previsionale dal modello Afflussi Deflussi',
                                    text : '',
                                    icon : 'error'
                                });
                                resolve({
                                    data: []
                                })
                            }
                        },
                        (msg) => {
                            console.log('Error retrieving data measurements');
                            reject()}
                    )
                }
            );
        }*/

	getOutflowsGeometryPromise(){
		return new Promise((resolve,reject) => {
			SensorClient.getOutflowsGeometryBySensorCode(
				this.props.sensorCode,
				(data) => {
					resolve({
						sectionShape : !!data.geometry ? JSON.parse(data.geometry) : [],
						hydrometricZero : !!data.hydrometricZero ? data.hydrometricZero : 0
					});

					if (!!!data.geometry) {
						ReactSwal.fire({
							title: 'La geometria dell\'alveo non è stata trovata',
							text : '',
							icon : 'warning'
						});
					}
				},
				(msg) => {
					console.log('Error retrieving section shape');
				})
		})
	}


	updateChart(newData, year){
		console.log("updating Hydro data");

		let xMax = newData.interval.end.valueOf()+ ((newData.interval.end.valueOf()- newData.interval.start.valueOf())*0.1);
		let optionsH = this.state.optionsH;

		optionsH.xaxis.min = newData.interval.start.valueOf() + 3600000;
		optionsH.xaxis.max = xMax + 3600000;

		let seriesH = [{ name: "Altezza Idrometrica", data: newData.data.map(x => { return  [x.measurement_timestamp, x.value] })}]
		let lastH = seriesH[0].data[seriesH[0].data.length - 1];

		optionsH.annotations.points[0].x = lastH[0];
		optionsH.annotations.points[0].y = lastH[1];
		optionsH.chart.events.markerClick = (event, chartContext, { seriesIndex, dataPointIndex, config}) => this.chartClick(seriesIndex,dataPointIndex);

		// Update y-axis range
		let minData = Math.min(...newData.data.map(x => x.value));
		let maxData = Math.max(...newData.data.map(x => x.value));
		optionsH = this.computeYaxisRange(optionsH, this.state.minThreshold, this.state.maxThreshold, minData, maxData);

		let optionsQ = {};
		let seriesQ = [];

		optionsQ = this.state.optionsQ;
		// Mark selected in flow chart
		let errors = [];
		seriesQ = [{
			name: "Portata",
			data: newData.data.map((x, i) => {
				let {flow, measurement_timestamp : timestamp} = x;
				// check flow values
				if (x.flow === null){
					if (i !== 0 ){
						if (errors.length > 0 && errors[errors.length - 1].x2 === timestamp && errors[errors.length - 1].label.text === 'Scala non trovata'){
							errors[errors.length - 1].x2 = timestamp + 1000*60*15
						} else {
							errors.push(newError(timestamp, 'Scala non trovata'))
						}
					} else {
						errors.push(newError(timestamp, 'Scala non trovata'))
					}
					flow = null;
				} else if (Number.isNaN(x.flow)){
					// Errore nel calcolo (base negativa)
					if (i !== 0 ){
						if (errors.length > 0 && errors[errors.length - 1].x2 === timestamp && errors[errors.length - 1].label.text === 'Errore nel calcolo (base negativa)'){
							errors[errors.length - 1].x2 = timestamp + 1000*60*15
						} else {
							errors.push(newError(timestamp, 'Errore nel calcolo (base negativa)'))
						}
					} else {
						errors.push(newError(timestamp, 'Errore nel calcolo (base negativa)'))
					}
					flow = null;
				} else if (x.flow < 0){
					// Valore negativo non accettabile
					if (i !== 0 ){
						if (errors.length > 0 && errors[errors.length - 1].x2 === timestamp && errors[errors.length - 1].label.text === 'Valore negativo non accettabile'){
							errors[errors.length - 1].x2 = timestamp + 1000*60*15
						} else {
							errors.push(newError(timestamp, 'Valore negativo non accettabile'))
						}
					} else {
						errors.push(newError(timestamp, 'Valore negativo non accettabile'))
					}
					flow = null;
				} else {
					// valore corretto
				}
				return [timestamp, flow ? flow.toFixed(2) : null]
			})
		}];
		if (this.props.afflussiDeflussi) {
			seriesQ.push({
				name: "Portata stimata",
				data: []
			})
		}
		if (newData.estimatedFlowData && newData.estimatedFlowData.length > 0){
			seriesQ[1].data = newData.estimatedFlowData;
		}
		if (newData.data.filter(x => x.flow !== null && !Number.isNaN(x.flow) && x.flow > 0).length === 0){
			errors = [{
				x: this.state.interval.start.valueOf(),
				x2: this.state.interval.end.valueOf(),
				fillColor: '#cf2929',
				label: {
					style: {
						color: '#cf2929',
					},
					orientation: 'horizontal',
					text: 'Scale di deflusso non trovate',
					textAnchor: 'start',
					offsetX: 100
				}
			}]
		}
		optionsQ.annotations.xaxis = errors;

		optionsQ.xaxis.min = newData.interval.start.valueOf() + 3600000;
		optionsQ.xaxis.max = xMax + 3600000;
		let lastQ = seriesQ[0].data[seriesQ[0].data.length - 1];
		optionsQ.annotations.points[0].x = lastQ[0];
		optionsQ.annotations.points[0].y = lastQ[1];

		// Update y-axis range on flows-chart
		let allFlowData = [...seriesQ[0].data.map(d => d[1])];
		if (this.props.afflussiDeflussi) {
			allFlowData = [...allFlowData, ...seriesQ[1].data.map(d => d[1])];
		}
		let minFlowData = Math.min(...allFlowData);
		let maxFlowData = Math.max(...allFlowData);

		optionsQ = this.computeYaxisRange(optionsQ, this.state.minFlowThreshold, this.state.maxFlowThreshold, minFlowData, maxFlowData);


		let hydrometricZero = this.state.hydrometricZero;
		let shape = this.state.sectionShape;
		let hydroLevel = shape.map(x => { return  [x[0], hydrometricZero + lastH[1]] });
		let seriesS = [{ name: "Livello", data: hydroLevel}, { name: "Sezione", data: shape}]

		loadingSwal.close();
		this.setState({
			seriesH : seriesH,

			seriesS: seriesS,
			chartKey : this.state.chartKey + 1,
			optionsH : optionsH,
			seriesQ,
			optionsQ,
			selectedTimestamp : lastH[0],
			loading : false,
			interval: newData.interval,
			year,
			showBigCalendar: false
		})

	}

	onPrevYear() {
		this.setState({
			year: this.state.year - 1
		})
	}
	onNextYear() {
		this.setState({
			year: this.state.year + 1
		})
	}
	onPickRange(start, end, year) {
		loadingSwal.fire('Caricamento dati in corso...');
		let isTillToday = end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		let isFromToday = start.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		end = isTillToday ? moment().add(-30, 'm') : end.add(1,'d').startOf('day');
		start = isFromToday ? end.clone().add(-24, 'h') : start.startOf('day');
		let interval = {start, end}
		let newData = {interval};
		Promise.all([
			this.getDataPromise(this.props.sensorCode,interval)
		])
			.then(([dataValues]) => {

				if (dataValues.data.length > 0){
					newData.data = dataValues.data;
					newData.estimatedFlowData = this.state.estimatedFlowData
					this.updateChart(newData, !!year ? year : this.state.year)
				} else {
					loadingSwal.close();
					ReactSwal.fire('Nessun dato reperito per le date selezionate','','info')
				}
			}).catch(errors =>{
				console.log('Errors from promise all',errors)
			}
		)
	}
	render() {
		return (
			<>
				<div className="torow m-4">
					<div className="col-4 ">
						{/* 				<div className="row align-items-center">
 */}					<div className="col-6" style={{maxWidth: "100%"}}>
						<div className="row">
							<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" }}>Intervallo selezionato :</span>
							<br/>
							{!!this.state.interval ?
								<span style={{ fontSize: "1.2rem", fontFamily: "Roboto Slab" }}>{DateUtils.epochToGMT1String(this.state.interval.start)} - {DateUtils.epochToGMT1String(this.state.interval.end)}</span> :
								<span style={{ fontSize: "1.2rem", fontFamily: "Roboto Slab" }}>{DateUtils.epochToGMT1String(this.props.interval.start)} - {DateUtils.epochToGMT1String(this.props.interval.end)}</span>
							}
							<Button
								variant="contained"
								color="default"
								startIcon={<FaRegCalendarAlt/>}
								onClick={() => this.setState({showBigCalendar: true})}
							>
								Modifica intervallo
							</Button>
						</div>
					</div>
					</div>
				</div>
				{this.state.showBigCalendar ?
					<div className="row m-4">
						<BigCalendarTimeZoned
							timeZone={"Etc/GMT-1"}
							range={this.state.interval}
							year={this.state.year}
							onPrevYear={() => this.onPrevYear()}
							onNextYear={() => this.onNextYear()}
							onPickRangeCallback={(start, end, year)=>this.onPickRange(start, end, year)}
						></BigCalendarTimeZoned>
					</div>
					: <div className="row m-4">
						<div className="col-7">
							{!!this.state.loading
								?
								<Skeleton height={"60vh"} duration={8}/>
								:
								<>
									<div style={{width: "100%", height: "300px"}}>
										<ReactApexChart
											key={"h_" + this.state.chartKey}
											options={this.state.optionsH}
											series={this.state.seriesH}
											type="line"
											height="100%"
											width="100%"
										/>
									</div>
									<div style={{width: "100%", height: "300px"}}>
										<ReactApexChart
											key={"q_" + this.state.chartKey}
											options={this.state.optionsQ}
											series={this.state.seriesQ}

											type="line"
											height="100%"
											width="100%"
										/>
										{/*<>
												<h3 style={{color: 'red'}}>Si sono verificati errori nel calcolo delle portate</h3>
												<Skeleton height={"30vh"} duration={8} />
											</>*/}
									</div>
								</>

							}
						</div>
						<div className="col-5">
							{!!this.state.loading || this.state.sectionShape.length === 0
								?
								<Skeleton height={"60vh"} duration={8}/>
								:
								<ReactApexChart
									key={"s_" + this.state.chartKey}
									options={this.state.optionsS}
									series={this.state.seriesS}
									type="area"
								/>
							}
						</div>
					</div>}
			</>)
	}



};





