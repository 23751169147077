import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import React, { useState } from "react";
import moment from "moment"
import Button from '@material-ui/core/Button';
import { reactFormatter } from "react-tabulator";
import {
	outflowValidationISPRA,
	outflowValidationOptions,
	outflowValidationColors,
	outflowValidationSIMN,
	rainValidationISPRA,
	rainValidationOptions,
	rainValidationColors,
	rainValidationSIMN,
	temperatureValidationISPRA,
	temperatureValidationOptions,
	temperatureValidationColors,
	temperatureValidationSIMN
} from "#/lib/DailyStatsValidation"

import { GiDinosaurRex } from "react-icons/gi"
import { TiWarning } from "react-icons/ti";
import { BsFillQuestionCircleFill } from "react-icons/bs";

import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import PuntualDetailsTable from "#/commons/table/PuntualDetailsTable"
import IntervalStatsTable from "#/commons/table/IntervalStatsTable"
import SensorClient from "#/lib/SensorClient"
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";

import _ from "lodash"
const isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

const HisticalIcon = (props) => {
	const rowData = props.cell._cell.row.data;
	const isHistoricalData = !!!rowData.dailyStats && !!rowData.historical;

	return (
		<>
			{isHistoricalData && <><GiDinosaurRex className="mr-2" />Dato Storico</>}
		</>

	)
}

const UpdateButton = (props) => {
	const rowData = props.cell._cell.row.data;
	const isHistoricalData = !!rowData.historical;
	let isFuture = rowData.timestampStart > props.now;

	const isChild = !rowData.dailyStats

	return (
		<>
			<Button
				className="justify-content-end ml-auto"
				variant="outlined"
				color="primary"
				onClick={(e) => { isChild ? props.calculateByDayAndStationCode(rowData) : props.calculateByDay(rowData) }}
				disabled={isHistoricalData || isFuture || !isOfficer()}
			>{isChild ? "Ricalcola" : "Ricalcola giorno"}</Button>
		</>

	)
}
const InterpolateButton = (props) => {
	const rowData = props.cell._cell.row.data;
	const isHistoricalData = !!rowData.historical;
	let isFuture = rowData.timestampStart > props.now;

	const isChild = !rowData.dailyStats
	if (!!isChild) {
		return (
			<>
				<Button
					className="justify-content-end ml-auto"
					variant="outlined"
					color="primary"
					onClick={(e) => { props.interpolateValue(rowData) }}
					disabled={isHistoricalData || isFuture || !isOfficer()}
				>Interpola</Button>
			</>
		)
	}
	else {
		return <></>
	}

}

const ValueCell = ({ cell, field, now }) => {


	let isFrontoffice =  window.location.href.includes("frontoffice")

	let data = cell.getRow()._row.data;
	let value = data[field];
	let isMissing = data.validate === "MISSING"
	let isSuspect = !!data.stats ? data.stats.suspect : false;
	let statsNotFound = !data.stats;
	let isFuture = data.timestampStart > now;
	if (!!data.dailyStats) {
		return <div></div>
	}
	else {
		if(!!isFrontoffice){
			if (isFuture) {
				return <div><span>ND</span></div>;
			}
			else if (isMissing) {
				return <div><span>ND</span></div>;
			}
			else {
				return <div><span>{value.toFixed(2)}</span></div>;
			}
		}
		else {
			if (isFuture) {
				return <div><span>ND</span></div>;
			}
			else if (isMissing) {
				cell.getElement().style.backgroundColor = "#ff00008a";
				return <div><span>ND</span></div>;
			}
			else {
				if (isSuspect) {
					return <div><span>{value.toFixed(2)}</span><div className="float-right"><TiWarning style={{ fontSize: 'x-large', color: 'DarkOrange' }} /></div></div>
				}
				if (statsNotFound) {
					return <div><span>{value.toFixed(2)}</span><div className="float-right"><BsFillQuestionCircleFill style={{ fontSize: 'x-large' }} /></div></div>
				}
				else {
					return <div><span>{value.toFixed(2)}</span></div>;
				}
			}
		}
	}


}



const DailyStatsTables = ({ selectedCategory, data, tableKey, calculateByDayAndStationCode, calculateByDay, updateTableKey, interpolateValue, nested, canEdit, tableRef, label }) => {


	let isFrontoffice =  window.location.href.includes("frontoffice")

	const now = moment().valueOf();

	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [showStatsModal, setShowStatsModal] = useState(false);
	const [details, setDetails] = useState({});

	const getTooltipText = (data) => {
		let isMissing = data.validate === "MISSING"
		let isSuspect = !!data.stats ? data.stats.suspect : false;
		let statsNotFound = !data.stats;
		if (!!data.dailyStats) {
			return false
		}
		else {
			if (isMissing) {
				return "Dato Mancante";
			}
			else {
				if (isSuspect) {
					return "Dato sospetto"
				}
				if (statsNotFound) {
					return "Statistiche Intervallo mancanti"
				}
				else {
					return false;
				}
			}
		}
	}

	const getColumns = () => {
		let columns = []
		columns.push(
			...[

				{
					title: "Data",
					field: "date",
					sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
						return aRow._row.data.timestampKey - bRow._row.data.timestampKey;
					},
					//formatter: cell => `${moment(new Date(cell._cell.row.data.timestampKey)).format("DD/MM/YYYY")}`,
				},
				{
					title: "Stazione",
					field: "stationName",
					headerFilter: true,
					headerFilterFunc: (headerValue, rowValue) => { return !rowValue || rowValue.toLowerCase().includes(headerValue.toLowerCase()) }

				}
			]

		);

		let validationOptions = null;
		let validationColors = null;

		switch (selectedCategory) {
			case "P":
				validationOptions = rainValidationOptions;
				validationColors = rainValidationColors;
				columns.push(
					...[
						{
							title: "Pioggia Cumulata",
							field: "value",
							sorter: "number",
							editor: "number",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							formatter: reactFormatter(<ValueCell field={"value"} now={now} />),
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							},
						}

					]
				);
				break;
			case "T":
				validationOptions = temperatureValidationOptions;
				validationColors = temperatureValidationColors;
				columns.push(
					...[
						{
							title: "MIN", field: "minValue", 
							formatter: reactFormatter(<ValueCell field={"minValue"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							}
						},
						{
							title: "AVG", field: "avgValue", 
							formatter: reactFormatter(<ValueCell field={"avgValue"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							},
						},
						{
							title: "MAX", field: "maxValue", 
							formatter: reactFormatter(<ValueCell field={"maxValue"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							},
						}
					]
				);
				break;
			case "I":
				validationOptions = outflowValidationOptions;
				validationColors = outflowValidationColors;
				columns.push(
					...[
						{
							title: "Flow (AVG)", field: "avgFlow", 
							formatter: reactFormatter(<ValueCell field={"avgFlow"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							},
						},
						{
							title: "Flow (MAX)", field: "maxFlow", 
							formatter: reactFormatter(<ValueCell field={"maxFlow"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							},
						},
						{
							title: "Deflusso", field: "outflow", 
							formatter: reactFormatter(<ValueCell field={"outflow"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							},
						},
						{
							title: "H (MAX)", field: "maxH", 
							formatter: reactFormatter(<ValueCell field={"maxH"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND",
							editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
							tooltip: (cell) => {
								let data = cell.getRow().getData();
								return getTooltipText(data);
							},
						},
					]
				);
				break;
			default:
				break;
		}
		if(!isFrontoffice){
			columns.push(
				...[
					{
						title: "Validazione", field: "validate", editor: "select",
						editorParams: {
							values: validationOptions
						},
						editable: (cell) => !!canEdit && !cell.getRow().getData().dailyStats && !cell._cell.row.data.historical,
						formatter: (cell) => {
							cell.getElement().style.backgroundColor = validationColors[cell.getValue()]
							return `${!!cell._cell.row.data.validate ? validationOptions[cell._cell.row.data.validate] : ""}`
						},
						accessorDownload: validationAccessor,
						tooltip: (cell) => getValidationString(cell._cell.row.data.validate, cell._cell.row.data)
					},
					{
						title: "Storico",
						field: "historical",
						formatter: reactFormatter(<HisticalIcon></HisticalIcon>),
						tooltip: (cell) => !!cell._cell.row.data.historical ? "Dato Storico" : ""
	
	
					},
	
				]
	
			);
	
			if (!!canEdit) {
				if (selectedCategory !== "I") {
					columns.push(
						{
							title: "",
							formatter: reactFormatter(<InterpolateButton now={now} interpolateValue={(elem) => { interpolateValue(elem) }} ></InterpolateButton>),
							headerSort: false,
							hozAlign: "center",
							vertAlign: "middle",
							download: false
	
						}
					);
				}
	
				columns.push(
	
					{
						title: "",
						formatter: reactFormatter(<UpdateButton now={now} calculateByDayAndStationCode={(elem) => { calculateByDayAndStationCode(elem) }} calculateByDay={(elem) => { calculateByDay(elem) }}></UpdateButton>),
						headerSort: false,
						hozAlign: "center",
						vertAlign: "middle",
						download: false
	
					}
				);
			}

		}
		

		return columns;

	}
	const validationAccessor = (value, data, type, params, column) => {

		return getValidationString(value, data)
	}

	const getValidationString = (value, data) => {
		let newVal = value;
		if (!!data.dailyStats) {
			return "";
		}

		switch (selectedCategory) {
			case 'P':
				newVal = `${rainValidationOptions[value]} [SIMN: ${rainValidationSIMN[value]}  ISPRA: ${rainValidationISPRA[value]}]`;
				break;
			case 'T':
				newVal = `${temperatureValidationOptions[value]} [SIMN: ${temperatureValidationSIMN[value]}  ISPRA: ${temperatureValidationISPRA[value]}]`;
				break;
			case 'I':
				newVal = `${outflowValidationOptions[value]} [SIMN: ${outflowValidationSIMN[value]}  ISPRA: ${outflowValidationISPRA[value]}]`;
				break;

			default:
				break;
		}
		return newVal
	}



	const rowFormatter = (row) => {
		if(!isFrontoffice){
			let data = row.getData();
			let isFuture = !!data.timestampStart ? data.timestampStart > now : false;
	
			if (isFuture) {
				row.getElement().style.backgroundColor = "#5cf5e980";
			}
			else if (!!data.dirty) {
				row.getElement().style.backgroundColor = "rgba(245,203,92,0.5)";
			}
	
			if (!data.historical) {
				row.getElement().style.cursor = "help";
			}
		}
		
	}

	const cellEdited = (cell) => {
		cell.getRow().getData().dirty = true;
		updateTableKey();
	}

	const getTabulatorOptions = () => {
		let options = {
			data: [],
			height: "65vh",
			invalidOptionWarnings: false,
			selectable: false,
			rowFormatter: (row) => rowFormatter(row),
			dataTree: !!nested,
			dataTreeStartExpanded: true,
			dataTreeFilter: true,
			dataTreeSort: true,
			dataTreeChildField: "dailyStats",
			cellEdited: (cell) => cellEdited(cell),
			downloadReady: (fileContents, blob) => blob,
			rowContextMenu: [
				{
					label: "Statistiche",
					disabled: (row) => row._row.data.validate === "MISSING" || !!row._row.data.dailyStats,
					action: (e, row) => {
						let data = row._row.data;
						if (!!data.stats && !!data.stats.sensorCode) {
							openStatsModal(data.stats.sensorCode, data.timestampStart, data.timestampEnd, selectedCategory, data.date, data.stats)
						}
						else {
							SensorClient.getSensorsByStationCodeAndCategory(
								data.stationCode,
								selectedCategory,
								(sensor) => {
									openStatsModal(sensor.code, data.timestampStart, data.timestampEnd, selectedCategory, data.date, data.stats)

								},
								(error) => {
									console.log(error)
								})
						}

					}
				},
				{
					label: "Dettagli",
					disabled: (row) => row._row.data.validate === "MISSING" || !!row._row.data.dailyStats,
					action: (e, row) => {
						let data = row._row.data;
						if (!!data.stats && !!data.stats.sensorCode) {
							openDetailsModal(data.stats.sensorCode, data.timestampStart, data.timestampEnd, selectedCategory, data.date, data.stats)
						}
						else {
							SensorClient.getSensorsByStationCodeAndCategory(
								data.stationCode,
								selectedCategory,
								(sensor) => {
									openDetailsModal(sensor.code, data.timestampStart, data.timestampEnd, selectedCategory, data.date, data.stats)

								},
								(error) => {
									console.log(error)
								})
						}
					}
				},
			]
		}

		return options
	};

	const openDetailsModal = (sensorCode, start, end, category, intervalString, stats) => {
		setDetails({
			start,
			end,
			sensorCode,
			category,
			intervalString,
			stats
		})
		setShowDetailsModal(true)


	}

	const openStatsModal = (sensorCode, start, end, category, intervalString, stats) => {
		setDetails({
			start,
			end,
			sensorCode,
			category,
			intervalString,
			stats
		})
		setShowStatsModal(true)
	}

	const onCloseDetailsModal = () => {
		setShowDetailsModal(false)
	}

	const onCloseStatsModal = () => {
		setShowStatsModal(false)
	}

	const getData = () => {
		let tableData = !!nested ? data : data.map(d => d.dailyStats[0])
		tableData.sort((a, b) => {
			// Parse the dates to compare
			const dateA = new Date(a.date.split('/').reverse().join('-')); // Convert to YYYY-MM-DD format
			const dateB = new Date(b.date.split('/').reverse().join('-'));
		  
			// Primary sort by date
			if (dateA - dateB !== 0) return dateA - dateB;
		  
			// Secondary sort by stationName
			return a.stationName.localeCompare(b.stationName);
		  });
		  console.log("tableData", tableData)
		  return tableData
	}

	return <>
		{!!data && !_.isEmpty(data) &&<ReactTabulator
			ref={tableRef}
			/*ref={ref => (this.ref = ref)}*/
			columns={getColumns()}
			data={getData()}
			options={getTabulatorOptions()}
			key={tableKey}
		/>}
		{/*---------------- DETAILS MODAL -----------------------*/}
		<Modal key={'puntual_modal'}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				overflow: 'scroll',
			}}
			open={showDetailsModal}
			onClose={() => onCloseDetailsModal()}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<div
				style={{
					background: "white",
					width: "40vw",
					height: "70vh"
				}}
			>
				<div className='w-100 d-flex justify-content-end'>
					<IconButton aria-label="close" onClick={() => onCloseDetailsModal()}>
						<CloseIcon />
					</IconButton>
				</div>
				<div className="mt-5 mb-5 ml-5 mr-5">
					<h3>
						{label}
					</h3>
					<h3>Dati {details.intervalString}</h3>

					<PuntualDetailsTable
						height={"50vh"}
						interval={{ start: details.start, end: details.end }}
						sensorCode={details.sensorCode}
						category={details.category}
					></PuntualDetailsTable>
				</div>
			</div>
		</Modal>

		{/*---------------- STATS MODAL -----------------------*/}
		<Modal key={'stats_modal'}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				overflow: 'scroll',
			}}
			open={showStatsModal}
			onClose={() => onCloseStatsModal()}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<div
				style={{
					background: "white",
					width: "40vw",
					height: "70vh"
				}}
			>
				<div className='w-100 d-flex justify-content-end'>
					<IconButton aria-label="close" onClick={() => onCloseStatsModal()}>
						<CloseIcon />
					</IconButton>
				</div>
				<div className="mt-5 mb-5 ml-5 mr-5">
					<h3>
						{label}
					</h3>
					<h3>Statistiche {details.intervalString}</h3>

					<IntervalStatsTable
						height={"50vh"}
						interval={{ start: details.start, end: details.end }}
						stats={details.stats}
						sensorCode={details.sensorCode}
					></IntervalStatsTable>
				</div>
			</div>
		</Modal>
	</>
}
export default DailyStatsTables;
