import {meteosatBaseUrl, properties} from '#/properties.js';

import parse_georaster from "georaster"
import APIUtils from "#/lib/APIUtils";


export default class MeteosatClient {

	static getAvailableMeteosatLayers(okCallback, errCallback){
		let url = properties.url.meteosatLayers
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getMeteosatDataByInterval(okCallback, errCallback, interval, category){
		let url = properties.url.meteosatByInterval + "/" + category;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(interval)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getMeteosatByTiffUrl = (okCallback, errCallback, tiffUrl) => {
		let url = new URL(meteosatBaseUrl + tiffUrl);
		fetch(url)
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				console.log("arrayBuffer", arrayBuffer)
				parse_georaster(arrayBuffer).then(georaster => {
					okCallback(georaster)
				})
			})
			.catch((error) => {
				return errCallback(error);
			});
	};

	static getMeteosatByPngUrl = (okCallback, errCallback,pngUrl) => {
		let url = new URL(meteosatBaseUrl + pngUrl);
		fetch(url)

			.then(response => response.blob())
			.then(image => {
				okCallback(URL.createObjectURL(image));
			})
	};
}
