import React, {Component} from 'react';
import ThresholdRangeComponent from "#/commons/components/ThesholdRangeComponent";
import MonitoringPluvsThresholdClient from "#/lib/MonitoringPluvsThresholdClient"

import Button from '@material-ui/core/Button';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ReactSwal = withReactContent(Swal);

export default class ReturnTimeThresholdComponent extends Component {

	constructor(props) {
		super(props);


		this.state = {
			thresholds : props.thresholds,
			enableSave : false

		}
	}


	onChangeValues(values) {
		let thresholds = this.state.thresholds;
		thresholds=[0, ...values];
		this.setState({thresholds,enableSave : true})
	}

	saveThresholds(){
		let params = [this.state.thresholds[1],this.state.thresholds[2],this.state.thresholds[3]]
		
		MonitoringPluvsThresholdClient.updateThresholdsReturnTime(
			()=>{
				this.setState(
					{enableSave : false},
					()=>{
						if(!!this.props.saveCallback){
							this.props.saveCallback(this.state.thresholds)
						}
					})
			},
			()=>{
				console.log("ERROR")
			},
			params
		);
	}


	render() {
		return (
			<>
				{!!this.state.thresholds ?
					<>
						<div className="row" style={{marginTop:"4rem"}}>
							<div className="col-3">
								Tempi di Ritorno
							</div>
							<div className="col-9">
								<ThresholdRangeComponent
									min={0}
									max={75}
									step={1}
									values={[this.state.thresholds[1],this.state.thresholds[2],this.state.thresholds[3]]}
									colors={["green", "yellow", "DarkOrange", "red"]}
									onChange={(values) => this.onChangeValues(values)}
								/>
							</div>
						</div>
						

						<div className="row" style={{marginTop:"4rem"}}>

							<Button
								disabled={!this.state.enableSave}
								size="large"
								className="justify-content-end ml-auto"
								variant="contained"
								color="primary"
								startIcon={<SendSharpIcon />}
								onClick={(e) => this.saveThresholds()}
							>Salva Soglie</Button>



						</div>
					</>

					:
					<></>
				}
			</>


		)

	}

}

