import React from 'react';
import BulletinClient from '#/lib/BulletinClient';
import Skeleton from "react-loading-skeleton";

export default class BimDetails extends React.Component {

    constructor(props) {
        super(props)
        if (!!this.props) {
            this.state = { ...this.props }
        }
    }

    componentDidMount() {
        if (!(!!this.props.id)) {
            let params = {
                pageNumber: 1,
                pageSize: 1,
                bulletinTypeName: this.props.bulletinTypeName
            }
			BulletinClient.listBulletins(
                (obj) => { this.setState({...obj.data[0]}) },
                (err) => { console.log('Errore mount bim Details') },
                params
            )
        }
    }


    render() {

        if (!(!!this.state.id)) {
            return <Skeleton height={"50vh"} duration={8} />
        }

        //console.log('this.state.jsonData: ', this.state.jsonData);

        const rainfallsTitle = "Precipitazioni";
        const rainfallsImg = this.state.jsonData.rainfalls.img;
        const monitorMaxTitle = "Temperatura : media delle medie";
        const monitorMaxImg = this.state.jsonData.monitoraggio.img_medie;


        return (
            <>
                <div className="card item-card transparent-card" >
                    <div >
                        <div className="container">

                            <div className="map-container">
                                <div className="row">
                                    <div className="col-xl-6 col-12 text-center">
                                        <div className="map-header">
                                            <h3 className="map-header-title">
                                                {rainfallsTitle}
                                            </h3>
                                        </div>
                                        <div className="map-image overflow-auto">
                                            <img
                                                className="item-img"
                                                src={rainfallsImg}
                                                alt={rainfallsTitle}
                                                align="center"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-12 text-center map-header">
                                        <div className="map-header">
                                            <h3 className="map-header-title">
                                                {monitorMaxTitle}
                                            </h3>
                                        </div>
                                        <div className="map-image overflow-auto">
                                            <img
                                                className="item-img"
                                                src={monitorMaxImg}
                                                alt={monitorMaxTitle}
                                                align="center"
                                                >
                                            </img>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
};
