import React from 'react'
import DateUtils from "#/lib/DateUtils";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import CheckIcon from "@material-ui/icons/Check"
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import _ from "lodash";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';


import AddIcon from '@material-ui/icons/Add';

const livelli = {
	"LEVEL1": "Livello 1",
	"LEVEL2": "Livello 2",
	"LEVEL3": "Livello 3"
}

const colors = {
	"LEVEL1": "yellow",
	"LEVEL2": "DarkOrange",
	"LEVEL3": "red"
}

const MAUCheckIcon = (props) => props.cell._cell.row.data.mauAlerted ?
	<CheckIcon fontSize="large" htmlColor={colors[props.cell._cell.row.data.mauAlerted]} /> :
	<></>;
const A3CheckIcon = (props) => {
	console.log("A3CheckIcon", props.cell._cell.row.data.a3Alerted, props.cell._cell.row.data)
	if(!!props.cell._cell.row.data.a3Alerted) {
		console.log("color ", colors[props.cell._cell.row.data.a3Alerted])
		return	<CheckIcon fontSize="large" htmlColor={colors[props.cell._cell.row.data.a3Alerted]}/> 
	} 
	else {
		return <></>
	}
}


const toBeAlerted = data => {

	let alertLevel = parseInt(data.alertLevel.replace("LEVEL", ""));
	let mauAlerted = !!data.mauAlerted ? parseInt(data.mauAlerted.replace("LEVEL", "")) : 0;
	return data.isNew && (alertLevel > 1 || (alertLevel === 1 && mauAlerted === 0))
};

const AddButton = (props) => {
	const rowData = props.cell._cell.row.data;


	let elem = {

		alertLevel : rowData.alertLevel,
		istatCode: rowData.municipalityIstatCode,
		name: rowData.municipalityName
	};

	return (
		<>
			<IconButton color="primary" aria-label="upload picture" component="span"
						onClick={(e) => { props.clickCallback(elem) }}>
				<AddIcon />
			</IconButton>
		</>
	)


}

const AddButtonLast12H = (props) => {
	const rowData = props.cell._cell.row.data;
	if (rowData.exceededList !== undefined) {

		let elem = {
			istatCode: rowData.municipalityIstatCode,
			name: rowData.municipalityName,
			alertLevel: rowData.maxAlertLevel
		};

		return (
			<>
				<IconButton color="primary" aria-label="upload picture" component="span"
							onClick={(e) => { props.clickCallback(elem) }}>
					<AddIcon />
				</IconButton>
			</>
		)
	}
	else {
		return <></>
	}
}

function AddAllButton(props) {


	let elements = [];
	props.data.forEach(d => {
		let elem = {
			alertLevel: d.alertLevel,
			istatCode: d.municipalityIstatCode,
			name: d.municipalityName
		};
		elements.push(elem);
	})

	return (
		<>
			<Button
				variant="info"
				style={{ margin: "2vh", minWidth: "10vw" }}
				startIcon={<AddIcon />}
				onClick={(e) => { props.clickCallback(elements) }}>
				Aggiungi tutti
			</Button>
		</>
	)
}

export default class MosipCurrentReport extends React.Component {

	getLastRunWarningsColumns() {
		let columns = [];
		columns = columns.concat([
			/*{ title: "Codice Istat", field: "municipalityIstatCode", headerSort: true, headerFilter: true },*/
			{ title: "Comune", field: "municipalityName", headerSort: true },
			{
				title: "Data di rilevazione</br>(tempo trascorso)", field: "timestamp", formatter: function (cell) {

					return cell.getValue() > 0
						?
						(DateUtils.epochToDateMinuteResolution(cell.getValue()) + " (" + DateUtils.diffFromNow(cell.getValue()) + ")")
						: ''
				}
			},
			{
				title: "Livello", field: "alertLevel", width: 120, formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return livelli[cell.getValue()];
				}
			},

			{
				title: "mau<sup>(*)</sup>", field: "mauAlerted", width: 100, hozAlign: "center",
				headerSort: false, vertAlign: "middle", formatter: reactFormatter(<MAUCheckIcon />)
			},
			{
				title: "A3", field: "a3Alerted", width: 100, hozAlign: "center",
				vertAlign: "middle", formatter: reactFormatter(<A3CheckIcon />)
			}

		]);

		if(!!this.props.onAddItem ){
			columns.push(
				{
					title: "",
					formatter: reactFormatter(<AddButton clickCallback={(obj) => this.props.onAddItem(obj)}></AddButton>),
					headerSort: false,
					hozAlign: "center",
					vertAlign: "middle",
					width: 100,
				}
			)
		}
		return columns;
	}
	getLastRunTabulatorOptions() {
		return {
			data: [],
			height: "40vh",
			selectable: false,
			invalidOptionWarnings : false,
			rowClick: (e, row) => {
				if (!!this.props.onLastRunItemClick) {
					this.props.onLastRunItemClick(row._row.data.municipalityIstatCode)
				}
			},
			rowFormatter: (row) => this.rowFormatter(row),
		}
	}



	rowFormatter(row) {
		let data = row.getData();
		if (toBeAlerted(data)) {
			row.getElement().style.backgroundColor = "#87CEEB";
		}
	}

	/**
	 * Expiring warnings' table
	 */

	getExpiringAlertsColumns() {
		let columns = [];
		columns = columns.concat([
			/*{ title: "Codice Istat", field: "municipalityIstatCode", headerSort: true, headerFilter: true },*/
			{ title: "Comune", field: "municipalityName", headerSort: true },
			{
				title: "Data di rilevazione</br>(tempo trascorso)", field: "timestamp", formatter: function (cell) {

					return cell.getValue() > 0
						?
						(DateUtils.epochToDateMinuteResolution(cell.getValue()) + " (" + DateUtils.diffFromNow(cell.getValue()) + ")")
						: ''
				}
			},
			{
				title: "Tempo rimanente", field: "expiringTimestamp", width: 180, formatter: function (cell) {

					return cell.getValue() > 0
						?
						DateUtils.diffFromNow(cell.getValue() + 43200000)
						:
						''
				}
			},
			{
				title: "Livello", field: "alertLevel", width: 120, formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return livelli[cell.getValue()];
				}
			},

			{
				title: "MAU", field: "mauAlerted", width: 100, hozAlign: "center",
				vertAlign: "middle", formatter: reactFormatter(<MAUCheckIcon />)
			}
		]);
		if(!!this.props.onAddItem){
			columns.push(
				{
					title: "",
					formatter: reactFormatter(<AddButton clickCallback={(obj) => this.props.onAddItem(obj)}></AddButton>),
					headerSort: false,
					hozAlign: "center",
					vertAlign: "middle",
					width: 100,
				}
			)
		}
		return columns;
	}

	getExpiringAlertsTabulatorOptions() {
		return {
			data: [],
			height: "40vh",
			selectable: false,
			dataTree: true,
			invalidOptionWarnings : false,
			rowClick: (e, row) => {
				if (!!this.props.onExpiringAlertItemClick) {
					this.props.onExpiringAlertItemClick(row._row.data.municipalityIstatCode)
				}
			},
		}
	}

	getLast12HWarningsColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Comune", field: "municipalityName", headerSort: true, headerFilter: true },
			{ title: "Esecuzione mosip", field: "runId", headerSort: false, headerFilter: false },
			{ title: "Livello", field: "maxAlertLevel", width: 100, formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return livelli[cell.getValue()];

				}
			},
			{
				title: "Age", field: "timestamp", width: 150, formatter: function (cell) {

					return cell.getValue() === 0 || !!!cell.getValue()
						?
						''
						:
						DateUtils.diffFromNow(cell.getValue()) ;
				}
			},
			{ title: "Cod. Rischio", field: "riskCode", width: 150 },
			{ title: "Misura", field: "measureType", width: 100, },
			{
				title: "Soglia", field: "alertLevel", width: 100, formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return livelli[cell.getValue()];

				}
			}

		]);
		if(!!this.props.onAddItem){
			columns.push(
				{
					title: "",
					formatter: reactFormatter(<AddButtonLast12H clickCallback={(obj) => this.props.onAddItem(obj)}></AddButtonLast12H>),
					headerSort: false,
					hozAlign: "center",
					vertAlign: "middle",
					width: 100,
				}
			)
		}
		return columns;
	}

	getLast12HTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "40vh",
			dataTree: true,
			dataTreeChildField: "exceededList",
			selectable: false,
			invalidOptionWarnings : false,
			/*dataTreeCollapseElement:"<span>►</span>",
			dataTreeExpandElement:"<span>▼</span>",*/
			/*	dataTreeChildField: "exceededList",*/
		}
		return tabulatorOptions;
	}

	toBeAlertedCounter(){
		let counter = 0;
		this.props.lastRunWarningsMapByIstat.forEach(data => {
			let alertLevel = parseInt(data.alertLevel.replace("LEVEL", ""));
			let mauAlerted = !!data.mauAlerted ? parseInt(data.mauAlerted.replace("LEVEL", "")) : 0;
			if (data.isNew && (alertLevel > 1 || (alertLevel === 1 && mauAlerted === 0))) {
				counter++;
			}
		})
		return counter;
	}



	render() {

		let ewl = Object.keys(this.props.expiringAlertsMapByIstat).length;
		let newAlertCount = this.toBeAlertedCounter();
		let mauReference = "Nessun bollettino mau disponibile."
		if (!!this.props.mauState && !_.isEmpty(this.props.mauState)) {
			let { MAU: mau } = this.props.mauState;
			mauReference = "Informazioni relative al mau " + mau.type + " del " + mau.date;
		}

		let filteredLastRunWarningsMapByIstat = this.props.hideAlreadyAlerted ? this.props.lastRunWarningsMapByIstat.filter(x => x.isNew) : this.props.lastRunWarningsMapByIstat
		let alertCount = newAlertCount ? newAlertCount +" da allertare" : "Nessun comune da allertare"
		return (
			<>
				<Accordion defaultActiveKey="0">
					{!!this.props.lastRunWarningsMapByIstat ?
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
									<div className="d-flex justify-content-between">
										<h3>Ultimo run MOSIP: {!!this.props.lastExecutionTimestamp ? DateUtils.epochToDateMinuteResolution(this.props.lastExecutionTimestamp) : 'nd'} (<b style={{ color: newAlertCount ? '#E20808' : "green" }}> {alertCount} </b>)</h3>
										{!!this.props.onAddAllItems?
											<AddAllButton clickCallback={(list) => this.props.onAddAllItems(list)} data = {this.props.lastRunWarningsMapByIstat.filter(d => toBeAlerted(d))}/>

											:<></>}
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<ReactTabulator
										columns={this.getLastRunWarningsColumns()}
										data={filteredLastRunWarningsMapByIstat}
										options={this.getLastRunTabulatorOptions()}
										key={"lastRunWarnings_" + this.props.tableKey}
									/>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						:
						<></>
					}
					{!!this.props.expiringAlertsMapByIstat ?
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
									<div className="d-flex justify-content-between">
										<h3>Allerte in scadenza ({ewl})</h3>
										{!!this.props.onAddAllItems?
											<AddAllButton clickCallback={(list) => this.props.onAddAllItems(list)} data = {this.props.expiringAlertsMapByIstat}/>
											:<></>}
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body>
									<ReactTabulator
										columns={this.getExpiringAlertsColumns()}
										data={this.props.expiringAlertsMapByIstat}
										options={this.getExpiringAlertsTabulatorOptions()}
										key={"expiringAlerts_" + this.props.tableKey}
									/>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						:
						<></>
					}
					{!!this.props.last12HWarnings ?
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
									<div className="d-flex justify-content-between">
										<h3>Soglie Superate nelle ultime 12 ore</h3>
										{!!this.props.onAddAllItems?
											<AddAllButton clickCallback={(list) => this.props.onAddAllItems(list)} data = {this.props.last12HWarnings}/>
											:<></>}
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="2">
								<Card.Body>
									<ReactTabulator
										columns={this.getLast12HWarningsColumns()}
										data={this.props.last12HWarnings}
										options={this.getLast12HTabulatorOptions()}
										key={"last12H_" + this.props.tableKey}
									/>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						:
						<></>
					}
				</Accordion>
				<h6 className="mt-2"><sup>(*)</sup> {mauReference}</h6>
			</>
		)
	}
}


