import React from "react";
import {getTrackBackground, Range} from "react-range";

export default class ThresholdRangeComponent extends React.Component {
  /*
		min
		max
		step
		values
		colors
		onChange
	*/
  render() {
      console.log("wquyterwvyfdewjxbnhg", this.props);
    return (
      <div className="d-flex justify-content-center">
        <Range
          values={this.props.values}
          step={this.props.step}
          min={this.props.min}
          max={this.props.max}
          onChange={(values) => {
            this.props.onChange(values);
          }}
          renderTrack={({ props, children }) => (
            <div
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "80%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: this.props.values,
                    colors: this.props.colors,
                    min: this.props.min,
                    max: this.props.max,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "42px",
                width: "42px",
                borderRadius: "4px",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-28px",
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "18px",
                  fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                  padding: "4px",
                  borderRadius: "4px",
                  //backgroundColor: '#548BF4'
                }}
              >
                {this.props.values[index]}
              </div>
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "#548BF4" : "#CCC",
                }}
              />
            </div>
          )}
        />
      </div>
    );
  }
}
