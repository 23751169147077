import React from "react";

import {BUTTON_PROGRESS_STYLE} from "#/backoffice/bim/utils/BIMUtils";
import CircularProgress from '@material-ui/core/CircularProgress';

import MobileStepper from '@material-ui/core/MobileStepper';

import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton, SkipButton} from "#/commons/components/forms/NextButton";
import {SelectForm} from "#/commons/components/forms/SelectForm";
import Button from '@material-ui/core/Button';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import PolygonMapComponent from '#/commons/map/PolygonMapComponent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import RainStatsClient from "#/lib/RainStatsClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ChromaticScale, {spiColors} from "#/lib/ChomaticScale";
import {GeoJSON} from "react-leaflet";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import GISTools from "#/lib/GISTools";
import IdwClient from "#/lib/IdwClient";
import * as turf from "@turf/turf";
import external_points from "#/commons/geodata/external_points.json";
import {ReactTabulator} from "react-tabulator";
import L from "leaflet";
import _ from "lodash";
import NumberUtils from "#/lib/NumberUtils"

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import DateUtils from "#/lib/DateUtils";
import DownloadContainer from "#/commons/components/DownloadContainer"
import MapComponent from "#/commons/map/MapComponent";


function hasDuplicates(array) {
	return (new Set(array)).size !== array.length;
}

const spiArray = [1,3,6,12,24];
const legendArray =  [
	'Siccità estrema (<-2)',
	'Siccità severa (-2, -1.5)',
	'Siccità moderata (-1.5, -1)',
	'Siccità leggera (-1, 0)',
	'Piovosità leggera (0, 1)',
	'Piovosità moderata (1, 1.5)',
	'Piovosità severa (1.5, 2)',
	'Piovosità estrema (>2)'
];

const findSPIAssociatedColor = (value) => {
	const scale = ChromaticScale.getSPIScale();
	return scale(value);
}

const findSpiFontColor = (value) => {
	if(value > -1 && value< 1){
		return "#000"
	}
	else{
		return "#FFF"
	}
}

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
const mode = {
	ZONAL_SPI : 0,
	ZONAL_FROM_RASTER : 1
}

const modeDisplayValues = {
	ZONAL_SPI : "SPI Zonali",
	ZONAL_FROM_RASTER : "Statistiche Zonali da Raster"
}

const createBlobFromArrayBuffer = (arrayBuffer) => {
	let blob =  new Blob([arrayBuffer], { type: '' });
	blob.lastModifiedDate = new Date();
	blob.name = "something.tif";
	return blob;
}

export class StepSPI extends React.Component {

	static whyDidYouRender = false;
	section = "sp_index";
	images = ["legend"];
	refMonthName = !!this.props.formData ? this.props.formData.reference_month : DateUtils.getMonthNameFromMonthNumber( this.props.month);
	inputData = !!this.props.formData ? this.props.formData[this.section]["inputData"] : null;
	hasBeenModified = false;
	mapOptions = {
		center: [39.11, 16.55],
		zoom: 9,
		minZoom: 3,
		maxZoom: 11,
		width: "15vw",
		height: "40vh",
		zoomControl:false,
		scrollWheelZoom: false,
		dragging: false,
		showTileLayer: false,
		showIcons: false,
		doubleClickZoom : false
	}

	constructor(props) {
		super(props);
		spiArray.map(s => {return this.images.push("img_" + s + "m")});
		this.state = {
			enableSave: this.props.viewMode,
			showModal: false,
			calculating: false,
			loading: false,
			mapKey: 1,
			zonalSPI:null,
			zonalSPIFromLocal:null,
			rawSPIObject: null,
			file: null,
			zoneGeoJson: null,
			geojsonForZonalStats:null,
			identifierCandidates: null,
			geoJsonIdentifier: null,
			interpolatedData : {},
			spiMode: mode.ZONAL_SPI

		}
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		if (!!this.inputData) {
			this.setState({...this.inputData});
		}
	}
	handleSkipStep = () => {
		if(this.props.context==="BIM") {
			this.props.handleNext(true); // skip = true
		}
	}
	handleNextStep = () => {
		if(this.props.context==="BIM"){
			if (this.props.viewMode) {
				this.props.handleNext();
			} else {
				let rawSPIObject = this.state.rawSPIObject;
				let formData = this.props.formData;
				if (!!rawSPIObject) {
					if (this.hasBeenModified) {
						loadingSwal.fire('Salvataggio immagini in corso...');
						this.setState({loading: true});
						const promises = [];
						this.images.map( (img) => {
							return promises.push(this.props.handleSaveImg(img));
						})
						Promise.all(promises)
							.then((response) => {
								let idx = 0;
								this.images.map( (img) => {
									return formData[this.section][img] = response[idx++];
								});
								formData[this.section]["inputData"] = {...this.state, loading: false};

								["1","3","6","12","24"].forEach(timescale => {
									formData[this.section]["zs_" + timescale + "m"] =
										Object.keys(rawSPIObject).map(zone => {
											let value = NumberUtils.round(this.state.rawSPIObject[zone][timescale],1);
											let backgroundColor = findSPIAssociatedColor(value).hex()
											let fontColor = findSpiFontColor(value)
											return {'zone' : zone, 'value' : value, backgroundColor:backgroundColor, color:fontColor}
										});
								})
								ReactSwal.close();
								this.props.handleNext();
							})
							.catch(error => {this.setState({loading: false}); console.log(`Error in executing ${error}`);})
					} else {
						this.props.handleNext();
					}
				} else {
					ReactSwal.fire(
						"Redazione Bollettino Idrologico/Siccità",
						"Selezionare un GeoJSON e calcolare l'SPI prima di procedere",
						"error"
					);
				}
			}
		}
	}


	handleSelectGeoJSON = (valueSelected) => {
		let options = this.props.selectOptions;
		let selectedOption = options.filter(opt => opt.value === valueSelected);
		let geoJSON = selectedOption[0].value;
		let geoJsonIdentifier = selectedOption[0].identifier;
		if (geoJsonIdentifier==="__NEW__" && geoJSON === "NEW") {
			this.openModal();
		} else {
			let geojsonForZonalStats = _.cloneDeep(geoJSON)
			let clonedGeoJson = _.cloneDeep(geoJSON)
			this.addCentroidGeoJSON(clonedGeoJson);
			this.setState({
				zoneGeoJson: clonedGeoJson,
				geojsonForZonalStats: geojsonForZonalStats,
				tableKey: (this.state.tableKey + 1) % 1000,
				mapKey: (this.state.mapKey + 1) % 1000,
				geoJsonIdentifier: geoJsonIdentifier
			})
		}
		this.hasBeenModified = true;
	};

	getPerformIdwPromise = (timescale,data) => {
		let resolved = {};
		return new Promise((resolve,reject) => {
			IdwClient.performIdw(
				(interpGrid)=> {
					resolved[timescale] = interpGrid;
					resolve(resolved)
				},
				(error)=>{
					reject()
				},
				data,
				timescale
			)
		})
	}

	static interpolate(points,cellSize,timescale) {

		cellSize = !!cellSize ? cellSize : 5;
		console.time("Inverse Distance Weighting")

		turf.featureEach(external_points, (point)=>{
			let nearest = turf.nearestPoint(point, points);
			point.properties = {...nearest.properties}
		})
		let pointsAll = turf.featureCollection([...external_points.features, ...points.features]);

		let options = { gridType: 'square', property: timescale };
		let grid = turf.interpolate(pointsAll, cellSize, options);

		console.timeEnd("Inverse Distance Weighting")
		return grid;
	}

	performZonalStatsOnLocalSpi = () => {
		let interpolatedData = this.state.interpolatedData

		let promises = [];
		["1","3","6","12","24"].forEach(timescale => {
			let data = new FormData();
			let currentSpiArrayBuffer = interpolatedData[timescale]._data;
			const file =  createBlobFromArrayBuffer(currentSpiArrayBuffer);
			data.append("raster", file );
			data.append("shapes", JSON.stringify(this.state.geojsonForZonalStats));
			data.append("codeFieldName", this.state.geoJsonIdentifier);

			let currentPromise = new Promise((resolve, reject) => {
				IdwClient.sendTifsAndGetFinalGeoJson(
					(zonalValues)=>	resolve({[timescale]: zonalValues}),
					(error)=>{console.log("Error performing Zonal Stats");reject(error) },
					data
				)
			})
			promises.push(currentPromise);
		})

		Promise.all(promises).then(
			(values) => {console.log(values)
				let zonalSPIFromLocal = {};
				values.forEach(spi=>{
					let timescale = Object.keys(spi)[0];
					let zonalStats = spi[timescale];
					zonalStats.forEach(zs =>{
						let code = zs.code;
						let value = zs.mean;
						if(!!!zonalSPIFromLocal[code]){
							zonalSPIFromLocal[code] = {}
						}
						zonalSPIFromLocal[code][timescale] = value;
					})
				})
				console.log(zonalSPIFromLocal)
				this.setState({
					zonalSPIFromLocal,
					rawSPIObject: zonalSPIFromLocal,
					mapKey: (this.state.mapKey + 1) % 1000,
				})

			}
		);
	}

	loadSPIFromAPI = () => {
		this.hasBeenModified = true;
		this.setState({calculating: true});
		loadingSwal.fire('Calcolo delle SPI per le zone caricate...');
		let geoJSON = this.state.zoneGeoJson;

		RainStatsClient.getZonalAndLocalSPI(this.props.year,
			this.props.month + 1,
			this.state.geoJsonIdentifier,
			geoJSON,
			(data)=>{

				const {localSPI, zonalSPI} = data;
				if (localSPI.hasOwnProperty('features') && Object.keys(zonalSPI).length > 0){
					let promises = [];
					["1","3","6","12","24"].forEach(timescale =>
						promises.push(this.getPerformIdwPromise(timescale,localSPI))
					)
					let interpolatedData = {};
					Promise.all(promises).then(
						(values) => {
							Object.assign(interpolatedData, ...values);
							this.setState(
								{
									interpolatedData,
									rawSPIObject: zonalSPI,
									zonalSPI : zonalSPI,
									mapKey: (this.state.mapKey + 1) % 1000,
									showModal: false,
									enableSave: true,
									calculating: false

								},() => ReactSwal.close());
						}
					).catch(() => {
						console.log("Error performing IDW -> Trying to perform IDW locally ");
						let interpolatedData = {};
						["1","3","6","12","24"].forEach(timescale => {
							interpolatedData[timescale] = GISTools.interpolate(data, 2,timescale);
						});
						this.setState(
							{
								interpolatedData,
								rawSPIObject: zonalSPI,
								zonalSPI : zonalSPI,
								mapKey: (this.state.mapKey + 1) % 1000,
								showModal: false,
								enableSave: true,
								calculating: false

							})

					})

				} else {
					ReactSwal.hideLoading();
					ReactSwal.fire('Si è verificato un errore nel calcolo delle SPI zonali', '', 'error');
					this.setState({calculating: false});
				}
			},
			()=>{
				console.log('Problems retrieving SPI data!');
				this.setState({calculating: false});
				ReactSwal.hideLoading();
				ReactSwal.fire('Si è verificato un errore nel calcolo delle SPI zonali', '', 'error');
			}
		)
	}

	findIdentifierCandidates = (geoJSON) => {
		let identifiers = [];
		const features = geoJSON.features;
		if (!!features) {
			const feature = features[0];
			const keys = Object.keys(feature.properties);
			keys.forEach(key => {
				let allValues = features.map(feature => feature.properties[key])
				if (!hasDuplicates(allValues)){
					identifiers.push(key)
				}
			})
		}
		return identifiers;
	}

	handleChangeStatusZone = ({ file }, status) => {
		if (status === "removed") {
			this.setState({
				file: null,
				zoneGeoJson: null,
				geoJsonIdentifier: null,
				mapKey: (this.state.mapKey + 1) % 1000,
				identifierCandidates: null,
				enableSave: false,
				rawSPIObject: null,
				zonalSpi : null,
				zonalSPIFromLocal:null
			})
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let geoJSON = JSON.parse(fileContent);
				// Check keys
				let identifierCandidates = this.findIdentifierCandidates(geoJSON);
				if (identifierCandidates.length > 0)
				{
					let geojsonForZonalStats = _.cloneDeep(geoJSON)
					let clonedGeoJson = _.cloneDeep(geoJSON)
					this.addCentroidGeoJSON(clonedGeoJson);
					this.setState({
						zoneGeoJson: clonedGeoJson,
						geojsonForZonalStats: geojsonForZonalStats,
						mapKey: (this.state.mapKey + 1) % 1000,
						identifierCandidates: identifierCandidates,
						file: file
					})
				}
			};
			reader.onerror = (event) => {
				ReactSwal.hideLoading();
				ReactSwal.fire('Si è verificato un errore nella lettura delle zone', '', 'error');
			};
			reader.readAsText(file);
		}
	}
	addCentroidGeoJSON(geoJSON) {
		if (!!geoJSON){
			GISTools.addCentroids(geoJSON)
		}
	}

	openModal = () => {
		this.setState({
			showModal: true
		})
	}

	onCloseModal = () => {
		this.setState({
			showModal: false
		})
	}


	polygonStyle = (feature,latlng, timescale) => {
		return {
			fillColor: !!this.state.rawSPIObject ? findSPIAssociatedColor(NumberUtils.round(this.state.rawSPIObject[feature.properties[this.state.geoJsonIdentifier]][timescale],1)) : "#fff0",
			weight: 0.6,
			opacity: 1,
			color: "blue",
			fillOpacity: 0.5
		};
	}

	onSpiModeChange(value){
		this.setState({
				spiMode : value
			},
			()=>{
				if(value===mode.ZONAL_SPI){
					this.setState({
						rawSPIObject: this.state.zonalSPI,
						mapKey: (this.state.mapKey + 1) % 1000,
					})
				}
				if(value===mode.ZONAL_FROM_RASTER){
					this.performZonalStatsOnLocalSpi();
				}
			})



	}

	/*enableSPICalculation = () => !!this.props.month && !!this.props.year && !!this.state.zoneGeoJson;*/
	enableSPICalculation = () => !!this.state.zoneGeoJson;
	render() {

		let title = this.props.stepLabel + ' ' + DateUtils.getMonthNameFromMonthNumber( this.props.month) + ' ' + this.props.year

		return (
			<>
				<div className="row justify-content-center">
					<h2>{title}</h2>
				</div>

				{!this.props.viewMode &&
				<div className="row">
					<div className="col-4">
						<SelectForm
							id="select_default_GeoJSON"
							label="Seleziona file precaricati"
							className="mt-3 col-8 row justify-content-center"
							valueSelected={{id:0}}
							options={this.props.selectOptions}
							selectedValue = {this.props.selectedGeoJSON}
							handleOnChange = {this.handleSelectGeoJSON}
						/>
					</div>
					<div className="col-4">
						<FormControl className="col-4" component="fieldset" disabled={!!!this.state.rawSPIObject}>
							<RadioGroup row={true} aria-label="gender" name="gender1" value={this.state.spiMode} onChange={(event) => this.onSpiModeChange(parseInt(event.target.value))}>
								<FormControlLabel style={{margin:0}} value={mode.ZONAL_SPI} control={<Radio />} label={modeDisplayValues.ZONAL_SPI} />
								<FormControlLabel style={{margin:0}} value={mode.ZONAL_FROM_RASTER} control={<Radio />} label={modeDisplayValues.ZONAL_FROM_RASTER} />
							</RadioGroup>
						</FormControl>
					</div>
					<div className="col-4 mt-4">
						<Button
							disabled={!this.enableSPICalculation() || this.state.calculating || this.state.loading}
							size="large"
							className="justify-content-end ml-auto"
							variant="contained"
							color="primary"
							onClick={(e) => this.loadSPIFromAPI()}
						>Calcola SPI</Button>
					</div>
				</div>
				}

				<div className="row mt-4">
					{spiArray.map(s => {
						let img_id = "img_" + s + "m";
						let retValue = !this.props.viewMode ?
							(

								<SPIComponent
									imgId={img_id}
									zoneGeoJson={this.state.zoneGeoJson}
									rawSPIObject={this.state.rawSPIObject}
									mapKey={this.state.mapKey}
									timescale={s}
									polygonStyle={(feature,latlng)=>this.polygonStyle(feature,latlng,s)}
									geoJsonIdentifier={this.state.geoJsonIdentifier}
									raster = {this.state.interpolatedData[s]}
								/>
							)
							:
							(
								<div className="col-2" key={img_id}>
									<h3>{`SPI - ${s}`}</h3>
									<img
										id ={img_id}
										alt={img_id}
										src={this.props.formData[this.section][img_id]}
									/>
								</div>
							);
						return retValue;
					})}
				</div>

				<MobileStepper
					style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
					position="static"
					variant="text"
					activeStep={this.props.activeStep}
					steps={this.props.totalSteps}
					nextButton={this.props.context === "BIM"
						?
						<div style={{position: 'relative'}}>
							<SkipButton
								variant="contained"
								color="primary"
								disabled={this.state.calculating || this.state.loading }
								onClick={this.handleSkipStep}
								textbutton={this.props.textSkipButton}
							/>
							<NextButton
								variant="contained"
								color="primary"
								disabled={this.state.loading || !this.state.enableSave}
								onClick={this.handleNextStep}
								textbutton={this.props.textNextButton}
							/>
							{this.state.loading && <CircularProgress size={24} style={BUTTON_PROGRESS_STYLE} />}
						</div>
						:
						<></>
					}
					backButton={
						<BackButton
							variant="contained"
							disabled={this.state.calculating || this.state.loading || this.props.disableBackButton}
							onClick={this.props.handleBack}
						/>
					}
				/>

				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "50vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3 >Import Zone da file</h3>
						</div>

						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<Dropzone
									onChangeStatus={this.handleChangeStatusZone}
									accept=".geojson,.json"
									maxFiles={1}
									inputContent="Inserisci il file delle zone in formato GeoJSON"
									styles={{
										dropzone: { overflow: "hidden", width: "30vw" },
										dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
										inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
									}}
								/>
							</div>
						</div>
						<br/>
						{!!this.state.identifierCandidates ?
							<>
								<div className="d-flex justify-content-center">
									{this.state.identifierCandidates.length > 0 ?
										<h3>Scegli il campo che identifica le geometrie zonali:</h3> :
										<h3 style={{color: "red"}}>Il file caricato non contiene alcun campo identificativo. Caricare un file valido.</h3>
									}
								</div>
								<div className="d-flex justify-content-center">
									{this.state.identifierCandidates.map(key =>
										<Button
											key={key}
											size="medium"
											className="col-sm-2 my-auto"
											variant="contained"
											color="primary"
											onClick={(e) => this.setState({geoJsonIdentifier: key, showModal: false})}
										>{key}</Button>

									)}
								</div>
							</> : <></>
						}


					</div>

				</Modal>
			</>
		)
	}
}

class SPIComponent extends React.Component {


	mapOptions = {
		center: [39.11, 16.55],
		zoom: 13,
		minZoom: 3,
		maxZoom: 13,
		width: "25vw",
		height: "60vh",
		zoomControl:false,
		scrollWheelZoom: false,
		dragging: false,
		showTileLayer: false,
		showIcons: false,
		doubleClickZoom : false
	}

	constructor(props) {
		super(props);

		this.state = {

		}
	}


	getColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Identificativo zonale", field: "zone", headerSort: false },
			{ title: "SPI", field: "value", headerSort: false }
		]);
		return columns;
	}
	getTabulatorOptions() {
		return {
			data: [],
			height: "100%",
		}
	}

	pointToLayer(feature, latlng){
		let marker = L.marker(latlng, {
			icon: L.divIcon({
				className: 'label',
				html: `<span style="font-size:1rem; font-weight:600">${feature.properties[this.props.geoJsonIdentifier]}</span>`,
			})
		});
		return marker;

	}

	getTableData = ()=> {
		let {imgId,zoneGeoJson,rawSPIObject,mapKey,timescale,polygonStyle,geoJsonIdentifier,raster} = this.props;

		let tabulatorData = [];
		if (!!rawSPIObject){
			tabulatorData = Object.keys(rawSPIObject).map(zone => {
				return {'zone' : zone, 'value' : NumberUtils.round(rawSPIObject[zone][timescale],1)}
			});
		}

		tabulatorData.sort((a, b) => a.zone.localeCompare(b.zone));
		return tabulatorData
	}


	render(){
		let {imgId,zoneGeoJson,rawSPIObject,mapKey,timescale,polygonStyle,geoJsonIdentifier,raster} = this.props;


		
		return(<>
				<div className="col-4" key={"local_" + imgId}>
					<div className="custom-popup">
						<div className="d-flex justify-content-between">
							<h3>SPI - {timescale} (puntuale)</h3>
						</div>
						<div  style={{display: 'inline-block'}}>

							<DownloadContainer
								imageName={"local_" + imgId +"_map"}
								disabled={!!!raster}
							>
								<div id={imgId}>
									<MapComponent

										width={this.mapOptions.width}
										height={this.mapOptions.height}
										zoom={this.mapOptions.zoom}
										minZoom={this.mapOptions.minZoom}
										maxZoom={this.mapOptions.maxZoom}
										zoomControl={false}
										zoomSnap={false}
										scrollWheelZoom={false }
										dragging={ false }
										doubleClickZoom = {false}
										buttonKey={1}

										zoneBbox={this.state.zoneBbox}
										noHome={true}
										setMapRef={mapRef => this.mapRef = mapRef}
										attributionControl={false}
									>
										{!!zoneGeoJson ? <GeoJSON key={"polygons_" + imgId + "_" + timescale}
																  data={zoneGeoJson}
																  style={{
																	  fillColor:  "#fff0",
																	  weight: 0.6,
																	  opacity: 1,
																	  color: "blue",
																	  fillOpacity: 0.5
																  }}
																  pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}

										/> : <></>}
										{!!raster ? <GeotiffRasterLayer
											key={"raster_temp_" + timescale + "_" + mapKey}
											georaster={raster}
											opacity={0.5}
											resolution={256}
											colorScale={ChromaticScale.getSPIScaleGradient()}
											handleClick={(val, latlng) => GISTools.showPopup(val, latlng, this.mapRef.leafletElement)}
										/> : <></>}

									</MapComponent>
								</div>
							</DownloadContainer>
						</div>
					</div>
				</div>
				<div className="col-8" key={"zonal_" + imgId}>
					<h3>SPI - {timescale} (zonale)</h3>
					<div className="row ">
						<div className="col-5" >
							<DownloadContainer
								imageName={"zonal_" + imgId +"_map"}
								disabled={!!!rawSPIObject}
							>
								<PolygonMapComponent
									id ={imgId}
									geoJSON={zoneGeoJson}
									spiValues={rawSPIObject}
									key={mapKey + "_" + timescale}
									zoomOnSelected = {true}
									timeScale={timescale}
									polygonStyle = {(feature,latlng)=>polygonStyle(feature,latlng,timescale)}
									mapOptions = {this.mapOptions}
									geoJsonIdentifier = {geoJsonIdentifier}
									noHome={true}
								/>
							</DownloadContainer>
						</div>
						<div className="col-4">
							<DownloadContainer
								imageName={"zonal_" + imgId +"_table"}
								disabled={!!!rawSPIObject}
							>
								<ReactTabulator
									columns={this.getColumns()}
									data={this.getTableData()}
									options={this.getTabulatorOptions()}
									key={"spiTable_" + timescale + "_" + mapKey}
								/>
							</DownloadContainer>
						</div>
						<div className="col-3 mt-5" id="legend">
							{spiColors.map((c,i) => {
								return (
									<div className="row mt-1" key={"key_"+i}>
										<div className="col-2" style={{background: c, width: '1vw',height:'2vh', marginBottom:'1px', border : 'solid', borderColor: '#b3b1b1'}}>&nbsp;</div>
										<div className="col-10"> <h6>{legendArray[i]}</h6></div>
									</div>
								)})
							}
						</div>

					</div>
				</div>
			</>
		)
	}
}
