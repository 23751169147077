import React, {useEffect, useState, useCallback} from 'react'
import TimeField from 'react-simple-timefield';

import { ALLERTAMENTO_IDRO_DEFINITIONS, getWeightByColor } from "#/backoffice/mau/utils/MauUtils";
import LabelledOutline from '#/commons/components/LabelledOutline';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useDebouncedCallback} from "use-debounce";

export const MAUMultizoneIdroForm = (props) => {

	const viewMode = props.viewMode;
	const criticita = props.formData.criticita;
	let rootSection = props.rootSection;
	let section = props.section;

	/*let element = document.getElementById('scenario');
	useEffect(() => {

		if (element) {
			/!*element.addEventListener('focus', () => {
				let that = this;
				setTimeout(() => { that.selectionStart = that.selectionEnd = 10000; }, 0);
			}, false);*!/
			element.addEventListener('focus', () => {
				console.log('CLICKED')
				let that = this;
				if (that) {
					setTimeout(() => {
						that.selectionStart = 10000;
					}, 0);
				}
			}, false)
		}
	},[criticita]);*/

	const toItemFunction = (zone) => {
		return (
			<Grid container key={"grid_"+zone.id} style={{ marginLeft: "30px" }} spacing={1}>
				<Grid item  xs={1}><h3>{zone.label}</h3></Grid>
				<Grid item  xs={5}>
					<TextField
						disabled = {viewMode}
						fullWidth
						select
						id="idro_temp"
						name="idro_temp"
						label="Livello di allertamento Idrogeologico per TEMPORALI"
						value={!!criticita && !!criticita.zones && !!criticita.zones.find(z=> z.zone===zone.id) ? criticita.zones.find(z=> z.zone===zone.id).idro_temp || 'VERDE' : 'VERDE'}
						onChange={(e)=>props.onFormChange(section, zone.id, e.target.name, e.target.value)}
					>
						{ALLERTAMENTO_IDRO_DEFINITIONS.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={5}>
					<TextField
						disabled = {viewMode}
						fullWidth
						select
						id="idro_idra"
						name="idro_idra"
						label="Livello di allertamento Idrogeologico IDRAULICO"
						value={!!criticita && !!criticita.zones && !!criticita.zones.find(z=> z.zone===zone.id) ? criticita.zones.find(z=> z.zone===zone.id).idro_idra || 'VERDE' : 'VERDE'}
						onChange={(e)=>props.onFormChange(section, zone.id, e.target.name, e.target.value)}
					>
						{ALLERTAMENTO_IDRO_DEFINITIONS.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			</Grid>
		)
	}

	const selectList = !!props.zones ? props.zones.map((zone) => toItemFunction(zone)) : [];

	const isAvvisoDisabled = criticita => criticita ? criticita.zones.some(z=> z.idro_idra !== 'VERDE' || z.idro_temp !== 'VERDE') : false;

	const [innerValue, setInnerValue] = useState('');
	useEffect(() => {
		if (criticita && criticita.scenario_string) {
			setInnerValue(criticita.scenario_string);
		} else {
			setInnerValue('');
		}
	}, [criticita.scenario_string]);

	const handleOnChangeScenario = useCallback((event) => {
		event.persist();
		const newValue = event.currentTarget.value;
		setInnerValue(newValue);
		debouncedHandleOnChange(event);
	}, []);

	const debouncedHandleOnChange = useDebouncedCallback(
		(e) => {
			props.onFormChange(section, null, e.target.name, e.target.value)
		},
		200
	);

	return (
		!!selectList.length &&
		<LabelledOutline id="myID" label={props.labelForm}>
			<FormGroup >
				<Grid container style={{ marginLeft: "30px" }} spacing={1}>
					<Grid item xs={4}>
						<TextField
							disabled
							label="Inizio Validità"
							name="hour_start"
							value={!!props.formData ? props.formData.hour_start || '' : ''}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							disabled
							label="Fine Validità"
							name="hour_end"
							value={!!props.formData ? props.formData.hour_end || '' : ''}
						/>
					</Grid>
					<Grid item xs={4}>
						<FormControlLabel
							disabled = {viewMode}//|| isAvvisoDisabled(criticita)
							id="avviso"
							label="Avviso di criticità idrogeologica - idraulica e idrogeologica per temporali"
							labelPlacement="end"
							control={
								<Checkbox
									color="primary"
									name="avviso"
									checked={criticita.avviso}
									onChange={(e)=>props.onFormChange(section, null, e.target.name, e.target.checked)}
								/>
							}
						/>
					</Grid>
				</Grid>
				{selectList}
				<Grid container style={{ marginLeft: "30px" }} spacing={1}>
					<Grid item xs={11}>
						<TextField
							fullWidth
							multiline
							autoFocus
							disabled = {false}
							rows={4}
							id="scenario_string"
							name="scenario_string"
							label="Scenario"
							value={innerValue}
							onFocus={({target})=>{
								target.selectionStart = target.selectionEnd = 10000;
							}}
							onClick={({target})=>{
								target.selectionStart = target.selectionEnd = 10000;
							}}
							onChange={handleOnChangeScenario}
						/>
					</Grid>
				</Grid>
			</FormGroup>
		</LabelledOutline>
	)


}
