import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import _ from "lodash";
import React, {useState} from "react";
import DateUtils from "#/lib/DateUtils";
import {COMBINED_TO_STANDARD, getUnitByCategory} from "#/lib/MeasurementCategory";
import {TiWarning} from "react-icons/ti";
import {reactFormatter} from "react-tabulator";

const DateCell = ({cell : {_cell : {row : {data : {timestamp}}}}}) => {
return timestamp === 9999 ?
        <span style={{color: 'red', fontWeight: 900}}><TiWarning style={{fontSize: 'x-large', color: 'red'}}/> Più di due ore fa</span> :
        <div>{DateUtils.epochToDateMinuteResolution(timestamp)}</div>
}


const MidaTables = ({selectedGeoData, selectedCategory, selectedEvents, tableKey, rainMode, rainReturnTimeEvents, rainThresholdsEvents, pointsKey, rowClickListener}) => {

    const [showAnomalies, toggleAnomalies] = useState(false);
    const getColumns = () => {
        switch(selectedCategory){
            case 'P':
                return [
                    {title: "Stazione", field: "name", headerSort: true, headerFilter: true, headerFilterPlaceholder: 'Ricerca...'},
                    {
                        title: "Data ultima<br/>misurazione",
                        field: "timestamp",
                        formatter: reactFormatter(<DateCell></DateCell>),
                        headerSort: true,
                        headerFilter: false
                    },
                    {title: "Pioggia giornaliera<br/>[mm]", field: "P_DAILY", headerSort: true, headerFilter: false},
                    {title: "Intensità pioggia<br/>[mm/H]", field: "P_ESTIMATED_1H", headerSort: true, headerFilter: false,
                        formatter: cell => assignAnomalyColor(cell)},
                    {
                        title: "1 ora",
                        field: "P1H",
                        headerSort: true,
                        headerFilter: false,
                        width: 100,
                        formatter: cell => assignAnomalyColor(cell)
                    },
                    {
                        title: "3 ore",
                        field: "P3H",
                        headerSort: true,
                        headerFilter: false,
                        width: 100,
                        formatter: cell => assignAnomalyColor(cell)
                    },
                    {
                        title: "6 ore",
                        field: "P6H",
                        headerSort: true,
                        headerFilter: false,
                        width: 100,
                        formatter: cell => assignAnomalyColor(cell)
                    },
                    {
                        title: "12 ore",
                        field: "P12H",
                        headerSort: true,
                        headerFilter: false,
                        width: 100,
                        formatter: cell => assignAnomalyColor(cell)
                    },
                    {
                        title: "24 ore",
                        field: "P24H",
                        headerSort: true,
                        headerFilter: false,
                        width: 100,
                        formatter: cell => assignAnomalyColor(cell)
                    },
                ];
            case 'WV':
            case 'WS':
            case 'WR':
                return [
                    {title: "Stazione", field: "name", headerSort: true, headerFilter: true, headerFilterPlaceholder: 'Ricerca...'},
                    {
                        title: `Intensità [m/s]`,
                        field: `${COMBINED_TO_STANDARD[selectedCategory].replace('D','V')}`,
                        headerSort: true,
                        headerFilter: false,
                        formatter: cell => assignAnomalyColor(cell)
                    },
                    {
                        title: "Direzione [°]",
                        field: `${COMBINED_TO_STANDARD[selectedCategory]}`,
                        headerSort: true,
                        headerFilter: false
                    },
                    {
                        title: "Data ultima<br/>misurazione",
                        field: "timestamp",
                        formatter: reactFormatter(<DateCell></DateCell>),
                        headerSort: true,
                        headerFilter: false
                    }];
            default:
                return [
                    {title: "Stazione", field: "name", headerSort: true, headerFilter: true, headerFilterPlaceholder: 'Ricerca...'},
                    {
                        title: `Ultima misurazione [${getUnitByCategory(selectedCategory)}]`,
                        field: "value",
                        headerSort: true,
                        headerFilter: false,
                        formatter: cell => assignAnomalyColor(cell)
                    },
                    (selectedCategory === 'I' && {
                        title: "Portata relativa [m<sup>3</sup>/s]",
                        field: "flow",
                        headerSort: true,
                        headerFilter: false
                    }),
                    {
                        title: "Data ultima<br/>misurazione",
                        field: "timestamp",
                        formatter: reactFormatter(<DateCell></DateCell>),
                        headerSort: true,
                        headerFilter: false
                    },
                ].filter(col => !!col);
        }
    }

    const assignAnomalyColor = cell => {
        let {column : {field}, row : {data : {name : stationName}}} = cell._cell;
        if (selectedCategory === 'P'){
            let events = rainMode === 'RT' ? rainReturnTimeEvents : rainThresholdsEvents;
            let stationRelatedEvent = events.find(event => event.stationName === stationName);
            if (!!stationRelatedEvent){
                const num = field.match(/\d+/g).map(Number)[0];
                const altCondition = child =>  field === 'P_ESTIMATED_1H' && child.duration === '1 (stimata)';
                let timescaleRelatedEvent = stationRelatedEvent._children.find(child => child.duration[0] === num || altCondition(child));
                if (!!timescaleRelatedEvent){
                    cell.getElement().style.backgroundColor = timescaleRelatedEvent.color;
                    cell.getElement().style.fontWeight = 'bold';
                }
            }
        }
        else {
            let stationRelatedEvent = selectedEvents.find(event => event.name === stationName);
            if (!!stationRelatedEvent){
                cell.getElement().style.backgroundColor = stationRelatedEvent.color;
                cell.getElement().style.fontWeight = 'bold';
            }
        }
        return cell.getValue();
    };
    const getTabulatorOptions = () => ({
        data: [],
        height: showAnomalies ? "25vh" : "50vh",
        dataTree:true,
        dataTreeStartExpanded: false,
        invalidOptionWarnings : false,
        selectable:false,
        rowClick:  (e, row) => rowClickListener(row._row.data.code, pointsKey)
    });
    const getAnomaliesColumns = () => {
        let columns = [];
        if (selectedCategory === 'P') {
            switch (rainMode) {
                case 'RT': {
                    columns = columns.concat([
                        {title: "Stazione", field: "stationName", headerSort: true, headerFilter: true, headerFilterPlaceholder: 'Ricerca...'},
                        {title: "Durata pioggia [ore]", field: "duration", headerSort: false, headerFilter: false},
                        {title: "Valore [mm]", field: "value", headerSort: false, headerFilter: false},
                        {title: "Distribuzione ", field: "distribution", headerSort: false, headerFilter: false},
                        {
                            title: "Tempo di ritorno [anni]",
                            field: "returnTime",
                            headerSort: false,
                            headerFilter: false,
                            formatter: (cell) => {
                                if (!!cell._cell.row && !cell._cell.row.data.hasOwnProperty("_children")) {
                                    cell.getElement().style.backgroundColor = cell._cell.row.data.color;
                                    return cell.getValue();
                                }
                            }
                        }
                    ]);
                    break;
                }
                case 'TH': {
                    columns = columns.concat([
                        {
                            title: "Stazione",
                            field: "stationName",
                            headerSort: true,
                            headerFilter: true, headerFilterPlaceholder: 'Ricerca...',
                            formatter: (cell) => {
                                let value = cell.getValue();
                                return !!value ? `<span style='background-color:${cell._cell.row.data.stationColor}; width:20px; height:10px; display:inline-block'></span>${value}` : ""
                            }
                        },
                        {title: "Durata pioggia [ore]", field: "duration", headerSort: false, headerFilter: false},
                        {
                            title: "Valore [mm]",
                            field: "value",
                            headerSort: false,
                            headerFilter: false,
                            formatter: (cell) => {
                                if (!!cell._cell.row && !cell._cell.row.data.hasOwnProperty("_children")) {

                                    cell.getElement().style.backgroundColor = cell._cell.row.data.color;
                                    return cell.getValue();
                                }
                            }
                        }
                    ]);
                    break;
                }
                default :
                    break;
            }
        } else {
            columns = columns.concat([
                { title: "Stazione", field: "name", headerFilter: true, headerFilterPlaceholder: 'Ricerca...' },
                { title: "Tipo superamento", field: "thresholdType"},
                { title: "Data misurazione", field: "timestamp", formatter: cell => DateUtils.epochToDateMinuteResolution(cell.getValue()), headerSort: true, headerFilter: false },
                { title: `Valore [${getUnitByCategory(selectedCategory)}]`, field: "value",formatter: (cell) =>
                    {
                        cell.getElement().style.backgroundColor = cell._cell.row.data.color;
                        return cell.getValue();
                    }
                }
            ]);
        }
        return columns;
    }
    const getAnomaliesData = () => selectedCategory === 'P' ? (rainMode === 'RT' ? rainReturnTimeEvents : rainThresholdsEvents) : selectedEvents;
    const getAnomaliesTabulatorOptions = () =>
        ({
            data: [],
            height: "25vh",
            dataTree:true,
            dataTreeStartExpanded: false,
            invalidOptionWarnings : false,
        });
    return <>

        { !!selectedCategory &&
        <>
            {/* ----------------------- REAL TIME TABLE ----------------------- */}
            <ReactTabulator
                columns={getColumns()}
                data={!_.isEmpty(selectedGeoData) ? selectedGeoData.features.map(f => f.properties) : []}
                options={getTabulatorOptions()}
                key={"table_" + selectedCategory + tableKey + "_" + rainMode + "_" + showAnomalies}
            />
            <div className="d-flex justify-content-between">
                <span onClick={() => toggleAnomalies(!showAnomalies)} className="noUnderlineAnchor" style={{cursor:"pointer"}}>
                    <h3>{!showAnomalies ? 'Mostra anomalie' : 'Nascondi anomalie'}</h3>
                </span>
            </div>
            {/* ----------------------- ANOMALIES TABLE ----------------------- */}
            {showAnomalies ?
                <ReactTabulator
                    columns={getAnomaliesColumns()}
                    data={getAnomaliesData()}
                    options={getAnomaliesTabulatorOptions()}
                    key={"anomalies_table_" + selectedCategory + tableKey + "_" + rainMode}
                />
                : <></>}
        </>}
    </>
}
export default MidaTables;
