import React from 'react';
import _ from 'lodash';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import SensorClient from "#/lib/SensorClient";
import MixedChart from "#/commons/chart/MixedChart";
import NumberUtils from "#/lib/NumberUtils";
import MixedChartNavigationHeader from "#/test/pages/MixedChartNavigationHeader";
import Button from "@material-ui/core/Button";
import {FiCloudRain} from "react-icons/fi";
import Skeleton from "react-loading-skeleton";

const ReactSwal = withReactContent(Swal);

export default class AggregatedChart extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			ready: false,
			channels: {},
			channelNames: [],
			outflowScales: {},
			joinRainAxes: false,
			startIndex: 0,
			stopIndex: 0,
			chartKey: 0
		};
	}
	componentDidMount() {
		setTimeout(() => {
			let {interval, measure, compareList, colorsMap} = this.props;
			this.getData(interval, measure,	compareList,
				(dataset, outflowScales) => {

					const series = this.buildSeries(dataset, outflowScales, colorsMap);
					const sensorCategories = this.buildSensorCategories([measure,...compareList], colorsMap );
					const xLabels = this.buildXlabels(dataset);
					const maxRange = this.calculateMaxRange(series);
					const stopIndex = xLabels.length;
					const startIndex = stopIndex - maxRange - 1 > 0 ? stopIndex - maxRange - 1 : 0;

					this.setState({ready: true, series, sensorCategories, xLabels, outflowScales, maxRange, startIndex, stopIndex, chartKey: (this.state.chartKey + 1) % 1000 });
				}
			)
		}, 0);
	}

	getData(interval, measure, compareList, okCallback) {
		let dataset = {};
		Promise.all(this.dataPromises())
			.then(values => {
				values.forEach((response) => {
					dataset[response.sensorCode] = {};
					dataset[response.sensorCode].data = response.data;
					dataset[response.sensorCode].target = response.target
				})
			}).then(() => {
			if ((measure.value === 'I' || !!compareList.find(c => c.value === 'I')) && _.isEmpty(this.state.outflowScales)){
				this.outflowScalesPromise()
					.then((data) => okCallback(dataset,data.outflowScales));
			} else {
				okCallback(dataset, {})
			}
		})
	}
	dataPromises = () => {
		const {interval, measure, compareList, dataSetCache, aggregationInterval} = this.props;
		return [measure,...compareList].map(currentMeasure =>
			new Promise((resolve, reject) => {
				if(!!dataSetCache && !!dataSetCache[currentMeasure.sensor[0].sensorCode]){
					resolve({
						target: false,
						data: dataSetCache[currentMeasure.sensor[0].sensorCode].data,
						sensorCode: currentMeasure.sensor[0].sensorCode,
					});
				} else {
					let params = {
						start: interval.start.valueOf(),
						end: interval.end.valueOf(),
						code: currentMeasure.sensor[0].sensorCode,
						aggregationFunction: currentMeasure.value === 'P' ? 'DELTA' : 'AVG',
						aggregationInterval
					};
					MeasurementsDataClient.getData(
						measuresList => resolve({
							target: false,
							data: measuresList.filter(measure => measure.interval.label !== 'not valid interval'),
							sensorCode: currentMeasure.sensor[0].sensorCode,
						}),
						msg => console.log("Si è verificato un errore inaspettato", msg),
						params
					);
				}
			})
		)
	}
	outflowScalesPromise = () => new Promise((resolve, reject) => {
		SensorClient.getAllOutflowsScalesMapBySensorCode(
			(data) => {
				if (!_.isEmpty(data)){
					resolve({
						outflowScales:  data
					})
				} else {
					ReactSwal.fire({
						title: 'Nessuna scala di deflusso trovata',
						text : 'Non è possibile calcolare le portate.',
						icon : 'error'
					});
				}
			},
			(msg) => {
				console.log('Error retrieving outflow scales');
			}
		)
	});

	buildSensorCategories = (measures, colorsMap) => {
		let categories = [];
		Object.keys(colorsMap).forEach(category => {
			const masterColor = colorsMap[category].color;
			const label = measures.find(meas => meas.value === category).label;
			categories.push({id: category, label, masterColor});
			if (category === 'P'){categories.push({id: `${category}_cumulated`, label: `${label} (Pioggia cumulata)`, masterColor})}
			if (category === 'I'){categories.push({id: `${category}_flow`, label: `${label} (Portata)`, masterColor})}
		})
		return categories;
	}
	buildSeries = (dataset, scales, colorsMap) => {
		let series = [];
		Object.keys(dataset).sort((a, b) => dataset[b].data[0].category === 'P' ? -1 : 0).forEach( (sensorCode,index) => {
			const data = dataset[sensorCode].data;
			const {category: sensorCategory, stationCode} = data[0];
			const stationName = this.props.stations.find(stat => stat.value === stationCode).label.split('(')[0].trim();
			const color = colorsMap[sensorCategory].sensors[sensorCode];
			series.push({
				label: `${stationName} (${sensorCategory})`,
				color,
				data: data.map(x => NumberUtils.round(x.value)),
				sensorCategory: sensorCategory
			})
			if (sensorCategory === 'P'){
				
				let lastNumericValue = 0;

				const updatedData = data.map(d => {
					if (typeof d.cumulatedValue === "number" && !isNaN(d.cumulatedValue)) {
						lastNumericValue = d.cumulatedValue;
					}
					else {
						d.cumulatedValue = lastNumericValue
					}
					return d;

				});
				let min = Math.min(...updatedData.map(x=>x.cumulatedValue));
				let firstValue = updatedData[0].value || 0;
				series.push({
					label: `${stationName} (Pioggia cumulata)`,
					color,
					data: updatedData.map(x =>  NumberUtils.round(x.cumulatedValue - min + firstValue,1)),
					sensorCategory: `${sensorCategory}_cumulated`
				})
			}
			if (sensorCategory === 'I'){
				let outflowScales = _.isEmpty(scales) ? this.state.outflowScales : scales;
				series.push({
					label: `${stationName} (Portata)`,
					color,
					data: data.map(x => NumberUtils.round(this.calculateFlow(x.value, outflowScales[sensorCode]))),
					sensorCategory: `${sensorCategory}_flow`
				})
			}
		})

		console.log("SERIES", series)
		return series;
	}
	buildXlabels = (dataset) => {
		const code = Object.keys(dataset)[0];
		const data = dataset[code].data;
		return data.map(x =>  x.interval.label.replace(' (GMT+1)',''))
	}

	calculateFlow = (value, scales) => {
		if (!!scales){
			const scale = scales.find(scale => value >= scale.thresholdStart && value < scale.thresholdEnd);
			if (!!scale){
				const {a, b, c, e} = scale;
				return Math.round(((a*Math.pow(value + e, b) + c) + Number.EPSILON) * 100) / 100;
			}
		}
		return 'nd'
	}
	calculateMaxRange = (series) => {
		const barCountPerXCategory = series.filter(s => s.sensorCategory === 'P').length;
		return Math.floor(40/barCountPerXCategory);
	}

	pruneSeries = (startIndex, stopIndex) => {
		console.log(this.state.series)	
		return _.cloneDeep(this.state.series).map(s => {
		
			s.data = s.data.slice(startIndex, stopIndex);
			return s;
		})
	};
	pruneXLabels = (startIndex, stopIndex) => _.cloneDeep(this.state.xLabels).slice(startIndex, stopIndex);

	moveBack = (startIndex, stopIndex) => this.setState({
		startIndex: startIndex - 1,
		stopIndex: stopIndex - 1,
		chartKey: (this.state.chartKey + 1) % 1000
	})
	goToStart = (series, xLabels) => {
		const maxRange = this.calculateMaxRange(series);
		this.setState({
			startIndex: 0,
			stopIndex: xLabels.length > maxRange ? maxRange + 1 : xLabels.length,
			chartKey: (this.state.chartKey + 1) % 1000
		})
	}

	moveForward = (startIndex, stopIndex) => this.setState({
		startIndex: startIndex + 1,
		stopIndex: stopIndex + 1,
		chartKey: (this.state.chartKey + 1) % 1000
	})
	goToEnd = (series, xLabels) => {
		const maxRange = this.calculateMaxRange(series);
		this.setState({
			startIndex: xLabels.length - maxRange - 1 > 0 ? xLabels.length - maxRange - 1 : 0,
			stopIndex: xLabels.length,
			chartKey: (this.state.chartKey + 1) % 1000
		})
	}

	joinRainAxes = () => this.setState({joinRainAxes: !this.state.joinRainAxes, chartKey: (this.state.chartKey + 1) % 1000});

	render = () => {
		const {startIndex, stopIndex, series, xLabels, sensorCategories, chartKey, ready} = this.state;
		if (!ready) {
			return <Skeleton height={"60vh"} duration={8}/>;
		} else {
			const prunedXlabels = this.pruneXLabels(startIndex, stopIndex);
			return <div>
				<MixedChartNavigationHeader
					prevDisabled={startIndex === 0}
					nextDisabled={stopIndex === xLabels.length}
					backHandler={() => this.moveBack(startIndex, stopIndex)}
					fastBackHandler={() => this.goToStart(series, xLabels)}
					forwardHandler={() => this.moveForward(startIndex, stopIndex)}
					fastForwardHandler={() => this.goToEnd(series, xLabels)}
					title={`${prunedXlabels[0]} - ${prunedXlabels[prunedXlabels.length - 1]}`}
				/>
				{this.state.sensorCategories.find(cat => cat.id === 'P') ? <Button
						size="small"
						variant="contained"
						color="default"
						startIcon={<FiCloudRain/>}
						onClick={(e) => this.joinRainAxes()}
					>
						{this.state.joinRainAxes ? 'Separa scale' : 'Unisci scale'}
					</Button>
					: <></>}
				<MixedChart
					sensorCategories={sensorCategories}
					xLabels={prunedXlabels}
					series={this.pruneSeries(startIndex, stopIndex)}
					joinRainAxes={this.state.joinRainAxes}
					chartKey={chartKey}
				/>
			</div>
		}
	}
};






