import React from "react";

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';
import {BackButton} from "#/commons/components/forms/BackButton";
import {SaveButton} from "#/commons/components/forms/SaveButton";
import Dropzone from "react-dropzone-uploader";


export const StepPrevisioniMeteoFile = (props) => {

  const initialFile = props.initialFile;

  const handleNextStep = () => {
    props.handleNext();
  }

  return (
      <>
        <div className="row justify-content-center">
          <h2>{props.stepLabel}</h2>
        </div>
        {props.uploadedFile ? 
          <div className="row justify-content-center">
            <span className="my-2"><strong>File caricato:</strong> {props.uploadedFile}</span>
          </div> : 
          <> </>
        }
        <Grid container justify="center" spacing={1}>
            <Grid item xs={12}>
              <Dropzone
                  onChangeStatus={props.handleDropzoneChangeStatus}
                  accept=".pdf"
                  maxFiles={1}
                  inputContent="Seleziona il file da caricare"
                  initialFiles={initialFile && [initialFile]}
                  styles={{
                    dropzone: { overflow: "hidden", width: "30vw" },
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
                  }}
              />
          </Grid>
        </Grid>

        <MobileStepper
          style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
          position="static"
          variant="text"
          activeStep={props.activeStep}
          steps={props.totalSteps}
          nextButton={
              <SaveButton
                  variant="contained"
                  color="primary"
                  onClick={handleNextStep}
              />
          }
          backButton={
              <BackButton
                variant="contained"
                disabled={props.disableBackButton}
                onClick={props.handleBack}
              />
          }
        />
      </>
    );
  };
