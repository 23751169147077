import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class SensorCategoryClient {

	static getAll(okCallback, errCallback){
		let url = new URL(properties.url.sensorCategory);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static save(params, okCallback, errCallback){
		let url = new URL(properties.url.sensorCategorySave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static delete(id,okCallback, errCallback){
		let url = new URL(properties.url.sensorCategoryDelete + "/" + id);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getSensorClassThresholds(okCallback, errCallback){
		let url = new URL(properties.url.sensorClassThresholdMapByCategory);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static saveThresholdsBySensorCategory(params,okCallback, errCallback){
		let url = new URL(properties.url.sensorCategoryThresholdSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static deleteThresholdsBySensorCategory(category,okCallback, errCallback){
		let url = new URL(properties.url.sensorCategoryThresholdDelete + "/" + category);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
}
