import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class StationClient {


	static getStationsGeojson(okCallback, errCallback) {
		let url = properties.url.stations;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getStationsGeojsonByCategory(sensorCategory,okCallback, errCallback) {
		let url = properties.url.stations + "/" + sensorCategory;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getAllStationsGeojsonByCategory(sensorCategory,okCallback, errCallback) {
		let url = properties.url.stations + "/" + sensorCategory+"?all=true";
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getPluvsStationsGeojson(okCallback, errCallback, all=false) {
		let url = properties.url.stations+"/P?all="+all;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getHydrometerStationsGeojson(okCallback, errCallback) {
		let url = properties.url.stations+"/I";
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static saveStation(params, okCallback, errCallback){
		let url = new URL(properties.url.saveStation);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	static deleteStation(stationId, okCallback, errCallback){
		let url = new URL(properties.url.deleteStation + "/" + stationId) ;
		let fetch_options = {
			method: 'DELETE',
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}



}
