import React, {useEffect, useState} from 'react';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import MeasurementDataClient from "#/lib/MeasurementsDataClient";
import {FaFileCsv, FaFilePdf} from "react-icons/fa";
import {FiSend} from "react-icons/fi";
import {ReactTabulator} from "react-tabulator";
import DateUtils from "#/lib/DateUtils";
import {MeasurementAggregationDisplayValues} from "#/lib/MeasurementAggregation";
import {getUnitByCategory, MEASUREMENT_CATEGORY, MEASUREMENT_CATEGORY_ICONS} from "#/lib/MeasurementCategory";
import ExportButton from "#/commons/components/forms/ExportButton";
import _ from "lodash";
import {PdfModal} from "#/commons/table/PdfModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import {BUTTON_PROGRESS_STYLE} from "#/backoffice/bim/utils/BIMUtils";
import WidthUtils from "#/lib/WidthUtils"

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {BackingDataTable} from "#/commons/components/BackingDataTable";
import SensorClient from '#/lib/SensorClient';
const ReactSwal = withReactContent(Swal);
export default class LightMeasurementsBody extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dataSetCache: {},
			sensorValueMapByTimestamp : {},
			aggregationInterval : !!this.props.aggregationInterval ? this.props.aggregationInterval.value : ''
		}
	}

	componentDidUpdate() {
		if (!!this.props.aggregationInterval && this.props.aggregationInterval.value !== this.state.aggregationInterval){
			this.setState({dataSetCache : {}, aggregationInterval : this.props.aggregationInterval.value});
		}
	}
	getDataByInterval(interval, measures, callback){

		console.log(measures)

		let codes = []
		measures.forEach(element => {
			if(Array.isArray(element.sensorCode) ){
				element.sensorCode.forEach(s=>codes.push(s))
			}
			else{
				codes.push(element.sensorCode)
			}
		});
		let params = {
			codes,
			start : interval.start.valueOf(),
			end : interval.end.valueOf(),
		}
		if (this.props.aggregationInterval.value === 'PUNTUAL' || this.props.aggregationInterval.value === 'NONE') {
			MeasurementDataClient.getMultiSensorPagedByTime(
				(data) => {

					callback(data);
				},
				(err) => {
				},
				params
			)
		} else {
			params.aggregationInterval = this.props.aggregationInterval.value;
			MeasurementDataClient.getAggregatedMultiSensorPagedByTime(
				(data) => {
					callback(data);
				},
				(err) => {
				},
				params
			)
		}
	}

	render() {
		const {interval, selectedMeasure, selectedStation, compareList} = this.props;

		let mobileMode = WidthUtils.isMobile()
		let allStations = false;
		if (!selectedStation.value && compareList.length === 0) {
			allStations = true;
			this.props.stations.features.map((station) =>
				compareList.push({
					station: {
						name: station.properties.name,
						value: station.properties.code ?? '',
						label: station.properties.name ?? '',
						icon: '',
					},
					measure: selectedMeasure
				})
			)
		}
		let isBackoffice = window.location.href.includes("backoffice");
		let measures = []
		if(selectedMeasure.group==="WIND_COMBO"){
			selectedMeasure.sensor.forEach(s=>{
				measures.push({
					sensorCode : s.sensorCode,
					sensorCategory : s.category
				})
			})
		}
		else{
			measures.push({
				sensorCode : selectedMeasure.sensor[0].sensorCode,
				sensorCategory : selectedMeasure.value
			});
		}

		compareList.map(x => x.measure).forEach(comp =>
			measures.push({
				sensorCode: comp.sensor.map(s=>s.sensorCode),
				sensorCategory: comp.value
			}));


		return (
			<>
				<div className='d-flex flex-wrap align-items-center justify-content-between'>
					<h2>Sensori selezionati per il download:</h2>
					<BackingDataTable
						ref={React.createRef()}
						aggregation={this.props.aggregationInterval.value}
						measures={measures}
						interval={interval}
						stationNameBySensorCode={this.props.stationNameBySensorCode}
						getDataByInterval={(interval, measures, callback)=>this.getDataByInterval(interval, measures, callback)}
					/>
				</div>
				<div className="scrollbar scrollbar-primary" style={{height: '50vh', overflowY: 'auto'}}>
					<table style={{width: '98%'}}>

						<tr>
							<th className="pr-4"><h3>Nome stazione</h3></th>
							{isBackoffice && <th className="pr-4"><h3>Codice stazione</h3></th>}
							<th className="pr-4"><h3>Categoria sensore</h3></th>
							{isBackoffice && <th className="pr-4"><h3>Codice sensore</h3></th>}
						</tr>


						{
							!allStations && selectedStation.value ?
							<tr>
								<td>{selectedStation.label}</td>
								{isBackoffice && <td>{selectedStation.value}</td>}
								<td>{selectedMeasure.label}</td>
								{isBackoffice && <td>{selectedMeasure.sensor[0].sensorCode}</td>}
							</tr>
							:
							<></>
						}

						{compareList.map((compared,i) =>
							<tr style={{backgroundColor: i % 2 === 0 ? '#bef5cf' : '#fff'}}>
								<td className="pr-4">{compared.station.name}</td>
								{isBackoffice && <td className="pr-4">{compared.station.value}</td>}
								<td className="pr-4">{compared.measure.label}</td>
								{isBackoffice && <td className="pr-4">{compared.measure.sensor[0].sensorCode}</td>}
							</tr>)}

					</table>
				</div>
			</>
		);
	}
}

