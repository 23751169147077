import React from 'react'

import Button from '@material-ui/core/Button';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MosipClient from '#/lib/MosipClient'
import PolygonMapComponent from '#/commons/map/PolygonMapComponent';

import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import AuthenticationService, {ROLE_ADMIN, ROLE_CONFIGURATOR} from "#/lib/AuthenticationService";

function ShowButton(props) {
	const rowData = props.cell._cell.row.data;

	return (
		<>
			<Tooltip title={"Mostra " + rowData.basin + " sulla mappa"}>
				<NotListedLocationIcon style={{ color: "#324aa8" }} onClick={(e) => { props.clickCallback(rowData.riskCode) }} />
			</Tooltip>


		</>
	)
}

export default class MosipBasinsPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 7,
		maxZoom: 12,
		width: "30vw",
		height: "70vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			basinsArray: [],
			basinsGeoJSON: null,
			selectedBasinRiskCode: null,
			enableSave: false,
			showModal: false,
			mapKey: 1
		}
	}

	componentDidMount() {


		this.loadFromApi();


	}

	loadFromApi() {
		new Promise((resolve, reject) => {
			MosipClient.getAllBasins((data) => { resolve(data); }, () => { console.log("ERROR BACINI"); });
		}).then(data => {
			let geoJSON = this.buildGeoJSONFromArray(data);
			this.setState({
				basinsArray: data,
				basinsGeoJSON: geoJSON,
				selectedBasinRiskCode: null,
				mapKey: (this.state.mapKey + 1) % 1000,
				enableSave: false,
				showModal: false
			});
		});
	}
	isConfigurator = () => AuthenticationService.haveRolesPermssions([ROLE_CONFIGURATOR, ROLE_ADMIN]);

	buildGeoJSONFromArray(data) {
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Basins";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				CODICE_RISCHIO : value.riskCode,
				ISTAT : value.istatCode,
				PROVINCIA : value.province,
				FIUME : value.river,
				BACINO : value.basin
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}

	buildArrayFromGeoJSON(geoJSON) {
		let basins = geoJSON.features.map(x => {
			return {
				riskCode: x.properties.CODICE_RISCHIO,
				istatCode: x.properties.ISTAT,
				province: x.properties.PROVINCIA,
				river: x.properties.FIUME,
				basin: x.properties.BACINO
			}
		})
		return basins;

	}

	getBasinsColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Codice Rischio", field: "riskCode", headerSort: true, headerFilter: true },
			{ title: "Provincia", field: "province", headerSort: true, headerFilter: "select", headerFilterParams: { "CZ": "Catanzaro", "CS": "Cosenza", "KR": "Crotone", "VV": "Vibo Valenzia", "RC": "Reggio Calabria" } },
			{ title: "Istat", field: "istatCode", headerSort: true, headerFilter: true },
			{ title: "Fiume", field: "river", headerSort: true, headerFilter: true },
			{ title: "Bacino", field: "basin", headerSort: true, headerFilter: true },
			{
				title: "",
				formatter: reactFormatter(<ShowButton clickCallback={(riskCode) => this.selectBasin(riskCode)}></ShowButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				minWidth: "200px",
			}
		]);
		return columns;
	}

	selectBasin(riskCode) {
		this.setState({
			selectedBasinRiskCode: riskCode
		})
	}

	resetSelectedBasin() {
		this.setState({
			selectedBasinRiskCode: null,
			mapKey: (this.state.mapKey + 1) % 1000

		})
	}

	rowFormatter(row){
	
		if(!!this.state.enableSave){
			row.getElement().style.backgroundColor = "#f5cb5c";
		}
	}

	getTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "70vh",
			rowFormatter:(row)=>this.rowFormatter(row),
		}
		return tabulatorOptions;
	}

	handleChangeStatusBasins = ({ file }, status) => {
		if (status === "revoved") {
			this.setState({
				basinsGeoJSON: null,
			})
			this.loadFromApi();
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let geoJSON = JSON.parse(fileContent);
				let data = this.buildArrayFromGeoJSON(geoJSON);
				this.setState({
					basinsArray: data,
					basinsGeoJSON: geoJSON,
					selectedBasinRiskCode: null,
					mapKey: (this.state.mapKey + 1) % 1000,
					enableSave: true,
					showModal: false
				})
			};
			reader.onerror = (event) => {
				this.loadFromApi();
			};
			reader.readAsText(file);
		}


	}

	openModal() {
		this.setState({
			showModal: true
		})
	}

	onCloseModal() {
		this.setState({
			showModal: false
		})
	}

	saveBasins() {

		let basins = this.state.basinsGeoJSON.features.map(x => this.basinFeatureToObject(x))

		MosipClient.replaceAllBasins(
			() => { this.loadFromApi() },
			() => { console.log("ERROR COMUNI") },
			basins

		)


	}

	basinFeatureToObject(feature) {
		return {

			riskCode: feature.properties.CODICE_RISCHIO,
			istatCode: feature.properties.ISTAT,
			province: feature.properties.PROVINCIA,
			river: feature.properties.FIUME,
			basin: feature.properties.BACINO,
			riskDescription: feature.properties.DESCRIZIONE,
			polygonGeojson: JSON.stringify(feature.geometry)

		}
	}

	render() {



		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className="row mt-4">

						<div className="col-4">
							<PolygonMapComponent
								selectedFieldToCheck={"CODICE_RISCHIO"}
								selected={this.state.selectedBasinRiskCode}
								geoJSON={this.state.basinsGeoJSON}
								key={this.state.mapKey}
								resetSelection={() => this.resetSelectedBasin()}
								onSelect={(riskCode) => this.selectBasin(riskCode)}
								zoomOnSelected = {true}
								mapOptions={this.mapOptions}
								showTileLayer={true}
								reload={()=>this.loadFromApi()}
							></PolygonMapComponent>
						</div>
						<div className="col-8" >
							<ReactTabulator
								ref={ref => (this.tableRef = ref)}
								columns={this.getBasinsColumns()}
								data={this.state.basinsArray}
								options={this.getTabulatorOptions()}
								key={"table_" + this.props.mapKey}
							/>
							<div className="d-flex">
								<Button
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									startIcon={<PublishIcon />}
									onClick={(e) => this.openModal()}
									disabled={!this.isConfigurator()}
								>Carica Nuovi Bacini</Button>
							</div>
							<div className="d-flex mt-1">
								<Button
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									startIcon={<SaveIcon />}
									disabled={!this.state.enableSave}
									onClick={(e) => this.saveBasins()}
								>Salva Bacini</Button>
							</div>

						</div>
					</div>

				</div>
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "50vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3 >Import Bacini da file</h3>
						</div>

						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<Dropzone
									onChangeStatus={this.handleChangeStatusBasins}
									accept=".geojson,.json"
									maxFiles={1}
									inputContent="Inserisci il file dei bacini in formato GeoJSON"
									styles={{
										dropzone: { overflow: "hidden", width: "30vw" },
										dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
										inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
									}}
								/>
							</div>
						</div>


					</div>

				</Modal>
			</>
		)
	}
}



