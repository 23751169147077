

export let MeasurementAggregationFunction = {
	AVG: "AVG",
	DELTA: "DELTA",
	MAX: "MAX",
	MIN: "MIN"
}
export let MeasurementAggregationFunctionDisplayValues = {
	AVG: "Valore medio",
	DELTA: "Incremento intervallo",
	CUMULATION: "Valore cumulato",
	MAX: "Valore massimo",
	MIN: "Valore minimo"

}

export let MeasurementAggregationForChart = {
	PUNTUAL: "PUNTUAL",
	HOUR:	"HOUR",
	HOUR_2:	"HOUR_2",
	HOUR_3:	"HOUR_3",
	HOUR_6:	"HOUR_6",
	HOUR_12:"HOUR_12",
	DAY:	"DAY",
	MONTH:	"MONTH",
	YEAR:	"YEAR"
}

export let MeasurementAggregation = {
	PUNTUAL: "PUNTUAL",
	MIN_15:	"MIN_15",
	MIN_30:	"MIN_30",
	HOUR:	"HOUR",
	HOUR_2:	"HOUR_2",
	HOUR_3:	"HOUR_3",
	HOUR_6:	"HOUR_6",
	HOUR_12:"HOUR_12",
	DAY:	"DAY",
	MONTH:	"MONTH",
	YEAR:	"YEAR"
}

export let MeasurementAggregationDisplayValues = {
	PUNTUAL:		"Misure puntuali",
	MIN_5 : "5 minuti",
	MIN_15:	"15 minuti",
	MIN_20: "20 minuti",
	MIN_30:	"30 minuti",
	HOUR:	"oraria",
	HOUR_1: "1 ora",
	HOUR_2:	"2 ore",
	HOUR_3:	"3 ore",
	HOUR_6:	"6 ore",
	HOUR_12:"12 ore",
	DAY:	"giornaliera",
	MONTH:	"mensile",
	YEAR:	"annuale"
}

export let MeasurementAggregationInMilliseconds = {
	PUNTUAL: 600000,
	MIN_15:	900000,
	MIN_30:	1800000,
	HOUR: 3600000,
	HOUR_2:	7200000,
	HOUR_3:	10800000,
	HOUR_6:	21600000,
	HOUR_12: 43200000,
	DAY:	86400000,
	MONTH:	2592000000,
	YEAR:	31536000000
}
