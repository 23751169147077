import React, {useContext} from 'react';
import ReactApexChart from "react-apexcharts";
import parse_georaster from "georaster"
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Dropzone from "react-dropzone-uploader";
import Button from "@material-ui/core/Button";
import IdwClient from "#/lib/IdwClient";
import ChromaticScale from "#/lib/ChomaticScale";
import GISTools from "#/lib/GISTools";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import {GeoJSON, ImageOverlay} from "react-leaflet";
import Fab from "@material-ui/core/Fab";
import NumericInput from 'react-numeric-input';
import DeleteSweep from "@material-ui/icons/DeleteSweep";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import FireRiskClient from "#/lib/FireRiskClient";
import _ from "lodash";
import MapComponent from "#/commons/map/MapComponent";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import {properties} from '#/properties.js';
import {AccordionContext} from "react-bootstrap";
import moment from "moment";
import DateUtils from "#/lib/DateUtils";
import WrfClient from "#/lib/WrfClient";

const ComputeGeoTiffButton = (props) =>
	<Fab variant="extended" color="inherit" aria-label="add"
		 onClick={(e) => { props.clickCallback()}}>
		Calcola dai dati
	</Fab>

const mustBeProvided = {
	NDVI : "NDVI",
	NDMI : "NDMI"
}
const cannotBeChanged = {
	ESP_VERSANTI : "Esposizione dei versanti",
	VIABILITA : "Viabilità",
	FUEL_MAP : "Fuel map"
}

const DAY = {
	TODAY : 'oggi',
	TOMORROW : 'domani'
}

const isLayerPrevisional = (day = DAY.TODAY, layer) => layer.includes('CURRENT') || day === DAY.TOMORROW;

const getLabels = day => {
	const previousDayString = day === DAY.TODAY ? 'ieri' : 'oggi';
	const currentDayString = day === DAY.TODAY ? 'oggi' : 'domani';
	const isPreviousDayPrevisional = day === DAY.TODAY ? '' : ' prevista';

	return ({
		...(day === DAY.TODAY ? {
			P_LAST_HOUR : "Precipitazione cumulata nell'ultima ora"
		} : {}),
		P_PREVIOUS_DAY : `Precipitazione cumulata${isPreviousDayPrevisional} ${previousDayString}` ,
		P_CURRENT_DAY : `Precipitazione cumulata prevista ${currentDayString}`,
		T_PREVIOUS_DAY : `Temperatura${isPreviousDayPrevisional} ${previousDayString}` ,
		T_CURRENT_DAY : `Temperatura prevista ${currentDayString}`,
		IG_PREVIOUS_DAY : `Umidità relativa media${isPreviousDayPrevisional} ${previousDayString}`,
		IG_CURRENT_DAY : `Umidità relativa media prevista ${currentDayString}`,
		VV_CURRENT_DAY : `Intensità vento prevista ${currentDayString}`,
		...cannotBeChanged,
		...mustBeProvided
	})}

const ranges = {
	P_LAST_HOUR : 'da 0 a max',
	P_PREVIOUS_DAY : 'da 0 a max',
	P_CURRENT_DAY : 'da 0 a max',
	T_PREVIOUS_DAY : 'da 0 a max' ,
	T_CURRENT_DAY : 'da 0 a max',
	IG_PREVIOUS_DAY : 'da 0 a 100%',
	IG_CURRENT_DAY : 'da 0 a 100%',
	VV_CURRENT_DAY : 'da 0 a max',
	ESP_VERSANTI : 'da 0 a 100',
	VIABILITA : 'da 0 a 100',
	FUEL_MAP : 'da 10 a 100',
	NDVI : 'da -1 a +1',
	NDMI : 'da -1 a +1'
}

const getFakeSeriesFromTrend = (isDescendant) => isDescendant ? [100,100,0,0] : [0,0,100,100];
const getTitleFromTrend = (isDescendant) => isDescendant ? 'Lineare decrescente' : 'Lineare crescente';

const nullConf = {a : '', b : '', weight : null, descendant : null};

const getDefaultConfig = day => Object.keys(getLabels(day)).reduce((map, obj) => {map[obj] = nullConf;return map},{})

const getSensorCategory = layer => layer.includes('_') ? layer.split('_')[0] : null;
const getWrfLayer = layer => {
	switch (layer) {
		case 'P_PREVIOUS_DAY' :
		case 'P_CURRENT_DAY' :
			return 'RAIN';
		case 'T_PREVIOUS_DAY' :
		case 'T_CURRENT_DAY' :
			return 'TEMP2M'
		case 'IG_PREVIOUS_DAY' :
		case 'IG_CURRENT_DAY' :
			return 'RH';
		case 'VV_CURRENT_DAY' :
			return 'WIND';
		default:
			return null;
	}
}

const getShortName = layer => {
	switch (layer) {
		case 'P_LAST_HOUR' :
			return 'P1H';
		case 'P_PREVIOUS_DAY':
		case 'P_CURRENT_DAY':
			return 'P24H';
		case 'T_PREVIOUS_DAY':
		case 'T_CURRENT_DAY':
			return 'T';
		case 'IG_PREVIOUS_DAY':
		case 'IG_CURRENT_DAY':
			return 'IG24H';
		case 'VV_CURRENT_DAY' :
			return 'WIND';
		case 'ESP_VERSANTI' :
		case 'VIABILITA' :
		case 'FUEL_MAP' :
			return null;
		case 'NDVI' :
		case 'NDMI' :
			return null; // TODO
	}
}

const getSeries = (isDescendant) => [{
	name: "Factors",
	data: getFakeSeriesFromTrend(isDescendant)
}];

const getOptions = (config) =>  ({
	chart: {
		height: 350,
		type: 'line',
		zoom: {
			enabled: false
		},
		toolbar: {
			show: false
		}
	},
	tooltip: {
		enabled: false
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: 'straight'
	},
	title: {
		text: getTitleFromTrend(config.desc),
		align: 'left'
	},
	grid: {
		row: {
			colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
			opacity: 0.5
		},
	},
	xaxis: {
		categories: ['', config.a, config.b,''],
	},
	yaxis : {
		floating: true,
		axisTicks: {
			show: false
		},
		axisBorder: {
			show: false
		},
		labels: {
			show: false
		},
	},

});

const createBlobFromArrayBuffer = (arrayBuffer) => {
	let blob =  new Blob([arrayBuffer], { type: '' });
	blob.lastModifiedDate = new Date();
	blob.name = "something.tif";
	return blob;
}

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});


export default class FireRiskPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			labels: getLabels(props.day || DAY.TODAY),
			chartKey: 1,
			rasterMap: props.cache.rasterMap || {},
			files: props.cache.files || {},
			config: null,
			originalConfig : getDefaultConfig(props.day || DAY.TODAY),
			errorWeight : false,
			excludedFactors : props.cache.excludedFactors || []
		}
	}
	currentMap = {};

	componentDidMount() {

		FireRiskClient.getConfigList(
			(result) => {
				if (result.length > 0){
					let config = {};
					result.forEach(lvl => {
						const {a, b, weight, descendant: desc} = lvl;
						config[lvl.item] = {a, b, weight, desc};
					});
					if (this.props.day && this.props.day === DAY.TOMORROW){
						delete config.P_LAST_HOUR
					}
					this.setState({
						config: this.props.cache.config || config,
						originalConfig: _.cloneDeep(config),
						chartKey: (this.state.chartKey + 1) % 1000,

					});
				} else {
					ReactSwal.fire('Parametri mancanti','Configurare i parametri nella sezione <i>Allerte e Bollettini / Rischio incendi / Configurazione</i>','error');
				}
			},
			() => {
				console.log("ERROR retrieving fire risk configuration list");
			}
		);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		loadingSwal.close();
	}

	handleUploadNewFile = (e, key) => {
		const {file} = e;
		const {status} = e.meta;

		if (status === "removed") {

		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;

				parse_georaster(fileContent).then(geoRaster => {
						let rasterMap = this.state.rasterMap;
						rasterMap[key] = geoRaster;

						let files = this.state.files;
						files[key] = file;

						this.setState({rasterMap,files})
					}
				)

			};
			reader.onerror = (event) => {
				ReactSwal.fire('Si è verificato un errore nella lettura delle zone', '', 'error');
			};
			reader.readAsArrayBuffer(file);
		}
	}

	handleChangeWeight = (value,key) => {
		let config = this.state.config;
		config[key].weight = value;
		this.checkTotalWeight(config)
	}

	checkTotalWeight = config => {

		let sum = Object.keys(config).filter(k => !this.state.excludedFactors.includes(k)).map(k2 =>config[k2].weight).reduce( (a,b) => a+b);
		sum =  Math.round((sum + Number.EPSILON) * 100) / 100;

		if (sum !== 1 ){
			if (!this.state.errorWeight){
				ReactSwal.fire('La somma dei pesi non può essere diversa da 1', 'Valore attuale: ' + sum, 'warning').then(
					() => this.setState({
						config,
						errorWeight: true
					}))
			} else {
				this.setState({config});
			}
		} else {
			console.log("NO ERROR")
			this.setState({config, errorWeight: false});
		}
	}

	excludeFactor = (key) => {
		let newFiles = this.state.files;
		delete newFiles[key];
		this.setState({
			excludedFactors : [...this.state.excludedFactors,key],
			files : newFiles},() => this.checkTotalWeight(this.state.config))
	}
	includeFactor = (key) => {
		this.setState({excludedFactors : this.state.excludedFactors.filter(f => f !== key)},() => this.checkTotalWeight(this.state.config))
	}

	computeGeoTiff(layer) {
		loadingSwal.fire('<div>Costruzione del raster di <br><i>' + this.state.labels[layer] + '</i><br> in corso...</div>');
		/*
                let params = {}
                params.instant = 0;
                params.category = sensorCategory;

                let valueToInterpolate = "";
                switch(layer){
                    case 'P_LAST_HOUR' :
                    case 'P_PREVIOUS_DAY':
                    case 'P_CURRENT_DAY':
                        valueToInterpolate= getShortName(layer);
                        break;
                    default:
                        valueToInterpolate="value";
                }*/

		if (isLayerPrevisional(this.props.day, layer)){
			this.getPrevisionalTiff(layer)
		} else {
			this.getTiffFromMeasuredData(layer)
		}
	}
	getPrevisionalTiff = layer => {

		let wrfLayer = getWrfLayer(layer);
		let today = moment().tz('Etc/GMT-1');
		let tomorrow = moment().tz('Etc/GMT-1').add(1,'days');
		let day = this.props.day === DAY.TOMORROW && layer.includes('PREVIOUS') ? tomorrow : today;

		let params = {
			start: day.startOf('day').valueOf(),
			end: day.endOf('day').valueOf(),
			function: wrfLayer === 'RAIN' ? 'SUM' : 'AVG',
			layer: wrfLayer
		}

		console.log('>>> PREVISIONAL DATA FOR SENSOR CATEGORY ' + params.layer + ' FROM ' + DateUtils.epochToGMT1String(params.start) + ' TO ' + DateUtils.epochToGMT1String(params.end));

		WrfClient.getTiffFromWrfData(
			(arrayBuffer)=> {

				let {files} = this.state;
				files[layer] = createBlobFromArrayBuffer(arrayBuffer)

				parse_georaster(arrayBuffer).then(georaster =>{
					let rasterMap = this.state.rasterMap;
					rasterMap[layer] = georaster;

					this.setState({
							mapKey: (this.state.mapKey + 1) % 1000,
							rasterMap,
							arrayBuffer,
							files
						}
					)
				})
			},
			(error)=>{
				console.log("Error performing IDW");
				ReactSwal.fire('Errore nella ricostruzione del raster del WRF', '', 'error');
			}, params
		)
	}
	getTiffFromMeasuredData = layer => {
		let yesterday = moment().tz('Etc/GMT-1').add(-1,'days');
		let params = {
			category: getSensorCategory(layer),
			start: yesterday.startOf('day').valueOf(),
			end: yesterday.endOf('day').valueOf()
		};
		if (layer === 'P_LAST_HOUR'){
			let todayLastHour = moment().tz('Etc/GMT-1').add(-1,'hours')
			params.start = todayLastHour.startOf('hour').valueOf();
			params.end = todayLastHour.endOf('hour').valueOf();
		}

		console.log('>>> MEASURED DATA FOR SENSOR CATEGORY ' + params.category + ' FROM ' + DateUtils.epochToGMT1String(params.start) + ' TO ' + DateUtils.epochToGMT1String(params.end));
		FireRiskClient.getTiffFromMeasuredData(
			(arrayBuffer)=> {

				let {files} = this.state;
				files[layer] = createBlobFromArrayBuffer(arrayBuffer)

				parse_georaster(arrayBuffer).then(georaster =>{
					let rasterMap = this.state.rasterMap;
					rasterMap[layer] = georaster;

					this.setState({
							mapKey: (this.state.mapKey + 1) % 1000,
							rasterMap,
							arrayBuffer,
							files
						}
					)
				})
			},
			(error)=>{
				console.log("Error performing IDW");
				ReactSwal.fire('Errore nella ricostruzione del raster dai dati', '', 'error');
			}, params)
	}

	generateFinalMap = () => {

		let data = new FormData();
		let {files, config, excludedFactors, rasterMap, labels} = this.state;
		let finalConfig = {};
		let factors = [];

		// For files created or uploaded by user
		Object.keys(files).forEach(key => {
			data.append(key, files[key]);
			data.append("name", key)
			finalConfig[key] = config[key];
			factors.push({label: labels[key], weight: finalConfig[key].weight})
		});

		// For preset files
		Object.keys(cannotBeChanged).forEach(key => {
			if (!excludedFactors.includes(key)) {
				finalConfig[key] = config[key];
				factors.push({label: labels[key], weight: finalConfig[key].weight});
			}
		});

		console.log("CONFIG", finalConfig)
		data.append("config", JSON.stringify(finalConfig))
		data.append("name", "config")

		loadingSwal.fire('<div>Calcolo del Rischio di Suscettività all\'innesco incendi in corso...</div>');
		IdwClient.sendTifsAndGetFinalRaster((arrayBuffer) => {
				loadingSwal.close();
				let cache = {arrayBuffer, factors, files, config, excludedFactors, rasterMap};
				ReactSwal.fire(
					{
						title: `<h2>Calcolo terminato con successo</h2>`,
						showCancelButton: false,
						confirmButtonText: 'Visualizza mappa',
						type: 'success'
					}).then((isConfirm) => {
					this.props.showFinalMap(cache);
				})
			}, () => {

				loadingSwal.close();
				ReactSwal.fire('Errore nel calcolo del raster finale', '', 'error')
			},
			data
		)
	}
	allFactorsHaveBeenInserted = () => {
		let countNotInserted = this.state.excludedFactors.filter(value => !Object.keys(cannotBeChanged).includes(value)).length;
		let countFiles = Object.keys(this.state.files).length;
		console.log("Esclusi e modificabili", countNotInserted);
		console.log("file caricati", countFiles)
		return countNotInserted + countFiles === this.props.day && this.props.day === DAY.TOMORROW ? 9 : 10;
	};
	addMap = (map, i) => {
		console.log(i, map, this.currentMap);
		this.currentMap[i + ''] = map
	}
	fixMap = (eventKey) => {

		if (this.currentMap && this.currentMap[eventKey]){

			setTimeout(() => {
				this.currentMap[eventKey].leafletElement.invalidateSize();
				let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
				this.currentMap[eventKey].leafletElement.fitBounds([
					[bbox.bbox[1], bbox.bbox[0]],
					[bbox.bbox[3], bbox.bbox[2]]
				]);
			}, 300)
		}
	}

	render() {

		const {config, originalConfig, excludedFactors, errorWeight, rasterMap, mapKey, labels} = this.state;
		let weighErrorCol = errorWeight ? {backgroundColor: 'rgba(180,27,27,0.4)'} : {};
		if (!!config){
			return (
				<>
					<div className="row justify-content-center">
						<h2>Mappa di suscettività al rischio incendi</h2>
					</div>
					<div className="justify-content-center mx-auto" style={{width: '80vw', paddingBottom: '12vh'}}>
						<Accordion defaultActiveKey={'0'}>
							{Object.keys(labels).map((key, i) =>
								<Card>
									<Card.Header>
										<ContextAwareToggle callback={() => this.fixMap(i + '')} eventKey={i + ''}  >
											<Accordion.Toggle as={Card.Header} variant="link" eventKey={i + ''} >
												<div className="d-flex justify-content-between">
													<h3 style={{color: excludedFactors.includes(key) && 'grey'}}>{labels[key]}</h3>
													{!excludedFactors.includes(key) ?
														<Tooltip title="Escludi questo criterio dal calcolo">
															<Button color="secondary" onClick={(e) => {
																e.stopPropagation();
																this.excludeFactor(key)
															}}>
																<DeleteSweep fontSize="large"/>
															</Button>
														</Tooltip> :
														<Tooltip title="Includi questo criterio dal calcolo">
															<Button color="primary" onClick={(e) => {
																e.stopPropagation();
																this.includeFactor(key)
															}}>
																<AddIcon fontSize="large"/>
															</Button>
														</Tooltip>}
												</div>
											</Accordion.Toggle>
										</ContextAwareToggle>
									</Card.Header>
									<Accordion.Collapse eventKey={i + ''}>
										{!excludedFactors.includes(key) ? <Card.Body>
											<div className="row mx-4">

												<div className="col-3 " style={{textAlign: 'center'}}>
													{!!!cannotBeChanged[key] && !excludedFactors.includes(key) ?
														<>
															<Dropzone
																onChangeStatus={(e) => this.handleUploadNewFile(e, key)}
																accept=".tif"
																maxFiles={1}
																inputContent="Inserisci il file raster"
																styles={{
																	dropzone: {overflow: "hidden", width: "90%"},
																	dropzoneReject: {
																		borderColor: 'red',
																		backgroundColor: '#DAA'
																	},
																	inputLabel: (files, extra) => (extra.reject ? {color: 'red'} : {color: "#315495"}),
																}}
															/>
															{!!!mustBeProvided[key] ?
																<>
																	<div className="mt-1 mb-1">Oppure</div>
																	<ComputeGeoTiffButton
																		clickCallback={() => this.computeGeoTiff(key)}/>
																</> :
																<></>}
														</> :
														<></>}
												</div>
												<div className="col-1">
													{!excludedFactors.includes(key) ? <h3>{ranges[key]}</h3> : <></>}
												</div>
												<div className="col-3">
													{!excludedFactors.includes(key) ?
														<ReactApexChart
															key={"h_" + this.state.chartKey}
															options={getOptions(config[key])}
															series={getSeries(config[key].desc)}
															type="line" height={"90%"}
														/> :
														<></>}
												</div>
												<div className="col-1" style={weighErrorCol}>
													{!excludedFactors.includes(key) ?
														<>
															<NumericInput min={0} max={1} step={0.05} precision={2}
																		  value={config[key].weight}
																		  onChange={(e) => this.handleChangeWeight(e, key)}/>

															{config[key].weight !== originalConfig[key].weight ?
																<h3>Valore di default: {originalConfig[key].weight}</h3> :
																<></>}
														</> :
														<></>}
												</div>
												<div className="col-4" style={{height: "50vh"}}>
													{(!!rasterMap[key] || !!cannotBeChanged[key]) && !excludedFactors.includes(key) ?
														<ComplexMapElement key={i} factor={key} rasterMap={rasterMap} day={this.props.day}
																		   passMapToFix={map => this.addMap(map, i)}
																		   mapKey={mapKey}/> : <></>}
												</div>
											</div>
										</Card.Body> : <></>}
									</Accordion.Collapse>
								</Card>)}
						</Accordion>
					</div>

					<div style={{position: "fixed", bottom: "5vh", right: "3vw", zIndex: 9999}}>
						<Fab variant="extended" color="primary" aria-label="add"
							 onClick={this.generateFinalMap}
							 disabled={!this.allFactorsHaveBeenInserted() || errorWeight }>
							Genera la mappa di suscettività al rischio incendi
							<i className="ml-2 fas fa-chevron-right"></i>
							<i className="fas fa-chevron-right"></i>
						</Fab>
					</div>
				</>
			)
		} else return <></>
	}
}

const ContextAwareToggle = ({ children, eventKey, callback }) => {
	const currentEventKey = useContext(AccordionContext);
	const isCurrentEventKey = currentEventKey === eventKey;

	const fixMap = () => {
		if(isCurrentEventKey && callback) {
			callback()
		}
	}
	return (<div>
			{fixMap()}
			{children}
		</div>
	);
}
class ComplexMapElement extends React.Component {

	componentDidMount() {
		if (this.props.passMapToFix && this.mapRef){
			this.props.passMapToFix(this.mapRef);
		}
	}

	render() {
		const {factor,mapKey,rasterMap, day = DAY.TODAY} = this.props;
		const shortName = getShortName(factor) === 'T' ? 'T' :(isLayerPrevisional(day, factor) ? getWrfLayer(factor) : getShortName(factor));
		console.log("Factor is " + shortName)
		return (
			<MapComponent
				width={"90%"}
				height={"90%"}
				zoomControl={false}
				zoomSnap={false}
				scrollWheelZoom={false }
				dragging={ false }
				doubleClickZoom = {false}
				attributionControl={false}
				tile={null}
				noHome={true}
				setMapRef={mapRef => this.mapRef = mapRef}>
				{!!rasterMap[factor] ? <GeotiffRasterLayer
					key={"raster_" + factor + "_" + mapKey}
					georaster={rasterMap[factor]}
					opacity={0.5}
					resolution={1024}
					colorScale={shortName ? ChromaticScale.getScaleBySensorCategory(shortName) : ChromaticScale.getScale('T')}
					handleClick={(val, latlng) => GISTools.showPopup(val, latlng, this.mapRef.leafletElement, factor)}
				/> : <></>}
				{!!cannotBeChanged[factor] ?
					<ImageOverlay
						url={properties.url.imageUrl + factor + ".png"}
						bounds = {[[37.9146819294039190,15.6295450390794617], [40.1436819294039182,17.2065450390794616]]}
						opacity = {0.5}
						options = {{
							opacity : 0.5
						}}
					/> : <></>}

				<GeoJSON
					key={"outer_" + factor + "_" + mapKey}
					data={GISTools.getOuter()}
					style={{
						fillColor: "#fff",
						fillOpacity: 1,
						weight: 2,
						opacity: 1,
						color: "green",
					}}
				/>
			</MapComponent>
		)
	}
}
