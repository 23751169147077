import React from "react";
import { withRouter } from 'react-router';

import {properties} from "#/properties.js";

import {DEFAULT_FORM_JSON_DATA, DEFAULT_STEPS,} from "#/backoffice/reportPostEvento/utils/RpeUtils";
import BulletinClient from "#/lib/BulletinClient";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {BackButton} from "#/commons/components/forms/BackButton";
import DateUtils from "#/lib/DateUtils";


const ReactSwal = withReactContent(Swal);

class ReportPostEventoEditorPage extends React.Component {
  steps = undefined;
  totalSteps = 0;

  constructor(props) {
    super(props);
    this.steps = props.viewMode ? DEFAULT_STEPS.VIEW : DEFAULT_STEPS.EDIT;
    this.totalSteps = this.steps.length;
    const url = window.location.href;
    const idString = url.split("/").pop();
    const id = parseInt(idString);


    let maps = {};
    let formJsonData = DEFAULT_FORM_JSON_DATA;


    this.state = {
      id: !!id ? id : null,
      bulletinTypeName: "RPE",
      approved: false,
      published: false,
      viewMode: props.viewMode,
      activeStep: 0,
      maps: maps,
      formJsonData: formJsonData,
      selectedFile: null,
    };
  }

  componentDidMount() {
    if (!!this.state.id) {
      this.getBulletinById(this.state.id);
    } else {
      this.setState({ allowCreationToday: true });
    }
  }

  goToListPage = () => {
    this.props.history.push("/backoffice/postevento");
  }

  getBulletinById = (id) => {
    if (!!id) {
      BulletinClient.getBulletin(
          (result) => {
            this.setState({
              formJsonData: result.jsonData,
              published: result.published,
              allowCreationToday: true,
              selectedFile: result.jsonData.selectedFile,
              selectedFileName: result.jsonData.selectedFileName,
              startDate: DateUtils.fromISO8601ToDate(result.jsonData.startDate),
              endDate: DateUtils.fromISO8601ToDate(result.jsonData.endDate),
            });


            if (result.jsonData.selectedFile) {
              // rebuild file
              fetch(result.jsonData.selectedFile)
                  .then(res => res.blob())
                  .then(blob => {
                    const file = new File([blob], this.state.selectedFileName ,{ type: "application/pdf" });
                    this.setState({
                      initialFile: file
                    });

                  });

            }

          },
          (msg) => {
            console.log("msg: ", msg);
          },
          {
            id: this.state.id,
            bulletinTypeName: this.state.bulletinTypeName,
          }
      );
    }
  }

  isCreationAllowedToday = (trueCallback, falseCallback) => {
    BulletinClient.isCreationAllowedToday(
        (data) => {
          this.setState({ allowCreationToday: true }, trueCallback);
        },
        (error) => {
          falseCallback();
        },
        this.state.bulletinTypeName
    );
  };




  send = () => {
    let jsonData = this.state.formJsonData;
    jsonData.selectedFile = this.state.selectedFile;
    jsonData.selectedFileName = this.state.selectedFileName;
    jsonData.startDate = this.state.startDate;
    jsonData.endDate = this.state.endDate;
    const prevActiveStep = this.state.activeStep;
    const nextStep = prevActiveStep + 1;
    let params = {
      bulletinId: this.state.id,
      documentTypeName: this.state.bulletinTypeName,
      jsonData: JSON.stringify(jsonData),
      trigger: 'NEW'
    };
    BulletinClient.saveBulletin(
        (result) => {
          this.setState({
            id: result.id,
            saveStatus: " Report Post Evento archiviato con successo.",
            activeStep: nextStep,
          });
        },
        (msg) => {
          this.setState({
            saveStatus:
                "Si è verificato un errore durante l'archiviazione del Report Post Evento",
            activeStep: nextStep,
          });
        },
        params
    );
  };


  handleDropzoneChangeStatus = ({ file }, status) => {
    if (status === "removed") {
      this.setState({
        selectedFile : null
      });
    }
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target.result;
        this.setState({
          selectedFileName: file.name,
          selectedFile : fileContent
        });

      };
      reader.onerror = (event) => {
        console.log("Error");
      };
      reader.readAsDataURL(file);
    }
  };


  handleNext = () => {
    const prevActiveStep = this.state.activeStep;
    const nextStep = prevActiveStep + 1;
    if (nextStep === this.totalSteps) {
      this.send();
    } else {
      this.setState({ activeStep: nextStep });
    }
  };

  handleBack = () => {
    const prevActiveStep = this.state.activeStep;
    this.setState({
      activeStep: prevActiveStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      saveStatus: "",
    });
  };

  handleStartDate = (date) => {
    this.setState({
      startDate: date
    });
  };

  handleEndDate = (date) => {
    this.setState({
      endDate: date
    });
  };



  getStepContentComponent = (stepIndex) => {

    let commonsProps = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      selectedFile: this.state.selectedFile,
      initialFile: this.state.initialFile,
      selectedFileName: this.state.selectedFileName,
      viewMode: this.state.viewMode,
      stepLabel: this.steps[stepIndex].label,
      totalSteps: this.totalSteps,
      activeStep: stepIndex,
      reactSwal: ReactSwal,
      formData: this.state.formJsonData,
      disableBackButton: !stepIndex,
      textNextButton: "Successivo",
      handleNext: this.handleNext,
      handleBack: this.handleBack,
      published: this.state.published,
      handleDropzoneChangeStatus: this.handleDropzoneChangeStatus,
      handleStartDate: this.handleStartDate,
      handleEndDate: this.handleEndDate,

    };
    let comp = this.steps[stepIndex].component(commonsProps);
    return comp;
  };

  render() {
    if (this.state.allowCreationToday === undefined) {
      return <></>;
    } else {
      let downloadLink = !!this.state.id
          ? properties.url.bulletinDownloadFile + "/" + this.state.id + "/pdf"
          : null;
      return (
          <div className="generic-page container-fluid data-page">
            <div>
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {this.steps.map((step) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
              </Stepper>
              <div>
                {this.state.activeStep === this.totalSteps ? (
                    <>
                      <div className="mt-2 row justify-content-center">
                        <h3>
                          {this.state.saveStatus}
                          <br />
                          <a href={downloadLink}>Scarica file</a>
                        </h3>
                      </div>
                      <BackButton
                          variant="contained"
                          textbutton="Torna alla prima pagina"
                          onClick={this.handleReset}
                      />
                    </>
                ) : (
                    <div>
                      {!!this.state.maps &&
                      !!this.state.formJsonData &&
                      this.getStepContentComponent(this.state.activeStep)}
                    </div>
                )}
              </div>
            </div>
          </div>
      );
    }
  }
}

export default withRouter(ReportPostEventoEditorPage)

