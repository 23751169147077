
import React from "react";

import DateUtils from "#/lib/DateUtils";
/** STEPS **/
import {StepFasiOperative} from "#/backoffice/mau/steps/StepFasiOperative";
import {StepPrevisioniMeteoToday} from "#/backoffice/mau/steps/StepPrevisioniMeteoToday";
import {StepPrevisioniMeteoTomorrow} from "#/backoffice/mau/steps/StepPrevisioniMeteoTomorrow";
import {StepCriticitaIdroToday} from "#/backoffice/mau/steps/StepCriticitaIdroToday";
import {StepCriticitaIdroTomorrow} from "#/backoffice/mau/steps/StepCriticitaIdroTomorrow";
import {StepRecipients} from "#/backoffice/mau/steps/StepRecipients";
import moment from 'moment';

const today = new Date().getTime();
const tomorrow = today + 24 * 60 * 60 * 1000;

export const DEFAULT_STEPS = {
	EDIT: [
		/*{
			label: "Caricamento GeoJson",
			component: function (props) { return <StepUploadGeoJson {...props} /> }
		},*/
		{
			label: "Previsione meteo marina-costiera OGGI",
			component: function (props) { return <StepPrevisioniMeteoToday {...props} /> }
		},
		{
			label: "Previsione meteo marina-costiera DOMANI",
			component: function (props) { return <StepPrevisioniMeteoTomorrow {...props} /> }
		},
		{
			label: "Criticità idrogeologica-idraulica OGGI",
			component: function (props) { return <StepCriticitaIdroToday {...props} /> }
		},
		{
			label: "Criticità idrogeologica-idraulica DOMANI",
			component: function (props) { return <StepCriticitaIdroTomorrow {...props} /> }
		},
		{
			label: "Fasi operative comunali minime da adottare",
			component: function (props) { return <StepFasiOperative {...props} /> }
		},
		{
			label: "Destinatari",
			component: function (props) { return <StepRecipients {...props} /> }
		}
	],
	VIEW: [
		{
			label: "Fasi operative comunali minime da adottare",
			component: function (props) { return <StepFasiOperative {...props} /> }
		},
		{
			label: "Previsione meteo marina-costiera OGGI",
			component: function (props) { return <StepPrevisioniMeteoToday {...props} /> }
		},
		{
			label: "Previsione meteo marina-costiera DOMANI",
			component: function (props) { return <StepPrevisioniMeteoTomorrow {...props} /> }
		},
		{
			label: "Criticità idrogeologica-idraulica OGGI",
			component: function (props) { return <StepCriticitaIdroToday {...props} /> }
		},
		{
			label: "Criticità idrogeologica-idraulica DOMANI",
			component: function (props) { return <StepCriticitaIdroTomorrow {...props} /> }
		},
		{
			label: "Destinatari",
			component: function (props) { return <StepRecipients {...props} /> }
		}
	]
}


export const DEFAULT_MAPS = [
	
	{
		title: "Oggi",
		labelForm: " Oggi ",
		mapKey: 0,
		mapId: "today",
		zones: [],
		shapesGeoJSON: null,
	},
	{
		title: "Domani",
		labelForm: " Domani ",
		mapKey: 1,
		mapId: "tomorrow",
		zones: [],
		shapesGeoJSON: null,
	},
	{
		title: "empty",
		labelForm: " empty ",
		mapKey: 2,
		mapId: "empty",
		zones: [],
		shapesGeoJSON: null,
	},
];

export const DEFAULT_INSTITUTIONS = [
	"Presidenza del consiglio dei Ministri - Dipartimento della Protezione Civile - Sala Italia",
	"Presidenza del consiglio dei Ministri - Dipartimento della Protezione Civile - Centro Funzionale Centrale",
	"Uffici Provinciali della UOA della Protezione Civile regionale",
	"Prefetture – UTG",
	"Province / Città metropolitana",
	"SUEM 118",
	"Croce Rossa Italiana",
	"Consorzi di Bonifica",
	"Calabria Verde",
	"Terna",
	"ENEL",
	"Telecom",
	"a2a",
	"Endesa",
	"Italgas",
	"SNAM",
	"ENI",
	"Rete Ferroviaria Italiana",
	"Registro Italiano Dighe",
	"Ferrovie della Calabria",
	"Compartimento viabilità ANAS (Viabilità ordinaria e Compartimento autostrade)",
	"Sorical",
	"Società di gestione delle Grandi Dighe",
	"Centri Funzionali Decentrati delle Regioni limitrofe",
	"Associazioni di volontariato e gruppi comunali di protezione civile",
];
const getNowStartTime = () => {
	const now = moment();

	// Round the minutes up to the next 10
	const roundedMinutes = Math.ceil(now.minutes() / 10) * 10;

	// Set the rounded minutes back to the moment object
	now.minutes(roundedMinutes).seconds(0); // Set seconds to 0 for exact rounding

	// Format to "HH:mm"
	return now.format("HH:mm");
}

export let DEFAULT_FORM_JSON_DATA = (hourStart) => { return {
	geoJson: "",
	img_empty: "",
	start: {
		date_short: DateUtils.epochToLocaleDateFormatted(today),
		hour_start: hourStart,
		hour_end: "24:00",
		fasi_op: {
			zones: [],
		},
		previsione: {
			avers: "",
			zones: [],
			pmmc_desc: "",
			avviso: false
		},
		criticita: {
			avviso: false,
			img: "",
			zones: [],
			scenario: [],
		},
	},
	end: {
		date_short: DateUtils.epochToLocaleDateFormatted(tomorrow),
		hour_start: "00:00",
		hour_end: "24:00",
		fasi_op: {
			zones: [],
		},
		previsione: {
			avers: "",
			zones: [],
			pmmc_desc: "",
			avviso: false
		},
		criticita: {
			avviso: false,
			img: "",
			zones: [],
			scenario: [],
		},
	},
	address_to: []
}};

export const ZONES_DESC = [
	"",
	"Tirreno Settentrionale",
	"Tirreno Centro-Settentrionale",
	"Tirreno Centro-Meridionale",
	"Tirreno Meridionale",
	"Ionio Settentrionale",
	"Ionio Centro-Settentrionale",
	"Ionio Centro-Meridionale",
	"Ionio Meridionale",
];

export const PRECIPITAZIONI_IMPULSIVE_OPTIONS = [
	{
		label: "",
		value: "",
	},
	{
		label: "Rovesci o Temporali Isolati",
		value: "rt_isolati",
		color: 'yellow'
	},
	{
		label: "Temporali isolati",
		value: "t_isolati",
		color: 'orange'
	},
	{
		label: "Temporali sparsi",
		value: "t_sparsi",
		color: 'red'
	},
	{
		label: "Temporali diffusi",
		value: "t_diffusi",
		color: '#4a0505'
	},
];

export const FASI_OPERATIVE_COMUNALI_OPTIONS = [
	{
		value: "BASE",
		color: 'green'
	},
	{
		value: "ATTENZIONE",
		color: 'yellow'
	},
	{
		value: "PREALLARME",
		color: 'orange'
	},
	{
		value: "ALLARME",
		color: 'red'
	}
];

export const PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS = [
	{
		label: "",
		value: "",
	},
	{
		label: "Piogge Isolate",
		value: "p_isolate",
		color: 'yellow'
	},
	{
		label: "Piogge Sparse",
		value: "p_sparse",
		color: 'orange'
	},
	{
		label: "Piogge diffuse",
		value: "p_diffuse",
		color: 'red'
	},
];

export const PRECIPITAZIONI_ICONS = {
	p_isolate : '/img/mauIcons/water-drop_512px.png',
	p_sparse : '/img/mauIcons/2_drops_512px.png',
	p_diffuse : '/img/mauIcons/3_drops_512px.png',
	rt_isolati : '/img/mauIcons/bolt_emprty_triangle_512px.png',
	t_isolati : '/img/mauIcons/bolt_solid_triangle_512px.png',
	t_sparsi : '/img/mauIcons/bolt_512px.png',
	t_diffusi : '/img/mauIcons/2_bolts_512px.png',
	wind: '/img/mauIcons/windStrong_512px.png',
	frost: '/img/mauIcons/thermometer_512px.png',
	storm_surges: '/img/mauIcons/big_wave_512px.png',
	no_wind: '/img/mauIcons/absence_windStrong_512px.png',
	no_frost: '/img/mauIcons/absence_thermometer_512px.png',
	no_storm_surges: '/img/mauIcons/absence_big_wave_512px.png',
}

export const getWeightByColor = (color) => {
	return ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.label === color).weight || 0;
}

export const ALLERTAMENTO_IDRO_DEFINITIONS = [
	{
		label: "VERDE",
		value: "VERDE",
		color: "rgb(0, 255, 0)",
		weight: 0,
		scenario:
			"APPENDICE 4: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello VERDE;",
	},
	{
		label: "GIALLO",
		value: "GIALLO",
		color: "rgb(255, 255, 0)",
		weight: 1,
		scenario:
			"APPENDICE 5: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello GIALLO;",
	},
	{
		label: "ARANCIONE",
		value: "ARANCIONE",
		color: "rgb(255,130,0)",
		weight: 2,
		scenario:
			"APPENDICE 6: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello ARANCIONE;",
	},
	{
		label: "ROSSO",
		value: "ROSSO",
		color: 'rgb(255, 0, 0)',
		weight: 3,
		scenario:
			"APPENDICE 7: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello ROSSO;",
	},
];

export const CRITICALITY_LAYER_LABELS = {
	idro_temp: 'Criticità idrogeologica per temporali',
	idro_idra: 'Criticità idrogeologica idraulica',
}
export const UNGROUPED_LAYER_LABELS = {
	fasi_op: 'Fasi operative comunali',
	rainfall_pni: 'Precipitazioni',
	rainfall_pi: 'Temporali'
}
export const LEVELED_LAYER_LABELS = {
	...CRITICALITY_LAYER_LABELS,
	...UNGROUPED_LAYER_LABELS
}
export const BOOLEAN_LAYER_LABELS = {
	storm_surges: 'Mareggiate',
	wind: 'Venti',
	frost: 'Temperature estreme'
}
export const MAP_LAYER_LABELS = {
	general:'Criticità Generale',
	...LEVELED_LAYER_LABELS,
	...BOOLEAN_LAYER_LABELS
}
