import {properties} from '#/properties.js';

import APIUtils from '#/lib/APIUtils';

export default class MaxValuesClient {
    



    static getMaxRainValues(okCallback, errCallback, stationCode){
        let url = `${properties.url.maxRainValuesByStationCode}/${stationCode}`;
        APIUtils.getFetchResponse(url, null, okCallback, errCallback);
    }

	static getMaxRainValuesUntil(okCallback, errCallback, stationCode, year){
        let url = `${properties.url.maxRainValuesByStationCode}/${stationCode}/${year}`;
        APIUtils.getFetchResponse(url, null, okCallback, errCallback);
    }

    static computeMaxRainValues(okCallback, errCallback, params) {
        let url = properties.url.computeRainValues;
        let fetchOptions = {
            headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
            method: 'POST',
            body: JSON.stringify(params)
        };
        APIUtils.getFetchResponse(url, fetchOptions, okCallback, errCallback);
	}
	
    static saveMaxRainValues(okCallback, errCallback, params) {
        let url = properties.url.saveRainValues;
		let fetchOptions = {
            headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
            method: 'POST',
            body: JSON.stringify(params)
        };
        APIUtils.getFetchResponse(url, fetchOptions, okCallback, errCallback);
    }

  }
