import React from 'react';
import {
    FiBatteryCharging,
    FiCloudRain,
    FiDroplet,
    FiHash,
    FiNavigation,
    FiSunrise,
    FiThermometer,
    FiWind
} from "react-icons/fi";
import {IoMdSnow} from "react-icons/io";
import {WiBarometer} from "react-icons/wi";
import {CgFormatSeparator} from "react-icons/cg";


export let COMBINED_TO_STANDARD =  {
	WV : "DV",
	WS : "DS",
	WR : "DR"
}
export let STANDARD_TO_COMBINED =  {
	DV : "WV",
	DS : "WS",
	DR : "WR",
	VV : "WV",
	VS : "WS",
	VR : "WR"
}
export const COMBINED_MEASUREMENT_CATEGORY = {
	// MISURE COMBINATE
	WV: 	"WV", 		// Vento (Combinato) Vettoriale
	WS: 	"WS", 		// Vento (Combinato) Scalare
	WR: 	"WR",		// Vento (Combinato) Raffiche
}

export const COMBO_TYPE_BY_CATEGORY = {
	WS: 'SCALARE',
	WV: 'VETTORIALE',
	WR: 'RAFFICA'
}

export const COMBO_TYPES = Object.keys(COMBO_TYPE_BY_CATEGORY);
export let MEASUREMENT_CATEGORY = {
	T: 		"T",		//	Temperatura aria
	IG: 	"IG",		//	Umidità
	P: 		"P",		//	Precipitazione
	B: 		"B",		//	Pressione
	R: 		"R",		//	Radiazione solare
	DV: 	"DV",		//	Dir vento vet
	DS: 	"DS",		//	Dir vento sca
	DR: 	"DR",		//	Dir vento raf
	VV: 	"VV",		//	Vel vento vet
	VS: 	"VS",		//	Vel vento sca
	VR: 	"VR",		//	Vel vento raf
	N: 		"N",		//	Nivometro
	I: 		"I",		//	Idrometro
	RETE: 	"RETE",		//	On/Off
	BA: 	"BA",		//	BatterY
	LF: 	"LF",		//	Livello falda
	IG30: 	"IG30",		//	Umidità suolo -30
	IG60: 	"IG60",		//	Umidità suolo -60
	IG90: 	"IG90",		//	Umidità suolo -90
	DISD: 	"DISD",		//	Disdrometri
	WAVE: 	"WAVE",		//	Boe ondametriche
	SEA: 	"SEA",		//	mareografi
	TC: 	"TC",		//	termometro compensazione
	...COMBINED_MEASUREMENT_CATEGORY

}

export const UNIT_BY_CATEGORY = {
	EV: 'm',
	P: 'mm',
	T: '°C',
	VR: 'm/s',
	VV: 'm/s',
	VS: 'm/s',
	DR: '°',
	DS: '°',
	DV: '°',
	I: 'm',
	N: 'cm',
	B: 'hPa',
	RUVE: 'mW/mq',
	R: 'W/mq',
	IG: '%',
	IGS: '% vol',
	F: 'm',
	MR: 'si/no',
	SSP: '',
	BA: 'V',
	C: '°',
	CC: 'A',
	INC: '°',
	IP: 'mm/h',
	PR: 'm',
	TI: '°C',
	TC: '°C',
}

export const getUnitByCategory = category => !!UNIT_BY_CATEGORY[category] ? UNIT_BY_CATEGORY[category] : '';


const COMBINED_MEASUREMENT_CATEGORY_SORT = {
	// MISURE COMBINATE
	WV: 	32, 		// Vento (Combinato) Vettoriale
	WS: 	34, 		// Vento (Combinato) Scalare
	WR: 	36,		// Vento (Combinato) Raffiche
}
export const MEASUREMENT_CATEGORY_SORT = {
	P: 		10,		//	Precipitazione		
	T: 		20,		//	Temperatura aria	
	B: 		30,		//	Pressione			
	...COMBINED_MEASUREMENT_CATEGORY_SORT,
	IG: 	40,		//	Umidità
	I: 		50,		//	Idrometro
	R: 		60,		//	Radiazione solare
	N: 		70,		//	Nivometro
	DV: 	80,		//	Dir vento vet
	VV: 	90,		//	Vel vento vet
	DS: 	100,		//	Dir vento sca
	VS: 	110,		//	Vel vento sca
	DR: 	120,		//	Dir vento raf
	VR: 	130,		//	Vel vento raf
	IG30: 	140,		//	Umidità suolo -30
	IG60: 	150,		//	Umidità suolo -60
	IG90: 	160,		//	Umidità suolo -90
	RETE: 	170,		//	On/Off
	BA: 	180,		//	BatterY
	LF: 	190,		//	Livello falda
	DISD: 	200,		//	Disdrometri
	WAVE: 	210,		//	Boe ondametriche
	SEA: 	220,		//	mareografi
	TC: 	230,		//	mareografi

}

export const getSortOrderByCategory = category => !!MEASUREMENT_CATEGORY_SORT[category] ? MEASUREMENT_CATEGORY_SORT[category] : 999

const COMBINED_MEASUREMENT_CATEGORY_ICONS = {
	// MISURE COMBINATE
	WV: 	<><FiNavigation  className="mr-2"></FiNavigation><FiWind className="mr-2"></FiWind></>, 		// Vento (Combinato) Vettoriale
	WS: 	<><FiNavigation  className="mr-2"></FiNavigation><FiWind className="mr-2"></FiWind></>, 		// Vento (Combinato) Scalare
	WR: 	<><FiNavigation  className="mr-2"></FiNavigation><FiWind className="mr-2"></FiWind></>,		// Vento (Combinato) Raffiche
}
export let MEASUREMENT_CATEGORY_ICONS = {
	T: 		<FiThermometer className="mr-2"></FiThermometer>,		//	Temperatura aria	
	IG:		<FiDroplet className="mr-2"></FiDroplet>,		//	Umidità				
	P: 		<FiCloudRain className="mr-2"></FiCloudRain>,		//	Precipitazione		
	B: 		<WiBarometer className="mr-2"></WiBarometer>,		//	Pressione			
	R: 		<FiSunrise style={{transform: "rotate(180deg)"}} className="mr-2"></FiSunrise>,		//	Radiazione solare	
	DV: 	<FiNavigation className="mr-2"></FiNavigation>,		//	Dir vento vet		
	DS: 	<FiNavigation className="mr-2"></FiNavigation>,		//	Dir vento sca		
	DR: 	<FiNavigation className="mr-2"></FiNavigation>,		//	Dir vento raf		
	VV: 	<FiWind className="mr-2"></FiWind>,		//	Vel vento vet		
	VS: 	<FiWind className="mr-2"></FiWind>,		//	Vel vento sca		
	VR: 	<FiWind className="mr-2"></FiWind>,		//	Vel vento raf		
	N: 		<IoMdSnow className="mr-2"></IoMdSnow>,		//	Nivometro			
	I: 		<CgFormatSeparator style={{transform: "rotate(180deg)"}} className="mr-2"></CgFormatSeparator>,		//	Idrometro			
	RETE: 	<FiHash className="mr-2"></FiHash>,		//	On/Off				
	BA: 	<FiBatteryCharging className="mr-2"></FiBatteryCharging>,	//	BatterY				
	LF: 	<FiHash className="mr-2"></FiHash>,		//	Livello falda		
	IGS: 	<FiDroplet className="mr-2"></FiDroplet>,		
	IG30: 	<FiDroplet className="mr-2"></FiDroplet>,	//	Umidità suolo -30	
	IG60: 	<FiDroplet className="mr-2"></FiDroplet>,		//	Umidità suolo -60	
	IG90: 	<FiDroplet className="mr-2"></FiDroplet>,		//	Umidità suolo -90	
	DISD: 	<FiHash className="mr-2"></FiHash>,		//	Disdrometri			
	WAVE: 	<FiHash className="mr-2"></FiHash>,		//	Boe ondametriche	
	SEA: 	<FiHash className="mr-2"></FiHash>,		//	mareografi	
	TC: 	<FiHash className="mr-2"></FiHash>,		//	mareografi		

	...COMBINED_MEASUREMENT_CATEGORY_ICONS
}

export const COMBINED_MEASUREMENT_LABELS = {
	// MISURE COMBINATE
	WV: "Vento vettoriale", 		// Vento (Combinato) Vettoriale
	WS: "Vento scalare", 		// Vento (Combinato) Scalare
	WR: "Vento raffiche",		// Vento (Combinato) Raffiche
}
export const MIDA_MEASUREMENT_LABELS = {
	CC:	"Corrente cella",
	SSP: "Stato Sp200",
	P:	"Pluviometro",
	TC:	"Termometro compensazione",
	/*R:	"Radiometro diretta",*/
	PR:	"Profondimetro",
	T:	"Termometro",
	/*C:	"Inclinometro",*/
	BA:	"Batteria",
	IG:	"Igrometro",
	F:	"Freatimetro",
	IGS:	"Igrometro Suolo",
	IP:	"Intensita' di pioggia",
	C:	"Clinometro",
	N:	"Nivometro",
	MR:	"Mancanza rete",
	R:	"Radiometro UV-E",
	TI:	"Temperatura interna",
	I:	"Idrometro",
	B:	"Barometro"
}
const WIND_MEASUREMENT_LABELS = {
	VS:	"Velocita' vento scalare",
	DS:	"Direzione vento scalare",
	VR:	"Velocita' vento raffica",
	DR:	"Direzione vento raffica",
	VV:	"Velocita' vento vett.",
	DV:	"Direzione vento vett."
}
export const MEASUREMENT_LABELS = {
	...MIDA_MEASUREMENT_LABELS,
	...WIND_MEASUREMENT_LABELS
}

const defaultMeasurement = {
	value: null,
	label: <>&nbsp;</>
}
const getAllCategoriesOptions = () => {
	let data = Object.keys(MEASUREMENT_LABELS)
		.map(cat => ({
			category: cat,
			label: MEASUREMENT_LABELS[cat],
			code: -9999
		})).sort((a,b)=>{
			let aSortValue = MEASUREMENT_CATEGORY_SORT[a.category] || 999999999;
			let bSortValue = MEASUREMENT_CATEGORY_SORT[b.category] || 999999999;
			return aSortValue - bSortValue;
		});

	let availableMeasurement = [];
	availableMeasurement.push(defaultMeasurement);

	data.forEach((value, index) => {
		let measurement = {};
		measurement.value = value.category;
		measurement.label = value.label;
		measurement.icon = MEASUREMENT_CATEGORY_ICONS[value.category];
		measurement.sensor = [{
			category: value.category,
			sensorCode: value.code,
		}];
		measurement.group = "SENSOR";
		measurement.groupLabel = "Misure sensori";

		availableMeasurement.push(measurement);
	})
	if (!!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DV)
		&& !!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VV)
	) {
		let measurement = {};
		measurement.value = MEASUREMENT_CATEGORY.WV;
		measurement.label = "Vento Vettoriale";
		measurement.icon = MEASUREMENT_CATEGORY_ICONS[measurement.value];
		measurement.group = "WIND_COMBO";
		measurement.groupLabel = "Misure combinate";
		measurement.sensor = [availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DV).sensor[0], availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VV).sensor[0]];

		availableMeasurement.push(measurement);
	}

	if (!!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DS) && !!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VS)) {
		let measurement = {};
		measurement.value = MEASUREMENT_CATEGORY.WS;
		measurement.label = "Vento Scalare";
		measurement.icon = MEASUREMENT_CATEGORY_ICONS[measurement.value];
		measurement.group = "WIND_COMBO";
		measurement.groupLabel = "Misure combinate";
		measurement.sensor = [availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DS).sensor[0], availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VS).sensor[0]];
		availableMeasurement.push(measurement);
	}

	if (!!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DR) && !!availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VR)) {
		let measurement = {};
		measurement.value = MEASUREMENT_CATEGORY.WR;
		measurement.label = "Vento Raffiche";
		measurement.icon = MEASUREMENT_CATEGORY_ICONS[measurement.value];
		measurement.group = "WIND_COMBO";
		measurement.groupLabel = "Misure combinate";
		measurement.sensor = [availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.DR).sensor[0], availableMeasurement.find(element => element.value === MEASUREMENT_CATEGORY.VR).sensor[0]];
		availableMeasurement.push(measurement);
	}
	return availableMeasurement;
}

const excludeFromMida = ['VV','VS','VR','DV','DS','DR','SSP'];
export const EXCLUDE_FROM_INTERPOLATION = ['I','N','BA','TC','TI','MR','C','IP','IGS','F','PR','CC','P','WV','WS','WR','R','RUVE'];
export const TO_INTERPOLATE = ['IG', 'T', 'P1H', 'P3H', 'P6H', 'P12H', 'P24H', 'P_DAILY', 'P_ESTIMATED_1H']
const excludeFromMidaLight = [...excludeFromMida,'BA','TC','TI','MR','C','IP','IGS','F','PR','CC'];
export const ALL_CATEGORIES_OPTIONS = getAllCategoriesOptions()
export const MIDA_CATEGORIES_OPTIONS = getAllCategoriesOptions().filter(opt => !excludeFromMida.includes(opt.value)).filter(o => !!o.value);
export const MIDA_LIGHT_CATEGORIES_OPTIONS = getAllCategoriesOptions().filter(opt => !excludeFromMidaLight.includes(opt.value)).filter(o => !!o.value);
