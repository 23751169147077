
export default class GumbelCalculator {


	static getFreqByH(alfa, epsilon, heigth) {
		let f = Math.exp(-Math.exp(-alfa*(heigth-epsilon)));
		return f;
	}

	static getTempoDiRitornoByH(alfa, epsilon, heigth) {
		let f = Math.exp(-Math.exp(-alfa*(heigth-epsilon)));
		let tempoDiRitorno = 1/(1-f);
		return tempoDiRitorno;
		
	}

	static getHByTempoDiRitorno(alfa, epsilon, tempoDiRitorno) {
		let f = 1 - (1/(tempoDiRitorno));
		let h = (Math.log(-Math.log(f)) / (-alfa)) + epsilon;
		return h;
	}	

}
