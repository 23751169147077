import React from "react";
import DateUtils from "#/lib/DateUtils";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import RefreshIcon from "@material-ui/icons/Refresh";
import Slider from "@material-ui/core/Slider";
import SettingsIcon from "@material-ui/icons/Settings";
import Popover from "@material-ui/core/Popover";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CircularProgress from "@material-ui/core/CircularProgress";

const timeSlotOptions = {H3: '3 ore', H6 : '6 ore', H12: '12 ore', H24: '24 ore'};

export default class AnimationPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: 0,
            paused: true,
            currentInterval: null,
            anchorEl: null
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevState.timer !== this.props.forcedIndex){
            this.setState({timer: this.props.forcedIndex});
        }
    }

    moveForward = () => {
        if (this.state.timer < this.props.data.length - 1 ){
            this.setState({timer: this.state.timer + 1}, () =>{
                this.props.updateSnapshot(this.state.timer, this.state.paused)
            })
        } else {
            this.pause();
        }

    }

    moveBackward = () => {
        this.setState({timer: this.state.timer - 1}, () => {
            this.props.updateSnapshot(this.state.timer, this.state.paused)
        })
    }

    goTo = timer => {
        this.setState({timer}, () => {
            this.props.updateSnapshot(this.state.timer, this.state.paused)
        })
    }

    play = () => {
        let currentInterval = setInterval(() => this.moveForward(), 1000);
        this.setState({currentInterval, paused: false}, () => {
            this.props.updateSnapshot(this.state.timer, this.state.paused)
        })
    }

    pause = () => {
        clearInterval(this.state.currentInterval);
        this.setState({ paused: true}, () => {
            this.props.updateSnapshot(this.state.timer, this.state.paused)
        });
    }

    reset = () => {
        clearInterval( this.state.currentInterval);
        this.setState({timer: 0, paused: true}, () => {
            this.props.updateSnapshot(this.state.timer, this.state.paused)
        })
    }

    handlePopoverOpen = (event) => {
        console.log("Showing")
        this.setState({anchorEl: event.currentTarget})
    };

    handlePopoverClose = () => {
        console.log("Closing")
        this.setState({anchorEl: null});
    };
    selectTimeSlot = selectedTimeSlot => {
        this.setState({timer: 0, anchorEl: null}, () => {
            if (this.props.filterData){
                this.props.filterData(selectedTimeSlot)
            }
        } )
    }
    render = () => {

        let isBackoffice = window.location.href.includes("backoffice");

        const open = Boolean(this.state.anchorEl);
        let currentDateString ='In attesa dei dati';
        if (this.props.data.length > 0) {
            try {
                currentDateString = DateUtils.epochToDateMinuteResolution(this.props.data[this.state.timer].timestamp);
            } catch (err) {
                console.error(err)
            }
        }
        let disabledButtons = this.props.data.length === 0 || this.props.loadingLayer;
        return (
            <div className="d-inline-flex" style={{ width: this.props.width || '100%'}}>
                <div className="my-auto textAlignCenter">
                    <Tooltip title={"Backward"}>
						<span><IconButton color="primary" aria-label="upload picture"
                                          disabled={!this.state.paused || this.state.timer === 0 || disabledButtons}
                                          onClick={(e) => this.moveBackward()}>
							<SkipPreviousIcon/>
						</IconButton></span>
                    </Tooltip>
                </div>
                <div className="my-auto textAlignCenter">
                    <Tooltip title={this.state.paused ? "Play" : 'Pause'}>
						<span><IconButton color="primary"  aria-label="play/pause" disabled={disabledButtons}
                                          onClick={(e) => this.state.paused ? this.play() : this.pause()}>
							{this.state.paused ? <PlayArrowIcon/> : <PauseIcon className="playButton"/>}
						</IconButton></span>
                    </Tooltip>
                </div>
                <div className="my-auto textAlignCenter">
                    <Tooltip title={"Forward"}>
						<span><IconButton color="primary" aria-label="upload picture"
                                          disabled={disabledButtons || !this.state.paused || this.state.timer === this.props.data.length - 1}
                                          onClick={(e) => this.moveForward()}>
							<SkipNextIcon/>
						</IconButton></span>
                    </Tooltip>
                </div>
                <div className="my-auto textAlignCenter">
                    <Tooltip title={"Reset"}>
						<span><IconButton color="primary" aria-label="upload picture" disabled={disabledButtons}
                                          onClick={(e) => this.reset()}>
							<RefreshIcon/>
						</IconButton></span>
                    </Tooltip>
                </div>

                <div className="my-auto textAlignCenter" style={{
                    background: disabledButtons ? 'grey' : '#3f51b5',
                    color: '#efefef',
                    borderRadius: '15px',
                    zIndex: 0,
                    padding: '4px 8px',
                    fontSize: 'small'
                }}>
                    {currentDateString}
                </div>
                <div className="my-auto mx-3" style={{width: '12vw'}}>
                    <Slider
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        value={this.state.timer}
                        min={0}
                        max={this.props.data.length - 1}
                        valueLabelFormat={value => ''}
                        valueLabelDisplay="auto"
                        disabled={disabledButtons}
                        onChangeCommitted={(event, newValue) => this.goTo(newValue)}
                    />
                </div>
                {this.props.selectedTimeSlot && <div className="my-auto textAlignCenter mx-1">
                    
                    <Popover
                        id={'mouse-over-popover'}
                        open={open}
                        anchorEl={this.state.anchorEl}
                        onClose={() => this.handlePopoverClose()}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <div className="col">
                            <FormControl>
                                <RadioGroup row={true} aria-label="gender" name="gender1"
                                            value={this.props.selectedTimeSlot}
                                            onChange={(event) => this.selectTimeSlot(event.target.value)}>
                                    {Object.keys(timeSlotOptions).map(opt =>
                                        <FormControlLabel
                                            key={opt}
                                            value={opt}
                                            control={<Radio/>}
                                            label={timeSlotOptions[opt]}
                                        />
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Popover>
                </div>}
                {this.props.loadingLayer && <div className="my-auto textAlignCenter mx-1">
                    <CircularProgress size={24} style={{color: 'green'}}/>
                </div>}
            </div>
        )
    }
}
