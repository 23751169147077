import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class SettingsClient {


	static getGeojsonTemplates(okCallback, errCallback) {
		let url = properties.url.geojsonTemplates;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getGeojsonTemplateByDocumentType(okCallback, errCallback, documentType) {
		let url = properties.url.geojsonTemplateByDocumentType + "/" + documentType;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static updateGeojsonTemplate(okCallback, errCallback, template){
		let url = properties.url.updateGeojsonTemplate;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(template)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	static updateGeojsonTemplates(okCallback, errCallback, templates){
		let url = properties.url.updateGeojsonTemplates;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(templates)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getBlacklist(okCallback, errCallback){
		let url = new URL(properties.url.getBlacklist);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static saveBlacklistItem(params, okCallback, errCallback){
		let url = new URL(properties.url.saveBlacklistItem);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	static removeBlacklistItem(sensorCode, okCallback, errCallback){
		let url = new URL(properties.url.removeBlacklistItemBySensorCode + "/" + sensorCode) ;
		let fetch_options = {
			method: 'DELETE',
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}


}
