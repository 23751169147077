import React from 'react';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {ReactTabulator} from "react-tabulator";
import AuthenticationService from "#/lib/AuthenticationService"


export default class TableComponent extends React.Component {
	


	getTabulatorOptions(){
		let tabulatorOptions = {
			movableRows: false,
			movableColumns: false,
			layout: "fitColumns",
			pagination: "remote",
			data: [],
			ajaxURL: "",
			ajaxParams: {},
			paginationSize: 10,
			ajaxResponse: function (url, params, response) {
				return response;
			}
		};


		let ajaxConfig = {
			"method" : "post",
			headers: {	
				"Authorization" : AuthenticationService.getLoggedInToken()
			}
		}

		tabulatorOptions.ajaxConfig = ajaxConfig;
		tabulatorOptions.ajaxParams = this.props.params;
		tabulatorOptions.ajaxURL = this.props.url;
		return tabulatorOptions;
	}


	render() {
		return (
			<>
				<ReactTabulator
					columns={this.props.columns}
					data={[]}
					options={this.getTabulatorOptions()}
					key={"table_"+this.props.tableKey}
				/>
			</>
		)
	}
}
