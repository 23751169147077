import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class WorkItemClient {


	static getWorkItemsState( okCallback, errCallback) {
		let url = new URL(properties.url.workItemsState );
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getWorkItemsList(year, month, day, okCallback, errCallback) {
		let url = new URL(properties.url.workItemsList + "/" + year + "/" + month + "/" + day);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static addWorkItem(description, okCallback, errCallback) {
		let url = new URL(properties.url.addWorkItem);
		const params = {description: description}
		APIUtils.getFetchResponse(url,
			{
				headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
				method: 'POST',
				body: JSON.stringify(params)
			}
			, okCallback, errCallback);
	}
	static updateWorkItem(data, okCallback, errCallback) {
		let url = new URL(properties.url.updateWorkItem );
		const params = {description: data.activity, id: data.id }
		console.log(params)
		APIUtils.getFetchResponse(url,
			{
				headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
				method: 'POST',
				body: JSON.stringify(params)
			}
			, okCallback, errCallback);
	}

	static deleteWorkItem(id, okCallback, errCallback) {
		let url = new URL(properties.url.deleteWorkItem + "/" + id);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}


}
