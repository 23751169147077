class NumberUtils {

	static round(value,decimals){
		if(!!!decimals){
			decimals=4;
		}
		return +parseFloat(value).toFixed( decimals );
	}


}

export default NumberUtils;