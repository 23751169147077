import React, {useEffect, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import 'moment/locale/it';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {reactFormatter, ReactTabulator} from "react-tabulator";
import Skeleton from "react-loading-skeleton";
import IconButton from "@material-ui/core/IconButton";
import MailingListClient from "#/lib/MailingListClient";
import {useForm} from "react-hook-form";
import {DialogForm} from "#/commons/components/forms/DialogForm";
import Grid from "@material-ui/core/Grid";
import {TextFieldForm} from "#/commons/components/forms/TextFieldForm";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});
const title = {
    PRIVATE: 'Private',
    MUNICIPALITY: 'Comuni',
    INSTITUTION: 'Enti'
}

const label = {
    PRIVATE: 'privato',
    MUNICIPALITY: 'comune',
    INSTITUTION: 'ente'
}

const ModificaButton = (props) =>  (
    props.cell._cell.row.data.isChild ?
        <Tooltip title={"Modifica mail"}>
            <IconButton color="secondary" aria-label="modifica mail" component="span"
                        onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
                <EditIcon />
            </IconButton>
        </Tooltip>
        : <></>);

const EliminaButton = (props) =>  (
    props.cell._cell.row.data.isChild ?
        <Tooltip title={"Elimina mail"}>
            <IconButton color="secondary" aria-label="modifica mail" component="span"
                        onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
                <DeleteIcon />
            </IconButton>
        </Tooltip> : <></>);

const AggiungiButton = (props) =>  (
    !props.cell._cell.row.data.isChild ?
        <Tooltip title={"Aggiungi mail"}>
            <IconButton color="secondary" aria-label="modifica mail" component="span"
                        onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
                <AddIcon />
            </IconButton>
        </Tooltip> : <></>);

const MailingListManagerPage = ({category}) => {
    const [mailingList, setMailingList] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [showModal, toggleModal] = useState(false);

    useEffect(() => {
        if (selectedData){
            toggleModal(true);
        }}, [selectedData]);

    useEffect(() => {if (!showModal){
        setSelectedData(null);
    }}, [showModal]);

    useEffect(() => {
        toggleModal(false);
    }, [mailingList]);

    useEffect(() => {
        getAllData()
    }, []);
    const getAllData = () => {
        loadingSwal.fire('Caricamento in corso...')
        MailingListClient.getMailingListByCategory(
            mailingList => {
                buildNestedMailingList(mailingList);
                loadingSwal.close();
                setMailingList(buildNestedMailingList(mailingList));
            },
            err => {
                console.error(err);
                /*loadingSwal.close();*/
            },
            category
        )
    }

    const editRow = data => {
        setSelectedData(data);
    }
    const deleteRow = data => {
        const {id, emailAddress} = data;
        ReactSwal.fire({
            title: <h3>Eliminare l'indirizzo {emailAddress} ?</h3>,
            showCancelButton: true,
            confirmButtonColor: '#30ac03',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        })
            .then((result) => {
                if (result.value) {
                    loadingSwal.fire("Invio richiesta in corso...");

                    MailingListClient.deleteById(() => {
                            loadingSwal.close();
                            ReactSwal.fire('Eliminazione avvenuta con successo','','success')
                                .then(value => {getAllData()})
                        },
                        (err) => {
                            loadingSwal.close();
                            ReactSwal.fire('Errore','Mail non eliminata','error');
                        },
                        id)

                }
            })
    }
    const addRow = data => {
        setSelectedData(data);
    }
    const getKey = obj => category === 'PRIVATE' ? obj.name + '_' + obj.surname : obj.institutionName;

    const buildNestedMailingList = mailingList => {

        let grouped = mailingList.reduce((obj, item)=> {
            if (obj[`${getKey(item)}`]){
                obj[`${getKey(item)}`]._children.push({...item,isChild: true })
            } else {
                obj[`${getKey(item)}`] = {...item, ['_children'] : [{...item,isChild: true }]};
                delete  obj[`${getKey(item)}`].emailAddress;
            }
            return obj}, {});

        return Object.values(grouped);
    }
    const onSave = () => {
        getAllData()
    }
    const getColumns = () => [
        ...(category === 'PRIVATE' ? [
            { title: "Nome", field: "name", formatter: (cell, formatterParams, onRendered) => cell._cell.row.data.isChild ? '' : cell.getValue()},
            { title: "Cognome", field: "surname", formatter: (cell, formatterParams, onRendered) => cell._cell.row.data.isChild ? '' : cell.getValue() }
        ] : [{title: `Nome ${label[category]}`, field: "institutionName", formatter: (cell, formatterParams, onRendered) => cell._cell.row.data.isChild ? '' : cell.getValue()}])
        ,
        { title: "Indirizzo E-mail", field: "emailAddress" },
        {
            title: "",
            formatter: reactFormatter(<ModificaButton clickCallback={(data) => editRow(data)} variant="outlined" size="small"/>  ),
            headerSort:false,
            hozAlign:"center",
            vertAlign: "middle"
        },
        {
            title: "",
            formatter: reactFormatter(<EliminaButton clickCallback={(data) => deleteRow(data)} variant="outlined" size="small"/>  ),
            headerSort:false,
            hozAlign:"center",
            vertAlign: "middle"
        },
        {
            title: "",
            formatter: reactFormatter(<AggiungiButton clickCallback={(data) => addRow(data)} variant="outlined" size="small"/>  ),
            headerSort:false,
            hozAlign:"center",
            vertAlign: "middle"
        }
    ];

    return (
        <>
            <div className="generic-page container-fluid data-page">
                <h3>Mailing list {title[category]}</h3>
                {mailingList ? <ReactTabulator
                        columns={getColumns()}
                        data={mailingList}
                        options={{
                            height: "70vh",
                            dataTree: true,
                            dataTreeStartExpanded: true
                        }}
                        key={"table_" + JSON.stringify(mailingList)}
                    /> :
                    <Skeleton height={"70vh"} duration={8} />
                }
                {selectedData && <MailingListForm
                    open={showModal}
                    title={selectedData.emailAddress ? 'Modifica mail' : 'Aggiungi mail'}
                    onClose={() => toggleModal(false)}
                    data={selectedData}
                    onSuccessSubmit={() => onSave()}
                    category={category}
                />}
                <div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
                    <Tooltip title={`Aggiungi un nuovo ${label[category]} alla mailing list`}>
                        <Fab color="primary" onClick={() => {setSelectedData({})}}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
        </>
    )
}

export default MailingListManagerPage;

const MailingListForm = ({data, onSuccessSubmit, category, open, title, onClose}) =>  {
    const fieldRequiredMessage = "Campo obbligatorio";
    const { register, handleSubmit, errors, setValue, control, reset } = useForm({defaultValues: data});
    const onSubmit = data => submitForm(data);
    const onReset = () => reset({ acquisitionType: '' });

    const submitForm = (formContent) => {
        console.log("Form content", formContent);
        console.log("Data", data);
        formContent.category = category;
        formContent.id = data.isChild && data.id;
        MailingListClient.save(
            (data) => {
                ReactSwal.fire('Archiviazione andata a buon fine', '', 'success').then(values => {
                    onSuccessSubmit();
                })
            },
            (err) => {
                let msg = err.error && err.error === 'already exists' ? 'Indirizzo già esistente' : '';
                ReactSwal.fire('Archiviazione dati fallita', msg, 'error');
                console.log(msg);
            },
            formContent
        )
    }

    return (

        <DialogForm
            open={open}
            title={title}
            onClose = {onClose}
            handleSubmit={handleSubmit(onSubmit)}
            handleReset={onReset}
        >
            <form onSubmit = {handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    {category === 'PRIVATE' ? <>
                            <Grid item xs={12}>
                                <TextFieldForm
                                    autoFocus={true}
                                    id="name"
                                    label="Nome*"
                                    disabled={data.id}
                                    inputRef={register({required: fieldRequiredMessage})}
                                    handleClearButton={() => setValue('name', null)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldForm
                                    id = "surname"
                                    label = "Cognome*"
                                    disabled={data.id}
                                    inputRef={register({required: fieldRequiredMessage})}
                                    handleClearButton={() => setValue('surname', null)}
                                />
                            </Grid>
                        </> :
                        <Grid item xs={12}>
                            <TextFieldForm
                                id = "institutionName"
                                label = {`Nome ${label[category]}*`}
                                disabled={data.id}
                                inputRef={register({required: fieldRequiredMessage})}
                                handleClearButton={() => setValue('institutionName', null)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextFieldForm
                            id = "emailAddress"
                            label = "Indirizzo email"
                            inputRef={register}
                            handleClearButton={() => setValue('emailAddress', null)}
                        />
                    </Grid>
                </Grid>
            </form>

        </DialogForm>

    )
}
