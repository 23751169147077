export let AlertLevels = {

    NONE    : "NONE",
    LOW     : "LOW",
    MEDIUM  : "MEDIUM",
    HIGH    : "HIGH"
}

export let AlertLevelDisplayValues = {
    NONE    : " ",
    LOW     : "Basso livello di allerta",
    MEDIUM  : "Medio livello di allerta",
    HIGH    : "Alto livello di allerta"
}

export let AlertLevelPalette = {
    NONE    : "white",
    LOW     : "rgb(0, 255, 0)",
    MEDIUM  : "rgb(255, 255, 0)",
    HIGH    :"rgb(255, 0, 0)"
}
