import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export const SelectRangeForm = (props) => {

	const buildOptions = () => {
		let optionsMenuItems = [];
		props.options.forEach((o,i)=>{
			optionsMenuItems.push(<MenuItem key={"opt_"+i} value={o.value || ''}>{o.label}</MenuItem>);
		})
		return optionsMenuItems;
	}

	let options = buildOptions();
	let classname = !!props.className ? props.className : "mt-4 col-8 row justify-content-center";

	return (
		<FormControl className = {classname} key={"select_"+props.id} >
			<InputLabel id="h-helper-label">{props.label}</InputLabel>
			<Select
				fullWidth
				labelId={props.label}
				id={props.id}
				value={props.selectedRange}
				onChange={(e)=>props.handleSelectRange(e.target.value)}
			>
				{options}
			</Select>
		</FormControl>
	)



}