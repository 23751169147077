import React from "react";

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

export const SaveButton = (props) =>
	<Button disabled={props.hasOwnProperty('validForm') ? !props.validForm : false}
				   color="primary" style={{ margin: "2vh", minWidth: "10vw" }}
				   startIcon={<SendIcon />} {...props}>
		{!!props.label ? props.label :"Invia"}
	</Button>;

