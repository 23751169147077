import React, {useEffect, useState, useRef} from "react";
import MapComponent from "#/commons/map/MapComponent";
import {GeoJSON} from "react-leaflet";
import MapWatermark from "#/commons/map/MapWatermark";
import NowcastingClient from "#/lib/NowcastingClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import GISTools from "#/lib/GISTools";
import DateUtils from "#/lib/DateUtils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const mapOptions = {
    center: [39.11, 16.55],
    zoom: 6,
    minZoom: 6,
    width: "40vw",
    height: "70vh",
    forecastColor: '#0eb1b7',
    stormColor: '#bc4949'
}


const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

const NowcastingPage = ({ready, mapHeight}) => {
    const [data, setData] = useState(null);
    const [showBorders, toggleBorders] = useState(false);
    const [loading, setLoading] = useState(true);
    const mapRef = useRef(null);
    useEffect(() => {
        loadingSwal.fire('Caricamento in corso...')
        getNowcastingData();
      /*  setInterval(() => {
            console.log(new Date());
            getNowcastingData()
        },4000)*/

    },[]);
    useEffect(() => {
        if (data){
            if (ready) {
                console.log("Set tab ready")
                ready();
            }
            setLoading(false);

        }

    },[data])

    const getNowcastingData = () => {

        NowcastingClient.getNowcastingData(
            data => {
                loadingSwal.close();;
                setData(data)
            },
            err => {
                loadingSwal.close();
                console.error(err)
            }
        )
    }
    const zoneBbox = GISTools.getBBoxFromPoints(/*data ? data.storm :*/ GISTools.getCalabriaBorders());
    const fixMap = () => {
        if (mapRef && mapRef.current){
            mapRef.current.leafletElement.invalidateSize();
            mapRef.current.leafletElement.fitBounds([
                [zoneBbox.bbox[1], zoneBbox.bbox[0]],
                [zoneBbox.bbox[3], zoneBbox.bbox[2]]
            ]);
        }
    }
    const stub = (<div className=" pr-0 pl-0">
        <SkeletonTheme>
            <div className="d-inline-flex mt-2 mb-2"  style={{width: '100%', height: '70vh'}}>
                <div style={{width: '100%'}} >
                    <Skeleton height={'70vh'} duration={8}/>
                </div>
            </div>
        </SkeletonTheme>
    </div>);

    return (loading ? stub :
            <div>
                <h3>Situazione Nowcasting</h3>

                <MapComponent
                    functionalRef={mapRef}
                    key={data ? JSON.stringify(data.storm) : 'aKey'}
                    width={"100%"}
                    height={mapHeight || mapOptions.height}
                    zoom={mapOptions.zoom}
                    zoneBbox={zoneBbox}
                    zoomSnap={false}
                    buttonKey={'nowcasting'}
                    tile={null}
                    skipRecenter={false}
                    toggleBorders={() => toggleBorders(!showBorders)}>
                    {data &&
                    <>
                        <GeoJSON
                            key={'storm'}
                            data={data.storm}
                            style={{
                                fillColor: "#fff",
                                fillOpacity: 1,
                                weight: 2,
                                opacity: 1,
                                color: mapOptions.stormColor,
                            }}
                        />
                        <GeoJSON
                            key={'forecast'}
                            data={data.forecast}
                            style={{
                                fillColor: "#fff",
                                fillOpacity: 1,
                                weight: 2,
                                opacity: 1,
                                color: mapOptions.forecastColor,
                            }}
                        />
                        {data &&
                        <>
                            <MapWatermark
                                key={'exec'}
                                customClass={'exec'}
                                text={'Tempo di esecuzione: ' + DateUtils.epochToGMT1String(data.executionTimestamp)}

                            />
                            <MapWatermark
                                key={'watermark_storm'}
                                customClass={'stormWatermark'}
                                text={'Storm'}
                                customColor={mapOptions.stormColor}
                            />
                            <MapWatermark
                                key={'watermark_forecast'}
                                customClass={'forecastWatermark'}
                                text={'Forecast'}
                                customColor={mapOptions.forecastColor}
                            />
                        </>}
                    </>}
                    {showBorders && <GeoJSON key={"calabria_radar_"}
                                             data={GISTools.getRegioniBorders()}
                                             style={{
                                                 fillColor: "#fff",
                                                 fillOpacity: 0,
                                                 weight: 2,
                                                 opacity: 1,
                                                 color: "green",
                                             }}/>}
                </MapComponent>
                {fixMap()}
            </div>
    )
}

export default NowcastingPage;
