import * as turf from '@turf/turf';
import zone from '#/commons/geodata/zone.json';
import calabria from '#/commons/geodata/calabria.json';
import regioni from '#/commons/geodata/regioni.json';
import province from '#/commons/geodata/province.json';
import unifiedCalabria from '#/commons/geodata/unifiedCalabria.json';
import calabria_idro from '#/commons/geodata/calabria_idro.json';
import external_points from '#/commons/geodata/external_points.json';
import municipalities from '#/commons/geodata/municipalities.json';
import outer from '#/commons/geodata/outer.json';
import NumberUtils from '#/lib/NumberUtils'
import L from "leaflet";

const customCentroids = [
	{ "type": "Feature", "properties": { "ZONE": 1 }, "geometry": { "type": "Point", "coordinates": [ 16.043408637200216, 39.721778975251347 ]  } },
	{ "type": "Feature", "properties": { "ZONE": 2 }, "geometry": { "type": "Point", "coordinates": [ 16.230810889456002, 39.302726716734931 ]  } },
	{ "type": "Feature", "properties": { "ZONE": 3 }, "geometry": { "type": "Point", "coordinates": [ 16.280264261579056, 38.831618277036362 ]  } },
	{ "type": "Feature", "properties": { "ZONE": 4 }, "geometry": { "type": "Point", "coordinates": [ 15.952310320131431, 38.334481746746704 ]  } },
	{ "type": "Feature", "properties": { "ZONE": 8 }, "geometry": { "type": "Point", "coordinates": [ 15.965324365426971, 38.063789604599457 ]  } },
	{ "type": "Feature", "properties": { "ZONE": 7 }, "geometry": { "type": "Point", "coordinates": [ 16.616026630204008, 38.990389629641953 ]  } },
	{ "type": "Feature", "properties": { "ZONE": 6 }, "geometry": { "type": "Point", "coordinates": [ 16.829456973050878, 39.300123907675825 ]  } },
	{ "type": "Feature", "properties": { "ZONE": 5 }, "geometry": { "type": "Point", "coordinates": [ 16.527531122194333, 39.557802004527531 ]  } }
];

const customSeaPoints = [
	{ "type": "Feature", "properties": { "ZONE": 1 }, "geometry": { "type": "Point", "coordinates":  [ 15.637370423979345, 39.664517175950969 ] } },
	{ "type": "Feature", "properties": { "ZONE": 2 }, "geometry": { "type": "Point", "coordinates":  [ 15.835183912471564, 39.214231208725259 ] } },
	{ "type": "Feature", "properties": { "ZONE": 3 }, "geometry": { "type": "Point", "coordinates":  [ 15.895048520831052, 38.852440749509228 ] } },
	{ "type": "Feature", "properties": { "ZONE": 4 }, "geometry": { "type": "Point", "coordinates":  [ 15.551477725028777, 38.389140736987976 ] } },
	{ "type": "Feature", "properties": { "ZONE": 8 }, "geometry": { "type": "Point", "coordinates":  [ 16.366156960529626, 38.050775559303915 ] } },
	{ "type": "Feature", "properties": { "ZONE": 7 }, "geometry": { "type": "Point", "coordinates":  [ 16.772195173750497, 38.73531434184936 ] } },
	{ "type": "Feature", "properties": { "ZONE": 6 }, "geometry": { "type": "Point", "coordinates":  [ 17.313579458044991, 39.313137952971367 ] } },
	{ "type": "Feature", "properties": { "ZONE": 5 }, "geometry": { "type": "Point", "coordinates":  [ 16.707124947272792, 39.825891337615673 ] } }
];
export default class GISTools {

	static intersect(points, polygon) {
		var ptsWithin = turf.pointsWithinPolygon(points, polygon);
		return ptsWithin;
	}
	static getStationsOutsideCalabriaWithBuffer(points) {
		const calabriaBorders = this.getCalabriaSingleBorder();

		// Add buffer to points
		let bufferedPoints = turf.buffer(points, 0.5, {units: 'kilometers'});

		let ptsOutside = []
		bufferedPoints.features.forEach(f => {
			if(turf.booleanDisjoint(f,calabriaBorders)){
				ptsOutside.push(points.features.find(f2 => f2.properties.code === f.properties.code))
			};
		})
		return turf.featureCollection(ptsOutside);
	}

	static getStationsOutsideCalabria(points) {
		const calabriaBorders = this.getCalabriaSingleBorder();
		let ptsWithin = turf.pointsWithinPolygon(points, calabriaBorders);
		let stationCodesWithin =  ptsWithin.features.map(feature => feature.properties.code);
		let ptsOutside = points.features.filter(feature => !stationCodesWithin.includes(feature.properties.code));
		return turf.featureCollection(ptsOutside);
	}

	static unifyPolygons(polygons) {
		if (polygons.length <= 1) {
			return polygons[0];
		}

		var union = turf.union(...polygons);


		return union;

	}

	extent = [15.216064, 37.700392, 17.237549, 40.178086];

	static createRandomStationPoint() {


		//var points = turf.randomPoint(30, { bbox: [15.216064, 37.700392, 17.237549, 40.178086] });



		var cellSide = 27;
		var options = {units: 'kilometers'};

		let bbox = this.getBBoxFromPoints(calabria)

		var points = turf.pointGrid(bbox.bbox, cellSide, options);



		let result = [];
		let idx = 0
		turf.featureEach(points, (point) => {
			point.properties.name = "stazione_" + idx;
			idx++;
			let inSomeZone = false;
			turf.featureEach(zone, (zone) => {
				inSomeZone += this.isWithinPoligon(point, zone);
			})
			if (inSomeZone) {
				result.push(point);
			}
		});

		let id=0;
		let string = "";
		turf.featureEach(turf.featureCollection(result), (point)=>{
			id++;
			string = string + "stazione_"+id+","+point.geometry.coordinates[0]+","+point.geometry.coordinates[1]+"\n";
		})
		console.log(string)






		return turf.featureCollection(result);
	}


	static filterPointsByField(points,fieldName,values){
		let result = [];
		turf.featureEach(points, (point) => {
			if(values.includes(point.properties[fieldName])){
				result.push(point);
			}
		})
		return turf.featureCollection(result);
	}

	static createRandomData() {
		var points1 = turf.randomPoint(20, { bbox: [15.216064, 37.700392, 16.216064, 39] });
		turf.featureEach(points1, function (point) {
			point.properties.value = Math.random() * (100 - 80) + 80;;
		});

		var points2 = turf.randomPoint(20, { bbox: [15.216064, 39, 16.216064, 40.178086] });
		turf.featureEach(points2, function (point) {
			point.properties.value = Math.random() * (80 - 20) + 20;;
		});

		var points3 = turf.randomPoint(20, { bbox: [16.216064, 39, 17.237549, 40.178086] });
		turf.featureEach(points3, function (point) {
			point.properties.value = Math.random() * (20);;
		});

		var points4 = turf.randomPoint(20, { bbox: [16.216064, 37.700392, 17.237549, 39] });
		turf.featureEach(points4, function (point) {
			point.properties.value = Math.random() * (80 - 20) + 20;;
		});

		let points = turf.featureCollection([...points1.features, ...points2.features, ...points3.features, ...points4.features]);

		return points;
	}

	static interpolate(points,cellSize) {

		cellSize = !!cellSize ? cellSize : 5;
		console.time("Inverse Distance Weighting")

		turf.featureEach(external_points, (point)=>{
			let nearest = turf.nearestPoint(point, points);
			point.properties = {...nearest.properties}
		})
		let pointsAll = turf.featureCollection([...external_points.features, ...points.features]);

		let options = { gridType: 'square', property: 'value' };
		let grid = turf.interpolate(pointsAll, cellSize, options);

		console.timeEnd("Inverse Distance Weighting")
		return grid;
	}

	static interpolateByCodeFieldName(points, cellSize, codeFieldName) {

		cellSize = !!cellSize ? cellSize : 5;

		turf.featureEach(external_points, point =>{
			let nearest = turf.nearestPoint(point, points);
			point.properties = {...nearest.properties}
		})
		let pointsAll = turf.featureCollection([...external_points.features, ...points.features]);

		let options = { gridType: 'square', property: codeFieldName };
		return turf.interpolate(pointsAll, cellSize, options);

	}

	static pointListToMap(points, val) {

		let map = {};
		turf.featureEach(points, (point) => {
			let key = point.properties.name;
			let value = val;
			Object.assign(map, { [key]: value });
		});

		return map;
	}

	static getExternalPoints(){
		return external_points;
	}
	static getCalabriaBorders(){
		return calabria;
	}
	static getCalabriaMunicipalities(){
		return municipalities;
	}

	static getRegioniBorders(){
		return regioni;
	}
	static getProvinceGeoJson(){
		return province;
	}

	static getOuter(){
		return outer;
	}

	static getCalabriaSingleBorder(){
		return unifiedCalabria.features[0];
	}

	static getCalabriaIdro(){
		return calabria_idro;
	}

	static getAllZonePolygon() {
		let zones = [];
		turf.featureEach(zone, (feature) => {

			zones.push(feature);

		});
		return zones;
	}

	static getZonePolygonByField(fieldName, id) {
		let foundZone;
		turf.featureEach(zone, (feature) => {
			if (feature.properties[fieldName] === id) {
				foundZone = feature;
			}
		});
		return foundZone;
	}

	static getStationByField(stations, fieldName, value) {
		let foundZone;
		turf.featureEach(stations, (feature) => {
			if (feature.properties[fieldName] === value) {
				foundZone = feature;
			}
		});

		return foundZone;
	}

	static getFeatureByField(geoJSON, fieldName, value) {
		let foundZone = {};
		turf.featureEach(geoJSON, (feature) => {
			if (feature.properties[fieldName] === value) {
				foundZone = feature;
			}
		});

		return foundZone;
	}

	static getZonePolygonByPoint(point) {
		let foundZone = {};
		turf.featureEach(zone, (feature) => {
			if (this.isWithinPoligon(point, feature)) {
				foundZone = feature;
			}
		})
		return foundZone;
	}

	static getProvincePolygonByPoint(point) {
		let foundProvince = {};
		turf.featureEach(province, (feature) => {
			if (this.isWithinPoligon(point, feature)) {
				foundProvince = feature;
			}
		})
		return foundProvince;
	}

	static isWithinPoligon(point, polygon) {
		let isWithin = turf.booleanPointInPolygon(point, polygon);
		return isWithin;
	}

	static getValuesFromFeatureByFieldName(features, fieldName) {
		let result = [];
		turf.featureEach(features, (feature) => {
			result.push(feature.properties[fieldName]);
		});
		return result;
	}

	static getPropertiesFromFeature(features) {
		let result = [];
		turf.featureEach(features, (feature) => {
			result.push(feature.properties);
		});
		return result;
	}

	static getBBoxFromPoints(points) {
		var bbox = turf.bbox(points);
		var bboxPolygon = turf.bboxPolygon(bbox);
		return {
			bboxPolygon: bboxPolygon,
			bbox :bbox
		}
	}


	static getArrayStationByDistance(target, points){
		let pointWithDistance = [];

		turf.featureEach(points, (point) => {


			var from = turf.point(target?.geometry?.coordinates);
			var to = turf.point(point?.geometry?.coordinates);

			var distance = turf.distance(from, to);

			let item = point.properties;
			item.distance = NumberUtils.round(distance,2);

			pointWithDistance.push(item);
		});

		pointWithDistance.sort((a,b)=> a.distance - b.distance);

		return pointWithDistance;

	}
	static addCustomCentroids = (geoJSON) => {
		let centroids = [];
		turf.featureEach(geoJSON, (feature) => {
			if (feature.geometry.type === 'MultiPolygon') {
				let centroid = customCentroids.find(c => c.properties.ZONE === feature.properties.ZONE);
				centroid.properties = feature.properties;
				centroids.push(centroid);
			}
		})
		geoJSON.features.push(...centroids);
	}
	static addCustomSeaPoints = (geoJSON) => {
		let centroids = [];
		turf.featureEach(geoJSON, (feature) => {
			if (feature.geometry.type === 'MultiPolygon') {
				let centroid = customSeaPoints.find(c => c.properties.ZONE === feature.properties.ZONE);
				centroid.properties = {...feature.properties, sea: true};
				centroids.push(centroid);
			}
		})

		geoJSON.features.push(...centroids);
	}
	static addCentroids(geoJSON){
		let centroids = [];
		turf.featureEach(geoJSON, (polygon) => {
			let centroid = turf.centroid(polygon);
			centroid.properties = polygon.properties;
			centroids.push(centroid);
		})

		geoJSON.features.push(...centroids);
		console.log('>>>>>>>>>>>', geoJSON)
	}

	static showPopup(val,lnglat,map, factor){
		let popupContent = "<p></p>";
		popupContent += 'latitudine:' + NumberUtils.round(lnglat[0], 6);
		popupContent += '</br>longitudine:' + NumberUtils.round(lnglat[1], 6);
		popupContent += '</br>';
		popupContent += '</br>Valore:' + NumberUtils.round(val, 2);
		const latlng = L.latLng(lnglat[0], lnglat[1]);

		L.popup({ offset: [40, 40]})
			.setLatLng(latlng)
			.setContent(popupContent)
			.openOn(map);
	}
}
