import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class BulletinClient {

	static getShareUrl = (id) => {
		return properties.url.bulletinShare+"/"+id;
	};

	static saveBulletinForecastFile = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinUploadFile + "/" + params.id);
		const formData = new FormData();
		// let atobFile = atob(params.file.split(',')[1]);
		// const blob = new Blob([atobFile], { type: 'application/pdf' });
		let binaryString = atob(params.file.split(',')[1]);
		let binaryLen = binaryString.length;
		let bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		const blob = new Blob([bytes], { type: 'application/pdf' });
		formData.append("file", blob, params.fileName);
		let fetch_options = {
			method: 'POST',
			body: formData
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static getBulletinForecastFile = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinDownloadFile + "/" + params.id + "/pdf");
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	};

	static saveBulletin = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static getSchema = (okCallback, errCallback, bullettinTypeId) => {
		let url = new URL(properties.url.bulletinSchema + "/" + bullettinTypeId);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	};

	static listBulletins = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinList + "/" + params.bulletinTypeName);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		if (params.bulletinTypeName==='MAIB') {
			APIUtils.getFetchResponse(url, fetch_options, (res) => okCallback(this._trasformListPrintDataInListFormData(res)), errCallback);
		} else {
			APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
		}
	};

	static listAllBulletins = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinFullList + "/" + params.bulletinTypeName);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		if (params.bulletinTypeName==='MAIB') {
			APIUtils.getFetchResponse(url, fetch_options, (res) => okCallback(this._trasformListPrintDataInListFormData(res)), errCallback);
		} else {
			APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
		}
	};



	static getBulletin = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinGet + "/" + params.bulletinTypeName + "/" + params.id);
		if (params.bulletinTypeName==='MAIB') {
			APIUtils.getFetchResponse(url, null, (res) => okCallback(this._trasformSinglePrintDataInSingleFormData(res)), errCallback);
		} else {
			APIUtils.getFetchResponse(url, null, okCallback, errCallback);
		}
	};
	static getYesterdayBulletin = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinGetPrevious + "/" + params.bulletinTypeName );
		if (params.bulletinTypeName==='MAIB') {
			APIUtils.getFetchResponse(url, null, (res) => okCallback(this._trasformSinglePrintDataInSingleFormData(res)), errCallback);
		} else {
			APIUtils.getFetchResponse(url, null, okCallback, errCallback);
		}
	};

	static approveBulletin = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinApprove + "/" + params.bulletinTypeName + "/" + params.id);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST'
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static publishBulletin = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinPublish + "/" + params.bulletinTypeName + "/" + params.id);
		if(!!params.protocol){
			url.search = new URLSearchParams({'protocol': params.protocol}).toString();
		}

		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST'
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static deleteBulletin = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.bulletinDelete + "/" + params.bulletinTypeName + "/" + params.id);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST'
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static _trasformListPrintDataInListFormData = (listData) => {
		let resultData = [];
		Object.keys(listData.data).forEach( (key) => {
			resultData.push(this._trasformSinglePrintDataInSingleFormData(listData.data[key]));
		});
		listData.data = resultData;
		return listData;
	}

	static _trasformSinglePrintDataInSingleFormData = (data) => {
		if (!!data.jsonData.start){
			const today = data.jsonData.start;
			const tomorrow = data.jsonData.end;
			const zones = data.jsonData.zones;
			let maibToday = {};
			let maibTomorrow = {};
			Object.keys(zones).forEach( (key) => {
				let zone = parseInt(key)+1;
				maibToday = {...maibToday, [zone] : zones[key].start};
				maibTomorrow = {...maibTomorrow, [zone] : zones[key].end};
			});
			const newJsonData = {
				maib_general: {
					precipitazioni_oggi: today.rainfall,
					temperature_oggi: today.temperature,
					venti_oggi: today.wind,
					precipitazioni_domani: tomorrow.rainfall,
					temperature_domani: tomorrow.temperature,
					venti_domani: tomorrow.wind
				},
				maib_today: maibToday,
				maib_tomorrow: maibTomorrow,
				address_to: data.jsonData.address_to
			}
			data.jsonData = newJsonData;
		}
		return data;
	}

	static getSchemaDeprecated = (okCallback, errCallback, params) => {
		let url;
		if (!!params.id) {
			url = `${properties.url.deprecatedbulletinData}/${params.id}`;
			//url = "http://192.168.2.35:8080/rest/bulletin/edit/" + params.bulletinId;
		} else {
			url = `${properties.url.deprecatedbulletinSchema}/${params.bulletinTypeId}`;
			//url = "http://192.168.2.35:8080/rest/bulletin/new/" + params.bulletinTypeId;
		}

		fetch(url)
			.then(res => res.json())
			.then(res => {
				if (res.status === "success") {
					return okCallback(res.data);
				} else if (res.status === "error") {
					return errCallback(res.message);
				}
			})
			.catch(error => {
				console.log(error);
				return errCallback(error.message);
			});
	};

	static isCreationAllowedToday = (okCallback, errCallback, bulletinTypeName) => {
		let url = new URL(properties.url.isCreationAllowedToday + "/" + bulletinTypeName );
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'GET'
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static getBulletinTypes = (okCallback, errCallback) => {
		let url = new URL(properties.url.bulletinGetTypes );
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'GET'
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static updateBulletinTemplate = (okCallback, errCallback, documentTypeName, templateName, template) => {
		let url = new URL(properties.url.bulletinTypeUpdateTemplate(documentTypeName, templateName) );
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify({template})
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};


}
