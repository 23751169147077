import React from 'react'
import {COMBO_TYPE_BY_CATEGORY, MEASUREMENT_CATEGORY} from "#/lib/MeasurementCategory";
import DateUtils from "#/lib/DateUtils";
import NumberUtils from "#/lib/NumberUtils";
import Skeleton from "react-loading-skeleton";
import WindComboChartComponent from "#/commons/chart/WindComboChartComponent";
import {ReactTabulator} from "react-tabulator";

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';

import {properties} from "#/properties";

import moment from "moment";
import MeasurementDataClient from "#/lib/MeasurementsDataClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MultiSensorTableComponentScrollable from "#/commons/table/MultiSensorTableComponentScrollable";
import ChartViewComponent from "#/commons/chart/ChartViewComponent";
import SensorClient from "#/lib/SensorClient";
import _ from "underscore";
import ReactTimeseriesChart from "#/commons/chart/ReactTimeseriesChart";

const ReactSwal = withReactContent(Swal);
const VIEW_MODE = {
	NONE: "NONE",
	TABLE: "TABLE",
	AGGREGATED_TABLE: "AGGREGATED_TABLE",
	CHART: "CHART",
	AGGREGATED_CHART: "AGGREGATED_CHART",
	CROSS_VALIDATION: "CROSS_VALIDATION"
};




let comboWindMeasurements = [
	MEASUREMENT_CATEGORY.WS,
	MEASUREMENT_CATEGORY.WV,
	MEASUREMENT_CATEGORY.WR
];

export default class MeasurementsBody extends React.Component {
	constructor(props) {
		console.log(props)
		super(props);

		this.state = {
			dataSetCache: {},
			sensorValueMapByTimestamp : {},
			chartKey: 1,
			aggregationInterval : !!this.props.aggregationInterval ? this.props.aggregationInterval.value : '',
		}
	}

	componentDidUpdate() {
		if (!!this.props.aggregationInterval && this.props.aggregationInterval.value !== this.state.aggregationInterval){
			this.setState({dataSetCache : {}, aggregationInterval : this.props.aggregationInterval.value});
		}
	}

	getContent() {



		let content =
			<div className="row mt-2">
				<div className="col-12 float-right" >
					<Skeleton height={"60vh"} duration={8} />
				</div>
			</div>;

		switch (this.props.selectedViewMode.value) {

			case VIEW_MODE.AGGREGATED_TABLE:
				/*
                                if((!!this.props.aggregationInterval.value && !!this.props.aggregationFunction.value) || (!!this.props.aggregationInterval.value && this.props.selectedMeasure.value === MEASUREMENT_CATEGORY.P)){
                */
				content = this.buildAggregatedTable();

				break;
			case VIEW_MODE.TABLE:
				if(comboWindMeasurements.includes(this.props.selectedMeasure.value)){
					content = this.buildComboWindTable()
				}
				// EXPLAIN: never called
				else {
					content = this.buildTable();
				}
				break;
			case VIEW_MODE.CHART:
				if (comboWindMeasurements.includes(this.props.selectedMeasure.value)) {
					console.log("error not available")
				}
				else {
					content = this.buildChart(this.props.interval, this.props.selectedMeasure);
				}
				break;

			case VIEW_MODE.AGGREGATED_CHART:

				if (comboWindMeasurements.includes(this.props.selectedMeasure.value)) {
					content = this.buildWindComBoAggregatedChart(this.props.interval, this.props.selectedMeasure, this.props.selectedStation);
				}

				break;
			case VIEW_MODE.CROSS_VALIDATION:

				content = this.buildCrossValidationTable(this.props.selectedMeasure.sensor[0].sensorCode, this.props.interval)
				break;
			default:
				break;
		}

		return content;
	}

	buildCrossValidationTable(sensorCode, interval){
		let params = {
			code: sensorCode,
			start : interval.start.valueOf(),
			end : interval.end.valueOf()
		}

		let tabulatorOptions = {
			movableRows: true,
			movableColumns: true,
			layout: "fitColumns",
			data: [],
			ajaxURL: "",
			ajaxConfig: "post",
			ajaxParams: {},
			paginationSize: 10,
			placeholder:"Non sono presenti anomalie di secondo livello per il sensore nell'intervallo selezionato",
			ajaxResponse: function (url, params, response) {
				return response;
			}
		};

		tabulatorOptions.ajaxParams = params;
		tabulatorOptions.ajaxURL = properties.url.crossValidation;

		let columns = [];
		columns = columns.concat([{ title: "Stazione", field: "stationCode" }]);
		columns = columns.concat([
			{
				title: "Inizio",
				field: "validationStart",
				formatter: function (cell) {
					return DateUtils.epochToGMT1String(cell.getValue());
				}
			},
			{
				title: "Fine",
				field: "validationEnd",
				formatter: function (cell) {
					return DateUtils.epochToGMT1String(cell.getValue());
				}
			},
			{
				title: "Scostamento",
				field: "deviation",
				formatter: function (cell) {
					return NumberUtils.round(cell.getValue());
				}
			}
		]);

		return (
			<>
				<ReactTabulator
					columns={columns}
					data={[]}
					options={tabulatorOptions}
					key={"table_"+this.props.dataKey}
				/>
			</>
		)

	}

	buildComboWindTable(){

		const {interval, selectedMeasure, compareList } = this.props;
		let measures = [{
			sensorCode : selectedMeasure.sensor[0].sensorCode,
			sensorCategory : selectedMeasure.sensor[0].category
		},
		{
			sensorCode : selectedMeasure.sensor[1].sensorCode,
			sensorCategory : selectedMeasure.sensor[1].category
		}];

		compareList.map(x => x.measure).forEach(comp =>
			measures.push({
				sensorCode: comp.sensor[0].sensorCode,
				sensorCategory: comp.value
			})
		);

		return (
			<MultiSensorTableComponentScrollable
				key ={this.props.dataKey}
				interval = {interval}
				pageDuration = {moment.duration(15, 'minutes')}
				aggregation = {"PUNTUAL"}
				getDataByInterval = {(interval, measures, callback)=>this.getDataByInterval(interval, measures, callback)}
				exportData = {()=>this.downloadHandler(this.getExportParams(measures, interval))}
				measures = {measures}
				stationNameBySensorCode = {this.props.stationNameBySensorCode}
			/>
		);

	}

	/*
	*  New Table
	* */
	getDataByInterval(interval, measures, callback){
		let codes = []
		measures.forEach(element => {
			codes.push(element.sensorCode)
		});
		let params = {
			codes,
			start : interval.start.valueOf(),
			end : interval.end.valueOf(),
		}
		if (this.props.aggregationInterval.value === 'PUNTUAL' || this.props.aggregationInterval.value === 'NONE') {
			MeasurementDataClient.getMultiSensorPagedByTime(
				(data) => {

					callback(data);
				},
				(err) => {
				},
				params
			)
		} else {
			params.aggregationInterval = this.props.aggregationInterval.value;
			MeasurementDataClient.getAggregatedMultiSensorPagedByTime(
				(data) => {
					callback(data);
				},
				(err) => {
				},
				params
			)
		}
	}
	downloadHandler(params){
		if (this.props.aggregationInterval.value === 'PUNTUAL') {
			MeasurementDataClient.exportMultiSensorCSV(
				() => {
					ReactSwal.fire('Export CSV terminato con successo', '', 'success');

				},
				() => {
					ReactSwal.fire('Export CSV fallito', '', 'error');

				},
				"export.csv",
				params
			)
		} else {
			params.aggregationInterval = this.props.aggregationInterval.value;
			MeasurementDataClient.exportAggregatedMultiSensorCSV(
				() => {
					ReactSwal.fire('Export CSV terminato con successo', '', 'success');

				},
				() => {
					ReactSwal.fire('Export CSV fallito', '', 'error');

				},
				"export.csv",
				params
			)
		}

	}
	getExportParams(measures, interval){
		let {start, end} = interval;
		return {
			codes : measures.map(element => element.sensorCode),
			start : start.valueOf(),
			end : end.valueOf()
		}
	}
	buildTable() {

		const {interval, selectedMeasure, compareList } = this.props;
		let measures = [{
			sensorCode : selectedMeasure.sensor[0].sensorCode,
			sensorCategory : selectedMeasure.value
		}];

		compareList.map(x => x.measure).forEach(comp =>
			measures.push({
				sensorCode: comp.sensor[0].sensorCode,
				sensorCategory: comp.value
			}));

		return (
			<MultiSensorTableComponentScrollable
				key ={this.props.dataKey}
				interval = {interval}
				pageDuration = {moment.duration(15, 'minutes')}
				aggregation = {"PUNTUAL"}
				getDataByInterval = {(interval, measures, callback)=>this.getDataByInterval(interval, measures, callback)}
				exportData = {()=>this.downloadHandler(this.getExportParams(measures, interval))}
				measures = {measures}
				stationNameBySensorCode = {this.props.stationNameBySensorCode}
			/>
		);
	}

	buildAggregatedTable(){
		let {interval, selectedMeasure, compareList, aggregationInterval : {value : aggregationInterval}} = this.props;

		let measures = [{
			sensorCode : selectedMeasure.sensor[0].sensorCode,
			sensorCategory : selectedMeasure.value
		}];

		compareList.map(x => x.measure).forEach(comp =>
			measures.push({
				sensorCode: comp.sensor[0].sensorCode,
				sensorCategory: comp.value
			}));


		return (
			<MultiSensorTableComponentScrollable
				key={this.props.dataKey}
				interval={interval}
				pageDuration={moment.duration(15, 'minutes')}
				aggregation={aggregationInterval}
				getDataByInterval={(interval, measures, callback) => this.getDataByInterval(interval, measures, callback)}
				exportData={() => this.downloadHandler(this.getExportParams(measures, interval))}
				measures={measures}
				stationNameBySensorCode = {this.props.stationNameBySensorCode}
				outflowScales={this.props.outflowScales}
			/>
		);
	}

	buildWindComBoAggregatedChart(interval, measure, station){
		return <WindComboChartComponent
			stationCode={station.value}
			start={interval.start.valueOf()}
			end={interval.end.valueOf()}
			comboType={COMBO_TYPE_BY_CATEGORY[measure.value]}
			key={this.props.dataKey}
		></WindComboChartComponent>

	}

	onDataSetCallback(dataset) {

		let sensorKeys = Object.keys(dataset);

		let sensorValueMapByTimestamp = {};
		sensorKeys.forEach(sensor =>{
			let dataArray = dataset[sensor].data;
			let dataMap = dataArray.reduce(function(map, obj) {
				map[obj.measurement_timestamp] = obj.value;
				return map;
			}, {});

			sensorValueMapByTimestamp[sensor] = dataMap

		})
		this.setState({
			dataSetCache: dataset,
			sensorValueMapByTimestamp
		})
	}
	onFilterCallBack = filter => {
		this.setState({filter})
	};

	buildChart(interval, measure){

		/* 		console.log(measure)
                if(measure.value===MEASUREMENT_CATEGORY.P){
                    measure.aggregationFunction = this.props.aggregationFunction.value;
                } */
		return <ChartViewComponent
			selectedMeasure = {measure}
			interval = {interval}
			selectedStation = {this.props.selectedStation}
			stationsGeoJSON = {this.props.stationsGeoJSON}
			dataSetCache = {this.state.dataSetCache}
			onDataSetCallback = {(dataset) => this.onDataSetCallback(dataset)}
			onFilterCallback = {(filter) => this.onFilterCallBack(filter)}
			key={this.props.dataKey}
			compareList = {this.props.compareList}
			aggregationInterval={this.props.aggregationInterval.value}
			allSensorsMap = {this.props.allSensorsMap}
			outflowScales={this.props.outflowScales}

		/>


	}


	render() {
		return this.getContent()
	}
}
