import React from 'react'
import {FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight,} from 'react-icons/fa';

const ZoomHoverButtonDecorator = (
    {
        disabled = false,
        color = "#438f81",
        maxZoom = 1,
        timing = 150,
        onClick,
        children
    }) => {
    const [isBooped, setIsBooped] = React.useState(false);
    const style = {
        color: !disabled ? color : "lightgray",
        cursor: !disabled ? 'pointer': 'not-allowed',
        display : "inline-block",
        backfaceVisibility: 'hidden',
        transform: isBooped && !disabled
            ? `scale(${maxZoom})`
            : `scale(1)`,
        transition: `all ease ${timing}ms`,
    };
    React.useEffect(() => {
        if (!isBooped) {
            return;
        }
        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);
        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [isBooped, timing]);
    const trigger = () => {
        setIsBooped(true);
    };
    const clickHandler = () =>{
        if(!disabled){
            onClick()
        }
    }
    return (
        <span onMouseEnter={trigger} onClick={(e)=>clickHandler()} style={style}>
            {children}
        </span>
    );
};

const MixedChartNavigationHeader = (
    {
        prevDisabled,
        nextDisabled,
        backHandler,
        fastBackHandler,
        forwardHandler,
        fastForwardHandler,
        title,
        buttonColor = "#438f81",
        titleColor = "#438f81"
    }) => {

    return (
        <div className="mx-4 d-flex justify-content-between" >
            <div >
                <ZoomHoverButtonDecorator disabled={prevDisabled} color={buttonColor} maxZoom={1.5} timing={400} onClick={fastBackHandler}>
                    <FaAngleDoubleLeft size={50} />
                </ZoomHoverButtonDecorator>
                <ZoomHoverButtonDecorator disabled={prevDisabled} color={buttonColor} maxZoom={1.5} timing={400} onClick={backHandler}>
                    <FaAngleLeft size={50} />
                </ZoomHoverButtonDecorator>
            </div>
            <div >
                <h2 style={{"color":titleColor}}>{title}</h2>
            </div>
            <div >
                <ZoomHoverButtonDecorator disabled={nextDisabled} color={buttonColor} maxZoom={1.5} timing={400} onClick={forwardHandler}>
                    <FaAngleRight size={50} />
                </ZoomHoverButtonDecorator>
                <ZoomHoverButtonDecorator disabled={nextDisabled} color={buttonColor} maxZoom={1.5} timing={400} onClick={fastForwardHandler}>
                    <FaAngleDoubleRight size={50} />
                </ZoomHoverButtonDecorator>
            </div>
        </div>
    );
};

export default MixedChartNavigationHeader;
