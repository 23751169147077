import React from 'react'
import {MEASUREMENT_CATEGORY} from "#/lib/MeasurementCategory";
import Skeleton from "react-loading-skeleton";

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';


import moment from "moment";
import LegacyMeasurementsDataClient from "#/lib/LegacyMeasurementsDataClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MultiSensorTableComponentScrollable from "#/commons/table/MultiSensorTableComponentScrollable";

const ReactSwal = withReactContent(Swal);
const VIEW_MODE = {
	NONE: "NONE",
	AGGREGATED_TABLE: "AGGREGATED_TABLE",
	AGGREGATED_CHART: "AGGREGATED_CHART"
};






export default class LegacyMeasurementsBody extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			chartKey: 1,
			aggregationInterval : !!this.props.aggregationInterval ? this.props.aggregationInterval.value : ''
		}
	}


	getContent() {
		let content =
			<div className="row mt-2">
				<div className="col-12 float-right" >
					<Skeleton height={"60vh"} duration={8} />
				</div>
			</div>;

		switch (this.props.selectedViewMode.value) {
			case VIEW_MODE.AGGREGATED_TABLE:
				content = this.buildAggregatedTable();
				break;

			case VIEW_MODE.AGGREGATED_CHART:
				content = this.buildChart(this.props.interval, this.props.selectedStation);
				break;
			default:
				break;
		}

		return content;
	}

	
	/*
	*  New Table
	* */
	getDataByInterval(interval, measures, callback){
		let stationCodes = []
		measures.forEach(element => {
			stationCodes.push(element.sensorCode)
		});


		let params = {
			stationCodes,
			start : interval.start.valueOf(),
			end : interval.end.valueOf(),
			aggregation : this.props.aggregationInterval.value
		}

		LegacyMeasurementsDataClient.getJsonData(
			(data) => {
				callback(data);
			},
			(err) => {
			},
			params
		)

		
	}



	getExportParams(measures, interval){
		let {start, end} = interval;
		return {
			codes : measures.map(element => element.sensorCode),
			start : start.valueOf(),
			end : end.valueOf()
		}
	}


	buildAggregatedTable(){
		let {interval, selectedStation, compareList, aggregationInterval : {value : aggregationInterval}} = this.props;

		let measures = [{
			sensorCode : selectedStation.value,
			sensorCategory : "P",
			hideContextMenu : true
		}];

		compareList.map(x => x.station).forEach(comp =>
			measures.push({
				sensorCode: comp.value,
				sensorCategory: "P",
			hideContextMenu : true
			}));


		return (
			<MultiSensorTableComponentScrollable
				key={this.props.datakey}
				interval={interval}
				pageDuration={moment.duration(15, 'minutes')}
				aggregation={aggregationInterval}
				getDataByInterval={(interval, measures, callback) => this.getDataByInterval(interval, measures, callback)}
				measures={measures}
				stationNameBySensorCode = {this.props.stationNameBySensorCode}
			/>
		);
	}



	buildChart(interval, measure){

		return 
		<Skeleton height={"60vh"} duration={8} />


	}


	render() {
		return this.getContent()
	}
}
