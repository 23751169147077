import React from 'react';
import FireRiskMapPage from "#/backoffice/fireRisk/FireRiskMapPage";
import FireRiskPage from "#/backoffice/fireRisk/FireRiskPage";

const STEPS = {
	SETTINGS: 'SETTINGS',
	MAP: 'MAP',
}
export default class FireRiskBuilder extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			step: STEPS.SETTINGS,
			cache: props.cache || {
				arrayBuffer: null,
				factors: [],
				files: null,
				config: null,
				excludedFactors: null,
				rasterMap: null
			}
		}
	}

	goToMap = (cache) => {
		this.setState({
			step: STEPS.MAP,
			cache}, () => {
			if (this.props.handleCache){
				this.props.handleCache(cache)
			}
		})
	}
	goToSetting = () => {
		this.setState({
			step: STEPS.SETTINGS
		})
	}

	render = () => {
		const {step, cache} = this.state;
		return (
			<div className={`${!this.props.day ? 'generic-page container-fluid data-page' : ''}`}>
				{step === STEPS.SETTINGS && <FireRiskPage day={this.props.day} showFinalMap = {(cache) => this.goToMap(cache)} cache={cache} />}
				{step === STEPS.MAP && cache.arrayBuffer &&
				<FireRiskMapPage
					geoInfo={this.props.geoInfo}
					zonalMapCreationHandler={zonalValuesMap => {
							if(this.props.zonalMapCreationHandler){
								this.props.zonalMapCreationHandler(zonalValuesMap)
							} 
						}
					}
					arrayBuffer = {cache.arrayBuffer}
					factors = {cache.factors}
					goBack={() => this.goToSetting()}/>}
			</div>
		)
	}
}
