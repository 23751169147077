import React, { useEffect } from 'react';
import FollowUpArrow from '#/frontoffice/components/FollowUpArrow.jsx';
import { useLocation } from "react-router-dom";


function HomePageSection(props) {
	var firstSectionClass = "";
	if(props.first){
		firstSectionClass = "first-section";
	}

	const location = useLocation();

	useEffect(() => {
	  if (location.hash.includes("#")) {
		const element = document.getElementById(location.hash.replace("#", ""));
		if (element) {
		  setTimeout(() => {
			element.scrollIntoView({ behavior: "smooth", block: "center" });
		  }, 100); // Delay for page rendering
		}
	  }
	}, [location]);
	
    return (
	<section className={"page-section masthead "+firstSectionClass} style={{backgroundColor:props.backgroundColor, backgroundSize:"cover", backgroundAttachment:"fixed"}} id={props.id}>
		<div>
			<div className="row col-12 p-0 m-0 masthead align-items-center justify-content-around">
				
				<div className="col-12 p-0" style={{color:props.colorTitle}}>
					{!!!props.noHeader &&
						<>
							{props.title ? <h2 className="section-title text-center " style={{color:props.colorTitle}}>{props.title}</h2> : <></>}
							{/* <hr className="divider"/> */}
							<p className="section-subtitle text-center">{props.subtitle}</p>
						</>
					}
				</div>
				
				<div className="col-12 p-0" style={{backgroundColor:props.contentBackground}}>
                    {props.content}
				</div>		
				{/* <FollowUpArrow nextSection={props.nextSection} nextSectionName={props.nextSectionName} color={props.colorTitle}></FollowUpArrow> */}
			</div>	
		</div>
	</section>
    );
}

export default HomePageSection;

