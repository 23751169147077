import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import MidaLightTab from "#/frontoffice/tabs/MidaLightTab";
import BulletinClient from "#/lib/BulletinClient";
import MauMapComponent from "#/commons/mau/MauMapComponent";
import Skeleton from "react-loading-skeleton";
import FireRiskClient from "#/lib/FireRiskClient";
import MaibMapComponent from "#/commons/maib/MaibMapComponent";
import ForecastComponent from "#/commons/forecast/ForecastComponent";

export default class CurrentSituationComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            mauData: null,
            maibData: null,
            forecastData: null
        }
    }
    componentDidMount = () => {
        let promises = [this.getLastBulletinPromise('MAU'), this.getLastBulletinPromise('FORECAST')];

        FireRiskClient.getCampaign(
            (data) => {
                if (data.active){
                    promises.push(this.getLastBulletinPromise('MAIB'));
                }
                Promise.all(promises).then(([mauData, forecastData, maibData]) => {
                    this.setState({mauData, forecastData, maibData})
                })
            },
            (err) => {
				Promise.all(promises).then(([mauData, forecastData]) => {
                    this.setState({mauData, forecastData})
                })
                console.error('Error while retrieving Fire Rsik Campaign');
            }
        )
    }

    getLastBulletinPromise = bulletinTypeName => new Promise((resolve, reject) =>
        {
            BulletinClient.listBulletins(
                (obj) => {
                    console.log(bulletinTypeName + ' DATA', obj.data);
                    if (obj.data.length > 0) {
                        resolve(obj.data[0]);
                    } else {
                        resolve(null)
                    }
                },
                (err) => {
                    console.log('Error while retrieving ' + bulletinTypeName + ' data', err);
                    reject()
                },
                {
                    pageNumber: 1,
                    pageSize: 1,
                    bulletinTypeName
                }
            )
        }
    )

    render = () => {
        return (
            <div className="container-fluid p-0">
                <FrontofficeTabComponent mauData={this.state.mauData} maibData={this.state.maibData} forecastData={this.state.forecastData}/>
            </div>
        );
    }
}

const TabPanel = ({ children, value, index, ...other } ) =>
    (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const scrollableProps = (index) =>
    ({
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    });


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
            minHeight : "50vh"
        },
    }
}));

const CustomTab = withStyles((theme) => ({
    root: {
        color: 'white',
        fontWeight: theme.typography.fontWeightRegular,
        margin: "0.6rem",
        '&:hover': {
            color: 'white',
            opacity: 1,
        },
        '&$selected': {
            color: '#134367',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor:"#b7e6ff",
        },
        '&:focus': {
            color: '#134367',
        },
    },
    selected: {},
}))((props) => <Tab {...props} />);

const CustomAppBar = withStyles((theme) => ({
    root: {
        backgroundColor:"#0c6aaf"

    }
}))((props) => <AppBar position="static" color="default" {...props} />);


const FrontofficeTabComponent = ({mauData, maibData, forecastData}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <CustomAppBar style={{position: 'relative', zIndex: '2'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="#0c6aaf"
                    textColor="#0c6aaf"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable force tabs example"
                >
                    <CustomTab className='rounded' label={<b><b className='text-capitalize'>Messaggio</b> <b className='text-lowercase'>di Allertamento Unificato</b></b>} {...scrollableProps(0)}/>
                    <CustomTab className='rounded' label={<b className='text-capitalize'>Monitoraggio</b>} {...scrollableProps(1)}/>
					<CustomTab className='rounded' label={<b><b className='text-capitalize'>Previsioni</b> <b className='text-lowercase'>Meteo</b></b>} {...scrollableProps(2)}/>
                    {maibData && <CustomTab label={<b><b className='text-capitalize'>Messaggio</b> <b className='text-lowercase'>di Allertamento Incendi Boschivi</b></b>} {...scrollableProps(3)}/>}
					
                </Tabs>
            </CustomAppBar>

            <TabPanel value={value} index={0} className={classes.tab}>
                {mauData ? <MauMapComponent mapHeight='50vh' zoom={4} mauItem={mauData}/> : <Skeleton height={"50vh"} duration={8}/>}
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tab}>
                <MidaLightTab mauId={mauData && mauData.id} mauCreatedAt={mauData && mauData.createdAt}/>
            </TabPanel>
			<TabPanel value={value} index={2} className={classes.tab}>
			    {forecastData ? <ForecastComponent forecastItem={forecastData} /> : <Skeleton height={"50vh"} duration={8}/>}
            </TabPanel>
            {maibData && <TabPanel value={value} index={3} className={classes.tab}>
                <MaibMapComponent mapHeight='50vh' zoom={4} maibItem={maibData}/>
            </TabPanel>
			}

        </div>
    )
}
