import React from 'react'


import {COMBO_TYPES,} from "#/lib/MeasurementCategory";


import MeasurementsHeader from "#/commons/data/components/MeasurementsHeader"
import MeasurementsBody from "#/commons/data/components/MeasurementsBody"
import {AiOutlineRadarChart} from "react-icons/ai";


const VIEW_MODE = {
	NONE: "NONE",
	TABLE: "TABLE",
	AGGREGATED_TABLE: "AGGREGATED_TABLE",
	CHART: "CHART",
	AGGREGATED_CHART: "AGGREGATED_CHART",
	CROSS_VALIDATION: "CROSS_VALIDATION"
};


let defaultViewMode = {
	label: "",
	value: VIEW_MODE.NONE
}

let defaultAggregationFunction = {
	label: "",
	value: 0
}


let defaultAggregationInterval = {
	label: "",
	value: 'PUNTUAL'
}


export default class MeasurementsComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedViewMode: defaultViewMode,
			aggregationInterval: defaultAggregationInterval,
			aggregationFunction: defaultAggregationFunction,
			aggregationIntervalDisabled: true,
			aggregationFunctionDisabled: true,
			key : 1,

			compareList : [],
		}
	}

	componentDidMount() {
		



		this.setState({

			selectedViewMode : COMBO_TYPES.includes(this.props.selectedMeasure.value) ?  {
				label: "Grafico Combinato",
				value: VIEW_MODE.AGGREGATED_CHART,
				icon: <AiOutlineRadarChart className="mr-2"></AiOutlineRadarChart>
			} : {
				label : 'Grafico',
				value : VIEW_MODE.CHART,
				disabled : false
			},
			aggregationInterval: {
				label: "Misure Puntuali",
				value: 'PUNTUAL'
			},
			aggregationIntervalDisabled : COMBO_TYPES.includes(this.props.selectedMeasure.value),
			aggregationFunctionDisabled : true,
			stationNameBySensorCode : this.getStationNameBySensorCodeMap(),
			key: (this.state.key + 1) % 1000
		});
		/*	}*/
	}

	onSelectViewMode(data) {
		let	aggregationIntervalDisabled = true;
		let	aggregationFunctionDisabled = true;

		switch(data.value){
			case VIEW_MODE.NONE:
				break;
			case VIEW_MODE.TABLE:
				break;
			case VIEW_MODE.AGGREGATED_TABLE:
				aggregationIntervalDisabled = false;
				/*	if(this.props.selectedMeasure.value===MEASUREMENT_CATEGORY.P){
                        aggregationFunctionDisabled = true;
                    }
                    else{
                        aggregationFunctionDisabled = false;
                    }*/
				break;
			case VIEW_MODE.CHART:
				aggregationIntervalDisabled = false;
				break;
			case VIEW_MODE.AGGREGATED_CHART:
			case VIEW_MODE.CROSS_VALIDATION:
			default:
				break;
		}

		this.setState({
			selectedViewMode: data,
			aggregationInterval: defaultAggregationInterval,
			aggregationFunction: defaultAggregationFunction,
			aggregationIntervalDisabled: aggregationIntervalDisabled,
			aggregationFunctionDisabled: aggregationFunctionDisabled,
			key: (this.state.key + 1) % 1000
		})

	}

	onSelectAggregationInterval(data) {

		this.setState({
			aggregationInterval: data,
			key: (this.state.key + 1) % 1000
		})

	}

	onSelectAggregationFunction(data) {
		this.setState({
			aggregationFunction: data,
			key: (this.state.key + 1) % 1000
		})

	}
	updateCompareList(data) {

		this.setState({
			compareList: data,
			stationNameBySensorCode : this.getStationNameBySensorCodeMap(),
			key: (this.state.key + 1) % 1000,
		})
	}

	getStationNameBySensorCodeMap(){
		let mapToStationName = {
		}
		this.props.selectedMeasure.sensor.forEach(s=>{
			mapToStationName[s.sensorCode] = this.props.selectedStation.label
		})

		
		this.state.compareList.reduce((map,elem)=>{
			map[elem.measure.sensor[0].sensorCode] = elem.station.name
			return map;
		}, mapToStationName);

		return mapToStationName
	}

	render() {
		return <>
			<MeasurementsHeader
				resetInterval={(interval) => this.props.resetInterval(interval)}
				selectedMeasure={this.props.selectedMeasure}
				selectedStation={this.props.selectedStation}
				selectedViewMode={this.state.selectedViewMode}
				interval={this.props.interval}
				onSelectViewMode={(data) => this.onSelectViewMode(data)}
				aggregationInterval={this.state.aggregationInterval}
				aggregationFunction={this.state.aggregationFunction}
				aggregationIntervalDisabled = {this.state.aggregationIntervalDisabled}
				aggregationFunctionDisabled = {this.state.aggregationFunctionDisabled}
				onSelectAggregationInterval={(data) => this.onSelectAggregationInterval(data)}
				onSelectAggregationFunction={(data) => this.onSelectAggregationFunction(data)}
				stationsGeoJSON = {this.props.stationsGeoJSON}
				updateCompareList = {data => this.updateCompareList(data)}
				compareList = {this.state.compareList}
			></MeasurementsHeader>
			<MeasurementsBody
				selectedMeasure={this.props.selectedMeasure}
				selectedStation={this.props.selectedStation}
				interval={this.props.interval}
				aggregationInterval={this.state.aggregationInterval}
				aggregationFunction={this.state.aggregationFunction}
				selectedViewMode={this.state.selectedViewMode}
				dataKey={this.state.key+"_"+this.props.selectedMeasure.value}
				stationsGeoJSON = {this.props.stationsGeoJSON}
				compareList = {this.state.compareList}
				stationNameBySensorCode = {this.state.stationNameBySensorCode}
				allSensorsMap = {this.props.allSensorsMap}
				outflowScales = {this.props.outflowScales}
			></MeasurementsBody>
		</>

	}
}
