import React from 'react'

import Button from '@material-ui/core/Button';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";
import MosipClient from '#/lib/MosipClient'
import PolygonMapComponent from '#/commons/map/PolygonMapComponent';

import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'

import Grid from '@material-ui/core/Grid';
import '#/backoffice/style/SweetAlert.css';
import MosipThresholdForm from './components/MosipThresholdForm';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SaveButton} from '#/commons/components/forms/SaveButton';
import {TrashButton} from '#/commons/components/forms/TrashButton';
import AuthenticationService, {ROLE_ADMIN, ROLE_CONFIGURATOR} from "#/lib/AuthenticationService";


const ThresholdButton = ({thresholds, cell, clickCallback, disabled}) => {
	const rowData = cell._cell.row.data;

	let icon = !!thresholds[rowData.riskCode] && thresholds[rowData.riskCode].action!=="delete" ? <EditIcon></EditIcon> : <AddIcon></AddIcon>

	if(!!thresholds[rowData.riskCode] && !!thresholds[rowData.riskCode].dirty){
		cell.getElement().style.backgroundColor = "#bbbec2";
	}
	return (
		<>
			<Tooltip title={"Mostra Soglie"}>
				<Button
					className="justify-content-end ml-auto"
					variant="outlined"
					color="primary"
					startIcon={icon}
					disabled={disabled}
					onClick={(e) =>  clickCallback(rowData.riskCode, thresholds[rowData.riskCode]) }
				>Soglie</Button>
			</Tooltip>
		</>
	)
}

export default class MosipRisksPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 7,
		maxZoom: 12,
		width: "30vw",
		height: "70vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			risksArray: [],
			geoJSON: null,
			selectedRiskCode: null,
			thresholds : [],
			enableSave: false,
			showModal: false,
			showDialogThreshold : false,
			titleModal : null,
			selectedThreshold : null,
			replaceAll : false,
			mapKey: 1,
			tableKey : 1
		}
	}


	componentDidMount() {
		this.loadFromApi();
	}

	loadThresholdsFromApi(){
		let riskThresholdsPromise = new Promise((resolve, reject) => {
			MosipClient.getAllRiskThresholds((data) => { resolve({riskThresholds:data}); }, () => { console.log("ERROR Soglie"); });
		})

		let result = {};
		Promise.all([riskThresholdsPromise])
			.then( data =>{
				Object.assign(result, ...data);
				let thresholds = result.riskThresholds.reduce((obj, item)=> {obj[item.riskCode]=item; return obj}, {});
				this.setState({
					thresholds : thresholds,
					enableSave: false,
					showModal: false,
					tableKey : (this.state.tableKey+1)%1000,
					replaceAll : false
				});
			})
	}

	loadFromApi() {

		let risksPromise = new Promise((resolve, reject) => {
			MosipClient.getAllRisks((data) => { resolve({risks:data}); }, () => { console.log("ERROR Rischi"); });
		})

		let risksByMunicipalityPromise = new Promise((resolve, reject) => {
			MosipClient.getAllRisksByMunicipality((data) => { resolve({risksByMunicipality:data}); }, () => { console.log("ERROR Rischi"); });
		})

		let riskThresholdsPromise = new Promise((resolve, reject) => {
			MosipClient.getAllRiskThresholds((data) => { resolve({riskThresholds:data}); }, () => { console.log("ERROR Soglie"); });
		})

		let result = {};
		Promise.all([risksPromise, risksByMunicipalityPromise, riskThresholdsPromise])
			.then( data =>{
				Object.assign(result, ...data);
				let geoJSON = this.buildGeoJSONFromRiskArray(result.risks);
				let thresholds = result.riskThresholds.reduce((obj, item)=> {obj[item.riskCode]=item; return obj}, {});

				this.setState({
					risksArray: result.risksByMunicipality,
					geoJSON : geoJSON,
					selectedRiskCode: null,
					thresholds : thresholds,
					mapKey: (this.state.mapKey + 1) % 1000,
					tableKey : (this.state.tableKey+1)%1000,
					enableSave: false,
					showModal: false,
					replaceAll : false
				});
			})
	}

	buildGeoJSONFromRiskArray(data){
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Risks";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				CODICE_RISCHIO : value.riskCode,
				TYPE : value.riskType
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}


	getMunicipalitiesColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Codice Rischio", field: "riskCode", headerSort: true },
			{ title: "", field: "type", width:40 },
			{ title: "Comune", field: "comune", headerSort: true, headerFilter: true },
			{ title: "Provincia", field: "province", headerSort: true, headerFilter: "select", headerFilterParams: { "CZ": "Catanzaro", "CS": "Cosenza", "KR": "Crotone", "VV": "Vibo Valenzia", "RC": "Reggio Calabria" } },
			/* 			{ title: "Istat", field: "istatCode", headerSort: true },
             */			{ title: "Bacino", field: "basin"},
			{ title: "Fiume", field: "river"},
			{ title: "Descrizione", field: "riskDescription", formatter:"textarea" },
			/* 			{
                            title: "",
                            formatter: reactFormatter(<ShowButton clickCallback={(riskCode) => {console.log(riskCode)}}></ShowButton>),
                            headerSort: false,
                            hozAlign: "center",
                            vertAlign: "middle",
                            width: 50,
                        }, */
			{
				title: "",
				formatter: reactFormatter(<ThresholdButton disabled={!this.isConfigurator()} thresholds={this.state.thresholds} clickCallback={(riskCode,threshold) => this.openThresholdForm(riskCode,threshold)}></ThresholdButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 150,
			}
		]);
		return columns;
	}

	openThresholdForm(riskCode,selectedThresholdArrayItem){
		let selectedThreshold = {
			critical : {
				P1H:0,
				P3H:0,
				P6H:0,
				P12H:0,
				P24H:0,
			},
			levels : [0.3,0.5,0.8],
			riskCode : riskCode
		};

		if(!!selectedThresholdArrayItem && selectedThresholdArrayItem.action!=="delete"){
			selectedThreshold = {
				critical : {
					P1H:selectedThresholdArrayItem.criticalRain1h,
					P3H:selectedThresholdArrayItem.criticalRain3h,
					P6H:selectedThresholdArrayItem.criticalRain6h,
					P12H:selectedThresholdArrayItem.criticalRain12h,
					P24H:selectedThresholdArrayItem.criticalRain24h,
				},
				levels : [
					selectedThresholdArrayItem.lev1,
					selectedThresholdArrayItem.lev2,
					selectedThresholdArrayItem.lev3
				],
				riskCode : selectedThresholdArrayItem.riskCode,
				id : selectedThresholdArrayItem.id
			}
		}

		this.setState({
			showDialogThreshold : true,
			titleModal : "Codice Rischio : " + riskCode,
			selectedThreshold : selectedThreshold
		})
	}

	handleCloseDialog(){
		this.setState({
			showDialogThreshold : false,
			titleModal : null,
			selectedThreshold : null
		})
	}


	getTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "70vh",
			dataTree:true,
			dataTreeStartExpanded:true,
			dataTreeChildField:"relatedRisks", //look for the child row data array in the childRows field
			rowMouseOver:(e, row)=>{
				if (this.state.selectedRiskCode !== row.getData().riskCode){
					this.setState({
						selectedRiskCode: row.getData().riskCode
					})
				}
			},
			rowMouseLeave:(e, row)=>{
				if (!!this.state.selectedRiskCode){
					this.setState({
						selectedRiskCode: null
					})
				}
			},
		}
		return tabulatorOptions;
	}



	openModal() {
		this.setState({
			showModal: true
		})
	}

	onCloseModal() {
		this.setState({
			showModal: false
		})
	}

	saveThresholds(){
		if(this.state.replaceAll){
			console.log("replacingAll")
			MosipClient.replaceAllRiskThresholds(
				(data)=>{
					this.loadThresholdsFromApi();
				},
				(error)=>{
					console.log("Error Saving RiskThresholds")
				},
				Object.values(this.state.thresholds)
			)
		}
		else{

			let dirtyThresholds = Object.values(this.state.thresholds).filter(x=> x.dirty===true);
			console.log("Saving", dirtyThresholds)
			MosipClient.saveRiskThresholds(
				(data)=>{
					this.loadThresholdsFromApi();
				},
				(error)=>{
					console.log("Error Saving RiskThresholds")
				},
				Object.values(this.state.thresholds).filter(x=> x.dirty===true)
			)

		}
	}

	saveSingleThreshold(){
		let riskCode = this.state.selectedThreshold.riskCode
		let obj = {
			riskCode : riskCode,
			criticalRain1h : parseFloat(this.state.selectedThreshold.critical.P1H),
			criticalRain3h : parseFloat(this.state.selectedThreshold.critical.P3H),
			criticalRain6h : parseFloat(this.state.selectedThreshold.critical.P6H),
			criticalRain12h : parseFloat(this.state.selectedThreshold.critical.P12H),
			criticalRain24h : parseFloat(this.state.selectedThreshold.critical.P24H),
			lev1 : this.state.selectedThreshold.levels[0],
			lev2 : this.state.selectedThreshold.levels[1],
			lev3 : this.state.selectedThreshold.levels[2],
			aggregation : riskCode.startsWith("1") ? 'MAX' : 'AVG',
			dirty : true,
			id : null,
			action : "save"
		}

		let thresholdMap = this.state.thresholds;
		obj.id = !!thresholdMap[this.state.selectedThreshold.riskCode] ? thresholdMap[this.state.selectedThreshold.riskCode].id : null;
		thresholdMap[this.state.selectedThreshold.riskCode] = obj;
		this.setState({
			thresholds:thresholdMap,
			tableKey : (this.state.tableKey+1)%1000,
			enableSave : true,
			showDialogThreshold : false
		})
	}

	deleteSingleThreshold(){
		let riskCode = this.state.selectedThreshold.riskCode;
		let obj = {
			riskCode : riskCode,
			criticalRain1h : parseFloat(this.state.selectedThreshold.critical.P1H),
			criticalRain3h : parseFloat(this.state.selectedThreshold.critical.P3H),
			criticalRain6h : parseFloat(this.state.selectedThreshold.critical.P6H),
			criticalRain12h : parseFloat(this.state.selectedThreshold.critical.P12H),
			criticalRain24h : parseFloat(this.state.selectedThreshold.critical.P24H),
			lev1 : this.state.selectedThreshold.levels[0],
			lev2 : this.state.selectedThreshold.levels[1],
			lev3 : this.state.selectedThreshold.levels[2],
			aggregation : riskCode.startsWith("1") ? 'MAX' : 'AVG',
			dirty : true,
			id : null,
			action : "delete"
		}

		let thresholdMap = this.state.thresholds;
		if(!!thresholdMap[this.state.selectedThreshold.riskCode] && !!thresholdMap[this.state.selectedThreshold.riskCode].id){
			obj.id = !!thresholdMap[this.state.selectedThreshold.riskCode] ? thresholdMap[this.state.selectedThreshold.riskCode].id : null;
			thresholdMap[this.state.selectedThreshold.riskCode] = obj;
		}
		else{
			delete thresholdMap[this.state.selectedThreshold.riskCode]
		}


		this.setState({
			thresholds:thresholdMap,
			tableKey : (this.state.tableKey+1)%1000,
			enableSave : true,
			showDialogThreshold : false
		})
	}

	onChangeLevels(levels) {

		let threshold = this.state.selectedThreshold;
		threshold.levels = levels;
		this.setState({
			selectedThreshold : threshold
		})

	}
	onChangeCritical = (name, value) => {
		let threshold = this.state.selectedThreshold;
		threshold.critical[name] = value;
		this.setState({
			selectedThreshold : threshold
		})
	}

	handleChangeStatusThresholds = ({ file }, status) => {
		if (status === "revoved") {
			this.setState({
				thresholds: [],
			})
			this.loadThresholdsFromApi();
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let arrayOfLines = fileContent.match(/[^\r\n]+/g);

				let thresholds = [];

				arrayOfLines.forEach((line,index)=>{
					if(index!==0){

						let tokens = line.split(";");
						let riskCode = tokens[0];
						let currentThreshold = {
							riskCode : riskCode,
							criticalRain1h : parseFloat(tokens[1].replace(",",".")),
							criticalRain3h : parseFloat(tokens[2].replace(",",".")),
							criticalRain6h : parseFloat(tokens[3].replace(",",".")),
							criticalRain12h : parseFloat(tokens[4].replace(",",".")),
							criticalRain24h : parseFloat(tokens[5].replace(",",".")),
							lev1 : parseFloat(tokens[7].replace(",",".")),
							lev2 : parseFloat(tokens[8].replace(",",".")),
							lev3 : parseFloat(tokens[9].replace(",",".")),
							aggregation : riskCode.startsWith("1") ? 'MAX' : 'AVG',
							dirty : true,
							id:null
						}
						thresholds.push(currentThreshold);
					}
				})


				let thresholdsMap = thresholds.reduce((obj, item)=> {obj[item.riskCode]=item; return obj}, {});
				this.setState({
					thresholds: thresholdsMap,
					tableKey : (this.state.tableKey+1)%1000,
					enableSave: true,
					showModal: false,
					replaceAll : true
				})
			};
			reader.onerror = (event) => {
				this.setState({
					thresholds: [],
				})
				this.loadThresholdsFromApi();
			};
			reader.readAsText(file);
		}


	}

	polygonStyle(feature,latlng){
		let style = null;
		let isSelected = !!this.state.selectedRiskCode && (feature.properties["CODICE_RISCHIO"] === this.state.selectedRiskCode);
		if (isSelected) {
			if(feature.properties.TYPE==="A"){
				style = {
					fillColor: "red",
					weight: 0.2,
					opacity: 1,
					color: "red",
					fillOpacity: 0.5
				};
			}
			else {

				style = {
					fillColor: "#324aa8",
					weight: 0.2,
					opacity: 1,
					color: "blue",
					fillOpacity: 0.5
				};
			}

		}
		else {
			style = {
				fillColor: "#fff0",
				weight: 0.2,
				opacity: 1,
				color: "#fff0",
				fillOpacity: 0
			};
		}

		return style;
	}
	isConfigurator = () => AuthenticationService.haveRolesPermssions([ROLE_CONFIGURATOR, ROLE_ADMIN]);


	render() {



		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className="row mt-4">

						<div className="col-4">
							<PolygonMapComponent
								selectedFieldToCheck={"CODICE_RISCHIO"}
								selected={this.state.selectedRiskCode}
								geoJSON={this.state.geoJSON}
								key={this.state.mapKey}
								reload={() => this.loadFromApi()}
								mapOptions={this.mapOptions}
								showTileLayer={true}
								polygonStyle = {(feature,latlng)=>this.polygonStyle(feature,latlng)}
							></PolygonMapComponent>
						</div>
						<div className="col-8" >
							<ReactTabulator
								ref={ref => (this.tableRef = ref)}
								columns={this.getMunicipalitiesColumns()}
								data={this.state.risksArray}
								options={this.getTabulatorOptions()}
								key={"table_" + this.state.tableKey}
							/>
							<div className="d-flex">
								<Button
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									startIcon={<PublishIcon />}
									onClick={(e) => this.openModal()}
									disabled={!this.isConfigurator()}
								>Carica Nuove Soglie</Button>
							</div>
							<div className="d-flex mt-1">
								<Button
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									startIcon={<SaveIcon />}
									disabled={!this.state.enableSave}
									onClick={(e) => this.saveThresholds()}
								>Salva Soglie</Button>
							</div>

						</div>
					</div>

				</div>

				<RiskThresholdForm
					open={this.state.showDialogThreshold}
					title={this.state.titleModal}
					onClose = {()=>this.handleCloseDialog()}
					threshold={this.state.selectedThreshold}
					onChangeLevels = {(levels)=>this.onChangeLevels(levels)}
					onChangeCritical = {(name, value)=>this.onChangeCritical(name, value)}
					saveSingleThreshold = {()=>this.saveSingleThreshold()}
					deleteSingleThreshold = {()=>this.deleteSingleThreshold()}
				/>


				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "50vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3 >Import Soglie da file</h3>
						</div>

						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<Dropzone
									onChangeStatus={this.handleChangeStatusThresholds}
									accept=".csv"
									maxFiles={1}
									inputContent="Inserisci il file delle Soglie in formato CSV"
									styles={{
										dropzone: { overflow: "hidden", width: "30vw" },
										dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
										inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
									}}
								/>
							</div>
						</div>


					</div>

				</Modal>
			</>
		)
	}
}



const RiskThresholdForm = (props) =>  {

	const submitForm = () => {
		props.saveSingleThreshold();

	}

	const remove = () => {
		props.deleteSingleThreshold();

	}

	const iconButtonStyle = {position: 'absolute',right: 1,top: 1,color: 'grey'};

	return (

		<Dialog open={props.open} onClose={()=>props.onClose()} key={props.title} aria-labelledby="form-dialog-title" height="100%">
			<DialogTitle id="form-dialog-title" style={{paddingTop: 5}} disableTypography={true}>
				<h2>{props.title}</h2>
				<IconButton aria-label="close" style={iconButtonStyle} onClick={()=>props.onClose()}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<MosipThresholdForm
							min={0}
							max={1}
							step={0.01}
							threshold={props.threshold}
							onChangeLevels={(levels)=>props.onChangeLevels(levels)}
							onChangeCritical = {(name,value)=>props.onChangeCritical(name,value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<SaveButton variant="contained" type="submit" onClick={() => submitForm()}/>
				<TrashButton variant="contained" type="submit" onClick={() => remove()}/>
			</DialogActions>
		</Dialog>

	)
}


