import React, { Component } from 'react';
import { Card, Form, InputGroup, Row, Button } from 'react-bootstrap';
import { MdPermIdentity, MdLockOutline } from 'react-icons/md';
import AuthenticationService, { ROLE_OFFICER, ROLE_ADMIN } from '#/lib/AuthenticationService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link, withRouter } from 'react-router-dom';
import WorkItemClient from "#/lib/WorkItemClient";
import SponsorPage from '#/commons/components/SponsorPage';
import { useAuth } from "react-oidc-context";

const ReactSwal = withReactContent(Swal)
const fireAlert = (data) => {

    let expiring = data.find(wi => wi.level === 'EXPIRING');
    let warningCount = !!expiring ? expiring.number : 0;
    let expired = data.find(wi => wi.level === 'EXPIRED');
    let errorCount = !!expired ? expired.number : 0;
    if (warningCount + errorCount > 0) {
        let message = 'Trovate ';
        message += errorCount > 0 ? errorCount + ' attività scadute e ' : '';
        if (warningCount > 0) {
            message += warningCount + ' attività in scadenza';
        } else {
            message = message.replace(' e ', '');
        }
        ReactSwal.fire(message, 'Per maggiori dettagli, consultare il giornale di sala', 'warning');
    }

}
class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            autenticated: AuthenticationService.isValidSession(null),
            successRedirectUrl: this.props.path,
            resetpassword: false
        }
    }



    showForgotPasswordForm = () => {
        this.setState({ resetpassword: true });
    }

    showLoginForm = () => {
        this.setState({ resetpassword: false });
    }

    handleResetPassword = () => {
        let params = {
            username: this.state.username
        }

        AuthenticationService.resetPassword(
            (result) => {
                ReactSwal.fire({
                    title: 'Password modificata correttamente',
                    text: 'La nuova password temporanea è stata spedita via email.',
                    icon: 'success',
                    confirmButtonText: 'Conferma',
                }).then((isConfirm) => {
                    this.setState({
                        resetpassword: false
                    })
                })
            },
            (msg) => {

                ReactSwal.fire({
                    title: 'Errore nel reset password',
                    text: msg,
                    icon: 'error'
                })
                //ReactSwal.fire('Errore nel reset password', msg, 'error')
                this.setState({
                    autenticated: false
                })
                console.error(msg);
            },
            params
        )
    }


    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }



    isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);
    loginClicked = () => {
        let params = {
            username: this.state.username,
            password: this.state.password
        }
        AuthenticationService.authenticate(
            (result) => {
                ReactSwal.fire({
                    title: 'Accesso riuscito',
                    text: 'Cliccare su CONFERMA per essere redirezionati sulla home del backoffice',
                    icon: 'success',
                    confirmButtonText: 'Conferma',
                }).then((isConfirm) => {

                    if (this.isOfficer()) {
                        WorkItemClient.getWorkItemsState(
                            (data) => {
                                if (data.length > 0) {
                                    /* fireAlert(data);*/
                                    if (!!this.props.setworkItems) {
                                        this.props.setworkItems(data);
                                    }
                                    this.props.history.push("/backoffice")
                                }
                                localStorage.setItem("workItemsState", JSON.stringify(data));

                            },
                            () => console.log("Error while retrieving work items data")
                        )
                    }
                    this.setState({
                        autenticated: true
                    })
                })
            },
            (msg) => {
                ReactSwal.fire('Accesso fallito', 'Credenziali non corrette', 'error')
                this.setState({
                    autenticated: false
                })
            },
            params
        )
    }



    render() {
        const iconSize = 25;
        const disableSubmit = !(!!this.state.username && !!this.state.password);
        console.log("Rendering LoginComponent")
        if (this.state.resetpassword) {
            return (

                <div className="generic-page mt-2">
                    <div className="mt-4 container" style={{ maxWidth: "640px" }}>
                        <Card>
                            <Card.Header>
                                <Card.Title className="text-center"><h4 className="mb-4 mt-4">Reset Password</h4></Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="justify-content-center">
                                    <p className="text-success">[Immettere il proprio username]</p>
                                </Row>
                                <Form>
                                    <Form.Group controlId="validationCustomUsername">
                                        <Form.Label>Username</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><MdPermIdentity size={iconSize} title="Username" /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control type="text" placeholder="Username" name="username" defaultValue={this.state.username} required onChange={e => this.handleChange(e)} />
                                            <Form.Control.Feedback type="invalid">Inserire la username.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>

                                <Row className="">
                                    <Link to="#" onClick={(e) => this.showLoginForm()} className="btn btn-link">Vai alla login</Link>
                                </Row>

                                <Row className="justify-content-center">
                                    <Button style={{ margin: "1vw", minWidth: "10vw" }} size="lg" variant="primary" disabled={disableSubmit} onClick={(e) => this.handleResetPassword(e)} >Invia</Button>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </div>


            )

        }

        return (

            <div className="generic-page mt-2">
                <div className="mt-4 container" style={{ maxWidth: "640px" }}>
                    <Card>
                        <Card.Header>
                            <Card.Title className="text-center"><h4 className="mb-4 mt-4">Accesso utente</h4></Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className="justify-content-center">
                                <p className="text-success">[Accedere come admin/admin o come gverdi/gverdi]</p>
                            </Row>
                            <Form>
                                <Form.Group controlId="validationCustomUsername">
                                    <Form.Label>Username</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><MdPermIdentity size={iconSize} title="Username" /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type="text" placeholder="Username" name="username" defaultValue={this.state.username} required onChange={e => this.handleChange(e)} />
                                        <Form.Control.Feedback type="invalid">Inserire la username.</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="validationCustomPassword">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><MdLockOutline size={iconSize} title="Password" /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type="password" placeholder="Password" name="password" defaultValue={this.state.password} required onChange={e => this.handleChange(e)} />
                                        <Form.Control.Feedback type="invalid">Inserire la password.</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Form>

                            <Row className="">
                                <Link to="#" onClick={(e) => this.showForgotPasswordForm()} className="btn btn-link">Password dimenticata?</Link>
                            </Row>

                            <Row className="justify-content-center">
                                <Button style={{ margin: "1vw", minWidth: "10vw" }} size="lg" variant="primary" disabled={disableSubmit} onClick={(e) => this.loginClicked(e)} >Login</Button>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </div>


        )
    }

}

export default withRouter(LoginComponent)

