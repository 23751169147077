import React, {useState, useEffect} from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import 'moment/locale/it';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import DataRequestClient from "#/lib/DataRequestClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {reactFormatter, ReactTabulator} from "react-tabulator";
import Skeleton from "react-loading-skeleton";
import DateUtils from "#/lib/DateUtils";
import {FaFileCsv} from "react-icons/fa";
import ExportButton from "#/commons/components/forms/ExportButton";
import {BackingDataTable} from "#/commons/components/BackingDataTable";
import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import MeasurementDataClient from "#/lib/MeasurementsDataClient";
import moment from "moment";
import AuthenticationService from "#/lib/AuthenticationService";
import {MEASUREMENT_LABELS} from "#/lib/MeasurementCategory";
import {MeasurementAggregationDisplayValues} from "#/lib/MeasurementAggregation";

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
        Swal.showLoading()
    },
});

const statusColors = {
    FULFILLED : "rgba(16,187,34,0.8)",
    PENDING: "rgba(239,221,61,0.8)"
}
const statusLabels = {
    FULFILLED : 'Dati inviati',
    PENDING: 'In attesa'
}

const ConsultaButton = (props) =>  (<Button startIcon={<WarningIcon />} {...props}
                                            onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>Consulta</Button>);

const RequestManagerPage = () => {
    const [dataRequests, setDataRequests] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [showModal, toggleModal] = useState(false);

    useEffect(() => {
        if (selectedData){
            toggleModal(true);
        }}, [selectedData]);

    useEffect(() => {if (!showModal){
        setSelectedData(null);
    }}, [showModal]);

    useEffect(() => {
        getAllData()
    }, []);
    const getAllData = () => {
        loadingSwal.fire('Caricamento in corso...')
        DataRequestClient.getDataRequestsByStatus(
            dataRequests => {
                loadingSwal.close();
                setDataRequests(dataRequests);
            },
            err => {
                console.error(err);
                loadingSwal.close();
            },
            'ALL'
        )
    }
    const updateDataRequest = data => {
        data.fulfilmentTimestamp = moment().valueOf();
        data.employee = AuthenticationService.getLoggedInName() + ' ' + AuthenticationService.getLoggedInSurname();
        DataRequestClient.save(() => {
                loadingSwal.close();
                ReactSwal.fire('Dati inviati','Funzionario: ' + data.employee + ' - Data invio: ' +  moment(data.fulfilmentTimestamp).format("DD/MM/yyyy HH:mm"),'success')
                    .then(val => {
                        getAllData();
                    } )
            },
            (err) => {
                loadingSwal.close();
                ReactSwal.fire('Errore','Richiesta non inviata','error');
            },
            data)
    }
    const getColumns = () => [
        { title: "Nome", field: "name" },
        { title: "Cognome", field: "surname" },
        { title: "Data richiesta", field: "requestTimestamp", formatter: cell => cell.getValue() ? moment(cell.getValue()).format("DD/MM/yyyy HH:mm"): '' },
        { title: "Data invio", field: "fulfilmentTimestamp", formatter: cell => cell.getValue() ? moment(cell.getValue()).format("DD/MM/yyyy HH:mm") : '' },
        {title: "Responsabile invio", field: "employee"},
        { title: "Indirizzo E-mail", field: "emailAddress" },
        {
            title: "Stato",
            field: "status",

            formatter: (cell) => {
                cell.getElement().style.backgroundColor = statusColors[cell.getValue()]
                return statusLabels[cell.getValue()];
            },
            editor: "select",
            editorParams: {
                values: statusLabels
            },
            editable: cell => cell.getValue() === 'PENDING',
            cellEdited: (cell) => {
                if (cell.getValue() === 'FULFILLED'){
                    updateDataRequest(cell._cell.row.data)
                }
            }
        },
        {
            title: "",
            formatter: reactFormatter(<ConsultaButton clickCallback={(data) => setSelectedData(data)} variant="outlined" size="small"/>  ),
            headerSort:false,
            hozAlign:"center",
            vertAlign: "middle",
            minWidth :"150px"
        }
    ];

    const getDataByInterval = (interval, measures, callback) => {
        let params = {
            codes: measures.map(m => m.sensorCode),
            start : interval.start.valueOf(),
            end : interval.end.valueOf(),
        }
        if (selectedData.jsonData.aggregation === 'PUNTUAL' || selectedData.jsonData.aggregation === 'NONE') {
            MeasurementDataClient.getMultiSensorPagedByTime(
                (data) => {

                    callback(data);
                },
                (err) => {
                },
                params
            )
        } else {
            params.aggregationInterval = selectedData.jsonData.aggregation;
            MeasurementDataClient.getAggregatedMultiSensorPagedByTime(
                (data) => {
                    callback(data);
                },
                (err) => {
                },
                params
            )
        }
    }

    const getTabulatorData = () => selectedData.jsonData.measures.map(measure => ({
        stationLabel : selectedData.jsonData.stationNameBySensorCode[measure.sensorCode],
        sensorCategory : MEASUREMENT_LABELS[measure.sensorCategory],
        sensorCode: measure.sensorCode
    }));

    const getTabulatorColumns = () => [{
        title: "Nome stazione",
        field: "stationLabel"
    },{
        title: "Categoria sensore",
        field: "sensorCategory"
    },{
        title: "Codice sensore",
        field: "sensorCode"
    }];
    return (
        <>
            <div className="generic-page container-fluid data-page">
                <h3>Richieste in attesa</h3>
                {dataRequests ? <ReactTabulator
                        columns={getColumns()}
                        data={dataRequests}
                        options={{
                            layout: "fitColumns",
                            height: "70vh"
                        }}
                        key={"table_" + JSON.stringify(dataRequests)}
                    /> :
                    <Skeleton height={"70vh"} duration={8} />
                }

                <Modal key={'newExecution'}
                       style={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'
                       }}
                       open={showModal}
                       onClose={() => toggleModal(false)}
                       aria-labelledby="simple-modal-title"
                       aria-describedby="simple-modal-description"
                >
                    <div
                        style={{
                            background: "white",
                            width: "50vw",
                            height: "70vh",
                            borderRadius: '15px'
                        }}

                    >
                        <div className='w-100 d-flex justify-content-end'>
                            <IconButton aria-label="close"
                                        onClick={() => toggleModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className="d-flex justify-content-center">
                            <h2>Scarica i file da inviare al richiedente</h2>
                        </div>

                        <div className="d-flex h-80">
                            <div className="col-sm-12 my-auto">
                                {selectedData && <>
                                    <div className="row">
                                        <div className="col-4">
                                            <h3 className="m-0">Data inizio:</h3> {moment(selectedData.jsonData.interval.start).format('L')}
                                        </div>
                                        <div className="col-4">
                                            <h3 className="m-0">Data fine:</h3> {moment(selectedData.jsonData.interval.end).format('L')}
                                        </div>
                                        <div className="col-4">
                                            <h3 className="m-0">Aggregazione richiesta:</h3> {MeasurementAggregationDisplayValues[selectedData.jsonData.aggregation]}
                                        </div>
                                    </div>
                                    <h3>Sensori selezionati per il download:</h3>
                                    <ReactTabulator
                                        columns={getTabulatorColumns()}
                                        data={getTabulatorData()}
                                        options={{height: "35vh"}}
                                        key={"table_preview"}
                                    />
                                    {/*<div style={{height: '40vh', overflowY:'auto'}} className="scrollbar scrollbar-primary my-4">
                                        <table>
                                            <tr>
                                                <th className="pr-4"><h3>Nome stazione</h3></th>
                                                <th className="pr-4"><h3>Categoria sensore</h3></th>
                                                <th className="pr-4"><h3>Codice sensore</h3></th>
                                            </tr>
                                            {selectedData.jsonData.measures.map((measure, i) => (
                                                <tr style={{backgroundColor: i % 2 === 0 ? '#bef5cf' : '#fff'}}>
                                                <td className="pr-4">{selectedData.jsonData.stationNameBySensorCode[measure.sensorCode]}</td>
                                                <td className="pr-4">{MEASUREMENT_LABELS[measure.sensorCategory]}</td>
                                                <td className="pr-4">{measure.sensorCode}</td>
                                            </tr>))}
                                        </table>
                                    </div>*/}
                                    <BackingDataTable
                                        ref={React.createRef()}
                                        aggregation={selectedData.jsonData.aggregation}
                                        measures={selectedData.jsonData.measures}
                                        interval={{
                                            start: moment(selectedData.jsonData.interval.start),
                                            end: moment(selectedData.jsonData.interval.end)
                                        }}
                                        stationNameBySensorCode={selectedData.jsonData.stationNameBySensorCode}
                                        getDataByInterval={(interval, measures, callback) => getDataByInterval(interval, measures, callback)}
                                    />
                                </>}
                            </div>
                        </div>
                        <br/>
                    </div>
                </Modal>


            </div>
        </>
    )
}

export default RequestManagerPage;
