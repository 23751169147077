import {properties} from '#/properties.js';
import APIUtils from "#/lib/APIUtils";

export default class MeasurementsDataClient {

	static getPuntualData(okCallback, errCallback, params){
		let url = properties.url.intervalData;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static getIntervalStats(okCallback, errCallback, params){
		let url = properties.url.intervalStats;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static getDataForChart(okCallback, errCallback, params, numOfPointsForEachSensor){
		let url = properties.url.dataForPuntualChart + "/" + numOfPointsForEachSensor;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getData(okCallback, errCallback, params) {
		let url = properties.url.data;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static validateData(okCallback, errCallback, level, params) {
		let url = properties.url.validate+"/"+level;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static updateData(okCallback, errCallback, params) {
		let url = properties.url.updateData;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static saveLockData(okCallback, errCallback, params) {
		let url = properties.url.saveLockData;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static getLockData(okCallback, errCallback, sensorCode) {
		let url = new URL(properties.url.getLockData + "/" + sensorCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getLastValuesGeoJsonData(okCallback, errCallback, params) {
		let url = properties.url.lastValuesGeoJsonData;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);	
	}

	static getMidaGeoJson(okCallback, errCallback, instant) {
		let url = `${properties.url.midaGeoJson}${!!instant ? `?instant=${instant}` : ``}`;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getMidaLightGeoJson(okCallback, errCallback) {
		let url = properties.url.midaLightGeoJson;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getCrossValidationList = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.crossValidation);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static getMultiSensorPagedByTime = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.multiSensorData);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static exportMultiSensorCSV = (okCallback, errCallback, filename, params) => {
		let url = new URL(properties.url.multiSensorDataExport);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.downloadFile(url, fetch_options, filename, okCallback, errCallback);
	};
	static getAggregatedMultiSensorPagedByTime = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.aggregatedMultiSensorData);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static exportAggregatedMultiSensorCSV = (okCallback, errCallback, filename, params) => {
		let url = new URL(properties.url.aggregatedMultiSensorDataExport);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.downloadFile(url, fetch_options, filename, okCallback, errCallback);
	};

	static editMeasurementsByCommandList = (okCallback, errCallback, commandList) => {
		let url = new URL(properties.url.editMeasurementsByCommandList);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(commandList)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};
	

}
