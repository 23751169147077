import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";


import { Hooks, IdentityClient } from "@asgardio/oidc-js";


import environment from 'environment';
import { Button } from '@material-ui/core';

const authClient = IdentityClient.getInstance();


function ProtectedPage() {

	const [authenticateState, setAuthenticateState] = useState({});
	const [isAuth, setIsAuth] = useState(sessionStorage.getItem("username"));

	authClient.on(Hooks.SignIn, (response) => {
		setAuthenticateState({
			...authenticateState,
			displayName: response.displayName,
			email: response.email ? response.email[0] : "",
			username: response.username,
			isAuth: true

		});

		setIsAuth(true);
		sessionStorage.setItem("isInitLogin", "false");
	});

	authClient.on(Hooks.SignOut, () => {
		setIsAuth(false);
		sessionStorage.setItem("isInitLogin", "false");
	});

	const handleLogin = () => {
		// Add a check property to the session, so we can recall sign-in method upon redirect with authorization code.
		// authorization code grant type flow
		sessionStorage.setItem("isInitLogin", "true");
		authClient.signIn();
	};

	const handleLogout = () => {
		authClient.signOut();
	};

    const getIsInitLogin = () => {
        if (sessionStorage.getItem("isInitLogin") === "true") {
            return true;
        }
        else {
            return false;
        }
    };

	useEffect(() => {
		console.log("ENV ", environment);

		authClient.initialize(environment);

		// Check if the page redirected by the sign-in method with authorization code, if it is recall sing-in method to
		// continue the sign-in flow
		if (JSON.parse(sessionStorage.getItem("isInitLogin"))) {
			authClient.signIn();
		} else {

			if (sessionStorage.getItem("username")) {

				setAuthenticateState({
					...authenticateState,
					displayName: sessionStorage.getItem("display_name"),
					email: JSON.parse(sessionStorage.getItem("email")) ?
						JSON.parse(sessionStorage.getItem("email"))[0] : "",
					username: sessionStorage.getItem("username"),
					isAuth: true
				});

				setIsAuth(true);
			}
		}
	}, [isAuth]);



	return (
		<>
			{
			authenticateState.isAuth ?
				<div className="generic-page" style={{ marginTop: "10vh", minHeight: "120vh", backgroundColor: "white" }}>
					Sono loggato  <br/>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							handleLogout();
						}}
					>
						Logout
					</Button>
				</div>
				:
				(getIsInitLogin()) ?
					<div className="generic-page" style={{  marginTop: "10vh", minHeight: "120vh", backgroundColor: "white" }}>
						<div >mi sto loggando ...... Loading ...</div>
					</div>
					:
					<div className="generic-page" style={{  marginTop: "10vh", minHeight: "120vh", backgroundColor: "white" }}>
						Non sono loggato <br/>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								handleLogin();
							}}
						>
							Login
						</Button>
									
					</div>

			}
		</>
	);

}

export default ProtectedPage;
