import React from "react";
import ReactApexChart from "react-apexcharts";

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { SaveButton } from "#/commons/components/forms/SaveButton";
import { ResetButton } from "#/commons/components/forms/ResetButton";

import Modal from "@material-ui/core/Modal";
import "react-dropzone-uploader/dist/styles.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import FireRiskClient from "#/lib/FireRiskClient";

const TABULATOR_OPTIONS = {
  data: [],
  height: "55vh"
}

const CHART_TEMPLATE = {
  series: [{
    name: "",
    data: []
  }],
  options: {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: [ 'red'],
        shadeIntensity: 1,
        type: 'vertical',
        opacityFrom: 1,
        opacityTo: 1
      }
    },
    yaxis: {
      max : 2
    },

  },

};

function GraphButton(props) {
  return (
    <Tooltip title={"Visualizza grafico"}>
      <IconButton
        color="primary"
        aria-label="visualizza grafico"
        component="span"
        onClick={(e) => {
          props.clickCallback(props.cell._cell.row.data);
        }}
      >
        <ShowChartIcon />
      </IconButton>
    </Tooltip>
  );
}

function UpDownIcon(props) {
  let cell= props.cell;
  let row =cell._cell.row;
  const cell_data = row.data;
  return (
    <Tooltip title={cell_data.descendant ? "Decrescente" : "Crescente"}>
      <IconButton
        color="primary"
        aria-label={cell_data.descendant ? "Decrescente" : "Crescente"}
        component="span"
        onClick={(e) => props.clickCallback(props.cell)}>
        {cell_data.descendant ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </IconButton>
    </Tooltip>
  );
}

const calculateFactorM = (a, b, isDescendant) => {
  const dividendo = isDescendant ? -1 : 1;
  const divisore = b-a;
  return dividendo / divisore;
}

const calculateFactorQ = (m, a, b, isDescendant) => {
  //se desc  => q = 1 - (m * a)
  //se  asc  => q = 1 - (m * b)
  const t = isDescendant ? m*a : m*b;
  return 1-t;
}

// y = mx + q
const getPlotPoints = (a, b, isDescendant, xStep) => {
  let points = [];
  let x = 0;
  if (b === a) {
    //let p = isDescendant ? points.push([x, 1]) : points.push([x, 0]);
  } else {
    while (x <= a) {
        //let p = isDescendant ? points.push([x, 1]) : points.push([x, 0]);
        x += xStep;
        x = Math.round((x + Number.EPSILON) * 100) / 100;
    }
    while (x <= b) {
        const m = calculateFactorM (a, b, isDescendant);
        const q = calculateFactorQ (m, a, b, isDescendant);
        const y = Math.round(((m*x + q) + Number.EPSILON) * 100) / 100;
        points.push([x, y]);
        x += xStep;
        x = Math.round((x + Number.EPSILON) * 100) / 100;
    }
    /*
    while (x <= b+2*xStep) {
      let p = isDescendant ? points.push([x, 0]) : points.push([x, 1]);
      x += xStep;
      x = Math.round((x + Number.EPSILON) * 100) / 100;
    }
    */
  }

  return points;
}

const ReactSwal = withReactContent(Swal);

export default class FireRiskConfigPage extends React.Component {
  static whyDidYouRender = false;
  FIRERISK_TABLE_COLUMNS = [
    { title: "Layer", field: "item", headerSort: true, hozAlign: "left", vertAlign: "middle"},
    { title: "Peso", field: "weight", editor:"input", hozAlign: "right", vertAlign: "middle", validator:[ "numeric", "required", "min:0"], cellEdited:(cell)=>{this.setCellColor(cell, "#ffbe33")} },
    { title: "A", field: "a", editor:"input", hozAlign: "right", vertAlign: "middle", validator:[ {type:(cell, value)=>{return (value <= parseFloat(cell._cell.row.data.b))}}, "numeric", "required", "min:0"], cellEdited:(cell)=>{this.setCellColor(cell, "#ffbe33")} },
    { title: "B", field: "b", editor:"input", hozAlign: "right", vertAlign: "middle",validator:[ {type:(cell, value)=>{return (parseFloat(cell._cell.row.data.a) <= value)}}, "numeric", "required", "min:0"], cellEdited:(cell)=>{this.setCellColor(cell, "#ffbe33")} },
    {
      title: "Crescente / Decrescente",
      field: "descendant",
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      formatter: reactFormatter(<UpDownIcon clickCallback={(cell)=>this.handleChangeAscDesc(cell)}></UpDownIcon>),
    },
    {
      title: "Visualizza grafico",
      formatter: reactFormatter(
        <GraphButton clickCallback={(elem) => this.showChart(elem)}></GraphButton>
      ),
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      width: 150,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      changedData : [],
      showModal: false,
      tableKey: 1,
      tableData: [],
      chart : {}
    };
  }

  componentDidMount() {
    this.getTableData();
  }

  getTableData = () => {
    FireRiskClient.getConfigList(
      (result) => {
          this.setState({tableData : result, changedData : []});
      },
      () => {
          console.log("ERROR retrieving fire risk configuration list");
      }
    );
  }

  setCellColor = (cell, color) => {
    const cell_data = cell._cell.row.data;
    let changedData = this.state.changedData;
    if (changedData.length > 0) {
      changedData.find( (element, index) => {
        if (element.id === cell_data.id) {
          changedData[index] = cell_data;
        } else {
          changedData.push(cell_data);
        }
        return true;
      });
    } else {
      changedData.push(cell_data);
    }
    cell.getElement().style.backgroundColor = color;
    this.setState({changedData : changedData});
  }

  openModal = (data, chart) => {
    this.setState({
      showModal: true,
      modalData : data,
      chart : chart
    });
  };

  onCloseModal = () => {
    this.setState({
      showModal: false,
      modalData : undefined
    });
  };

  showChart = (cellData) => {
    let step = parseFloat(cellData.b) - parseFloat(cellData.a) < 1 ? 0.1 : 1;
    const points = getPlotPoints(parseFloat(cellData.a), parseFloat(cellData.b), cellData.descendant, step);
    let chart  = CHART_TEMPLATE;
    chart.series[0].data = points;
    this.openModal(cellData, chart);
  }

  handleChangeAscDesc = (cell) => {
    cell._cell.row.height=43;
    const cell_data = cell._cell.row.data;
    cell_data.descendant = !cell_data.descendant;
    if(!!this.tableRef){
      this.tableRef.table.updateData([{...cell_data}]);
      this.setCellColor(cell, "#ffbe33");
    }
  }

  saveAllEvents = () => {
    let changedData = this.state.changedData;
    if (changedData.length > 0) {
      FireRiskClient.saveAll (
        (result) => {
          ReactSwal.fire('Modifiche salvate correttamente', '', 'success');
          this.getTableData();
        },
        (msg) => {
          ReactSwal.fire('Errore durante il salvataggio dati', '', 'error');
        },
        changedData
      )
    }
  }

  render() {
    return (
      <div className="generic-page container-fluid data-page">
        <div className="row justify-content-center">
          <h2>Configurazione Rischio Incendi</h2>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <ReactTabulator
              ref={(ref) => (this.tableRef = ref)}
              columns={this.FIRERISK_TABLE_COLUMNS}
              data={this.state.tableData}
              options={TABULATOR_OPTIONS}
              key={"table_" + this.state.tableKey}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <SaveButton  className="float-right" variant="contained" size="large" label="Salva modifiche" disabled={this.state.changedData.length === 0} onClick={this.saveAllEvents}/>
            <ResetButton className="float-right" variant="contained" size="large" label="Annulla modifiche" disabled={this.state.changedData.length === 0} onClick={this.getTableData}/>
          </div>
        </div>

        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={this.state.showModal}
          onClose={() => this.onCloseModal()}
          aria-labelledby="view-graph"
          aria-describedby="view-graph-fire-risk"
        >
        <div
          style={{
            background: "white",
            width: "50vw",
            height: "50vh",
          }}
        >
          <IconButton
            aria-label="close"
            style={{ position: "absolute", right: 1, top: 1, color: "white" }}
            onClick={() => this.onCloseModal()}
          >
            <CloseIcon />
          </IconButton>

          <div className="d-flex justify-content-center">
            <h3>Grafico configurazione layer {!!this.state.modalData ? this.state.modalData.item : ""}</h3>
          </div>

          <div className="d-flex h-80">
            <div className="col-sm-12 my-auto">
            {!!this.state.chart.series &&
              <ReactApexChart
                options= {this.state.chart.options}
                series={this.state.chart.series}
                type="line"
                height={350}
                key={1}
              />
            }
            </div>
          </div>
          <br />
        </div>
      </Modal>
      </div>
    );
  }

}
