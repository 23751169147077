import { properties} from '#/properties.js';

import parse_georaster from "georaster"
import APIUtils from "#/lib/APIUtils";
import AuthenticationService from "#/lib/AuthenticationService";


export default class MailingListClient {

	static getMailingListByCategory(okCallback, errCallback, category = 'ALL' ){
		let url = new URL(properties.url.mailingList + category);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static save = (okCallback, errCallback, request) => {
		let url = properties.url.saveMailingList;

		let fetch_options = {
			headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
			method: 'POST',
			body: JSON.stringify(request)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static deleteById(okCallback, errCallback, id){

		let url = new URL(properties.url.deleteFromMailingList + "/" + id);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
}
