import Button from "@material-ui/core/Button";
import React from "react";

const ExportButton = props => (
        <>
            <Button
                className="mr-2"
				variant="contained"
				color="primary"
                disabled={props.disabled}
                style={{  minWidth: "10vw" }}
                startIcon={props.icon}
                onClick={(e) => props.downloadHandler() }
            >
                {props.label}
            </Button>
        </>
    );

export default ExportButton;
