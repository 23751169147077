import React, {Component, useState} from 'react';
import {Modal, Container, Row, Col, Form} from 'react-bootstrap';
import 'react-dropzone-uploader/dist/styles.css';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import moment from 'moment';
import 'moment/locale/it';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import TimeField from 'react-simple-timefield';
import DatePicker from "react-datepicker";
import it from 'date-fns/locale/it';
import WorkshiftClient from '#/lib/WorkShfitClient';
import UserClient from '#/lib/UserClient';
import DateUtils from '#/lib/DateUtils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import AuthenticationService, { ROLE_ADMIN, ROLE_OFFICER }  from '#/lib/AuthenticationService';
import LoginComponent from '#/backoffice/components/LoginComponent';
import { Route } from 'react-router-dom';
import _ from 'lodash';
const localizer = momentLocalizer(moment);

const ReactSwal = withReactContent(Swal)

const messages = {
  date: "Data",
  time: "Ora",
  event: "Evento",
  allDay: "Tutto il giorno",
  today: "Oggi",
  month: "Mese",
  day: "Giorno",
  week: "Settimana",
  previous : "Precedente",
  next : "Successivo",
  noEventsInRange: "Non sono presenti turni nell'intervallo selezionato.",
  showMore: total => `+${total} ulteriori`,
}
const fakeHoursByFunction = {
    FR: {fakeStartHour: 3, fakeEndHour: 4},
    F: {fakeStartHour: 4, fakeEndHour: 5},
    REP_f: {fakeStartHour: 5, fakeEndHour: 6},
    SW1: {fakeStartHour: 6, fakeEndHour: 7},
    LR1: {fakeStartHour: 6, fakeEndHour: 7},
    SW2: {fakeStartHour: 7, fakeEndHour: 8},
    LR2: {fakeStartHour: 7, fakeEndHour: 8},
    REP_n: {fakeStartHour: 8, fakeEndHour: 9}
}

const colorByFunction = {
	FR: "#3f51b5",
	F: "#4caf50",
	REP_f: "#f44336",
	SW1: "#ff9800",
	SW2: "#ff9800",
  LR1: "#ff9800",
	LR2: "#ff9800",
	REP_n: "#795548"
  }

  export default class WorkShiftsPage extends Component {


    username = AuthenticationService.getLoggedInUsername();

    constructor(props){
        super(props);
        const today = new Date();
        const firstDayMonth = DateUtils.getFirstDayMonth(today).getTime();
        const lastDayMonth = DateUtils.getLastDayMonth(today).getTime();

        this.state = {
            modalState : {
              event : {},
              show: false,
              writePermission : false,
              onHide : this.hideModal
            },
            calendarState : {
              writePermission : false ,
              events : [],
              onSelectEvent : (e) => this.onSelectEventHandle(e),
              onRangeChange : (e) => this.onRangeChange(e)
            },
            calendarRange : {
              start : firstDayMonth,
              end : lastDayMonth
            },
            saveButtonEnabled : false
        }
    }

    componentDidMount() {
      console.log(AuthenticationService.getLoggedInUserData());
      let calendarPromise = new Promise((resolve,reject)=>{
        let params = {
          startDate : this.state.calendarRange.start,
          endDate : this.state.calendarRange.end
          /*username : this.username*/
        };
        WorkshiftClient.workShiftGetList (
          (result) => {
            result = this.getEventsFromResult(result);
            resolve({
              calendarState : {
                events : result
              }
            })
          },
          (msg) => {
              ReactSwal.fire('Recupero turni fallito', '', 'error')
          },
          params
        )
      });

      let userPromise = new Promise((resolve,reject)=>{
        UserClient.getUserList (
          (result) => {
            resolve({
                users : result
            })
          },
          (msg) => {
              ReactSwal.fire('List users Failed', '', 'error')
          }
        )
      });

      let userFunctionsPromise = new Promise((resolve,reject)=>{
        WorkshiftClient.workShiftGetFunctionsList (
          (result) => {
            resolve({
                userFunctions : result
            })
          },
          (msg) => {
              ReactSwal.fire('List users Failed', msg, 'error')
          }
        )
      });

      Promise.all([calendarPromise, userPromise, userFunctionsPromise]).then(values => {
        const mergedValues = {...values[0], ...values[1], ...values[2]};
        const modalState = {...this.state.modalState, users : mergedValues.users, userFunctions : mergedValues.userFunctions};
        const calendarState = {...this.state.calendarState, events : mergedValues.calendarState.events};
        this.setState({modalState : modalState, calendarState : calendarState});
      });

    }

    getEventsFromResult = (result) => {
      result.forEach ( event => {
        event.start = !!event.startDate ? new Date(event.startDate) : null;
        event.end = !!event.endDate ? new Date(event.endDate) : null;
        event.title = event.surname + ' ' + event.name + ' - ' + event.function;
        event.dbOrigin = true;
        event.action = '-';
        event.saved = true;
      })
      return result;
    }

    getCalendarEvents = (startDate, endDate) =>{
      let params = {
        startDate : !!startDate ? startDate : this.state.calendarRange.start,
        endDate : !!endDate ? endDate : this.state.calendarRange.end
      };
      WorkshiftClient.workShiftGetList (
        (result) => {
          result = this.getEventsFromResult(result);
          const calendarState = {...this.state.calendarState, events : result };
          this.setState({calendarState : calendarState});
        },
        (msg) => {
            ReactSwal.fire('Recupero turni fallito', msg, 'error');
        },
        params
      )
    }

    onRangeChange = (range, view) => {
      let startDate;
      let endDate;
      if (range.length > 1){
        startDate = range[0];
        endDate = range[6];
      } else if (range.length === 1){
        startDate = range[0];
        endDate = range[0];
      } else {
        startDate = range.start;
        endDate = range.end;
      }
      startDate = startDate.getTime();
      endDate = endDate.getTime();
      this.setState({
        calendarRange : {
          start : startDate,
          end : endDate
        }
      })
      this.getCalendarEvents(startDate, endDate);
    }

    onSelectEventHandle = (e) => {

      const newModalState = {...this.state.modalState,
                          show : true,
                          title : 'Modifica Turno',
                          new : false,
                          event : {
                            ...e,
                            startTime : (e.start.getHours()<10 ? '0' : '') + e.start.getHours() + ':' + (e.start.getMinutes()<10 ? '0' : '') + e.start.getMinutes(),
                            endTime : (e.end.getHours()<10 ? '0' : '') + e.end.getHours() + ':' + (e.end.getMinutes()<10 ? '0' : '') + e.end.getMinutes(),
                            action : 'mod'
                          }
                         };
      this.setState({modalState : newModalState});
    }

    hideModal = () => {
      this.setState({modalState: {...this.state.modalState, show: false}});
    }

    render() {
        const roles = !!this.props.roles ? this.props.roles : [ROLE_ADMIN, ROLE_OFFICER];
        if (!AuthenticationService.isValidSession() || !AuthenticationService.haveRolesPermssions(roles))  {
          return <Route render={(props) => <LoginComponent {...this.props}/>}></Route>
        }

        return (
            <div className="generic-page container-fluid data-page">
            <Grid container direction="row" justify="space-between" alignItems="center" >
              <Grid item xs={3} className="mt-4">
                <h2 className="ml-3">Visualizza turni</h2>
              </Grid>

            </Grid>

            <Grid container direction="row" justify="space-between" alignItems="center" spacing={0} style={{width: 'inherit'}} >
                  {!!this.state.calendarState.events && !!this.state.modalState.users && !!this.state.modalState.userFunctions ?
                    <Grid item xs={12}>
                      <MyCalendar className="col-12 my-4" {...this.state.calendarState} username={this.username}/>
                    </Grid>
                     :
                     <Grid item xs={12}>
                      <Skeleton variant="rect" width="100%" height={720}/>
                     </Grid>
                  }
                  {this.state.modalState.show && <ModalForm {...this.state.modalState} />}
            </Grid>
            </div>
        )
    }

}


const ModalForm = props => {

  const optionUsers = props.users.map(function (item) {
    let displayValue = item.name + ' ' + item.surname;
    return <option value={item.username} key={item.username}>{displayValue}</option>;
  });

  const optionUserFunctions = props.userFunctions.map(function (item) {
    return <option value={item.code} key={item.code}>{item.description}</option>;
  });
  const classReadOnlyField = " form-control-plaintext";
  const event = props.event;

  return (
      <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p>{props.title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>

            <Row className="mt-2">
              <Col md={4}>Funzionario</Col>
              <Col md={8}>
                  <Form.Control
                    as="select"
                    plaintext = {true}
                    readOnly = {true}
                    disabled = {true}
                    name="username"
                    value={event.username}
                    >
                      <option value=""></option>
                      {optionUsers}
                  </Form.Control>
            </Col>
            </Row>

            <Row className="mt-2">
              <Col md={4}>Ruolo</Col>
              <Col md={8}>
                <Form.Control
                  as="select"
                  plaintext = {true}
                  readOnly = {true}
                  disabled = {true}
                  name="function"
                  value={event.function}
                  >
                  <option value=""></option>
                  {optionUserFunctions}
                </Form.Control>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md={4}>Giorno</Col>
                <Col md={4}>
                  <DatePicker
                      className = {"col-12" + classReadOnlyField}
                      plaintext = {true}
                      readOnly = {true}
                      locale={it}
                      dateFormat="dd/MM/yyyy"
                      selected={event.start}

                    />
                </Col>
                <Col md={4}>
                  <DatePicker
                      className = {"col-12" + classReadOnlyField}
                      plaintext = {true}
                      readOnly = {true}
                      locale={it}
                      dateFormat="dd/MM/yyyy"
                      selected={event.end}
                    />
                </Col>
            </Row>

            <Row className="mt-2">
              <Col md={4}>Fascia oraria</Col>
              <Col md={4}>
                <TimeField
                  plaintext = {true}
                  readOnly = {true}
                  name='startTime'
                  value={event.startTime}
                  input={<Form.Control type="text" value={event.startTime}/>}
                  style={{textAlign: 'center'}}
                />
              </Col>
              <Col md={4}>
                <TimeField
                  plaintext = {true}
                  readOnly = {true}
                  name='endTime'
                  value={event.endTime}
                  input={<Form.Control type="text" value={event.endTime}/>}
                  style={{textAlign: 'center'}}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

const MyCalendar = (props) => {
    const [selectedView, setSelectedView] = useState(Views.MONTH);

    const eventStyleGetter = (props) => {
    let backgroundColor = colorByFunction[props.function];

    if (props.event && props.event.username === props.username) {
          backgroundColor = "#065407";
    }
    var style = {
      border: "none",
      boxSizing: "border-box",
      boxShadow: "none",
      margin: "0",
      padding: "2px 5px",
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      color: "#fff",
      cursor: "pointer",
      width: "100%",
      textAlign: "left",
    };
    return {
      style: style,
    };
  };
    const formatStart = ({start, function : fun}) => {

        start.setHours(fakeHoursByFunction[fun].fakeStartHour);
        return start
    };
    const formatEnd = ({start, function : fun}) => {

        start.setHours(fakeHoursByFunction[fun].fakeEndHour);
        return start
    }
    const formatEvents = () => {
        if (selectedView === Views.MONTH){
            let retval =  props.events.map(e => ({
                ...e,
                allDay: true,
                originalEnd: e.end,
                end: formatEnd(_.cloneDeep(e)),
                originalStart: e.start,
                start: formatStart(_.cloneDeep(e))
            }));
            return retval;
        } return props.events
    };
    const fixSelectedEvent = e => selectedView === Views.MONTH ? {...e, end: e.originalEnd || e.end, start: e.originalStart || e.start, allDay: false} : e;

    return (
    <div className={props.className}>
      <Calendar
        selectable={props.writePermission}
        events={formatEvents()}
        views={[Views.MONTH]}
        defaultView={Views.MONTH}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        step={10}
        timeslots={3}
        showMultiDayTimes
        messages={messages}
        style={{ height: 1100, fontSize: 12 }}
        onSelectEvent={e => props.onSelectEvent(fixSelectedEvent(e))}
        onSelectSlot={props.onSelectSlot}
        onRangeChange={props.onRangeChange}
        eventPropGetter={eventStyleGetter}
        onView={view => {
            console.log('view', view)
            setSelectedView(view)
        }}
      />
    </div>
  );
};
