import React from 'react';
import { withRouter } from 'react-router';

import { Navbar } from 'react-bootstrap';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import UserItem from './UserItem';
import UserDetails from './UserDetails';
import PaginationComponent from '#/commons/components/PaginationComponent';
import UserClient from '#/lib/UserClient';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ReactSwal = withReactContent(Swal);

class UserPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDateFilter: null,
			endDateFilter: null
		}
	}

	onDeleteHandle = (username) => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione dello user {username}</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Invia',
			cancelButtonText: 'Annulla'
		})
		.then((result) => {
			if (result.value) {
				let params = {
					username: username
				}
				UserClient.userDelete (
					(result) => {
						ReactSwal.fire('Cancellazione user', 'Lo user ' + username + ' è stato cancellato con successo', 'success');
						this.props.history.go(0); //refresh
					},
					function (msg) {
						ReactSwal.fire('Errore cancellazione user ' + username, msg, 'error');
					},
					params
				)
			}
		})
	}

	fromJsonToUserItem(obj) {
		let UserDetailsComponent = <UserDetails {...obj} />;
		let editable = this.props.canEdit;
		editable = true;
		return <UserItem {...obj.userAnag} key={obj.username} details={UserDetailsComponent} editable={editable} onClickDelete={this.onDeleteHandle}/>;
	}

	getPage(successCallback, errorCallback, page) {
		let params = {
			page: 1,
			size: 9999999999,
		}
		UserClient.getUserListRolesPermissions(
			(obj) => {
				const dati = {data : obj, last_page : 1};
				successCallback(dati);
			},
			(err) => { errorCallback(err) },
			params
		)
	}

	render() {
		return (
			<div className="generic-page">
				<div className="container">
					<Navbar>
						<Navbar.Brand><h2>Gestione utenti</h2></Navbar.Brand>
						<Navbar.Toggle />
						{/* TODO ricerca */}
						{/*
						<Navbar.Collapse className="justify-content-end">
							<Form inline>
      							<Form.Control type="text" placeholder="Ricerca per nome" className="mr-sm-2" />
    						</Form>
						</Navbar.Collapse>
						*/}
					</Navbar>
					<PaginationComponent
						className = "container"
						toItemFunction={(obj) => this.fromJsonToUserItem(obj)}
						getPage={(successCallback, errorCallback, page ) => this.getPage( successCallback, errorCallback, page)} />

					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
						<Tooltip title="Crea un nuovo utente">
							<Fab color="primary" onClick={()=>{this.props.history.push("/backoffice/user/new")}}>
								<AddIcon />
							</Fab>
						</Tooltip>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(UserPagedList)