import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class InfostreamClient {

	static getAllNewsList(okCallback, errCallback, params){
		const url = new URL(properties.url.streamNewsList);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getRssConfig(okCallback, errCallback, params){
		const url = new URL(properties.url.streamRssConfig);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getTweetConfig(okCallback, errCallback, params){
		const url = new URL(properties.url.streamTweetConfig);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getRssConfigById(id, okCallback, errCallback, ){
		const url = new URL(properties.url.streamRssConfigById + '/' + id);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getTweetConfigById(id, okCallback, errCallback, ){
		const url = new URL(properties.url.streamTweetConfigById + '/' + id);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getConfigById(type, id, okCallback, errCallback, ){
		if (type==='rss'){
			return this.getRssConfigById(id, okCallback, errCallback);
		} else {
			return this.getTweetConfigById(id, okCallback, errCallback);
		}
	}

	static save(params, okCallback, errCallback){
		let url = new URL(properties.url.streamConfigSave + '/' + params.type);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static delete(type, id, okCallback, errCallback){
		let url = new URL(properties.url.streamConfigDelete + "/" + type + "/" + id);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getThresholdsBySensorCategory(sensorCategory,okCallback, errCallback){
		let url = new URL(properties.url.sensorCategoryThreshold  + "/" + sensorCategory);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static saveThresholdsBySensorCategory(params,okCallback, errCallback){
		let url = new URL(properties.url.sensorCategoryThresholdSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static deleteThresholdsBySensorCategory(category,okCallback, errCallback){
		let url = new URL(properties.url.sensorCategoryThresholdDelete + "/" + category);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
}
