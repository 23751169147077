import {properties} from '#/properties.js';
import APIUtils from "#/lib/APIUtils";


export default class MosipClient {
	
	static getAllMunicipalities(okCallback, errCallback){
		let url = new URL(properties.url.mosipMunicipalities);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static saveMunicipalitiesMauZone(okCallback, errCallback, municipalitiesMauZone){
		let url = new URL(properties.url.mosipMunicipalitiesMauZoneSave);
		let fetch_options = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(municipalitiesMauZone),
		  };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static replaceAllMunicipalities(okCallback, errCallback, params){
		let url = new URL(properties.url.mosipMunicipalitiesReplaceAll);
		let fetch_options = {
		  headers: { "Content-Type": "application/json", charset: "utf-8" },
		  method: "POST",
		  body: JSON.stringify(params),
		};
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getAllBasins(okCallback, errCallback){
		let url = new URL(properties.url.mosipBasins);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static replaceAllBasins(okCallback, errCallback, params){
		let url = new URL(properties.url.mosipBasinsReplaceAll);
		let fetch_options = {
		  headers: { "Content-Type": "application/json", charset: "utf-8" },
		  method: "POST",
		  body: JSON.stringify(params),
		};
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getAllRisksByMunicipality(okCallback, errCallback){
		let url = new URL(properties.url.mosipRisksByMunicipality);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getAllRisks(okCallback, errCallback){
		let url = new URL(properties.url.mosipRisks);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getAllRiskThresholds(okCallback, errCallback){
		let url = new URL(properties.url.mosipRiskThresholds);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static replaceAllRiskThresholds(okCallback, errCallback, params){
		let url = new URL(properties.url.mosipRiskThresholdsReplaceAll);
		let fetch_options = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(params),
		  };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	
	static saveRiskThresholds(okCallback, errCallback, params){
		let url = new URL(properties.url.mosipRiskThresholdSave);
		let fetch_options = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(params),
		  };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}


	static getRelevanMosipExecutions(okCallback, errCallback){
		let url = new URL(properties.url.mosipExecutions+"/false");
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getAllMosipExecutions(okCallback, errCallback){
		let url = new URL(properties.url.mosipExecutions+"/true");
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}


	static getMosipExceededThresholdsByMosipExecution(okCallback, errCallback, runId){

		let url = new URL(properties.url.mosipExceededThresholdsByMosipExecution + "/" + runId);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getMosipExceededThresholds12HrAll(okCallback, errCallback){

		let url = new URL(properties.url.mosipExceededThresholds12HrAll);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getMosipExceededTresholdInLastRun(okCallback, errCallback){

		let url = new URL(properties.url.exceededThresholdsLastRun);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getLastMAUState(okCallback, errCallback){

		let url = new URL(properties.url.getLastMAUstate);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}


	static getMosipAlertDocuments(okCallback, errCallback){
		let url = new URL(properties.url.mosipAlertDocuments);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getMosipLast12HAlertedMunicipalities(okCallback, errCallback){
		let url = new URL(properties.url.mosipLast12HAlertedMunicipalities);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getMosipAlertedMunicipalitiesByProtocol(okCallback, errCallback, protocol){
		let url = new URL(properties.url.mosipAlertedMunicipalitiesByProtocol +"/"+protocol);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	
	static getMosipLast12HExceededThresholds(okCallback, errCallback){
		let url = new URL(properties.url.mosipLast12HExceededThresholds);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}


	static buildA3(okCallback, errCallback, params){

		let url = new URL(properties.url.mosipBuildA3);
		let fetch_options = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(params),
		  };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getA3UrlByProtocol(protocol){
		return properties.url.mosipDownloadA3 + "/" + protocol
	}

	static getMosipExecutionReportById(id){
		return properties.url.mosipExecutionReport + "/" + id
	}

	static getMosipExecutionFullReportByRunId(runId){
		return properties.url.mosipExecutionFullReport + "/" + runId
	}

	
	static testMosipExecution(okCallback, errCallback, istatCode){
		let url = new URL(properties.url.mosipTestExecution);
		if(!!istatCode){
			url = new URL(properties.url.mosipTestExecution+"?istatCode="+istatCode)
		}
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	

}
