import React from "react";

/** STEPS **/
import {StepSelectMonth} from "#/backoffice/bim/steps/StepSelectMonth";
import {StepPrecipitazioniMese} from "#/backoffice/bim/steps/StepPrecipitazioniMese";
import {StepSPI} from "#/backoffice/bim/steps/StepSPI";
import {StepMonitoraggioTemperature} from "#/backoffice/bim/steps/StepMonitoraggioTemperature";
import {StepDeflussiSezioni} from "#/backoffice/bim/steps/StepDeflussiSezioni";

/** GEOJSON **/
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";
import {StepRecipients} from "#/backoffice/mau/steps/StepRecipients";

export const SINGLE_STEPS = {

	PRECIPITAZIONI_MESE :[
		{
			label : "Selezione Mese",
			component : function(props) { return <StepSelectMonth {...props} />}
		},
		{
			label : "Precipitazioni Mese",
			component : function(props) { return <StepPrecipitazioniMese {...props} />}
		}
	],
	SPI :[
		{
			label : "Selezione Mese",
			component : function(props) { return <StepSelectMonth {...props} />}
		},
		{
		  label : "Standard Precipitation Index",
		  component : function(props) { return <StepSPI {...props} />}
		}
	],
	MONITORAGGIO_TEMPERATURE :[
		{
			label : "Selezione Mese",
			component : function(props) { return <StepSelectMonth {...props} />}
		},
		{
		  label : "Monitoraggio Temperature Medie Giornaliere nel mese",
		  component : function(props) { return <StepMonitoraggioTemperature {...props} />}
		}
	],
	DEFLUSSI :[
		{
			label : "Selezione Mese",
			component : function(props) { return <StepSelectMonth {...props} />}
		},
		{
		  label : "Deflussi Sezioni Significative",
		  component : function(props) { return <StepDeflussiSezioni {...props} />}
		}
	],

}

export const BIM_STEPS = {
  EDIT : [
    {
      label : "Selezione Mese",
        value: 'monthRef',
      component : function(props) { return <StepSelectMonth {...props} />}
    },
    {
      label : "Precipitazioni Mese",
      value: 'rainfalls',
      component : function(props) { return <StepPrecipitazioniMese {...props} />}
    },
    {
      label : "Standard Precipitation Index",
        value: 'sp_index',
      component : function(props) { return <StepSPI {...props} />}
    },
    {
      label : "Monitoraggio Temperature Medie Giornaliere nel mese",
        value: 'monitoraggio',
      component : function(props) { return <StepMonitoraggioTemperature {...props} />}
    },
    {
      label : "Deflussi Sezioni Significative",
        value: 'outflows',
      component : function(props) { return <StepDeflussiSezioni {...props} />}
    },
      {
          label : "Destinatari",
          value: 'recipients',
          component : function(props) { return <StepRecipients {...props} />}
      }
  ],
  VIEW : [
    {
      label : "Precipitazioni Mese",
      component : function(props) { return <StepPrecipitazioniMese {...props} />}
    },
    {
      label : "Standard Precipitation Index",
      component : function(props) { return <StepSPI {...props} />}
    },
    {
      label : "Monitoraggio Temperature Medie Giornaliere nel mese",
      component : function(props) { return <StepMonitoraggioTemperature {...props} />}
    },
    {
      label : "Deflussi Sezioni Significative",
      component : function(props) { return <StepDeflussiSezioni {...props} />}
    }
  ]
}

export const OPTIONAL_MAP_FILES = [
  {
    label : "8 zone",
    value : zonesGeoJson8,
    identifier : "ZONE"
  },
  {
    label : "Carica nuove zone",
    value : "NEW",
    identifier : "__NEW__"
  }
]

export let DEFAULT_FORM_JSON_DATA = {
  reference_month: "",
  rainfalls:{
      img:"",
      zones:[]
  },
  sp_index:{
      img_1m:"",
      img_3m:"",
      img_6m:"",
      img_12m:"",
      img_24m:"",
      legend:""
  },
  monitoraggio:{
      img_minime:"",
      img_minime_anomalie:"",
      img_medie:"",
      img_medie_anomalie:"",
      img_massime:"",
      img_massime_anomalie:""
  },
  outflows:{
      img:"",
      zones:[]
  }
};

export const PRECIPITAZIONI_TABLE_COLUMNS = [
  { title: "Bacino", field: "code", headerSort: true },
  { title: "Totale [mm]", field: "currentMonthTot"},
  { title: "Volume [10<sup>6</sup>mc]",formatter: "html", field: "currentMonthVol"},
  { title: "Scarto Volume [10<sup>6</sup>mc]",formatter: "html", field: "monthDeviation"},
  { title: "Scarto [%]", field: "monthPercentDeviation"},
  { title: "Precip. anno <br>solare [mm]",formatter: "html", field: "currentYearTot"},
  { title: "Scarto anno <br>solare [%]",formatter: "html", field: "yearPercentDeviation"}
];

export const DEFLUSSI_TABLE_COLUMNS = [
  { title: "<div style=\"text-align: center\">Bacino</div>", formatter: "html", field: "basin", headerSort: false},
  { title: "<div style=\"text-align: center\">Sezione</div>", formatter: "html", field: "section", widthGrow: 2, headerSort: false},
  {
      title:"<div style=\"text-align: center\">Portata</div>",
      formatter: "html",
      columns:[
          { title: "<div style=\"text-align: center\">Media <br>mensile <br>[mc/s]</div>", formatter: "html", field: "flowMonthAvg",headerSort: false},
          { title: "<div style=\"text-align: center\">Media mensile <br>storica <br>[mc/s]</div>", formatter: "html", field: "flowMonthHystoricalAvg", headerSort: false},
          { title: "<div style=\"text-align: center\">Scarto <br>[%]</div>", formatter: "html", field: "flowDeviation", headerSort: false}
      ],
  },
  {
      title:"<div style=\"text-align: center\">Deflussi</div>",
      formatter: "html",
      columns:[
          { title: "<div style=\"text-align: center\">Mensile <br>[10<sup>6</sup>mc]</div>", formatter: "html", field: "outflowMonth", headerSort: false},
          { title: "<div style=\"text-align: center\">Mensile <br>storico [10<sup>6</sup>mc]</div>", formatter: "html", field: "outflowMonthHystorical", headerSort: false},
          { title: "<div style=\"text-align: center\">Scarto <br>[10<sup>6</sup>mc]</div>", formatter: "html", field: "outflowDeviation", headerSort: false}
      ]
  }
];

export const TABULATOR_OPTIONS = {
  data: [],
  height: "50vh"
}

export const BUTTON_PROGRESS_STYLE = {
  color: 'green',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};
