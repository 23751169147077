import React from "react";
import ThresholdRangeComponent from "#/commons/components/ThesholdRangeComponent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";


export default class MosipThresholdForm extends React.Component {


	componentDidMount() {
	}

	handleChangeTextField(obj){
		console.log(obj.name+" "+obj.value)
	}

	render() {


		return (
			<div className="pt-4">

				<div className="mt-4 container">
					<ThresholdRangeComponent
						min={this.props.min}
						max={this.props.max}
						step={this.props.step}
						values={this.props.threshold.levels}
						colors={["grey", "yellow", "orange", "red"]}
						onChange={(levels) => this.props.onChangeLevels(levels)}
					/>
				</div>
				<div className="mt-4 container">
					<Typography
						component="span"
						variant="body2"
						color="textPrimary"
					>
						<Grid container spacing={5}>
							<Grid item xs={12} md={12}>
								<TextField
									fullWidth
									name="P1H"
									label="Pioggia Indicatrice 1 Ora"
									required
									type="number"
									value={this.props.threshold.critical.P1H}
									onChange={(e) => this.props.onChangeCritical(e.target.name, e.target.value)}
								>
								</TextField>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									fullWidth
									name="P3H"
									label="Pioggia Indicatrice 3 Ore"
									required
									type="number"
									value={this.props.threshold.critical.P3H}
									onChange={(e) => this.props.onChangeCritical(e.target.name, e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									fullWidth
									name="P6H"
									label="Pioggia Indicatrice 6 Ore"
									required
									type="number"
									value={this.props.threshold.critical.P6H}
									onChange={(e) => this.props.onChangeCritical(e.target.name, e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									fullWidth
									name="P12H"
									label="Pioggia Indicatrice 12 Ore"
									required
									type="number"
									value={this.props.threshold.critical.P12H}
									onChange={(e) => this.props.onChangeCritical(e.target.name, e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									fullWidth
									name="P24H"
									label="Pioggia Indicatrice 24 Ore"
									required
									type="number"
									value={this.props.threshold.critical.P24H}
									onChange={(e) => this.props.onChangeCritical(e.target.name, e.target.value)}
								/>
							</Grid>
						</Grid>
					</Typography>
				</div>
			</div>
		);
	}
}
