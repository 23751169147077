import React from 'react';
import GISTools from '#/lib/GISTools';
import * as turf from '@turf/turf';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import DateUtils from "#/lib/DateUtils";
import _ from 'lodash';
import '#/backoffice/style/scrollbar.css';
import WidthUtils from "#/lib/WidthUtils"
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {properties} from "#/properties";
import MosipClient from "#/lib/MosipClient";
import MosipMapComponent from "../../commons/map/MosipMapComponent";
import chroma from 'chroma-js'
import {FiClock} from "react-icons/fi";

const ReactSwal = withReactContent(Swal);


const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});


const livelli = {
	"LEVEL1" : "Livello 1",
	"LEVEL2" : "Livello 2",
	"LEVEL3" : "Livello 3"
}

const colors = {
	"LEVEL1" : "yellow",
	"LEVEL2" : "DarkOrange",
	"LEVEL3" : "red"
}

const alertLabels = {
	"LEVEL1" : "GIALLA",
	"LEVEL2" : "ARANCIONE",
	"LEVEL3" : "ROSSA"
}

class AllertamentoTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// Commons
			tableKey: 1,
			legendKey: 1,
			pointsKey: 1,
			lastUpdate: null,
			intervalId: 0,
			tableThemes: 0,
			loadingTab: true,
			showStations: true,

			// Rains

			interpolatedData: null,
			cfGumbelParametersMap: {},
			cfTCEVParametersMap: {},
			rainReturnTimeEvents: [],
			rainThresholdsEvents: [],
			rasterEnabled: true,
			currentLayer: 'NONE',

			// Hydrometric height

			outflowScales: {},

			stationSensorThresholds: {},

			selectedFeature: null,
			showInfoModal: false,
			showThresholdModal: false,

			geoDataByCategory: {},
			selectedGeoData: {},
			eventsByCategory: {},
			selectedCategory: null,
			selectedCategoryOption: '',
			rainMode: 'TH',
			rainThresholds: {},
			lastRequestTimestamp: 0,
			interpolatedDataCache: {},

			// MIDA LIGHT
			cachedTiff: {},
			showLegend: false,
			selectedLayer: '',
			showTable: false,

			// MOSIP
			municipalitiesGeoJSON : null,
			alertedMapByIstat : [],
			polygonsKey : 1,
			mapKey: 1,
			lastRunThresholdExceedMapByIstat : [],

		}
	}

	loadFromApi() {

		let municipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getAllMunicipalities((data) => { resolve({municipalities:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let last12HAlertedMunicipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getMosipLast12HAlertedMunicipalities((data) => { resolve({alerted:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let lastMAUStatePromise = new Promise((resolve, reject) => {
			MosipClient.getLastMAUState((data) => { resolve({mauState:data}); }, () => { console.log("ERROR COMUNI NON ALLERTATI"); });
		})

		let result = {};
		Promise.all([municipalitiesPromise, last12HAlertedMunicipalitiesPromise, lastMAUStatePromise])
			.then( data =>{
				Object.assign(result, ...data);
				let {municipalities,alerted,lastExecutionTimestamp, mauState} = result;
				let geoJSON = this.buildGeoJSONFromArray(municipalities);

				let alertedMapByIstat = alerted.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});

				let newState = {
					municipalitiesGeoJSON : geoJSON,
					mauState,
					alertedMapByIstat : alertedMapByIstat,

					mapKey: (this.state.mapKey + 1) % 1000,
					tableKey: (this.state.tableKey + 1) % 1000,
					lastExecutionTimestamp,
					loadingTab: false
				}

				this.setState(newState);
			})
	}

	buildGeoJSONFromArray(data) {
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Municipalities";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				COMUNE: value.name,
				ISTAT: value.istatCode,
				PROVINCIA: value.province,
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}


	centerMap() {
		if (!!this.map) {
			let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
			this.map.leafletElement.fitBounds([
				[bbox.bbox[1], bbox.bbox[0]],
				[bbox.bbox[3], bbox.bbox[2]]
			]);
		}
	}


	componentDidMount = () => {this.loadFromApi()}


	componentDidUpdate() {
		if (!!this.map) {
			this.map.leafletElement.invalidateSize();
			this.centerMap();
		}
		loadingSwal.close();
	}



	calculateThresholdSeverity(temp, thresholds) {
		let severity = 'white';
		if (thresholds.length < 1) {
			return severity;
		}

		thresholds.sort(this.compareThreshold);
		thresholds.forEach(t => {
				if (temp >= t.thresholdStart) {
					severity = t.thresholdLevel;
				}
			}
		)
		return severity;
	}


	mobileMode = () => WidthUtils.isMobile();

	polygonStyle(feature, latlng){
		let istatCode = feature.properties.ISTAT;
		const isThisSelected = this.state.selectedIstatCode === feature.properties.ISTAT;
		const hasBeenAlerted = !!this.state.alertedMapByIstat[istatCode];

		let style = {
			fillColor: hasBeenAlerted ? colors[this.state.alertedMapByIstat[istatCode].alertLevel] : "#fff0",
			weight: isThisSelected ? 2 : (hasBeenAlerted ? 1 : 0.2),
			opacity: 1,
			color: isThisSelected ? 'black' : 'grey',
			fillOpacity: hasBeenAlerted ? 1 : 0
		};
		return style;
	}

	// Info modal
	openInfoModal(selectedFeature, latlng){
		this.setState({
			showInfoModal : true,
			selectedFeature,
			selectedLatLng: latlng,
			selectedIstatCode : selectedFeature.properties.ISTAT
		})
	}

	closeInfoModal(){
		this.setState({
			showInfoModal : false,
			selectedFeature : null,
			selectedIstatCode : null,
			mapKey : (this.state.mapKey + 1) % 1000
		})
	}


	getInfoModalContent(){
		let modalContent = [];
		let alertedListContent = [];
		const {selectedFeature, mauState, selectedLatLng} = this.state;
		if (!!selectedFeature) {
			let {properties} = selectedFeature;
			let istatCode = properties.ISTAT;
			let color = '#fff';

			if (!!this.state.alertedMapByIstat[istatCode]) {
				let municipalityAlert = this.state.alertedMapByIstat[istatCode];
				let mainColor = colors[municipalityAlert.alertLevel];
				let color = chroma(mainColor).alpha(0.5);
				let time = DateUtils.epochToDateMinuteResolution(municipalityAlert.timestamp);
				alertedListContent.push(<h3 style={{textAlign: 'center', color: mainColor}}> Ultime allerte </h3>)
				alertedListContent.push(
					<table className="textAlignCenter" style={{minWidth: '100%', fontSize: this.mobileMode() ? '12px':'16px'}}>
						<tr>
							<th style={{paddingRight: '10px'}}>Protocollo</th>
							<th style={{paddingRight: '10px'}}>Data</th>
							<th style={{paddingRight: '10px'}}>Livello allerta</th>
						</tr>
						<tr>
							<td style={{backgroundColor: color}}>{municipalityAlert.protocol}</td>
							<td style={{backgroundColor: color}}>{time}</td>
							<td style={{backgroundColor: color}}>{livelli[municipalityAlert.alertLevel]}</td>
						</tr>
					</table>
				);
			}

			if(!!mauState && !_.isEmpty(mauState)){
				let point = turf.point([selectedLatLng.lng, selectedLatLng.lat ]);
				let zonalFeature = mauState.features.find(zone => turf.booleanPointInPolygon(point, zone));

				if (zonalFeature){
					let mauColor = chroma(colors[zonalFeature.properties.level] || 'green').alpha(0.5);
					alertedListContent.push(<h3 style={{textAlign: 'center', color: '#fff'}}> Livello di allerta
						idrogeologica da MAU: </h3>)
					alertedListContent.push(<h3 style={{
						textAlign: 'center',
						color: '#fff',
						backgroundColor: mauColor,
						borderRadius: '15px'
					}}> {alertLabels[zonalFeature.properties.level] || 'VERDE'}</h3>)
				}
			}
			modalContent.push(<h3 style={{textAlign: 'center', color: color}}> {properties.COMUNE} ({properties.PROVINCIA})<br></br> {properties.ISTAT} </h3>);

			if (alertedListContent.length > 0){
				/*modalContent.push(<br/>);*/
				/*	modalContent.push(<hr style={{margin: 0, height: '4vh'}}/>);*/
				modalContent.push(...alertedListContent);
			} else {
				modalContent.push(<h3 style={{textAlign: 'center', color: color}}> Nessuna allerta </h3>)
			}
		}
		return modalContent;
	}
	getAdditionalMapProps = () => {
		return {
			additionalGeoJSON : this.state.mauState,
			additionalPolygonStyle : (feature, latlng) => this.additionalPolygonStyle(feature, latlng),
			onEachAdditionalPolygon : this.onEachAdditionalPolygon,
			showMauMap : true
		}
	}
	onEachAdditionalPolygon(feature, layer) {

	}


	additionalPolygonStyle(feature, latlng) {
		let zoneNumber = feature.properties.ZONE;
		if(!!this.state.mauState && !_.isEmpty(this.state.mauState)){
			let mauColor = colors[this.state.mauState.features.find(f => f.properties.ZONE === zoneNumber).properties.level];
			return {
				fillColor: !!mauColor ? mauColor : 'green',
				weight: 0.4,
				opacity: 1,
				color: mauColor,
				fillOpacity: 0.2
			};
		}
		else{
			return {
				fillColor: "green",
				weight: 0.4,
				opacity: 1,
				color: "green",
				fillOpacity: 0.2
			};
		}
	}
	render() {
		const {loadingTab, municipalitiesGeoJSON, mapKey, selectedIstatCode, polygonsKey, mauState} = this.state;
		const {mauId, mauCreatedAt} = this.props;
		let additionalMapProps = (!!mauState && !_.isEmpty(mauState)) ? this.getAdditionalMapProps() : {};
		let docUrl = !!mauId ? properties.url.bulletinDownloadFile + '/' + mauId + '/pdf' : '';
		const stub = (<div className=" pr-0 pl-0">
			<SkeletonTheme>
				<div className="mt-2 mb-2">
					<Skeleton height={'60vh'} duration={8}/>
				</div>
			</SkeletonTheme>
		</div>);

		return (
			<>
				{loadingTab ? stub :
					<div className="container-fluid pr-0 pl-0 mt-2 mb-2">
						{!!docUrl ? <div className="row d-flex justify-content-center">
							<a href={docUrl}>
								<span className="icon-span" style={{...(this.mobileMode() && {fontSize: '10px'})}}>
									<FiClock/> Stato di allertamento emanato dal MAU (Messaggio Allertamento Unificato) del {DateUtils.epochToDate(mauCreatedAt)}
								</span>
							</a>

						</div> : <></>}
						<div className="row mt-2 mb-2">
							<div className="col-sm"></div>
							<div className="col-sm-8 custom-popup">
								<MosipMapComponent
									title={""}
									geoJSON={municipalitiesGeoJSON}
									polygonStyle={(feature, latlng)=>this.polygonStyle(feature, latlng)}
									key={mapKey}
									selectedIstatCode={selectedIstatCode}
									polygonsKey={polygonsKey}
									openInfoModal = {(feature, latlng) => this.openInfoModal(feature, latlng)}
									showMapUpdateButton = {true}
									{...additionalMapProps}
									height={this.mobileMode() ? "40vh" : "50vh"}
									width={'100%'}
									zoom={6}
								></MosipMapComponent>
							</div>
							<div className="col-sm"></div>
						</div>
					</div>
				}
				{/* ----------------------- INFO MODAL ----------------------- */}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showInfoModal /*&& this.state.selectedFeature*/}
					onClose={() => this.closeInfoModal()}
					onBackdropClick={() => this.closeInfoModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "rgba(27,39,50,0.83)",//2c3e50d4
							color : "#fff",
							width: this.mobileMode() ? "100vw":"50vw",
							height: "auto",
							padding: '5vh',
							borderRadius: '15px',
							display: 'inline-grid'
						}}
					>
						<div>
							<IconButton  aria-label="close" style={{ float: 'right', color: 'white', padding: 0 }} onClick={() => this.closeInfoModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div>
							{this.getInfoModalContent()}
						</div>
					</div>
				</Modal>
			</>
		)
	}
}

export default (AllertamentoTab);
