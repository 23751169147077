import React from "react";

import {BUTTON_PROGRESS_STYLE, DEFLUSSI_TABLE_COLUMNS, TABULATOR_OPTIONS} from "#/backoffice/bim/utils/BIMUtils";

import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import Tooltip from '@material-ui/core/Tooltip';

import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton, SkipButton} from "#/commons/components/forms/NextButton";

import '#/backoffice/bim/style/OutflowStats.css';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import 'react-dropzone-uploader/dist/styles.css'

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AnalysisClients from "#/lib/AnalysisClient";
import {GeoJSON} from "react-leaflet";
import GISTools from "#/lib/GISTools";
import StationClient from "#/lib/StationClient";
import L from "leaflet";

import DateUtils from "#/lib/DateUtils";
import DownloadContainer from "#/commons/components/DownloadContainer"
import MapComponent from "#/commons/map/MapComponent";

function AddButton(props) {
    return (
            <Tooltip title={"Aggiungi ai bacini selezionati"}>
                <IconButton color="primary" aria-label="aggiungi bacini" component="span"
                            onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
    )
}

function RemoveButton(props) {
    return (
            <Tooltip title={"Rimuovi dai bacini selezionati"}>
                <IconButton color="secondary" aria-label="rimuovi bacini" component="span"
                            onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
                    <RemoveIcon />
                </IconButton>
            </Tooltip>
    )
}

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

export class StepDeflussiSezioni extends React.Component {

	section = "outflows";
    img = "img";
    images = [this.img];
	refMonthName = !!this.props.formData ? this.props.formData.reference_month : DateUtils.getMonthNameFromMonthNumber( this.props.month);
	inputData = !!this.props.formData ? this.props.formData[this.section]["inputData"] : null;
    selectedOutflows = !!this.props.formData ? this.props.formData[this.section]["zones"]:null;

	hasBeenModified = false;

    constructor(props) {
        super(props);
        this.state = {
            enableSave: false,
            loading: false,
            tableKey: 1,
            statsData : [],
            selectedOutflows : !!this.selectedOutflows ? [...this.selectedOutflows] : [],
            stationGeoJson : null,
			hideAvailable : false
        }
    }

    componentDidMount() {
		window.scrollTo(0, 0)
        if (this.props.viewMode && !!this.selectedOutflows) {
            return;
        } else if (!this.props.viewMode && !!this.inputData) {
            this.setState({...this.inputData});
        } else {
            StationClient.getHydrometerStationsGeojson(
                (geoJSON) => {
                    this.setState({
                        stationGeoJson: geoJSON
                    });
                    this.getOutflowStats();
                },
                () => {
                    console.log("ERROR retrieving stations geojson ");
                });
        }
    }
    handleSkipStep = () => {
        if(this.props.context==="BIM") {
            this.props.handleNext(true); // skip = true
        }
    }
    handleNextStep = () => {
		if(this.props.context==="BIM"){
			if (this.props.viewMode) {
				return;
			} else {
				let formData = this.props.formData;
				
				if (this.hasBeenModified ){
					loadingSwal.fire('Generazione bollettino in corso...');
					this.setState({loading: true});
					const promises = [];
					this.images.forEach( (img) => {
						promises.push(this.props.handleSaveImg(img));
					})
					Promise.all(promises)
					.then((response) => {
						let idx = 0;
						this.images.forEach( (img) => {
						formData[this.section][img] = response[idx++];
						});
						formData[this.section]["zones"] = [...this.state.selectedOutflows];
						formData[this.section]["inputData"] = {...this.state, loading: false};
						ReactSwal.close();
						this.props.handleNext();
					})
					.catch(error => {this.setState({loading: true}); console.log(`Error in executing ${error}`);})
				} else {
					this.props.handleNext();
				}
				
			}
		}
    }

    getOutflowStatsAvailableColumns = () => {
        return [
            ...DEFLUSSI_TABLE_COLUMNS,
            {
                title: "",
                formatter: reactFormatter(<AddButton clickCallback={(elem) => this.addOutflow(elem)}></AddButton>),
                headerSort: false,
                hozAlign: "center",
                vertAlign: "middle",
                width: 75
            }
        ];
    }

    getOutflowStatsSelectedColumns = () => {
        return [
            ...DEFLUSSI_TABLE_COLUMNS,
            !this.props.viewMode ?
            {
                title: "",
                formatter: reactFormatter(<RemoveButton clickCallback={(elem) => this.removeOutflow(elem)}></RemoveButton>),
                headerSort: false,
                hozAlign: "center",
                vertAlign: "middle",
                width: 75
            }
            :
            {}
        ];
    }

    getTabulatorOptions = () => {
        return  {
            ...TABULATOR_OPTIONS
        };
    }

    addOutflow = (elem) => {
		this.hasBeenModified = true;
		let selectedOutflows = [...this.state.selectedOutflows, elem]



        this.setState({
            selectedOutflows,
            tableKey: (this.state.tableKey + 1) % 1000,
			enableSave: selectedOutflows.length>0

        })
    }

    removeOutflow = (elem) => {
		this.hasBeenModified = false;
		let selectedOutflows = this.state.selectedOutflows.filter(o => o.stationCode !== elem.stationCode)
        this.setState({
            selectedOutflows,
            tableKey: (this.state.tableKey + 1) % 1000,
			enableSave: selectedOutflows.length>0
        })
    }

    getOutflowStats = () => {
      loadingSwal.fire('Calcolo dei deflussi...');
      let month = this.props.month + 1;
      let year = this.props.year;
      let newDateString = month < 10 ? `${year}-0${month}` : `${year}-${month}`;
      this.loadOutflowStatsFromAPI(month, year, newDateString);
    }

    loadOutflowStatsFromAPI = (month, year, newDateString) => {
        AnalysisClients.getOutflowStats(year, month,
            (data)=>{

				console.log("loadOutflowStatsFromAPI ", data)
                ReactSwal.close();
                this.setState({
                    statsData: data,
                    selectedOutflows : [],
                    tableKey: (this.state.tableKey + 1) % 1000,
                    statsDateLimit: new Date(newDateString),
                    enableSave: false
                })
            },
            ()=>{
                console.log('Problems retrieving Ouflow Stats data!')
                ReactSwal.hideLoading();
                ReactSwal.fire('Si è verificato un errore nel calcolo dei deflussi', '', 'error');
            }
        )
    }

    clearSelected = ()=>{
		this.hasBeenModified = true;
        this.setState({
            tableKey: (this.state.tableKey + 1) % 1000,
            selectedOutflows: [],
            pointsInMap : []
        })
    }

    stationToMarker = (feature, latlng) => {
        let marker = null;
        let code = feature.properties.code;
        let isSelected = this.state.selectedOutflows.map(s => s.stationCode).includes(code);
        if (isSelected) {
            marker = L.marker(latlng, {
                icon: new L.Icon({
                    iconSize: [15, 15],
                    iconUrl: '/img/marker_point_selected.png',
                    popupAnchor: [0, 0]
                })

            });
        }
        return marker;
    }

    onEachStation(feature, layer) {
        let code = feature.properties.code;
        let isSelected = this.state.selectedOutflows.map(s => s.stationCode).includes(code);
        if (isSelected) {
            let popup = L.popup({closeButton: false, autoClose: false, offset: [40, 40]})
				.setContent(`<div>${this.state.selectedOutflows.find(s =>s.stationCode === code).basin}</div>`);
            layer.bindPopup(popup);
            layer.on("add", function (event) {
                event.target.openPopup();
            });
        }
    }

    hideAvailable(){
        this.setState({
            hideAvailable : true
        })
    }

	showAvailable(){
		this.setState({
            hideAvailable : false
        })
	}

    render() {
		let title = this.props.stepLabel + ' ' + DateUtils.getMonthNameFromMonthNumber( this.props.month) + ' ' + this.props.year
        return (
            <>
                <div className="row justify-content-center">
                    <h2>{title}</h2>
                </div>
                <div className="row">
                    <div className="col-8" >
                        {!this.state.viewMode &&
                            <>
							
                                <div className="d-flex justify-content-between">
                                    <h3>Deflussi disponibili</h3>
									{!this.state.hideAvailable ? 
										<Fab variant="extended" color="primary" aria-label="add"
											onClick={(e) => this.hideAvailable()} >
											Nascondi Deflussi disponibili
										</Fab>
									:
										<Fab variant="extended" color="primary" aria-label="add"
											onClick={(e) => this.showAvailable()} >
											Mostra Deflussi disponibili
										</Fab>
									}
                                </div>
								{!this.state.hideAvailable && 
									<ReactTabulator
										columns={this.getOutflowStatsAvailableColumns()}
										data={(this.state.statsData||[]).filter(a => !this.state.selectedOutflows.map(s => s.stationCode).includes(a.stationCode))}
										options={this.getTabulatorOptions()}
										key={"available_" + this.props.tableKey}
									/>
								}
                            </>
                        }
                        <div className="d-flex justify-content-between">
                            <h3>Deflussi selezionati</h3>

                            
                            {!this.props.viewMode &&
                                <Tooltip title={"Rimuovi tutti"}>
                                    <IconButton color="secondary" aria-label="upload picture" component="span"
                                                size="medium"
                                                disabled={this.state.selectedOutflows.length < 1}
                                                onClick={(e) => {
                                                    this.clearSelected()
                                                }}>
                                        <RemoveIcon fontSize="large"/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
						<DownloadContainer 
							imageName={title+" tabella"}
							disabled={this.state.loading || this.props.viewMode}
							>
							<ReactTabulator
								width={"500px"}
								columns={this.getOutflowStatsSelectedColumns()}
								data={this.state.selectedOutflows || []}
								options={this.getTabulatorOptions()}
								key={"selected_" + this.props.tableKey}
							/>
						</DownloadContainer>
                    </div>
                    <div className="col-4" >
                        {!this.props.viewMode ?
                            <div className="custom-popup-map" style={{pointerEvents : "none"}} onClick={(event)=>{event.stopPropagation()}}>
							{!!this.state.stationGeoJson ?
							<DownloadContainer 
							imageName={title+" mappa"}
							disabled={this.state.loading || this.props.viewMode}
							>
                                <MapComponent
                                    id = {this.img}
                                    attributionControl={false}
                                    width={'100%"'}
                                    height={'60vh'}
                                    backgroundColor={'rgba(255,0,0,0.0)'}
                                    zoomSnap={false}
                                    dragging={false}
                                    scrollWheelZoom = {false}
                                    zoomControl = {false}
                                    doubleClickZoom = {false}
                                    noHome={true}>

                                <GeoJSON key={"calabria_"}
                                          data={GISTools.getCalabriaBorders()}
                                          style={{
                                              fillColor: "#fff",
                                              fillOpacity: 0,
                                              weight: 2,
                                              opacity: 1,
                                              color: "green",
                                          }}

                                />

                                <GeoJSON key={"idro_"}
                                          data={GISTools.getCalabriaIdro()}
                                          style={{
                                              fillColor: "#fff",
                                              fillOpacity: 0,
                                              weight: 1,
                                              opacity: 0.3,
                                              color: "#36998d",
                                          }}
                                />

                                
                                    <GeoJSON key={"stations_" + this.state.tableKey}
                                              data={this.state.stationGeoJson}
                                              onEachFeature={(feature, layer) => this.onEachStation(feature, layer)}
                                              pointToLayer={(feature, latlng) => this.stationToMarker(feature, latlng)}

                                    />
                                    

                                </MapComponent>
							</DownloadContainer>
							:
							<></>}
                        </div>
                        :
                            <img alt={''} id={this.img} src={this.props.formData[this.section][this.img]} />
                        }
                    </div>
                </div>

                <MobileStepper
                  style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
                  position="static"
                  variant="text"
                  activeStep={this.props.activeStep}
                  steps={this.props.totalSteps}
                  nextButton={
                  <div style={{position: 'relative'}}>
                      <SkipButton
                          variant="contained"
                          color="primary"
                          disabled={this.state.calculating || this.state.loading }
                          onClick={this.handleSkipStep}
                          textbutton={this.props.textSkipButton}
                      />
                    <NextButton
						variant="contained"
						color="primary"
						disabled={this.state.loading || !this.state.enableSave || this.props.viewMode}
						onClick={this.handleNextStep}
						textbutton={this.props.textNextButton}
                    />
                    {this.state.loading && <CircularProgress size={24} style={BUTTON_PROGRESS_STYLE} />}
                  </div>
                  }
                  backButton={
                  <BackButton
                    variant="contained"
                    disabled={this.props.disableBackButton || this.state.loading}
                    onClick={this.props.handleBack}
                  />
                  }
                />


            </>
        )
    }
}
