import { properties} from '#/properties.js';

import parse_georaster from "georaster"
import APIUtils from "#/lib/APIUtils";
import AuthenticationService from "#/lib/AuthenticationService";


export default class DataRequestClient {

	static getNowcastingData(okCallback, errCallback){
		let url = properties.url.nowcastingData;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

}
