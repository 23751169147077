import React from 'react';
import { withRouter } from 'react-router';

import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InfostreamClient from '#/lib/InfostreamClient';

import { HeaderForm } from '#/commons/components/forms/HeaderForm';
import { TextFieldForm } from '#/commons/components/forms/TextFieldForm';
import { ResetButton } from '#/commons/components/forms/ResetButton';
import { SaveButton } from '#/commons/components/forms/SaveButton';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

/* icons */
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TwitterIcon from '@material-ui/icons/Twitter';
import AddIcon from '@material-ui/icons/Add';
import { blue, orange } from '@material-ui/core/colors';

const ReactSwal = withReactContent(Swal);

class InfostreamEditorPage extends React.Component {

	constructor(props) {
		super(props);
		const url = window.location.href;
		const id = url.split('/').pop();
		this.state = {
			id: id!=='tweet' && id!=='rss' ? id : null,
			defaultValues : {},
			regex : null,
			ambiti : [],
			sinossi : null
		}
	}

	componentDidMount() {
		if(!!this.state.id){
			InfostreamClient.getConfigById (
				this.props.type,
				this.state.id,
				(result) => {
					const ambiti = !!result.json_data ? JSON.parse(result.json_data) : [];
					const sinossi = this.translateAmbitiToSinossi(ambiti);
					this.setState({defaultValues:{...result}, ambiti: ambiti, sinossi: sinossi})
				},
				(msg) => {
					ReactSwal.fire(
						'Errore API',
						'Errore durante il recupero dei dati',
						'error'
					)
				}
			)
		}
	}

	translateAmbitiToSinossi = (ambiti) => {
		let sinossi = '';
		for (let i=0; i < ambiti.length; i++){
			const ambito = ambiti[i];
			const listItemToSearch = ambito.listItem;
			sinossi += i===0 ? 'Ricerca articoli che menzionano<br/>' : '<br/>E<br/>';
			for (let j=0; j < listItemToSearch.length; j++){
				sinossi += j===0 ? listItemToSearch[j] : ' O ' + listItemToSearch[j];
			}
		}
		return sinossi;
	}

	translateAmbitiToREGEX = (ambiti) => {
		let regex = '';
		for (let i=0; i < ambiti.length; i++){
			const ambito = ambiti[i];
			const listItemToSearch = ambito.listItem;
			if (listItemToSearch.length > 0) {
				for (let j=0; j < listItemToSearch.length; j++){
					regex += j===0 ? '(?=.*\\b(?:' + listItemToSearch[j] : '|' + listItemToSearch[j];
				}
				regex += '\\b))';
			}
		}
		regex = !!regex ? 'REGEXP:^' + regex + '.*$' : '';
		return regex;
	}

	translateAmbitiToREGEXTwitter = (ambiti) => {
		let regex = '';
		for (let i=0; i < ambiti.length; i++){
			const ambito = ambiti[i];
			const listItemToSearch = ambito.listItem;
			if (listItemToSearch.length > 0) {
				regex += i>0 ? ' AND ' : '';
				for (let j=0; j < listItemToSearch.length; j++){
					regex += j===0 ? '(' + listItemToSearch[j] : ' OR ' + listItemToSearch[j];
				}
				regex += ')';
			}
		}
		return regex;
	}

	translateToRegexp = (string) =>{
		let res = '';
		if (!!string) {
			string = string.replace(/ /g,'');
			let splittedStringByAND = string.split('&');
			for (let i=0; i < splittedStringByAND.length; i++) {
				res += splittedStringByAND[i].replace('(', '(?=.*\\b(?:') + '\\b)';
			}
			if(!!res){
				res = 'REGEXP:^' + res + '.*$';
			}
		}
		return res;
	}

	translateToSinossi = (string) => {
		let res = '';
		if (!!string) {
			string = string.replace(/ /g,'');
			let splittedStringByAND = string.split('&');
			for (let i=0; i < splittedStringByAND.length; i++) {
				let tmpString = splittedStringByAND[i].replace('(', '');
				tmpString = tmpString.replace(')','');
				tmpString = tmpString.replace('|',' O ');
				if (!!res){
					res += ' E ' + tmpString;
				} else {
					res = tmpString;
				}

			}
		}
		return res;
	}

	createAmbito = (name) => {
		if (!!name){
			let ambito = {
				title: name,
				listItem : []
			}
			let tmpAmbiti = this.state.ambiti;
			tmpAmbiti.push(ambito);
			this.setState({
				ambiti : tmpAmbiti
			})
		}
	}

	deleteAmbito = (idx) => {
		let tmpAllAmbiti = this.state.ambiti;
		tmpAllAmbiti.splice(idx, 1);
		const sinossi = this.translateAmbitiToSinossi(tmpAllAmbiti);
		this.setState({
			ambiti : tmpAllAmbiti,
			sinossi : sinossi
		})
	}

	addListItemToAmbito = (idxAmbito, item) => {
		if (!!item){
			let tmpAllAmbiti = this.state.ambiti;
			let tmpAmbito = tmpAllAmbiti[idxAmbito];
			tmpAmbito.listItem.push(item);
			const sinossi = this.translateAmbitiToSinossi(tmpAllAmbiti);
			this.setState({
				ambiti : tmpAllAmbiti,
				sinossi : sinossi
			})
		}
	}

	deleteListItemFromAmbito = (idxAmbito, idxItem) => {
		let tmpAllAmbiti = this.state.ambiti;
		let tmpAmbito = tmpAllAmbiti[idxAmbito];
		tmpAmbito.listItem.splice(idxItem, 1);
		const sinossi = this.translateAmbitiToSinossi(tmpAllAmbiti);
		this.setState({
			ambiti : tmpAllAmbiti,
			sinossi : sinossi
		})
	}

	onSubmitForm = (data) => {
		const ambiti = this.state.ambiti;
		let errors = 0;
		if (ambiti.length>0){
			for (let i=0; i < ambiti.length; i++){
				const ambito = ambiti[i];
				const listItemToSearch = ambito.listItem;
				if (listItemToSearch.length === 0) {
					errors++;
				}
			}
		} else {
			errors++;
		}

		if (errors>0){
			ReactSwal.fire(
				'Errore di validazione',
				'Verificare che ogni ambito creato abbia un elemento di ricerca associato',
				'error'
			);
			return;
		}

		let regexAmbiti = this.props.type === 'rss' ? this.translateAmbitiToREGEX(this.state.ambiti) : this.translateAmbitiToREGEXTwitter(this.state.ambiti);
		let params = {
			type : this.props.type,
			id: this.state.id,
			audit_crt_time: !!this.state.defaultValues.audit_crt_time ? this.state.defaultValues.audit_crt_time : new Date().getTime(),
			audit_mod_time: new Date().getTime(),
			urls: data.urls,
			include_words: regexAmbiti,
			contained_words: regexAmbiti,
			howmany : 20,
			languages : 'it',
			json_data: JSON.stringify(this.state.ambiti)
		}
		InfostreamClient.save (
			params,
			(result) => {
				ReactSwal.fire('Archiviazione andata a buon fine', '', 'success');
				this.props.history.push("/backoffice/stream/conf");
			},
			(msg) => {
				ReactSwal.fire(
					'Errore API',
					'Errore durante il salvataggio dei dati',
					'error'
				)
			}
		)
	}

	render = () => {
		if ((!!this.state.id && !!this.state.defaultValues.audit_crt_time) || (this.state.id===null)){
			return (
				<div className="generic-page">
					<div className="container">
						<HeaderForm
							title={!!this.state.id ? "Modifica criteri di ricerca" : "Creazione nuovo criterio di ricerca"}
							iconStart={this.props.type === 'rss' ? 
								<RssFeedIcon  style={{ color: orange[500] }}/> : 
								// <TwitterIcon  style={{ color: blue[500] }}/>
								<svg fontSize={'1rem'} color={blue[500]} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
									<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
								</svg>								
							}
							buttonLabel="Elenco criteri di ricerca"
							buttonHref="/backoffice/stream/conf"/>
						{
							this.props.type === 'rss' ?
								<InfostreamSearchRSSForm
									defaultValues={this.state.defaultValues}
									getSinossi={this.translateToSinossi}
									ambiti={this.state.ambiti}
									sinossi={this.state.sinossi}
									createAmbito={this.createAmbito}
									deleteAmbito={this.deleteAmbito}
									addListItemToAmbito={this.addListItemToAmbito}
									deleteListItemFromAmbito={this.deleteListItemFromAmbito}
									onSubmitForm={this.onSubmitForm}
								/>
								:
								<InfostreamSearchTweetForm
									defaultValues={this.state.defaultValues}
									getSinossi={this.translateToSinossi}
									ambiti={this.state.ambiti}
									sinossi={this.state.sinossi}
									createAmbito={this.createAmbito}
									deleteAmbito={this.deleteAmbito}
									addListItemToAmbito={this.addListItemToAmbito}
									deleteListItemFromAmbito={this.deleteListItemFromAmbito}
									onSubmitForm={this.onSubmitForm}
								/>

						}
					</div>
				</div>

			)
		} else {
			return null;
		}
	}
}

export default withRouter(InfostreamEditorPage)

const InfostreamSearchTweetForm = (props) => {
	const { register, handleSubmit, getValues, setValue, reset } = useForm({defaultValues : props.defaultValues});

	const onSubmit = (data) => {
		props.onSubmitForm(data);
	}

	return (
		<form onSubmit = {handleSubmit(onSubmit)} autoComplete="off">
			<Card>
				<CardHeader title="Criteri di ricerca" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
				<Divider/>
				<CardContent>
					<Grid container direction="row" justify="center" alignItems="center" spacing={3}>
						<Grid item xs={12}>
							<Alert severity="info">
								Inserire l'ambito di ricerca e, per ciascuno di essi, le parole chiave da ricercare.<br/>
							</Alert>
						</Grid>

						<Grid item xs={12}>
							<TextField
								fullWidth
								id = "ambito"
								name = "ambito"
								label = "Crea ambito"
								inputRef={register()}
								InputProps={{endAdornment: (<IconButton tabIndex="-1" edge='end' onClick={() => {props.createAmbito(getValues("ambito")); setValue('ambito', '')}}><AddIcon /></IconButton>)}}
							/>
						</Grid>

					</Grid>


				</CardContent>
			</Card>

			<Card className="mt-4" hidden={props.ambiti.length===0}>
				<CardHeader title="Ambiti di ricerca" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
				<Divider/>
				<CardContent>
				{
					!!props.ambiti.length>0 &&
					props.ambiti.map((element, idx)=> {
						return <AccordionAmbito
									key={idx}
									idxAmbito={idx}
									{...props}
									{...element}/>
					})
				}
				</CardContent>
			</Card>

			{
				!!props.sinossi
				&&
				<Card className="mt-4">
					<CardHeader title="Sinossi" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
					<Divider/>
					<CardContent>
						<div dangerouslySetInnerHTML={{__html: props.sinossi}} />
					</CardContent>
				</Card>
			}

			<Grid container justify="center" alignItems="center" className="mt-1">
				<ResetButton variant="contained" size="large" onClick={() => reset()}/>
				<SaveButton variant="contained" size="large" type="submit" />
			</Grid>

		</form>

	);
}


const InfostreamSearchRSSForm = (props) => {
	const fieldRequiredMessage = "Campo obbligatorio";
	const { register, handleSubmit, getValues, errors, setValue, reset } = useForm({defaultValues : props.defaultValues});

	const onSubmit = (data) => {
		props.onSubmitForm(data);
	}

	return (
		<form onSubmit = {handleSubmit(onSubmit)} autoComplete="off">
			<Card>
				<CardHeader title="Criteri di ricerca" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
				<Divider/>
				<CardContent>
					<Grid container direction="row" justify="center" alignItems="center" spacing={3}>
						<Grid item xs={12}>
							<Alert severity="info">
								Inserire l'ambito di ricerca e, per ciascuno di essi, le parole chiave da ricercare.<br/>
							</Alert>
						</Grid>
						<Grid item xs={12}>
							<TextFieldForm
								id = "urls"
								label = "Url RSS"
								error={!!errors.urls}
								helperText={!!errors.urls ? errors.urls.message : null}
								inputRef={register({ required: fieldRequiredMessage})}
								handleClearButton={() => setValue('urls', null)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id = "ambito"
								name = "ambito"
								label = "Crea ambito"
								inputRef={register()}
								InputProps={{endAdornment: (<IconButton tabIndex="-1" edge='end' onClick={() => {props.createAmbito(getValues("ambito")); setValue('ambito', '')}}><AddIcon /></IconButton>)}}
							/>
						</Grid>

					</Grid>


				</CardContent>
			</Card>

			<Card className="mt-4" hidden={props.ambiti.length===0}>
				<CardHeader title="Ambiti di ricerca" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
				<Divider/>
				<CardContent>
				{
					!!props.ambiti.length>0 &&
					props.ambiti.map((element, idx)=> {
						return <AccordionAmbito
									key={idx}
									idxAmbito={idx}
									{...props}
									{...element}/>
					})
				}
				</CardContent>
			</Card>

			{
				!!props.sinossi
				&&
				<Card className="mt-4">
					<CardHeader title="Sinossi" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
					<Divider/>
					<CardContent>
						<div dangerouslySetInnerHTML={{__html: props.sinossi}} />
					</CardContent>
				</Card>
			}

			<Grid container justify="center" alignItems="center" className="mt-1">
				<ResetButton variant="contained" size="large" onClick={() => reset()}/>
				<SaveButton variant="contained" size="large" type="submit" />
			</Grid>

		</form>

	);
}

const AccordionAmbito = (props) => {
	const { register, getValues, setValue} = useForm();

	return (
		<Grid container direction="row" justify="center" alignItems="center" spacing={3}>
			<Grid item xs={12}>
				<Accordion className="mt-4" defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-label="Espandi"
						aria-controls={props.idxAmbito + "-content"}
						id={props.idxAmbito + "-header"}
						style={{backgroundColor:'#f9f9f9'}}
					>
						<Typography variant='h6'>{props.title}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container direction="row" alignItems="center" spacing={3}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id = "searchItem"
									name = "searchItem"
									label = "Crea voce di ricerca"
									inputRef={register()}
									InputProps={{endAdornment: (<IconButton tabIndex="-1" edge='end' onClick={()=> {props.addListItemToAmbito(props.idxAmbito, getValues("searchItem")); setValue('searchItem', '')}}><AddIcon /></IconButton>)}}
								/>
							</Grid>
							<Grid item xs={12}>
								{
									!!props.listItem &&
									props.listItem.map((element, idx) => getChipItem(idx, element, props))
								}
							</Grid>
						</Grid>
					</AccordionDetails>
					<Divider />
					<AccordionActions>
						<Button size="small" color="primary" onClick={() => {props.deleteAmbito(props.idxAmbito)}}>
							Cancella Ambito
						</Button>
					</AccordionActions>
			</Accordion>
			</Grid>
		</Grid>
	)
}

const getChipItem = (idx, item, props) => {
	return (
		<Chip key={idx} label={item} onDelete={() => props.deleteListItemFromAmbito(props.idxAmbito, idx)} style={{ justifyContent: 'center', flexWrap: 'wrap', padding: '0.5px', marginRight:'5px', marginTop:'5px'}}/>
	)
}