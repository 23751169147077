import React from "react";

import DateUtils from "#/lib/DateUtils";
/** STEPS **/
import {StepPrevisioniMeteoToday} from "#/backoffice/forecast/steps/StepPrevisioniMeteoToday";
import {StepPrevisioniMeteoTomorrow} from "#/backoffice/forecast/steps/StepPrevisioniMeteoTomorrow";
import moment from 'moment';
import { StepPrevisioniMeteoFile } from "../steps/StepPrevisioniMeteoFile";

const today = new Date().getTime();
const tomorrow = today + 24 * 60 * 60 * 1000;

export const DEFAULT_STEPS = {
	EDIT: [
		{
			label: "Previsione meteo OGGI",
			component: function (props) { return <StepPrevisioniMeteoToday {...props} /> }
		},
		{
			label: "Previsione meteo DOMANI",
			component: function (props) { return <StepPrevisioniMeteoTomorrow {...props} /> }
		},
		{
			label: "Caricamento file",
			component: function (props) { return <StepPrevisioniMeteoFile {...props} /> }
		}
	],
	VIEW: [
		{
			label: "Previsione meteo OGGI",
			component: function (props) { return <StepPrevisioniMeteoToday {...props} /> }
		},
		{
			label: "Previsione meteo DOMANI",
			component: function (props) { return <StepPrevisioniMeteoTomorrow {...props} /> }
		}
	]
}

export const DEFAULT_MAPS = [
	
	{
		title: "Oggi",
		labelForm: " Oggi ",
		mapKey: 0,
		mapId: "today",
		zones: [],
		shapesGeoJSON: null,
	},
	{
		title: "Domani",
		labelForm: " Domani ",
		mapKey: 1,
		mapId: "tomorrow",
		zones: [],
		shapesGeoJSON: null,
	},
	{
		title: "empty",
		labelForm: " empty ",
		mapKey: 2,
		mapId: "empty",
		zones: [],
		shapesGeoJSON: null,
	},
];

export let DEFAULT_FORM_JSON_DATA = {
	geoJson: "",
	img_empty: "",
	today: {
		date_short: DateUtils.epochToLocaleDateFormatted(today),
		hour_start: moment().format("HH:mm"),
		hour_end: "23:59",
		meteoByZone: [],
		fields: {
			situazione: "",
			cielo: "",
			precipitazioni: "",
			temperature: "",
			venti: "",
			mari: ""
		},
	},
	tomorrow: {
		date_short: DateUtils.epochToLocaleDateFormatted(tomorrow),
		hour_start: "00:00",
		hour_end: "23:59",
		meteoByZone: [],
		fields: {
			situazione: "",
			cielo: "",
			precipitazioni: "",
			temperature: "",
			venti: "",
			mari: ""
		},
	},
};

export const ZONES_DESC = [
	"",
	"Tirreno Settentrionale",
	"Tirreno Centro-Settentrionale",
	"Tirreno Centro-Meridionale",
	"Tirreno Meridionale",
	"Ionio Settentrionale",
	"Ionio Centro-Settentrionale",
	"Ionio Centro-Meridionale",
	"Ionio Meridionale",
];

export const PRECIPITAZIONI_IMPULSIVE_OPTIONS = [
	{
		label: "",
		value: "",
	},
	{
		label: "Rovesci o Temporali Isolati",
		value: "rt_isolati",
		color: 'yellow'
	},
	{
		label: "Temporali isolati",
		value: "t_isolati",
		color: 'orange'
	},
	{
		label: "Temporali sparsi",
		value: "t_sparsi",
		color: 'red'
	},
	{
		label: "Temporali diffusi",
		value: "t_diffusi",
		color: '#4a0505'
	},
];
export const WEATHER_ICONS_PNG = {
	black_low_cloud: '/img/weather/png/black_low_cloud.png',
	cloudy_with_heavy_rain: '/img/weather/png/cloudy_with_heavy_rain.png',
	cloudy_with_heavy_snow: '/img/weather/png/cloudy_with_heavy_snow.png',
	cloudy_with_light_rain: '/img/weather/png/cloudy_with_light_rain.png',
	cloudy_with_light_snow: '/img/weather/png/cloudy_with_light_snow.png',
	cloudy_with_sleet: '/img/weather/png/cloudy_with_sleet.png',
	fog: '/img/weather/png/fog.png',
	heavy_rain_showers: '/img/weather/png/heavy_rain_showers.png',
	heavy_snow_showers: '/img/weather/png/heavy_snow_showers.png',
	light_rain_showers: '/img/weather/png/light_rain_showers.png',
	light_snow_showers: '/img/weather/png/light_snow_showers.png',
	mist: '/img/weather/png/mist.png',
	sleet_showers: '/img/weather/png/sleet_showers.png',
	sunny: '/img/weather/png/sunny.png',
	sunny_intervals: '/img/weather/png/sunny_intervals.png',
	thunderstorms: '/img/weather/png/thunderstorms.png',
	thundery_showers: '/img/weather/png/thundery_showers.png',
	white_cloud: '/img/weather/png/white_cloud.png'

}
export const WIND_ICONS_PNG = {
	wind_5: '/img/weather/png/wind_5.png',
	wind_10: '/img/weather/png/wind_10.png',
	wind_20: '/img/weather/png/wind_20.png',
	wind_25: '/img/weather/png/wind_25.png',
	wind_50: '/img/weather/png/wind_50.png',
	wind_max: '/img/weather/png/wind_max.png'
}
export const SEA_ICONS_PNG = {
	agitato: '/img/weather/png/agitato.png',
	calmo_1: '/img/weather/png/calmo_1.png',
	molto_mosso_2_2: '/img/weather/png/molto_mosso_2_2.png',
	mosso_1: '/img/weather/png/mosso_1.png',
	poco_mosso_1: '/img/weather/png/poco_mosso_1.png'
}
export const WEATHER_ICONS_SVG = {
	black_low_cloud: '/img/weather/svg/black_low_cloud.svg',
	cloudy_with_heavy_rain: '/img/weather/svg/cloudy_with_heavy_rain.svg',
	cloudy_with_heavy_snow: '/img/weather/svg/cloudy_with_heavy_snow.svg',
	cloudy_with_light_rain: '/img/weather/svg/cloudy_with_light_rain.svg',
	cloudy_with_light_snow: '/img/weather/svg/cloudy_with_light_snow.svg',
	cloudy_with_sleet: '/img/weather/svg/cloudy_with_sleet.svg',
	fog: '/img/weather/svg/fog.svg',
	heavy_rain_showers: '/img/weather/svg/heavy_rain_showers.svg',
	heavy_snow_showers: '/img/weather/svg/heavy_snow_showers.svg',
	light_rain_showers: '/img/weather/svg/light_rain_showers.svg',
	light_snow_showers: '/img/weather/svg/light_snow_showers.svg',
	mist: '/img/weather/svg/mist.svg',
	sleet_showers: '/img/weather/svg/sleet_showers.svg',
	sunny: '/img/weather/svg/sunny.svg',
	sunny_intervals: '/img/weather/svg/sunny_intervals.svg',
	thunderstorms: '/img/weather/svg/thunderstorms.svg',
	thundery_showers: '/img/weather/svg/thundery_showers.svg',
	white_cloud: '/img/weather/svg/white_cloud.svg'
}
export const WIND_ICONS_SVG = {
	wind_5: '/img/weather/svg/wind_5.svg',
	wind_10: '/img/weather/svg/wind_10.svg',
	wind_20: '/img/weather/svg/wind_20.svg',
	wind_25: '/img/weather/svg/wind_25.svg',
	wind_50: '/img/weather/svg/wind_50.svg',
	wind_max: '/img/weather/svg/wind_max.svg'
}

export const PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS = [
	{
		label: "",
		value: "",
	},
	{
		label: "Piogge Isolate",
		value: "p_isolate",
		color: 'yellow'
	},
	{
		label: "Piogge Sparse",
		value: "p_sparse",
		color: 'orange'
	},
	{
		label: "Piogge diffuse",
		value: "p_diffuse",
		color: 'red'
	},
];

export const FORECAST_OPTIONS = [
	{
		label: "",
		value: "",
	},
	{
		label: "Sole",
		value: "sunny",
		color: 'yellow'
	},
	{
		label: "Pioggia",
		value: "rainy",
		color: 'orange'
	},
	{
		label: "Temporale",
		value: "thunderstorm",
		color: 'red'
	},
];
export const PRECIPITAZIONI_ICONS = {
	p_isolate : '/img/mauIcons/water-drop_512px.png',
	p_sparse : '/img/mauIcons/2_drops_512px.png',
	p_diffuse : '/img/mauIcons/3_drops_512px.png',
	rt_isolati : '/img/mauIcons/bolt_emprty_triangle_512px.png',
	t_isolati : '/img/mauIcons/bolt_solid_triangle_512px.png',
	t_sparsi : '/img/mauIcons/bolt_512px.png',
	t_diffusi : '/img/mauIcons/2_bolts_512px.png',
	wind: '/img/mauIcons/windStrong_512px.png',
	frost: '/img/mauIcons/thermometer_512px.png',
	storm_surges: '/img/mauIcons/big_wave_512px.png',
	no_wind: '/img/mauIcons/absence_windStrong_512px.png',
	no_frost: '/img/mauIcons/absence_thermometer_512px.png',
	no_storm_surges: '/img/mauIcons/absence_big_wave_512px.png',
}

export const getWeightByColor = (color) => {
	return ALLERTAMENTO_IDRO_DEFINITIONS.find(def => def.label === color).weight || 0;
}

export const ALLERTAMENTO_IDRO_DEFINITIONS = [
	{
		label: "VERDE",
		value: "VERDE",
		color: "green",
		weight: 0,
		scenario:
			"APPENDICE 4: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello VERDE;",
	},
	{
		label: "GIALLO",
		value: "GIALLO",
		color: "yellow",
		weight: 1,
		scenario:
			"APPENDICE 5: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello GIALLO;",
	},
	{
		label: "ARANCIONE",
		value: "ARANCIONE",
		color: "orange",
		weight: 2,
		scenario:
			"APPENDICE 6: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello ARANCIONE;",
	},
	{
		label: "ROSSO",
		value: "ROSSO",
		color: "red",
		weight: 3,
		scenario:
			"APPENDICE 7: Scenari d'evento e possibili danni per piogge previste - Allertamento Livello ROSSO;",
	},
];

export const MAP_LAYER_LABELS = {
	rainfall_pni: 'Precipitazioni',
	rainfall_pi: 'Temporali',
	storm_surges: 'Mareggiate',
	wind: 'Venti',
	frost: 'Temperature estreme',
	fasi_op: 'Fasi operative comunali',
	idro_temp: 'Criticità idrogeologica per temporali',
	idro_idra: 'Criticità idrogeologica idraulica'
}
