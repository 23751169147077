import React from 'react';
import BulletinClient from '#/lib/BulletinClient';
import DateUtils from '#/lib/DateUtils';
import Skeleton from "react-loading-skeleton";

export default class PostEventoDetails extends React.Component {

    constructor(props) {
        super(props)
        if (!!this.props) {
            this.state = { ...this.props }
        }
    }

    componentDidMount() {
        if (!(!!this.props.id)) {
            let params = {
                pageNumber: 1,
                pageSize: 1,
                bulletinTypeName: this.props.bulletinTypeName
            }
			BulletinClient.listBulletins(
                (obj) => { this.setState({...obj.data[0]}) },
                (err) => { console.log('Errore mount Report post evento Details') },
                params
            )
        }
    }


    render() {
        if (!(!!this.state.id)) {
            return <Skeleton height={"50vh"} duration={8} />
        }

		let startD = DateUtils.fromISO8601ToDate(this.state.jsonData.startDate);
		let startF = this.state.jsonData.endDate ? startD.getDate() : DateUtils.epochToLongLocaleDateFormatted(startD.getTime());
		let endD = this.state.jsonData.endDate ? DateUtils.fromISO8601ToDate(this.state.jsonData.endDate) : null;
		let endF = endD ?  "-" + DateUtils.epochToLongLocaleDateFormatted(endD.getTime()) : "";
		let todayTitle = "Report post evento " + startF + endF;


        const tomorrowTitle = "Report post evento " + startF + endF;
        const imgToday =  '';
        const imgTomorrow = '';

        return (
            <>
                <div className="card item-card transparent-card" >
                    <div >
                        <div className="container">

                            <div className="map-container">
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <div className="map-header">
                                            <h3 className="map-header-title">
                                                {todayTitle} 
                                            </h3>
                                        </div>
                                        <div className="map-image">
                                            <img
                                                className="item-img"
                                                src={imgToday}
                                                alt="mau"
                                                align="center"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 text-center map-header">
                                        <div className="map-header">
                                            <h3 className="map-header-title">
                                                {tomorrowTitle} 
                                            </h3>
                                        </div>
                                        <div className="map-image">
                                            <img
                                                className="item-img"
                                                src={imgTomorrow}
                                                alt="mau"
                                                align="center"
                                                >
                                            </img>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
};
