import React from "react";

import DateUtils from "#/lib/DateUtils";
/** STEPS **/
import { StepUploadFile } from "#/backoffice/reportPostEvento/steps/StepUploadFile";

const today = new Date().getTime();
const tomorrow = today + 24 * 60 * 60 * 1000;

export const DEFAULT_STEPS = {
  EDIT : [
    {
      label : "Caricamento report",
      component : function(props) { return <StepUploadFile {...props} />}
    }
  ],
  VIEW : [
    {
      label : "Report post evento",
      component : function(props) { return <StepUploadFile {...props} />}
    },
  ]
}



export let DEFAULT_FORM_JSON_DATA = {
  selectedFile: null,
  selectedFileName: null,
  startDate: DateUtils.epochToLocaleDateFormatted(today),
  endDate: DateUtils.epochToLocaleDateFormatted(tomorrow)
};

