import React from 'react';
import {withRouter} from 'react-router';

import DateUtils from '#/lib/DateUtils'
import {FiClock} from 'react-icons/fi';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {Badge, Modal} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '#/backoffice/style/SweetAlert.css';
import BulletinClient from '#/lib/BulletinClient';
import {ApproveButton} from "#/commons/components/forms/ApproveButton";
import {PublishButton} from "#/commons/components/forms/PublishButton";
import ForecastComponent from "#/commons/forecast/ForecastComponent";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import GISTools from "#/lib/GISTools";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import GetAppIcon from '@material-ui/icons/GetApp';
import {properties} from '#/properties';

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
class ForecastItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modalState : {
			  show: false,
			  onHide : this.hideModal,
			},
		}
	}
	
	componentDidMount() {
		let accordionBody = document.getElementById("forecastAccordion_" + this.props.id);
		if (!!accordionBody) {
			accordionBody.addEventListener('transitionend', () => {
				setTimeout(() => {
					this.fixMap()
				}, 250);
			})
		}
		const modalState = {...this.state.modalState};
		this.setState({modalState : modalState});
	}

	approve = (id) => {
		loadingSwal.fire('Approvazione in corso...')
		let params = {
			bulletinTypeName: 'forecast',
			id: id
		};
		BulletinClient.approveBulletin(
			(result) => {
				loadingSwal.close()
				ReactSwal.fire("Approvazione Previsioni Meteo", "Previsioni Meteo approvate con successo","success")
					.then(result => {
						if (result.value && this.props.refreshList) {
							this.props.refreshList()
						}
					});
			},
			function (msg) {
				loadingSwal.close()
				ReactSwal.fire("Approvazione Previsioni Meteo", msg, "error");
			},
			params
		);
	}

	publish = (id) => {
		loadingSwal.fire('Pubblicazione in corso...')
		let params = {
			bulletinTypeName: 'forecast',
			id: id
		};

		BulletinClient.publishBulletin(
			(result) => {
				loadingSwal.close()
				ReactSwal.fire("Pubblicazione Previsioni Meteo", "Previsioni Meteo pubblicate con successo", "success")
					.then(result => {
						if (result.value && this.props.refreshList) {
							this.props.refreshList()
						}
					});
			},
			(msg) => {
				loadingSwal.close()
				ReactSwal.fire("Pubblicazione Previsioni Meteo", msg, "error");
			},
			params
		);



	}

	delete = (id) => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione dell Previsioni Meteo del giorno {DateUtils.epochToLocaleDateFormatted(DateUtils.fromISO8601ToDate(this.props.createdAt).getTime())}?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'No'
		})
			.then((result) => {
				if (result.value) {
					loadingSwal.fire('Eliminazione in corso...')
					let params = {
						bulletinTypeName: 'forecast',
						id: id
					};
					BulletinClient.deleteBulletin (
						(result) => {
							loadingSwal.close()
							ReactSwal.fire('Cancellazione Previsioni Meteo', 'Il record è stato eliminato con successo', 'success')
								.then(result => {
									if (result.value && this.props.refreshList) {
										this.props.refreshList()
									}
								});
						},
						(msg) => {
							loadingSwal.close()
							ReactSwal.fire('Errore cancellazione Previsioni Meteo', 'error');
						},
						params
					)
				}
			})

	}


	onEdit(){
		if(this.props.isEditable ){
			this.props.history.push("/backoffice/forecast/edit/"+this.props.id)
		}
	}

	getForecastTitle(){
		let baseTitle = "Previsioni Meteo del : " + DateUtils.epochToLocaleDateFormatted(DateUtils.fromISO8601ToDate(this.props.createdAt).getTime());
		return baseTitle;

	}
	fixMap = () => {
		// if (!!this.state.currentMap){
		// 	this.state.currentMap.leafletElement.invalidateSize();
		// 	this.centerMap();
		// }
		this.showModal();
	}
	centerMap() {
		let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
		this.state.currentMap.leafletElement.fitBounds([
			[bbox.bbox[1], bbox.bbox[0]],
			[bbox.bbox[3], bbox.bbox[2]]
		]);
	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	hideModal = () => {
		const modalState = {...this.state.modalState, show: false};
		this.setState({ modalState : modalState });
	  }
	
	showModal = () => {
		const modalState = {...this.state.modalState, show: true};
		this.setState({ modalState : modalState });
	}

	render() {
		let forecastTitle = this.getForecastTitle();
		// TODO : Aggiungere controllo ruolo amministratore
		let docUrl = !!this.props.id ? properties.url.bulletinDownloadFile + '/' + this.props.id + '/pdf' : "";
		let isEditable = this.props.isEditable && !this.props.published && this.isOfficer();
		let isDeletable = this.props.isDeletable && !this.props.published && this.isOfficer();

		return (
			<>
			<Card style={{ marginBottom: '0.7rem', borderRadius: '5px', border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }}>
				<Card.Header onClick={() => this.fixMap()}>
					<div className="row" style={{ display: "flex" }}>
						<div className="col-sm">
							<div className="row">
								<span className="icon-span"> <FiClock /> Emanate il: {DateUtils.epochToDate(this.props.createdAt)} </span>
								{(!!this.props.editable) ? this.getBadge(this.props) : <></>}
							</div>
							<div>
								<span className="item-title">{forecastTitle}</span>
							</div>
						</div>
						<div className="col-sm d-flex justify-content-end align-items-center">
							{!!this.props.editable ?
								<>
									<div className="my-auto col-lg-6 col-12 pr-auto" style={{textAlign: '-webkit-center'}}>
										{!!this.props.id && !this.props.published  && this.getApprovePublishButton(this.props)}
									</div>
									<div className="my-auto pr-2" style={{textAlign: '-webkit-center'}}>
										<Fab size="medium" title={isEditable ? "Modifica" : "Visualizza"} aria-label={isEditable ? "edit" : "view"} onClick={() => this.onEdit()} disabled={!isEditable}
											style={isEditable ? {backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'} 
											: {backgroundColor: '#0000001f', borderRadius: '5px', color: '#fff'}}
										>
											<EditIcon color={'#fff'} />
										</Fab>
									</div>
									<div className="my-auto pr-auto" style={{textAlign: '-webkit-center'}}>
										<Fab size="medium" title='Cancella' aria-label="delete" onClick={() => this.delete(this.props.id)}
											style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
										>
											<DeleteIcon color={'#fff'} />
										</Fab>
									</div>
								</>
								:
								<></>
							}
							{
								this.props.jsonData.uploadedFile ?
								<>
								<div className="my-auto mx-2" >
									<Fab size="medium" title="Scarica" aria-label="Scarica" href={docUrl} onClick={(event) => {event.stopPropagation();}}
										style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
									>
										<GetAppIcon  color={!!this.props.id ? 'action' : 'disabled'} style={{color: '#fff'}} />
									</Fab>
								</div>
								</>
								:
								<></>
							}
						</div>
					</div>
				</Card.Header>

				{/* <Accordion.Collapse eventKey={this.props.id} id={"forecastAccordion_" + this.props.id}>
					<Card.Body>
						<ForecastComponent mapHasBeenChanged={map => this.setState({currentMap: map})} key={this.props.id} forecastItem={this.props.forecastItem} mapHeight='50vh' zoom={4}/>
					</Card.Body>
				</Accordion.Collapse> */}
			</Card>
			{this.state.modalState.show && <this.ModalToggle {...this.state.modalState} />}
			</>
		)
	}

	getBadge = (props) => {
		let retValue = <></>;
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		let published = props.published;
		if (notApproved){
			retValue = <span>  </span>;
		} else if(onlyApproved){
			retValue = <span> <Badge variant="info">Approvato</Badge> </span>;
		} else if (published) {
			retValue = <span> <Badge variant="success">Pubblicato</Badge> </span>;
		}
		return (retValue);
	}

	getApprovePublishButton = (props) => {
		let retValue = <></>;
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		if (notApproved){
			retValue = <ApproveButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={() => this.approve(props.id)}/>;
		} else if(onlyApproved){
			retValue = <PublishButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={() => this.publish(props.id)}/>;
		}
		return (retValue);
	}

	ModalToggle = (props) => {
		let forecastTitle = this.getForecastTitle();
	
		return (
			<Modal
				show={props.show}
				onHide={props.onHide}
				aria-labelledby="contained-modal-title-vcenter"
				size="xl"
				centered
			>
				<Modal.Header closeButton style={{border: 'none'}}>
					<Modal.Title id="contained-modal-title-vcenter">
						<div className='d-flex flex-column'>
							<span className="item-title">{forecastTitle}</span>
							<span className="icon-span"> <FiClock /> Emanate il: {DateUtils.epochToDate(this.props.createdAt)} </span>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='d-flex justify-content-center align-items-center overflow-auto'>
					<ForecastComponent mapHasBeenChanged={map => this.setState({currentMap: map})} key={this.props.id} forecastItem={this.props.forecastItem} mapHeight='50vh' zoom={4}/>
				</Modal.Body>
				<Modal.Footer style={{border: 'none'}}></Modal.Footer>		  
			</Modal>
		);
	}
};

export default withRouter(ForecastItem)
