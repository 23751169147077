import React from "react";
import {ReturnListButton} from "#/commons/components/forms/ReturnListButton";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

export const HeaderForm = (props) => {
  return (
    <AppBar
      position="static"
      color="transparent"
      className="mt-2"
      elevation={0}
    >
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={0}
        >
          <h2>{props.iconStart} {props.title} {props.iconEnd}</h2>


          {!!props.buttonLabel ? <ReturnListButton label={props.buttonLabel} href={props.buttonHref} /> : null}

        </Grid>
      </Toolbar>
    </AppBar>
  );
};
