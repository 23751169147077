

export const validationOptions = {
	VALID: "Dato valido",
	MISSING: "Dato mancante",
	SUSPECT : "Dato Sospetto",
	NOT_VALID : "Dato errato",
	NOT_VALIDATED : "Dato non validato",
	RECONSTRUCTURED : "Dato ricostruito"
}
export const validationColors = {
	VALID: "rgba(16,187,34,0.8)",
	MISSING: "#ff00008a",
	SUSPECT : "rgba(234,129,49,0.8)",
	NOT_VALID : "rgba(241,46,2,0.8)",
	NOT_VALIDATED : "rgb(255,255,255)",
	RECONSTRUCTURED : "#31a9e4"
}


