import React from 'react';
import ReactDOM from 'react-dom';
import environment from 'environment';
import './index.css';
import App from './App.jsx';
import { AuthProvider } from "react-oidc-context";
import * as serviceWorker from './serviceWorker';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { UserManager } from 'oidc-client-ts';

const oidcSettings = {
	authority: window.CAE_CONFIG.datascape_url,
	client_id: 'AllertaCal',
	scope: 'openid profile offline_access',
	redirect_uri: environment.base_url + '/backoffice/signin-oidc',
	silent_redirect_uri: environment.base_url + '/backoffice/signin-oidc',
	post_logout_redirect_uri: environment.base_url + '/signout-callback-oidc',
	loadUserInfo: true,
	metadata: {
		"issuer": window.CAE_CONFIG.datascape_url,
		"authorization_endpoint": window.CAE_CONFIG.datascape_url + "/connect/authorize",
		"token_endpoint": window.CAE_CONFIG.datascape_url + "/connect/token",
		"end_session_endpoint": window.CAE_CONFIG.datascape_url + "/connect/logout",
		"jwks_uri": window.CAE_CONFIG.datascape_url + "/.well-known/jwks",
		"userinfo_endpoint": environment.base_api_url + "/priv/userinfo"
	},
	response_type: "code",
}

if (environment.env === 'local') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, { logOnDifferentValues: true });
}

ReactDOM.render(
	<AuthProvider {...oidcSettings}>
		<App />
	</AuthProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const userManager = new UserManager(oidcSettings);

const refreshToken = async () => {
    try {
        const user = await userManager.signinSilent();

        if (user && user.access_token) {
            sessionStorage.setItem("access_token", user.access_token);
            sessionStorage.setItem("refresh_token", user.refresh_token || "");
            return user.access_token;
        } else {
            console.log("Nessun token aggiornato ricevuto.");
        }
    } catch (error) {
        console.log("Errore nel refresh del token:", error);
    }
    return null;
};

export { refreshToken };







