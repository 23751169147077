import React from 'react';
import {withRouter} from 'react-router';
import {Link} from "react-router-dom";

import {Navbar} from 'react-bootstrap';
import InfostreamSearchParamItem from './InfostreamSearchParamItem';
import PaginationComponent from '#/commons/components/PaginationComponent';
import Chip from '@material-ui/core/Chip';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TwitterIcon from '@material-ui/icons/Twitter';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import {blue, orange} from '@material-ui/core/colors';
import InfostreamClient from '#/lib/InfostreamClient';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ReactSwal = withReactContent(Swal);
class InfostreamSearchPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			twitterFilter: true,
			rssFilter: false,
			newsType: "tweet"
		}
	}

	fromJsonToInfostreamSearchParamItem = (obj, editable) => {
		return <InfostreamSearchParamItem {...obj}  key={obj.id} editable={editable} onClickDelete={this.onDeleteHandle}/>;
	}

	getPage = (successCallback, errorCallback, page, type) => {
		let params = {
			pageNumber: page,
			pageSize: 5
		}

		if (this.state.rssFilter) {
			InfostreamClient.getRssConfig(
				(obj)=>{successCallback(obj, page)},
				(err)=>{errorCallback(err)},
				params
			)
		} else {
			InfostreamClient.getTweetConfig(
				(obj)=>{successCallback(obj, page)},
				(err)=>{errorCallback(err)},
				params
			)
		}

	}

	handleTwitterChipClick = () => {
		this.setState({
			twitterFilter: true,
			rssFilter: false,
			newsType: "tweet"
		});
	};

	handleRSSChipClick = () => {
		this.setState({
			twitterFilter: false,
			rssFilter: true,
			newsType: "rss"
		});
	};

	onDeleteHandle = (type, id) => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione del criterio di ricerca?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Invia',
			cancelButtonText: 'Annulla'
		})
		.then((result) => {
			if (result.value) {
				InfostreamClient.delete(
					type,
					id,
					(result) => {
						ReactSwal.fire('Cancellazione criterio di ricerca', 'Il criterio di ricerca selezionato è stato cancellato con successo', 'success');
						this.props.history.go(0); 
					},
					function (msg) {
						ReactSwal.fire('Errore durante la cancellazione', 'error');
					}
				)
			}
		})
	}
	render() {
		return (
			<div className="generic-page">
				<div className="container">
					<Navbar>
						<Navbar.Brand><h2>Configurazione criteri ricerca News</h2></Navbar.Brand>
						<Navbar.Toggle />
						<Navbar.Collapse className="justify-content-end">
							<Chip
								variant="outlined"
								style={{ marginRight: 10}}
								color={this.state.twitterFilter ? "primary" : "secondary"}
								// icon={<TwitterIcon  style={{ color: blue[500] }}/>}
								// label="Twitter"
								icon={
									<svg fontSize={'1rem'} color={blue[500]} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
										<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
									</svg>
								}
								label="X"
								onClick={this.handleTwitterChipClick}
								onDelete={this.handleTwitterChipClick}
								deleteIcon={this.state.twitterFilter ? <DoneIcon /> : <CancelIcon />}
								clickable
							/>
							<Chip
								variant="outlined"
								color={this.state.rssFilter ? "primary" : "secondary"}
								icon={<RssFeedIcon  style={{ color: orange[500] }}/>}
								label="RSS"
								onClick={this.handleRSSChipClick}
								onDelete={this.handleRSSChipClick}
								deleteIcon={this.state.rssFilter ? <DoneIcon /> : <CancelIcon /> }
								clickable
							/>
						</Navbar.Collapse>
					</Navbar>
				</div>
				<PaginationComponent
					key={this.state.newsType}
					className = "container"
					toItemFunction={(obj) => this.fromJsonToInfostreamSearchParamItem(obj, this.props.canEdit)}
					getPage={(successCallback, errorCallback, page) => this.getPage( successCallback, errorCallback, page)} />
				{this.props.canEdit &&
					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
						<Link to={this.props.createUrl+'/'+this.state.newsType}>
							<Tooltip title="Crea un nuovo criterio di ricerca">
								<Fab color="primary">
									<AddIcon />
								</Fab>
							</Tooltip>
						</Link>
					</div>
				}
			</div>
		)
	}
};

export default withRouter(InfostreamSearchPagedList)
