import React from 'react';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import FrontOffice from '#/frontoffice/FrontOffice';
import Test from '#/test/Test';
import BackOffice from './backoffice/BackOffice';
function App() {

	return (
		<>
			<Router>
				<Switch>
					<Route path="/frontoffice">
						<FrontOffice />
					</Route>
					<Route path="/backoffice">
						<BackOffice />
					</Route>
					<Route path="/test">
						<Test />
					</Route>
					<Redirect from="/" to="/frontoffice" />
				</Switch>
			</Router>
		</>
	);
}

export default App;


