import React from 'react';
import PostEventoItem from './PostEventoItem';
import PostEventoDetails from './PostEventoDetails';
import PaginationComponent from '#/commons/components/PaginationComponent';
import BulletinClient from '#/lib/BulletinClient';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import { CircularProgress } from '@material-ui/core';


export default class PostEventoPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDateFilter: null,
			endDateFilter: null,
			pageKey: 0,
			/*allowCreationToday: true*/
			isLoading: false
		}
	}

	forcePageReloading = () => {this.setState({pageKey: (this.state.pageKey + 1) % 1000 })};
	fromJsonToPostEventoItem(obj) {
		let mauDetails = <PostEventoDetails bulletinTypeName={this.props.bulletinTypeName} {...obj} />
		return <PostEventoItem {...obj} key={obj.id} details={mauDetails} editable={this.props.canEdit} refreshList={() => this.forcePageReloading()}/>;
	}

	getPage(successCallback, errorCallback, page) {
		let params = {
			pageNumber: page,
			pageSize: 5,
			bulletinTypeName: this.props.bulletinTypeName
		}
		this.setState({ isLoading: true });
		BulletinClient.listBulletins(
			(obj) => { 
				this.setState({ isLoading: false });
				successCallback(obj, page) 
			},
			(err) => { 
				this.setState({ isLoading: false });
				errorCallback(err) 
			},
			params
		)

	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	render() {
		return (
			<div className="generic-page list-page">

				<h2 className='d-flex justify-content-center align-items-center mb-5'>Report post evento</h2>
				{this.state.isLoading && (
					<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<CircularProgress />
					</div>
				)}
				<PaginationComponent
					key={this.state.pageKey}
					toItemFunction={(obj) => this.fromJsonToPostEventoItem(obj)}
					getPage={(successCallback, errorCallback, page) => this.getPage(successCallback, errorCallback, page)} />

					{this.props.canEdit ?
					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
					<Link to={this.props.createUrl}>
						<Tooltip title="Crea un nuovo Report post evento">
							<Fab color="primary" disabled={!this.isOfficer()}>
								<AddIcon />
							</Fab>
						</Tooltip>
					</Link>
					</div>
				:
				<></>
				}
			</div>
		)
	}
}
