import React, {useState} from "react";
import SensorClient from "#/lib/SensorClient";
import Grid from "@material-ui/core/Grid";
import ThresholdOutflowSensorComponent from "#/backoffice/components/ThresholdOutflowSensorComponent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";

const ReactSwal = withReactContent(Swal);
const SensorOutflowForm = (props) =>  {

    const [allowSave,setAllowSave] = useState(false);
    const [hydrometricZero,setHydrometricZero] = useState();
    const [thresholds,setThresholds] = useState([])
    const [sectionGeometry,setSectionGeometry] = useState([])

    const submitForm = async () => {
        if (!!thresholds && thresholds.length > 0) {

            let thresholdsToSave = [];
            thresholds.forEach(th => {
                thresholdsToSave.push({
                    a: th.a,
                    b: th.b,
                    c: th.c,
                    e: th.e,
                    thresholdEnd: th.thresholdEnd,
                    thresholdStart: th.thresholdStart,
                    sensorCode: props.data.code,
                    stationCode: props.data.stationCode
                })
            })

            let thresholdPromise = new Promise((resolve,reject)=>{
                SensorClient.saveOutflowsBySensorCode(
                    thresholdsToSave,
                    (data) => 	resolve(data),
                    (msg) => reject(msg)
                )
            });
            const geometryToSave = {
                hydrometricZero: hydrometricZero,
                geometry: JSON.stringify(sectionGeometry),
                sensorCode: props.data.code,
                stationCode: props.data.stationCode
            }
            let geometryPromise = new Promise((resolve,reject)=>{
                SensorClient.saveOutflowsGeometryBySensorCode(
                    geometryToSave,
                    (data) => 	resolve(data),
                    (msg) => reject(msg)
                )
            });
            await Promise.all([thresholdPromise, geometryPromise]).then(
                values => {
                    console.log(values);
                    ReactSwal.fire('Archiviazione andata a buon fine', '', 'success')
                }
            ).catch(
                err => {
                    console.log(err);
                    ReactSwal.fire('Archiviazione dati fallita', '', 'error');
                }
            ).finally(
                () => props.onClose()
            )


        }

    }

    const onChangeThresholdsValue = (thresholdsValues) => {
        setThresholds(thresholdsValues);
    }

    const onChangeZeroValue = (hydroZero) => {
        setHydrometricZero(hydroZero);
    }

    const isSaveAllowed = (dirty, valid) => {
        setAllowSave(dirty && valid);
    }

    const onUploadCsvFile = (geom) => {

        setSectionGeometry(geom);
    }
    const iconButtonStyle = {position: 'absolute',right: 1,top: 1,color: 'grey'};
    const children =   <Grid container spacing={1}>
        <Grid item xs={12}>
            <ThresholdOutflowSensorComponent
                allowSave = {(dirty, valid)=>isSaveAllowed(dirty, valid)}
                onChangeThresholdsValue={(thresholds)=> onChangeThresholdsValue(thresholds)}
                onChangeZeroValue={(hydroZero) => onChangeZeroValue(hydroZero)}
                onUploadCsvFile={(geom) => onUploadCsvFile(geom)}
                data={props.data}/>
        </Grid>
    </Grid>;
    return (
            <Dialog fullScreen open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" height="100%">
                <DialogTitle id="form-dialog-title" style={{paddingTop: 5}} disableTypography={true}>
                    <h2>{props.title}</h2>
                    <IconButton aria-label="close" style={iconButtonStyle} onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers children={children} />

                <DialogActions>
                    <Button disabled={!allowSave}
                            color="primary" style={{ margin: "2vh", minWidth: "10vw" }}
                            startIcon={<SendIcon />} {...props}
                            variant="contained" type="submit"
                            onClick={() => submitForm()}>
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>

    )
}

export default SensorOutflowForm;
