import React from 'react';
import {GeoJSON} from 'react-leaflet';
import L from 'leaflet';
import GISTools from '#/lib/GISTools';
import {FaCrosshairs, FaMapMarkerAlt} from "react-icons/fa";
import {reactFormatter, ReactTabulator} from "react-tabulator";
import StationClient from '#/lib/StationClient';
import SensorClient from '#/lib/SensorClient';

import Skeleton from "react-loading-skeleton";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import Grid from '@material-ui/core/Grid';
import {DialogForm} from '#/commons/components/forms/DialogForm';
import ThresholdSensorComponent from '#/backoffice/components/ThresholdSensorComponent';

import WarningIcon from '@material-ui/icons/Warning';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '#/backoffice/style/SweetAlert.css';
import {MEASUREMENT_CATEGORY_ICONS, MEASUREMENT_CATEGORY_SORT} from '#/lib/MeasurementCategory';
import SensorOutflowForm from "#/backoffice/components/SensorOutflowForm";
import MapComponent from "#/commons/map/MapComponent";
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import _ from "lodash";
import Fab from "@material-ui/core/Fab";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import RemoveIcon from '@material-ui/icons/Remove';

import {Form, Formik} from "formik";

import {
	BooleanField,
	LocationField,
	NumericField,
	SensorCategoryField,
	StringField,
	SubmitButton
} from "#/commons/components/forms/formik/FormikItems";

import SensorCategoryClient from '#/lib/SensorCategoryClient';
import Tooltip from "@material-ui/core/Tooltip";
import {AiOutlineStop} from "react-icons/ai";
import SettingsClient from "#/lib/SettingsClient";
import CumulatedPluvsThresholdComponent from "#/backoffice/Monitoring/components/CumulatedPluvsThresholdComponent";
import MonitoringPluvsThresholdClient from "#/lib/MonitoringPluvsThresholdClient";
import AuthenticationService, {ROLE_ADMIN, ROLE_CONFIGURATOR} from "#/lib/AuthenticationService";

const ReactSwal = withReactContent(Swal);

const defaultZone = {
	value: null,
	label: "Tutte le zone"
}

const defaultStation = {
	value: null,
	label: <>&nbsp;</>
}

const EditOutflowScalesOrRainThresholdsButton = (props) => {
	if (props.cell._cell.row.data.category === 'I'){
		return (<Button disabled={props.disabled} color="primary" startIcon={<EditIcon />} variant="outlined" size="small"
						onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>Scale deflusso</Button>);
	} else if(props.cell._cell.row.data.category === 'P') {
		return (<Button disabled={props.disabled} color="primary" startIcon={<EditIcon />} variant="outlined" size="small"
						onClick={(e) => { props.clickCallback2(props.cell._cell.row.data) }}>Soglie pioggia</Button>);
	} else return <></>
}


const EditThresholdButton = (props) => {

	if (props.cell._cell.row.data.category !== 'DV' && props.cell._cell.row.data.category !== 'DS' && props.cell._cell.row.data.category !== 'DR'){
		return (<Button disabled={props.disabled} startIcon={<WarningIcon />} {...props}
						onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>Soglie</Button>);
	} else return <></>
};

const AddToBlacklistButton = (props) => {
	const {isBlacklisted} = props.cell._cell.row.data
	return (
		<Button  startIcon={<AiOutlineStop/>} {...props} disabled={isBlacklisted || props.disabled}
				onClick={(e) => {
					props.clickCallback(props.cell._cell.row.data)
				}}>{isBlacklisted ? 'Aggiunto' : 'Aggiungi'} alla blacklist</Button>
	)
};



const RemoveButton = (props) => {
	return (
		<Tooltip title={"Rimuovi sensore"}>
			<IconButton disabled={props.disabled} color="secondary" aria-label="rimuovi sensore" component="span"
						onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
				<RemoveIcon />
			</IconButton>
		</Tooltip>
	)
}



const CategoryIcon = ({cell : {_cell : {row : {data : {category}}}}}) => <>{MEASUREMENT_CATEGORY_ICONS[category]}  {category}</>



export default class GridManagementPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 0,
		maxZoom: 12,
		width: "40vw",
		height: "75vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			mapKey: 1,
			zoneOptions: [],
			stationOptions: [],
			selectedZone: defaultZone,
			selectedStation: defaultStation,
			zoneBbox : null,
			sensors:[],
			showStationModal:false,
			showNewSensorModal:false,
			showEditSensorModal:false,
			stationFormData:{
				xswg: "",
				yswg: "",
				regione: "",
				provincia: "",
				active: false,
				code: "",
				label: "",
				quota: ""
			},
			sensorCategories: [],
			sensorFormData:{
				active: true,
				stationCode: "",
				code: "",
				label: "",
				category: "",
				dtr: "",
				min: "",
				max: "",
				unit: ""
			},
			rainThresholdsMap: {},
			showAggregatedRainThresholdModal: false,
			selectedPluviometerCode: null
		}
	}
	buildPromisesAndInitState = () => {
		let stationGeoJsonPromise = new Promise( (resolve, reject) => {
			StationClient.getStationsGeojson(
				(data) => {	resolve(data) },
				(err) => { reject(err) }
			)}
		);

		let categoriesPromise = new Promise( (resolve, reject) => {
			SensorCategoryClient.getAll(
				(data) => {resolve(data)},
				(err) => { reject(err) }
			)}
		);

		let rainThresholdsPromise = new Promise((resolve, reject) => {
				MonitoringPluvsThresholdClient.getThresholdsGroupedBySensorCode(
					rainThresholdsMap => {
						resolve(rainThresholdsMap)
					},
					() => {
						console.log('Problems retrieving rain thresholds!')
					}
				)
			}
		);

		Promise.all([stationGeoJsonPromise, categoriesPromise, rainThresholdsPromise])
			.then(([stationsGeoJSON, categories, rainThresholdsMap])=>{this.initState(stationsGeoJSON, categories, rainThresholdsMap)})
	}

	componentDidMount() {
		this.buildPromisesAndInitState();
	}

	initState(stationsGeoJSON, sensorCategories, rainThresholdsMap){
		let selectedZone = defaultZone;
		let selectedStation = defaultStation;

		let zoneOptions = this.buildZoneOptions();
		let stationOptions = this.buildStationOptions(stationsGeoJSON, selectedZone.value);

		// Find stations out of Calabria
		let stationsOutsideRegion = GISTools.getStationsOutsideCalabria(stationsGeoJSON);

		this.setState({
			rainThresholdsMap,
			sensorCategories,
			stationsGeoJSON: stationsGeoJSON,
			zoneOptions: zoneOptions,
			stationOptions: stationOptions,
			selectedZone: selectedZone,
			selectedStation: selectedStation,
			stationsOutsideRegion: stationsOutsideRegion,
			mapKey: (this.state.mapKey + 1) % 1000
		})

	}

	buildZoneOptions() {
		let zoneOptions = [];
		let zones = GISTools.getAllZonePolygon();

		zoneOptions.push(defaultZone)
		zones.forEach(zone => {
			let id = zone.properties.ZONE;
			zoneOptions.push({
				value: parseInt(id),
				label: "ZONA " + id,
				icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
			});
		})
		// Add Out-of-Calabria zone
		let id = 9999;
		zoneOptions.push({
			value: parseInt(id),
			label: "Territorio extraregionale",
			icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
		});

		return zoneOptions
	}

	buildStationOptions(stations, zoneId) {
		let stationOptions = [];

		if (!!zoneId) {
			if (zoneId === 9999){
				stations = this.state.stationsOutsideRegion;
			} else {
				let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
				let stationWithinZone = GISTools.intersect(stations, zonePolygon);

				/*				// Add selected station eventually not included in this zone
                                if (!stationWithinZone.features.some((f) => f.properties.code === stationCode)){
                                    stationWithinZone.features.push(stations.features.find(f => f.properties.code === stationCode))
                                }*/

				stations = stationWithinZone;
			}
		}

		if (stations.features.length>0) {
			let stationsProperties = GISTools.getPropertiesFromFeature(stations);
			stationOptions.push(defaultStation);
			stationsProperties.forEach(properties => {
				stationOptions.push({
					label: properties.name,
					value: properties.code,
					id: properties.id,
					icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
				});
			})
		}

		return stationOptions;
	}

	zonesStyle(feature) {
		let zone = parseInt(feature.properties.ZONE);
		let style = {};
		if (this.state.selectedZone.value === 0) {
			if (this.state.zoneOptions.map(x => x.value).includes(zone)) {

				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}

		}
		else {
			if (this.state.selectedZone.value === zone) {
				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}
		}


		return style;
	}

	onEachStation(feature, layer) {
		layer.on('click', (e) => this.onSelectStation(feature.properties.code));

		var popup = L.popup({ closeButton: false })
			.setContent('<div><span class="mr-2 fas fa-map-marker-alt"></span>' + feature.properties.name + '</div>');
		layer.bindPopup(popup);
		let timer = null;
		layer.on('mouseover', function (e) {
			timer = setTimeout(() => this.openPopup(), 1000)
		});
		layer.on('mouseout', function (e) {
			clearTimeout(timer);
			this.closePopup();
		});
	}
	isConfigurator = () => AuthenticationService.haveRolesPermssions([ROLE_CONFIGURATOR, ROLE_ADMIN]);
	stationToMarker(feature, latlng) {
		let marker = null;

		let name = feature.properties.name;
		let isSelected = name === this.state.selectedStation.label;

		if (isSelected) {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point_selected.png',
					popupAnchor: [0, 0]
				})
			});
		}
		else {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point.png',
					popupAnchor: [0, 0]
				})
			});
		}
		return marker;

	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {zoneBbox} = this.state;
		const {zoneBbox : prevBbox} = prevState;

		if (!!zoneBbox && !_.isEqual(zoneBbox, prevBbox)){
			this.setState({zoneBbox})
		}
	}

	onSelectZone(zoneId) {

		let zoneBbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
		if (!!zoneId & zoneId !== 9999) {
			let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
			zoneBbox = GISTools.getBBoxFromPoints(zonePolygon);
		}

		let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
		let stationOptions = this.buildStationOptions(this.state.stationsGeoJSON, zoneId);
		this.setState({
			stationOptions,
			selectedZone: selectedZone,
			selectedStation: defaultStation,
			zoneBbox,
			mapKey: (this.state.mapKey + 1) % 1000
		})

	}


	onSelectStation(stationCode) {
		if(!!!stationCode){
			this.initState(this.state.stationsGeoJSON)
		}
		else{
			let stationOptions = this.buildStationOptions(this.state.stationsGeoJSON);
			let selectedStation = stationOptions.find(element => element.value === stationCode);
			let stationPoint = GISTools.getStationByField(this.state.stationsGeoJSON, "code", stationCode);
			let zonePolygon = GISTools.getZonePolygonByPoint(stationPoint);
			let zoneId = (!!zonePolygon && !!zonePolygon.properties)  ? zonePolygon.properties.ZONE : 9999;


			let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
			stationOptions = this.buildStationOptions(this.state.stationsGeoJSON, zoneId, stationCode);

			this.getSensors(selectedStation)
				.then((sensors) => {
					sensors = sensors.sort((a,b)=>{
						let aSortValue = MEASUREMENT_CATEGORY_SORT[a.category] || 999999999;
						let bSortValue = MEASUREMENT_CATEGORY_SORT[b.category] || 999999999;
						return aSortValue - bSortValue;
					});
					this.setState({
						stationOptions: stationOptions,
						selectedZone: selectedZone,
						selectedStation: selectedStation,
						sensors : sensors,
						mapKey: (this.state.mapKey + 1) % 1000
					})
				});
		}
	}

	getSensors(selectedStation) {
		return new Promise((resolve, reject) => {
			SensorClient.getSensorsByStation(selectedStation.value,
				(data) => {
					resolve(data)
				}
			)
		})
	}

	modifyThresholds = (data) => {
		console.log("BUTTON CLICKED")
		SensorClient.getThresholdsBySensorCode (
			data.code,
			(result) => {
				this.setState({
					showDialogThreshold : true,
					titleModal : 'Modifica Soglie Sensore : ' + data.label,
					dataSelected: {...data, thresholds : result}
				})
			},
			function (msg) {
				console.log(msg)
				ReactSwal.fire('Errore recupero soglie sensore ' + data.category, '', 'error');
			}
		)
	}

	modifyOutflows = async (data) => {
		;
		let retrieveScalesPromise = new Promise((resolve, reject) => {
				SensorClient.getOutflowsScalesBySensorCode(
					data.code,
					thresholds => resolve(thresholds),
					(msg) => reject(msg))
			}
		);

		let retrieveGeometryPromise = new Promise((resolve, reject) => {
				SensorClient.getOutflowsGeometryBySensorCode(
					data.code,
					geometryData => resolve(geometryData),
					(msg) => reject(msg))
			}
		)
		await Promise.all([retrieveScalesPromise, retrieveGeometryPromise]).then(
			([thresholds,  {geometry, hydrometricZero : h0}]) => {
				console.log('thresholds', thresholds, 'geometry', geometry, 'h0', h0);
				this.setState({
					showDialogOutflow : true,
					titleModal : 'Modifica Scale Deflusso Sensore : ' + data.label,
					dataSelected: {...data, thresholds, geometry, h0},
				});
			}
		).catch(
			err => {
				console.log(err);
				ReactSwal.fire('Errore recupero delle scale di deflusso del sensore ' + data.label, '', 'error');
			}
		)
	}



	getSensorColumns(){
		let columns = [];
		columns = columns.concat([
			{ title: "Categoria", field: "category", formatter: reactFormatter(<CategoryIcon ></CategoryIcon>), headerSort:false },
			{ title: "Codice", field: "code", headerSort:false },
			{ title: "Nome", field: "label", headerSort:false },
			{ title: "Unità", field: "unit", headerSort:false },
			{ title: "scala", field: "conversionFactor", headerSort:false },
			{ title: "min", field: "min", headerSort:false },
			{ title: "max", field: "max", headerSort:false, formatter: function (cell, formatterParams, onRendered){ if(cell.getValue()>1000000000) { return  "∞" } else { return cell.getValue()}} },
			{
				title: "",
				formatter: reactFormatter(<EditThresholdButton disabled={!this.isConfigurator()} clickCallback={(data) => this.modifyThresholds(data)} variant="outlined" size="small"/> ),
				headerSort:false,
				hozAlign:"center",
				vertAlign: "middle",
				minWidth :"150px"
			},
			{
				title: "",
				formatter: reactFormatter(<EditOutflowScalesOrRainThresholdsButton disabled={!this.isConfigurator()}
					clickCallback={(data) => this.modifyOutflows(data)}
					clickCallback2={(data) => this.setState({selectedPluviometerCode: data.code, showAggregatedRainThresholdModal: true})}
				></EditOutflowScalesOrRainThresholdsButton>),
				headerSort:false,
				hozAlign:"center",
				vertAlign: "middle",
				minWidth :"150px"
			},
			{
				title: "",
				formatter: reactFormatter(<AddToBlacklistButton disabled={!this.isConfigurator()} clickCallback={(data) => this.addToBlacklist({...data, stationName : this.state.selectedStation.label})} variant="outlined" size="small"/> ),
				headerSort:false,
				hozAlign:"center",
				vertAlign: "middle",
				minWidth :"230px"
			},
			{
				title: "",
				formatter: reactFormatter(<RemoveButton disabled={!this.isConfigurator()} clickCallback={(elem) => this.deleteSelectedSensor(elem.id, elem.code)}></RemoveButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				maxWidth: "60px"

			}
		]);

		return columns;
	}
	handleCloseDialog = () => {
		this.setState({showDialogOutflow: false, showDialogThreshold : false})
	}

	onCloseModal = (scope) => {

		if (scope==="aggregatedRainThresholds"){
			this.setState({
				showAggregatedRainThresholdModal : false
			})
		}
		if (scope==="sensorEdit"){
			this.setState({
				showEditSensorModal : false
			})
		}
		if(scope==="sensor"){
			this.setState({
				showNewSensorModal : false
			})
		}
		if(scope==="station"){
			this.setState({
				showStationModal : false
			})
		}
	}

	onStationSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
		this.setState({
			showStationModal : false
		}, () => {
			StationClient.saveStation(
				{...values, active: !values.active},
				() => {

					ReactSwal.fire('Il salvataggio della stazione è andato a buon fine', '', 'success');
					this.buildPromisesAndInitState();
				},
				() => {
					ReactSwal.fire('Si è verificato un errore durante il salvataggio della stazione ', '', 'error');
				})
		})

	}
	onSensorSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
		let id = this.state.editingSensor ? this.state.editingSensor.id : null;
		this.setState({
			showNewSensorModal : false,
			showEditSensorModal: false,
			editingSensor: null
		}, () => {

			SensorClient.saveSensor(
				{...values, id},
				() => {
					ReactSwal.fire('Il salvataggio del sensore è andato a buon fine', '', 'success');
					this.onSelectStation(this.state.selectedStation.value);
				},
				() => {
					ReactSwal.fire('Si è verificato un errore durante il salvataggio del sensore ', '', 'error');
				})
		})
	}


	deleteSelectedStation = () => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione della stazione {this.state.selectedStation.value}?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'Annulla'
		})
			.then((result) => {
				if (result.value) {

					StationClient.deleteStation(
						this.state.selectedStation.id,
						(result) => {
							ReactSwal.fire(`Cancellazione Stazione ${this.state.selectedStation.value}`, 'Il record è stato eliminato con successo', 'success');
							this.buildPromisesAndInitState();

						},
						(msg) => {
							ReactSwal.fire(`Errore cancellazione stazione ${this.state.selectedStation.value}`,'', 'error');
						}
					)
				}
			})
	}

	deleteSelectedSensor = (sensorId, sensorCode) => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione del sensore {sensorCode}?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'Annulla'
		})
			.then((result) => {
				if (result.value) {

					SensorClient.deleteSensor(
						sensorId,
						(result) => {
							ReactSwal.fire(`Cancellazione Sensore ${sensorCode}`, 'Il record è stato eliminato con successo', 'success');
							this.onSelectStation(this.state.selectedStation.value);
						},
						(msg) => {
							ReactSwal.fire(`Errore cancellazione sensore ${sensorCode}`,'', 'error');
						}
					)
				}
			})
	}

	addToBlacklist = ({code, category, stationCode, label, stationName}) => {

		ReactSwal.fire({
			title: <h3>Aggiungere il sensore {label} {stationName} alla blacklist?</h3>,
			text: 'Il sensore verrà escluso dall\'allertamento Mosip e dall\'interpolazione dei dati di misurazione all\'interno del modulo MIDA',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'Annulla'
		})
			.then((result) => {
				if (result.value) {
					SettingsClient.saveBlacklistItem(
						{code, category, stationCode, label},
						(result) => {
							ReactSwal.fire(`Inserimento Sensore ${label} ${stationName}`, 'Il record è stato inserito con successo', 'success');
							this.onSelectStation(this.state.selectedStation.value);
						},
						(msg) => {
							ReactSwal.fire(`Errore inserimento sensore ${label} ${stationName}`,'', 'error');
						}
					)
				}
			})
	}

	openEditSensorModal = (data) => {
		console.log("ROW CLICKED")
		this.setState({
			editingSensor: data,
			showEditSensorModal: true
		})
	}
	areModalsCloded = () =>
		!this.state.showEditSensorModal &&
		!this.state.showNewSensorModal &&
		!this.state.showStationModal &&
		!this.state.showDialogOutflow &&
		!this.state.showDialogThreshold &&
		!this.state.showAggregatedRainThresholdModal;

	onRainThresholdsSave = (thresholds) => {
		this.setState({
			rainThresholdsMap: {...this.state.rainThresholdsMap, [this.state.selectedPluviometerCode]: thresholds},
			showAggregatedRainThresholdModal: false,
			selectedPluviometerCode: null
		})
	}
	render() {
		return (
			<>
				<div className="row generic-page container-fluid data-page">

					<div className="col-4 mt-4">
						<div className="row mt-2 mb-4 ">
							<InputSelectComponent
								className={"float-right col-6"}
								placeholder={"Selezione Zona"}
								optionList={this.state.zoneOptions}
								value={!!this.state.selectedZone ? this.state.selectedZone.value : null }
								onChange={(option) => this.onSelectZone(option)}
							/>
						</div>
						<div className="row mt-2">
							<InputSelectComponent
								className={"float-right col-6"}
								placeholder={"Selezione Stazione"}
								optionList={this.state.stationOptions}
								value={!!this.state.selectedStation ? this.state.selectedStation.value : null }
								isSearchable={true}
								onChange={(option) => this.onSelectStation(option)}
							/>
						</div>


						<div >
							<MapComponent
								width={"100%"}
								height={"70vh"}
								zoom={this.mapOptions.zoom}
								minZoom={7}
								maxZoom={12}
								zoomSnap={false}
								zoneBbox={this.state.zoneBbox}>

								<GeoJSON key={"points_" + this.state.mapKey}
										 data={this.state.stationsGeoJSON}
										 onEachFeature={(feature, layer, context) => this.onEachStation(feature, layer, this)}
										 pointToLayer={(feature, latlng, context) => this.stationToMarker(feature, latlng, this)}
								/>

								<GeoJSON key={"borders_" + this.state.mapKey}
										 data={GISTools.getCalabriaBorders()}
										 style={{
											 fillColor: "#fff",
											 fillOpacity: 0,
											 weight: 2,
											 opacity: 1,
											 color: "green",
										 }}
								/>

								<GeoJSON key={"zone_" + this.state.mapKey}
										 data={GISTools.getAllZonePolygon()}
										 style={(feature) => this.zonesStyle(feature)}
								/>


							</MapComponent>
						</div>
					</div>
					<div className="col-8 mt-4">
						{!!this.state.sensors && this.state.sensors.length>0
							?
							<>
								<span style={{color: "#3fb262",  fontFamily: 'Roboto Slab', fontSize:"1.5rem", fontWeight: "500"}}>Sensori della Stazione [{this.state.selectedStation.label}]</span>
								<ReactTabulator
									ref={ref => (this.tableRef = ref)}
									columns={this.getSensorColumns()}
									data={this.state.sensors}
									options={{layout:"fitDataStretch",
										height: "50vh",
										rowContextMenu:[
											{
												label:"Modifica sensore",
												action:(e, row) => {
													this.openEditSensorModal(row._row.data)
												}
											}
										],
									}}
									key={"table_"+this.props.tableKey+ "_" + this.state.mapKey}
								/>
								{
									(this.state.showDialogThreshold &&
										<SensorThresholdForm
											open={this.state.showDialogThreshold}
											title={this.state.titleModal}
											onClose = {this.handleCloseDialog}
											data={this.state.dataSelected}
										/>)
									||
									(this.state.showDialogOutflow &&
										<SensorOutflowForm
											open={this.state.showDialogOutflow}
											title={this.state.titleModal}
											onClose = {this.handleCloseDialog}
											data={this.state.dataSelected}
										/>)

								}
							</>
							:
							<>
								<Skeleton height={"7vh"} duration={8} />
								<Skeleton className="mt-2" height={"75vh"} duration={8} />
							</>
						}
					</div>
				</div>

				{(
					this.areModalsCloded()
				) && <div style={{position: "fixed", bottom: "5vh", right: "3vw", zIndex: 9999}}>
					<div>
						<Fab variant="extended" color="primary" aria-label="add"
							 onClick={() => {
								 this.setState({
									 showStationModal: true
								 })
							 }}
							 disabled={!this.isConfigurator()}>
							Aggiungi Stazione
						</Fab>
					</div>
					<div className="mt-4">
						<Fab variant="extended" color="secondary" aria-label="add"
							 onClick={() => {
								 this.deleteSelectedStation();
							 }}
							 disabled={!this.state.selectedStation.value || (this.state.sensors && this.state.sensors.length > 0) || !this.isConfigurator()}>
							Rimuovi Stazione
						</Fab>
					</div>
					<div className="mt-4">

						<Fab variant="extended" color="primary" aria-label="add"
							 onClick={() => {
								 this.setState({
									 showNewSensorModal: true
								 })
							 }}
							 disabled={!this.state.selectedStation.value || !this.isConfigurator()}>
							Aggiungi Sensore
						</Fab>
					</div>
				</div>}

				{/* MODAL STAZIONE ADD */}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						zIndex : 99999
					}}
					open={this.state.showStationModal}
					onClose={() => this.onCloseModal("station")}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "70vh",
							borderRadius: '15px'
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal("station")}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3>Inserimento Nuova Stazione</h3>
						</div>

						<Formik
							enableReinitialize
							initialValues={this.state.stationFormData}
							onSubmit={(values, props) => this.onStationSubmit(values, props)}
						>
							<Form className="needs-validation" noValidate="" style={{ width: "50vw" }}>
								<div className="d-block w-80 m-4">
									<StringField className="mt-4" name="code" label="Codice" />
									<StringField className="mt-4" name="label" label="Nome" />
									<LocationField className="mt-4"
												   xName="xswg" xLabel="x WGS 84"
												   yName="yswg" yLabel="y WGS 84"
												   regName="regione" regLabel="Regione"
												   provName="provincia" provLabel="Provincia"/>

									<NumericField className="mt-4" name="quota" label="Quota" />
									<BooleanField className="mt-4" name="active" label="Stazione storica" />
									<SubmitButton style={{float: 'right'}} className="mt-4" title="Invia" />
								</div>
							</Form>
						</Formik>
					</div>

				</Modal>

				{/* MODAL SENSORE ADD */}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						zIndex : 99999
					}}
					open={this.state.showNewSensorModal}
					onClose={() => this.onCloseModal("sensor")}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "70vh",
							borderRadius: '15px'
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal("sensor")}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3>Inserimento Nuovo Sensore</h3>
						</div>

						<Formik
							enableReinitialize
							initialValues={{...this.state.sensorFormData, stationCode: this.state.selectedStation.value}}
							onSubmit={(values, props) => this.onSensorSubmit(values, props)}
						>

							<Form className="needs-validation" noValidate="" style={{ width: "50vw" }}>
								<div className="d-block w-80 m-4">
									<StringField className="mt-4" name="code" label="Codice" />
									<StringField className="mt-4" name="stationCode" label="Codice stazione" disabled={true}/>
									<StringField className="mt-4" name="label" label="Nome" />
									<SensorCategoryField className="mt-4" options={this.state.sensorCategories.map(c => ({value: c.category, label: c.meta, unit: c.unit}))}
														 catName="category" catLabel="Categoria Sensore"
														 unitName="unit" unitLabel="Unità di misura"/>
									<NumericField className="mt-4" name="dtr" label="Intervallo di misurazione [s]" />
									<NumericField className="mt-4" name="min" label="Minimo" />
									<NumericField className="mt-4" name="max" label="Massimo" />
									<BooleanField className="mt-4" name="active" label="Sensore attivo" />
									<SubmitButton style={{float: 'right'}} className="mt-4" title="Invia" />
								</div>
							</Form>
						</Formik>
					</div>
				</Modal>
				{/* MODAL SENSORE EDIT */}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						zIndex : 99999
					}}
					open={this.state.showEditSensorModal}
					onClose={() => this.onCloseModal("sensorEdit")}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "70vh",
							borderRadius: '15px'
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal("sensorEdit")}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3>Inserimento Nuovo Sensore</h3>
						</div>

						<Formik
							enableReinitialize
							initialValues={{...this.state.editingSensor}}
							onSubmit={(values, props) => this.onSensorSubmit(values, props)}
						>

							<Form className="needs-validation" noValidate="" style={{ width: "50vw" }}>
								<div className="d-block w-80 m-4">
									<StringField className="mt-4" name="code" label="Codice" />
									<StringField className="mt-4" name="stationCode" label="Codice stazione" disabled={true}/>
									<StringField className="mt-4" name="label" label="Nome" />
									<SensorCategoryField className="mt-4" options={this.state.sensorCategories.map(c => ({value: c.category, label: c.meta, unit: c.unit}))}
														 catName="category" catLabel="Categoria Sensore"
														 unitName="unit" unitLabel="Unità di misura"/>
									<NumericField className="mt-4" name="dtr" label="Intervallo di misurazione [s]" />
									<NumericField className="mt-4" name="min" label="Minimo" />
									<NumericField className="mt-4" name="max" label="Massimo" />
									<BooleanField className="mt-4" name="active" label="Sensore attivo" />
									<SubmitButton style={{float: 'right'}} className="mt-4" title="Invia" />
								</div>
							</Form>
						</Formik>
					</div>
				</Modal>
				{/*AGGREGATED RAIN THRESHOLDS*/}
				<Modal key={'thresholds'}
					   style={{
						   display: 'flex',
						   alignItems: 'center',
						   justifyContent: 'center'
					   }}
					   open={this.state.showAggregatedRainThresholdModal}
					   onClose={() => this.onCloseModal("aggregatedRainThresholds")}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "70vw",
							height: "75vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal("aggregatedRainThresholds")}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3>Soglie Piogge Cumulate del sensore {this.state.selectedPluviometerCode}</h3>
						</div>


						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<CumulatedPluvsThresholdComponent
									thresholds = {this.state.rainThresholdsMap[this.state.selectedPluviometerCode] || null}
									genericThresholds={this.state.rainThresholdsMap['GENERIC']}
									saveCallback = {(thresholds)=>this.onRainThresholdsSave(thresholds)}
									sensorCode={this.state.selectedPluviometerCode}
								></CumulatedPluvsThresholdComponent>
							</div>
						</div>
						<br/>
					</div>
				</Modal>
			</>
		)
	}
}


const SensorThresholdForm = (props) =>  {

	let thresholds = [{}];
	const submitForm = () => {

		if (!!thresholds) {

			let thresholdsToSave = thresholds.filter ( (el) => {
				return el.thresholdLevel!=="grey";
			});

			// TODO : verifica dati
			if (thresholdsToSave.length > 0) {

				SensorClient.saveThresholdsBySensorCode(
					thresholdsToSave,
					(data) => {
						props.onClose();
						ReactSwal.fire('Archiviazione andata a buon fine', '', 'success');
					},
					(msg) => {
						props.onClose();
						ReactSwal.fire('Archiviazione dati fallita', '', 'error');
						console.log(msg);
					}
				)
			} else {
				SensorClient.deleteThresholdsBySensorCode(
					props.data.code,
					(data) => {
						props.onClose();
						ReactSwal.fire('Eliminazione soglie andata a buon fine', '', 'success');
					},
					(msg) => {
						props.onClose();
						ReactSwal.fire('Eliminazione soglie fallita', '', 'error');
						console.log(msg);
					}
				)
				props.onClose();
			}
		}

	}


	const onChangeThresholdsValue = (thresholdsValues) => {
		thresholds = thresholdsValues;
	}

	return (
		<DialogForm
			fullScreen
			open={props.open}
			title={props.title}
			key={props.title}
			onClose = {props.onClose}
			handleSubmit={() => submitForm()}
		>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<ThresholdSensorComponent onChangeThresholdsValue={(thresholds)=> onChangeThresholdsValue(thresholds)} data={props.data}/>
				</Grid>
			</Grid>

		</DialogForm>

	)
}


