import React from "react";
import { withRouter } from 'react-router';
import domtoimage from "dom-to-image-more";

import { DEFAULT_FORM_JSON_DATA, DEFAULT_MAPS, DEFAULT_STEPS, } from "#/backoffice/mau/utils/MauUtils";
import BulletinClient from "#/lib/BulletinClient";
import GISTools from "#/lib/GISTools";
import ObjectManager from "#/lib/ObjectManager";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { zonesGeoJson8 } from "#/lib/ZoneGeoJsonDefault";
import SettingsClient from "#/lib/SettingsClient";
import WorkItemClient from "#/lib/WorkItemClient";
import moment from "moment"
const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
class MauEditorMultiPage extends React.Component {
	steps = undefined;
	totalSteps = 0;

	constructor(props) {
		super(props);
		this.steps = props.viewMode ? DEFAULT_STEPS.VIEW : DEFAULT_STEPS.EDIT;
		this.totalSteps = this.steps.length;
		const url = window.location.href;
		const idString = url.split("/").pop();
		const id = parseInt(idString);

		this.state = {
			id: !!id ? id : null,
			bulletinTypeName: "MAU",
			lastBulletinNumber: null,
			initialLastBulletinNumber: null,
			approved: false,
			published: false,
			viewMode: props.viewMode,
			activeStep: 0,
		};
	}

	componentDidMount() {
		if (!!this.state.id) {
			this.getBulletinById(this.state.id);
		} else {
			this.getLastBulletinNumber();
			SettingsClient.getGeojsonTemplateByDocumentType(
				(data) => {

					this.isCreationAllowedToday(
						() => {

							ReactSwal.fire({
								title: this.state.lastBulletinNumber ? "Imposta numero di bollettino e orario di Inizio Validità" : "Imposta orario di Inizio Validità",
								html: (
									<div className="d-flex flex-column align-items-center">
										{
											this.state.lastBulletinNumber ?
											<>
											<label>Numero bollettino: </label>
											<input 
												type="number"
												id="numBollettino"
												className="swal2-input"
												min={Number(this.state.lastBulletinNumber)}
												defaultValue={Number(this.state.lastBulletinNumber)}
												onChange={this.handleChangeNumber}
											/><br></br>
											<label>Orario di inizio validità: </label>
											</>
											: <></>
										}
										<input
											type="time"
											id="timePickerXXX"
											className="swal2-input"
											min="00:00"
											max="23:59"
											defaultValue={this.getNowStartTime()}
										/>
									</div>
								),
								focusConfirm: false,
								showCancelButton: false,
								confirmButtonText: "Imposta",
								preConfirm: () => {
									const numBollettinoInput = document.getElementById("numBollettino").value ?? null;
									const timeInput = document.getElementById("timePickerXXX").value ?? null;
									if (!timeInput || timeInput === null) {
										Swal.showValidationMessage("Please select a time");
									}
									if (!numBollettinoInput || numBollettinoInput === null) {
										Swal.showValidationMessage("Please select a bulletin number");
									}
									const inputTimeAndNumber = {
										time: timeInput ?? null,
										numBulletin: numBollettinoInput ?? null
									}
									return inputTimeAndNumber;
								},
							})
								.then(({ value }) => {
									if (value) {
										console.log("TIME", value?.time ?? null)
										console.log("BULLETTIN NUMBER: ", value?.numBulletin ?? null);
									}
									let defaultMaps = this._getMapShapesGeoJSONFromLocalFile(DEFAULT_MAPS, JSON.parse(data.geoJson));
									let defaultFormJsonData = this._setZonesOnFormJsonData(DEFAULT_FORM_JSON_DATA(value?.time), JSON.parse(data.geoJson));

									this.getYesterdayBulletin(
										result => {
											const geoJson = result.jsonData.geoJson;
											const maps = !!geoJson
												? this._getMapShapesGeoJSONFromLocalFile(DEFAULT_MAPS, geoJson)
												: this.state.maps;
											let newFormJsonData = { ...defaultFormJsonData, start: result.jsonData.end };
											newFormJsonData.start.date_short = defaultFormJsonData.start.date_short;
											newFormJsonData.start.hour_start = value?.time;
											this.setState({
												maps: maps,
												formJsonData: newFormJsonData,
												allowCreationToday: true
											});
										},
										() => {
											this.setState(
												{
													allowCreationToday: true,
													formJsonData: defaultFormJsonData,
													selectedGeoJSON: JSON.parse(data.geoJson),
													maps: defaultMaps
												})
										})
								});

						},
						this.goToListPage);
				},
				() => {
					ReactSwal.fire('Nessun file zonale trovato', 'Configurare il file zonale nella sezione <i>Amministrazione/Impostazioni generali</i>', 'error')
					.then(this.goToListPage)
				},
				"MAU")
		}
	}

	handleChangeNumber = (event) => {
		if (event.target.value < this.state.initialLastBulletinNumber) {
			document.getElementById("numBollettino").value = this.state.initialLastBulletinNumber;
		}
		this.setState({ lastBulletinNumber: Number(event.target.value) });
	};

	goToListPage = () => {
		this.props.history.push("/backoffice/mau");
	}

	getNowStartTime = () => {
		const now = moment();

		// Round the minutes up to the next 10
		const roundedMinutes = Math.ceil(now.minutes() / 10) * 10;

		// Set the rounded minutes back to the moment object
		now.minutes(roundedMinutes).seconds(0); // Set seconds to 0 for exact rounding

		// Format to "HH:mm"
		return now.format("HH:mm");
	}

	getLastBulletinNumber = () => {
		BulletinClient.listBulletins(
			(obj) => {

				if (obj.data.length > 0) {
					this.parseMauData(obj.data[0]);
				}
			},
			(err) => { console.log('Error while retrieving mau data', err) },
			{
				pageNumber: 1,
				pageSize: 1,
				bulletinTypeName: 'MAU'
			}
		)
	}

	parseMauData(data) {
		this.setState({
			lastBulletinNumber: data.bulletinNumber ? data.bulletinNumber + 1 : null,
			initialLastBulletinNumber: data.bulletinNumber ? data.bulletinNumber + 1 : null,
		});
	}

	getBulletinById = (id) => {

		if (!!id) {

			ReactSwal.fire({
				title: "Imposta orario di Inizio Validità",
				html: (
					<input
						type="time"
						id="timePickerXXX"
						className="swal2-input"
						min="00:00"
						max="23:59"
						defaultValue={this.getNowStartTime()}
					/>
				),
				focusConfirm: false,
				showCancelButton: false,
				confirmButtonText: "Imposta",
				preConfirm: () => {
					const timeInput = document.getElementById("timePickerXXX").value;
					if (!timeInput) {
						Swal.showValidationMessage("Please select a time");
					}
					return timeInput;
				},
			})
				.then(({ value }) => {
					BulletinClient.getBulletin(
						(result) => {
							const geoJson = result.jsonData.geoJson;
							const maps = !!geoJson
								? this._getMapShapesGeoJSONFromLocalFile(DEFAULT_MAPS, geoJson)
								: this.state.maps;
							result.jsonData.start.hour_start = value
							this.setState({
								maps: maps,
								formJsonData: result.jsonData,
								published: result.published,
								allowCreationToday: true
							});
						},
						(msg) => {
							console.log("msg: ", msg);
						},
						{
							id: this.state.id,
							bulletinTypeName: this.state.bulletinTypeName,
						}
					);
				})
		} else {
			this.setImgOnJsonData(["img_empty"], this.state.maps[0].mapId);
		}
	}
	getYesterdayBulletin = (okCallback, errCallback) => {

		BulletinClient.getYesterdayBulletin(
			(result) => {
				okCallback(result)
			},
			(msg) => {
				console.log("msg: ", msg);
				errCallback()
			},
			{
				bulletinTypeName: this.state.bulletinTypeName,
			}
		);
	}

	isCreationAllowedToday = (trueCallback, falseCallback) => {
		BulletinClient.isCreationAllowedToday(
			(data) => {
				trueCallback()
			},
			(error) => {
				falseCallback();
			},
			this.state.bulletinTypeName
		);
	};

	_addCentroidGeoJSON = (geoJSON) => {
		GISTools.addCentroids(geoJSON);
	};

	_buildZonesFromGeoJSON = (geoJSON) => {
		let zones = null;
		if (!!geoJSON && !!geoJSON.features) {
			zones = geoJSON.features.map((x) => {
				return {
					id: x.properties.ZONE,
					label: "ZONA " + x.properties.ZONE,
					value: null,
				};
			});
		}
		return zones;
	};

	_setZonesOnFormJsonData = (formJsonData, geoJSON) => {
		let zonesPrevisione = [];
		let zonesCriticita = [];
		let cale = [];
		if (!!geoJSON && !!geoJSON.features) {
			zonesPrevisione = geoJSON.features.map((x) => {
				return {
					zone: x.properties.ZONE,
				};
			});
			zonesCriticita = geoJSON.features.map((x) => {
				return {
					zone: x.properties.ZONE,
					idro_temp: "VERDE",
					idro_idra: "VERDE"
				};
			});
			cale = geoJSON.features.map((x) => {
				return {
					id: x.properties.ZONE,
					zone: x.properties.ZONE,
					label: "ZONA " + x.properties.ZONE,
					value: "BASE",
				};
			});
		}
		formJsonData = {
			...formJsonData,
			geoJson: geoJSON,
			start: {
				...formJsonData.start,
				fasi_op: { zones: JSON.parse(JSON.stringify(cale)) },
				previsione: { zones: JSON.parse(JSON.stringify(zonesPrevisione)) },
				criticita: { zones: JSON.parse(JSON.stringify(zonesCriticita)) },
			},
			end: {
				...formJsonData.end,
				fasi_op: { zones: JSON.parse(JSON.stringify(cale)) },
				previsione: { zones: JSON.parse(JSON.stringify(zonesPrevisione)) },
				criticita: { zones: JSON.parse(JSON.stringify(zonesCriticita)) },
			},
		};

		return formJsonData;
	};

	_getMapShapesGeoJSONFromLocalFile = (initMaps, localGeoJSONContent) => {
		let maps = initMaps;
		let geoJSON = JSON.parse(JSON.stringify(localGeoJSONContent));
		const zonesFromGeoJSON = this._buildZonesFromGeoJSON(localGeoJSONContent);
		this._addCentroidGeoJSON(geoJSON);
		maps.forEach((value, index) => {
			maps[index] = {
				...maps[index],
				zones: JSON.parse(JSON.stringify(zonesFromGeoJSON)), //clona -  [...zonesFromGeoJSON] non funziona
				shapesGeoJSON: geoJSON,
				mapKey: (maps[index].mapKey + 1) % 1000,
			};
		});
		return maps;
	};

	setImgOnJsonData = (section, elementId, successCallback) => {
		let formJsonData = this.state.formJsonData;
		const path = [...section];

		domtoimage
			.toPng(document.getElementById(elementId))
			.then((dataUrl) => {
				ObjectManager.set(formJsonData, path, dataUrl);
				this.setState({ formJsonData: formJsonData }, successCallback);
			})
			.catch((error) => {
				console.error("oops, something went wrong!", error);
			});
	};

	send = (mail_addresses, to_include) => {
		loadingSwal.fire('Archiviazione del bollettino in corso...');
		let jsonData = this.state.formJsonData;
		jsonData.mail_addresses = mail_addresses;
		jsonData.to_include = to_include;

		const prevActiveStep = this.state.activeStep;
		const nextStep = prevActiveStep + 1;
		let params = {
			bulletinId: !!this.props.edit ? this.state.id : null,
			documentTypeName: this.state.bulletinTypeName,
			jsonData: JSON.stringify(jsonData),
			trigger: this.props.trigger,
			referenceId: this.state.id
		};
		if (this.state.lastBulletinNumber && this.state.lastBulletinNumber !== null) {
			params['bulletinNumber'] = this.state.lastBulletinNumber;
		}
		BulletinClient.saveBulletin(
			(result) => {
				this.setState({
					id: result.id,
					saveStatus: "mau archiviato con successo.",
					activeStep: nextStep,
				}, () => {
					loadingSwal.close();
					ReactSwal.fire(
						{
							icon: 'success',
							title: 'mau archiviato con successo.',
							html: 'Ti Stiamo Reindirizzando alla lista dei mau',
							timer: 4000,
							confirmButtonText: `OK`,
						})
						.then(() => {
							WorkItemClient.getWorkItemsState(
								(data) => {
									const originalSetItem = localStorage.setItem;
									localStorage.setItem = function (key, value) {
										const event = new Event('workItemsUpdated');

										document.dispatchEvent(event);
										originalSetItem.apply(this, arguments);
									};
									localStorage.setItem("workItemsState", JSON.stringify(data));
									this.goToListPage();
								}
								, () => {
									console.log("Error while retrieving work items data");
									this.goToListPage();
								}
							)
						}
						)
				});
			},
			(msg) => {
				this.setState({
					saveStatus:
						"Si è verificato un errore durante l'archiviazione del mau",
					activeStep: nextStep,
				}, () => {
					loadingSwal.close();
					ReactSwal.fire(
						{
							icon: 'error',
							title: 'mau Errore',
							html: 'Si è verificato un errore',
							timer: 4000,
							confirmButtonText: `OK`,
						})
						.then(this.goToListPage)
				});
			},
			params
		);
	};

	handleSetMaps = (maps) => {
		this.setState({ maps: maps });
	};

	handleDropzoneChangeStatus = ({ file }, status) => {
		let maps = this.state.maps;
		if (status === "removed") {
			maps.forEach((value, index) => {
				maps[index] = {
					...maps[index],
					shapesGeoJSON: null,
					zones: [],
					mapKey: (maps[index].mapKey + 1) % 1000,
				};
			});
			this.setState({
				maps: maps,
			});
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let geoJSON = JSON.parse(fileContent);
				const zonesFromGeoJSON = this._buildZonesFromGeoJSON(geoJSON);
				this._addCentroidGeoJSON(geoJSON);
				maps.forEach((value, index) => {
					maps[index] = {
						...maps[index],
						zones: JSON.parse(JSON.stringify(zonesFromGeoJSON)), //clona -  [...zonesFromGeoJSON] non funziona
						shapesGeoJSON: geoJSON,
						mapKey: (maps[index].mapKey + 1) % 1000,
					};
				});

				this.setState({ maps: maps, shapesGeoJSON: geoJSON }, () => {
					this.setImgOnJsonData(["img_empty"], this.state.maps[0].mapId);
				});
			};
			reader.onerror = (event) => {
				console.log("Error");
			};
			reader.readAsText(file);
		}
	};

	handleSelectGeoJSON = (valueSelected) => {
		let maps = this.state.maps;

		maps = !!valueSelected
			? this._getMapShapesGeoJSONFromLocalFile(maps, valueSelected)
			: DEFAULT_MAPS;

		this.setState({ maps: maps, selectedGeoJSON: valueSelected });
	};
	setValueToForm = (section, zoneId, name, value, formJsonData) => {
		let path = [...section];
		if (!!name) {
			if (!!zoneId) {
				path.push("zones");
				const zones = ObjectManager.get(formJsonData, path);
				let indexZone = !!zones
					? zones.findIndex((element) => element.zone === zoneId)
					: null;
				path.push(indexZone);
			}
			path.push(name);
		}
		ObjectManager.set(formJsonData, path, value);
	}


	handleFormChange = (section, zoneId, name, value) => {

		let formJsonData = this.state.formJsonData;
		this.setValueToForm(section, zoneId, name, value, formJsonData);

		// debugger
		// Update Avviso Previsione
		if (['frost', 'wind', 'storm_surges'].includes(name)) {
			name = 'avviso';
			if (value) {
				this.setValueToForm(section, null, name, value, formJsonData)
			} else {
				if (!formJsonData[section[0]].previsione.zones.some(z => z.frost || z.wind || z.storm_surges)) {
					this.setValueToForm(section, null, name, value, formJsonData)
				}
			}
		}
		// Update Avviso Criticità
		if (['idro_temp', 'idro_idra'].includes(name)) {
			name = 'avviso';
			if (value !== 'VERDE') {
				this.setValueToForm(section, null, name, true, formJsonData)
			} else {
				if (!formJsonData[section[0]].criticita.zones.some(z => z.idro_temp !== 'VERDE' || z.idro_idra !== 'VERDE')) {
					this.setValueToForm(section, null, name, false, formJsonData)
				}
			}
		}

		// Update Fasi operative (se non modificate direttamente)
		if (!section.includes('fasi_op')) {
			formJsonData.start.fasi_op.zones = formJsonData.start.fasi_op.zones.map(fase => ({ ...fase, value: formJsonData.start.previsione.avviso ? 'ATTENZIONE' : 'BASE' }));
			formJsonData.end.fasi_op.zones = formJsonData.end.fasi_op.zones.map(fase => ({ ...fase, value: formJsonData.end.previsione.avviso ? 'ATTENZIONE' : 'BASE' }));

		}

		this.setState({
			formJsonData: formJsonData,
		});
	};

	handleNext = (mail_addresses = [], to_include = []) => {
		const prevActiveStep = this.state.activeStep;
		const nextStep = prevActiveStep + 1;
		if (nextStep === this.totalSteps) {
			this.send(mail_addresses, to_include);
		} else {
			this.setState({ activeStep: nextStep });
		}
	};

	handleBack = () => {
		const prevActiveStep = this.state.activeStep;
		this.setState({
			activeStep: prevActiveStep - 1,
		});
		const step = prevActiveStep - 1;
	};

	handleReset = () => {
		this.setState({
			activeStep: 0,
			saveStatus: "",
		});
	};

	getStepContentComponent = (stepIndex) => {
		let commonsProps = {
			viewMode: this.state.viewMode,
			stepLabel: this.steps[stepIndex].label,
			totalSteps: this.totalSteps,
			activeStep: stepIndex,
			reactSwal: ReactSwal,
			formData: this.state.formJsonData,
			maps: this.state.maps,
			disableBackButton: !stepIndex,
			textNextButton: "Successivo",
			onFormChange: this.handleFormChange,
			handleSaveImg: this.setImgOnJsonData,
			handleNext: this.handleNext,
			handleBack: this.handleBack,
			published: this.state.published,
			map: this.state.maps[0],
			zones: this.state.maps[0].zones,
			handleDropzoneChangeStatus: this.handleDropzoneChangeStatus,
			handleSelectGeoJSON: this.handleSelectGeoJSON,
			selectedGeoJSON: this.state.selectedGeoJSON,
			selectOptions: [
				{ value: "", label: "Seleziona" },
				{ value: { ...zonesGeoJson8 }, label: "GeoJson 8 Zone" },
			],
		};
		let comp = this.steps[stepIndex].component(commonsProps);
		return comp;
	};

	render() {
		if (this.state.allowCreationToday === undefined) {
			return <></>;
		} else {

			return (
				<div className="generic-page container-fluid data-page">
					{this.state.lastBulletinNumber ? <span><strong>Numero di bollettino:</strong> {this.state.lastBulletinNumber}</span> : <></>}
					<Stepper activeStep={this.state.activeStep} alternativeLabel>
						{this.steps.map((step) => (
							<Step key={step.label}>
								<StepLabel>{step.label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<div>
						{this.state.activeStep !== this.totalSteps &&
							<div>
								{!!this.state.maps &&
									!!this.state.formJsonData &&
									this.getStepContentComponent(this.state.activeStep)}
							</div>}
					</div>

				</div>
			);
		}
	}
}

export default withRouter(MauEditorMultiPage)
