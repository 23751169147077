import React, {useCallback, useEffect, useState} from 'react'
import TimeField from 'react-simple-timefield';

import { PRECIPITAZIONI_IMPULSIVE_OPTIONS, PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS } from "#/backoffice/mau/utils/MauUtils";

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import LabelledOutline from '#/commons/components/LabelledOutline';
import {PRECIPITAZIONI_ICONS} from "#/backoffice/forecast/utils/ForecastUtils";
import {useDebouncedCallback} from "use-debounce";

export const MAUMultizoneMeteoForm = (props) => {

	const viewMode = props.viewMode;
	console.log('props.formData',props.formData)
	const previsione = props.formData.previsione;
	let rootSection = props.rootSection;
	let section = props.section;

	const isAvvisoDisabled = previsione => false;//previsione ? previsione.zones.some(z=> z.frost || z.wind || z.storm_surges) : false;

	const toItemFunction = (zone) => {
		return (
			<Grid container key={"grid_"+zone.id} style={{ marginLeft: "30px" }} spacing={1}>
				<Grid item xs={1}><h3>{zone.label}</h3></Grid>
				<Grid item xs={3}>
					<TextField
						fullWidth
						select
						id="rainfall_pni"
						disabled = {viewMode}
						name="rainfall_pni"
						label="Precipitazioni non impulsive"
						value={!!previsione && !!previsione.zones && !!previsione.zones.find(z=> z.zone===zone.id) ? previsione.zones.find(z=> z.zone===zone.id).rainfall_pni || '' : ''}
						onChange={(e)=>props.onFormChange(section, zone.id, e.target.name, e.target.value)}
					>
						{PRECIPITAZIONI_NON_IMPULSIVE_OPTIONS.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								<img height={'20px'} alt={''} id={option.value} src={PRECIPITAZIONI_ICONS[option.value]}/> &nbsp;{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={3}>
					<TextField
						fullWidth
						select
						id="rainfall_pi"
						disabled = {viewMode}
						name="rainfall_pi"
						label="Precipitazioni impulsive"
						value={!!previsione && !!previsione.zones && !!previsione.zones.find(z=> z.zone===zone.id) ? previsione.zones.find(z=> z.zone===zone.id).rainfall_pi || '' : ''}
						onChange={(e)=>props.onFormChange(section, zone.id, e.target.name, e.target.value)}
					>
						{PRECIPITAZIONI_IMPULSIVE_OPTIONS.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								<img height={'20px'} alt={''} id={option.value} src={PRECIPITAZIONI_ICONS[option.value]}/> &nbsp;{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={2}>
					<FormControlLabel
						id="frost"
						disabled = {viewMode}
						style={{ marginTop: "18px", }}
						label="Temperature estreme"
						labelPlacement="start"
						control={
							<Checkbox
								color="primary"
								name="frost"
								checked={!!previsione && !!previsione.zones && !!previsione.zones.find(z=> z.zone===zone.id) ? previsione.zones.find(z=> z.zone===zone.id).frost || false : false}
								onChange={(e)=>props.onFormChange(section, zone.id, e.target.name, e.target.checked)}
							/>
						}
					/>
				</Grid>
				<Grid item xs={1}>
					<FormControlLabel
						id="wind"
						disabled = {viewMode}
						style={{ marginTop: "18px", }}
						label="Venti"
						labelPlacement="start"
						control={
							<Checkbox
								color="primary"
								name="wind"
								checked={!!previsione && !!previsione.zones && !!previsione.zones.find(z=> z.zone===zone.id) ? previsione.zones.find(z=> z.zone===zone.id).wind || false : false}
								onChange={(e)=>props.onFormChange(section, zone.id, e.target.name, e.target.checked)}
							/>
						}
					/>
				</Grid>
				<Grid item xs={1}>
					<FormControlLabel
						disabled = {viewMode}
						value="storm_surges"
						style={{ marginTop: "18px", }}
						label="Mareggiate"
						labelPlacement="start"
						control={
							<Checkbox
								color="primary"
								name="storm_surges"
								checked={!!previsione && !!previsione.zones && !!previsione.zones.find(z=> z.zone===zone.id) ? previsione.zones.find(z=> z.zone===zone.id).storm_surges || false : false}
								onChange={(e)=>props.onFormChange(section, zone.id, e.target.name, e.target.checked)}
							/>
						}
					/>
				</Grid>
			</Grid>
		)
	}

	const selectList = !!props.zones ? props.zones.map((zone) => toItemFunction(zone)) : [];

	const [innerValue, setInnerValue] = useState('');
	useEffect(() => {
		if (previsione && previsione.pmmc_desc) {
			setInnerValue(previsione.pmmc_desc);
		} else {
			setInnerValue('');
		}
	}, [previsione.pmmc_desc]);

	const handleOnChangeDescrizioneEvento = useCallback((event) => {
		event.persist();
		const newValue = event.currentTarget.value;
		setInnerValue(newValue);
		debouncedHandleOnChange(event);
	}, []);

	const debouncedHandleOnChange = useDebouncedCallback(
		(e) => {
			props.onFormChange(section, null, e.target.name, e.target.value)
		},
		200
	);

	return (
			 !!selectList.length &&
				<LabelledOutline id="myID" label={props.labelForm}>
					<FormGroup >
						<Grid container style={{ marginLeft: "30px" }} spacing={1}>
							<Grid item xs={4}>
							<TextField
								disabled
								label="Inizio Validità"
								name="hour_start"
								value={!!props.formData ? props.formData.hour_start || '' : ''}
							/>
							</Grid>
							<Grid item xs={4}>
							<TextField
								disabled
								label="Fine Validità"
								name="hour_end"
								value={!!props.formData ? props.formData.hour_end || '' : ''}
							/>
							</Grid>
							<Grid item xs={4}>
								<FormControlLabel
									disabled = {viewMode || isAvvisoDisabled(previsione)}
									id="avers"
									label="Avviso condizioni meteo avverse"
									labelPlacement="end"
									control={
									<Checkbox
										color="primary"
										name="avviso"
										checked={previsione.avviso }
										onChange={(e)=>props.onFormChange(section, null, e.target.name, e.target.checked)}

									/>
									}
								/>
							</Grid>
						</Grid>
						{selectList}
						<Grid container style={{ marginLeft: "30px" }} spacing={1}>
							<Grid item xs={11}>
								<TextField
									disabled = {viewMode}
									autoFocus
									fullWidth
									multiline
									rows={3}
									id="pmmc_desc"
									name="pmmc_desc"
									label="Descrizione evento"
									value={innerValue}
									onChange={handleOnChangeDescrizioneEvento}
								/>
							</Grid>
						</Grid>
					</FormGroup>
				</LabelledOutline>
	)


}
