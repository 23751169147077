import React from 'react';

export default class AegisTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			src: window.CAE_CONFIG.aegis_src
		}
	}

    render() {
        return (
			<>
                <iframe frameborder="0" src={this.state.src} className='w-100' height="800" title='Aegisrcalab'></iframe>
			</>
		)
	}
}