import React from 'react'
import {AiOutlineLineChart, AiOutlineRadarChart, AiOutlineTable} from "react-icons/ai";
import {VscGroupByRefType} from "react-icons/vsc";
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import {
	MeasurementAggregation,
	MeasurementAggregationDisplayValues,
	MeasurementAggregationForChart
} from "#/lib/MeasurementAggregation";
import {COMBINED_TO_STANDARD, COMBO_TYPES, MEASUREMENT_CATEGORY} from "#/lib/MeasurementCategory";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import lapsWrfensorComponent from "#/commons/data/components/CompareSensorComponent";
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import CompareSensorComponent from "#/commons/data/components/CompareSensorComponent";
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';
import moment from 'moment';
import DateUtils from "#/lib/DateUtils";


const VIEW_MODE = {
	NONE: "NONE",
	TABLE: "TABLE",
	AGGREGATED_TABLE: "AGGREGATED_TABLE",
	CHART: "CHART",
	AGGREGATED_CHART: "AGGREGATED_CHART",
	CROSS_VALIDATION: "CROSS_VALIDATION"
};


let defaultAggregationInterval = {
	label: <>&nbsp;</>,
	value: 0
}

let notAggregableMeasurements = [
	/*MEASUREMENT_CATEGORY.P,*/
	MEASUREMENT_CATEGORY.DS,
	MEASUREMENT_CATEGORY.DV,
	MEASUREMENT_CATEGORY.DR,
	MEASUREMENT_CATEGORY.WS,
	MEASUREMENT_CATEGORY.WR,
	MEASUREMENT_CATEGORY.WV,
	MEASUREMENT_CATEGORY.BA,
	MEASUREMENT_CATEGORY.R,
	MEASUREMENT_CATEGORY.RETE

];

export default class MeasurementsHeader extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			isFrontoffice : window.location.href.includes("frontoffice"),
			showModal : false,
			target : {measure : this.props.selectedMeasure, station : this.props.selectedStation}
		}
	}
	getTitle() {
		let title = "";
		switch (this.props.selectedViewMode.value) {
			case VIEW_MODE.NONE:
				title += "";
				break;
			case VIEW_MODE.TABLE:
				title += "Tabella puntale misure ";
				break;
			case VIEW_MODE.AGGREGATED_TABLE:
				title += "Tabella misure ";
				break;
			case VIEW_MODE.CHART:
				title += "Grafico misure ";
				break;
			case VIEW_MODE.AGGREGATED_CHART:
				title += "Grafico aggregato ";
				break;
			case VIEW_MODE.CROSS_VALIDATION:
				title += "Cross Validation ";
				break;
			default:
				title += "";
				break;
		}

		let stationDescription = <>{this.props.selectedMeasure.icon}{this.props.selectedMeasure.label + " " + this.props.selectedStation.label + " (" +this.props.selectedStation.value+")"}</>;



		return <h3>{title+"   "}{stationDescription}</h3>;
	}

	getViewModeSelect(){
		let options = [];
		if (!notAggregableMeasurements.includes(this.props.selectedMeasure.value)) {
			options = [
				{
					label: "",
					value: VIEW_MODE.NONE
				},
				{
					label: "Tabella",
					value: VIEW_MODE.AGGREGATED_TABLE,
					icon: <AiOutlineTable className="mr-2"></AiOutlineTable>
				},
				{
					label: "Grafico",
					value: VIEW_MODE.CHART,
					icon: <AiOutlineLineChart className="mr-2"></AiOutlineLineChart>
				}
			];
		}
		else if(COMBO_TYPES.includes(this.props.selectedMeasure.value)){
			options = [
				{
					label: "",
					value: VIEW_MODE.NONE
				},
				{
					label: "Tabella Combinata",
					value: VIEW_MODE.TABLE,
					icon: <AiOutlineTable className="mr-2"></AiOutlineTable>
				},
				{
					label: "Grafico Combinato",
					value: VIEW_MODE.AGGREGATED_CHART,
					icon: <AiOutlineRadarChart className="mr-2"></AiOutlineRadarChart>
				}

			];
		}
		else {
			options = [
				{
					label: "",
					value: VIEW_MODE.NONE
				},
				{
					label: "Tabella Puntuale",
					value: VIEW_MODE.TABLE,
					icon: <AiOutlineTable className="mr-2"></AiOutlineTable>
				},
				{
					label: "Grafico Puntuale",
					value: VIEW_MODE.CHART,
					icon: <AiOutlineLineChart className="mr-2"></AiOutlineLineChart>
				}

			];
		}

		if(!this.state.isFrontoffice && this.props.selectedMeasure.value===MEASUREMENT_CATEGORY.P){
			options.push({
				label: "Cross Validation",
				value: VIEW_MODE.CROSS_VALIDATION,
				icon: <VscGroupByRefType className="mr-2"></VscGroupByRefType>
			})
		}

		return <InputSelectComponent
			className={""}
			placeholder={"Seleziona Vista"}
			onChange={val => this.props.onSelectViewMode(options.find(opt => opt.value === val))}
			optionList={options}
			value={!!this.props.selectedViewMode ? this.props.selectedViewMode.value : null}
		/>

	}
	isOptionDisabled(key){
		let {start, end} = this.props.interval;
		let duration = end.add(1,'seconds').diff(start, 'days');
		let monthDuration = end.add(1,'seconds').diff(start, 'months');
		let yearDuration = end.add(1,'seconds').diff(start, 'years');
		switch (key) {
			case 'HOUR': return monthDuration > 0;
			case 'HOUR_2': return monthDuration > 0;
			case 'HOUR_3':	return monthDuration > 0;
			case 'HOUR_6': return duration < 2;
			case 'HOUR_12': return duration < 5;
			case 'DAY':	return duration < 7;
			case 'MONTH': return monthDuration < 1;
			case 'YEAR': return yearDuration < 1;
			default : return false;
		}
	}
	getAggregationIntervalSelect() {


		let options = [];
		options.push(defaultAggregationInterval)

		if(this.props.selectedViewMode.value === VIEW_MODE.AGGREGATED_TABLE || this.props.selectedViewMode.value === VIEW_MODE.AGGREGATED_CHART){
			if (!notAggregableMeasurements.includes(this.props.selectedMeasure.value)) {
				Object.keys(MeasurementAggregation).forEach(key => {
					if(key===MeasurementAggregation.DAY){
						options.push({
							label: "24 Ore",
							value: MeasurementAggregation[key]
						});
					}
					else {
						options.push({
							label: MeasurementAggregationDisplayValues[key],
							value: MeasurementAggregation[key]
						});
					}
					
				});
			}
		}
		if (this.props.selectedViewMode.value === VIEW_MODE.CHART){
			if (!notAggregableMeasurements.includes(this.props.selectedMeasure.value)) {
				Object.keys(MeasurementAggregationForChart).forEach(key => {
					if(key===MeasurementAggregationForChart.DAY){
						options.push({
							label: "24 Ore",
							value: MeasurementAggregation[key],
							disabled : false/*this.isOptionDisabled(key)*/
						});
					}
					else {
						options.push({
							label: MeasurementAggregationDisplayValues[key],
							value: MeasurementAggregation[key],
							disabled : false/*this.isOptionDisabled(key)*/
						});
					}
				});
			}
		}

		options = options.filter(x=>x.value!== "MONTH" && x.value!== "YEAR")

		return <InputSelectComponent
			className={""}
			placeholder={"Seleziona Intervallo"}
			onChange={val => this.props.onSelectAggregationInterval(options.find(opt => opt.value === val))}
			optionList={options}
			value={!!this.props.aggregationInterval ? this.props.aggregationInterval.value : null}
			isDisabled={this.props.aggregationIntervalDisabled}
		/>
	}
	/*
	* Sensors comparison
	* */
	showCompareSensorModal() {
		this.setState({
			showModal: true
		})
	}
	onCloseModal() {
		this.setState({
			showModal: false
		})
	}

	updateCompareList(data) {

		this.setState({
			showModal: false
		}, () => this.props.updateCompareList(data))
	}

	onPickRange(dates){
		let interval = null
		if(dates && dates[0] && dates[1]){
			interval = {}

			let epochStart = dates[0].getTime() + (dates[0].getTimezoneOffset() === -120 ? 1000*60*60 : 0);
			let momentStart = moment(epochStart);
			let stringStart = momentStart.format('YYYY-MM-DDTHH:mm:ss')
			interval.start = moment.tz(stringStart, 'Etc/GMT-1').startOf('day');

			let epochEnd = dates[1].getTime() + (dates[0].getTimezoneOffset() === -120 ? 1000*60*60 : 0);
			let momentEnd = moment(epochEnd);
			let stringEnd = momentEnd.format('YYYY-MM-DDTHH:mm:ss')
			interval.end = moment.tz(stringEnd, 'Etc/GMT-1').endOf('day');

		}
		this.props.resetInterval(interval)
	}

	render() {
		return (
			<>
				<div className="row align-items-center mt-2">
					<div className="col-4 ">
						<div className="row align-items-center">
							<div className="col-6">
								<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab", display: 'block' }}>Intervallo di osservazione:</span>
								{/*<span style={{display: 'block'}}>{this.props.interval.start.format("DD/MM/YYYY")} - {this.props.interval.end.format("DD/MM/YYYY")}</span>*/}
								<DateRangePicker
									locale={"it-IT"}
									onChange={(dates) => this.onPickRange(dates)}
									value={[
										this.props.interval && this.props.interval.start && new Date(this.props.interval.start.valueOf()),
										this.props.interval && this.props.interval.end && new Date(this.props.interval.end.valueOf())]}
									maxDate={new Date()}
									format={"dd/MM/yyyy"}
									rangeDivider='&nbsp;&nbsp;&nbsp;'
								/>
							</div>

							{/*<div className="col-6 my-auto">
								<Button
									variant="contained"
									color="default"
									startIcon={<DeleteIcon />}
									onClick={(event) => this.props.resetInterval()}
								>RESET</Button>
		</div>*/}
						</div>
					</div>
					<div className="col-4 ml-auto">
						<div className="row m-2">
							<Button
								size="small"
								variant="contained"
								color="default"
								startIcon={<AddIcon />}
								onClick={(e) => this.showCompareSensorModal()}
							>
								Aggiungi misure
							</Button>
						</div>
					</div>
					<div className="col-4 ml-auto">
						<div className="m-2">
							{this.getViewModeSelect()}
						</div>
					</div>

				</div>
				<div className="row align-items-center mt-2">
					<div className="col-8 ">
						{this.getTitle()}
					</div>
					{![...Object.keys(COMBINED_TO_STANDARD), ...Object.values(COMBINED_TO_STANDARD)].includes(this.props.selectedMeasure.value) && <div className="col-4 ">
						<div className="m-2">
							{this.getAggregationIntervalSelect()}
						</div>
					</div>}
				</div>
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "90vw",
							height: "90vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" style={{ position: 'absolute', right: 1, top: 1, color: 'white' }} onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<CompareSensorComponent
							stationsGeoJSON={this.props.stationsGeoJSON}
							target={this.state.target}
							compareList={this.props.compareList}
							interval={this.props.interval}
							confirm={(data) => { this.updateCompareList(data) }}
							maxCompareListLenght = {10}
						/>
					</div>

				</Modal>
			</>

		)

	}
}
