import React from 'react';
import {Skeleton} from "@material-ui/lab";
import {Link} from "react-router-dom";
import {reactFormatter, ReactTabulator} from "react-tabulator";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SettingsClient from "#/lib/SettingsClient";
import AuthenticationService, {ROLE_ADMIN, ROLE_CONFIGURATOR} from "#/lib/AuthenticationService";

const ReactSwal = withReactContent(Swal);

const RemoveButton = (props) => {
	return (
		<Tooltip title={"Rimuovi sensore dalla blacklist"}>
			<IconButton color="secondary" aria-label="rimuovi sensore" component="span" disabled={props.disabled}
						onClick={(e) => { props.clickCallback(props.cell._cell.row.data) }}>
				<RemoveIcon />
			</IconButton>
		</Tooltip>
	)
}

const mockedBlacklist = [{category: 'P', stationCode: '1548', code: '45896', label: 'Quartu San\'Elena'},
	{category: 'T', stationCode: '1549', code: '45897', label: 'Cagliari'},
	{category: 'I', stationCode: '150', code: '45898', label: 'Sestu'}];

export default class BlacklistPage extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			blacklist: [],
			tableKey: 0
		}
	}
	isConfigurator = () => AuthenticationService.haveRolesPermssions([ROLE_CONFIGURATOR, ROLE_ADMIN]);

	componentDidMount = () => {
		this.getBlacklist();
	}

	getBlacklist = () => {
		SettingsClient.getBlacklist(
			blacklist => {
				this.setState({
					blacklist,
					tableKey: (this.state.tableKey + 1) % 1000,
				})
			},
			() => {
				ReactSwal.fire('Errore', 'Si è verificato un errore nel recupero dei dati', 'error');
			}
		)
	}
	removeSensorFromBlacklist = sensorCode => {
		ReactSwal.fire({
			title: <h3>Confermi la rimozione del sensore {sensorCode} dalla blacklist?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'Annulla'
		})
			.then((result) => {
				if (result.value) {
					// TODO call SensorClient.removeFromBlacklistBySensorCode() thn call SensorClient.getBlacklist()
					SettingsClient.removeBlacklistItem(
						sensorCode,
						() => {
							ReactSwal.fire(`Cancellazione sensore ${sensorCode}`, 'Il record è stato eliminato con successo', 'success');
							this.getBlacklist();
						},
						() => {
							ReactSwal.fire(`Errore cancellazione sensore ${sensorCode}`,'', 'error');

						}
					)
				}
			})

	}
	getColumns = () => [].concat([
		{ title: "Categoria", field: "category", headerSort:false, widthGrow:1 },
		{ title: "Codice stazione", field: "stationCode", headerSort: true, widthGrow:1 },
		{ title: "Codice sensore", field: "code", headerSort: true, widthGrow:1 },
		{ title: "Nome", field: "label", headerSort: true},
		{
			title: "",
			formatter: reactFormatter(<RemoveButton disabled={!this.isConfigurator()} clickCallback={(elem) => this.removeSensorFromBlacklist(elem.code)}></RemoveButton>),
			headerSort: false,
			hozAlign: "center",
			vertAlign: "middle",
			widthGrow: 1
		}
	]);

	render = () =>
		(<div className="generic-page container-fluid data-page">
			<div className="textAlignCenter">
				<h2 >Blacklist Sensori</h2>
				<h3>Di seguito vengono mostrati i sensori esclusi dal calcolo del Mosip e dall'interpolazione dei valori di misurazione all'interno del modulo Mida</h3>
				<h3 style={{display: 'inline-flex'}}>È possibile aggiungere nuovi sensori alla blacklist nella pagina di &nbsp;<Link to={"/backoffice/gridmgr"}>Gestione Sensori</Link></h3>

				<ReactTabulator
					key={"table_" + this.state.tableKey}
					ref={ref => (this.tableRef = ref)}
					columns={this.getColumns()}
					data={this.state.blacklist}
					options={{layout:"fitColumns", height: "60vh"}}
				/>
			</div>
		</div>)

}
