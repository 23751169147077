import React from 'react';
import {withRouter} from 'react-router';

import DateUtils from '#/lib/DateUtils'
import {FiClock} from 'react-icons/fi';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import {Badge} from 'react-bootstrap';
import {properties} from '#/properties';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '#/backoffice/style/SweetAlert.css';
import BulletinClient from '#/lib/BulletinClient';
import {ApproveButton} from "#/commons/components/forms/ApproveButton";
import {PublishButton} from "#/commons/components/forms/PublishButton";
import {Link} from "react-router-dom";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import MauMapComponent from "#/commons/mau/MauMapComponent";


const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
class PostEventoItem extends React.Component {

	approve = (id) => {
		loadingSwal.fire('Approvazione in corso...')
		let params = {
			bulletinTypeName: 'RPE',
			id: id
		};
		BulletinClient.approveBulletin(
		  (result) => {
			  loadingSwal.close()
			ReactSwal.fire("Approvazione Report post evento", "Report post evento  approvato con successo","success")
				.then(result => {
					if (result.value && this.props.refreshList) {
						this.props.refreshList()
					}
				});
		  },
		  function (msg) {
			  loadingSwal.close()
		  	ReactSwal.fire("Approvazione Report post evento ", msg, "error");
		  },
		  params
		);
	}

	publish = (id) => {
		loadingSwal.fire('Pubblicazione in corso...')
		let params = {
			bulletinTypeName: 'RPE',
			id: id
		};
		BulletinClient.publishBulletin(
		  (result) => {
			  loadingSwal.close()
			ReactSwal.fire("Pubblicazione Report post evento ", "Report post evento pubblicato con successo","success")
				.then(result => {
					if (result.value && this.props.refreshList) {
						this.props.refreshList()
					}
				});
		  },
		  function (msg) {
			  loadingSwal.close()
			ReactSwal.fire("Pubblicazione Report post evento ", msg, "error");
		  },
		  params
		);
	}

	delete = (id) => {
		const title = this.getReportTitle();
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione del {title}</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'No'
		})
		.then((result) => {
			if (result.value) {
				let params = {
					bulletinTypeName: 'RPE',
					id: id
				};
				loadingSwal.fire('Eliminazione in corso...')
				BulletinClient.deleteBulletin (
					(result) => {
						loadingSwal.close()
						ReactSwal.fire('Cancellazione Report post evento ', 'Il record è stato eliminato con successo', 'success')
							.then(result => {
								if (result.value && this.props.refreshList) {
									this.props.refreshList()
								}
							});
					},
					(msg) => {
						loadingSwal.close()
						ReactSwal.fire('Errore cancellazione Report post evento ', 'error');
					},
					params
				)
			}
		})

    }


	getReportTitle = () => {
		const jsonData = this.props.jsonData;
		let startD = DateUtils.fromISO8601ToDate(jsonData.startDate);
		let startF = jsonData.endDate ? startD.getDate() : DateUtils.epochToLongLocaleDateFormatted(startD.getTime());
		let endD = jsonData.endDate ? DateUtils.fromISO8601ToDate(jsonData.endDate) : null;
		let endF = endD ?  "-" + DateUtils.epochToLongLocaleDateFormatted(endD.getTime()) : "";
		let todayTitle = "Report post evento " + startF + endF;
		return todayTitle;		
	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);
    render() {

		let docUrl = !!this.props.id ? properties.url.bulletinDownloadFile + '/' + this.props.id + '/pdf' : "";
		let todayTitle = this.getReportTitle();
		// TODO : Aggiungere controllo ruolo amministratore

		let isEditable = this.props.isEditable && !this.props.published && this.isOfficer();
		let isDeletable = this.props.isDeletable && !this.props.published && this.isOfficer() ;

        return (
			<Card style={{ marginBottom: '0.7rem', borderRadius: '5px', border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }}>
				<Accordion.Toggle as={Card.Header} variant="link" eventKey={this.props.id} onClick={() => {}}>
					<div className="row" style={{ display: "flex" }}>
						<div className="col-sm">
							<div className="row">
								<span className="icon-span"> <FiClock /> Emanato il: {DateUtils.epochToDate(this.props.createdAt)} </span>
								{(!!this.props.editable) ? this.getBadge(this.props) : <></>}
							</div>
							<div>
								<span className="item-title">{todayTitle}</span>
							</div>
						</div>
						<div className="col-sm d-flex justify-content-end align-items-center">
							<div className="d-flex justify-content-end">
								{!!this.props.editable ?
									<>
										<div className="my-auto mx-2" >
											{!!this.props.id && !this.props.published  && this.getApprovePublishButton(this.props)}
										</div>

										<div className="my-auto mx-2" >
											<Fab size="medium" title="Cancella" aria-label="delete"
												 onClick={(event) => {event.stopPropagation(); this.delete(this.props.id)}}
												//  disabled={!isDeletable}
												 style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
											>
												<DeleteIcon style={{color: '#fff'}} />
											</Fab>
										</div>
									</>
									:
									<></>

								}
								<div className="my-auto mx-2" >
									<Fab size="medium" title="Scarica" aria-label="Scarica" href={docUrl} onClick={(event) => {event.stopPropagation();}}
										style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
									>
										<GetAppIcon  color={!!this.props.id ? 'action' : 'disabled'} style={{color: '#fff'}} />
									</Fab>
			
								</div>
							</div>
						</div>
					</div>
				</Accordion.Toggle>
			</Card>
        )
    }

	getBadge = (props) => {
		let retValue = <></>;
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		let published = props.published;
		if (notApproved){
			retValue = <span> <Badge variant="secondary">In attesa di approvazione</Badge> </span>;
		} else if(onlyApproved){
			retValue = <span> <Badge variant="info">Approvato</Badge> </span>;
		} else if (published) {
			retValue = <span> <Badge variant="success">Pubblicato</Badge> </span>;
		}
		return (retValue);
	}

	getApprovePublishButton = (props) => {
		let retValue = <></>;
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		if (notApproved){
			retValue = <ApproveButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={() => this.approve(props.id)}/>;
		} else if(onlyApproved){
			retValue = <PublishButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={() => this.publish(props.id)}/>;
		}
		return (retValue);
	}
};


export default withRouter(PostEventoItem)
