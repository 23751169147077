import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, useFormikContext} from "formik";
import TextField from '@material-ui/core/TextField';
import InputSelectComponent from '#/commons/components/InputSelectComponent'
import SendIcon from '@material-ui/icons/Send';

import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import {GeoJSON, Marker, Popup, TileLayer} from "react-leaflet";
import MapComponent from "#/commons/map/MapComponent";
import GISTools from "#/lib/GISTools";
import * as turf from "leaflet";
import L from "leaflet";
import _ from 'lodash';
import {Switch} from 'formik-material-ui';
import FormControlLabel from "@material-ui/core/FormControlLabel";


const mapOptions = {
	center: [39.11, 16.55],
	zoom: 6,
	minZoom: 6,
	width: "40vw",
	height: "60vh"
}

const provinceGeoJson = () => {
	let provinces = _.cloneDeep(GISTools.getProvinceGeoJson());
	GISTools.addCentroids(provinces);
	return provinces;
};

export function StringField(props) {
	const { name, label, placeholder, disabled = false, ...rest } = props;
	return (
		<>
			<Field name={name}>
				{({
					  field, // { name, value, onChange, onBlur }
					  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
					  meta,
				  }) => (
					<div>

						<TextField
							fullWidth
							disabled={disabled}
							autoComplete='off'
							label={label}
							name={name}
							id={name}
							InputLabelProps={{
								shrink: true,
							  }}
							placeholder={placeholder || ""}
							{...field}
						/>

						<ErrorMessage
							name={name}
							render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
						/>
					</div>
				)}
			</Field>

		</>
	);
}
export function SensorCategoryField(props) {
	const {options,  catName, catLabel, unitName, unitLabel, catPlaceholder = '', unitPlaceholder = '', ...rest } = props;
	return (
			<div className="d-flex justify-content-between align-items-center">
				<div style={{width:"100%"}}>
					<SelectField name={catName}
								 label={catLabel}
								 options={options}
								 otherFields={[{
						name: 'unit',
						key: 'unit'
					}]}/>

					<Field name={unitName}>
						{({
							  field, // { name, value, onChange, onBlur }
							  form: { touched, errors },
							  meta,
						  }) => (
							<div>

								<TextField
									fullWidth
									disabled
									autoComplete='off'
									label={unitLabel}
									name={unitName}
									id={unitName}
									placeholder={unitPlaceholder || ""}
									{...field}
								/>

								<ErrorMessage
									name={unitName}
									render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
								/>
							</div>
						)}
					</Field>
				</div>
			</div>
	);
}


export function LocationField(props) {
	const context = useFormikContext();
	const { xName, xLabel, xPlaceholder, yName, yLabel, yPlaceholder, currentZIndex, regName, regLabel, regPlaceholder, provName, provLabel, provPlaceholder, ...rest } = props;

	const [location, setLocation] = useState(null);

	const [temporaryLocation, setTemporaryLocation] = useState({
		[xName] : context.values[xName],
		[yName] : context.values[yName],
		[regName] : context.values[regName],
		[provName]: context.values[provName]
	})

	const [showMapModal, setShowMapModal] = useState(false)

	useEffect(() => {
		setShowMapModal(false)
	}, [location])

	const confirmLocation = () =>{
		context.setFieldValue(xName,temporaryLocation[xName]);
		context.setFieldTouched(xName,true)
		context.setFieldValue(yName,temporaryLocation[yName]);
		context.setFieldTouched(yName,true);

		context.setFieldValue(regName,temporaryLocation[regName]);
		context.setFieldTouched(regName,true);
		context.setFieldValue(provName,temporaryLocation[provName]);
		context.setFieldTouched(provName,true);

		setLocation(temporaryLocation)
	}

	const findLocationGeoData = ({latlng : {lat, lng}})  => {
		let region = "", province = "";
		let provincePolygon = GISTools.getProvincePolygonByPoint([lng, lat]);
		if (provincePolygon){
			region = provincePolygon.properties.reg_name;
			province = provincePolygon.properties.prov_acr;
		}
		setTemporaryLocation({
			[xName] : lng,
			[yName] : lat,
			[regName] : region,
			[provName] : province
		})
	}

	const pointToLayer = (feature, latlng) =>
		L.marker(latlng, {
			icon: L.divIcon({
				className: 'label',
				html: `<span style="font-size:2rem; font-weight:600; color: #b8b5b5">${feature.properties.prov_acr}</span>`,
			})
		});


	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<div style={{width:"80%"}}>
					<Field name={xName}>
						{({
							  field, // { name, value, onChange, onBlur }
							  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							  meta,
						  }) => (
							<div>

								<TextField
									fullWidth
									autoComplete='off'
									label={xLabel}
									name={xName}
									id={xName}
									type="number"
									placeholder={xPlaceholder || ""}
									{...field}
								/>

								<ErrorMessage
									name={xName}
									render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
								/>
							</div>
						)}
					</Field>

					<Field name={yName}>
						{({
							  field, // { name, value, onChange, onBlur }
							  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							  meta,
						  }) => (
							<div>

								<TextField
									className="mt-2"
									fullWidth
									autoComplete='off'
									label={yLabel}
									name={yName}
									id={yName}
									type="number"
									placeholder={yPlaceholder || ""}
									{...field}
								/>

								<ErrorMessage
									name={yName}
									render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
								/>
							</div>
						)}
					</Field>

					<Field name={regName}>
						{({
							  field, // { name, value, onChange, onBlur }
							  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							  meta,
						  }) => (
							<div>

								<TextField
									fullWidth
									autoComplete='off'
									label={regLabel}
									name={regName}
									id={regName}
									placeholder={regPlaceholder || ""}
									{...field}
								/>

								<ErrorMessage
									name={regName}
									render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
								/>
							</div>
						)}
					</Field>

					<Field name={provName}>
						{({
							  field, // { name, value, onChange, onBlur }
							  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
							  meta,
						  }) => (
							<div>

								<TextField
									fullWidth
									autoComplete='off'
									label={provLabel}
									name={provName}
									id={provName}
									placeholder={provPlaceholder || ""}
									{...field}
								/>

								<ErrorMessage
									name={provName}
									render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
								/>
							</div>
						)}
					</Field>
				</div>
				<div>
					<Button variant="contained" color="primary" onClick={()=>{setShowMapModal(true)}}
							startIcon={<SendIcon />}>
						MAPPA
					</Button>
				</div>

			</div>
			<Modal
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					zIndex : currentZIndex ? currentZIndex + 10 : 999999
				}}
				open={showMapModal}
				onClose={() => setShowMapModal(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div
					style={{
						background: "white",
						width: "60vw",
						height: "70vh"
					}}
				>
					<div className='w-100 d-flex justify-content-end'>
						<IconButton  aria-label="close" onClick={() => setShowMapModal(false)}>
							<CloseIcon />
						</IconButton>
					</div>

					<div className="d-flex justify-content-center">
						<h3>Inserimento Posizione da Mappa</h3>
					</div>
					<div className="row mx-auto">
						<div className="col-8">
							<MapComponent
								width={"90%"}
								height={'60vh'}
								zoom={mapOptions.zoom}
								zoomSnap={false}
								buttonKey={'pointSelect'}
								tile={null}
								handleClick={findLocationGeoData}
							>
								<GeoJSON key={"polygons_provinces"}
										 data={provinceGeoJson()}
										 onEachFeature={(feature, layer) => {}}
										 style={{
											 fillColor: "#fff",
											 fillOpacity: 0,
											 weight: 2,
											 opacity: 1,
											 color: "green",
										 }}
										 pointToLayer={(feature, latlng) => pointToLayer(feature, latlng)}
								/>
								<Marker position={[temporaryLocation[xName], temporaryLocation[yName]]}>
								</Marker>
							</MapComponent>
						</div>
						<div className="col-4">
							<div className="row">
								<div className="col">Regione selezionata:</div>
								<div className="col">{temporaryLocation[regName] || ''}</div>
							</div>
							<div className="row">
								<div className="col">Provincia selezionata:</div>
								<div className="col">{temporaryLocation[provName] || ''}</div>
							</div>

							<Button style={{position: 'absolute', top: '55vh', left: '10vw'}} variant="contained" color="primary" onClick={()=>{confirmLocation()}}>
								Conferma
							</Button>
						</div>

					</div>
				</div>

			</Modal>
		</>
	);
}

export function BooleanField (props) {
	const { name, label, placeholder, ...rest } = props;
	const context = useFormikContext();

	const [toggle, setToggle] = useState(context.values[name]);
	const handleToggle = () => {
		context.setFieldValue(name, !toggle);
		context.setFieldTouched(name,true);
		setToggle(!toggle);
	};
	return (
		<><FormControlLabel
			control={
			<Field
				label={label}
				name={name}
				component={Switch}
				onChange={handleToggle}
				checked={toggle}
			/> }
			label={label}
		/>
		</>
	);
}


export function NumericField(props) {
	const { name, label, placeholder, ...rest } = props;
	return (
		<>

			<Field name={name}>
				{({
					  field, // { name, value, onChange, onBlur }
					  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
					  meta,
				  }) => (
					<div>

						<TextField
							fullWidth
							autoComplete='off'
							id="standard-basic"
							label={label}
							name={name}
							id={name}
							type="number"
							placeholder={placeholder || ""}
							{...field}
						/>

						<ErrorMessage
							name={name}
							render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
						/>
					</div>
				)}
			</Field>
		</>
	);
}

export function PasswordField(props) {
	const { name, label, placeholder, ...rest } = props;
	return (
		<>
			<Field name={name}>
				{({
					  field, // { name, value, onChange, onBlur }
					  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
					  meta,
				  }) => (
					<div>

						<TextField
							fullWidth
							autoComplete='off'
							id="standard-basic"
							label={label}
							name={name}
							id={name}
							type="password"
							placeholder={placeholder || ""}
							{...field}
						/>

						<ErrorMessage
							name={name}
							render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
						/>
					</div>
				)}
			</Field>
		</>
	);
}

export function SelectField(props) {
	const context = useFormikContext();
	const { name, label, options, otherFields = [] } = props;
	const handleSelect = selectedValue => {

		context.setFieldValue(name, selectedValue);
		context.setFieldTouched(name,true);

		if (otherFields.length > 0){ // Sets other form fields consequently
			otherFields.forEach(field => {
				context.setFieldValue(field.name, options.find(opt => opt.value === selectedValue)[field.key]);
				context.setFieldTouched(field.name,true);
			})
		}
	};
	return (
		<>
			<Field  id={name} name={name}>
				{({
					  field, // { name, value, onChange, onBlur }
					  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
					  meta,
				  }) => (
					<div>
						<InputSelectComponent
							className={"mt-4 col-12 row justify-content-center"}
							placeholder={label}
							isSearchable={true}
							optionList={options}
							value={field.value}
							onChange={selectedValue => handleSelect(selectedValue)}
						/>

						<ErrorMessage
							name={name}
							render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
						/>
					</div>
				)}
			</Field>

		</>
	);
}



export function SubmitButton(props) {
	const { title, ...rest } = props;
	const { isSubmitting } = useFormikContext();

	return (
		<Button variant="contained" color="primary" type="submit" {...rest} disabled={isSubmitting}
				startIcon={<SendIcon />}>
			{title}
		</Button>

	);
}
