import React from "react";

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';

import MapFormComponent from "#/commons/map/MapFormComponent";
import { MAUMultizoneMeteoForm }from "#/backoffice/mau/components/MAUMultizoneMeteoForm";
import { BackButton } from "#/commons/components/forms/BackButton";
import { NextButton } from "#/commons/components/forms/NextButton";


export const StepPrevisioniMeteoTomorrow = (props) => {
    const formData = props.formData;
    const map = !!props.maps && props.maps.length > 0 ? props.maps[1] : null;
    const tomorrowDate = !!formData && !!formData.end && !!formData.end.date_short ? formData.end.date_short || '' : '';
    const rootSection = ["end"];
    const section =  ["end","previsione"];
    let validForm = true;

    const handleNextStep = () => {
        if (validForm || props.viewMode) {
            props.handleNext();
        } else {
            props.reactSwal.fire(
                "Redazione mau",
                "Compilare tutti i campi prima di procedere",
                "error"
            );
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <h2>{props.stepLabel + ' ' + tomorrowDate}</h2>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {!!map &&
                    <MapFormComponent
                        id={map.Id}
                        key={map.Key}
                        geoJSON={map.shapesGeoJSON}
                        width={map.width || "25vw"}
                        height={map.height || "60vh"}
                        mapKey={map.Key}
                        getColorById={(id) => {return null}}
                    />
                    }
                </Grid>
                <Grid item xs={8}>
                    {!!map &&
                    <MAUMultizoneMeteoForm
                        key={JSON.stringify(formData.end)}
                        viewMode={props.viewMode}
                        labelForm={map.labelForm + tomorrowDate}
                        zones={map.zones}
                        rootSection={rootSection}
                        section={section}
                        onFormChange={props.onFormChange}
                        formData={formData.end}
                    />
                    }
                </Grid>
            </Grid>
            <MobileStepper
                style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
                position="static"
                variant="text"
                activeStep={props.activeStep}
                steps={props.totalSteps}
                nextButton={
                    <NextButton
                        variant="contained"
                        color="primary"
                        disabled={!validForm}
                        onClick={handleNextStep}
                        textbutton={props.textNextButton}
                    />
                }
                backButton={
                    <BackButton
                        variant="contained"
                        disabled={props.disableBackButton}
                        onClick={props.handleBack}
                    />
                }
            />
        </>
    );
};
