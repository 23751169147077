import React from 'react'

import LegacyMeasurementsHeader from "#/commons/legacyData/components/LegacyMeasurementsHeader"
import LegacyMeasurementsBody from "#/commons/legacyData/components/LegacyMeasurementsBody"


const VIEW_MODE = {
	NONE: "NONE",
	AGGREGATED_TABLE: "AGGREGATED_TABLE",
	AGGREGATED_CHART: "AGGREGATED_CHART",
};


let defaultViewMode = {
	label: "",
	value: VIEW_MODE.AGGREGATED_TABLE
}

let defaultAggregationInterval = {
	label: "5 minuti",
	value: 'MIN_5'
}


export default class LegacyMeasurementsComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedViewMode: defaultViewMode,
			aggregationInterval: defaultAggregationInterval,
			key : 1,
			compareList : [],
			stationNameByCode : {}
		}
	}

	componentDidMount() {

		let stationNameByCode = {}
		this.props.stationsGeoJSON.features.forEach(feature => {
			
			stationNameByCode[feature.properties.code]=feature.properties.name;
		})
		this.setState({
			stationNameByCode : stationNameByCode,
			selectedViewMode :  defaultViewMode,
			aggregationInterval: defaultAggregationInterval,
			key: (this.state.key + 1) % 1000
		});
	}

	onSelectViewMode(data) {
		this.setState({
			selectedViewMode: data,
			key: (this.state.key + 1) % 1000
		})
	}

	onSelectAggregationInterval(data) {
		this.setState({
			aggregationInterval: data,
			key: (this.state.key + 1) % 1000
		})
	}

	updateCompareList(data) {

		this.setState({
			compareList: data,
			key: (this.state.key + 1) % 1000,
		})
	}

	render() {
		return <>
			<LegacyMeasurementsHeader
				resetInterval={(interval) => this.props.resetInterval(interval)}
				selectedStation={this.props.selectedStation}
				selectedViewMode={this.state.selectedViewMode}
				interval={this.props.interval}
				onSelectViewMode={(data) => this.onSelectViewMode(data)}
				aggregationInterval={this.state.aggregationInterval}
				onSelectAggregationInterval={(data) => this.onSelectAggregationInterval(data)}
				onSelectAggregationFunction={(data) => this.onSelectAggregationFunction(data)}
				stationsGeoJSON = {this.props.stationsGeoJSON}
				updateCompareList = {data => this.updateCompareList(data)}
				compareList = {this.state.compareList}
			></LegacyMeasurementsHeader>
			<LegacyMeasurementsBody
				datakey={this.state.key}
				selectedStation={this.props.selectedStation}
				interval={this.props.interval}
				aggregationInterval={this.state.aggregationInterval}
				selectedViewMode={this.state.selectedViewMode}
				stationsGeoJSON = {this.props.stationsGeoJSON}
				compareList = {this.state.compareList}
				stationNameBySensorCode = {this.state.stationNameByCode}
			></LegacyMeasurementsBody>
		</>

	}
}
