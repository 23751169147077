import React from 'react'
import MidaComponent from "#/backoffice/mida/components/MidaComponent";

export default class MidaPage extends React.Component {
	render() {
		return (
			<div className="generic-page container-fluid data-page">
				<MidaComponent withDateSelection={true}/>
			</div>
		)
	}
}
