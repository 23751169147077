

export default class GumbelCalculator {


	static getFreqByH(lambdaUno, tetaUno, lambdaStar, tetaStar, heigth) {
		let f = Math.exp( -lambdaUno * Math.exp(-heigth/tetaUno) - lambdaStar * Math.pow(lambdaUno, 1/tetaStar) * Math.exp(-heigth/(tetaStar*tetaUno)));
		return f;
	}

	static getTempoDiRitornoByH(lambdaUno, tetaUno, lambdaStar, tetaStar, heigth) {
		let f = Math.exp( -lambdaUno * Math.exp(-heigth/tetaUno) - lambdaStar * Math.pow(lambdaUno, 1/tetaStar) * Math.exp(-heigth/(tetaStar*tetaUno)));
		let tempoDiRitorno = 1/(1-f);
		return tempoDiRitorno;
		
	}
 
	static getHByTempoDiRitorno(lambdaUno, tetaUno, lambdaStar, tetaStar, tempoDiRitorno) {
		let f = 1 - (1/(tempoDiRitorno));
		
		let maxRain = 4.8 * 60 * 24;
		let targetH = -1;		
		let minDifference = 10000000000000000000000;

		for (let h=maxRain; h>0; h=h-0.1) {
			let currentF = Math.exp( -lambdaUno * Math.exp(-h/tetaUno) - lambdaStar * Math.pow(lambdaUno, 1/tetaStar) * Math.exp(-h/(tetaStar*tetaUno)));
			let currentDiff = Math.abs(f-currentF);
			if(currentDiff <= minDifference) {
				minDifference = currentDiff;
				targetH = h;
			}
			
		}
		return targetH;
	}	

}
