import { properties} from '#/properties.js';

import parse_georaster from "georaster"
import APIUtils from "#/lib/APIUtils";
import AuthenticationService from "#/lib/AuthenticationService";
import {hecHmsBaseUrl} from "#/properties";


export default class HecHmsClient {

	static getExecutionListByInterval = (okCallback, errCallback, start, end) => {
		let url = properties.url.hecHmsListByInterval;
		let params = {start, end};
		let fetch_options = {
			headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	static execute = (okCallback, errCallback, start, end) => {
		let url = properties.url.hecHmsExecute;
		let params = {start, end};
		let fetch_options = {
			headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	static reExecute = (okCallback, errCallback, execution) => {
		let url = properties.url.hecHmsReExecute;
		let fetch_options = {
			headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
			method: 'POST',
			body: JSON.stringify(execution)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getExecutionDataById = (okCallback, errCallback, id) => {
		let url = hecHmsBaseUrl + "/" + id;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
}
