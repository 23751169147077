import React from 'react'

import Button from '@material-ui/core/Button';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter} from "react-tabulator";
import APIUtils from "#/lib/APIUtils";
import MosipClient from '#/lib/MosipClient'
import MosipMapComponent from '#/commons/map/MosipMapComponent';
import DateUtils from "#/lib/DateUtils";

import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';

import TableComponent from "#/commons/table/TableComponent";
import {properties} from '#/properties.js';

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ReactSwal = withReactContent(Swal);


function ShowButton(props) {
	const rowData = props.cell._cell.row.data;

	return (
		<>
			<Tooltip title={"Mostra Comuni Allertati"}>
				<IconButton color="primary" aria-label="show" component="span"
						onClick={(e) => { console.log("click callback"); props.clickCallback(rowData.protocol) }}>
					<VisibilityIcon />
				</IconButton>
			</Tooltip>


		</>
	)
}

function DownloadButton(props) {
	const rowData = props.cell._cell.row.data;
	let url = MosipClient.getA3UrlByProtocol(rowData.protocol)
	return (
		<>
			<Tooltip title={"Scarica Documento"}>
				<IconButton color="primary" aria-label="download file" component="span" onClick={()=>props.downloadHandler(url, rowData.protocol+".pdf")}>
					<GetAppIcon />
				</IconButton>
			</Tooltip>


		</>
	)
}

const colors = {
	"LEVEL1" : "yellow",
	"LEVEL2" : "orange",
	"LEVEL3" : "red"
}

const livelli = {
	"LEVEL1" : "GIALLO",
	"LEVEL2" : "ARANCIO",
	"LEVEL3" : "ROSSO"
}

export default class MosipWarningsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			alertDocuments: [],
			municipalitiesGeoJSON : null,
			currentWarnings: {},
			mapTitle : "Comuni Allertati nelle ultime 12 ore",
			tableKey : 1,
			mapKey : 1
		}
	}

	componentDidMount() {
		this.loadFromApi();
	}

	loadFromApi() {


		let municipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getAllMunicipalities((data) => { resolve({municipalities:data}); }, () => { console.log("ERROR COMUNI"); });
		})
		let last12HAlertedMunicipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getMosipLast12HAlertedMunicipalities((data) => { resolve({alerted:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let result = {};
		Promise.all([municipalitiesPromise, last12HAlertedMunicipalitiesPromise])
		.then( data =>{
			Object.assign(result, ...data);
			let geoJSON = this.buildGeoJSONFromArray(result.municipalities);
			let warningsMap = result.alerted.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});

			this.setState({
				municipalitiesGeoJSON : geoJSON,
				currentWarnings : warningsMap,
				mapKey: (this.state.mapKey + 1) % 1000
			});
		})

	}


	buildGeoJSONFromArray(data) {
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Municipalities";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				COMUNE: value.name,
				ISTAT: value.istatCode,
				PROVINCIA: value.province,
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}

	

	getAlertDocumentsColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Protocollo", field: "protocol"},
			{ title: "Time", field: "timestamp", headerSort: true, 
				formatter: function (cell) {
				
					return DateUtils.fromISO8601(cell.getValue());
				}
			},
			{
				title: "",
				formatter: reactFormatter(<ShowButton clickCallback={(protocol) => this.selectAlertDocument(protocol)}></ShowButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 150,
			},
			{
				title: "",
				formatter: reactFormatter(<DownloadButton downloadHandler={this.downloadHandler}></DownloadButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 150,
			}
		]);
		return columns;
	}

	showLast12HWarnings(){
		console.log("Last 12 H")
		new Promise((resolve, reject) => {
			MosipClient.getMosipLast12HAlertedMunicipalities(
				(data) => { resolve(data); },
				() => { console.log("ERROR Executions"); }
			);
		}).then(data => {
			let warningsMap = data.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});
			this.setState({
				mapTitle : "Comuni Allertati nelle ultime 12 ore",
				currentWarnings : warningsMap,
				mapKey: (this.state.mapKey + 1) % 1000
			});
		});
	}

	selectAlertDocument(protocol) {

		console.log("selectAlertDocument")
		new Promise((resolve, reject) => {
			MosipClient.getMosipAlertedMunicipalitiesByProtocol(
				(data) => { resolve(data); },
				() => { console.log("ERROR Executions"); },
				protocol
			);
		}).then(data => {
			let warningsMap = data.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});
			this.setState({
				mapTitle : "Comuni allertati dal protocollo: " +protocol,
				currentWarnings : warningsMap,
				mapKey: (this.state.mapKey + 1) % 1000
			});
		});
	}

	downloadHandler(url, fileName){
		APIUtils.downloadFile(url, null, fileName, null, 
		()=>{
			ReactSwal.fire('Il modello A3 '+fileName+' non è più disponibile!', '', 'error');

		})
		
	}



	getTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "60vh"
		}
		return tabulatorOptions;
	}

	polygonStyle(feature, latlng){
		let istatCode = feature.properties.ISTAT;
		if(!!this.state.currentWarnings[istatCode]){
			let municipalityAlert = this.state.currentWarnings[istatCode];
			return {
				fillColor: colors[municipalityAlert.alertLevel],
				weight: 1,
				opacity: 1,
				color: 'grey',
				fillOpacity: 1
			};
		}
		else{

			return {
				fillColor: "#fff0",
				weight: 0.2,
				opacity: 1,
				color: "grey",
				fillOpacity: 0
			};
		} 
	}

	getPopupContent(properties){
		let istatCode = properties.ISTAT;
		let content = '';
		let municipalityData = '';
		municipalityData += `COMUNE : ${properties.COMUNE}</br>`;
		municipalityData += `PROVINCIA : ${properties.PROVINCIA}</br>`;
		municipalityData += `ISTAT : ${properties.ISTAT}</br>`;

		 if(!!this.state.currentWarnings[istatCode]){
			let municipalityAlert = this.state.currentWarnings[istatCode];
			let color = colors[municipalityAlert.alertLevel];
			let time = DateUtils.fromISO8601(municipalityAlert.timestamp)
			municipalityData += `PROTOCOLLO : ${municipalityAlert.protocol}</br>`;
			municipalityData += `TIME : ${time}</br>`;
			municipalityData += `LIVELLO ALLERTA : ${livelli[municipalityAlert.alertLevel]}</br>`;
			content += `<div style='color:${color}'>${municipalityData}</div></br>`;
		}
		else{
			content += `<div>${municipalityData}</div></br>`;
			content += "Nessuna Allerta";
			
		} 

		return `<div style='font-weight: 600'>${content}</div>`;

	}

	render() {



		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className="row mt-4">
						<div className="col-8" >
							<div className="d-flex justify-content-between">
								<h3>Comuni Attualmente Allertati</h3>
								<Button
									className="justify-content-end ml-auto"
									variant="outlined"
									color="primary"
									onClick={(e) => { this.showLast12HWarnings() }}
								>Mostra</Button>
							</div>
							<div className="d-flex justify-content-between">
								<h3>Elenco Protocolli</h3>
							</div>
							<TableComponent
								columns={this.getAlertDocumentsColumns()}
								url={properties.url.mosipAlertDocumentsPaginated}
								tableKey={this.state.tableKey}
							></TableComponent>
{/* 							<ReactTabulator
								ref={ref => (this.tableRef = ref)}
								columns={this.getAlertDocumentsColumns()}
								data={this.state.alertDocuments}
								options={this.getTabulatorOptions()}
								key={this.state.tableKey}
							/> */}
						</div>
						<div className="col-4">
							<MosipMapComponent
								title={this.state.mapTitle}
								geoJSON={this.state.municipalitiesGeoJSON}
								polygonStyle={(feature, latlng)=>this.polygonStyle(feature, latlng)}
								getPopupContent = {(properties)=>this.getPopupContent(properties)}
								polygonsKey={this.state.mapKey}
							></MosipMapComponent>
						</div>

					</div>

				</div>
			</>
		)
	}
}



