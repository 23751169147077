import React from 'react'


import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import { ReactTabulator } from "react-tabulator";

import CloseIcon from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import Skeleton from "react-loading-skeleton";
import ReactApexChart from "react-apexcharts";
import TextField from '@material-ui/core/TextField';
import StationClient from "#/lib/StationClient";
import RainStatsClient from "#/lib/RainStatsClient";

import GumbelCalculator from "#/lib/GumbelCalculator";
import TCEVCalculator from "#/lib/TCEVCalculator";

import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import FormControl from '@material-ui/core/FormControl';
import { FaCrosshairs, FaMapMarkerAlt } from "react-icons/fa";
import { GoInfo } from "react-icons/go";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LabelledOutline from '#/commons/components/LabelledOutline'
import FormGroup from '@material-ui/core/FormGroup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';


import InputLabel from '@material-ui/core/InputLabel';

import StationGisPicker from "#/commons/map/StationGisPicker";

import MaxValuesClient from "#/lib/MaxValuesClient"
import NumberUtils from "#/lib/NumberUtils"
import moment from "moment"

import L from 'leaflet';
import GISTools from '#/lib/GISTools';

import { mean, std } from 'mathjs/number'
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {MeasurementAggregationDisplayValues} from "#/lib/MeasurementAggregation";
import {BackingDataTable} from "#/commons/components/BackingDataTable";
const ReactSwal = withReactContent(Swal);

const defaultZone = {
	value: null,
	label: "Tutte le zone"
}

const defaultStation = {
	value: null,
	label: <>&nbsp;</>
}
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

export default class RainStatsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			geoJSON: null,
			zoneOptions: [],
			stationOptions: [],
			selectedZone: defaultZone,
			selectedStation: defaultStation,

			maxValuesData: [],
			distributionParameters: null,
			cppParameters: null,
			sensorCode: "test",
			loading: true,
			selectedCppTempoDiRitorno: null,
			showStationsModal: false,
			distribution:"GUMBEL",
			tableKey: 1,
			chartKey: 1,
			mapKey: 1,
			calculatorKey : 1,
			stats : null
		}
	}

	componentDidMount() {

		let pluvsPromise = new Promise((resolve, reject) => {
			StationClient.getPluvsStationsGeojson((geoJSON) => { resolve({ geoJSON }); }, () => { console.log("ERROR distributionParameters"); });
		});

		Promise.all([pluvsPromise])
			.then(data => {
				let result = {}
				Object.assign(result, ...data);
				let zoneOptions = this.buildZoneOptions();
				let stationOptions = this.buildStationOptions(defaultStation.value, result.geoJSON);
				// Find stations out of Calabria
				let stationsOutsideRegion = GISTools.getStationsOutsideCalabria(result.geoJSON);
				this.setState({
					geoJSON: result.geoJSON,
					zoneOptions: zoneOptions,
					stationOptions: stationOptions,
					stationsOutsideRegion: stationsOutsideRegion,
					loading: true
				})
			});
	}

	buildMaxValuesData(maxValues) {
		let array = [];
		Object.keys(maxValues).forEach(year => {
			let hour1Element = maxValues[year].find(el=> el.scope==="HOUR_1");
			let hour3Element = maxValues[year].find(el=> el.scope==="HOUR_3");
			let hour6Element = maxValues[year].find(el=> el.scope==="HOUR_6");
			let hour12Element = maxValues[year].find(el=> el.scope==="HOUR_12");
			let hour24Element = maxValues[year].find(el=> el.scope==="HOUR_24");
			let obj = {
				year: year,
				HOUR_1:  !!hour1Element  ? {value:hour1Element.value, validation:hour1Element.validation, notValid : !hour1Element.value || hour1Element.value<=0} : null,
				HOUR_3:  !!hour3Element  ? {value:hour3Element.value, validation:hour3Element.validation, notValid : !hour3Element.value || hour3Element.value<=0} : null,
				HOUR_6:  !!hour6Element  ? {value:hour6Element.value, validation:hour6Element.validation, notValid : !hour6Element.value || hour6Element.value<=0} : null,
				HOUR_12: !!hour12Element ? {value:hour12Element.value, validation:hour12Element.validation, notValid : !hour12Element.value || hour12Element.value<=0} : null,
				HOUR_24: !!hour24Element ? {value:hour24Element.value, validation:hour24Element.validation, notValid : !hour24Element.value || hour24Element.value<=0} : null
			}
			array.push(obj)
		})
		return array;
	}



	buildCfChartOptions() {
		let options = {
			title: {
				text: "Curve di Frequenza"
			},
			chart: {
				id: "CF",
				type: "line",
				animations: {
					enabled: false
				}
			},
			xaxis: {
				type: 'numeric',
				tooltip: {
					formatter: function (val) {
						return val.toFixed(2);
					}
				},
				title: {
					text: "h[mm]"
				},
				tickAmount: 10
			},
			tooltip: {
				x: {
					formatter: function (val) {
						return val.toFixed(2);
					}
				},
				y: {
					formatter: function (val) {
						return val.toFixed(2);
					}
				}
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return val.toFixed(2);
					}
				},

				min: 0,
				max: 1.0,

				title: {
					text: "F(h)"
				},
				tooltip: {
					formatter: function (val) {
						return val.toFixed(2);
					}
				},

				tickAmount: 10
			},

			stroke: {
				curve: "smooth"
			}
		};

		return options;
	}

	buildCfSeries() {

		let distributionParameters = this.state.distributionParameters;
		let mapByDuration = distributionParameters.reduce((obj, params) => { obj[params.scope] = params; return obj }, {});
		let gumbelSeriesByDuration = [];
		Object.keys(mapByDuration).forEach(duration => {

			let data = [];
			let params = mapByDuration[duration];

			for (let h = 1; h < 200; h = h + 0.25) {
				let f;
				if(this.state.distribution==="GUMBEL"){
					f = GumbelCalculator.getFreqByH(params.alfa, params.epsilon, h);
				}
				else if(this.state.distribution==="TCEV"){
					f = TCEVCalculator.getFreqByH(params.lambdaUno, params.tetaUno, params.lambdaStar, params.tetaStar, h);
				}
				else{
					f=0;
				}
				data.push([h, f]);
			}
			let obj = {
				name: duration,
				data: data
			}
			gumbelSeriesByDuration.push(obj);
		})
		return gumbelSeriesByDuration;
	}

	buildCppChartOptions() {
		let options = {
			title: {
				text: "Curve Possibilità Pluviometrica"
			},
			chart: {
				id: "CPP",
				type: "line",
				animations: {
					enabled: false
				}
			},
			xaxis: {
				type: 'numeric',
				tooltip: {
					formatter: val => !!val ? val.toFixed(2) : ''
				},
				title: {
					text: "durata [H]"
				},
				tickAmount: 10
			},
			tooltip: {
				x: {
					formatter:  val => !!val ? val.toFixed(2) : ''
				},
				y: {
					formatter: val => !!val ? val.toFixed(2) : ''
				}
			},
			yaxis: {
				labels: {
					formatter: val => !!val ? val.toFixed(2) : ''
				},

				title: {
					text: "h[mm]"
				},
				tooltip: {
					formatter: val => !!val ? val.toFixed(2) : ''
				},

				tickAmount: 10
			},

			stroke: {
				curve: "smooth"
			}
		};

		return options;
	}


	buildCppSeries() {

		let cppParameters = this.state.cppParameters;
		let mapByTempoDiRitorno = cppParameters.reduce((obj, params) => { obj[params.tempoDiRitorno] = params; return obj }, {});
		let cppSeriesByTempoDiRitorno = [];
		Object.keys(mapByTempoDiRitorno).forEach(tRitorno => {
			let data = [];
			let params = mapByTempoDiRitorno[tRitorno];
			for (let t = 1; t < 25; t = t + 0.1) {
				let h = params.a * Math.pow(t, params.n);
				data.push([t, h]);
			}
			let obj = {
				name: tRitorno,
				data: data
			}
			cppSeriesByTempoDiRitorno.push(obj);
		})
		return cppSeriesByTempoDiRitorno;
	}




	onCloseStationsModal() {
		this.setState({
			showStationsModal: false
		})
	}

	openStationsModal() {
		this.setState({
			showStationsModal: true
		})
	}


	zonesStyle(feature) {
		let zone = parseInt(feature.properties.ZONE);
		let style = {};

		if (this.state.selectedZone.value === 0) {
			if (this.state.zoneOptions.map(x => x.value).includes(zone)) {

				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}

		}
		else {
			if (this.state.selectedZone.value === zone) {
				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}
		}


		return style;
	}

	onEachStation(feature, layer) {
		layer.on('click', (e) => this.onSelectStation(feature.properties.code));

		var popup = L.popup({ closeButton: false })
			.setContent('<div><span class="mr-2 fas fa-map-marker-alt"></span>' + feature.properties.name + '</div>');
		layer.bindPopup(popup);
		let timer = null;
		layer.on('mouseover', function (e) {
			timer = setTimeout(() => this.openPopup(),
				1000)
		});
		layer.on('mouseout', function (e) {
			clearTimeout(timer);
			this.closePopup();
		});
	}

	stationToMarker(feature, latlng) {
		let marker = null;

		let code = feature.properties.code;
		let isSelected = code === this.state.selectedStation.value;

		if (isSelected) {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point_selected.png',
					popupAnchor: [0, 0]
				})
			});
		}
		else {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point.png',
					popupAnchor: [0, 0]
				})
			});
		}

		return marker;

	}


	buildZoneOptions() {
		let zoneOptions = [];
		let zones = GISTools.getAllZonePolygon();

		zoneOptions.push(defaultZone)
		zones.forEach(zone => {
			let id = zone.properties.ZONE;
			zoneOptions.push({
				value: parseInt(id),
				label: "ZONA " + id,
				icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
			});
		})
		// Add Out-of-Calabria zone
		let id = 9999;
		zoneOptions.push({
			value: parseInt(id),
			label: "Territorio extraregionale",
			icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
		});

		return zoneOptions
	}

	buildStationOptions(zoneId, geoJSON) {
		let stationOptions = [];
		let stations = geoJSON;

		if (!!zoneId) {
			if (zoneId === 9999){
				stations = this.state.stationsOutsideRegion;
			} else {
				let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
				let stationWithinZone = GISTools.intersect(geoJSON, zonePolygon);
				stations = stationWithinZone;
			}
		}
		let stationsProperties = GISTools.getPropertiesFromFeature(stations);
		stationOptions.push(defaultStation);
		stationsProperties.forEach(properties => {
			stationOptions.push({
				label: properties.name,
				value: properties.code,
				icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
			});
		})

		return stationOptions
	}

	onSelectZone(zoneId) {
		let stationOptions = [];
		let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
		stationOptions = this.buildStationOptions(zoneId, this.state.geoJSON);

		this.setState({
			stationOptions: stationOptions,
			selectedZone: selectedZone,
			selectedStation: defaultStation,


		})

	}

	updateCfAndCppByStats(){
		let stationCode = this.state.selectedStation.value;
		console.log("Evaluating CF and CPP for " + stationCode + " with " +this.state.distribution+" distribution and new stats ",this.state.stats)
		let distributionParametersPromise = new Promise((resolve, reject) => {
			RainStatsClient.getCfByStationCodeAndDistribution(
				stationCode,
				this.state.distribution,
				this.state.stats,
				(distributionParameters) => { resolve({ distributionParameters }) },
				() => { console.log("ERROR distributionParameters"); });
		});

		let cppParametersPromise = new Promise((resolve, reject) => {
			RainStatsClient.getCppByStationCodeAndDistribution(
				stationCode,
				this.state.distribution,
				[10.0, 20.0, 50.0, 100.0],
				this.state.stats,
				(cppParameters) => { resolve({ cppParameters }); },
				() => { console.log("ERROR distributionParameters"); }
			);
		});

		Promise.all([distributionParametersPromise, cppParametersPromise])
			.then(data => {
				let result = {}
				Object.assign(result, ...data);
				if (result.distributionParameters.length > 0){
					this.setState({
						distributionParameters: result.distributionParameters,
						cppParameters: result.cppParameters,
						loading: false,
						calculatorKey : (this.state.calculatorKey+1)%1000,
						chartKey : (this.state.chartKey+1)%1000

					})
				} else {
					console.log("ERROR");
				}
			})
	}

	computeCfAndCpp(stationCode){
		console.log("Evaluating CF and CPP for " + stationCode + " with " +this.state.distribution+" distribution")
		let distributionParametersPromise = new Promise((resolve, reject) => {
			RainStatsClient.getCfByStationCodeAndDistribution(
				stationCode,
				this.state.distribution,
				null,
				(distributionParameters) => { resolve({ distributionParameters }) },
				() => { console.log("ERROR distributionParameters"); });
		});

		let cppParametersPromise = new Promise((resolve, reject) => {
			RainStatsClient.getCppByStationCodeAndDistribution(
				stationCode,
				this.state.distribution,
				[10.0, 20.0, 50.0, 100.0],
				this.state.stats,
				(cppParameters) => { resolve({ cppParameters }); },
				() => { console.log("ERROR distributionParameters"); }
			);
		});

		let maxValuesPromise = new Promise((resolve, reject) => {
			MaxValuesClient.getMaxRainValuesUntil(
				(maxValues) => { resolve({ maxValues }); },
				() => { console.log("Max values ERROR"); },
				stationCode,
				moment().year()
			);
		});

		Promise.all([distributionParametersPromise, cppParametersPromise, maxValuesPromise])
			.then(data => {

				let stationOptions = this.buildStationOptions(null, this.state.geoJSON);
				let selectedStation = stationOptions.find(element => element.value === stationCode);

				let stationPoint = GISTools.getStationByField(this.state.geoJSON, "code", stationCode);
				let zonePolygon = GISTools.getZonePolygonByPoint(stationPoint);
				let zoneId = (!!zonePolygon && !!zonePolygon.properties)  ? zonePolygon.properties.ZONE : 9999;
				let selectedZone = this.state.zoneOptions.find(element => element.value === parseInt(zoneId, 10));

				let result = {}
				Object.assign(result, ...data);
				let zoneOptions = this.buildZoneOptions();
				let maxValuesData = this.buildMaxValuesData(result.maxValues);
				let stats = this.calculateAllStats(maxValuesData);
				if (result.distributionParameters.length > 0){
					this.setState({
						zoneOptions: zoneOptions,
						stationOptions: stationOptions,
						distributionParameters: result.distributionParameters,
						cppParameters: result.cppParameters,
						maxValuesData,
						stats,
						loading: false,
						selectedZone: selectedZone,
						selectedStation: selectedStation,
						calculatorKey : (this.state.calculatorKey+1)%1000
					}, () => {
						loadingSwal.close()
					})
				} else {
					loadingSwal.close()
					ReactSwal.fire('Impossibile calcolare la distribuzione di Gumbel per la stazione selezionata', '', 'error');
				}

			});
	}

	onSelectStation(stationCode) {
		this.setState({showStationsModal: false}, () =>{
			loadingSwal.fire('Caricamento dei dati...');
			this.computeCfAndCpp(stationCode);

		})
	}

	cellFormatter(cell) {
		let item = cell.getValue();
		if(!item || !!item.notValid){
			cell.getElement().style.backgroundColor = "#ff00008a";
			return "ND";
		}
		else{
			if(this.isIncluded(item)){
				cell.getElement().style.backgroundColor = "rgba(16,187,34,0.8)";
			}
			return NumberUtils.round(item.value,2);
		}
	}

	calculateAllStats(maxValuesData){
		maxValuesData = maxValuesData ?? this.state.maxValuesData;
		let stats = {};
		["HOUR_1","HOUR_3","HOUR_6","HOUR_12","HOUR_24"].forEach(scope=>{
			let scopeValues = maxValuesData.map(x=>x[scope]);
			stats[scope] = this.getStats(scopeValues);
		})
		return stats;
	}

	getStats(values) {
		let filteredValues = values.filter(x=>this.isIncluded(x));
		return {
			average: !!filteredValues && filteredValues.length>0 ? mean(filteredValues.map(x=>x.value)) : 0,
			standardDeviation: !!filteredValues && filteredValues.length>0 ? std(filteredValues.map(x=>x.value)) : 0,
			samples : !!filteredValues && filteredValues.length>0 ? filteredValues.filter(x=> !!x && x.value>0).length : 0
		};
	}

	bottomCalcFormatter(value){
		return 	"<span># : " +value.getValue().samples +"</span></br>"+
			"<span>μ : " +NumberUtils.round(value.getValue().average,2) +"</span></br>"+
			"<span>σ : " +NumberUtils.round(value.getValue().standardDeviation,2)+"</span>";

	}

	isIncluded(obj){
		if(!obj || !!obj.notValid){
			return false;
		}
		else{
			if(obj.validation==="VALID") {
				return !('exclude' in  obj);
			}
			else {
				return 'include' in  obj;
			}
		}

	}

	getMaxHistoryColumns() {

		let valueContextMenu = [
			{
				label:"Includi",
				disabled: (cell) => {
					let obj = cell._cell.value;
					let notValid = !obj || obj.notValid;
					return !!notValid || this.isIncluded(obj);
				},
				action:(e, cell) => {
					delete cell._cell.value.exclude;
					cell._cell.value.include = true
					this.setState({
						tableKey : (this.state.tableKey + 1)%1000,
						stats : this.calculateAllStats()
					},()=>this.updateCfAndCppByStats())
				}
			},
			{
				label:"Escludi",
				disabled: (cell) => {
					let obj = cell._cell.value;
					let notValid = !obj || obj.notValid;
					return  !!notValid || !this.isIncluded(obj);
				},
				action:(e, cell) => {
					delete cell._cell.value.include;
					cell._cell.value.exclude = true
					this.setState({
						tableKey : (this.state.tableKey + 1)%1000,
						stats : this.calculateAllStats()
					},()=>this.updateCfAndCppByStats())
				}
			},
		]

		let dateContextMenu = [
			{
				label:(cell)=>"Includi "+ cell._cell.value,
				action:(e, cell) => {
					let data = cell.getRow()._row.data;

					["HOUR_1","HOUR_3","HOUR_6","HOUR_12","HOUR_24"].forEach(scope =>{
						let obj = data[scope];
						let notValid = !obj || obj.notValid;
						if(!notValid){
							delete obj.exclude;
							obj.include = true
						}
					});
					this.setState({
						tableKey : (this.state.tableKey + 1)%1000,
						stats : this.calculateAllStats()
					},()=>this.updateCfAndCppByStats())
				}
			},
			{
				label:(cell)=>"Escludi "+ cell._cell.value,
				action:(e, cell) => {
					let data = cell.getRow()._row.data;

					["HOUR_1","HOUR_3","HOUR_6","HOUR_12","HOUR_24"].forEach(scope =>{
						let obj = data[scope];
						let notValid = !obj || obj.notValid;
						if(!notValid){
							delete obj.include;
							obj.exclude = true
						}
					});
					this.setState({
						tableKey : (this.state.tableKey + 1)%1000,
						stats : this.calculateAllStats()
					},()=>this.updateCfAndCppByStats())
				}
			},
		]

		let columns = [];
		columns = columns.concat([
			{ title: "Anno", field: "year", headerSort: true, contextMenu: dateContextMenu },
			{
				title: "1 ora", field: "HOUR_1", headerSort: false,
				formatter: (cell) => this.cellFormatter(cell),
				bottomCalcFormatter: this.bottomCalcFormatter,
				bottomCalc: (values)=>this.getStats(values),
				contextMenu: valueContextMenu
			},
			{
				title: "3 ore", field: "HOUR_3", headerSort: false,
				formatter:  (cell) => this.cellFormatter(cell),
				bottomCalcFormatter: this.bottomCalcFormatter,
				bottomCalc: (values)=>this.getStats(values),
				contextMenu: valueContextMenu
			},
			{
				title: "6 ore", field: "HOUR_6", headerSort: false,
				formatter:  (cell) => this.cellFormatter(cell),
				bottomCalcFormatter: this.bottomCalcFormatter,
				bottomCalc: (values)=>this.getStats(values),
				contextMenu: valueContextMenu
			},
			{
				title: "12 ore", field: "HOUR_12", headerSort: false,
				formatter:  (cell) => this.cellFormatter(cell),
				bottomCalcFormatter: this.bottomCalcFormatter,
				bottomCalc: (values)=>this.getStats(values),
				contextMenu: valueContextMenu
			},
			{
				title: "24 ore", field: "HOUR_24", headerSort: false,
				formatter:  (cell) => this.cellFormatter(cell),
				bottomCalcFormatter: this.bottomCalcFormatter,
				bottomCalc: (values)=>this.getStats(values),
				contextMenu: valueContextMenu
			}
		]);
		return columns;

	}



	getMaxHistoryTableOptions() {
		let tabulatorOptions = {
			data: [],
			height: "80vh"
		}
		return tabulatorOptions;
	}

	setCppTempiDiRitorno(val,stationCode){
		RainStatsClient.getCppByStationCodeAndDistribution(
			stationCode,
			this.state.distribution,
			[val],
			this.state.stats,
			(cppParameters) => {
				this.setState({
					cppParameters,
					selectedCppTempoDiRitorno : val,
					chartKey : (this.state.chartKey+1)%1000

				})
			},
			() => { console.log("ERROR distributionParameters"); }
		);
	}

	resetCppTempiDiRitorno(stationCode){
		RainStatsClient.getCppByStationCodeAndDistribution(
			stationCode,
			this.state.distribution,
			[10.0, 20.0, 50.0, 100.0],
			this.state.stats,
			(cppParameters) => {
				this.setState({
					cppParameters,
					selectedCppTempoDiRitorno : "",
					chartKey : (this.state.chartKey+1)%1000

				})
			},
			() => { console.log("ERROR distributionParameters"); }
		);
	}

	onDistributionChange(value){
		this.setState({
			distribution:value,
			calculatorKey : (this.state.calculatorKey+1)%1000
		},()=>{
			if(!!this.state.selectedStation.value){
				this.computeCfAndCpp(this.state.selectedStation.value)
			}
		})
	}

	render() {



		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className=" mt-4">

						<div className="row">
							<div className="col-8" >
								<div>
									<LabelledOutline label="Pluviometro">
										<div className="d-flex justify-content-between">
											<h3 style={{ margin: 0 }}>{!!this.state.selectedStation.value ? this.state.selectedStation.label + " (" + this.state.selectedStation.value + ")" : ""}</h3>
											<Tooltip title={"Scegli Stazione"}>
												<Button
													className="justify-content-end ml-auto"
													variant="contained"
													color="primary"
													startIcon={<></>}
													onClick={(e) => this.openStationsModal()}
												>Scegli pluviometro</Button>
											</Tooltip>
											<FormControl className="ml-4" component="fieldset" >
												<FormLabel component="legend">Distribuzione</FormLabel>
												<RadioGroup row={true} aria-label="gender" name="gender1" value={this.state.distribution}
															onChange={(event) => this.onDistributionChange(event.target.value)}>
													<FormControlLabel value="GUMBEL" control={<Radio />} label="GUMBEL" />
													<FormControlLabel value="TCEV" control={<Radio />} label="TCEV" />
												</RadioGroup>
											</FormControl>
										</div>

									</LabelledOutline>

								</div>
								<div className="row">
									<div className="col-6">
										{!!this.state.sensorCode && !this.state.loading
											?
											<div style={{ height: "50vh" }}>
												<ReactApexChart options={this.buildCfChartOptions()} series={this.buildCfSeries()} type="line" height={"100%"} key={"cf_" + this.state.chartKey} />
											</div>
											:
											<Skeleton height={"50vh"} duration={8} />
										}

									</div>
									<div className="col-6">
										{!!this.state.sensorCode && !this.state.loading && !!this.state.selectedStation.value
											?
											<>
												<div className="d-flex justify-content-between ">
													<TextField
														fullWidth
														id="tr"
														name="tr"
														label="Tempo Di Ritorno"
														type="number"
														value={this.state.selectedCppTempoDiRitorno}
														onChange={(e) => { this.setCppTempiDiRitorno(e.target.value,this.state.selectedStation.value)}}
													/>
													<Tooltip title={"Reset Tempo Di Ritorno Selezionato"}>
														<IconButton color="primary" aria-label="upload picture" component="span"
																	onClick={(e) => { this.resetCppTempiDiRitorno(this.state.selectedStation.value) }}
																	disabled = {!!!this.state.selectedCppTempoDiRitorno}
														>

															<DeleteIcon />
														</IconButton>
													</Tooltip>
													<Tooltip title={"Reset Tempo Di Ritorno Selezionato"}>
														<IconButton color="primary" aria-label="upload picture" component="span"
																	onClick={(e) => { this.setState({showDetailModal:true}) }}
																	disabled={!this.state.cppParameters}
														>
															<GoInfo />
														</IconButton>
													</Tooltip>
												</div>

												<div style={{ height: "45vh" }}>
													<ReactApexChart options={this.buildCppChartOptions()} series={this.buildCppSeries()} type="line" height={"100%"} key={"cpp_" + this.state.chartKey} />
												</div>
											</>
											:
											<>
												<Skeleton height={"5vh"} duration={8} />
												<Skeleton height={"45vh"} duration={8} />
											</>
										}
									</div>
								</div>
								<div>
									{!!this.state.sensorCode && !this.state.loading
										?
										<HeightCalculatorComponent key={"h_"+this.state.calculatorKey} distibutionType={this.state.distribution} distributionParameters={this.state.distributionParameters.reduce((obj, params) => { obj[params.scope] = params; return obj }, {})} className="mt-2" />
										:
										<Skeleton height={"10vh"} duration={8} />
									}
								</div>
								<div>
									{!!this.state.sensorCode && !this.state.loading
										?
										<TempoDiRitornoCalculatorComponent key={"t_"+this.state.calculatorKey} distibutionType={this.state.distribution} distributionParameters={this.state.distributionParameters.reduce((obj, params) => { obj[params.scope] = params; return obj }, {})} className="mt-2" />
										:
										<Skeleton height={"10vh"} duration={8} />
									}
								</div>
							</div>
							<div className="col-4" >
								<ReactTabulator
									columns={this.getMaxHistoryColumns()}
									data={this.state.maxValuesData}
									options={this.getMaxHistoryTableOptions()}
									key={"warnings_" + this.state.tableKey}
								/>
							</div>
						</div>

					</div>

				</div>


				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showStationsModal}
					onClose={() => this.onCloseStationsModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						className="p-2"
						style={{
							background: "white",
							width: "40vw",
							height: "85vh",
							borderRadius: "15px"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseStationsModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3>Scelta Stazione</h3>
						</div>

						<div>
							<StationGisPicker
								centerMapOnSelect={true}
								geoJSON={this.state.geoJSON}
								zoneOptions={this.state.zoneOptions}
								selectedZone={this.state.selectedZone}
								onSelectZone={(option) => this.onSelectZone(option)}
								stationOptions={this.state.stationOptions}
								selectedStation={this.state.selectedStation}
								onSelectStation={(option) => this.onSelectStation(option)}
								mapKey={this.state.mapKey}
								onEachStation={(feature, layer) => this.onEachStation(feature, layer)}
								stationToMarker={(feature, latlng) => this.stationToMarker(feature, latlng)}
								zonesStyle={(feature) => this.zonesStyle(feature)}
								mapHeight={"60vh"}
							></StationGisPicker>
						</div>
					</div>
				</Modal>
				<Modal key={'detailModal'}
					   style={{
						   display: 'flex',
						   alignItems: 'center',
						   justifyContent: 'center'
					   }}
					   open={this.state.showDetailModal}
					   onClose={() => {this.setState({showDetailModal: false})}}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "70vh",
							borderRadius: '15px'
						}}

					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close"
										onClick={() => {this.setState({showDetailModal: false})}}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h2>Dettaglio Parametri</h2>
						</div>

						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<ReactTabulator
									columns={[{
										title: "Tempo di ritorno",
										field: "tempoDiRitorno"
									},{
										title: "Parametro a",
										field: "a"
									},{
										title: "Parametro n",
										field: "n"
									}]}
									data={this.state.cppParameters ? this.state.cppParameters.map(({tempoDiRitorno, a, n}) => ({tempoDiRitorno, a, n})) : []}
									options={{height: "35vh"}}
									key={"table_preview"}
								/>
							</div>
						</div>
						<br/>
					</div>
				</Modal>
			</>
		)

	}

}

class HeightCalculatorComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			duration: '',
			tRitorno: null,
			height: null
		}
	}

	onDurationChange(val) {
		let duration = val;
		let tRitorno = this.state.tRitorno;
		let height = null;
		if (!!tRitorno) {
			let p = this.props.distributionParameters[duration];
			if(this.props.distibutionType==="GUMBEL"){
				height = GumbelCalculator.getHByTempoDiRitorno(p.alfa, p.epsilon, tRitorno);
			}
			else{
				height = TCEVCalculator.getHByTempoDiRitorno(p.lambdaUno, p.tetaUno, p.lambdaStar, p.tetaStar, tRitorno);
			}
		}
		this.setState({
			duration: duration,
			height: height
		})
	}
	onChangeTempoDiRitorno(val) {
		let tRitorno = val;
		let duration = this.state.duration;
		let height = null;
		if (!!duration) {
			let p = this.props.distributionParameters[duration];
			if(this.props.distibutionType==="GUMBEL"){
				height = GumbelCalculator.getHByTempoDiRitorno(p.alfa, p.epsilon, tRitorno);
			}
			else{
				height = TCEVCalculator.getHByTempoDiRitorno(p.lambdaUno, p.tetaUno, p.lambdaStar, p.tetaStar, tRitorno);
			}
		}
		this.setState({
			tRitorno: tRitorno,
			height: height
		})
	}

	render() {
		return (
			<LabelledOutline id="height_calc" label="Calcolatore Altezze">

				<FormGroup row="true">
					<FormControl >
						<InputLabel id="h-helper-label_height_calc">Durata</InputLabel>
						<Select
							style={{ minWidth: "15vw" }}
							labelId="durata"
							id="durata_height_calc"
							value={this.state.duration}
							onChange={(e) => this.onDurationChange(e.target.value)}
						>
							<MenuItem value={"HOUR_1"}>1 ora</MenuItem>
							<MenuItem value={"HOUR_3"}>3 ore</MenuItem>
							<MenuItem value={"HOUR_6"}>6 ore</MenuItem>
							<MenuItem value={"HOUR_12"}>12 ore</MenuItem>
							<MenuItem value={"HOUR_24"}>24 ore</MenuItem>
						</Select>
					</FormControl>
					<TextField
						style={{ marginLeft: "5vw" }}
						width={"15vw"}
						id="tr_height_calc"
						name="tr"
						label="Tempo Di Ritorno"
						type="number"
						value={this.state.tRitorno}
						onChange={(e) => { this.onChangeTempoDiRitorno(e.target.value) }}
					/>
					<TextField
						style={{ marginLeft: "5vw" }}
						id="h_height_calc"
						label="Altezza"
						defaultValue=" "
						value={this.state.height}
						InputProps={{
							readOnly: true,
						}}
					/>

				</FormGroup>


			</LabelledOutline>
		)
	}

}


class TempoDiRitornoCalculatorComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			duration: '',
			tRitorno: null,
			height: null
		}
	}

	onDurationChange(val) {
		let duration = val;
		let height = this.state.height;
		let tRitorno = null;
		if (!!height) {
			let p = this.props.distributionParameters[duration];
			if(this.props.distibutionType==="GUMBEL"){
				tRitorno = GumbelCalculator.getTempoDiRitornoByH(p.alfa, p.epsilon, height);
			}
			else{
				tRitorno = TCEVCalculator.getTempoDiRitornoByH(p.lambdaUno, p.tetaUno, p.lambdaStar, p.tetaStar, height);

			}
		}
		this.setState({
			duration: duration,
			tRitorno: tRitorno
		})
	}
	onChangeH(val) {
		let height = val;
		let duration = this.state.duration;
		let tRitorno = null;
		if (!!duration) {
			let p = this.props.distributionParameters[duration];
			if(this.props.distibutionType==="GUMBEL"){
				tRitorno = GumbelCalculator.getTempoDiRitornoByH(p.alfa, p.epsilon, height);
			}
			else{
				tRitorno = TCEVCalculator.getTempoDiRitornoByH(p.lambdaUno, p.tetaUno, p.lambdaStar, p.tetaStar, height);
			}
		}
		this.setState({
			tRitorno: tRitorno,
			height: height
		})
	}

	render() {
		return (
			<LabelledOutline id="tr_calc" label="Calcolatore Tempi Di Ritorno">

				<FormGroup row="true">
					<FormControl >
						<InputLabel id="h-helper-label">Durata</InputLabel>
						<Select
							style={{ minWidth: "15vw" }}
							labelId="durata"
							id="durata_tr_calc"
							value={this.state.duration}
							onChange={(e) => this.onDurationChange(e.target.value)}
						>
							<MenuItem value={"HOUR_1"}>1 ora</MenuItem>
							<MenuItem value={"HOUR_3"}>3 ore</MenuItem>
							<MenuItem value={"HOUR_6"}>6 ore</MenuItem>
							<MenuItem value={"HOUR_12"}>12 ore</MenuItem>
							<MenuItem value={"HOUR_24"}>24 ore</MenuItem>
						</Select>
					</FormControl>
					<TextField
						style={{ marginLeft: "5vw" }}
						width={"15vw"}
						id="tr_tr_calc"
						name="tr"
						label="Altezza"
						type="number"
						value={this.state.height}
						onChange={(e) => { this.onChangeH(e.target.value) }}
					/>
					<TextField
						style={{ marginLeft: "5vw" }}
						id="tr_tr_calc"
						label="Tempo Di Ritorno"
						defaultValue=" "
						value={this.state.tRitorno}
						InputProps={{
							readOnly: true,
						}}
					/>

				</FormGroup>


			</LabelledOutline>
		)
	}

}

