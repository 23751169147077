import React from 'react';


export default class ForesidePage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			src: window.CAE_CONFIG.foreside_src
		};
	}
	
	render() {
		return (
			<iframe frameborder="0" src={this.state.src} className='p-0 generic-page' title='Foresidercalab'></iframe>
		)
	}
}