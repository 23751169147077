import React from 'react'
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {ReactTabulator} from "react-tabulator";

import MeasurementsDataClient from "#/lib/MeasurementsDataClient";


const colors = {
	VALID : "rgba(16,187,34,0.8)",
	SUSPECT: "rgba(234,129,49,0.8)",
	NOT_VALID: "rgba(241,46,2,0.8)",
	NOT_VALIDATED: "rgba(239,221,61,0.8)",
	RECONSTRUCTED: "rgba(126,197,219,0.8)",
	MISSING: "#ff00008a",
	NONE: "rgb(255,255,255)"
}

const validationOptions = {
	VALID : "Dato corretto",
	SUSPECT: "Dato sospetto",
	NOT_VALID: "Dato errato",
	NOT_VALIDATED: "Dato non validato",
	RECONSTRUCTED: "Dato ricostruito",
	MISSING: "Dato mancante",
	NONE: "none"
}

export default class IntervalStatsTable extends React.Component {


	constructor(props){
		super(props);

		this.state = {
			data : [],
			tableKey : 1
		}
	}

	componentDidMount(){
		this.getStats()
	}

	formatData(stats){
		let data = [];
		data.push(
			{
				key: "# Misure Attese",
				value : stats.expectedMeasurements
			},
			{
				key: "# Misure Acquisite",
				value : stats.foundMeasurements
			},
			{
				key: `# Misure ${validationOptions["NONE"]}`,
				value : stats.numByValidation["NONE"] || 0,
				color : colors["NONE"]
			},
			{
				key: `# Misure ${validationOptions["NOT_VALIDATED"]}`,
				value : stats.numByValidation["NOT_VALIDATED"] || 0,
				color : colors["NOT_VALIDATED"]
			},
			{
				key: `# Misure ${validationOptions["SUSPECT"]}`,
				value : stats.numByValidation["SUSPECT"] || 0,
				color : colors["SUSPECT"]
			},
			{
				key: `# Misure ${validationOptions["RECONSTRUCTED"]}`,
				value : stats.numByValidation["RECONSTRUCTED"] || 0,
				color : colors["RECONSTRUCTED"]
			},
			{
				key: `# Misure ${validationOptions["MISSING"]}`,
				value : stats.numByValidation["MISSING"] || 0,
				color : colors["MISSING"]
			},
			{
				key: `# Misure ${validationOptions["NOT_VALID"]}`,
				value : stats.numByValidation["NOT_VALID"] || 0,
				color : colors["NOT_VALID"]
			},
			{
				key: `# Misure ${validationOptions["VALID"]}`,
				value : stats.numByValidation["VALID"] || 0,
				color : colors["VALID"]
			}


		)
		return data;
	}

	getStats(){
		if(!this.props.stats){
			let params = {};
			params.start = this.props.interval.start;
			params.end = this.props.interval.end;
			params.code = this.props.sensorCode;
			
			MeasurementsDataClient.getIntervalStats(
				(stats) =>{
					let data = this.formatData(stats);
					this.setState({
						data,
						tableKey : (this.state.tableKey + 1)%1000
					})
				},
				function (msg) {
					console.log("Si è verificato un errore inaspettato");
				},
				params,
			);
		}
		else{
			let data = this.formatData(this.props.stats);
			this.setState({
				data,
				tableKey : (this.state.tableKey + 1)%1000
			})
		}
		
		
		
	}

	getTabulatorOptions(){
		let tabulatorOptions = {
			height:this.props.height,
		}
		return tabulatorOptions;
	}


	getColumns(){
		let columns =   [
			{
				title: "",
				field: "key",
			},
			{
				title: "#",
				field: "value",
				formatter: (cell) => {
					if(!!cell.getRow().getData().color && !!cell.getValue()){
						cell.getElement().style.backgroundColor = cell.getRow().getData().color
					}
					return cell.getValue() ;
				},
			}
			
		];

		return columns;

	}




	render(){
		return(<>

				<ReactTabulator
					key={this.state.tableKey}
					columns={this.getColumns()}
					data={this.state.data}
					options={this.getTabulatorOptions()}
				/>
			</>
		)
	}

}
