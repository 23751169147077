import React from "react";

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';
import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton} from "#/commons/components/forms/NextButton";
import {ForecastMultizoneMeteoForm} from "#/backoffice/forecast/components/ForecastMultizoneMeteoForm";
import MapWeatherFormComponent from "#/commons/map/MapWeatherFormComponent";

export const StepPrevisioniMeteoToday = (props) => {
  const formData = props.formData;
  const map = !!props.maps && props.maps.length > 0 ? props.maps[0] : null;
  const todayDate = !!formData && !!formData.today && !!formData.today.date_short ? formData.today.date_short : '';
  const currentDay = "today";

  const handleNextStep = () => {
      props.handleNext();
  }

  return (
      <>
        <div className="row justify-content-center">
          <h2>{props.stepLabel + ' ' + todayDate}</h2>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={4}>
              {!!map &&
                <MapWeatherFormComponent
                  id={map.mapId}
                  key={'today' + map.mapKey}
                  geoJSON={map.shapesGeoJSON}
                  width={map.width || "25vw"}
                  height={map.height || "60vh"}
                  mapKey={map.mapKey}
                  currentDay={'today'}
                  getColorById={(id) => {return null}}
                  onDragEnd={(key,latlng) => props.onDragEnd('today',key, latlng)}
                  onRightClick={(key) => props.onRightClick('today',key)}
                  onDoubleClick={(key) => props.onDoppioClick('today',key)}
                />
              }
          </Grid>
          <Grid item xs={8}>
            {!!map &&
              <ForecastMultizoneMeteoForm
                viewMode={props.viewMode}
                labelForm={map.labelForm + todayDate}
                zones={map.zones}
                currentDay={currentDay}
                onFormChange={props.onFormChange}
                formData={formData.today}
                onIconSelected={iconKey => props.onIconSelected('today',iconKey)}
              />
            }
          </Grid>
        </Grid>
        <MobileStepper
          style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
          position="static"
          variant="text"
          activeStep={props.activeStep}
          steps={props.totalSteps}
          nextButton={
              <NextButton
                variant="contained"
                color="primary"
                onClick={handleNextStep}
                textbutton={props.textNextButton}
              />
          }
          backButton={
              <BackButton
                variant="contained"
                disabled={props.disableBackButton}
                onClick={props.handleBack}
              />
          }
        />
      </>
    );
  };
