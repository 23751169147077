import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';
import Skeleton from "react-loading-skeleton";

const pageShown = 5;

class PaginationComponent extends React.Component {

    constructor(props){
        super(props)
        this.state = {
			currentPage : 1,
			totalPage : 1,
			objects : [],
			startDateFilter : null,
			endDateFilter : null

		};
		
    }



    componentDidMount(){
        this.internalgetPage(1);
    }

	internalgetPage(page){
		this.setState({
			objects : []
		},()=>{
			this.props.getPage(
				(data)=>{this.receivedPageCallback(data,page)},
				(err)=>{this.receivedErrorCallback(err)},
				page,
				this.state.startDateFilter,
				this.state.endDateFilter,
			);
		})
		
	}

    receivedPageCallback = (data, page) => {
        this.setState({
            currentPage: page,
            totalPage: data.last_page,
            objects: data.data
        })
    }

    receivedErrorCallback = (err) => {
        console.log(err)
    }

    getAvailablePageNumber = () => {
        let pages = [];
        if(this.state.totalPage<=pageShown){
            for (let i = 1; i <= this.state.totalPage; i++) {
                pages.push(i);
            }
            return pages;
        }
        else{
            pages.push(this.state.currentPage);
            let last = -1;
            let min=this.state.currentPage;
            let max=this.state.currentPage;
            while(pages.length<pageShown){
                if(last<0){ // aggiungi a sinistra
                    min--;
                    if(min >= 1){
                        pages.push(min);
                    }
                }
                else{ // aggiungi a destra
                    max++;
                    if(max <= this.state.totalPage){
                        pages.push(max);
                    }
                }
                last= -last
            }
            return pages.sort((a, b) => a - b);
        }


    }

	handleChangeDates(dates) {
		let start, end;
		if (!!dates){
			start = dates[0].getTime();
			end = dates[1].getTime();
		} 

		this.setState({
			startDateFilter : start,
			endDateFilter: end,
			currentPage : 1
		},()=>{
			this.internalgetPage(1);
		})
	}

	buildStubComponent(){
		return [1,2,3,4,5].map((element) => 
			<span>element{element}</span>
		) 
	}


    render(){

        const linkpagelist = this.getAvailablePageNumber().map((element)=><li className="page-item" key={element}><span style={{cursor:"pointer"}} className="page-link" onClick={(e)=>{e.preventDefault(); this.internalgetPage(element)}} >{element}</span></li>)

        const lista = undefined !== this.state.objects || this.state.objects.length!==0 ? this.state.objects.map((element) => this.props.toItemFunction(element)) : this.buildStubComponent();
        let prevDisabled = this.state.currentPage===1;
        let nextDisabled = this.state.currentPage===this.state.totalPage;
        return (

            <div className={!!this.props.className ? this.props.className : "mt-4 container"}>
				
				{this.props.enableDateFilter && 
				
					<div className="d-flex justify-content-center mb-4" >
						<span className="my-auto" style={{color: "#3fb262",  fontFamily: 'Roboto Slab', fontSize:"1rem", fontWeight: "500"}}>Filtra per data:&nbsp;&nbsp;</span>
						<DateRangePicker
							locale={"it-IT"}
							onChange={(dates) => this.handleChangeDates(dates)}
							value={[this.state.startDateFilter && new Date(this.state.startDateFilter), this.state.endDateFilter && new Date(this.state.endDateFilter)]}
							maxDate={new Date()}
							format={"dd/MM/yyyy"}
							rangeDivider='&nbsp;&nbsp;&nbsp;'
						/>
						
					</div>
				}
                <Accordion>
                    {lista}
                </Accordion>
                <br></br>

                <br/>
                <nav className="link-text" aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <li className={"page-item "+ (prevDisabled ? "disabled" : "")} >
                            <span className="page-link" style={{cursor:"pointer"}} onClick={(e)=>{e.preventDefault();this.internalgetPage(this.state.currentPage-1)}} tabIndex="-1">Previous</span>
                        </li>
                        {linkpagelist}
                        <li className={"page-item "+ (nextDisabled ? "disabled" : "")}>
                            <span className="page-link" style={{cursor:"pointer"}} onClick={(e)=>{e.preventDefault();this.internalgetPage(this.state.currentPage-1)}}>Next</span>
                        </li>
                    </ul>
                </nav>
                <br/>



            </div>
        );
    }

}

export default PaginationComponent;

// const DATI_MOCK = [
//     {
//         "id": 3092,
//         "documentTypeId": 10,
//         "bulletinNumber": null,
//         "documentTypeName": "FORECAST",
//         "trigger": "NEW",
//         "filesFolderPath": null,
//         "documentWebTemplateCode": null,
//         "dateStart": null,
//         "dateEnd": null,
//         "jsonData": {
//             "geoJson": {
//                 "type": "FeatureCollection",
//                 "name": "zone",
//                 "crs": {
//                     "type": "name",
//                     "properties": {
//                         "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                     }
//                 },
//                 "features": [
//                     {
//                         "type": "Feature",
//                         "properties": {
//                             "ZONE": 1
//                         },
//                         "geometry": {
//                             "type": "MultiPolygon",
//                             "coordinates": [
//                                 [
//                                     [
//                                         [
//                                             16.239884850339113,
//                                             39.57325295431402
//                                         ],
//                                         [
//                                             16.238362610158273,
//                                             39.57145891591384
//                                         ],
//                                         [
//                                             16.236201741636034,
//                                             39.57166166800958
//                                         ],
//                                     ]
//                                 ]
//                             ]
//                         }
//                     },
//                 ]
//             },
//             "img_empty": "",
//             "today": {
//                 "date_short": "26/11/2024",
//                 "hour_start": "07:47",
//                 "hour_end": "23:59",
//                 "meteoByZone": [],
//                 "fields": {
//                     "situazione": "Il movimento verso levante della tempesta Bert che sta caratterizzando il tempo meteorologico dell'Europa occidentale influenzerà con la sua coda anche l'Italia, comportando tempo perturbato sulle regioni nord-occidentali e sul versante tirrenico della Campania. Ciononostante, sulla Calabria sono garantite condizioni di tempo prevalentemente stabile, complice l'estensione di un promontorio di matrice sub-tropicale fino alle nostre latitudini.",
//                     "cielo": "Da nuvoloso a nuvoloso sui settori tirrenici e sulle aree interne, sereno o poco nuvoloso altrove.",
//                     "precipitazioni": "Possibili precipitazioni di lieve entità sulla catena costiera e sulla Sila Greca nelle ore centrali del giorno.",
//                     "temperature": "In lieve aumento sia nei valori massimi che nei valori minimi.",
//                     "venti": "I venti spireranno con intensità di brezza o brezza tesa dai quadranti settentrionali sulle coste tirreniche, dai quadranti meridionali sulle coste ioniche. Venti di brezza tesa di direzione variabile sono attesi delle aree interne.",
//                     "mari": "Poco mosso sia il Tirreno che lo Ionio."
//                 }
//             },
//             "tomorrow": {
//                 "date_short": "27/11/2024",
//                 "hour_start": "00:00",
//                 "hour_end": "23:59",
//                 "meteoByZone": [],
//                 "fields": {
//                     "situazione": "L'ingresso sull'Italia di un fronte freddo di origine atlantica comporterà, sulla nostra regione, un incremento della nuvolosità. Tuttavia, flussi meridionali caldi e umidi porteranno ad un ulteriore incremento delle temperature, specie nelle aree interne.",
//                     "cielo": "Irregolarmente nuvoloso sui settori tirrenici del cosentino, sui rilievi dell'Aspromonte e della Sila Greca. Cielo velato altrove. A partire dal pomeriggio, nubi alte in estensione su tutta la regione renderanno il cielo irregolarmente nuvoloso anche sui versanti ionici della Calabria centrale e, localmente, sull'Istmo di Catanzaro.",
//                     "precipitazioni": "Non sono previste precipitazioni di carattere rilevante.",
//                     "temperature": "Generalmente in aumento. Valori massimi in aumento specialmente nelle aree interne. ",
//                     "venti": "La ventilazione risulterà debole sulle fasce costiere. Da nord sui settori tirrenici, da sud sui settori ionici. Da molto debole ad assente di direzione variabile altrove.",
//                     "mari": "Poco mosso il Tirreno, quasi calmo sotto costa e poco mosso al largo lo Ionio."
//                 }
//             },
//             "maps": {
//                 "today": {
//                     "type": "FeatureCollection",
//                     "name": "zone",
//                     "crs": {
//                         "type": "name",
//                         "properties": {
//                             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                         }
//                     },
//                     "features": [
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "ZONE": 1
//                             },
//                             "geometry": {
//                                 "type": "MultiPolygon",
//                                 "coordinates": [
//                                     [
//                                         [
//                                             [
//                                                 16.239884850339113,
//                                                 39.57325295431402
//                                             ],
//                                             [
//                                                 16.238362610158273,
//                                                 39.57145891591384
//                                             ],
//                                             [
//                                                 16.236201741636034,
//                                                 39.57166166800958
//                                             ],
//                                         ]
//                                     ]
//                                 ]
//                             }
//                         },
//                     ]
//                 },
//                 "tomorrow": {
//                     "type": "FeatureCollection",
//                     "name": "zone",
//                     "crs": {
//                         "type": "name",
//                         "properties": {
//                             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                         }
//                     },
//                     "features": [
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "ZONE": 1
//                             },
//                             "geometry": {
//                                 "type": "MultiPolygon",
//                                 "coordinates": [
//                                     [
//                                         [
//                                             [
//                                                 16.239884850339113,
//                                                 39.57325295431402
//                                             ],
//                                             [
//                                                 16.238362610158273,
//                                                 39.57145891591384
//                                             ],
//                                             [
//                                                 16.236201741636034,
//                                                 39.57166166800958
//                                             ],
//                                         ]
//                                     ]
//                                 ]
//                             }
//                         },
//                     ]
//                 }
//             }
//         },
//         "year": 2024,
//         "month": 11,
//         "day": 26,
//         "approved": true,
//         "published": true,
//         "outdated": false,
//         "protocol": null,
//         "referenceId": 3092,
//         "editor": "s.veltri",
//         "createdAt": 1732612131967,
//         "updatedAt": 1732612331298,
//         "isEditable": true,
//         "isDeletable": true
//     },
//     {
//         "id": 3086,
//         "documentTypeId": 10,
//         "bulletinNumber": null,
//         "documentTypeName": "FORECAST",
//         "trigger": "NEW",
//         "filesFolderPath": null,
//         "documentWebTemplateCode": null,
//         "dateStart": null,
//         "dateEnd": null,
//         "jsonData": {
//             "geoJson": {
//                 "type": "FeatureCollection",
//                 "name": "zone",
//                 "crs": {
//                     "type": "name",
//                     "properties": {
//                         "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                     }
//                 },
//                 "features": [
//                     {
//                         "type": "Feature",
//                         "properties": {
//                             "ZONE": 1
//                         },
//                         "geometry": {
//                             "type": "MultiPolygon",
//                             "coordinates": [
//                                 [
//                                     [
//                                                                                     [
//                                             16.239884850339113,
//                                             39.57325295431402
//                                         ],
//                                         [
//                                             16.238362610158273,
//                                             39.57145891591384
//                                         ],
//                                         [
//                                             16.236201741636034,
//                                             39.57166166800958
//                                         ],
//                                     ]
//                                 ]
//                             ]
//                         }
//                     },
//                 ]
//             },
//             "img_empty": "",
//             "today": {
//                 "date_short": "25/11/2024",
//                 "hour_start": "08:34",
//                 "hour_end": "23:59",
//                 "meteoByZone": [],
//                 "fields": {
//                     "situazione": "La nostra regione è interessata da un campo di alta pressione che dal continente africano si estende sul Mediterraneo centrale favorendo condizioni di stabilità.",
//                     "cielo": "Sereno, dalla serata poco nuvoloso sui settori tirrenici e rilievi.",
//                     "precipitazioni": "Assenti.",
//                     "temperature": "In leggero rialzo sia nei valori massimi che in quelli minimi.",
//                     "venti": "Da nord di brezza sui settori tirrenici e aree interne. Da nord-ovest di brezza sui settori ionici. Sui rilievi di brezza tesa dai quadranti settentrionali.",
//                     "mari": "Poco mossi sia lo Ionio che il Tirreno."
//                 }
//             },
//             "tomorrow": {
//                 "date_short": "26/11/2024",
//                 "hour_start": "00:00",
//                 "hour_end": "23:59",
//                 "meteoByZone": [],
//                 "fields": {
//                     "situazione": "Lo spostamento verso Est della saccatura presente sul Nord Europa determina un lieve indebolimento del campo di alta pressione sull'Italia settentrionale, tuttavia sulla nostra Regione la pressione si mantiene alta con conseguenti condizioni di stabilità.",
//                     "cielo": "Nuvoloso sui settori tirrenici e rilievi.",
//                     "precipitazioni": "Possibili precipitazioni di intensità debole sui settori tirrenici e rilievi.",
//                     "temperature": "In rialzo sia nei valori massimi che in quelli minimi.",
//                     "venti": "Di bava di vento di direzione variabile sui settori tirrenici, tesi sui rilievi di direzione variabile, di brezza da nord-ovest sui settori ionici.",
//                     "mari": "Poco mossi sia lo Ionio che il Tirreno."
//                 }
//             },
//             "maps": {
//                 "today": {
//                     "type": "FeatureCollection",
//                     "name": "zone",
//                     "crs": {
//                         "type": "name",
//                         "properties": {
//                             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                         }
//                     },
//                     "features": [
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "ZONE": 1
//                             },
//                             "geometry": {
//                                 "type": "MultiPolygon",
//                                 "coordinates": [
//                                     [
//                                         [
//                                             [
//                                                 16.239884850339113,
//                                                 39.57325295431402
//                                             ],
//                                             [
//                                                 16.238362610158273,
//                                                 39.57145891591384
//                                             ],
//                                             [
//                                                 16.236201741636034,
//                                                 39.57166166800958
//                                             ],
//                                         ]
//                                     ]
//                                 ]
//                             }
//                         },
//                     ]
//                 },
//                 "tomorrow": {
//                     "type": "FeatureCollection",
//                     "name": "zone",
//                     "crs": {
//                         "type": "name",
//                         "properties": {
//                             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                         }
//                     },
//                     "features": [
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "ZONE": 1
//                             },
//                             "geometry": {
//                                 "type": "MultiPolygon",
//                                 "coordinates": [
//                                     [
//                                         [
//                                             [
//                                                 16.239884850339113,
//                                                 39.57325295431402
//                                             ],
//                                             [
//                                                 16.238362610158273,
//                                                 39.57145891591384
//                                             ],
//                                             [
//                                                 16.236201741636034,
//                                                 39.57166166800958
//                                             ],
//                                         ]
//                                     ]
//                                 ]
//                             }
//                         },
//                     ]
//                 }
//             }
//         },
//         "year": 2024,
//         "month": 11,
//         "day": 25,
//         "approved": true,
//         "published": true,
//         "outdated": false,
//         "protocol": null,
//         "referenceId": 3086,
//         "editor": "sa.bloise",
//         "createdAt": 1732524113762,
//         "updatedAt": 1732524196917,
//         "isEditable": true,
//         "isDeletable": true
//     },
//     {
//         "id": 3083,
//         "documentTypeId": 10,
//         "bulletinNumber": null,
//         "documentTypeName": "FORECAST",
//         "trigger": "NEW",
//         "filesFolderPath": null,
//         "documentWebTemplateCode": null,
//         "dateStart": null,
//         "dateEnd": null,
//         "jsonData": {
//             "geoJson": {
//                 "type": "FeatureCollection",
//                 "name": "zone",
//                 "crs": {
//                     "type": "name",
//                     "properties": {
//                         "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                     }
//                 },
//                 "features": [
//                     {
//                         "type": "Feature",
//                         "properties": {
//                             "ZONE": 1
//                         },
//                         "geometry": {
//                             "type": "MultiPolygon",
//                             "coordinates": [
//                                 [
//                                     [
//                                         [
//                                             16.239884850339113,
//                                             39.57325295431402
//                                         ],
//                                         [
//                                             16.238362610158273,
//                                             39.57145891591384
//                                         ],
//                                         [
//                                             16.236201741636034,
//                                             39.57166166800958
//                                         ],
//                                     ]
//                                 ]
//                             ]
//                         }
//                     },
//                 ]
//             },
//             "img_empty": "",
//             "today": {
//                 "date_short": "22/11/2024",
//                 "hour_start": "16:32",
//                 "hour_end": "23:59",
//                 "meteoByZone": [],
//                 "fields": {
//                     "situazione": "Una vasta area di alta pressione, si posiziona sull'Europa centro - meridionale. ",
//                     "cielo": "Generalmente sereno.",
//                     "precipitazioni": "Assenti o non rilevanti.",
//                     "temperature": "Massime in diminuzione.",
//                     "venti": "Forti dai quadranti settentrionali, in generale attenuazione dalla serata.",
//                     "mari": "Agitato lo Ionio, Molto mossi gli altri. Moto ondoso in attenuazione dalla serata."
//                 }
//             },
//             "tomorrow": {
//                 "date_short": "23/11/2024",
//                 "hour_start": "00:00",
//                 "hour_end": "23:59",
//                 "meteoByZone": [],
//                 "fields": {
//                     "situazione": "La vasta area di alta pressione, posiziona sull'Europa centro - meridionale, determina tempo stabile e soleggiato. ",
//                     "cielo": "Generalmente sereno.",
//                     "precipitazioni": "Assenti o non rilevanti.",
//                     "temperature": "Massime in aumento, minime in diminuzione.",
//                     "venti": "Nessun fenomeno significativo.",
//                     "mari": "Localmente mosso lo ionio, con moto ondoso in attenuazione."
//                 }
//             },
//             "maps": {
//                 "today": {
//                     "type": "FeatureCollection",
//                     "name": "zone",
//                     "crs": {
//                         "type": "name",
//                         "properties": {
//                             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                         }
//                     },
//                     "features": [
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "ZONE": 1
//                             },
//                             "geometry": {
//                                 "type": "MultiPolygon",
//                                 "coordinates": [
//                                     [
//                                         [
//                                             [
//                                                 16.239884850339113,
//                                                 39.57325295431402
//                                             ],
//                                             [
//                                                 16.238362610158273,
//                                                 39.57145891591384
//                                             ],
//                                             [
//                                                 16.236201741636034,
//                                                 39.57166166800958
//                                             ],
//                                         ]
//                                     ]
//                                 ]
//                             }
//                         },
//                     ]
//                 },
//                 "tomorrow": {
//                     "type": "FeatureCollection",
//                     "name": "zone",
//                     "crs": {
//                         "type": "name",
//                         "properties": {
//                             "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
//                         }
//                     },
//                     "features": [
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "ZONE": 1
//                             },
//                             "geometry": {
//                                 "type": "MultiPolygon",
//                                 "coordinates": [
//                                     [
//                                         [
//                                             [
//                                                 16.239884850339113,
//                                                 39.57325295431402
//                                             ],
//                                             [
//                                                 16.238362610158273,
//                                                 39.57145891591384
//                                             ],
//                                             [
//                                                 16.236201741636034,
//                                                 39.57166166800958
//                                             ],
//                                         ]
//                                     ]
//                                 ]
//                             }
//                         },
//                     ]
//                 }
//             }
//         },
//         "year": 2024,
//         "month": 11,
//         "day": 23,
//         "approved": true,
//         "published": true,
//         "outdated": false,
//         "protocol": null,
//         "referenceId": null,
//         "editor": "a.guerriero",
//         "createdAt": 1732362123868,
//         "updatedAt": 1732362144089,
//         "isEditable": true,
//         "isDeletable": true
//     }
// ];