import React from "react";
import {COMBINED_MEASUREMENT_LABELS, MEASUREMENT_LABELS} from "#/lib/MeasurementCategory";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import MidaModalContent from "#/backoffice/mida/components/MidaModalContent";
import CloseIcon from '@material-ui/icons/Close';
import CumulatedPluvsThresholdComponent from "#/backoffice/Monitoring/components/CumulatedPluvsThresholdComponent"
import ReturnTimeThresholdComponent from "#/backoffice/Monitoring/components/ReturnTimeThresholdComponent"

import MeasurementsComponent from "#/commons/data/components/MeasurementsComponent";
import HydroCharts from "#/commons/chart/HydroCharts";
import moment from "moment";
import StationClient from "#/lib/StationClient";
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import {Link} from "react-router-dom";
import SensorClient from "#/lib/SensorClient";


export default class MidaModals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stationsGeoJSON: {},

            showHydroModal: false,
            selectedHydroSensor: '',
            hydroViewInterval: {},
            hydroComponentKey: 1,

            showMeasurementModal: false,
            interval: null,
            selectedStation: {},
            selectedMeasure: {},
            year: moment().year(),
            selectedRange: [],
            originalTimestamp: 0,


        }
    }
    componentDidMount = () => {Promise.all([this.getStationsGeoJsonPromise(), this.getSensorPromise()])
        .then(([stationsGeoJSON, sensors]) => {
            let allSensorsMap = sensors.reduce((obj, item)=> {obj[item.code]=item; return obj}, {});
            this.setState({stationsGeoJSON, allSensorsMap})
        })
    }

    getStationsGeoJsonPromise = () => new Promise((resolve, reject) => {
        StationClient.getStationsGeojson(
            (stationsGeoJSON) => {
                resolve(stationsGeoJSON)
            },
            (err) => {
                console.log(err);
                reject(err)
            }
        )}
    );
    getSensorPromise = () => new Promise((resolve,reject)=>{
        SensorClient.getAllSensors(
            (data) => resolve(data),
            (msg) => reject(msg)
        )
    });

    openHydroModal(sensorCode, timestamp) {
		timestamp = timestamp || moment().valueOf();

        let hydroViewInterval = {
            start: moment(timestamp).add(-1,'days'),
            end: moment(timestamp)
        }
        this.setState({
            showHydroModal: true,
            selectedHydroSensor: sensorCode,
            hydroViewInterval,
            hydroComponentKey : (this.state.hydroComponentKey + 1)%1000
        })
    }
    onCloseModal() {
        this.setState({
            showMeasurementModal : false,
            showHydroModal : false
        })
    }
    openMeasurementModal(name, code, sensorCode, category, timestamp){
		timestamp = timestamp || moment().valueOf();
        let interval = {
            start: moment(timestamp).add(-1,'days'),
            end: moment(timestamp),
        }

        let selectedStation = {
            label : name,
            value : code,
        }
        let sensor = sensorCode instanceof Object ? Object.keys(sensorCode).map(category => ({
            category, sensorCode: sensorCode[category]
        })) : [{
            category : category,
            sensorCode : sensorCode
        }]
        let selectedMeasure = {
            value : category,
            label : {...MEASUREMENT_LABELS, ...COMBINED_MEASUREMENT_LABELS}[category],
            sensor
        }

        this.setState({
            interval,
            selectedStation,
            selectedMeasure,
            originalTimestamp : timestamp,
            showMeasurementModal : true
        })
    }

    onPrevYear() {
        this.setState({
            year: this.state.year - 1
        })
    }
    onNextYear() {
        this.setState({
            year: this.state.year + 1
        })
    }
    onPickRange(start, end, year) {

        let isTillToday = end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
        let isFromToday = start.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
        end = isTillToday ? moment().add(-30, 'm') : end.add(1,'d').startOf('day');
        start = isFromToday ? end.clone().add(-24, 'h') : start.startOf('day');

        let interval = {start, end}

        this.setState({
            year: !!year ? year : this.state.year,
            selectedRange: [start, end],
            interval: interval
        })
    }

	resetInterval(interval) {

		interval = interval ||  {
            start: moment().add(-1,'days'),
            end: moment(),
        }


		this.setState({
			interval,
			selectedRange: !!interval ? [interval.start, interval.end] : [moment(this.state.originalTimestamp).startOf('day'), moment(this.state.originalTimestamp)],
			year: moment().year()
		});
	}


    render(){
		let key = this.state.interval ? JSON.stringify(this.state.interval) :"init" 
        const {showInfoModal, selectedFeature, dataByStationCode, selectedCategory, returnTimeRainThresholds, rainThresholds, cfGumbelParametersMap, cfTCEVParametersMap, showThresholdModal, showReturnTimeThresholdModal, closeThresholdModal, closeReturnTimeThresholdModal, outflowScales, stationSensorThresholds, sensorBlacklist} = this.props;
        const {closeInfoModal, onThresholdsSave, onReturnTimeThresholdsSave} = this.props;
        const {showHydroModal, selectedHydroSensor, hydroViewInterval, hydroComponentKey, showMeasurementModal, interval, selectedStation, selectedMeasure, year, selectedRange, stationsGeoJSON} = this.state;
        return <>
            {/* ----------------------- INFO MODAL ----------------------- */}
            <Modal key={'info'}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'flex-end',
                       marginRight: '5vw'

                   }}
                   open={showInfoModal && selectedFeature}
                   onClose={() => closeInfoModal()}
                   onBackdropClick={() => closeInfoModal()}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        background: "rgba(27,39,50,0.83)",//2c3e50d4
                        color : "#fff",
                        width: "50vw",
                        height: "80vh",
                        padding: '5vh',
                        borderRadius: '15px',
                    }}
                    className="scrollbar scrollbar-primary"
                >
                    <IconButton  aria-label="close" style={{ float: 'right', color: 'white' }} onClick={() => closeInfoModal()}>
                        <CloseIcon/>
                    </IconButton>
                    {!!selectedFeature && dataByStationCode && selectedCategory && rainThresholds &&
                    <MidaModalContent
                        openModal={(name, code, sensorCode, category, timestamp) => category === 'I' ? this.openHydroModal(sensorCode, timestamp) : this.openMeasurementModal(name, code, sensorCode, category, timestamp)}
                        selectedCategory={selectedCategory}
                        feature={selectedFeature}
                        data={dataByStationCode[selectedFeature.properties.code]}
                        rainThresholds={rainThresholds}
                        returnTimeRainThresholds={returnTimeRainThresholds}
                        gumbelMap={cfGumbelParametersMap}
                        TCEVMap={cfTCEVParametersMap}
                        sensorBlacklist={sensorBlacklist}/>}
                </div>
            </Modal>

            {/* ----------------------- THRESHOLDS MODAL ----------------------- */}
            <Modal key={'thresholds'}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}
                   open={showThresholdModal}
                   onClose={() => closeThresholdModal()}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        background: "white",
                        width: "70vw",
                        height: "75vh"
                    }}
                >
                    <div className='w-100 d-flex justify-content-end'>
                        <IconButton  aria-label="close" onClick={() => closeThresholdModal()}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div className="d-flex justify-content-center">
                        <h3>Soglie Piogge Cumulate</h3>
                    </div>
                    <div className="d-flex justify-content-center">
                        Soglie generiche valide per tutti i pluviometri. È possibile modificare le soglie specifiche nella pagina di &nbsp;
                        <Link to={"/backoffice/gridmgr"}>Gestione Sensori</Link>
                    </div>


                    <div className="d-flex h-80">
                        <div className="col-sm-12 my-auto">
                            <CumulatedPluvsThresholdComponent
                                thresholds = {rainThresholds['GENERIC']}
                                saveCallback = {(thresholds)=>onThresholdsSave(thresholds)}
                                sensorCode={'GENERIC'}
                            ></CumulatedPluvsThresholdComponent>
                        </div>
                    </div>
                    <br/>
                </div>

            </Modal>

            {/* ----------------------- RETURN TIME THRESHOLDS MODAL ----------------------- */}
            <Modal key={'rt_thresholds'}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}
                   open={showReturnTimeThresholdModal}
                   onClose={() => closeReturnTimeThresholdModal()}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        background: "white",
                        width: "70vw",
                        height: "75vh"
                    }}
                >
                    <div className='w-100 d-flex justify-content-end'>
                        <IconButton  aria-label="close" onClick={() => closeReturnTimeThresholdModal()}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div className="d-flex justify-content-center">
                        <h3>Soglie Tempi di Ritorno</h3>
                    </div>


                    <div className="d-flex h-80">
                        <div className="col-sm-12 my-auto">
                            <ReturnTimeThresholdComponent
                                thresholds = {returnTimeRainThresholds}
                                saveCallback = {(thresholds)=>onReturnTimeThresholdsSave(thresholds)}
                                sensorCode={'GENERIC'}
                            ></ReturnTimeThresholdComponent>
                        </div>
                    </div>
                    <br/>
                </div>

            </Modal>
            {/* ----------------------- TABLE AND CHARTS MODAL ----------------------- */}
            <Modal key={'measurement'}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center',
                       overflow:'scroll',
                   }}
                   open={showMeasurementModal}
                   onClose={() => this.onCloseModal()}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        background: "white",
                        width: "90vw",
                        height: "90vh"
                    }}
                >
                    <div className='w-100 d-flex justify-content-end'>
                        <IconButton aria-label="close" onClick={() => this.onCloseModal()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="mt-5 mb-5 ml-5 mr-5">
                        {!interval ?
                            <div classNames="justify-content-center align-items-center">
                                <h3>Seleziona Intervallo di osservazione</h3>
                                <BigCalendarTimeZoned
                                    timeZone={"Etc/GMT-1"}
                                    range={interval}
                                    year={year}
                                    onPrevYear={() => this.onPrevYear()}
                                    onNextYear={() => this.onNextYear()}
                                    onPickRangeCallback={(start, end, year)=>this.onPickRange(start, end, year)}
                                ></BigCalendarTimeZoned>
                            </div>
                            :
                            <MeasurementsComponent
								key={key}
                                fromMonitoring = {true}
								resetInterval={(interval) => this.resetInterval(interval)}
                                interval={this.state.interval}
                                selectedMeasure={selectedMeasure}
                                selectedStation={selectedStation}
                                stationsGeoJSON={stationsGeoJSON}
                                allSensorsMap = {this.state.allSensorsMap}
                            />}
                    </div>
                </div>
            </Modal>
            {/* ----------------------- HYDROMETERS MODAL ----------------------- */}
            <Modal key={'hydro'}
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}
                   open={showHydroModal}
                   onClose={() => this.onCloseModal()}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        background: "white",
                        width: "90vw",
                        height: "90vh"
                    }}
                >
                    <div className='w-100 d-flex justify-content-end'>
                        <IconButton aria-label="close" onClick={() => this.onCloseModal()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {/*passing only sensor specific thresholds*/}
                    <HydroCharts
                        outflowScale = {outflowScales[selectedHydroSensor]}
                        sensorThresholdList = {stationSensorThresholds['I'] && stationSensorThresholds['I'].filter((th, selectedHydroSensor) => th.sensorCode === selectedHydroSensor)}
                        sensorCode={selectedHydroSensor}
                        interval = {hydroViewInterval}
                        key = {hydroComponentKey}
                        afflussiDeflussi={true}
                    />
                </div>
            </Modal>
        </>
    }

}
