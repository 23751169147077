import React from 'react';
import {withRouter} from 'react-router';
import DateUtils from '#/lib/DateUtils'
import {FiClock} from 'react-icons/fi';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import {Badge} from 'react-bootstrap';
import {properties} from '#/properties';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '#/backoffice/style/SweetAlert.css';
import BulletinClient from '#/lib/BulletinClient';
import {ApproveButton} from "#/commons/components/forms/ApproveButton";
import {PublishButton} from "#/commons/components/forms/PublishButton";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import GISTools from "#/lib/GISTools";
import MauMapComponent from "#/commons/mau/MauMapComponent";
import MosipClient from '#/lib/MosipClient'
import * as turf from "@turf/turf";

import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon

} from "react-share";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import moment from "moment";

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
class MauItem extends React.Component {

	componentDidMount() {
		let accordionBody = document.getElementById("mauAccordion_" + this.props.id);
		if (!!accordionBody) {
			accordionBody.addEventListener('transitionend', () => {
				setTimeout(() => {
					this.fixMap()
				}, 250);
			})
		}
	}

	getMunicipalitiesMauZone = () => {
		return new Promise((resolve, reject)=>{
			let municipalitiesMauZone = [];
			MosipClient.getAllMunicipalities(
				(data) => { 
					data.forEach(municipality=>{

						let municipalityGeometry = JSON.parse(municipality.polygonGeojson);
						let geojson = this.props.mauItem.jsonData.geoJson.features.filter(feature => feature.geometry.type !== 'Point');
						geojson.forEach(feature => {
							let isMunicipalityInside = turf.booleanPointInPolygon(turf.centroid(municipalityGeometry).geometry.coordinates ,feature.geometry)
							if(isMunicipalityInside){
								municipalitiesMauZone.push({
									zone : feature.properties.ZONE,
									name : municipality.name,
									istatCode : municipality.istatCode
								})
							}
						})
					})
					resolve(municipalitiesMauZone)
				}, 
				() => { console.log("ERROR COMUNI"); }
			);
		})
			
		
	}



	approve = (id) => {
		loadingSwal.fire('Approvazione in corso...')
		let params = {
			bulletinTypeName: 'MAU',
			id: id
		};
		BulletinClient.approveBulletin(
			(result) => {
				loadingSwal.close()
				ReactSwal.fire("Approvazione mau", "mau approvato con successo","success")
					.then(result => {
						if (result.value && this.props.refreshList) {
							this.props.refreshList()
						}
					});
			},
			function (msg) {
				loadingSwal.close()
				ReactSwal.fire("Approvazione mau", msg, "error");
			},
			params
		);
	}

	publish = (id) => {

		let params = {
			bulletinTypeName: 'MAU',
			id: id,
			protocol:""
		};

		loadingSwal.fire('Pubblicazione in corso...')

		this.getMunicipalitiesMauZone()
		.then(municipalitiesMauZone =>{
			MosipClient.saveMunicipalitiesMauZone(
				()=>{
					BulletinClient.publishBulletin(
						(result) => {
							loadingSwal.close();
							let errors = result.errors || [];
							if (errors.length > 0){
								let swal_html = `<div>
													<h3>MAU pubblicato con successo</h3>
													<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
													<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
													`;
								errors.forEach(err => swal_html += `<li>${err}</li>`);
								swal_html += `</div></div>`;
								ReactSwal.fire({title:"Pubblicazione mau", html: swal_html})
									.then(result => {
										if (result.value && this.props.refreshList) {
											this.props.refreshList()
										}
									});
							} else {
								ReactSwal.fire("Pubblicazione mau", "Mau pubblicato con successo", "success")
									.then(result => {
										if (result.value && this.props.refreshList) {
											this.props.refreshList()
										}
									});
							}
						},
						(res) => {
							if(res.status===502){
								loadingSwal.close()

								ReactSwal.fire({
									title: 'Protocollo in errore. Inserisci Numero di Protocollo di emergenza',
									input: 'text',
									inputLabel: 'N° protocollo',
									showCancelButton: true,
									inputValidator: (value) => {
										if (!value) {
											return 'Inserisci Numero di Protocollo'
										}
									}
								}).then(result => {
						
									if (result.value){
										params.protocol = result.value;
						
										loadingSwal.fire('Pubblicazione in corso...')
										BulletinClient.publishBulletin(
											(result) => {
												loadingSwal.close();
												let errors = result.errors || [];
												if (errors.length > 0){
													let swal_html = `<div>
																		<h3>MAU pubblicato con successo</h3>
																		<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
																		<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
																		`;
													errors.forEach(err => swal_html += `<li>${err}</li>`);
													swal_html += `</div></div>`;
													ReactSwal.fire({title:"Pubblicazione mau", html: swal_html})
														.then(result => {
															if (result.value && this.props.refreshList) {
																this.props.refreshList()
															}
														});
												} else {
													ReactSwal.fire("Pubblicazione mau", "Mau pubblicato con successo", "success")
														.then(result => {
															if (result.value && this.props.refreshList) {
																this.props.refreshList()
															}
														});
												}
											},
											function (msg) {
												loadingSwal.close()
												ReactSwal.fire("Pubblicazione mau", msg, "error");
											},
											params
										);
									}
								})
							}
							else {
								loadingSwal.close()
								ReactSwal.fire("Pubblicazione mau", res.status, "error");
							}
							
						},
						params
					);

				},
				(err)=>{
					console.log("ERROR SAVING municipalitiesMauZone")
				},
				municipalitiesMauZone
			)
		})

		/*

		let params = {
			bulletinTypeName: 'MAU',
			id: id
		};

		ReactSwal.fire({
			title: 'Inserisci Numero di Protocollo',
			input: 'text',
			inputLabel: 'N° protocollo',
			showCancelButton: true,
			inputValidator: (value) => {
				if (!value) {
					return 'Inserisci Numero di Protocollo'
				}
			}
		}).then(result => {

			if (result.value){
				params.protocol = result.value;

				loadingSwal.fire('Pubblicazione in corso...')

				this.getMunicipalitiesMauZone()
				.then(municipalitiesMauZone =>{
					MosipClient.saveMunicipalitiesMauZone(
						()=>{
							BulletinClient.publishBulletin(
								(result) => {
									loadingSwal.close();
									let errors = result.errors || [];
									if (errors.length > 0){
										let swal_html = `<div>
															<h3>MAU pubblicato con successo</h3>
															<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
															<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
															`;
										errors.forEach(err => swal_html += `<li>${err}</li>`);
										swal_html += `</div></div>`;
										ReactSwal.fire({title:"Pubblicazione mau", html: swal_html})
											.then(result => {
												if (result.value && this.props.refreshList) {
													this.props.refreshList()
												}
											});
									} else {
										ReactSwal.fire("Pubblicazione mau", "Mau pubblicato con successo", "success")
											.then(result => {
												if (result.value && this.props.refreshList) {
													this.props.refreshList()
												}
											});
									}
								},
								function (msg) {
									loadingSwal.close()
									ReactSwal.fire("Pubblicazione mau", msg, "error");
								},
								params
							);

						},
						(err)=>{
							console.log("ERROR SAVING municipalitiesMauZone")
						},
						municipalitiesMauZone
					)
				})


				
			}
		})
		*/
	}

	delete = (id) => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione del MAU del giorno {DateUtils.epochToLocaleDateFormatted(DateUtils.fromISO8601ToDate(this.props.createdAt).getTime())}?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'No'
		})
			.then((result) => {

				if (result.value) {
					loadingSwal.fire('Eliminazione in corso...')
					let params = {
						bulletinTypeName: 'MAU',
						id: id
					};
					BulletinClient.deleteBulletin (
						(result) => {
							loadingSwal.close()
							ReactSwal.fire('Cancellazione mau', 'Il record è stato eliminato con successo', 'success')
								.then(result => {
									if (result.value && this.props.refreshList) {
										this.props.refreshList()
									}
								});
						},
						(msg) => {
							loadingSwal.close()
							ReactSwal.fire('Errore cancellazione maib', 'error');
						},
						params
					)
				}
			})

	}


	onEdit(){

		if(this.props.isEditable && !this.props.published){
			this.props.history.push("/backoffice/mau/edit/" + this.props.id);
		}

		if(this.props.isEditable && this.props.published){

			ReactSwal.fire({
				title: 'Modalità di aggiornamento',
				input: 'radio',
				inputOptions: {
					"update" : "Aggiornamento",
					"errata_corrige" : "Errata Corrige"
				},
				inputValidator: (value) => {
					if (!value) {
						return 'Scegli un opzione'
					}
				}
			}).then(result =>{

				let value = result.value;
				this.props.history.push("/backoffice/mau/"+value+"/"+this.props.id)
			})
		}

	}

	getMauTitle(){
		let baseTitle = "MAU del giorno : " + DateUtils.epochToLocaleDateFormatted(DateUtils.fromISO8601ToDate(this.props.createdAt).getTime());

		switch(this.props.trigger){
			case "UPDATE":
				return "[Aggiornamento] "+baseTitle;
			case "ERRATA_CORRIGE":
				return "[Errata Corrige] "+baseTitle;
			default:
				return baseTitle;

		}
	}

	getMauShareTitle(){
		let baseTitle = "Messaggio di Allertamento Unificato del giorno : " + DateUtils.epochToLocaleDateFormatted(DateUtils.fromISO8601ToDate(this.props.createdAt).getTime());

		switch(this.props.trigger){
			case "UPDATE":
				return "[Aggiornamento] "+baseTitle;
			case "ERRATA_CORRIGE":
				return "[Errata Corrige] "+baseTitle;
			default:
				return baseTitle;

		}
	}

	fixMap = () => {
		if (!!this.state.currentMap){
			this.state.currentMap.leafletElement.invalidateSize();
			this.centerMap();
		}
	}
	centerMap() {
		let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
		this.state.currentMap.leafletElement.fitBounds([
			[bbox.bbox[1], bbox.bbox[0]],
			[bbox.bbox[3], bbox.bbox[2]]
		]);
	}

	isTodaysMau = ({year, month, day}) => {
		let now = moment();
		return now.year() === year &&  now.month() + 1 === month && now.date() === day;
	}
	render() {
		let docUrl = !!this.props.id ? properties.url.bulletinDownloadFile + '/' + this.props.id + '/pdf' : "";
		let mauTitle = this.getMauTitle();
		// TODO : Aggiungere controllo ruolo amministratore
		let isEditable = this.props.isEditable && !this.props.published && this.isOfficer();
		let isDeletable = this.props.isDeletable && !this.props.published && this.isOfficer();

		return (
			<Card style={{ marginBottom: '0.7rem', borderRadius: '5px', border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }} onClick={()=>{this.props.onDetails(this.props.mauItem)}}>
				<Card.Header>
					<div className="row" style={{ display: "flex" }}>
						<div className="col-sm">
							<div className="row">
								<span className="icon-span"> <FiClock /> Emanato il: {DateUtils.epochToDate(this.props.createdAt)} </span>
								{(!!this.props.editable) ? this.getBadge(this.props) : <></>}
							</div>
							<div>
								<span className="item-title">{mauTitle}</span>
							</div>
						</div>
						<div className="col-sm d-flex justify-content-end align-items-center">
							<div className="d-flex justify-content-end">
								{!!this.props.editable ?
									<>
										<TwitterShareButton
											className="my-auto mx-2"
											url={BulletinClient.getShareUrl(this.props.id)}
											title={this.getMauShareTitle()}
											hashtag={"#allertamentounificato"}
											onClick={(event) => {event.stopPropagation();}}
											disabled={!this.props.published || !this.isOfficer()}
										>
											<img src={"/img/icon-twitter-x-circle.svg"} alt="Twitter X"/>
										</TwitterShareButton>
										<FacebookShareButton
											className="my-auto mx-2"
											url={BulletinClient.getShareUrl(this.props.id)}
											quote={this.getMauShareTitle()}
											hashtag={"#allertamentounificato"}
											onClick={(event) => {event.stopPropagation();}}
											disabled={!this.props.published || !this.isOfficer()}
										>
											<FacebookIcon size={48} round={true} />
										</FacebookShareButton>
										<div className="my-auto mx-2" >
											{!!this.props.id && !this.props.published  && this.getApprovePublishButton(this.props)}
										</div>
										<div className="my-auto mx-2" >
											<Fab size="medium" title={isEditable ? "Modifica" : "Visualizza"} aria-label={isEditable ? "edit" : "view"} onClick={(event) => {event.stopPropagation(); this.onEdit()}} disabled={!this.isTodaysMau(this.props) || this.props.outdated || !this.isOfficer()}
												style={isEditable ? {backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'} 
												: {backgroundColor: '#0000001f', borderRadius: '5px', color: '#fff'}}
											>
												<EditIcon style={{color: '#fff'}} />
											</Fab>
										</div>
										<div className="my-auto mx-2" >
											<Fab size="medium" title="Cancella" aria-label="delete"
												 onClick={(event) => {event.stopPropagation(); this.delete(this.props.id)}}
												 disabled={!isDeletable}
												 style={isDeletable ? {backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'} 
												 : {backgroundColor: '#0000001f', borderRadius: '5px', color: '#fff'}}
											>
												<DeleteIcon style={{color: '#fff'}} />
											</Fab>
										</div>
									</>
									:
									<></>

								}
								<div className="my-auto mx-2" >
									<Fab size="medium" title="Scarica" aria-label="Scarica" href={docUrl} onClick={(event) => {event.stopPropagation();}}
										style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
									>
										<GetAppIcon  color={!!this.props.id ? 'action' : 'disabled'} style={{color: '#fff'}} />
									</Fab>
								</div>
							</div>
						</div>
					</div>
				</Card.Header>
			</Card>
		)
	}

	getBadge = (props) => {
		let retValue = <></>;
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		let published = props.published;
		if (notApproved){
			retValue = <span>  </span>;
		} else if(onlyApproved){
			retValue = <span> <Badge variant="info">Approvato</Badge> </span>;
		} else if (published) {
			retValue = <span> <Badge variant="success">Pubblicato</Badge><Badge variant="secondary"> Prot. {props.protocol}</Badge> </span>;
		}
		return (retValue);
	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	getApprovePublishButton = (props) => {
		let retValue = <></>;
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		if (notApproved){
			retValue = <ApproveButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={(event) => {event.stopPropagation(); this.approve(props.id)}}/>;
		} else if(onlyApproved){
			retValue = <PublishButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={(event) => {event.stopPropagation(); this.publish(props.id)}}/>;
		}
		return (retValue);
	}
};

export default withRouter(MauItem)
