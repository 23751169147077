import React from 'react';
import {MdDelete} from 'react-icons/md';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkIcon from '@material-ui/icons/Link';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import {blue, orange} from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';

export default class InfostreamSearchParamItem extends React.Component {

	translateAmbitiToSinossi = (ambiti) => {
		let sinossi = '';
		for (let i=0; i < ambiti.length; i++){
			const ambito = ambiti[i];
			const listItemToSearch = ambito.listItem;
			sinossi += i===0 ? 'Articoli che menzionano ' : ' e ';
			for (let j=0; j < listItemToSearch.length; j++){
				sinossi += j===0 ? '(' + listItemToSearch[j] : ' o ' + listItemToSearch[j];
			}
			sinossi += ')';
		}
		return sinossi;
	}

	render() {

		const iconSize = 25;
		const link = this.props.urls;
		const channel = !!link ? "rss" : "tweet";
		const ambiti = !!this.props.json_data ? JSON.parse(this.props.json_data) : [];
		const sinossi =  this.translateAmbitiToSinossi(ambiti);

		return (
			<>
				<div className="accordion" id={"accordion_" + this.props.title}>
					<div className="card">
						<div className="card-header" style={{ display: "flex" }}>
							<button className={"col-"+ (!!this.props.editable ? "10" : "12") + " btn accordion-header text-left"} type="button" data-toggle="collapse" data-target={"#collapse_" + this.props.title} aria-expanded="true">
								<div>
									<span className="item-title">
										{channel === 'tweet' ? 
										// <TwitterIcon  style={{ color: blue[500] }}/> :
										<svg fontSize={'1rem'} color={blue[500]} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
											<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
										</svg> : 
										<RssFeedIcon  style={{ color: orange[500] }}/>} {this.props.title}
									</span>
								</div>
								{
									!!link ?
									/* RSS */
									<>
										<div>
											<span style={{ "fontStyle": "italic"}}> <FindInPageOutlinedIcon/> Criteri di ricerca : <span style={{ "fontWeight": "bold" }}>{sinossi}</span> </span>
										</div>
										<div>
											<span style={{ "fontStyle": "italic"}}> <LinkIcon /> Link: <a title={link} href={link} target="_blank" rel="noopener noreferrer">{link}</a></span>
										</div>
									</>
									:
									/* TWITTER */
									<>
										<div>
											<span style={{ "fontStyle": "italic"}}> <FindInPageOutlinedIcon /> Criteri di ricerca : <span style={{ "fontWeight": "bold" }}>{sinossi}</span> </span>
										</div>
									</>
								}

							</button>


							{!!this.props.editable &&
							<>
								<div className="my-auto col-1">
									<Fab size="medium" aria-label="Cancella" onClick={() => this.props.onClickDelete(channel, this.props.id)} >
										<MdDelete size={iconSize} title="Cancella" />
									</Fab>
								</div>
								<div className="my-auto col-1">
									<Fab size="medium" aria-label="Modifica" href={"editsearch/" + channel + "/" +this.props.id} >
										<EditIcon size={iconSize} title="Modifica"/>
									</Fab>
								</div>
							</>
							}
						</div>
					</div>
				</div>
				<div id={"collapse_" + this.props.username} className="collapse" data-parent={"#accordion_" + this.props.username}>
					{this.props.message}
				</div>
			</>
		)
	}

};
