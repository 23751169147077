import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function CentroFunzionale(props) {

  return (
    <div className='container'>
        <div className="row" style={{ marginTop:"1rem", padding: "3rem" }}>
            <div className='col-lg-7 col-12 p-3' style={{ borderRight: "1px solid #2b7943" }}>
                <h3 className='mt-0'>Cos'è il Centro Funzionale</h3>
                <span>
                    Il Centro supporta la gestione delle emergenze
                    di protezione civile attraverso previsione, monitoraggio
                    in tempo reale e analisi dei rischi.
                    Utilizza una rete di stazioni, modelli meteorologici e dati 
                    satellitari per valutare le condizioni atmosferiche e idrologiche,
                    identificando i potenziali impatti su aree critiche.
                </span>
            </div>
            <div className='col-lg-5 col-12 p-3'>
                <div className='d-flex justify-content-center align-items-center'>
                    <a href='https://www.arpacal.it/' className='d-flex flex-column align-items-center justify-content-center mr-3 text-decoration-none' style={{ minWidth: '8rem' }}>
                        <i className='fas fa-newspaper' style={{ fontSize: '1.5rem', color: '#2b7943' }}></i>
                        <h3 className='mt-1' style={{ color: '#2b7943' }}>News</h3>
                    </a>
                    <Link to={'/frontoffice/foto_gallery'} className='d-flex flex-column align-items-center justify-content-center ml-3 text-decoration-none' style={{ minWidth: '8rem' }}>
                        <i className='fas fa-image' style={{ fontSize: '1.5rem', color: '#2b7943' }}></i>
                        <h3 className='mt-1' style={{ color: '#2b7943' }}>Galleria</h3>
                    </Link>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-3'>
                    <Link to={'/frontoffice/dove_siamo'} className='d-flex flex-column align-items-center justify-content-center mr-3 text-decoration-none' style={{ minWidth: '8rem' }}>
                        <i className='fas fa-map-pin' style={{ fontSize: '1.5rem', color: '#2b7943' }}></i>
                        <h3 className='mt-1' style={{ color: '#2b7943' }}>Dove siamo</h3>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  );
}

export default CentroFunzionale;