import {MapControl, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "#/commons/map/LeafletLegend.css";
import chroma from 'chroma-js';

class PrettyLegend extends MapControl {
	createLeafletElement(props) {}
	componentWillUnmount(){}
	getTickers = (grades) => {
		let tickers = {};
		const {tickerGap, tickerValues} = this.props;
		if (tickerGap){
			let increment = Math.floor(grades.length / tickerGap);
			[...Array(tickerGap).keys()].forEach(i => {
					tickers = {...tickers, [i* increment] : grades[i* increment]}
				}
			)
			tickers = {...tickers, [grades.length - 1] : grades[grades.length - 1]};
		} else if (tickerValues) {
			tickerValues.forEach(tick => {
				const closest = grades.reduce((a, b) => {
					return Math.abs(b - tick) < Math.abs(a - tick) ? b : a;
				});
				tickers = {...tickers, [grades.indexOf(closest)]: tick}
			})
		}
		return tickers;
	}

	removeExistingLegend = customClass => {
		let legendArray = document.getElementsByClassName(customClass);
		if (!!legendArray && legendArray.length > 0) {
			legendArray[0].remove()
		};
	}

	buildLegendBodyAsArray = () => {
		const {grades, legendTitle, getColor, mobileMode} = this.props;
		let legendBody = [];

		if (legendTitle){
			legendBody.push(`<div style="font-size: smaller"><b>${legendTitle}</b></div>`)
		}
		let percent = 95/(grades.length);
		let tickers = this.getTickers(grades);

		legendBody.push(`
					<div  class="prettyLegendBodyContainer">
							${grades.map((grade, i) => {
				let value = Object.keys(tickers).includes(i+'') ? tickers[i] : grade;
				let className = Object.keys(tickers).includes(i+'') ? 'tickertextTest' : 'tooltiptextTest';
				let color =  !Object.keys(tickers).includes(i+'') && getColor(grade);
				return (
					`<div class="tooltipTest" style="width: 2vw; height: ${percent}%; background-color: ${getColor(grade)}">
						<span class=${className} style="color: ${color}; margin-left: ${mobileMode ? '2vw' : '1vw'} ">
							${(value + '').length < 3 ? ((value + '').length < 2 ? `&nbsp;&nbsp;${value}&nbsp;&nbsp;` : `&nbsp;${value}&nbsp;`) : `${value}`}
						</span>
					</div>`
				)
			}
		).join("")}
						</div>`);
		return legendBody;
	}

	addClickListener = (div, onLegendClick) => {
		if(!!onLegendClick){
			div.style.cursor = 'pointer';
			div.addEventListener('click', (event) =>{event.preventDefault(); onLegendClick()})
		}
	}

	componentDidMount = () => {

		const legend = L.control({ position: "bottomright" });
		const {customClass, mobileMode, onLegendClick, leaflet : {map}} = this.props;
		legend.onAdd = () => {
			const div = L.DomUtil.create("div", "info legend prettyLegendContainer  " + customClass);
			div.style.width = mobileMode ? '12vw' : '6vw';
			div.innerHTML = this.buildLegendBodyAsArray().join("<br>");
			this.addClickListener(div, onLegendClick)
			return div;
		};
		this.removeExistingLegend(customClass);
		legend.addTo(map);
	}
}

export default withLeaflet(PrettyLegend);
