import React, {Component} from 'react';
import ThresholdRangeComponent from "#/commons/components/ThesholdRangeComponent";
import MonitoringPluvsThresholdClient from "#/lib/MonitoringPluvsThresholdClient"

import Button from '@material-ui/core/Button';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ReactSwal = withReactContent(Swal);

const defaultRainThresholds = {
	P24H: [1,1,1],
	P12H: [1,1,1],
	P6H: [1,1,1],
	P3H: [1,1,1],
	P1H: [1,1,1],
	P_ESTIMATED_1H: [1,1,1]
};
export default class CumulatedPluvsThresholdComponent extends Component {

	constructor(props) {
		super(props);


		this.state = {
			thresholds : props.thresholds,
			enableSave : false

		}
	}

	componentDidMount() {
		if(!this.state.thresholds && this.props.sensorCode){
			console.log("Initialize Thresholds")

			ReactSwal.fire({
				title: 'Nessuna soglia specifica per questo sensore',
				text: 'Utilizzare le soglie generiche come valori iniziali?',
				icon: 'warnig',
				showDenyButton: true,
				denyButtonText: 'No',
				confirmButtonText: 'Sì'
			}).then(({isConfirmed}) => {
				this.setState({
					thresholds: isConfirmed ? this.props.genericThresholds : defaultRainThresholds
				})
			})
		}
	}


	onChangeValues(aggr, values) {
		let thresholds = this.state.thresholds;
		thresholds[aggr]=values;
		this.setState({thresholds,enableSave : true})
	}

	saveThresholds(){
		let params = {
			sensorCode: this.props.sensorCode,
			thresholds: this.state.thresholds
		}
		MonitoringPluvsThresholdClient.updateThresholds(
			()=>{
				this.setState(
					{enableSave : false},
					()=>{
						if(!!this.props.saveCallback){
							this.props.saveCallback(this.state.thresholds)
						}
					})
			},
			()=>{
				console.log("ERROR")
			},
			params
		);
	}


	render() {
		return (
			<>
				{!!this.state.thresholds ?
					<>
						<div className="row" style={{marginTop:"4rem"}}>
							<div className="col-3">
								Pr. 1H stimata (15min)
							</div>
							<div className="col-9">
								<ThresholdRangeComponent
									min={0}
									max={75}
									step={1}
									values={this.state.thresholds["P_ESTIMATED_1H"]}
									colors={["green", "yellow", "DarkOrange", "red"]}
									onChange={(values) => this.onChangeValues("P_ESTIMATED_1H", values)}
								/>
							</div>
						</div>
						<div className="row" style={{marginTop:"4rem"}}>
							<div className="col-3">
								Pioggia Cumulata 1 ora
							</div>
							<div className="col-9">
								<ThresholdRangeComponent
									min={0}
									max={75}
									step={1}
									values={this.state.thresholds["P1H"]}
									colors={["green", "yellow", "DarkOrange", "red"]}
									onChange={(values) => this.onChangeValues("P1H", values)}
								/>
							</div>
						</div>
						<div className="row" style={{marginTop:"4rem"}}>
							<div className="col-3">
								Pioggia Cumulata 3 ore
							</div>
							<div className="col-9">
								<ThresholdRangeComponent
									min={0}
									max={100}
									step={1}
									values={this.state.thresholds["P3H"]}
									colors={["green", "yellow", "DarkOrange", "red"]}
									onChange={(values) => this.onChangeValues("P3H", values)}
								/>
							</div>
						</div>
						<div className="row" style={{marginTop:"4rem"}}>
							<div className="col-3">
								Pioggia Cumulata 6 ore
							</div>
							<div className="col-9">
								<ThresholdRangeComponent
									min={0}
									max={100}
									step={1}
									values={this.state.thresholds["P6H"]}
									colors={["green", "yellow", "DarkOrange", "red"]}
									onChange={(values) => this.onChangeValues("P6H", values)}
								/>
							</div>
						</div>
						<div className="row" style={{marginTop:"4rem"}}>
							<div className="col-3">
								Pioggia Cumulata 12 ore
							</div>
							<div className="col-9">
								<ThresholdRangeComponent
									min={0}
									max={150}
									step={1}
									values={this.state.thresholds["P12H"]}
									colors={["green", "yellow", "DarkOrange", "red"]}
									onChange={(values) => this.onChangeValues("P12H", values)}
								/>
							</div>
						</div>
						<div className="row" style={{marginTop:"4rem"}}>
							<div className="col-3">
								Pioggia Cumulata 24 ore
							</div>
							<div className="col-9">
								<ThresholdRangeComponent
									min={0}
									max={150}
									step={1}
									values={this.state.thresholds["P24H"]}
									colors={["green", "yellow", "DarkOrange", "red"]}
									onChange={(values) => this.onChangeValues("P24H", values)}
								/>
							</div>



						</div>

						<div className="row" style={{marginTop:"4rem"}}>

							<Button
								disabled={!this.state.enableSave}
								size="large"
								className="justify-content-end ml-auto"
								variant="contained"
								color="primary"
								startIcon={<SendSharpIcon />}
								onClick={(e) => this.saveThresholds()}
							>Salva Soglie</Button>



						</div>
					</>

					:
					<></>
				}
			</>


		)

	}

}

