import React from 'react';
import DateUtils from '#/lib/DateUtils';
import {FiAlignJustify, FiClock} from 'react-icons/fi';
import {SocialIcon} from 'react-social-icons';
import {Link} from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ComunicatoClient from '#/lib/ComunicatoClient';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const comunicatiBaseRelativeUrl = "comunicato/";


const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

class ComunicatoItem extends React.Component {

	getPreview(data){
		return data;
	}

	delete(id){
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione del comunicato ?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'No'
		})
			.then((result) => {

				if (result.value) {
					loadingSwal.fire('Eliminazione in corso...')
					ComunicatoClient.deleteNews (
						(result) => {
							loadingSwal.close()
							ReactSwal.fire('Cancellazione comunicato', 'Il record è stato eliminato con successo', 'success')
								.then(result => {
									window.location.reload();
								});
						},
						(msg) => {
							loadingSwal.close()
							ReactSwal.fire('Errore cancellazione comunicato', 'error');
						},
						id
					)
				}
			})

	}

	render() {

		let data = this.props.jsonData;
		let imgUrl = this.props.imageBase64;
		let testoRidotto = this.props.jsonData?.body.slice(0, 100) + '...';
		return (
			<>
				<div className="card item-card" style={{ marginBottom: '0.7rem', borderRadius: '5px', border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }}>
						<div className="card-body">
						<div className="container">

							{/*Visualizzazione Orizzontale */}

							<div className="row d-none d-xl-flex">
								<div className='col-3'>
									<img
										style={{ margin: 9 }}
										src={imgUrl}
										alt=''
										align="right">
									</img>
								</div>
								<div className="col-7">
									<div className="card-title">
										<h4><Link to={comunicatiBaseRelativeUrl + this.props.id}>{this.props.title}</Link></h4>
									</div>
									<div className="card-categories d-flex flex-column">
										<span className="icon-span"> <FiAlignJustify /> Categoria: {data.category}</span>
										<span className="icon-span"> <FiClock /> Pubblicato: {DateUtils.epochToDate(this.props.createdAt)} </span>
									</div>
									<div className="card-link">
										<span >{this.props.subtitle}</span>
										<Link className="link-text" to={comunicatiBaseRelativeUrl + this.props.id}> Leggi di più -&#62; &nbsp;</Link>
									</div>
									{/* <div className="card-social">
										<span><SocialIcon url="http://twitter.com/" className="icon-social" /> <SocialIcon url="http://facebook.com/" className="icon-social" /></span>
									</div> */}
								</div>
								{!!this.props.editable
									?
									<>
										<div className="my-auto mr-2">
											<Link to={"/backoffice/comunicato/edit/"+this.props.id}>
												<Fab size="medium" title='Modifica' aria-label="add" style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}} >
													<EditIcon />
												</Fab>
											</Link>
										</div>
										<div className="my-auto">
											<Fab size="medium" title='Cancella' aria-label="add" style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
											 onClick={(event) => {event.stopPropagation(); this.delete(this.props.id)}}>
												<DeleteIcon />
											</Fab>
										</div>
									</>
									:
									<></>
								}

							</div>

							{/*Visualizzazione Verticale */}
							<div className="row d-xl-none">
								<div >
									<img
										className=""
										style={{ margin: 9 }}
										src={imgUrl}
										alt=''
										align="left">
									</img>
								</div>
								<div className="col-8" >
									<div >
										<span className="icon-span"> <FiAlignJustify /> Categoria: {this.props.category}</span>
									</div>
									<div>
										<div>
											<span className="icon-span"> <FiClock /> Pubblicato: {DateUtils.epochToDate(this.props.createdAt)} </span>
										</div>
									</div>
									<div >
										<span >{this.props.subtitle}</span>
										<Link className="link-text" to={comunicatiBaseRelativeUrl + this.props.id}> Leggi di più -&#62; &nbsp;</Link>
									</div>
									{/* <div >
										<span> <SocialIcon url="http://twitter.com/" className="icon-social" /> <SocialIcon url="http://facebook.com/" className="icon-social" /></span>
									</div> */}
								</div>
								{!!this.props.editable
									?
									<>
										<div className="my-auto mr-2">
											<Link to={"/backoffice/comunicato/edit/"+this.props.id}>
												<Fab size="medium" title='Modifica' aria-label="add" style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}} >
													<EditIcon />
												</Fab>
											</Link>
										</div>
										<div className="my-auto">
											<Fab size="medium" title='Cancella' aria-label="add"  style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
											onClick={(event) => {event.stopPropagation(); this.delete(this.props.id)}}>
												<DeleteIcon />
											</Fab>
										</div>
									</>

									:
									<></>
								}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
};

export default ComunicatoItem;
