import React, {useRef} from 'react';
import Form from "react-jsonschema-form";
import JoditEditor from "jodit-react";

export default class JsonFormHolderComponent extends React.Component {


    render() {
        return (
            <>

                {(!!this.props.inline) ? (
                    <Form
                        showErrorList={false}
						schema={this.props.schema}
						uiSchema={this.props.uiSchema}
                        formData={this.props.formData}
                        onChange={this.props.onChange}
                        widgets={widgets}
                        FieldTemplate={CustomFieldTemplate}
                    ><br /> </Form>
                )
                    : (
                        <Form
                            showErrorList={false}
							schema={this.props.schema}
							uiSchema={this.props.uiSchema}
                            formData={this.props.formData}
                            widgets={widgets}
                            onChange={this.props.onChange}
                        ><br /> </Form>

                    )}
            </>

        );
    }

}

function CustomFieldTemplate(props) {
    const { id, classNames, label, help, description, errors, children } = props;
    if (id === 'root') {
        return (
            <div className={classNames} >
                {description}
                {children}
                {errors}
                {help}
            </div>
        );
    }
    else {
        return (
            <div className={classNames} style={{ display: "flex" }}>
                <label htmlFor={id} style={{ marginRight: "100px" }}>{label}</label>
                {description}
                {children}
                {errors}
                {help}
            </div>
        );
    }
}


function WysiwygWidget (props) {
    console.log('WysiwygWidget props: ', props);
	const editor = useRef(null);
	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		toolbarButtonSize: "large",
		language: 'it',
		buttons: [
			'source', '|', 'bold', 'underline', 'italic', 'eraser', '|',
			'superscript', 'subscript', '|',
			'ul', 'ol', '|',
			'outdent', 'indent', '|',
			'fontsize', 'brush', 'paragraph', '|',
			'table', 'link', '|',
			'align', 'undo', 'redo', '|',
			'hr', 'symbol', 'fullsize'
		]
	}

	return (
            <JoditEditor
            	ref={editor}
                value={props.value}
                config={config}
                onBlur={(e)=> props.onChange(e)}
                //onChange={props.onChange}
            />
    );
}

const widgets = {
    wysiwygWidget: WysiwygWidget
};
