import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {SaveButton} from '#/commons/components/forms/SaveButton';
import {ResetButton} from '#/commons/components/forms/ResetButton';
import {DeleteButton} from '#/commons/components/forms/DeleteButton';


export const DialogForm = (props) => {
  const iconButtonStyle = {position: 'absolute',right: 1,top: 1,color: 'grey'};

  return (
      <Dialog fullScreen = {props.fullScreen} open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" height="100%">
        <DialogTitle id="form-dialog-title" style={{paddingTop: 5}} disableTypography={true}>
            <h2>{props.title}</h2>
            <IconButton aria-label="close" style={iconButtonStyle} onClick={props.onClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>

        <DialogContent dividers children={props.children} />

        <DialogActions>
            {!!props.handleReset && <ResetButton variant="contained" onClick={props.handleReset}/>}
            {!!props.handleDelete && <DeleteButton variant="contained" onClick={props.handleDelete}/>}
            <SaveButton validForm = {props.hasOwnProperty('validForm') ? props.validForm : true} variant="contained" type="submit" onClick={props.handleSubmit}/>
        </DialogActions>
      </Dialog>
  );
}
