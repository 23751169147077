import React from 'react';
import BulletinClient from '#/lib/BulletinClient';
import SvgHolderComponent from '#/commons/components/SvgHolderComponent';
import {AlertLevelPalette} from '#/lib/AlertLevel';
import DateUtils from '#/lib/DateUtils';


export default class MaibDetails extends React.Component {



	constructor(props) {
		super(props)
		let id;
		if (!!this.props.id) {
			id = this.props.id;
		}
		else {
			const url = window.location.href;
			const idString = url.split('/').pop();
			id = parseInt(idString);
		}
		this.state = {
			id
		}

	}

	componentDidMount() {
		if (!!this.props.size){
			let params = {
				bulletinTypeName: 'MAIB',
				pageNumber: 1,
				pageSize: this.props.size
			}
			BulletinClient.listBulletins(
				(obj) => {
					if (!!obj.data[0]){
						let data = obj.data[0];
						let todayDate = DateUtils.fromISO8601ToDate(data.createdAt);
						let todayTitle = "maib del giorno : " + DateUtils.epochToLocaleDateFormatted(todayDate.getTime());
						let tomorrowTitle = "maib del giorno : " + DateUtils.epochToLocaleDateFormatted(todayDate.getTime()+24*60*60*1000);
						this.setState({
							data : data.jsonData,
							todayTitle : todayTitle,
							tomorrowTitle : tomorrowTitle
						})
					}
				},
				(err) => { console.log(err) },
				params
			)
		}
		else {
			let params = {
				bulletinTypeName: 'MAIB',
				id: this.state.id
			}
			BulletinClient.getBulletin(
				(data) => {
					let todayDate = DateUtils.fromISO8601ToDate(data.createdAt);
					let todayTitle = "maib del giorno : " + DateUtils.epochToLocaleDateFormatted(todayDate.getTime());
					let tomorrowTitle = "maib del giorno : " + DateUtils.epochToLocaleDateFormatted(todayDate.getTime()+24*60*60*1000);
					this.setState({
						data : data.jsonData,
						todayTitle : todayTitle,
						tomorrowTitle : tomorrowTitle
					})
				},
				(err) => {
					console.log(err)
				},
				params
			)
		}
	}


	render() {
		return (
			<>
				{!!this.state.data ?

					<div className="card item-card transparent-card" >
						<div>
							<div className="container">
								<div className="map-container">
									<div className="row">
										<div className="col-6 justify-content-center">
											<div className="row d-flex justify-content-center">
												<h2>{this.state.todayTitle}</h2>
												<SvgHolderComponent
													id={"maib_today"}
													baseZoneId={"zona"}
													svgState={this.state.data.maib_today}
													svgUrl={"/img/svgTemplate/calabria_8zone.svg"}
													palette={AlertLevelPalette}
													scaleFactor={0.6}
													></SvgHolderComponent>
											</div>
										</div>
										<div className="col-6">
											<div className="row d-flex justify-content-center">
												<h2>{this.state.tomorrowTitle}</h2>
												<SvgHolderComponent
													className="col-6"
													id={"maib_tomorrow"}
													baseZoneId={"zona"}
													svgState={this.state.data.maib_tomorrow}
													svgUrl={"/img/svgTemplate/calabria_8zone.svg"}
													palette={AlertLevelPalette}
													scaleFactor={0.6}
													></SvgHolderComponent>
												</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					null
				}
			</>
		)
	}
}
