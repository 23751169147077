import React from 'react'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal';
import ReactTimeseriesChart from '#/commons/chart/ReactTimeseriesChart';
import Tooltip from '@material-ui/core/Tooltip';
import AggregatedChart from "#/commons/chart/AggregatedChart";
import CompareSensorComponent3 from "#/commons/data/components/CompareSensorComponent";
import ChromaticScale, {masterHues} from "#/lib/ChomaticScale";

export default class ChartViewComponent extends React.Component {
	constructor(props) {
		/*
		* dataSetCache
		* selectedMeasure
		* selectedStation
		* interval
		* onDataSetCallback
		* stationsGeoJSON
		* */
		super(props);
		this.state = {
			showModal : false,
			chartKey : 1,
			compareList : !!this.props.compareList ? this.props.compareList : [],
			filter : null,
			target : {}
		}
	}

	componentDidMount() {
		let newState = {};
		let target = {};
		target.measure = this.props.selectedMeasure;
		target.station = this.props.selectedStation;

		let filter = {
			min: this.props.interval.start.valueOf(),
			max: this.props.interval.end.valueOf()
		}
		newState.target = target;
		newState.filter = filter;
		this.setState(newState);
	}

	aggregatedMode = () => !!this.props.aggregationInterval && this.props.aggregationInterval !== 'PUNTUAL';
/*	buildColorsMap = (measures) => [...(new Set(measures.map(meas => meas.value)))].reduce((map, category, i) => {
		const sensorCodes = measures.filter(meas => meas.value === category).map(meas => meas.sensor[0].sensorCode);
		const hue = i > 5 ? null : masterHues[i];
		map[category] = {
			color: ChromaticScale.getMasterColor(hue),
			sensors: sensorCodes.reduce((sensorMap, sensorCode, j) => {
				sensorMap[sensorCode] = ChromaticScale.getColorAroundHue(j, sensorCodes.length, hue);
				return sensorMap;
			},{})
		};
		return map;
	}, {});*/
	buildColorsMap = (measures) => {
		let k = 0;
		let colorsMap = [...(new Set(measures.map(meas => meas.value)))].reduce((map, category, i) => {
			const sensorCodes = measures.filter(meas => meas.value === category).map(meas => meas.sensor[0].sensorCode);
			map[category] = {
				color: ChromaticScale.getEvenSpacedColor(k),
				sensors: sensorCodes.reduce((sensorMap, sensorCode) => {
					sensorMap[sensorCode] = ChromaticScale.getEvenSpacedColor(k);
					k += 1;
					return sensorMap;
				},{})
			};
			return map;
		}, {});
		return colorsMap;
	}
	buildChart() {
		let measure = this.props.selectedMeasure;
		let station = this.props.selectedStation;
		let compareMeasureList = this.state.compareList.map(x => x.measure);
		let compareStationList = this.state.compareList.map(x => x.station);
		let interval = this.props.interval;
		const colorsMap = this.buildColorsMap([measure,...compareMeasureList]);
		if (!!this.props.aggregationInterval && this.props.aggregationInterval !== 'NONE'){

			if (this.aggregatedMode()){
				return <AggregatedChart
					interval={interval}
					measure={measure}
					stations={[station,...compareStationList]}
					compareList={compareMeasureList}
					dataSetCache={this.props.dataSetCache}
					onDataSetCallback={(dataset) => this.props.onDataSetCallback(dataset)}
					chartKey={this.state.chartKey}
					aggregationInterval = {this.props.aggregationInterval}
					colorsMap = {colorsMap}

				/>
			} else {

				return <ReactTimeseriesChart
					interval={interval}
					measure={measure}
					stations={[station,...compareStationList]}
					compareList={compareMeasureList}
					dataSetCache={this.props.dataSetCache}
					onDataSetCallback={(dataset) => this.props.onDataSetCallback(dataset)}
					key={this.state.chartKey}
					onFilterCallBack={filter => this.props.onFilterCallback(filter)}
					filter={this.props.filter}
					colorsMap={colorsMap}
					comboMode={this.props.comboMode}
					updateChart={() => this.updateChart()}
					allSensorsMap = {this.props.allSensorsMap}
					outflowScales={this.props.outflowScales}
				/>

			}
		} else return <></>
	}
	onChangeView(min, max) {
		this.setState({
			filter: {
				min: min,
				max: max
			},
		})
	}

	showCompareSensorModal() {
		this.setState({
			showModal: true
		})
	}

	onCloseModal() {
		this.setState({
			showModal: false
		})
	}
	updateChart(){
		this.setState({
			chartKey: this.state.chartKey + 1
		})
	}
	updateCompareList(data) {

		this.setState({
				showModal: false,
				compareList: data,
				chartKey: this.state.chartKey + 1
			},
			()=>{
				if(!!this.props.onCompareListUpdate) {
					this.props.onCompareListUpdate(data)
				}
			})
	}

	render(){
		return (
			<>
				<div className="d-flex">
					{!!!this.props.compareList ? <Button
						size="small"
						variant="contained"
						color="default"
						startIcon={<AddIcon/>}
						onClick={(e) => this.showCompareSensorModal()}
					>Aggiungi misure</Button> : <></>}
				</div>
				{this.buildChart()}

				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "90vw",
							height: "90vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<CompareSensorComponent3
							stationsGeoJSON={this.props.stationsGeoJSON}
							target={this.state.target}
							compareList={this.state.compareList}
							interval={this.props.interval}
							confirm={(data) => { this.updateCompareList(data) }}
							maxCompareListLenght = {10}
						/>
					</div>

				</Modal>
			</>
		)
	}
}
