import React from 'react'

import LabelledOutline from '#/commons/components/LabelledOutline';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export const RecipientForm = (props) => {

	const viewMode = props.viewMode;
	const address_to = props.formData.address_to;

	const toItemFunction = ({label, disabled}, index) => {
		return (
				<Grid item key={"key_"+index} id={"item_"+index} xs={4}>
					<FormControlLabel
						disabled = {viewMode || disabled}
						id="address_to"
						label={label}
						labelPlacement="end"
						control={
						<Checkbox
							color="primary"
							name="address_to"
							onChange={(e)=>props.onFormChange(label, e.target.checked)}
							checked={!!address_to && address_to.length > 0 ? address_to.indexOf(label)!==-1 : false}
						/>
						}
					/>
				</Grid>
		)
	}

	const optionsList = !!props.recipients ? props.recipients.map( (recipient, index) => toItemFunction(recipient,index)) : [];

	return (
			!!optionsList.length &&
			<LabelledOutline id="myID" label={props.labelForm}>
				<FormGroup >
					<Grid container style={{ marginLeft: "30px" }} spacing={1}>
						{optionsList}
					</Grid>
				</FormGroup>
			</LabelledOutline>
	)


}
