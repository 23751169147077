import React, {useEffect, useState} from 'react'


import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MosipClient from '#/lib/MosipClient'

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Modal from '@material-ui/core/Modal';
import 'react-dropzone-uploader/dist/styles.css'
import Fab from '@material-ui/core/Fab';

import DateUtils from "#/lib/DateUtils";
import moment from "moment";
import _ from "lodash";
import MosipMapComponent from '#/commons/map/MosipMapComponent';
import TextField from '@material-ui/core/TextField';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import MosipCurrentReport from "#/backoffice/mosip/components/MosipCurrentReport"
import * as turf from "@turf/turf";
import { withRouter } from 'react-router';
import Skeleton from "react-loading-skeleton";
import Grid from "@material-ui/core/Grid";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import SwitchComponent from "#/commons/components/SwitchComponent";
import {RecipientForm} from "#/backoffice/mau/components/RecipientForm";
import {DEFAULT_INSTITUTIONS} from "#/backoffice/mau/utils/MauUtils";
import MailingListClient from "#/lib/MailingListClient";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
function AddCustomButton(props) {
	const rowData = props.cell._cell.row.data;
	return (
		<>
			<IconButton color="primary" aria-label="upload picture" component="span"
						onClick={(e) => { e.stopPropagation(); props.clickCallback(rowData) }}>
				<AddIcon />
			</IconButton>
		</>
	)

}

function RemoveButton(props) {
	const rowData = props.cell._cell.row.data;

	return (
		<>
			<Tooltip title={"Rimuovi dai Comuni da allertare"}>
				<IconButton color="primary" aria-label="upload picture" component="span"
							onClick={(e) => { props.clickCallback(rowData.istatCode) }}>
					<RemoveIcon />
				</IconButton>
			</Tooltip>
		</>
	)
}

const colors = {
	"LEVEL1": "yellow",
	"LEVEL2": "DarkOrange",
	"LEVEL3": "red"
}

const livelli = {
	"LEVEL1" : "Livello 1",
	"LEVEL2" : "Livello 2",
	"LEVEL3" : "Livello 3"
}

class MosipA3BuilderPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mauState:null,
			municipalities:[],
			last12HWarnings:[],
			alertedMapByIstat : [],
			lastRunThresholdExceedMapByIstat : [],
			lastRunWarningsMapByIstat : [],
			expiringAlertsMapByIstat : [],
			mapKey: 1,
			lastExecutionTimestamp:0,
			warnings: [],
			selectedMunicipality: [],
			municipalitiesArray: [],
			municipalitiesGeoJSON: null,
			enableSave: false,
			showModal: false,
			showAddModal: false,
			showRecapModal: false,
			ptotocol: "",
			expanded: {
				panel1 : true,
				panel2 : true
			},
			tableKey: 1
		}
	}

	componentDidMount() {
		this.loadFromApi();
	}

	getLastRunWarnings(alertedMapByIstat, lastRunThresholdExceedMapByIstat, municipalities, mauState){
		let lastRunWarningsMapByIstat = {};

		Object.values(lastRunThresholdExceedMapByIstat).forEach(aW => {
			aW.exceededList.forEach( eL => {
					//let isNew = !Object.keys(alertedMapByIstat).includes(eL.municipalityIstatCode);

					if (eL.municipalityIstatCode in lastRunWarningsMapByIstat){
						let currentLevel = lastRunWarningsMapByIstat[eL.municipalityIstatCode].alertLevel;
						let currentLevelNumber = parseInt(currentLevel.replace("LEVEL",""))
						let newLevel = eL.alertLevel;
						let newLevelNumber =  parseInt(newLevel.replace("LEVEL",""))
						if(newLevelNumber>currentLevelNumber){
							lastRunWarningsMapByIstat[eL.municipalityIstatCode] = {
								timestamp: eL.timestamp,
								municipalityName: aW.municipalityName,
								municipalityIstatCode: eL.municipalityIstatCode,
								alertLevel: eL.alertLevel,
								//isNew
							}
						}
					}
					else{
						lastRunWarningsMapByIstat[eL.municipalityIstatCode] = {
							timestamp: eL.timestamp,
							municipalityName: aW.municipalityName,
							municipalityIstatCode: eL.municipalityIstatCode,
							alertLevel: eL.alertLevel,
							//isNew
						}
					}
				}
			)
		})
		if (!_.isEmpty(mauState)) {
			Object.keys(lastRunWarningsMapByIstat).forEach(istat => {
				let w = lastRunWarningsMapByIstat[istat];
				this.checkIfMAUAlerted(w, municipalities, mauState);
			})
		}

		Object.keys(lastRunWarningsMapByIstat).forEach(istat => {
			let w = lastRunWarningsMapByIstat[istat];

			let currentAlert = alertedMapByIstat[istat]?.alertLevel
			let isNew = true;
			let a3Alerted = currentAlert;
			if(!!currentAlert){
				let currentAlertLevelNumber = parseInt(currentAlert.replace("LEVEL",""))
				let newLevelNumber =  parseInt(w.alertLevel.replace("LEVEL",""))
				if(newLevelNumber > currentAlertLevelNumber){
					isNew=true;
					
				}
				else {
					isNew = false;
				}

			}
			w.isNew = isNew
			w.a3Alerted = a3Alerted
			
		})

		return Object.values(lastRunWarningsMapByIstat);
	}

	getExpiringAlerts(alertedMapByIstat, lastRunThresholdExceedMapByIstat, municipalities, mauState){

		let expiringAlertsMapByIstat = [];
		let now = moment().valueOf();
		Object.values(alertedMapByIstat).forEach(alert => {
			if (Math.abs(now - alert.timestamp) > 43200000 - 3600000) {
				// find related warnings
				let children =  [];
				let tst = lastRunThresholdExceedMapByIstat[alert.municipalityIstatCode]
				if (!!tst){
					tst.exceededList.forEach( eL => {
						if (Object.keys(livelli).indexOf(eL.alertLevel) <= Object.keys(livelli).indexOf(alert.alertLevel)){
							children.push(
								{
									timestamp: eL.timestamp,
									municipalityName: "Nuova soglia superata",
									municipalityIstatCode : alert.municipalityIstatCode,
									alertLevel : eL.alertLevel
								}
							)
						}
					})
				}

				expiringAlertsMapByIstat.push(
					{
						expiringTimestamp: alert.timestamp,
						municipalityName: alert.municipalityName,
						municipalityIstatCode: alert.municipalityIstatCode,
						alertLevel: alert.alertLevel,
						_children : children
					}
				)
			}

		})

		if (!_.isEmpty(mauState)) {
			expiringAlertsMapByIstat.forEach(ew => {
				this.checkIfMAUAlerted(ew, municipalities, mauState);
			})
		}

		return expiringAlertsMapByIstat;
	}
	checkIfMAUAlerted(warning,municipalities, mauState){

		let istatCode = warning.municipalityIstatCode;
		let municipalityGeometry = JSON.parse(municipalities.find(m => m.istatCode === istatCode).polygonGeojson);
		let mauMaxLevel = null;
		mauState.features.forEach(feature => {
			let isMunicipalityInside = turf.booleanPointInPolygon(turf.centroid(municipalityGeometry).geometry.coordinates ,feature.geometry)

			if (isMunicipalityInside && !!feature.properties.level){
				if (Object.keys(colors).indexOf(feature.properties.level) > Object.keys(colors).indexOf(mauMaxLevel)){
					mauMaxLevel = feature.properties.level;
				}
			}
		});

		warning.mauAlerted = mauMaxLevel;
	}

	loadFromApi() {

		let municipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getAllMunicipalities((data) => { resolve({municipalities:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let last12HAlertedMunicipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getMosipLast12HAlertedMunicipalities((data) => { resolve({alerted:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let lastRunExceededTresholdsPromise = new Promise((resolve, reject) => {
			MosipClient.getMosipExceededTresholdInLastRun((data) => { resolve({lastRun:data.exceeded_thresholds,lastExecutionTimestamp: data.last_execution_timestamp}); }, () => { console.log("ERROR COMUNI NON ALLERTATI"); });
		})

		let lastMAUStatePromise = new Promise((resolve, reject) => {
			MosipClient.getLastMAUState((data) => { resolve({mauState:data}); }, () => { console.log("ERROR COMUNI NON ALLERTATI"); });
		})

		let last12HExceededThresholdsPromise = new Promise((resolve, reject) => {
			MosipClient.getMosipLast12HExceededThresholds((data) => { resolve({ last12HWarnings : data }); }, () => { console.log("ERROR BACINI"); });
		})

		let result = {};
		Promise.all([municipalitiesPromise, last12HAlertedMunicipalitiesPromise, lastRunExceededTresholdsPromise, lastMAUStatePromise, last12HExceededThresholdsPromise])
			.then( data =>{
				Object.assign(result, ...data);
				let {municipalities,alerted,lastRun,lastExecutionTimestamp,mauState, last12HWarnings} = result;
				let geoJSON = this.buildGeoJSONFromArray(municipalities);

				let alertedMapByIstat = alerted.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});
				let lastRunThresholdExceedMapByIstat = lastRun.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});

				let lastRunWarningsMapByIstat = this.getLastRunWarnings({...alertedMapByIstat}, {...lastRunThresholdExceedMapByIstat}, municipalities, mauState);
				let expiringAlertsMapByIstat = this.getExpiringAlerts({...alertedMapByIstat}, {...lastRunThresholdExceedMapByIstat}, municipalities, mauState);

				let newState = {
					municipalities,
					municipalitiesGeoJSON : geoJSON,
					mauState,
					last12HWarnings,
					alertedMapByIstat : alertedMapByIstat,
					lastRunThresholdExceedMapByIstat : lastRunThresholdExceedMapByIstat,
					lastRunWarningsMapByIstat : lastRunWarningsMapByIstat,
					expiringAlertsMapByIstat : expiringAlertsMapByIstat,
					mapKey: (this.state.mapKey + 1) % 1000,
					tableKey: (this.state.tableKey + 1) % 1000,
					lastExecutionTimestamp,
					enableSave: false,
					showModal: false,
				}
				this.setState(newState);
			})
	}


	buildGeoJSONFromArray(data) {
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Municipalities";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				COMUNE: value.name,
				ISTAT: value.istatCode,
				PROVINCIA: value.province,
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}

	polygonStyle(feature, latlng) {
		let istatCode = feature.properties.ISTAT;
		let municipalityAlert = this.state.selectedMunicipality.find(x => x.istatCode === istatCode)
		if (!!municipalityAlert) {
			return {
				fillColor: colors[municipalityAlert.alertLevel],
				weight: 1,
				opacity: 1,
				color: 'grey'/*colors[municipalityAlert.alertLevel]*/,
				fillOpacity: 1
			};
		}
		else {

			return {
				fillColor: "#fff0",
				weight: 0.2,
				opacity: 1,
				color: "grey",
				fillOpacity: 0
			};
		}
	}

	getPopupContent(properties) {
		let istatCode = properties.ISTAT;
		let content = '';
		let municipalityData = '';
		municipalityData += `COMUNE : ${properties.COMUNE}</br>`;
		municipalityData += `PROVINCIA : ${properties.PROVINCIA}</br>`;
		municipalityData += `ISTAT : ${properties.ISTAT}</br>`;

		let municipalityAlert = this.state.selectedMunicipality.find(x => x.istatCode === istatCode)
		if (!!municipalityAlert) {
			let color = colors[municipalityAlert.alertLevel];
			let time = DateUtils.fromISO8601(municipalityAlert.timestamp)
			municipalityData += `PROTOCOLLO : ${municipalityAlert.protocol}</br>`;
			municipalityData += `TIME : ${time}</br>`;
			municipalityData += `LIVELLO ALLERTA : ${livelli[municipalityAlert.alertLevel]}</br>`;
			content += `<div style='color:${color}'>${municipalityData}</div></br>`;
		}
		else {
			content += `<div>${municipalityData}</div></br>`;
			content += "Nessuna Allerta";

		}

		return `<div style='font-weight: 600'>${content}</div>`;

	}








	getSelectedMunicipalityTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "50vh",

		}
		return tabulatorOptions;
	}

	getMunicipalityTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "50vh"
		}
		return tabulatorOptions;
	}

	getSelectedMunicipalityColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Istat", field: "istatCode", headerSort: true, headerFilter: true },
			{ title: "Comune", field: "name", headerSort: true, headerFilter: true },
			{
				title: "Livello", field: "alertLevel", width: 100, formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return livelli[cell.getValue()];
				},
				editor: "select", editorParams: {
					values: {
						"LEVEL1": "Livello 1",
						"LEVEL2": "Livello 2",
						"LEVEL3": "Livello 3"
					}
				}
			},
			{
				title: "",
				formatter: reactFormatter(<RemoveButton clickCallback={(istatCode) => this.removeMunicipality(istatCode)}></RemoveButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 100
			}
		]);
		return columns;
	}

	getMunicipalityColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Codice Istat", field: "istatCode", headerSort: true, headerFilter: true },
			{ title: "Comune", field: "name", headerSort: true, headerFilter: true },
			{
				title: "Livello", field: "alertLevel", width: 150, formatter: function (cell) {
					cell.getElement().style.backgroundColor = !!cell.getValue() ? colors[cell.getValue()] : "white";
					return !!cell.getValue() ? livelli[cell.getValue()] : "";
				},
				editor: "select", editorParams: {
					values: {
						null: "",
						"LEVEL1": "GIALLO",
						"LEVEL2": "ARANCIO",
						"LEVEL3": "ROSSO"
					}
				}
			},
			{
				title: "",
				formatter: reactFormatter(<AddCustomButton clickCallback={(elem) => this.addCustomMunicipality(elem)}></AddCustomButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 150
			}
		]);
		return columns;
	}

	addCustomMunicipality(elem) {
		if (!!elem.alertLevel) {
			let selectedMunicipality = this.state.selectedMunicipality;
			selectedMunicipality.push(elem);
			this.setState({
				selectedMunicipality: selectedMunicipality,
				tableKey: (this.state.tableKey + 1) % 1000
			})
		}

	}



	addMunicipality(elem) {
		let selectedMunicipality = this.state.selectedMunicipality;
		if (!selectedMunicipality.find(x => x.istatCode === elem.istatCode)) {
			selectedMunicipality.push(elem);
			this.setState({
				selectedMunicipality: selectedMunicipality,
				tableKey: (this.state.tableKey + 1) % 1000

			})


		}
	}

	addMunicipalities(elems) {
		let selectedMunicipality = this.state.selectedMunicipality;
		elems.forEach(elem => {
			if (!selectedMunicipality.find(x => x.istatCode === elem.istatCode)) {
				selectedMunicipality.push(elem);
			}
		})

		this.setState({
			selectedMunicipality: selectedMunicipality,
			tableKey: (this.state.tableKey + 1) % 1000,
		})
	}

	removeMunicipality(istatCode) {
		let selectedMunicipality = this.state.selectedMunicipality;
		selectedMunicipality = selectedMunicipality.filter(x => x.istatCode !== istatCode);
		this.setState({
			selectedMunicipality: selectedMunicipality,
			tableKey: (this.state.tableKey + 1) % 1000

		})
	}

	getMunicipalities() {
		let array = _.cloneDeep(this.state.municipalities);
		let istatCodeToFilter = [ ...this.state.selectedMunicipality.map(x => x.istatCode)]
		array = array.filter(x => !istatCodeToFilter.includes(x.istatCode));
		return array;
	}

	openAddMunicipalityModal() {
		this.setState({
			showAddModal: true,
			tableKey: (this.state.tableKey + 1) % 1000
		})
	}

	onCloseAddMunicipalityModal() {
		this.setState({
			showAddModal: false,
			tableKey: (this.state.tableKey + 1) % 1000
		})
	}

	openRecapModal() {
		this.setState({
			showRecapModal: true,
			tableKey: (this.state.tableKey + 1) % 1000
		})
	}

	onCloseRecapModal() {
		this.setState({
			showRecapModal: false,
			tableKey: (this.state.tableKey + 1) % 1000
		})
	}

	onChangeProtocol(protocol) {
		this.setState({
			protocol: protocol
		})
	}


	buildA3(mailAddresses, toInclude, allMunicipalities){
		let municipalityIstatCodeByAlertLevel = {
			LEVEL1:[],
			LEVEL2:[],
			LEVEL3:[]
		};

		this.state.selectedMunicipality.forEach(m=>{
			switch(m.alertLevel){
				case "LEVEL1":
					municipalityIstatCodeByAlertLevel.LEVEL1.push(m.istatCode);
					break;
				case "LEVEL2":
					municipalityIstatCodeByAlertLevel.LEVEL2.push(m.istatCode);
					break;
				case "LEVEL3":
					municipalityIstatCodeByAlertLevel.LEVEL3.push(m.istatCode);
					break;
				default:
					break;

			}

		})

		let A3 = {
			municipalityIstatCodeByAlertLevel,
			protocol:this.state.protocol,
			mailAddresses,
			toInclude,
			allMunicipalities
		}
		loadingSwal.fire('Pubblicazione in corso...');
		MosipClient.buildA3(
			(result)=>{
				loadingSwal.close();
				let errors = result.errors || [];
				if (errors.length > 0){
					let swal_html = `<div>
												<h3>Comunicato Superamento Soglie archiviato con successo</h3>
												<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
												<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
												`;
					errors.forEach(err => swal_html += `<li>${err}</li>`);
					swal_html += `</div></div>`;
					ReactSwal.fire({title:"Nuovo A3", html: swal_html})
						.then(v => {this.props.history.push("/backoffice/mosip/allertamenti");});
				} else {
					ReactSwal.fire("Nuovo A3", "Comunicato Superamento Soglie archiviato con successo", "success")
						.then(v => {this.props.history.push("/backoffice/mosip/allertamenti");});
				}

			},
			(res)=>{
				if(res.status===502){
					loadingSwal.close()

					ReactSwal.fire({
						title: 'Protocollo in errore. Inserisci Numero di Protocollo di emergenza',
						input: 'text',
						inputLabel: 'N° protocollo',
						showCancelButton: true,
						inputValidator: (value) => {
							if (!value) {
								return 'Inserisci Numero di Protocollo'
							}
						}
					}).then(result => {
						if (result.value){
							A3.protocol = result.value;
							loadingSwal.fire('Pubblicazione in corso...')
							MosipClient.buildA3(
								(result)=>{
									loadingSwal.close();
									let errors = result.errors || [];
									if (errors.length > 0){
										let swal_html = `<div>
																	<h3>Comunicato Superamento Soglie archiviato con successo</h3>
																	<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
																	<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
																	`;
										errors.forEach(err => swal_html += `<li>${err}</li>`);
										swal_html += `</div></div>`;
										ReactSwal.fire({title:"Nuovo A3", html: swal_html})
											.then(v => {this.props.history.push("/backoffice/mosip/allertamenti");});
									} else {
										ReactSwal.fire("Nuovo A3", "Comunicato Superamento Soglie archiviato con successo", "success")
											.then(v => {this.props.history.push("/backoffice/mosip/allertamenti");});
									}
					
								},
								(res)=>{
									loadingSwal.close()
									ReactSwal.fire('Nuovo A3', 'Errore nel salvataggio del Comunicato Superamento Soglie ', 'error');
								},
								A3
							)
						}
					})
				}
				else{
					loadingSwal.close()
					ReactSwal.fire('Nuovo A3', 'Errore nel salvataggio del Comunicato Superamento Soglie ', 'error');
				}


			},
			A3
		)



	}
	getTotalCount(data){
		let count = 0;
		data.forEach(d =>
			count += d.exceededList.length)
		return count
	}

	render() {

		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className=" mt-4">

						<div className="row">
							<div className="col-8" >
								<MosipCurrentReport
									expiringAlertsMapByIstat = {this.state.expiringAlertsMapByIstat}
									lastRunWarningsMapByIstat = {this.state.lastRunWarningsMapByIstat}
									last12HWarnings = {this.state.last12HWarnings}
									lastExecutionTimestamp= {this.state.lastExecutionTimestamp}
									tableKey = {this.state.tableKey}
									mauState = {this.state.mauState}
									onAddAllItems = {(list)=>this.addMunicipalities(list)}
									onAddItem={(obj)=>this.addMunicipality(obj)}
									hideAlreadyAlerted = {true}
								></MosipCurrentReport>

							</div>
							<div className="col-4" >
								<div className="d-flex justify-content-between">
									<h3>Comuni Selezionati per allertamento</h3>
									<Tooltip title={"Aggiungi Comuni da allertare"}>
										<IconButton color="primary" aria-label="upload picture" component="span"
													onClick={(e) => { this.openAddMunicipalityModal() }}>
											<AddIcon />
										</IconButton>
									</Tooltip>
								</div>

								<ReactTabulator
									columns={this.getSelectedMunicipalityColumns()}
									data={this.state.selectedMunicipality}
									options={this.getSelectedMunicipalityTabulatorOptions()}
									key={"municipaty_to_alert_" + this.state.tableKey}
								/>

							</div>
							<div className="col-3" />
						</div>

					</div>

				</div>

				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showAddModal}
					onClose={() => this.onCloseAddMunicipalityModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "70vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseAddMunicipalityModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3>Aggiungi Comune</h3>
						</div>

						<div className="d-flex h-80">
							<div className="col-12 my-auto">
								<ReactTabulator
									columns={this.getMunicipalityColumns()}
									data={this.getMunicipalities()}
									options={this.getMunicipalityTabulatorOptions()}
									key={"municipaty_to_add_" + this.state.tableKey}
								/>
							</div>
						</div>


					</div>

				</Modal>

				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showRecapModal}
					onClose={() => this.onCloseRecapModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<A3MultiStep
						municipalitiesGeoJSON={this.state.municipalitiesGeoJSON}
						protocol={this.state.protocol}
						tableKey={this.state.tableKey}
						selectedMunicipality={this.state.selectedMunicipality}
						getPopupContent={(properties) => this.getPopupContent(properties)}
						polygonStyle={(feature, latlng) => this.polygonStyle(feature, latlng) }
						onCloseRecapModal={() => this.onCloseRecapModal()}
						onChangeProtocol={(protocol) => this.onChangeProtocol(protocol)}
						buildA3={(mailAddresses, toInclude, allMunicipalities) => this.buildA3(mailAddresses, toInclude, allMunicipalities)}
					/>

				</Modal>

				<div className="row" style={{ height: "10vh" }}></div>
				<div style={{ position: "fixed", bottom: "5vh", right: "3vw" }}>
					<Fab variant="extended" color="primary" aria-label="add" disabled={this.state.selectedMunicipality.length === 0}
						 onClick={(e) => this.openRecapModal()} >
						Salva
					</Fab>
				</div>

			</>
		)
	}
}

export default withRouter(MosipA3BuilderPage)


const A3MultiStep = ({municipalitiesGeoJSON, protocol, tableKey, getPopupContent, polygonStyle, onCloseRecapModal, onChangeProtocol, buildA3, selectedMunicipality}) => {
	const STEP = {
		PROTOCOL: 'Review',
		RECIPIENTS: 'Scelta destinatari'
	};

	const [currentStep, setCurrentStep] = useState(STEP.PROTOCOL);
	const [formData, setFormData] = useState({address_to: [], to_include: [] });
	const [recipients, setRecipients] = useState(null);
	const [onlyInvolved, setOnlyInvolved] = useState(false);


	useEffect(() => {
		/*   loadingSwal.fire('Caricamento in corso...')*/
		MailingListClient.getMailingListByCategory(
			mailingList => {
				let mapByCategory = mailingList.reduce((obj, item)=> {
					obj[item.category] = obj[item.category] ? [...obj[item.category], item] : [item];
					return obj;
				},{});
				let recipients = {};
				Object.keys(mapByCategory).forEach( category => {
					recipients[category] = groupByName(mapByCategory[category], category)
				});
				setRecipients(recipients)
			},
			err => {
				console.error(err);

			},
			'ALL'
		)
	}, []);

	useEffect(() => {

		if (onlyInvolved){
			let address_to = formData.address_to.filter(rec => !Object.keys(recipients.MUNICIPALITY).includes(rec));
			address_to = address_to.concat(selectedMunicipality.map(m => m.name))
			setFormData({...formData, address_to})
		} else {}
	}, [onlyInvolved]);

	const findMails = key => ({...recipients.INSTITUTION, ...recipients.MUNICIPALITY, ...recipients.PRIVATE }[key] || []);
	const groupByName = (mailingList, category) =>  mailingList.reduce((obj, item)=> {
		let key = category === 'PRIVATE' ? item.name + ' ' + item.surname : item.institutionName;
		obj[key] = obj[key] ? [...obj[key], item.emailAddress] : [item.emailAddress]
		return obj;
	}, {});

	const onCheckRecipient = (recipient, checked) => {
		let address_to = formData.address_to;
		if (checked) {
			address_to.push(recipient);
		} else {
			const indexElement = address_to.indexOf(recipient);
			if (indexElement!==-1){
				address_to.splice(indexElement,1);
			}
		}
		setFormData({...formData, address_to})
	}

	const toggleAll = (checked, category) => {
		let address_to = formData.address_to;
		if (checked){
			address_to = [...new Set([...address_to, ...Object.keys(recipients[category])])];
		} else {
			address_to = address_to.filter(rec => !Object.keys(recipients[category]).includes(rec));
		}
		setFormData({...formData, address_to})

	}

	const allEntiSelected = () => recipients.INSTITUTION ? !Object.keys(recipients.INSTITUTION).some( ai => !formData.address_to.includes(ai)) : false;
	const allComuniSelected = () => recipients.MUNICIPALITY ? !Object.keys(recipients.MUNICIPALITY).some( ai => !formData.address_to.includes(ai)) : false;
	const allPrivatiSelected = () => recipients.PRIVATE ? !Object.keys(recipients.PRIVATE).some( ai => !formData.address_to.includes(ai)) : false;

	const privateRecipients = () => recipients.PRIVATE ? Object.keys(recipients.PRIVATE).map(rec => {
		return {label: rec}
	}) : [];

	const institutionRecepients =  () => [...new Set([...DEFAULT_INSTITUTIONS, ...(recipients.INSTITUTION ? Object.keys(recipients.INSTITUTION) : [])])].map(rec => {
		return {label: rec,
			disabled: DEFAULT_INSTITUTIONS.includes(rec) && (recipients.INSTITUTION ? !Object.keys(recipients.INSTITUTION).includes(rec) : true)}
	});

	const municipalityRecipients = () => recipients.MUNICIPALITY ? Object.keys(recipients.MUNICIPALITY).map(rec => {
		return {label: rec, disabled: onlyInvolved}
	}) : [];

	const prepareRecipientsAndBuildA3 = () => {
		let mailAddresses = [];
		let toInclude = recipients.INSTITUTION ? formData.address_to.filter(add => Object.keys(recipients.INSTITUTION).includes(add)) : [];
		formData.address_to.forEach(key => mailAddresses.push(...findMails(key)));
		let allMunicipalities = allComuniSelected();

		// mailAddresses = all addresses that will receive the A3 by mail
		// toInclude = institutions that will be mentioned within the document
		// allMunicipalities = if true, document will say "Responsabili di tutti i COMUNI" else "Responsabili dei COMUNI interessati dagli eventi in atto"

		buildA3(mailAddresses, toInclude, allMunicipalities);
	}
	return (
		<div
			style={{
				background: "white",
				width: "80vw",
				height: "85vh",
				borderRadius: '15px'
			}}
		>
			<div className='w-100 d-flex justify-content-end'>
				<IconButton aria-label="close" onClick={() => onCloseRecapModal()}>
					<CloseIcon />
				</IconButton>
			</div>

			<div className="d-flex justify-content-center">
				<h3>Documento Di Superamento Soglie</h3>
			</div>

			{currentStep === STEP.PROTOCOL &&
			<>
				<div className="d-flex h-80">
					<div className="col-12 my-auto">
						<MosipMapComponent
							title={""}
							height={"60vh"}
							geoJSON={municipalitiesGeoJSON}
							polygonStyle={(feature, latlng) => polygonStyle(feature, latlng)}
							getPopupContent={(properties) => getPopupContent(properties)}
							key={"map_" + tableKey}
						></MosipMapComponent>
					</div>
					
				</div>
				<div className="d-flex justify-content-end">
					<Fab style={{marginRight: "50px"}} variant="extended" color="primary" aria-label="add"
						 onClick={(e) => setCurrentStep(STEP.RECIPIENTS)} >
						Scegli destinatari
					</Fab>
				</div>
			</>}
			{currentStep === STEP.RECIPIENTS &&
			<>
				<div className="mx-2">
					<div className="scrollbar scrollbar-primary" style={{maxHeight: "65vh", minHeight: "65vh"}}>
						<Grid container >
							<Grid item xs={12}>
								{formData && recipients && <>
									<Accordion defaultActiveKey="0">
										<Card>
											<Card.Header>
												<Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
													<div className="justify-content-between">
														<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Enti</span>
														<span style={{fontSize: '0.8rem', float: 'right'}}>Gli enti selezionati verranno mostrati nel bollettino</span>
													</div>
												</Accordion.Toggle>
											</Card.Header>
											<Accordion.Collapse eventKey="0">
												<Card.Body>
													<div >
														<SwitchComponent
															name={allEntiSelected() ? 'Deseleziona tutti' : 'Seleziona tutti'}
															value={allEntiSelected()}
															disabled={institutionRecepients().length === 0}
															onChange={(e) => toggleAll(e.target.checked,'INSTITUTION')}

														/>
													</div>
													<div>
														<RecipientForm
															key={"Enti"}
															viewMode={false}
															recipients ={institutionRecepients()}
															onFormChange={onCheckRecipient}
															formData={formData}
														/>
													</div>
												</Card.Body>
											</Accordion.Collapse>
										</Card>
										<Card>
											<Card.Header>
												<Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
													<div className="justify-content-between">
														<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Comuni</span>
														<span style={{fontSize: '0.8rem', float: 'right'}}>Nel bollettino apparirà la voce <i>Responsabili dei COMUNI interessati dagli eventi in atto</i></span>
													</div>
												</Accordion.Toggle>
											</Card.Header>
											<Accordion.Collapse eventKey="1">
												<Card.Body>
													<div className="row">
														<div className="col">
															<SwitchComponent
																name={allComuniSelected() ? 'Deseleziona tutti' : 'Seleziona tutti'}
																value={allComuniSelected()}
																disabled={onlyInvolved}
																onChange={(e) => toggleAll(e.target.checked,'MUNICIPALITY')}
															/>
														</div>
														<div className="col">
															<FormControlLabel
																id="onlyInvolved"
																label={'Allerta solo i comuni coinvolti'}
																labelPlacement="end"
																control={
																	<Checkbox
																		color="primary"
																		name="address_to"
																		onChange={(e)=>setOnlyInvolved(!onlyInvolved)}
																		checked={onlyInvolved}
																	/>
																}
															/>
														</div>
													</div>
													<div>
														<RecipientForm
															key={"Comuni"}
															viewMode={false}
															recipients ={municipalityRecipients()}
															onFormChange={onCheckRecipient}
															formData={formData}
														/>
													</div>
												</Card.Body>
											</Accordion.Collapse>
										</Card>
										<Card>
											<Card.Header>
												<Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
													<div className="justify-content-between">
														<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Altri</span>
														<span style={{fontSize: '0.8rem', float: 'right'}}>Questi contatti <b>NON</b> verranno inseriti nel bollettino</span>
													</div>
												</Accordion.Toggle>
											</Card.Header>
											<Accordion.Collapse eventKey="2">
												<Card.Body>
													<div >
														<SwitchComponent
															name={allPrivatiSelected() ? 'Deseleziona tutti' : 'Seleziona tutti'}
															value={allPrivatiSelected()}
															disabled={privateRecipients().length === 0}
															onChange={(e) => toggleAll(e.target.checked,'PRIVATE')}
														/>
													</div>
													<div>
														<RecipientForm
															key={"Altri"}
															viewMode={false}
															recipients ={privateRecipients()}
															onFormChange={onCheckRecipient}
															formData={formData}
														/>
													</div>
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									</Accordion>
								</>}
							</Grid>
						</Grid>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-6 textAlignCenter" >
						<Fab variant="extended" color="primary" aria-label="add"
							 onClick={(e) => setCurrentStep(STEP.PROTOCOL)} >
							Indietro
						</Fab>
					</div>
					<div className="col-6 textAlignCenter" >
						<Fab variant="extended" color="primary" aria-label="add" 
							 onClick={(e) => prepareRecipientsAndBuildA3()} >
							Genera A3
						</Fab>
					</div>
				</div>
			</>}
		</div>
	)
}
