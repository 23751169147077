import React from 'react'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';

import DataEditorTable from "#/commons/data/components/DataEditorTable"
import VerifiedUserSharpIcon from '@material-ui/icons/VerifiedUserSharp';
import SendSharpIcon from '@material-ui/icons/SendSharp';

import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import DateUtils from "#/lib/DateUtils";
import ChartViewComponent from "#/commons/chart/ChartViewComponent";

import _ from 'lodash';
import it from "date-fns/locale/it";
import moment from "moment";
import DatePicker from "react-datepicker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
const CustomInput = React.forwardRef(({ value, onClick }, ref ) => (
		<Button
			ref={ref}
			size="small"
			variant="contained"
			color="default"
			startIcon={<DateRangeIcon />}
			onClick={onClick}
		>
			{value}
		</Button>

	)
);

const commandTypes = {
	UPDATE_VALIDATION_BY_INTERVAL: 'UPDATE_VALIDATION_BY_INTERVAL',
	UPDATE_VALUE_BY_INTERVAL: 'UPDATE_VALUE_BY_INTERVAL',
	UPDATE_ABSOLUTE_VALUE_BY_INTERVAL: 'UPDATE_ABSOLUTE_VALUE_BY_INTERVAL',
	UPDATE_MEASUREMENT: 'UPDATE_MEASUREMENT',
	CREATE_MEASUREMENT: 'CREATE_MEASUREMENT'
}

const newUpdateValidationByInterval = (sensorCode, validation, start, end) => ({
	type: commandTypes.UPDATE_VALIDATION_BY_INTERVAL,
	sensorCode,
	validation,
	start,
	end
})
const newUpdateValueByInterval = (sensorCode, value, aVal, start, end) => ({
	type: aVal ? commandTypes.UPDATE_ABSOLUTE_VALUE_BY_INTERVAL : commandTypes.UPDATE_VALUE_BY_INTERVAL,
	sensorCode,
	...(aVal ? {absoluteValue: aVal} : {value}),
	start,
	end
})

const newUpdateMeasurement = (measurement,sensorCode)  => {
	let newMeasurement = _.cloneDeep(measurement);
	delete newMeasurement.createdNew;
	delete newMeasurement.dirty;
	delete newMeasurement.valueDirty;
	newMeasurement.sensorCode = sensorCode;
	return {
		type: commandTypes.UPDATE_MEASUREMENT,
		measurement: newMeasurement
	}
}

const newCreateMeasurement = (measurement, sensor) => {
	let newMeasurement = _.cloneDeep(measurement);
	delete newMeasurement.createdNew;
	delete newMeasurement.dirty;
	delete newMeasurement.valueDirty;
	newMeasurement.sensorCode = sensor.code;
	newMeasurement.stationCode = sensor.stationCode;
	newMeasurement.category = sensor.category;
	return {
		type: commandTypes.CREATE_MEASUREMENT,
		measurement: newMeasurement
	}
}
export default class ValidationSection extends React.Component {


	/*
	props
		selectedStation
		selectedSensor
		interval
	*/
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			dirty : false,
			chartKey: 1,
			tableKey: 1,
			filter: null,
			dataSetCache: {},
			sensorValueMapByTimestamp : {},
			selectedTimestamps : [],
			editCommands: [],
			selectedStartDate: null,
			selectedEndDate: null,
		}
	}

	componentDidMount = () => {
		this.setState({
			stationNameBySensorCode : this.getStationNameBySensorCodeMap(),
			categoryBySensorCode : this.getCategoryBySensorCodeMap(),
		})
	}

	onDataSetCallback(dataset) {

		let sensorKeys = Object.keys(dataset);

		let sensorValueMapByTimestamp = {};
		sensorKeys.forEach(sensor =>{
			let dataArray = dataset[sensor].data;
			let dataMap = dataArray.reduce((map, item)=> {
				let value = "ND"
				if(!!item && item.v3!=="MISSING"){
					value = this.state.categoryBySensorCode[item.sensorCode]==='P' ? item.aVal : item.value
				}
				map[item.ts] = value;
				return map;
			}, {});
			sensorValueMapByTimestamp[sensor] = dataMap
		})
		this.setState({
			dataSetCache: dataset,
			sensorValueMapByTimestamp,
			table_Key: this.state.tableKey + 1
		})
	}

	onFilterCallBack = filter => {
		this.setState({filter, selectedTimestamps: [filter.min, filter.max], selectedStartDate: new Date(filter.min), selectedEndDate: new Date(filter.max)})
	}

	dataEditedCallback(data) {
		this.setState({
			chartKey: (this.state.chartKey + 1) % 1000,
			dirty :true
		})
	}

	allDataValidatedCallback(value) {
		const { filter, dataSetCache} = this.state;
		const {selectedMeasure : {sensor}, interval : {start, end}} = this.props;
		const sensorCode = sensor[0].sensorCode;
		let newData = {...dataSetCache};

		let dataToEdit = newData[sensorCode].data;
		if (!!filter){
			dataToEdit = dataToEdit.filter(d => d.ts >= filter.min && d.ts <= filter.max)
		}
		dataToEdit.forEach(data => {
			if(!data.locked && data.v3 !== 'MISSING') {
				data.v3 = value;
				data.dirty = true;
			}
		});
		console.log('Validating' + dataToEdit.length +  'data to ' + value);

		let newCommand = newUpdateValidationByInterval(
			sensorCode,
			value,
			filter ? filter.min : start.valueOf(),
			filter ?  filter.max : end.valueOf()
		)
		this.setState({
			editCommands: [...this.state.editCommands, newCommand],
			dataSetCache: newData,
			dirty :true,
			chartKey: (this.state.chartKey + 1) % 1000
		})
	}
	allDataEditedCallback = value => {

		const {dataSetCache, filter} = this.state;
		const {selectedMeasure : {value : category, sensor}, interval : {start, end}} = this.props;
		const sensorCode = sensor[0].sensorCode;
		let newData = {...dataSetCache};
		let dataToEdit = newData[sensorCode].data;

		if (!!filter){
			dataToEdit = dataToEdit.filter(d => d.ts >= filter.min && d.ts <= filter.max)
		}
		if (dataToEdit.length > 300) {
			if (category === 'P' && Number(value) !== 0) {
				ReactSwal.fire('Attenzione','Non può essere applicato un valore diverso da zero in un intervallo maggiore di 5 ore','error');
			} else {
				ReactSwal.fire({
					title: 'Il nuovo valore verrà applicato a ' + dataToEdit.length + ' misure',
					text: 'Procedere comunque?',
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: 'No',
					confirmButtonText : 'Applica valore'
				}).then(
					(res) => {
						console.log("RES", res)
						if (res.isConfirmed){
							this.editAll(value);
						}

					}
				);
			}
		} else {
			this.editAll(value);
		}

	}

	editAll = (value) => {
		// debugger
		const {dataSetCache, filter} = this.state;
		const {selectedMeasure : {value : category, sensor}, interval : {start, end}} = this.props;
		const sensorCode = sensor[0].sensorCode;
		let sensorObj = this.props.allSensorsMap[sensorCode]
		let newData = {...dataSetCache};
		let dataToEdit = newData[sensorCode].data;

		if (!!filter){
			dataToEdit = dataToEdit.filter(d => d.ts >= filter.min && d.ts <= filter.max)
		}
		dataToEdit.forEach(data => {
			if(!data.locked /*&& data.v3 !== 'MISSING'*/) {
				data[category === 'P' ? "aVal" : "value"] = value;
				data.v3 = 'RECONSTRUCTED';
				data.dirty = true;
			}
		});

		dataToEdit.forEach(data => {
			if(!data.locked /*&& data.v3 !== 'MISSING'*/) {
				data.createdNew = true;
			}
		});

		let newCommands = [newUpdateValueByInterval(
			sensorCode,
			category !== 'P' && value,
			category === 'P' && value,
			filter ? filter.min : start.valueOf(),
			filter ?  filter.max : end.valueOf()
		), ...(dataToEdit.filter(data => !data.id && data.createdNew).map(measurement => newCreateMeasurement(measurement, sensorObj)))];



		this.setState({
			dataSetCache: newData,
			dirty :true,
			editCommands: [...this.state.editCommands, ...newCommands],
			chartKey: (this.state.chartKey + 1) % 1000
		})
	}

	cellEditedCallback = row => {

		let sensorCode = this.props.selectedMeasure.sensor[0].sensorCode;

		let sensorObj = this.props.allSensorsMap[sensorCode]

		console.log('Row edited', row);
		row.dirty = true;

		let newCommand = {};
		let measurement = _.cloneDeep(row)
		delete measurement.dirty;
		delete measurement.valueDirty;
		newCommand =  !row.createdNew ? newUpdateMeasurement(measurement, sensorCode) : newCreateMeasurement(measurement, sensorObj);

		this.setState({editCommands: [...this.state.editCommands, newCommand]})

	}


	resetUpdate(){
		this.setState({
			dataSetCache: {},
			chartKey: this.state.chartKey + 1,
			dirty : false
		},()  => this.props.prevStep({
			interval: this.state.interval,
			selectedStation: this.state.selectedStation,
			selectedMeasure: this.state.selectedMeasure
		}))
	}
	saveUpdate(){
		console.log('COMMANDS', this.state.editCommands);
		loadingSwal.fire('Salvataggio degli aggiornamenti in corso...');
		MeasurementsDataClient.editMeasurementsByCommandList(
			(mes)=>{
				console.log('Saved data!',mes);
				ReactSwal.close();
				ReactSwal.fire({
					title: `<h2>Misurazioni salvate con successo</h2>`,
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK'
				});
				this.resetUpdate();
			},
			(mes)=>{
				console.log('Problems saving data!',mes)
			},
			this.state.editCommands
		)
	}


	/*	saveUpdate(){
            loadingSwal.fire('Salvataggio degli aggiornamenti in corso...');
            const editedData = this.getEditedData();

            MeasurementsDataClient.updateData(
                (mes)=>{
                    console.log('Saved data!',mes);
                    ReactSwal.close();
                    ReactSwal.fire({
                        title: `<h2>${this.getSavedTitle(editedData.length)}</h2>`,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK'
                    });

                    this.resetUpdate();


                },
                (mes)=>{
                    console.log('Problems saving data!',mes)
                },
                editedData
            )
        }*/


	getEditedData() {
		const selectedSensorCode = this.props.selectedMeasure.sensor[0].sensorCode;
		const data = [...this.state.dataSetCache[selectedSensorCode].data].filter(d => d.dirty);
		const dirtyValueData = data.filter(d => d.valueDirty);
		console.log('edited data',data)
		console.log('dirty value data',dirtyValueData)

		data.forEach(
			d => {
				if (d.v3 !== 'VALID' || d.valueDirty){
					d.updateMax = true
				} else {
					d.updateMax = false;
				}
				delete d.dirty;
				delete d.valueDirty;
			}
		);
		console.log('data',data)
		return data;
	};

	getSavedTitle(len) {
		let title = len > 1 ? len + ' misurazioni sono state modificate' : '1 misurazione è stata modificata';
		title += ' con successo';
		return title;
	}

	removeDirty() {
		const selectedSensorCode = this.props.selectedMeasure.sensor[0].sensorCode;
		const data = [...this.state.dataSetCache[selectedSensorCode].data];
		data.forEach(
			d => d.dirty = false
		);
	}

	lockData(){
		const params = this.getDataToBeLocked();

		const startDate = (new Date(params.start)).toLocaleDateString();
		const endDate = (new Date(params.end)).toLocaleDateString();
		loadingSwal.fire('Consolidamento delle misure in corso...');
		MeasurementsDataClient.saveLockData(
			()=>{
				ReactSwal.close();
				ReactSwal.fire({
					title: `<h2>Le misure nell'intervallo ${startDate} - ${endDate} sono state consolidate!</h2>`,
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK'
				});
				this.resetUpdate();
			},
			()=>{
				console.log('Problems locking data!')
			},
			params
		)
	}

	getDataToBeLocked() {
		const params = {}
		params.start = this.props.interval.start.valueOf();
		params.end =  this.props.interval.end.valueOf();
		params.sensorCode = this.props.selectedMeasure.sensor[0].sensorCode;
		return params;
	};
	onTableChange = () => this.setState({
		table_Key: (this.state.tableKey + 1) % 1000
	});

	onCompareListUpdate = (compareList) => {
		this.setState({
			stationNameBySensorCode : this.getStationNameBySensorCodeMap(compareList),
			categoryBySensorCode : this.getCategoryBySensorCodeMap(compareList),
			compareList : compareList
		})
	}


	getCategoryBySensorCodeMap(compareList=[]){
		let mapToCategory = {
			[this.props.selectedMeasure.sensor[0].sensorCode] : this.props.selectedMeasure.value
		}

		compareList.reduce((map,elem)=>{
			map[elem.measure.sensor[0].sensorCode] = elem.measure.value
			return map;
		}, mapToCategory);

		return mapToCategory
	}

	getStationNameBySensorCodeMap(compareList=[]){
		let mapToStationName = {
			[this.props.selectedMeasure.sensor[0].sensorCode] : this.props.selectedStation.label
		}

		compareList.reduce((map,elem)=>{
			map[elem.measure.sensor[0].sensorCode] = elem.station.name
			return map;
		}, mapToStationName);

		return mapToStationName
	}

	handleSelectTimestampFromTable = timestamp => {
		let newState = {};
		if (!timestamp){
			newState.filter = null;
			newState.selectedStartDate = null;
			newState.selectedEndDate = null;

		}
		let selectedTimestamps = !!timestamp ? [...this.state.selectedTimestamps, timestamp] : [];


		if (selectedTimestamps.length > 0) {
			if (selectedTimestamps.length % 2 !== 0) {
				newState.selectedStartDate = new Date(timestamp);
			} else {
				let lastSelectedTimestamps = selectedTimestamps.slice(Math.max(selectedTimestamps.length - 2, 0));
				let filterMin = Math.min(...lastSelectedTimestamps);
				let filterMax = Math.max(...lastSelectedTimestamps);
				newState.filter = {min: filterMin, max: filterMax};
				newState.selectedStartDate = new Date(filterMin);
				newState.selectedEndDate = new Date(filterMax);
			}
		}

		newState.selectedTimestamps = selectedTimestamps;
		this.setState(newState)
	}

	handleChangeDates = dateArray => {
		console.log("Date array", dateArray);
		if (!dateArray){
			this.setState({selectedStartDate: null , selectedEndDate:null, filter: null, selectedTimestamps: []})
		} else {
			this.setState({
				selectedStartDate: dateArray[0] ,
				selectedEndDate: dateArray[1],
				filter: {
					min: DateUtils.getGMT1DateFromLocalDate(dateArray[0]).getTime(),
					max: DateUtils.getGMT1DateFromLocalDate(dateArray[1]).getTime()
				},
				selectedTimestamps: [DateUtils.getGMT1DateFromLocalDate(dateArray[0]).getTime(), DateUtils.getGMT1DateFromLocalDate(dateArray[1]).getTime()]
			})
		}

	}
	handleChangeStartDate = selectedStartDate => {
		const {selectedEndDate} = this.state;
		console.log("Selected START date from date picker", selectedStartDate);
		this.setState({selectedStartDate: selectedEndDate ? (selectedStartDate < selectedEndDate ? selectedStartDate : new Date(this.props.interval.start)) : selectedStartDate})

	}
	handleChangeEndDate = selectedEndDate => {
		const {selectedStartDate} = this.state;
		console.log("Selected END date from date picker", selectedEndDate);
		this.setState({selectedEndDate: selectedStartDate < selectedEndDate ? selectedEndDate : new Date(this.props.interval.end)})
	}
	handleSelectedTimestampFromDatePickers = () => {
		this.setState({
			selectedTimestamps: [DateUtils.getGMT1DateFromLocalDate(this.state.selectedStartDate).getTime(), DateUtils.getGMT1DateFromLocalDate(this.state.selectedEndDate).getTime()],
			filter: {min: DateUtils.getGMT1DateFromLocalDate(this.state.selectedStartDate).getTime(), max: DateUtils.getGMT1DateFromLocalDate(this.state.selectedEndDate).getTime()}
		})
	}

	render() {

		return (
			<>
				<div className="mt-4">
					<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab", display: 'block', textAlign: 'center' }}>
							Validazione dati :  {this.props.selectedMeasure.icon}{this.props.selectedMeasure.label +" "+this.props.selectedStation.label}
					</span>
					<div className="d-flex">
						<div className="d-inline-flex my-auto mx-auto">
							<span className="my-auto" style={{color: "#3fb262",  fontFamily: 'Roboto Slab', fontSize:"1rem", fontWeight: "500"}}>Intervallo di osservazione:&nbsp;&nbsp;</span>
							<span style={{display: 'block'}}>{this.props.interval.start.format("DD/MM/YYYY")} - {this.props.interval.end.format("DD/MM/YYYY")}</span>
						</div>
						<div className="d-inline-flex my-auto mx-auto">
							<span className="my-auto" style={{color: "#3fb262",  fontFamily: 'Roboto Slab', fontSize:"1rem", fontWeight: "500"}}>Intervallo selezionato:&nbsp;&nbsp;</span>
							<span className="mx-auto">
									<DateTimeRangePicker
										locale={"it-IT"}
										onChange={(dates) => this.handleChangeDates(dates)}
										value={[this.state.selectedStartDate, this.state.selectedEndDate]}
										minDate={new Date(this.props.interval.start)}
										maxDate={new Date(this.props.interval.end)}
										maxDetail={'minute'}
										format={"dd/MM/yyyy HH:mm"}
										rangeDivider='&nbsp;&nbsp;&nbsp;'
									/>
								</span>
						</div>

						<Button
							size="small"
							className="justify-content-end ml-auto"
							variant="contained"
							color="default"
							startIcon={<DeleteIcon />}
							disabled={!this.state.dirty}
							onClick={(e) => this.resetUpdate()}
						>Reset</Button>
					</div>
					<div className="row mt-2">
						<div className="col-7">

							<ChartViewComponent
								key={this.state.chartKey}
								dataSetCache = {this.state.dataSetCache}
								selectedMeasure = {this.props.selectedMeasure}
								selectedStation = {this.props.selectedStation}
								interval = {this.props.interval}
								onDataSetCallback = {(dataset) => this.onDataSetCallback(dataset)}
								stationsGeoJSON = {this.props.stationsGeoJSON}
								onFilterCallback = {(filter) => this.onFilterCallBack(filter)}
								filter={this.state.filter}
								chartKey={this.state.chartKey}
								aggregationInterval={'PUNTUAL'}
								onCompareListUpdate={(compareList)=>this.onCompareListUpdate(compareList)}
								comboMode={true}
								allSensorsMap = {this.props.allSensorsMap}
								outflowScales = {this.props.outflowScales}
							/>
						</div>
						<div className="col-5" >
							<DataEditorTable
								dataset={(!!this.state.dataSetCache && !!this.state.dataSetCache[this.props.selectedMeasure.sensor[0].sensorCode]) ? this.state.dataSetCache[this.props.selectedMeasure.sensor[0].sensorCode].data : []}
								sensorValueMapByTimestamp={this.state.sensorValueMapByTimestamp}
								filter={this.state.filter}
								tableKey={this.state.tableKey}
								height = {"60vh"}
								dataEditedCallback={(data) => this.dataEditedCallback(data)}
								allDataValidatedCallback={(value) => this.allDataValidatedCallback(value)}
								allDataEditedCallback={(value) => this.allDataEditedCallback(value)}
								cellEditedCallback={(row) => this.cellEditedCallback(row)}
								selectedMeasure = {this.props.selectedMeasure}
								compareList = {this.state.compareList}
								stationNameBySensorCode = {this.state.stationNameBySensorCode}
								categoryBySensorCode = {this.state.categoryBySensorCode}
								onTableChange={this.onTableChange}
								selectedTimestamps={this.state.selectedTimestamps}
								handleSelectTimestamp ={timestamp => this.handleSelectTimestampFromTable(timestamp)}
							></DataEditorTable>
							<div className="d-flex mt-1 float-right">
								<Button
									size="large"
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									endIcon={<SendSharpIcon />}
									disabled={!this.state.dirty}
									onClick={(e) => this.saveUpdate()}
								>Salva</Button>
								<Button
									disabled={this.state.dirty}
									size="large"
									className="justify-content-end ml-2"
									variant="contained"
									color="primary"
									startIcon={<VerifiedUserSharpIcon />}
									onClick={(e) => this.lockData()}
								>Consolida Misure</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="row" ></div>
				<div style={{ position: "fixed", bottom: "1vh", left: "1vw" }}>
					<Fab variant="extended" color="primary" aria-label="add" className="goToValidation"
						 onClick={(e) => this.props.prevStep({
							 interval: this.state.interval,
							 selectedStation: this.state.selectedStation,
							 selectedMeasure: this.state.selectedMeasure

						 })} >
						<i className="ml-2 fas fa-chevron-left"></i>
						<i className="fas fa-chevron-left"></i>
						Indietro
					</Fab>
				</div>
			</>

		)
	}


}



