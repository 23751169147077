import React from 'react';
import { Skeleton } from "@material-ui/lab";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Calendar, CalendarControls} from "react-yearly-calendar";
import FireRiskClient from "#/lib/FireRiskClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FaCircle } from "react-icons/fa";
import { TextField } from '@material-ui/core';

const ReactSwal = withReactContent(Swal);



export default class FireRiskCampaign extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			start:null,
			end: null,
			pianoAib : "",
			year: moment().year(),
			edited: false,
			openRangePicker: false,
			loading : true,
			active : false
		}
	}

	componentDidMount(){
		FireRiskClient.getCampaign(
			(data) => {
				this.setState({
					start : moment(data.start),
					end : moment(data.end),
					active : data.active,
					pianoAib : data.pianoAib,
					loading : false
				})
			},
			(err) => {
				this.setState({
					start : null,
					end : null,
					pianoAib : "",
					active : false,
					loading : false
				})
			}
		)
	}

	save(){
		FireRiskClient.saveCampaign(
			() => {
				ReactSwal.fire('Campagna Antincendi','Dati Salvati con Successo').then(
					(res) => {
						this.setState({
							edited : false
						})
					}
				);
				
			},
			(err) => {
				console.log("error")
			},
			{
				start : this.state.start.valueOf(),
				end : this.state.end.valueOf(),
				pianoAib : this.state.pianoAib
			}
		)
	}



	openRangePickerModal() {
		this.setState({
			openRangePicker: true
		});
	}
	onCloseRangePickerModal() {
		this.setState({
			openRangePicker: false
		});
	}

	onPickRange(start, end, year) {


		
		this.setState({
			start,
			end,
			openRangePicker: false,
			active : moment().isBetween(start,end),
			edited:true
		})
	}


	render() {

		return (
		<>
		<div className="textAlignCenter">
			<>
				<h1 className="mt-4" style={{ color: "#2b7943", fontWeight: 900 }}>
					Campagna Rischio Incendi Boschivi {this.state.year}
				</h1>
				{this.state.loading ?
				<Skeleton height={'60vh'}  duration={8}/>
				:
				<>
					<ShowCampaign
					start={this.state.start}
					end={this.state.end}
					edited={this.state.edited}
					active={this.state.active}
					pianoAib={this.state.pianoAib}
					onPianoAibChange={v=>this.setState({pianoAib:v, edited:true})}
					openRangePickerModal={this.openRangePickerModal}
					></ShowCampaign>
					<div className = "mt-4">
						{this.state.start && this.state.end
						?
						<>
							
							<Button disabled={!this.state.edited}
								color="primary" style={{ margin: "2vh", minWidth: "10vw" }}
								startIcon={<SendIcon />}
								variant="contained" type="submit"
								onClick={() => {this.save()}}>
								Salva
							</Button>
						</>
						:
						<Button 
							color="primary" style={{ margin: "2vh", minWidth: "10vw" }}
							startIcon={<AddIcon />}
							variant="contained" type="submit"
							onClick={() => this.openRangePickerModal()}>
							Crea
						</Button>
						}
					</div>
				</>
				}


			</>
		</div>
		<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						overflow:'scroll',
					}}
					open={this.state.openRangePicker}
					onClose={() => this.onCloseRangePickerModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "90vw",
							height: "90vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseRangePickerModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div className="mt-5 mb-5 ml-5 mr-5">

							<div className="justify-content-center align-items-center">
								<h3>Seleziona Periodo Campagna Anti Incendi Boschivi</h3>
								<CalendarControls
									year={this.state.year}
									showTodayButton={true}
									onPrevYear={() => {}}
									onNextYear={() => {}}
									goToToday={() => { let now = moment(); this.onPickRange(now, now, now.year()) }}
								/>
								<Calendar
									year={this.state.year}
									showDaysOfWeek={true}
									selectRange={true}
									selectedRange={ [ this.state.start || moment(), this.state.end || moment()]}
									onPickRange={(start, end) => this.onPickRange(start, end)}
									customClasses={{future:true}}
								/>
								
								
								
							</div>

						</div>
					</div>

				</Modal>

		</>)
	}
}

const ShowCampaign = ({ start, end, pianoAib, onPianoAibChange, openRangePickerModal, active, edited }) => {

	return (
		<>
			{start && end ?
				<div className="mt-2">
					{active  
					? <h2 style={{ color: "black" }}>Campagna Attiva<FaCircle className="ml-4" style={{color:"green"}}></FaCircle></h2> 
					: <h2 style={{ color: "black" }}>Campagna NON Attiva <FaCircle className="ml-4" style={{color:"red"}}></FaCircle></h2>
					}
					<h2 style={{ color: "#4c61a4" }}>Data di Inizio</h2>
					<span style={{ padding: "1rem", borderRadius: "25px", backgroundColor: edited ? "#e6e66d" : "transparent" }}>{start.format("DD/MM/YYYY")}</span>
					<h2 style={{ color: "#4c61a4" }} >Data di Fine</h2>
					<span style={{ padding: "1rem", borderRadius: "25px", backgroundColor: edited ? "#e6e66d" : "transparent" }}>{end.format("DD/MM/YYYY")}</span>
					<Button 
						color="primary" style={{ margin: "2vh", minWidth: "10vw" }}
						startIcon={<EditIcon />}
						variant="contained" type="submit"
						onClick={openRangePickerModal}>
						Modifica
					</Button>
					<TextField
						fullWidth
						autoComplete='off'
						label={"Piano AIB"}
						value={pianoAib}
						onChange={e=>{ onPianoAibChange(e.target.value)}}
						InputLabelProps={{
							shrink: true,
						}}
						placeholder={ ""}
					/>
				</div>
				:
				<span>Non sono state pervenute Campagne anti Incendi boschivi per l'anno in corso</span>
			}

		</>

	)
}
