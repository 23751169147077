import React, { useEffect, useState } from 'react';
import '#/backoffice/style/BackOffice.css';

import { Redirect, Route, Switch } from "react-router-dom";

import Header from '#/commons/components/Header'
import BackOfficeNavigationComponent from '#/backoffice/components/BackOfficeNavigationComponent';
import ComunicatoPagedList from '#/commons/comunicato/ComunicatoPagedList';
import ComunicatoDetails from '#/commons/comunicato/ComunicatoDetails';
import ComunicatoEditorPage from '#/backoffice/pages/ComunicatoEditorPage';
import MaibEditorMultiPage from '#/backoffice/pages/MaibEditorMultiPage';
import MaibPagedList from '#/commons/maib/MaibPagedList';
import AuthenticateRoute from '#/backoffice/components/AuthenticateRoute';
import LoginComponent from '#/backoffice/components/LoginComponent';
import UserPagedList from '#/backoffice/user/UserPagedList';
import UserEditorPage from '#/backoffice/pages/UserEditorPage';
import WorkShiftsPage from '#/backoffice/pages/WorkShiftsPage';
import SettingsPage from '#/backoffice/pages/SettingsPage';
import UserWorkShiftsPage from '#/backoffice/pages/UserWorkShiftsPage';
import SensorCategoryPage from '#/backoffice/pages/SensorCategoryPage';
import DataContainerPage from '#/commons/data/DataContainerPage';
import LegacyDataPage from '#/commons/legacyData/LegacyDataPage';
import GridManagementPage from '#/backoffice/pages/GridManagementPage';
import BlacklistPage from '#/backoffice/pages/BlacklistPage';
import InfostreamPagedList from '#/commons/infostream/InfostreamPagedList';
import InfostreamSearchPagedList from '#/commons/infostream/InfostreamSearchPagedList';
import InfostreamEditorPage from '#/backoffice/pages/InfostreamEditorPage';

import AuthenticationService, { ROLE_ADMIN, ROLE_ADMIN_PERSONALE, ROLE_OFFICER, ROLE_CONFIGURATOR } from '#/lib/AuthenticationService';
import MosipMunicipalitiesPage from '#/backoffice/mosip/MosipMunicipalitiesPage';
import MosipBasinsPage from '#/backoffice/mosip/MosipBasinsPage';
import MosipRisksPage from '#/backoffice/mosip/MosipRisksPage';
import MosipExecutionsPage from '#/backoffice/mosip/MosipExecutionsPage';
import AccountEditorPage from './pages/AccountEditorPage';
import MosipWarningsPage from '#/backoffice/mosip/MosipWarningsPage';
import MosipA3BuilderPage from '#/backoffice/mosip/MosipA3BuilderPage';
import MosipTestPage from '#/backoffice/mosip/MosipTestPage';
import RainStatsPage from '#/backoffice/pages/RainStatsPage';
import MaxPluvsPage from '#/backoffice/pages/MaxPluvsPage';
import MauPagedList from "#/commons/mau/MauPagedList";
import MauEditorMultiPage from '#/backoffice/mau/MauEditorMultiPage';
import ForecastPagedList from "#/commons/forecast/ForecastPagedList";
import ForecastEditorMultiPage from '#/backoffice/forecast/ForecastEditorMultiPage';
import BollettinoIdrologicoMeseEditorPage from "#/backoffice/bim/BollettinoIdrologicoMeseEditorPage";
import BollettinoIdrologicoMeseSingleStepPage from "#/backoffice/bim/BollettinoIdrologicoMeseSingleStepPage";

import BimPagedList from '#/commons/bim/BimPagedList';
import AccountInfoPage from './pages/AccountInfoPage';
import WorkItemsPage from "#/backoffice/pages/WorkItemsPage";
import FireRiskConfigPage from "#/backoffice/fireRisk/FireRiskConfigPage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MonitoringPage from "#/backoffice/Monitoring/MonitoringPage";
import FireRiskBuilder from "#/backoffice/fireRisk/FireRiskBuilder";
import FirePropagationPage from "#/backoffice/fireRisk/FirePropagationPage";
import PostEventoPagedList from '#/commons/reportPostEvento/PostEventoPagedList';
import ReportPostEventoEditorPage from '#/backoffice/reportPostEvento/ReportPostEventoEditorPage';
import MassiveDownloadPage from "#/backoffice/pages/MassiveDownloadPage";
import MidaPage from "#/backoffice/mida/MidaPage";
import DailyStatsPage from '#/commons/dailyStats/DailyStatsPage'
import SponsorPage from '#/commons/components/SponsorPage';
import RadarMeteosatComponent from "#/backoffice/radar/component/RadarMeteosatComponent";
import { AfflussiDeflussiPage } from "#/backoffice/pages/AfflussiDeflussiPage";
import HecHMSPage from "#/backoffice/hecHMS/component/HecHMSPage";
import RequestManagerPage from '#/backoffice/pages/RequestManagerPage';
import NowcastingPage from '#/commons/nowcasting/NowcastingPage';

import MailingListManagerPage from '#/backoffice/pages/MailingListManagerPage';
import TemplateConfigPage from '#/backoffice/pages/TemplateConfigPage';
import StaticPagesConf from '#/backoffice/pages/StaticPagesConf';
import IngestionForce from './pages/IngestionForce';
import MonthlyStatsPage from '#/commons/monthlyStats/MonthlyStatsPage';
import { useAuth } from "react-oidc-context";
import APIUtils from '#/lib/APIUtils';
import ForesidePage from './pages/ForesidePage';
import { refreshToken } from '..';
const ReactSwal = withReactContent(Swal);

function BackOffice() {
	// window.addEventListener('storage', function (e) {
	// 	/*
	// 			console.log("RELOADING")
	// 			if (e.url.includes(`${environment.base_url}/backoffice`)){
	// 				window.location.reload();
	// 			}
	// 	*/
	// });
	const auth = useAuth();

	useEffect(() => {
        const interval = setInterval(() => {
            refreshToken(); 
        }, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

	const [workItemsFromLogin, setworkItemsFromLogin] = useState([]);

	let navComponent = <BackOfficeNavigationComponent id="mainNavCompoment" workItemsFromLogin={workItemsFromLogin}></BackOfficeNavigationComponent>;
	switch (auth.activeNavigator) {
		case "signinSilent":
			return <div>Signing you in...</div>;
		case "signoutRedirect":
			return <div>Signing you out...</div>;
	}
	if (auth.isLoading) {
		return <div>Loading...</div>;
	}
	if (auth.error) {
		return <div>Oops... {auth.error.message}</div>;
	}
	APIUtils.setTokenProvider(() => auth?.user?.access_token || null);
	AuthenticationService.init(auth);
	return (
		<>

			<Header home={"/backoffice"} infoPage={"/backoffice/info"} nav={navComponent} ></Header>
			<Route component={({ match }) =>
				<div>

					<Switch>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maibmp/new"} component={<MaibEditorMultiPage trigger={"NEW"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maibmp/view/:id"} component={<MaibEditorMultiPage viewMode={true} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maibmp/edit/:id"} component={<MaibEditorMultiPage edit={true} trigger={"NEW"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maibmp/update/:id"} component={<MaibEditorMultiPage trigger={"UPDATE"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maibmp/errata_corrige/:id"} component={<MaibEditorMultiPage trigger={"ERRATA_CORRIGE"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maib"} component={<MaibPagedList bulletinTypeName={"MAIB"} canEdit={true} createUrl={"/backoffice/maibmp/new"} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maib/configurazione/firerisk"} component={<FireRiskConfigPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/firePropagation"} component={<FirePropagationPage />}></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mau/new"} component={<MauEditorMultiPage trigger={"NEW"} viewMode={false} />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mau/view/:id"} component={<MauEditorMultiPage viewMode={true} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mau/edit/:id"} component={<MauEditorMultiPage edit={true} trigger={"NEW"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mau/update/:id"} component={<MauEditorMultiPage trigger={"UPDATE"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mau/errata_corrige/:id"} component={<MauEditorMultiPage trigger={"ERRATA_CORRIGE"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mau"} component={<MauPagedList bulletinTypeName={"MAU"} canEdit={true} createUrl={"/backoffice/mau/new"} />}></AuthenticateRoute>

						{/* forecast */}
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/forecast/new"} component={<ForecastEditorMultiPage trigger={"NEW"} viewMode={false} />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/forecast/view/:id"} component={<ForecastEditorMultiPage viewMode={true} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/forecast/edit/:id"} component={<ForecastEditorMultiPage edit={true} trigger={"NEW"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/forecast/update/:id"} component={<ForecastEditorMultiPage trigger={"UPDATE"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/forecast/errata_corrige/:id"} component={<ForecastEditorMultiPage trigger={"ERRATA_CORRIGE"} viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/forecast"} component={<ForecastPagedList bulletinTypeName={"forecast"} canEdit={true} createUrl={"/backoffice/forecast/new"} />}></AuthenticateRoute>

						{/* REPORT POST EVENTO */}
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/postevento/new"} component={<ReportPostEventoEditorPage viewMode={false} />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/postevento/view/:id"} component={<ReportPostEventoEditorPage viewMode={true} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/postevento/edit/:id"} component={<ReportPostEventoEditorPage viewMode={false} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/postevento"} component={<PostEventoPagedList bulletinTypeName={"RPE"} canEdit={true} createUrl={"/backoffice/postevento/new"} />}></AuthenticateRoute>

						{/* bim */}
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/bim/new"} component={<BollettinoIdrologicoMeseEditorPage trigger={"NEW"} viewMode={false} />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/bim/view/:id"} component={<BollettinoIdrologicoMeseEditorPage viewMode={true} />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/bim/edit/:id"} component={<BollettinoIdrologicoMeseEditorPage viewMode={false} />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/bim"} component={<BimPagedList bulletinTypeName={"BIM"} canEdit={true} createUrl={"/backoffice/bim/new"} />} ></AuthenticateRoute>

						{/* COMUNICATI */}
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/comunicato/new"} component={<ComunicatoEditorPage />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/comunicato/edit/:id"} component={<ComunicatoEditorPage />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/comunicato/:id"} component={<ComunicatoDetails />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/comunicato"} component={<ComunicatoPagedList canEdit={true} createUrl={"/backoffice/comunicato/new"} />} ></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/maxvalues"} component={<MaxPluvsPage canEdit={true} />}></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/data"} component={<DataContainerPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/legacydata"} component={<LegacyDataPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/download"} component={<MassiveDownloadPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mida"} component={<MidaPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/gridmgr"} component={<GridManagementPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/foreside"} component={<ForesidePage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/blacklist"} component={<BlacklistPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/sensorcategory"} component={<SensorCategoryPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/rainstats"} component={<RainStatsPage />}></AuthenticateRoute>

						<Route exact path={match.url + "/stream/news"}><InfostreamPagedList /></Route>
						<Route exact path={match.url + "/stream/conf"}><InfostreamSearchPagedList canEdit={true} createUrl={"/backoffice/stream/newsearch"} /></Route>
						<Route exact path={match.url + "/stream/newsearch/rss"}><InfostreamEditorPage type='rss' /></Route>
						<Route exact path={match.url + "/stream/newsearch/tweet"}><InfostreamEditorPage type='tweet' /></Route>
						<Route exact path={match.url + "/stream/editsearch/rss/:id"}><InfostreamEditorPage type='rss' /></Route>
						<Route exact path={match.url + "/stream/editsearch/tweet/:id"}><InfostreamEditorPage type='tweet' /></Route>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mosip/configurazione/municipalities"} component={<MosipMunicipalitiesPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mosip/configurazione/basins"} component={<MosipBasinsPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mosip/configurazione/risks"} component={<MosipRisksPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mosip/executions"} component={<MosipExecutionsPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mosip/allertamenti"} component={<MosipWarningsPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mosip/a3_builder"} component={<MosipA3BuilderPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/mosip/testMosip"} component={<MosipTestPage />}></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/workItems"} component={<WorkItemsPage />} ></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_ADMIN_PERSONALE]} path={match.url + "/user/new"} component={<UserEditorPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_ADMIN_PERSONALE]} path={match.url + "/user/edit/:username"} component={<UserEditorPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_ADMIN_PERSONALE]} path={match.url + "/user"} component={<UserPagedList username={AuthenticationService.getLoggedInUsername()} />} ></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/user/work"} username={AuthenticationService.getLoggedInUsername()} component={<UserWorkShiftsPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/user/account/info"} username={AuthenticationService.getLoggedInUsername()} component={<AccountInfoPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/user/account/edit"} username={AuthenticationService.getLoggedInUsername()} component={<AccountEditorPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_ADMIN_PERSONALE]} path={match.url + "/work"} component={<WorkShiftsPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_CONFIGURATOR]} path={match.url + "/settings"} component={<SettingsPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_CONFIGURATOR]} path={match.url + "/templateConfig"} component={<TemplateConfigPage />}></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/requestManager"} component={<RequestManagerPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_CONFIGURATOR]} path={match.url + "/privateMailingListManager"} component={<MailingListManagerPage key={"PRIVATE"} category={"PRIVATE"} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_CONFIGURATOR]} path={match.url + "/institutionMailingListManager"} component={<MailingListManagerPage key={"INSTITUTION"} category={"INSTITUTION"} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[ROLE_ADMIN, ROLE_CONFIGURATOR]} path={match.url + "/municipalityMailingListManager"} component={<MailingListManagerPage key={"MUNICIPALITY"} category={"MUNICIPALITY"} />}></AuthenticateRoute>


						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/monitoring"} component={<MonitoringPage />}></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/fireRisk"} component={<FireRiskBuilder />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/fireRiskConfig"} component={<FireRiskConfigPage />}></AuthenticateRoute>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/monthlyRain"} component={<BollettinoIdrologicoMeseSingleStepPage key="PRECIPITAZIONI_MESE" context="PRECIPITAZIONI_MESE" />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/spi"} component={<BollettinoIdrologicoMeseSingleStepPage key="SPI" context="SPI" />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/monthlyTemperature"} component={<BollettinoIdrologicoMeseSingleStepPage key="MONITORAGGIO_TEMPERATURE" context="MONITORAGGIO_TEMPERATURE" />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/monthlyOutflow"} component={<BollettinoIdrologicoMeseSingleStepPage key="DEFLUSSI" context="DEFLUSSI" />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/outflowModel"} component={<AfflussiDeflussiPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/nowcasting"} component={<div className="generic-page container-fluid data-page"><NowcastingPage /></div>}></AuthenticateRoute>



						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/radarMeteosat"} component={<div className="generic-page container-fluid data-page mt-2"><RadarMeteosatComponent /></div>}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/hecHMS"} component={<HecHMSPage />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/dailyStats"} component={<DailyStatsPage canEdit={true} />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/monthlyStats"} component={<MonthlyStatsPage />}></AuthenticateRoute>

						<Route exact path={match.url + "/info"} ><SponsorPage /></Route>

						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/staticPagesConf"} component={<StaticPagesConf />}></AuthenticateRoute>
						<AuthenticateRoute exact from={match.url} roles={[]} path={match.url + "/forceIngestion"} component={<IngestionForce />}></AuthenticateRoute>

						<Redirect from={match.url + "/signin-oidc"} to="/backoffice/monitoring" push={false} />
						<Redirect from={match.url} to="/backoffice/monitoring" push={true} />
					</Switch>
				</div>
			} />
		</>
	);

}

export default BackOffice;


