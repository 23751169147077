class ObjectManager {

  static set = (obj, path, newValue) => {
    return this._allActions(obj, path, newValue, 'Set');
  }

  static get = (obj, path) => {
    return this._allActions(obj, path, null, 'Get');
  }

  static unset = (obj, path) => {
    return this._allActions(obj, path, null, 'Unset');
  }

  static _allActions = (obj, Path, value, Action) => {

    try {
      if (Array.isArray(Path) === false) {
        Path = [Path];
      }

      let increment = 0;
      let result;
      Path.reduce((a, b) => {
        increment++;
        if (increment === Path.length) {
          switch(Action){
            case "Set":
              a[b] = value;
              return value;
            case "Get":
              result = a[b];
              return null;
            case "Unset":
              delete a[b];
              return null;
            default:
              return null;
          }
        } else {
          return a[b];
        }
      }, obj);
      return result;
    } catch (err) {
      console.error(err);
      return obj;
    }
  };
}

export default ObjectManager;
