import {MapControl, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "#/commons/map/LeafletLegend.css";

class LeafletMauLegendControl extends MapControl {
	createLeafletElement(props) {}

	componentWillUnmount(){}
	
    componentDidMount() {
		const centerControl = L.control({position: 'topright'});  // see http://leafletjs.com/reference.html#control-positions for other positions

		centerControl.onAdd =  () => {
			let div = L.DomUtil.create('div', 'legendControl');
			div.innerHTML = `<a><i style="color: ${this.props.showLegend ? '#56b374' : 'grey'}" class="fas fa-info-circle fa-2x"></i></a>`;
			div.addEventListener('click',  () => this.props.toggleLegend())
			return div;
		};

        const { map } = this.props.leaflet;
		let tmp = document.getElementsByClassName("legendControl");
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		};
		centerControl.addTo(map);

    }
}

export default withLeaflet(LeafletMauLegendControl);
