import React, {useEffect, useState} from "react";
import {Index, TimeRange, TimeSeries} from "pondjs";
import {
    AreaChart,
    BarChart,
    Brush,
    ChartContainer,
    ChartRow,
    Charts,
    Legend,
    LineChart,
    Resizable,
    styler,
    YAxis
} from "react-timeseries-charts";
import DownloadContainer from "#/commons/components/DownloadContainer";
import moment from "moment";
import {format} from "d3-format";
import Skeleton from "react-loading-skeleton";


const LABEL_BY_SERIES_NAME = {
    'Precip Excess' : 'Precipitazione (eccesso)',
    'Precip Loss' : 'Precipitazione (perdita)',
    'Heights' : 'Altezza idrometrica',
    'Outflow' : 'Portata',
    'MeasuredOutflow' : 'Portata misurata'
}

const UNIT_BY_SERIES_NAME = {
    'Precip Excess' : 'mm',
    'Precip Loss' : 'mm',
    'Heights' : 'm',
    'Outflow' : 'm^3/s',
    'MeasuredOutflow' : 'm^3/s'
}

const COLOR_BY_SERIES_NAME = {
    'Precip Excess' : '#3bb506',
    'Precip Loss' : '#f15b46',
    'Heights' : '#0f89c1',
    'Outflow' : '#79b6d2',
    'MeasuredOutflow' :  '#123467'
}
const chartStyle = {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#DDD",
    paddingTop: 10,
    marginBottom: 10
};

const brushStyle = {
    boxShadow: "inset 0px 2px 5px -2px rgba(189, 189, 189, 0.75)",
    background: "#FEFEFE",
    paddingTop: 10
};
const HecHmsChartAlt = ({series, chartKey}) => {

    const initialRange = () => {
        let outflowSeries = series['Outflow'];

        let start = outflowSeries[0][0];
        let end = outflowSeries[outflowSeries.length - 1][0];
        return new TimeRange([start, end]);
    }

    const [state, setState] = useState({
        ready: false,
        channels: {},
        channelNames: [],
        missingIntervals: [],
        displayChannels: [],
        tracker: null,
        highlight: null,
        timerange: initialRange(),
        brushrange: initialRange(),
        outflowScales: {}
    })
    useEffect(() => {
        const points = series;
        let channels = {};
        let styleArray = [];
        let yAxisInfo = {};

        Object.keys(series).forEach((seriesName, i) => {

            styleArray.push({
                key: seriesName,
                color: COLOR_BY_SERIES_NAME[seriesName],
                width: 3
            });
            let points = series[seriesName];
            if (seriesName.includes('Precip')){
                points = points.map(p => [
                    Index.getIndexString("1h", new Date(p[0])),
                    p[1]
                ])
            }

            const timeseries = new TimeSeries({
                name: seriesName,
                columns: [seriesName.includes('Precip') ? "index" : "time", seriesName],
                points
            });

            channels[seriesName] = {
                units: UNIT_BY_SERIES_NAME[seriesName],
                label: seriesName,
                format: ".1f",
                series: timeseries,
                show: true,
                avg: timeseries.avg(seriesName),
                max: timeseries.max(seriesName),
            };
            yAxisInfo[seriesName] = {
                category: seriesName,
                max: timeseries.max(seriesName) || 0.0,
                min: seriesName.includes('Precip')
                || timeseries.min(seriesName) === timeseries.max(seriesName) ? 0.0 : timeseries.min(seriesName)
            };

        });
        const minTime = channels['Outflow'].series.range().begin();
        const maxTime = channels['Outflow'].series.range().end();
        const minDuration = 10 * 60 * 1000;
        const style = styler(styleArray);
        let displayChannels = Object.keys(channels);

        setState({
            ...state,
            ready: true,
            channels,
            points,
            displayChannels,
            minTime,
            maxTime,
            minDuration,
            style,
            yAxisInfo
        })
    },[])

    const buildLegend = () => {
        const {channels, displayChannels} = state;
        let legend = displayChannels.map(channelName => ({
            key: channelName,
            label: LABEL_BY_SERIES_NAME[channelName],
            disabled: !channels[channelName].show
        }));
        return legend;
    }
    const handleActiveChange = channelName => {
        const channels = state.channels;
        channels[channelName].show = !channels[channelName].show;
        setState({ ...state, channels });
    };

    const buildTrackerInfoBox = (displayChannels, channels, timerange) =>{
        let trackerInfoWidth = 0;
        let {tracker, points} = state;
        const trackerInfoValues = displayChannels
            .filter(channelName => channels[channelName].show )
            .map(channelName => {
                const fmt = format(channels[channelName].format);

                let series;
                try {
                    series =  channels[channelName].series.crop(timerange);
                } catch(err){
                    series = channels[channelName].series
                }
                let v = "--";
                if (tracker) {
                    if (channelName.includes('Precip')) {
                        const trackerInMillis = (new Date(tracker)).getTime();
                        const timestamps = points[channelName].map(p => p[0]);//.filter(t => t < trackerInMillis);
                        const closestMillis = timestamps.reduce((a, b) => {
                            return Math.abs(b - trackerInMillis) < Math.abs(a - trackerInMillis) ? b : a;
                        });
                        v = fmt(points[channelName].find(p => p[0] === closestMillis)[1])
                    } else {
                        if (!!series.atTime(new Date(tracker))) {
                            const vv = series.atTime((new Date(tracker))).get(channelName);
                            if (vv !== null) {
                                v = fmt(vv);
                            }
                        }
                    }
                }
                const label = LABEL_BY_SERIES_NAME[channelName];

                const value = `${v < 0 ? 0.0 : v} ${channels[channelName].units}`;
                trackerInfoWidth = value.length > trackerInfoWidth ? value.length : trackerInfoWidth;
                return { label, value };
            });
        return { trackerInfoWidth, trackerInfoValues };
    }
    const buildBarInfoBox = (displayChannels, channels) => {
        const {highlight} = state;
        let barInfoWidth = 0;
        let barInfoValues = [];
        displayChannels
            .filter(channelName => channels[channelName].show)
            .forEach(channelName => {
                if (highlight && highlight.column === channelName) {
                    let v = highlight.event.toJSON().data[channelName];
                    const value = `${v} ${channels[channelName].units}`;
                    const label = LABEL_BY_SERIES_NAME[channelName];
                    barInfoWidth = value.length > barInfoWidth ? value.length : barInfoWidth;
                    barInfoValues.push({ label, value });
                }
            });
        return { barInfoWidth, barInfoValues };
    }

    const handleTimeRangeChange = timerange => {
        const { channels } = state;
        timerange = timerange || channels['Outflow'].range();
        let brushrange = timerange || null;
        setState({...state, timerange, brushrange });
    };
    const handleTrackerChanged = tracker => !!!state.highlight && setState({...state, tracker });
    const buildAxes = (yAxisInfo, channels) => {
/*        let axisList = [];
        Object.keys(series).forEach(seriesName => {


            let min = yAxisInfo[seriesName].min;
            let max = yAxisInfo[seriesName].max;
            let label = LABEL_BY_SERIES_NAME[seriesName]

            const format = channels[seriesName].format;
            const id = `${seriesName}_axis`;
            const visible = channels[seriesName].show;
            axisList.push(
                <YAxis
                    id={id}
                    key={id}
                    visible={visible}
                    label={label}
                    min={min}
                    max={max}
                    width={70}
                    type="linear"
                    format={format}
                />
            );
        });*/
        let arePrecipVisible = channels['Precip Excess'].show || channels['Precip Loss'].show;
        let minPrecip = Math.min(yAxisInfo['Precip Excess'].min, yAxisInfo['Precip Loss'].min);
        let maxPrecip =  Math.max(yAxisInfo['Precip Excess'].max, yAxisInfo['Precip Loss'].max);
        let format = ".1f";

        let areOutflowVisible = channels['Outflow'].show || channels['MeasuredOutflow'].show;
        let minOutflow = Math.min(yAxisInfo['Outflow'].min, yAxisInfo['MeasuredOutflow'].min);
        let maxOutflow =  Math.max(yAxisInfo['Outflow'].max, yAxisInfo['MeasuredOutflow'].max);

        let isHeightVisible = channels['Heights'].show ;
        let minHeight = yAxisInfo['Heights'].min;
        let maxHeight =   yAxisInfo['Heights'].max;

        let axisList = [<YAxis
            id={'Precip_axis'}
            key={'Precip_axis'}
            visible={arePrecipVisible}
            label={'Precipitazione cumulata'}
            min={minPrecip}
            max={maxPrecip}
            width={70}
            type="linear"
            format={format}
        />,
            <YAxis
                id={'Outflow_axis'}
                key={'Outflow_axis'}
                visible={areOutflowVisible}
                label={'Portata'}
                min={minOutflow}
                max={maxOutflow}
                width={70}
                type="linear"
                format={format}
            />,
            <YAxis
                id={'Heights_axis'}
                key={'Heights_axis'}
                visible={isHeightVisible}
                label={'Altezza idrometrica'}
                min={minHeight}
                max={maxHeight}
                width={70}
                type="linear"
                format={format}
            />
        ]

        return axisList;
    }
    const buildCharts = (displayChannels, channels, timerange, barInfoValues, barInfoWidth, missingIntervals) => {

        const charts = [];
        const absoluteChannels = Object.keys(channels).filter(ch => ch.includes('Precip'));
        let defaultOffset = 10;
        const offsets = {};
        let subtracted = (absoluteChannels.length - 1) / 2;
        absoluteChannels.forEach((absChannel, index) => offsets[absChannel] = (index - subtracted) * defaultOffset);

        for (let channelName of displayChannels) {
            let series = channels[channelName].series;
            let axis = `${channelName}_axis`;

           if (channelName.includes('Precip')){
               charts.push(
                   <BarChart
                   axis={'Precip_axis'}
                   style={state.style}
                   visible={channels[channelName].show}
                   spacing={1}
                   columns={[channelName]}
                   series={series}
                   minBarHeight={1}
               />)
           } else {
               charts.push(
                   <LineChart
                       key={`line-${channelName}`}
                       axis={channelName.includes('Outflow') ? 'Outflow_axis' : 'Heights_axis'}
                       visible={channels[channelName].show}
                       series={series}
                       columns={[channelName]}
                       style={state.style}
                       interpolation={ "curveLinear" }
                       breakLine
                   />
               );
           }

        }
        return charts;
    }
    const renderMultiAxisChart = t => {
        let {
            timerange,
            displayChannels,
            channels,
            maxTime,
            minTime,
            minDuration,
            yAxisInfo,
            tracker,
            missingIntervals } = state;


/*        timerange = initialRange();*/

        const { trackerInfoWidth, trackerInfoValues } = buildTrackerInfoBox(displayChannels, channels, timerange);
        const { barInfoWidth, barInfoValues } = buildBarInfoBox(displayChannels, channels, timerange);

        return (
            <ChartContainer
                key={chartKey}
                timeRange={timerange}
                trackerPosition={tracker}
                onTrackerChanged={handleTrackerChanged}
                trackerShowTime={true}
                maxTime={new Date(maxTime)}
                minTime={new Date(minTime)}
                onTimeRangeChanged={handleTimeRangeChange}
                enablePanZoom={true}
                minDuration={minDuration}
                format="%d/%m/%Y %H:%M"
            >
                <ChartRow
                    height="450"
                    trackerInfoValues={trackerInfoValues}
                    trackerInfoHeight={10 + trackerInfoValues.length * 16}
                    trackerInfoWidth={10 + trackerInfoWidth * 42}
                >
                    {buildAxes(yAxisInfo, channels)}
                    <Charts>{buildCharts(displayChannels, channels, timerange, barInfoValues, barInfoWidth, missingIntervals)}</Charts>
                </ChartRow>
            </ChartContainer>
        );
    }
    const renderBrush = () => {
        let { channels, missingIntervals, brushrange, tracker} = state;

        const areaStyle = {
            Outflow: {
                line: {
                    normal: { stroke: '#79b6d2', fill: "none", strokeWidth: 1 },
                    highlighted: { stroke: '#79b6d2', fill: "none", strokeWidth: 1 },
                    selected: { stroke: '#79b6d2', fill: "none", strokeWidth: 1 },
                    muted: { stroke: '#79b6d2', fill: "none", opacity: 0.4, strokeWidth: 1 }
                },
                area: {
                    normal: { fill: '#79b6d2', stroke: "none", opacity: 0.75 },
                    highlighted: { fill: '#79b6d2', stroke: "none", opacity: 0.75 },
                    selected: { fill: '#79b6d2', stroke: "none", opacity: 0.75 },
                    muted: { fill: '#79b6d2', stroke: "none", opacity: 0.25 }
                }
            }
        };

        return (
            <ChartContainer
                timeRange={channels['Outflow'].series.range()}
                trackerPosition={tracker}
                format="%d/%m/%Y %H:%M"
                key={chartKey}
            >
                <ChartRow height="100" debug={false}>
                    <Brush
                        timeRange={brushrange}
                        allowSelectionClear
                        onTimeRangeChanged={handleTimeRangeChange}
                    />
                    <YAxis
                        id={`Outflow_brush_axis`}
                        label={LABEL_BY_SERIES_NAME['Outflow']}
                        min={channels['Outflow'].min}
                        max={channels['Outflow'].max}
                        width={70}
                        type="linear"
                        format=".1f"
                    />
                    <Charts>
                        <AreaChart
                            axis={`Outflow_brush_axis`}
                            style={areaStyle}
                            columns={{ up: ['Outflow'], down: [] }}
                            series={channels['Outflow'].series}
                            interpolation="curveStep"
                        />
                    </Charts>
                </ChartRow>
            </ChartContainer>
        );
    };
    return (
        !state.ready ?
            <Skeleton height={"60vh"} duration={8}/>
            : <div className="mt-2">

            <DownloadContainer
                imageName={"punctual_measurements"}
                disabled={false}
            >
                <>
                    <div className="row">
                        <div className="col-6">
                            <Legend
                                type={"line"}
                                style={state.style}
                                categories={buildLegend()}
                                onSelectionChange={handleActiveChange}
                            />
                        </div>

                        <div className="col-6">
                            {state.tracker
                                ? `${moment(state.tracker).format('DD/MM/YYYY HH:mm:ss')}`
                                : "-:--:--"}
                        </div>
                    </div>

                    <hr style={{margin: 0}}/>
                    <div >
                        <div style={chartStyle}>
                            <Resizable>
                                {renderMultiAxisChart()}
                            </Resizable>
                        </div>
                    </div>
                </>
            </DownloadContainer>
            <div >
                <div style={brushStyle}>
                    <Resizable>{renderBrush()}</Resizable>
                </div>
                <span style={{fontSize: '0.8rem', fontFamily: 'sans-serif'}}>
							<sup>(*)</sup>
							I grafici mostrano i dati in ora locale (GMT+{(new Date()).getTimezoneOffset() === -120 ? 2 : 1})</span>
            </div>
        </div>
    )

}

export default HecHmsChartAlt;
