import React, {Component} from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import 'moment/locale/it';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';

import {MEASUREMENT_CATEGORY_ICONS, MEASUREMENT_LABELS} from "#/lib/MeasurementCategory";
import Markers from "#/lib/Markers";
import StationClient from "#/lib/StationClient";
import DailyStatsClient from "#/lib/DailyStatsClient";
import {GeoJSON} from 'react-leaflet';
import Skeleton from "react-loading-skeleton";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import moment from 'moment';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from "@material-ui/core/Tooltip";
import {FaAngleLeft, FaCalculator, FaRegCalendarAlt} from "react-icons/fa"
import DailyStatsTables from './DailyStatsTables';
import SwitchComponent from "#/commons/components/SwitchComponent";
import SendSharpIcon from '@material-ui/icons/SendSharp';

import chroma from 'chroma-js'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import {outflowValidationOptions, rainValidationOptions, temperatureValidationOptions} from "#/lib/DailyStatsValidation"

import GISTools from '#/lib/GISTools';
import _ from 'lodash'
import MapComponent from "#/commons/map/MapComponent";
import DateUtils from "#/lib/DateUtils";
import {FaFileCsv, FaFilePdf} from "react-icons/fa";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import InputSelectComponent from '../components/InputSelectComponent';


const defaultYear = moment().year();

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

const defaultStation = {
	value: null,
	label: <>&nbsp;</>
}

export default class DailyStatsPage extends Component {

	constructor(props){
		super(props);

		this.state = {
			selectedCategory : "",
			allStations : false,
			stationOptions : [],
			selectedStationCode : null,
			selectedStationName : null,
			geojson : null,
			stationMapByCode : null,
			mapKey : 0,
			tableKey : 0,
			year : defaultYear,
			interval : null,
			openRangePicker : false,
			data : null,
			showGisPicker: true,
			showSidebar: true,
			selectedMassiveValidation:""
		}

	}

	onPrevYear() {
		this.setState({
			year: this.state.year - 1
		})
	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	onNextYear() {
		this.setState({
			year: this.state.year + 1
		})
	}

	onCategoryChange = (selectedCategory) => {
		if(!!selectedCategory){

			loadingSwal.fire('Recupero Stazioni in corso...');
			StationClient.getAllStationsGeojsonByCategory(
				selectedCategory,
				(data)=>{
					loadingSwal.close();

					let stationMapByCode = data.features.reduce((map, f)=>{
						map[f.properties.code] = f.properties.name
						return map;
					}, {})

					let stationOptions = [];
					let stationsProperties = GISTools.getPropertiesFromFeature(data);
					stationOptions.push(defaultStation);
					stationsProperties.forEach(properties => {
						stationOptions.push({
							label: properties.name,
							value: properties.code,
						});
					})
					
					this.setState({
						selectedCategory,
						selectedStationCode : null,
						selectedStationName : null,
						geojson: data,
						stationMapByCode,
						stationOptions,
						mapKey: (this.state.mapKey + 1) % 1000
					},()=>{this.fetchDailyData()})
				},
				(err) => {
					loadingSwal.close();
					console.log(err)
				}
			)
		}
		else{
			this.setState({
				selectedCategory,
				geojson: null,
				selectedStationCode : null,
				selectedStationName : null,
				mapKey: (this.state.mapKey + 1) % 1000
			},()=>{this.fetchDailyData()})
		}
	}

	onSelectStation = (feature) => {
		this.setState({
			selectedStationCode : feature.properties.code,
			selectedStationName : feature.properties.name,
			allStations : false,
			mapKey: (this.state.mapKey + 1) % 1000
		},()=>{this.fetchDailyData()})
	}

	onSelectStationOption = (val) => {
		this.setState({
			selectedStationCode : val.value,
			selectedStationName : val.label,
			allStations : false,
			mapKey: (this.state.mapKey + 1) % 1000
		},()=>{this.fetchDailyData()})
	}

	pointToMarker(feature, latlng, context) {
		let color = "grey";
		let borderColor = "white";

		let marker = Markers.getMarker(latlng, color,borderColor).on('click', () => {
			this.onSelectStation(feature);
		});
		if (!!this.state.allStations || this.state.selectedStationCode ===  feature.properties.code){
			marker = Markers.getMarker(latlng, "green",borderColor);
		}
		return marker;
	}

	onEachFeatureStations(feature, layer, context) {
		layer.bindTooltip(`<b>${feature.properties.name}</b>`)
	}

	onPickRange(start, end, year) {

		let isTillToday = end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		let isFromToday = start.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		end = isTillToday ? moment().add(-30, 'm') : end.add(1,'d').startOf('day');
		start = isFromToday ? end.clone().add(-24, 'h') : start.startOf('day');

		let interval = {start, end}
		this.setState({
			year: !!year ? year : this.state.year,
			interval: interval,
			openRangePicker: false
		},()=>{this.fetchDailyData()})
	}

	resetInterval() {
		this.setState({
			interval: null,
			year: defaultYear
		},()=>{this.fetchDailyData()});
	}

	openRangePickerModal() {
		this.setState({
			openRangePicker: true
		});
	}
	onCloseRangePickerModal() {
		this.setState({
			openRangePicker: false
		});
	}

	fetchDailyData() {
		if(!!this.state.selectedStationCode && !!this.state.interval && !!this.state.selectedCategory){
			loadingSwal.fire('Caricamento dati...');
			DailyStatsClient.getDailyStats(
				(data)=>{
					this.addCalculatedFieldsToData(data, this.state.stationMapByCode);
					loadingSwal.close();
					this.setState({
						data,
						showGisPicker:false,
						tableKey : (this.state.tableKey + 1) % 1000
					})
				},
				(err) => {
					loadingSwal.close();
					ReactSwal.fire('Errore nel caricamento dati!','','error');
				},
				{
					start : this.state.interval.start.valueOf(),
					end : this.state.interval.end.valueOf(),
					category : this.state.selectedCategory,
					stationCode : this.state.selectedStationCode
				}

			)
		}
		else if(!!this.state.allStations && !!this.state.interval && !!this.state.selectedCategory){
			loadingSwal.fire('Caricamento dati...');
			DailyStatsClient.getDailyStats(
				(data)=>{
					this.addCalculatedFieldsToData(data, this.state.stationMapByCode)
					loadingSwal.close();
					this.setState({
						data,
						showGisPicker:false,
						tableKey : (this.state.tableKey + 1) % 1000
					})
				},
				(err) => {
					loadingSwal.close();
					ReactSwal.fire('Errore nel caricamento dati!','','error');
				},
				{
					start : this.state.interval.start.valueOf(),
					end : this.state.interval.end.valueOf(),
					category : this.state.selectedCategory
				}

			)
		}
		else{
			this.setState({
				data : null
			});

		}
	}

	updateDailyStats = ()=>{
		let dirtyArray = this.getDirtyStats();
		loadingSwal.fire('Salvataggio dati...');
		DailyStatsClient.updateAllDailyStats(
			(data)=>{
				loadingSwal.close();
				ReactSwal.fire('Salvataggio effettuato con successo','','success').then(()=>{
					console.log("confirmed")
					this.fetchDailyData();
				});
			},
			(err) => {
				loadingSwal.close();
				ReactSwal.fire('Errore nel caricamento dati!','','error');
			},
			this.state.selectedCategory,
			dirtyArray
		)

	}

	getDirtyStats = () =>{
		let dirtyArray = [];

		this.state.data.forEach(row=>{
			row.dailyStats.forEach(innerRow =>{
				if(!!innerRow.dirty){
					dirtyArray.push(innerRow);
				}
			})
		})
		return dirtyArray;
	}
	createCSVFileName = () => {
		const {selectedStationName, allStations, interval : {start, end}, selectedCategory} = this.state;
		return `${MEASUREMENT_LABELS[selectedCategory]}
			${allStations ? 'ALL' : selectedStationName}
			${moment(new Date(start)).format("DDMMYYYY")}-${moment(new Date(end)).format("DDMMYYYY")}`;

	}
	downloadDataCsv = () => {
		this.tableRef.table.download("csv", `${this.createCSVFileName()}.csv`);
	};
	downloadDataPdf = () => {

		const {interval} = this.state;
		this.tableRef.table.download("pdf", "data.pdf", {
			orientation: "portrait",
			title:`Report statistiche giornaliere\nDal ${DateUtils.epochToDateMinuteResolution(interval.start)} al ${DateUtils.epochToDateMinuteResolution(interval.end)}`,
			autoTable: doc => {
				doc.autoTableSetDefaults( {
					addPageContent: ( data ) => {
						let footerStr = String(doc.internal.getNumberOfPages());
						doc.setFontSize( 10 );
						doc.text( footerStr, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10 );
						doc.setFontSize( 14 );
					}
				} );
				return {
					styles: {
						fillColor: [214,236,237],
						minCellWidth: 20
					},
					headStyles: {
						/*id: {
							fillColor: 255
						},*/
						textColor: [0,0,0]
					},
					columnStyles: {
						0: {cellWidth: 60},
						[this.tableRef.table.columnManager.columns.findIndex(col => col.field === 'validate')]: {cellWidth: 170}
					},
					margin: {
						top: 70,
						bottom: 70
					}
				}
			}
		});
	};

	addCalculatedFieldsToData = (data, stationMapByCode) => {
		data.forEach(row=>{
			row.date = moment(new Date(row.timestampKey)).format("DD/MM/YYYY")
			row.dailyStats.forEach(innerRow=>{
				innerRow.date = moment(new Date(innerRow.timestampKey)).format("DD/MM/YYYY")
				innerRow.stationName = stationMapByCode[innerRow.stationCode]
			})
		})
	}

	buildTable(){
		if(!this.state.data ){
			return (
				<>
					<Skeleton height={"10vh"} duration={8} />
					<Skeleton height={"70vh"} duration={8} />
				</>
			);
		}
		else{

			let stationLabel = <>{MEASUREMENT_CATEGORY_ICONS[this.state.selectedCategory]} {MEASUREMENT_LABELS[this.state.selectedCategory]} {!!this.state.selectedStationCode && "  -  "+this.state.selectedStationName}</>
			let title = <h3 >Dati giornalieri {stationLabel}</h3>

			return (
				<>
					<div className="row">
						<div className=" col-4 ml-auto my-auto">
							{title}
						</div>
						<div className=" col-2 d-flex justify-content-center m-auto">
							<MassiveValidationForm
								id="selectValidation"
								label="Seleziona una validazione"
								disabled={!this.isOfficer()}
								selectedCategory={this.state.selectedCategory}
								selectedMassiveValidation = {this.state.selectedMassiveValidation}
								handleMassiveValidation={(val)=> this.massiveValidation(val)}
							/>
						</div>
						<div className="col-2 d-flex justify-content-center m-auto">
							<Button
								startIcon = {<FaCalculator/>}
								size="small"
								disabled={!this.isOfficer()}
								variant="contained"
								color="primary"
								onClick={(event) => this.calculateDailyStats()}
							>Ricalcola</Button>
						</div>
						<div className="col-2 d-flex justify-content-center m-auto">
							<Button
								startIcon = {<FaFileCsv/>}
								size="small"
								variant="contained"
								color="primary"
								onClick={(event) => this.downloadDataCsv()}
							>Download csv</Button>
						</div>
						<div className="col-2 d-flex justify-content-center m-auto">
							<Button
								startIcon = {<FaFilePdf/>}
								size="small"
								variant="contained"
								color="primary"
								onClick={(event) => this.downloadDataPdf()}
							>Download pdf</Button>
						</div>
					</div>

					<div style={{width:"92vw"}}>
						<DailyStatsTables
							selectedCategory = {this.state.selectedCategory}
							data = {this.state.data}
							tableKey = {this.state.tableKey}
							calculateByDay = {elem => this.calculateByDay(elem)}
							calculateByDayAndStationCode = {elem => this.calculateByDayAndStationCode(elem)}
							nested = {this.state.allStations}
							updateTableKey = {()=>this.updateTableKey()}
							interpolateValue= {elem => this.interpolateValue(elem)}
							canEdit = {this.props.canEdit && this.isOfficer()}
							tableRef={el => this.tableRef = el}
							downloadData={() => this.downloadDataCsv()}
							label = {stationLabel}
						></DailyStatsTables>
					</div>
					<div className="d-flex">

						<Button
							size="small"
							className="justify-content-end ml-auto"
							variant="contained"
							color="primary"
							endIcon={<SendSharpIcon />}
							onClick={(event) =>  this.updateDailyStats()}
							disabled={this.getDirtyStats().length===0}
						>Salva</Button>
					</div>

				</>
			);
		}
	}

	updateTableKey = () =>{
		this.setState({
			tableKey : (this.state.tableKey + 1) % 1000
		})
	}

	onToggleAllStations = () => {
		const newAllStationsState = !this.state.allStations;
		this.setState({
			data : !!newAllStationsState ? null : this.state.data,
			allStations : newAllStationsState,
			selectedStationCode : null,
			selectedStationName : null,
			mapKey: (this.state.mapKey + 1) % 1000
		},()=>{this.fetchDailyData()})

	}

	massiveValidation = (val) =>{
		let data = this.state.data
		data.forEach(row=>{
			row.dirty=true;
			row.dailyStats.forEach(innerRow=>{
				if(!!!innerRow.historical){
					innerRow.dirty=true;
					innerRow.validate=val;
				}
			})
		})
		//this.addCalculatedFieldsToData(data, this.state.stationMapByCode)

		this.setState({
			data,
			tableKey : (this.state.tableKey + 1) % 1000
		})
	}
	calculateDailyStats = () => {
		let params = {
			start : this.state.interval.start.valueOf(),
			end : this.state.interval.end.valueOf(),
			category : this.state.selectedCategory
		}
		if(!this.state.allStations){
			params = {
				...params,
				stationCode : this.state.selectedStationCode
			}
		}

		loadingSwal.fire('Ricalcolo dati in corso...');
		DailyStatsClient.calculateDailyStatsByInterval(
			(data)=>{
				loadingSwal.close();
				data.forEach(row=>{
					row.dirty=true;
					row.dailyStats.forEach(innerRow=>{
						if(innerRow.validate!=="MISSING"){
							innerRow.dirty=true;
						}
					})
				})
				this.addCalculatedFieldsToData(data, this.state.stationMapByCode)

				this.setState({
					data,
					tableKey : (this.state.tableKey + 1) % 1000
				})
			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)

	}

	calculateByDay = (elem) => {
		let params = {
			day : elem.dailyStats[0].day,
			month : elem.dailyStats[0].month,
			year : elem.dailyStats[0].year,
			category : this.state.selectedCategory
		}

		loadingSwal.fire('Ricalcolo dati in corso...');
		DailyStatsClient.calculateDailyStatsByDate(
			(data)=>{
				loadingSwal.close();
				let newData = this.state.data;
				data.forEach(row=>{
					row.dirty=true;
					row.dailyStats.forEach(innerRow=>{
						if(innerRow.validate!=="MISSING"){
							innerRow.dirty=true;
						}
					})
					var index = _.findIndex(newData, {timestampKey: row.timestampKey});
					newData.splice(index, 1, row);

				})

				this.addCalculatedFieldsToData(newData, this.state.stationMapByCode)

				this.setState({
					data : newData,
					tableKey : (this.state.tableKey + 1) % 1000
				})

			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)
	}

	interpolateValue = (elem) =>{
		let params = {
			day : elem.day,
			month : elem.month,
			year : elem.year,
			category : this.state.selectedCategory,
			stationCode : elem.stationCode
		}

		loadingSwal.fire('Intepolazione dati in corso...');
		DailyStatsClient.interpolateDailyStatsByDate(
			(data)=>{
				loadingSwal.close();

				data.dirty=true;

				let newData = this.state.data;

				let currentDayData = newData.find(x=>x.timestampKey===data.timestampKey).dailyStats;

				var index = _.findIndex(currentDayData, {stationCode: data.stationCode});
				currentDayData.splice(index, 1, data);

				this.addCalculatedFieldsToData(newData, this.state.stationMapByCode)

				this.setState({
					data : newData,
					tableKey : (this.state.tableKey + 1) % 1000
				})

			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)
	}

	calculateByDayAndStationCode = (elem)=>{
		let params = {
			day : elem.day,
			month : elem.month,
			year : elem.year,
			category : this.state.selectedCategory,
			stationCode : elem.stationCode
		}

		loadingSwal.fire('Ricalcolo dati in corso...');
		DailyStatsClient.calculateDailyStatsByDate(
			(data)=>{
				loadingSwal.close();

				if(data[0].dailyStats[0].validate!=="MISSING"){
					data[0].dailyStats[0].dirty=true;
				}

				let newData = this.state.data;

				let currentDayData = newData.find(x=>x.timestampKey===data[0].timestampKey).dailyStats;

				var index = _.findIndex(currentDayData, {stationCode: data[0].dailyStats[0].stationCode});
				currentDayData.splice(index, 1, data[0].dailyStats[0]);

				this.addCalculatedFieldsToData(newData, this.state.stationMapByCode)

				this.setState({
					data : newData,
					tableKey : (this.state.tableKey + 1) % 1000
				})

			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)
	}

	toggleSidebar(event) {

		if (event==="over") {
			document.getElementById("mySidebar").style.width = "2.5rem";
			document.getElementById("main").style.marginLeft = "1.5rem";
			document.getElementById("sidebar-button").style.color = "#428f81";
		} else {
			document.getElementById("mySidebar").style.width = "1.5rem";
			document.getElementById("main").style.marginLeft = "0";
			document.getElementById("sidebar-button").style.color = chroma('#428f81').alpha(0.3)
		}

	}

	toggleGisPicker = () => {
		if (!this.state.showGisPicker){
			document.getElementById("main").style.marginLeft = '0px';
		}
		let newState = {
			showGisPicker: !this.state.showGisPicker
		};

		if(!this.state.showGisPicker){
			newState = {
				...newState,
				selectedCategory : "",
				allStations : false,
				selectedStationCode : null,
				selectedStationName : null,
				data : null,
				tableKey : (this.state.tableKey + 1) % 1000,
				mapKey : (this.state.mapKey + 1) % 1000,
				geojson : null,
				stationMapByCode : null,
				interval : null,
				year : defaultYear
			}
		}
		this.setState(newState)
	}


	render() {

		return (
			<>
				<div className="generic-page container-fluid data-page mt-4">
					<div className="row">
						<div id="mySidebar" className="sidebar" style={{display: this.state.showGisPicker ? 'none' : 'block'}}
							 onMouseOver={() => this.toggleSidebar("over")}
							 onMouseOut={() => this.toggleSidebar("out")}
							 onClick={() => this.toggleGisPicker()}>
							<Tooltip open={false} title={"Torna alla selezione"} >
								<><FaAngleLeft style={{height: 'inherit', width: 'inherit'}} id="sidebar-button" color={chroma('#428f81').alpha(0.3)}/></>
							</Tooltip>
						</div>
						<div className="col-4 custom-popup" style={{display: this.state.showGisPicker ? 'block' : 'none'}}>
							<div className="row">
								<div className="col-7">
									<SelectCategoryForm
										id="selectCat"
										label="Seleziona una categoria"
										selectedCategory={this.state.selectedCategory}
										handleSelectCategory={this.onCategoryChange}
									/>
								</div>
							</div>
							<div className="mt-2">
								{!!this.state.interval ?
									<div className="d-flex align-items-center">
										<div>
											<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab", display: 'block' }}>Intervallo selezionato:</span>
											<span style={{display: 'block'}}>{this.state.interval.start.format("DD/MM/YYYY")} - {this.state.interval.end.format("DD/MM/YYYY")}</span>
										</div>
										<div className="ml-4">
											<Tooltip title={"Reset Intervallo"}>
												<Button color="secondary" onClick={() =>this.resetInterval()} startIcon={<DeleteIcon />}/>
											</Tooltip>
										</div>
									</div>
									:

									<Button
										startIcon = {<FaRegCalendarAlt/>}
										variant="contained"
										color="default"
										onClick={(event) => this.openRangePickerModal()}
									>Seleziona Intervallo</Button>



								}
							</div>
							{!this.state.allStations && <div className="mb-4">
								<InputSelectComponent
									placeholder={"Selezione Stazione"}
									isSearchable={true}
									optionList={this.state.stationOptions}
									value={!!this.state.selectedStationCode ? this.state.selectedStationCode : null }
									onChange={(option) => this.onSelectStationOption(option)}
								/>
							</div>}
							<SelectAllStationForm
								className="mt-2"
								allStations={this.state.allStations}
								onToggleAllStations={(e)=>{this.onToggleAllStations()}}
								disabled={!!!this.state.geojson}
							/>
							<MapComponent
								width={"100%"}
								height={"65vh"}
								minZoom={7}
								maxZoom={12}>
									{/* ----------------------- MARKERS ----------------------- */}
									{!!this.state.geojson && <GeoJSON key={`${this.state.mapKey}`}
																	  data={this.state.geojson}
																	  onEachFeature={(feature, layer, context) => this.onEachFeatureStations(feature, layer, this)}
																	  pointToLayer={(feature, latlng, context) => this.pointToMarker(feature, latlng, this)}
									/>}
							</MapComponent>

						</div>


						<div key={this.state.tableKey} className={this.state.showGisPicker ? "col-8" : "col-12 ml-4 mr-4"} >
							<div className="custom-popup" id="main">

								{this.buildTable()}
							</div>
						</div>
					</div>
				</div>

				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						overflow:'scroll',
					}}
					open={this.state.openRangePicker}
					onClose={() => this.onCloseRangePickerModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "90vw",
							height: "90vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseRangePickerModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div className="mt-5 mb-5 ml-5 mr-5">

							<div className="justify-content-center align-items-center">
								<h3>Seleziona Intervallo di osservazione</h3>
								<BigCalendarTimeZoned
									timeZone={"Etc/GMT-1"} 
									range={this.state.interval} 
									year={this.state.year}
									onPrevYear={() => this.onPrevYear()}
									onNextYear={() => this.onNextYear()}
									onPickRangeCallback={(start, end, year)=> this.onPickRange(start, end, year)}
								></BigCalendarTimeZoned>
								
							</div>

						</div>
					</div>

				</Modal>

			</>
		)
	}


}

const SelectAllStationForm = (props) => {
	return (
		<>
			<SwitchComponent
				name={"Seleziona Tutte le Stazioni"}
				value={props.allStations}
				disabled={props.disabled}
				onChange={(e) => props.onToggleAllStations()}
			/>
		</>
	)
}

const SelectCategoryForm = (props) => {

	let options = [];
	options.push(<MenuItem key={"default"} value={""}></MenuItem>)
	options.push(<MenuItem key={"P"} value={"P"}>{MEASUREMENT_CATEGORY_ICONS.P}{MEASUREMENT_LABELS.P}</MenuItem>)
	options.push(<MenuItem key={"T"} value={"T"}>{MEASUREMENT_CATEGORY_ICONS.T}{MEASUREMENT_LABELS.T}</MenuItem>)
	options.push(<MenuItem key={"I"} value={"I"}>{MEASUREMENT_CATEGORY_ICONS.I}{MEASUREMENT_LABELS.I}</MenuItem>)

	let classname = !!props.className ? props.className : "col-12 row justify-content-center";

	return (
		<FormControl className = {classname} key={"select_category_form"} >
			<InputLabel id="h-helper-label">{props.label}</InputLabel>
			<Select
				disabled={false}
				fullWidth
				labelId={props.label}
				id={props.id}
				value={props.selectedCategory}
				onChange={(e)=>props.handleSelectCategory(e.target.value)}
			>
				{options}
			</Select>
		</FormControl>
	)
}


const MassiveValidationForm = (props) => {

	let validationOptions = null;

	switch (props.selectedCategory) {
		case "P":
			validationOptions = rainValidationOptions;
			break;
		case "T":
			validationOptions = temperatureValidationOptions;
			break;
		case "I":
			validationOptions = outflowValidationOptions;
			break;
		default:
			break;
	}

	let options = Object.keys(validationOptions).map(x=><MenuItem key={x} value={x}>{validationOptions[x]}</MenuItem>);

	let classname = !!props.className ? props.className : "col-12 row justify-content-center";

	return (
		<FormControl className = {classname} key={"select_validation_form"} >
			<InputLabel id="h-helper-label_2">{props.label}</InputLabel>
			<Select
				disabled={props.disabled}
				fullWidth
				labelId={props.label}
				id={props.id}
				value={props.selectedMassiveValidation}
				onChange={(e)=>props.handleMassiveValidation(e.target.value)}
			>
				{options}
			</Select>
		</FormControl>
	)
}

