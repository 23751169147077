import React from 'react';
import {Tab, ListGroup, Row, Col} from 'react-bootstrap'

export default class UserDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			listGroupItems : [],
			tabPanes : []
		}
	}

	componentDidMount() {
		const mapRoles = Object.entries(this.props.roles);
		let tabPanes = [];
		let listGroupItems = [];
		mapRoles.forEach((value, key) => {
			listGroupItems.push(ListGroupItems({key: key, value : value[1].roleDescription}));
			let listTabPanes = [];
			tabPanes.push(TabPane({key: key, value : listTabPanes}));
		});
		this.setState({
			listGroupItems : listGroupItems,
			tabPanes : tabPanes
		})

	}

	render() {
		return (
			<div className="card item-card transparent-card mb-4" >
				<div>
					<div className="container">
						<div className="map-container">
							<Tab.Container id="list-group-tabs-example" defaultActiveKey="#0">
								<Row>
									<Col sm={6}>
										<ListGroup>{this.state.listGroupItems}</ListGroup>
									</Col>
									<Col sm={6}>
										<Tab.Content>{this.state.tabPanes}</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


let ListGroupItems = (props) => {
	return <ListGroup.Item variant="info" action key={props.key} href={"#" + props.key}>{props.value}</ListGroup.Item>;
}

let TabPane = (props) => {
	return <Tab.Pane key={props.key} eventKey={"#" + props.key} className="nav-item">{props.value}</Tab.Pane>;
}