import React from 'react'
import {GeoJSON} from 'react-leaflet';
import L from 'leaflet';
import GISTools from '#/lib/GISTools';
import Tooltip from '@material-ui/core/Tooltip';
import "#/commons/data/section/year-calendar.css"

import CachedIcon from '@material-ui/icons/Cached';
import "#/backoffice/style/PolygonMapComponent.css"
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import MapComponent from "#/commons/map/MapComponent";

import _ from 'lodash';

export default class PolygonMapComponent extends React.Component {


	/*
	selectedFieldToCheck
	selected
	geoJSON
	*/
	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 7,
		maxZoom: 11,
		width: "40vw",
		height: "75vh"
	}
	constructor(props) {
		super(props);
		this.state = {
			zoneBbox: null
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let zoneBbox;
		if(!!this.props.zoomOnSelected){
			if (!!this.props.selected && !!this.props.geoJSON) {
				zoneBbox = GISTools.getBBoxFromPoints(GISTools.getFeatureByField(this.props.geoJSON, this.props.selectedFieldToCheck, this.props.selected));
			}
			else {
				zoneBbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
			}
			if (!_.isEqual(prevState.zoneBbox, zoneBbox)){
				this.setState({zoneBbox})
			}
		}
	}


	resetZoom() {
		let zoneBbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
		this.setState({zoneBbox},() => {
			if(!!this.props.resetSelection){
				this.props.resetSelection();
			}
		})
	}

	onEachPolygon(feature, layer) {
		if (!!this.props.spiValues){
			let popupContent = "";
			const key = this.props.geoJsonIdentifier;
			const zoneNumber = feature.properties[key];
			popupContent += `${key} : ${zoneNumber}</br>`;
			popupContent += `SPI : ${this.props.spiValues[zoneNumber][this.props.timeScale]}`
			layer.bindPopup(popupContent);

		} else {

			if (!!this.props.onSelect) {
				let keys = Object.keys(feature.properties);
				var popupContent = "";
				keys.forEach(key => {
					popupContent += `${key} : ${feature.properties[key]}</br>`;
				})

				layer.bindPopup(popupContent);

				layer.on('click', (e) => this.props.onSelect(feature.properties[this.props.selectedFieldToCheck]));
			}
		}

	}

	polygonStyle(feature, latlng) {
		if(!!this.props.polygonStyle){
			return this.props.polygonStyle(feature, latlng);
		}
		else{

			let style = null;
			let isSelected = !!this.props.selected && (feature.properties[this.props.selectedFieldToCheck] === this.props.selected);
			if (isSelected) {
				style = {
					fillColor: "#324aa8",
					weight: 0.2,
					opacity: 1,
					color: "blue",
					fillOpacity: 0.5
				};

			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.2,
					opacity: 1,
					color: "blue",
					fillOpacity: 0
				};
			}

			return style;
		}

	}
	pointToLayer(feature, latlng){
		if (!!this.props.timeScale){
			let marker = L.marker(latlng, {
				icon: L.divIcon({
					className: 'label',
					html: `<span style="font-size:1rem; font-weight:600">${feature.properties[this.props.geoJsonIdentifier]}</span>`,
				})
			});
			return marker;
		}
	}

	render() {


		let mapOptions = !!this.props.mapOptions ? this.props.mapOptions : this.mapOptions;
		let showIcons = !!!mapOptions.showIcons ? false : true;
		return (

			<div className="custom-popup">
				<div className="d-flex justify-content-between">
					{!!this.props.timeScale ? <></> : <h3>Mappa dei Comuni</h3>}
					<div>
						{showIcons ? <>
							<Tooltip title={"Reset Zoom"}>
								<ZoomOutMapIcon fontSize="large"
												style={{marginTop: "1.5rem", color: "grey", cursor: "pointer"}}
												onClick={() => this.resetZoom()}></ZoomOutMapIcon>
							</Tooltip>
							<Tooltip title={"Ricarica Comuni"}>
								<CachedIcon fontSize="large" style={{
									marginTop: "1.5rem",
									color: "grey",
									cursor: "pointer"
								}} onClick={() => this.props.reload()}></CachedIcon>
							</Tooltip>
						</> : <></>}

					</div>
				</div>
				<MapComponent
					key={this.props.mapKey}
					width={mapOptions.width}
					height={mapOptions.height}
					zoom={mapOptions.zoom}
					minZoom={mapOptions.minZoom}
					maxZoom={mapOptions.maxZoom}
					zoomSnap={false}
					zoomDelta={false}
					touchZoom={false}
					closePopupOnClick={false}


					zoomControl={mapOptions.zoomControl === false ? false : true}
					scrollWheelZoom={mapOptions.scrollWheelZoom === false ? false : true}
					dragging={mapOptions.dragging === false ? false : true}
					doubleClickZoom = {mapOptions.doubleClickZoom === false ? false : true}

					attributionControl={false}
					zoneBbox={this.state.zoneBbox}

					{...('noHome' in this.props ? {noHome: true} : {})}
				>
					<GeoJSON key={"polygons_" + this.props.id + "_" + this.props.timeScale}
							 data={this.props.geoJSON}
							 onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
							 style={(feature, latlng) => this.polygonStyle(feature, latlng)}
							 pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}

					/>
				</MapComponent>
			</div>


		)
	}
}


