import React from 'react';
import {withRouter} from 'react-router';
import DateUtils from '#/lib/DateUtils'
import {FiClock} from 'react-icons/fi';
import Fab from '@material-ui/core/Fab';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import {Badge, Modal} from 'react-bootstrap';
import {properties} from '#/properties';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '#/backoffice/style/SweetAlert.css';
import BulletinClient from '#/lib/BulletinClient';
import {ApproveButton} from "#/commons/components/forms/ApproveButton";
import {PublishButton} from "#/commons/components/forms/PublishButton";

import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon

} from "react-share";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import EditIcon from "@material-ui/icons/Edit";
import MauMapComponent from "#/commons/mau/MauMapComponent";

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
class BimItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modalState : {
			  show: false,
			  onHide : this.hideModal,
			},
		}
		const modalState = {...this.state.modalState};
		this.setState({modalState : modalState});
	}

	approve = (id) => {
		loadingSwal.fire('Approvazione in corso...')
		let params = {
			bulletinTypeName: 'BIM',
			id: id
		};
		BulletinClient.approveBulletin(
			(result) => {
				loadingSwal.close()
				ReactSwal.fire("Approvazione Bollettino Idrologico Mensile", "Bollettino approvato con successo","success")
					.then(result => {
						if (result.value && this.props.refreshList) {
							this.props.refreshList()
						}
					});
			},
			function (msg) {
				loadingSwal.close()
				ReactSwal.fire("Approvazione Bollettino Idrologico Mensile", msg, "error");
			},
			params
		);
	}

	publish = (id) => {

		let params = {
			bulletinTypeName: 'BIM',
			id: id,
			protocol : ""
		};

		loadingSwal.fire('Pubblicazione in corso...')
		BulletinClient.publishBulletin(
			(result) => {
				loadingSwal.close()
				let errors = result.errors || [];
				if (errors.length > 0){

					let swal_html = `<div>
										<h3>Bollettino pubblicato con successo</h3>
										<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
										<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
										`;
					errors.forEach(err => swal_html += `<li>${err}</li>`);
					swal_html += `</div></div>`;
					ReactSwal.fire({title:"Pubblicazione Bollettino Idrologico Mensile", html: swal_html})
						.then(result => {
							if (result.value && this.props.refreshList) {
								this.props.refreshList()
							}
						});
				} else {
					ReactSwal.fire("Pubblicazione Bollettino Idrologico Mensile", "Bollettino pubblicato con successo", "success")
						.then(result => {
							if (result.value && this.props.refreshList) {
								this.props.refreshList()
							}
						});
				}
			},
			function (res) {
				if(res.status===502){
					loadingSwal.close()

					ReactSwal.fire({
						title: 'Protocollo in errore. Inserisci Numero di Protocollo di emergenza',
						input: 'text',
						inputLabel: 'N° protocollo',
						showCancelButton: true,
						inputValidator: (value) => {
							if (!value) {
								return 'Inserisci Numero di Protocollo'
							}
						}
					}).then(result => {
						if (result.value){
							params.protocol = result.value;
							loadingSwal.fire('Pubblicazione in corso...')
							BulletinClient.publishBulletin(
								(result) => {
									loadingSwal.close()
									let errors = result.errors || [];
									if (errors.length > 0){
			
										let swal_html = `<div>
															<h3>Bollettino pubblicato con successo</h3>
															<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
															<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
															`;
										errors.forEach(err => swal_html += `<li>${err}</li>`);
										swal_html += `</div></div>`;
										ReactSwal.fire({title:"Pubblicazione Bollettino Idrologico Mensile", html: swal_html})
											.then(result => {
												if (result.value && this.props.refreshList) {
													this.props.refreshList()
												}
											});
									} else {
										ReactSwal.fire("Pubblicazione Bollettino Idrologico Mensile", "Bollettino pubblicato con successo", "success")
											.then(result => {
												if (result.value && this.props.refreshList) {
													this.props.refreshList()
												}
											});
									}
								},
								function (res) {
									loadingSwal.close()
									ReactSwal.fire("Pubblicazione Bollettino Idrologico Mensile", res.status, "error");
								},
								params
							);
							
						}
					})
				}
				else{
					loadingSwal.close()
					ReactSwal.fire("Pubblicazione Bollettino Idrologico Mensile", res.status, "error");
				}		
			},
			params
		);

		/*
		let params = {
			bulletinTypeName: 'BIM',
			id: id
		};
		ReactSwal.fire({
			title: 'Inserisci Numero di Protocollo',
			input: 'text',
			inputLabel: 'N° protocollo',
			showCancelButton: true,
			inputValidator: (value) => {
				if (!value) {
					return 'Inserisci Numero di Protocollo'
				}
			}
		}).then(result => {
			if (result.value) {
				params.protocol = result.value;
				loadingSwal.fire('Pubblicazione in corso...')
				BulletinClient.publishBulletin(
					(result) => {
						loadingSwal.close()
						let errors = result.errors || [];
						if (errors.length > 0){

							let swal_html = `<div>
												<h3>Bollettino pubblicato con successo</h3>
												<h3 style="color: red">Si sono verificati errori durante l'invio delle notifiche</h3>
												<div style="width: 90%; height: 40vh; overflow-y: auto" class="scrollbar scrollbar-primary">
												`;
							errors.forEach(err => swal_html += `<li>${err}</li>`);
							swal_html += `</div></div>`;
							ReactSwal.fire({title:"Pubblicazione Bollettino Idrologico Mensile", html: swal_html})
								.then(result => {
									if (result.value && this.props.refreshList) {
										this.props.refreshList()
									}
								});
						} else {
							ReactSwal.fire("Pubblicazione Bollettino Idrologico Mensile", "Bollettino pubblicato con successo", "success")
								.then(result => {
									if (result.value && this.props.refreshList) {
										this.props.refreshList()
									}
								});
						}
					},
					function (msg) {
						loadingSwal.close()
						ReactSwal.fire("Pubblicazione Bollettino Idrologico Mensile", msg, "error");
					},
					params
				);
			}
		});
		*/
	}

	delete = (id) => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione del Bollettino Idrologico del giorno {DateUtils.epochToLocaleDateFormatted(DateUtils.fromISO8601ToDate(this.props.createdAt).getTime())}?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'No'
		})
			.then((result) => {
				if (result.value) {
					loadingSwal.fire('Eliminazione in corso...')
					let params = {
						bulletinTypeName: 'BIM',
						id: id
					};
					BulletinClient.deleteBulletin (
						(result) => {
							loadingSwal.close()
							ReactSwal.fire('Cancellazione Bollettino Idrologico Mensile', 'Il record è stato eliminato con successo', 'success')
								.then(result => {
									if (result.value && this.props.refreshList) {
										this.props.refreshList()
									}
								});
						},
						(msg) => {
							loadingSwal.close()
							ReactSwal.fire('Errore cancellazione Bollettino Idrologico Mensile', 'error');
						},
						params
					)
				}
			})

	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	hideModal = () => {
		const modalState = {...this.state.modalState, show: false};
		this.setState({ modalState : modalState });
	  }
	
	showModal = () => {
		const modalState = {...this.state.modalState, show: true};
		this.setState({ modalState : modalState });
	}
	
	render() {
		let docUrl = !!this.props.id ? properties.url.bulletinDownloadFile + '/' + this.props.id + '/pdf' : "";
		const refMonth = !!this.props.jsonData.reference_month ? this.props.jsonData.reference_month : '';
		const refYear = !!this.props.jsonData.reference_year ? this.props.jsonData.reference_year : '';
		let todayTitle = "Bollettino Idrologico del mese di " + refMonth + ' ' + refYear;
		// TODO : Aggiungere controllo ruolo amministratore
		let isDeletable = this.props.isDeletable && this.isOfficer();

		return (
			<>
			<Card style={{ marginBottom: '0.7rem', borderRadius: '5px', border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }}>
				<Card.Header onClick={() => this.showModal()}>
					<div className="row" style={{ display: "flex" }}>
						<div className="col-sm">
							<div className="row">
								<span className="icon-span"> <FiClock /> Generato il: {DateUtils.epochToDate(this.props.createdAt)} </span>
								{(!!this.props.editable) ? this.getBadge(this.props) : <></>}
							</div>
							<div>
								<span className="item-title">{todayTitle}</span>
							</div>
						</div>
						<div className="col-sm d-flex justify-content-end align-items-center">
							<div className="d-flex justify-content-end">
								{!!this.props.editable
									?
									<>
										<TwitterShareButton
											className="my-auto mx-2"
											url={BulletinClient.getShareUrl(this.props.id)}
											title={todayTitle}
											hashtag={"#bollettinoidrologico"}
											onClick={(event) => {event.stopPropagation();}}
											disabled={!this.props.published || !this.isOfficer()}
										>
											<img src={"/img/icon-twitter-x-circle.svg"} alt="Twitter X"/>
										</TwitterShareButton>
										<FacebookShareButton
											className="my-auto mx-2"
											url={BulletinClient.getShareUrl(this.props.id)}
											quote={todayTitle}
											hashtag={"#bollettinoidrologico"}
											onClick={(event) => {event.stopPropagation();}}
											disabled={!this.props.published || !this.isOfficer()}
										>
											<FacebookIcon size={48} round={true} />
										</FacebookShareButton>
										<div className="my-auto mx-2">
											{!!this.props.id && !this.props.published  && this.getApprovePublishButton(this.props)}
										</div>
										<div className="my-auto mx-2">
											<Fab size="medium" title="Cancella" aria-label="Cancella" onClick={() => this.delete(this.props.id)} disabled={!isDeletable} 
												style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
											>
												<DeleteIcon style={{color: '#fff'}} />
											</Fab>
										</div>
									</>
									:
									<></>
								}
								<div className="my-auto mx-2" >
									<Fab size="medium" title="Scarica" aria-label="Scarica" href={docUrl} onClick={(event) => {event.stopPropagation();}}
										style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
									>
										<GetAppIcon  color={!!this.props.id ? 'action' : 'disabled'} style={{color: '#fff'}} />
									</Fab>
								</div>
							</div>
						</div>
					</div>
				</Card.Header>
				{/* <Accordion.Collapse eventKey={this.props.id} id={"mauAccordion_" + this.props.id}>
					<Card.Body>
						{this.props.details}
					</Card.Body>
				</Accordion.Collapse> */}
			</Card>
			{this.state.modalState.show && <this.ModalToggle {...this.state.modalState} />}
			</>
		)
	}

	getBadge = (props) => {
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		let published = props.published;
		let retValue = <></>;
		if (notApproved){
			retValue = <span> <Badge variant="secondary">In attesa di approvazione</Badge> </span>;
		} else if(onlyApproved){
			retValue = <span> <Badge variant="info">Approvato</Badge> </span>;
		} else if (published) {
			retValue = <span> <Badge variant="success">Pubblicato</Badge> </span>;
		}
		return (retValue);
	}

	getApprovePublishButton = (props) => {
		let retValue = <></>;
		let notApproved = !props.approved;
		let onlyApproved = props.approved && !props.published;
		if (notApproved){
			retValue = <ApproveButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={() => this.approve(props.id)}/>;
		} else if(onlyApproved){
			retValue = <PublishButton disabled={!this.isOfficer()} size="small" variant="contained" onClick={() => this.publish(props.id)}/>;
		}
		return (retValue);
	}

	ModalToggle = (props) => {
		let docUrl = !!this.props.id ? properties.url.bulletinDownloadFile + '/' + this.props.id + '/pdf' : "";
		const refMonth = !!this.props.jsonData.reference_month ? this.props.jsonData.reference_month : '';
		const refYear = !!this.props.jsonData.reference_year ? this.props.jsonData.reference_year : '';
		let todayTitle = "Bollettino Idrologico del mese di " + refMonth + ' ' + refYear;
	
		return (
			<Modal
				show={props.show}
				onHide={props.onHide}
				aria-labelledby="contained-modal-title-vcenter"
				size="xl"
				centered
			>
				<Modal.Header closeButton style={{border: 'none'}}>
					<Modal.Title id="contained-modal-title-vcenter">
						<div className='d-flex flex-column'>
							<span className="item-title">{todayTitle}</span>
							<span className="icon-span"> <FiClock /> Generato il: {DateUtils.epochToDate(this.props.createdAt)} </span>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='d-flex justify-content-center align-items-center overflow-auto'>
					{this.props.details}
				</Modal.Body>
				<Modal.Footer style={{border: 'none'}}>
					<div className="my-auto mx-2" >
						<Fab size="medium" title="Scarica" aria-label="Scarica" href={docUrl} onClick={(event) => {event.stopPropagation();}}
							style={{backgroundColor: '#2b7943', borderRadius: '5px', color: '#fff'}}
						>
							<GetAppIcon  color={!!this.props.id ? 'action' : 'disabled'} style={{color: '#fff'}} />
						</Fab>
					</div>
				</Modal.Footer>
			</Modal>
		);
	};
};
export default withRouter(BimItem)
