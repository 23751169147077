import {isMobile as isMobileRDD, isAndroid as isAndroidRDD, isIOS as isIosRDD} from 'react-device-detect'


export default class WidthUtils {
	static DIM_DOWN = {
		xs : 600,
		sm : 960,
		md : 1280,
		lg : 1920,
		xl : 999999999
	}
	static DIM_UP = {
		xs : 0,
		sm : 600,
		md : 960,
		lg : 1280,
		xl : 1920
	}

	static isWidthUp = (dim) => window.innerWidth > this.DIM_UP[dim];

	static isWidthDown = (dim) =>  window.innerWidth <= this.DIM_DOWN[dim];

	static isMobile = () => this.isWidthDown('sm') || isMobileRDD
}

