import React, {useState, useEffect} from "react";
import {withRouter} from 'react-router';
import {AlertLevelDisplayValues, AlertLevelPalette, AlertLevels,} from "#/lib/AlertLevel";
import BulletinClient from "#/lib/BulletinClient";
import Grid from "@material-ui/core/Grid";
import {properties} from '#/properties.js';

import SvgFormComponent from "#/commons/components/SvgFormComponent";
import JsonFormHolderComponent from "#/commons/components/JsonFormHolderComponent";
import DateUtils from "#/lib/DateUtils";
import {svgAsPngUri} from "save-svg-as-png/lib/saveSvgAsPng";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {Button} from "react-bootstrap";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import MobileStepper from '@material-ui/core/MobileStepper';
import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton} from "#/commons/components/forms/NextButton";
import {SaveButton} from "#/commons/components/forms/SaveButton";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dropzone from 'react-dropzone-uploader';
import Modal from "@material-ui/core/Modal";
import _ from "lodash";
import FireRiskBuilder from "#/backoffice/fireRisk/FireRiskBuilder";
import {SelectForm} from "#/commons/components/forms/SelectForm";
import {GeoJSON} from "react-leaflet";
import GISTools from "#/lib/GISTools";
import MapComponent from "#/commons/map/MapComponent";
import L from "leaflet";
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";
import FireRiskClient from "#/lib/FireRiskClient";
import StationClient from "#/lib/StationClient";
import {StepRecipients} from "#/backoffice/mau/steps/StepRecipients";
import MosipClient from "#/lib/MosipClient";
import domtoimage from "dom-to-image-more";
import ObjectManager from "#/lib/ObjectManager";
import WorkItemClient from "#/lib/WorkItemClient";

const ReactSwal = withReactContent(Swal);
const ZONES_DESC = ["", "Tirreno Settentrionale","Tirreno Centro-Settentrionale","Tirreno Centro-Meridionale","Tirreno Meridionale","Ionio Settentrionale","Ionio Centro-Settentrionale","Ionio Centro-Meridionale","Ionio Meridionale"]

const DAY = {
  TODAY : 'oggi',
  TOMORROW : 'domani'
}
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});


class MaibEditorMultiPage extends React.Component {
  defaultValues = {};
  steps = [
    "Recupero mappe generate automaticamente",
    "Mappa della suscettività all'innesco - Oggi",
    "Mappa della suscettività all'innesco - Domani",
    "Suscettività all'innesco e alla propagazione degli incendi boschivi",
    "Informazioni meteoclimatiche sintetiche",
    "Destinatari"
  ];
  constructor(props) {
    super(props);
    const url = window.location.href;
    const idString = url.split("/").pop();
    const id = parseInt(idString);
    this.state = {
      id: !!id ? id : null,
      bulletinTypeName: "MAIB",
      bulletinWebTemplateCode: null,
      todayId: null,
      tomorrowId: null,
      generalId: null,
      maibFormConf: {},
      maibFormState: {},
      approved: false,
      published: false,
      activeStep: 0,
      geoInfo: {
        geoJsonIdentifier: 'ZONE', geoJson: zonesGeoJson8
      },
      addressTo: []
    };
  }

  componentDidMount() {
    let promises = [];
    let schemaPromise = new Promise((resolve, reject) => {
      BulletinClient.getSchema(
          (schema) => {
            resolve({
              schema,
            });
          },
          (error) => {
            reject({
              error,
            });
          },
          this.state.bulletinTypeName
      );
    });
    promises.push(schemaPromise);

    if (!!this.state.id) {
      let getBulletinPromise = new Promise((resolve, reject) => {
        BulletinClient.getBulletin(
            (data) => {
              resolve({data});
            },
            (error) => {
              reject({error});
            },
            {
              id: this.state.id,
              bulletinTypeName: this.state.bulletinTypeName,
            }
        );
      });
      promises.push(getBulletinPromise);
    } else {
      let isCreationAllowedTodayPromise = new Promise((resolve, reject) => {
        BulletinClient.isCreationAllowedToday(
            (isCreationAllowedToday) => {
              resolve({isCreationAllowedToday:true});
            },
            (error) => {
              reject({isCreationAllowedToday:false});
            },
            this.state.bulletinTypeName
        );
      });
      promises.push(isCreationAllowedTodayPromise);
    }

    let result = {};
    promises.push(this.getMunicipalitiesPromise());

    Promise.all(promises).then((values) => {

      Object.assign(result, ...values);
      this.getFormSuccessCallBack(result);
    });
  }

  getMunicipalitiesPromise = () => new Promise((resolve, reject) => {
    MosipClient.getAllMunicipalities((municipalitiesData) => { resolve({municipalitiesData}); }, () => { console.log("ERROR COMUNI"); });
  })
  getFormSuccessCallBack = (result) => {

    let {schema, data, municipalitiesData} = result;
    let isCreationAllowedToday = result.isCreationAllowedToday;

    let bulletinWebTemplateCode = schema.code;
    let maibFormConf = schema.jsonSchema;
    let todayId = schema.jsonSchema.maib_today.id;
    let tomorrowId = schema.jsonSchema.maib_tomorrow.id;
    let generalId = schema.jsonSchema.maib_general.id;
    this.defaultValues = schema.jsonData;
    let id;
    let initValues;
    let todayDate;
    let approved = this.state.approved;
    let published = this.state.published;

    let activeStep = this.state.activeStep;

    if (!!data) {
      id = data.id;
      initValues = data.jsonData;
      approved = data.approved;
      published = data.published;
      todayDate = DateUtils.fromISO8601ToDate(data.createdAt);
      activeStep = 3;
    } else {
      if (isCreationAllowedToday) {
        initValues = schema.jsonData;
        todayDate = new Date();
      } else {
        this.goToListPage();
      }
    }

    let todayTitle =
        "maib del giorno : " +
        DateUtils.epochToLocaleDateFormatted(todayDate.getTime());
    let tomorrowTitle =
        "maib del giorno : " +
        DateUtils.epochToLocaleDateFormatted(
            todayDate.getTime() + 24 * 60 * 60 * 1000
        );
    let addressTo = initValues.address_to || [];


    let municipalitiesJSON = municipalitiesData && municipalitiesData.length > 0 ? this.buildGeoJSONFromArray(municipalitiesData) : null;

    this.setState({
      id: id,
      bulletinWebTemplateCode: bulletinWebTemplateCode,
      municipalitiesJSON,
      todayId: todayId,
      tomorrowId: tomorrowId,
      generalId: generalId,
      maibFormConf: maibFormConf,
      maibFormState: initValues,
      approved: approved,
      published: published,
      todayTitle: todayTitle,
      tomorrowTitle: tomorrowTitle,
      todayDate : todayDate.getTime(),
      tomorrowDate : todayDate.getTime() + 24 * 60 * 60 * 1000,
      addressTo,
      activeStep
    });
  };
  buildGeoJSONFromArray(data) {
    let geoJSON = {};
    geoJSON.type = "FeatureCollection";
    geoJSON.name = "Municipalities";
    geoJSON.features = [];

    data.forEach(value => {
      let feature = {};
      feature.type = "Feature";
      feature.geometry = JSON.parse(value.polygonGeojson);
      feature.properties = {
        COMUNE: value.name,
        ISTAT: value.istatCode,
        PROVINCIA: value.province,
      }
      geoJSON.features.push(feature);

    })
    return geoJSON;
  }
  goToListPage = () => {
    this.props.history.push("/backoffice/maib");
  }

  _getNextLevel = (currentLevel) => {
    switch (currentLevel) {
      case AlertLevels.NONE:
        return AlertLevels.LOW;
      case AlertLevels.LOW:
        return AlertLevels.MEDIUM;
      case AlertLevels.MEDIUM:
        return AlertLevels.HIGH;
      case AlertLevels.HIGH:
        return AlertLevels.NONE;
      default:
        return AlertLevels.NONE;
    }
  };

  reset(e) {
    e.preventDefault();
    this.setState({
      maibFormState: this.defaultValues,
    });
  }

  svgToPngPromise = (id, svgImage) => {
    let imgOptions = {
      encoderOptions: 1,
      scale: 2,
    };
    let converterPromise = new Promise((resolve, reject) => {
      svgAsPngUri(svgImage, imgOptions, (uri) => {
        resolve({
          [id]: uri,
        });
      });
    });
    return converterPromise;
  };
  divToPngPromise = (id) =>
      new Promise((resolve, reject) => {
        domtoimage
            .toPng(document.getElementById(id), null)
            .then((uri) => {
              resolve({
                uri
              })
            })
            .catch((error) => {
              console.error("oops, something went wrong!", error);
            });
      })

  send = (mail_addresses, to_include) => {

    loadingSwal.fire('Archiviazione del bollettino in corso...');

    let maibFormState = this.state.maibFormState;
    let zones = [];

    Object.keys(maibFormState.maib_today).forEach(function(key) {
      let zona = {
        "zone" : "Zona " + key,
        "desc" : ZONES_DESC[key],
        "start": AlertLevels[maibFormState.maib_today[key]],
        "end" : AlertLevels[maibFormState.maib_tomorrow[key]]
      }
      zones.push(zona);
    });

    let jsonData = {
      /*
              "address_to" : ["S.O.U.P. - Catanzaro","Centro Operativo Regionale: Catanzaro","C.O.P.: Catanzaro - Cosenza - Crotone - Reggio Calabria - Vibo Valentia","Sindaci dei Comuni Calabresi: Loro Sedi","Sala Operativa Regionale di Protezione Civile: Catanzaro","Direzione Regionale del Corpo Nazionale dei Vigili del Fuoco: Catanzaro","Comando Regione Carabinieri Forestale Calabria: Reggio Calabria","Calabria Verde: Catanzaro","Enel - Terna - Ente Parchi: Aspromonte, Pollino, Serre, Sila","e per conoscenza","Dipartimento della Protezione Civile Nazionale: Roma","Prefettura - U.T.G.: Catanzaro - Cosenza - Crotone - Reggio Calabria - Vibo Valentia"],
      */
      address_to: this.state.addressTo,
      "start" : {
        "date_short" : DateUtils.epochToLocaleDateFormatted(this.state.todayDate),
        "date_long" : DateUtils.epochToLongLocaleDateFormatted(this.state.todayDate),
        "rainfall" : maibFormState.maib_general.precipitazioni_oggi,
        "temperature" : maibFormState.maib_general.temperature_oggi,
        "wind" : maibFormState.maib_general.venti_oggi,
        "img" : this.state.todayUri
      },
      "end" : {
        "date_short" : DateUtils.epochToLocaleDateFormatted(this.state.tomorrowDate),
        "date_long" : DateUtils.epochToLongLocaleDateFormatted(this.state.tomorrowDate),
        "rainfall" : maibFormState.maib_general.precipitazioni_domani,
        "temperature" : maibFormState.maib_general.temperature_domani,
        "wind" :  maibFormState.maib_general.venti_domani,
        "img" : this.state.tomorrowUri
      },
      "zones" : zones,
      mail_addresses,
      to_include
    }

    let params = {
      bulletinId: this.state.id,
      trigger : this.props.trigger,
      documentTypeName: this.state.bulletinTypeName,
      jsonData: JSON.stringify(jsonData),
    };

    BulletinClient.saveBulletin(
        (result) => {
          this.setState({
            id: result.id,
            saveStatus: 'maib archiviato con successo.',
          },()=>{
            loadingSwal.close();
            ReactSwal.fire(
                {
                  icon: 'success',
                  title: 'Maib archiviato con successo.',
                  html: 'Ti Stiamo Reindirizzando alla lista dei maib',
                  timer: 4000,
                  confirmButtonText: `OK`,
                })
                .then(() => {
                      WorkItemClient.getWorkItemsState(
                          (data) => {
                            const originalSetItem = localStorage.setItem;
                            localStorage.setItem = function(key, value) {
                              const event = new Event('workItemsUpdated');

                              document.dispatchEvent(event);
                              originalSetItem.apply(this, arguments);
                            };
                            localStorage.setItem("workItemsState", JSON.stringify(data));
                            this.goToListPage();
                          }
                          ,() => {
                            console.log("Error while retrieving work items data");
                            this.goToListPage();
                          }
                      )
                    }
                );
          });
        },
        (msg) => {
          this.setState({
            saveStatus: "Si è verificato un errore durante l'archiviazione del maib",
          }, () =>{
            loadingSwal.close();
            ReactSwal.fire(
                {
                  icon: 'error',
                  title: 'Errore Maib',
                  html: 'Si è verificato un errore',
                  timer: 4000,
                  confirmButtonText: `OK`,
                })
          });
        },
        params
    );
  };

  approve = () => {
    let params = {
      id: this.state.id,
      bulletinTypeName: this.state.bulletinTypeName,
    };
    BulletinClient.approveBulletin(
        (result) => {
          this.setState({
            approved: true,
          });
          ReactSwal.fire(
              "Approvazione maib",
              "maib approvato con successo",
              "success"
          );
        },
        function (msg) {
          ReactSwal.fire("Approvazione maib", msg, "error");
        },
        params
    );
  }


  onMaibFormStateChange = (param, data) => {

    let maibFormState = this.state.maibFormState;
    let newState = Object.assign({}, maibFormState[param], data.formData);
    maibFormState[param] = newState;
    this.setState({
      maibFormState: maibFormState,
    });
  };

  onSvgClickActions = (param) => {
    return {
      1: () => this.onSvgZoneClick(param, 1),
      2: () => this.onSvgZoneClick(param, 2),
      3: () => this.onSvgZoneClick(param, 3),
      4: () => this.onSvgZoneClick(param, 4),
      5: () => this.onSvgZoneClick(param, 5),
      6: () => this.onSvgZoneClick(param, 6),
      7: () => this.onSvgZoneClick(param, 7),
      8: () => this.onSvgZoneClick(param, 8),
    };
  };

  onSvgZoneClick(param, id) {
    let data = {};
    data.formData = {};
    data.formData[id] = this._getNextLevel(this.state.maibFormState[param][id]);
    this.onMaibFormStateChange(param, data);
  }

  handleNext = () => {
    const prevActiveStep = this.state.activeStep;
    this.setState({
      activeStep: prevActiveStep + 1,
    });
  };

  handleBack = () => {
    const prevActiveStep = this.state.activeStep;
    this.setState({
      activeStep: prevActiveStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      saveStatus: "",
    });
  };

  handleNextStep3 = () => {
    const maibFormState = this.state.maibFormState;
    const today_includes_none = Object.values(
        maibFormState[this.state.todayId]
    ).includes("NONE");
    const tomorrow_includes_none = Object.values(
        maibFormState[this.state.tomorrowId]
    ).includes("NONE");
    if (!today_includes_none && !tomorrow_includes_none) {
      Promise.all([this.divToPngPromise(this.state.todayId), this.divToPngPromise(this.state.tomorrowId)])
          .then(([todayUri, tomorrowUri]) => {

            this.setState({todayUri: todayUri.uri, tomorrowUri: tomorrowUri.uri});
            this.handleNext();
          })

    } else {
      ReactSwal.fire(
          "Redazione maib - Step 1",
          "Popolare tutti i campi",
          "error"
      );
    }
  };

  handleNextStep4 = () => {
    const maibFormState = this.state.maibFormState;
    const infos_no_values = Object.values(
        maibFormState[this.state.generalId]
    ).includes("");
    if (!infos_no_values) {
      this.handleNext()
    } else {
      ReactSwal.fire(
          "Redazione maib - Step 4",
          "Popolare tutti i campi",
          "error"
      );
    }
  };
  handleNext0 = geoInfo => {
    this.setState({geoInfo}, () => this.handleNext())
  }
  handleNext1 = (zonalMap, todaySettingsCache) => {
    this.setState({todayMap: zonalMap, todaySettingsCache}, () => this.handleNext())
  }
  handleSkipToStep3 = (maps) => {
    if (maps) {
      let {todayMap, tomorrowMap} = maps;
      let {maibFormState} = this.state;
      maibFormState.maib_today = Object.values(todayMap).reduce((map, {code, mean}) => {
        map[code] = this.findMaibLevelByFireSusceptibility(mean);
        return map;
      }, {});
      maibFormState.maib_tomorrow = Object.values(tomorrowMap).reduce((map, {code, mean}) => {
        map[code] = this.findMaibLevelByFireSusceptibility(mean);
        return map;
      }, {});
    }
    this.setState({
      activeStep: 3,
      cachedMapsInUse: true
    });

  }
  findMaibLevelByFireSusceptibility = meanVal => {
    if (meanVal >= 0.8){
      return AlertLevels.HIGH;
    } else if (meanVal >= 0.4){
      return AlertLevels.MEDIUM
    } return AlertLevels.LOW
  }
  handleNext2 = (zonalMap, tomorrowSettingsCache) => {
    let {maibFormState, todayMap} = this.state;
    maibFormState.maib_today = Object.values(todayMap).reduce((map,{code, mean}) => {
      map[code] = this.findMaibLevelByFireSusceptibility(mean);
      return map;
    },{});
    maibFormState.maib_tomorrow = Object.values(zonalMap).reduce((map,{code, mean}) => {
      map[code] = this.findMaibLevelByFireSusceptibility(mean);
      return map;
    },{});
    this.setState({tomorrowMap: zonalMap, tomorrowSettingsCache, maibFormState}, () => this.handleNext())
  }
  getStepContentComponent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Step0
            handleNext={() => this.handleNext()}
            skipToStep3={(maps) => this.handleSkipToStep3(maps)}
            textNextButton={"Successivo"}
            disableBackButton={true}
        />
      case 1:
        return <StepSingleDayMap
            key={'single_day_' + stepIndex}
            geoInfo={this.state.geoInfo}
            activeStep={stepIndex}
            totalSteps={this.steps.length}
            handleNext={this.handleNext1}
            textNextButton={"Successivo"}
            disableBackButton={true}
            handleBack={this.handleBack}
            day={DAY.TODAY}
            dayRelatedSettingsCache={this.state.todaySettingsCache}
        />
      case 2:
        return <StepSingleDayMap
            key={'single_day_' + stepIndex}
            geoInfo={this.state.geoInfo}
            activeStep={stepIndex}
            totalSteps={this.steps.length}
            handleNext={this.handleNext2}
            textNextButton={"Successivo"}
            disableBackButton={false}
            handleBack={this.handleBack}
            day={DAY.TOMORROW}
            dayRelatedSettingsCache={this.state.tomorrowSettingsCache}
        />
      case 3:
        return (

            <Step3
                levels={AlertLevels}
                displayValues={AlertLevelDisplayValues}
                palette={AlertLevelPalette}
                stepLabel={this.steps[stepIndex]}
                todayId={this.state.todayId}
                todayTitle={this.state.todayTitle}
                todaySvgUrl={this.state.maibFormConf[this.state.todayId].svgUrl}
                todayBaseZoneId={this.state.maibFormConf[this.state.todayId].baseZoneId}
                todayStateByZone={this.state.maibFormState[this.state.todayId]}
                todayFormSchema={this.state.maibFormConf[this.state.todayId].jsonFormSchema}
                tomorrowId={this.state.tomorrowId}
                tomorrowTitle={this.state.tomorrowTitle}
                tomorrowSvgUrl={this.state.maibFormConf[this.state.tomorrowId].svgUrl}
                tomorrowBaseZoneId={this.state.maibFormConf[this.state.tomorrowId].baseZoneId}
                tomorrowStateByZone={this.state.maibFormState[this.state.tomorrowId]}
                tomorrowFormSchema={this.state.maibFormConf[this.state.tomorrowId].jsonFormSchema}
                onZoneClickActions={this.onSvgClickActions}
                onZoneClickActions2={(param, zoneId) => this.onSvgZoneClick(param, Number(zoneId))}
                municipalitiesJSON={this.state.municipalitiesJSON}
                onFormDataChange={this.onMaibFormStateChange}
                disableBackButton={this.props.edit || this.state.cachedMapsInUse}
                textNextButton="Successivo"
                handleNext={this.handleNextStep3}
                activeStep={stepIndex}
                totalSteps={this.steps.length}
            />
        );
      case 4:
        return (
            <Step4
                generalId={this.state.generalId}
                stepLabel={this.steps[stepIndex]}
                formSchema={this.state.maibFormConf[this.state.generalId].jsonFormSchema}
                formData={this.state.maibFormState[this.state.generalId]}
                onFormDataChange={this.onMaibFormStateChange}
                disableBackButton={false}
                handleBack={this.handleBack}
                handleNext={this.handleNextStep4}
                id={this.state.id}
                approved={this.state.approved}
                published={this.state.published}
                activeStep={stepIndex}
                totalSteps={this.steps.length}
            />
        );
      case 5:
        return (
            <StepRecipients
                stepLabel={this.steps[stepIndex]}
                viewMode={false}
                formData={{address_to: this.state.addressTo}}
                handleNext={(mail_addresses, to_include) => this.send(mail_addresses, to_include)}
                onFormChange={(section, zoneId, name, addressTo)  => this.setState({addressTo})}
                activeStep={stepIndex}
                totalSteps={this.steps.length}
                handleBack={this.handleBack}
                disableBackButton={false}
            />
        )
      default:
        return (
            <div>
              <h3>Step scononosciuto</h3>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
        );
    }
  };

  render() {
    let downloadLink = !!this.state.id ? properties.url.bulletinDownloadFile + '/' + this.state.id + '/pdf' : null;

    return (
        <div className="generic-page container-fluid data-page" >
          <div>
            <Stepper activeStep={this.state.activeStep} alternativeLabel>
              {this.steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
              ))}
            </Stepper>
            <div>
              {this.state.activeStep === this.steps.length ? (
                  <>
                    <div className="mt-2 row justify-content-center">
                      <h3>{this.state.saveStatus}<br/><a href={downloadLink}>Scarica file</a></h3>
                    </div>
                    <BackButton
                        variant="contained"
                        textbutton="Torna alla prima pagina"
                        onClick={this.handleReset}
                    />
                  </>
              ) : (
                  <div>
                    {!!this.state.todayId &&
                    !!this.state.tomorrowId &&
                    !!this.state.generalId &&
                    this.getStepContentComponent(this.state.activeStep)}
                  </div>
              )}
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(MaibEditorMultiPage);

const selectOptions = [
  { value: "", label: "Seleziona", identifier : null },
  {
    label : "8 zone",
    value : zonesGeoJson8,
    identifier : "ZONE"
  },
  {
    label : "Carica nuove zone",
    value : "NEW",
    identifier : "__NEW__"
  }];
export class GeoJsonPicker extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      mapKey: 1,
      file: null,
      zoneGeoJson: null,
      identifierCandidates: null,
      geoJsonIdentifier: null,
      cachedMapsInUse: false,
    }
  }
  hasDuplicates = array => {
    return (new Set(array)).size !== array.length;
  }
  findIdentifierCandidates = (geoJSON) => {
    let identifiers = [];
    const features = geoJSON.features;
    if (!!features) {
      const feature = features[0];
      const keys = Object.keys(feature.properties);
      keys.forEach(key => {
        let allValues = features.map(feature => feature.properties[key])
        if (!this.hasDuplicates(allValues)){
          identifiers.push(key)
        }
      })
    }
    return identifiers;
  }
  openModal = () => {
    this.setState({showModal: true});
  }
  onCloseModal = () => {
    this.setState({showModal: false});
  }
  addCentroidGeoJSON(geoJSON) {
    if (!!geoJSON){
      GISTools.addCentroids(geoJSON)
    }
  }
  handleSelectGeoJSON = (valueSelected) => {
    let options = selectOptions;
    let selectedOption = options.filter(opt => opt.value === valueSelected);
    let geoJSON = selectedOption[0].value;
    let geoJsonIdentifier = selectedOption[0].identifier;
    if (geoJsonIdentifier==="__NEW__" && geoJSON === "NEW") {
      this.openModal();
    } else {
      let clonedGeoJson = _.cloneDeep(geoJSON)
      this.addCentroidGeoJSON(clonedGeoJson);
      this.setState({
        zoneGeoJson: clonedGeoJson,
        mapKey: (this.state.mapKey + 1) % 1000,
        geoJsonIdentifier: geoJsonIdentifier
      }, () =>{
        this.props.sendGeoJsonInfo({geoJsonIdentifier, geoJson: this.state.zoneGeoJson })
      })
    }
    this.hasBeenModified = true;
  };
  handleChangeStatusZone = ({ file }, status) => {
    if (status === "removed") {
      this.setState({
        zoneGeoJson: null,
        identifierCandidates: null,
        file : null,
        statsData: [],
        interpolatedData : null,
        enableSave: false
      })
    }
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target.result;
        let geoJSON = JSON.parse(fileContent);
        // Check keys
        let identifierCandidates = this.findIdentifierCandidates(geoJSON);
        if (identifierCandidates.length > 0)
        {
          let clonedGeoJson = _.cloneDeep(geoJSON)

          this.setState({
            file: file,
            zoneGeoJson: clonedGeoJson,
            mapKey: (this.state.mapKey + 1) % 1000,
            identifierCandidates: identifierCandidates
          })
        }
      };
      reader.onerror = (event) => {
        ReactSwal.hideLoading();
        ReactSwal.fire('Si è verificato un errore nella lettura delle zone', '', 'error');
      };
      reader.readAsText(file);
    }
  }
  pointToLayer(feature, latlng){
    let marker = L.marker(latlng, {
      icon: L.divIcon({
        className: 'label',
        html: `<span style="font-size:1rem; font-weight:600">${feature.properties[this.state.geoJsonIdentifier]}</span>`,
      })
    });
    return marker;

  }
  onEachPolygon = (feature, layer) => {
  }
  render = () => {
    return (
        <>
          <div className="row">
            <div className="col-4">
              <SelectForm
                  id="select_default_GeoJSON"
                  label="Seleziona file di zone"
                  className="mt-3 col-8 row justify-content-center"
                  options={selectOptions}
                  selectedValue = {this.state.zoneGeoJson}
                  handleOnChange = {this.handleSelectGeoJSON}
              />
            </div>
            <div className="col-8" >
              <MapComponent
                  width={"100%"}
                  height={"60vh"}
                  backgroundColor={'rgba(255,0,0,0.0)'}
                  zoomSnap={false}
                  dragging={false}
                  scrollWheelZoom = {false}
                  zoomControl = {false}
                  doubleClickZoom ={false}
                  noHome={true}
                  setMapRef={mapRef => this.mapRef = mapRef}>

                {!!this.state.zoneGeoJson ?
                    <GeoJSON key={"polygons_" + this.state.mapKey}
                             data={this.state.zoneGeoJson}
                             onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
                             style={{
                               fillColor:  "#fff0",
                               weight: 0.6,
                               opacity: 1,
                               color: "blue",
                               fillOpacity: 0.5
                             }}
                             pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}

                    /> : <></>}
              </MapComponent>
            </div>
          </div>

          <Modal
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              open={this.state.showModal}
              onClose={() => this.onCloseModal()}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
          >
            <div
                style={{
                  background: "white",
                  width: "50vw",
                  height: "50vh"
                }}
            >
              <div className='w-100 d-flex justify-content-end'>
                <IconButton  aria-label="close" onClick={() => this.onCloseModal()}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="d-flex justify-content-center">
                <h3>Import Zone da file</h3>
              </div>

              <div className="d-flex h-80">
                <div className="col-sm-12 my-auto">
                  <Dropzone
                      {...this.state.file && {initialFiles: [this.state.file]}}
                      onChangeStatus={this.handleChangeStatusZone}
                      accept=".geojson,.json"
                      maxFiles={1}
                      inputContent="Inserisci il file delle zone in formato GeoJSON"
                      styles={{
                        dropzone: {overflow: "hidden", width: "30vw"},
                        dropzoneReject: {borderColor: 'red', backgroundColor: '#DAA'},
                        inputLabel: (files, extra) => (extra.reject ? {color: 'red'} : {color: "#315495"}),
                      }}
                  />
                </div>
              </div>
              <br/>
              {!!this.state.identifierCandidates ?
                  <>
                    <div className="d-flex justify-content-center">
                      {this.state.identifierCandidates.length > 0 ?
                          <h3>Scegli il campo che identifica le geometrie zonali:</h3> :
                          <h3 style={{color: "red"}}>Il file caricato non contiene alcun campo identificativo. Caricare un
                            file valido.</h3>
                      }
                    </div>
                    <div className="d-flex justify-content-center">
                      {this.state.identifierCandidates.map(key =>
                          <Button
                              key={key}
                              size="medium"
                              className="col-sm-2 my-auto"
                              variant="contained"
                              color="primary"
                              onClick={(e) => this.setState({
                                mapKey: (this.state.mapKey + 1) % 1000,
                                geoJsonIdentifier: key,
                              }, () => this.props.sendGeoJsonInfo({geoJsonIdentifier: key, geoJson: this.state.zoneGeoJson }))}
                          >{key}</Button>
                      )}
                    </div>
                  </> : <></>
              }
            </div>
          </Modal>
        </>
    )
  }
}


const Step0 = ({handleNext, skipToStep3}) => {

  const [initialized, setInitialized] = useState(false)
  const [maps, setMaps] = useState(null);
  useEffect(() => {
	if(!!initialized){


	console.log(_.isEmpty(maps), maps)
    loadingSwal.close();
    if (_.isEmpty(maps)) {
      ReactSwal.fire({
        title: 'Nessun mappa trovata',
        text: 'Procedere con una delle seguenti opzioni',

        confirmButtonText: `Creare le mappe manualmente procedendo al quarto step`,
        confirmButtonColor: '#2b7943',

        showCancelButton: true,
        cancelButtonText: 'Creare le mappe tramite la funzione di calcolo di rischio incendi',
        cancelButtonColor: '#d33',

        reverseButtons: true
      })
          .then((result) => {
            if (result.isConfirmed) {
              skipToStep3();
            } else {
              handleNext();
            }
          })
    } else {
      ReactSwal.fire({
        title: 'Sono state trovate le mappe del MAIB di oggi e domani',
        text: 'Procedere con una delle seguenti opzioni',


        confirmButtonText: 'Utilizza ultimo run modello suscettività incendi',
        confirmButtonColor: '#2b7943',

        showDenyButton: true,
        denyButtonText: 'Crea modello MAIB manualmente',
        denyButtonColor: '#3399dd',

        showCancelButton: true,
        cancelButtonText: 'Crea una nuova mappa di suscettività (Utenti Esperti)',
        cancelButtonColor: '#d33',


      }).then((result) => {

        if (result.isConfirmed) {
          skipToStep3(maps);
        } else if (result.isDenied){
          skipToStep3();
        } else {
          handleNext()
        }
      })
    }
	}
  },[maps, initialized])

  useEffect(() => {
    loadingSwal.fire('Ricerca delle mappe di suscettività in corso...');
    FireRiskClient.getTodayMaps(
        (fireMaps) => {
          if (!_.isEmpty(fireMaps)){
            const {today, tomorrow} = fireMaps;
            if (today && tomorrow){
              let todayMap = today.reduce((obj, item)=> {obj[item.code]=item; return obj}, {});
              let tomorrowMap = tomorrow.reduce((obj, item)=> {obj[item.code]=item; return obj}, {});
              setMaps({todayMap, tomorrowMap})
            }
			else {
				setMaps(null)
			}
          } else {
            setMaps(null)
          }
		  setInitialized(true)
        },
        (err) => {
			console.error('Error while retrieving today cached maps');
			setMaps(null)
			setInitialized(true)

        }
    )
  },[])

  return null;
}
export const StepGeoJsonPicker = ({activeStep, totalSteps, handleNext, textNextButton, disableBackButton, handleBack}) => {
  const [geoInfo, setGeoInfo] = useState(null);

  return (
      <>
        <GeoJsonPicker sendGeoJsonInfo={geoInfo => setGeoInfo(geoInfo)}/>
        <MobileStepper
            style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
            position="static"
            variant="text"
            activeStep={activeStep}
            steps={totalSteps}
            nextButton={
              <NextButton
                  variant="contained"
                  color="primary"
                  disabled={!geoInfo}
                  onClick={() => handleNext(geoInfo)}
                  textbutton={textNextButton}
              />
            }
            backButton={
              <BackButton
                  variant="contained"
                  disabled={disableBackButton }
                  onClick={handleBack}
              />
            }
        />
      </>
  )}
export const StepSingleDayMap = ({activeStep, totalSteps, handleNext, textNextButton, disableBackButton, handleBack, geoInfo, day, dayRelatedSettingsCache})  => {
  const [zonalMap, setZonalMap] = useState(null);
  const [settingsCache, setSettingsCache] = useState(dayRelatedSettingsCache);
  return (
      <>
        <FireRiskBuilder
            day={day}
            geoInfo={geoInfo}
            zonalMapCreationHandler={zonalMap => setZonalMap(zonalMap)}
            cache={settingsCache}
            handleCache={cache => setSettingsCache(cache)}/>
        <MobileStepper
            style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
            position="static"
            variant="text"
            activeStep={activeStep}
            steps={totalSteps}
            nextButton={<>
              {zonalMap && <NextButton
                  variant="contained"
                  color="primary"
                  onClick={() =>handleNext(zonalMap, settingsCache)}
                  textbutton={textNextButton}
              />}
            </>}
            backButton={
              <BackButton
                  variant="contained"
                  disabled={disableBackButton}
                  onClick={handleBack}
              />
            }
        />
      </>
  )
}
export const Step3 = (props) => {
  return (
      <>
        <div className="mt-2 row justify-content-center">
          <h2>{props.stepLabel}</h2>
        </div>
        <Grid container spacing={1}>
          {!!props.todayId && (
              <Grid item xs={6}>
                <SvgFormComponent
                    levels={props.levels}
                    displayValues={props.displayValues}
                    palette={props.palette}
                    id={props.todayId}
                    title={props.todayTitle}
                    svgUrl={props.todaySvgUrl}
                    baseZoneId={props.todayBaseZoneId}
                    stateByZone={props.todayStateByZone}
                    formSchema={props.todayFormSchema}
                    onZoneClickActions={props.onZoneClickActions(props.todayId)}
                    onZoneClickActions2={(zoneId) => props.onZoneClickActions2(props.todayId, zoneId)}
                    municipalitiesJSON={props.municipalitiesJSON}
                    onFormDataChange={(data) =>
                        props.onFormDataChange(props.todayId, data)
                    }
                />
              </Grid>
          )}
          {!!props.tomorrowId && (
              <Grid item xs={6}>
                <SvgFormComponent
                    levels={props.levels}
                    displayValues={props.displayValues}
                    palette={props.palette}
                    id={props.tomorrowId}
                    title={props.tomorrowTitle}
                    svgUrl={props.tomorrowSvgUrl}
                    baseZoneId={props.tomorrowBaseZoneId}
                    stateByZone={props.tomorrowStateByZone}
                    formSchema={props.tomorrowFormSchema}
                    onZoneClickActions={props.onZoneClickActions(props.tomorrowId)}
                    onZoneClickActions2={(zoneId) => props.onZoneClickActions2(props.tomorrowId, zoneId)}
                    municipalitiesJSON={props.municipalitiesJSON}
                    onFormDataChange={(data) =>
                        props.onFormDataChange(props.tomorrowId, data)
                    }
                />
              </Grid>
          )}
        </Grid>
        <MobileStepper
            style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
            position="static"
            variant="text"
            activeStep={props.activeStep}
            steps={props.totalSteps}
            nextButton={
              <NextButton
                  variant="contained"
                  color="primary"
                  onClick={props.handleNext}
                  textbutton={props.textNextButton}
              />
            }
            backButton={
              <BackButton
                  variant="contained"
                  disabled={props.disableBackButton}
                  onClick={props.handleBack}
              />
            }
        />
      </>
  );
};

export const Step4 = (props) => {
  return (
      <>
        <div className="mt-2 row justify-content-center">
          <h2>{props.stepLabel}</h2>
        </div>
        <div className="mt-2 row ">
          {!!props.generalId && (
              <Grid container spacing={1}>
                <Grid item xs={1}/>
                <Grid item xs={10}>
                  <JsonFormHolderComponent

                      schema={props.formSchema}
                      formData={props.formData}
                      onChange={(data) => {
                        Object.keys(data.formData).forEach(k => {
                          data.formData[k] = data.formData[k] ? data.formData[k].slice(0,170) : data.formData[k];
                        })

                        props.onFormDataChange(props.generalId, data)
                      }}
                  />
                </Grid>
                <Grid item xs={1}/>
              </Grid>
          )}
          <div className="row mx-auto ">
            <p>Max 170 caratteri per campo</p>
          </div>

        </div>
        <MobileStepper
            style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
            position="static"
            variant="text"
            activeStep={props.activeStep}
            steps={props.totalSteps}
            nextButton={
              <NextButton
                  variant="contained"
                  color="primary"
                  onClick={props.handleNext}
                  textbutton={props.textNextButton}
              />
            }
            backButton={
              <BackButton
                  variant="contained"
                  disabled={props.disableBackButton}
                  onClick={props.handleBack}
              />
            }
        />
        <div>

        </div>
      </>
  );
};


