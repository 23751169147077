import moment from 'moment';
import _ from 'lodash';
import 'moment-timezone';

const displayDate = (date, tz, epoch) => ({
	data: date.toLocaleDateString(),
	ora: date.toLocaleTimeString(),
	fuso: 'GMT+' + tz,
	epoch
});
class DateUtils {

	static optionsDateShortFormat = { year: 'numeric', month: '2-digit', day: '2-digit' };
	static optionsDateLongFormat = { year: 'numeric', month: 'long', day: '2-digit' };
	static locale = "it-IT";

	static fromISO8601ToDate(dateString){
		if(dateString){
			return new Date(dateString);
		}
		else {
			return null;
		}
	}

	static isToday = (selectedDate) => {
		let selY = selectedDate.year();
		let selM = selectedDate.month() + 1;
		let selD = selectedDate.date();
		let now = moment();
		let nowY = now.year();
		let nowM = now.month() + 1;
		let nowD = now.date();
		return selY === nowY && selM === nowM && selD === nowD;
	}

	/* return date from dateString from Java BE */
	static fromISO8601(dateString){
		if(dateString){
			return new Date(dateString).toLocaleString();
		}
		else {
			return "";
		}
	}

	static epochToGMT1String(epoch) {
		return epoch !== undefined ? moment(epoch)
			.tz("Etc/GMT-1").format("HH:mm DD/MM/yyyy") : '';
	}



	static epochToDate(epochTime){
		if(epochTime){
			//return new Date(epochTime).toDateString();
			return moment(epochTime).format("DD/MM/yyyy HH:mm");
		}
		else {
			return "";
		}
	}

	static epochToDateMinuteResolution(epoch) {
		return epoch !== undefined ? moment(epoch).tz('Etc/GMT-1').format("DD/MM/yyyy HH:mm") : '';// + ' GMT+1' : '';
	}

	static epochToLocaleDate(epochTime){
		if(epochTime){
			return new Date(epochTime).toLocaleDateString();
		}
		else {
			return "";
		}
	}


	static epochToLocaleDateFormatted(epochTime){
		if(epochTime){
			//return new Date(epochTime).toDateString();
			return new Date(epochTime).toLocaleDateString(this.locale, this.optionsDateShortFormat);
		}
		else {
			return "";
		}
	}

	static epochToLongLocaleDateFormatted(epochTime){
		moment.locale('it');
		return !!epochTime ? moment(epochTime).format('dddd D MMMM YYYY'): "";
	}


	/* return first day of month from date */
	static getFirstDayMonth(dateInput){
		return dateInput!==null ? new Date(dateInput.getFullYear(), dateInput.getMonth(), 1) : null;
	}

	/* return last day of month from date */
	static getLastDayMonth(dateInput){
		return dateInput!==null ? new Date(dateInput.getFullYear(), dateInput.getMonth() + 1, 1) : null;
	}



	/* return next day from date */
	static getNextDay(date){
		return !!date ? new Date(date.getTime() + (24 * 60 * 60 * 1000)) : "";
	}
	static changeDateFormat(strDate){

		let d = strDate.split("/");
		let year = d.splice(-1)[0];
		let month = d.splice(-1)[0];
		let date = d.splice(-1)[0];
		month = (month < 10) ? "0"+month : month;
		date = (date < 10) ? "0"+date : date;
		return year + "-" + month + "-" + date;
	}

	static diffFromNow(epochTime){
		let date = moment(epochTime);
		let now = moment();

		let diff = moment.duration(now.diff(date));

		let diffString = "";
		let years = diff.years();
		let months = diff.months();
		let days = diff.days();
		let hours = diff.hours();
		let minutes = diff.minutes();
		if(years!==0){
			diffString += years + " y, ";
		}
		if(months!==0){
			diffString += months + " m, ";
		}
		if(days!==0){
			diffString += days + " d, ";
		}
		if(hours!==0){
			diffString += hours + " h, ";
		}
		if(minutes!==0){
			diffString += minutes + " m";
		}
		return diffString;
	}

	static getMonthNameFromMonthNumber(monthNumber) {
		const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
		return months[monthNumber];
	}


	static getGMT1DateFromLocalDate = date => {
		if (date.getTimezoneOffset() === -120){
			let newDate = _.cloneDeep(date);
			newDate.setHours(newDate.getHours() + 1);
			console.table([displayDate(date, 1, newDate.getTime()), displayDate(newDate ,2, newDate.getTime())]);
			return newDate;
		} else {
			console.table([displayDate(date, 1, date.getTime())]);
			return date;
		}
	}

	static getLocalDateFromGMT1Date = date => {
		if (date.getTimezoneOffset() === -120){
			date.setHours(date.getHours() - 1);
		}
		return date;
	}

}

export default DateUtils;
