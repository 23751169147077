import AuthenticationService from "#/lib/AuthenticationService"

const context = {
	public: "pub",
	private: "priv"
}
export default class APIUtils {
	static tokenProvider = null; // Funzione per ottenere il token

	static setTokenProvider = (provider) => {
		APIUtils.tokenProvider = provider;
	};

	static getToken = () => {
		return APIUtils.tokenProvider ? APIUtils.tokenProvider() : null;
	};
	static isPrivateContext = () => {
		return window.location.href.includes("backoffice");
	}

	static getContext = () => {
		if (window.location.href.includes("backoffice")) {
			return context.private;
		}
		else {
			return context.public;
		}
	}

	static getFetchResponse = (url, fetch_options, okCallback, errCallback) => {

		if (this.getContext() === context.private) {
			fetch_options = !!fetch_options ? fetch_options : {};
			let authorizationHeader = { "Authorization": `Bearer ${this.getToken()}` }
			fetch_options.headers = { ...fetch_options.headers, ...authorizationHeader };
		}

		fetch(url, fetch_options)
			.then((res) => {
				if (res.status < 400) {
					if (res.status === 304) {
						return okCallback('NOT_MODIFIED');
					}
					res.json().then((json) => {
						return okCallback(json);
					});
				} else {
					const contentType = res.headers.get("content-type");
					if (contentType && contentType.indexOf("application/json") !== -1) {
						res.text().then((body) => {
							try {
								let JSONbody = JSON.parse(body);
								console.log('Error API: ', JSONbody);
								return errCallback(JSONbody);
							} catch {
								return errCallback(res);
							}
						});
						/*
						res.json().then((json) => {
						  console.log("json: ", json);
						  return errCallback(json);
						});
						*/
					} else {
						return errCallback(res);
					}
				}
			})
			.catch((error) => {
				return errCallback(error);
			});
	};

	static downloadFile = (url, fetch_options, fileName, okCallback, errCallBack) => {
		if (this.getContext() === context.private) {
			fetch_options = !!fetch_options ? fetch_options : {};
			let authorizationHeader = { "Authorization": `Bearer ${this.getToken()}` }
			fetch_options.headers = { ...fetch_options.headers, ...authorizationHeader };
		}
		fetch(url, fetch_options)
			.then(res => {

				if (res.status !== 200) {
					throw new Error('error');
				}
				else {
					let contentDispositionHeaderValue = res.headers.get("Content-Disposition")
					if (!!contentDispositionHeaderValue) {
						let fn = contentDispositionHeaderValue.split("filename=")[1];
						if (!!fn) {
							fileName = fn;
						}
					}
					return res.blob();
				}
			})
			.then(blob => {
				const fileUrl = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = fileUrl;
				link.setAttribute('download', fileName);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
				if (!!okCallback) {
					return okCallback();
				}
				else {
					return;
				}

			})
			.catch(err => {
				return errCallBack();

			});

	}
}
