import {properties} from "#/properties.js";
import APIUtils from "#/lib/APIUtils";
import GumbelCalculator from "#/lib/GumbelCalculator";
import TCEVCalculator from "#/lib/TCEVCalculator";

export default class MonitoringPluvsThresholdClient {

	static getThresholdsReturnTime = (okCallback, errCallback) => {
		let url = new URL(properties.url.returnTimeRainThresholds);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	};

	static updateThresholdsReturnTime = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.returnTimeRainThresholdsUpdate);
		let fetch_options = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(params),
		  };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static getThresholdsGroupedBySensorCode = (okCallback, errCallback) => {
		let url = new URL(properties.url.cumulativeRainThresholdsMap);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	};

	static updateThresholds = (okCallback, errCallback, params) => {
		console.log(params)
		let url = new URL(properties.url.cumulativeRainThresholdsUpdate);
		let fetch_options = {
			headers: { "Content-Type": "application/json", charset: "utf-8" },
			method: "POST",
			body: JSON.stringify(params),
		  };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	// TODO: move following in RainUtils
	static getThresholdColors= () => {
		return [
			"green",
			"yellow",
			"DarkOrange",
			"red"
		]
	}

	static getSeverityByValue = (aggr, value, thresholds) => {

		let t = thresholds[aggr];
		if (!!t && value >= t[3]) {
			return {
				lev: 3,
				color: this.getThresholdColors()[3]
			}
		}
		else if (!!t && value >= t[2]) {
			return {
				lev: 2,
				color: this.getThresholdColors()[2]
			}
		}
		else if (!!t && value >= t[1]) {
			return {
				lev: 1,
				color: this.getThresholdColors()[1]
			}
		}
		else if (value >= 0){
			return {
				lev: 0,
				color: this.getThresholdColors()[0]
			}
		}
		else {
			return {
				lev: -1,
				color: "grey"
			}
		}
	};

	static severityRangeMap = [0,5,25,50];

	static rainLayerMap = {
		P1H: 'Pioggia cumulata in 1 ora',
		P3H: 'Pioggia cumulata in 3 ore',
		P6H: 'Pioggia cumulata in 6 ore',
		P12H: 'Pioggia cumulata in 12 ore',
		P24H: 'Pioggia cumulata in 24 ore'
	}
	static getColorByReturnTime = (rT, returnTimeRainThresholds) => {
		const range = returnTimeRainThresholds;
		if (rT === 'nd') return "grey";
		if (rT < range[1]) return 'green';
		else if (rT >= range[1] && rT < range[2]) return 'yellow';
		else if (rT >= range[2] && rT < range[3]) return 'DarkOrange';
		else return 'red';
	}

	static getReturnTime = (stationCode, value, num, distribution, gumbelMap, tcevMap) => {
		let tempoDiRitorno = 'nd';
		const scope = 'HOUR_' + num;


		if (distribution === 'GUMBEL' && !!gumbelMap[stationCode]) {
			const gumbelParameters = gumbelMap[stationCode].find(cf => cf.scope === scope);
			;
			if (!!gumbelParameters && !!gumbelParameters.alfa && !!gumbelParameters.epsilon) {
				const {alfa, epsilon} = gumbelParameters;
				tempoDiRitorno = GumbelCalculator.getTempoDiRitornoByH(alfa, epsilon, value);
			}
		} else if (distribution === 'TCEV' && !!tcevMap[stationCode]) {
			const tcevParameters = tcevMap[stationCode].find(cf => cf.scope === scope);

			const {lambdaUno, tetaUno, lambdaStar, tetaStar} = tcevParameters || {};
			;
			if (!!lambdaUno && !!tetaUno && !!lambdaStar && !!tetaStar){
				tempoDiRitorno = TCEVCalculator.getTempoDiRitornoByH(lambdaUno, tetaUno, lambdaStar, tetaStar, value);
				
			}
		} else {
			tempoDiRitorno = 'nd';
		}

		if(tempoDiRitorno===null || isNaN(tempoDiRitorno)){
			return 'nd';
		}
		else{
			return tempoDiRitorno.toFixed(2);
		}
	}

}
