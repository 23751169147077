import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';
import AuthenticationService from "#/lib/AuthenticationService";
import parse_georaster from "georaster"

export default class ForecastLinkClient {


	static getLink = (okCallback, errCallback) => {
		let url = new URL(properties.url.forecastLink);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);	
	};

	static saveLink = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.forecastLinkSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

}



