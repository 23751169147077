import React, { Component, useState} from 'react';
import {Modal, Container, Row, Col, Form} from 'react-bootstrap';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import SendIcon from '@material-ui/icons/Send';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { Beforeunload } from 'react-beforeunload';
import { SaveButton } from '#/commons/components/forms/SaveButton';
import { DeleteButton } from '#/commons/components/forms/DeleteButton';

import moment from 'moment';
import 'moment/locale/it';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import TimeField from 'react-simple-timefield';
import DatePicker from "react-datepicker";
import it from 'date-fns/locale/it';
import WorkshiftClient from '#/lib/WorkShfitClient';
import UserClient from '#/lib/UserClient';
import DateUtils from '#/lib/DateUtils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import AuthenticationService, { ROLE_ADMIN, ROLE_OFFICER }  from '#/lib/AuthenticationService';
import LoginComponent from '#/backoffice/components/LoginComponent';
import { Route } from 'react-router-dom';
import _ from "lodash";
const hour_options = {hour: 'numeric', minute: 'numeric', seconds: 'numeric'};
const local_string = 'it-IT';
const localizer = momentLocalizer(moment);

const ReactSwal = withReactContent(Swal)
const fakeHoursByFunction = {
  FR: {fakeStartHour: 3, fakeEndHour: 4},
  F: {fakeStartHour: 4, fakeEndHour: 5},
  REP_f: {fakeStartHour: 5, fakeEndHour: 6},
  SW1: {fakeStartHour: 6, fakeEndHour: 7},
  SW2: {fakeStartHour: 7, fakeEndHour: 8},
  LR1: {fakeStartHour: 6, fakeEndHour: 7},
  LR2: {fakeStartHour: 7, fakeEndHour: 8},
  REP_n: {fakeStartHour: 8, fakeEndHour: 9}
}

const colorByFunction = {
	FR: "#3f51b5",
	F: "#4caf50",
	REP_f: "#f44336",
	SW1: "#ff9800",
	SW2: "#ff9800",
  LR1: "#ff9800",
	LR2: "#ff9800",
	REP_n: "#795548"
  }

const messages = {
  date: "Data",
  time: "Ora",
  event: "Evento",
  allDay: "Tutto il giorno",
  today: "Oggi",
  month: "Mese",
  day: "Giorno",
  week: "Settimana",
  previous : "Precedente",
  next : "Successivo",
  noEventsInRange: "Non sono presenti turni nell'intervallo selezionato.",
  showMore: total => `+${total} ulteriori`,
}

export default class WorkShiftsPage extends Component {

  csvFile = null;

  constructor(props){
    super(props);
    const writePermission = AuthenticationService.haveRolesPermssions([ROLE_ADMIN]);
    const today = new Date();
    const firstDayMonth = DateUtils.getFirstDayMonth(today).getTime();
    const lastDayMonth = DateUtils.getLastDayMonth(today).getTime();

    this.state = {
      modalState : {
        event : {},
        show: false,
        writePermission : writePermission,
        onHide : this.hideModal,
        onChangeStartDate : this.onChangeStartDateHandle,
        onChangeEndDate: this.onChangeEndDateHandle,
        onChangeField: this.onChangeModalHandle,
        onSaveForm : this.onSaveModalHandle,
        onDelete : this.onDeleteModalHandle
      },
      calendarState : {
        writePermission : writePermission ,
        events : [],
        onSelectEvent : (e) => this.onSelectEventHandle(e),
        onSelectSlot : (e) => this.onSelectSlotHandle(e),
        onRangeChange : (e) => this.onRangeChange(e)
      },
      csvState : {
        writePermission : writePermission,
        show: false,
        onHide: this.hideModal,
        onChangeDropzoneHandle : this.onChangeDropzoneHandle,
        onDropzoneSubmit : this.onSubmitDropzoneHandle
      },
      calendarRange : {
        start : firstDayMonth,
        end : lastDayMonth
      },
      saveButtonEnabled : false
    }
  }

  componentDidMount() {
    let calendarPromise = new Promise((resolve,reject)=>{
      let params = {
        startDate : this.state.calendarRange.start,
        endDate : this.state.calendarRange.end
      };
      WorkshiftClient.workShiftGetList (
          (result) => {
            result = this.getEventsFromResult(result);
            resolve({
              calendarState : {
                events : result
              }
            })
          },
          (msg) => {
            ReactSwal.fire('Recupero turni fallito', '', 'error')
          },
          params
      )
    });

    let userPromise = new Promise((resolve,reject)=>{
      UserClient.getUserList (
          (result) => {
            resolve({
              users : result
            })
          },
          (msg) => {
            ReactSwal.fire('List users Failed', '', 'error')
          }
      )
    });

    let userFunctionsPromise = new Promise((resolve,reject)=>{
      WorkshiftClient.workShiftGetFunctionsList (
          (result) => {
            resolve({
              userFunctions : result
            })
          },
          (msg) => {
            ReactSwal.fire('List users Failed', msg, 'error')
          }
      )
    });

    Promise.all([calendarPromise, userPromise, userFunctionsPromise]).then(values => {
      const mergedValues = {...values[0], ...values[1], ...values[2]};
      const modalState = {...this.state.modalState, users : mergedValues.users, userFunctions : mergedValues.userFunctions};
      const calendarState = {...this.state.calendarState, events : mergedValues.calendarState.events};
      this.setState({modalState : modalState, calendarState : calendarState});
    });

  }

  getEventsFromResult = (result) => {
    result.forEach ( event => {
      event.start = !!event.startDate ? new Date(event.startDate) : null;
      event.end = !!event.endDate ? new Date(event.endDate) : null;
      event.title = event.surname + ' ' + event.name + ' - ' + event.function;
      event.dbOrigin = true;
      event.action = '-';
      event.saved = true;
    })
    return result;
  }

  getCalendarEvents = (startDate, endDate) =>{
    let params = {
      startDate : !!startDate ? startDate : this.state.calendarRange.start,
      endDate : !!endDate ? endDate : this.state.calendarRange.end
    };
    WorkshiftClient.workShiftGetList (
        (result) => {
          result = this.getEventsFromResult(result);
          const calendarState = {...this.state.calendarState, events : result };
          this.setState({calendarState : calendarState});
        },
        (msg) => {
          ReactSwal.fire('Recupero turni fallito', msg, 'error');
        },
        params
    )
  }

  onRangeChange = (range, view) => {

    let startDate;
    let endDate;
    if (range.length > 1){
      startDate = range[0];
      endDate = range[6];
    } else if (range.length === 1){
      startDate = range[0];
      endDate = range[0];
    } else {
      startDate = range.start;
      endDate = range.end;
    }
    startDate = startDate.getTime();
    endDate = endDate.getTime();
    this.setState({
      calendarRange : {
        start : startDate,
        end : endDate
      }
    })
    this.getCalendarEvents(startDate, endDate);
  }

  onSelectEventHandle = (e) => {
    const newModalState = {...this.state.modalState,
      show : true,
      title : 'Modifica Turno',
      new : false,
      event : {
        ...e,
        startTime : (e.start.getHours()<10 ? '0' : '') + e.start.getHours() + ':' + (e.start.getMinutes()<10 ? '0' : '') + e.start.getMinutes(),
        endTime : (e.end.getHours()<10 ? '0' : '') + e.end.getHours() + ':' + (e.end.getMinutes()<10 ? '0' : '') + e.end.getMinutes(),
        action : 'mod'
      }
    };
    this.setState({modalState : newModalState});
  }

  onSelectSlotHandle = (e) => {
    const newModalState = {...this.state.modalState,
      show : true,
      title : 'Definizione Turno',
      new : true,
      event : {
        ...e,
        id: null,
        startTime : e.start.toLocaleTimeString(local_string, hour_options),
        endTime : e.end.toLocaleTimeString(local_string, hour_options),
        dbOrigin : false,
        action : 'add',
        saved : false
      }
    };
    this.setState({modalState : newModalState});
  }


  onChangeStartDateHandle = (startDate) => {
    const modalState = {...this.state.modalState, startDate : startDate};
    this.setState({ modalState : modalState });
  }

  onChangeEndDateHandle = (endDate) => {
    const modalState = {...this.state.modalState, endDate : endDate};
    this.setState({ modalState : modalState });
  }

  onChangeModalHandle = (e) => {
    let modalState = {...this.state.modalState};
    let event = modalState.event;
    if (e.target.name === 'function') {
      const userFunctions = modalState.userFunctions;
      const functionIdx = userFunctions.findIndex(el => el.code === e.target.value);
      const functionDescr = functionIdx < 0 ? event.functionDescr : userFunctions[functionIdx].description;
      const startTime = functionIdx < 0 ? event.startTime : userFunctions[functionIdx].startTime;
      const endTime = functionIdx < 0 ? event.endTime : userFunctions[functionIdx].endTime;
      const startTimeHour = !!startTime ? parseInt(startTime.split(':')[0]) : 0;
      const endTimeHour = !!endTime ? parseInt(endTime.split(':')[0]) : 0;
      const endDate = startTimeHour > endTimeHour ? DateUtils.getNextDay(event.start) : event.start;
      modalState.event = {...event, [e.target.name] : e.target.value, functionDescr: functionDescr, startTime : startTime, endTime : endTime, end : new Date(endDate)};
    } else {
      modalState.event = {...event, [e.target.name] : e.target.value};
    }
    this.setState({ modalState : modalState });
  }

  onSaveModalHandle = () => {
    this.hideModal();
    const modalState = {...this.state.modalState};
    const event = modalState.event;
    const users = modalState.users;
    const oldEvents = this.state.calendarState.events;
    const userIdx = users.findIndex(el => el.username === event.username);
    event.name = userIdx < 0 ? event.username : users[userIdx].name;
    event.surname = userIdx < 0 ? '' : users[userIdx].surname;
    event.title = event.name + ' ' + event.surname + ' - ' + event.function + "  (*)";
    event.start.setHours(event.startTime.substring(0,2), event.startTime.substring(3,5));
    event.end.setHours(event.endTime.substring(0,2), event.endTime.substring(3,5));
    event.startDate = event.start.getTime();
    event.endDate = event.end.getTime();
    event.saved = false;
    if (event.action !== 'add') {
      const eventIdx = oldEvents.findIndex(el => el.id === event.id);
      oldEvents[eventIdx] = {
        ...event,
        action : event.dbOrigin ? 'mod' : 'add'
      }
      this.setState({saveButtonEnabled: true});
    } else {
      const newEvents = [...oldEvents, event];
      const calendarState = {...this.state.calendarState, events : newEvents };
      this.setState({calendarState : calendarState, saveButtonEnabled: true});
    }
  }

  onDeleteModalHandle = () => {
    this.hideModal();
    const event = this.state.modalState.event;
    const oldEvents = this.state.calendarState.events;
    if (!!event.id) {
      const eventIdx = oldEvents.findIndex(el => el.id === event.id);
      oldEvents[eventIdx].action = 'del';
      oldEvents[eventIdx].saved = false;
    } else {
      const eventIdx = oldEvents.findIndex(el => el.username === event.username &&
          el.function === event.function &&
          el.start === event.start &&
          el.end === event.end &&
          el.saved === event.saved
      );
      oldEvents.splice(eventIdx,1);
    }
    const eventsToSave = oldEvents.filter((e) => e.action !== '-');
    this.setState({saveButtonEnabled: eventsToSave.length!==0});
  }

  saveAllEvents = () => {
    if (this.checkEventsBeforeSave) {
      const events = this.state.calendarState.events;
      const eventsToSave = events.filter((e) => e.action === 'mod' || e.action === 'add');
      const eventsToDelete = events.filter((e) => e.action === 'del');
      let params = {
        toSave : eventsToSave,
        toDelete : eventsToDelete
      }
      WorkshiftClient.workShiftSaveAll (
          (result) => {
            this.getCalendarEvents();
            ReactSwal.fire('Turni salvati correttamente', '', 'success');
          },
          (msg) => {
            ReactSwal.fire('Errore durante il caricamento dei turni', '', 'error');
          },
          params
      )
    }
  }

  checkEventsBeforeSave = () => {
    return true;
  }

  hideModal = () => {
    const modalState = {...this.state.modalState, show: false};
    const csvState = {...this.state.csvState, show: false};
    this.csvFile = null;
    this.setState({ modalState : modalState, csvState : csvState });
  }

  showCsvModal = () => {
    const modalState = {...this.state.csvState, show: true};
    this.setState({ csvState : modalState });
  }

  onChangeDropzoneHandle = (e) => {
    const status = e.meta.status;
    const file = e.file;
    if (status === "done") {
      this.csvFile = file;
    } else if (status === "removed") {
      this.csvFile = null;
    }
  }

  onSubmitDropzoneHandle = (e) => {
    if (!!!this.csvFile){
      ReactSwal.fire('Definire un file da importare', '', 'error');
      return;
    }
    let params = {
      file : this.csvFile
    };
    WorkshiftClient.workShiftCsvUpload (
        (result) => {
          this.getCalendarEvents();
          this.hideModal();
          ReactSwal.fire('Turni importati correttamente', '', 'success');
        },
        (msg) => {
          this.hideModal();
          ReactSwal.fire('Importazione dei turni ha presentato degli errori', '', 'error');
        },
        params
    )
  }

  render() {
    const roles = !!this.props.roles ? this.props.roles : [ROLE_ADMIN, ROLE_OFFICER];
    if (!AuthenticationService.isValidSession() || !AuthenticationService.haveRolesPermssions(roles))  {
      return <Route render={(props) => <LoginComponent {...this.props}/>}></Route>
    }

    return (
        <div className="generic-page container-fluid data-page">
          <Grid container direction="row" justify="space-between" alignItems="center" >
            <Grid item xs={3} className="mt-4">
              <h2 className="ml-3">Gestione turni</h2>
            </Grid>
            <Grid item xs={9} className="mt-4">
              {this.state.calendarState.writePermission &&
              <>
                <Button variant="contained" className="float-right" style={{ margin: "0.5vw", marginRight: "10px", minWidth: "5vw" }} onClick={this.showCsvModal} startIcon={<CloudUploadIcon />}>Importa CSV</Button>
                <Button variant="contained" className="float-right" style={{ margin: "0.5vw", marginRight: "10px", minWidth: "5vw" }} disabled={!this.state.saveButtonEnabled} onClick={this.saveAllEvents} startIcon={<SendIcon />}>Salva modifiche</Button>
              </>
              }
            </Grid>
          </Grid>

          <Grid container direction="row" justify="space-between" alignItems="center" spacing={0} style={{width: 'inherit'}} >
            {!!this.state.calendarState.events && !!this.state.modalState.users && !!this.state.modalState.userFunctions ?
                <>
                  <Grid item xs={12}>
                    <MyCalendar className="col-12" {...this.state.calendarState} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid className="ml-3 my-4" container direction="row" justify="center" alignItems="center" spacing={0}>
                      <Grid item xs={12} >
						  {this.state.modalState.userFunctions.map(function (item) {
								return <span key={item.code}><FiberManualRecordIcon className="ml-5" fontSize="small" style={{fill: colorByFunction[item.code]}} />{item.code +" - "+item.description}</span>;
							})

						  }
                        
                      </Grid>
                    </Grid>
                  </Grid>
                </>:
                <Grid item xs={12}>
                  <Skeleton variant="rect" width="60%" height={720}/>
                </Grid>
            }
            {this.state.modalState.show && <ModalForm {...this.state.modalState} />}
            {this.state.csvState.show && <ModalImportCSV {...this.state.csvState} users={this.state.modalState.users} userFunctions={this.state.modalState.userFunctions}/>}
          </Grid>
          <Beforeunload onBeforeunload={() => {return this.state.saveButtonEnabled ? "You'll lose your data!" : false}} />
        </div>
    )
  }

}


const ModalForm = props => {

  const optionUsers = props.users.map(function (item) {
    let displayValue = item.name + ' ' + item.surname;
    return <option value={item.username} key={item.username}>{displayValue}</option>;
  });

  const optionUserFunctions = props.userFunctions.map(function (item) {
    return <option value={item.code} key={item.code}>{item.description}</option>;
  });
  const classReadOnlyField = props.writePermission ? "" : " form-control-plaintext";
  const event = props.event;
  const enableSubmitButton = !!event.username && !!event.function && !!event.start && !!event.startTime && !!event.end && !!event.endTime;

  return (
      <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p>{props.title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>

            <Row className="mt-2">
              <Col md={4}>Funzionario</Col>
              <Col md={8}>
                <Form.Control
                    as="select"
                    plaintext = {!props.writePermission}
                    readOnly = {!props.writePermission}
                    disabled = {!props.writePermission}
                    name="username"
                    value={event.username}
                    onChange={props.onChangeField}>
                  <option value=""></option>
                  {optionUsers}
                </Form.Control>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md={4}>Ruolo</Col>
              <Col md={8}>
                <Form.Control
                    as="select"
                    plaintext = {!props.writePermission}
                    readOnly = {!props.writePermission}
                    disabled = {!props.writePermission}
                    name="function"
                    value={event.function}
                    onChange={props.onChangeField}>
                  <option value=""></option>
                  {optionUserFunctions}
                </Form.Control>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md={4}>Giorno</Col>
              <Col md={4}>
                <DatePicker
                    className = {"col-12" + classReadOnlyField}
                    plaintext = {!props.writePermission}
                    readOnly = {!props.writePermission}
                    locale={it}
                    dateFormat="dd/MM/yyyy"
                    selected={event.start}
                    onChange={props.onChangeStartDate}
                />
              </Col>
              <Col md={4}>
                <DatePicker
                    className = {"col-12" + classReadOnlyField}
                    plaintext = {!props.writePermission}
                    readOnly = {!props.writePermission}
                    locale={it}
                    dateFormat="dd/MM/yyyy"
                    selected={event.end}
                    onChange={props.onChangeEndDate}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md={4}>Fascia oraria</Col>
              <Col md={4}>
                <TimeField
                    plaintext = {!props.writePermission}
                    readOnly = {!props.writePermission}
                    name='startTime'
                    value={event.startTime}
                    input={<Form.Control type="text" value={event.startTime}/>}
                    style={{textAlign: 'center'}}
                    onChange = {props.onChangeField}
                />
              </Col>
              <Col md={4}>
                <TimeField
                    plaintext = {!props.writePermission}
                    readOnly = {!props.writePermission}
                    name='endTime'
                    value={event.endTime}
                    input={<Form.Control type="text" value={event.endTime}/>}
                    style={{textAlign: 'center'}}
                    onChange = {props.onChangeField}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {props.writePermission ?
            <Modal.Footer>
              {!props.new &&
              <DeleteButton variant="contained" onClick={props.onDelete}/>
              }
              <SaveButton variant="contained" onClick={props.onSaveForm} disabled={!enableSubmitButton}/>
            </Modal.Footer>
            : <Modal.Footer></Modal.Footer>
        }
      </Modal>
  );
}

const ModalImportCSV = (props) => {
  return (
      <Modal
          show={props.show}
          onHide={props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p>Importa CSV</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <Dropzone
                    onChangeStatus={props.onChangeDropzoneHandle}
                    accept=".csv, application/vnd.ms-excel, text/csv"
                    maxFiles={1}
                    inputContent="Trascina o scegli il file CSV da caricare"
                    styles={{
                      dropzone: { overflow: "hidden" },
                      dropzoneReject: {
                        borderColor: "red",
                        backgroundColor: "#DAA",
                      },

                      inputLabel: (files, extra) =>
                          extra.reject ? { color: "red" } : { color: "#315495" },
                    }}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {props.writePermission ? (
            <Modal.Footer>
              <SaveButton variant="contained" onClick={props.onDropzoneSubmit} />
            </Modal.Footer>
        ) : (
            <Modal.Footer></Modal.Footer>
        )}
      </Modal>
  );
};


const MyCalendar = (props) => {
  const [selectedView, setSelectedView] = useState(Views.MONTH)

  const eventStyleGetter = (props) => {
	const {event, start, end, isSelected,  username} = props
	

    let backgroundColor = colorByFunction[props.function];
    /*if (!!event && !event.saved) {
      switch (event.action) {
        case "del": {
          backgroundColor = "#9C231A";
          break;
        }
        case "mod": {
          backgroundColor = "#BF7E28";
          break;
        }
        default: {
          backgroundColor = "#20468C";
          break;
        }
      }
    }
	*/
    var style = {
      border: "none",
      boxSizing: "border-box",
      boxShadow: "none",
      margin: "0",
      padding: "2px 5px",
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      color: "#fff",
      cursor: "pointer",
      width: "100%",
      textAlign: "left",
    };
    return {
      style: style,
    };
  };

  const formatStart = ({start, function : fun}) => {

    start.setHours(fakeHoursByFunction[fun].fakeStartHour);
    return start
  };
  const formatEnd = ({start, function : fun}) => {

    start.setHours(fakeHoursByFunction[fun].fakeEndHour);
    return start
  }
  const formatEvents = () => {
    if (selectedView === Views.MONTH){
      let retval =  props.events.map(e => ({
        ...e,
        allDay: true,
        originalEnd: e.end,
        end: formatEnd(_.cloneDeep(e)),
        originalStart: e.start,
        start: formatStart(_.cloneDeep(e))
      }));
      return retval;
    } return props.events
  };
  const fixSelectedEvent = e => selectedView === Views.MONTH ? {...e, end: e.originalEnd || e.end, start: e.originalStart || e.start, allDay: false} : e;
  return (

      <div className={props.className}>
        <Calendar
            selectable={props.writePermission}
            events={formatEvents()}
            views={[Views.MONTH]}
            defaultView={Views.MONTH}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            step={10}
            timeslots={3}
            showMultiDayTimes
            messages={messages}
            style={{ height: 1100, fontSize: 12 }}
            onSelectEvent={e => props.onSelectEvent(fixSelectedEvent(e))}
            onSelectSlot={props.onSelectSlot}
            onView={view => {
              setSelectedView(view)
            }}
            onRangeChange ={props.onRangeChange}
			    popup={true}            
            eventPropGetter={eventStyleGetter}
        />
      </div>
  );
};
