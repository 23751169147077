import {properties} from '#/properties.js';

import parse_georaster from "georaster"
import APIUtils from "#/lib/APIUtils";
import {radarBaseUrl} from "#/properties";


export default class RadarClient {

	static getAvailableRadarLayers(okCallback, errCallback){
		let url = properties.url.radarLayers
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getRadarDataByInterval(okCallback, errCallback, interval, category){
		let url = properties.url.radarByInterval + "/" + category;
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(interval)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getRadarByTiffUrl = (okCallback, errCallback, tiffUrl) => {
		let url = new URL(radarBaseUrl + tiffUrl);
		fetch(url)
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				parse_georaster(arrayBuffer).then(georaster => {
					okCallback(georaster)
				})
			})
			.catch((error) => {
				return errCallback(error);
			});
	};

	static getRadarByPngUrl = (okCallback, errCallback,pngUrl) => {
		let url = new URL(radarBaseUrl + pngUrl);
		fetch(url)

			.then(response => response.blob())
			.then(image => {
				okCallback(URL.createObjectURL(image));
			})
	};



}
