import React from 'react';
import _ from 'underscore';
import lodash from 'lodash';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {TimeRange, TimeSeries} from 'pondjs';
import moment from "moment";
import {format} from "d3-format";
import {
	AreaChart,
	Brush,
	ChartContainer,
	ChartRow,
	Charts,
	Legend,
	LineChart,
	Resizable,
	styler,
	TimeRangeMarker,
	YAxis
} from "react-timeseries-charts";
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import SensorClient from "#/lib/SensorClient";
import DownloadContainer from "#/commons/components/DownloadContainer";
import {UNIT_BY_CATEGORY} from "#/lib/MeasurementCategory";
import Skeleton from "react-loading-skeleton";

const ReactSwal = withReactContent(Swal);

const linkStyle = {
	fontWeight: 600,
	color: "green",
	cursor: "pointer"
};

const linkStyleActive = { ...linkStyle, color: "steelblue" };

const chartStyle = {
	borderStyle: "solid",
	borderWidth: 1,
	borderColor: "#DDD",
	paddingTop: 10,
	marginBottom: 10
};

const brushStyle = {
	boxShadow: "inset 0px 2px 5px -2px rgba(189, 189, 189, 0.75)",
	background: "#FEFEFE",
	paddingTop: 10
};

export default class ReactTimeseriesChart extends React.Component {

	/*  props:
		measure
		compareList
		interval
		onChangeView,
		dataSets
	*/

	constructor(props) {
		super(props);
		let minTime = this.props.interval.start.valueOf();
		let maxTime = this.props.interval.end.valueOf()

		const initialRange = new TimeRange([minTime, maxTime]);
		let mode = { showAbsoluteRain: false, showOutflow: false };
		this.state = {
			ready: false,
			mode,
			channels: {},
			channelNames: [],
			missingIntervals: [],
			displayChannels: [],
			tracker: null,
			highlight: null,
			timerange: initialRange,
			brushrange: initialRange,
			outflowScales: {}
		};
	}
	componentDidMount() {
		let { interval, measure, compareList, onDataSetCallback, colorsMap, allSensorsMap } = this.props;

		this.getData(interval, measure, compareList,
			(dataset) => {

				let channels = {};
				let missingIntervals = []
				let outflowScales = this.props.outflowScales;
				let emptyDataSensorCodes = this.cleanEmptyData(dataset);
				if (!!onDataSetCallback) {
					onDataSetCallback(dataset);
				}
				const points = {};
				let styleArray = [];
				let yAxisInfo = {};
				const targetSensorCode = measure.sensor[0].sensorCode;
				const { value: targetSensorCategory } = measure;
				Object.keys(dataset).forEach(sensorCode => {
					let sensorCategory = sensorCode === targetSensorCode ? targetSensorCategory : compareList.find(compared => compared.sensor[0].sensorCode === sensorCode).value;

					let data = sensorCategory === 'P' ? this.removeZeroValuesAndSum(dataset[sensorCode].data) : dataset[sensorCode].data;
					let temp = data.map(x => { return [x.ts, x[sensorCategory === 'P' ? "aVal" : "value"]] });

					const  stationCode  = allSensorsMap[sensorCode].stationCode;
					const stationName = this.props.stations.find(stat => stat.value === stationCode).label.split('(')[0].trim();
					if (temp.length > 0) {
						points[sensorCode] = temp;
						channels[sensorCode] = {
							units: UNIT_BY_CATEGORY[sensorCategory],
							label: `${stationName} (${sensorCategory})`,
							sensorCategory,
							format: ".1f",
							series: null,
							show: true
						};
						styleArray.push({ key: sensorCode, color: !this.props.autoColors && colorsMap[sensorCategory].sensors[sensorCode], width: 3 });
						yAxisInfo[sensorCode] = { category: sensorCategory };
					}
					if(!!dataset[sensorCode].primary){
						missingIntervals = this.findMissingIntervals(dataset[sensorCode].data);
						console.log('missingIntervals: ', missingIntervals);
					}
					if (sensorCategory === 'I') {
						let temp = dataset[sensorCode].data.map(x => { return [x.ts, this.calculateFlow(x.value, outflowScales[sensorCode])] });
						if (temp.length > 0) {
							points[`${sensorCode}_flow`] = temp;
							channels[`${sensorCode}_flow`] = {
								units: "m^3 /s",
								label: `${stationName} (Portata)`,
								sensorCategory: 'I_flow',
								format: ".1f",
								series: null,
								show: false
							};
							styleArray.push({ key: `${sensorCode}_flow`, color: !this.props.autoColors && colorsMap[sensorCategory].sensors[sensorCode], width: 3 });
							yAxisInfo[`${sensorCode}_flow`] = { category: sensorCategory };
						}
					}
				})
				let displayChannels = Object.keys(channels);

				for (let channel of Object.keys(points)) {
					const series = new TimeSeries({
						name: channel,
						columns: ["time", channel],
						points: points[channel]
					});

					channels[channel].series = series;
					channels[channel].avg = series.avg(channel);
					channels[channel].max = series.max(channel);
					yAxisInfo[channel].max = series.max(channel);
					yAxisInfo[channel].min = channel.includes('absolute') || series.min(channel) === series.max(channel) ? 0.0 : series.min(channel);
				}

				const minTime = channels[targetSensorCode].series.range().begin();
				const maxTime = channels[targetSensorCode].series.range().end();
				const minDuration = 10 * 60 * 1000;
				const style = styler(styleArray);

				this.setState({ ready: true, channels, points, displayChannels, minTime, maxTime, minDuration, style, yAxisInfo, outflowScales, missingIntervals /*, seriesEvents*/ },
					() => {
						if (emptyDataSensorCodes.length > 0) {
							ReactSwal.fire('Dati mancanti', 'Nessun dato trovato per i sensori ' + emptyDataSensorCodes.toString(), 'warning')
						}
					});
			}
		)
	}

	findMissingIntervals = (data) =>{

		let missingIntervals = [];

		let i=0;

		while(i<data.length){
			if(data[i].v3!=="MISSING"){
				i++;
			}
			else{
				let start = data[i].ts;
				let end;
				if(i===data.length-1){
					i++;
				}
				else{
					do {
						end = data[i+1].ts;
						i++;
					}while(i<data.length-1 && data[i].v3==="MISSING")
					console.log(start+"-"+end)
					missingIntervals.push(new TimeRange([start, end]))
				}
			}
		}
		return missingIntervals
	}

	cleanEmptyData = (dataset) => {
		let emptyArray = [];
		Object.keys(dataset).forEach(sensorCode => {
			if (dataset[sensorCode].data.length === 0) {
				emptyArray.push(sensorCode);
			}
		});
		emptyArray.forEach(code => delete dataset[code]);
		return emptyArray;
	}

	getData(interval, measure, compareList, okCallback) {
		let dataset = {};


		Promise.all([ ...this.buildRequests(interval, measure, compareList)])
			.then(values => {
				let measurements = [];
				[...measurements] = values;


				measurements.forEach((response) => {
					dataset[response.sensorCode] = {};
					dataset[response.sensorCode].data = response.data;
					dataset[response.sensorCode].target = response.target
					dataset[response.sensorCode].primary = response.sensorCode === measure.sensor[0].sensorCode
				})
				okCallback(dataset)
			})
	}

	removeZeroValuesAndSum = (data) => {
		let prunedData = lodash.cloneDeep(data).filter((d, i) =>
			i === 0 || // Keep first value and
			i === data.length - 1 || // last value and
			(i < data.length - 2 && i > 1 && d.aVal === 0 && (data[i + 1].aVal !== 0 || data[i - 1].aVal !== 0)) || // internal values right before and after non-zero values
			(i !== 0 && i !== data.length - 1 && d.aVal !== 0));// non-zero values

		prunedData.forEach((d, i) => { // build integral
			if (i === 0){
				d.aVal = Number(d.aVal);
			} else {
				d.aVal = Number(d.aVal) + Number(prunedData[i - 1].aVal);
			}
		});
		return prunedData;
	};

	buildRequests(interval, measure, compareList) {
		let promises = [];
		[measure, ...compareList].forEach((currentMeasure,i) => {
			let promise = new Promise((resolve, reject) => {
				let params = {};
				params.start = interval.start.valueOf();
				params.end = interval.end.valueOf();
				params.code = currentMeasure.sensor[0].sensorCode;
				if(i===0){
					params.addMissing = true;
				}
				if (!!this.props.dataSetCache && !!this.props.dataSetCache[currentMeasure.sensor[0].sensorCode]) {
					console.log("CACHED DATASET FOR ", currentMeasure.sensor[0].sensorCode)
					resolve({
						target: false,
						data: this.props.dataSetCache[currentMeasure.sensor[0].sensorCode].data,
						sensorCode: currentMeasure.sensor[0].sensorCode,
					});
				}
				else {
					MeasurementsDataClient.getPuntualData(
						function (measuresList) {
							resolve({
								target: false,
								data: measuresList,
								sensorCode: currentMeasure.sensor[0].sensorCode,
							});
						},
						function (msg) {
							console.log("Si è verificato un errore inaspettato");
						},
						params,
					);
				}
			});
			promises.push(promise);
		})
		return promises;
	}
	getOutflowsScalesPromise() {
		return new Promise((resolve, reject) => {
			SensorClient.getAllOutflowsScalesMapBySensorCode(
				(data) => {
					if (!_.isEmpty(data)) {
						resolve(data)
					} else {
						ReactSwal.fire({
							title: 'Nessuna scala di deflusso trovata',
							text: 'Non è possibile calcolare le portate.',
							icon: 'error'
						});
					}
				},
				(msg) => {
					console.log('Error retrieving outflow scales');
				}
			)
		})
	}

	calculateFlow = (value, scales) => {
		if (!!scales) {
			const scale = scales.find(scale => value >= scale.thresholdStart && value < scale.thresholdEnd);
			if (!!scale) {
				const { a, b, c, e } = scale;
				return Math.round(((a * Math.pow(value + e, b) + c) + Number.EPSILON) * 100) / 100;
			}
		}
		return 'nd';
	}
	handleTrackerChanged = t => !!!this.state.highlight && this.setState({ tracker: t });
	handleTimeRangeChange = timerange => {
		const { channels } = this.state;
		const targetSensorCode = this.props.measure.sensor[0].sensorCode;
		if (this.props.onFilterCallBack) {
			let min = timerange.toJSON()[0];
			let max = timerange.toJSON()[1];

			this.props.onFilterCallBack({ min, max })
		}
		this.setState(timerange ? { timerange, brushrange: timerange } : { timerange: channels[targetSensorCode].range(), brushrange: null });
	};
	handleChartResize = width => this.setState({ width });
	handleActiveChange = channelName => {
		const channels = this.state.channels;
		channels[channelName].show = !channels[channelName].show;
		this.setState({ channels });
	};
	renderMultiAxisChart(t) {
		let { timerange, displayChannels, channels, maxTime, minTime, minDuration, yAxisInfo, tracker, missingIntervals } = this.state;

		if (this.props.comboMode) {
			if (!!this.props.filter) {
				timerange = new TimeRange([this.props.filter.min, this.props.filter.max]);
			} else {
				timerange = new TimeRange([this.props.interval.start.valueOf(), this.props.interval.end.valueOf()]);
			}
		}

		const { trackerInfoWidth, trackerInfoValues } = this.buildTrackerInfoBox(displayChannels, channels, timerange);
		const { barInfoWidth, barInfoValues } = this.buildBarInfoBox(displayChannels, channels, timerange);

		return (
			<ChartContainer
				timeRange={timerange}
				trackerPosition={tracker}
				onTrackerChanged={this.handleTrackerChanged}
				trackerShowTime={true}
				maxTime={new Date(maxTime)}
				minTime={new Date(minTime)}
				onTimeRangeChanged={this.handleTimeRangeChange}
				enablePanZoom={true}
				minDuration={minDuration}
				format="%d/%m/%Y %H:%M"
			>
				<ChartRow
					height="450"
					trackerInfoValues={trackerInfoValues}
					trackerInfoHeight={10 + trackerInfoValues.length * 16}
					trackerInfoWidth={10 + trackerInfoWidth * 42}
				>
					{this.buildAxes(yAxisInfo, channels)}
					<Charts>{this.buildCharts(displayChannels, channels, timerange, barInfoValues, barInfoWidth, missingIntervals)}</Charts>
				</ChartRow>
			</ChartContainer>
		);
	}
	buildBarInfoBox(displayChannels, channels) {
		const highlight = this.state.highlight;
		let barInfoWidth = 0;
		let barInfoValues = [];
		displayChannels
			.filter(channelName => channels[channelName].show && channelName.includes('absolute'))
			.forEach(channelName => {
				if (highlight && highlight.column === channelName) {
					let v = highlight.event.toJSON().data[channelName];
					const value = `${v} ${channels[channelName].units}`;
					const label = channels[channelName].label;
					barInfoWidth = value.length > barInfoWidth ? value.length : barInfoWidth;
					barInfoValues.push({ label, value });
				}
			});
		return { barInfoWidth, barInfoValues };
	}
	buildCharts(displayChannels, channels, timerange, barInfoValues, barInfoWidth, missingIntervals) {

		const charts = [];
		const absoluteChannels = Object.keys(channels).filter(ch => ch.includes('_absolute'));
		let defaultOffset = 10;
		const offsets = {};
		let subtracted = (absoluteChannels.length - 1) / 2;
		absoluteChannels.forEach((absChannel, index) => offsets[absChannel] = (index - subtracted) * defaultOffset);

		for (let channelName of displayChannels) {
			let series = channels[channelName].series;
			let axis = '';
			/*let category = channelName === this.props.measure.sensor[0].sensorCode ?
				this.props.measure.value :
				this.props.compareList.find(comp => comp.sensor[0].sensorCode === channelName).value;*/
			if (channelName.includes('flow')) {
				axis = `flow_axis_${this.state.mode.showOutflow}`
			} else {
				axis = `${channels[channelName].sensorCategory}_axis`;
			}
			// Line charts
			charts.push(
				<LineChart
					key={`line-${channelName}`}
					axis={axis}
					visible={channels[channelName].show}
					series={series}
					columns={[channelName]}
					style={this.state.style}
					interpolation={channels[channelName].sensorCategory !== 'P' ? "curveLinear" : "curveStep"}
					breakLine
				/>
			);

		}
		for (let index = 0; index < missingIntervals.length; index++) {
			const interval = missingIntervals[index];
			charts.push(
				<TimeRangeMarker
					key={`missing_data-${index}`}
					visible={true}
					style={{ fill: "#ff00008a" }}
					timerange={interval}
					timeScale={()=>null}
					height={1}
					width={1}
				/>
			);
		}
		return charts;
	}
	buildTrackerInfoBox(displayChannels, channels, timerange) {
		let trackerInfoWidth = 0;
		const trackerInfoValues = displayChannels
			.filter(channelName => channels[channelName].show && !channelName.includes('absolute'))
			.map(channelName => {
				const fmt = format(channels[channelName].format);
				let series = channels[channelName].series.crop(timerange);
				let v = "--";
				if (this.state.tracker) {
					if (channels[channelName].sensorCategory === 'P') {
						const trackerInMillis = (new Date(this.state.tracker)).getTime();
						const timestamps = this.state.points[channelName].map(p => p[0]);//.filter(t => t < trackerInMillis);
						const closestMillis = timestamps.reduce((a, b) => {
							return Math.abs(b - trackerInMillis) < Math.abs(a - trackerInMillis) ? b : a;
						});
						v = fmt(this.state.points[channelName].find(p => p[0] === closestMillis)[1])
					} else {
						if (!!series.atTime(new Date(this.state.tracker))) {
							const vv = series.atTime((new Date(this.state.tracker))).get(channelName);
							if (vv !== null) {
								v = fmt(vv);
							}
						}
					}
				}
				const label = channels[channelName].label;
				const value = `${v} ${channels[channelName].units}`;
				trackerInfoWidth = value.length > trackerInfoWidth ? value.length : trackerInfoWidth;
				return { label, value };
			});
		return { trackerInfoWidth, trackerInfoValues };
	}
	buildAxes(yAxisInfo, channels) {
		const axisList = [];
		const categories = Object.values(yAxisInfo).map(sensor => sensor.category);
		const measures = [this.props.measure, ...this.props.compareList];
		[...new Set(categories)].forEach(category => {

			let sensors = Object.keys(yAxisInfo).filter(s1 => yAxisInfo[s1].category === category);

			let absSensors = sensors.filter(sensor => sensor.includes('absolute'));
			if (absSensors.length > 0) {
				let min = 9999;
				let max = 0;
				let label = 'Pluviometro (Pioggia assoluta)';
				absSensors.forEach(absSensor => {
					min = yAxisInfo[absSensor].min < min ? yAxisInfo[absSensor].min : min;
					max = yAxisInfo[absSensor].max > max ? yAxisInfo[absSensor].max : max;
				})
				const format = channels[absSensors[0]].format;
				const id = `absolute_axis_${this.state.mode.showAbsoluteRain}`;
				const visible = channels[absSensors[0]].show;
				axisList.push(
					<YAxis
						id={id}
						key={id}
						visible={visible}
						label={label}
						min={min}
						max={max}
						width={70}
						type="linear"
						format={format}
					/>
				);
			}

			let flowSensors = sensors.filter(sensor => sensor.includes('flow'));
			if (flowSensors.length > 0) {
				let min = 9999;
				let max = 0;
				let label = 'Idrometro (Portata)';
				flowSensors.forEach(flowSensor => {
					min = yAxisInfo[flowSensor].min < min ? yAxisInfo[flowSensor].min : min;
					max = yAxisInfo[flowSensor].max > max ? yAxisInfo[flowSensor].max : max;
				})
				const format = channels[flowSensors[0]].format;
				const id = `flow_axis_${this.state.mode.showOutflow}`;
				const visible = channels[flowSensors[0]].show;
				axisList.push(
					<YAxis
						id={id}
						key={id}
						visible={visible}
						label={label}
						min={min}
						max={max}
						width={70}
						type="linear"
						format={format}
					/>
				);
			}

			let otherSensors = sensors.filter(sensor => !(sensor.includes('absolute') || sensor.includes('flow')));
			if (otherSensors.length > 0) { // always true
				let min = 9999;
				let max = 0;
				let label = measures.find(meas => meas.value === category).label
				otherSensors.forEach(otherSensor => {
					min = yAxisInfo[otherSensor].min < min ? yAxisInfo[otherSensor].min : min;
					max = yAxisInfo[otherSensor].max > max ? yAxisInfo[otherSensor].max : max;
				})
				const format = channels[otherSensors[0]].format;
				const id = `${category}_axis`;
				const visible = channels[otherSensors[0]].show;
				axisList.push(
					<YAxis
						id={id}
						key={id}
						visible={visible}
						label={label}
						min={min}
						max={max}
						width={70}
						type="linear"
						format={format}
					/>
				);
			}
		});
		return axisList;
	}
	renderBrush = () => {
		let { channels, missingIntervals, brushrange, tracker} = this.state;
		let {colorsMap, measure, comboMode, filter, interval} = this.props;
		let targetSensorCode = measure.sensor[0].sensorCode;
		let targetSensorCategory = measure.value;
		const targetColor = !this.props.autoColors && colorsMap[targetSensorCategory].sensors[targetSensorCode];
		const areaStyle = {
			[targetSensorCode]: {
				line: {
					normal: { stroke: targetColor, fill: "none", strokeWidth: 1 },
					highlighted: { stroke: targetColor, fill: "none", strokeWidth: 1 },
					selected: { stroke: targetColor, fill: "none", strokeWidth: 1 },
					muted: { stroke: targetColor, fill: "none", opacity: 0.4, strokeWidth: 1 }
				},
				area: {
					normal: { fill: targetColor, stroke: "none", opacity: 0.75 },
					highlighted: { fill: targetColor, stroke: "none", opacity: 0.75 },
					selected: { fill: targetColor, stroke: "none", opacity: 0.75 },
					muted: { fill: targetColor, stroke: "none", opacity: 0.25 }
				}
			}
		};

		let missingIntervalsCharts = []
		for (let index = 0; index < missingIntervals.length; index++) {
			const interval = missingIntervals[index];
			missingIntervalsCharts.push(
				<TimeRangeMarker
					key={`missing_data_brush-${index}`}
					visible={true}
					style={{ fill: "#ff00008a" }}
					timerange={interval}
					timeScale={()=>null}
					height={1}
					width={1}
				/>
			);
		}

		if (comboMode){
			if (!!filter) {
				brushrange = new TimeRange([filter.min, filter.max]);
			} else {
				brushrange = new TimeRange([interval.start.valueOf(), interval.end.valueOf()]);
			}
		}

		return (
			<ChartContainer
				timeRange={channels[targetSensorCode].series.range()}
				trackerPosition={tracker}
				format="%d/%m/%Y %H:%M"
			>
				<ChartRow height="100" debug={false}>
					<Brush
						timeRange={brushrange}
						allowSelectionClear
						onTimeRangeChanged={this.handleTimeRangeChange}
					/>
					<YAxis
						id={`${targetSensorCategory}_brush_axis`}
						label={channels[targetSensorCode].label}
						min={channels[targetSensorCode].min}
						max={channels[targetSensorCode].max}
						width={70}
						type="linear"
						format=".1f"
					/>
					<Charts>
						<AreaChart
							axis={`${targetSensorCategory}_brush_axis`}
							style={areaStyle}
							columns={{ up: [targetSensorCode], down: [] }}
							series={channels[targetSensorCode].series}
							interpolation="curveStep"
						/>
						{missingIntervalsCharts}
					</Charts>
				</ChartRow>
			</ChartContainer>
		);
	};
	changeRainMode = () => {
		let { mode, channels } = this.state;
		mode.showAbsoluteRain = !this.state.mode.showAbsoluteRain;
		Object.keys(channels)
			.filter(key => key.includes('absolute'))
			.forEach(k => {
				channels[k].show = this.state.mode.showAbsoluteRain;
				channels[k.replace('_absolute', '')].show = !this.state.mode.showAbsoluteRain;
			});
		this.setState({ mode, channels })
	}
	changeOutflowMode = () => {
		let { mode, channels } = this.state;
		mode.showOutflow = !this.state.mode.showOutflow;
		let missingScales = [];
		Object.keys(channels)
			.filter(key => key.includes('flow'))
			.forEach(k => {
				channels[k].show = this.state.mode.showOutflow;
				channels[k.replace('_flow', '')].show = !this.state.mode.showOutflow;
				if (this.state.mode.showOutflow && !!!this.state.outflowScales[k.replace('_flow', '')]) {
					missingScales.push(k.replace('_flow', ''));
				}
			});
		this.setState({ mode, channels }, () => {
			if (this.state.mode.showOutflow && missingScales.length > 0) {
				let text = `Non sono presenti i parametri per i${missingScales.length > 1 ? '' : 'l'} sensor${missingScales.length > 1 ? 'i' : 'e'} ${missingScales.toString()}`
				ReactSwal.fire('Errore nel calcolo delle portate', text, 'error')
			}
		})
	}
	categoryInSelection = (queryCategory) => {
		let { measure, compareList } = this.props;
		let targetSensorCategory = measure.value;
		return (!!compareList.find(compared => compared.value === queryCategory) || targetSensorCategory === queryCategory);
	}
	renderMode = () => {
		const { mode: { showOutflow } } = this.state;
		return (
			<div className="col-md-6" style={{ fontSize: 14, color: "#777" }}>
				{this.categoryInSelection('I') ? <span
					style={showOutflow ? linkStyleActive : linkStyle}
					onClick={() => this.changeOutflowMode()}
				>
					{showOutflow ? 'Idrometri: altezze idrometriche' : 'Idrometri: portate'}
				</span> : <></>}
			</div>
		);
	};
	buildLegend = (channels, displayChannels) => {
		let legend = displayChannels.filter(key => !(key.includes('absolute') || key.includes('flow'))).map(channelName => ({
			key: channelName,
			label: channels[channelName].label,
			disabled: !channels[channelName].show
		}));
		if (this.state.mode.showAbsoluteRain) {
			legend = legend.filter(leg => !displayChannels.includes(`${leg.key}_absolute`));
			legend.push(...displayChannels.filter(key => key.includes('absolute')).map(channelName => ({
				key: channelName,
				label: channels[channelName].label,
				disabled: !channels[channelName].show
			})));
		}
		if (this.state.mode.showOutflow) {
			legend = legend.filter(leg => !displayChannels.includes(`${leg.key}_flow`));
			legend.push(...displayChannels.filter(key => key.includes('flow')).map(channelName => ({
				key: channelName,
				label: channels[channelName].label,
				disabled: !channels[channelName].show
			})));
		}
		return legend;
	}
	render() {
		const { ready, channels, displayChannels, style, tracker } = this.state;
		if (!ready) {
			return <Skeleton height={this.props.comboMode ? "65vh" : "60vh"} duration={8}/>;
		} else {
			const legend = this.buildLegend(channels, displayChannels);
			return (
				<div className="mt-2">
					<div>
						{this.renderMode()}
					</div>
					<hr style={{margin: 0}}/>
					<DownloadContainer
						imageName={"punctual_measurements"}
						disabled={false}
						updateChart={() => this.props.updateChart()}
					>
						<>
							<div className="row">
								<div className="col-6">
									<Legend
										type={"line"}
										style={style}
										categories={legend}
										onSelectionChange={this.handleActiveChange}
									/>
								</div>

								<div className="col-6">
									{tracker
										? `${moment(tracker).format('DD/MM/YYYY HH:mm:ss')}`
										: "-:--:--"}
								</div>
							</div>

							<hr style={{margin: 0}}/>
							<div >
								<div style={chartStyle}>
									<Resizable>
										{this.renderMultiAxisChart()}
									</Resizable>
								</div>
							</div>
						</>
					</DownloadContainer>
					<div >
						<div style={brushStyle}>
							<Resizable>{this.renderBrush()}</Resizable>
						</div>
						<span style={{fontSize: '0.8rem', fontFamily: 'sans-serif'}}>
							<sup>(*)</sup>
							I grafici mostrano i dati in ora locale (GMT+{(new Date()).getTimezoneOffset() === -120 ? 2 : 1})</span>
					</div>
				</div>
			)
		}
	}
};






