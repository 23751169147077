import React, {useEffect, useState} from 'react'
import {Bar} from "react-chartjs-2";
import chroma from 'chroma-js'
import DownloadContainer from "#/commons/components/DownloadContainer";

const buildData = (labels, series, joinRainAxes, legendVisibility) => ({
    labels,
    datasets: series.map(({label, data, sensorCategory: yAxisID, color}) => ({
        label,
        data,
        yAxisID: yAxisID === 'P_cumulated' && joinRainAxes ? 'P' : yAxisID,
        ...(yAxisID === 'P' ? {
                 backgroundColor: color
            } : {
                type: 'line',
                cubicInterpolationMode: 'monotone',
                borderColor: color,
                borderWidth: 3,
                fill: yAxisID === 'P_cumulated',
                backgroundColor: color && chroma(color).alpha(0.2).hex()
            }
        ),
        hidden:  legendVisibility[label]
    }))
})
const buildOptions = (sensorCategories, joinRainAxes, chartReference, updateLegends) => ({
    maintainAspectRatio: false,
     responsive: true,
    scales: {
        yAxes: sensorCategories.map(({id, label: labelString, masterColor: fontColor}) => ({
            id,
            display: 'auto',
            gridLines: {display: false},
            ticks: {beginAtZero: id === 'P', fontColor},
            scaleLabel: {
                display: true,
                labelString,
                fontColor,
                fontSize: 14
            }
        })).filter(yAxis => joinRainAxes ? yAxis.id !== 'P_cumulated' : true)
    },
    legend: {
        onClick: (e, legendItem) => {
            let index = legendItem.datasetIndex;
            let chartInstance = chartReference.chartInstance;
            let meta = chartInstance.getDatasetMeta(index);
            meta.hidden = meta.hidden === null ? !chartInstance.data.datasets[index].hidden : null;
            chartInstance.update();
            console.log('Passing ', chartInstance.data.datasets[index].label, meta.hidden)
            updateLegends(chartInstance.data.datasets[index].label, meta.hidden)
        }
    }
})

const MixedChart = ({sensorCategories, xLabels, series, chartKey, joinRainAxes}) => {
    let chartReference = {};
    const [map, setMap] = useState({
        data: {},
        options: {},
    });
    const [legendVisibility, setLegendVisibility] = useState(
        series.reduce((map, s) => {
            map[s.label] = ['P_cumulated','I_flow'].includes(s.sensorCategory)
            return map;
        }, {})
    );

    useEffect(() => {
        setMap({
            data: buildData(xLabels, series, joinRainAxes, legendVisibility),
            options: buildOptions(sensorCategories, joinRainAxes, chartReference, (label, hidden) => setLegendVisibility({...legendVisibility, [label]: hidden}))});
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [chartKey]);

    return (<DownloadContainer
            imageName={"aggregated_measurements"}
            disabled={false}
        >
            <div style={{width: '100%', height: '50vh'}}>
                <Bar ref={reference => chartReference = reference} key={chartKey} {...map} height={null} width={null}/>
            </div>
        </DownloadContainer>)
}

export default MixedChart;
