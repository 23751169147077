import APIUtils from "./APIUtils";

export const ROLE_ADMIN = 'ROLE_ADMINISTRATOR';
export const ROLE_ADMIN_PERSONALE = 'ROLE_ADMIN_PERSONALE';
export const ROLE_CONFIGURATOR = 'ROLE_CONFIGURATOR';
export const ROLE_OFFICER = 'ROLE_OFFICER';
export const ROLE_READER = 'ROLE_READER';

class AuthenticationService {

    auth = null;


    init = (auth) => {
        this.auth = auth;
        //TODO salvare come cache il profilo utente e chiamare il backend per prelevare i ruoli
        //la chiamata deve essere bloccante
    }

    logout = () => {
        const issuer = this.auth.user?.profile.iss;
        const accessToken = this.auth.user?.access_token;
        let authorizationHeader = { "Authorization": `Bearer ${accessToken}` }
        fetch(`${issuer}/connect/revoke-sso-tokens?accessToken=${accessToken}`,{
            method: "POST",
            headers: authorizationHeader
        }).then( () => {
            // debugger;
            this.auth.removeUser();
            this.auth.signoutRedirect();
        });
    }

    haveRolesPermssions = (roles) => {
        // debugger;
        return this.auth.user?.profile.authorities?.length > 0 && (this.auth.user?.profile.authorities?.some((roleName) => roles.includes(roleName)) || roles.length === 0);
    }

    isUserLoggedIn = () => {
        return this.auth.isAuthenticated;
    }

    isValidSession = () => {
        return this.auth.isAuthenticated && !this.auth.user?.expired;
    }

    getLoggedInToken = () => {
        return this.auth.user?.profile.access_token;
    }

    getLoggedInUsername = () => {
        return this.auth.user?.profile.username;
    }

    getLoggedInName = () => {
        return this.auth.user?.profile.name;
    }

    getLoggedInSurname = () => {
        return this.auth.user?.profile.surname;
    }

    getLoggedInExpDate = () => {
        return this.auth.user?.profile.expires_at;
    }

    getLoggedInUserData = () => {
        return this.auth.user?.profile;
    }

}

export default AuthenticationService = new AuthenticationService();