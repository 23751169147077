import React, { useState, useRef } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import ForceIngestionClient from '#/lib/ForceIngestionClient';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const IngestionForce = () => {
	const ReactSwal = withReactContent(Swal);
	const loadingSwal = Swal.mixin({
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			Swal.showLoading();
		},
	});

	// State to store the files
	const [files, setFiles] = useState([]);

	const handleChangeStatusZone = (fileWithMeta, status) => {
		let {
			file,
			meta: { size },
		} = fileWithMeta;

		if (status === 'removed') {
			// Handle file removal here
			setFiles([]);
		}

		if (status === 'done') {
			const reader = new FileReader();
			reader.onload = (event) => {


				const fileContent = new Blob([new Uint8Array(event.target.result)], {
					type: fileWithMeta?.file?.type,
				});
				loadingSwal.fire('Ingestion forzata in corso...');

				ForceIngestionClient.forceIngestion(fileContent, fileWithMeta?.file?.name).then(() => {
					ReactSwal.hideLoading();
					ReactSwal.fire('Ingestion presa in carico. I nuovi dati saranno disponibili in 2/3 minuti', '', 'success').then (()=>{window.location.reload()})
					// Clear files manually in the state
					
				});
				
			};
			reader.onerror = (event) => {
				// Error handling
			};
			reader.readAsArrayBuffer(file);
		}
	};

	return (
		<div className="mt-4 generic-page container-fluid data-page textAlignCenter">
			<h2>Ingestion Forzata</h2>
			<div className="d-flex h-80">
				<div className="col-sm-12 my-auto">
					<Dropzone
						key={files.length}
						onChangeStatus={handleChangeStatusZone}
						accept=".csv"
						maxFiles={1}
						inputContent="Inserisci il file da importare"
						styles={{
							dropzone: { overflow: 'hidden', width: '30vw' },
							dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
							inputLabel: (files, extra) =>
								extra.reject ? { color: 'red' } : { color: '#315495' },
						}}
						// Pass files state to Dropzone
						files={files}
					/>
				</div>
			</div>
		</div>
	);
};

export default IngestionForce;
