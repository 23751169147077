import moment from "moment";
import { reactFormatter } from "react-tabulator";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";

import { BsFillQuestionCircleFill } from "react-icons/bs";
import { TiWarning } from "react-icons/ti";


import AuthenticationService, { ROLE_ADMIN, ROLE_OFFICER } from "#/lib/AuthenticationService";

import _ from "lodash";
const isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);



const ValueCell = ({ cell, field, now }) => {


	let data = cell.getRow()._row.data;
	let value = data[field];
	let isFuture = data.timestampStart > now;
	if (!!data.monthlyStats) {
		return <div></div>
	}
	else {
		return <div><span>{!!value ? value.toFixed(2) : "ND"}</span></div>;			
	}


}



const MonthlyStatsTables = ({ selectedCategory, data, tableKey, nested, tableRef, label }) => {

	const now = moment().valueOf();

	const getColumns = () => {
		let columns = []
		columns.push(
			...[

				{
					title: "Data",
					field: "date",
					sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
						return aRow._row.data.timestampKey - bRow._row.data.timestampKey;
					},
					//formatter: cell => `${moment(cell._cell.row.data.timestampKey).format("MM/YYYY")}`,
				},
				{
					title: "Stazione",
					field: "stationName",
					headerFilter: true,
					headerFilterFunc: (headerValue, rowValue) => { return !rowValue || rowValue.toLowerCase().includes(headerValue.toLowerCase()) }

				}
			]

		);


		switch (selectedCategory) {
			case "P":
				columns.push(
					...[
						{
							title: "Pioggia Cumulata",
							field: "value",
							sorter: "number",
							formatter: reactFormatter(<ValueCell field={"value"} now={now} />),
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"
						}

					]
				);
				break;
			case "T":
				columns.push(
					...[
						{
							title: "MIN", field: "minValue", 
							formatter: reactFormatter(<ValueCell field={"minValue"} now={now} />), sorter: "number", editor: "number", 
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"

						},
						{
							title: "AVG", field: "avgValue", 
							formatter: reactFormatter(<ValueCell field={"avgValue"} now={now} />), sorter: "number", editor: "number", 			
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"
						},
						{
							title: "MAX", field: "maxValue", 
							formatter: reactFormatter(<ValueCell field={"maxValue"} now={now} />), sorter: "number", editor: "number",  			
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"
						}
					]
				);
				break;
			case "I":
				columns.push(
					...[
						{
							title: "Flow (AVG)", field: "avgFlow", 
							formatter: reactFormatter(<ValueCell field={"avgFlow"} now={now} />), sorter: "number", editor: "number",  			
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"
						},
						{
							title: "Flow (MAX)", field: "maxFlow", 
							formatter: reactFormatter(<ValueCell field={"maxFlow"} now={now} />), sorter: "number", editor: "number",  			
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"
						},
						{
							title: "Deflusso", field: "outflow", 
							formatter: reactFormatter(<ValueCell field={"outflow"} now={now} />), sorter: "number", editor: "number",  			
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"
						},
						{
							title: "H (MAX)", field: "maxH", 
							formatter: reactFormatter(<ValueCell field={"maxH"} now={now} />), sorter: "number", editor: "number",  			
							accessorDownload: value => !!value ? value.toFixed(2) : "ND"
						},
					]
				);
				break;
			default:
				break;
		}
		

		return columns;

	}



	const rowFormatter = (row) => {
		
	}



	const getTabulatorOptions = () => {
		let options = {
			data: [],
			height: "65vh",
			invalidOptionWarnings: false,
			selectable: false,
			rowFormatter: (row) => rowFormatter(row),
			dataTree: !!nested,
			dataTreeStartExpanded: true,
			dataTreeFilter: true,
			dataTreeSort: true,
			dataTreeChildField: "monthlyStats",
			downloadReady: (fileContents, blob) => blob,
		}

		return options
	};

	

	const getData = () => {
		let tableData = !!nested ? data : data.map(d => d.monthlyStats[0])
		tableData.sort((a, b) => {
			// Parse the dates to compare
			const dateA = new Date(a.date.split('/').reverse().join('-')); // Convert to YYYY-MM-DD format
			const dateB = new Date(b.date.split('/').reverse().join('-'));
		  
			// Primary sort by date
			if (dateA - dateB !== 0) return dateA - dateB;
		  
			// Secondary sort by stationName
			return a.stationName.localeCompare(b.stationName);
		  });
		  console.log("tableData", tableData)
		  return tableData
	}

	return <>
		{!!data && !_.isEmpty(data) &&<ReactTabulator
			ref={tableRef}
			/*ref={ref => (this.ref = ref)}*/
			columns={getColumns()}
			data={getData()}
			options={getTabulatorOptions()}
			key={tableKey}
		/>}
		
	</>
}
export default MonthlyStatsTables;
