import React from 'react';
import ProtocolloSettingsClient from "#/lib/ProtocolloSettingsClient"

import {Form, Formik} from "formik";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
	BooleanField,
	LocationField,
	NumericField,
	SensorCategoryField,
	StringField,
	SubmitButton
} from "#/commons/components/forms/formik/FormikItems";
import LabelledOutline from '#/commons/components/LabelledOutline'

export default class ProtocolloSettings extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			settings : {},
			selectedTabKey : "MAU"
		}
	}

	componentDidMount(){
		ProtocolloSettingsClient.get(
			(data) => {
				console.log(data)
				this.setState({
					settings : data
				})
			},
			(err) => {
				this.setState({
					settings : {}
				})
			}
		)
	}


	onSave = () => {
		ProtocolloSettingsClient.get(
			(data) => {
				console.log(data)
				this.setState({
					settings : data
				})
			},
			(err) => {
				this.setState({
					settings : {}
				})
			}
		)
	}



	render() {


		return <div className="">
					<Tabs defaultActiveKey="MAU"
						activeKey={this.state.selectedTabKey}
						onSelect={(k) => this.setState({
							selectedTabKey : k
						})}
					>
					<Tab eventKey="MAU" title="MAU">
						<ProtocolloSettingsForm type="MAU" settings={this.state.settings["MAU"]} onSave={this.onSave}/>
					</Tab>
					<Tab eventKey="MAIB" title="MAIB">
						<ProtocolloSettingsForm type="MAIB" settings={this.state.settings["MAIB"]} onSave={this.onSave}/>
					</Tab>
					<Tab eventKey="A3" title="A3">
						<ProtocolloSettingsForm type="A3" settings={this.state.settings["A3"]} onSave={this.onSave}/>
					</Tab>
					<Tab eventKey="BIM" title="BIM">
						<ProtocolloSettingsForm type="BIM" settings={this.state.settings["BIM"]} onSave={this.onSave}/>
					</Tab>
					<Tab eventKey="TEST" title="TEST">
						<ProtocolloSettingsForm type="TEST" settings={this.state.settings["TEST"]} onSave={this.onSave}/>
					</Tab>
					
					</Tabs>
				</div>
		
	}
}

class ProtocolloSettingsForm extends React.Component {


	constructor(props) {
		super(props);
	}

	componentDidMount(){

	}

	onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
		ProtocolloSettingsClient.store(
			() => {
				setSubmitting(false)
				this.props.onSave()
			},
			(err) => {
				resetForm()
			},
			values,
			this.props.type
		)
	}

	



	render() {

		return <>{this.props.settings && <div className="d-flex">
			<div>
			<Formik
				enableReinitialize
				initialValues={this.props.settings}
				onSubmit={(values, props) => this.onSubmit(values,props)}
			>
				<Form className="needs-validation" noValidate="" style={{ width: "50vw" }}>
					<div className="d-block w-80 m-4">
						<div className=""><BooleanField className="mt-4" name="protocolloEnabled" label="protocolloEnabled" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloUser" label="protocolloUser" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloPassword" label="protocolloPassword" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloUrl" label="protocolloUrl" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloIdA00" label="protocolloIdA00" /></div>
						<div className="mt-2"><BooleanField className="mt-4" name="protocolloMailingListEnabled" label="protocolloMailingListEnabled" /></div>

						<div className="mt-2"><StringField className="mt-4" name="protocolloCorrispondenteCodiceSoggetto" label="Corrispondenti" /></div>

						<div className="mt-2"><StringField className="mt-4" name="protocolloUfficioMittente" label="protocolloUfficioMittente" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloUfficioDestinatario" label="protocolloUfficioDestinatario" /></div>
						<div className="mt-2"><StringField  name="protocolloTipoMezzo" label="protocolloTipoMezzo" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloTipoDocumento" label="protocolloTipoDocumento" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloAllegatoClassificazione" label="protocolloAllegatoClassificazione" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloTipoInvioPec" label="protocolloTipoInvioPec" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloModoInvioCopiaAllegati" label="protocolloModoInvioCopiaAllegati" /></div>
						<div className="mt-2"><StringField className="mt-4" name="protocolloResponseFormat" label="protocolloResponseFormat" /></div>


						<SubmitButton style={{float: 'right'}} className="mt-4" title="Invia" />
					</div>
				</Form>
			</Formik>
			</div>
			<div>{this.props.type==="TEST" && 
				<>
					<div className="mb-4">
					<Formik
						enableReinitialize
						initialValues={{}}
						onSubmit={(values, props) => {
							ProtocolloSettingsClient.testProtocol(
								(data) => {
									alert(data.protocol)
									props.setSubmitting(false)
								},
								(err) => {
									props.resetForm()
								}
							)
						}}
					>
						<Form className="needs-validation" noValidate="" style={{ width: "50vw" }}>
							<div className="d-block w-80 m-4">
								<SubmitButton style={{float: 'right'}}  title="Test Protocollo" />
							</div>
						</Form>
					</Formik>
					</div>
					<div className="mt-4">

						<Formik
							enableReinitialize
							initialValues={{}}
							onSubmit={(values, props) => {
								console.log(values)

								let a = document.createElement('a');
								document.body.appendChild(a);
								a.download = "test.pdf";
								a.href = ProtocolloSettingsClient.testRetrieve(values.protocolNumber)
								a.click();

							}}
						>
							<Form className="needs-validation" noValidate="" style={{ width: "50vw" }}>
								<div className="d-block w-80 m-4">
									<div className="mt-2"><StringField className="mt-4" name="protocolNumber" label="Numero Protocollo" /></div>
									<SubmitButton style={{float: 'right'}} className="mt-4" title="Recupera Protocollo" />
								</div>
							</Form>
						</Formik>
					
					</div>
				</>
			}
			</div>
		</div>
		
		}
		</>
	}
}


	