import React, {useState, useEffect} from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Button from "@material-ui/core/Button";
import {GoFileDirectory} from "react-icons/go";
import StaticPageClient from '#/lib/StaticPageClient';

const baseImgUrl = '/img/salaOperativa/';
const urls = ['dsc_0026.jpg','dsc_0027.jpg','dsc_0028.jpg','dsc_0029.jpg','dsc_0030.jpg','img_0564.jpg','img_0565.jpg'];




const CentroFunzionalePage = () => {

	const [page, setPage] = useState(null)

	useEffect(()=>{
		StaticPageClient.getByCode(
			(sp)=>{
				console.log(sp)
				setPage(sp.code)
			},
			()=>{},
			"about"
		)
	})

    return<div className="generic-page container-fluid textAlignCenter">
		{!!page && <p dangerouslySetInnerHTML={{ __html: `${page}` }}></p>}
        {/*<h2>La nostra Sala Operativa</h2>

        <AwesomeSlider className="mx-auto" style={{height: '70vh', width: '60vw'}}>
            {urls.map((name, i) => <div key={i} data-src={`${baseImgUrl}${name}`}/>)}
        </AwesomeSlider>



        <section style={{marginTop: '10vh', marginRight: '10vw', marginLeft: '10vw'}}>
            <hr/>
            <p><strong>Un pò di storia</strong></p>
            <p>&nbsp;</p>
            <p style={{textAlign: 'justify'}}>In Italia, come in altri paesi, l'attività&nbsp; di rilevamento,
                validazione, archiviazione e pubblicazione delle grandezze meteoclimatiche&nbsp; gestita, da quasi un
                secolo, dal Servizio Idrografico.</p>
            <p style={{textAlign: 'justify'}}>Il sistema di rilevazione sistematica dei dati idrologici nacque in Italia
                all' inizio del XX secolo con l' stituzione dell' Ufficio Idrografico del Magistrato delle acque di
                Venezia nel 1907 e dell' Ufficio Idrografico del Po nel 1912. Successivamente, nel 1917, furono creati
                gli altri Uffici Compartimentali come sezioni autonome del Genio Civile e nel 1919 un Ufficio
                Idrografico centrale.</p>
            <p style={{textAlign: 'justify'}}>Nel 1970 gli Uffici Compartimentali delle regioni a statuto speciale vennero
                trasferiti alle Regioni. Successivamente nel 1991 il Servizio Idrografico venne trasferito dal Ministero
                dei Lavori Pubblici alla Presidenza del Consiglio, all'interno del Dipartimento dei Servizi Tecnici
                Nazionali. Tale trasferimento sembrava rispondere al mutato ruolo del Servizio Idrografico che da
                struttura di supporto nella rilevazione di dati finalizzati alla progettazione di opere pubbliche (da
                cui la collocazione nel Ministero dei Lavori Pubblici) diventava un servizio tecnico a supporto di vari
                settori (dall'ambiente, ai lavori pubblici, alla protezione civile ecc.) da cui la collocazione nella
                Presidenza del Consiglio.</p>
            <p style={{textAlign: 'justify'}}>Dopo poco più di un decennio dal suo trasferimento nella Presidenza del
                Consiglio, il Servizio Idrografico Nazionale fu di fatto smantellato in quanto nel 2002 la competenza,
                in conseguenza del decentramento amministrativo introdotto con la <span style={{color: '#ff0000'}}>legge 59/97</span> ed
                il <span style={{color: '#ff0000'}}>D. L.vo 112/98</span>, passa alle Regioni e come struttura nazionale
                rimase solo un servizio dell'Agenzia per la protezione dell' Ambiente (APAT- oggi <span
                    style={{color: '#ff0000'}}>ISPRA</span>) incaricato di raccordare a livello nazionale le
                attività&nbsp; dei servizi regionali.</p>
            <p style={{textAlign: 'justify'}}>Il trasferimento alle Regioni, che era già&nbsp; avvenuto nel 1970 per le
                regioni a statuto speciale, ha creato realtà&nbsp; notevolmente diverse sia nell' individuazione delle
                strutture regionali incaricate di gestire il servizio idrografico, sia nei livelli di efficienza del
                servizio.</p>
            <p style={{textAlign: 'justify'}}>L' attività&nbsp; di coordinamento da parte dell' ISPRA, non essendo sancita
                in maniera chiara dalla legge, non ha avuto modo di tradursi in maniera operativa, per cui oggi,
                esistono realtà&nbsp; notevolmente diverse nelle varie Regioni.</p>
            <p style={{textAlign: 'justify'}}>Per quanto riguarda in particolare la Calabria, il suo territorio ricade
                interamente nel territorio dell' ex Compartimento Idrografico di Catanzaro, che gestiva il territorio di
                tutti i bacini idrografici compresi tra il Bradano ed il Noce, comprendenti oltre che l' intera Regione
                Calabria anche buona parte della Regione Basilicata ed una piccolissima parte della regione Puglia.</p>
            <p style={{textAlign: 'justify'}}>Al trasferimento delle competenze dallo Stato alle Regioni, la struttura
                dell' ex Ufficio Compartimentale di Catanzaro fu trasferita alla Regione Calabria, mentre le stazioni di
                monitoraggio ed i dati (cartacei ed elettronici) relativi al territorio lucano Regione Basilicata,
                nonchÃ© la sezione staccata di Potenza dell' Ufficio Compartimentale furono trasferiti alla Regione
                Basilicata.</p>
            <p style={{textAlign: 'justify'}}>La Regione Calabria ha assegnato la competenza del Servizio Idrografico all'
                Agenzia Regionale per la Protezione dell' Ambiente (<span style={{color: '#ff0000'}}>ARPACAL</span>).</p>
            <p style={{textAlign: 'justify'}}>Le mutazioni normative e di collocazione istituzione del servizio
                idrografico derivano, oltre che al generale decentramento dalla funzioni amministrative introdotto in
                Italia dalla metà&nbsp; degli anni '90 in poi, anche dall' evoluzione tecnologica che ha notevolmente
                cambiato le natura del Servizio Idrografico rispetto all' inizio del secolo scorso, quando fu istituito.
                A partire dai primi anni '80, infatti, l'introduzione di stazioni di rilevamento automatiche rese
                possibile l' acquisizione di nuove tipologie di dati, l' aumento delle frequenze di rilevazione dei dati
                e soprattutto la loro rilevazione in tempo reale. In particolare quest' ultimo aspetto, ossia la
                disponibilità&nbsp; immediata del dato, ha trasformato sempre di più la natura del Servizio Idrografico
                da un servizio dedito alla raccolta di dati con fini di studio verso un servizio di supporto alle
                strutture di protezione civile nella gestione dell' allertamento per possibili fenomeni di dissesto
                idrogeologico.</p>
            <p style={{textAlign: 'justify'}}>La consapevolezza dell' importanza della creazione di strutture operative
                capaciti di interpretare i dati rilevati in tempo reale (e non solo di rilevarli) apparve a tutti chiara
                dopo le tragedie di Sarno (SA) del 5 maggio 1998 e di Soverato (CZ) del 10 settembre 2000.</p>
            <p style={{textAlign: 'justify'}}>Questi tragici eventi, diedero il via ad una serie di provvedimenti
                organizzativi e normativi (tra cui l' <span style={{color: '#ff0000'}}>Ordinanza di Protezione Civile n. 3081 del 12 settembre 2000</span> che
                istituì il Centro Funzionale Meteoidrologico per la Regione Calabria) che si completarono con l'
                emissione della <span style={{color: '#ff0000'}}>Direttiva del Presidente del Consiglio del 27 febbraio 2004</span>,
                a seguito della quale le varie Regioni italiane stanno attivando i propri centri funzionali.</p>
            <hr/>
                <p>&nbsp;</p>
                <p><strong>I compiti del centro</strong></p>
                <p style={{textAlign: 'justify'}}>&nbsp;</p>
                <p style={{textAlign: 'justify'}}>Il Centro Funzionale Meteorologico, Idrografico e Mareografico della
                    Regione Calabria svolge la funzione di supporto tecnico-scientifico alla decisione per la gestione
                    di emergenze di protezione civile.</p>
                <p style={{textAlign: 'justify'}}>I compiti ed il ruolo del Centro sono definiti, dal punto di vista
                    normativo dalla <span style={{color: '#ff0000'}}>Direttiva del Presidente del Consiglio del 27 febbraio 2004</span> e
                    dalla <span style={{color: '#ff0000'}}>Direttiva sul Sistema di allertamento per il rischio idrogeologico ed idraulico in Calabria</span> e
                    possono essere riassunti in:</p>
                <ul style={{textAlign: 'justify'}}>
                    <li>Attività&nbsp; previsionale:
                        <ul>
                            <li>valutazione, sostenuta da modellistica numerica, della situazione meteorologica,
                                idrologica, idraulica attesa e dei previsti effetti al suolo;
                            </li>
                        </ul>
                    </li>
                    <li>Attività&nbsp; di monitoraggio e sorveglianza:
                        <ul>
                            <li>osservazione quantitativa, attraverso una rete di stazioni di monitoraggio diffuse su
                                tutto il territorio regionale dei fenomeni in atto;
                            </li>
                            <li>previsione a breve dei relativi effetti attraverso il now casting meteorologico e/o
                                modelli si simulazione afflussi-deflussi inizializzati anche con misure rilevate in
                                tempo reale
                            </li>
                        </ul>
                    </li>
                    <li>Attività&nbsp; di analisi e studio:
                        <ul>
                            <li>costante sviluppo della modellistica previsionale</li>
                            <li>analisi delle principali condizioni di rischio</li>
                            <li>gestione del sistema informativo di interscambio dei dati raccolti per finalità&nbsp; di
                                pianificazione, programmazione e progettazione
                            </li>
                            <li>redazione di rapporti sugli eventi meteorologici più rilevanti sul territorio regionale
                                ecc.
                            </li>
                        </ul>
                    </li>
                </ul>
                <p style={{textAlign: 'justify'}}>Il centro inoltre svolge le funzioni del Servizio Idrografico e
                    Mareografico, trasferito dallo Stato alla Regione ai sensi del <span style={{color: '#ff0000'}}>D.L.vo n. 112 del 31.3.1998</span> ed
                    in particolare:</p>
                <ul style={{textAlign: 'justify'}}>
                    <li>provvede al rilievo sistematico e alle elaborazioni delle grandezze relative al clima
                        terrestre;
                    </li>
                    <li>provvede al rilievo sistematico dei corsi d'acqua;</li>
                    <li>provvede al rilievo sistematico ed alle elaborazioni delle grandezze relative ai deflussi
                        superficiali;
                    </li>
                    <li>provvede alla pubblicazione sistematica, alla fornitura ufficiale dei dati e degli elementi
                        osservati ed elaborati nonchè alla pubblicazione di cartografie tematiche;
                    </li>
                </ul>
                <p style={{textAlign: 'justify'}}>Esso partecipa, inoltre, al Comitato Tecnico dell' <span
                    style={{color: '#ff0000'}}>Autorità&nbsp; di Bacino Regionale</span></p>
                <p style={{textAlign: 'justify'}}>&nbsp;</p>
                <p><strong>L' attività&nbsp; del centro</strong></p>
                <p>&nbsp;</p>
                <p style={{textAlign: 'justify'}}>Il ridotto tempo di preannuncio necessario e i tempi di risposta
                    idrologici dei bacini calabresi che spesso non eccedono le 24 ore, impongono l' osservazione delle
                    misure di precipitazione disponibili in tempo reale, ma un sistema di valutazione, basato solo sulla
                    pioggia caduta ed osservata dai pluviometri, non potrebbe garantire un preannuncio efficace perchè
                    la predizione precederebbe di poco l' evento.</p>
                <p style={{textAlign: 'justify'}}>Di conseguenza la predizione deve utilizzare non solo le osservazioni di
                    pioggia, ma anche la previsione delle stesse derivanti da modelli di circolazione atmosferica, e
                    tutte le altre osservazioni (satellitari e da radar meteorologico), che consentono di ridurre il
                    grado
                    di incertezza.</p>
                <p style={{textAlign: 'justify'}}>Il Centro funzionale, quindi, in collaborazione con Centri di Competenza
                    nazionali e regionali sviluppa tecniche per la gestione operativa di situazioni ad alto rischio a
                    rapida evoluzione temporale, per migliorare la previsione idrometeorologica a brevissimo ed a breve
                    termine, per sperimentare sistemi modellistici per l' utilizzo integrato di osservazioni
                    convenzionali e non convenzionali, per valutare gli effetti al suolo.</p>
                <p style={{textAlign: 'justify'}}>L' attività&nbsp; di monitoraggio e sorveglianza si basa:</p>
                <ul>
                    <li style={{textAlign: 'justify'}}>sulla ricezione dati convenzionali e non convenzionali
                        <ul>
                            <li>Osservazioni in tempo reale provenienti dalla fitta rete di stazioni in telemisura
                                gestite dal Centro (misure a terra di altezze di precipitazione e di livelli
                                idrometrici, pressione atmosferica, l' umidità&nbsp; e temperatura dell' aria, la
                                velocità&nbsp; del vento e direzione del vento ecc.)
                            </li>
                            <li>Osservazioni dai satelliti (Meteosat e satelliti ad orbita polare)</li>
                            <li>Previsioni meteorologiche con modelli di circolazione atmosferica sia a scala globale
                                (GCM) che ad area locale (LAM).
                            </li>
                            <li>Radar meteorologici (in corso di attivazione)</li>
                            <li>Sistema di rilevamento dell' attività&nbsp; elettrica in atmosfera (fulmini) in tempo
                                reale
                            </li>
                        </ul>
                    </li>
                    <li style={{textAlign: 'justify'}}>sulla elaborazione dati
                        <ul>
                            <li>visualizzazione dei dati su piattaforma GIS,</li>
                            <li>validazione delle previsioni,</li>
                            <li>modellistica idrologica ed idraulica</li>
                        </ul>
                    </li>
                    <li>sulla individuazione livelli di allerta e diffusione dellâ' informazione
                        <ul>
                            <li style={{textAlign: 'justify'}}>I livelli attesi o osservati di precipitazione sono
                                comparati con le soglie di allerta predefinite. Gli output dei modelli di simulazione e
                                le altre informazioni disponibili sono utilizzati per valutare possibili situazioni di
                                crisi in particolare punti del territorio. La valutazione di possibili situazioni di
                                pericolositÃ&nbsp; viene comunicata alla sala operativa regionale di protezione civile,
                                ai fini del successivo allertamento del sistema di protezione civile
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr/>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
        </section>*/}

    </div>
}

export default CentroFunzionalePage;
