import React from 'react'
import {GeoJSON} from 'react-leaflet';
import "#/commons/data/section/year-calendar.css"
import "#/backoffice/style/PolygonMapComponent.css"
import {withStyles} from '@material-ui/core/styles';
import GISTools from '#/lib/GISTools';

import moment from 'moment';
import {zonesGeoJson8} from '#/lib/ZoneGeoJsonDefault';
import BulletinClient from "#/lib/BulletinClient";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import {FiClock} from "react-icons/fi";
import DateUtils from "#/lib/DateUtils";
import {properties} from "#/properties";
import MapComponent from "#/commons/map/MapComponent";
import _ from 'lodash';
import {AlertLevelDisplayValues, AlertLevelPalette} from "#/lib/AlertLevel";
import WidthUtils from "#/lib/WidthUtils"
import * as turf from "@turf/turf";
import chroma from "chroma-js";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const defaultTodayTomorrowLabels = {today : 'Oggi', tomorrow : 'Domani'};
const colorScale = ['grey', 'black','green','yellow','orange','red','#4a0505'];

const CustomBottonNavigation = withStyles((theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightRegular,
		backgroundColor:"#f5f5f5",
		'&:hover': {
		  color: '#0c6aaf',
		  opacity: 1,
		},
		'&$selected': {
		  color: '#134367',
		  fontWeight: theme.typography.fontWeightMedium,
		  backgroundColor:"#b7e6ff"
		},
		'&:focus': {
		  color: '#0c6aaf',
		},
	},
	selected: {},
  }))((props) => <BottomNavigationAction {...props} />);

const polygonHighlightStyle = {
	'color': 'black',
	'weight': 2,
	'opacity': 1
}
const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

class MaibMapComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			mapKey: 1,
			title : '',
			geoJson : zonesGeoJson8,
			envelope : {},

			selectedDay : 'today',
			createdAt : null,
			todayTomorrowLabels : defaultTodayTomorrowLabels,
			general: null,
			showLegend : !this.columnMode()

		}
	}
	mapOptions = {
		center: [39.11, 16.55],
		zoom: 6,
		minZoom: 6,
		width: "40vw",
		height: "50vh"
	}

	componentDidMount() {
		if (!!this.props.maibItem){
			console.log('maibItem', this.props.maibItem)
			this.parseMaibData(this.props.maibItem);
		} else {
			this.getLastMAIB();
		}
	}
	getLastMAIB() {
		BulletinClient.listBulletins(
			(obj) => {

				if (obj.data.length > 0) {
					this.parseMaibData(obj.data[0]);
				}
			},
			(err) => { console.log('Error while retrieving mau data', err) },
			{
				pageNumber: 1,
				pageSize: 1,
				bulletinTypeName: 'MAIB'
			}
		)

	}

	parseMaibData(data) {
		let geoJson = this.state.geoJson;
		let {
			jsonData: {
				maib_general,
				maib_today,
				maib_tomorrow
			},
			createdAt,
			id: maibId
		} = data;
		geoJson.features.forEach(feature => {
			feature.properties.today = maib_today[feature.properties.ZONE];
			feature.properties.tomorrow = maib_tomorrow[feature.properties.ZONE];;
		});
		
		let todayTomorrowLabels = defaultTodayTomorrowLabels;
		let selectedDay = 'today';
		let now = moment();
		let passedDays = now.endOf('day').diff(moment(createdAt).startOf('day'), 'days');
		if (passedDays > 1) {
			todayTomorrowLabels = {
				today: '',
				tomorrow: ''
			};
		} else if (passedDays === 1) {
			todayTomorrowLabels = {today: 'Ieri', tomorrow: 'Oggi'};
			selectedDay = 'tomorrow';
		}

		let general = Object.keys(maib_general).reduce((obj, item) => {
			if (item.includes('oggi')){
				let field = item.split('_')[0];
				obj.today[field] = maib_general[item];
			}
			if (item.includes('domani')){
				let field = item.split('_')[0];
				obj.tomorrow[field] = maib_general[item];
			}
			return obj;
		}, {today: {}, tomorrow: {}})
		/*this.addCentroidGeoJSON(geoJson);*/
		this.setState({
			geoJson,
			createdAt,
			maibId,
			todayTomorrowLabels,
			selectedDay,
			general,
			mapKey: (this.state.mapKey + 1) % 1000
		}, () => {
			if (!!this.mapRef){
				this.passMapToFix(this.mapRef)
			}
		})

	}


	polygonStyle(feature, latlng) {
		const {selectedDay} = this.state;
		let level = feature.properties[selectedDay];

		let color = AlertLevelPalette[level];

		return {
			fillColor: color,
			weight: 1,
			opacity: 1,
			color: "black",
			fillOpacity: 1
		};
	}
	handleListItemClick = (event, index) => {
		this.setState({selectedLayer: index, mapKey: (this.state.mapKey + 1) % 1000}, () => {
			if (this.state.selectedLayer === 'fasi_op'){
				this.removeLegend();
			}
		});
	};
	handleDaySelection = (event, newDay) => {
		this.setState({selectedDay : newDay, mapKey: (this.state.mapKey + 1) % 1000});
	};
	findWorstAlert(layerKey){

		let color = 'grey';
		try {
			let {geoJson, selectedDay, createdAt} = this.state;

			if (!!createdAt){
				geoJson.features.forEach(feature => {
					let property = feature.properties[selectedDay][layerKey];
					let zone = feature.properties.ZONE;
					let newColor = this.getIconColor(property, layerKey, selectedDay, zone);
					if (colorScale.indexOf(newColor) > colorScale.indexOf(color)) {
						color = newColor;
					}
				})
			}	
		} catch (err) {
			console.error(err)
		}
		
		return color;
	}

	columnMode = () => WidthUtils.isMobile();
	showLegendControl = () => WidthUtils.isMobile() && this.state.selectedLayer !== 'fasi_op';

	toggleLegend = () => {
		this.setState({
			showLegend : !this.state.showLegend,
			mapKey: (this.state.mapKey + 1) % 1000
		}, () => {
			if (!this.state.showLegend){
				this.removeLegend();
			}
		})
	}
	removeLegend(){
		let tmp = document.getElementsByClassName("newLegend");
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		};
	}
	removeLegendControl(){
		let tmp = document.getElementsByClassName("legendControl");
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		};
	}
	passMapToFix(map){
		if (!!this.props.mapHasBeenChanged){
			this.props.mapHasBeenChanged(map)
		}
	}
	mobileMode = () => WidthUtils.isMobile();

	openInfoModal(selectedFeature, latlng){
		this.setState({
			showInfoModal : true,
			selectedFeature,
			selectedLatLng: latlng,
			selectedIstatCode : selectedFeature.properties.ISTAT
		})
	}

	closeInfoModal(){
		this.setState({
			showInfoModal : false,
			selectedFeature : null,
			selectedIstatCode : null,
			mapKey : (this.state.mapKey + 1) % 1000
		})
	}
	onEachMunicipalityPolygon(feature, layer) {
		layer.bindTooltip(`<b>${feature.properties.COMUNE}</b>`);
		let isBackoffice = window.location.href.includes("backoffice");
		layer.on('click', (e) => {
			if (!isBackoffice){
				layer.setStyle(polygonHighlightStyle)
				this.openInfoModal(feature, e.latlng);
			}
		});
	}

	getInfoModalContent = () => {
		let modalContent = [];
		let alertedListContent = [];
		const {selectedFeature, selectedLatLng, geoJson, selectedDay} = this.state;


		if (!!selectedFeature) {
			let {properties} = selectedFeature;


			let point = turf.point([selectedLatLng.lng, selectedLatLng.lat ]);
			let zonalFeature = geoJson.features.find(zone => turf.booleanPointInPolygon(point, zone));

			if (zonalFeature){

				let level = zonalFeature.properties[selectedDay];
				let color = chroma(AlertLevelPalette[level]).alpha(0.5);
				alertedListContent.push(<h3 style={{textAlign: 'center', color: '#fff'}}> Livello generale di allerta
					incendi boschivi da MAIB: </h3>)
				alertedListContent.push(<h3 style={{
					textAlign: 'center',
					color: '#fff',
					backgroundColor: color,
					borderRadius: '15px'
				}}> {AlertLevelDisplayValues[level] || 'Nessun livello d\'allerta'}</h3>)
			}

			modalContent.push(<h3 style={{textAlign: 'center', color: '#fff'}}> {properties.COMUNE} ({properties.PROVINCIA})<br></br> {properties.ISTAT} </h3>);

			if (alertedListContent.length > 0){
				modalContent.push(...alertedListContent);
			} else {
				modalContent.push(<h3 style={{textAlign: 'center', color: '#fff'}}> Nessuna allerta </h3>)
			}
		}
		return modalContent;
	}
	render() {
		let { selectedDay, maibId, todayTomorrowLabels, createdAt, showLegend, general, mapKey, geoJson} = this.state;
		let docUrl = !!maibId ? properties.url.bulletinDownloadFile + '/' + maibId + '/pdf' : '';
		let todayStr = moment(createdAt).format('DD/MM/yyyy');
		let tomorrowStr = moment(createdAt).add(1,'days').format('DD/MM/yyyy');

		return (
			<div className=" container-fluid mb-2">
				<div className="d-flex justify-content-center m-3">
					<BottomNavigation
						value={selectedDay}
						onChange={(event, newDay) => this.handleDaySelection(event,newDay)}
						style={{ height: "auto"}}
						showLabels>
						<CustomBottonNavigation style={{minWidth: "10vw", borderRadius: "25px 0px 0px 25px"}} label={<><b>{todayTomorrowLabels.today}</b><br/><b>PREVISIONE</b><br/>{todayStr}</>} value="today"/>
						<CustomBottonNavigation style={{minWidth: "10vw", borderRadius: "0px 25px 25px 0px"}} label={<><b>{todayTomorrowLabels.tomorrow}</b><br/><b>PREVISIONE</b><br/>{tomorrowStr}</>} value="tomorrow" />
					</BottomNavigation>
				</div>
				{createdAt ? <div className="row d-flex justify-content-center">
					<a href={docUrl}>
						<span className="icon-span">
							<FiClock/> Stato di allertamento emanato dal MAIB (Messaggio Allertamento Incendi Boschivi) del {DateUtils.epochToDate(createdAt)}
						</span>
					</a>
				</div> : <></>}
				{general && <div className="row mt-2">
					<div className="col-sm mt-2 scrollbar scrollbar-primary"
						 style={{height: '40vh', overflowY: 'auto'}}>
						{Object.keys(general[selectedDay]).map(field =>
							<React.Fragment key={`${this.state.maibId}_${field}`}>
								<h3>{capitalizeFirstLetter(field)}</h3>
								<p style={this.mobileMode() ? {fontSize: '0.8rem'} : {}}>{general[selectedDay][field]}</p>
							</React.Fragment>
						)}
					</div>
					<div className="col-sm">
						<div className="custom-popup">
							<MapComponent
								key={mapKey}
								width={"100%"}
								height={this.props.mapHeight || this.mapOptions.height}
								zoom={this.mapOptions.zoom}
								zoomSnap={false}
								zoomControl={false}
								doubleClickZoom={false}
								dragging={false}
								zoomDelta={false}
								touchZoom={false}
								scrollWheelZoom={false}
								closePopupOnClick={false}
								attributionControl={false}
								tile={null}
								noHome={true}

								setMapRef={mapRef => this.mapRef = mapRef}>

								{geoJson && selectedDay &&
								<>
									<GeoJSON key={"polygons_" + selectedDay + "_" + mapKey}
											data={geoJson}
										/* onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}*/
											style={(feature, latlng) => this.polygonStyle(feature, latlng)}
										/*			 pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}*/
									/>
									<GeoJSON key={"municipalities_" +mapKey}
												data={GISTools.getCalabriaMunicipalities()}
												style={{weight: 0.4,opacity: 1,color: "grey"}}
											 onEachFeature={(feature, layer) => this.onEachMunicipalityPolygon(feature, layer)}
									/>
								</>
								}
							</MapComponent>
						</div>

					</div>
				</div>}
				{/* ----------------------- INFO MODAL ----------------------- */}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showInfoModal}
					onClose={() => this.closeInfoModal()}
					onBackdropClick={() => this.closeInfoModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "rgba(27,39,50,0.83)",//2c3e50d4
							color : "#fff",
							width: this.columnMode() ? "100vw":"50vw",
							height: "auto",
							padding: '5vh',
							borderRadius: '15px',
							display: 'inline-grid'
						}}
					>
						<div>
							<IconButton  aria-label="close" style={{ float: 'right', color: 'white', padding: 0 }} onClick={() => this.closeInfoModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div>
							{this.getInfoModalContent()}
						</div>
					</div>
				</Modal>
			</div>


		)
	}
}


export default (MaibMapComponent);
