import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Header from '#/commons/components/Header';
import ComunicatoPagedList from '#/commons/comunicato/ComunicatoPagedList';
import ComunicatoDetails from '#/commons/comunicato/ComunicatoDetails';

import MauPagedList from '#/commons/mau/MauPagedList';
import MaibPagedList from '#/commons/maib/MaibPagedList';
import HomePage from '#/frontoffice/pages/HomePage';
import ProtectedPage from '#/frontoffice/pages/ProtectedPage';
import FrontOfficeNavigationComponent from '#/frontoffice/components/FrontOfficeNavigationComponent';
import Footer from '#/frontoffice/components/Footer';
import SponsorPage from '#/commons/components/SponsorPage';
import DataContainerPage from "#/commons/data/DataContainerPage";
import ForecastPagedList from "#/commons/forecast/ForecastPagedList";
import LegacyDataPage from "#/commons/legacyData/LegacyDataPage";
import MassiveDownloadPage from "#/backoffice/pages/MassiveDownloadPage";
import LinkUtiliPage from "#/frontoffice/pages/LinkUtiliPage";
import DoveSiamoPage from "#/frontoffice/pages/DoveSiamoPage";
import ContattiPage from "#/frontoffice/pages/ContattiPage";
import FotoGalleryPage from "#/frontoffice/pages/FotoGalleryPage";
import CentroFunzionalePage from "#/frontoffice/pages/CentroFunzionalePage";
import BimPagedList from '#/commons/bim/BimPagedList';
import PostEventoPagedList from '#/commons/reportPostEvento/PostEventoPagedList';
import DailyStatsPage from '#/commons/dailyStats/DailyStatsPage';
import MonthlyStatsPage from '#/commons/monthlyStats/MonthlyStatsPage';

import MaxPLuvsPage from '#/backoffice/pages/MaxPluvsPage';
import RainStatsPage from '#/backoffice/pages/RainStatsPage';
function FrontOffice() {

	let navComponent = <FrontOfficeNavigationComponent id="mainNavCompoment"></FrontOfficeNavigationComponent>;

	return (

		<>
			<Header nav={navComponent} home={"/frontoffice"} infoPage={"/frontoffice/info"}></Header>
			<Route component={({ match }) =>
				<div>
					<Switch>
						<Route exact path={match.url + "/centro_funzionale"}><CentroFunzionalePage/></Route>
						<Route exact path={match.url + "/link_utili"}><LinkUtiliPage/></Route>
						<Route exact path={match.url + "/dove_siamo"}><DoveSiamoPage/></Route>
						<Route exact path={match.url + "/contatti"}><ContattiPage/></Route>
						<Route exact path={match.url + "/foto_gallery"}><FotoGalleryPage/></Route>
						<Route exact path={match.url + "/comunicato"}><ComunicatoPagedList /></Route>
						<Route path={match.url + "/comunicato/:comunicatoId"}><ComunicatoDetails /></Route>
						{/**
							<Route exact path={match.url + "/mau"}><MauPagedList bulletinTypeName={"MAU"} canEdit={false}/></Route>
							<Route exact path={match.url + "/maib"}><MaibPagedList bulletinTypeName={"MAIB"} /></Route>
						 */}

						<Route exact path={match.url + "/bim"}><BimPagedList bulletinTypeName={"BIM"}/></Route>
						<Route exact from={match.url} path={match.url + "/postevento"}><PostEventoPagedList bulletinTypeName={"RPE"} canEdit={false}/></Route>

						<Route exact path={match.url + "/meteo"}><ForecastPagedList bulletinTypeName={"forecast"} canEdit={false}/></Route>

						<Route exact path={match.url + "/info"}><SponsorPage/></Route>

 						<Route path={match.url + "/dati"}><DataContainerPage/></Route>
						<Route path={match.url + "/dailyStats"}><DailyStatsPage canEdit={false}/></Route>
						<Route path={match.url + "/monthlyStats"}><MonthlyStatsPage/></Route>


						<Route path={match.url + "/datiPioggia"}><LegacyDataPage/></Route>
						<Route path={match.url + "/maxvalues"}><MaxPLuvsPage canEdit={false}/></Route>
						<Route path={match.url + "/rainstats"}><RainStatsPage/></Route>

						<Route path={match.url + "/richiestaDati"}><MassiveDownloadPage/></Route>
						<Route path={[match.url + "/protectedPage", match.url + "/auth", match.url + "/logout"]}><ProtectedPage/></Route>


						<Redirect from={match.url + "/signout-callback-oidc"} to="/" push={false} />
						<Route exact path={match.url + "/"}><HomePage/></Route>
						<Redirect from={match.url} to="/" />
					</Switch>
				</div>
			} />
			<Footer/>
		</>


	);
}

export default FrontOffice;


