import React from 'react';
import {GeoJSON, ImageOverlay} from 'react-leaflet';

import NumberUtils from "#/lib/NumberUtils"

import ChromaticScale from "#/lib/ChomaticScale"
import MeasurementsDataClient from "#/lib/MeasurementsDataClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LeafletLegend from "#/commons/map/LeafletLegend";
import DateUtils from "#/lib/DateUtils";
import _ from 'lodash';
import IdwClient from "#/lib/IdwClient";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Markers from "#/lib/Markers";
import L from "leaflet";
import {
	COMBINED_MEASUREMENT_LABELS,
	COMBINED_TO_STANDARD,
	getUnitByCategory,
	MEASUREMENT_CATEGORY_ICONS,
	MEASUREMENT_CATEGORY_SORT,
	MEASUREMENT_LABELS,
	MIDA_LIGHT_CATEGORIES_OPTIONS,
	STANDARD_TO_COMBINED, TO_INTERPOLATE,
	UNIT_BY_CATEGORY
} from "#/lib/MeasurementCategory";
import '#/backoffice/style/scrollbar.css';
import SwitchComponent from '#/commons/components/SwitchComponent';
import GridList from "@material-ui/core/GridList";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LeafletMauLegendControl from "#/commons/map/LeafletMauLegendControl";
import WidthUtils from "#/lib/WidthUtils"
import Collapse from "@material-ui/core/Collapse";
import {
	buildEventsByCategory,
	calculateFlow,
	checkWindThresholdsAnomalies,
	getGenericMarkerColor,
	getOutflowScalesPromise,
	getRainThresholdSeverityByAggregation,
	getRainThresholdsPromise,
	getSensorClassThresholdsPromise,
	getSensorThresholdsPromise,
	hasLinkLost,
	RAIN_LAYER_OPTIONS
} from "#/lib/MidaUtils";
import LeafletWindLegend from "#/commons/map/LeafletWindLegend";
import MapComponent from "#/commons/map/MapComponent";
import PrettyLegend from "#/commons/map/PrettyLegend";
import MapWatermark from "#/commons/map/MapWatermark";
import GISTools from "#/lib/GISTools";
import AnimationPlayer from "#/commons/map/AnimationPlayer";
import {RiRadarFill} from "react-icons/ri";
import {FaSatellite} from "react-icons/fa";
import {TiWarningOutline} from "react-icons/ti";
import moment from "moment";
import RadarClient from "#/lib/RadarClient";
import * as turf from "@turf/turf";
import MeteosatClient from "#/lib/MeteosatClient";
import AllertamentoTab from "../tabs/AllertamentoTab";

const ReactSwal = withReactContent(Swal);

const rainColumns = [
	{
		title: "Pioggia giornaliera<br/>[mm]",
		field: "P_DAILY",
		headerSort: true,
		headerFilter: false
	},
	{
		title: "Intensità pioggia<br/>[mm/H]",
		field: "P_ESTIMATED_1H",
		headerSort: true,
		headerFilter: false},
	{
		title: "1 ora",
		field: "P1H",
		headerSort: true,
		headerFilter: false,
		width: 100,
	},
	{
		title: "3 ore",
		field: "P3H",
		headerSort: true,
		headerFilter: false,
		width: 100,
	},
	{
		title: "6 ore",
		field: "P6H",
		headerSort: true,
		headerFilter: false,
		width: 100,
	},
	{
		title: "12 ore",
		field: "P12H",
		headerSort: true,
		headerFilter: false,
		width: 100
	},
	{
		title: "24 ore",
		field: "P24H",
		headerSort: true,
		headerFilter: false,
		width: 100,
	}
]

const midaLightOptions =  MIDA_LIGHT_CATEGORIES_OPTIONS
	.filter(opt => opt.value !== 'P') // Rain are nested in another object
	.sort((opt1, opt2) =>  (MEASUREMENT_CATEGORY_SORT[opt1.value] || 999) - (MEASUREMENT_CATEGORY_SORT[opt2.value] || 9999));

const intruderOptions = [
	{ value: "VMI", udm: 'dBz', label: "Vertical Maximum Intensity (VMI)", legendLabel: "Vertical Maximum Intensity", icon: <RiRadarFill/>, area: 'radar'},
	{ value: "PM_VIS008", udm: '', label: "Visible 8 µm", legendLabel: "Visible 8 µm", icon: <FaSatellite/>, area: 'meteosat'},
	{ value: "COMUNI_ALLERTATI", udm: '', label: "Comuni Allertati", legendLabel: "", icon: <TiWarningOutline/>, area: 'mosip'}
]
const mapOptions = {
	center: [39.11, 16.55],
	zoom: 6,
	minZoom: 6,
	width: "40vw",
	height: "60vh"
}

const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

const TableMapSwitch = (props) => {
	return (
		<>
			<SwitchComponent
				small={WidthUtils.isMobile()}
				name={props.label}
				value={props.showStations}
				disabled={props.disabled}
				onChange={(e) => props.onToggleShowTable()}
			/>
		</>
	)
}
const zoneBbox= GISTools.getBBoxFromPoints(turf.lineString([[14.0000000000000000,36.0000000000000000], [18.0000000000000000,41.0000000000000000]]));
const defaultPngCache = {
	radar: {},
	meteosat: {}
}
class MidaLightTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// Commons
			mapKey: 1,
			tableKey: 1,
			legendKey: 1,
			pointsKey: 1,
			intervalId: 0,
			loadingTab: true,
			showStations: true,
			geoData:null,
			interpolatedData: null,

			outflowScales: {},

			geoDataByCategory: {},
			selectedGeoData: {},
			selectedCategory: null,

			cachedTiff: {},
			showLegend: false,
			selectedLayer: '',
			showTable: false,


			// Intruders

			mapRadarKey: 1,
			legendRadarKey: 1,

			radarData: [],
			filteredRadarData: [],
			dataRadarIndex: 0,
			showRadarRaster: true,
			radarLayerMap: {},
			selectedRadarTimeSlot: 'H3',
			showRadarBorders: true,

			mapMeteosatKey: 1,
			legendMeteosatKey: 1,

			meteosatData: [],
			filteredMeteosatData: [],
			dataMeteosatIndex: 0,
			showMeteosatRaster: true,
			meteosatLayerMap: {},
			selectedMeteosatTimeSlot: 'H3',
			showMeteosatBorders: true,

			cachedPng: defaultPngCache,
			aegisPublicSrc: window.CAE_CONFIG.aegis_public_src
		}
	}

	getGeoDataPromise = () => new Promise((resolve, reject) => {
		MeasurementsDataClient.getMidaLightGeoJson(
			(geoData) => {
				if (geoData === 'NOT_MODIFIED' || !!geoData.features) {
					resolve(geoData)
				} else {
					ReactSwal.hideLoading();
					reject();
				}
			},
			() => {
				console.log('Problems retrieving geoJson data!')
			}
		)
	})

	performIdwPromise = () => new Promise((resolve, reject) => {
		const {selectedLayer, mapKey, legendKey, cachedTiff} = this.state;
		this.removeLegend();
		this.removeLegendControl();
		let state = {
			mapKey: (mapKey + 1) % 1000,
			legendKey: (legendKey + 1) % 1000,
			loading: false,
			showLegend: !WidthUtils.isMobile()
		}
		if (!TO_INTERPOLATE.includes(selectedLayer)) {
			console.log("EXCLUDED: " + selectedLayer)
			resolve({...state, interpolatedData: null, showLegend: Object.keys(COMBINED_MEASUREMENT_LABELS).includes(selectedLayer)})
		}
		else if (selectedLayer in cachedTiff) {
			console.log(">>>>>>>>>>>>>>>>> Found category " + selectedLayer + " in cache");
			resolve({...state, interpolatedData: cachedTiff[selectedLayer]})
		} else {
			console.log(">>>>>>>>>>>>>>>>> Getting IDW remotely");
			IdwClient.performIdwMidaLight(
				interpolatedData => {
					resolve({
						...state,
						interpolatedData,
						cachedTiff: {...cachedTiff, [selectedLayer]: interpolatedData}
					})
				},
				(error) => console.log(">>>>>>>>>>>>>>>>> Problems performing IDW "),
				['WV', 'WS', 'WR'].includes(selectedLayer) ? selectedLayer.replace('W', 'V') : selectedLayer
			)
		}
	})

	performInterpolation = layerCategory => {
		this.performIdwPromise(layerCategory).then(state => this.setState(state))
	};

	handleListItemClick = (event, selectedLayer) => {
		this.removeLegend();
		let mainCategory = RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ? 'P' : selectedLayer;
		let selectedEvents = this.state.eventsByCategory[mainCategory];
		console.log(mainCategory)
		console.log(selectedEvents)
		this.setState({
			selectedLayer,
			selectedGeoData: this.state.geoDataByCategory[mainCategory],
			selectedEvents,
			mapKey: (this.state.mapKey + 1) % 1000,
			legendKey: (this.state.legendKey + 1) % 1000,
			pointsKey: (this.state.pointsKey + 1) % 1000
		}, () => {
			this.performInterpolation(selectedLayer);
		});
	};

	componentDidMount() {
		Promise.all(this.getPromises())
			.then(([geoData, outflowScales, rainThresholds, stationSensorThresholds, sensorClassThresholds]) => {

				let geoDataByCategory = this.buildGeoDataByCategory(geoData, outflowScales);
				delete geoDataByCategory.VV;
				delete geoDataByCategory.VS;
				delete geoDataByCategory.VR;

				const eventsByCategory = {
					...buildEventsByCategory(geoDataByCategory, stationSensorThresholds, sensorClassThresholds), // generic events
					...checkWindThresholdsAnomalies(geoDataByCategory, stationSensorThresholds, sensorClassThresholds) // wind events
				};
				let intervalId = setInterval(() => this.updateGeoData(), 300000);

				this.setState({
					mapKey: (this.state.mapKey + 1) % 1000,
					tableKey: (this.state.tableKey + 1) % 1000,
					pointsKey: (this.state.pointsKey + 1) % 1000,
					lastRequestTimestamp: moment().valueOf(),
					geoData,
					geoDataByCategory,
					outflowScales,
					rainThresholds,
					eventsByCategory,
					intervalId,
					loadingTab: false,
				})
				this.handleListItemClick(null,"P1H")
			}).catch(errors => {
				ReactSwal.fire('Si è verificato un errore nel recupero dei dati in tempo reale', '', 'error');
				console.log('Errors from promise all', errors)
			}
		)
	}

	buildGeoDataByCategory = (geoData, outflowScales) => Object.keys(MEASUREMENT_LABELS).reduce((geoDataByCategory, category) => {
		let categoryGeoData = _.cloneDeep(geoData);
		categoryGeoData.features = categoryGeoData.features.filter(feature => category in feature.properties.lastMeasurementTimestamps);
		categoryGeoData.features = categoryGeoData.features.map(feature => {
			const {properties, properties: {station: {code, name}, sensors, lastMeasurementTimestamps}} = feature;
			feature.properties = {
				code,
				name,
				sensorCode: sensors[category],
				timestamp: lastMeasurementTimestamps[category],
				...((() => {
					switch (category) {
						case 'P':
							return RAIN_LAYER_OPTIONS.reduce((obj, {value}) => {
								obj[value] = properties[value];
								return obj
							}, {});
						case 'I':
							return {
								value: properties[category],
								flow: calculateFlow(properties[category], outflowScales[sensors[category]])
							};
						case 'DV':
						case 'DS':
						case 'DR':
							return {
								[category]: properties[category],
								[category.replace('D', 'V')]: properties[category.replace('D', 'V')]
							}
						default:
							return {
								value: properties[category]
							};
					}
				})())
			}
			return feature;
		})
		delete categoryGeoData.requestTimestamp;
		geoDataByCategory[STANDARD_TO_COMBINED[category] || category] = categoryGeoData;
		return geoDataByCategory;
	}, {});

	getPromises = () => [this.getGeoDataPromise(), getOutflowScalesPromise(), getRainThresholdsPromise(), getSensorThresholdsPromise(),
		getSensorClassThresholdsPromise(),];

	componentDidUpdate() {
		loadingSwal.close();
	}

	updateGeoData = () => {

		this.getGeoDataPromise().then(
			geoData => {
				let geoDataByCategory = this.buildGeoDataByCategory(geoData, this.state.outflowScales);
				delete geoDataByCategory.VV;
				delete geoDataByCategory.VS;
				delete geoDataByCategory.VR;

				console.log(">>>>>>>>>>>>>>>>> Geo data are now up to date!")
				this.setState({
					mapKey: (this.state.mapKey + 1) % 1000,
					tableKey: (this.state.tableKey + 1) % 1000,
					pointsKey: (this.state.pointsKey + 1) % 1000,

					geoDataByCategory,
					...(this.state.selectedCategory && {
						selectedGeoData: geoDataByCategory[this.state.selectedLayer],
						cachedTiff: {}
					})
				})
			}
		).catch(
			error => {
				console.log("Error while updating data", error);
			}
		)
	}

	onEachFeatureStations({properties}, layer, context) {
		if (!this.mobileMode()){
			layer.bindTooltip(`<b>${properties.name}</b>`);
		}
		let {selectedLayer} = this.state;
		let value = properties[RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ? selectedLayer : (['WV', 'WR', 'WS'].includes(selectedLayer) ? selectedLayer.replace('W', 'V') : 'value')];
		let units = UNIT_BY_CATEGORY[RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ? 'P' : (['WV', 'WR', 'WS'].includes(selectedLayer) ? selectedLayer.replace('W', 'V') : selectedLayer)];
		let windDirection = ['WV', 'WR', 'WS'].includes(selectedLayer) ? properties[selectedLayer.replace('W', 'D')] : "";





		layer.bindPopup(`<div>
							<b>${properties.name}</b>
							<div>Valore misurato: ${NumberUtils.round(value, 2)} ${units}</div>
							<div>${selectedLayer === 'I' ? `Portata: ${properties.flow} ${properties.flow !== 'nd' ? `m<sup>3</sup>/s` : ``}` : ``}</div>
							<div>${['WV', 'WR', 'WS'].includes(selectedLayer) ? `Direzione : ${windDirection} °` : ``}</div>
							
						</div>`, {
			closeButton: false
		});
	}

	showInterpolatedPopup = (val, lnglat, map) => {
		const {selectedLayer} = this.state;
		L.popup({offset: [40, 40], closeButton: false})
			.setLatLng(L.latLng(lnglat[0], lnglat[1]))
			.setContent(`<div>
						Valore interpolato: ${NumberUtils.round(val, 2)} ${UNIT_BY_CATEGORY[RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ? 'P' : (['WV', 'WR', 'WS'].includes(selectedLayer) ? selectedLayer.replace('W', 'V') : selectedLayer)]}
							</div>`)

			.openOn(map);
	}

	getRainMarkerColor = (
		aggregation,
		props,
		rainThresholds = this.state.rainThresholds,
		lastRequestTimestamp = this.state.lastRequestTimestamp) => hasLinkLost(props.timestamp, lastRequestTimestamp) ? 'grey' :  getRainThresholdSeverityByAggregation(props, rainThresholds, aggregation);

	pointToMarker = (feature, latlng, context) => {

		const {properties: {code : stationCode, timestamp, sensorCode}, properties} = feature;
		const {selectedLayer, selectedEvents} =this.state;
		let mainCategory = RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ? 'P' : selectedLayer;

		const color = mainCategory === 'P' ?
			this.getRainMarkerColor(selectedLayer, properties, this.state.rainThresholds[sensorCode] || this.state.rainThresholds['GENERIC']) :
			getGenericMarkerColor(stationCode, timestamp, selectedEvents, this.state.lastRequestTimestamp);
		return this.createMarker(feature, latlng, color, 'grey');
	}


	createMarker = (feature, latlng, color, borderColor = 'grey') => Markers.getMarker(latlng, color, borderColor);

	windPointToMarker = (feature, latlng, context) =>
		Markers.getWindMarker(latlng,
			feature.properties[this.state.selectedLayer.replace('W','D')],
			feature.properties[this.state.selectedLayer.replace('W','V')]);

	getGridListCols = () => WidthUtils.isMobile() ? 4 : 1;

	toggleRainCategoryList = () => {
		this.setState({
			showRainCategoryList: !this.state.showRainCategoryList,
			mapKey: (this.state.mapKey + 1) % 1000
		}, () => {
			this.removeLegend();
			this.removeLegendControl()
		})
	}

	removeLegend() {
		let tmp = document.getElementsByClassName("newLegend");
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		}
		;
	}

	toggleLegend = () => {
		this.setState({
			showLegend: !this.state.showLegend,
			legendKey: (this.state.legendKey + 1) % 1000,
			mapKey: (this.state.mapKey + 1) % 1000
		}, () => {
			if (!this.state.showLegend) {
				this.removeLegend();
			}
		})
	}
	showLegendControl = () => WidthUtils.isMobile();

	removeLegendControl() {
		let tmp = document.getElementsByClassName("legendControl");
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		}
		;
	}

	


	handleIntruderItemClick = (event, selectedLayer, area) => {
		console.log("Selected layer", selectedLayer)
		/*this.removeLegend(area);*/
		if (area === 'radar') {
			this.getRadarData(selectedLayer);
		} else if (area === 'meteosat') {
			this.getMeteosatData(selectedLayer);
		} else {

			this.setState({
				selectedLayer,
				showTable: false
			});

		}
	};
	getFilteredMeteosatData = (selectedTimeSlot, meteosatData = this.state.meteosatData) => {
		if (meteosatData.length > 0) {
			let numOfHours = selectedTimeSlot.match(/\d+/g).map(Number)[0];
			let lastTimestamp = meteosatData[meteosatData.length - 1].timestamp;
			let startTimestamp = lastTimestamp - numOfHours * 60 * 60 * 1000;
			return meteosatData.filter(data => data.timestamp >= startTimestamp);
		} else return meteosatData;
	}
	getMeteosatPngPromise = (pngUrl, timestamp, selectedLayer, cachedPng) => new Promise((resolve, reject) => {
		let cachedPngImage = cachedPng.meteosat[selectedLayer][timestamp]
		if (cachedPngImage){
			resolve({[timestamp]: cachedPngImage})
		} else {
			MeteosatClient.getMeteosatByPngUrl(image => {
				resolve({[timestamp]: image})
			}, err => {
				console.log(err);
			}, pngUrl)
		}
	})
	updateMeteosatSnapshot = (dataMeteosatIndex, showMeteosatRaster, newState = {}) => {

		this.setState({
			...newState,
			dataMeteosatIndex,
			showMeteosatRaster,
			mapMeteosatKey: (this.state.mapMeteosatKey + 1) % 1000,
		})

	}

	getMeteosatData = selectedLayer => {
		let now = moment().valueOf();
		let selectedInterval = {start: now - (26*60*60*1000), end: now};
		let currentTimestamp = this.state.filteredMeteosatData.length > 0 ?
			this.getCurrentTimestampForCurrentLayer(this.state.filteredMeteosatData, this.state.dataMeteosatIndex) : null;
		loadingSwal.fire('Recupero dati di '+selectedLayer+' in corso');
		MeteosatClient.getMeteosatDataByInterval(

			meteosatData => {
				let {cachedPng} = this.state;
				if (!(selectedLayer in cachedPng.meteosat)){
					cachedPng.meteosat[selectedLayer] = {};
				}
				let filteredMeteosatData = this.getFilteredMeteosatData(this.state.selectedMeteosatTimeSlot, meteosatData)
				if(filteredMeteosatData.length === 0){
					loadingSwal.close();
					ReactSwal.fire(
						"Recupero dati Meteosat",
						"Layer " + selectedLayer + " non disponibile!",
						"error"
					);
					return;
				}

				// Get first image
				let lastData = meteosatData[meteosatData.length -1];
				let {pngUrl : temporaryMeteosatImageUrl, timestamp : lastTimestamp} = lastData;

				loadingSwal.close();
				this.getMeteosatPngPromise(temporaryMeteosatImageUrl, lastTimestamp, selectedLayer, cachedPng)
					.then(obj => {
						this.setState({
							temporaryMeteosatImageUrl: obj[lastTimestamp],
							loadingMeteosatLayer: true,
							selectedLayer,
							filteredMeteosatData,
							meteosatData,
							mapMeteosatKey: (this.state.mapMeteosatKey + 1) % 1000,
							showTable: false
						},() => {
							let newState = {
								filteredMeteosatData,
								cachedPng,
								loadingMeteosatLayer: false
							}

							Promise.all(meteosatData.map(({pngUrl, timestamp}) => this.getMeteosatPngPromise(pngUrl, timestamp, selectedLayer, cachedPng)))
								.then( cachedImageObjects => {
									newState.cachedPng.meteosat[selectedLayer] = cachedImageObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{})
									loadingSwal.close();
									this.updateMeteosatSnapshot(filteredMeteosatData.length - 1, true, newState)
								})
						})
					})


			},
			(error) => {
				console.log(">>>>>>>>>>>>>>>>> Problems retrieving meteosat data ");
				loadingSwal.close();
				ReactSwal.fire('Errore nel recupero dei dati','','error');
			},
			selectedInterval,
			selectedLayer
		)
	};
	getCurrentTimestampForCurrentLayer = (data, index) => data[index].timestamp;
	getFilteredRadarData = (selectedTimeSlot, radarData = this.state.radarData) => {
		if (radarData.length > 0) {
			let numOfHours = selectedTimeSlot.match(/\d+/g).map(Number)[0];
			let lastTimestamp = radarData[radarData.length - 1].timestamp;
			let startTimestamp = lastTimestamp - numOfHours * 60 * 60 * 1000;
			return radarData.filter(data => data.timestamp >= startTimestamp);
		} else return radarData;
	}
	getRadarPngPromise = (pngUrl, timestamp, selectedLayer, cachedPng) => new Promise((resolve, reject) => {
		let cachedPngImage = cachedPng.radar[selectedLayer][timestamp]
		if (cachedPngImage){
			resolve({[timestamp]: cachedPngImage})
		} else {
			RadarClient.getRadarByPngUrl(image => {
				resolve({[timestamp]: image})
			}, err => {
				console.log(err);
			}, pngUrl)
		}
	})
	findClosestTimestamp = (data, timestampToFind) => data.reduce(({timestamp}, {timestamp:a}) =>
		Math.abs(timestampToFind - a) < Math.abs(timestampToFind - timestamp) ? {timestamp: a} : {timestamp}
	).timestamp;
	updateRadarSnapshot = (dataRadarIndex, showRadarRaster, newState = {} ) => {

		/*	if (currentTimestamp){
                let newTimestamp = this.findClosestTimestamp(newState.filteredRadarData, currentTimestamp);
                dataRadarIndex = newState.filteredRadarData.findIndex(d => d.timestamp === newTimestamp);
            }*/

		if (!showRadarRaster){
			this.setState({
				...newState,
				showRadarRaster,
				dataRadarIndex,
				mapRadarKey: (this.state.mapRadarKey + 1) % 1000
			})
		} else {
			const {mapRadarKey, legendRadarKey} = this.state;
			const {filteredRadarData} = _.isEmpty(newState) ? this.state : newState;

			if (filteredRadarData.length > 0) {
				RadarClient.getRadarByTiffUrl(
					currentRadarTiff => {
						this.setState({
							...newState,
							mapRadarKey: (mapRadarKey + 1) % 1000,
							currentRadarTiff,
							showRadarRaster,
							dataRadarIndex
						})
					},
					(error) => console.log(">>>>>>>>>>>>>>>>> Problems retrieving radar tiff "),
					filteredRadarData[dataRadarIndex].tiffUrl
				)
			}
		}
	}
	getRadarData = selectedLayer => {
		let now = moment().valueOf();
		let selectedInterval = {start: now - (26*60*60*1000), end: now};
		loadingSwal.fire('Recupero dati di ' + selectedLayer + ' in corso');
		let currentTimestamp = this.state.filteredRadarData.length > 0 ?
			this.getCurrentTimestampForCurrentLayer(this.state.filteredRadarData, this.state.dataRadarIndex) : null;
		RadarClient.getRadarDataByInterval(

			radarData => {
				let {cachedPng, legendRadarKey} = this.state;
				if (!(selectedLayer in cachedPng.radar)){
					cachedPng.radar[selectedLayer] = {};
				}

				let filteredRadarData = this.getFilteredRadarData(this.state.selectedRadarTimeSlot, radarData)
				if(filteredRadarData.length === 0){
					loadingSwal.close();
					ReactSwal.fire(
						"Recupero dati Radar",
						"Layer " + selectedLayer + " non disponibile!",
						"error"
					);
					return;
				}

				// Get first image
				let lastData = radarData[radarData.length -1];
				let {pngUrl : temporaryRadarImageUrl, timestamp : lastTimestamp} = lastData;

				loadingSwal.close();
				this.getRadarPngPromise(temporaryRadarImageUrl, lastTimestamp, selectedLayer, cachedPng)
					.then(obj => {
						this.setState({
							temporaryRadarImageUrl: obj[lastTimestamp],
							loadingRadarLayer: true,
							selectedLayer,
							filteredRadarData,
							radarData,
							mapRadarKey: (this.state.mapRadarKey + 1) % 1000,
							showTable: false
						}, () => {

							let newState = {
								cachedPng,
								filteredRadarData,
								legendRadarKey: (legendRadarKey + 1) % 1000,
								loadingRadarLayer: false
							}
							Promise.all(radarData.map(({pngUrl, timestamp}) => this.getRadarPngPromise(pngUrl, timestamp, selectedLayer, cachedPng)))
								.then( cachedImageObjects => {
									newState.cachedPng.radar[selectedLayer] = cachedImageObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{})
									this.updateRadarSnapshot(filteredRadarData.length - 1, this.state.showRadarRaster, newState)
								})
						})
					})
			},
			(error) => {
				console.log(">>>>>>>>>>>>>>>>> Problems retrieving radar data ");
				loadingSwal.close();
				ReactSwal.fire('Errore nel recupero dei dati','','error');
			},
			selectedInterval,
			selectedLayer === 'NOWCASTING' ? 'VMI' : selectedLayer
		)
	};
	mobileMode = () => {
		console.log("mobileMode", WidthUtils.isMobile());
		return WidthUtils.isMobile()
	};

	getRainCategoryList = selectedLayer => !this.mobileMode() && [
		<ListItem
			key={'rain_container'}
			button
			disabled={!this.state.showMeteosatRaster || !this.state.showRadarRaster}
			selected={selectedLayer === 'rain_container'}
			onClick={() => this.toggleRainCategoryList()}
			style={{padding: '7.5px', color:"#0c6aaf"}}
		>
			<ListItemIcon style={{ fontSize:"x-large",color:"#0c6aaf"}}>
				{MEASUREMENT_CATEGORY_ICONS.P}
			</ListItemIcon>
			<ListItemText>Piogge</ListItemText>
			<ListItemIcon style={{ fontSize:"x-large", color:"#0c6aaf"}}>
				{this.state.showRainCategoryList ? <ExpandLess/> : <ExpandMore/>}
			</ListItemIcon>
		</ListItem>,
		<Collapse key={`collapsed_rain`} in={this.state.showRainCategoryList} timeout="auto" unmountOnExit>
			<GridList spacing={10} cellHeight='auto' cols={this.getGridListCols()}>
				{RAIN_LAYER_OPTIONS.map(({value: layerKey, label, icon}) =>
					<ListItem
						key={layerKey}
						className="pl-4"
						button
						selected={selectedLayer === layerKey}
						style={{ color:"#0c6aaf"}}
						onClick={(event) => this.handleListItemClick(event, layerKey)}
					>
						<ListItemIcon style={{fontSize:"x-large", color:"#0c6aaf"}}>{icon}</ListItemIcon>
						{!this.mobileMode() ?
							<ListItemText primary={label}/> : <></>}
					</ListItem>
				)}
			</GridList>
		</Collapse>
	]

	getCategoryList = selectedLayer => midaLightOptions.map(({value: layerKey, icon, label}) =>
		[<ListItem
			key={layerKey}
			button
			style={{ color:"#0c6aaf"}}
			disabled={!this.state.showMeteosatRaster || !this.state.showRadarRaster}
			selected={selectedLayer === layerKey}
			onClick={(event) => this.handleListItemClick(event, layerKey)}
		>
			<ListItemIcon style={{ fontSize:"x-large", color:"#0c6aaf"}}>{icon}</ListItemIcon>
			{!this.mobileMode() && <ListItemText primary={label}/>}
		</ListItem>]
	)
	getRadarMeteosatList = selectedLayer => intruderOptions.map(({value: layerKey, icon, label, area}) =>
		[
			<ListItem
				key={layerKey}
				button
				disabled={!this.state.showMeteosatRaster || !this.state.showRadarRaster}
				selected={selectedLayer === layerKey}
				onClick={(event) => this.handleIntruderItemClick(event, layerKey, area)}
			>
				<ListItemIcon>{icon}</ListItemIcon>
				{!this.mobileMode() && <ListItemText primary={label}/>}
			</ListItem>]
	)

	onToggleShowTable = () => {
		this.setState({showTable: !this.state.showTable})
	}
	getColumns = () => {
		const {selectedLayer} = this.state;
		switch (selectedLayer) {
			case "P_ESTIMATED_1H":
			case "P_DAILY":
			case "P1H":
			case "P3H":
			case "P6H":
			case "P12H":
			case "P24H":
				return [
					{
						title: "Stazione",
						field: "name",
						headerSort: true,
						headerFilter: true,
						headerFilterPlaceholder: 'Ricerca...'
					},
					{
						title: "Data ultima<br/>misurazione",
						formatter: cell => DateUtils.epochToDateMinuteResolution(cell.getValue()),
						field: "timestamp",
						headerSort: true,
						headerFilter: false
					},
				].concat(rainColumns.find(col => col.field === selectedLayer))
			case 'WV':
			case 'WS':
			case 'WR':
				return [
					{
						title: "Stazione",
						field: "name",
						headerSort: true,
						headerFilter: true,
						headerFilterPlaceholder: 'Ricerca...'
					},
					{
						title: "Data ultima<br/>misurazione",
						formatter: cell => DateUtils.epochToDateMinuteResolution(cell.getValue()),
						field: "timestamp",
						headerSort: true,
						headerFilter: false
					},
					{
						title: `Intensità [m/s]`,
						field: `${COMBINED_TO_STANDARD[selectedLayer].replace('D', 'V')}`,
						headerSort: true,
						headerFilter: false,
					},
					{
						title: "Direzione [°]",
						field: `${COMBINED_TO_STANDARD[selectedLayer]}`,
						headerSort: true,
						headerFilter: false
					}]
			default:
				return [
					{
						title: "Stazione",
						field: "name",
						headerSort: true,
						headerFilter: true,
						headerFilterPlaceholder: 'Ricerca...'
					},
					{
						title: "Data ultima<br/>misurazione",
						formatter: cell => DateUtils.epochToDateMinuteResolution(cell.getValue()),
						field: "timestamp",
						headerSort: true,
						headerFilter: false
					},
					{
						title: `Ultima misurazione [${getUnitByCategory(selectedLayer)}]`,
						field: "value",
						headerSort: true,
						headerFilter: false
					},
					(selectedLayer === 'I' && {
						title: "Portata relativa [m<sup>3</sup>/s]",
						field: "flow",
						headerSort: true,
						headerFilter: false
					})

				].filter(col => !!col);
		}
	}

	getTabulatorOptions = () => ({
		data: [],
		height: (this.mobileMode() ? "40vh" : "60vh"),
		dataTree: true,
		dataTreeStartExpanded: false,
		invalidOptionWarnings: false,
		selectable: false
	});
	fixRadarMap = () => {
		if (!!this.mapRadarRef){
			try {
				this.mapRadarRef.leafletElement.invalidateSize();
				this.mapRadarRef.leafletElement.fitBounds([
					[zoneBbox.bbox[1], zoneBbox.bbox[0]],
					[zoneBbox.bbox[3], zoneBbox.bbox[2]]
				]);
			} catch (e) {
				console.error(e)
			}
		}
	}
	fixMeteosatMap = () => {
		if (!!this.mapMeteosatRef){
			try {
				this.mapMeteosatRef.leafletElement.invalidateSize();
				this.mapMeteosatRef.leafletElement.fitBounds([
					[zoneBbox.bbox[1], zoneBbox.bbox[0]],
					[zoneBbox.bbox[3], zoneBbox.bbox[2]]
				]);
			} catch (e) {
				console.error(e)
			}
		}
	}

	getMapComponentToShow = () => {
		const {selectedLayer, showLegend, interpolatedData, showTable, selectedGeoData, mapKey, tableKey, pointsKey, legendKey, loadingTab} = this.state;

		const {
			currentRadarTiff,
			mapRadarKey, mapMeteosatKey,
			legendRadarKey,
			showRadarRaster,
			showMeteosatRaster,
			filteredRadarData, filteredMeteosatData,
			dataRadarIndex, dataMeteosatIndex, cachedPng,
			showRadarBorders, showMeteosatBorders} = this.state;
		
		
		let mapHeight = !!this.props.mapHeight ? this.props.mapHeight : (this.mobileMode() ? "70vh" : "60vh");

		const switchMarkersBySelectedCategory = () =>
		!_.isEmpty(selectedGeoData) ?
			<>
				{['WR', 'WS', 'WV'].includes(selectedLayer) &&
				<GeoJSON key={`windArrows_${selectedLayer}_${pointsKey}`}
						 data={selectedGeoData}
						 onEachFeature={(feature, layer, context) => this.onEachFeatureStations(feature, layer, this)}
						 pointToLayer={(feature, latlng, context) => this.windPointToMarker(feature, latlng, this)}
				/>}
				<GeoJSON key={`${selectedLayer}_${pointsKey}`}
						 data={selectedGeoData}
						 onEachFeature={(feature, layer, context) => this.onEachFeatureStations(feature, layer, this)}
						 pointToLayer={(feature, latlng, context) => this.pointToMarker(feature, latlng, this)}
				/>
			</>
			: <></>;

		switch(selectedLayer){
			case "VMI":
				let radarImgUrl = null;
				if (filteredRadarData.length > 0 ){
					try {
						radarImgUrl = cachedPng.radar[selectedLayer][filteredRadarData[dataRadarIndex].timestamp];
					} catch (err) {
						console.error(err)
					}
				}
				
				return (
					<>
					<MapComponent
						key={'radarMapComponent'}
						width={"100%"}
						height={'60vh'}
						zoom={!!this.props.zoom ? this.props.zoom : mapOptions.zoom}
						zoomSnap={false}
						zoneBbox={zoneBbox}
						setMapRef={mapRef => this.mapRadarRef = mapRef}
						buttonKey={'radar'}
						tile={null}
						skipRecenter={true}
						toggleBorders={() => this.setState({showRadarBorders: !showRadarBorders})}>
						{selectedLayer && <>
							{(showRadarRaster && currentRadarTiff) && <GeotiffRasterLayer
								key={'radar_' + mapRadarKey}
								georaster={currentRadarTiff}
								opacity={0}
								resolution={256}
								colorScale={ChromaticScale.getScaleBySensorCategory(selectedLayer)}
								handleClick={(val, latlng) => this.showInterpolatedPopup(val, latlng, this.mapRadarRef.leafletElement, 'radar')}
								ndColor={'grey'}
								enableClickOutOfCalabria={true}
							/>}
							{radarImgUrl && <ImageOverlay
								key={"image_radar_" + mapRadarKey}
								url={radarImgUrl}
								bounds={filteredRadarData[dataRadarIndex].pngBox}
								opacity={1}
								zIndex={10}
							></ImageOverlay>
							}
							<PrettyLegend
								key={'legend_radar_' + legendRadarKey}
								customClass={'radarLegend'}
								/*legendTitle={ALL_RADAR_OPTIONS.find(opt => opt.value === selectedLayer).legendLabel}*/
								grades={ChromaticScale.getDomainBySensorCategory(selectedLayer)}
								getColor={num => ChromaticScale.getScaleBySensorCategory(selectedLayer)(num)}
								tickerGap={4} // a sixth
								// tickerValues={[-20, 0, 20, 40, 60]}
							/>
							<MapWatermark
								key={'watermark_radar_' + mapRadarKey}
								customClass={'radarWatermark'}
								text={intruderOptions.find(opt => opt.value === selectedLayer).label}
							/>
						</>}
						{showRadarBorders && <GeoJSON key={"calabria_radar_"}
													  data={GISTools.getRegioniBorders()}
													  style={{
														  fillColor: "#fff",
														  fillOpacity: 0,
														  weight: 2,
														  opacity: 1,
														  color: "green",
													  }}/>}
					</MapComponent>
					{filteredRadarData.length > 0 && <AnimationPlayer
						forcedIndex={this.state.dataRadarIndex}
						data={this.state.filteredRadarData}
						updateSnapshot={(dataIndex, showRaster) => this.updateRadarSnapshot(dataIndex, showRaster)}
						filterData ={selectedTimeSlot => this.filterRadarData(selectedTimeSlot)}
						selectedTimeSlot={this.state.selectedRadarTimeSlot}/>}
				</>
				);

			case "PM_VIS008":
				let meteosatImgUrl = null;
				if (filteredMeteosatData.length > 0 ){
					try {
						meteosatImgUrl = cachedPng.meteosat[selectedLayer][filteredMeteosatData[dataMeteosatIndex].timestamp];
					} catch (err) {
						console.error(err)
					}
				}
				return (<>
					<MapComponent
						key={'meteosatMapComponent'}
						width={"100%"}
						height={'52vh'}
						zoom={!!this.props.zoom ? this.props.zoom : mapOptions.zoom}
						zoomSnap={false}
						zoneBbox={zoneBbox}
						setMapRef={mapRef => this.mapMeteosatRef = mapRef}
						buttonKey={'meteosat'}
						tile={null}
						skipRecenter={true}
						toggleBorders={() => this.setState({showMeteosatBorders: !showMeteosatBorders})}>
						{selectedLayer && <>
							{meteosatImgUrl && <ImageOverlay
								key={"image_meteosat_" + mapMeteosatKey}
								url={meteosatImgUrl}
								bounds={filteredMeteosatData[dataMeteosatIndex].pngBox}
								opacity={1}
								zIndex={10}
							></ImageOverlay>
							}
							<MapWatermark
								key={'watermark_meteosat_' + mapMeteosatKey}
								customClass={'meteosatWatermark'}
								text={intruderOptions.find(opt => opt.value === selectedLayer).label}
							/>
						</>}
						{showMeteosatBorders && false && <GeoJSON key={"calabria_meteosat_"}
														 data={GISTools.getRegioniBorders()}
														 style={{
															 fillColor: "#fff",
															 fillOpacity: 0,
															 weight: 2,
															 opacity: 1,
															 color: "green",
														 }}/>}
					</MapComponent>
					{filteredMeteosatData.length > 0 && <AnimationPlayer
						forcedIndex={this.state.dataMeteosatIndex}
						data={this.state.filteredMeteosatData}
						updateSnapshot={(dataIndex, showRaster) => this.updateMeteosatSnapshot(dataIndex, showRaster)}
						filterData ={selectedTimeSlot => this.filterMeteosatData(selectedTimeSlot)}
						selectedTimeSlot={this.state.selectedMeteosatTimeSlot}/>}
				</>);
			case "COMUNI_ALLERTATI":
				return ( <AllertamentoTab mauId={this.props.mauId} mauCreatedAt={this.props.mauCreatedAt}/>);
			default :
			     return (<>
					<MapComponent
						key={'midaMapComponent'}
						width={"100%"}
						height={mapHeight}
						zoom={!!this.props.zoom ? this.props.zoom : mapOptions.zoom}
						zoomControl={false}
						doubleClickZoom={false}
						dragging={true}
						zoomSnap={false}
						zoomDelta={false}
						touchZoom={true}
						scrollWheelZoom={true}
						setMapRef={mapRef => this.mapRef = mapRef}>

						{interpolatedData ? <GeotiffRasterLayer
							key={"raster_" + mapKey}
							georaster={interpolatedData}
							opacity={0.5}
							resolution={256}
							colorScale={RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ?
								ChromaticScale.getRainScaleByTimescale(selectedLayer) :
								ChromaticScale.getScaleBySensorCategory(selectedLayer)}
							handleClick={(val, latlng) => this.showInterpolatedPopup(val, latlng, this.mapRef.leafletElement)}
						/> : <></>}
						{switchMarkersBySelectedCategory()}
						{this.showLegendControl() && selectedLayer ? <LeafletMauLegendControl
							key={"legendControl_" + mapKey}
							toggleLegend={() => this.toggleLegend()}
							showLegend={showLegend}
						/> : <></>}
						{showLegend && <>
							{Object.keys(COMBINED_MEASUREMENT_LABELS).includes(selectedLayer) ?
								<LeafletWindLegend
									key={"wind_legend_" + mapKey}
									replaceMode={true}
								/>
								: <LeafletLegend
									key={"legend_" + legendKey}
									replaceMode={true}
									legendTitle={''}
									firstLabel={RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ? "assenti" : null}
									grades={RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ?
										ChromaticScale.getRainDomainByTimescale(selectedLayer) :
										ChromaticScale.getDomainBySensorCategory(selectedLayer)}
									getColor={(num) => RAIN_LAYER_OPTIONS.map(opt => opt.value).includes(selectedLayer) ?
										ChromaticScale.getRainScaleByTimescale(selectedLayer)(num) :
										ChromaticScale.getScaleBySensorCategory(selectedLayer)(num)}/>
							}</>}
					</MapComponent>
				</>);

		}

		

	}

	render() {

		// Intruder
		const {
			showRadarRaster,
			showMeteosatRaster,
			loadingRadarLayer,
			loadingMeteosatLayer} = this.state;

		if (showRadarRaster && showMeteosatRaster || loadingRadarLayer || loadingMeteosatLayer){
			this.fixRadarMap();
			this.fixMeteosatMap();
		}

		return (
			<>
				<iframe frameborder="0" src={this.state.aegisPublicSrc} className='w-100 d-flex' height="800" title='Aegisrcalab'></iframe>
			</>
		)
	}
}

export default (MidaLightTab);
