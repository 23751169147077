import React from 'react';
import BulletinClient from '#/lib/BulletinClient';
import Skeleton from "react-loading-skeleton";

export default class MauDetails extends React.Component {

    constructor(props) {
        super(props)
        if (!!this.props) {
            this.state = { ...this.props }
        }
    }

    componentDidMount() {
        if (!(!!this.props.id)) {
            let params = {
                pageNumber: 1,
                pageSize: 1,
                bulletinTypeName: this.props.bulletinTypeName
            }
			BulletinClient.listBulletins(
                (obj) => { this.setState({...obj.data[0]}) },
                (err) => { console.log('Errore mount mau Details') },
                params
            )
        }
    }


    render() {
        if (!(!!this.state.id)) {
            return <Skeleton height={"50vh"} duration={8} />
        }
        const today = !!this.state.jsonData.start.date_short ? this.state.jsonData.start.date_short : '';
        const tomorrow = !!this.state.jsonData.end.date_short ? this.state.jsonData.end.date_short : '';
        const todayTitle = "mau del giorno : " + today;
        const tomorrowTitle = "mau del giorno : " + tomorrow;
        const imgToday = !!this.state.jsonData.start.criticita.img ? this.state.jsonData.start.criticita.img : '';
        const imgTomorrow = !!this.state.jsonData.end.criticita.img ? this.state.jsonData.end.criticita.img : '';

        return (
            <>
                <div className="card item-card transparent-card" >
                    <div >
                        <div className="container">

                            <div className="map-container">
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <div className="map-header">
                                            <h3 className="map-header-title">
                                                {todayTitle}
                                            </h3>
                                        </div>
                                        <div className="map-image">
                                            <img
                                                className="item-img"
                                                src={imgToday}
                                                alt="mau"
                                                align="center"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 text-center map-header">
                                        <div className="map-header">
                                            <h3 className="map-header-title">
                                                {tomorrowTitle}
                                            </h3>
                                        </div>
                                        <div className="map-image">
                                            <img
                                                className="item-img"
                                                src={imgTomorrow}
                                                alt="mau"
                                                align="center"
                                                >
                                            </img>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="col-12 text-center">
                                        <img
                                            className="item-legend"
                                            src={this.state._legend}
                                            alt="legend"
                                            align="center">
                                        </img>
                                    </div>
                                    */}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
};
