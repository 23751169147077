import React from 'react'
import {AiOutlineLineChart, AiOutlineTable} from "react-icons/ai";
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import LegacyCompareSensorComponent from "#/commons/legacyData/components/LegacyCompareSensorComponent";
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';
import moment from 'moment';

const VIEW_MODE = {
	NONE: "NONE",
	AGGREGATED_TABLE: "AGGREGATED_TABLE",
	AGGREGATED_CHART: "AGGREGATED_CHART"
};


export default class LegacyMeasurementsHeader extends React.Component {

	constructor(props) {

		super(props);
		this.state = {
			showModal : false,
			target : { station : this.props.selectedStation}
		}
	}
	getTitle() {
		let title = "";
		switch (this.props.selectedViewMode.value) {
			case VIEW_MODE.NONE:
				title += "";
				break;
			case VIEW_MODE.AGGREGATED_TABLE:
				title += "Tabella";
				break;
			case VIEW_MODE.AGGREGATED_CHART:
				title += "Grafico ";
				break;
			default:
				title += "";
				break;
		}

		let stationDescription = <>{this.props.selectedStation.label + " (" +this.props.selectedStation.value+")"}</>;



		return <h3>{title+"   "}{stationDescription}</h3>;
	}

	getViewModeSelect(){
		let options =[
			{
				label: "Tabella",
				value: VIEW_MODE.AGGREGATED_TABLE,
				icon: <AiOutlineTable className="mr-2"></AiOutlineTable>
			},
			/*
			{
				label: "",
				value: VIEW_MODE.NONE
			},	
			{
				label: "Grafico",
				value: VIEW_MODE.CHART,
				icon: <AiOutlineLineChart className="mr-2"></AiOutlineLineChart>
			}
			*/
		];
	
		
		return <InputSelectComponent
			className={""}
			placeholder={"Seleziona Vista"}
			onChange={val => this.props.onSelectViewMode(options.find(opt => opt.value === val))}
			optionList={options}
			value={!!this.props.selectedViewMode ? this.props.selectedViewMode.value : null}
		/>

	}

	getAggregationIntervalSelect() {


		let options = [];
		options.push({
			label: "5 minuti",
			value: "MIN_5",
			disabled : false
		});
		options.push({
			label: "20 minuti",
			value: "MIN_20",
			disabled : false
		});
		options.push({
			label: "30 minuti",
			value: "MIN_30",
			disabled : false
		});
		options.push({
			label: "1 ora",
			value: "HOUR_1",
			disabled : false
		});


		return <InputSelectComponent
			className={""}
			placeholder={"Seleziona Intervallo"}
			onChange={val => this.props.onSelectAggregationInterval(options.find(opt => opt.value === val))}
			optionList={options}
			value={!!this.props.aggregationInterval ? this.props.aggregationInterval.value : null}
			isDisabled={this.props.aggregationIntervalDisabled}
		/>
	}
	/*
	* Sensors comparison
	* */
	showCompareSensorModal() {
		this.setState({
			showModal: true
		})
	}
	onCloseModal() {
		this.setState({
			showModal: false
		})
	}

	updateCompareList(data) {

		this.setState({
			showModal: false
		}, () => this.props.updateCompareList(data))
	}

	onPickRange(dates){
		let interval = null
		if(dates && dates[0] && dates[1]){
			interval = {}
			interval.start = moment(dates[0].getTime()).startOf('day')
			interval.end = moment(dates[1].getTime()).endOf('day')
		}
		this.props.resetInterval(interval)
	}

	render() {
		return (
			<>
				<div className="row align-items-center mt-2">
					<div className="col-4 ">
						<div className="row align-items-center">
							<div className="col-6">
								<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab", display: 'block' }}>Intervallo di osservazione:</span>
								<DateRangePicker
									locale={"it-IT"}
									onChange={(dates) => this.onPickRange(dates)}
									value={[this.props.interval && this.props.interval.start && new Date(this.props.interval.start.valueOf()), this.props.interval && this.props.interval.end && new Date(this.props.interval.end.valueOf())]}
									maxDate={new Date()}
									format={"dd/MM/yyyy"}
									rangeDivider='&nbsp;&nbsp;&nbsp;'
								/>							
							</div>

						</div>
					</div>
					<div className="col-4 ml-auto">
						<div className=" m-2">
							<Button
								size="small"
								variant="contained"
								color="default"
								startIcon={<AddIcon />}
								onClick={(e) => this.showCompareSensorModal()}
							>
								Aggiungi misure
							</Button>
						</div>
					</div>
					<div className="col-4 ml-auto">
						<div className=" m-2">
							{this.getViewModeSelect()}
						</div>
					</div>

				</div>
				<div className="row align-items-center mt-2">
					<div className="col-8 ">
						{this.getTitle()}
					</div>
					<div className="col-4 ">
						<div className=" m-2">
							{this.getAggregationIntervalSelect()}
						</div>
					</div>
				</div>
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "90vw",
							height: "90vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<LegacyCompareSensorComponent
							stationsGeoJSON={this.props.stationsGeoJSON}
							target={this.state.target}
							compareList={this.props.compareList}
							interval={this.props.interval}
							confirm={(data) => { this.updateCompareList(data) }}
							maxCompareListLenght = {10}
						/>
					</div>

				</Modal>
			</>

		)

	}
}
