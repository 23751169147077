import React from 'react';
import MauItem from './MauItem';
import MauDetails from './MauDetails';
import PaginationComponent from '#/commons/components/PaginationComponent';
import BulletinClient from '#/lib/BulletinClient';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";

import Modal from '@material-ui/core/Modal';
import MauMapComponent from "#/commons/mau/MauMapComponent";
import { CircularProgress } from '@material-ui/core';


export default class MauPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			allowCreationToday: false,
			pageKey: 0,
			showMauDetailsModal:false,
			selectedMauDetails:null,
			isLoading: false
		}
	}

	componentDidMount(){
		if(!!this.props.canEdit){
			BulletinClient.isCreationAllowedToday(
				(data)=>{
					this.setState({
						allowCreationToday : true
					})
				},
				(error)=>{
					this.setState({
						allowCreationToday : false
					})
				},
				this.props.bulletinTypeName
			);
		}
	}
	forcePageReloading = () => {this.setState({pageKey: (this.state.pageKey + 1) % 1000 })};
	fromJsonToMauItem(obj) {
		return <MauItem {...obj} key={obj.id}  editable={this.props.canEdit} mauItem={obj} onDetails={this.onDetails} refreshList={() => this.forcePageReloading()}/>;
	}

	getPage = (successCallback, errorCallback, page, start, end) => {
		let params = {
			pageNumber: page,
			pageSize: 5,
			bulletinTypeName: this.props.bulletinTypeName,
			start : start,
			end : end
		}
		this.setState({ isLoading: true });
		BulletinClient.listBulletins(
			(obj) => { 
				this.setState({ isLoading: false });
				successCallback(obj, page);
				setTimeout(() => this.getPage(successCallback, errorCallback, page, start, end), 120000);
			},
			(err) => { 
				this.setState({ isLoading: false });
				errorCallback(err);
				setTimeout(() => this.getPage(successCallback, errorCallback, page, start, end), 120000);
			},
			params
		)

	}

	onDetails = (mauItem)=>{
		this.setState({
			showMauDetailsModal:true,
			selectedMauDetails:mauItem
		})

	}

	onCloseMauDetailsModal = () => {
		this.setState({
			showMauDetailsModal:false,
			selectedMauDetails:null
		})
	}


	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	render() {

		return (
			<div className="generic-page list-page">
					
				<h2 className='d-flex justify-content-center align-items-center mb-5'>Messaggio di Allertamento Unificato</h2>

				{this.state.isLoading && (
					<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<CircularProgress />
					</div>
				)}
				<PaginationComponent
					key={this.state.pageKey}
					enableDateFilter={true}
					toItemFunction={(obj) => this.fromJsonToMauItem(obj)}
					getPage={(successCallback, errorCallback, page, start, end) => this.getPage(successCallback, errorCallback, page, start, end)} />

					{this.props.canEdit ?
					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
						<Link to={this.props.createUrl}>
							<Tooltip title="Crea un nuovo MAU">
								<Fab color="primary" disabled={!this.state.allowCreationToday || !this.isOfficer()}>
									<AddIcon />
								</Fab>
							</Tooltip>
						</Link>

					</div>
				:
				<></>
				}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showMauDetailsModal}
					onClose={() => this.onCloseMauDetailsModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "70vw",
							height: "70vh"
						}}
					>
						<MauMapComponent mauItem={this.state.selectedMauDetails} mapHeight='50vh' zoom={4}/>
					</div>

				</Modal>
			</div>
		)
	}
}
