import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class InfluxOutflowClient {

	static getEstimatedFakeFlowBySensorAndTimestamp(sensorCode, instant, okCallback, errCallback){
		let url = new URL(properties.url.estimatedFakeFlow + "/" + sensorCode+ "/" + instant) ;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getInfluxOuflowDataByBasinCode(basinCode, okCallback, errCallback){
		let url = new URL(properties.url.influxOutflowDataBySensorAndTimestamp + "/" + basinCode) ;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getInfluxOuflowBasins( okCallback, errCallback){
		let url = new URL(properties.url.influxOutflowBasins) ;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getInfluxOutflowDataByHydrometer(sensorCode, okCallback, errCallback){
		let url = new URL(properties.url.influxOutflowDataByHydrometer + "/" + sensorCode) ;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
}
