import React from 'react'
import {MapLayer, withLeaflet} from 'react-leaflet';
import L from "leaflet";

import chroma from 'chroma-js'
import GeoRasterLayer from "georaster-layer-for-leaflet";
import GISTools from "#/lib/GISTools";

import * as turf from "@turf/turf";

class GeotiffLayer extends MapLayer {



	createLeafletElement(props) {
		return new GeoRasterLayer({
			debugLevel: 0,
			georaster: this.props.georaster,
			opacity: this.props.opacity,
			resolution: this.props.resolution,
			keepBuffer: 25,
			pixelValuesToColorFn: (x)=>this.colorScaleFunction(x)
		});

	}

	colorScaleFunction = (x) =>{
		if(x[0]===-9999){
			return chroma(this.props.ndColor || "#ffffff00")
		}
		else{
			return this.props.colorScale(x)
		}
	}

	componentDidMount() {
		const { map } = this.props.leaflet;
		this.leafletElement.addTo(map);
		map.on('click', e => { this.layerClick(e, this.props.georaster) });

	}

	layerClick(e, georaster) {
		const latlng = [ e.latlng.lat, e.latlng.lng];
		let val = null;
		const lng = e.latlng.lng;
		const lat = e.latlng.lat;


		// By normalizing the difference in latitude and longitude between the image
		// origin and the point geometry by the cell height and width respectively,
		// we can map the latitude and longitude of the point geometry in the
		// coordinate space to their associated pixel location in the image space.
		// Note that the y value is inverted to account for the inversion between the
		// coordinate and image spaces.
		const x = Math.floor((lng - georaster.xmin) / georaster.pixelWidth);
		const y = Math.floor((georaster.ymax - lat) / georaster.pixelHeight);


		// iterate through the bands
		// get the row and then the column of the pixel that you want

		if (x > 0 && x < georaster.width && y > 0 && y < georaster.height) {

			if (turf.booleanPointInPolygon(turf.point([lng, lat]), GISTools.getCalabriaSingleBorder())) {
				val = georaster.values.map(rows => rows[y][x]);
				if (!!this.props.handleClick){
					this.props.handleClick(val,latlng)
				} else {
					this.addPopup(val, latlng);
				}
				console.log("clicked on " + latlng + " " + val)
			}
			else {
				console.log("clicked on " + latlng + " out of calabria borders")
				if (this.props.enableClickOutOfCalabria){
					val = georaster.values.map(rows => rows[y][x]);
					if (!!this.props.handleClick){
						this.props.handleClick(val,latlng)
					} else {
						this.addPopup(val, latlng);
					}
				}
			}
		} else {
			console.log("clicked on " + latlng + " out of raster ")

		}



	}

	addPopup(val,lnglat){
		let popupContent = val
		const latlng = L.latLng(lnglat[0], lnglat[1]);

		L.popup({ offset: [40, 40]})
			.setLatLng(latlng)
			.setContent(`<p>Valore:<br />${popupContent}</p>`)
			.openOn(this.props.leaflet.map);
	}

}

export const GeotiffRasterLayer = withLeaflet(props => {
	return <GeotiffLayer {...props} />;
});



