import { ListItemIcon } from '@material-ui/core';
import React from 'react';
import { FaFacebook } from 'react-icons/fa';
import {Link} from "react-router-dom";

function Footer() {
	const annoAttuale = new Date().getFullYear() + '';

	return (
		<>

			<footer className="bg-dark text-white pb-3" id="footer">
				<div className="" style={{fontSize:"x-small"}}>
					<h2 className="sr-only">Informazioni su ARPA Calabria</h2>
					<div className="row justify-content-between pt-4 mx-4">
						<div className='col-sm-6 col-lg-4 pt-2'>
							<Link className="navbar-brand" to={'/'}>
								<img className="img-fluid" style={{maxWidth: "60vw"}}
									src={"/img/4x/allertacal_logo_bigger.png"}
									alt="Logo"/>
							</Link>
							<span className="footer-legal border-0 mt-1">Seguici su</span>
							<div className="d-flex mb-3">
								<ListItemIcon className='min-vw-0 me-3'>
									<a href='https://www.facebook.com/arpacal/?locale=it_IT'><FaFacebook fontSize={'1rem'} color={'#fff'}/></a>
								</ListItemIcon>
								<ListItemIcon className='min-vw-0'>
									<a href='https://twitter.com/cfm_arpacal'>
										<svg fontSize={'1rem'} color={'#fff'} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
											<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
										</svg>
									</a>
								</ListItemIcon>
							</div>
						</div>
						<div className="col-sm-6 col-lg-8 row m-0 p-0 justify-content-between pt-2 px-2">
							<div className="footer-link-block" style={{ maxWidth: "20rem" }}>
								<h3 className="footer-legal border-0 p-0 mt-0 text-break">Area Meteo - Servizio Meteorologico Regionale</h3>
								<ul className="list-unstyled footer-link-list">
									<li><span className="footer-legal border-0 mt-0 py-1">Contr. Vaglio Lise, Piazza Sila, n.14 – piano 3, Cosenza</span></li>
									<li><span className="footer-legal border-0 mt-0 py-1">Tel: 0984.1865050, 0984.1865051</span></li>
									<li><span className="footer-legal border-0 mt-0 py-1">Pec: protocollo.cfm@pec.arpacal.it</span></li>
									<li><span className="footer-legal border-0 mt-0 py-1">Pec Sala Operativa: so.cfmcalabria@arpacal.telecompost.it</span></li>
								</ul>
							</div>
							<div className="footer-link-block" style={{ maxWidth: "20rem" }}>
								<h3 className="footer-legal border-0 p-0 mt-0 text-break">Area Idro - Servizio Idrogeologico Regionale</h3>
								<ul className="list-unstyled footer-link-list">
									<li><span className="footer-legal border-0 mt-0 py-1">Viale degli Angioini 143 - Catanzaro</span></li>
									<li><span className="footer-legal border-0 mt-0 py-1">Tel: 0961.531111</span></li>
									<li><span className="footer-legal border-0 mt-0 py-1">Fax: 0961.531122</span></li>
								</ul>
							</div>
							<div className="footer-link-block" style={{ maxWidth: "20rem" }}>
								<h3 className="footer-legal border-0 p-0 mt-0">Servizi</h3>
								<ul className="list-unstyled footer-link-list">
									<li><span className="footer-legal border-0 mt-0 py-1">Bollettini</span></li>
									<li><Link to={'/frontoffice/bim'}><span className="footer-legal border-0 mt-0 py-1">Siccità</span></Link></li>
									<li><Link to={'/frontoffice/meteo'}><span className="footer-legal border-0 mt-0 py-1">Previsioni meteo</span></Link></li>
								</ul>
							</div>
							<div className="footer-link-block" style={{ maxWidth: "20rem" }}>
								<h3 className="footer-legal border-0 p-0 mt-0">Dati e monitoraggio</h3>
								<ul className="list-unstyled footer-link-list">
									{/* <li><span className="footer-legal border-0 mt-0 py-1">Dati Ambientali</span></li> */}
									<li><Link to={'/frontoffice/richiestaDati'}><span className="footer-legal border-0 mt-0 py-1">Richiesta Dati</span></Link></li>
									{/* <li><span className="footer-legal border-0 mt-0 py-1">Dati Storici</span></li> */}
								</ul>
							</div>
						</div>
					</div>
					<div className="footer-legal mx-4">
						<span>Copyright ©{annoAttuale} - ARPACAL</span>
						{/* <span className='px-3'>Privacy RPD e Cookie Policy</span> */}
					</div>
				</div>
			</footer>
		</>


	);
}

export default Footer;

