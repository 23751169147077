import React from 'react'


import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter } from "react-tabulator";

import APIUtils from "#/lib/APIUtils";
import MosipClient from '#/lib/MosipClient'
import MosipMapComponent from '#/commons/map/MosipMapComponent';
import DateUtils from "#/lib/DateUtils";
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

import TableComponent from "#/commons/table/TableComponent";
import { properties } from '#/properties.js';
import Tooltip from '@material-ui/core/Tooltip';


import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import GetAppIcon from '@material-ui/icons/GetApp';

import { GrDocumentZip } from "react-icons/gr";

    
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const ReactSwal = withReactContent(Swal);

function ShowButton(props) {
	const rowData = props.cell._cell.row.data;

	return (
		<>
			<Tooltip title={"Mostra Soglie Superate"}>
				<IconButton color="primary" aria-label="upload picture" component="span"
						onClick={(e) => props.clickCallback(rowData.runId)}>
					<VisibilityIcon />
				</IconButton>
			</Tooltip>


		</>
	)
}

function DownloadButton(props) {
	const rowData = props.cell._cell.row.data;
	let url = MosipClient.getMosipExecutionReportById(rowData.id)
	return (
		<>
			<Tooltip title={"Scarica Report"}>
				<IconButton color="primary" aria-label="download file" component="span" onClick={()=>props.downloadHandler(url, "report_"+rowData.runId+".json")}>
					<GetAppIcon />
				</IconButton>

			</Tooltip>


		</>
	)
}

function DownloadFullReportButton(props) {
	const rowData = props.cell._cell.row.data;
	let url = MosipClient.getMosipExecutionFullReportByRunId(rowData.runId)
	return (
		<>
			<Tooltip title={"Scarica Report Integrale 2222"}>
				<IconButton color="primary" aria-label="download file" component="span" onClick={()=>props.downloadHandler(url, "report_"+rowData.runId+".zip")}>
					<GrDocumentZip />
				</IconButton>

			</Tooltip>


		</>
	)
}

const colors = {
	"LEVEL1" : "yellow",
	"LEVEL2" : "orange",
	"LEVEL3" : "red"
}

export default class MosipExecutionsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			municipalitiesGeoJSON : null,
			selectedExecution: {},
			mapTitle : "MOSIP Execution :",
			showAll: false,
			tableKey : 1,
			mapKey : 1
		}
	}

	componentDidMount() {
		this.loadFromApi();
	}

	loadFromApi() {


		let municipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getAllMunicipalities((data) => { resolve({municipalities:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let result = {};
		Promise.all([municipalitiesPromise])
		.then( data =>{
			Object.assign(result, ...data);
			let geoJSON = this.buildGeoJSONFromArray(result.municipalities);
			this.setState({
				municipalitiesGeoJSON : geoJSON,
				mapKey: (this.state.mapKey + 1) % 1000
			});
		})

	}


	buildGeoJSONFromArray(data) {
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Municipalities";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				COMUNE: value.name,
				ISTAT: value.istatCode,
				PROVINCIA: value.province,
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}

	

	getExecutionsColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "ID", field: "runId"},
			{ title: "Time", field: "timestamp", headerSort: true, 
				formatter: function (cell) {
				
					return DateUtils.fromISO8601(cell.getValue());
				}
			},
			{ title: "Livello 1", field: "lev1Count", width: 120, align: "center", formatter: function (cell) {

					if(cell.getValue()>0){
						cell.getElement().style.backgroundColor = "yellow"
					}
					cell.getElement().style.fontWeight ="bold"
					cell.getElement().style.fontSize ="20px"
					return cell.getValue();
				}
			},
			{ title: "Livello 2", field: "lev2Count", width: 120, align: "center", formatter: function (cell) {
					if(cell.getValue()>0){
						cell.getElement().style.backgroundColor = "orange"
					}					
					cell.getElement().style.fontWeight ="bold"
					cell.getElement().style.fontSize ="20px"
					return cell.getValue();
				}
			},
			{ title: "Livello 3", field: "lev3Count", width: 120, align: "center", formatter: function (cell) {
					if(cell.getValue()>0){
						cell.getElement().style.backgroundColor = "red"
					}
					cell.getElement().style.fontWeight ="bold"
					cell.getElement().style.fontSize ="20px"
					return cell.getValue();
				}
			},
			{
				title: "",
				formatter: reactFormatter(<ShowButton clickCallback={(runId) => this.selectExecution(runId)}></ShowButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 150,
			},
			{
				title: "",
				formatter: reactFormatter(<DownloadButton downloadHandler={this.downloadHandler}></DownloadButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 150,
			},
			{
				title: "",
				formatter: reactFormatter(<DownloadFullReportButton downloadHandler={this.downloadHandler}></DownloadFullReportButton>),
				headerSort: false,
				hozAlign: "center",
				vertAlign: "middle",
				width: 150,
			}
		]);
		return columns;
	}

	downloadHandler(url, fileName){
		APIUtils.downloadFile(url, null, fileName, null, 
		()=>{
			ReactSwal.fire('Il report '+fileName+' non è più disponibile!', '', 'error');

		})
		
	}

	selectExecution(runId) {
		new Promise((resolve, reject) => {
			MosipClient.getMosipExceededThresholdsByMosipExecution(
				(data) => { resolve(data); },
				() => { console.log("ERROR Executions"); },
				runId
			);
		}).then(data => {
			console.log("DATA",data)
			let exceededThresholdMap = data.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});
			console.log("exceededThresholdMap",exceededThresholdMap)
			this.setState({
				mapTitle : "MOSIP Execution: " +runId,
				selectedExecution: exceededThresholdMap,
				mapKey: (this.state.mapKey + 1) % 1000
			});
		});
	}

	

	polygonStyle(feature, latlng){
		let istatCode = feature.properties.ISTAT;
		if(!!this.state.selectedExecution[istatCode]){
			let municipalityAlert = this.state.selectedExecution[istatCode];
			return {
				fillColor: colors[municipalityAlert.maxAlertLevel],
				weight: 1,
				opacity: 1,
				color: "grey",
				fillOpacity: 1
			};
		}
		else{

			return {
				fillColor: "#fff0",
				weight: 0.5,
				opacity: 1,
				color: "grey",
				fillOpacity: 0
			};
		}
	}

	getPopupContent(properties){
		let istatCode = properties.ISTAT;
		let content = '';
		let municipalityData = '';
		municipalityData += `COMUNE : ${properties.COMUNE}</br>`;
		municipalityData += `PROVINCIA : ${properties.PROVINCIA}</br>`;
		municipalityData += `ISTAT : ${properties.ISTAT}</br>`;

		if(!!this.state.selectedExecution[istatCode]){
			let municipalityAlert = this.state.selectedExecution[istatCode];
			let color = colors[municipalityAlert.maxAlertLevel];
			content += `<div style='color:${color}'>${municipalityData}</div></br>`;
			content += "Soglie Superate :</br>";

			municipalityAlert.exceededList.forEach(t=>{
				let thresholdData = '';
				thresholdData += `Codice Rischio : ${t.riskCode}</br>`;
				thresholdData += `${t.measureType} MAX: ${t.max}</br>`;          
				thresholdData += `${t.measureType} AVG: ${t.avg}</br>`;          
				thresholdData += `${t.measureType} STD: ${t.std}</br>`;          
				let tColor = colors[t.alertLevel];
				content += `<div style='color:${tColor}'>${thresholdData}</div></br>`;
			})
		}
		else{
			content += `<div>${municipalityData}</div></br>`;
			content += "Nessuna Allerta";
			
		}

		return `<div style='font-weight: 600'>${content}</div>`;

	}

	onSwitchShowAll(event){
		this.setState({
			showAll : event.target.checked,
			tableKey : (this.state.tableKey+1)%1000
		})
	}

	render() {



		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className="row mt-4">
						<div className="col-8" >
							<div className="d-flex justify-content-between">
								<h3>Elenco Esecuzioni MOSIP</h3>
								<FormGroup row>
									<FormControlLabel
										control={
											<Switch
											checked={this.state.showAll}
											onChange={(e)=>this.onSwitchShowAll(e)}
											color="primary"
											name="checkedB"
											inputProps={{ 'aria-label': 'primary checkbox' }}
										/>
										}
										label="Mostra tutte"
									/>
									</FormGroup>
								
							</div>
							<TableComponent
								columns={this.getExecutionsColumns()}
								url={properties.url.mosipExecutionsPaginated+"/"+this.state.showAll}
								 tableKey={this.state.tableKey} 
							></TableComponent>

						</div>
						<div className="col-4">
							<MosipMapComponent
								title={this.state.mapTitle}
								geoJSON={this.state.municipalitiesGeoJSON}
								polygonStyle={(feature, latlng)=>this.polygonStyle(feature, latlng)}
								getPopupContent = {(properties)=>this.getPopupContent(properties)}
								polygonsKey={this.state.mapKey}
							></MosipMapComponent>
						</div>

					</div>

				</div>
			</>
		)
	}
}



