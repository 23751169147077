import React, {Component, useEffect} from "react";
import {Map, TileLayer} from "react-leaflet";
import GISTools from "#/lib/GISTools";
import L from "leaflet";
import "leaflet-easybutton";
import Modal from "@material-ui/core/Modal";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import MapWatermark from "#/commons/map/MapWatermark";



const tilelayers = {
    T1: {
        label: 'Tile 1',
        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
    },
    T2: {
        label: 'Tile 2',
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        url: "https://stamen-tiles-c.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png"
    },
    T3: {
        label: 'Tile 3',
        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/Specialty/DeLorme_World_Base_Map/MapServer/tile/{z}/{y}/{x}"
    },
    T4: {
        label: 'Tile 1',
        attribution: '<a href="https://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a> ',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    },
    T5: {
        label: 'Tile 2',
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    },
    T6: {
        label: 'Tile 3',
        attribution: ' <a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
        url: 'https://wxs.ign.fr/choisirgeoportail/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'
    }
}


export default class MapComponent extends Component {

    constructor() {
        super();
        this.state={
            showTileModal: false,
            selectedTile: 'T1',
            modalOffset: {top: 0, left: 0}
        }
    }
    findLeafletElement = () => {
        let leafletElement = null;
        let {functionalRef} = this.props;
        if (functionalRef && functionalRef.current){
            leafletElement = functionalRef.current.leafletElement;
        }
        if (this.map && this.map.leafletElement){
            leafletElement = this.map.leafletElement;
        }
        return leafletElement;
    }
    componentDidMount = () => {

        let leafletElement = this.findLeafletElement();

        if (leafletElement){

            leafletElement.invalidateSize();
            this.centerMap(leafletElement);

            if (!this.props.noHome){
                L.easyButton('fa-home', (btn, map) => {
                    this.centerMap(leafletElement);
                } ,'Centra mappa', `${this.props.buttonKey || 0}_idCenterMap`).addTo(leafletElement);
            }
            if (!this.props.tile){
                L.easyButton('fa-layer-group', (btn, map) => {
                    this.openTileModal()
                } ,'Cambia sfondo', `${this.props.buttonKey || 0}_idSelectTile`).addTo(leafletElement);
            }
            if (this.props.toggleBorders){
                L.easyButton('fa-draw-polygon', (btn, map) => {
                    this.props.toggleBorders()
                } ,'Toggle confini regionali', `${this.props.buttonKey || 0}_idSToggleBorders`).addTo(leafletElement);
            }
            if (this.props.toggleWindArrows){
                L.easyButton('fa-wind', (btn, map) => {
                    this.props.toggleWindArrows()
                } ,'Toggle particelle', `${this.props.buttonKey || 0}_idSToggleArrows`).addTo(leafletElement);
            }
            if (this.props.addMarker){
                leafletElement.on('contextmenu', this.props.addMarker);
            }
        }
        if (this.props.setMapRef && this.map){
            this.props.setMapRef(this.map);
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        let leafletElement = this.findLeafletElement();
        if (leafletElement && !this.props.skipRecenter) {
            const {zoneBbox} = this.props;
            if (!!zoneBbox) {
                leafletElement.fitBounds([
                    [zoneBbox.bbox[1], zoneBbox.bbox[0]],
                    [zoneBbox.bbox[3], zoneBbox.bbox[2]]
                ]);
            } else {
                leafletElement.invalidateSize();
                this.centerMap(leafletElement);
            }
        }
    }
    offset = el => {
        let rect = el.getBoundingClientRect();
        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        console.log({ top: rect.top, scrollTop,  height: el.offsetHeight, left: rect.left, scrollLeft, width: el.offsetWidth})
        return { top: rect.top + scrollTop + el.offsetHeight, left: rect.left + scrollLeft + el.offsetWidth}
    }

    openTileModal = () => {
        let selectTileButton = document.getElementById(`${this.props.buttonKey || 0}_idSelectTile`);
        this.setState({showTileModal: true, modalOffset: this.offset(selectTileButton)})
    }
    closeTileModal = () => {
        this.setState({showTileModal: false})
    }
    onSelectZone(zoneId){
        let stations = this.props.geoJSON;
        let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
        if (!!zoneId & zoneId !== 9999) {
            let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
            bbox = GISTools.getBBoxFromPoints(zonePolygon);
            let stationWithinZone = GISTools.intersect(stations, zonePolygon);
            stations = stationWithinZone;
        }

        if (!!this.props.centerMapOnSelect) {
            let leafletElement = this.findLeafletElement();
            leafletElement.fitBounds([
                [bbox.bbox[1], bbox.bbox[0]],
                [bbox.bbox[3], bbox.bbox[2]]
            ]);
        }
        this.props.onSelectZone(zoneId);
    }

    centerMap = (leafletElement = this.map.leafletElement) => {

        let bbox = this.props.zoneBbox || GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
        leafletElement.fitBounds([
            [bbox.bbox[1], bbox.bbox[0]],
            [bbox.bbox[3], bbox.bbox[2]]
        ]);

        let {zoom, forceZoom, buttonKey} = this.props;

        // forceZoom is true ( and zoom = 7 ) only for LapsWrf and radarMeteosat maps
        if (forceZoom && zoom) {
            leafletElement.panTo(new L.LatLng(39.11, 16.55));
            leafletElement.setZoom(zoom);
            leafletElement.invalidateSize();
        }

    }

    onTileLayerChange = (selectedTile) => {
        console.log(selectedTile);
        this.setState({selectedTile},() => this.closeTileModal())
    }

    render(){

        let {id, minZoom, maxZoom, children, width, height, zoom, key, zoomSnap, zoomControl,
            doubleClickZoom,
            closePopupOnClick,
            dragging,
            zoomDelta,
            trackResize,
            touchZoom,
            scrollWheelZoom,
            tile,
            backgroundColor,
            attributionControl,
            handleClick,
            functionalRef} = this.props;
        return (<>
            <Map className="mt-2"
                 style={{ width, height, borderRadius: '10px', zIndex: 0,...(backgroundColor ? {backgroundColor} : {})}}
                 onbaselayerchange={(data) => this.onTileLayerChange(data)}
                /*ADDITIONAL PROPS*/
                 {...(id ? {id} : {})}
                 {...(minZoom ? {minZoom} : {})}
                 {...(maxZoom ? {maxZoom} : {})}
                 {...(zoom ? {zoom} : {})}
                 {...(key ? {key} : {})}

                 {...('functionalRef' in this.props ? {ref: functionalRef} : {ref: Map => this.map = Map})}
                 {...('zoomSnap' in this.props ? {zoomSnap} : {})}
                 {...('zoomControl' in this.props ? {zoomControl} : {})}
                 {...('doubleClickZoom' in this.props ? {doubleClickZoom} : {})}
                 {...('closePopupOnClick' in this.props ? {closePopupOnClick} : {})}
                 {...('dragging' in this.props ? {dragging} : {})}
                 {...('zoomDelta' in this.props ? {zoomDelta} : {})}
                 {...('trackResize' in this.props ? {trackResize} : {})}
                 {...('touchZoom' in this.props ? {touchZoom} : {})}
                 {...('scrollWheelZoom' in this.props ? {scrollWheelZoom} : {})}
                 {...('attributionControl' in this.props ? {attributionControl} : {})}
                 {...(handleClick ? {onClick : handleClick} : {})}
                /*ONCLICK TO PICK POSITION (->CREATE NEW STATION)*/
            >
                {tile || <TileLayer attribution={tilelayers[this.state.selectedTile].attribution} url={tilelayers[this.state.selectedTile].url}/>}


                {children}
            </Map>
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute'
                }}
                open={this.state.showTileModal}
                onClose={() => this.closeTileModal()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        background: "white",
                        width: '15vw',
                        height: "30vh",
                        borderRadius: '15px',
                        position: "absolute",
                        top: this.state.modalOffset.top,
                        left: this.state.modalOffset.left,
                        overflowY: 'auto'
                    }}
                    className="scrollbar scrollbar-primary"
                >
                    <div className="p-2 m-2 row">
                        <FormControl className="col" component="fieldset" >
                            <RadioGroup row={false} aria-label="tile" name="tile" value={this.state.selectedTile} onChange={(event) => this.onTileLayerChange(event.target.value)}>
                                {Object.keys(tilelayers).map(tileKey =>
                                    <TileItem key={tileKey} tileKey={tileKey} ref={React.createRef()}/>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>

            </Modal>
        </>)
    }
}

const TileItem = React.forwardRef(({tileKey},ref) => {
        useEffect(() => {
            if (ref && ref.leafletElement){
                ref.leafletElement.invalidateSize();
                let bbox = GISTools.getBBoxFromPoints(GISTools.getCalabriaBorders());
                ref.leafletElement.fitBounds([
                    [bbox.bbox[1], bbox.bbox[0]],
                    [bbox.bbox[3], bbox.bbox[2]]
                ]);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[]);

        return (
            <div className="row mb-2 " style={{height: '10vh'}}>
                <div className="col-2 my-auto">
                    <FormControlLabel style={{margin:0}} value={tileKey} control={<Radio  color="primary"/>} label={''} />
                </div>
                <div className="col-10">
                    <Map ref={Map => ref = Map}
                         key={tileKey}
                         style={{center: [39.11, 16.55], width: "100%", height: "100%", borderRadius: "15px"}}
                         zoomSnap={false}
                         attributionControl={false}
                         zoomControl={false}
                         scrollWheelZoom={false}
                         dragging={false}
                         doubleClickZoom={false}>
                        <TileLayer attibution={tilelayers[tileKey].attribution} url={tilelayers[tileKey].url}/>
                    </Map>
                </div>
            </div>
        )
    }
)
