import React, { useState } from 'react';

import {Route, useHistory, withRouter} from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {Controller, useForm} from "react-hook-form";
import CheckboxTree from 'react-checkbox-tree';
import UserClient from '#/lib/UserClient';
import AuthenticationService, { ROLE_ADMIN, ROLE_OFFICER }  from '#/lib/AuthenticationService';
import LoginComponent from '#/backoffice/components/LoginComponent';

import { HeaderForm } from '#/commons/components/forms/HeaderForm';
import { TextFieldForm } from '#/commons/components/forms/TextFieldForm';
import { ResetButton } from '#/commons/components/forms/ResetButton';
import { SaveButton } from '#/commons/components/forms/SaveButton';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

/* icons */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import InputSelectComponent from "#/commons/components/InputSelectComponent";
import MenuItem from "@material-ui/core/MenuItem";

const ReactSwal = withReactContent(Swal);

const rolesHierarchy = [
		{
			value	: "ROLE_ADMINISTRATOR",
			children: [ "ROLE_ADMIN_PERSONALE", "ROLE_CONFIGURATOR", "ROLE_OFFICER", "ROLE_READER" ]
		},
		{
			value	: "ROLE_ADMIN_PERSONALE",
			children: [ ]
		},
		{
			value	: "ROLE_CONFIGURATOR",
			children: [ "ROLE_OFFICER", "ROLE_READER" ]
		},
		{
			value	: "ROLE_OFFICER",
			children: [ "ROLE_READER" ]
		},
		{
			value	: "ROLE_READER",
			children: [ ]
		}
	];
/*
const titleOptions = [
	{
		value: 'Sig.',
		label: 'Sig.' 
	},
	{
		value: 'Sig.ra',
		label: 'Sig.ra'
	},
	{
		value: 'Dott.',
		label: 'Dott.'
	},
	{
		value: 'Dott.ssa',
		label: 'Dott.ssa'
	},
	{
		value: 'Ing.',
		label: 'Ing.'
	},
	{
		value: 'Arch.',
		label: 'Arch.'
	},
	{
		value: 'Avv.',
		label: 'Avv.'
	},{
		value: 'Prof.',
		label: 'Prof.'
	},{
		value: 'Prof.ssa',
		label: 'Prof.ssa'
	},{
		value: 'Geom.',
		label: 'Geom.'
	},{
		value: 'Sig.na',
		label: 'Sig.na'
	},
	
];*/

const titleOptions = [
		 'Sig.',
		 'Sig.ra',
		 'Dott.',
		 'Dott.ssa',
		 'Ing.',
		 'Arch.',
		 'Avv.',
		 'Prof.',
		 'Prof.ssa',
		 'Geom.',
		 'Sig.na'
];

class UserEditorPage extends React.Component {

	formContent = {};

	constructor(props) {
		super(props);
		this.auth = props.auth;
		const url = window.location.href;
		const username = url.split('/').pop();
		//const username = AuthenticationService.getLoggedInUsername();
		this.state = {
			username: username!=='new' ? username : null,
			checkedRolesPermessions: [],
			userAnag: {},
			rolesList: []
		}
	}

	componentDidMount() {
		let promises = [];

		let getRolesListPromise = new Promise((resolve,reject)=>{
			UserClient.getRolesList (
				(roles) => { resolve({roles}) },
				(error) => {
					ReactSwal.fire(
						'getRolesListPromise Failed',
						'',
						'error'
					);
					reject({error});
				}
			)
		});
		promises.push(getRolesListPromise);

		if(!!this.state.username){
			let getUserRolesPermissionsPromise = new Promise((resolve,reject)=>{
				UserClient.getUserRolesPermissions (
					(user) => { resolve({user}) },
					(error) => {
						ReactSwal.fire(
							'getUserRolesPermissions Failed',
							'',
							'error'
						);
						reject({error});
					},
					{ username : this.state.username }
				)
			})
			promises.push(getUserRolesPermissionsPromise);
		}

		Promise.all(promises).then(values => {
			let result = {};

			Object.assign(result, ...values);
			const userAnag = !!result.user && result.user.userAnag ? result.user.userAnag : {};
			const userRoles = !!result.user && !!result.user.roles ? this.getCheckedRolesPermissions(result.user.roles) : [];
			const rolesList = !!result.roles ? this.getRolesListForTree(result.roles) : [];
			this.setState({
				userAnag : userAnag,
				rolesList : rolesList,
				checkedRolesPermessions : userRoles
			})
		});
	}

	getRolesListForTree = (roles) => {
		let nodes = [];
		roles.forEach( role => {
			nodes.push({ value: role.name, label: role.description});
		})
		return nodes;
	}

	getCheckedRolesPermissions = (userRoles) =>{
		let rolesChecked = [];
		userRoles.forEach ( role => {
			rolesChecked.push(role.roleName);
		})
		return rolesChecked;
	}

	render = () => {

        const roles = !!this.props.roles ? this.props.roles : [ROLE_ADMIN, ROLE_OFFICER];
        if (!AuthenticationService.isValidSession() || !AuthenticationService.haveRolesPermssions(roles))  {
          return <Route render={() => <LoginComponent {...this.props}/>}></Route>
        }
		const userAnag = this.state.userAnag;
		const userRoles = this.state.checkedRolesPermessions;
		const rolesList = this.state.rolesList;
		const modifyExistingUser = !!this.state.username;

		return (
			<div className="generic-page">
				<div className="container">
					<HeaderForm title={!!this.state.username ? "Modifica dati utente" : "Creazione nuovo utente"} buttonLabel="Elenco utenti" buttonHref="/backoffice/user"/>
					{modifyExistingUser && !!userAnag.name ?
						<UserForm userAnag={userAnag} userRoles={userRoles} modifyExistingUser={modifyExistingUser} rolesList={rolesList}/>:
						!modifyExistingUser && <UserForm userAnag={userAnag} userRoles={userRoles} modifyExistingUser={modifyExistingUser} rolesList={rolesList}/>
					}
				</div>

			</div>

		)
	}
}

export default withRouter(UserEditorPage)

const UserForm = (props) => {
	const history = useHistory();
	const fieldRequiredMessage = "Campo obbligatorio";
	const patternValidationNumber = /\d+/;
	const patternValidationEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
	const patternValidationCodiceFiscale = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
	const defaultValues = !!props.userAnag ? {...props.userAnag} : '';
	const { register, handleSubmit, errors, setValue,control, reset } = useForm({defaultValues: defaultValues});
	const [checked_nodes, setCheckedNodes] = useState(!!props.userRoles ? props.userRoles : ['ROLE_READER']);

	const getRolesPermissionsFromChecked = (checkedRolesPermissions) => {
		let rolesPermissions = [];
		if (!!checkedRolesPermissions){
			checkedRolesPermissions.forEach( rolePermission => {
				const splitRolePermission = rolePermission.split('|');
				const role = splitRolePermission[0];
				const permission = splitRolePermission[1];
				const indexRole = rolesPermissions.findIndex( p => p.roleName === role);
				indexRole > -1 ?  rolesPermissions[indexRole].permissionsName.push(permission) : rolesPermissions.push({roleName: role, permissionsName: [permission] });
			})
		}
		return rolesPermissions;
	}

	const getRelatedRolesFromCheckedRole = (checkedRoles) => {
		let rolesToCheck = [...checkedRoles];
		checkedRoles.forEach( checkedRole => {
			const roleHierarchy = rolesHierarchy.find(role => role.value === checkedRole);
			const relatedRoles = !!roleHierarchy ? roleHierarchy.children : [];
			rolesToCheck = [...new Set([...rolesToCheck, ...relatedRoles])];
		})
		setCheckedNodes(rolesToCheck);
	}

	const resetForm = () => {
		setCheckedNodes([]);
		if (props.modifyExistingUser ) {
			reset({ username: defaultValues.username });
		} else {
			reset();
		}
	}

	const onSubmit = (data) => {

		const rolesPermissions = getRolesPermissionsFromChecked(checked_nodes);
		const params = {rolePermission : rolesPermissions, ...data};

		if (props.modifyExistingUser ) {//Modify
			let recap = '';
			ReactSwal.fire({
				title: "<h2>Confermi la modifica dei dati utente</h2>",
				html: recap,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Invia',
				cancelButtonText: 'Annulla'
			}).then((result) => {
				if (result.value) {
					UserClient.userSave(
						(result) => {
							ReactSwal.fire('Archiviazione utente','Utente archiviato con successo','success');
							history.push("/backoffice/user");
						},
						(msg)  => {
							ReactSwal.fire('Errore archiviazione utente', !!msg ? msg.message : '', 'error');

						},
						params
					)
				}
			});
		} else {
			UserClient.usernameExist(
				(result) => {
					if(result){
						ReactSwal.fire('Username', 'Username già presente in archivio','error');
					} else {
						ReactSwal.fire({
							title: "<h2>Confermi la creazione dell'utente?</h2>",
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Invia',
							cancelButtonText: 'Annulla'
						}).then((result) => {
							if (result.value) {
								UserClient.userSave(
									(result) => {
										ReactSwal.fire('Archiviazione utente','Utente archiviato con successo','success');

										history.push("/backoffice/user");
									},
									(msg)  => {
										ReactSwal.fire('Errore archiviazione utente', msg, 'error');
									},
									params
								)
							}
						});
					}
				},
				function (msg) {
					ReactSwal.fire('Username', 'Verifica username fallita','error')
				},
				params
			)
		}
	}

	return (
		<form onSubmit = {handleSubmit(onSubmit)} autoComplete="off">

			<Card>
				<CardHeader title="Anagrafica" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
				<Divider/>
				<CardContent>
					<Grid container spacing={10}>
						<Grid item xs={3}>
							<Controller
								as={
									<TextFieldForm
										className = "mt-2"
										select
										id = "title"
										label = "Titolo*"
										error={!!errors.title}
										helperText={!!errors.title ? errors.title.message : null}
									>
										{titleOptions.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)}
									
									</TextFieldForm>
								}
								name="title"
								defaultValue={ defaultValues.title || ''}
								rules={{ required: fieldRequiredMessage }}
								control={control}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextFieldForm
								id = "name"
								label = "Nome*"
								error={!!errors.name}
								helperText={!!errors.name ? errors.name.message : null}
								inputRef={register({ required: fieldRequiredMessage })}
								handleClearButton={() => setValue('name', null)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextFieldForm
								id = "surname"
								label = "Cognome*"
								error={!!errors.surname}
								helperText={!!errors.surname ? errors.surname.message : null}
								inputRef={register({ required: fieldRequiredMessage })}
								handleClearButton={() => setValue('surname', null)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextFieldForm
								id = "cf"
								label = "Codice Fiscale*"
								error={!!errors.cf}
								helperText={!!errors.cf ? errors.cf.message : null}
								inputRef={register({ required: fieldRequiredMessage, pattern: {value: patternValidationCodiceFiscale, message: "Formato codice fiscale non valido"}})}
								handleClearButton={() => setValue('cf', null)}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={10}>
						<Grid item xs={4}>
							<TextFieldForm
								disabled = {!!props.modifyExistingUser}
								id = "username"
								label = "Username*"
								error={!!errors.username}
								helperText={!!errors.username ? errors.username.message : null}
								inputRef={register({ required: fieldRequiredMessage })}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PermIdentityOutlinedIcon />
										</InputAdornment>
									),
									endAdornment: (
										<IconButton tabIndex="-1" disabled={props.modifyExistingUser}  onClick={() => setValue('username', null)}><ClearIcon /></IconButton>
									),
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextFieldForm
								id = "email"
								label = "Email*"
								error={!!errors.email}
								helperText={!!errors.email ? errors.email.message : null}
								inputRef={register({ required: fieldRequiredMessage, pattern: {value: patternValidationEmail, message: "Formato email non valido"}})}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<MailOutlineIcon />
										</InputAdornment>
									),
									endAdornment: (
										<IconButton tabIndex="-1" onClick={() => setValue('email', null)}><ClearIcon /></IconButton>
									)
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextFieldForm
								id = "mobilePhone"
								label = "Numero Telefono*"
								error={!!errors.mobilePhone}
								helperText={!!errors.mobilePhone ? errors.mobilePhone.message : null}
								inputRef={register({ required: fieldRequiredMessage, pattern: {value: patternValidationNumber, message: "Inserire solo numeri"}})}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PhoneAndroidOutlinedIcon />
										</InputAdornment>
									),
									endAdornment: (
										<IconButton tabIndex="-1" onClick={() => setValue('mobilePhone', null)}><ClearIcon /></IconButton>
									)
								}}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			<Card className="mt-4">
				<CardHeader title="Ruoli*" titleTypographyProps={{variant:'h6'}} style={{backgroundColor: "#f9f9f9"}}/>
				<Divider/>
				<CardContent>
					<TextFieldForm
						hidden
						id = "rolesText"
						value = {checked_nodes}
						error={!!errors.rolesText}
						inputRef={register({ required: fieldRequiredMessage })}
					/>

					<CheckboxTree
						id="userRoles"
						name="userRoles"
						nodes={props.rolesList}
						showExpandAll
						expandDisabled
						checked={checked_nodes}
						onCheck={checked => getRelatedRolesFromCheckedRole(checked)}
						icons={{
							parentClose: <ExpandMoreIcon/>,
							parentOpen: <LockOutlinedIcon fontSize="inherit"/>,
							leaf: <VpnKeyIcon fontSize="inherit"/>
						}}
					/>
					{!!errors.rolesText && <p style={{color: '#f44336', fontSize: '0.75rem', marginTop : '3px'}}>{errors.rolesText.message}</p>}

				</CardContent>
			</Card>

			<Grid container justify="center" alignItems="center" className="mt-1">
				<ResetButton variant="contained" size="large" onClick={() => resetForm()}/>
				<SaveButton variant="contained" size="large" type="submit"/>
			</Grid>

		</form>

	);
}
