import React,{useState, useEffect} from 'react';
import SvgHolderComponent from './SvgHolderComponent'
import JsonFormHolderComponent from './JsonFormHolderComponent'
import {GeoJSON} from "react-leaflet";
import MapComponent from "#/commons/map/MapComponent";
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";
import GISTools from "#/lib/GISTools";
import L from "leaflet";
import * as turf from "leaflet";

export default class SvgFormComponent extends React.Component {

    render() {
        return (
            <>
                <div className="row d-flex justify-content-center">
                    <h2>{this.props.title}</h2>
                </div>
                <div className="row mt-4">
                    <div className="col-6">

                        {/* <SvgHolderComponent
                            id={this.props.id}
                            baseZoneId={this.props.baseZoneId}
                            svgUrl={this.props.svgUrl}
                            palette={this.props.palette}
                            svgState={this.props.stateByZone}
                            onClickActions={this.props.onZoneClickActions}
                        ></SvgHolderComponent> */}
                        <LeafletHolderComponent
                            id={this.props.id}
                            palette={this.props.palette}
                            stateByZone={this.props.stateByZone}
                            onClickAction={this.props.onZoneClickActions2}
                            municipalitiesJSON={this.props.municipalitiesJSON}
                        ></LeafletHolderComponent>

                    </div>
                    <div className="form col-6">
                        <JsonFormHolderComponent
                            schema={this.props.formSchema}
                            formData={this.props.stateByZone}
                            onChange={this.props.onFormDataChange}
                            inline={true}
                        ></JsonFormHolderComponent>
                    </div>
                </div>
            </>
        );
    }

}
const LeafletHolderComponent = ({stateByZone, id, palette, onClickAction, municipalitiesJSON}) => {
    const [geoJson, setgeoJson] = useState(null)
    useEffect(() => {
        let geoJson = zonesGeoJson8;
        GISTools.addCustomCentroids(geoJson);
        setgeoJson(geoJson)},[])

    const polygonStyle = (feature, latlng) => {
        let state = stateByZone[feature.properties.ZONE];
        let color = palette[state];
        return {
            fillColor: color,
            weight: 1,
            opacity: 1,
            color: "black",
            fillOpacity: 1
        }
    }
    const muniPolygonStyle = (feature, latlng) =>
        ({
            weight: 0.3,
            opacity: 1,
            color: '#152458',
            fillOpacity: 0
        })

    const onEachPolygon = (feature, layer) => {

        layer.on('click', (e) => {
            let {lat, lng} = e.latlng;
            let feature = null;
            try {
                feature = geoJson.features.find(feature =>  GISTools.isWithinPoligon([lng, lat], feature.geometry));
            } catch(err){
                console.log("NO POLYGON FOUND")
            }

            if (feature){
                let zoneId = feature.properties.ZONE;
                onClickAction(zoneId);
            }
        });
    }
    const pointToLayer = (feature, latlng) => {
        let marker = L.marker(latlng, {
            icon: L.divIcon({
                className: 'label',
                html: `<span style="font-size:2rem; font-weight:600">${feature.properties.ZONE}</span>`,
            })
        });
        return marker;

    }
    return (geoJson &&
        <div id={id}>
            <MapComponent
                width={"100%"}
                height={"70vh"}
                zoom={8}
                zoomControl={false}
                zoomSnap={false}
                buttonKey={0}
                touchZoom={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                attributionControl={false}
                noHome={true}
                tile={<></>}>


                <GeoJSON key={"polygons_" + id }
                         data={geoJson}
                         onEachFeature={(feature, layer) => onEachPolygon(feature, layer)}
                         style={(feature, latlng) => polygonStyle(feature, latlng)}
                         pointToLayer={(feature, latlng) => pointToLayer(feature, latlng)}
                />
                {municipalitiesJSON && <GeoJSON key={"muni_" + id}
                                                data={municipalitiesJSON}
                                                onEachFeature={(feature, layer) => onEachPolygon(feature, layer)}
                                                style={(feature, latlng) => muniPolygonStyle(feature, latlng)}
                />}

            </MapComponent>
        </div>)
}
