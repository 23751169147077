import {properties} from "#/properties.js";
import APIUtils from "#/lib/APIUtils";

export default class UserClient {



	
  static getUserList = (okCallback, errCallback) => {
    let url = new URL(properties.url.userList);
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static getRolesList = (okCallback, errCallback) => {
    let url = new URL(properties.url.rolesList);
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static getUserRolesPermissions = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.userRolesPermissions + "/" + params.username +"/authorities");
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static getUserListRolesPermissions = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.userListRolesPermissions);
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static usernameExist = (okCallback, errCallback, params) => {
    let url = new URL(
      properties.url.usernameExist + "/" + params.username
    );
    APIUtils.getFetchResponse(url, null, okCallback, errCallback);
  };

  static userSave = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.userSave);
    let fetch_options = {
      headers: { "Content-Type": "application/json", charset: "utf-8" },
      method: "POST",
      body: JSON.stringify(params),
    };
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };

  static userChangePassword = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.userChangePassword);
    let fetch_options = {
      headers: { "Content-Type": "application/json", charset: "utf-8" },
      method: "POST",
      body: JSON.stringify(params),
    };
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };


  static userDelete = (okCallback, errCallback, params) => {
    let url = new URL(properties.url.userDelete + "/" + params.username);
    let fetch_options = { method: "DELETE" };
    APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
  };
}
