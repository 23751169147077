import React, {useEffect, useState} from 'react';
import StaticPageClient from '#/lib/StaticPageClient'


const DoveSiamoPage = () => {
	const [page, setPage] = useState(null)

	useEffect(()=>{
		StaticPageClient.getByCode(
			(sp)=>{
				console.log(sp)
				setPage(sp.code)
			},
			()=>{},
			"where"
		)
	})

	return<div className="generic-page container-fluid textAlignCenter">
		{!!page && <p dangerouslySetInnerHTML={{ __html: `${page}` }}></p>}
		{/*<><h2>Elenco contatti C.F.M.</h2>
		<ReactTabulator
			columns={[{title: "Nome", field: 'name', headerFilter: true,  headerFilterPlaceholder:"Filtra per nome..."},{title: "Ruolo", field: 'role'},{title: "Telefono", field: 'phone', headerSort: false}]}
			data={data}
			options={{height: '70vh'}}
			key={"contacts"}/></>*/}
	</div>;
}

export default DoveSiamoPage;
