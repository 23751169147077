import React from 'react'
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';

import MosipClient from '#/lib/MosipClient'

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const ReactSwal = withReactContent(Swal);


class MosipTestPage extends React.Component {



	componentDidMount() {
	}

	generateMosipExecution(istatCode){
		 		
				
		MosipClient.testMosipExecution(
			()=>{
				ReactSwal.fire('Nuova Esecuzione Mosip - TEST', 'Mosip Execution generata con successo', 'success')
				.then((value) => {
					this.props.history.push("/backoffice/mosip/executions");
				});
			},
			()=>{
				ReactSwal.fire('Nuova Esecuzione Mosip - TEST', 'Errore nell esecuzione di test del MOSIP', 'error');
			},
			istatCode
		) 
	}

	

	render() {



		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className=" mt-4">
						<span> Funzionalità di test<br></br>Al click sul bottone sarà generata un'esecuzione fittizia del Mosip</span>
						<br></br>
						<Button style={{ margin :"1vw", minWidth: "10vw" }} size="lg" variant="contained" color="primary"  onClick={(e)=>this.generateMosipExecution()} >Genera Mosip Execution</Button>
						<Button style={{ margin :"1vw", minWidth: "10vw" }} size="lg" variant="contained" color="primary"  onClick={(e)=>this.generateMosipExecution("1880063")} >Genera Soglie Superate a Reggio Calabria</Button>


					</div>
				</div>

			</>
		)
	}
}

export default withRouter(MosipTestPage)