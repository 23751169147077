import React from "react";
import domtoimage from "dom-to-image-more";
import { properties } from "#/properties.js";

import {
  DEFAULT_STEPS,
  DEFAULT_MAPS,
  DEFAULT_FORM_JSON_DATA,
} from "#/backoffice/forecast/utils/ForecastUtils";
import BulletinClient from "#/lib/BulletinClient";
import GISTools from "#/lib/GISTools";
import ObjectManager from "#/lib/ObjectManager";

import Swal from "sweetalert2";
import { withRouter } from 'react-router';
import withReactContent from "sweetalert2-react-content";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { BackButton } from "#/commons/components/forms/BackButton";

import { zonesGeoJson8 } from "#/lib/ZoneGeoJsonDefault";
import _ from 'lodash';
import SettingsClient from "#/lib/SettingsClient";

const ReactSwal = withReactContent(Swal);

const getPointFeature = (key,iconKey) => ({ "type": "Feature", "properties": { "key": key, "iconKey": iconKey, "angle": 0 }, "geometry": { "type": "Point", "coordinates": [16.331519676805296, 39.29891237334977]}});
class ForecastEditorMultiPage extends React.Component {
  steps = undefined;
  totalSteps = 0;

  constructor(props) {
    super(props);
    this.steps = props.viewMode ? DEFAULT_STEPS.VIEW : DEFAULT_STEPS.EDIT;
    this.totalSteps = this.steps.length;
    const url = window.location.href;
    const idString = url.split("/").pop();
    const id = parseInt(idString);

    this.state = {
      id: !!id ? id : null,
      bulletinTypeName: "forecast",
      approved: false,
      published: false,
      viewMode: props.viewMode,
      activeStep: 0,
      uploadedFile: null,
      selectedFile: null,
    };
  }

  componentDidMount() {

    if (!!this.state.id) {
      this.getBulletinById(this.state.id);
    } else {
      SettingsClient.getGeojsonTemplateByDocumentType(
          (data) => {

            console.log(">>>>>>>>>>>>>>>> geoJson",data.geoJson,"identifier",data.identifier);
            this.isCreationAllowedToday(this.setState(
                {
                  allowCreationToday: true,
                  formJsonData: this._setZonesOnFormJsonData(DEFAULT_FORM_JSON_DATA, JSON.parse(data.geoJson)),
                  selectedGeoJSON: JSON.parse(data.geoJson),
                  maps: this._getMapShapesGeoJSONFromLocalFile(DEFAULT_MAPS, JSON.parse(data.geoJson))
                }),
                this.goToListPage);
          },
          () => {ReactSwal.fire('Nessun file zonale trovato','Configurare il file zonale nella sezione <i>Amministrazione/Impostazioni generali</i>','error')
              .then(this.goToListPage)},
          "forecast")
    }
  }

  goToListPage = () => {
    this.props.history.push("/backoffice/forecast");
  }

  getBulletinById = (id) => {
    if (!!id) {
      BulletinClient.getBulletin(
          (result) => {
            const geoJson = result.jsonData.geoJson;
            const maps = !!geoJson
                ? this._getMapShapesGeoJSONFromJsonData(DEFAULT_MAPS, geoJson, result.jsonData.maps)//this._getMapShapesGeoJSONFromLocalFile(DEFAULT_MAPS, geoJson)
                : this.state.maps;
            this.setState({
              maps: maps,
              formJsonData: result.jsonData,
              published: result.published,
              allowCreationToday: true,
            });
            if (result.jsonData.uploadedFile) {
              let dataCreazione = new Date(result.createdAt).toLocaleDateString();
              dataCreazione = dataCreazione.replace(/[^0-9]/g, "");
              let ora = new Date(result.createdAt);
              ora.setHours(ora.getHours() - 1);
              let oraCreazione = ora.toLocaleTimeString();
              oraCreazione = oraCreazione.replace(/:/g, "").slice(0, -2);
              this.setState({
                uploadedFile: "FORECAST_" + dataCreazione + "_" + oraCreazione + ".pdf",
              });
            }
          },
          (msg) => {
            console.log("msg: ", msg);
          },
          {
            id: this.state.id,
            bulletinTypeName: this.state.bulletinTypeName,
          }
      );
    } else {
      this.setImgOnJsonData(["img_empty"], this.state.maps[0].mapId);
    }
  }

  isCreationAllowedToday = (trueCallback, falseCallback) => {
    BulletinClient.isCreationAllowedToday(
        (data) => {
          this.setState({ allowCreationToday: true }, trueCallback);
        },
        (error) => {
          falseCallback();
        },
        this.state.bulletinTypeName
    );
  };
  isCreationAllowedToday = (trueCallback, falseCallback) => {
    this.setState({ allowCreationToday: true })
  };

  _addCentroidGeoJSON = (geoJSON) => {
    GISTools.addCentroids(geoJSON);
  };

  _buildZonesFromGeoJSON = (geoJSON) => {
    let zones = null;
    if (!!geoJSON && !!geoJSON.features) {
      zones = geoJSON.features.map((x) => {
        return {
          id: x.properties.ZONE,
          label: "ZONA " + x.properties.ZONE,
          value: null,
        };
      });
    }
    return zones;
  };

  _setZonesOnFormJsonData = (formJsonData, geoJSON) => {
    return {
      ...formJsonData,
      geoJson: geoJSON
    };
  };

  _getMapShapesGeoJSONFromLocalFile = (initMaps, localGeoJSONContent) => {

    let maps = initMaps;
    let geoJSON = JSON.parse(JSON.stringify(localGeoJSONContent));
    const zonesFromGeoJSON = this._buildZonesFromGeoJSON(localGeoJSONContent);
 /*   this._addCentroidGeoJSON(geoJSON);*/
    maps.forEach((value, index) => {
      maps[index] = {
        ...maps[index],
        zones: JSON.parse(JSON.stringify(zonesFromGeoJSON)), //clona -  [...zonesFromGeoJSON] non funziona
        shapesGeoJSON: _.cloneDeep(geoJSON),
        mapKey: (maps[index].mapKey + 1) % 1000,
      };
    });
    return maps;
  };
  _getMapShapesGeoJSONFromJsonData = (initMaps, localGeoJSONContent, storedMaps) => {

    let maps = initMaps;
    let geoJSON = JSON.parse(JSON.stringify(localGeoJSONContent));
    const zonesFromGeoJSON = this._buildZonesFromGeoJSON(localGeoJSONContent);
    /*   this._addCentroidGeoJSON(geoJSON);*/
    maps.forEach((value, index) => {
      maps[index] = {
        ...maps[index],
        zones: JSON.parse(JSON.stringify(zonesFromGeoJSON)), //clona -  [...zonesFromGeoJSON] non funziona
        shapesGeoJSON: index <= 1 ? (index === 0 ? storedMaps.today : storedMaps.tomorrow) : _.cloneDeep(geoJSON),
        mapKey: (maps[index].mapKey + 1) % 1000,
      };
    });
    return maps;
  };
  setImgOnJsonData = (section, elementId, successCallback) => {
    let formJsonData = this.state.formJsonData;
    const path = [...section];
    domtoimage
        .toPng(document.getElementById(elementId))
        .then((dataUrl) => {
          ObjectManager.set(formJsonData, path, dataUrl);
          this.setState({ formJsonData: formJsonData }, successCallback);
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        });
  };

  send = () => {
    let jsonData = this.state.formJsonData;
    jsonData.maps = {today: this.state.maps[0].shapesGeoJSON, tomorrow: this.state.maps[1].shapesGeoJSON}
    if (this.state.selectedFile) {
      jsonData.uploadedFile = true;
    }
    const prevActiveStep = this.state.activeStep;
    const nextStep = prevActiveStep + 1;

    let params = {
      bulletinId: !!this.props.edit ? this.state.id : null,
      documentTypeName: this.state.bulletinTypeName,
      jsonData: JSON.stringify(jsonData),
      trigger : this.props.trigger,
      referenceId : this.state.id,
    };
    BulletinClient.saveBulletin(
        (result) => {
          this.setState({
            id: result.id,
            saveStatus: "Previsioni archiviate con successo.",
            activeStep: nextStep,
          },()=>{
            ReactSwal.fire(
                {
                  icon: 'success',
                  title: 'Previsioni archiviate con successo.',
                  html: 'Ti Stiamo Reindirizzando alla lista delle previsioni',
                  timer: 4000,
                  confirmButtonText: `OK`,
                })
                .then(this.goToListPage)
          });
          if (this.state.selectedFile) {
            this.onSubmitDropzoneHandle(result.id);
          }
        },
        (msg) => {
          this.setState({
            saveStatus:
                "Si è verificato un errore durante l'archiviazione delle previsioni",
            activeStep: nextStep,
          });
        },
        params
    );
  };

  onSubmitDropzoneHandle = (idBulletin) => {
    let params = {
      id: idBulletin,
      file: this.state.selectedFile ? this.state.selectedFile : null
    };
    BulletinClient.saveBulletinForecastFile (
      (result) => {
        this.setState({
          id: result.id,
          saveStatus: "File Previsioni archiviato con successo.",
        },()=>{
          ReactSwal.fire(
              {
                icon: 'success',
                title: 'File Previsioni archiviato con successo.',
                html: 'Ti Stiamo Reindirizzando alla lista delle previsioni',
                timer: 4000,
                confirmButtonText: `OK`,
              })
              .then(this.goToListPage)
        });
      },
      (msg) => {
        this.setState({
          saveStatus:
              "Si è verificato un errore durante il salvataggio del file",
        });
      },
      params
    )
  }

  handleSetMaps = (maps) => {
    this.setState({ maps: maps });
  };

  handleDropzoneChangeStatus = ({ file }, status) => {
    let maps = this.state.maps;
    if (status === "removed") {
      maps.forEach((value, index) => {
        maps[index] = {
          ...maps[index],
          shapesGeoJSON: null,
          zones: [],
          mapKey: (maps[index].mapKey + 1) % 1000,
        };
      });
      this.setState({
        maps: maps,
      });
    }
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target.result;
        let geoJSON = JSON.parse(fileContent);
        const zonesFromGeoJSON = this._buildZonesFromGeoJSON(geoJSON);
        this._addCentroidGeoJSON(geoJSON);
        maps.forEach((value, index) => {
          maps[index] = {
            ...maps[index],
            zones: JSON.parse(JSON.stringify(zonesFromGeoJSON)), //clona -  [...zonesFromGeoJSON] non funziona
            shapesGeoJSON: geoJSON,
            mapKey: (maps[index].mapKey + 1) % 1000,
          };
        });

        this.setState({ maps: maps, shapesGeoJSON: geoJSON }, () => {
          this.setImgOnJsonData(["img_empty"], this.state.maps[0].mapId);
        });
      };
      reader.onerror = (event) => {
        console.log("Error");
      };
      reader.readAsText(file);
    }
  };

  handleSelectGeoJSON = (valueSelected) => {
    let maps = this.state.maps;

    maps = !!valueSelected
        ? this._getMapShapesGeoJSONFromLocalFile(maps, valueSelected)
        : DEFAULT_MAPS;
    this.setState({ maps: maps, selectedGeoJSON: valueSelected });
  };
  handleFormChange = (currentDay, zoneId, name, value) => { // FIXME: refactor this
    console.log('day, zoneId, name, value: ', currentDay, zoneId, name, value);

    let formJsonData = this.state.formJsonData;
    if (!!name) {
      if (!!zoneId) {
        let zone = formJsonData[currentDay].meteoByZone.find(mbz => mbz.zone === zoneId);
        if (!!zone){
          zone.meteo = value
        } else {
          formJsonData[currentDay].meteoByZone.push({zone: zoneId, meteo: value})
        }
        let map = this.state.maps.find(map => map.mapId === currentDay);
        let zonalPunctualFeature = map.shapesGeoJSON.features.find(feature => feature.geometry.type === 'Point' && feature.properties.ZONE === zoneId)
        if (!!zonalPunctualFeature){
          zonalPunctualFeature.properties.weather = value;
          map.mapKey = (map.mapKey + 1) % 1000;
        }
      } else {
        if (['hour_start','hour_end'].includes(name)){
          formJsonData[currentDay][name] = value;
        } else {
          formJsonData[currentDay]['fields'][name] = value;
        }
      }
    }
    this.setState({
      formJsonData: formJsonData,
      maps: this.state.maps,
      /*mapKey: (this.state.maps[0].mapKey + 1) % 1000,*/
    });
  };

  handleDropzoneChangeStatus = ({ file }, status) => {
    if (status === "removed") {
      this.setState({
        selectedFile : null
      });
    }
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target.result;
        this.setState({
          selectedFileName: file.name,
          selectedFile : fileContent
        });

      };
      reader.onerror = (event) => {
        console.log("Error");
      };
      reader.readAsDataURL(file);
    }
  };

  handleNext = () => {
    const prevActiveStep = this.state.activeStep;
    const nextStep = prevActiveStep + 1;
    if (nextStep === this.totalSteps) {

      this.send();
    } else {
      this.setState({ activeStep: nextStep });
    }
  };

  handleBack = () => {
    const prevActiveStep = this.state.activeStep;
    this.setState({
      activeStep: prevActiveStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      saveStatus: "",
    });
  };
  removeMarker  = (day, key) => {
    let {maps} = this.state;
    let dayMap = maps[day === 'today' ? 0 : 1];
    let {shapesGeoJSON} = dayMap;

    shapesGeoJSON.features =  shapesGeoJSON.features.filter(feature => feature.geometry.type !== 'Point' || feature.properties.key !== key);
    dayMap.shapesGeoJSON = shapesGeoJSON;
    dayMap.mapKey = (dayMap.mapKey + 1) % 1000;
    maps[day === 'today' ? 0 : 1] = dayMap;
    this.setState({maps});
  }
  rotateMarker  = (day, key) => {
    let {maps} = this.state;
    let dayMap = maps[day === 'today' ? 0 : 1];
    let {shapesGeoJSON} = dayMap;
    let pointFeature = shapesGeoJSON.features.find(feature => feature.properties.key === key);
    pointFeature.properties.angle = pointFeature.properties.angle + 45;
    dayMap.shapesGeoJSON = shapesGeoJSON;
    dayMap.mapKey = (dayMap.mapKey + 1) % 1000;
    maps[day === 'today' ? 0 : 1] = dayMap;
    this.setState({maps});
  }
  addIconToGeoJson = (day, iconKey) => {
    let {maps} = this.state;
    let dayMap = maps[day === 'today' ? 0 : 1];
    let {shapesGeoJSON} = dayMap;

    shapesGeoJSON.features.push(getPointFeature(dayMap.mapKey, iconKey));
    dayMap.shapesGeoJSON = shapesGeoJSON;
    dayMap.mapKey = (dayMap.mapKey + 1) % 1000;
    maps[day === 'today' ? 0 : 1] = dayMap;
    this.setState({maps});
  }
  setCoordinatesToIconPoint = (day, key , {lat, lng}) => {
    let {maps} = this.state;
    let dayMap = maps[day === 'today' ? 0 : 1];
    let {shapesGeoJSON} = dayMap;
    let pointFeature = shapesGeoJSON.features.find(feature => feature.properties.key === key);
    pointFeature.geometry.coordinates = [lng,lat];
    dayMap.shapesGeoJSON = shapesGeoJSON;
    dayMap.mapKey = (dayMap.mapKey + 1) % 1000;
    maps[day === 'today' ? 0 : 1] = dayMap;
    this.setState({maps});
}
  getStepContentComponent = (stepIndex) => {
    let commonsProps = {
      viewMode: this.state.viewMode,
      stepLabel: this.steps[stepIndex].label,
      totalSteps: this.totalSteps,
      activeStep: stepIndex,
      reactSwal: ReactSwal,
      formData: this.state.formJsonData,
      maps: this.state.maps,
      disableBackButton: !stepIndex,
      textNextButton: "Successivo",
      onFormChange: this.handleFormChange,
      onIconSelected: this.addIconToGeoJson,
      onDragEnd: this.setCoordinatesToIconPoint,
      onRightClick: this.removeMarker,
      onDoppioClick: this.rotateMarker,
      updateWeatherIconOnMap: this.updateWeatherIconOnMap,
      handleSaveImg: this.setImgOnJsonData,
      handleNext: this.handleNext,
      handleBack: this.handleBack,
      published: this.state.published,
      map: this.state.maps[0],
      zones: this.state.maps[0].zones,
      handleDropzoneChangeStatus: this.handleDropzoneChangeStatus,
      uploadedFile: this.state.uploadedFile,
      handleSelectGeoJSON: this.handleSelectGeoJSON,
      selectedGeoJSON: this.state.selectedGeoJSON,
      selectOptions: [
        { value: "", label: "Seleziona" },
        { value: { ...zonesGeoJson8 }, label: "GeoJson 8 Zone" },
      ],
    };
    let comp = this.steps[stepIndex].component(commonsProps);
    return comp;
  };

  render() {
    if (this.state.allowCreationToday === undefined) {
      return <></>;
    } else {
      let downloadLink = !!this.state.id
          ? properties.url.bulletinDownloadFile + "/" + this.state.id + "/pdf"
          : null;
      return (
          <div
              className="generic-page container-fluid data-page"
              style={{ paddingTop: "4rem" }}
              /*           style={{ marginInline: "5vw" }}
               */        >
            <div>
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {this.steps.map((step) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
              </Stepper>
              <div>
                {this.state.activeStep === this.totalSteps ? (
                    <>
                      <div className="mt-2 row justify-content-center">
                        <h3>
                          {this.state.saveStatus}
                          <br />
                          <a href={downloadLink}>Scarica file</a>
                        </h3>
                      </div>
                      <BackButton
                          variant="contained"
                          textbutton="Torna alla prima pagina"
                          onClick={this.handleReset}
                      />
                    </>
                ) : (
                    <div>
                      {!!this.state.maps &&
                      !!this.state.formJsonData &&
                      this.getStepContentComponent(this.state.activeStep)}
                    </div>
                )}
              </div>
            </div>
          </div>
      );
    }
  }
}

export default withRouter(ForecastEditorMultiPage)
