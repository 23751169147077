import React, {Component} from 'react';
import {getWindComboDataChart} from '#/lib/WindComboClient';
import NumberUtils from '#/lib/NumberUtils'
import {Polar} from 'react-chartjs-2';
import DownloadContainer from "#/commons/components/DownloadContainer"


const colorByLabel = {
	"occurrences": {
		transparent : 'rgba(14,167,181,0.2)',
		opaque: 'rgba(14,167,181,1)'
	},
 	"frequency":{
		transparent : 'rgba(12,69,125,0.2)',
		opaque: 'rgba(12,69,125,1)'
	},
 	"avgIntensity":{
		transparent : 'rgba(232,112,42,0.2)',
		opaque: 'rgba(232,112,42,1)'
	}

}

export default class WindComboChartComponent extends Component {


	

    constructor(props){
        super(props);
        this.state = {
			emptyData : {},
			occurencesData : {},
			frequenciesData : {},
			intensitiesData : {}
		}
	}
	
	componentDidMount() {

		let params = {
			code: this.props.stationCode,
			type: this.props.comboType,
			start: this.props.start,
			end: this.props.end
		}
		getWindComboDataChart(
			(data) => {
				
				let labels = this.extractLabels(data);
				let occurencesData = this.extractDataByFieldName(data, labels, "occurrences");
				let frequenciesData = this.extractDataByFieldName(data, labels, "frequency");
				let intensitiesData = this.extractDataByFieldName(data, labels, "avgIntensity");
				let emptyData = this.getEmptyDataset(labels);


				this.setState({
					emptyData : emptyData,
					occurencesData : occurencesData,
					frequenciesData : frequenciesData,
					intensitiesData : intensitiesData,
				})
			},
			(data) => {console.log(data)},
			params
		)

	}

	extractDataByFieldName(data, labels, fieldName){
		let result = {};
		let values = [];
		labels.forEach((value)=>{
			values.push(NumberUtils.round(data[value][fieldName]));
		})
		result.labels = labels;
		result.datasets = [{
			label: fieldName,
			backgroundColor: colorByLabel[fieldName].transparent,
			borderColor: colorByLabel[fieldName].opaque,
			pointBackgroundColor: colorByLabel[fieldName].opaque,
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: colorByLabel[fieldName].opaque,
			data: values
		  }];

		  return result;
	}
	getEmptyDataset(labels){
		let result = {};
		result.labels = labels;
		return result;
	}

	extractLabels(data){
		return Object.keys(data);
	}
   

    render() {
        return (
			<DownloadContainer 
				imageName={"VentoCombinato"}
				disabled={false}
			>
            <div className="row">
				<div className="col-4 windComboChart">
					<h3 className="text-center">Occorrenze</h3>

	{/* 				<Radar 
						data={this.state.emptyData}
						options={{
							scale: {
								ticks: {
								   display: false,
								   maxTicksLimit: 3
								}
							 },
							legend: {
								display: false,
							}
						}}
						width={100} height={100}
					></Radar> */}
 					
 					<Polar
						data={this.state.occurencesData}
						options={{
							startAngle: (-1 * 0.5625 * Math.PI ),
							legend: {
								display: false,
							}
						}}
						width={100} height={100}
					></Polar>
				</div>
				<div className="col-4 windComboChart">
					<h3 className="text-center">Frequenza %</h3>
					<Polar
						data={this.state.frequenciesData}
						options={{
							startAngle: (-1 * 0.5625 * Math.PI ),
							legend: {
								display: false,
							}
						}}
						width={100} height={100}
					></Polar>
				</div>
				<div className="col-4 windComboChart">
					<h3 className="text-center">Intensità media</h3>
					<Polar
						data={this.state.intensitiesData}
						options={{
							startAngle: (-1 * 0.5625 * Math.PI ),
							legend: {
								display: false,
							}
						}}
						width={100} height={100}
					></Polar>
				</div>
			</div>
			
			</DownloadContainer>
        )
    }

}


