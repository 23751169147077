import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';


export let getWindComboDataChart = (okCallback, errCallback, params) => {

	let url = new URL(properties.url.windComboAggregated);	
	let fetch_options = {
		headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
		method: 'POST',
		body: JSON.stringify(params)
	}
	APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);

	

};

