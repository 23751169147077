import React from 'react';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";
import DateUtils from "#/lib/DateUtils";
import {FaFileCsv, FaFilePdf} from "react-icons/fa";

import {getUnitByCategory, MEASUREMENT_CATEGORY, MEASUREMENT_CATEGORY_ICONS} from "#/lib/MeasurementCategory"
import {MeasurementAggregationDisplayValues} from "#/lib/MeasurementAggregation"
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Tooltip from '@material-ui/core/Tooltip';

import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import PuntualDetailsTable from "#/commons/table/PuntualDetailsTable"
import IntervalStatsTable from "#/commons/table/IntervalStatsTable"
import {TiWarning} from "react-icons/ti";
import _ from 'lodash';

import ExportButton from '#/commons/components/forms/ExportButton';
import {PdfModal} from "#/commons/table/PdfModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import {BUTTON_PROGRESS_STYLE} from "#/backoffice/bim/utils/BIMUtils";

const colors = {
	VALID : "rgba(16,187,34,0.8)",
	SUSPECT: "rgba(234,129,49,0.8)",
	NOT_VALID: "rgba(241,46,2,0.8)",
	NOT_VALIDATED: "rgba(239,221,61,0.8)",
	RECONSTRUCTED: "rgba(126,197,219,0.8)",
	MISSING: "#ff00008a",
	NONE: "rgb(255,255,255)"
}

function ValidationIcons (props) {

	let isFrontoffice =  window.location.href.includes("frontoffice")

	if(!!props.cell._cell.row.data.measurementsWithValidations[props.sensorCode]){

		const {value, v1, v2, v3, rTs} = props.cell._cell.row.data.measurementsWithValidations[props.sensorCode];


		if(v3==="MISSING"){
			props.cell.getElement().style.backgroundColor = "#ff00008a";
		}

		let tooltip = v3==="MISSING" ? "Dato Mancante" : props.category==="P" ? "Ref. Time : "+ DateUtils.epochToDateMinuteResolution(rTs) : "";
		return (
			<>
				<Tooltip title={tooltip} arrow>
					<div>
						<span>{v3==="MISSING" || (!value && value!==0) ? "ND" : value.toFixed(2)}</span>
						{!isFrontoffice && <span style={{float: 'right'}}>
							<LooksOneIcon style={{color: v1 === 'VALID' ? "green" : "red"}} />
							<LooksTwoIcon style={{color: v2 === 'SUSPECT' ? "red" : "green"}}/>
							<Looks3Icon style={{color: colors[v3]}}/>
						</span>}
					</div>
				</Tooltip>

			</>
		)
	}
	else {
		return <></>
	}
}
const calculateFlow = (value, scales) => {
	if (!!scales) {
		const scale = scales.find(scale => value >= scale.thresholdStart && value < scale.thresholdEnd);
		if (!!scale) {
			const { a, b, c, e } = scale;
			return Math.round(((a * Math.pow(value + e, b) + c) + Number.EPSILON) * 100) / 100;
		}
	}
	return 'nd';
}
const OutflowCell = (props) => {

	if(!!props.cell._cell.row.data.measurementsWithValidations[props.sensorCode]){

		const {value} = props.cell._cell.row.data.measurementsWithValidations[props.sensorCode];
		const {sensorScales} = props;
		let outflow = calculateFlow(value, sensorScales);

		let tooltip = !sensorScales ? "Scale di deflusso mancanti" : "";
		return (
			<>
				<Tooltip title={tooltip} arrow>
					<div>
						<span>{outflow}</span>
					</div>
				</Tooltip>

			</>
		)
	}
	else {
		return <></>
	}
}

const AggregatedCell = ({cell, sensorCode, aggregation}) => {

	if (!!cell.getElement().style) {
		cell.getElement().style.cursor = "help";
	}
	let value = cell.getRow()._row.data.measurements[sensorCode][aggregation];
	if(!value && value!==0){
		cell.getElement().style.backgroundColor = "#ff00008a";
		return <div><span>ND</span></div>;
	}
	else {
		if(!!cell.getRow()._row.data.measurements[sensorCode].stats && !!cell.getRow()._row.data.measurements[sensorCode].stats.suspect){
			return <div><span>{value.toFixed(2)}</span><div className="float-right"><TiWarning style={{fontSize: 'x-large', color: 'DarkOrange'}}/></div></div>
		}
		else{
			return <div><span>{value.toFixed(2)}</span></div>;
		}
	}


}

const MeasureHeader = ({aggregation, sensorCode, sensorCategory, stationNameBySensorCode, suspect}) => {
	let aggr = "";
	if(!!aggregation){
		aggr = " - "+aggregation
	}
	let stationLabel = sensorCode;
	if(!!stationNameBySensorCode && !!stationNameBySensorCode[sensorCode]){
		stationLabel = stationNameBySensorCode[sensorCode];
	}
	let suspectItem = !!suspect && <span style={{color:"DarkOrange", textDecoration: "underline"}} className="ml-4">Dati Sospetti <TiWarning style={{fontSize: 'x-large', color: 'DarkOrange'}}/></span>;

	return (
		<>
			{MEASUREMENT_CATEGORY_ICONS[sensorCategory]}
			<span>{sensorCategory+"  ("+stationLabel+")"+aggr}</span>
			{suspectItem}
		</>
	)
};
const OutflowHeader =  ({aggregation, sensorCode, sensorCategory, stationNameBySensorCode, suspect}) => {
	let aggr = "";
	if(!!aggregation){
		aggr = " - "+aggregation
	}
	let stationLabel = sensorCode;
	if(!!stationNameBySensorCode && !!stationNameBySensorCode[sensorCode]){
		stationLabel = stationNameBySensorCode[sensorCode];
	}
	return (
		<>
			{MEASUREMENT_CATEGORY_ICONS[sensorCategory]}
			<span>{"Portata  ("+stationLabel+")"+aggr}</span>
		</>
	)
};
const getOutflowHeaderString = (sensorCode, stationNameBySensorCode) => {
	let stationLabel = sensorCode;
	if(!!stationNameBySensorCode && !!stationNameBySensorCode[sensorCode]){
		stationLabel = stationNameBySensorCode[sensorCode];
	}
	return `Portata (${stationLabel}) [m^3/s]`;
};
const getMeasureHeaderString = ({aggregation, sensorCode, sensorCategory, stationNameBySensorCode}) => {
	let aggr = "";
	if(!!aggregation){
		aggr = " - "+aggregation
	}
	let stationLabel = sensorCode;
	if(!!stationNameBySensorCode && !!stationNameBySensorCode[sensorCode]){
		stationLabel = stationNameBySensorCode[sensorCode];
	}
	return `${sensorCategory} (${stationLabel}) [${getUnitByCategory(sensorCategory)}] ${aggr}`;
};


export default class MultiSensorTableComponent extends React.Component {


	constructor(props){

		super(props);
		let totalPages = Math.ceil(this.props.interval.end.diff(this.props.interval.start)/this.props.pageDuration)
		this.state = {
			currentPage : 1,
			totalPages : totalPages,
			data : [],
			tableKey : 1,
			showDetailsModal : false,
			showStatsModal : false,
			showPdfModal : false,
			excludedColumns: [],
			details : {},
			loading: false
		}
	}

	componentDidMount(){
		this.setState({loading:true}, () => {
			this.props.getDataByInterval(
				this.props.interval,
				this.props.measures,
				(data)=>{
					console.log(data)
					this.setState({
						data : data.sort((a, b) => a.timestamp - b.timestamp),
						tableKey: (this.state.tableKey + 1) % 1000,
						loading: false
					})
				}
			)
		})

	}

	openDetailsModal(sensorCode, start, end, category, intervalString, stats){
		this.setState({
			showDetailsModal:true,
			details : {
				start,
				end,
				sensorCode,
				category,
				intervalString,
				stats
			}
		})
	}

	openStatsModal(sensorCode, start, end, category, intervalString, stats){
		this.setState({
			showStatsModal:true,
			details : {
				start,
				end,
				sensorCode,
				category,
				intervalString,
				stats
			}
		})
	}

	onCloseDetailsModal(){
		this.setState({showDetailsModal:false, details : {}})
	}

	onCloseStatsModal(){
		this.setState({showStatsModal:false, details : {}})
	}

	onClosePdfModal(){
		this.setState({showPdfModal:false})
	}


	getTabulatorOptions(){
		let tabulatorOptions = {
			movableRows: false,
			movableColumns: false,
			height: !!this.props.height? this.props.height :"50vh",
			selectable: false,
			columnMinWidth:200,
			layout:"fitData",
			downloadReady: (fileContents, blob) => {
				return blob
			},
		};
		return tabulatorOptions;
	}

	getTabulatorColumns(){
		let columns = [];
		if(this.props.aggregation !== 'PUNTUAL'){
			columns = columns.concat([
					{
						title: "Data",
						field: "timestamp",
						frozen:true,
						headerSort:true,
						titleDownload: "Data (*)",
						accessorDownload: this.dateAccessor,
						formatter: function (cell) {
							return cell.getValue() > 0
								?
								DateUtils.epochToDateMinuteResolution(cell.getValue())
								: ''
						}
					}
				],
				[
					{
						title: "Aggregazione", frozen:true, headerSort:false, download:false,
						formatter: (cell) => {
							return MeasurementAggregationDisplayValues[this.props.aggregation]
						}
					}

				]);
			this.props.measures.forEach(m=>{

				let cellContextMenu = [
					{
						label:"Statistiche",
						action:(e, cell) => {
							let data = cell.getRow()._row.data.measurements[m.sensorCode];
							let intervalString = cell.getRow()._row.data.timestamp;
							this.openStatsModal(m.sensorCode, data.start, data.end, data.category, intervalString, data.stats)
						}
					},
					{
						label:"Dettagli",
						action:(e, cell) => {
							let data = cell.getRow()._row.data.measurements[m.sensorCode];
							let intervalString = cell.getRow()._row.data.timestamp;
							this.openDetailsModal(m.sensorCode, data.start, data.end, data.category, intervalString, data.stats)
						}
					},
				]

				if(m.sensorCategory===MEASUREMENT_CATEGORY.P){
					let titleDownload = getMeasureHeaderString({...m, aggregation: 'CUM', stationNameBySensorCode: this.props.stationNameBySensorCode});
					columns = columns.concat([
						{
							titleFormatter:  reactFormatter(<MeasureHeader {...m} aggregation="CUM" stationNameBySensorCode = {this.props.stationNameBySensorCode}/>) ,
							formatter: reactFormatter(<AggregatedCell sensorCode={m.sensorCode} aggregation={"sum"} /> ),
							headerSort:false,
							contextMenu:!!m.hideContextMenu ? [] : cellContextMenu,
							field: titleDownload,
							titleDownload,
							download: !this.state.excludedColumns.includes(titleDownload),
							accessorDownload: this.aggregatedAccessor,
							accessorDownloadParams: {aggregation: "sum", sensorCode: m.sensorCode},
							
						}

					]);
				}
				else{

					["avg","min","max"].forEach(aggr=>{
						let titleDownload = getMeasureHeaderString({...m, aggregation: aggr, stationNameBySensorCode: this.props.stationNameBySensorCode});
						columns = columns.concat([
							{
								titleFormatter:  reactFormatter(<MeasureHeader {...m} aggregation={aggr} stationNameBySensorCode={this.props.stationNameBySensorCode}/>) ,
								formatter: reactFormatter(<AggregatedCell sensorCode={m.sensorCode} aggregation={aggr} /> ),
								headerSort:false,
								contextMenu:cellContextMenu,
								field: titleDownload,
								titleDownload,
								download: !this.state.excludedColumns.includes(titleDownload),
								accessorDownload: this.aggregatedAccessor,
								accessorDownloadParams: {aggregation: aggr, sensorCode: m.sensorCode},
							}

						]);
					})
				}
			})
		}
		else {
			columns = columns.concat([
				{
					title: "Data", field: "timestamp", frozen:true, headerSort:true,
					accessorDownload: this.dateAccessor,
					formatter: function (cell) {
						return cell.getValue() > 0
							?
							DateUtils.epochToDateMinuteResolution(cell.getValue())
							: ''
					}
				}

			]);
			this.props.measures.forEach(m=>{
				let titleDownload = getMeasureHeaderString({...m, stationNameBySensorCode: this.props.stationNameBySensorCode});
				columns = columns.concat(
					[
						{
							titleFormatter:  reactFormatter(<MeasureHeader {...m} stationNameBySensorCode = {this.props.stationNameBySensorCode}/>),
							field: titleDownload,
							headerSort:false,
							accessorDownload: this.validationAccessor,
							accessorDownloadParams: {sensorCode: m.sensorCode},
							titleDownload,
							download: !this.state.excludedColumns.includes(titleDownload),
							formatter: reactFormatter(<ValidationIcons category = {m.sensorCategory} sensorCode = {m.sensorCode}/> ),

						},

					]);
				if (m.sensorCategory === 'I'){
					let outflowTitle = getOutflowHeaderString(m.sensorCode, this.props.stationNameBySensorCode);
					columns = columns.concat(
						[
							{
								titleFormatter:  reactFormatter(<OutflowHeader {...m} stationNameBySensorCode = {this.props.stationNameBySensorCode}/>),
								field: outflowTitle,
								headerSort: false,
								accessorDownload: this.outflowAccessor,
								accessorDownloadParams: {sensorCode: m.sensorCode, sensorScales: this.props.outflowScales && this.props.outflowScales[m.sensorCode]},
								titleDownload: outflowTitle,
								download: !this.state.excludedColumns.includes(titleDownload),
								formatter: reactFormatter(<OutflowCell sensorScales={this.props.outflowScales && this.props.outflowScales[m.sensorCode]} sensorCode = {m.sensorCode}/> ),

							},

						]);
				}
			})
		}
		return columns;
	}
	dateAccessor = (value, data, type, params, column) => DateUtils.epochToDateMinuteResolution(value);
	intervalAccessor = (value, data, type, params, column) =>  DateUtils.epochToDateMinuteResolution(value);

	validationAccessor = (value, data, type, {sensorCode}, column) => {
		if(!!data.measurementsWithValidations[sensorCode]){
			const {value, v3} = data.measurementsWithValidations[sensorCode];
			return v3==="MISSING" || (!value && value!==0) ? "ND" : value.toFixed(2)
		} else return ""
	};

	outflowAccessor = (value, data, type, {sensorCode, sensorScales}, column) => {
		const {value: val} = data.measurementsWithValidations[sensorCode];
		if(val){
			return calculateFlow(val, sensorScales);
		} else return ""
	};

	aggregatedAccessor = (value, data, type, {sensorCode, aggregation}, column) => {
		let val = data.measurements[sensorCode][aggregation];
		if (aggregation === 'sum'){
		}
		return !!val || val ===0 || val === 0.0 ? val.toFixed(2) : 'ND';
	};
	openPfdModal = () => {
		this.setState({showPdfModal:true})
	}
	downloadPdf = () => {
		this.tableRef.table.download("pdf", "data.pdf", {
			orientation: "portrait",
			title:`Report misure ${this.props.aggregation !== 'PUNTUAL' ? `Aggregazione: ${MeasurementAggregationDisplayValues[this.props.aggregation]}` : 'puntuali'}\nDal ${DateUtils.epochToDateMinuteResolution(this.props.interval.start)} al ${DateUtils.epochToDateMinuteResolution(this.props.interval.end)}`,
			autoTable: doc => {
				doc.autoTableSetDefaults( {
					addPageContent: ( data ) => {
						let footerStr = String(doc.internal.getNumberOfPages());
						doc.setFontSize( 10 );
						if (this.props.aggregation !== 'PUNTUAL'){
							doc.text( "(*) La data riportata rappresenta la fine dell'intervallo di aggregazione", data.settings.margin.left, doc.internal.pageSize.height - 30 );
						}
						doc.text( footerStr, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10 );
						doc.setFontSize( 14 );
					}
				} );
				return {
					styles: {
						fillColor: [214,236,237],
						minCellWidth: 20
					},
					headStyles: {
						/*id: {
							fillColor: 255
						},*/
						textColor: [0,0,0]
					},
					margin: {
						top: 70,
						bottom: 70
					}
				}
			}
		});
	};

	downloadDataCsv = () => {
		this.tableRef.table.download("csv", `data.csv`);
	}

	toggleColumn = event => {
		const {name: columnTitle, checked} = event.target;
		let excludedColumns = _.cloneDeep(this.state.excludedColumns);
		if (checked){
			excludedColumns = excludedColumns.filter(col => col !== columnTitle)
		} else excludedColumns.push(columnTitle);
		this.setState({excludedColumns, tableKey: (this.state.tableKey + 1) % 1000});
	}
	render() {
		return (
			<>
				<div>
					<div className="mt-2">
						<ReactTabulator
							ref={ref => this.tableRef = ref}
							columns={this.getTabulatorColumns()}
							data={this.state.data || []}
							options={this.getTabulatorOptions()}
							key={"table_"+this.state.tableKey}
						/>
					</div>
					<div className="float-left" >
						{this.state.loading &&
						<div className="col-12">
							<CircularProgress size={24} style={BUTTON_PROGRESS_STYLE}/>
						</div>}
						<ExportButton disabled={this.state.loading} label={"Esporta csv"} icon={<FaFileCsv />} downloadHandler={()=>this.downloadDataCsv()}></ExportButton>
						<ExportButton disabled={this.state.loading} label={"Esporta pdf"}  icon={<FaFilePdf/>} downloadHandler={() => this.openPfdModal()}/>
					</div>
				</div>

				<PdfModal
					showPdfModal={this.state.showPdfModal}
					onClosePdfModal={() => this.onClosePdfModal()}
					getTabulatorColumns={() => this.getTabulatorColumns()}
					excludedColumns={this.state.excludedColumns}
					toggleColumn={(e) => this.toggleColumn(e)}
					downloadPdf={() => this.downloadPdf()}/>

				{/*---------------- DETAILS MODAL -----------------------*/}
				<Modal key={'puntual_modal'}
					   style={{
						   display: 'flex',
						   alignItems: 'center',
						   justifyContent: 'center',
						   overflow:'scroll',
					   }}
					   open={this.state.showDetailsModal}
					   onClose={() => this.onCloseDetailsModal()}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "40vw",
							height: "70vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseDetailsModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div className="mt-5 mb-5 ml-5 mr-5">
							<h3>
								<MeasureHeader
									sensorCode={this.state.details.sensorCode}
									sensorCategory={this.state.details.category}
									stationNameBySensorCode = {this.props.stationNameBySensorCode}
									suspect={!!this.state.details && !!this.state.details.stats && this.state.details.stats.suspect}/></h3>
							<h3>{this.state.details.intervalString}</h3>

							<PuntualDetailsTable
								height = {"50vh"}
								interval = {{start: this.state.details.start, end : this.state.details.end }}
								sensorCode = {this.state.details.sensorCode}
								category = {this.state.details.category}
							></PuntualDetailsTable>
						</div>
					</div>
				</Modal>

				{/*---------------- STATS MODAL -----------------------*/}
				<Modal key={'stats_modal'}
					   style={{
						   display: 'flex',
						   alignItems: 'center',
						   justifyContent: 'center',
						   overflow:'scroll',
					   }}
					   open={this.state.showStatsModal}
					   onClose={() => this.onCloseStatsModal()}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "40vw",
							height: "70vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseStatsModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div className="mt-5 mb-5 ml-5 mr-5">
							<h3>
								<span className="mr-2">Statistiche </span>
								<MeasureHeader
									sensorCode={this.state.details.sensorCode}
									sensorCategory={this.state.details.category}
									stationNameBySensorCode = {this.props.stationNameBySensorCode}
									suspect={!!this.state.details && !!this.state.details.stats && this.state.details.stats.suspect}/>
							</h3>
							<h3>{this.state.details.intervalString}</h3>

							<IntervalStatsTable
								height = {"50vh"}
								interval = {{start: this.state.details.start, end : this.state.details.end }}
								sensorCode = {this.state.details.sensorCode}
								stats = {this.state.details.stats}
							></IntervalStatsTable>
						</div>
					</div>
				</Modal>
			</>
		)
	}
}
