import React from 'react';
import ForecastItem from './ForecastItem';
import PaginationComponent from '#/commons/components/PaginationComponent';
import BulletinClient from '#/lib/BulletinClient';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";
import { CircularProgress } from '@material-ui/core';

export default class ForecastPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDateFilter: null,
			endDateFilter: null,
			allowCreationToday: false,
			pageKey: 0,
			isLoading: false
		}
	}

	componentDidMount(){
		if(!!this.props.canEdit){
			BulletinClient.isCreationAllowedToday(
				(data)=>{
					this.setState({
						allowCreationToday : true
					})
				},
				(error)=>{
					this.setState({
						allowCreationToday : false
					})
				},
				this.props.bulletinTypeName
			);
		}
	}
	forcePageReloading = () => {this.setState({pageKey: (this.state.pageKey + 1) % 1000 })};
	fromJsonToForecastItem(obj) {
		return <ForecastItem {...obj} key={obj.id} editable={this.props.canEdit} forecastItem={obj} refreshList={() => this.forcePageReloading()}/>;
	}

	getPage = ( successCallback, errorCallback, page) => {
		let params = {
			pageNumber: page,
			pageSize: 5,
			bulletinTypeName: this.props.bulletinTypeName
		}
		this.setState({ isLoading: true });
		BulletinClient.listBulletins(
			(obj) => { 
				this.setState({ isLoading: false });
				successCallback(obj, page);
				setTimeout(() => this.getPage(successCallback, errorCallback, page), 120000);
			},
			(err) => { 
				this.setState({ isLoading: false });
				errorCallback(err);
				setTimeout(() => this.getPage(successCallback, errorCallback, page), 120000);
			},
			params
		)

	}


	render() {
		return (
			<div className="generic-page list-page">

				<h2 className='d-flex justify-content-center align-items-center mb-5'>Previsioni meteo</h2>
				{this.state.isLoading && (
					<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<CircularProgress />
					</div>
				)}
				<PaginationComponent
					key={this.state.pageKey}
					toItemFunction={(obj) => this.fromJsonToForecastItem(obj)}
					getPage={(successCallback, errorCallback, page) => this.getPage(successCallback, errorCallback, page)} />

					{this.props.canEdit ?
					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
						
						<Link to={this.props.createUrl}>
							<Tooltip title="Crea Previsioni Meteo">
								<Fab color="primary"  disabled={!this.state.allowCreationToday}>
									<AddIcon />
								</Fab>
							</Tooltip>
						</Link>
					</div>
				:
				<></>
				}
			</div>
		)
	}
}
