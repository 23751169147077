import React from 'react';

import {withRouter} from "react-router-dom";


import MosipClient from "#/lib/MosipClient";
import MosipMapComponent from "#/commons/map/MosipMapComponent";
import DateUtils from "#/lib/DateUtils";
import {reactFormatter} from "react-tabulator";
import CheckIcon from "@material-ui/icons/Check"
import Fab from "@material-ui/core/Fab";
import moment from "moment";
import _ from "lodash";
import * as turf from "@turf/turf";

import NumberUtils from "#/lib/NumberUtils"
import MosipCurrentReport from "#/backoffice/mosip/components/MosipCurrentReport"
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import chroma from 'chroma-js'
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

require('leaflet.sync')

const livelli = {
	"LEVEL1" : "Livello 1",
	"LEVEL2" : "Livello 2",
	"LEVEL3" : "Livello 3"
}

const colors = {
	"LEVEL1" : "yellow",
	"LEVEL2" : "DarkOrange",
	"LEVEL3" : "red"
}
const Stub = <SkeletonTheme>
	<div className="row">
		<div className="col-6">
			<Skeleton height={"70vh"} duration={8} />
		</div>
		<div className="col-3">

			<Skeleton height={"70vh"} duration={8} />
		</div>
		<div className="col-3">
			<Skeleton height={"70vh"} duration={8} />
		</div>
	</div>
</SkeletonTheme>

const MAUCheckIcon = (props) => props.cell._cell.row.data.mauAlerted ?
	<CheckIcon fontSize="large" htmlColor={colors[props.cell._cell.row.data.mauAlerted]}/> :
	<></>;
const A3CheckIcon = (props) => !props.cell._cell.row.data.isNew ?
	<CheckIcon fontSize="large" htmlColor={"black"}/> :<></>
;

const GoToA3BuilderButton = withRouter(({history})=>
	<Fab variant="extended" color="primary" aria-label="add" onClick={()=>{history.push("/backoffice/mosip/a3_builder");}}>
		Compila A3
		<i className="ml-2 fas fa-chevron-right"></i>
		<i className="fas fa-chevron-right"></i>
	</Fab>
)

export default class MosipTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			municipalitiesGeoJSON : null,
			intervalId: 0,
			alertedMapByIstat : [],
			lastRunThresholdExceedMapByIstat : [],
			lastRunWarningsMapByIstat : [],
			expiringAlertsMapByIstat : [],
			mapKey : 1,
			mosipMapKey: 1,
			polygonsKey : 1,
			showMauMap : false,

			showInfoModal : false,
			isReady: false
		}
	}

	componentDidMount() {
		let intervalId = setInterval(() => this.loadFromApi(null), 60000);
		this.loadFromApi(intervalId);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!!this.map){
			this.map.leafletElement.invalidateSize();
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalId);
	}

	getLastRunWarnings(alertedMapByIstat, lastRunThresholdExceedMapByIstat, municipalities, mauState){
		let lastRunWarningsMapByIstat = {};

		Object.values(lastRunThresholdExceedMapByIstat).forEach(aW => {
			aW.exceededList.forEach( eL => {

					if (eL.municipalityIstatCode in lastRunWarningsMapByIstat){
						let currentLevel = lastRunWarningsMapByIstat[eL.municipalityIstatCode].alertLevel;
						let currentLevelNumber = parseInt(currentLevel.replace("LEVEL",""))
						let newLevel = eL.alertLevel;
						let newLevelNumber =  parseInt(newLevel.replace("LEVEL",""))
						if(newLevelNumber>currentLevelNumber){
							lastRunWarningsMapByIstat[eL.municipalityIstatCode] = {
								timestamp: eL.timestamp,
								municipalityName: aW.municipalityName,
								municipalityIstatCode: eL.municipalityIstatCode,
								alertLevel: eL.alertLevel,
							}
						}
					}
					else{
						lastRunWarningsMapByIstat[eL.municipalityIstatCode] = {
							timestamp: eL.timestamp,
							municipalityName: aW.municipalityName,
							municipalityIstatCode: eL.municipalityIstatCode,
							alertLevel: eL.alertLevel,
						}
					}
				}
			)
		})

		if (!_.isEmpty(mauState)) {
			Object.keys(lastRunWarningsMapByIstat).forEach(istat => {
				let w = lastRunWarningsMapByIstat[istat];
				this.checkIfMAUAlerted(w, municipalities, mauState);
			})
		}

		Object.keys(lastRunWarningsMapByIstat).forEach(istat => {
			let w = lastRunWarningsMapByIstat[istat];

			let currentAlert = alertedMapByIstat[istat]?.alertLevel
			let a3Alerted = currentAlert;
			let isNew = true;
			if(!!currentAlert){
				let currentAlertLevelNumber = parseInt(currentAlert.replace("LEVEL",""))
				let newLevelNumber =  parseInt(w.alertLevel.replace("LEVEL",""))

				if(newLevelNumber > currentAlertLevelNumber){
					isNew=true;
				}
				else {
					isNew = false;
				}

			}
			w.isNew = isNew
			w.a3Alerted = a3Alerted

		})


		return Object.values(lastRunWarningsMapByIstat);
	}

	getExpiringAlerts(alertedMapByIstat, lastRunThresholdExceedMapByIstat, municipalities, mauState){

		let expiringAlertsMapByIstat = [];
		let now = moment().valueOf();
		Object.values(alertedMapByIstat).forEach(alert => {
			if (Math.abs(now - alert.timestamp) > 43200000 - 3600000) {
				// find related warnings
				let children =  [];
				let tst = lastRunThresholdExceedMapByIstat[alert.municipalityIstatCode]
				if (!!tst){
					tst.exceededList.forEach( eL => {
						if (Object.keys(livelli).indexOf(eL.alertLevel) <= Object.keys(livelli).indexOf(alert.alertLevel)){
							children.push(
								{
									timestamp: eL.timestamp,
									municipalityName: "Nuova soglia superata",
									municipalityIstatCode : alert.municipalityIstatCode,
									alertLevel : eL.alertLevel
								}
							)
						}
					})
				}

				expiringAlertsMapByIstat.push(
					{
						expiringTimestamp: alert.timestamp,
						municipalityName: alert.municipalityName,
						municipalityIstatCode: alert.municipalityIstatCode,
						alertLevel: alert.alertLevel,
						_children : children
					}
				)
			}

		})

		if (!_.isEmpty(mauState)) {
			expiringAlertsMapByIstat.forEach(ew => {
				this.checkIfMAUAlerted(ew, municipalities, mauState);
			})
		}

		return expiringAlertsMapByIstat;
	}

	loadFromApi(intervalId) {

		let municipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getAllMunicipalities((data) => { resolve({municipalities:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let last12HAlertedMunicipalitiesPromise = new Promise((resolve, reject) => {
			MosipClient.getMosipLast12HAlertedMunicipalities((data) => { resolve({alerted:data}); }, () => { console.log("ERROR COMUNI"); });
		})

		let lastRunExceededTresholdsPromise = new Promise((resolve, reject) => {
			MosipClient.getMosipExceededTresholdInLastRun((data) => { resolve({lastRun:data.exceeded_thresholds,lastExecutionTimestamp: data.last_execution_timestamp}); }, () => { console.log("ERROR COMUNI NON ALLERTATI"); });
		})

		let lastMAUStatePromise = new Promise((resolve, reject) => {
			MosipClient.getLastMAUState((data) => { resolve({mauState:data}); }, () => { console.log("ERROR COMUNI NON ALLERTATI"); });
		})

		let result = {};
		Promise.all([municipalitiesPromise, last12HAlertedMunicipalitiesPromise, lastRunExceededTresholdsPromise, lastMAUStatePromise])
			.then( data =>{
				Object.assign(result, ...data);
				let {municipalities,alerted,lastRun,lastExecutionTimestamp,mauState} = result;
				let geoJSON = this.buildGeoJSONFromArray(municipalities);

				let alertedMapByIstat = alerted.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});
				let lastRunThresholdExceedMapByIstat = lastRun.reduce((obj, item)=> {obj[item.municipalityIstatCode]=item; return obj}, {});

				let lastRunWarningsMapByIstat = this.getLastRunWarnings({...alertedMapByIstat}, {...lastRunThresholdExceedMapByIstat}, municipalities, mauState);
				let expiringAlertsMapByIstat = this.getExpiringAlerts({...alertedMapByIstat}, {...lastRunThresholdExceedMapByIstat}, municipalities, mauState);


				let newState = {
					municipalitiesGeoJSON : geoJSON,
					mauState,
					alertedMapByIstat : alertedMapByIstat,
					lastRunThresholdExceedMapByIstat : lastRunThresholdExceedMapByIstat,
					lastRunWarningsMapByIstat : lastRunWarningsMapByIstat,
					expiringAlertsMapByIstat : expiringAlertsMapByIstat,
					mapKey: (this.state.mapKey + 1) % 1000,
					mosipMapKey: (this.state.mosipMapKey + 1) % 1000,
					tableKey: (this.state.tableKey + 1) % 1000,
					lastExecutionTimestamp,
					isReady: true
				}
				if (!!intervalId){
					newState.intervalId = intervalId;
				}
				this.setState(newState, () => {
					this.syncMaps();
					this.updateBadges()
				});
			})
	}
	syncMaps = () => {
		if (!!this.lastAllertedMapRef && !!this.lastRunMapRef){
			this.lastAllertedMapRef.leafletElement.sync(this.lastRunMapRef.leafletElement);
			this.lastRunMapRef.leafletElement.sync(this.lastAllertedMapRef.leafletElement);
		}
	}
	checkIfMAUAlerted(warning,municipalities, mauState){

		let istatCode = warning.municipalityIstatCode;
		let municipalityGeometry = JSON.parse(municipalities.find(m => m.istatCode === istatCode).polygonGeojson);
		let mauMaxLevel = null;
		mauState.features.forEach(feature => {
			let isMunicipalityInside = turf.booleanPointInPolygon(turf.centroid(municipalityGeometry).geometry.coordinates ,feature.geometry)

			if (isMunicipalityInside && !!feature.properties.level){
				if (Object.keys(colors).indexOf(feature.properties.level) > Object.keys(colors).indexOf(mauMaxLevel)){
					mauMaxLevel = feature.properties.level;
				}
			}
		});

		warning.mauAlerted = mauMaxLevel;
	}

	buildGeoJSONFromArray(data) {
		let geoJSON = {};
		geoJSON.type = "FeatureCollection";
		geoJSON.name = "Municipalities";
		geoJSON.features = [];

		data.forEach(value => {
			let feature = {};
			feature.type = "Feature";
			feature.geometry = JSON.parse(value.polygonGeojson);
			feature.properties = {
				COMUNE: value.name,
				ISTAT: value.istatCode,
				PROVINCIA: value.province,
			}
			geoJSON.features.push(feature);

		})
		return geoJSON;
	}

	polygonStyle(feature, latlng){
		let istatCode = feature.properties.ISTAT;
		const isThisSelected = this.state.selectedIstatCode === feature.properties.ISTAT;
		const hasBeenAlerted = !!this.state.alertedMapByIstat[istatCode];

		let style = {
			fillColor: hasBeenAlerted ? colors[this.state.alertedMapByIstat[istatCode].alertLevel] : "#fff0",
			weight: isThisSelected ? 2 : (hasBeenAlerted ? 1 : 0.2),
			opacity: 1,
			color: isThisSelected ? 'black' : 'grey',
			fillOpacity: hasBeenAlerted ? 1 : 0
		};
		return style;
	}

	getPopupContent(properties){
		let istatCode = properties.ISTAT;
		let content = '';
		let municipalityData = '';
		municipalityData += `COMUNE : ${properties.COMUNE}</br>`;
		municipalityData += `PROVINCIA : ${properties.PROVINCIA}</br>`;
		municipalityData += `ISTAT : ${properties.ISTAT}</br>`;

		if(!!this.state.alertedMapByIstat[istatCode]){
			let municipalityAlert = this.state.alertedMapByIstat[istatCode];
			let color = colors[municipalityAlert.alertLevel];
			let time = DateUtils.epochToDateMinuteResolution(municipalityAlert.timestamp)
			municipalityData += `PROTOCOLLO : ${municipalityAlert.protocol}</br>`;
			municipalityData += `TIME : ${time}</br>`;
			municipalityData += `LIVELLO ALLERTA : ${livelli[municipalityAlert.alertLevel]}</br>`;
			content += `<div style='color:${color}'>${municipalityData}</div></br>`;
		}
		else{
			content += `<div>${municipalityData}</div></br>`;
			content += "Nessuna Allerta";
		}
		return `<div style='font-weight: 600'>${content}</div>`;
	}

	/**
	 * Not alerted municipalities' map
	 * */

	polygonStyleAll(feature, latlng){
		let istatCode = feature.properties.ISTAT;
		const isThisSelected = this.state.selectedIstatCode === feature.properties.ISTAT;
		const hasExceedings = !!this.state.lastRunThresholdExceedMapByIstat[istatCode];

		let style = {
			fillColor: hasExceedings ? colors[this.state.lastRunThresholdExceedMapByIstat[istatCode].maxAlertLevel] : "#fff0",
			weight: isThisSelected ? 2 : (hasExceedings ? 1 : 0.2),
			opacity: 1,
			color: isThisSelected ? 'black' : 'grey',
			fillOpacity: hasExceedings ? 1 : 0
		};
		return style;
	}

	getPopupContentAll(properties){
		let istatCode = properties.ISTAT;
		let content = '';
		let municipalityData = '';
		municipalityData += `COMUNE : ${properties.COMUNE}</br>`;
		municipalityData += `PROVINCIA : ${properties.PROVINCIA}</br>`;
		municipalityData += `ISTAT : ${properties.ISTAT}</br>`;

		if(!!this.state.lastRunThresholdExceedMapByIstat[istatCode]){
			let municipalityAlert = this.state.lastRunThresholdExceedMapByIstat[istatCode];
			let color = colors[municipalityAlert.maxAlertLevel];
			content += `<div style='color:${color}'>${municipalityData}</div></br>`;
			content += "Soglie Superate :</br>";
			municipalityAlert.exceededList.forEach(t=>{
				let thresholdData = '';
				thresholdData += `Data : ${DateUtils.epochToDateMinuteResolution(t.timestamp)}</br>`;
				thresholdData += `Codice Rischio : ${t.riskCode}</br>`;
				thresholdData += `Timescale : ${t.measureType}</br>`;
				thresholdData += `Media : ${NumberUtils.round(t.avg, 2)}</br>`;
				thresholdData += `Massimo : ${NumberUtils.round(t.max, 2)}</br>`;
				thresholdData += `Dev.std. : ${NumberUtils.round(t.std, 2)}</br>`;

				let tColor = colors[t.alertLevel];
				content += `<div style='color:${tColor}'>${thresholdData}</div></br>`;
			})
		}
		else{
			content += `<div>${municipalityData}</div></br>`;
			content += "Nessuna Allerta";

		}
		return `<div style='font-weight: 600'>${content}</div>`;
	}
	/**
	 * New warnings' table
	 * */
	getWarningsColumns() {
		let columns = [];
		columns = columns.concat([
			/*{ title: "Codice Istat", field: "municipalityIstatCode", headerSort: true, headerFilter: true },*/
			{ title: "Comune", field: "municipalityName", headerSort: true },
			{
				title: "Data di rilevazione</br>(tempo trascorso)", field: "timestamp", formatter: function (cell) {

					return cell.getValue() > 0
						?
						(DateUtils.epochToDateMinuteResolution(cell.getValue()) + " (" + DateUtils.diffFromNow(cell.getValue()) + ")")
						: ''}
			},
			{
				title: "Livello", field: "alertLevel", width: 120, formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return livelli[cell.getValue()];
				}
			},

			{ title: "Allertato</br>dal mau <sup>(*)</sup>", field: "mauAlerted", width: 120 , hozAlign: "center",
				headerSort: false, 	vertAlign: "middle", formatter : reactFormatter(<MAUCheckIcon/>)
			},
			{ title: "Allertato</br>da A3", field: "isNew", width: 120 , hozAlign: "center",
				vertAlign: "middle", formatter : reactFormatter(<A3CheckIcon/>)}

		]);
		return columns;
	}
	getTabulatorOptions() {
		return {
			data: [],
			height: "40vh",
			selectable:false,
			rowClick:  (e, row) => {
				console.log("ISTAT CODE", row._row.data.municipalityIstatCode)
				this.setState({
					selectedIstatCode : row._row.data.municipalityIstatCode,
					polygonsKey: (this.state.polygonsKey + 1) % 1000

				})
			},
			rowFormatter:(row)=>this.rowFormatter(row),
		}
	}

	rowFormatter(row){
		let data = row.getData();
		let alertLevel = parseInt(data.alertLevel.replace("LEVEL", ""));
		let mauAlerted = !!data.mauAlerted ? parseInt(data.mauAlerted.replace("LEVEL", "")) : 0;
		if (data.isNew && (alertLevel>1 || (alertLevel===1 && mauAlerted===0)) ) {
			row.getElement().style.backgroundColor = "#87CEEB";
		}
	}

	updateBadges(){
		let badges = [];
		this.state.lastRunWarningsMapByIstat.forEach(data => {
			let alertLevel = parseInt(data.alertLevel.replace("LEVEL", ""));
			let mauAlerted = !!data.mauAlerted ? parseInt(data.mauAlerted.replace("LEVEL", "")) : 0;
			if (data.isNew && (alertLevel > 1 || (alertLevel === 1 && mauAlerted === 0))) {
				badges.push(colors[data.alertLevel])
			}
		})
		if (badges.length > 0){
			this.props.showNotifications(badges);
		}
		this.props.ready();
		this.props.lastUpdate(DateUtils.epochToDateMinuteResolution(moment().valueOf()));
	}

	/**
	 * Expiring warnings' table
	 */

	getExpiringAlertsColumns() {
		let columns = [];
		columns = columns.concat([
			/*{ title: "Codice Istat", field: "municipalityIstatCode", headerSort: true, headerFilter: true },*/
			{ title: "Comune", field: "municipalityName", headerSort: true },
			{
				title: "Data di rilevazione</br>(tempo trascorso)", field: "timestamp", formatter: function (cell) {

					return cell.getValue() > 0
						?
						(DateUtils.epochToDateMinuteResolution(cell.getValue()) + " (" + DateUtils.diffFromNow(cell.getValue()) + ")")
						: ''}
			},
			{
				title: "Tempo rimanente", field: "expiringTimestamp",width : 180, formatter: function (cell) {

					return cell.getValue() > 0
						?
						DateUtils.diffFromNow(cell.getValue() + 43200000)
						:
						''
				}
			},
			{
				title: "Livello", field: "alertLevel", width: 150, formatter: function (cell) {
					cell.getElement().style.backgroundColor = colors[cell.getValue()]
					return livelli[cell.getValue()];
				}
			},

			{ title: "Allertato</br>dal mau", field: "mauAlerted", width: 120 , hozAlign: "center",
				vertAlign: "middle", formatter : reactFormatter(<MAUCheckIcon/>)}
		]);
		return columns;
	}

	getExpiringAlertsTabulatorOptions() {
		return {
			data: [],
			height: "40vh",
			selectable:false,
			dataTree:true,
			rowClick:  (e, row) => {
				this.setState({
					selectedIstatCode : row._row.data.municipalityIstatCode,
					polygonsKey: (this.state.polygonsKey + 1) % 1000

				})
			},
		}
	}

	/**
	 * Additional layer showing mau alerts*/
	onEachAdditionalPolygon(feature, layer) {

	}


	additionalPolygonStyle(feature, latlng) {
		let zoneNumber = feature.properties.ZONE;
		if(!!this.state.mauState && !_.isEmpty(this.state.mauState)){
			let mauColor = colors[this.state.mauState.features.find(f => f.properties.ZONE === zoneNumber).properties.level];
			return {
				fillColor: !!mauColor ? mauColor : 'green',
				weight: 0.4,
				opacity: 1,
				color: mauColor,
				fillOpacity: 0.2
			};
		}
		else{
			return {
				fillColor: "green",
				weight: 0.4,
				opacity: 1,
				color: "green",
				fillOpacity: 0.2
			};
		}
	}

	getAdditionalMapProps = () => {
		return {
			additionalGeoJSON : this.state.mauState,
			additionalPolygonStyle : (feature, latlng) => this.additionalPolygonStyle(feature, latlng),
			onEachAdditionalPolygon : this.onEachAdditionalPolygon,
			showMauMap : this.state.showMauMap
		}
	}

	onLastRunItemClick(municipalityIstatCode){
		this.setState({
			selectedIstatCode : municipalityIstatCode,
			polygonsKey: (this.state.polygonsKey + 1) % 1000

		})
	}

	onExpiringAlertItemClick(municipalityIstatCode){
		this.setState({
			selectedIstatCode : municipalityIstatCode,
			polygonsKey: (this.state.polygonsKey + 1) % 1000

		})
	}

	// Info modal
	openInfoModal(selectedFeature){
		this.setState({
			showInfoModal : true,
			selectedFeature,
			selectedIstatCode : selectedFeature.properties.ISTAT
		})
	}

	closeInfoModal(){
		this.setState({
			showInfoModal : false,
			selectedFeature : null,
			selectedIstatCode : null,
			mapKey : (this.state.mapKey + 1) % 1000
		})
	}

	getInfoModalContent(){
		let modalContent = [];
		let exceededListContent = [];
		let alertedListContent = [];
		if (!!this.state.selectedFeature) {
			let {selectedFeature: {properties}} = this.state;
			let istatCode = properties.ISTAT;
			let color = '#fff';
			if (!!this.state.lastRunThresholdExceedMapByIstat[istatCode]){
				let municipalityAlert = this.state.lastRunThresholdExceedMapByIstat[istatCode];
				let color = colors[municipalityAlert.maxAlertLevel];
				exceededListContent.push(<h2 style={{textAlign: 'center', color: color}}> Soglie superate </h2>)
				exceededListContent.push(
					<table className="textAlignCenter" style={{minWidth: '100%'}}>
						<tr>
							<th style={{paddingRight: '10px'}}>Data</th>
							<th style={{paddingRight: '10px'}}>Codice rischio</th>
							<th style={{paddingRight: '10px'}}>Timescale</th>
							<th style={{paddingRight: '10px'}}>Media</th>
							<th style={{paddingRight: '10px'}}>Massimo</th>
							<th style={{paddingRight: '10px'}}>Dev.std.</th>
						</tr>

						{municipalityAlert.exceededList.map(t => {
								let tColor = chroma(colors[t.alertLevel]).alpha(0.5);

								return (
									<tr>
										<td style={{backgroundColor: tColor}}>{DateUtils.epochToDateMinuteResolution(t.timestamp)}</td>
										<td style={{backgroundColor: tColor}}>{t.riskCode}</td>
										<td style={{backgroundColor: tColor}}>{t.measureType}</td>
										<td style={{backgroundColor: tColor}}>{NumberUtils.round(t.avg, 2)}</td>
										<td style={{backgroundColor: tColor}}>{NumberUtils.round(t.max, 2)}</td>
										<td style={{backgroundColor: tColor}}>{NumberUtils.round(t.std, 2)}</td>
									</tr>
								)
							}
						)
						}
					</table>
				)
			}

			if (!!this.state.alertedMapByIstat[istatCode]) {
				let municipalityAlert = this.state.alertedMapByIstat[istatCode];
				let mainColor = colors[municipalityAlert.alertLevel];
				let color = chroma(mainColor).alpha(0.5);
				let time = DateUtils.epochToDateMinuteResolution(municipalityAlert.timestamp);
				alertedListContent.push(<h2 style={{textAlign: 'center', color: mainColor}}> Ultime allerte </h2>)
				alertedListContent.push(
					<table className="textAlignCenter" style={{minWidth: '100%'}}>
						<tr>
							<th style={{paddingRight: '10px'}}>Protocollo</th>
							<th style={{paddingRight: '10px'}}>Data</th>
							<th style={{paddingRight: '10px'}}>Livello allerta</th>
						</tr>
						<tr>
							<td style={{backgroundColor: color}}>{municipalityAlert.protocol}</td>
							<td style={{backgroundColor: color}}>{time}</td>
							<td style={{backgroundColor: color}}>{livelli[municipalityAlert.alertLevel]}</td>
						</tr>
					</table>
				);
			}
			modalContent.push(<h1 style={{textAlign: 'center', color: color}}> {properties.COMUNE} ({properties.PROVINCIA}), {properties.ISTAT} </h1>);
			if (exceededListContent.length > 0){
				modalContent.push(...exceededListContent);
			} else {
				modalContent.push(<h2 style={{textAlign: 'center', color: color}}> Nessuna soglia superata </h2>)
			}
			if (alertedListContent.length > 0){

				modalContent.push(...alertedListContent);
			} else {
				modalContent.push(<h2 style={{textAlign: 'center', color: color}}> Nessuna allerta </h2>)
			}
		}
		return modalContent;
	}

	render() {
		const {mauState, isReady} = this.state;
		let additionalMapProps = (!!mauState && !_.isEmpty(mauState)) ? this.getAdditionalMapProps() : {};
		return !isReady ? Stub : (
			<>
				<div className="row">
					<div className="col-6">
						<MosipCurrentReport
							expiringAlertsMapByIstat = {this.state.expiringAlertsMapByIstat}
							lastRunWarningsMapByIstat = {this.state.lastRunWarningsMapByIstat}
							lastExecutionTimestamp= {this.state.lastExecutionTimestamp}
							tableKey = {this.state.tableKey}
							mauState = {this.state.mauState}
							onLastRunItemClick = {(istatCode)=>this.onLastRunItemClick(istatCode)}
							onExpiringAlertItemClick = {(istatCode)=>this.onExpiringAlertItemClick(istatCode)}
							hideAlreadyAlerted = {false}
						></MosipCurrentReport>
					</div>
					<div className="col-3">

						<MosipMapComponent
							key={"1_" + this.state.mosipMapKey}
							buttonKey={1}
							title={!!this.state.lastExecutionTimestamp ? "Ultimo run MOSIP: "+DateUtils.epochToDateMinuteResolution(this.state.lastExecutionTimestamp) : "Ultimo run MOSIP: nd"}
							geoJSON={this.state.municipalitiesGeoJSON}
							{...additionalMapProps}
							polygonStyle={(feature, latlng)=>this.polygonStyleAll(feature, latlng)}
							getPopupContent = {(properties)=>this.getPopupContentAll(properties)}
							poligonsKey={this.state.mapKey}
							selectedIstatCode={this.state.selectedIstatCode}
							polygonsKey={this.state.polygonsKey}
							openInfoModal = {(feature) => this.openInfoModal(feature)}
							showMapUpdateButton = {true}
							setMapRef={mapRef => this.lastRunMapRef = mapRef}
						></MosipMapComponent>
					</div>
					<div className="col-3">
						<MosipMapComponent
							key={"2_" + this.state.mosipMapKey}
							buttonKey={2}
							title={"Comuni Allertati nelle ultime 12 ore"}
							geoJSON={this.state.municipalitiesGeoJSON}
							polygonStyle={(feature, latlng)=>this.polygonStyle(feature, latlng)}
							getPopupContent = {(properties)=>this.getPopupContent(properties)}
							poligonsKey={this.state.mapKey}
							selectedIstatCode={this.state.selectedIstatCode}
							polygonsKey={this.state.polygonsKey}
							openInfoModal = {(feature) => this.openInfoModal(feature)}
							showMapUpdateButton = {true}
							setMapRef={mapRef => this.lastAllertedMapRef = mapRef}
						></MosipMapComponent>
					</div>
				</div>
				<div style={{ position: "fixed", bottom: "5vh", right: "3vw" }}>
					<GoToA3BuilderButton/>
				</div>
				{/* ----------------------- INFO MODAL ----------------------- */}
				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						marginLeft: '5vw'
					}}
					open={this.state.showInfoModal /*&& this.state.selectedFeature*/}
					onClose={() => this.closeInfoModal()}
					onBackdropClick={() => this.closeInfoModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "rgba(27,39,50,0.83)",//2c3e50d4
							color : "#fff",
							width: "50vw",
							height: "auto",
							padding: '5vh',
							borderRadius: '15px'
						}}
					>
						<IconButton  aria-label="close" style={{ float: 'right', color: 'white' }} onClick={() => this.closeInfoModal()}>
							<CloseIcon />
						</IconButton>
						{this.getInfoModalContent()}
					</div>
				</Modal>
			</>
		)
	}
}



