import L from "leaflet";
import 'leaflet-rotatedmarker';

const markerHtmlStyles = (color,borderColor, dim="0.7rem") =>
	`background-color: ${color};
								  width: ${dim};
								  height: ${dim};
								  display: block;
								  left: -.35rem;
								  top: -.35rem;
								  position: relative;
								  border-radius: 3rem;
								  transform: rotate(45deg);
								  border: 1px solid ${borderColor}`;


export default class Markers {

	static getMarker = (latlng, color, borderColor, dim="0.7rem") => {
		const icon = L.divIcon({
			iconAnchor: [0, 0],
			labelAnchor: [0, 0],
			popupAnchor: [0, 0],
			className: 'dummy',
			html: `<span style="${markerHtmlStyles(color, borderColor, dim)}" />`
		})
		return L.marker(latlng, {
			icon: icon
		});
	}

/*	
static getWindMarker = (latlng, degrees) => L.marker(latlng, {
		icon: new L.Icon({
			iconSize: [40, 40],
			iconUrl: '/img/wind_directions/N arrow2.png',
			popupAnchor: [0, 0]
		})
	}).setRotationAngle(degrees).setRotationOrigin('center center');
*/
	static getIconByIntensity = (intensity, white=false) => {
		const path = '/img/wind_directions/';
		switch (true) {
			case (intensity < 1):
				return `${path}wind_0${!!white ?  "_white" : ""}.svg`
			case (intensity < 2.5):
				return `${path}wind_5${!!white ?  "_white" : ""}.svg`
			case (intensity < 5):
				return `${path}wind_10${!!white ? "_white" : ""}.svg`
			case (intensity < 10):
				return `${path}wind_20${!!white ? "_white" : ""}.svg`
			case (intensity < 12.5):
				return `${path}wind_25${!!white ? "_white" : ""}.svg`
			case (intensity < 25):
				return `${path}wind_50${!!white ? "_white" : ""}.svg`
			default:
				return `${path}wind_max${!!white ? "_white" : ""}.svg`
		}
	}
	static getWindMarker = (latlng, degrees, intensity, iconSize = 40, white = false, isKmh = false) => {
		if(intensity < 1){
			return null
		}
		else{
			let realIntensity = isKmh ? intensity/3.6 : intensity;

			return L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [iconSize, iconSize],
					iconUrl: this.getIconByIntensity(intensity, white),
					popupAnchor: [0, 0]
				})
			}).setRotationAngle(degrees<=180 ? degrees + 180 : degrees - 180).setRotationOrigin('center center');
		}
	}
		

}
