import React from 'react';
import ViewSection from '#/commons/data/section/ViewSection'
import ValidationSection from '#/commons/data/section/ValidationSection'
import StationClient from '#/lib/StationClient';
import SensorClient from '#/lib/SensorClient';
import _ from "underscore";

const STEP = {
	VIEW: 0,
	VALIDATION: 1
}



export default class DataContainerPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step : STEP.VIEW,
			stationsGeoJSON : null,
			interval : null,
			selectedMeasure : null,
			selectedStation : null,
			allSensorsMap : null,
		}
	}

	getOutflowsScalesPromise() {
		return new Promise((resolve, reject) => {
			SensorClient.getAllOutflowsScalesMapBySensorCode(
				(data) => {
					if (!_.isEmpty(data)) {
						resolve(data)
					}
				},
				(msg) => {
					console.log('Error retrieving outflow scales');
				}
			)
		})
	}

	componentDidMount(){


		let geoGsonPromise = new Promise((resolve,reject)=>{
			StationClient.getStationsGeojson(
				(data) => resolve(data),
				(msg) => reject(msg)
			)
		});

		let sensorPromise = new Promise((resolve,reject)=>{
			SensorClient.getAllSensors(
				(data) => resolve(data),
				(msg) => reject(msg)
			)
		});
			
		Promise.all([geoGsonPromise, sensorPromise, this.getOutflowsScalesPromise()]).then(
			values => {
				let stationsGeoJSON = values[0];
				let allSensorsMap = values[1].reduce((obj, item)=> {obj[item.code]=item; return obj}, {});
				this.setState({
					stationsGeoJSON,
					allSensorsMap,
					outflowScales: values[2]
				})
			}
		).catch(
			err => {
				console.log(err);
			}
		)

	}

	nextStep(state) {
		let targetStep = this.state.step + 1;
		this.setState({
			step: targetStep,
			...state
		})
	}

	prevStep() {
		let targetStep = this.state.step - 1;
		this.setState({
			step: targetStep
		})
	}

	render() {


		let step;
		switch(this.state.step){
			case STEP.VIEW :
				step = <>
					{!!this.state.stationsGeoJSON
					? 
						<ViewSection
							stationsGeoJSON = {this.state.stationsGeoJSON}
							selectedZone = {this.state.selectedZone}
							selectedStation = {this.state.selectedStation}
							selectedMeasure = {this.state.selectedMeasure}
							nextStep = {(data)=>this.nextStep(data)}
							allSensorsMap = {this.state.allSensorsMap}
							outflowScales = {this.state.outflowScales}
						></ViewSection>
					: 
					<></>
					}
					
				</>;
				break;
			case STEP.VALIDATION : 
				step = <>
					<ValidationSection
						stationsGeoJSON = {this.state.stationsGeoJSON}
						selectedZone = {this.state.selectedZone}
						selectedStation = {this.state.selectedStation}
						selectedMeasure = {this.state.selectedMeasure}
						interval = {this.state.interval}
						prevStep = {()=>this.prevStep()}	
						allSensorsMap = {this.state.allSensorsMap}
						outflowScales = {this.state.outflowScales}
					></ValidationSection>
				</>;
				break;
			default : 
				step = <></>;
				break;
		}


		return (

			<div className="generic-page container-fluid data-page">
				{step}
			</div>

		)
	}
}
