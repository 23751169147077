import React from 'react'

import Button from '@material-ui/core/Button';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';

import SaveIcon from '@material-ui/icons/Save';

import 'react-dropzone-uploader/dist/styles.css'

import {validationColors, validationOptions} from "#/lib/MaxPluvsValidation"

import withReactContent from 'sweetalert2-react-content';
import '#/backoffice/style/SweetAlert.css';
import Skeleton from "react-loading-skeleton";
import StationGisPicker from "#/commons/map/StationGisPicker";
import StationClient from "#/lib/StationClient";
import MaxValuesClient from "#/lib/MaxValuesClient";
import L from "leaflet";
import GISTools from "#/lib/GISTools";
import {FaCrosshairs, FaFileCsv, FaFilePdf, FaMapMarkerAlt} from "react-icons/fa";
import {reactFormatter} from "react-tabulator";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import DateUtils from "#/lib/DateUtils";
import {GiDinosaurRex} from "react-icons/gi"

import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import PuntualDetailsTable from "#/commons/table/PuntualDetailsTable"
import IntervalStatsTable from "#/commons/table/IntervalStatsTable"

import {FiCloudRain} from "react-icons/fi";

import Swal from 'sweetalert2';
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import moment from "moment"

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
const defaultZone = {
	value: null,
	label: "Tutte le zone"
}

const defaultStation = {
	value: null,
	label: "  "
}

const UpdateMaxButton = (props) => {
	const rowData = props.cell._cell.row.data;
	const isContainerRow = rowData.hasOwnProperty('maxList');
	const isHistoricalData = isContainerRow && rowData.maxList.length > 0 && rowData.maxList[0].storico;
	;
	if (isHistoricalData){
		return <><GiDinosaurRex className="mr-2"/>{"Dati storici (non ricalcolabili)"}</>;
	} else if (rowData.storico) {
		return <><GiDinosaurRex className="mr-2"/>Dato Storico</>;
	} else
		return (
			<>
				<Button
					className="justify-content-end ml-auto"
					variant="outlined"
					color="primary"
					onClick={(e) => { props.clickCallback(rowData) }}
					disabled={isHistoricalData || props.disabled}
				>{isContainerRow ? `Aggiorna tutti` : `Aggiorna`}</Button>
			</>

		)
}

export default class MaxPLuvsPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 0,
		maxZoom: 12,
		width: "40vw",
		height: "60vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			geoJSON: null,
			zoneOptions: [],
			stationOptions: [],
			selectedZone: defaultZone,
			selectedStation: defaultStation,

			maxValuesData: [],
			sensorCode: "test",
			loading: true,
			showStationsModal: false,
			tableKey: 1,
			chartKey: 1,
			mapKey: 1,
			showDetailsModal : false,
			showStatsModal : false,
			details : {}
		}
	}

	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);



	componentDidMount() {
		let pluvsPromise = new Promise((resolve, reject) => {
			StationClient.getPluvsStationsGeojson((geoJSON) => { resolve({ geoJSON }); }, () => { console.log("ERROR retrieving stations geojson "); });
		});
		Promise.all([pluvsPromise])
			.then(data => {
				let result = {}
				Object.assign(result, ...data);
				let zoneOptions = this.buildZoneOptions();
				let stationOptions = this.buildStationOptions(null, result.geoJSON);
				// Find stations out of Calabria
				let stationsOutsideRegion = GISTools.getStationsOutsideCalabria(result.geoJSON);
				this.setState({
					geoJSON: result.geoJSON,
					zoneOptions: zoneOptions,
					stationOptions,
					stationsOutsideRegion: stationsOutsideRegion,
					loading: false
				})
			});
	}



	buildZoneOptions() {
		let zoneOptions = [];
		let zones = GISTools.getAllZonePolygon();

		zoneOptions.push(defaultZone)
		zones.forEach(zone => {
			let id = zone.properties.ZONE;
			zoneOptions.push({
				value: parseInt(id),
				label: "ZONA " + id,
				icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
			});
		})
		// Add Out-of-Calabria zone
		let id = 9999;
		zoneOptions.push({
			value: parseInt(id),
			label: "Territorio extraregionale",
			icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
		});

		return zoneOptions
	}

	buildStationOptions(zoneId, stations = this.state.geoJSON) {
		let stationOptions = [];

		if (!!zoneId) {
			if (zoneId === 9999){
				stations = this.state.stationsOutsideRegion;
			} else {
				let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
				let stationWithinZone = GISTools.intersect(stations, zonePolygon);
				stations = stationWithinZone;
			}
		}

		let stationsProperties = GISTools.getPropertiesFromFeature(stations);
		stationOptions.push(defaultStation);
		stationsProperties.forEach(properties => {
			stationOptions.push({
				label: properties.name,
				value: properties.code,
				icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
			});
		})

		return stationOptions
	}

	onSelectZone(zoneId) {
		let stationOptions = [];
		let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
		stationOptions = this.buildStationOptions(zoneId, this.state.geoJSON);

		this.setState({
			stationOptions: stationOptions,
			selectedZone: selectedZone,
			selectedStation: defaultStation,


		})

	}

	fillMissingData = (data, station_code) => {

		const sortedYears = Object.keys(data).map(Number).sort((a, b) => b-a);
		let sensorCode = data[sortedYears[0]][0].sensorCode

		console.log(data[sortedYears[0]])
		  
		const currentYear = moment().year();
		const allYears = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => 1900 + i);
		const scopes = ["MIN_15", "MIN_30", "HOUR_1", "HOUR_3", "HOUR_6", "HOUR_12", "HOUR_24"];
	  
		for (const year of allYears) {
		  if (!data[year]) {
			data[year] = [];
		  }
	  
		  const existingScopes = data[year].map((entry) => entry.scope);
		  const missingScopes = scopes.filter((scope) => !existingScopes.includes(scope));
	  
		  missingScopes.forEach((scope) => {
			const yearStartMillis = moment(`${year}-01-01T00:00:00.000Z`).valueOf();

			data[year].push({
			  id: null, // Use a unique ID generator if needed
			  sensorCode: `${sensorCode}`,
			  stationCode: `${station_code}`,
			  year: year,
			  measurementTimestampEnd: yearStartMillis,
			  measurementTimestampStart: yearStartMillis,
			  scope: scope,
			  value: null,
			  locked: false,
			  validation: "MISSING",
			  storico: false,
			  lastComputedTimestamp: null,
			});
		  });
		}
	  
		return data;
	}

	getMaximumValues(selectedStation, stationOptions, selectedZone){
		MaxValuesClient.getMaxRainValues((data) => {

				console.log(data)

				data = this.fillMissingData(data, selectedStation.value)


				let arrayData = Object.entries(data).map(e => {
					return {
						year: parseInt(e[0]),
						maxList: e[1]
					}
				});
				console.log('Max values ',arrayData);
				let newState = {
					selectedStation: selectedStation,
					maxValuesData: arrayData,
					dirty: false,
					mapKey : (this.state.mapKey +1)%1000
				};
				newState.selectedZone = !!selectedZone ? selectedZone : this.state.selectedZone;
				newState.stationOptions = !!stationOptions ? stationOptions : this.state.stationOptions;
				this.setState(newState)
			},
			() => {
				console.log("ERROR retrieving rain max values ")
				this.setState({
					stationOptions: stationOptions,
					selectedStation: selectedStation,
					selectedZone: selectedZone,
					maxValuesData: [],
					dirty: false,
					mapKey : (this.state.mapKey +1)%1000
				})
			},
			selectedStation.value);
	}
	onSelectStation(stationCode) {
		let stationOptions = this.buildStationOptions();
		let selectedStation = stationOptions.find(element => element.value === stationCode);
		let stationPoint = GISTools.getStationByField(this.state.geoJSON, "code", stationCode);
		let zonePolygon = GISTools.getZonePolygonByPoint(stationPoint);
		let zoneId = (!!zonePolygon && !!zonePolygon.properties)  ? zonePolygon.properties.ZONE : 9999;

		;
		let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
		stationOptions = this.buildStationOptions(zoneId);

		this.getMaximumValues(selectedStation, stationOptions, selectedZone);
	}

	onEachStation(feature, layer) {
		layer.on('click', (e) => this.onSelectStation(feature.properties.code));

		var popup = L.popup({ closeButton: false })
			.setContent('<div><span class="mr-2 fas fa-map-marker-alt"></span>' + feature.properties.name + '</div>');
		layer.bindPopup(popup);
		let timer = null;
		layer.on('mouseover', function (e) {
			timer = setTimeout(() => this.openPopup(), 1000)
		});
		layer.on('mouseout', function (e) {
			clearTimeout(timer);
			this.closePopup();
		});
	}
	getSavedTitle(len) {
		let title = len > 1 ? len + ' massimi sono state modificati' : '1 massimo è stata modificato';
		title += ' con successo';
		return title;
	}

	saveNewMax(){
		const editedData = this.getEditedData();
		MaxValuesClient.saveMaxRainValues(
			(mes)=>{
				console.log('Saved data!',mes);
				ReactSwal.fire({
					title: `<h2>${this.getSavedTitle(editedData.length)}</h2>`,
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'OK'
				})
				this.getMaximumValues(this.state.selectedStation);


			},
			(mes)=>{
				console.log('Problems saving data!',mes)
			},
			editedData
		)

	}

	stationToMarker(feature, latlng) {
		let marker = null;
		let code = feature.properties.code;
		let isSelected = code === this.state.selectedStation.value;
		if (isSelected) {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point_selected.png',
					popupAnchor: [0, 0]
				})
			});
		}
		else {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point.png',
					popupAnchor: [0, 0]
				})
			});
		}

		return marker;

	}
	zonesStyle(feature) {
		let zone = parseInt(feature.properties.ZONE);
		let style = {};

		if (this.state.selectedZone.value === 0) {
			if (this.state.zoneOptions.map(x => x.value).includes(zone)) {

				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}

		}
		else {
			if (this.state.selectedZone.value === zone) {
				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}
		}


		return style;
	}

	fixDurationLabel = (label) => {
		if (label === undefined){
			return label
		} else {
			switch(label){
				case "MIN_15" : return "15 minuti";
				case "MIN_30" : return "30 minuti";
				case "HOUR_1" : return "1 ora";
				case "HOUR_3" : return "3 ore";
				case "HOUR_6" : return "6 ore";
				case "HOUR_12": return "12 ore";
				case "HOUR_24": return "24 ore";
				default : return "ND";
			}

		}
	}

	getScopeSortingOrder = (label) =>{
		switch(label){
			case "MIN_15" : return 0;
			case "MIN_30" : return 1;
			case "HOUR_1" : return 2;
			case "HOUR_3" : return 3;
			case "HOUR_6" : return 4;
			case "HOUR_12": return 5;
			case "HOUR_24": return 6;
			default : return 0;
		}
	}


	updateMax(rowData){

		loadingSwal.fire('Calcolo dei nuovi massimi...');
		let {year} = rowData;
		let params = [];
		if (!!rowData.maxList){
			rowData.maxList.forEach( child => {
					params.push((({stationCode, sensorCode, scope, measurementTimestampStart: start, measurementTimestampEnd: end, year}) =>
						({stationCode, sensorCode, scope, start, end, year}))(child));
				
			})
		} else {
			params.push((({stationCode, sensorCode, scope, measurementTimestampStart : start, measurementTimestampEnd : end, year}) =>
				({stationCode, sensorCode, scope, start , end, year }))(rowData));
		};


		let alertTitle = params.length > 1 ? 'Massimi ricalcolati' : 'Massimo ricalcolato';


		MaxValuesClient.computeMaxRainValues((data) => {
				let arrayData = Object.entries(data).map(e => {
					return {
						year: e[0],
						maxList: e[1]
					}
				});
				let currentData = this.state.maxValuesData;

				let scopes = data[year].map(d => d.scope);
				let dataToEdit = currentData.find(o => o.year === year);
				dataToEdit.maxList.forEach((element, index) => {
					if(scopes.includes(element.scope)) {
						let editedData = dataToEdit.maxList[index];
						let newData = arrayData[0].maxList.find(d => d.scope === element.scope);
						editedData.value = newData.value;
						editedData.measurementTimestampStart = newData.measurementTimestampStart;
						editedData.measurementTimestampEnd = newData.measurementTimestampEnd;
						editedData.validation = newData.validation;
						editedData.dirty = true;
					}
				})
				loadingSwal.close();
				ReactSwal.fire(alertTitle, '', 'success');
				this.setState({
					dirty: true,
					maxValuesData: currentData,
					tableKey: this.state.tableKey + 1,
				})

			},
			() => {
				console.log("ERROR computing rain max values ");
				ReactSwal.hideLoading();
				ReactSwal.fire('Errore nel ricalcolo dei massimi', '', 'error');
			},
			params);

	}
	dateAccessor = (value, data, type, params, column) => DateUtils.epochToDateMinuteResolution(value);

	getMaxDataColumns() {
		let columns = [];
		columns = columns.concat([
			{ title: "Anno", field: "year", headerSort: true, width:100, headerFilter: true, headerFilterPlaceholder:"Anno" },
			{ title: "Durata",
				field: "scope",
				width:120,
				formatter: (cell) => this.fixDurationLabel(cell.getValue()),
				sorter:(a, b)=>{
					return this.getScopeSortingOrder(a) - this.getScopeSortingOrder(b);
				},
				accessorDownload: (value, data, type, params, column) => this.fixDurationLabel(value)
			},
			{ title: "Inizio",
				field: "measurementTimestampStart",
				formatter: (cell) => DateUtils.epochToGMT1String(cell.getValue()),
				accessorDownload: this.dateAccessor},
			{ title: "Fine",
				field: "measurementTimestampEnd",
				formatter: (cell) => DateUtils.epochToGMT1String(cell.getValue()),
				accessorDownload: this.dateAccessor},
			{ title: "Massimo", field: "value", width:120, editor: "number", editable: (cell) => this.props.canEdit && !cell._cell.row.data.storico},
			
			... this.props.canEdit ? [
				{
					title: "Validazione", field: "validation", editor: "select",
					editorParams: {
						values: validationOptions
					},
					editable: (cell) => !cell._cell.row.data.storico && this.isOfficer() && this.props.canEdit,
					formatter: (cell) => {
						cell.getElement().style.backgroundColor = validationColors[cell.getValue()]
						return `${!!cell.getValue() ? validationOptions[cell.getValue()] : ""}`
					},
					accessorDownload: (value, data, type, params, column) => `${!!value ? validationOptions[value] : ""}`
				},
				{
					title: "",
					formatter: reactFormatter(<UpdateMaxButton disabled={!this.isOfficer()} clickCallback={(rowData) => this.updateMax(rowData)}></UpdateMaxButton>),
					headerSort: false,
					hozAlign: "center",
					vertAlign: "middle",
					download: false
			}] : []
		]);
		return columns;
	}

	rowFormatter(row){
		let data = row.getData();
		if(this.props.canEdit){
			if(!!data.dirty){
				row.getElement().style.backgroundColor = "rgba(245,203,92,0.5)";
			}
			if(!data.storico){
				row.getElement().style.cursor = "help";
			}
		}
		
	}

	cellEdited(cell){
		if(cell.getField()==="value"){
			cell.getRow().getData().validation = "RECONSTRUCTURED";
		}
		cell.getRow().getData().dirty = true;
		this.setState({
			dirty: true,
			tableKey: (this.state.tableKey+1)%1000
		})
	}

	getTabulatorOptions() {
		let tabulatorOptions = {
			data: [],
			height: "65vh",
			dataTree:true,
			dataTreeStartExpanded:true,
			dataTreeChildField:"maxList",
			rowFormatter:(row)=>this.rowFormatter(row),
			cellEdited:(cell)=>this.cellEdited(cell),
			rowContextMenu:this.props.canEdit && [
				{
					label:"Statistiche",
					disabled : (row) => !!row._row.data.storico || !!row._row.data.maxList,
					action:(e, row) => {
						let data = row._row.data;
						if(!!data.sensorCode){
							this.openStatsModal(data.sensorCode, data.measurementTimestampStart, data.measurementTimestampEnd, data)
						}
					}
				},
				{
					label:"Dettagli",
					disabled : (row) => !!row._row.data.storico || !!row._row.data.maxList,
					action:(e, row) => {
						let data = row._row.data;
						if(!!data.sensorCode){
							this.openDetailsModal(data.sensorCode, data.measurementTimestampStart, data.measurementTimestampEnd, data)
						}
					}
				},
			],
			downloadReady: (fileContents, blob) => blob,

		}
		return tabulatorOptions;
	}


	getEditedData() {
		let editedData = [];
		this.state.maxValuesData.forEach(obj =>
			{
				obj.maxList.forEach(data => {
					if(data.dirty){
						delete data.dirty;
						editedData.push(data);
					}
				})
			}
		)
		return editedData;
	};

	openDetailsModal(sensorCode, start, end, data){
		let header = <>Dati puntuali:&nbsp;&nbsp;{this.getHeader()}<br></br>{this.getRowHeader(data)}</>;
		this.setState({
			showDetailsModal:true,
			details : {
				start,
				end,
				sensorCode,
				header
			}
		})
	}

	openStatsModal(sensorCode, start, end, data){
		let header = <>Statistiche:&nbsp;&nbsp;{this.getHeader()}<br></br>{this.getRowHeader(data)}</>;
		this.setState({
			showStatsModal:true,
			details : {
				start,
				end,
				sensorCode,
				header
			}
		})
	}

	onCloseDetailsModal(){
		this.setState({showDetailsModal:false, details : {}})
	}

	onCloseStatsModal(){
		this.setState({showStatsModal:false, details : {}})
	}

	getHeader(){
		return <><span>Stazione di:&nbsp;&nbsp; {this.state.selectedStation.label}</span> <FiCloudRain></FiCloudRain></>;
	}

	getRowHeader(data){
		return <><span>{this.fixDurationLabel(data.scope)} [{data.value} mm]: { DateUtils.epochToGMT1String(data.measurementTimestampStart)} - { DateUtils.epochToGMT1String(data.measurementTimestampEnd)} </span></>;
	}

	createCSVFileName = () => `piogge massime ${this.state.selectedStation.label}`;

	downloadDataCsv = () => {
		this.tableRef.table.download("csv", `${this.createCSVFileName()}.csv`);
	};

	downloadDataPdf = () => {
		this.tableRef.table.download("pdf", "data.pdf", {
			orientation: "portrait",
			title:`Report massimi di pioggia \nStazione di ${this.state.selectedStation.label}`,
			autoTable: doc => {
				doc.autoTableSetDefaults( {
					addPageContent: ( data ) => {
						let footerStr = String(doc.internal.getNumberOfPages());
						doc.setFontSize( 10 );
						doc.text( footerStr, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10 );
						doc.setFontSize( 14 );
					}
				} );
				return {
					styles: {
						fillColor: [214,236,237],
						minCellWidth: 20
					},
					headStyles: {
						/*id: {
							fillColor: 255
						},*/
						textColor: [0,0,0]
					},
					/*columnStyles: {
						0: {cellWidth: 60},
						[this.tableRef.table.columnManager.columns.findIndex(col => col.field === 'validate')]: {cellWidth: 170}
					},*/
					margin: {
						top: 70,
						bottom: 70
					}
				}
			}
		});
	};

	getTableData=()=>{
		let tableData = this.state.maxValuesData

		tableData.sort((a, b) => b.year - a.year);
		const scopeOrder = ["MIN_15", "MIN_30", "HOUR_1", "HOUR_3", "HOUR_6", "HOUR_12", "HOUR_24"];

		// Sort each maxList by scope ascending
		tableData.forEach(item => {
			item.maxList.sort((a, b) => {
				return scopeOrder.indexOf(a.scope) - scopeOrder.indexOf(b.scope);
			});
		});


		return tableData
	}
	render() {
		return (
			<>
				<div className="generic-page container-fluid data-page">
					<div className="row mt-4">

						<div className="col-4">
							{!this.state.loading ?

								<StationGisPicker
									centerMapOnSelect={true}
									geoJSON={this.state.geoJSON}
									zoneOptions={this.state.zoneOptions}
									selectedZone={this.state.selectedZone}
									onSelectZone={(option) => this.onSelectZone(option)}
									stationOptions={this.state.stationOptions}
									selectedStation={this.state.selectedStation}
									onSelectStation={(option) => this.onSelectStation(option)}
									mapKey={this.state.mapKey}
									onEachStation={(feature, layer) => this.onEachStation(feature, layer)}
									stationToMarker={(feature, latlng) => this.stationToMarker(feature, latlng)}
									zonesStyle={(feature) => this.zonesStyle(feature)}
									mapHeight={"65vh"}
								/>
								: <Skeleton height={"80vh"} duration={8}/>}
						</div>
						<div className="col-8" >
							{this.state.maxValuesData.length===0
								?
								<>
									<div>
										<Skeleton height={"10vh"} duration={8}/>
									</div>
									<div>
										<Skeleton height={"65vh"} duration={8}/>
									</div>

									<div className="d-flex mt-1 justify-content-end ml-auto">
										<Skeleton  width={"10vw"} height={"5vh"} duration={8}/>
									</div>
								</>
								:
								<>
									<div className="row">
										<div className="col-8">
											<h3><span>Massimi di Pioggia di Breve Durata</span><br></br>{this.getHeader()}</h3>
										</div>
										<div className="col-2 d-flex justify-content-center m-auto">
											<Button
												startIcon = {<FaFileCsv/>}
												size="small"
												variant="contained"
												color="primary"
												onClick={(event) => this.downloadDataCsv()}
											>Download csv</Button>
										</div>
										<div className="col-2 d-flex justify-content-center m-auto">
											<Button
												startIcon = {<FaFilePdf/>}
												size="small"
												variant="contained"
												color="primary"
												onClick={(event) => this.downloadDataPdf()}
											>Download pdf</Button>
										</div>
									</div>
									<div>
										<ReactTabulator
											ref={ref => this.tableRef = ref}
											columns={this.getMaxDataColumns()}
											data={this.getTableData()}
											options={this.getTabulatorOptions()}
											key={"table_" + this.state.tableKey}
										/>
									</div>

									<div className="d-flex mt-1">
										{this.props.canEdit && <Button
											className="justify-content-end ml-auto"
											variant="contained"
											color="primary"
											startIcon={<SaveIcon />}
											disabled={!this.state.dirty}
											onClick={(e) => this.saveNewMax()}
										>Salva Massimi</Button>}
									</div>
								</>
							}


						</div>
					</div>

				</div>

				{/*---------------- DETAILS MODAL -----------------------*/}
				<Modal key={'puntual_modal'}
					   style={{
						   display: 'flex',
						   alignItems: 'center',
						   justifyContent: 'center',
						   overflow:'scroll',
					   }}
					   open={this.state.showDetailsModal}
					   onClose={() => this.onCloseDetailsModal()}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "40vw",
							height: "70vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseDetailsModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div className="mt-5 mb-5 ml-5 mr-5">
							<h3>
								{this.state.details.header}
							</h3>

							<PuntualDetailsTable
								height = {"50vh"}
								interval = {{start: this.state.details.start, end : this.state.details.end }}
								sensorCode = {this.state.details.sensorCode}
								category = {"P"}
							></PuntualDetailsTable>
						</div>
					</div>
				</Modal>

				{/*---------------- STATS MODAL -----------------------*/}
				<Modal key={'stats_modal'}
					   style={{
						   display: 'flex',
						   alignItems: 'center',
						   justifyContent: 'center',
						   overflow:'scroll',
					   }}
					   open={this.state.showStatsModal}
					   onClose={() => this.onCloseStatsModal()}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "40vw",
							height: "70vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton aria-label="close" onClick={() => this.onCloseStatsModal()}>
								<CloseIcon />
							</IconButton>
						</div>
						<div className="mt-5 mb-5 ml-5 mr-5">
							<h3>{this.state.details.header}</h3>
							<IntervalStatsTable
								height = {"50vh"}
								interval = {{start: this.state.details.start, end : this.state.details.end }}
								sensorCode = {this.state.details.sensorCode}
							></IntervalStatsTable>
						</div>
					</div>
				</Modal>


			</>
		)
	}
}
