import {properties} from '#/properties.js';

import parse_georaster from "georaster"
import APIUtils from "#/lib/APIUtils";
import {radarBaseUrl} from "#/properties";


export default class ProtocolloSettingsClient {

	static get(okCallback, errCallback){
		let url = properties.url.protocolloSettings
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static store(okCallback, errCallback, settings, type){
		let url = properties.url.protocolloStore(type);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(settings)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static testProtocol(okCallback, errCallback){
		let url = properties.url.protocolloStoreTest();
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static testRetrieve(protocol){
		return  properties.url.protocolloGetTest(protocol);
	}

	

}
