import React from 'react';
import BimItem from './BimItem';
import BimDetails from './BimDetails';
import PaginationComponent from '#/commons/components/PaginationComponent';
import BulletinClient from '#/lib/BulletinClient';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import { CircularProgress } from '@material-ui/core';


export default class BimPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			startDateFilter: null,
			endDateFilter: null,
			allowCreationToday: false,
			pageKey: 0,
			isLoading: false
		}
	}

	componentDidMount() {
		if (!!this.props.canEdit) {
			BulletinClient.isCreationAllowedToday(
				(data) => {
					this.setState({
						allowCreationToday: true
					})
				},
				(error) => {
					console.log('error: ', error);
					this.setState({
						allowCreationToday: false
					})
				},
				this.props.bulletinTypeName
			);
		}
	}
	forcePageReloading = () => {this.setState({pageKey: (this.state.pageKey + 1) % 1000 })};
	fromJsonToBimItem(obj) {
		let bimDetails = <BimDetails bulletinTypeName={this.props.bulletinTypeName} {...obj} />
		return <BimItem {...obj} key={obj.id} details={bimDetails} editable={this.props.canEdit} refreshList={() => this.forcePageReloading()}/>;
	}

	getPage(successCallback, errorCallback, page) {
		let params = {
			pageNumber: page,
			pageSize: 3,
			bulletinTypeName: this.props.bulletinTypeName
		}
		this.setState({ isLoading: true });
		BulletinClient.listBulletins(
			(obj) => { 
				this.setState({ isLoading: false });
				this.setState({ loading: false }, successCallback(obj, page)) },
			(err) => { 
				this.setState({ isLoading: false });
				this.setState({ loading: false }, errorCallback(err)) },
			params
		)
	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	render() {
		return (
			<div className="generic-page list-page">

<				h2 className='d-flex justify-content-center align-items-center mb-5'>Bollettino idrologico siccità</h2>
				{this.state.isLoading && (
					<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<CircularProgress />
					</div>
				)}
				<PaginationComponent
					key={this.state.pageKey}
					enableDateFilter={true}
					toItemFunction={(obj) => this.fromJsonToBimItem(obj)}
					getPage={(successCallback, errorCallback, page, start, end) => this.getPage(successCallback, errorCallback, page, start, end)} />

				{this.props.canEdit && !this.state.loading ?
					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
						<Link to={this.props.createUrl}>

							<Tooltip title="Crea un nuovo Bollettino Idrologico">
								<Fab color="primary" disabled={!this.state.allowCreationToday || !this.isOfficer()}>
									<AddIcon />
								</Fab>
							</Tooltip>
						</Link>

					</div>
					:
					<></>
				}
			</div>
		)
	}
}
