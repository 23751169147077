import React from "react";

import {BUTTON_PROGRESS_STYLE, PRECIPITAZIONI_TABLE_COLUMNS, TABULATOR_OPTIONS} from "#/backoffice/bim/utils/BIMUtils";
import CircularProgress from '@material-ui/core/CircularProgress';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton, SkipButton} from "#/commons/components/forms/NextButton";
import {SelectForm} from "#/commons/components/forms/SelectForm";

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DateUtils from "#/lib/DateUtils";

import Modal from '@material-ui/core/Modal';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import RainStatsClient from "#/lib/RainStatsClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ChromaticScale, {hydroDomain} from "#/lib/ChomaticScale";
import ReactTabulator from "react-tabulator/lib/ReactTabulator";
import {GeoJSON} from "react-leaflet";
import GISTools from "#/lib/GISTools";
import StationClient from "#/lib/StationClient";
import * as turf from "@turf/turf";
import external_points from "#/commons/geodata/external_points.json";
import LeafletLegend from "#/commons/map/LeafletLegend";
import IdwClient from "#/lib/IdwClient";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import moment from "moment";
import {SelectRangeForm} from "#/commons/components/forms/SelectRangeForm";
import L from "leaflet";
import _ from "lodash";
import DownloadContainer from "#/commons/components/DownloadContainer"
import MapComponent from "#/commons/map/MapComponent";


function hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
}

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

const rangeOptions = [
    { value: "", label: "Seleziona" },
    {
        label : "1961 - 1990",
        value : [1961,1990]
    },
    {
        label : "ultimo trentennio",
        value : [moment().year() - 30,moment().year()]
    }
]

export class StepPrecipitazioniMese extends React.Component {


    static whyDidYouRender = false;
	
    section = "rainfalls";
    img = "img";
    images = [this.img];
	refMonthName = !!this.props.formData ? this.props.formData.reference_month : DateUtils.getMonthNameFromMonthNumber( this.props.month);
	inputData = !!this.props.formData ? this.props.formData[this.section]["inputData"] : null;
    hasBeenModified = false;

    constructor(props) {
        super(props);
        this.state = {
            enableSave: this.props.viewMode,
            showModal: false,
            calculating: false,
            loading: false,
            tableKey: 1,
            mapKey: 1,
            statsData: [],
            file: null,
            zoneGeoJson: null,
            identifierCandidates: null,
            geoJsonIdentifier: null,
            rasterEnabled : true,
            selectedRange : []
        }
    }

    componentDidMount() {
		window.scrollTo(0, 0)
        if (!this.props.viewMode) {
            if (!!this.inputData) {
                this.setState({...this.inputData});
            } else {
                Promise.all([
                    this.getStationsGeojsonPromise(),
                ])
                    .then(values => {
                        this.setState({
                            stationsGeoJSON: values[0].stationsGeoJSON,
                            mapKey: values[0].mapKey
                        })
                    })
                    .catch(errors =>{
                        console.log('Errors from promise all',errors)
                    })
            }
        }
    }
    handleSkipStep = () => {
        if(this.props.context==="BIM") {
            this.props.handleNext(true); // skip = true
        }
    }
    handleNextStep = () => {
		if(this.props.context==="BIM"){

			if (this.props.viewMode) {
				this.props.handleNext();
			} else {
				let statsData = this.state.statsData;
            let formData = this.props.formData;
            if (!!statsData && statsData.length > 0) {
                if (this.hasBeenModified ) {
					loadingSwal.fire('Salvataggio immagini in corso...');
                    this.setState({loading: true});
                    const promises = [];
                    this.images.forEach( (img) => {
						promises.push(this.props.handleSaveImg(img));
                    })
                    Promise.all(promises)
					.then((response) => {
						let idx = 0;
                            this.images.forEach( (img) => {
								formData[this.section][img] = response[idx++];
                            });
                            formData[this.section]["zones"] = [...statsData];
                            formData[this.section]["inputData"] = {...this.state, loading: false};
                            formData[this.section]["historicalPeriodString"] = rangeOptions
							.find(opt => opt.value === this.state.selectedRange).label;
                            ReactSwal.close();
                            this.props.handleNext();
                        })
                        .catch(error => {this.setState({loading: false}); console.log(`Error in executing ${error}`);})
					} else {
                    this.props.handleNext();
                }
            } else {
				ReactSwal.fire(
                    "Redazione Bollettino Idrologico/Siccità",
                    "Caricare nuove zone ed effettuare i calcoli statistici prima di procedere",
                    "error"
					);
				}
			}
		}
	}
    addCentroidGeoJSON(geoJSON) {
        if (!!geoJSON){
            GISTools.addCentroids(geoJSON)
        }
    }

    handleSelectGeoJSON = (valueSelected) => {
        let options = this.props.selectOptions;
        let selectedOption = options.filter(opt => opt.value === valueSelected);
        let geoJSON = selectedOption[0].value;
        let geoJsonIdentifier = selectedOption[0].identifier;
        if (geoJsonIdentifier==="__NEW__" && geoJSON === "NEW") {
            this.openModal();
        } else {
            let clonedGeoJson = _.cloneDeep(geoJSON)
            this.addCentroidGeoJSON(clonedGeoJson);
            this.setState({
                zoneGeoJson: clonedGeoJson,
                tableKey: (this.state.tableKey + 1) % 1000,
                mapKey: (this.state.mapKey + 1) % 1000,
                geoJsonIdentifier: geoJsonIdentifier
            })
        }
        this.hasBeenModified = true;
    };

    handleSelectRange = (valueSelected) => {
        this.setState({
            selectedRange : valueSelected
        })
    };


    getStationsGeojsonPromise = () => {
        return new Promise((resolve, reject) => {
                StationClient.getStationsGeojson(
                    (data) => {
                        resolve({
                            stationsGeoJSON: data,
                            mapKey: this.state.mapKey + 1
                        })
                    },
                    (err) => { console.log(err);
                        reject(err)}
                )
            }
        );
    }

    onClickCalculate = () => {

        this.hasBeenModified = true;
        loadingSwal.fire('Calcolo delle statistiche zonali per le zone caricate...');

        this.setState({calculating:true});
        this.loadLastMonthData();
        this.loadZonalStatsFromAPI();
    }

    loadZonalStatsFromAPI = () => {
        let geoJSON = this.state.zoneGeoJson;
        RainStatsClient.getZonalStats(this.state.selectedRange, this.props.year,
            this.props.month + 1,
            this.state.geoJsonIdentifier,
            geoJSON,
            (data)=>{
                let flags = !!this.state.interpolatedData ? {enableSave: true, calculating: false} : {};
                this.setState({
                    statsData: data,
                    tableKey: (this.state.tableKey + 1) % 1000,
                    ...flags
                },()=>{
                    if(!!this.state.interpolatedData){
                        ReactSwal.close();
                    }
                })
            },
            ()=>{
                console.log('Problems retrieving Hydro Stats data!')
                ReactSwal.hideLoading();
                this.setState({calculating:false});
                ReactSwal.fire('Si è verificato un errore nel calcolo delle statistiche idrologiche zonali', '', 'error');
            }
        )
    }

    loadLastMonthData = () => {
        RainStatsClient.getLastMonthCumulatedRain(this.props.year,
            this.props.month + 1,
            (data)=>{
                IdwClient.performIdw(
                    (georaster)=>	{

                        let flags = this.state.statsData.length!==0 ? {enableSave: true, calculating: false} : {};
                        this.setState({
                            mapKey: (this.state.mapKey + 1) % 1000,
                            interpolatedData: georaster,
                            ...flags
                        },()=>{
                            if(this.state.statsData.length!==0){
                                ReactSwal.close();
                            }
                        })
                    },
                    ()=>{
                        console.log("Error performing IDW -> Trying to perform IDW locally");
                        let interpGrid = !!data.features ? this.interpolate(data, 5) : [];
                        let flags = this.state.statsData.length!==0 ? {enableSave: true, calculating: false} : {};
                        ReactSwal.close();
                        this.setState({
                            mapKey: (this.state.mapKey + 1) % 1000,
                            interpolatedData: interpGrid,
                            rasterEnabled : false,
                            ...flags
                        },()=>{
                            if(this.state.statsData.length!==0){
                                ReactSwal.close();
                            }
                        })
                    },
                    data,
                    "monthValue"
                )

            },
            ()=>{
                console.log('Problems retrieving Hydro Stats data!')
                ReactSwal.hideLoading();
                this.setState({calculating:false});
                ReactSwal.fire('Si è verificato un errore nel calcolo delle statistiche idrologiche zonali', '', 'error');
            }
        )
    }

    interpolate = (points,cellSize) => {
        cellSize = !!cellSize ? cellSize : 5;
        turf.featureEach(external_points, (point) => {
            let nearest = turf.nearestPoint(point, points);
            point.properties = {...nearest.properties}
        })
        let pointsAll = turf.featureCollection([...external_points.features, ...points.features]);
        let options = {gridType: 'square', property: 'monthValue'};
        let grid =  turf.interpolate(pointsAll, cellSize, options);
        return grid;
    }

    findIdentifierCandidates = (geoJSON) => {
        let identifiers = [];
        const features = geoJSON.features;
        if (!!features) {
            const feature = features[0];
            const keys = Object.keys(feature.properties);
            keys.forEach(key => {
                let allValues = features.map(feature => feature.properties[key])
                if (!hasDuplicates(allValues)){
                    identifiers.push(key)
                }
            })
        }
        return identifiers;
    }

    handleChangeStatusZone = ({ file }, status) => {
        if (status === "removed") {
            this.setState({
                zoneGeoJson: null,
                identifierCandidates: null,
                file : null,
                statsData: [],
                interpolatedData : null,
                tableKey: (this.state.tableKey + 1) % 1000,
                //mapKey: (this.state.mapKey + 1) % 1000,
                enableSave: false
            })
        }
        if (status === "done") {
            const reader = new FileReader();
            reader.onload = (event) => {
                const fileContent = event.target.result;
                let geoJSON = JSON.parse(fileContent);
                // Check keys
                let identifierCandidates = this.findIdentifierCandidates(geoJSON);
                if (identifierCandidates.length > 0)
                {
                    let clonedGeoJson = _.cloneDeep(geoJSON)
                    this.addCentroidGeoJSON(clonedGeoJson);
                    this.setState({
                        file: file,
                        zoneGeoJson: clonedGeoJson,
                        tableKey: (this.state.tableKey + 1) % 1000,
                        mapKey: (this.state.mapKey + 1) % 1000,
                        identifierCandidates: identifierCandidates
                    })
                }
            };
            reader.onerror = (event) => {
                ReactSwal.hideLoading();
                ReactSwal.fire('Si è verificato un errore nella lettura delle zone', '', 'error');
            };
            reader.readAsText(file);
        }
    }
    pointToLayer(feature, latlng){
        let marker = L.marker(latlng, {
            icon: L.divIcon({
                className: 'label',
                html: `<span style="font-size:1rem; font-weight:600">${feature.properties[this.state.geoJsonIdentifier]}</span>`,
            })
        });
        return marker;

    }



    openModal = () => {
        this.setState({showModal: true});
    }

    onCloseModal = () => {
        this.setState({showModal: false});
    }

    enableZonalStatsCalculation = () =>  !!this.props.year && !!this.state.zoneGeoJson;


    onEachPolygon = (feature, layer) => {
    }
    onEachInterpolatedFeatureStations = (feature, layer, context) => {
    }

    gradientStyle = (feature) => {
        const scale = ChromaticScale.getIdrologicoScale();
        const color = scale(feature.properties.monthValue);
        return {
            fillColor: color,
            weight: 0.3,
            color: 'transparent',
            opacity: 1,
            fillOpacity: 0.7
        };
    };


    render() {

		let title = this.props.stepLabel + ' ' + DateUtils.getMonthNameFromMonthNumber( this.props.month) + ' ' + this.props.year

        return (
            <>
                <div className="row justify-content-center">
                    <h2>{title}</h2>
                </div>
                {!this.props.viewMode &&
                <div className="row">
                        <div className="col-4">
                            <SelectForm
								id="select_default_GeoJSON"
								label="Seleziona file di zone"
                                className="mt-3 col-8 row justify-content-center"
								options={this.props.selectOptions}
								selectedValue = {this.props.selectedGeoJSON}
								handleOnChange = {this.handleSelectGeoJSON}
							/>
                        </div>
                        <div className="col-4">
                            <SelectRangeForm
                                id="select_default_GeoJSON"
                                label="Seleziona il trentennio di riferimento"
                                className="mt-3 col-8 row justify-content-center"
                                valueSelected={{id:0}}
                                options={rangeOptions}
                                selectedRange = {this.state.selectedRange}
                                handleSelectRange = {this.handleSelectRange}
                            />
                        </div>
                        <div className="col-4 mt-4">
                            <Button
                                disabled={!this.enableZonalStatsCalculation() || this.state.calculating || this.state.loading || this.state.selectedRange.length === 0}
                                size="large"
                                className="justify-content-end ml-auto"
                                variant="contained"
                                color="primary"
                                onClick={(e) => this.onClickCalculate()}
                            >Calcola Statistiche Zonali</Button>
                        </div>
                </div>
                }
                <div className="row mt-4">
                    <div className="col-8">
						<DownloadContainer 
							imageName={title+" tabella"}
							disabled={this.state.calculating || this.state.loading || !this.state.enableSave}
						>
							<ReactTabulator
								ref={ref => (this.tableRef = ref)}
								columns={PRECIPITAZIONI_TABLE_COLUMNS}
								data={!this.props.viewMode ? this.state.statsData : this.props.formData[this.section].zones}
								options={TABULATOR_OPTIONS}
								key={"table_" + this.state.tableKey}
							/>
						</DownloadContainer>
                    </div>
                    <div className="col-4" >
						<DownloadContainer 
							imageName={title+" mappa"}
							disabled={this.state.calculating || this.state.loading || !this.state.enableSave}
						>
                        {!this.props.viewMode ?

                            <MapComponent
                                id={this.img}
                                width={"100%"}
                                height={"60vh"}
                                backgroundColor={'rgba(255,0,0,0.0)'}
                                zoomSnap={false}
                                dragging={false}
                                scrollWheelZoom = {false}
                                zoomControl = {false}
                                doubleClickZoom ={false}
                                noHome={true}
                                setMapRef={mapRef => this.mapRef = mapRef}>

                                {!!this.state.zoneGeoJson ?
                                    <GeoJSON key={"polygons_" + this.state.mapKey}
                                             data={this.state.zoneGeoJson}
                                             onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
                                             style={{
                                                 fillColor:  "#fff0",
                                                 weight: 0.6,
                                                 opacity: 1,
                                                 color: "blue",
                                                 fillOpacity: 0.5
                                             }}
                                             pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}

                                    /> : <></>}
                                {!!this.state.interpolatedData ? <>

                                    <>
                                        {this.state.rasterEnabled ?
                                            <GeotiffRasterLayer
                                                key={"raster_" + this.state.mapKey}
                                                georaster={this.state.interpolatedData}
                                                opacity={0.5}
                                                resolution={256}
                                                colorScale={ChromaticScale.getIdrologicoScale()}
                                                handleClick = {(val,latlng) => GISTools.showPopup(val,latlng,this.mapRef.leafletElement)}
                                            /> :
                                            <GeoJSON key={"idw_" + this.state.mapKey}
                                                     data={this.state.interpolatedData}
                                                     onEachFeature={(feature, layer, context) => this.onEachInterpolatedFeatureStations(feature, layer, this)}
                                                     style={(feature) => this.gradientStyle(feature)}/>}
                                        <LeafletLegend key={"legend_" + this.state.mapKey}
                                                       grades={hydroDomain}
                                                       replaceMode={true}
                                                       legendTitle={"Altezza [mm]"}
                                                       firstLabel={"Assenza di precipitazione"}
                                                       getColor={(num)=>ChromaticScale.getIdrologicoScale()(num)}/>
                                    </>
                                </> : <></>}
                            </MapComponent>
                            :
                            <img alt={''} src={this.props.formData[this.section][this.img]}/>
                        }
						</DownloadContainer>
                    </div>

                </div>

				
					<MobileStepper
						style={{ backgroundColor: 'rgba(255,0,0,0.0)' }}
						position="static"
						variant="text"
						activeStep={this.props.activeStep}
						steps={this.props.totalSteps}

						nextButton={this.props.context === "BIM"
							?
							<div style={{ position: 'relative' }}>
                                <SkipButton
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.calculating || this.state.loading }
                                    onClick={this.handleSkipStep}
                                    textbutton={this.props.textSkipButton}
                                />
								<NextButton
									variant="contained"
									color="primary"
									disabled={this.state.calculating || this.state.loading || !this.state.enableSave}
									onClick={this.handleNextStep}
									textbutton={this.props.textNextButton}
								/>
								{this.state.loading ? <CircularProgress size={24} style={BUTTON_PROGRESS_STYLE} /> : <></>}
							</div>
							:
							<></>
						}
						backButton={
							<BackButton
								variant="contained"
								disabled={this.state.calculating || this.state.loading || this.props.disableBackButton}
								onClick={this.props.handleBack}
							/>
						}
					/>

				

                


                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    open={this.state.showModal}
                    onClose={() => this.onCloseModal()}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div
                        style={{
                            background: "white",
                            width: "50vw",
                            height: "50vh"
                        }}
                    >
                        <div className='w-100 d-flex justify-content-end'>
                            <IconButton  aria-label="close" onClick={() => this.onCloseModal()}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className="d-flex justify-content-center">
                            <h3 >Import Zone da file</h3>
                        </div>

                        <div className="d-flex h-80">
                            <div className="col-sm-12 my-auto">
                                <Dropzone
                                    {...this.state.file && {initialFiles : [this.state.file]}}
                                    onChangeStatus={this.handleChangeStatusZone}
                                    accept=".geojson,.json"
                                    maxFiles={1}
                                    inputContent="Inserisci il file delle zone in formato GeoJSON"
                                    styles={{
                                        dropzone: { overflow: "hidden", width: "30vw" },
                                        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                                        inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
                                    }}
                                />
                            </div>
                        </div>
                        <br/>
                        {!!this.state.identifierCandidates ?
                            <>
                                <div className="d-flex justify-content-center">
                                    {this.state.identifierCandidates.length > 0 ?
                                        <h3>Scegli il campo che identifica le geometrie zonali:</h3> :
                                        <h3 style={{color: "red"}}>Il file caricato non contiene alcun campo identificativo. Caricare un file valido.</h3>
                                    }
                                </div>
                                <div className="d-flex justify-content-center">
                                    {this.state.identifierCandidates.map(key =>
                                        <Button
                                            key = {key}
                                            size="medium"
                                            className="col-sm-2 my-auto"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => this.setState({mapKey: (this.state.mapKey + 1) % 1000, geoJsonIdentifier: key, showModal: false})}
                                        >{key}</Button>

                                    )}
                                </div>
                            </> : <></>
                        }


                    </div>

                </Modal>
            </>
        )
    }
}
