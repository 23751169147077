import React from "react";
import ThresholdListComponent from "#/commons/components/ThesholdListComponent";
import ThresholdRangeComponent from "#/commons/components/ThesholdRangeComponent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';

const thresholdsLevels = [
  {
    value: 'grey',
    label: 'Nessuna allerta',
  },
  {
    value: 'yellow',
    label: 'Giallo',
  },
  {
    value: 'orange',
    label: 'Arancio',
  },
  {
    value: 'red',
    label: 'Rosso',
  }
];

const getThresholdsLevelsOptions = () => {
  let options = [];
  thresholdsLevels.map((option) => (
    options.push(
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
    )
  ));
  return options;
}

export default class ThresholdSensorComponent extends React.Component {

  thresholdsLevelsOptions = getThresholdsLevelsOptions();
  thresholdsNone = "grey";

  constructor(props) {
    super(props);
    this.state = {
      min: 0,
      max: 100,
      step: 1,
      values: [],
      colors: [],
      params: [],
    };
  }

  componentDidMount() {
    let params = [];

    const min = !!this.props.data.min ? this.props.data.min : this.state.min;
    const max = !!this.props.data.max ? this.props.data.max : this.state.max;

    let colors = []
    let values = [];

    if (this.props.data.thresholds.length!==0) {
      params = [...this.props.data.thresholds];
      params = this.buildGhostThreshold(params, min, max);

      for (let i = 0; i < params.length; i++) {
        if (params[i].thresholdStart !== min){
          values.push(params[i].thresholdStart);
        }
        colors.push(params[i].thresholdLevel);
      }
    } else {
      colors.push('grey');
      params.unshift({
        sensorClass : this.props.data.category,
        stationCode : this.props.data.stationCode,
        sensorCode : this.props.data.code,
        thresholdStart : min,
        thresholdEnd : max,
        thresholdLevel : 'grey',
        thresholdCode : ''
      })
    }
    this.props.onChangeThresholdsValue(params);

    this.setState({
      params: params,
      colors: colors,
      values: values,
      min : min,
      max : max
    });
  }

  buildGhostThreshold = (params, min, max) => {
    let ghostParams = [];
    params = params.sort(this.compareParams);

    let intervalArray = [];
    let values = [];
    values.push(min);
    for (let i = 0; i < params.length; i++) {
      if (!values.includes(params[i].thresholdStart)){
        values.push(params[i].thresholdStart);
      }
      if (!values.includes(params[i].thresholdEnd)){
        values.push(params[i].thresholdEnd);
      }
    }
    if (!values.includes(max)){
      values.push(max);
    }

    //let extValues = [min, ...values, max];
    for (let i = 0; i < values.length - 1; i++) {
      intervalArray.push({ min: values[i], max: values[i + 1] });
    }

    for (let i = 0; i < intervalArray.length; i++) {
      let start = intervalArray[i].min;
      let end = intervalArray[i].max;
      const filter_params =params.filter( el => el.thresholdStart === start && el.thresholdEnd === end);
      if (filter_params.length === 0) {
        console.log('Intervallo assente da ', start, ' a ', end);
        ghostParams.push({
          sensorClass : this.props.data.category,
          stationCode : this.props.data.stationCode,
          sensorCode : this.props.data.code,
          thresholdStart : start,
          thresholdEnd : end,
          thresholdLevel : 'grey',
          thresholdCode : ''
        })
      }
    }

    params = [...params, ...ghostParams];
    return params.sort(this.compareParams);
  }

  compareParams = (a, b) => {
    const thresholdA = parseFloat(a.thresholdStart);
    const thresholdB = parseFloat(b.thresholdStart);

    let comparison = 0;
    if (thresholdA > thresholdB) {
      comparison = 1;
    } else if (thresholdA < thresholdB) {
      comparison = -1;
    }
    return comparison;
  }

  handleChangeTextField (index, obj) {
    let params = this.state.params;
    let colors = this.state.colors;
    params[index] = {...params[index], [obj.name] : obj.value};
    colors[index] = obj.value;
    this.setState({
      params: params,
      colors: colors
    });
  }

  toItem(index, interval, showDelete, param) {

    return (
      <>
        {index > 0 && <Divider variant="inset" />}
        <Grid item md={12} key={index}>
          <List dense={false}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>{index}</Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={interval}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    <Grid container spacing={5}>
                      <Grid item xs={4} md={4}>
                        <TextField
                          fullWidth
                          select
                          id={`${index}_level`}
                          name="thresholdLevel"
                          label="Livello"
                          required
                          value={param.thresholdLevel}
                          onChange={(e) => this.handleChangeTextField(index, e.target)}
                          onBlur = {() => this.props.onChangeThresholdsValue(this.state.params)}
                        >
                          {this.thresholdsLevelsOptions}
                        </TextField>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField
                          fullWidth
                          id={`${index}_code`}
                          name="thresholdCode"
                          label="Codice"
                          hidden={param.thresholdLevel===this.thresholdsNone}
                          required = {!(param.thresholdLevel===this.thresholdsNone)}
                          value={param.thresholdCode}
                          onChange={(e) => this.handleChangeTextField(index, e.target)}
                          onBlur = {() => this.props.onChangeThresholdsValue(this.state.params)}
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                {showDelete && (
                    <IconButton
                      edge="end"
                      aria-label="Elimina"
                      tabIndex ={-1}
                      onClick={(e) => {
                        this.delete(index);
                      }}
                    >
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                )}
                  <IconButton
                    edge="end"
                    aria-label="Aggiungi"
                    tabIndex ={-1}
                    onClick={(e) => {
                      this.split(index);
                    }}
                  >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </>
    );
  }

  delete(index) {
    let colors = this.state.colors;
    let params = this.state.params;
    let values = this.state.values;

    colors.splice(index, 1);
    params.splice(index, 1);
    values.splice(index - 1, 1);

    params.forEach((value, index) => {
      params[index] = {
        ...params[index],
        thresholdStart: index === 0 ? this.state.min : values[index - 1],
        thresholdEnd: !!values[index] || values[index]===0 ? values[index] : this.state.max,
      };
    });

    this.props.onChangeThresholdsValue(params);

    this.setState({
      colors: colors,
      params: params,
      values: values,
    });
  }

  split(index) {
    let colors = this.state.colors;
    let params = this.state.params;
    let values = this.state.values;

    let intervalArray = [];
    let extValues = [this.state.min, ...values, this.state.max];
    for (let i = 0; i < extValues.length - 1; i++) {
      intervalArray.push({ min: extValues[i], max: extValues[i + 1] });
    }

    let intervalToSplit = intervalArray[index];
    let middleValue =
      Math.floor((intervalToSplit.max - intervalToSplit.min) / 2) +
      intervalToSplit.min;

    values.splice(index, 0, middleValue);
    colors.splice(index + 1, 0, "grey");
    params.splice(index + 1, 0, {});
    params.forEach((value, index) => {
      params[index] = {
        sensorClass : this.props.data.category,
        stationCode : this.props.data.stationCode,
        sensorCode : this.props.data.code,
        thresholdLevel : 'grey',
        thresholdCode : '',
        ...params[index],
        thresholdStart: index === 0 ? this.state.min : values[index - 1],
        thresholdEnd: !!values[index] || values[index]===0 ? values[index] : this.state.max,
      };
    });

    this.props.onChangeThresholdsValue(params);

    this.setState({
      colors: colors,
      params: params,
      values: values,
    });
  }

  onChangeValues = (values) => {
    let params = [...this.state.params];
    params.forEach((value, index) => {
      params[index] = {
        ...params[index],
        thresholdStart: index === 0 ? this.state.min : values[index - 1],
        thresholdEnd: !!values[index] || values[index]===0 ? values[index] : this.state.max,
      };
    });

    this.props.onChangeThresholdsValue(params);

    this.setState({
      params: params,
      values: values,
    });
  };

  render() {
    let itemsList = [];
    let intervalArray = [];

    let extValues = [this.state.min,...this.state.values, this.state.max];

    for (let i = 0; i < extValues.length - 1; i++) {
      intervalArray.push({ min: extValues[i], max: extValues[i + 1] });
    }

    const itemsNumber = this.state.params.length;
    for (let i = 0; i < itemsNumber; i++) {
      itemsList.push(
        this.toItem(
		  i,
		  "] " + intervalArray[i].min + " , " + intervalArray[i].max+" ]",
          //this.state.params[i].thresholdStart + " - " + this.state.params[i].thresholdEnd,
          itemsNumber > 1,
          this.state.params[i]
        )
      );
    }


    return (
      <div className="generic-page pt-4">
        <ThresholdRangeComponent
          min={this.state.min}
          max={this.state.max}
          step={this.state.step}
          values={this.state.values}
          colors={this.state.colors}
          onChange={(values) => this.onChangeValues(values)}
        />
        <ThresholdListComponent
          colors={this.state.colors}
          items={itemsList}
        ></ThresholdListComponent>
      </div>
    );
  }
}
