import React from 'react';

class InformatiEPreparatiItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hover: false,
			imgUrl: this.props.imgUrl
		}
	}

	toggleHover = (e) => {
		console.log(this.props.text + " " + !this.state.hover);
		console.log(e);

		var newState = !this.state.hover
		var imgUrl = this.state.hover ? this.props.imgUrlAlternative : this.props.imgUrl;
		this.setState({
			hover: newState,
			imgUrl: imgUrl
		})
        /*
        this.setState({
            imgUrl : this.props.imgUrlAlternative
        })
        */
	};

	render() {
		return (
			<>
				<div className="col-lg-6 col-xl-4" >
					<div className="informati-e-preparati-item mx-auto text-center" data-toggle="modal" data-target={"#" + this.props.id}>
						<div className="informati-e-preparati-item-caption d-flex align-items-center justify-content-center h-100 w-100">
							<div 
								className="informati-e-preparati-item-caption-content text-center text-white d-flex align-items-center py-2 px-3 bg-white" 
								style={{ border: "1px solid #242a30", width: "22rem", borderRadius: '10px' }}
							>
								<img className="img-fluid informati-e-preparati-item-img mr-3" alt={this.props.text}
									src={this.state.imgUrl}
								/>
								<h5 style={{color:"#000000"}} className='text-break'>{this.props.text}</h5>
							</div>
						</div>
					</div>
				</div>

				<div className="informati-e-preparati-modal modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="informati-e-preparatiModal1Label" aria-hidden="true">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="informati-e-preparati-modal-title text-secondary mb-0">{this.props.text}</h2>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span >
										<i className="fas fa-times"></i>
									</span>
								</button>
							</div>
							<div className="modal-body">
								<p dangerouslySetInnerHTML={{ __html: `${this.props.content}` }}></p>
								
							</div>
							<div className="modal-footer text-center">
								<button className="btn btn-primary modal-informati-e-preparati-close-button"  data-dismiss="modal">
									<i className="fas fa-times fa-fw"></i>
									Chiudi
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

}


export default InformatiEPreparatiItem;
