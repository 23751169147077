import React from 'react';
import {Navbar} from 'react-bootstrap';
import InfostreamItem from './InfostreamItem';
import PaginationComponent from '#/commons/components/PaginationComponent';
import Chip from '@material-ui/core/Chip';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TwitterIcon from '@material-ui/icons/Twitter';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import {blue, orange} from '@material-ui/core/colors';
import InfostreamClient from '#/lib/InfostreamClient';

export default class InfostreamPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			twitterFilter: true,
			rssFilter: true,
			newsType: "all"
		}
	}

	fromJsonToInfostreamItem = (obj, editable) => {
		return <InfostreamItem {...obj}  key={obj.id} editable={editable}/>;
	}

	getPage = (successCallback, errorCallback, page, type) => {
		let params = {
			newsType : this.state.newsType,
			pageNumber: page,
			pageSize: 5
		}

		InfostreamClient.getAllNewsList(
			(obj)=>{successCallback(obj, page)},
			(err)=>{errorCallback(err)},
			params
		);

	}

	handleTwitterChipClick = () => {
		const twitterFilter = !this.state.twitterFilter;
		const rssFilter = this.state.rssFilter;
		const newsType = twitterFilter && rssFilter ? "all" : (twitterFilter ? "tweet" : "rss");

		this.setState({
			twitterFilter: twitterFilter,
			rssFilter: twitterFilter===false && rssFilter===false ? true : rssFilter,
			newsType: newsType
		});

	};

	handleRSSChipClick = () => {
		const twitterFilter = this.state.twitterFilter;
		const rssFilter = !this.state.rssFilter;
		const newsType = twitterFilter && rssFilter ? "all" : (rssFilter ? "rss" : "tweet");

		this.setState({
			twitterFilter: twitterFilter===false && rssFilter===false ? true : twitterFilter,
			rssFilter: rssFilter,
			newsType: newsType
		});
	};

	render() {
		return (
			<div className="generic-page">
				<div className="container">
					<Navbar>
						<Navbar.Brand><h2>Stream News</h2></Navbar.Brand>
						<Navbar.Toggle />
						<Navbar.Collapse className="justify-content-end">
							<Chip
								variant="outlined"
								style={{ marginRight: 10}}
								color={this.state.twitterFilter ? "primary" : "secondary"}
								// icon={<TwitterIcon  style={{ color: blue[500] }}/>}
								// label="Twitter"
								icon={
									<svg fontSize={'1rem'} color={blue[500]} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
										<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
									</svg>
								}
								label="X"
								onClick={this.handleTwitterChipClick}
								onDelete={this.handleTwitterChipClick}
								deleteIcon={this.state.twitterFilter ? <DoneIcon /> : <CancelIcon />}
								clickable
							/>
							<Chip
								variant="outlined"
								color={this.state.rssFilter ? "primary" : "secondary"}
								icon={<RssFeedIcon  style={{ color: orange[500] }}/>}
								label="RSS"
								onClick={this.handleRSSChipClick}
								onDelete={this.handleRSSChipClick}
								deleteIcon={this.state.rssFilter ? <DoneIcon /> : <CancelIcon /> }
								clickable
							/>
						</Navbar.Collapse>
					</Navbar>
				</div>
				<PaginationComponent
					key={this.state.newsType}
					className = "container"
					toItemFunction={(obj) => this.fromJsonToInfostreamItem(obj, this.props.canEdit)}
					getPage={(successCallback, errorCallback,page) => this.getPage(successCallback, errorCallback, page)} />
			</div>
		)
	}
};
