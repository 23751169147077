import React from 'react';
import {Skeleton} from "@material-ui/lab";

export default class OtherSettings extends React.Component {


	constructor(props) {
		super(props);
		this.state = {

		}
	}

	render() {
		return <div className="textAlignCenter">
			<h2 >View under costruction</h2>
			<Skeleton duration={8}/>
		</div>
	}
}
