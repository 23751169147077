import React from 'react';
import UploadGeoJsonComponent from "#/commons/components/UploadGeoJsonComponent";
import {GeoJSON} from "react-leaflet";
import SettingsClient from "#/lib/SettingsClient";
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";
import moment from 'moment';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import _ from 'lodash';
import SendSharpIcon from "@material-ui/icons/SendSharp";
import Button from "@material-ui/core/Button";
import MapComponent from "#/commons/map/MapComponent";
import MosipClient from '#/lib/MosipClient'
import * as turf from "@turf/turf";


const bulletinTypes = [
	{value: 'MAU', label: 'Messaggio di Allertamento Unificato'},
	{value: 'forecast', label: 'Previsioni Meteo'}
];
const defaultGeoJsonTemplate = {geoJson : zonesGeoJson8, identifier : "ZONE", createdAt : moment().valueOf()};
const ReactSwal = withReactContent(Swal);
export default class MapSettings extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			initialMap: {},
			zoneGeoJsonMap: {},
			mapKey: 0,
			selectKey: 0
		}
	}

	componentDidMount() {
		SettingsClient.getGeojsonTemplates(
			data =>{
				let zoneGeoJsonMap = {};
				let initialMap = {};
				if (data.length > 0) {
					data.forEach(d => d.geoJson = JSON.parse(d.geoJson));
					zoneGeoJsonMap = data.reduce((obj, item) => {
						// create new options
						initialMap[item.name] = {initialIdentifier: 'ZONE', initialTemplate: item.geoJson}
						// fill actual options
						return {
							...obj,
							[item.name]: {...item, identifier: 'ZONE', dirty: false}
						};
					}, {});
					let notFoundFromAPI = bulletinTypes.filter(type => !Object.keys(zoneGeoJsonMap).includes(type.value));
					notFoundFromAPI.forEach(type => zoneGeoJsonMap[type.value] = {...defaultGeoJsonTemplate, name : type.value, dirty: true});
					console.log("Set GeoJson from API")
				} else {
					bulletinTypes.forEach(type => zoneGeoJsonMap[type.value] = {...defaultGeoJsonTemplate, name : type.value, dirty: true});
					console.log("Set Default GeoJson");
				}
				this.setState({zoneGeoJsonMap, initialMap, selectKey: (this.state.selectKey + 1) % 1000});
			},
			err => ReactSwal.fire('Errore','Si è verificato un errore nel recupero dei file zonali','error'))
	}
	handleNewGeoJsonUpload = (identifier, zoneGeoJson, name, dirty) => {
		let {zoneGeoJsonMap} = this.state;
		zoneGeoJsonMap[name] = {...zoneGeoJsonMap[name], geoJson : zoneGeoJson, identifier : identifier, dirty: dirty};
		this.setState({zoneGeoJsonMap, mapKey: (this.state.mapKey + 1) % 1000})
	}
	save = name => {
		console.log("<<<<<<<<<<<<<<<<<<<<<<<<")
		console.log(name)
		console.log(this.state.zoneGeoJsonMap[name])
		console.log("<<<<<<<<<<<<<<<<<<<<<<<<")
		let templateToSave =  _.cloneDeep(this.state.zoneGeoJsonMap[name]);
		delete templateToSave.dirty;
		// Remove points from geoJson
		templateToSave.geoJson.features = templateToSave.geoJson.features.filter(feature => feature.geometry.type !== 'Point');
		// Change identifier to 'ZONE'
		templateToSave.geoJson.features.forEach(feature => feature.properties = {'ZONE' : feature.properties[templateToSave.identifier]});
		delete templateToSave.identifier
		templateToSave.geoJson = JSON.stringify(templateToSave.geoJson);

		if(name==="MAU"){
			this.createAssociationBetweenMunicipalitiesAndZones(JSON.parse(templateToSave.geoJson))
		}

		SettingsClient.updateGeojsonTemplate(
			ok =>ReactSwal.fire('Il file zonale è stato salvato con successo','','success')
				.then(() => window.location.reload()),
			err => ReactSwal.fire('Errore','Si è verificato un errore nel salvataggio del file zonale','error'),
			templateToSave);
	}

	createAssociationBetweenMunicipalitiesAndZones = zonesGeoJson =>{
		
		MosipClient.getAllMunicipalities(
			(data) => { 
				data.forEach(municipality=>{
					let municipalityGeometry = JSON.parse(municipality.polygonGeojson);
					let mauMaxLevel = null;
					zonesGeoJson.features.forEach(feature => {
						let isMunicipalityInside = turf.booleanPointInPolygon(turf.centroid(municipalityGeometry).geometry.coordinates ,feature.geometry)
						if(isMunicipalityInside){
							console.log(feature.properties.ZONE, municipality.name, municipality.istatCode)
						}
					})
				})
				/*
				data.forEach(municipality=>{
					let municipalityGeometry = JSON.parse(municipality.polygonGeojson);
					let mauMaxLevel = null;
					zonesGeoJson.features.forEach(feature => {
						let isMunicipalityInside = turf.booleanPointInPolygon(turf.centroid(municipalityGeometry).geometry.coordinates ,feature.geometry)
						if(isMunicipalityInside){
							console.log(municipality.properties.ZONE, municipality)
						}
					});
				})
				*/
				
			}, 
			() => { console.log("ERROR COMUNI"); }
		);
	}

	

	render() {
		return (
			<>
				<div className="row mt-4">
					<div className="col-2 textAlignCenter">
						<h2>Tipo bollettino</h2>
					</div>
					<div className="col-3 textAlignCenter">
						<h2>Upload file</h2>
					</div>
					<div className="col-5 textAlignCenter">
						<h2>Anteprima</h2>
					</div>
					<div className="col-2 textAlignCenter">

					</div>
				</div>

				{bulletinTypes.map(type => (
					<div className="row mt-4">
						<div className="col-2 textAlignCenter">
							<h3>{type.label}</h3>
						</div>
						<div className="col-3 textAlignCenter">
							<UploadGeoJsonComponent
								key={type.value + "_" + this.state.selectKey}
								initialTemplate={!!this.state.initialMap[type.value] ? this.state.initialMap[type.value].initialTemplate : null}
								initialIdentifier={!!this.state.initialMap[type.value] ? this.state.initialMap[type.value].initialIdentifier : null}
								handleNewGeoJsonUpload={(identifier, zoneGeoJson, dirty ) => this.handleNewGeoJsonUpload(identifier, zoneGeoJson, type.value, dirty)}/>
						</div>
						<div className="col-5" style={{textAlign: '-webkit-center'}}>
							{!_.isEmpty(this.state.zoneGeoJsonMap) ?
								<SimpleZonalMap mapKey={`${type.value}_${this.state.mapKey}`}
												identifier={this.state.zoneGeoJsonMap[type.value].identifier}
												zoneGeoJson={this.state.zoneGeoJsonMap[type.value].geoJson}/> : <></>}
						</div>
						<div className="col-2 textAlignCenter">
							{!_.isEmpty(this.state.zoneGeoJsonMap) ?
								<Button
									disabled={!this.state.zoneGeoJsonMap[type.value].dirty}
									size="large"
									className="justify-content-end ml-auto"
									variant="contained"
									color="primary"
									startIcon={<SendSharpIcon />}
									onClick={(e) => this.save(type.value)}
								>Salva Modifiche</Button> : <></>}
						</div>
					</div>))}

			</>
		)
	}
}

const defaultPolygonStyle = {
	fillColor: "#fff0",
	weight: 0.6,
	opacity: 1,
	color: "blue",
	fillOpacity: 0.5
};
export class SimpleZonalMap extends React.Component {

	onEachFeature(feature, layer, context) {
		layer.bindTooltip(`<b>${feature.properties[this.props.identifier]}</b>`);
		layer.on({
			'mouseover': (e) => {
				layer.setStyle({
					fillColor: "rgba(111,205,147,0.88)",
					weight: 0.5,
					opacity: 1,
					color: "#23a555",
					fillOpacity: 0.5
				});
			},
		})
		layer.on({
			'mouseout': (e) => {
				layer.setStyle(defaultPolygonStyle);
			},
		})
	}
	pointToLayer = (feature, latlng) => {
	}

	render()
	{
		return (

			<MapComponent
				width={"80%"}
				height={"60vh"}
				backgroundColor={'rgba(255,0,0,0.0)'}
				zoomSnap={false}
				dragging={true}
				scrollWheelZoom={true}
				zoomControl={true}
				doubleClickZoom={false}
				buttonKey={this.props.mapKey}>

				{!!this.props.zoneGeoJson ?
					<GeoJSON key={"polygons_" + this.props.mapKey}
							 data={this.props.zoneGeoJson}
							 style={defaultPolygonStyle}
							 onEachFeature={(feature, layer, context) => this.onEachFeature(feature, layer, this)}
							 pointToLayer={(feature, latlng) => this.pointToLayer(feature, latlng)}

					/> : <></>}
			</MapComponent>
		)
	}
}

