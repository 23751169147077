import { properties} from '#/properties.js';

import parse_georaster from "georaster"
import APIUtils from "#/lib/APIUtils";
import AuthenticationService from "#/lib/AuthenticationService";


export default class WrfClient {

	static getAvailableWrfLayersBySource(okCallback, errCallback, source){
		let url = properties.url.wrfLayers + "/" + source
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}


	static getWrfDataByLayerAndSource(okCallback, errCallback,  layer, source){
		let url = properties.url.wrfDataByLayer + "/" + source + "/" + layer;
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}


	static getWrfByTiffPath = (okCallback, errCallback, tiffPath) => {
		let url = new URL(properties.url.wrfImageByPath + "/" + tiffPath);
		fetch(url)
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				console.log("arrayBuffer", arrayBuffer);
				let timeout = setTimeout(() => {errCallback()}, 10000);
					parse_georaster(arrayBuffer).then(georaster => {
						clearTimeout(timeout);
						okCallback(georaster)
					}).catch( err =>{
					errCallback(err)
				})
			})
			.catch((error) => {
				 errCallback(error);
			});
	};

	static getWrfByPngPath = (okCallback, errCallback,pngPath) => {
		let url = new URL(properties.url.wrfImageByPath + "/" + pngPath);
		fetch(url)
			.then(response => response.blob())
			.then(image => {
				okCallback(URL.createObjectURL(image));
			})
	};
	static getTiffFromWrfData = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.wrfCombinedData);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8', "Authorization": AuthenticationService.getLoggedInToken() },
			method: 'POST',
			body: JSON.stringify(params)
		};

		fetch(url, fetch_options)
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				okCallback(arrayBuffer)
			})
			.catch((error) => {
				return errCallback(error);
			});
	};

}
