import React from "react";
import moment from 'moment';

import {BUTTON_PROGRESS_STYLE} from "#/backoffice/bim/utils/BIMUtils";
import CircularProgress from '@material-ui/core/CircularProgress';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton, SkipButton} from "#/commons/components/forms/NextButton";
import {SelectForm} from "#/commons/components/forms/SelectForm";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ChromaticScale, {statsTDomain, temperatureDomain} from "#/lib/ChomaticScale";
import {GeoJSON} from "react-leaflet";
import GISTools from "#/lib/GISTools";
import * as turf from "@turf/turf";
import external_points from "#/commons/geodata/external_points.json";
import AnalysisClient from "#/lib/AnalysisClient";
import LeafletTemperatureLegend from "#/commons/components/forms/LeafletTemperatureLegend";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import IdwClient from "#/lib/IdwClient";
import {SelectRangeForm} from "#/commons/components/forms/SelectRangeForm";

import DateUtils from "#/lib/DateUtils";
import DownloadContainer from "#/commons/components/DownloadContainer"
import MapComponent from "#/commons/map/MapComponent";
import LeafletLegend from "#/commons/map/LeafletLegend";
import {LAYER_OPTIONS} from "#/lib/MidaUtils";


const temperatureDeviationStats = {
	minDeviation : 'Anomalia mensile della media delle minime',
	avgDeviation : 'Anomalia mensile della media delle medie',
	maxDeviation : 'Anomalia mensile della media delle massime'
}
const temperatureStats = {
	monthMin : 'Media delle minime',
	monthAvg : 'Media delle medie',
	monthMax : 'Media delle massime',
	...temperatureDeviationStats
};
const rangeOptions = [
	{ value: "", label: "Seleziona" },
	{
		label : "1961 - 1990",
		value : [1961,1990]
	},
	{
		label : "ultimo trentennio",
		value : [moment().year() - 30,moment().year()]
	}
]
const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});

export class StepMonitoraggioTemperature extends React.Component {

	section = "monitoraggio";
	images = ["img_minime", "img_medie", "img_massime", "img_minime_anomalie", "img_medie_anomalie",  "img_massime_anomalie"];
	refMonthName = !!this.props.formData ? this.props.formData.reference_month : DateUtils.getMonthNameFromMonthNumber( this.props.month);
	inputData = !!this.props.formData ? this.props.formData[this.section]["inputData"] : null;
	hasBeenModified = false;

	constructor(props) {
		super(props);
		this.state = {
			enableSave: this.props.viewMode,
			showModal: false,
			calculating: false,
			loading: false,
			mapKey: 1,
			statsData: [],
			file: null,
			zoneGeoJson: null,
			identifierCandidates: null,
			geoJsonIdentifier: null,
			rasterEnabled: true,
			selectedRange : []
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		if (!!this.inputData) {
			this.setState({...this.inputData});
		}
	}
	handleSkipStep = () => {
		if(this.props.context==="BIM") {
			this.props.handleNext(true); // skip = true
		}
	}
	handleNextStep = () => {
		if(this.props.context==="BIM"){
			if (this.props.viewMode) {
				this.props.handleNext();
			} else {
				let statsData = this.state.statsData;
				let formData = this.props.formData;
				if (!!statsData) {
					if (this.hasBeenModified) {
						loadingSwal.fire('Salvataggio immagini in corso...');
						this.setState({loading: true});
						const promises = [];
						this.images.map( (img) => {
							return promises.push(this.props.handleSaveImg(img));
						})
						Promise.all(promises)
							.then((response) => {
								let idx = 0;
								this.images.map( (img) => {
									return formData[this.section][img] = response[idx++];
								});
								formData[this.section]["inputData"] = {...this.state, loading: false};
								formData[this.section]["historicalPeriodString"] = rangeOptions
									.find(opt => opt.value === this.state.selectedRange).label;
								ReactSwal.close();
								this.props.handleNext();
							})
							.catch(error => {this.setState({loading: false}); console.log(`Error in executing ${error}`);})
					} else {
						this.props.handleNext();
					}
				} else {
					ReactSwal.fire(
						"Redazione Bollettino Idrologico/Siccità",
						"Selezionare un GeoJSON e calcolare le statistiche prima di procedere",
						"error"
					);
				}
			}
		}
	}

    handleSelectGeoJSON = (valueSelected) => {
        let options = this.props.selectOptions;
        let selectedOption = options.filter(opt => opt.value === valueSelected);
        let geoJSON = selectedOption[0].value;
        let geoJsonIdentifier = selectedOption[0].identifier;
        if (geoJsonIdentifier==="__NEW__" && geoJSON === "NEW") {
            this.openModal();
        } else {
            this.setState({
                zoneGeoJson: geoJSON,
                tableKey: (this.state.tableKey + 1) % 1000,
                mapKey: (this.state.mapKey + 1) % 1000,
                geoJsonIdentifier: geoJsonIdentifier
            })
        }
        this.hasBeenModified = true;
	};

	onClickCalculate = () => {
		this.hasBeenModified = true;
		loadingSwal.fire('Calcolo dei valori di temperatura statistici...');
		this.setState({calculating:true}, this.loadTemperatureStatsFromAPI());
	}

	loadTemperatureStatsFromAPI = () => {
		AnalysisClient.getTemperatureStats(
			this.state.selectedRange,
			this.props.year,
			this.props.month + 1,
			(data)=>{

				console.log("Start interpolating features...")

				let promises = [];
				Object.keys(temperatureStats).forEach(key => {

					promises.push(new Promise((resolve, reject) => {
						IdwClient.performIdw(
							(interpGridMap)=>	resolve({key, interpGridMap}),
							(error)=>{console.log("Error performing IDW -> Trying to perform IDW locally ");reject(error) },
							data,
							key
						)
					}));
				})
				Promise.all(promises)
					.then((response) => {
						console.log("FROM IDW",response);
						let interpGridMap = {};
						response.forEach( r => {
							interpGridMap[r.key] = r.interpGridMap;
						})
						ReactSwal.close();
						this.setState({
							statsData: data,
							interpGridMap : interpGridMap,
							mapKey: (this.state.mapKey + 1) % 1000,
							calculating: false,
							enableSave: true
						})
					}).catch(() => {
					let interpGridMap = {};
					console.log("Performing IDW locally - START")
					Object.keys(temperatureStats).forEach(key => {
						interpGridMap[key] = this.interpolate(data, 5, key);
					})
					ReactSwal.close();
					this.setState({
						statsData: data,
						interpGridMap : interpGridMap,
						rasterEnabled : false,
						mapKey: (this.state.mapKey + 1) % 1000,
						calculating: false,
						enableSave: true
					})

				});

			},
			()=>{
				console.log('Problems retrieving Temperature Stats data!');
				this.setState({calculating: false});
				ReactSwal.hideLoading();
				ReactSwal.fire('Si è verificato un errore nel calcolo delle statistiche ', '', 'error');
			}
		)
	}

	interpolate = (points,cellSize,field) => {
		cellSize = !!cellSize ? cellSize : 5;
		console.time("Inverse Distance Weighting")
		turf.featureEach(external_points, (point) => {
			let nearest = turf.nearestPoint(point, points);
			point.properties = {...nearest.properties}
		})
		let pointsAll = turf.featureCollection([...external_points.features, ...points.features]);
		let options = {gridType: 'square', property: field};
		let grid =  turf.interpolate(pointsAll, cellSize, options);
		return grid;
	}

	handleChangeStatusZone = ({ file }, status) => {
		if (status === "removed") {
			this.setState({
				enableSave: false,
				loading: false,
				mapKey: (this.state.mapKey + 1) % 1000,
				statsData: [],
				file: null,
				zoneGeoJson: null,
				identifierCandidates: null,
				geoJsonIdentifier: null
			})
		}
		if (status === "done") {
			const reader = new FileReader();
			reader.onload = (event) => {
				const fileContent = event.target.result;
				let geoJSON = JSON.parse(fileContent);
				const features = geoJSON.features;
				if (!!features) {
					this.setState({
						file: file,
						zoneGeoJson: geoJSON,
						mapKey: (this.state.mapKey + 1) % 1000,
						showModal : !this.state.showModal
					})
				}
			};
			reader.onerror = (event) => {
				ReactSwal.hideLoading();
				ReactSwal.fire('Si è verificato un errore nella lettura delle zone', '', 'error');
			};
			reader.readAsText(file);
		}
	}
	handleSelectRange = (valueSelected) => {
		this.setState({
			selectedRange : valueSelected
		})
	};

	openModal = () => {
		this.setState({showModal: true});
	}

	onCloseModal = () => {
		this.setState({showModal: false})
	}

	enableZonalStatsCalculation = () => !!this.props.month+1 && !!this.props.year && !!this.state.zoneGeoJson;

	render() {
		let title = this.props.stepLabel + ' ' + DateUtils.getMonthNameFromMonthNumber( this.props.month) + ' ' + this.props.year
		return (
			<>
				<div className="row justify-content-center">
					<h2>{title}</h2>
				</div>
				{
					!this.props.viewMode &&
					<div className="row">
						<div className="col-4">
							<SelectForm
								id="select_default_GeoJSON"
								label="Seleziona file precaricati"
								className="mt-3 col-8 row justify-content-center"
								valueSelected={{id:0}}
								options={this.props.selectOptions}
								selectedValue = {this.props.selectedGeoJSON}
								handleOnChange = {this.handleSelectGeoJSON}
							/>
						</div>
						<div className="col-4">
							<SelectRangeForm
                                id="select_default_GeoJSON"
                                label="Seleziona il trentennio di riferimento"
                                className="mt-3 col-8 row justify-content-center"
                                valueSelected={{id:0}}
                                options={rangeOptions}
                                selectedRange = {this.state.selectedRange}
                                handleSelectRange = {this.handleSelectRange}
                            />
						</div>
						<div className="col-4 mt-4">
							<Button
								disabled={!this.enableZonalStatsCalculation() || this.state.calculating || this.state.loading || this.state.selectedRange.length === 0}
								size="large"
								className="justify-content-end ml-auto"
								variant="contained"
								color="primary"
								onClick={(e) => this.onClickCalculate()}
							>Calcola Statistiche</Button>
						</div>
					</div>
				}
				<div className="row mt-4">
					{Object.keys(temperatureStats).map((key,i) =>
						!this.props.viewMode ?
							<TemperatureMapComponent key = {i}
													id = {this.images[i]}
													interpolatedData = {!!this.state.interpGridMap  ? this.state.interpGridMap[key]  : null}
													rasterEnabled = {this.state.rasterEnabled}
													zoneGeoJson = {this.state.zoneGeoJson}
													mapKey = {this.state.mapKey}
													field = {key}/>
						:
							<div className="col-4" key={i} >
								<div className="custom-popup">
									<h3>{temperatureStats[key]}</h3>
									<img id={this.images[i]} alt={this.images[i]} src={this.props.formData[this.section][this.images[i]]}/>
								</div>
							</div>
					)}
				</div>

				<MobileStepper
					style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
					position="static"
					variant="text"
					activeStep={this.props.activeStep}
					steps={this.props.totalSteps}
					nextButton={this.props.context === "BIM"
						?
							<div style={{position: 'relative'}}>
								<SkipButton
									variant="contained"
									color="primary"
									disabled={this.state.calculating || this.state.loading }
									onClick={this.handleSkipStep}
									textbutton={this.props.textSkipButton}
								/>
								<NextButton
									variant="contained"
									color="primary"
									disabled={this.state.calculating || this.state.loading || !this.state.enableSave}
									onClick={this.handleNextStep}
									textbutton={this.props.textNextButton}
								/>
								{this.state.loading && <CircularProgress size={24} style={BUTTON_PROGRESS_STYLE} />}
							</div>
						:
							<></>
					}
					backButton={
						<BackButton
							variant="contained"
							disabled={this.props.disableBackButton || this.state.calculating || this.state.loading}
							onClick={this.props.handleBack}
						/>
					}
				/>

				<Modal
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					open={this.state.showModal}
					onClose={() => this.onCloseModal()}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "50vw",
							height: "50vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.onCloseModal()}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3 >Import Zone da file</h3>
						</div>


						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<Dropzone
									onChangeStatus={this.handleChangeStatusZone}
									accept=".geojson,.json"
									maxFiles={1}
									inputContent="Inserisci il file delle zone in formato GeoJSON"
									styles={{
										dropzone: { overflow: "hidden", width: "30vw" },
										dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
										inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: "#315495" }),
									}}
								/>
							</div>
						</div>
						<br/>


					</div>

				</Modal>
			</>
		)
	}
}


export class TemperatureMapComponent extends React.Component {

	onEachInterpolatedFeatureStations(feature, layer, context) {
	}


	gradientStyle = (feature ) => {
		const scale = temperatureDeviationStats.hasOwnProperty(this.props.field) ? ChromaticScale.getTemperatureStatsScale() : ChromaticScale.getScale('T'); ;
		const color = scale(feature.properties[this.props.field]);
		return {
			fillColor: color,
			weight: 0.3,
			color: 'transparent',
			opacity: 1,
			fillOpacity: 0.7
		};
	};

	render() {
		return (
			<div className="col-4">
				<div className="custom-popup">
					<h3>{temperatureStats[this.props.field]}</h3>
					<DownloadContainer 
								imageName={temperatureStats[this.props.field]}
								disabled={!!!this.props.interpolatedData}
							>
							<MapComponent
								id = {this.props.id}
								attributionControl={false}
								width={'100%"'}
								height={'60vh'}
								backgroundColor={'rgba(255,0,0,0.0)'}
								dragging={false}
								scrollWheelZoom = {false}
								zoomControl = {false}
								doubleClickZoom = {false}
								minZoom = {7}
								zoom ={8}
								noHome={true}
								buttonKey={this.props.field}
								setMapRef={mapRef => this.mapRef = mapRef}>

							{!!this.props.zoneGeoJson ?
								<GeoJSON key={"polygons_" + this.props.mapKey}
										data={this.props.zoneGeoJson}
										style={{
											fillColor:  "#fff0",
											weight: 0.6,
											opacity: 1,
											color: "blue",
											fillOpacity: 0.5
										}}
								/> : <></>}

							{!!this.props.interpolatedData
								?
								<>
									{this.props.rasterEnabled ?
										<GeotiffRasterLayer
											key={"raster_" + this.props.mapKey}
											georaster={this.props.interpolatedData}
											opacity={0.5}
											resolution={256}
											colorScale={temperatureDeviationStats.hasOwnProperty(this.props.field) ? ChromaticScale.getTemperatureStatsScale() : ChromaticScale.getScale('T')}
											handleClick = {(val,latlng) => GISTools.showPopup(val,latlng,this.mapRef.leafletElement)}
										/> :
										<GeoJSON key={"idw_" + this.props.mapKey}
												data={this.props.interpolatedData}
												onEachFeature={(feature, layer, context) => this.onEachInterpolatedFeatureStations(feature, layer, this)}
												style={(feature) => this.gradientStyle(feature)}/>
									}
									<LeafletLegend
										size="big"
										replaceMode={false}
										grades={temperatureDeviationStats.hasOwnProperty(this.props.field) ? statsTDomain : temperatureDomain}
										getColor={(num)=>temperatureDeviationStats.hasOwnProperty(this.props.field) ? ChromaticScale.getTemperatureStatsScale()(num) : ChromaticScale.getScale('T')(num)}/>


								</>
								:
								<></>
							}
						</MapComponent>
					</DownloadContainer>
				</div>
			</div>
		)
	}
}
