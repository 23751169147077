import React, {useState, useEffect} from 'react';
import ReactTabulator from "react-tabulator/lib/ReactTabulator";

import StaticPageClient from '#/lib/StaticPageClient'
const data = [
    {
        name:'Sala Operativa',
        phone: '0961/531131',
        role: ''},
    {
        name:'Fax Sala Operativa',
        phone: '0961/531122',
        role: ''},
    {
        name:'Fax Amministrazione',
        phone: '0961/531161',
        role: ''
    },{
        name:'Info',
        phone: '0961/531111',
        role: ''
    },{
        name:'ing. Eugenio Filice', role: 'Dirigente',
        phone: '0961/531153'
    },{
        name:'ing. Arcuri Salvatore', role: 'Collaboratore tecnico professionale',
        phone: '0961/531127'
    },{
        name:'ing. Chiappetta Francesc', role: 'Collaboratore tecnico professionale',
        phone: '0961/531164'
    },{
        name:'ing. Fusto Francesco', role: 'Collaboratore tecnico professionale',
        phone: '0961/531189'
    },{
        name:'sig.ra Bollino Maria Domenica', role: 'Assistente tecnico',
        phone: '0961/531154'
    },{
        name:'ing. Guerriero Antonio', role: 'Collaboratore tecnico professionale',
        phone: '0961/531157'
    },{
        name:'ing. Marsico Loredana', role: 'Collaboratore tecnico professionale',
        phone: '0961/531151'
    },{
        name:'sig.ra Mazza Romana', role: 'Collaboratore amministrativo professionale esperto',
        phone: '0961/531163'
    },{
        name:'sig. Montorsi Ettore', role: 'Operatore tecnico specializzato',
        phone: '0961/531158'
    },{
        name:'ing. Poerio Piterà Paolo', role: 'Collaboratore tecnico professionale',
        phone: '0961/531150'
    },{
        name:'arch. Raffaele Francesco', role: 'Collaboratore tecnico professionale',
        phone: '0961/531165'
    },{
        name:'arch. Raso Roberta', role: 'Collaboratore tecnico professionale esperto',
        phone: '0961/531162'
    },{
        name:'ing. Rotundo Roberta', role: 'Collaboratore tecnico professionale',
        phone: '0961/531171'
    },{
        name:'ing. Russo Gino', role: 'Collaboratore tecnico professionale',
        phone: '0961/531152'
    },{
        name:'Dott.ssa Anna Maria Grazioso', role: 'Collaboratore tecnico professionale',
        phone: '0961/531172'
    }];

const ContattiPage = () => {
    const [page, setPage] = useState(null)

	useEffect(()=>{
		StaticPageClient.getByCode(
			(sp)=>{
				console.log(sp)
				setPage(sp.code)
			},
			()=>{},
			"contacts"
		)
	})

    return<div className="generic-page container-fluid textAlignCenter">
		{!!page && <p dangerouslySetInnerHTML={{ __html: `${page}` }}></p>}
        {/*<><h2>Elenco contatti C.F.M.</h2>
        <ReactTabulator
            columns={[{title: "Nome", field: 'name', headerFilter: true,  headerFilterPlaceholder:"Filtra per nome..."},{title: "Ruolo", field: 'role'},{title: "Telefono", field: 'phone', headerSort: false}]}
            data={data}
            options={{height: '70vh'}}
            key={"contacts"}/></>*/}
    </div>;
}

export default ContattiPage;
