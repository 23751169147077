
export const rainValidationOptions = {
	VALID: "Dato valido",
	MISSING: "Dato mancante",
	SNOW: "Precipitazione Nevosa",
	UNCERTAIN: "Dato incerto",
	CUM_RAIN: "Dato cumulato (pioggia)",
	CUM_SNOW: "Dato cumulato (neve)",
	RAIN_DROP: "Gocce di Pioggia",
	SNOW_NOT_MEASURED: "Precipitazione nevosa *",
	IDW : "Dato interpolato",
	SUSPECT : "Dato Sospetto",
	NOT_VALID : "Dato errato",
	NOT_VALIDATED : "Dato non validato"
}

export const rainValidationColors = {
	VALID: "rgba(16,187,34,0.8)",
	MISSING: "#ff00008a",
	SNOW: "rgba(234,129,49,0.8)",
	UNCERTAIN: "rgba(234,129,49,0.8)",
	CUM_RAIN: "rgb(255,255,255)",
	CUM_SNOW: "rgba(234,129,49,0.8)",
	RAIN_DROP: "rgba(234,129,49,0.8)",
	SNOW_NOT_MEASURED: "rgba(234,129,49,0.8)",
	IDW : "#31a9e4",
	SUSPECT : "rgba(234,129,49,0.8)",
	NOT_VALID : "rgba(241,46,2,0.8)",
	NOT_VALIDATED : "rgb(255,255,255)"
}

export const rainValidationSIMN = {
	VALID: "0",
	MISSING: "M",
	SNOW: "L",
	UNCERTAIN: "B",
	CUM_RAIN: "A",
	CUM_SNOW: "X",
	RAIN_DROP: "G",
	SNOW_NOT_MEASURED: "F",
	IDW : "C",
	SUSPECT : "-",
	NOT_VALID : "-",
	NOT_VALIDATED : "-"
}
export const rainValidationISPRA = {
	VALID: "1",
	MISSING: "6",
	SNOW: "-",
	UNCERTAIN: "-",
	CUM_RAIN: "-",
	CUM_SNOW: "-",
	RAIN_DROP: "-",
	SNOW_NOT_MEASURED: "-",
	IDW : "5",
	SUSPECT : "2",
	NOT_VALID : "3",
	NOT_VALIDATED : "4"
}

export const temperatureValidationOptions = {
	VALID: "Dato valido",
	MISSING: "Dato mancante",
	MISSING_MAX: "Massimo mancante",
	MISSING_MIN: "Minimo mancante",
	UNCERTAIN: "Dato incerto",
	UNCERTAIN_MAX: "Massimo incerto",
	UNCERTAIN_MIN: "Minimo incerto",
	IDW : "Dato interpolato",
	SUSPECT : "Dato Sospetto",
	NOT_VALID : "Dato errato",
	NOT_VALIDATED : "Dato non validato"
}

export const temperatureValidationColors = {
	VALID: "rgba(16,187,34,0.8)",
	MISSING: "#ff00008a",
	MISSING_MAX: "#ff00008a",
	MISSING_MIN: "#ff00008a",
	UNCERTAIN: "rgba(234,129,49,0.8)",
	UNCERTAIN_MAX: "rgba(234,129,49,0.8)",
	UNCERTAIN_MIN: "rgba(234,129,49,0.8)",
	IDW : "#31a9e4",
	SUSPECT : "rgba(234,129,49,0.8)",
	NOT_VALID : "rgba(241,46,2,0.8)",
	NOT_VALIDATED : "rgb(255,255,255)"
}

export const temperatureValidationSIMN = {
	VALID: "0",
	MISSING: "M",
	MISSING_MAX: "Q",
	MISSING_MIN: "T",
	UNCERTAIN: "B",
	UNCERTAIN_MAX: "R",
	UNCERTAIN_MIN: "S",
	IDW : "C",
	SUSPECT : "-",
	NOT_VALID : "-",
	NOT_VALIDATED : "-"
}

export const temperatureValidationISPRA = {
	VALID: "1",
	MISSING: "6",
	MISSING_MAX: "-",
	MISSING_MIN: "-",
	UNCERTAIN: "-",
	UNCERTAIN_MAX: "-",
	UNCERTAIN_MIN: "-",
	IDW : "5",
	SUSPECT : "2",
	NOT_VALID : "3",
	NOT_VALIDATED : "4"
}

export const outflowValidationOptions = {
	VALID: "Dato valido",
	MISSING: "Dato mancante",
	SUSPECT : "Dato Sospetto",
	NOT_VALID : "Dato errato",
	NOT_VALIDATED : "Dato non validato"
}
export const outflowValidationColors = {
	VALID: "rgba(16,187,34,0.8)",
	MISSING: "#ff00008a",
	SUSPECT : "rgba(234,129,49,0.8)",
	NOT_VALID : "rgba(241,46,2,0.8)",
	NOT_VALIDATED : "rgb(255,255,255)"
}

export const outflowValidationSIMN = {
	VALID: "0",
	MISSING: "M",
	SUSPECT : "B",
	NOT_VALID : "-",
	NOT_VALIDATED : "-"
}

export const outflowValidationISPRA = {
	VALID: "1",
	MISSING: "6",
	SUSPECT : "2",
	NOT_VALID : "3",
	NOT_VALIDATED : "4"
}
