import React from "react";
import DateUtils from "#/lib/DateUtils";

//import { DEFAULT_MAPS } from "#/backoffice/mau/utils/MAUConst";
import {OPTIONAL_MAP_FILES, SINGLE_STEPS} from "#/backoffice/bim/utils/BIMUtils";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {zonesGeoJson8} from "#/lib/ZoneGeoJsonDefault";

export default class BollettinoIdrologicoMeseSingleStepPage extends React.PureComponent {


	static whyDidYouRender = false;
  steps = undefined;
  totalSteps = 0;
  today = new Date();
  refMonthNumber = this.today.getMonth() + 1;
  refYear = this.refMonthNumber === 0 ? this.today.getFullYear()-1 : this.today.getFullYear();
  refMonthName = DateUtils.getMonthNameFromMonthNumber(this.refMonthNumber);
  mapKey = 0;

  constructor(props) {
	super(props);
	switch(this.props.context){
		case "PRECIPITAZIONI_MESE":
			this.steps = SINGLE_STEPS["PRECIPITAZIONI_MESE"];
			break;
		case "SPI":
			this.steps = SINGLE_STEPS["SPI"];
			break;
		case "MONITORAGGIO_TEMPERATURE":
			this.steps = SINGLE_STEPS["MONITORAGGIO_TEMPERATURE"];
			break;
		case "DEFLUSSI":
			this.steps = SINGLE_STEPS["DEFLUSSI"];
			break;
		default:
			this.steps = SINGLE_STEPS["PRECIPITAZIONI_MESE"];
			break;
	}
    this.totalSteps = this.steps.length;
    const url = window.location.href;
    const idString = url.split("/").pop();
    const id = parseInt(idString);
    this.state = {
      id: !!id ? id : null,
      selectedMonth: this.today.getMonth() + 1,
      selectedYear: this.today.getFullYear(),
      monthName : DateUtils.getMonthNameFromMonthNumber(this.today.getMonth()),
      activeStep: 0,
    };
  }

  componentDidMount = () => {
	  this.setState({
		  activeStep : 0
	  })

  }
    componentWillReceiveProps(nextProps, nextContext) {
        switch(nextProps.context){
            case "PRECIPITAZIONI_MESE":
                this.steps = SINGLE_STEPS["PRECIPITAZIONI_MESE"];
                break;
            case "SPI":
                this.steps = SINGLE_STEPS["SPI"];
                break;
            case "MONITORAGGIO_TEMPERATURE":
                this.steps = SINGLE_STEPS["MONITORAGGIO_TEMPERATURE"];
                break;
            case "DEFLUSSI":
                this.steps = SINGLE_STEPS["DEFLUSSI"];
                break;
            default:
                this.steps = SINGLE_STEPS["PRECIPITAZIONI_MESE"];
                break;
        }
        this.totalSteps = this.steps.length;
    }


    changeYearMonthRef = (month, year) => {
	    let monthName = DateUtils.getMonthNameFromMonthNumber( month);

	    this.setState({
          selectedMonth: month + 1,
          selectedYear: year,
          monthName : monthName
        })

	  }




  handleNext = () => {
    const prevActiveStep = this.state.activeStep;
    const nextStep = prevActiveStep + 1;
    this.setState({activeStep: nextStep});
    
  };

  handleBack = () => {
    const prevActiveStep = this.state.activeStep;
    this.setState({
      activeStep: prevActiveStep - 1,
    });
  };



  getStepContentComponent = (stepIndex) => {
    let commonsProps = {
      viewMode : this.state.viewMode,
      stepLabel : this.steps[stepIndex].label,
      totalSteps : this.totalSteps,
      activeStep : stepIndex,
      month : this.state.selectedMonth-1,
      year : this.state.selectedYear,
      disableBackButton : !stepIndex,
      textNextButton : "Successivo",
      handleSaveImg : this.convertHtmltoImg,
      changeYearMonthRef : this.changeYearMonthRef,
      handleNext : this.handleNext,
      handleBack : this.handleBack,
	  selectOptions: [{ value: "", label: "Seleziona", identifier : null }, ...OPTIONAL_MAP_FILES],

    }
    let comp = this.steps[stepIndex].component(commonsProps);
    return comp;
  };

  render() {

    return (
      <div className="generic-page container-fluid data-page" >
        <div>
          <Stepper activeStep={this.state.activeStep} alternativeLabel>
            {this.steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
              <div>
                
                  {this.getStepContentComponent(this.state.activeStep)}
              </div>
          </div>
        </div>
      </div>
    );
  }
}

