import {MapControl, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "#/commons/map/LeafletLegend.css";

class LeafletWindLegend extends MapControl {
	createLeafletElement(props) {}

	componentWillUnmount(){}
	
    componentDidMount() {

		const legend = L.control({ position: "bottomright" });
		let labels = [];

        legend.onAdd = () => {
			let classNames = !!this.props.replaceMode ?  "info legend newLegend " : "info legend ";
            const div = L.DomUtil.create("div", classNames);
			const path = '/img/wind_directions/';
				let legendArray = [];
				legendArray.push({range:"< 1", img: null/*path + "wind_0.svg"*/})
				legendArray.push({range:"1 - 2.5", img: path + "wind_5.svg"})
				legendArray.push({range:"2.5 - 5", img: path + "wind_10.svg"})
				legendArray.push({range:"5 - 10", img: path + "wind_20.svg"})
				legendArray.push({range:"10 - 12.5", img: path + "wind_25.svg"})
				legendArray.push({range:"12.5 - 25", img: path + "wind_50.svg"})
				legendArray.push({range:"> 25", img: path + "wind_max.svg"})




			legendArray.forEach(opt => {
					labels.push(`
					<div style="display:inline-flex">
						<div style="width:50px; height:30px">
							${!!opt.img ? `<img src=${opt.img}	style="max-width: 100%; max-height: 100%; transform: rotate(90deg) translate(-50%,-50%)" ></img>` :""}
						</div>
						<span> ${opt.range}</span>
					</div>`);
			});

			div.innerHTML = labels.join("<br>");
            return div;
        };

        const { map } = this.props.leaflet;

        let tmp = document.getElementsByClassName("newLegend");
        if (!!this.props.replaceMode && !!tmp && tmp.length > 0) {
            tmp[0].remove()
        };
        legend.addTo(map);

    }
}

export default withLeaflet(LeafletWindLegend);
