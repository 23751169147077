import React from "react";

import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipNextIcon from '@material-ui/icons/SkipNext';

export const NextButton = (props) => {
	return <Button color="secondary" style={{ margin: "2vh", minWidth: "10vw" }}  endIcon={<NavigateNextIcon />} {...props}>{!!props.textbutton ? props.textbutton : 'Successivo'}</Button>;
}

export const SkipButton = (props) => {
	return <Button color="secondary" style={{ margin: "2vh", minWidth: "10vw" }}  endIcon={<SkipNextIcon />} {...props}>{!!props.textbutton ? props.textbutton : 'Successivo'}</Button>;
}
