import React from 'react';
import {SvgLoader, SvgProxy} from "react-svgmt";

export default class SvgHolderComponent extends React.Component {


    onSvgReady(){
		let svgDomElement = document.getElementById(this.props.id);
		if(this.props.onClickActions){
			Object.keys(this.props.onClickActions).forEach((value)=>{

				svgDomElement.getElementById(this.props.baseZoneId+value).addEventListener('click', this.props.onClickActions[value]);
			})
		}
    }

    render(){

        let proxys = [];
        Object.keys(this.props.svgState).forEach((key)=>{
            let svgProxy = <SvgProxy key={key} selector={"#"+this.props.baseZoneId+key} style={"fill:"+this.props.palette[this.props.svgState[key]]+";fill-opacity:1;cursor:grab"} />
            proxys.push(svgProxy);
        })
		let dimInVh = 50 * (this.props.scaleFactor || 1);
        return (

            <SvgLoader id={this.props.id} width={dimInVh+"vh"} height={dimInVh+"vh"} path={this.props.svgUrl} onSVGReady={(svg) => this.onSvgReady()} >
                {proxys}
            </SvgLoader>
        );
    }

}
