import React from "react";

import {BUTTON_PROGRESS_STYLE} from "#/backoffice/bim/utils/BIMUtils";
import CircularProgress from '@material-ui/core/CircularProgress';

import MobileStepper from '@material-ui/core/MobileStepper';
import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton} from "#/commons/components/forms/NextButton";

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';

import BulletinClient from "#/lib/BulletinClient"

import 'react-dropzone-uploader/dist/styles.css'

import DatePicker from "react-datepicker";
import it from 'date-fns/locale/it';

import moment from 'moment';


export class StepSelectMonth extends React.PureComponent {

    static whyDidYouRender = false;
    section = "rainfalls";
    img = "img";
	images = [this.img];
	refMonthName = !!this.props.formData ? this.props.formData.reference_month : null;
	inputData = !!this.props.formData ? this.props.formData[this.section]["inputData"] : null;
	

    constructor(props) {
      super(props);
      this.state = {
        selectedMonth: this.props.month,
		selectedYear: this.props.year,
		dateToExlude : []
      }
    }

    componentDidMount() {
		let params = {
			bulletinTypeName: "BIM"
		}

		if(this.props.context==="BIM"){

			BulletinClient.listAllBulletins(
				(data) => { 
					let dateToExlude = [];
				data.forEach(bulletin=>{
					let d = moment([bulletin.year, (bulletin.month)-1, bulletin.day, 0, 0, 0, 0]).toDate();
					dateToExlude.push(d);
					
				})
				if (!!this.inputData) {
					this.setState({dateToExlude, ...this.inputData});
				}
				else{
					this.setState({dateToExlude});
					
				}
			},
			() => {
				
			},
			params
			)
		}


    }

    handleChangeYearMonth = (dateSelected) => {
      let month = dateSelected.getMonth();
      let year = dateSelected.getFullYear();
      this.props.changeYearMonthRef(month, year);
      this.setState({selectedMonth : month, selectedYear: year});
    }

    handleNextStep = () => {
		if(!!this.props.formData){
			let formData = this.props.formData;
			formData[this.section]["inputData"] = {...this.state, loading: false};
		}
      this.props.handleNext();
    }

    render() {
      return (
        <>
          <div className="row justify-content-center">
            <h2>{this.props.stepLabel}</h2>
          </div>
          <div className="row mt-4 justify-content-center">
              <DatePicker
                locale={it}
                selected={new Date(this.state.selectedYear, this.state.selectedMonth, 1)}
                onChange={date => {console.log(date.getMonth() + ' - ' + date.getFullYear() ); this.handleChangeYearMonth(date)}}
				maxDate={new Date()}
				excludeDates={this.state.dateToExlude}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                inline
              />
          </div>

          <MobileStepper
            style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
            position="static"
            variant="text"
            activeStep={this.props.activeStep}
            steps={this.props.totalSteps}
            nextButton={
              <div style={{position: 'relative'}}>
                <NextButton
                  variant="contained"
                  color="primary"
                  onClick={this.handleNextStep}
                  textbutton={this.props.textNextButton}
                />
                {this.state.loading ? <CircularProgress size={24} style={BUTTON_PROGRESS_STYLE}/> : <></>}
              </div>
            }
            backButton={
              <BackButton
                variant="contained"
                disabled={this.state.calculating || this.state.loading || this.props.disableBackButton}
                onClick={this.props.handleBack}
              />
            }
          />

        </>
      )
    }
  }
