import React, {Component} from 'react';
import {Controller, useForm} from "react-hook-form";

import ThresholdSensorComponent from '#/backoffice/components/ThresholdSensorComponent';

import {ThresholdButton} from "#/commons/components/forms/ThresholdButton";
import {UpdateButton} from '#/commons/components/forms/UpdateButton';
import {DeleteButton} from '#/commons/components/forms/DeleteButton';
import {DialogForm} from '#/commons/components/forms/DialogForm';
import {TextFieldForm} from '#/commons/components/forms/TextFieldForm';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import {reactFormatter, ReactTabulator} from "react-tabulator";

import SensorCategoryClient from '#/lib/SensorCategoryClient';

import AuthenticationService, {ROLE_ADMIN, ROLE_CONFIGURATOR, ROLE_OFFICER} from '#/lib/AuthenticationService';
import LoginComponent from '#/backoffice/components/LoginComponent';
import {Route} from 'react-router-dom';


import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '#/backoffice/style/SweetAlert.css';
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CumulatedPluvsThresholdComponent from "#/backoffice/Monitoring/components/CumulatedPluvsThresholdComponent";
import MonitoringPluvsThresholdClient from "#/lib/MonitoringPluvsThresholdClient";
import Checkbox from "@material-ui/core/Checkbox";
import {FaCheck} from "react-icons/fa";

const ReactSwal = withReactContent(Swal);


const IsCheckedIcon = ({field,...props}) => props.cell._cell.row.data[field] ? <div className="textAlignCenter"><FaCheck/></div> : <></>;
const IsReturnTimeCheckedIcon = (props) => props.cell._cell.row.data.returnTimeNotify && props.cell._cell.row.data.category === 'P' ? <div className="textAlignCenter"><FaCheck/></div> : <></>;

export default class SensorCategoryPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			showDialog: false,
			showDialogThreshold : false,
			dataSelected : {},
			thresholds: [],
			rainThresholds: [],
			showAggregatedRainThresholdModal: false
		}
	}

	componentDidMount() {
		Promise.all([this.getAllSensorCategoryPromise(), this.getSensorClassThresholdsPromise(), this.rainThresholdsPromise()])
			.then(([data, thresholdsMapByCategory, rainThresholdsMap]) => {
				this.setState({data, thresholdsMapByCategory, rainThresholds: rainThresholdsMap['GENERIC']})
			})
	}
	getAllSensorCategory = () => {
		this.getAllSensorCategoryPromise().then(data => this.setState({data}));
	}
	getSensorClassThresholdsPromise = () => new Promise((resolve, reject) => {
		SensorCategoryClient.getSensorClassThresholds (
			(thresholdsMapByCategory) => {
				resolve(thresholdsMapByCategory)
			},
			(msg) =>{
				ReactSwal.fire('Errore nel recupero delle soglie ', '', 'error');
			}
		)
	})
	getAllSensorCategoryPromise = () => new Promise((resolve, reject) => {
		SensorCategoryClient.getAll(
			(data) => {
				resolve(data)
			},
			(error) => {
				ReactSwal.fire('Il recupero dei dati ha presentato degli errori', '', 'error');
				console.log(error.message);
			}
		)
	});

	rainThresholdsPromise = () => new Promise((resolve, reject) => {
			MonitoringPluvsThresholdClient.getThresholdsGroupedBySensorCode(
				rainThresholdsMap => {
					resolve(rainThresholdsMap)
				},
				() => {
					console.log('Problems retrieving rain thresholds!')
				}
			)
		}
	);
	getTabulatorOptions = () => {
		let tabulatorOptions = {
			layout: "fitColumns",
			height: "65vh"
		};
		return tabulatorOptions;
	}

	isConfigurator = () => AuthenticationService.haveRolesPermssions([ROLE_CONFIGURATOR, ROLE_ADMIN]);
	getColumns = () => {
		let columns = [];
		columns = columns.concat([
			{ title: "Categoria", field: "category" },
			{ title: "Meta", field: "meta" },
			{ title: "Unità di misura", field: "unit" },
			{ title: "Fattore di Conversione", field: "conversionFactor" },
			/*{ title: "Modalità di Aquisizione", field: "acquisitionType" },*/
			{
				title:"Notifiche superamento",
				columns:[
					{title:"Soglie specifiche", field:"sensorNotify", formatter: reactFormatter(<IsCheckedIcon field={'sensorNotify'}/>)},
					{title:"Soglie generiche", field:"categoryNotify", formatter: reactFormatter(<IsCheckedIcon field={'categoryNotify'}/>)},
					{title:"Soglie tempi di ritorno", field:"returnTimeNotify", formatter: reactFormatter(<IsReturnTimeCheckedIcon />)},
				],
			},
			{title:"Includi nel FO", field:"includeFrontoffice", formatter: reactFormatter(<IsCheckedIcon field={'includeFrontoffice'}/>)},
			{
				title: "",
				formatter: reactFormatter(<ThresholdButton disabled={!this.isConfigurator()} variant="outlined" size="small"/>),
				headerSort:false,
				hozAlign:"center",
				vertAlign: "middle",
				cellClick: (e, cell) => { this.modifyThresholds(cell.getRow().getData()) }
			},
			{
				title: "",
				formatter: reactFormatter(<UpdateButton disabled={!this.isConfigurator()} variant="outlined" size="small"/>),
				headerSort:false,
				hozAlign:"center",
				vertAlign: "middle",
				cellClick: (e, cell) => { this.modify(cell.getRow().getData()) }
			},
			{
				title: "",
				formatter: reactFormatter(<DeleteButton disabled={!this.isConfigurator()} variant="outlined" size="small"/>),
				headerSort:false,
				hozAlign:"center",
				vertAlign: "middle",
				cellClick: (e, cell) => { this.delete(cell.getRow().getData()) }
			},

		]);
		return columns;
	}

	create = () => {
		this.setState({
			showDialog: true,
			titleModal : 'Crea nuova Categoria Sensore',
			dataSelected : {}
		})
	}

	modify = (data) => {
		this.setState({
			showDialog : true,
			titleModal : 'Modifica Categoria Sensore',
			dataSelected: {...data}
		})
	}

	modifyThresholds = (data) => {
		if (data.category === 'P'){
			this.setState({showAggregatedRainThresholdModal: true})
		} else {
			let thresholds = this.state.thresholdsMapByCategory[data.category];

			this.setState({
				showDialogThreshold: true,
				titleModal: 'Modifica Soglie Categoria Sensore : ' + data.category,
				dataSelected: {...data, thresholds: thresholds || []}
			})
		}
	}

	delete = (data) => {
		ReactSwal.fire({
			title: <h3>Confermi la cancellazione dello categoria {data.category} ({data.meta})?</h3>,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sì',
			cancelButtonText: 'No'
		})
			.then((result) => {
				if (result.value) {
					SensorCategoryClient.delete (
						data.id,
						(result) => {
							ReactSwal.fire('Cancellazione Categoria Sensore', 'La categoria ' + data.category + ' è stato cancellata con successo', 'success');
							this.getAllSensorCategory(); //refresh
						},
						function (msg) {
							ReactSwal.fire('Errore cancellazione Categoria Sensore ' + data.category, '', 'error');
						}
					)
				}
			})

	}

	handleCloseDialog = () => {
		this.setState({showDialog: false, showDialogThreshold : false})
	}
	handleCloseThresholdDialog = () => {
		this.getSensorClassThresholdsPromise().then(thresholdsMapByCategory => {
			this.setState({showDialog: false, showDialogThreshold : false, thresholdsMapByCategory})
		})
	}

	hideModalAndRefresh = () => {
		this.setState({showDialog: false}, () => {
			this.getAllSensorCategory(); //refresh
		})
	}
	onRainThresholdsSave = (thresholds) => {
		this.setState({
			rainThresholds: thresholds,
			showAggregatedRainThresholdModal: false
		})
	}
	render() {
		const roles = !!this.props.roles ? this.props.roles : [ROLE_ADMIN, ROLE_OFFICER];
		if (!AuthenticationService.isValidSession() || !AuthenticationService.haveRolesPermssions(roles)) {
			return <Route render={(props) => <LoginComponent {...this.props} />}></Route>
		}
		return (
			<>
				<div className="generic-page container-fluid data-page mt-4">
					<h1>Categorie Sensori</h1>
					<div >
						<ReactTabulator
							columns={this.getColumns()}
							data={this.state.data}
							options={this.getTabulatorOptions()}
							key={"table_" + this.props.validationKey}
						/>
						{
							(this.state.showDialog &&
								<SensorCategoryForm
									open={this.state.showDialog}
									title={this.state.titleModal}
									onClose = {this.handleCloseDialog}
									data={this.state.dataSelected}
									onSuccessSubmit = {this.hideModalAndRefresh}/>)
							||
							(this.state.showDialogThreshold &&
								<SensorCategoryThresholdForm
									open={this.state.showDialogThreshold}
									title={this.state.titleModal}
									onClose = {this.handleCloseThresholdDialog}
									data={this.state.dataSelected}
								/>)
						}
					</div>

				</div>
				<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
					<Tooltip title="Crea una nuova categoria di sensori">
						<Fab color="primary" onClick={() => { this.create() }} disabled={!this.isConfigurator()}>
							<AddIcon />
						</Fab>
					</Tooltip>
				</div>
				{/*AGGREGATED RAIN THRESHOLDS*/}
				<Modal key={'thresholds'}
					   style={{
						   display: 'flex',
						   alignItems: 'center',
						   justifyContent: 'center'
					   }}
					   open={this.state.showAggregatedRainThresholdModal}
					   onClose={() => this.setState({showAggregatedRainThresholdModal: false})}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				>
					<div
						style={{
							background: "white",
							width: "70vw",
							height: "75vh"
						}}
					>
						<div className='w-100 d-flex justify-content-end'>
							<IconButton  aria-label="close" onClick={() => this.setState({showAggregatedRainThresholdModal: false})}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="d-flex justify-content-center">
							<h3>Soglie Piogge Cumulate </h3>
						</div>


						<div className="d-flex h-80">
							<div className="col-sm-12 my-auto">
								<CumulatedPluvsThresholdComponent
									thresholds = {this.state.rainThresholds}
									saveCallback = {(thresholds)=>this.onRainThresholdsSave(thresholds)}
									sensorCode={'GENERIC'}
								></CumulatedPluvsThresholdComponent>
							</div>
						</div>
						<br/>
					</div>
				</Modal>
			</>
		)
	}
}

const SensorCategoryForm = (props) =>  {
	const fieldRequiredMessage = "Campo obbligatorio";
	const { register, handleSubmit, errors, setValue, control, reset } = useForm({defaultValues: props.data});
	const onSubmit = data => submitForm(data);
	const onReset = () => reset({ acquisitionType: '' });

	const submitForm = (formContent) => {

		console.log("formContent", formContent)
		formContent.id = !!formContent.id ? formContent.id : (!!props.data.id ? props.data.id : null);
		formContent.conversionFactor = !!formContent.conversionFactor ? formContent.conversionFactor : null;
		SensorCategoryClient.save(
			formContent,
			(data) => {
				props.onSuccessSubmit();
				ReactSwal.fire('Archiviazione andata a buon fine', '', 'success');
			},
			(msg) => {
				ReactSwal.fire('Archiviazione dati fallita', msg, 'error');
				console.log(msg);
			}
		)
	}

	return (

		<DialogForm
			open={props.open}
			title={props.title}
			onClose = {props.onClose}
			handleSubmit={handleSubmit(onSubmit)}
			handleReset={onReset}
		>
			<form onSubmit = {handleSubmit(onSubmit)}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextFieldForm
							autoFocus={true}
							id = "category"
							label = "Categoria*"
							error={!!errors.category}
							helperText={!!errors.category ? errors.category.message : null}
							inputRef={register({ required: fieldRequiredMessage })}
							handleClearButton={() => setValue('category', null)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextFieldForm
							id = "meta"
							label = "Meta*"
							error={!!errors.meta}
							helperText={!!errors.meta ? errors.meta.message : null}
							inputRef={register({ required: fieldRequiredMessage })}
							handleClearButton={() => setValue('meta', null)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextFieldForm
							id = "unit"
							label = "Unità di misura"
							error={!!errors.unit}
							helperText={!!errors.unit ? errors.unit.message : null}
							inputRef={register}
							handleClearButton={() => setValue('unit', null)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextFieldForm
							id = "conversionFactor"
							label = "Fattore di Conversione"
							type="number"
							error={!!errors.conversionFactor}
							helperText={!!errors.conversionFactor ? errors.conversionFactor.message : null}
							inputRef={register}
							handleClearButton={() => setValue('conversionFactor', null)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Controller
							as={
								<TextFieldForm
									className = "mt-2"
									select
									id = "acquisitionType"
									label = "Modalità di Aquisizione"
									error={!!errors.acquisitionType}
									helperText={!!errors.acquisitionType ? errors.acquisitionType.message : null}
								>
									<MenuItem key="" value=""></MenuItem>
									<MenuItem key="ABSOLUTE" value="ABSOLUTE">ASSOLUTA</MenuItem>
									<MenuItem key="CUMULATIVE" value="CUMULATIVE">CUMULATIVA</MenuItem>
								</TextFieldForm>
							}
							name="acquisitionType"
							defaultValue={ props.data.acquisitionType || ''}
							rules={{ required: fieldRequiredMessage }}
							control={control}
						/>
					</Grid>
					<Grid item xs={12}>
						Notifica superamento soglia sensore
						<Controller
							control={control}
							as={<Checkbox
								onChange={(e) => setValue('sensorNotify',e.target.checked)}
								checked={props.data.sensorNotify}
							/>}
							name="sensorNotify"
						/>

					</Grid>
					<Grid item xs={12}>
						Notifica superamento soglia generica
						<Controller
							control={control}
							as={<Checkbox
								onChange={(e) => {console.log('categoryNotify', e.target.checked); setValue('categoryNotify', e.target.checked)}}
								checked={props.data.categoryNotify}
							/>}
							name="categoryNotify"
						/>
					</Grid>
					{props.data.category === 'P' &&<Grid item xs={12}>
						Notifica superamento soglia tempi di ritorno
						<Controller
							control={control}
							as={<Checkbox
								onChange={(e) => {console.log('returnTimeNotify', e.target.checked); setValue('returnTimeNotify', e.target.checked)}}
								checked={props.data.returnTimeNotify}
							/>}
							name="returnTimeNotify"
						/>
					</Grid>}
					<Grid item xs={12}>
						Includi nel Frontoffice
						<Controller
							control={control}
							as={<Checkbox
								onChange={(e) => {console.log('includeFrontoffice', e.target.checked); setValue('includeFrontoffice', e.target.checked)}}
								checked={props.data.includeFrontoffice}
							/>}
							name="includeFrontoffice"
						/>

					</Grid>
				</Grid>
			</form>

		</DialogForm>

	)
}

const SensorCategoryThresholdForm = (props) =>  {

	let thresholds = [{}];

	const submitForm = () => {

		if (!!thresholds) {

			let thresholdsToSave = thresholds.filter ( (el) => {
				return el.thresholdLevel!=="grey";
			});
			console.log('Thresholds to save', thresholdsToSave)

			if (thresholdsToSave.length > 0) {
				SensorCategoryClient.saveThresholdsBySensorCategory(
					thresholdsToSave,
					(data) => {
						props.onClose();
						ReactSwal.fire('Archiviazione andata a buon fine', '', 'success');
					},
					(msg) => {
						props.onClose();
						ReactSwal.fire('Archiviazione dati fallita', '', 'error');
						console.log(msg);
					}
				)
			} else {
				SensorCategoryClient.deleteThresholdsBySensorCategory(
					props.data.category,
					(data) => {
						props.onClose();
						ReactSwal.fire('Eliminazione soglie andata a buon fine', '', 'success');
					},
					(msg) => {
						props.onClose();
						ReactSwal.fire('Eliminazione soglie fallita', '', 'error');
						console.log(msg);
					}
				)
				props.onClose();
			}
		}

	}

	const onChangeThresholdsValue = (thresholdsValues) => {
		thresholds = thresholdsValues;
	}

	return (
		<DialogForm
			fullScreen
			open={props.open}
			title={props.title}
			key={props.title}
			onClose = {props.onClose}
			handleSubmit={() => submitForm()}
		>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<ThresholdSensorComponent onChangeThresholdsValue={(thresholds)=> onChangeThresholdsValue(thresholds)} data={props.data}/>
				</Grid>
			</Grid>

		</DialogForm>

	)
}
