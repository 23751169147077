import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default class SwitchComponent extends React.Component {


	render() {

			

		return (
			<div style={!!this.props.small ? { fontSize: '0.875rem' } : {}}>
				<FormControlLabel
					
					control={
						<Switch
							size={!!this.props.small ? "small" : "medium"}
							checked={this.props.value}
							disabled={this.props.disabled}
							onChange={this.props.onChange}
							color="primary" />
					}
					label={this.props.name}
				/>
			</div>
		);
	}
}
