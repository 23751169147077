import React from "react";

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

export const TextFieldForm = (props) => {
    const { handleClearButton, id, ...otherProps } = props;
    if (!!handleClearButton) {
        return 	<TextField
                    fullWidth
                    id = {id}
                    name = {id}
                    InputProps={{endAdornment: (<IconButton tabIndex="-1" edge='end' onClick={handleClearButton}><ClearIcon /></IconButton>)}}
                    {...otherProps}
                />
    }
    return 	<TextField
                fullWidth id = {id} name = {id}
                {...otherProps}
            />
}