import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';

export default class SensorClient {

	static getSensors(okCallback, errCallback) {
		let url = new URL(properties.url.sensorsData);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getAllSensors(okCallback, errCallback) {
		let url = new URL(properties.url.allSensors);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getSensorByCode(sensorCode, okCallback, errCallback) {
		let url = new URL(properties.url.sensorByCode+"/"+sensorCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getSensorsByStationCodeAndCategory(stationCode, category, okCallback, errCallback){
		let url = new URL(properties.url.sensorByStationCodeAndCategory+"/"+stationCode+"/"+category);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getSensorsByStation(stationCode, okCallback, errCallback){
		let url = new URL(properties.url.sensorsByStation+"/"+stationCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getSensorsBySingleCategory(sensorCategory, okCallback, errCallback){
		let url = new URL(properties.url.sensorsByCategory+"/"+ sensorCategory);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getThresholdsBySensorCode(sensorCode, okCallback, errCallback){
		let url = new URL(properties.url.sensorThresholds + "/" + sensorCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getThresholdsBySensorCategory(sensorCategory, okCallback, errCallback){
		let url = new URL(properties.url.sensorThresholdsBySensorCategory + "/" + sensorCategory);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getSensorThresholds( okCallback, errCallback){
		let url = new URL(properties.url.sensorThresholdsMapByCategory);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static saveThresholdsBySensorCode(params, okCallback, errCallback){
		let url = new URL(properties.url.sensorThresholdSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getSectionShape(sensorCode, okCallback, errCallback){
		let url = new URL(properties.url.sensorChannelSection + "/" + sensorCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static getHydrometricZero(sensorCode, okCallback, errCallback){
		let url = new URL(properties.url.hydrometricZero + "/" + sensorCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static deleteThresholdsBySensorCode(sensorCode,okCallback, errCallback){
		let url = new URL(properties.url.sensorThresholdDelete + "/" + sensorCode);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static getOutflowsScalesBySensorCode(sensorCode, okCallback, errCallback){
		let url = new URL(properties.url.sensorOutflows + "/" + sensorCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static getAllOutflowsScalesMapBySensorCode( okCallback, errCallback){
		let url = new URL(properties.url.allOutflowsScales);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}

	static saveOutflowsBySensorCode(params, okCallback, errCallback){
		let url = new URL(properties.url.sensorOutflowSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static deleteOutflowsScalesBySensorCode(sensorCode,okCallback, errCallback){
		let url = new URL(properties.url.sensorOutflowDelete + "/" + sensorCode);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static saveOutflowsGeometryBySensorCode(params, okCallback, errCallback){
		let url = new URL(properties.url.sensorOutflowGeomSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	static getOutflowsGeometryBySensorCode(sensorCode, okCallback, errCallback){
		let url = new URL(properties.url.sensorOutflowsGeom + "/" + sensorCode);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	}
	static deleteOutflowsGeometryBySensorCode(sensorCode,okCallback, errCallback){
		let url = new URL(properties.url.sensorOutflowGeomDelete + "/" + sensorCode);
		let fetch_options = { method: "DELETE" };
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

	static saveSensor(params, okCallback, errCallback){
		let url = new URL(properties.url.saveSensor);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}
	static deleteSensor(sensorId, okCallback, errCallback){
		let url = new URL(properties.url.deleteSensor + "/" + sensorId) ;
		let fetch_options = {
			method: 'DELETE',
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	}

}
