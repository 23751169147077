import {getUnitByCategory, MEASUREMENT_CATEGORY} from "#/lib/MeasurementCategory";
import React, {useEffect, useState} from "react";
import DateUtils from "#/lib/DateUtils";
import {MeasurementAggregationDisplayValues} from "#/lib/MeasurementAggregation";
import _ from "lodash";
import {ReactTabulator} from "react-tabulator";
import CircularProgress from "@material-ui/core/CircularProgress";
import {BUTTON_PROGRESS_STYLE} from "#/backoffice/bim/utils/BIMUtils";
import ExportButton from "#/commons/components/forms/ExportButton";
import {FaFileCsv, FaFilePdf} from "react-icons/fa";
import {FiSend} from "react-icons/fi";
import {PdfModal} from "#/commons/table/PdfModal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";
import DataRequestClient from "#/lib/DataRequestClient";
import {useForm} from "react-hook-form";
import MailingListClient from "#/lib/MailingListClient";
import {DialogForm} from "#/commons/components/forms/DialogForm";
import Grid from "@material-ui/core/Grid";
import {TextFieldForm} from "#/commons/components/forms/TextFieldForm";
import SendIcon from '@material-ui/icons/Send';

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
  allowOutsideClick: false,
  allowEscapeKey: false,
  didOpen: () => {
    Swal.showLoading()
  },
});
const getMeasureHeaderString = ({aggregation, sensorCode, sensorCategory, stationNameBySensorCode}) => {
    let aggr = "";
    if(!!aggregation){
        aggr = " - "+aggregation
    }
    let stationLabel = sensorCode;
    if(!!stationNameBySensorCode && !!stationNameBySensorCode[sensorCode]){
        stationLabel = stationNameBySensorCode[sensorCode];
    }
    return `${sensorCategory} (${stationLabel}) [${getUnitByCategory(sensorCategory)}] ${aggr}`;
};

export const BackingDataTable = React.forwardRef(({aggregation, measures, interval, stationNameBySensorCode, getDataByInterval}, ref) => {
    let isBackoffice = window.location.href.includes("backoffice");
    const [excludedColumns, setExcludedColumns] = useState([]);
    const [data, setData] = useState([]);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [loading, setLoading] = useState(isBackoffice);
    const [showDataRequestForm, toggleDataRequestForm] = useState(false);

    useEffect(() => {
        if (isBackoffice){
            getDataByInterval(
                interval,
                measures,
                (data) => {
                    setData(data.sort((a, b) => a.timestamp - b.timestamp));
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregation, measures, interval]);

    useEffect(() => {
        if (isBackoffice) {
            setLoading(true)
        }
    }, [aggregation, measures, interval])

    useEffect(() => {
        if (data.length > 0){
            setLoading(false)
        }}, [data])


    const getTabulatorOptions = () => {
        let tabulatorOptions = {
            columnMinWidth:200,
            layout:"fitData",
            downloadReady: (fileContents, blob) => blob
        };
        return tabulatorOptions;
    }

    const getTabulatorColumns = () => {
        let columns = [];
        if(aggregation !== 'PUNTUAL'){
            columns = columns.concat([
                {
                    title: "Data",
                    field: "timestamp",
                    frozen:true,
                    headerSort:false,
                    titleDownload: "Data (*)",
                    accessorDownload: intervalAccessor
                }
            ]);
            measures.forEach(m=>{

                if(m.sensorCategory===MEASUREMENT_CATEGORY.P){
                    let titleDownload = getMeasureHeaderString({...m, aggregation: 'CUM', stationNameBySensorCode});
                    columns = columns.concat([
                        {
                            field: titleDownload,
                            titleDownload,
                            download: !excludedColumns.includes(titleDownload),
                            accessorDownload: aggregatedAccessor,
                            accessorDownloadParams: {aggregation: "sum", sensorCode: m.sensorCode},
                        }

                    ]);
                }
                else{

                    ["avg","min","max"].forEach(aggregation =>{
                        let titleDownload = getMeasureHeaderString({...m, aggregation, stationNameBySensorCode});
                        columns = columns.concat([
                            {
                                headerSort:false,
                                field: titleDownload,
                                titleDownload,
                                download: !excludedColumns.includes(titleDownload),
                                accessorDownload: aggregatedAccessor,
                                accessorDownloadParams: {aggregation, sensorCode: m.sensorCode},
                            }

                        ]);
                    })
                }
            })
        }
        else {
            columns = columns.concat([
                {
                    title: "Data", field: "timestamp", frozen:true, headerSort:false,
                    accessorDownload: dateAccessor
                }

            ]);
            measures.forEach(m=>{
                let titleDownload = getMeasureHeaderString({...m, stationNameBySensorCode});
                columns = columns.concat(
                    [
                        {
                            field: titleDownload,
                            headerSort: false,
                            accessorDownload: validationAccessor,
                            accessorDownloadParams: {sensorCode: m.sensorCode},
                            titleDownload,
                            download: !excludedColumns.includes(titleDownload)
                        },

                    ]);
            })
        }
        return columns;
    }
    const dateAccessor = (value, data, type, params, column) => DateUtils.epochToDateMinuteResolution(value);
    const intervalAccessor = (value, data, type, params, column) => {
        return DateUtils.epochToDateMinuteResolution(value);//.split("- ").pop()
    };
    const validationAccessor = (value, data, type, {sensorCode}, column) => {
        if(!!data.measurementsWithValidations[sensorCode]){
            const {value, v3} = data.measurementsWithValidations[sensorCode];
            return v3==="MISSING" || (!value && value!==0) ? "ND" : value.toFixed(2)
        } else return ""
    };
    const aggregatedAccessor = (value, data, type, {sensorCode, aggregation}, column) => {
        let val = data.measurements[sensorCode][aggregation];
        return !!val || val ===0 || val === 0.0 ? val.toFixed(2) : 'ND';
    };

    // CSV
    const createCSVFileName = () => `Report misure ${aggregation !== 'PUNTUAL' ? `Aggregazione: ${MeasurementAggregationDisplayValues[aggregation]}` : 'puntuali'} dal ${DateUtils.epochToDateMinuteResolution(interval.start)} al ${DateUtils.epochToDateMinuteResolution(interval.end)}`;
    const downloadCsvData = () => {
        ref.table.download("csv", `${createCSVFileName()}.csv`);
    };

    // PDF
    const openPfdModal = () => {
        setShowPdfModal(true);
    }
    const onClosePdfModal = () => {
        setShowPdfModal(false);
    }
    const downloadPdf = () => {
        ref.table.download("pdf", "data.pdf", {
            orientation: "portrait",
            title:`Report misure ${aggregation !== 'PUNTUAL' ? `Aggregazione: ${MeasurementAggregationDisplayValues[aggregation]}` : 'puntuali'}\nDal ${DateUtils.epochToDateMinuteResolution(interval.start)} al ${DateUtils.epochToDateMinuteResolution(interval.end)}`,
            autoTable: doc => {
                doc.autoTableSetDefaults( {
                    addPageContent: ( data ) => {
                        console.log("PDF", data)
                        let footerStr = String(doc.internal.getNumberOfPages());
                        doc.setFontSize( 10 );
                        if (aggregation !== 'PUNTUAL'){
                            doc.text( "(*) La data riportata rappresenta la fine dell'intervallo di aggregazione", data.settings.margin.left, doc.internal.pageSize.height - 30 );
                        }
                        doc.text( footerStr, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10 );
                        doc.setFontSize( 14 );
                    }
                } );
                return {
                    styles: {
                        fillColor: [214,236,237],
                        minCellWidth: 20
                    },
                    headStyles: {
                        /*id: {
                            fillColor: 255
                        },*/
                        textColor: [0,0,0]
                    },
                    margin: {
                        top: 70,
                        bottom: 70
                    }
                }
            }
        });
    };

    const toggleColumn = event => {
        const {name: columnTitle, checked} = event.target;
        let newExcludedColumns = _.cloneDeep(excludedColumns);
        if (checked){
            newExcludedColumns = newExcludedColumns.filter(col => col !== columnTitle)
        } else newExcludedColumns.push(columnTitle);
        setExcludedColumns(newExcludedColumns); // FIXME: missing tableKey update
    }

    return (
        <>
            <div className="d-none">
                <ReactTabulator
                    ref={tableRef => ref = tableRef}
                    columns={getTabulatorColumns()}
                    data={data}
                    options={getTabulatorOptions()}
                    key={"table_1"}
                />
            </div>
            <div className="float-right mt-2">

                {loading && isBackoffice &&
                <div className="col-12">
                    <CircularProgress size={24} style={BUTTON_PROGRESS_STYLE}/>
                </div>}
                {isBackoffice ?
                    <>
                        <ExportButton disabled={loading} label={"Esporta csv"} icon={<FaFileCsv/>}
                                      downloadHandler={() => downloadCsvData()}></ExportButton>
                        <ExportButton disabled={loading}  label={"Esporta pdf"}  icon={
                            <FaFilePdf/>} downloadHandler={() => openPfdModal()}/>
                    </> :
                    <ExportButton disabled={loading} label={"Richiedi dati"} icon={<SendIcon/>}
                                  downloadHandler={() => toggleDataRequestForm(true)}></ExportButton>}
            </div>
            <PdfModal
                showPdfModal={showPdfModal}
                onClosePdfModal={() => onClosePdfModal()}
                getTabulatorColumns={() => getTabulatorColumns()}
                excludedColumns={excludedColumns}
                toggleColumn={(e) => toggleColumn(e)}
                downloadPdf={() => downloadPdf()}/>
            <DataRequestForm
                onSuccessSubmit={() => toggleDataRequestForm(false)}
                open={showDataRequestForm}
                title={"Compila il form per la richiesta dati"}
                onClose={() => toggleDataRequestForm(false)}
                measures={measures}
                aggregation={aggregation}
                interval={interval}
                stationNameBySensorCode={stationNameBySensorCode}/>
        </>
    )
})

const DataRequestForm = ({onSuccessSubmit, open, title, onClose, measures, aggregation, interval, stationNameBySensorCode}) =>  {
    const fieldRequiredMessage = "Campo obbligatorio";
    const { register, handleSubmit, errors, setValue, control, reset } = useForm({defaultValues: {name: '', surname: '', emailAddress: ''}});
    const onSubmit = data => submitForm(data);
    const onReset = () => reset({ acquisitionType: '' });

    const submitForm = (formContent) => {
        console.log("Form content", formContent);

        let title = `Si stanno richiedendo i dati di ${measures.length} sensor${measures.length > 1 ? 'i' : 'e'} dal ${interval.start.format('L')} al ${interval.end.format('L')}.`;
        ReactSwal.fire({
            title: <h3>{title}</h3>,
            showCancelButton: true,
            confirmButtonColor: '#30ac03',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        })
            .then((result) => {
                if (result.value) {
                    loadingSwal.fire("Invio richiesta in corso...");
                    let dataRequest = {
                        username: '',
                        jsonData: {
                            measures,
                            aggregation,
                            interval: {start: interval.start.valueOf(), end: interval.end.valueOf()},
                            stationNameBySensorCode
                        },
                        status: 'PENDING',
                        requestTimestamp: moment().valueOf(),
                        fulfilmentTimestamp: null,
                        ...formContent
                    }


                    DataRequestClient.save(() => {
                            loadingSwal.close();
                            ReactSwal.fire('La richiesta è stata inviata con successo','I dati verranno inviati tramite email all\'indirizzo ' + dataRequest.emailAddress,'success')
                                .then(val => onSuccessSubmit());
                        },
                        (err) => {
                            loadingSwal.close();
                            ReactSwal.fire('Errore','Richiesta non inviata','error');
                        },
                        dataRequest)

                }
            })
    }

    return (

        <DialogForm
            open={open}
            title={title}
            onClose = {onClose}
            handleSubmit={handleSubmit(onSubmit)}
            handleReset={onReset}
        >
            <form onSubmit = {handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextFieldForm
                            autoFocus={true}
                            id="name"
                            label="Nome*"
                            inputRef={register({required: fieldRequiredMessage})}
                            handleClearButton={() => setValue('name', null)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldForm
                            id = "surname"
                            label = "Cognome*"
                            inputRef={register({required: fieldRequiredMessage})}
                            handleClearButton={() => setValue('surname', null)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldForm
                            id = "emailAddress"
                            label = "Indirizzo email"
                            inputRef={register}
                            handleClearButton={() => setValue('emailAddress', null)}
                        />
                    </Grid>
                </Grid>
            </form>

        </DialogForm>

    )
}
