import {MapControl, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "#/commons/map/LeafletLegend.css";

class LeafletTemperatureLegend extends MapControl {
    createLeafletElement(props) {}
    componentWillUnmount(){}

    componentDidMount() {

        const legend = L.control({ position: "topright" });

        legend.onAdd = () => {
            const div = L.DomUtil.create("div", "info legend");
            const grades = this.props.grades;
            let labels = [];
            let grade;

            for (let i = 0; i < grades.length; i++) {
                grade = grades[i];


                labels.push(
                    '<i style="background:' +
                    this.props.getColor(grade)  +
                    '"></i> ' + grade
                );
            }
            let rev = labels.reverse();
            div.innerHTML = rev.join("<br>");
            return div;
        };

        const { map } = this.props.leaflet;
        legend.addTo(map);
    }
}

export default withLeaflet(LeafletTemperatureLegend);
