import {properties} from '#/properties.js';
import APIUtils from '#/lib/APIUtils';
import AuthenticationService from "#/lib/AuthenticationService";
import parse_georaster from "georaster"

export default class FireRiskClient {


	static getConfigList = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.fireRiskConfigList);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'GET',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static saveAll = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.fireRiskSaveAll);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static getTodayMaps = (okCallback, errCallback) => {
		let url = new URL(properties.url.fireRiskTodayMaps);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);
	};


	static getTiffFromMeasuredData = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.fireRiskTiffbyIntervalAndCategory);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8', "Authorization": AuthenticationService.getLoggedInToken() },
			method: 'POST',
			body: JSON.stringify(params)
		};

		fetch(url, fetch_options)
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				okCallback(arrayBuffer)
			})
			.catch((error) => {
				return errCallback(error);
			});
	};

	static getCampaign = (okCallback, errCallback) => {
		let url = new URL(properties.url.fireRiskCampaign);
		APIUtils.getFetchResponse(url, null, okCallback, errCallback);	
	};

	static saveCampaign = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.fireRiskCampaignSave);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
			method: 'POST',
			body: JSON.stringify(params)
		}
		APIUtils.getFetchResponse(url, fetch_options, okCallback, errCallback);
	};

	static getTiffFromPropagationData = (okCallback, errCallback, params) => {
		let url = new URL(properties.url.getPropagationData);
		let fetch_options = {
			headers: { 'Content-Type': 'application/json', 'charset': 'utf-8', "Authorization": AuthenticationService.getLoggedInToken() },
			method: 'POST',
			body: JSON.stringify(params)
		};

		fetch(url, fetch_options)
			.then(response => {
				if(response.status === 200){
					return response.arrayBuffer()
				}
			})
			.then(arrayBuffer => {
				console.log("arrayBuffer", arrayBuffer);
				let timeout = setTimeout(() => {errCallback()}, 10000);
				parse_georaster(arrayBuffer).then(georaster => {
					clearTimeout(timeout);
					okCallback(georaster)
				}).catch( err =>{
					errCallback(err)
				})
			})
			.catch((error) => {
				errCallback(error);
			});
	};

}



