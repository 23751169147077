import React from 'react';
import WidthUtils from "#/lib/WidthUtils"
import { Link } from "react-router-dom";
import { FaUserCircle } from 'react-icons/fa';
import { useAuth } from 'react-oidc-context';
import AuthenticationService from '#/lib/AuthenticationService';


function FrontOfficeNavigationComponent(props) {

    const auth = useAuth();

    return (
        <>

            <button className="navb navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target={"#" + props.id}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id={props.id}>
				{/* {WidthUtils.isWidthUp('xl')
				?
					<div style={{fontSize: "xx-small", fontWeight: "bold", color: "#2e5396"}}>
						Portale gestito dal Centro Funzionale Multirischi - Sicurezza del territorio dell'Agenzia Regionale per la Protezione dell'Ambiente della Calabria
					</div>
				:
					<></>
				} */}

                <ul className="navbar-nav my-2 my-lg-0">
                    <li className="nav-item"><Link to={"/frontoffice#sezione-1"} className="nav-link js-scroll-trigger">Home</Link></li>
                    <li className="nav-item"><Link to="/frontoffice#sezione-2" className="nav-link js-scroll-trigger">Informati</Link></li>
                    <li className="nav-item"><Link to="/frontoffice#sezione-3" className="nav-link js-scroll-trigger">Preparati</Link></li>
                    <li className="nav-item"><Link to={"/frontoffice/comunicato"} className="nav-link js-scroll-trigger">Comunicati</Link></li>
                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle" id={props.id + "-allerte-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Pubblicazioni</a>
                        <ul className="dropdown-menu" aria-labelledby={props.id + "-allerte-dropdown"}>
                            <li><a className="dropdown-item" href="https://www.cfd.calabria.it/index.php/pubblicazioni/pubblicazioni-p" target="'_blank'">Pubblicazioni storiche</a></li>
                            <li><a className="dropdown-item" href="https://www.cfd.calabria.it/DatiVari/PDF/Direttiva_Con_Appendici 123 del 29 Novembre 2017.pdf" target="'_blank'">Direttiva regionale</a></li>
                            <li><a className="dropdown-item" href="https://www.cfd.calabria.it/DatiVari/PDF/Dichiarazione_operativita_CFM.pdf" target="'_blank'">Dichiarazione operatività</a></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle" id={props.id + "-allerte-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Centro Funzionale</a>
                        <ul className="dropdown-menu" aria-labelledby={props.id + "-allerte-dropdown"}>
                            <Link to={"/frontoffice/centro_funzionale"} className="dropdown-item">Cos'è il Centro Funzionale</Link>
                            <Link to={"/frontoffice/link_utili"} className="dropdown-item">Link utili</Link>
                            <Link to={"/frontoffice/contatti"} className="dropdown-item">Contatti</Link>
                        </ul>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto my-2 my-lg-0">

                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle" id={props.id + "-allerte-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Bollettini</a>
                        <ul className="dropdown-menu" aria-labelledby={props.id + "-allerte-dropdown"}>
							{/*
                            <li><Link className="dropdown-item" to={"/frontoffice/mau"}>MAU</Link></li>
                            <li><Link className="dropdown-item" to={"/frontoffice/maib"}>MAIB</Link></li>
							*/}
                            <li><Link className="dropdown-item" to={"/frontoffice/meteo"}>Previsioni Meteo</Link></li>
                            <li><Link className="dropdown-item" to={"/frontoffice/postevento"}>Report post evento</Link></li>
                            <li className="dropdown-submenu">
                                <a href={() => false}  className="dropdown-item dropdown-toggle" id={props.id + "-allerte-dropdown"} role="button" data-toggle="dropdown" aria-expanded="false">Siccità</a>
                                <ul className="dropdown-menu dropdown-menu-start" aria-labelledby={props.id + "-allerte-dropdown"}>
                                    <Link className="dropdown-item" to={"/frontoffice/bim"}>Bollettino Idrologico Siccità</Link>
                                </ul>
                            </li>

                        </ul>
                    </li>

                    <li className="nav-item dropdown">
                        <a href={() => false}  className="nav-link dropdown-toggle" id={props.id + "-dati-dropdown"} role="button" data-toggle="dropdown"  aria-expanded="false">Dati osservati</a>
                        <div className="dropdown-menu" aria-labelledby={props.id + "-dati-dropdown"}>
                            <Link className="dropdown-item" to={"/frontoffice/dailyStats"}>Dati giornalieri</Link>
                            <Link className="dropdown-item" to={"/frontoffice/monthlyStats"}>Dati mensili</Link>
                            <Link className="dropdown-item" to={"/frontoffice/richiestaDati"}>Richiesta Dati</Link>
                        </div>
                    </li>

                    <ul className="navbar-nav my-2 my-lg-0">
                        <li className="nav-item">
                            <Link to={"/backoffice"} className="nav-link js-scroll-trigger">
                                <FaUserCircle className="mr-2" style={{ color: '#2b7943' }} />
                                    <span style={{ color: '#2b7943' }}>Accedi</span>
                            </Link>
                        </li>
                    </ul>

                    {/*<li className="nav-item"><Link to={"/frontoffice/protectedPage"} className="nav-link js-scroll-trigger">Protetta</Link></li>*/}



                </ul>
            </div>

        </>

    );

};

export default (FrontOfficeNavigationComponent);
