import React, {useEffect, useState} from "react";
import {DEFAULT_INSTITUTIONS} from "#/backoffice/mau/utils/MauUtils";

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';

import {RecipientForm} from "#/backoffice/mau/components/RecipientForm";
import {BackButton} from "#/commons/components/forms/BackButton";
import {SaveButton} from "#/commons/components/forms/SaveButton";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import MailingListClient from "#/lib/MailingListClient";
import SwitchComponent from "#/commons/components/SwitchComponent";

export const StepRecipients = (props) => {
    const section =  ["address_to"];
    let validForm = props.formData.address_to.length;

    const handleNextStep = () => {
        if (validForm) {
            let mail_addresses = [];
            let to_include = recipients.INSTITUTION ? props.formData.address_to.filter(add => Object.keys(recipients.INSTITUTION).includes(add)) : [];
            if (allComuniSelected()){
                to_include.push("Comuni");
            }
            props.formData.address_to.forEach(key => mail_addresses.push(...findMails(key)));

            props.handleNext(mail_addresses, to_include);
        } else {
            props.reactSwal.fire(
                "Redazione mau",
                "Compilare tutti i campi prima di procedere",
                "error"
            );
        }
    }
    const [recipients, setRecipients] = useState(null);


    useEffect(() => {
        /*   loadingSwal.fire('Caricamento in corso...')*/
        MailingListClient.getMailingListByCategory(
            mailingList => {
                let mapByCategory = mailingList.reduce((obj, item)=> {
                    obj[item.category] = obj[item.category] ? [...obj[item.category], item] : [item];
                    return obj;
                },{});
                let recipients = {};
                Object.keys(mapByCategory).forEach( category => {
                    recipients[category] = groupByName(mapByCategory[category], category)
                });
                setRecipients(recipients)
            },
            err => {
                console.error(err);

            },
            'ALL'
        )
    }, [])

    const findMails = key => ({...recipients.INSTITUTION, ...recipients.MUNICIPALITY, ...recipients.PRIVATE }[key] || []) ;
    const groupByName = (mailingList, category) =>  mailingList.reduce((obj, item)=> {
        let key = category === 'PRIVATE' ? item.name + ' ' + item.surname : item.institutionName;
        obj[key] = obj[key] ? [...obj[key], item.emailAddress] : [item.emailAddress]
        return obj;
    }, {});

    const onCheckRecipient = (recipient, checked) => {

        let address_to = props.formData.address_to;
        if (checked) {
            address_to.push(recipient);
        } else {
            const indexElement = address_to.indexOf(recipient);
            if (indexElement!==-1){
                address_to.splice(indexElement,1);
            }
        }
        props.onFormChange(section, null, null, [...new Set(address_to)]);
    }

    const toggleAll = (checked, category) => {
        let address_to = props.formData.address_to;
        if (checked){
            address_to = [...new Set([...address_to, ...Object.keys(recipients[category])])];
        } else {
            address_to = address_to.filter(rec => !Object.keys(recipients[category]).includes(rec));
        }
        props.onFormChange(section, null, null, address_to);

    }

    const allEntiSelected = () => recipients.INSTITUTION ? !Object.keys(recipients.INSTITUTION).some( ai => !props.formData.address_to.includes(ai)) : false;
    const allComuniSelected = () => recipients.MUNICIPALITY ? !Object.keys(recipients.MUNICIPALITY).some( ai => !props.formData.address_to.includes(ai)) : false;
    const allPrivatiSelected = () => recipients.PRIVATE ? !Object.keys(recipients.PRIVATE).some( ai => !props.formData.address_to.includes(ai)) : false;

    const privateRecipients = () => recipients.PRIVATE ? Object.keys(recipients.PRIVATE).map(rec => {
        return {label: rec}
    }) : [];

    const institutionRecepients = () => [...new Set([...DEFAULT_INSTITUTIONS, ...(recipients.INSTITUTION ? Object.keys(recipients.INSTITUTION) : [])])].map(rec => {
        return {label: rec,
            disabled: DEFAULT_INSTITUTIONS.includes(rec) && (recipients.INSTITUTION ? !Object.keys(recipients.INSTITUTION).includes(rec) : true)}
    });

    const municipalityRecipients = () => recipients.MUNICIPALITY ? Object.keys(recipients.MUNICIPALITY).map(rec => {
        return {label: rec}
    }) : [];

    return (
        <>
            <div className="mt-2 row justify-content-center">
                <h2>{props.stepLabel}</h2>
            </div>
            <div className="scrollbar scrollbar-primary" style={{maxHeight: "60vh", minHeight: "60vh"}}>
                <Grid container >
                    <Grid item xs={12}>
                        {!!props.formData && recipients && <>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                            <div className="justify-content-between">
                                                <span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Enti</span>
                                                <span style={{fontSize: '0.8rem', float: 'right'}}>Gli enti selezionati verranno mostrati nel bollettino</span>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div >
                                                <SwitchComponent
                                                    name={allEntiSelected() ? 'Deseleziona tutti' : 'Seleziona tutti'}
                                                    value={allEntiSelected()}
                                                    disabled={institutionRecepients().length === 0}
                                                    onChange={(e) => toggleAll(e.target.checked,'INSTITUTION')}

                                                />
                                            </div>
                                            <div>
                                                <RecipientForm
                                                    key={"Enti"}
                                                    viewMode={props.viewMode}
                                                    recipients ={institutionRecepients()}
                                                    onFormChange={onCheckRecipient}
                                                    formData={props.formData}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                            <div className="justify-content-between">
                                                <span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Comuni</span>
                                                <span style={{fontSize: '0.8rem', float: 'right'}}>Nel bollettino apparirà la voce <i>Comuni</i></span>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <div >
                                                <SwitchComponent
                                                    name={allComuniSelected() ? 'Deseleziona tutti' : 'Seleziona tutti'}
                                                    value={allComuniSelected()}
                                                    disabled={false}
                                                    onChange={(e) => toggleAll(e.target.checked,'MUNICIPALITY')}
                                                />
                                            </div>
                                            <div>
                                                <RecipientForm
                                                    key={"Comuni"}
                                                    viewMode={props.viewMode}
                                                    recipients ={municipalityRecipients()}
                                                    onFormChange={onCheckRecipient}
                                                    formData={props.formData}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                                            <div className="justify-content-between">
                                                <span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Altri</span>
                                                <span style={{fontSize: '0.8rem', float: 'right'}}>Questi contatti <b>NON</b> verranno inseriti nel bollettino</span>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <div >
                                                <SwitchComponent
                                                    name={allPrivatiSelected() ? 'Deseleziona tutti' : 'Seleziona tutti'}
                                                    value={allPrivatiSelected()}
                                                    disabled={privateRecipients().length === 0}
                                                    onChange={(e) => toggleAll(e.target.checked,'PRIVATE')}
                                                />
                                            </div>
                                            <div>
                                                <RecipientForm
                                                    key={"Altri"}
                                                    viewMode={props.viewMode}
                                                    recipients ={privateRecipients()}
                                                    onFormChange={onCheckRecipient}
                                                    formData={props.formData}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </>}
                    </Grid>
                </Grid>
            </div>
            <MobileStepper
                style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
                position="static"
                variant="text"
                activeStep={props.activeStep}
                steps={props.totalSteps}
                nextButton={
                    <SaveButton
                        variant="contained"
                        color="primary"
                        disabled={!validForm}
                        onClick={handleNextStep}
                    />
                }
                backButton={
                    <BackButton
                        variant="contained"
                        disabled={props.disableBackButton}
                        onClick={props.handleBack}
                    />
                }
            />
        </>
    );
};
