import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import "#/commons/data/section/year-calendar.css";
import StationGisPicker from "#/commons/map/StationGisPicker";
import GISTools from '#/lib/GISTools';
import StationClient from "#/lib/StationClient";
import Tooltip from "@material-ui/core/Tooltip";
import chroma from 'chroma-js';
import L from 'leaflet';
import moment from 'moment';
import React from 'react';
import { FaAngleLeft, FaCrosshairs, FaMapMarkerAlt, FaFileCsv, FaFilePdf, FaCalculator } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import DailyStatsClient from '#/lib/DailyStatsClient';
import WidthUtils from "#/lib/WidthUtils";
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


import { MEASUREMENT_CATEGORY_ICONS, MEASUREMENT_LABELS } from "#/lib/MeasurementCategory";
import SwitchComponent from "../components/SwitchComponent";
import DailyStatsTables from "./DailyStatsTables";
import _ from 'lodash'
import DateUtils from "#/lib/DateUtils";
import SendSharpIcon from '@material-ui/icons/SendSharp';
import Button from '@material-ui/core/Button';
import { outflowValidationOptions, rainValidationOptions, temperatureValidationOptions } from "#/lib/DailyStatsValidation"
import AuthenticationService, { ROLE_ADMIN, ROLE_OFFICER } from "#/lib/AuthenticationService";

const defaultZone = {
	value: null,
	label: "Tutte le zone"
}

const defaultStation = {
	value: null,
	label: <>&nbsp;</>
}

const defaultYear = moment().year();
const defaultSelectedRage = [moment().startOf("day"), moment().endOf("day")];

const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

class DailyStatsPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 0,
		maxZoom: 12,
		width: "40vw",
		height: "65vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			allStations: false,
			selectedCategory: '',
			stationsGeoJSON: {},
			year: defaultYear,
			selectedRange: defaultSelectedRage,

			stationMapByCode: null,
			zoneOptions: [],
			stationOptions: [],

			selectedZone: defaultZone,
			selectedStation: defaultStation,

			interval: null,
			mapKey: 0,
			tableKey :0, 

			showGisPicker: true,

			intervals: []


		}
	}

	componentDidMount() {


	}

	onCategoryChange = (selectedCategory) => {
		if (!!selectedCategory) {

			loadingSwal.fire('Recupero Stazioni in corso...');
			StationClient.getAllStationsGeojsonByCategory(
				selectedCategory,
				(stationsGeoJSON) => {

					loadingSwal.close();

					let selectedZone = defaultZone;
					let selectedStation = defaultStation;

					let stationMapByCode = stationsGeoJSON.features.reduce((map, f) => {
						map[f.properties.code] = f.properties.name
						return map;
					}, {})

					let zoneOptions = this.buildZoneOptions();
					let stationOptions = this.buildStationOptions(stationsGeoJSON, selectedZone.value);

					// Find stations out of Calabria
					let stationsOutsideRegion = GISTools.getStationsOutsideCalabria(stationsGeoJSON);

					let state = {
						stationMapByCode,
						selectedCategory,
						zoneOptions,
						stationOptions,
						stationsOutsideRegion,
						stationsGeoJSON,
						selectedZone,
						selectedStation,
						mapKey: (this.state.mapKey + 1) % 1000
					};
					this.setState(state)
				},
				(err) => {
					loadingSwal.close();
					console.log(err)
				}
			)
		}
		else {
			this.setState({
				selectedCategory,
				geojson: null,
				selectedStation: null,
				mapKey: (this.state.mapKey + 1) % 1000
			}, () => { })
		}
	}

	buildZoneOptions() {
		let zoneOptions = [];
		let zones = GISTools.getAllZonePolygon();

		zoneOptions.push(defaultZone)
		zones.forEach(zone => {
			let id = zone.properties.ZONE;
			zoneOptions.push({
				value: parseInt(id),
				label: "ZONA " + id,
				icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
			});
		})
		// Add Out-of-Calabria zone
		zoneOptions.push({
			value: 9999,
			label: "Territorio extraregionale",
			icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
		});

		return zoneOptions
	}

	buildStationOptions(stationsGeoJSON, zoneId = null) {
		let stationOptions = [];
		let stations = stationsGeoJSON;

		if (!!zoneId) {
			if (zoneId === 9999) {
				stations = this.state.stationsOutsideRegion;
			} else {
				let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
				let stationWithinZone = GISTools.intersect(stations, zonePolygon);
				stations = stationWithinZone;
			}
		}

		let stationsProperties = GISTools.getPropertiesFromFeature(stations);
		stationOptions.push(defaultStation);
		stationsProperties.forEach(properties => {
			stationOptions.push({
				label: properties.name,
				value: properties.code,
				icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
			});
		})

		return stationOptions;
	}

	zonesStyle(feature) {
		let zone = parseInt(feature.properties.ZONE);
		let style = {};

		if (this.state.selectedZone.value === 0) {
			if (this.state.zoneOptions.map(x => x.value).includes(zone)) {

				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}

		}
		else {
			if (this.state.selectedZone.value === zone) {
				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}
		}


		return style;
	}

	onEachStation(feature, layer) {
		layer.on('click', (e) => this.onSelectStation(feature.properties.code));

		var popup = L.popup({ closeButton: false })
			.setContent('<div><span class="mr-2 fas fa-map-marker-alt"></span>' + feature.properties.name + '</div>');
		layer.bindPopup(popup);
		let timer = null;
		layer.on('mouseover', function (e) {
			timer = setTimeout(() => this.openPopup(), 1000)
		});
		layer.on('mouseout', function (e) {
			clearTimeout(timer);
			this.closePopup();
		});
	}

	stationToMarker(feature, latlng) {
		let marker = null;

		let name = feature.properties.name;
		let isSelected = name === this.state.selectedStation.label;

		if (isSelected) {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point_selected.png',
					popupAnchor: [0, 0]
				})
			});
		}
		else {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point.png',
					popupAnchor: [0, 0]
				})
			});
		}

		return marker;

	}

	onSelectZone(zoneId) {

		let stationOptions = [];
		let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
		stationOptions = this.buildStationOptions(this.state.stationsGeoJSON, zoneId);
		this.setState({
			stationOptions: stationOptions,
			selectedZone: selectedZone,
			selectedStation: defaultStation,
			mapKey: (this.state.mapKey + 1) % 1000,
			filteredStationsGeoJson: null
		})

	}

	onSelectStation(stationCode) {

		let stationOptions = this.buildStationOptions(this.state.stationsGeoJSON);
		let selectedStation = stationOptions.find(element => element.value === stationCode);
		let stationPoint = GISTools.getStationByField(this.state.stationsGeoJSON, "code", stationCode);
		let zonePolygon = GISTools.getZonePolygonByPoint(stationPoint);
		let zoneId = (!!zonePolygon && !!zonePolygon.properties) ? zonePolygon.properties.ZONE : 9999;
		let selectedZone = this.state.zoneOptions.find(element => element.value === parseInt(zoneId, 10));
		let zoneBbox = null;
		if (zoneId !== 9999) {
			zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
			zoneBbox = GISTools.getBBoxFromPoints(zonePolygon);
		}
		this.setState({
			selectedZone: selectedZone,
			selectedStation: selectedStation,
			mapKey: (this.state.mapKey + 1) % 1000,
			showGisPicker: !!this.state.interval ? false : true,
			zoneBbox
		})






	}

	onPrevYear() {
		this.setState({
			year: this.state.year - 1
		})
	}

	onNextYear() {
		this.setState({
			year: this.state.year + 1
		})
	}

	onPickRange(start, end, year) {
		console.log("onPickRange")
		let isTillToday = end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		let isFromToday = start.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		end = isTillToday ? moment().add(-30, 'm') : end.add(1, 'd').startOf('day');
		start = isFromToday ? end.clone().add(-24, 'h') : start.startOf('day');

		let interval = { start, end }
		this.setState({
			data: null,
			year: !!year ? year : this.state.year,
			selectedRange: [start, end],
			interval: interval,
			showGisPicker: false
		}, () => {
			this.fetchDailyData()
		})
	}

	onMobilePickRange(dates) {
		let interval = null
		if (dates && dates[0] && dates[1]) {
			interval = {}
			interval.start = moment(dates[0].getTime()).startOf('day')
			interval.end = moment(dates[1].getTime()).endOf('day')
		}
		this.resetInterval(interval)
	}

	resetInterval(interval) {
		this.setState({
			data: null,
			interval,
			selectedRange: !!interval ? [interval.start, interval.end] : defaultSelectedRage,
			year: defaultYear,
			showGisPicker: !!interval && !!this.state.selectedStation.value ? false : true
		}, () => {
			this.fetchDailyData()
		});
	}

	toggleSidebar(event) {
		if (event === "over") {
			document.getElementById("mySidebar").style.width = "2.5rem";
			document.getElementById("main").style.marginLeft = "1.5rem";
			document.getElementById("sidebar-button").style.color = "#428f81";
		} else {
			document.getElementById("mySidebar").style.width = "1.5rem";
			document.getElementById("main").style.marginLeft = "0";
			document.getElementById("sidebar-button").style.color = chroma('#428f81').alpha(0.3)
		}
	}

	toggleGisPicker = () => {
		if (!this.state.showGisPicker) {
			document.getElementById("main").style.marginLeft = '0px';
		}
		this.setState({ showGisPicker: !this.state.showGisPicker, interval: null })
	}

	isDisabled = (day) => {
		return false;





	}

	onToggleAllStations = () => {
		const newAllStationsState = !this.state.allStations;
		this.setState({
			allStations: newAllStationsState,
			selectedZone: defaultZone,
			selectedStation: defaultStation,

		}, () => { })

	}

	fetchDailyData() {
		if (!!this.state.selectedStation && !!this.state.interval && !!this.state.selectedCategory) {
			console.log(this.state.selectedStation)

			loadingSwal.fire('Caricamento dati...');
			DailyStatsClient.getDailyStats(
				(data) => {
					loadingSwal.close();
					this.addCalculatedFieldsToData(data, this.state.stationMapByCode);

					this.setState({
						data,
						showGisPicker: false,
						tableKey: (this.state.tableKey + 1) % 1000
					})
				},
				(err) => {
					loadingSwal.close();
					ReactSwal.fire('Errore nel caricamento dati!', '', 'error');
				},
				{
					start: this.state.interval.start.valueOf(),
					end: this.state.interval.end.valueOf(),
					category: this.state.selectedCategory,
					stationCode: this.state.selectedStation.value
				}

			)
		}
		else if (!!this.state.allStations && !!this.state.interval && !!this.state.selectedCategory) {
			loadingSwal.fire('Caricamento dati...');
			DailyStatsClient.getDailyStats(
				(data) => {
					this.addCalculatedFieldsToData(data, this.state.stationMapByCode)
					loadingSwal.close();
					this.setState({
						data,
						showGisPicker: false,
						tableKey: (this.state.tableKey + 1) % 1000
					})
				},
				(err) => {
					loadingSwal.close();
					ReactSwal.fire('Errore nel caricamento dati!', '', 'error');
				},
				{
					start: this.state.interval.start.valueOf(),
					end: this.state.interval.end.valueOf(),
					category: this.state.selectedCategory
				}

			)
		}
		else {
			this.setState({
				data: null
			});

		}
	}


	updateDailyStats = () => {
		let dirtyArray = this.getDirtyStats();
		loadingSwal.fire('Salvataggio dati...');
		DailyStatsClient.updateAllDailyStats(
			(data) => {
				loadingSwal.close();
				ReactSwal.fire('Salvataggio effettuato con successo', '', 'success').then(() => {
					console.log("confirmed")
					this.fetchDailyData();
				});
			},
			(err) => {
				loadingSwal.close();
				ReactSwal.fire('Errore nel caricamento dati!', '', 'error');
			},
			this.state.selectedCategory,
			dirtyArray
		)

	}

	getDirtyStats = () => {
		let dirtyArray = [];

		this.state.data.forEach(row => {
			row.dailyStats.forEach(innerRow => {
				if (!!innerRow.dirty) {
					dirtyArray.push(innerRow);
				}
			})
		})
		return dirtyArray;
	}
	createCSVFileName = () => {
		const { selectedStation, allStations, interval: { start, end }, selectedCategory } = this.state;
		return `${MEASUREMENT_LABELS[selectedCategory]}
			${allStations ? 'ALL' : selectedStation.label}
			${moment(new Date(start)).format("DDMMYYYY")}-${moment(new Date(end)).format("DDMMYYYY")}`;

	}
	downloadDataCsv = () => {
		this.tableRef.table.download("csv", `${this.createCSVFileName()}.csv`);
	};
	downloadDataPdf = () => {

		const { interval } = this.state;
		this.tableRef.table.download("pdf", "data.pdf", {
			orientation: "portrait",
			title: `Report statistiche giornaliere\nDal ${DateUtils.epochToDateMinuteResolution(interval.start)} al ${DateUtils.epochToDateMinuteResolution(interval.end)}`,
			autoTable: doc => {
				doc.autoTableSetDefaults({
					addPageContent: (data) => {
						let footerStr = String(doc.internal.getNumberOfPages());
						doc.setFontSize(10);
						doc.text(footerStr, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10);
						doc.setFontSize(14);
					}
				});
				return {
					styles: {
						fillColor: [214, 236, 237],
						minCellWidth: 20
					},
					headStyles: {
						/*id: {
							fillColor: 255
						},*/
						textColor: [0, 0, 0]
					},
					columnStyles: {
						0: { cellWidth: 60 },
						[this.tableRef.table.columnManager.columns.findIndex(col => col.field === 'validate')]: { cellWidth: 170 }
					},
					margin: {
						top: 70,
						bottom: 70
					}
				}
			}
		});
	};

	addCalculatedFieldsToData = (data, stationMapByCode) => {
		data.forEach(row => {
			row.date = moment(new Date(row.timestampKey)).format("DD/MM/YYYY")
			row.dailyStats.forEach(innerRow => {
				innerRow.date = moment(new Date(innerRow.timestampKey)).format("DD/MM/YYYY")
				innerRow.stationName = stationMapByCode[innerRow.stationCode]
			})
		})
	}


	massiveValidation = (val) => {
		let data = this.state.data
		data.forEach(row => {
			row.dirty = true;
			row.dailyStats.forEach(innerRow => {
				if (!!!innerRow.historical) {
					innerRow.dirty = true;
					innerRow.validate = val;
				}
			})
		})
		//this.addCalculatedFieldsToData(data, this.state.stationMapByCode)

		this.setState({
			data,
			tableKey: (this.state.tableKey + 1) % 1000
		})
	}
	calculateDailyStats = () => {
		let params = {
			start: this.state.interval.start.valueOf(),
			end: this.state.interval.end.valueOf(),
			category: this.state.selectedCategory
		}
		if (!this.state.allStations) {
			params = {
				...params,
				stationCode: this.state.selectedStation.value
			}
		}

		loadingSwal.fire('Ricalcolo dati in corso...');
		DailyStatsClient.calculateDailyStatsByInterval(
			(data) => {
				loadingSwal.close();
				data.forEach(row => {
					row.dirty = true;
					row.dailyStats.forEach(innerRow => {
						if (innerRow.validate !== "MISSING") {
							innerRow.dirty = true;
						}
					})
				})
				this.addCalculatedFieldsToData(data, this.state.stationMapByCode)

				this.setState({
					data,
					tableKey: (this.state.tableKey + 1) % 1000
				})
			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)

	}

	calculateByDay = (elem) => {
		let params = {
			day: elem.dailyStats[0].day,
			month: elem.dailyStats[0].month,
			year: elem.dailyStats[0].year,
			category: this.state.selectedCategory
		}

		console.log("params", params)

		loadingSwal.fire('Ricalcolo dati in corso...');
		DailyStatsClient.calculateDailyStatsByDate(
			(data) => {
				loadingSwal.close();
				let newData = this.state.data;
				data.forEach(row => {
					row.dirty = true;
					row.dailyStats.forEach(innerRow => {
						if (innerRow.validate !== "MISSING") {
							innerRow.dirty = true;
						}
					})
					var index = _.findIndex(newData, { timestampKey: row.timestampKey });
					newData.splice(index, 1, row);

				})

				this.addCalculatedFieldsToData(newData, this.state.stationMapByCode)
				console.log("newData",newData)
				this.setState({
					data: newData,
					tableKey: (this.state.tableKey + 1) % 1000
				})

			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)
	}

	interpolateValue = (elem) => {
		let params = {
			day: elem.day,
			month: elem.month,
			year: elem.year,
			category: this.state.selectedCategory,
			stationCode: elem.stationCode
		}

		loadingSwal.fire('Intepolazione dati in corso...');
		DailyStatsClient.interpolateDailyStatsByDate(
			(data) => {
				loadingSwal.close();

				data.dirty = true;

				let newData = this.state.data;

				let currentDayData = newData.find(x => x.timestampKey === data.timestampKey).dailyStats;

				var index = _.findIndex(currentDayData, { stationCode: data.stationCode });
				currentDayData.splice(index, 1, data);

				this.addCalculatedFieldsToData(newData, this.state.stationMapByCode)

				this.setState({
					data: newData,
					tableKey: (this.state.tableKey + 1) % 1000
				})

			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)
	}

	calculateByDayAndStationCode = (elem) => {
		let params = {
			day: elem.day,
			month: elem.month,
			year: elem.year,
			category: this.state.selectedCategory,
			stationCode: elem.stationCode
		}

		loadingSwal.fire('Ricalcolo dati in corso...');
		DailyStatsClient.calculateDailyStatsByDate(
			(data) => {
				loadingSwal.close();

				if (data[0].dailyStats[0].validate !== "MISSING") {
					data[0].dailyStats[0].dirty = true;
				}

				let newData = this.state.data;

				let currentDayData = newData.find(x => x.timestampKey === data[0].timestampKey).dailyStats;

				var index = _.findIndex(currentDayData, { stationCode: data[0].dailyStats[0].stationCode });
				currentDayData.splice(index, 1, data[0].dailyStats[0]);

				this.addCalculatedFieldsToData(newData, this.state.stationMapByCode)

				this.setState({
					data: newData,
					tableKey: (this.state.tableKey + 1) % 1000
				})

			},
			(err) => {
				loadingSwal.close();
				console.log("error")
			},
			params

		)
	}

	buildTable() {
		if (!this.state.data) {
			return (
				<>
					<Skeleton height={"10vh"} duration={8} />
					<Skeleton height={"70vh"} duration={8} />
				</>
			);
		}
		else {

			let stationLabel = <>{MEASUREMENT_CATEGORY_ICONS[this.state.selectedCategory]} {MEASUREMENT_LABELS[this.state.selectedCategory]} {!!this.state.selectedStation.value && "  -  " + this.state.selectedStation.label}</>
			let title = <h3 >Dati giornalieri {stationLabel}</h3>

			return (
				<>
					<div className="row">
						<div className=" col-4 ml-auto my-auto">
							{title}
						</div>
						<div className=" col-2 d-flex justify-content-center m-auto">
							{this.props.canEdit && <MassiveValidationForm
								id="selectValidation"
								label="Seleziona una validazione"
								disabled={!this.isOfficer()}
								selectedCategory={this.state.selectedCategory}
								selectedMassiveValidation={this.state.selectedMassiveValidation}
								handleMassiveValidation={(val) => this.massiveValidation(val)}
							/>}
						</div>
						<div className="col-2 d-flex justify-content-center m-auto">
							{this.props.canEdit && <Button
								startIcon={<FaCalculator />}
								size="small"
								disabled={!this.isOfficer()}
								variant="contained"
								color="primary"
								onClick={(event) => this.calculateDailyStats()}
							>Ricalcola</Button>}
						</div>
						<div className="col-2 d-flex justify-content-center m-auto">
							<Button
								startIcon={<FaFileCsv />}
								size="small"
								variant="contained"
								color="primary"
								onClick={(event) => this.downloadDataCsv()}
							>Download csv</Button>
						</div>
						<div className="col-2 d-flex justify-content-center m-auto">
							<Button
								startIcon={<FaFilePdf />}
								size="small"
								variant="contained"
								color="primary"
								onClick={(event) => this.downloadDataPdf()}
							>Download pdf</Button>
						</div>
					</div>

					<div style={{ width: "92vw" }}>
						<DailyStatsTables
							selectedCategory={this.state.selectedCategory}
							data={this.state.data}
							tableKey={this.state.tableKey}
							calculateByDay={elem => this.calculateByDay(elem)}
							calculateByDayAndStationCode={elem => this.calculateByDayAndStationCode(elem)}
							nested={this.state.allStations}
							updateTableKey={() => this.updateTableKey()}
							interpolateValue={elem => this.interpolateValue(elem)}
							canEdit={this.props.canEdit && this.isOfficer()}
							tableRef={el => this.tableRef = el}
							downloadData={() => this.downloadDataCsv()}
							label={stationLabel}
						></DailyStatsTables>
					</div>
					<div className="d-flex">

						{this.props.canEdit && <Button
							size="small"
							className="justify-content-end ml-auto"
							variant="contained"
							color="primary"
							endIcon={<SendSharpIcon />}
							onClick={(event) => this.updateDailyStats()}
							disabled={this.getDirtyStats().length === 0}
						>Salva</Button>}
					</div>

				</>
			);
		}
	}

	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	getComponentToShow = () => {
		let mobileMode = WidthUtils.isMobile();

		console.log('mobileMode: ', mobileMode);

		let view = {
			STUB: "STUB",
			CALENDAR: "CALENDAR",
			DATA: "DATA"
		}

		let currentView = view.STUB;

		if (!this.state.interval && !this.state.selectedStation.value && !this.state.allStations) {
			currentView = view.STUB;
		}
		else if ((!!this.state.selectedStation.value || !!this.state.allStations) && !this.state.interval) {
			currentView = view.CALENDAR;
		}
		else if ((!!this.state.selectedStation.value || !!this.state.allStations) && !!this.state.interval) {
			currentView = view.DATA;
		}

		let componentToShow = null;
		switch (currentView) {
			case view.STUB:
				componentToShow = <StubComponent></StubComponent>;
				break;
			case view.CALENDAR:
				componentToShow = !mobileMode ?
					<div id={"main"}>
						<h3>Seleziona Intervallo di osservazione</h3>
						<BigCalendarTimeZoned
							timeZone={"Etc/GMT-1"}
							range={this.state.interval}
							year={this.state.year}
							onPrevYear={() => this.onPrevYear()}
							onNextYear={() => this.onNextYear()}
							onPickRangeCallback={(start, end, year) => this.onPickRange(start, end, year)}
							future={day => this.isDisabled(day)}
						></BigCalendarTimeZoned>
					</div> : <div id={"main"}></div>;
				break;
			case view.DATA:
				componentToShow = <div id="main" >

					{/*<h1>DATA</h1>
					<span>{JSON.stringify({...this.state, stationsGeoJSON:undefined, zoneOptions:undefined, stationOptions:undefined, selectedZone:undefined},null,4)}</span>
				*/}
					{this.buildTable()}
				</div>;

				break;
			default:
				componentToShow = <StubComponent></StubComponent>;
				break;
		}
		return componentToShow;
	}

	render() {
		let mobileMode = WidthUtils.isMobile();

		return (
			<div className="generic-page container-fluid data-page">

				<div className="row mt-4">

					{this.state.showGisPicker ? <div className={mobileMode ? "col-12" : "col-4"}>
						<SelectCategoryForm
							id="selectCat"
							label="Seleziona una categoria"
							selectedCategory={this.state.selectedCategory}
							handleSelectCategory={this.onCategoryChange}
						/>

						{!!this.state.selectedCategory && <>
							<SwitchComponent
								name={"Seleziona Tutte le Stazioni"}
								value={this.state.allStations}
								onChange={(e) => this.onToggleAllStations()}
							/>

							<div className={mobileMode ? "" : "d-none"}>
								<DateRangePicker
									className={"col-12"}
									locale={"it-IT"}
									onChange={(dates) => this.onMobilePickRange(dates)}
									value={[this.state.interval && this.state.interval.start && new Date(this.state.interval.start.valueOf()), this.state.interval && this.state.interval.end && new Date(this.state.interval.end.valueOf())]}
									maxDate={new Date()}
									format={"dd/MM/yyyy"}
									rangeDivider='&nbsp;&nbsp;&nbsp;'
								/>
							</div>
							{!this.state.allStations && <StationGisPicker
								centerMapOnSelect={true}
								geoJSON={!!this.state.filteredStationsGeoJson ? this.state.filteredStationsGeoJson : this.state.stationsGeoJSON}
								zoneOptions={this.state.zoneOptions}
								selectedZone={this.state.selectedZone}
								onSelectZone={(option) => this.onSelectZone(option)}
								stationOptions={this.state.stationOptions}
								selectedStation={this.state.selectedStation}
								onSelectStation={(option) => this.onSelectStation(option)}
								mapHeight={"65vh"}
								zoneBbox={this.state.zoneBbox}

								mapKey={this.state.mapKey}
								onEachStation={(feature, layer) => this.onEachStation(feature, layer)}
								stationToMarker={(feature, latlng) => this.stationToMarker(feature, latlng)}
								zonesStyle={(feature) => this.zonesStyle(feature)}

							></StationGisPicker>
							}
						</>}

					</div> : <></>}

					<div id="mySidebar" className="sidebar" style={{ display: this.state.showGisPicker ? 'none' : 'block' }}
						onMouseOver={() => this.toggleSidebar("over")}
						onMouseOut={() => this.toggleSidebar("out")}
						onClick={() => this.toggleGisPicker()}>
						<Tooltip open={false} title={"Seleziona un altro sensore"} >
							<><FaAngleLeft style={{ height: 'inherit', width: 'inherit' }} id="sidebar-button" color={chroma('#428f81').alpha(0.3)} /></>
						</Tooltip>
					</div>
					<div className={this.state.showGisPicker ? "col-8" : "col-12"} key={this.state.tableKey}>
						{/*
						<div style={{backgroundColor: '#b3e7ac', width:'1rem'}} onClick={() => this.setState({showGisPicker: !this.state.showGisPicker})}></div>
*/}
						{this.getComponentToShow()}
					</div>
				</div>
				<div className="row" ></div>

			</div>

		)
	}
}

export default (DailyStatsPage);

function StubComponent() {
	return (
		<div >
			<SkeletonTheme  >
				<div className="row mt-2">
					<div className="col-2 " >
						<Skeleton height={"5vh"} duration={8} />
					</div>
					<div className="col-4 ml-auto" >
						<Skeleton height={"5vh"} duration={8} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-4 ml-auto" >
						<Skeleton height={"5vh"} duration={8} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-12">

						<Skeleton height={"5vh"} duration={8} />


					</div>
				</div>
				<div className="row mt-2">
					<div className="col-12 float-right" >
						<Skeleton height={"60vh"} duration={8} />
					</div>
				</div>
			</SkeletonTheme>
		</div>
	)
}

const SelectCategoryForm = (props) => {

	let options = [];
	options.push(<MenuItem key={"default"} value={""}></MenuItem>)
	options.push(<MenuItem key={"P"} value={"P"}>{MEASUREMENT_CATEGORY_ICONS.P}{MEASUREMENT_LABELS.P}</MenuItem>)
	options.push(<MenuItem key={"T"} value={"T"}>{MEASUREMENT_CATEGORY_ICONS.T}{MEASUREMENT_LABELS.T}</MenuItem>)
	options.push(<MenuItem key={"I"} value={"I"}>{MEASUREMENT_CATEGORY_ICONS.I}{MEASUREMENT_LABELS.I}</MenuItem>)

	let classname = !!props.className ? props.className : "col-12 row justify-content-center";

	return (
		<FormControl className={classname} key={"select_category_form"} >
			<InputLabel id="h-helper-label">{props.label}</InputLabel>
			<Select
				disabled={false}
				fullWidth
				labelId={props.label}
				id={props.id}
				value={props.selectedCategory}
				onChange={(e) => props.handleSelectCategory(e.target.value)}
			>
				{options}
			</Select>
		</FormControl>
	)
}

const MassiveValidationForm = (props) => {

	let validationOptions = null;

	switch (props.selectedCategory) {
		case "P":
			validationOptions = rainValidationOptions;
			break;
		case "T":
			validationOptions = temperatureValidationOptions;
			break;
		case "I":
			validationOptions = outflowValidationOptions;
			break;
		default:
			break;
	}

	let options = Object.keys(validationOptions).map(x => <MenuItem key={x} value={x}>{validationOptions[x]}</MenuItem>);

	let classname = !!props.className ? props.className : "col-12 row justify-content-center";

	return (
		<FormControl className={classname} key={"select_validation_form"} >
			<InputLabel id="h-helper-label_2">{props.label}</InputLabel>
			<Select
				disabled={props.disabled}
				fullWidth
				labelId={props.label}
				id={props.id}
				value={props.selectedMassiveValidation}
				onChange={(e) => props.handleMassiveValidation(e.target.value)}
			>
				{options}
			</Select>
		</FormControl>
	)
}

