import React from 'react';

import NumberUtils from "#/lib/NumberUtils"

import ChromaticScale from "#/lib/ChomaticScale"
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import DateUtils from "#/lib/DateUtils";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Markers from "#/lib/Markers";
import L from "leaflet";
import '#/backoffice/style/scrollbar.css';
import GridList from "@material-ui/core/GridList";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import MapComponent from "#/commons/map/MapComponent";
import PrettyLegend from "#/commons/map/PrettyLegend";
import MapWatermark from "#/commons/map/MapWatermark";
import RadarClient from "#/lib/RadarClient";
import GISTools from "#/lib/GISTools";
import * as turf from '@turf/turf';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {GeoJSON, ImageOverlay} from "react-leaflet";
import Slider from "@material-ui/core/Slider";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import moment from "moment";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import MeteosatClient from "#/lib/MeteosatClient";
import _ from 'lodash';
import {RiRadarFill} from 'react-icons/ri';
import {FaSatellite} from 'react-icons/fa';
import AnimationPlayer from "#/commons/map/AnimationPlayer";
import NowcastingClient from "#/lib/NowcastingClient";

require('leaflet.sync')

const ReactSwal = withReactContent(Swal);

const SRT_LAYER_OPTIONS = [
	{ value: "SRT1", udm: 'mm', label: "Precipitazione cumulata a 1 ora", legendLabel: "Cumulata</br>a 1 ora [mm]" },
	{ value: "SRT3", udm: 'mm', label: "Precipitazione cumulata a 3 ore", legendLabel: "Cumulata</br>a 3 ore [mm]" },
	{ value: "SRT6", udm: 'mm', label: "Precipitazione cumulata a 6 ore", legendLabel: "Cumulata</br>a 6 ore [mm]" },
	{ value: "SRT12", udm: 'mm', label: "Precipitazione cumulata a 12 ore", legendLabel: "Cumulata</br>a 12 ore [mm]" },
	{ value: "SRT24", udm: 'mm', label: "Precipitazione cumulata a 24 ore", legendLabel: "Cumulata</br>a 24 ore [mm]"}
];
const CAPPI_LAYER_OPTIONS = [
	{ value: "CAPPI2", udm: 'dBz', label: "Riflettività a 2000 m", legendLabel: "Riflettività a 2000 m"},
	{ value: "CAPPI3", udm: 'dBz', label: "Riflettività a 3000 m", legendLabel: "Riflettività a 3000 m"},
	{ value: "CAPPI4", udm: 'dBz', label: "Riflettività a 4000 m", legendLabel: "Riflettività a 4000 m"},
	{ value: "CAPPI5", udm: 'dBz', label: "Riflettività a 5000 m", legendLabel: "Riflettività a 5000 m"},
];
const OTHER_RADAR_OPTIONS = [
	{ value: "SRI", udm: 'mm/h', label: "Surface Rainfall Intensity (SRI)", legendLabel: "Surface Rainfall Intensity"},
	{ value: "VMI", udm: 'dBz', label: "Vertical Maximum Intensity (VMI)", legendLabel: "Vertical Maximum Intensity"},
];
const nowCastingLayer = { value: "NOWCASTING", udm: 'mm', label: "Precipitazione cumulata a 1 ora", legendLabel: "Cumulata</br>a 1 ora [mm]" };
const ALL_RADAR_OPTIONS = [nowCastingLayer,...SRT_LAYER_OPTIONS, ...CAPPI_LAYER_OPTIONS, ...OTHER_RADAR_OPTIONS];

const VIS_LAYER_OPTIONS = [
	{ value: "PM_VIS006", udm: '', label: "Visible 6 µm", legendLabel: "Visible 6 µm"},
	{ value: "PM_VIS008", udm: '', label: "Visible 8 µm", legendLabel: "Visible 8 µm"}
];
const WV_LAYER_OPTIONS = [
	{ value: "PM_WV_062", udm: '', label: "Water vapour 6.2 µm", legendLabel: "Water vapour 6.2 µm"},
	{ value: "PM_WV_073", udm: '', label: "Water vapour 7.3 µm", legendLabel: "Water vapour 7.3 µm"}
];
const IF_LAYER_OPTIONS = [
	{ value: "PM_IR_016", udm: '', label: "Infrared 1.6 µm", legendLabel: "Infrared 1.6 µm"},
	{ value: "PM_IR_039", udm: '', label: "Infrared 3.9 µm", legendLabel: "Infrared 3.9 µm"},
	{ value: "PM_IR_087", udm: '', label: "Infrared 8.7 µm", legendLabel: "Infrared 8.7 µm"},
	{ value: "PM_IR_097", udm: '', label: "Infrared 9.7 µm", legendLabel: "Infrared 9.7 µm"},
	{ value: "PM_IR_108", udm: '', label: "Infrared 10.8 µm", legendLabel: "Infrared 10.8 µm"},
	{ value: "PM_IR_120", udm: '', label: "Infrared 12 µm", legendLabel: "Infrared 12 µm"},
	{ value: "PM_IR_134", udm: '', label: "Infrared 13.4 µm", legendLabel: "Infrared 13.4 µm"}
];
const ALL_METEOSAT_OPTIONS = [
	...VIS_LAYER_OPTIONS,
	...WV_LAYER_OPTIONS,
	...IF_LAYER_OPTIONS
];

const mapOptions = {
	center: [39.11, 16.55],
	zoom: 7,
	minZoom: 3,
	width: "40vw",
	height: "60vh",
	forecastColor: '#0eb1b7',
	stormColor: '#bc4949'
}

const zoneBbox = GISTools.getBBoxFromPoints(turf.lineString([[12.766113,36.553775],[18.204346,40.784701]]));

const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

const defaultPngCache = {
	radar: {},
	meteosat: {}
}

export default class RadarMeteosatComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			pointsKey: 1,
			loadingTab: true,

			mapRadarKey: 1,
			legendRadarKey: 1,
			selectedRadarLayer: null,
			radarData: [],
			filteredRadarData: [],
			dataRadarIndex: 0,
			showRadarRaster: true,
			radarLayerMap: {},
			selectedRadarTimeSlot: 'H3',
			showRadarBorders: true,

			mapMeteosatKey: 1,
			legendMeteosatKey: 1,
			selectedMeteosatLayer: null,
			meteosatData: [],
			filteredMeteosatData: [],
			dataMeteosatIndex: 0,
			showMeteosatRaster: true,
			meteosatLayerMap: {},
			selectedMeteosatTimeSlot: 'H3',
			showMeteosatBorders: true,

			cachedPng: defaultPngCache,

			nowcastingData: null,

			loadingRadarLayer: false

		}
	}

	componentDidMount = () => {

		Promise.all([this.getAvailableRadarLayersPromise(), this.getAvailableMeteosatLayersPromise()])
			.then(([radarLayerMap, meteosatLayerMap]) => {
				this.setState({
					radarLayerMap,
					meteosatLayerMap,
					loadingTab: false,
					lastRadarRunning: DateUtils.epochToDateMinuteResolution(radarLayerMap.timestamp),
					lastMeteosatRunning: DateUtils.epochToDateMinuteResolution(meteosatLayerMap.timestamp),
					lastRunning: DateUtils.epochToDateMinuteResolution(moment().valueOf()),
					//cachedImage : URL.createObjectURL(file)
				}, () => {
					this.fixRadarMap();
					this.fixMeteosatMap();
					this.syncMaps()
					if (this.props.ready){
						this.props.ready()
					}
					this.handleListItemClick(null, 'VMI', 'radar', true)

				})
			}).catch(errors =>{
			console.log('Errors from promise all',errors)
		})
	}


	getAvailableRadarLayersPromise = () => new Promise((resolve, reject) => {
		RadarClient.getAvailableRadarLayers(
			radarLayerMap => {
				console.log("radarLayerMap",radarLayerMap)
				resolve(radarLayerMap)
			},
			() => {
				console.log("Error retrieving radar layer map...")
			}
		)
	})
	getAvailableMeteosatLayersPromise = () => new Promise((resolve, reject) => {
		MeteosatClient.getAvailableMeteosatLayers(
			meteosatLayerMap => {
				console.log("meteosatLayerMap",meteosatLayerMap)
				resolve(meteosatLayerMap)
			},
			() => {
				console.log("Error retrieving meteosat layer map...")
			}
		)
	})


	getMeteosatPngPromise = (pngUrl, timestamp, selectedLayer, cachedPng) => new Promise((resolve, reject) => {
		let cachedPngImage = cachedPng.meteosat[selectedLayer][timestamp]
		if (cachedPngImage){
			resolve({[timestamp]: cachedPngImage})
		} else {
			MeteosatClient.getMeteosatByPngUrl(image => {
				resolve({[timestamp]: image})
			}, err => {
				console.log(err);
			}, pngUrl)
		}
	})

	getMeteosatData = selectedMeteosatLayer => {
		let now = moment().valueOf();
		let selectedInterval = {start: now - (26*60*60*1000), end: now};
		let currentTimestamp = this.state.filteredMeteosatData.length > 0 ?
			this.getCurrentTimestampForCurrentLayer(this.state.filteredMeteosatData, this.state.dataMeteosatIndex) : null;
		loadingSwal.fire('Recupero dati Meteosat ['+selectedMeteosatLayer+'] in corso');
		MeteosatClient.getMeteosatDataByInterval(

			meteosatData => {
				let {cachedPng} = this.state;
				if (!(selectedMeteosatLayer in cachedPng.meteosat)){
					cachedPng.meteosat[selectedMeteosatLayer] = {};
				}
				let filteredMeteosatData = this.getFilteredMeteosatData(this.state.selectedMeteosatTimeSlot, meteosatData)
				if(filteredMeteosatData.length === 0){
					loadingSwal.close();
					ReactSwal.fire(
						"Recupero dati Meteosat",
						"Layer " + selectedMeteosatLayer + " non disponibile!",
						"error"
					);
					return;
				}

				// Get first image
				let lastData = meteosatData[meteosatData.length -1];
				let {pngUrl : temporaryMeteosatImageUrl, timestamp : lastTimestamp} = lastData;

				loadingSwal.close();
				this.getMeteosatPngPromise(temporaryMeteosatImageUrl, lastTimestamp, selectedMeteosatLayer, cachedPng)
					.then(obj => {
						this.setState({
							temporaryMeteosatImageUrl: obj[lastTimestamp],
							loadingMeteosatLayer: true,
							selectedMeteosatLayer,
							filteredMeteosatData,
							meteosatData,
							mapMeteosatKey: (this.state.mapMeteosatKey + 1) % 1000
						},() => {
							let newState = {
								filteredMeteosatData,
								cachedPng,
								loadingMeteosatLayer: false
							}

							Promise.all(meteosatData.map(({pngUrl, timestamp}) => this.getMeteosatPngPromise(pngUrl, timestamp, selectedMeteosatLayer, cachedPng)))
								.then( cachedImageObjects => {
									newState.cachedPng.meteosat[selectedMeteosatLayer] = cachedImageObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{})
									loadingSwal.close();
									this.updateMeteosatSnapshot(filteredMeteosatData.length - 1, true, newState)
								})
						})
					})


			},
			(error) => {
				console.log(">>>>>>>>>>>>>>>>> Problems retrieving meteosat data ");
				loadingSwal.close();
				ReactSwal.fire('Errore nel recupero dei dati','','error');
			},
			selectedInterval,
			selectedMeteosatLayer
		)
	};

	getRadarPngPromise = (pngUrl, timestamp, selectedLayer, cachedPng) => new Promise((resolve, reject) => {
		let cachedPngImage = cachedPng.radar[selectedLayer][timestamp]
		if (cachedPngImage){
			resolve({[timestamp]: cachedPngImage})
		} else {
			RadarClient.getRadarByPngUrl(image => {
				resolve({[timestamp]: image})
			}, err => {
				console.log(err);
			}, pngUrl)
		}
	})


	getRadarData = (selectedRadarLayer, init) => {
		let now = moment().valueOf();
		let selectedInterval = {start: now - (26*60*60*1000), end: now};
		loadingSwal.fire('Recupero dati di ' + ALL_RADAR_OPTIONS.find(o => o.value === selectedRadarLayer).label + ' in corso');
		let currentTimestamp = this.state.filteredRadarData.length > 0 ?
			this.getCurrentTimestampForCurrentLayer(this.state.filteredRadarData, this.state.dataRadarIndex) : null;
		RadarClient.getRadarDataByInterval(

			radarData => {
				let {cachedPng, legendRadarKey} = this.state;
				if (!(selectedRadarLayer in cachedPng.radar)){
					cachedPng.radar[selectedRadarLayer] = {};
				}

				let filteredRadarData = this.getFilteredRadarData(this.state.selectedRadarTimeSlot, radarData)
				if(filteredRadarData.length === 0){
					loadingSwal.close();
					ReactSwal.fire(
						"Recupero dati Radar",
						"Layer " + selectedRadarLayer + " non disponibile!",
						"error"
					);
					return;
				}

				// Get first image
				let lastData = radarData[radarData.length -1];
				let {pngUrl : temporaryRadarImageUrl, timestamp : lastTimestamp} = lastData;

				loadingSwal.close();
				this.getRadarPngPromise(temporaryRadarImageUrl, lastTimestamp, selectedRadarLayer, cachedPng)
					.then(obj => {
						this.setState({
							temporaryRadarImageUrl: obj[lastTimestamp],
							loadingRadarLayer: true,
							selectedRadarLayer,
							filteredRadarData,
							radarData,
							mapRadarKey: (this.state.mapRadarKey + 1) % 1000
						}, () => {

							let newState = {
								cachedPng,
								filteredRadarData,
								legendRadarKey: (legendRadarKey + 1) % 1000,
								loadingRadarLayer: false
							}
							Promise.all(radarData.map(({pngUrl, timestamp}) => this.getRadarPngPromise(pngUrl, timestamp, selectedRadarLayer, cachedPng)))
								.then( cachedImageObjects => {
									newState.cachedPng.radar[selectedRadarLayer] = cachedImageObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{})
									this.updateRadarSnapshot(filteredRadarData.length - 1, this.state.showRadarRaster, newState, init)
								})
						})
					})
			},
			(error) => {
				console.log(">>>>>>>>>>>>>>>>> Problems retrieving radar data ");
				loadingSwal.close();
				ReactSwal.fire('Errore nel recupero dei dati','','error');
			},
			selectedInterval,
			selectedRadarLayer === 'NOWCASTING' ? 'SRT1' : selectedRadarLayer
		)
	};

	fixRadarMap = () => {
		if (!!this.mapRadarRef){
			this.mapRadarRef.leafletElement.invalidateSize();
			this.mapRadarRef.leafletElement.fitBounds([
				[zoneBbox.bbox[1], zoneBbox.bbox[0]],
				[zoneBbox.bbox[3], zoneBbox.bbox[2]]
			]);
			/*	this.mapRadarRef.leafletElement.panTo(new L.LatLng(39.11, 16.55));
                this.mapRadarRef.leafletElement.setZoom(mapOptions.zoom);
                this.mapRadarRef.leafletElement.invalidateSize();*/
		}
	}
	fixMeteosatMap = () => {
		if (!!this.mapMeteosatRef){
			this.mapMeteosatRef.leafletElement.invalidateSize();
			this.mapMeteosatRef.leafletElement.fitBounds([
				[zoneBbox.bbox[1], zoneBbox.bbox[0]],
				[zoneBbox.bbox[3], zoneBbox.bbox[2]]
			]);
			/*this.mapMeteosatRef.leafletElement.panTo(new L.LatLng(39.11, 16.55));
			this.mapMeteosatRef.leafletElement.setZoom(mapOptions.zoom);
			this.mapMeteosatRef.leafletElement.invalidateSize();*/
		}
	}
	syncMaps = () => {
		if (!!this.mapMeteosatRef && !!this.mapRadarRef){
			this.mapRadarRef.leafletElement.sync(this.mapMeteosatRef.leafletElement);
			this.mapMeteosatRef.leafletElement.sync(this.mapRadarRef.leafletElement);
		}
	}

	showInterpolatedPopup = (val, lnglat, map, area) => {
		let showRaster = area === 'radar' ? this.state.showRadarRaster : this.state.showMeteosatRaster;
		let selectedLayer = area === 'radar' ? this.state.selectedRadarLayer : this.state.selectedMeteosatLayer;
		if (showRaster){
			let valore = val[0] !== -9999 ? `${NumberUtils.round(val, 2)} ${ALL_RADAR_OPTIONS.find(o => o.value === selectedLayer).udm}` : 'non rilevato';
			L.popup({offset: [40, 40], closeButton: false})
				.setLatLng(L.latLng(lnglat[0], lnglat[1]))
				.setContent(`<div>Valore misurato: ${valore}</div>`)
				.openOn(map);
			setTimeout(() => {
				map.closePopup();
			}, 3000)
		}
	}

	createMarker = (feature, latlng, color, borderColor = 'grey') => Markers.getMarker(latlng, color, borderColor);

	toggleSRTCategoryList = () => {
		this.setState({
			showSRTCategoryList: !this.state.showSRTCategoryList,
		})
	}
	toggleCAPPICategoryList = () => {
		this.setState({
			showCAPPICategoryList: !this.state.showCAPPICategoryList,
		})
	}

	toggleMeteosatSubCategoryList = category => {
		this.setState({
			[`show${category.toUpperCase()}CategoryList`]: !this.state[`show${category.toUpperCase()}CategoryList`]
		})
	}

	removeLegend = area => {
		let tmp = document.getElementsByClassName(`${area}Legend`);
		if (!!tmp && tmp.length > 0) {
			tmp[0].remove()
		}
	}

	handleListItemClick = (event, selectedLayer, area, init ) => {
		console.log("Selected layer", selectedLayer)
		/*this.removeLegend(area);*/
		if (area === 'radar') {
			this.getRadarData(selectedLayer, init);
		} else {
			this.getMeteosatData(selectedLayer);
		}
	};
	handleNowcastingClick = () => {
		console.log("Selected layer", 'NOWCASTING');
		loadingSwal.fire('Caricamento Nowcasting in corso...');
		this.getNowcastingDataPromise()
			.then(nowcastingData => {
				loadingSwal.close();
				this.setState({nowcastingData}, () => {
					this.getRadarData("NOWCASTING");
				})
			}).catch(err => {
			console.error(err)
			loadingSwal.close();
		})
	};
	getNowcastingDataPromise = () => new Promise((resolve, reject) =>{
		if (!this.state.nowcastingData){
			NowcastingClient.getNowcastingData(
				nowcastingData => {
					resolve(nowcastingData)
				},
				err => {
					reject(err)
				}
			)
		} else resolve(this.state.nowcastingData)
	})
	getSRTCategoryList = layer =>[
		<ListItem
			key={'srt_container'}
			button
			selected={layer === 'srt_container'}
			onClick={() => this.toggleSRTCategoryList()}
			style={{padding: '7.5px'}}
		>
			<ListItemIcon>
				{this.state.showSRTCategoryList ? <ExpandLess/> : <ExpandMore/>}
			</ListItemIcon>
			<ListItemText>SRT</ListItemText>
		</ListItem>,
		<Collapse key={`collapsed_srt`} in={this.state.showSRTCategoryList} timeout="auto" unmountOnExit>
			<GridList spacing={15} cellHeight='auto' cols={1}>
				{SRT_LAYER_OPTIONS.map(({value: layerKey, label, icon}) =>
					<ListItem
						key={layerKey}
						className={"pl-4 " + ((!this.state.radarLayerMap[layerKey] ) ? "d-none":"")}
						button
						disabled={!this.state.radarLayerMap[layerKey] || !this.state.showRadarRaster}
						selected={layer === layerKey}
						onClick={(event) => this.handleListItemClick(event, layerKey, 'radar')}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={label}/>
					</ListItem>
				)}
			</GridList>
		</Collapse>
	]

	getCAPPICategoryList = selectedLayer =>[
		<ListItem
			key={'cappi_container'}
			button
			selected={selectedLayer === 'cappi_container'}
			onClick={() => this.toggleCAPPICategoryList()}
			style={{padding: '7.5px'}}
		>
			<ListItemIcon>
				{this.state.showCAPPICategoryList ? <ExpandLess/> : <ExpandMore/>}
			</ListItemIcon>
			<ListItemText>Constant altitude plan position indicator (CAPPI)</ListItemText>
		</ListItem>,
		<Collapse key={`collapsed_srt`} in={this.state.showCAPPICategoryList} timeout="auto" unmountOnExit>
			<GridList spacing={15} cellHeight='auto' cols={1}>
				{CAPPI_LAYER_OPTIONS.map(({value: layerKey, label, icon}) =>
					<ListItem
						key={layerKey}
						className={"pl-4 " + ((!this.state.radarLayerMap[layerKey]) ? "d-none":"")}
						button
						disabled={!this.state.radarLayerMap[layerKey] || !this.state.showRadarRaster}
						selected={selectedLayer === layerKey}
						onClick={(event) => this.handleListItemClick(event, layerKey, 'radar')}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={label}/>
					</ListItem>
				)}
			</GridList>
		</Collapse>
	]
	getNowcastingItem = selectedLayer =>
		<ListItem
			key={'NOWCASTING'}
			button
			disabled={!this.state.showRadarRaster}
			selected={selectedLayer === 'NOWCASTING'}
			onClick={(event) => this.handleNowcastingClick()}
		>
			<ListItemIcon>{}</ListItemIcon>
			<ListItemIcon><img className="img-fluid" style={{maxWidth: "6vw"}} src="/img/nowcasting/nowcasting.png" alt="Logo"  /></ListItemIcon>

		</ListItem>

	getOtherCategoryList = selectedLayer => OTHER_RADAR_OPTIONS.map(({value: layerKey, icon, label}) =>
		[<ListItem
			key={layerKey}
			className = { ((!this.state.radarLayerMap[layerKey] ) ? "d-none":"")}
			button
			disabled={!this.state.radarLayerMap[layerKey] || !this.state.showRadarRaster}
			selected={selectedLayer === layerKey}
			onClick={(event) => this.handleListItemClick(event, layerKey, 'radar')}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={label}/>
		</ListItem>]
	)
	getMeteosatSubCategoryList = (layer, category) => {
		let listLabel = category === 'vis' ? 'Visible' : (category === 'ir' ? 'Infrared' : 'Water Vapour');

		return [
			<ListItem
				key={`${category}_container`}
				button
				selected={layer === `${category}_container`}
				onClick={() => this.toggleMeteosatSubCategoryList(category)}
				style={{padding: '7.5px'}}
			>
				<ListItemIcon>
					{this.state[`show${category.toUpperCase()}CategoryList`] ? <ExpandLess/> : <ExpandMore/>}
				</ListItemIcon>
				<ListItemText>{listLabel}</ListItemText>
			</ListItem>,
			<Collapse key={`collapsed_${category}`} in={this.state[`show${category.toUpperCase()}CategoryList`]} timeout="auto" unmountOnExit>
				<GridList spacing={15} cellHeight='auto' cols={1}>
					{[...category === 'vis' ? VIS_LAYER_OPTIONS : (category === 'ir' ? IF_LAYER_OPTIONS : WV_LAYER_OPTIONS)].map(({value: layerKey, label, icon}) =>
						<ListItem
							key={layerKey}
							className={"pl-4 " + ((!this.state.meteosatLayerMap[layerKey]) ? "d-none":"")}
							button
							disabled={!this.state.meteosatLayerMap[layerKey] || !this.state.showMeteosatRaster}
							selected={layer === layerKey}
							onClick={(event) => this.handleListItemClick(event, layerKey, 'meteosat')}
						>
							<ListItemIcon>{icon}</ListItemIcon>
							<ListItemText primary={label}/>
						</ListItem>
					)}
				</GridList>
			</Collapse>
		]
	}
	getCurrentTimestampForCurrentLayer = (data, index) => data[index].timestamp;

	findClosestTimestamp = (data, timestampToFind) => data.reduce(({timestamp}, {timestamp:a}) =>
		Math.abs(timestampToFind - a) < Math.abs(timestampToFind - timestamp) ? {timestamp: a} : {timestamp}
	).timestamp;

	updateRadarSnapshot = (dataRadarIndex, showRadarRaster, newState = {},init ) => {

		/*	if (currentTimestamp){
                let newTimestamp = this.findClosestTimestamp(newState.filteredRadarData, currentTimestamp);
                dataRadarIndex = newState.filteredRadarData.findIndex(d => d.timestamp === newTimestamp);
            }*/

		if (!showRadarRaster){
			this.setState({
				...newState,
				showRadarRaster,
				dataRadarIndex,
				mapRadarKey: (this.state.mapRadarKey + 1) % 1000
			})
		} else {
			this.setState({
				loadingRadarLayer: true
			}, () => {
				const {mapRadarKey, legendRadarKey} = this.state;
				const {filteredRadarData} = _.isEmpty(newState) ? this.state : newState;

				if (filteredRadarData.length > 0) {
					RadarClient.getRadarByTiffUrl(
						currentRadarTiff => {
							this.setState({
								...newState,
								mapRadarKey: (mapRadarKey + 1) % 1000,
								currentRadarTiff,
								showRadarRaster,
								dataRadarIndex,
								loadingRadarLayer: false
							}, () => {
								if (init){
									this.handleListItemClick(null, 'PM_VIS008', 'meteosat');
								}
							})
						},
						(error) => console.log(">>>>>>>>>>>>>>>>> Problems retrieving radar tiff "),
						filteredRadarData[dataRadarIndex].tiffUrl
					)
				}

			})
		}
	}

	updateMeteosatSnapshot = (dataMeteosatIndex, showMeteosatRaster, newState = {}) => {

		this.setState({
			...newState,
			dataMeteosatIndex,
			showMeteosatRaster,
			mapMeteosatKey: (this.state.mapMeteosatKey + 1) % 1000,
		})

	}

	getFilteredRadarData = (selectedTimeSlot, radarData = this.state.radarData) => {
		if (radarData.length > 0) {
			let numOfHours = selectedTimeSlot.match(/\d+/g).map(Number)[0];
			let lastTimestamp = radarData[radarData.length - 1].timestamp;
			let startTimestamp = lastTimestamp - numOfHours * 60 * 60 * 1000;
			return radarData.filter(data => data.timestamp >= startTimestamp);
		} else return radarData;
	}
	filterRadarData = selectedTimeSlot => {
		this.setState({dataRadarIndex: 0, selectedTimeSlot, filteredRadarData: this.getFilteredRadarData(selectedTimeSlot)});
	}

	getFilteredMeteosatData = (selectedTimeSlot, meteosatData = this.state.meteosatData) => {
		if (meteosatData.length > 0) {
			let numOfHours = selectedTimeSlot.match(/\d+/g).map(Number)[0];
			let lastTimestamp = meteosatData[meteosatData.length - 1].timestamp;
			let startTimestamp = lastTimestamp - numOfHours * 60 * 60 * 1000;
			return meteosatData.filter(data => data.timestamp >= startTimestamp);
		} else return meteosatData;
	}
	filterMeteosatData = selectedTimeSlot => {
		this.setState({dataMeteosatIndex: 0, selectedTimeSlot, filteredMeteosatData: this.getFilteredMeteosatData(selectedTimeSlot)});
	}
	removeExistingWatermark = customClass => {
		let watermarkArray = document.getElementsByClassName(customClass);
		if (!!watermarkArray && watermarkArray.length > 0) {
			watermarkArray[0].remove()
		};
	}
	render() {

		const {selectedRadarLayer, selectedMeteosatLayer,
			currentRadarTiff,
			mapRadarKey, mapMeteosatKey,
			legendRadarKey,
			loadingTab,
			showRadarRaster,
			showMeteosatRaster,
			filteredRadarData, filteredMeteosatData,
			dataRadarIndex, dataMeteosatIndex, cachedPng,
			showRadarBorders,
			showMeteosatBorders,
			temporaryRadarImageUrl,
			loadingRadarLayer,
			temporaryMeteosatImageUrl,
			loadingMeteosatLayer,
			nowcastingData
		} = this.state;
		let radarImgUrl = null;
		if (showRadarRaster && showMeteosatRaster || loadingRadarLayer || loadingMeteosatLayer){
			this.fixRadarMap();
			this.fixMeteosatMap();
		}
		if (filteredRadarData.length > 0 ){
			try {
				radarImgUrl = cachedPng.radar[selectedRadarLayer][filteredRadarData[dataRadarIndex].timestamp];
			} catch (err) {
				console.error(err)
			}
		}
		let meteosatImgUrl = null;
		if (filteredMeteosatData.length > 0 ){
			try {
				meteosatImgUrl = cachedPng.meteosat[selectedMeteosatLayer][filteredMeteosatData[dataMeteosatIndex].timestamp];
			} catch (err) {
				console.error(err)
			}
		}

		if (selectedRadarLayer && selectedRadarLayer !== 'NOWCASTING'){
			this.removeExistingWatermark('forecastWatermark');
			this.removeExistingWatermark('stormWatermark');
			this.removeExistingWatermark('exec');
		}

		const stub = (<div className=" pr-0 pl-0">
			<SkeletonTheme>
				<div className="d-inline-flex mt-2 mb-2"  style={{width: '100%', height: '70vh'}}>
					<div style={{width: '25%'}} >
						<Skeleton height={'47%'} duration={8}/>
						<Skeleton height={'47%'} duration={8}/>
					</div>
					<div style={{width: '38%', paddingLeft:'1vw'}}>
						<Skeleton height={'90%'} duration={8}/>
						<Skeleton height={'5%'} duration={8}/>
					</div>
					<div style={{width: '38%', paddingLeft:'1vw'}}>
						<Skeleton height={'90%'} duration={8}/>
						<Skeleton height={'5%'} duration={8}/>

					</div>
				</div>
			</SkeletonTheme>
		</div>);
		const effectiveSelectedRadarLayer = selectedRadarLayer === 'NOWCASTING' ? 'SRT1' : selectedRadarLayer;
		return (
			<>
				{loadingTab ? stub :
					<div className="container-fluid pr-0 pl-0">
						<div className="d-inline-flex mt-2 mb-2"  style={{width: '100%'}}>
							<div style={{width: '25%', height: "70vh", overflowY: 'auto'}} className="scrollbar scrollbar-primary">
								<Accordion defaultActiveKey="0">
									<Card>
										<Card.Header>
											<Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
												<div className="justify-content-between">
													<RiRadarFill/>
													<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Radar</span>
													{this.state.lastRadarRunning &&
													<span style={{fontSize: '0.6rem', float: 'right'}}>Ultimo dato disponibile: {this.state.lastRadarRunning}</span>}
												</div>
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
												<GridList spacing={15} cellHeight='auto' cols={1}>
													{this.getNowcastingItem(selectedRadarLayer)}
													{this.getSRTCategoryList(selectedRadarLayer)}
													{this.getCAPPICategoryList(selectedRadarLayer)}
													{this.getOtherCategoryList(selectedRadarLayer)}
												</GridList>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Card.Header>
											<Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
												<div className="justify-content-between">
													<FaSatellite/>
													<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab", marginLeft: '1vw'}}>Meteosat</span>
													{this.state.lastMeteosatRunning &&
													<span style={{fontSize: '0.6rem', float: 'right'}}>Ultimo dato disponibile: {this.state.lastMeteosatRunning}</span>}
												</div>
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="1">
											<Card.Body>
												{['vis', 'ir', 'wv'].map(category => this.getMeteosatSubCategoryList(selectedMeteosatLayer, category))}
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div style={{width: '38%', paddingLeft:'1vw'}}>
								<div className="custom-popup">
									<MapComponent
										width={"100%"}
										height={'62vh'}
										zoomSnap={false}
										zoneBbox={zoneBbox}
										setMapRef={mapRef => this.mapRadarRef = mapRef}
										buttonKey={'radar'}
										tile={null}
										skipRecenter={true}
										toggleBorders={() => this.setState({showRadarBorders: !showRadarBorders})}>
										{selectedRadarLayer && <>
											{(showRadarRaster && currentRadarTiff) && <GeotiffRasterLayer
												key={'radar_' + mapRadarKey}
												georaster={currentRadarTiff}
												opacity={0}
												resolution={256}
												colorScale={ChromaticScale.getScaleBySensorCategory(effectiveSelectedRadarLayer)}
												handleClick={(val, latlng) => this.showInterpolatedPopup(val, latlng, this.mapRadarRef.leafletElement, 'radar')}
												ndColor={'grey'}
												enableClickOutOfCalabria={true}
											/>}
											{!loadingRadarLayer && radarImgUrl  && <ImageOverlay
												key={"image_radar_" + mapRadarKey}
												url={radarImgUrl}
												bounds={filteredRadarData[dataRadarIndex].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>
											}
											{loadingRadarLayer && <ImageOverlay
												key={"temp_image_radar_" + mapRadarKey}
												url={temporaryRadarImageUrl}
												bounds={filteredRadarData[filteredRadarData.length - 1].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>}
											{selectedRadarLayer === 'NOWCASTING' && nowcastingData && <>
												<GeoJSON
													key={'storm'}
													data={nowcastingData.storm}
													style={{
														fillColor: "#fff",
														fillOpacity: 1,
														weight: 2,
														opacity: 1,
														color: mapOptions.stormColor,
													}}
												/>
												<GeoJSON
													key={'forecast'}
													data={nowcastingData.forecast}
													style={{
														fillColor: "#fff",
														fillOpacity: 1,
														weight: 2,
														opacity: 1,
														color: mapOptions.forecastColor,
													}}
												/></>}
											<PrettyLegend
												key={'legend_radar_' + legendRadarKey}
												customClass={'radarLegend'}
												/*legendTitle={ALL_RADAR_OPTIONS.find(opt => opt.value === selectedRadarLayer).legendLabel}*/
												grades={ChromaticScale.getDomainBySensorCategory(effectiveSelectedRadarLayer)}
												getColor={num => ChromaticScale.getScaleBySensorCategory(effectiveSelectedRadarLayer)(num)}
												tickerGap={4} // a sixth
												// tickerValues={[-20, 0, 20, 40, 60]}
											/>
											{selectedRadarLayer === 'NOWCASTING' && nowcastingData && <>
												<MapWatermark
													key={'exec'}
													customClass={'exec'}
													text={'Tempo di esecuzione: ' + DateUtils.epochToGMT1String(nowcastingData.executionTimestamp)}

												/>
												<MapWatermark
													key={'watermark_storm'}
													customClass={'stormWatermark'}
													text={'Storm'}
													customColor={mapOptions.stormColor}
												/>
												<MapWatermark
													key={'watermark_forecast'}
													customClass={'forecastWatermark'}
													text={'Forecast'}
													customColor={mapOptions.forecastColor}
												/>
											</> }
											<MapWatermark
												key={'watermark_radar_' + mapRadarKey}
												customClass={'radarWatermark'}
												text={ALL_RADAR_OPTIONS.find(opt => opt.value === selectedRadarLayer).label}
											/>
										</>}
										{showRadarBorders && <GeoJSON key={"calabria_radar_"}
																	  data={GISTools.getRegioniBorders()}
																	  style={{
																		  fillColor: "#fff",
																		  fillOpacity: 0,
																		  weight: 2,
																		  opacity: 1,
																		  color: "green",
																	  }}/>}
									</MapComponent>

									<AnimationPlayer
										forcedIndex={this.state.dataRadarIndex}
										data={this.state.filteredRadarData}
										updateSnapshot={(dataIndex, showRaster) => this.updateRadarSnapshot(dataIndex, showRaster)}
										filterData ={selectedTimeSlot => this.filterRadarData(selectedTimeSlot)}
										selectedTimeSlot={this.state.selectedRadarTimeSlot}
										loadingLayer={loadingRadarLayer}/>
								</div>
							</div>
							<div style={{width: '38%', paddingLeft:'1vw'}}>
								<div className="custom-popup">
									<MapComponent
										width={"100%"}
										height={'62vh'}
										zoomSnap={false}
										zoneBbox={zoneBbox}
										setMapRef={mapRef => this.mapMeteosatRef = mapRef}
										buttonKey={'meteosat'}
										tile={null}
										skipRecenter={true}
										toggleBorders={() => this.setState({showMeteosatBorders: !showMeteosatBorders})}>
										{selectedMeteosatLayer && <>
											{!loadingMeteosatLayer && meteosatImgUrl && <ImageOverlay
												key={"image_meteosat_" + mapMeteosatKey}
												url={meteosatImgUrl}
												bounds={filteredMeteosatData[dataMeteosatIndex].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>}
											{loadingMeteosatLayer && <ImageOverlay
												key={"temp_image_meteosat_" + mapMeteosatKey}
												url={temporaryMeteosatImageUrl}
												bounds={filteredMeteosatData[filteredMeteosatData.length - 1].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>}
											<MapWatermark
												key={'watermark_meteosat_' + mapMeteosatKey}
												customClass={'meteosatWatermark'}
												text={ALL_METEOSAT_OPTIONS.find(opt => opt.value === selectedMeteosatLayer).label}
											/>
										</>}
										{showMeteosatBorders && false && <GeoJSON key={"calabria_meteosat_"}
																		 data={GISTools.getRegioniBorders()}
																		 style={{
																			 fillColor: "#fff",
																			 fillOpacity: 0,
																			 weight: 2,
																			 opacity: 1,
																			 color: "green",
																		 }}/>}
									</MapComponent>
									<AnimationPlayer
										forcedIndex={this.state.dataMeteosatIndex}
										data={this.state.filteredMeteosatData}
										updateSnapshot={(dataIndex, showRaster) => this.updateMeteosatSnapshot(dataIndex, showRaster)}
										filterData ={selectedTimeSlot => this.filterMeteosatData(selectedTimeSlot)}
										selectedTimeSlot={this.state.selectedMeteosatTimeSlot}
										loadingLayer={loadingMeteosatLayer}/>
								</div>
							</div>
						</div>

					</div>
				}
			</>
		)
	}
}



