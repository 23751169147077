import React from 'react';
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import moment from "moment";
import HecHMSExecutions from "#/backoffice/hecHMS/component/HecHMSExecutions";
import HecHMSData from "#/backoffice/hecHMS/component/HecHMSData";
import HecHmsClient from "#/lib/HecHmsClient";
import DateUtils from "#/lib/DateUtils";
import Tooltip from "@material-ui/core/Tooltip";
import {FaAngleLeft} from "react-icons/fa";
import chroma from "chroma-js";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SensorClient from "#/lib/SensorClient";
import _ from "underscore";
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';


const ReactSwal = withReactContent(Swal);
const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});
const STEP = {
	PICK_DATES: 0,
	SHOW_EXECUTION_LIST: 1,
	SHOW_EXECUTION_DATA: 2
}

let defaultInterval = {
	start : moment().add(-3,"M"),
	end : moment()
}

export default class HecHMSPage extends React.Component {
	constructor(props) {
		super(props);
		let now = moment();
		this.state = {
			step : STEP.SHOW_EXECUTION_LIST,
			year: moment().year(),
			interval: defaultInterval,
			executionList: [],
			selectedExecution: null,
			outflowScales: {}

		}
	}

	componentDidMount() {
		let executionsPromise = this.getExecutionsListPromise(this.state.interval.start.valueOf(), this.state.interval.end.valueOf())
		let outflowsScalesPromise = this.getOutflowsScalesPromise()
		Promise.all([executionsPromise, outflowsScalesPromise])
			.then (([executionList, outflowScales]) => {
				this.setState({
					executionList,
					outflowScales
				})

			})
								
		
	}

	onPrevYear() {
		this.setState({
			year: this.state.year - 1
		})
	}

	onNextYear() {
		this.setState({
			year: this.state.year + 1
		})
	}

	getExecutionsListPromise = (start, end) => new Promise((resolve, reject) => {
		HecHmsClient.getExecutionListByInterval(
			(executionList) => {
				resolve(executionList)
			},
			() => {
				console.error("Error while retrieving execution list");
				reject()
			},
			start,
			end
		)
	})
	getOutflowsScalesPromise = () => new Promise((resolve, reject) => {
		SensorClient.getAllOutflowsScalesMapBySensorCode(
			(data) => {
				if (!_.isEmpty(data)) {
					resolve(data)
				}
			},
			(msg) => {
				console.log('Error retrieving outflow scales');
			}
		)
	})

	getExecutionDataPromise = ({id}) => new Promise((resolve, reject) => {
		HecHmsClient.getExecutionDataById(
			(executionData) => {
				resolve(executionData)
			},
			() => {
				console.error("Error while re-executing");
				reject()
			},
			id
		)
	})
	getReExecutePromise = (execution) => new Promise((resolve, reject) => {
		HecHmsClient.reExecute(
			(newExecution) => {
				resolve(newExecution)
			},
			() => {
				console.error("Error while re-executing");
				reject()
			},
			execution
		)
	})
	onNewExecutionPickRange(start, end) {
		loadingSwal.fire();
		start = start.startOf('day');
		if (end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString()){
			end = moment().add(-30, 'm');
		} else {
			end = end.add(1,'d').startOf('day');
		}

		HecHmsClient.execute(
			execution => {
				loadingSwal.close();
				if (execution){
					const {interval} = this.state;
					ReactSwal.fire('La nuova esecuzione è andata a buon fine', '', 'success')
						.then(result => {
							this.getExecutionsListPromise(interval.start.valueOf(), interval.end.valueOf())
								.then(executionList => {
									this.setState({
										executionList
									})
								})
						}).catch(err => {
						console.error(err);
						loadingSwal.close()
					})
				}
			},
			() => {
				console.error("Error while creating new execution");
				loadingSwal.close();
				ReactSwal.fire('Errore', 'Si è verificato un errore durante la nuova esecuzione', 'error');

			},
			start.valueOf(),
			end.valueOf()
		)
	}


	
	onPickRange(dates){

		loadingSwal.fire();
	
		let interval = null
		if(dates && dates[0] && dates[1]){
			interval = {}
			interval.start = moment(dates[0].getTime()).startOf('day')
			interval.end = moment(dates[1].getTime()).endOf('day')
		}
		else{
			interval = defaultInterval
		}
		this.getExecutionsListPromise(interval.start.valueOf(), interval.end.valueOf())
			.then(executionList => {
				loadingSwal.close();
				this.setState({
					interval,
					executionList,
					tableKey: (this.state.tableKey + 1) % 1000	
				})
			}).catch(err => {
			console.error(err);
			loadingSwal.close()
		})
		
	}

	resetInterval = () => {
		this.setState({
			interval: null,
			year: moment().year()
		});
	}

	onSelectExecution = selectedExecution => {
		loadingSwal.fire();
		this.getExecutionDataPromise(selectedExecution)
			.then(executiondata => {
				loadingSwal.close();
				this.setState({
					executiondata,
					selectedExecution,
					step: STEP.SHOW_EXECUTION_DATA
				})
			}).catch(err => {
			console.error(err);
			loadingSwal.close()
		})

	}
	runExecution = data => {
		loadingSwal.fire();
		this.getReExecutePromise(data)
			.then( newData => {
				if (newData){
					const {start, end} = this.state.interval;
					this.getExecutionsListPromise(start, end)
						.then((executionList) => {
							loadingSwal.close();
							this.setState({
								executionList,
								tableKey: (this.state.tableKey + 1) % 1000
							})
						}).catch(err => {
						console.error(err);
						loadingSwal.close()
					})
				}
			})
	}
	toggleSidebar(event) {
		if (event==="over") {
			document.getElementById("mySidebar").style.width = "2.5rem";
			document.getElementById("main").style.marginLeft = "1.5rem";
			document.getElementById("sidebar-button").style.color = "#428f81";
		} else {
			document.getElementById("mySidebar").style.width = "1.5rem";
			document.getElementById("main").style.marginLeft = "0";
			document.getElementById("sidebar-button").style.color = chroma('#428f81').alpha(0.3)
		}
	}
	goBack = () => {
		const {step} = this.state;
		if (step === STEP.SHOW_EXECUTION_DATA){
			this.setState({step : STEP.SHOW_EXECUTION_LIST, selectedExecution: null})
		}
	}

	render = () => {
		let step;
		switch(this.state.step){
			case STEP.PICK_DATES :
				step = <>
					<div id={"main"}>
						<h3>Seleziona Intervallo di osservazione</h3>
						<BigCalendarTimeZoned
							timeZone={"Etc/GMT-1"}
							range={this.state.interval}
							year={this.state.year}
							onPrevYear={() => this.onPrevYear()}
							onNextYear={() => this.onNextYear()}
							onPickRangeCallback={(start, end, year)=> this.onPickRange(start, end, year)}
							lockedClasses={this.state.lockedClasses}
						></BigCalendarTimeZoned>
					</div>;
				</>;
				break;
			case STEP.SHOW_EXECUTION_LIST :
				step =
					<div id={"main"}>
						<h3>Esecuzioni effettuate nel periodo:</h3>
						<DateRangePicker
							locale={"it-IT"}
							onChange={(dates) => this.onPickRange(dates)}
							value={[this.state.interval && this.state.interval.start && new Date(this.state.interval.start.valueOf()), this.state.interval && this.state.interval.end && new Date(this.state.interval.end.valueOf())]}
							maxDate={new Date()}
							format={"dd/MM/yyyy"}
							rangeDivider='&nbsp;&nbsp;&nbsp;'
						/>
						<HecHMSExecutions
							interval={this.state.interval}
							tableKey={this.state.tableKey}
							data={this.state.executionList}
							onSelectExecution={data => this.onSelectExecution(data)}
							runExecution={data => this.runExecution(data)}
							onNewExecutionPickRange={(start, end) => this.onNewExecutionPickRange(start, end)}/>
					</div>;
				break;
			case STEP.SHOW_EXECUTION_DATA :
				step =
					<div id={"main"}>

						<HecHMSData
							title={`Esecuzione dal ${DateUtils.epochToDateMinuteResolution(this.state.selectedExecution.start)} al ${DateUtils.epochToDateMinuteResolution(this.state.selectedExecution.end)}`}
							dataKey={this.state.dataKey}
							data={this.state.executiondata}
							outflowScales={this.state.outflowScales}/>
					</div>;

				break;
			default :
				step = <></>;
				break;
		}

		return (
			<div className="generic-page container-fluid data-page">
				<div id="mySidebar" className="sidebar" style={{display: this.state.step === STEP.SHOW_EXECUTION_LIST ? 'none' : 'block'}}
					 onMouseOver={() => this.toggleSidebar("over")}
					 onMouseOut={() => this.toggleSidebar("out")}
					 onClick={() => this.goBack()}>
					<Tooltip open={false} title={"Seleziona un altro sensore"} >
						<><FaAngleLeft style={{height: 'inherit', width: 'inherit'}} id="sidebar-button" color={chroma('#428f81').alpha(0.3)}/></>
					</Tooltip>
				</div>
				{step}
			</div>
		)
	}
}
