import React, {useEffect, useState} from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import WidthUtils from "#/lib/WidthUtils"

function LandscapeMobileWrapper(props) {


    return (


		 
		(<>
			{WidthUtils.isMobile() ?
			 <DeviceOrientation lockOrientation={'landscape'}>
				
				<Orientation orientation='landscape' alwaysRender={false}>
				<div>
					{props.children}
				</div>
				</Orientation>
				<Orientation orientation='portrait' alwaysRender={false}>
				<div>
					<p>Please rotate your device</p>
					<img className="img-fluid" style={{width: "100vw", maxWidth: "100vw"}} src={"/img/rotate.gif"} alt="loading..." />
				</div>
				</Orientation>
			</DeviceOrientation>
			: 
			<>{props.children}</>
			}
        </>   )
		
		
    
	)
       

}

export default (LandscapeMobileWrapper);
