import React, {useEffect, useState} from "react";

import Grid from "@material-ui/core/Grid";
import MobileStepper from '@material-ui/core/MobileStepper';

import MapFormComponent from "#/commons/map/MapFormComponent";
import {MAUMultizoneMeteoForm} from "#/backoffice/mau/components/MAUMultizoneMeteoForm";
import {BackButton} from "#/commons/components/forms/BackButton";
import {NextButton} from "#/commons/components/forms/NextButton";

export const StepPrevisioniMeteoToday = (props) => {
    const formData = props.formData;
    const map = !!props.maps && props.maps.length > 0 ? props.maps[0] : null;
    const todayDate = !!formData && !!formData.start && !!formData.start.date_short ? formData.start.date_short : '';
    const rootSection = ["start"];
    const section =  ["start","previsione"];
    let validForm = true;

    // Updates map when form changes

    const handleNextStep = () => {
        if (validForm || props.viewMode) {
            props.handleNext();
        } else {
            props.reactSwal.fire(
                "Redazione mau",
                "Compilare tutti i campi prima di procedere",
                "error"
            );
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <h2>{props.stepLabel + ' ' + todayDate}</h2>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {!!map &&
                    <MapFormComponent
                        id={map.mapId}
                        key={map.mapKey }
                        geoJSON={map.shapesGeoJSON}
                        width={map.width || "25vw"}
                        height={map.height || "60vh"}
                        /*   formData={formData.start}*/
                        mapKey={map.Key}
                        getColorById={(id) => {return null}}
                    />
                    }
                </Grid>
                <Grid item xs={8}>
                    {!!map &&
                    <MAUMultizoneMeteoForm
                        key={JSON.stringify(formData.start)}
                        viewMode={props.viewMode}
                        labelForm={map.labelForm + todayDate}
                        zones={map.zones}
                        rootSection={rootSection}
                        section={section}
                        onFormChange={props.onFormChange}
                        formData={formData.start}

                    />
                    }
                </Grid>
            </Grid>
            <MobileStepper
                style={{backgroundColor: 'rgba(255,0,0,0.0)'}}
                position="static"
                variant="text"
                activeStep={props.activeStep}
                steps={props.totalSteps}
                nextButton={
                    <NextButton
                        variant="contained"
                        color="primary"
                        disabled={!validForm}
                        onClick={handleNextStep}
                        textbutton={props.textNextButton}
                    />
                }
                backButton={
                    <BackButton
                        variant="contained"
                        disabled={props.disableBackButton}
                        onClick={props.handleBack}
                    />
                }
            />
        </>
    );
};
