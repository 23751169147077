import React from 'react';
import ComunicatoClient from '#/lib/ComunicatoClient';
import DateUtils from '#/lib/DateUtils';
import {FiAlignJustify, FiClock} from 'react-icons/fi';
import {SocialIcon} from 'react-social-icons';
import { ListItemIcon } from '@material-ui/core';


class ComunicatoDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
		};
		const url = window.location.href;
		const idString = url.split('/').pop();
		this.id = parseInt(idString);


	}

	componentDidMount() {
		ComunicatoClient.getNews(
			(data) => {
				let details = data.jsonData;
				let imageUrl = data.imageBase64;
				this.setState({
					details,
					imageUrl,
					data
				})

			},
			(data) => { console.log(data) },
			this.id
		);
	}

	render() {
		return (
			<>
				<div className="generic-page">
					<div className="mt-4 container card">
						<div className="card-header">Comunicato {this.id}</div>
						<div className="card-body">
							{!!this.state.details ?
								<div>
									<div className="row">
										<span className="item-title"><h4>{this.state.details.title}</h4></span>
									</div>

									<div className="row">
										<span>
											<img
												style={{ margin: 9 }}
												src={this.state.imageUrl}
												alt=''
												align="left">
											</img>
										</span>
										<div className="col-8">
											<div>
												<span >{this.state.details.subtitle}</span>
											</div>
											<div>
												<span className="icon-span"> <FiClock /> Pubblicato: {DateUtils.epochToDate(this.state.data.createdAt)} </span>
											</div>
											<div>
												<span className="icon-span"> <FiAlignJustify /> Categoria: {this.state.details.category}</span>
											</div>
										</div>
									</div>
									<hr></hr>
									<div className="row">
										<span dangerouslySetInnerHTML={{ __html: `<div>${this.state.details.body}</div>` }}></span>
									</div>
									<div className="row mt-4 d-flex flex-column">
										<span> Condividi su: </span>
										<div className='d-flex'>
											<a href='http://twitter.com/' className='mr-2'>
												<img src={"/img/icon-twitter-x-circle.svg"} alt="Twitter X"/>
											</a>
											<SocialIcon url="http://facebook.com/" className="icon-social" /> 
										</div>
									</div>
								</div>
								:
								<div className="spinner-border"></div>

							}

						</div>
					</div>
				</div>

			</>
		)
	}
};

export default ComunicatoDetails;
