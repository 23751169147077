import React from 'react'
import L from 'leaflet';
import GISTools from '#/lib/GISTools';
import {FaAngleLeft, FaCrosshairs, FaMapMarkerAlt} from "react-icons/fa";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import BigCalendarTimeZoned from "#/commons/components/BigCalendarTimeZoned";
import "#/commons/data/section/year-calendar.css";
import _ from 'lodash';
import moment from 'moment';
import LegacyMeasurementsComponent from './components/LegacyMeasurementsComponent';
import StationGisPicker from "#/commons/map/StationGisPicker";
import StationClient from "#/lib/StationClient";
import chroma from 'chroma-js'
import Tooltip from "@material-ui/core/Tooltip";

import DateRangePicker from '@wojtekmaj/react-datetimerange-picker';
import LandscapeMobileWrapper from  "#/commons/components/LandscapeMobileWrapper"
import WidthUtils from "#/lib/WidthUtils"
import LegacyMeasurementsDataClient from '#/lib/LegacyMeasurementsDataClient';

const defaultZone = {
	value: null,
	label: "Tutte le zone"
}

const defaultStation = {
	value: null,
	label: <>&nbsp;</>
}

const defaultYear = 2021;
const defaultSelectedRage = [moment().startOf("day"), moment().endOf("day")];


class LegacyDataPage extends React.Component {

	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 0,
		maxZoom: 12,
		width: "40vw",
		height: "65vh"
	}

	constructor(props) {
		super(props);
		this.state = {
			stationsGeoJSON: {},
			year: defaultYear,
			selectedRange: defaultSelectedRage,

			zoneOptions: [],
			stationOptions: [],

			selectedZone: defaultZone,
			selectedStation: defaultStation,

			interval: null,
			mapKey: 0,

			showGisPicker: true,
			loading : true,

			intervals : []


		}
	}

	componentDidMount() {

		StationClient.getPluvsStationsGeojson(
		(stationsGeoJSON)=>{
				let selectedZone = defaultZone;
				let selectedStation = defaultStation;
		
				let zoneOptions = this.buildZoneOptions();
				let stationOptions = this.buildStationOptions(stationsGeoJSON, selectedZone.value);
		
				// Find stations out of Calabria
				let stationsOutsideRegion = GISTools.getStationsOutsideCalabria(stationsGeoJSON);
		
				let state = {
					loading:false,
					zoneOptions,
					stationOptions,
					stationsOutsideRegion,
					stationsGeoJSON,
					selectedZone,
					selectedStation,
					mapKey: (this.state.mapKey + 1) % 1000
				};
				this.setState(state)
			},
			(err) => {console.log(err)},
			true

		)

	}

	buildZoneOptions() {
		let zoneOptions = [];
		let zones = GISTools.getAllZonePolygon();

		zoneOptions.push(defaultZone)
		zones.forEach(zone => {
			let id = zone.properties.ZONE;
			zoneOptions.push({
				value: parseInt(id),
				label: "ZONA " + id,
				icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
			});
		})
		// Add Out-of-Calabria zone
		zoneOptions.push({
			value: 9999,
			label: "Territorio extraregionale",
			icon: <FaCrosshairs className="mr-2"></FaCrosshairs>
		});

		return zoneOptions
	}

	buildStationOptions(stationsGeoJSON, zoneId=null) {
		let stationOptions = [];
		let stations = stationsGeoJSON;

		if (!!zoneId) {
			if (zoneId === 9999){
				stations = this.state.stationsOutsideRegion;
			} else{
				let zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
				let stationWithinZone = GISTools.intersect(stations, zonePolygon);
				stations = stationWithinZone;
			}
		}

		let stationsProperties = GISTools.getPropertiesFromFeature(stations);
		stationOptions.push(defaultStation);
		stationsProperties.forEach(properties => {
			stationOptions.push({
				label: properties.name,
				value: properties.code,
				icon: <FaMapMarkerAlt className="mr-2"></FaMapMarkerAlt>
			});
		})

		return stationOptions;
	}

	zonesStyle(feature) {
		let zone = parseInt(feature.properties.ZONE);
		let style = {};

		if (this.state.selectedZone.value === 0) {
			if (this.state.zoneOptions.map(x => x.value).includes(zone)) {

				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}

		}
		else {
			if (this.state.selectedZone.value === zone) {
				style = {
					fillColor: "#20468c69",
					weight: 0.5,
					opacity: 1,
					color: "#20468c69",
					fillOpacity: 0.5
				};
			}
			else {
				style = {
					fillColor: "#fff0",
					weight: 0.5,
					opacity: 1,
					color: "#20468c",
					fillOpacity: 0
				};
			}
		}


		return style;
	}

	onEachStation(feature, layer) {
		layer.on('click', (e) => this.onSelectStation(feature.properties.code));

		var popup = L.popup({ closeButton: false })
			.setContent('<div><span class="mr-2 fas fa-map-marker-alt"></span>' + feature.properties.name + '</div>');
		layer.bindPopup(popup);
		let timer = null;
		layer.on('mouseover', function (e) {
			timer = setTimeout(() => this.openPopup(), 1000)
		});
		layer.on('mouseout', function (e) {
			clearTimeout(timer);
			this.closePopup();
		});
	}

	stationToMarker(feature, latlng) {
		let marker = null;

		let name = feature.properties.name;
		let isSelected = name === this.state.selectedStation.label;

		if (isSelected) {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point_selected.png',
					popupAnchor: [0, 0]
				})
			});
		}
		else {
			marker = L.marker(latlng, {
				icon: new L.Icon({
					iconSize: [15, 15],
					iconUrl: '/img/marker_point.png',
					popupAnchor: [0, 0]
				})
			});
		}

		return marker;

	}

	onSelectZone(zoneId) {

		let stationOptions = [];
		let selectedZone = this.state.zoneOptions.find(element => element.value === zoneId);
		stationOptions = this.buildStationOptions(this.state.stationsGeoJSON, zoneId);
		this.setState({
			stationOptions: stationOptions,
			selectedZone: selectedZone,
			selectedStation: defaultStation,
			mapKey: (this.state.mapKey + 1) % 1000,
			filteredStationsGeoJson : null
		})

	}

	onSelectStation(stationCode) {


		LegacyMeasurementsDataClient.getIntervals(
			intervals => {
				console.log(intervals)
				
				let stationOptions = this.buildStationOptions(this.state.stationsGeoJSON);
				let selectedStation = stationOptions.find(element => element.value === stationCode);
				let stationPoint = GISTools.getStationByField(this.state.stationsGeoJSON, "code", stationCode);
				let zonePolygon = GISTools.getZonePolygonByPoint(stationPoint);
				let zoneId = (!!zonePolygon && !!zonePolygon.properties)  ? zonePolygon.properties.ZONE : 9999;


				let selectedZone = this.state.zoneOptions.find(element => element.value === parseInt(zoneId, 10));

				let zoneBbox = null;
				if (zoneId !== 9999){
					zonePolygon = GISTools.getZonePolygonByField("ZONE", zoneId);
					zoneBbox = GISTools.getBBoxFromPoints(zonePolygon);
				}
				this.setState({
					selectedZone: selectedZone,
					selectedStation: selectedStation,
					mapKey: (this.state.mapKey + 1) % 1000,
					showGisPicker: !!this.state.interval ? false : true,
					intervals,
					zoneBbox
				})
			},
			()=>console.log("ERROR"),
			stationCode
		)



		


	}

	onPrevYear() {
		this.setState({
			year: this.state.year - 1
		})
	}

	onNextYear() {
		this.setState({
			year: this.state.year + 1
		})
	}

	onPickRange(start, end, year) {

		let isTillToday = end.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		let isFromToday = start.toDate().toLocaleDateString() === (new Date()).toLocaleDateString();
		end = isTillToday ? moment().add(-30, 'm') : end.add(1,'d').startOf('day');
		start = isFromToday ? end.clone().add(-24, 'h') : start.startOf('day');

		let interval = {start, end}
		this.setState({
			year: !!year ? year : this.state.year,
			selectedRange: [start, end],
			interval: interval,
			showGisPicker: false
		})
	}

	onMobilePickRange(dates){
		let interval = null
		if(dates && dates[0] && dates[1]){
			interval = {}
			interval.start = moment(dates[0].getTime()).startOf('day')
			interval.end = moment(dates[1].getTime()).endOf('day')
		}
		this.resetInterval(interval)
	}

	resetInterval(interval) {
		this.setState({
			interval,
			selectedRange: !!interval ? [interval.start, interval.end] : defaultSelectedRage,
			year: defaultYear,
			showGisPicker: !!interval && !!this.state.selectedStation.value  ? false : true
		});
	}

	toggleSidebar(event) {
		if (event==="over") {
			document.getElementById("mySidebar").style.width = "2.5rem";
			document.getElementById("main").style.marginLeft = "1.5rem";
			document.getElementById("sidebar-button").style.color = "#428f81";
		} else {
			document.getElementById("mySidebar").style.width = "1.5rem";
			document.getElementById("main").style.marginLeft = "0";
			document.getElementById("sidebar-button").style.color = chroma('#428f81').alpha(0.3)
		}
	}

	toggleGisPicker = () => {
		if (!this.state.showGisPicker){
			document.getElementById("main").style.marginLeft = '0px';
		}
		this.setState({showGisPicker : !this.state.showGisPicker, interval: null})
	}

	isDisabled = (day) => {
		
		if(day.isAfter( moment([2021, 5, 30, 0, 0, 0, 0]))){
			return true;
		}
		let active = false;
		let i = 0
		while (i < this.state.intervals.length && !active) {
			var startDate   = moment(this.state.intervals[i].start-1);
			var endDate     = moment(this.state.intervals[i].end+1);
			active = day.isBetween(startDate, endDate, "[]"); //false in this case
			i++
		}
		return !active;
		

		// omitting the optional third parameter, 'units'

		

	}

	render() {

		let mobileMode = WidthUtils.isMobile();
		console.log('mobileMode: ', mobileMode);

		let key = this.state.interval ? JSON.stringify(this.state.interval) :"init" 
		let view = {
			STUB: "STUB",
			CALENDAR: "CALENDAR",
			DATA: "DATA"
		}

		let currentView = view.STUB;

		if (!this.state.interval && !this.state.selectedStation.value) {
			currentView = view.STUB;
		}
		else if ( this.state.selectedStation.value && !this.state.interval) {
			currentView = view.CALENDAR;
		}
		else if (!!this.state.selectedStation.value && !!this.state.interval) {
			currentView = view.DATA;
		}

		let componentToShow = null;
		switch (currentView) {
			case view.STUB:
				componentToShow = <StubComponent></StubComponent>;
				break;
			case view.CALENDAR:
				componentToShow = !mobileMode ? 
					<div id={"main"}>
						<h3>Seleziona Intervallo di osservazione</h3>
						<BigCalendarTimeZoned
							timeZone={"Etc/GMT-1"} 
							range={this.state.interval} 
							year={this.state.year}
							onPrevYear={() => this.onPrevYear()}
							onNextYear={() => this.onNextYear()}
							onPickRangeCallback={(start, end, year)=> this.onPickRange(start, end, year)}
							future={day => this.isDisabled(day)}
						></BigCalendarTimeZoned>
					</div> : <div id={"main"}></div>;
				break;
			case view.DATA:
				componentToShow = <div id="main">
					<LandscapeMobileWrapper>
						<LegacyMeasurementsComponent
							key={key}
							resetInterval={(interval) => this.resetInterval(interval)}
							interval={this.state.interval}
							selectedStation={this.state.selectedStation}
							stationsGeoJSON = {this.state.stationsGeoJSON}
						></LegacyMeasurementsComponent>
					</LandscapeMobileWrapper>
				</div>;
				
				break;
			default:
				componentToShow = <StubComponent></StubComponent>;
				break;
		}
		return (
			<div className="generic-page container-fluid data-page">

				<div className="row mt-4">
					{(this.state.loading) &&
						<div className={mobileMode ? "col-12" : "col-4"}>
							<Skeleton height={"5vh"} duration={8} />
							<Skeleton height={"5vh"} duration={8} />
							<Skeleton height={"70vh"} duration={8} />
						</div>
					} 
					{this.state.showGisPicker && !this.state.loading ? <div className={mobileMode ? "col-12" : "col-4"}>
						<div className={mobileMode ? "" :"d-none"}>
							<DateRangePicker
								className={"col-12"}
								locale={"it-IT"}
								onChange={(dates) => this.onMobilePickRange(dates)}
								value={[this.state.interval && this.state.interval.start && new Date(this.state.interval.start.valueOf()), this.state.interval && this.state.interval.end && new Date(this.state.interval.end.valueOf())]}
								maxDate={new Date()}
								format={"dd/MM/yyyy"}
								rangeDivider='&nbsp;&nbsp;&nbsp;'
							/>
						</div>
						<StationGisPicker
							centerMapOnSelect={true}
							geoJSON={!!this.state.filteredStationsGeoJson ? this.state.filteredStationsGeoJson : this.state.stationsGeoJSON}
							zoneOptions={this.state.zoneOptions}
							selectedZone={this.state.selectedZone}
							onSelectZone={(option) => this.onSelectZone(option)}
							stationOptions={this.state.stationOptions}
							selectedStation={this.state.selectedStation}
							onSelectStation={(option) => this.onSelectStation(option)}
							mapHeight={"65vh"}
							zoneBbox={this.state.zoneBbox}

							mapKey={this.state.mapKey}
							onEachStation={(feature, layer) => this.onEachStation(feature, layer)}
							stationToMarker={(feature, latlng) => this.stationToMarker(feature, latlng)}
							zonesStyle={(feature) => this.zonesStyle(feature)}

						></StationGisPicker>
					</div> : <></>}

					<div id="mySidebar" className="sidebar" style={{display: this.state.showGisPicker ? 'none' : 'block'}}
						 onMouseOver={() => this.toggleSidebar("over")}
						 onMouseOut={() => this.toggleSidebar("out")}
						 onClick={() => this.toggleGisPicker()}>
						<Tooltip open={false} title={"Seleziona un altro sensore"} >
							<><FaAngleLeft style={{height: 'inherit', width: 'inherit'}} id="sidebar-button" color={chroma('#428f81').alpha(0.3)}/></>
						</Tooltip>
					</div>
					<div className={this.state.showGisPicker ? "col-8" : "col-12"} >
						{/*
						<div style={{backgroundColor: '#b3e7ac', width:'1rem'}} onClick={() => this.setState({showGisPicker: !this.state.showGisPicker})}></div>
*/}
						{componentToShow}
					</div>
				</div>
				<div className="row" ></div>
				
			</div>

		)
	}
}

export default (LegacyDataPage);

function StubComponent() {
	return (
		<div >
			<SkeletonTheme  >
				<div className="row mt-2">
					<div className="col-2 " >
						<Skeleton height={"5vh"} duration={8} />
					</div>
					<div className="col-4 ml-auto" >
						<Skeleton height={"5vh"} duration={8} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-4 ml-auto" >
						<Skeleton height={"5vh"} duration={8} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-12">

						<Skeleton height={"5vh"} duration={8} />


					</div>
				</div>
				<div className="row mt-2">
					<div className="col-12 float-right" >
						<Skeleton height={"60vh"} duration={8} />
					</div>
				</div>
			</SkeletonTheme>
		</div>
	)
}

