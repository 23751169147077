import React from 'react';

import NumberUtils from "#/lib/NumberUtils";
import * as turf from '@turf/turf';

import ChromaticScale from "#/lib/ChomaticScale";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import DateUtils from "#/lib/DateUtils";
import {GeotiffRasterLayer} from "#/commons/map/GeotiffRasterLayer";
import Markers from "#/lib/Markers";
import L from "leaflet";
import '#/backoffice/style/scrollbar.css';
import GridList from "@material-ui/core/GridList";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import MapComponent from "#/commons/map/MapComponent";
import PrettyLegend from "#/commons/map/PrettyLegend";
import MapWatermark from "#/commons/map/MapWatermark";
import WrfClient from "#/lib/WrfClient";
import GISTools from "#/lib/GISTools";
import {GeoJSON, ImageOverlay} from "react-leaflet";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import _ from 'lodash';
import {GiWorld} from 'react-icons/gi';
import AnimationPlayer from "#/commons/map/AnimationPlayer";
import GeoJsonUtils from "#/lib/GeoJsonUtils";

const ReactSwal = withReactContent(Swal);

const ALL_WRF_OPTIONS = [
	{ value: "RAIN", udm: 'mm', label: "Pioggia oraria", legendLabel: "RAIN" },
	{ value: "RAIN3H", udm: 'mm', label: "Pioggia a 3 ore", legendLabel: "RAIN3H" },
	{ value: "RAIN12H", udm: 'mm', label: "Pioggia a 12 ore", legendLabel: "RAIN12H" },
	{ value: "WIND", udm: 'Km/h', label: "Intensità vento", legendLabel: "WIND" },
	{ value: "SLP", udm: 'hPa', label: "Sea Level Pressure", legendLabel: "SLP" },
	{ value: "RH", udm: '%', label: "2 Meters Relative Humidity", legendLabel: "RH" },
	{ value: "TEMP2M", udm: '°C', label: "2 Meters Temperature", legendLabel: "TEMP2M" },
	{ value: "CIN", udm: 'J/Kg', label: "Convective Hinibition", legendLabel: "CIN" },
	{ value: "TPW", udm: 'Kg/m^2', label: "Total Precipitable Water", legendLabel: "TPW" },
	{ value: "CAPE", udm: 'J/Kg', label: "Convective Available Potential Energy", legendLabel: "CAPE" }
];

const getLegendLabel = ({label, udm}) => label + ' (' + udm + ')';

const ALL_LAPS_WRF_OPTIONS = [
	{ value: "RAIN", udm: 'mm', label: "Pioggia oraria", legendLabel: "RAIN" },
	/*	{ value: "RAIN3H", udm: 'mm', label: "Pioggia a 3 ore", legendLabel: "RAIN3H" },
        { value: "RAIN12H", udm: 'mm', label: "Pioggia a 12 ore", legendLabel: "RAIN12H" },*/
	{ value: "WIND", udm: 'Km/h', label: "Intensità vento", legendLabel: "WIND" },
	{ value: "SLP", udm: 'hPa', label: "Sea Level Pressure", legendLabel: "SLP" },
	{ value: "RH", udm: '%', label: "2 Meters Relative Humidity", legendLabel: "RH" },
	{ value: "TEMP2M", udm: '°C', label: "2 Meters Temperature", legendLabel: "TEMP2M" },
	{ value: "CIN", udm: 'J/Kg', label: "Convective Hinibition", legendLabel: "CIN" },
	{ value: "TPW", udm: 'Kg/m^2', label: "Total Precipitable Water", legendLabel: "TPW" },
	{ value: "CAPE", udm: 'J/Kg', label: "Convective Available Potential Energy", legendLabel: "CAPE" }
]

const mapOptions = {
	center: [39.11, 16.55],
	zoom: 7,
	minZoom: 6,
	width: "40vw",
	height: "60vh"
}

const zoneBbox = GISTools.getBBoxFromPoints(turf.lineString([[12.766113,36.553775],[18.204346,40.784701]]));

const loadingSwal = Swal.mixin({
	allowOutsideClick: false,
	allowEscapeKey: false,
	didOpen: () => {
		Swal.showLoading()
	},
});

export default class LapsWrfComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			pointsKey: 1,
			loadingTab: true,

			mapWrfKey: 1,
			legendWrfKey: 1,
			selectedWrfLayer: null,
			wrfData: [],
			dataWrfIndex: 0,
			showWrfRaster: true,
			wrfLayerMap: {},

			cachedPng: {wrf: {}},

			showBorders: true,
			showArrows: true,
			cachedWindGeoJson: {},

			mapLapsWrfKey: 1,
			legendLapsWrfKey: 1,
			selectedLapsWrfLayer: null,
			lapsWrfData: [],
			dataLapsWrfIndex: 0,
			showLapsWrfRaster: true,
			lapsWrfLayerMap: {},

			cachedLapsPng: {wrf: {}},

			showLapsBorders: true,
			showLapsArrows: true,
			cachedLapsWindGeoJson: {},

			loadingWrfLayer: false,
			loadingLapsWrfLayer: false,
		}
	}

	componentDidMount = () => {

		Promise.all([this.getAvailableWrfLayersPromise(), this.getAvailableLapsWrfLayersPromise()])
			.then(([wrfLayerMap, lapsWrfLayerMap]) => {
				this.setState({
					wrfLayerMap,
					lapsWrfLayerMap,
					loadingTab: false,
					lastWrfRunning: DateUtils.epochToDateMinuteResolution(wrfLayerMap.executionTimestamp),
					lastLapsWrfRunning: DateUtils.epochToDateMinuteResolution(lapsWrfLayerMap.executionTimestamp),
				}, () => {
					this.fixWrfMap();
					this.fixLapsWrfMap();
					/*		this.syncMaps()*/
					if (this.props.ready){
						this.props.ready()
					}
					this.handleWrfListItemClick(null, 'RAIN', true)
				})
			}).catch(errors =>{
			console.log('Errors from promise all',errors)
		})
	}


	getAvailableWrfLayersPromise = () => new Promise((resolve, reject) => {
		WrfClient.getAvailableWrfLayersBySource(
			wrfLayerMap => {
				console.log("wrfLayerMap",wrfLayerMap)
				resolve(wrfLayerMap)
			},
			() => {
				console.log("Error retrieving wrf layer map...")
			},
			'WRF'
		)
	})
	getAvailableLapsWrfLayersPromise = () => new Promise((resolve, reject) => {
		WrfClient.getAvailableWrfLayersBySource(
			lapsWrfLayerMap => {
				console.log("lapsWrfLayerMap",lapsWrfLayerMap)
				resolve(lapsWrfLayerMap)
			},
			() => {
				console.log("Error retrieving laps wrf layer map...")
			},
			'LAPSWRF'
		)

	})

	componentDidUpdate = () => {
		loadingSwal.close();
	}
	buildWindPromises = (angleTiffUrl, moduleTiffUrl) => {
		let anglePromise = new Promise((resolve, reject) => {
			WrfClient.getWrfByTiffPath(
				(georaster) => {resolve(georaster)},
				() => {console.log("Error retrieving wind angle tiff", angleTiffUrl); reject()},
				angleTiffUrl
			)
		});
		let modulePromise = new Promise((resolve, reject) => {
			WrfClient.getWrfByTiffPath(
				(georaster) => {resolve(georaster)},
				(err) => {console.log("Error retrieving wind module tiff", moduleTiffUrl); reject()},
				moduleTiffUrl
			)
		});
		return [anglePromise, modulePromise];
	}
	getWindGeoJsonPromise = (angleTiffUrl, moduleTiffUrl, timestamp, cachedWindGeoJson) => new Promise((resolve, reject) => {
		let cachedGeoJson = cachedWindGeoJson[timestamp];
		if (cachedGeoJson){
			resolve({[timestamp]: cachedGeoJson})
		} else {
			Promise.all(this.buildWindPromises(angleTiffUrl, moduleTiffUrl))
				.then(([angleGeoraster, moduleGeoraster]) => {
					let windGeoJson = GeoJsonUtils.buildGeoJSONFromGeorasters([angleGeoraster, moduleGeoraster], ['windAngle', 'windModule'] );
					if (windGeoJson){
						resolve({[timestamp]: windGeoJson})
					} else reject();
				}).catch(() => {
				resolve({[timestamp]: GeoJsonUtils.buildFakeGeoJSONFromGeorasters(null, null)})
			})
		}
	})
	getWrfPngPromise = (pngUrl, timestamp, selectedWrfLayer, cachedPng) => new Promise((resolve, reject) => {
		let cachedPngImage = cachedPng.wrf[selectedWrfLayer][timestamp]
		if (cachedPngImage){
			resolve({[timestamp]: cachedPngImage})
		} else {
			WrfClient.getWrfByPngPath(image => {
				resolve({[timestamp]: image})
			}, err => {
				console.log(err);
			}, pngUrl)
		}
	})


	getWrfData = (selectedWrfLayer, init) => {

		loadingSwal.fire('Recupero dati WRF ['+selectedWrfLayer+'] in corso');
		const {showWrfRaster, cachedWindGeoJson} = this.state;

		WrfClient.getWrfDataByLayerAndSource(

			wrfData => {
				console.log("wrfData", wrfData);

				let {cachedPng, legendWrfKey} = this.state;

				if (!(selectedWrfLayer in cachedPng.wrf)){
					cachedPng.wrf[selectedWrfLayer] = {};
				}

				if(wrfData.length === 0){
					loadingSwal.close();
					ReactSwal.fire(
						"Recupero dati WRF",
						"Layer " + selectedWrfLayer + " non disponibile!",
						"error"
					);
					return;
				}
				// Get first image
				let firstData = wrfData[0].data[selectedWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedWrfLayer] ;
				let {pngUrl : temporaryWrfImageUrl, timestamp : firstTimestamp} = firstData;
				this.getWrfPngPromise(temporaryWrfImageUrl, firstTimestamp, selectedWrfLayer, cachedPng)
					.then(obj => {
						this.setState({
							temporaryWrfImageUrl: obj[firstTimestamp],
							loadingWrfLayer: true,
							selectedWrfLayer,
							wrfData,
							mapWrfKey: (this.state.mapWrfKey + 1) % 1000
						}, () => {

							let newState = {
								loadingWrfLayer: false,
								wrfData,
								cachedPng,
								cachedWindGeoJson,
								selectedWrfLayer,
								legendWrfKey: (legendWrfKey + 1) % 1000
							}
							let effectiveLayerName = selectedWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedWrfLayer;
							let outerPromisePng = new Promise((resolve, reject) => {
								// Cache PNG
								Promise.all(wrfData.map(({data}) => this.getWrfPngPromise(data[effectiveLayerName].pngUrl, data[effectiveLayerName].timestamp, selectedWrfLayer, cachedPng)))
									.then( cachedImageObjects => {
										resolve(cachedImageObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{}));
									});
							})

							let outerGeojsonPromise = new Promise((resolve, reject) => {
								if (selectedWrfLayer === 'WIND'){
									Promise.all(wrfData.map(({data}) => this.getWindGeoJsonPromise(data['WIND_ANGLE'].tiffUrl, data['WIND_MODULE'].tiffUrl,data['WIND_MODULE'].timestamp, cachedWindGeoJson)))
										.then( cachedWindObjects => {
											resolve(cachedWindObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{}));
										});
								} else resolve({});
							})

							Promise.all([outerPromisePng, outerGeojsonPromise])
								.then(([pngObject, geojsonObject]) => {
									newState.cachedPng.wrf[selectedWrfLayer] = pngObject;
									newState.cachedWindGeoJson = geojsonObject;
									this.updateWrfSnapshot(0, showWrfRaster, newState, init)
								})
						})
					})


			},
			(error) => {
				console.log(">>>>>>>>>>>>>>>>> Problems retrieving wrf data ");
				loadingSwal.close();
				ReactSwal.fire('Errore nel caricamento dai dati','','error')
			},
			selectedWrfLayer,
			'WRF'
		)
	};

	getLapsWrfData = selectedLapsWrfLayer => {

		loadingSwal.fire('Recupero dati LAPS WRF ['+selectedLapsWrfLayer+'] in corso');
		const { showLapsWrfRaster, cachedLapsWindGeoJson} = this.state;

		WrfClient.getWrfDataByLayerAndSource(

			lapsWrfData => {
				console.log("laps wrf Data", lapsWrfData)
				let {cachedLapsPng, legendLapsWrfKey} = this.state;

				if (!(selectedLapsWrfLayer in cachedLapsPng.wrf)){
					cachedLapsPng.wrf[selectedLapsWrfLayer] = {};
				}

				if(lapsWrfData.length === 0){
					loadingSwal.close();
					ReactSwal.fire(
						"Recupero dati LAPS WRF",
						"Layer " + selectedLapsWrfLayer + " non disponibile!",
						"error"
					);
					return;
				}
				// Get first image
				let firstData = lapsWrfData[0].data[selectedLapsWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedLapsWrfLayer] ;
				let {pngUrl : temporaryLapsWrfImageUrl, timestamp : firstTimestamp} = firstData;
				this.getWrfPngPromise(temporaryLapsWrfImageUrl, firstTimestamp, selectedLapsWrfLayer, cachedLapsPng)
					.then(obj => {
						this.setState({
							temporaryLapsWrfImageUrl: obj[firstTimestamp],
							loadingLapsWrfLayer: true,
							selectedLapsWrfLayer,
							lapsWrfData,
							mapLapsWrfKey: (this.state.mapLapsWrfKey + 1) % 1000
						}, () => {

							let newState = {
								loadingLapsWrfLayer: false,
								lapsWrfData,
								selectedLapsWrfLayer,
								cachedLapsPng,
								cachedLapsWindGeoJson,
								legendLapsWrfKey: (legendLapsWrfKey + 1) % 1000,
							}
							let effectiveLayerName = selectedLapsWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedLapsWrfLayer;
							let outerPromisePng = new Promise((resolve, reject) => {
								// Cache PNG
								Promise.all(lapsWrfData.map(({data}) => this.getWrfPngPromise(data[effectiveLayerName].pngUrl, data[effectiveLayerName].timestamp, selectedLapsWrfLayer, cachedLapsPng)))
									.then( cachedImageObjects => {
										resolve(cachedImageObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{}));
									});
							})

							let outerGeojsonPromise = new Promise((resolve, reject) => {
								if (selectedLapsWrfLayer === 'WIND'){
									Promise.all(lapsWrfData.map(({data}) => this.getWindGeoJsonPromise(data['WIND_ANGLE'].tiffUrl, data['WIND_MODULE'].tiffUrl,data['WIND_MODULE'].timestamp, cachedLapsWindGeoJson)))
										.then( cachedWindObjects => {
											resolve(cachedWindObjects.reduce((map, obj) =>{map[Object.keys(obj)[0]] = Object.values(obj)[0];return map;},{}));
										});
								} else resolve({});
							})

							Promise.all([outerPromisePng, outerGeojsonPromise])
								.then(([pngObject, geojsonObject]) => {
									newState.cachedLapsPng.wrf[selectedLapsWrfLayer] = pngObject;
									newState.cachedLapsWindGeoJson = geojsonObject;
									this.updateLapsWrfSnapshot(0, showLapsWrfRaster, newState)
								})


						})
					})
			},
			(error) => {
				console.log(">>>>>>>>>>>>>>>>> Problems retrieving laps wrf data ");
				loadingSwal.close();
				ReactSwal.fire('Errore nel caricamento dai dati','','error')
			},
			selectedLapsWrfLayer,
			'LAPSWRF'
		)
	};

	fixWrfMap = () => {
		if (!!this.mapWrfRef){
			this.mapWrfRef.leafletElement.invalidateSize();
			this.mapWrfRef.leafletElement.fitBounds([
				[zoneBbox.bbox[1], zoneBbox.bbox[0]],
				[zoneBbox.bbox[3], zoneBbox.bbox[2]]
			]);
	/*		this.mapWrfRef.leafletElement.panTo(new L.LatLng(39.11, 16.55));
			this.mapWrfRef.leafletElement.setZoom(mapOptions.zoom);
			this.mapWrfRef.leafletElement.invalidateSize();*/
		}
	}
	fixLapsWrfMap = () => {
		if (!!this.mapLapsWrfRef){
			this.mapLapsWrfRef.leafletElement.invalidateSize();
			this.mapLapsWrfRef.leafletElement.fitBounds([
				[zoneBbox.bbox[1], zoneBbox.bbox[0]],
				[zoneBbox.bbox[3], zoneBbox.bbox[2]]
			]);
			/*this.mapLapsWrfRef.leafletElement.panTo(new L.LatLng(39.11, 16.55));
			this.mapLapsWrfRef.leafletElement.setZoom(mapOptions.zoom);
			this.mapLapsWrfRef.leafletElement.invalidateSize();*/

		}
	}
	syncMaps = () => {
		if (!!this.mapWrfRef && !!this.mapLapsWrfRef){
			this.mapLapsWrfRef.leafletElement.sync(this.mapWrfRef.leafletElement);
			this.mapWrfRef.leafletElement.sync(this.mapLapsWrfRef.leafletElement);
		}
	}

	showInterpolatedPopup = (val, lnglat, map, area) => {
		let showRaster = this.state.showWrfRaster;
		let selectedWrfLayer = this.state.selectedWrfLayer;
		console.log('Selected layer', selectedWrfLayer)
		if (selectedWrfLayer === 'WIND' && val < 0){
			return
		}
		if (showRaster){

			let valore = val[0] !== -9999 ? `${NumberUtils.round(selectedWrfLayer === 'TEMP2M' ? (val - 273.15) : val, 2)} ${ALL_WRF_OPTIONS.find(o => o.value === selectedWrfLayer).udm}` : 'non rilevato';
			L.popup({offset: [40, 40], closeButton: false})
				.setLatLng(L.latLng(lnglat[0], lnglat[1]))
				.setContent(`<div>Valore misurato: ${valore}</div>`)
				.openOn(map);
			setTimeout(() => {
				map.closePopup();
			}, 3000)
		}
	}

	createMarker = (feature, latlng, color, borderColor = 'grey') => Markers.getMarker(latlng, color, borderColor);
	handleWrfListItemClick = (event, selectedWrfLayer, init) => {
		console.log("Selected layer", selectedWrfLayer)
		this.getWrfData(selectedWrfLayer, init);
	};
	handleLapsWrfListItemClick = (event, selectedLapsWrfLayer) => {
		console.log("Selected laps layer", selectedLapsWrfLayer)
		this.getLapsWrfData(selectedLapsWrfLayer);
	};

	getWrfCategoryList = selectedWrfLayer => ALL_WRF_OPTIONS.map(({value: layerKey, icon, label}) =>
		[<ListItem
			key={layerKey + "_" + this.state.showWrfRaster}
			button
			disabled={!this.state.wrfLayerMap[layerKey] || !this.state.showWrfRaster}
			selected={selectedWrfLayer === layerKey}
			onClick={(event) => this.handleWrfListItemClick(event, layerKey)}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={label}/>
		</ListItem>]
	);

	getLapsWrfCategoryList = selectedLapsWrfLayer => ALL_LAPS_WRF_OPTIONS.map(({value: layerKey, icon, label}) =>
		[<ListItem
			key={"laps_" + layerKey + "_" + this.state.showLapsWrfRaster}
			button
			disabled={!this.state.lapsWrfLayerMap[layerKey] || !this.state.showLapsWrfRaster}
			selected={selectedLapsWrfLayer === layerKey}
			onClick={(event) => this.handleLapsWrfListItemClick(event, layerKey)}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={label}/>
		</ListItem>]
	)
	getCurrentTimestampForCurrentLayer = (wrfData, selectedWrfLayer, index) => wrfData[index].data[selectedWrfLayer] ? wrfData[index].data[selectedWrfLayer].timestamp : null;

	findClosestTimestamp = (data, timestampToFind) => data.reduce(({timestamp}, {timestamp:a}) =>
		Math.abs(timestampToFind - a) < Math.abs(timestampToFind - timestamp) ? {timestamp: a} : {timestamp}
	).timestamp;

	updateWrfSnapshot = (dataWrfIndex, showWrfRaster, newState = {}, init ) => {


		if (!showWrfRaster){
			this.setState({
				...newState,
				showWrfRaster,
				dataWrfIndex,
				mapWrfKey: (this.state.mapWrfKey + 1) % 1000
			},() => loadingSwal.close())
		} else {
			this.setState({
				loadingWrfLayer: true
			}, () => {
				const {mapWrfKey} = this.state;
				const {wrfData} = _.isEmpty(newState) ? this.state : newState;
				const {selectedWrfLayer} = _.isEmpty(newState) ? this.state : newState;
				if (wrfData.length > 0) {

					let {tiffUrl} = wrfData[dataWrfIndex].data[selectedWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedWrfLayer];

					WrfClient.getWrfByTiffPath(
						currentWrfTiff => {

							this.setState({
								...newState,
								mapWrfKey: (mapWrfKey + 1) % 1000,
								currentWrfTiff,
								showWrfRaster,
								dataWrfIndex,
								loadingWrfLayer: false
							}, () => {
								if (init){
									this.handleLapsWrfListItemClick(null, 'RAIN' );
								}
								loadingSwal.close();
							})
						},
						(error) => {
							console.log(">>>>>>>>>>>>>>>>> Problems retrieving wrf tiff ");
							this.setState({
								...newState,
								mapWrfKey: (mapWrfKey + 1) % 1000,
								currentWrfTiff : null,
								showWrfRaster,
								dataWrfIndex,
								loadingWrfLayer: false
							},() => {
								if (init){
									this.handleLapsWrfListItemClick(null, 'RAIN' );
								}
								loadingSwal.close();
							})
						},
						tiffUrl
					)
				}
			})

		}
	}
	updateLapsWrfSnapshot = (dataLapsWrfIndex, showLapsWrfRaster, newState = {} ) => {

		if (!showLapsWrfRaster){
			this.setState({
				...newState,
				showLapsWrfRaster,
				dataLapsWrfIndex,
				mapLapsWrfKey: (this.state.mapLapsWrfKey + 1) % 1000
			},() => loadingSwal.close())
		} else {
			this.setState({
				loadingLapsWrfLayer: true
			}, () => {
				const {mapLapsWrfKey} = this.state;
				const {lapsWrfData} = _.isEmpty(newState) ? this.state : newState;
				const {selectedLapsWrfLayer} = _.isEmpty(newState) ? this.state : newState;
				if (lapsWrfData.length > 0) {

					let {tiffUrl} = lapsWrfData[dataLapsWrfIndex].data[selectedLapsWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedLapsWrfLayer];

					WrfClient.getWrfByTiffPath(
						currentLapsWrfTiff => {

							this.setState({
								...newState,
								mapLapsWrfKey: (mapLapsWrfKey + 1) % 1000,
								currentLapsWrfTiff,
								showLapsWrfRaster: showLapsWrfRaster,
								dataLapsWrfIndex: dataLapsWrfIndex,
								loadingLapsWrfLayer: false
							},() => loadingSwal.close())
						},
						(error) => {
							console.log(">>>>>>>>>>>>>>>>> Problems retrieving wrf tiff ");
							this.setState({
								...newState,
								mapLapsWrfKey: (mapLapsWrfKey + 1) % 1000,
								currentLapsWrfTiff : null,
								showLapsWrfRaster,
								dataLapsWrfIndex,
								loadingLapsWrfLayer: false
							},() => loadingSwal.close())
						},
						tiffUrl
					)
				}
			})
		}
	}
	windPointToMarker = (feature, latlng, context) =>
		Markers.getWindMarker(latlng,
			feature.properties.windAngle,
			feature.properties.windModule,
			20,
			true,
			true);

	render() {

		const {
			selectedWrfLayer,
			wrfData,

			currentWrfTiff,
			mapWrfKey,
			legendWrfKey,
			loadingTab,
			showWrfRaster,
			dataWrfIndex,
			cachedPng,
			showBorders,
			showArrows,
			cachedWindGeoJson,

			selectedLapsWrfLayer,
			lapsWrfData,
			currentLapsWrfTiff,
			mapLapsWrfKey,
			legendLapsWrfKey,
			showLapsWrfRaster,
			dataLapsWrfIndex,
			cachedLapsPng,
			showLapsBorders,
			showLapsArrows,
			cachedLapsWindGeoJson,
			loadingWrfLayer,
			temporaryWrfImageUrl,
			loadingLapsWrfLayer,
			temporaryLapsWrfImageUrl
		} = this.state;
		if (showWrfRaster || showLapsWrfRaster || loadingWrfLayer || loadingLapsWrfLayer){
			this.fixWrfMap();
			this.fixLapsWrfMap()
		}

		let wrfImgUrl, lapsWrfImgUrl = null;
		const selectedWrfLayerName = selectedWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedWrfLayer;
		const selectedLapsWrfLayerName = selectedLapsWrfLayer === 'WIND' ? 'WIND_MODULE' : selectedLapsWrfLayer;

		try {
			wrfImgUrl = wrfData.length > 0 ? cachedPng.wrf[selectedWrfLayer][wrfData[dataWrfIndex].data[selectedWrfLayerName].timestamp] : null;
			lapsWrfImgUrl = lapsWrfData.length > 0 ? cachedLapsPng.wrf[selectedLapsWrfLayer][lapsWrfData[dataLapsWrfIndex].data[selectedLapsWrfLayerName].timestamp] : null;
		} catch (err) {
			console.error(err)
		}

		let instantWindGeoJson = null;
		if (selectedWrfLayer === 'WIND') {
			if (cachedWindGeoJson) {
				instantWindGeoJson = cachedWindGeoJson[wrfData[dataWrfIndex].data[selectedWrfLayerName].timestamp]
			}
		}

		let instantLapsWindGeoJson = null;
		if (selectedLapsWrfLayer === 'WIND') {
			if (cachedLapsWindGeoJson) {
				instantLapsWindGeoJson = cachedLapsWindGeoJson[lapsWrfData[dataLapsWrfIndex].data[selectedLapsWrfLayerName].timestamp]
			}
		}
		const stub = (<div className=" pr-0 pl-0">
			<SkeletonTheme>
				<div className="d-inline-flex mt-2 mb-2"  style={{width: '100%', height: '70vh'}}>
					<div style={{width: '25%'}} >
						<Skeleton height={'47%'} duration={8}/>
						<Skeleton height={'47%'} duration={8}/>
					</div>
					<div style={{width: '38%', paddingLeft:'1vw'}}>
						<Skeleton height={'90%'} duration={8}/>
						<Skeleton height={'5%'} duration={8}/>
					</div>
					<div style={{width: '38%', paddingLeft:'1vw'}}>
						<Skeleton height={'90%'} duration={8}/>
						<Skeleton height={'5%'} duration={8}/>

					</div>
				</div>
			</SkeletonTheme>
		</div>);

		return (
			<>

				{loadingTab ? stub :
					<div className="container-fluid pr-0 pl-0">
						<div className="d-inline-flex mt-2 mb-2"  style={{width: '100%'}}>
							<div style={{width: '25%', height: "70vh", overflowY: 'auto'}} className="scrollbar scrollbar-primary">
								<Accordion defaultActiveKey="0">
									{/*WRF*/}
									<Card>
										<Card.Header>
											<Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
												<div className="justify-content-between">
													<GiWorld/>
													<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>WRF</span>
													{this.state.lastWrfRunning &&
													<span style={{fontSize: '0.6rem', float: 'right'}}>Ultima esecuzione: {this.state.lastWrfRunning}</span>}
												</div>
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
												<GridList spacing={15} cellHeight='auto' cols={1}>
													{this.getWrfCategoryList(selectedWrfLayer)}
												</GridList>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									{/*LAPS*/}
									<Card>
										<Card.Header>
											<Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
												<div className="justify-content-between">
													<GiWorld/>
													<span style={{ color: "#2b7943", fontSize: "1.2rem", fontFamily: "Roboto Slab" , marginLeft: '1vw'}}>Laps WRF</span>
													{this.state.lastLapsWrfRunning &&
													<span style={{fontSize: '0.6rem', float: 'right'}}>Ultima esecuzione LAPS: {this.state.lastLapsWrfRunning}</span>}
												</div>
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="1">
											<Card.Body>
												<GridList spacing={15} cellHeight='auto' cols={1}>
													{this.getLapsWrfCategoryList(selectedLapsWrfLayer)}
												</GridList>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div style={{width: '38%', paddingLeft:'1vw'}}>
								<div className="custom-popup" >
									<MapComponent
										width={"100%"}
										height={'67vh'}
										zoomSnap={false}
										zoneBbox={zoneBbox}
										setMapRef={mapRef => this.mapWrfRef = mapRef}
										buttonKey={'wrf'}
										tile={null}
										toggleBorders={() => this.setState({showBorders: !showBorders})}
										toggleWindArrows={() => this.setState({showArrows: !showArrows})}
										skipRecenter={true}>
										{selectedWrfLayer && <>
											{(showWrfRaster && currentWrfTiff  ) && <GeotiffRasterLayer
												key={'wrf_' + mapWrfKey}
												georaster={currentWrfTiff}
												opacity={0}
												resolution={256}
												colorScale={ChromaticScale.getScaleBySensorCategory(selectedWrfLayer)}
												handleClick={(val, latlng) => this.showInterpolatedPopup(val, latlng, this.mapWrfRef.leafletElement, 'wrf')}
												ndColor={'grey'}
												enableClickOutOfCalabria={true}
											/>}
											{!loadingWrfLayer && wrfImgUrl &&
											<ImageOverlay
												key={"image_wrf_" + mapWrfKey}
												url={wrfImgUrl}
												bounds={wrfData[dataWrfIndex].data[selectedWrfLayerName].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>}
											{loadingWrfLayer &&
											<ImageOverlay
												key={"temp_image_wrf_" + mapWrfKey}
												url={temporaryWrfImageUrl}
												bounds={wrfData[0].data[selectedWrfLayerName].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>}

											{instantWindGeoJson && showArrows &&
											<GeoJSON key={`windArrows_${mapWrfKey}`}
													 data={instantWindGeoJson}
													 onEachFeature={(feature, layer, context) => {}}
													 pointToLayer={(feature, latlng, context) => this.windPointToMarker(feature, latlng, this)}
											/>}
											<PrettyLegend
												key={'legend_wrf_' + legendWrfKey}
												customClass={'wrfLegend'}
												legendTitle={getLegendLabel(ALL_WRF_OPTIONS.find(opt => opt.value === selectedWrfLayer))}
												grades={ChromaticScale.getDomainBySensorCategory(selectedWrfLayer).map(d => selectedWrfLayer === 'TEMP2M' ? Math.round(d - 273.15) : d)}
												getColor={num => ChromaticScale.getScaleBySensorCategory(selectedWrfLayer)(selectedWrfLayer === 'TEMP2M' ? (num + 273.15) : num)}
												tickerGap={4}
												// tickerValues={[-20, 0, 20, 40, 60]}
											/>
											<MapWatermark
												key={'watermark_wrf_' + mapWrfKey}
												customClass={'wrfWatermark'}
												text={`${ALL_WRF_OPTIONS.find(opt => opt.value === selectedWrfLayer).label}`}
											/>
										</>}
										{showBorders && <GeoJSON key={"calabria_"}
																 data={GISTools.getRegioniBorders()}
																 style={{
																	 fillColor: "#fff",
																	 fillOpacity: 0,
																	 weight: 2,
																	 opacity: 1,
																	 color: "green",
																 }}/>}
									</MapComponent>

									<AnimationPlayer
										key={'wrf_animation'}
										width={'100%'}
										forcedIndex={this.state.dataWrfIndex}
										data={wrfData.map(d => ({timestamp: d.data[selectedWrfLayerName].timestamp}))}
										updateSnapshot={(dataIndex, showRaster) => this.updateWrfSnapshot(dataIndex, showRaster)}
										selectedTimeSlot={null}
										loadingLayer={loadingWrfLayer}/>
								</div>
							</div>
							<div style={{width: '38%', paddingLeft:'1vw'}}>
								<div className="custom-popup" >
									<MapComponent
										width={"100%"}
										height={'67vh'}
										zoomSnap={false}
										zoneBbox={zoneBbox}
										setMapRef={mapRef => this.mapLapsWrfRef = mapRef}
										buttonKey={'laps_wrf'}
										tile={null}
										toggleBorders={() => this.setState({showLapsBorders: !showLapsBorders})}
										toggleWindArrows={() => this.setState({showLapsArrows: !showLapsArrows})}
										skipRecenter={true}>
										{selectedLapsWrfLayer && <>
											{(showLapsWrfRaster && currentLapsWrfTiff  ) && <GeotiffRasterLayer
												key={'laps_wrf_' + mapLapsWrfKey}
												georaster={currentLapsWrfTiff}
												opacity={0}
												resolution={256}
												colorScale={ChromaticScale.getScaleBySensorCategory(selectedLapsWrfLayer)}
												handleClick={(val, latlng) => this.showInterpolatedPopup(val, latlng, this.mapLapsWrfRef.leafletElement, 'wrf')}
												ndColor={'grey'}
												enableClickOutOfCalabria={true}
											/>}
											{!loadingLapsWrfLayer && lapsWrfImgUrl &&
											<ImageOverlay
												key={"image_laps_wrf_" + mapLapsWrfKey}
												url={lapsWrfImgUrl}
												bounds={lapsWrfData[dataLapsWrfIndex].data[selectedLapsWrfLayerName].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>}
											{loadingLapsWrfLayer &&
											<ImageOverlay
												key={"temp_image_laps_wrf_" + mapLapsWrfKey}
												url={temporaryLapsWrfImageUrl}
												bounds={lapsWrfData[0].data[selectedLapsWrfLayerName].pngBox}
												opacity={1}
												zIndex={10}
											></ImageOverlay>}

											{instantLapsWindGeoJson && showLapsArrows &&
											<GeoJSON key={`laps_windArrows_${mapLapsWrfKey}`}
													 data={instantLapsWindGeoJson}
													 onEachFeature={(feature, layer, context) => {}}
													 pointToLayer={(feature, latlng, context) => this.windPointToMarker(feature, latlng, this)}
											/>}
											<PrettyLegend
												key={'legend_laps_wrf_' + legendLapsWrfKey}
												customClass={'laps_wrfLegend'}
												legendTitle={getLegendLabel(ALL_LAPS_WRF_OPTIONS.find(opt => opt.value === selectedLapsWrfLayer))}
												grades={ChromaticScale.getDomainBySensorCategory(selectedLapsWrfLayer).map(d => selectedLapsWrfLayer === 'TEMP2M' ? Math.round(d - 273.15) : d)}
												getColor={num => ChromaticScale.getScaleBySensorCategory(selectedLapsWrfLayer)(selectedLapsWrfLayer === 'TEMP2M' ? (num + 273.15) : num)}
												tickerGap={4} // a sixth
												// tickerValues={[-20, 0, 20, 40, 60]}
											/>
											<MapWatermark
												key={'watermark_laps_wrf_' + mapLapsWrfKey}
												customClass={'laps_ wrfWatermark'}
												text={`${ALL_LAPS_WRF_OPTIONS.find(opt => opt.value === selectedLapsWrfLayer).label}`}
											/>
										</>}
										{showLapsBorders && <GeoJSON key={"laps_calabria_"}
																	 data={GISTools.getRegioniBorders()}
																	 style={{
																		 fillColor: "#fff",
																		 fillOpacity: 0,
																		 weight: 2,
																		 opacity: 1,
																		 color: "green",
																	 }}/>}
									</MapComponent>
									<AnimationPlayer
										key={'laps_animation'}
										width={'100%'}
										forcedIndex={dataLapsWrfIndex}
										data={lapsWrfData.map(d => ({timestamp: d.data[selectedLapsWrfLayerName].timestamp}))}
										updateSnapshot={(dataIndex, showRaster) => this.updateLapsWrfSnapshot(dataIndex, showRaster)}
										selectedTimeSlot={null}
										loadingLayer={loadingLapsWrfLayer}
									/>
								</div>
							</div>
						</div>

					</div>
				}
			</>
		)
	}
}
