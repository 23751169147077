import React from 'react';
import MaibItem from './MaibItem';
import MaibDetails from './MaibDetails';
import PaginationComponent from '#/commons/components/PaginationComponent';
import BulletinClient from '#/lib/BulletinClient';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";
import AuthenticationService, {ROLE_ADMIN, ROLE_OFFICER} from "#/lib/AuthenticationService";
import { CircularProgress } from '@material-ui/core';


export default class MaibPagedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDateFilter: null,
			endDateFilter: null,
			allowCreationToday : false,
			pageKey: 0,
			isLoading: false
		}
	}

	componentDidMount(){
		if(!!this.props.canEdit){
			BulletinClient.isCreationAllowedToday(
				(data)=>{
					this.setState({
						allowCreationToday : true
					})
				},
				(error)=>{
					this.setState({
						allowCreationToday : false
					})
				},
				this.props.bulletinTypeName
			);
		}
	}
	forcePageReloading = () => {this.setState({pageKey: (this.state.pageKey + 1) % 1000 })};
	fromJsonToMaibItem(obj) {
		let maibDetails = <MaibDetails bulletinTypeName={this.props.bulletinTypeName} {...obj} />
		return <MaibItem {...obj} key={obj.id} details={maibDetails} editable={this.props.canEdit} refreshList={() => this.forcePageReloading()} />;
	}

	getPage = (successCallback, errorCallback, page) => {
		let params = {
			pageNumber: page,
			pageSize: 5,
			bulletinTypeName: this.props.bulletinTypeName
		}
		this.setState({ isLoading: true });
		BulletinClient.listBulletins(
			(obj) => { 
				this.setState({ isLoading: false });
				successCallback(obj, page);
				setTimeout(() => this.getPage(successCallback, errorCallback, page), 120000);
			},
			(err) => { 
				this.setState({ isLoading: false });
				errorCallback(err);
				setTimeout(() => this.getPage(successCallback, errorCallback, page), 120000);
			},
			params
		)
	}
	isOfficer = () => AuthenticationService.haveRolesPermssions([ROLE_OFFICER, ROLE_ADMIN]);

	render() {
		return (
			<div className="generic-page list-page">

				<h2 className='d-flex justify-content-center align-items-center mb-5'>MAIB</h2>
				{this.state.isLoading && (
					<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<CircularProgress />
					</div>
				)}
				<PaginationComponent
					key={this.state.pageKey}
					enableDateFilter={true}
					toItemFunction={(obj) => this.fromJsonToMaibItem(obj)}
					getPage={(successCallback, errorCallback, page, start, end) => this.getPage(successCallback, errorCallback, page, start, end)} />
		
					{this.props.canEdit ?
					<div style={{ position: "fixed", bottom: "5vh", right: "5vw" }}>
					<Link to={this.state.allowCreationToday ? this.props.createUrl : '#'}>
						<Tooltip title="Crea un nuovo MAIB">
							<Fab color="primary" disabled={!this.state.allowCreationToday || !this.isOfficer()}>
								<AddIcon />
							</Fab>
						</Tooltip>
					</Link>
					</div>
				:
				<></>
				}
			</div>
		)
	}
}
