import React from 'react'
import {GeoJSON} from 'react-leaflet';
import "#/commons/data/section/year-calendar.css"
import "#/backoffice/style/PolygonMapComponent.css"
import MapComponent from "#/commons/map/MapComponent";


export default class MosipMapComponent extends React.Component {


	/*
	selectedFieldToCheck
	selected
	geoJSON
	*/
	mapOptions = {
		center: [39.11, 16.55],
		zoom: 8,
		minZoom: 7,
		maxZoom: 12,
		width: "100%",
		height: "70vh"
	}

	polygonHighlightStyle = {
		'color': 'black',
		'weight': 2,
		'opacity': 1
	}

	componentDidMount = () => {
		if (this.props.setMapRef){
			this.props.setMapRef(this.mapRef);
		}
	}

	onEachPolygon(feature, layer) {
		layer.bindTooltip(`<b>${feature.properties.COMUNE}</b>`)
		layer.on('click', (e) => {
			if (!!this.props.openInfoModal){
				this.props.openInfoModal(feature, e.latlng);
			}
		});
		if (!!this.props.selectedIstatCode ){
			layer.on("add",  (event) => {
				if (this.props.selectedIstatCode === feature.properties.ISTAT && !!this.props.openInfoModal){
					this.props.openInfoModal(feature);
					layer.setStyle(this.polygonHighlightStyle)
				}
			});
		}
	}


	polygonStyle(feature, latlng) {
		if(!!this.props.polygonStyle){
			return this.props.polygonStyle(feature, latlng);
		}
		else{
			return {
				fillColor: "#fff0",
				weight: 0.2,
				opacity: 1,
				color: "grey",
				fillOpacity: 0
			};
		}

	}



	render() {

		return (

			<div className="custom-popup">
				{this.props.title !== '' && <div className="d-flex justify-content-between">
					<h3>{this.props.title}</h3>
				</div>}
				<MapComponent
					width={ this.props.width || this.mapOptions.width}
					height={this.props.height || this.mapOptions.height}
					zoom={this.props.zoom || this.mapOptions.zoom}
					zoomSnap={false}
					setMapRef={mapRef => this.mapRef = mapRef}
					buttonKey={this.props.buttonKey || 0}>
					<div id="testamitutto">

						{!!this.props.additionalGeoJSON ?
							<GeoJSON key={"additional_"}
									 data={this.props.additionalGeoJSON}
									 onEachFeature={(feature, layer) => this.props.onEachAdditionalPolygon(feature, layer)}
									 style={(feature, latlng) => this.props.additionalPolygonStyle(feature, latlng)}
							/> : <></>}
						<GeoJSON key={"polygons_" + this.props.polygonsKey }
								 data={this.props.geoJSON}
								 onEachFeature={(feature, layer) => this.onEachPolygon(feature, layer)}
								 style={(feature, latlng) => this.polygonStyle(feature, latlng)}
						/>
					</div>
				</MapComponent>
			</div>


		)
	}
}


